import { AuthInfoStateModel } from '../data-management/states/auth-info.state';
import { RoleActivityName } from './role-activity-name';


export enum Path {
  IMAGING_PROJECT = 'imagingproject/projects',
  OPERATIONAL_DASHBOARD = 'operationdashboard',
  APP_VERSION = 'app-versions',
  AUDIT_TRAILS = 'audit-trails'
}


export class PathRedirectIfNoPermissions {
  // ... [___PATH_FROM___]: ... = ___PATH_TO___;
  static readonly [Path.IMAGING_PROJECT]: string = Path.OPERATIONAL_DASHBOARD;
  static readonly [Path.APP_VERSION]: string = Path.IMAGING_PROJECT;
  static readonly [Path.AUDIT_TRAILS]: string = Path.IMAGING_PROJECT;
}


const ROLE_NAME_ACCESS_APP_VERSIONS = 'IAG Project Manager';

export class PathAccessPermission {
  static readonly [Path.IMAGING_PROJECT]: ((info: AuthInfoStateModel) => boolean) = PathAccessPermission.hasClinicalTrialsAccess;
  static readonly [Path.OPERATIONAL_DASHBOARD]: ((info: AuthInfoStateModel) => boolean) = PathAccessPermission.hasOperationDashboardAccess;
  static readonly [Path.APP_VERSION]: ((info: AuthInfoStateModel) => boolean) = PathAccessPermission.hasAppVersionsAccess;
  static readonly [Path.AUDIT_TRAILS]: ((info: AuthInfoStateModel) => boolean) = PathAccessPermission.hasAuditTrailsAccess;

  private static hasClinicalTrialsAccess(authInfo: AuthInfoStateModel) {
    return !!authInfo.activities.find(a => a.activities.includes(RoleActivityName.CLINICAL_TRIALS_ACCESS));
  }

  private static hasOperationDashboardAccess(authInfo: AuthInfoStateModel) {
    return !!authInfo.activities.find(a => a.activities.includes(RoleActivityName.DASHBOARD_ACCESS));
  }

  private static hasAppVersionsAccess(authInfo: AuthInfoStateModel) {
    return authInfo.roles.map(role => role.roleName).includes(ROLE_NAME_ACCESS_APP_VERSIONS);
  }

  private static hasAuditTrailsAccess(authInfo: AuthInfoStateModel) {
    return !!authInfo.activities.find(a => a.activities.includes(RoleActivityName.AUDITTRAILS_ACCESS));
  }
}
