import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

export interface DialogModalityData {
  isAdd: boolean;
}

@Component({
  selector: 'app-qcmodalitysubmit-dialog',
  templateUrl: './qcmodalitysubmit-dialog.component.html',
  styleUrls: ['./qcmodalitysubmit-dialog.component.css']
})
export class QCModalitysubmitDialogComponent implements OnInit {

  reasonComment = '';

  constructor(public dialogRef: MatDialogRef<QCModalitysubmitDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogModalityData) {
  }

  ngOnInit() {
    this.reasonComment = '';
  }

  onClickCancel(): void {
    this.dialogRef.close({status: 'cancel', data: null} );
  }

  onClickOk(): void {
    this.dialogRef.close({status: 'ok', data: this.reasonComment} );
  }
}
