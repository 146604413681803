import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';


const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class EndpointService {

  constructor(private http: HttpClient) { }

  create(endpoint: any) {
    return this.http.post(requestURL + `/endpoint`, endpoint);
  }

  update(endpoint: any) {
    return this.http.put(requestURL + `/endpoint`, endpoint);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/endpoint/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/endpoint/` + id);
  }

  getAll(): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(requestURL + `/endpoint/retrieve-all`);
  }
}
