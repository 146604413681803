import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { MessageDialogComponent } from 'src/app/components/controls/message-dialog/message-dialog.component';
import {
  GlobalLesionModel,
  MranoEfficacyLesionSetting,
  OncologyLesionType,
  OncologyResponseEnum,
  OncologyResponseModel,
  OncologyResponseType,
  ResponseOptions
} from 'src/app/_models/Oncology/global-lesion-model';
import { OncologyAssessmentVisitModel } from 'src/app/_models/Oncology/oncology-assessment-visit-model';
import { OncologyAssessmentService } from 'src/app/_services/oncology-assessment.service';
import { MranoEfficacyResponseCommentDialogComponent } from './mrano-efficacy-response-comment-dialog/mrano-efficacy-response-comment-dialog.component';
import { MranoefficacyresponseoptionsComponent } from './mrano-efficacy-response-options/mrano-efficacy-response-options.component';


export interface ResponseDialogFormData {
  allLesionTypes: string[],
  studyId: number,
  readingId: number,
  readerId: number,
  endpointName: string,
  visit: OncologyAssessmentVisitModel,
  visits: OncologyAssessmentVisitModel[],
  baselineSPPD: any,
  nadirSPPD: any,
  historicalNadir: any[],
  allResponses: any[],
  allLesions: GlobalLesionModel[],
  primaryLocations: any[],
  nadirVisitConfigId: number
}

@Component({
  selector: 'app-mrano-efficacy-oncology-response-assessment-dialog',
  templateUrl: './mrano-efficacy-oncology-response-assessment-dialog.component.html',
  styleUrls: ['./mrano-efficacy-oncology-response-assessment-dialog.component.css']
})
export class MranoEfficacyOncologyResponseAssessmentDialogComponent implements OnInit, OnDestroy {
  responseEnum = OncologyResponseEnum;
  responseType = OncologyResponseType;
  lesionType = OncologyLesionType;
  minimized = false;
  showModalSpinnerLoadingData = false;
  multiTP = false;
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  lesionReviewedSubscription: Subscription;
  lesionsReviewed: any = {};
  MranoEfficacyLesionSetting = MranoEfficacyLesionSetting;

  constructor(public dialogRef: MatDialogRef<MranoEfficacyOncologyResponseAssessmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ResponseDialogFormData,
              private oncologyAssessmentService: OncologyAssessmentService,
              private toastyService: ToastyService,
              private dialogMessagePreview: MatDialog) {
    this.data.visits = this.data.visits.filter(visit => {
    return visit.id <= this.data.visit.id;
    });
  }

  ngOnInit(): void {
    this.lesionReviewedSubscription = this.oncologyAssessmentService.lesionReviewed.subscribe(result => {
      if (result) {
        if (result.visitConfigId === this.data.visit.visitConfigId)
          this.lesionsReviewed[result.status.type] = result.status.reviewed;
      }
    })
  }

  ngOnDestroy(): void {
    if (this.lesionReviewedSubscription)
      this.lesionReviewedSubscription.unsubscribe();
  }

  minimizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.add('minimized');
    let top = ((dialog[0] as HTMLElement).offsetTop + 60) + 'px';
    document.documentElement.style.setProperty('--dialog-display', 'translate3d(0 , calc(100vh - ' + top + ') , 0)');
    this.minimized = true;
  }

  maximizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.remove('minimized');
    document.documentElement.style.setProperty('--dialog-display', '');
    this.minimized = false;
  }

  getJustFollowups() {
    return this.data.visits.filter(visit => {
      return !visit.baseline;
    });
  }

  onChangeResponse(finalResponse, visitConfigId: number) {
    const responseObject = this.data.allResponses.find(response => {
      return response.visitConfigId === visitConfigId;
    });
    responseObject.response = finalResponse;
    const changeResponseVisit = this.data.visits.find(vi => vi.visitConfigId === visitConfigId);
    changeResponseVisit['changedResponse'] = true;
    this.showModalSpinnerLoadingData = true;
    const data: OncologyResponseModel = {
      id: responseObject.id,
      endpointName: this.data.endpointName,
      readingId: this.data.readingId,
      response: finalResponse,
      type: responseObject.type,
      userId: this.data.readerId,
      visitConfigId: visitConfigId
    };
    this.oncologyAssessmentService.updateResponsesByVisitConfigId(this.data.studyId, data).subscribe(() => {
      this.showModalSpinnerLoadingData = false;
    });
  }

  getLesions(type: OncologyLesionType) {
    const lesions = this.data.allLesions.filter(l => l.type === type);
    let flags = [];
    let output = [];
    for (let index = 0; index < lesions.length; index++) {
      if (flags[lesions[index].lesionName]) {
        continue;
      }
      flags[lesions[index].lesionName] = true;
      output.push(lesions[index]);
    }
    return output;
  }

  lesionTypePriority(type: OncologyLesionType ) {
    switch (type) {
      case this.lesionType.TARGET_LESION:
        return 0
        break;
        case this.lesionType.NON_TARGET_LESION:
          return 1
        break;
        case this.lesionType.NEW_LESION:
          return 2
        break;
    }
  }

  getLesion(lesionName: string, visitConfigId: number) {
    return this.data.allLesions.find(lesion => lesion.lesionName === lesionName && lesion.visitConfigId === visitConfigId);
  }

  onShowComment(lesion: GlobalLesionModel) {
    let title = `${lesion.lesionName} Comment`;
    let message = lesion.comment;
    this.dialogMessagePreview.open(MessageDialogComponent, {
      width: '350px',
      data: { title: title, message: message, html: false }
    });
  }
// check: .response : 'NA' remove
  getResponse(visitConfigId: number) {
    const result = this.data.allResponses.find(response => response.visitConfigId === visitConfigId);
    return result.response;
  }

  openResponseOptions(visitConfigId: number) {
    const dialogRef = this.dialogMessagePreview.open(MranoefficacyresponseoptionsComponent, {
      width: '',
      data: {
       response: this.getResponse(visitConfigId)
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.onChangeResponse(result, visitConfigId)
    });
  }

  openResponseComments(visit: OncologyAssessmentVisitModel ) {
    const dialogRef = this.dialogMessagePreview.open(MranoEfficacyResponseCommentDialogComponent, {
      width: '',
      data: {
       comment: visit.comment
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.save) {
        visit.comment = result.comment;
        this.onSaveVisitComment(result.comment);
      }
    });
  }

  disableResponse(visit: any) {
    return visit.visitConfigId !== this.data.visit.visitConfigId;
  }

  getSPPD(visitConfigId) {
    const lesions = this.data.allLesions.filter(lesion => {
      return  lesion.visitConfigId === visitConfigId && lesion.isMeasurable;
    });
    let sppd = 0;
    lesions.forEach(l => {
      if (l.ppd !== 'NA' && l.ppd !== 'NE' &&
      (l.status === 'PRESENT' || l.type === this.lesionType.TARGET_LESION)) {
        sppd += +l.ppd;
      }
    });
    return sppd;
  }

  compareSPPD(sppd: any, visitConfigId: number) {
    let sppdComparison = 0;
    if (sppd == 'NA' || sppd === null || sppd === undefined)
      return 'NA';
    if (sppd == 'NE' || sppd === 0)
      return 'NE';
    sppd = +sppd;

    const currentSPPD = this.getSPPD(visitConfigId);
    if (currentSPPD >= 0) {
      sppdComparison = (currentSPPD - sppd) * 100 / sppd;
    }
    return +sppdComparison.toFixed(2);
  }

  compareNadirSPPD(visitConfigId: number) {
    if (this.data.nadirVisitConfigId === visitConfigId) {
      return 0;
    }
    if (!this.data.historicalNadir) {
      return;
    }
    const nadir = this.data.historicalNadir.find(hn => hn.visitConfigId === visitConfigId);
    if (!nadir)
      return 'NA';
    
    if (nadir.nadirVisitConfigId === visitConfigId)
      return 0;

    let sppd = nadir.nadirVisitTotalPpd;
    if (nadir.nadirVisitConfigId === visitConfigId) {
      return 0;
    }
    if (sppd == 'NA' || sppd === null || sppd === undefined)
      return 'NA';
    if (sppd == 'NE' || sppd === 0)
      return 'NE';
    sppd = +sppd.toFixed(2);

    let sppdComparison = 0;
    const currentSPPD = this.getSPPD(visitConfigId);
    if (currentSPPD >= 0) {
      sppdComparison = (currentSPPD - sppd) * 100 / sppd;
    }
    return sppdComparison <= 0 ? 0 : +sppdComparison.toFixed(2);
  }

  comparePreliminaryPD(visitConfigId: number) {
    const preliminaryVisit = [...this.data.allResponses].reverse().find(res => {
      return res.response === 'PRELIMINARY_PD';
    });
    if (preliminaryVisit) {
      const visitIndex = this.data.visits.findIndex(v => v.visitConfigId === visitConfigId)
      const preliminaryVisitIndex = this.data.visits.findIndex(v => v.visitConfigId === preliminaryVisit.visitConfigId)
      if (preliminaryVisitIndex < visitIndex) {
        const preliminarySPPD = this.getSPPD(preliminaryVisit.visitConfigId)
        let sppdComparison = 0;
        const currentSPPD = this.getSPPD(visitConfigId);
        if (currentSPPD >= 0) {
          sppdComparison = (currentSPPD - preliminarySPPD) * 100 / preliminarySPPD;
        }
        return +sppdComparison.toFixed(2);
      }
      return 'NA'
    }
    return 'NA'
  }

  onSaveVisitComment(comment: string) {
    const commentObject = {
      id: this.data.visit.id,
      comment: comment
    }
    this.showModalSpinnerLoadingData = true;
    this.oncologyAssessmentService.updateVisitComment(this.data.studyId, commentObject).subscribe(response => {
      this.showModalSpinnerLoadingData = false;
      this.toastOptions.title = 'SUCCESS: The comment is saved';
      this.toastOptions.msg = 'Comment is successfully saved';
      this.toastyService.success(this.toastOptions);
    });
  }

  closeClick() {
    this.dialogRef.close({ submit: false, responses: this.data.allResponses });
  }

  saveClick() {
    this.dialogRef.close({ submit: true, responses: this.data.allResponses, multiTP: this.multiTP });
  }
  checkAllLesionsReviewed() {
      let count = 0
      this.MranoEfficacyLesionSetting.allLesionTypes.forEach(type => {
        if (this.lesionsReviewed[type] === false)
          count++;
      });
      return count === 0;
  }

  validateSave() {
   return this.data.visits.filter(visit => !visit.comment && visit['changedResponse'] ).length === 0
  }

}
