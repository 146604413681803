import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../reading-form-scoring.component';
import {Observable} from 'rxjs';
import {BasicResponse} from '../../../core/interfaces/basic-response';
import {BasicReading, BasicReadingVisit} from '../../../_models/basic-reading';
import {areAllVisitsLocked, hasReadingNotLockedVisits, setVisitsLockAvailability} from '../helper/visit-helper';
import {modifyTabHeader, updateTabHeaderWidth} from '../helper/mat-tab-helper';
import {sortDateRanged} from '../../../util/time-unit/time-unit-util';
import {ReadingRecistService} from '../../../_services/reading-recist.service';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';

@Component({
  selector: 'app-recist',
  templateUrl: './recist.component.html',
  styleUrls: ['./recist.component.css']
})
export class RecistComponent extends ReadingFormScoringComponent implements OnInit, AfterViewInit, ReadingFormScoring {

  studyId: number;
  currentReading: BasicReading;
  visits: BasicReadingVisit[] = [];

  constructor(private store: Store,
              private readingService: ReadingRecistService) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('RECIST 1.1 Scoring Form'));
    this.studyId = +JSON.parse(localStorage.getItem('project')).id;
    this.subscribeCurrentReading();
  }

  ngAfterViewInit(): void {
    modifyTabHeader('Visit selector');
  }

  changeTabHeaderWidth(): void {
    updateTabHeaderWidth();
  }

  subscribeCurrentReading(): void {
    this.currentReadingSubject.subscribe(reading => {
      this.currentReading = reading;
      this.visits = setVisitsLockAvailability(sortDateRanged<BasicReadingVisit>(this.currentReading.visits));
      this.switchSubmitBtnDisabledSubject.next(!hasReadingNotLockedVisits(this.currentReading));
    });
  }

  getEndpointName(): string {
    return 'RECIST 1.1';
  }

  clearForm(): void {
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingService.getReadingByStudyIdAndReaderId(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    const submitData = {
      id: this.currentReading.id,
      timeSpent: data.spentSeconds
    };
    return this.readingService.completeReading(studyId, submitData);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.updateReading(studyId, readingId, data.spentSeconds);
  }

  lockVisit(visitId: number): void {
    this.readingService.lockTimepoint(this.studyId, visitId, true).subscribe(response => {
      this.visits.find(v => v.id === visitId).locked = response.data.locked;
      this.visits = setVisitsLockAvailability(this.visits);
      this.switchSubmitBtnDisabledSubject.next(!areAllVisitsLocked(this.visits));
    });
  }

}
