import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {StudyService} from '../../../_services/study.service';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';

const moment = _moment;

export interface DialogModalityData {
  isAdd: boolean;
}

const DATE_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-qcpatientbirthdateedit-dialog',
  templateUrl: './qcpatientbirthdateedit-dialog.component.html',
  styleUrls: ['./qcpatientbirthdateedit-dialog.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
  ]
})
export class QCPatientBirthDateEditDialogComponent implements OnInit {
  startDate;

  date;
  reasonComment = '';

  patientForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<QCPatientBirthDateEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogModalityData,
              private studyService: StudyService,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.date = this.data['date'];
    this.patientForm = this.fb.group({
      date: new FormControl(this.date, [Validators.required]),
      reasonComment: new FormControl(this.reasonComment, Validators.required)
    });
  }

  onClickCancel(): void {
    this.dialogRef.close({status: 'cancel', data: null});
  }

  onClickOk(): void {
    const data = {
      patientBirthDate: this.date.getFullYear() + '-' + ('0' + (this.date.getMonth() + 1)).slice(-2) + '-01',
      reasonComment: this.patientForm.controls.reasonComment.value
    };
    this.dialogRef.close({status: 'ok', data});
  }

}
