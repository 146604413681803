import { Component, OnInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { Router, ActivatedRoute } from '@angular/router';
import { QueriesService } from '../../../../_services/queries.service';
import { UserService } from '../../../../_services/user.service';
import { DataUploadService } from '../../../../_services/data-upload.service';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';


@Component({
  selector: 'app-new-edcf',
  templateUrl: './new-edcf.component.html',
  styleUrls: ['./new-edcf.component.css']
})
export class NewEdcfComponent implements OnInit {

  edcfComment = '';

  selectedQCTask:any;
  selectedQCTaskUploadInfo: {};
  project: {};
  siteId: 0;
  qcModalities = [];
  qcFilteredModalities = [];

  recipients = [];

  displayedColumnsSeries: string[] = ['id', 'label', 'status', 'comment', 'repeat'];

  constructor(
    private dataUploadService: DataUploadService,
    private queriesService: QueriesService,
    private userService: UserService,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Create new eDCF'));
    this.project = JSON.parse(localStorage.getItem('project'));
    this.selectedQCTask = JSON.parse(localStorage.getItem('qc.selectTask'));
    this.siteId = this.selectedQCTask['siteId'];
    this.qcModalities = JSON.parse(localStorage.getItem("qc.queries.edcf.modalities"));

    this.userService.getUsersBySiteIdAndStudyId(this.siteId, this.project['id']).subscribe( recipientResult =>{
      this.recipients = recipientResult['data'];
    });

    this.dataUploadService.getUploadGeneralInfoById(this.project['id'], this.selectedQCTask['generalInfoId']).subscribe( deneralInfoRes => {
      this.selectedQCTaskUploadInfo = deneralInfoRes['data'];
    });

    this.queriesService.getQueriesByVisitConfigId(this.selectedQCTask['visitConfigId'], this.project['id']).subscribe( queriesVisitResult => {
      this.qcFilteredModalities = this.filterModalitiesWithCreatedEDCF(queriesVisitResult['data']);
      //add series dataSource
      this.qcFilteredModalities.forEach(modality =>{
        modality.failedSeries = modality.series.filter(ser => (ser.status == 'failed'));
        modality.dataSourceFailedSeries = new MatTableDataSource(modality.failedSeries);
      })
    });
  }

  filterModalitiesWithCreatedEDCF(edcfs){
    let qcmod = this.qcModalities.filter(mod => mod.qcModalityLock == false || mod.qcModalityLock == null );

    let modalities = [];
    qcmod.forEach( qcm => {
      if( edcfs.length > 0 ) {
        edcfs.forEach(edcf => {
          //let edcf
          let m = edcf.modalities.filter(modal => modal.modalityId == qcm.modalityId);
          if(m.length == 0)
            modalities.push(qcm);
        });
      }
      else {
        modalities.push(qcm);
      }
    });

    return modalities;
  }

  onCommentChange(){
  }

  onCreateEdcf(){
    let modalities = [];
    this.qcModalities.forEach(modality => {
      let m = {
        modalityId: modality.modalityId,
        name: modality.modalityName,
        comment: modality.comment,
        missingSequenceLabels: [],
        failedSeries: [],
      };
      modality.missingLabels.forEach(label => {
        let l = {
          sequenceLabelId: label.id,
        };
        m.missingSequenceLabels.push(l);
      });
      if(modality.failedSeries != null){
        modality.failedSeries.forEach(series => {
          let s = {
            seriesId: series.seriesId,
            repeatFlag: series.repeat,
            comment: series.comment,
          };
          m.failedSeries.push(s);
        });
      };
      modalities.push(m);
    });

    const edcf = {
      createUserId: + JSON.parse(localStorage.getItem('userId')),
      studyId: this.project['id'],
      qcTaskId: this.selectedQCTask['id'],
      uploadUserId: this.selectedQCTaskUploadInfo['uploadUserId'],
      visitConfigId: this.selectedQCTask['visitConfigId'],
      siteConfigId: this.selectedQCTask['siteConfigId'],
      patientId: this.selectedQCTask['patientId'],
      freeComment: this.edcfComment,
      modalities: modalities,
    };

    this.queriesService.create(edcf, this.project['id']).subscribe(edcfResult =>{
      localStorage.setItem('qc.queries.edcf.modalities',null);
      this.router.navigate(['/imagingproject/qc/details']);
    });
  }

  onCllickCancel(){
    localStorage.setItem('qc.queries.edcf.modalities',null);
    this.router.navigate(['/imagingproject/qc/details']);
  }

}
