import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ComponentFactoryResolver,
    ElementRef,
    OnDestroy,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren,
    ViewContainerRef
  } from '@angular/core';
  import {SeriesNavigatorComponent} from 'src/app/components/controls/series-navigator/series-navigator.component';
  import {forkJoin, interval, Observable, Subscription} from 'rxjs';
  import {EventService, ImagingProjectService, ReadingConfigFlexibleService, ReadingPsmaPetEligibilityService, StudySequenceLabelService, StudyUserService, UserService} from 'src/app/_services';
  import {Store} from '@ngxs/store';
  import {SetPageHeaderTitle} from 'src/app/core/data-management/actions/projects.action';
  import {ToastOptions, ToastyService} from 'ng2-toasty';
  import {SequenceLabelModel} from 'src/app/_models/ImagingProject/sequence-label-model';
  import {FlexibleConfig} from 'src/app/core/interfaces/flexible-config';
  import {BaseFlexibleConfig} from 'src/app/_models/ImagingProject/base-flexible-config';
  import { ViewerCoreLtComponent } from '../../Viewer/viewer-core-lt/viewer-core-lt.component';
  import {ViewerAuthenticationService} from 'src/app/_services';
  import { QualityControlService } from 'src/app/_services/quality-control.service';
  import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
  import { PSMAPETReadingModel } from 'src/app/_models/PSMA-PET/psma-pet-reading-model';
  import { PSMAPETLesionObject, PSMAPETScoringType } from 'src/app/_models/PSMA-PET/psma-pet-scoring-model';
  import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
  import { MatDialog } from '@angular/material/dialog';
  import { MessageDialogComponent } from 'src/app/components/controls/message-dialog/message-dialog.component';
  import { ActivatedRoute, Router } from '@angular/router';
import { ReadingFormBaseComponent } from '../reading-form-base.component';
  
  
  interface ViewsLayoutConfiguration {
      col: number;
      row: number;
      sizex: number;
      sizey: number;
  }
  @Component({
    selector: 'app-psma-form-pet',
    templateUrl: './psma-pet-form.component.html',
    styleUrls: ['./psma-pet-form.component.css']
  })
  export class PSMAPETFormComponent extends ReadingFormBaseComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
    @ViewChildren('viewerLT', {read: ViewContainerRef}) viewerLTs: QueryList<ViewContainerRef>;
    viewerLT: ViewContainerRef;
    @ViewChild('viewercontiner', {read: ElementRef}) viewercontiner: ElementRef;
    @ViewChild('seriesNavigator') seriesNavigator: SeriesNavigatorComponent;
    selectedTab=0;
    newViewer: any = null;
    newLTViewer: any = null;
    isActiveLTViewer = false;
    openedNumberOfImages = 0;
    viewsLayoutConfigurations: ViewsLayoutConfiguration[] = [];
    readingList: Array<PSMAPETReadingModel> = [];
    currentReading: PSMAPETReadingModel;
    userId: number;
    study: any;
    spentSecondsSubsctiption: Subscription;
    ltViewersSubsctiption: Subscription;
    ltViewerSubsctiption: Subscription;
    showViewer = false;
    spentSeconds = 0;
    showModalSpinnerLoadingData = true;
    visitedSeriesCounter = 0;
    visitConfig: any;
    visits=[];
    readingSeries = [];
    allseries=[];
    readingSeriesDetails = [];
    isExpanded = true;
    currentScoringFormSelector = PSMAPETScoringType.ELIGIBILITY;
    public get PSMAPETLesionObject(): typeof PSMAPETLesionObject {
      return PSMAPETLesionObject;
    }
    readingLevel: string;
    eCRFOnly:boolean=false;
    detailsGot:boolean=false;
    validationMessages: string[];
    toastOptions: ToastOptions = {
        title: '',
        showClose: true,
        timeout: 10000,
        theme: 'material',
    };
    validUids: string[] = [];
    availableSequenceLabels: SequenceLabelModel[] = [];
    defaultSequenceLabelIds: number[] = [];
    screenShotData:any;
    editObjectData:any;
    readingConfigResponse:any;
    resizeInterval: any = null;
    seriesStatusSubscription:Subscription;
    deletedAnnotationSubscription:Subscription;
    modalSpinnerSubscription:Subscription;
    openLTViewerSubscription:Subscription;
    editMeasurementSubscription:Subscription;
    lesionPresentField:boolean =false;
    commentField:string;
    visitForm:FormGroup;
    viewerData = null;
    viewerDataIsReady = false;
    viewerDataIsLoading = false;
    submitted : boolean = false;

  
    constructor(
        public componentFactoryResolver: ComponentFactoryResolver,
        private studySequenceLabelService: StudySequenceLabelService,
        private readingConfigFlexibleService: ReadingConfigFlexibleService,
        private readingPSMAService:ReadingPsmaPetEligibilityService,
        private toastyService: ToastyService,
        private store: Store,
        private readingConfigService: ReadingConfigFlexibleService,
        private authenticationService:ViewerAuthenticationService,
        private imagingProjectService:ImagingProjectService,
        private dialogMessagePreview:MatDialog,
        private router:Router,
        private studyUserService: StudyUserService,
        public utils: Utils,
        public route: ActivatedRoute,
        public cdr: ChangeDetectorRef,
        public eventService: EventService
    ) {
        super(route, cdr, componentFactoryResolver, eventService, utils);
    }
  
    ngOnInit(): void {
        
        //modal spinner
        this.modalSpinnerSubscription = this.readingPSMAService.modalSpinner.subscribe(state=>{
            this.ModalSpinnerChangeState(state);
        })
        this.store.dispatch(new SetPageHeaderTitle('PSMA-PET Eligibility Scoring Form'));
        this.userId = +JSON.parse(localStorage.getItem('userId'));
        this.study = JSON.parse(localStorage.getItem('project'));
        this.configureLayouts();
        const serviceSources = [
            this.readingPSMAService.getReadingByStudyIdAndReaderId(this.study.id, this.userId),
            this.readingConfigFlexibleService.getByStudyId(this.study['id'])
        ];
        forkJoin(serviceSources).subscribe(
            values => {
                const [readingResponse, readingConfigResponse] = values as any;
                if (readingResponse['responseCode'] === 200 && readingConfigResponse['responseCode'] === 200) {
                    this.readingConfigResponse = readingConfigResponse;
                    this.refreshReadingsList(readingResponse);
                } else if (readingResponse['responseCode'] !== 200) {
                    this.showErrorMessage(readingResponse);
                } else if (readingConfigResponse['responseCode'] !== 200) {
                    this.showErrorMessage(readingConfigResponse);
                }
                this.showModalSpinnerLoadingData=false;
            },
            (error) => this.showErrorMessage(error.error)
        );
        this.spentSecondsSubsctiption = interval(1000).subscribe(() => {
            this.spentSeconds++;
        });
        //get all annotation deleted when lesion deleted
        this.deletedAnnotationSubscription = this.readingPSMAService.deletedAnnObjects.subscribe(response=>{
            if(response){
               // console.log(response)
            }
        })
        //open lt viewer from children
        this.openLTViewerSubscription = this.readingPSMAService.openLTViewerData.subscribe(data=>{
            if(data)
                this.openLTViewerFromChildren(data)
        })
        this.editObjectData = null;
        //edit lesion measurement
        this.editMeasurementSubscription = this.readingPSMAService.editLesionMeasurement.subscribe(data=>{
            this.onEditMeasurement(data);
        })
        this.modalSpinnerSubscription = this.readingPSMAService.modalSpinner.subscribe(data=>
          {
              this.showModalSpinnerLoadingData = data;
              this.detailsGot=!data;
          }
        )
    }
  
    showReadingForm(): void {
        this.isExpanded = true;
        setTimeout(() => {
          this.onResize();
        }, 100);
      }
      hideReadingForm(): void {
        this.isExpanded = false;
        setTimeout(() => {
          this.onResize();
        }, 100);
      }
      onResize(): void {
        window.dispatchEvent(new Event('resize'));
      }
  
    ngOnDestroy(): void {
        if (this.spentSecondsSubsctiption) {
            this.spentSecondsSubsctiption.unsubscribe();
        }
        if (this.ltViewerSubsctiption) {
            this.ltViewerSubsctiption.unsubscribe();
        }
        if (this.ltViewersSubsctiption) {
            this.ltViewersSubsctiption.unsubscribe();
        }
        if (this.currentReading) {
            this.readingPSMAService.updateReading(
                this.study.id, this.currentReading.id, this.spentSeconds
            ).subscribe(response => {
            });
        }
        if(this.seriesStatusSubscription){
            this.seriesStatusSubscription.unsubscribe();
        }
        if(this.deletedAnnotationSubscription){
            this.deletedAnnotationSubscription.unsubscribe();
        }
        this.readingPSMAService.modalSpinnerChangeState(true);
        this.detailsGot=false;
        if(this.modalSpinnerSubscription){
            this.modalSpinnerSubscription.unsubscribe();
        }
        if(this.openLTViewerSubscription){
            this.openLTViewerSubscription.unsubscribe();
        }
        if(this.editMeasurementSubscription){
            this.editMeasurementSubscription.unsubscribe();
        }
        this.editObjectData = null;
    }
  
    ngAfterViewInit(): void {
        this.ltViewersSubsctiption = this.viewerLTs.changes.subscribe((comps: QueryList<ViewContainerRef>) => {
            if (!this.viewerLT && comps.first) {
                this.viewerLT = comps.first;
                this.preOpenLTViewer();
            }
        });
    }
  
    ngAfterViewChecked(): void {
        this.cdr.detectChanges(); // fix ExpressionChangedAfterItHasBeenCheckedError
    }
  
    configureFromConfigFlexible(response) {
        let existed = false;
        const data = response['data'];
        if (data) {
            for (let i1 = 0; i1 < data.length; i1++) {
                const i = data[i1];
                if (i.config['endpoint'].name.toLowerCase() === 'psma pet eligibility' && this.currentReading.configId === i['id'] ) {
                    if(i.config['imageViewerConfigurationType']==='eCRF_Only')
                        this.eCRFOnly=true;
                    this.readingLevel = i.config.readingLevel;
                    existed=true;
                    break;
                }
            }
        }
        if(!existed){
            this.toastOptions.title ='ERROR Reading configuration not existed'
            this.toastOptions.msg='There is no active reading configuration for this reading'
            this.toastyService.error(this.toastOptions);
        }
    }
  
    refreshReadingsList(response) {
        this.readingList = [];
        const read = response['data'];
        if (read != null) {
            this.readingList = read;
            if (this.readingList.length) {
                this.initReading(this.readingList[0]);
            }
            else{
                this.detailsGot = true;
                this.showModalSpinnerLoadingData = false;
            }
        }
    }
  
    initReading(reading) {
        this.submitted = false;
        this.detailsGot=false;
        this.eCRFOnly=false;
        this.showModalSpinnerLoadingData = true;
        this.currentReading = reading;
        //console.log(this.currentReading)
        this.currentScoringFormSelector = PSMAPETScoringType.ELIGIBILITY;
        this.currentReading.visit = reading['visit'];
        this.visitedSeriesCounter = 0;
        this.visitConfig = this.currentReading.visit;
        this.initVisitForm(this.visitConfig);
        this.currentReading.readingLevel = this.readingLevel;
  
        this.spentSeconds = this.currentReading.timeSpent || 0;
        if (this.currentReading.status.code < 700) {       // in_progress
            this.readingPSMAService.startReading(this.currentReading.studyId, this.currentReading.id).subscribe(response => {
                if (response['responseCode'] === 200) {
                    this.currentReading.status = response['data'].status;
                } else {
                    this.showErrorMessage(response);
                }
            });
        }
  
        this.configureFromConfigFlexible(this.readingConfigResponse);
        const viewerDataResults: Array<Observable<any>> = [];
        this.readingSeries = [];
        this.showModalSpinnerLoadingData = false;
        if(!this.eCRFOnly){
            [this.visitConfig].forEach(visits=>{
               this.imagingProjectService.getVisitConfig(visits.visitConfigId).subscribe(imagProjResp => {
                   let visitConfig = imagProjResp['data'];
                   if (visitConfig != null) {
                       visitConfig.series.forEach(visitSeries => {
                           const series = visitSeries;
                           if (series != null) {
                               this.allseries.push(series);
                               series.seriesId = visitSeries.id;
                               series.seriesInstanceUID = visitSeries.seriesUID;
                               series.visitConfigId = visits.visitConfigId;
                               series.seriesVisitName = visitConfig.visitName;
                               const visitInfo = this.getVisitInfo(series.seriesId);
                               series.timepoint = (visitInfo && visitInfo.timepoint) ? visitInfo.timepoint : 'Missing';
                               const info = { selectedPage: 'Reading' };
                               viewerDataResults.push(this.utils.initViewerData(series, this, info));
                           }
                       });
                   }
   
                   if (viewerDataResults.length) {
                       this.viewerDataIsLoading = true;
                       forkJoin(viewerDataResults).subscribe(() => {
                           this.viewerData['shouldHidePatient'] = true;
                           this.viewerDataIsReady = true;
                           this.viewerDataIsLoading = false;

                           this.openLTViewer(['-1']);
                           if (this.viewerData.studyUserRoles && this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
                               this.viewerData.toolbarExcludeItems = ['ShowDicom'];
                           }

                           //this._updateViewerToolbar();
                       });
                   }
               });
           })
           if (this.currentReading.visit) {
               this.readingSeries = [];
               this.readingSeriesDetails = [];
               const seriesIds = [];
               this.currentReading.visit.series.forEach(s => {
                       this.readingSeries.push(s);
                       seriesIds.push(s.seriesId);
                   });
               this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
                   this.detailsGot=true;
                   this.initDefaultSequenceLabels(configResp.data);
                 });
               this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).subscribe(response => {
                   if (response['responseCode'] === 200) {
                       if (response['data']) {
                           this.readingSeriesDetails = response['data'];
                          // console.log(this.readingSeriesDetails)
                           this.readingSeriesDetails.forEach(sd => {
                               const visit = this.getVisitInfo(sd.id);
                               if (visit != null) {
                                   sd['seriesVisitName'] = visit.timepoint;
                                   sd['visitConfigId'] = visit.visitConfigId;
                               }
                           });
                       }
                   } else {
                       this.showErrorMessage(response);
                   }
               });
           }
        }
        else{
            this.detailsGot=true;
            this.showModalSpinnerLoadingData=false;
        }
       
  
      }
      
    initVisitForm(visit){
      this.visitForm=new FormGroup({
          visits : new FormArray([]),
        })
      //  visit.forEach(v => {
          this.visits.push({visitId:visit.id,present:null})
          let control = new FormGroup({
              id : new FormControl(visit.id),
              comment: new FormControl(visit.comment,[Validators.maxLength(600)]),
              lesionPresent: new FormControl(visit.lesionPresent==true?true:false,[Validators.required]),
              data:new FormControl(visit),
              timepoint:new FormControl(visit.timepoint),
            });
           (<FormArray>this.visitForm.get('visits')).push(control);
      //  });
    }
    getVisitsControls() {
      return (this.visitForm.get('visits') as FormArray).controls;
    }
    initDefaultSequenceLabels(readingConfig: FlexibleConfig<BaseFlexibleConfig>): void {
      const configSequenceLabels: SequenceLabelModel[] = [];
      readingConfig.config.modalities.forEach(m => {
        m.sequenceLabels.forEach(s => configSequenceLabels.push(s));
      });
      this.availableSequenceLabels = configSequenceLabels;
      if (!!readingConfig.config.anatomySelector) {
        this.defaultSequenceLabelIds = (readingConfig.config.anatomySelector as { id: number }[])
          .filter(label => this.availableSequenceLabels.find(studyLabel => studyLabel.id === label.id)).map(l => l.id);
      } else {
        this.defaultSequenceLabelIds = this.availableSequenceLabels.map(l => l.id);
      }
    }
  
      getVisitInfo(seriesId) {
        let series;
          const serie = this.readingSeries?.find(s => s.seriesId === seriesId);
                if (serie) {
                    series = this.readingSeries;
                }
         return series;
    }
  
    configureLayouts() {
        /* layout configuration for multiple viewers */
        this.viewsLayoutConfigurations.push({'col': 0, 'row': 0, 'sizex': 12, 'sizey': 12});
        this.viewsLayoutConfigurations.push({'col': 6, 'row': 0, 'sizex': 6, 'sizey': 12});
        this.viewsLayoutConfigurations.push({'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6});
        this.viewsLayoutConfigurations.push({'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6});
        this.viewsLayoutConfigurations.push({'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6});
        this.viewsLayoutConfigurations.push({'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6});
        this.viewsLayoutConfigurations.push({'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6});
        this.viewsLayoutConfigurations.push({'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6});
    }
  
    /* --------------------------- navigator methods --------------------------- */
  
    onVisitedSeriesCounter(event) {
        this.visitedSeriesCounter = event;
    }
  
    onCloseAllViewers(event) {
        console.log('--- onCloseAllViewers');
  
        this.newLTViewer.instance.removeAllCells();
    }
  
    onOpenViewer(event) {
        if (event != null) {
            const seriesArr = [];
            event.forEach((seriesId) => {
                    localStorage.setItem('visitConfigId',this.getVisitInfo(seriesId).visitConfigId)
                    const series = this.readingSeries.find(s => s.seriesId === seriesId);
                    seriesArr.push(series.seriesId);
            });
            this.openLTViewer(seriesArr);
        }
    }
  
    /* --------------------------- viewer methods --------------------------- */
  
    openNewLTViewer(seriesInstanceUIDs) {
        const compFactory = this.componentFactoryResolver.resolveComponentFactory(
            ViewerCoreLtComponent
        );
  
        this.newLTViewer = this.viewerLT.createComponent(compFactory);
        this.newLTViewer.instance.seriesIds = seriesInstanceUIDs;
        this.newLTViewer.instance.shouldShowDICOM = true;//show scan date
        this.ltViewerSubsctiption = this.newLTViewer.instance.onClose.subscribe(({serID, shouldCloseViewer}) => {
            this.closeLTViewerSeries(serID, shouldCloseViewer);
        });
        this.newLTViewer.instance.onMakeSnapShotListner.subscribe((data) => {
            let series = this.allseries.find(s => s.seriesUID == data.seriesId)
           this.screenShotData ={
               seriesId : series.seriesId,
               image :data.image,
               preview:data.preview,
               seriesUID : data.seriesId,
               sliceNumber:data.sliceNum,
               modalityName:series.projectModality,
               sequenceLabelName:series.label,
           }
           if(this.screenShotData){
               this.toastOptions.title = 'ID 46: The screenshot of the lesion is acquired successfully';
               this.toastOptions.msg = 'The screenshot includes measurement info';
               this.toastyService.success(this.toastOptions);
           }
           else{
            this.toastOptions.title = 'ID 47: The screenshot failure';
            this.toastOptions.msg = 'Screenshot is not made due to some reason. Try again or contact support team';
            this.toastyService.error(this.toastOptions);
           }
          });
          this.newLTViewer.instance.onConfirmLesion.subscribe(
              (objectData) => {
                  if(objectData.objectType=='')
                  return
              //  if(this.currentReading?.historicalScan?.lock && !this.currentReading?.eligibilityScan?.lock)
              //       this.eligibilityComponent.addObjectFromLeadtools(objectData,this.editObjectData?.editMode,this.editObjectData?.editType,this.editObjectData?.currentLesionData);
              //  this.editObjectData= null;
              }
            );
    }
    onEditMeasurement(data){
        this.editObjectData= data;
    }
    onCancelEditMode(){
        this.readingPSMAService.onEditLesionMeasurement(null);
        this.editObjectData= null;
    }
  
    preOpenLTViewer() {
        this.openNewLTViewer(null);
    }
  
    openLTViewer(seriesInstanceUIDs: string[] = [],sliceNumber?:number,locked?:boolean) {
        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        }, 1500);
  
        this.viewercontiner.nativeElement.style.display = '';
  
        if (this.newLTViewer && this.viewerDataIsReady) {
            this.viewerData['sliceNumber'] = sliceNumber;
            this.viewerData['readingID'] = this.currentReading.id;
            this.viewerData['locked'] = locked;
            this.newLTViewer.instance.openNewSer(seriesInstanceUIDs,this.viewerData);
            console.log(this.viewerData)
            this.openedNumberOfImages = this.newLTViewer.instance.getNumberOfImages();
            return;
        } else {
            this.openNewLTViewer(seriesInstanceUIDs);
        }
    }
  
    closeLTViewerSeries(serID, shouldCloseViewer) {
        this.seriesNavigator.unmarkSeriesAsOpenedBySeriesId(serID);
  
        if (shouldCloseViewer) {
            this.viewercontiner.nativeElement.style.display = 'none';
        }
    }
    secondsToHHMMSS() {
        const currentSeconds = this.spentSeconds;
        let hours: number | string = Math.floor(currentSeconds / 3600);
        let minutes: number | string = Math.floor((currentSeconds - (hours * 3600)) / 60);
        let seconds: number | string = currentSeconds - (hours * 3600) - (minutes * 60);
  
        if (hours < 10) {
            hours = '0' + hours;
        }
        if (minutes < 10) {
            minutes = '0' + minutes;
        }
        if (seconds < 10) {
            seconds = '0' + seconds;
        }
        return hours + ':' + minutes + ':' + seconds;
    }
    onLesionExist(data){
        let item =this.visits.find(v=>v.visitId==data.visitId);
      let index = this.visits.indexOf(item);
      this.visits[index].present=data.present;
      let visit = (this.visitForm.value.visits as any[]).find(v=>v.id ===data.visitId);
        let visitData = {
          lesionPresent: true,
          comment: visit.comment
        }
      this.saveVisitData(visit,visitData,false);
    }
    getLesionPresent(visitId:number){
      let item =this.visits.find(v=>v.visitId==visitId);
      let index = this.visits.indexOf(item);
      return this.visits[index].present;
    }
    skipCurrentReadingAndUpdate() {
      const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
          width: '800px',
          disableClose: true,
          data: {
              title: 'Skip Study',
               message: 'Do you want to skip and load next study?',
                html: false,
                showOk: true,
                showCancel: true,
              }
        });
    
        dialogMessaegRef.afterClosed().subscribe(result => {
            if(result==='ok'){
                this.showModalSpinnerLoadingData = true;
                this.currentReading.timeSpent = this.spentSeconds;
                this.readingPSMAService.updateReading(
                    this.study.id, this.currentReading.id, this.spentSeconds
                ).subscribe(response => {
                    if (response['responseCode'] === 200) {
                        this.skipCurrentReading(this.getIndexOfCurrentReading() + 1);
                    } else {
                        this.showErrorMessage(response);
                    }
                });
          }
      });
    }
  
    getIndexOfCurrentReading() {
        return this.readingList.indexOf(this.currentReading);
    }
  
    skipCurrentReading(nextIndex: number) {
      if (this.newLTViewer) {
          this.newLTViewer.instance.removeAllCells();
      }
      this.visitedSeriesCounter = 0;
      if (nextIndex >= this.readingList.length) {  // circle
          nextIndex = 0;
      }
      this.initReading(this.readingList[nextIndex]);
    }
  
    onSaveForm(exit:boolean,visitId:number){
        let visit = (this.visitForm.value.visits as any[]).find(v=>v.id ===visitId);
        let data = {
          lesionPresent: visit.lesionPresent,
          comment: visit.comment
        }
        if(exit){
          const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
              width: '800px',
              disableClose: true,
              data: {
                  title: 'Save & Exit',
                   message: 'Do you want to save and exit the reading process?',
                    html: false,
                    showOk: true,
                    showCancel: true,
                  }
            });
        
            dialogMessaegRef.afterClosed().subscribe(result => {
                if(result==='ok'){
                    this.saveVisitData(visit,data,true);
                   this.router.navigate(['/imagingproject/projects']);
                }
            });
            return;
        }
        this.saveVisitData(visit,data,true);
  
    }
    saveVisitData(visit:any,data:any,toast:boolean,completeReading?:boolean){
      this.showModalSpinnerLoadingData= true;
      this.readingPSMAService.updateVisitData(this.study.id,visit.id,data).subscribe(
          response=>{
              if(response['responseCode']===200){
                  if(toast){
                      this.toastOptions.title=`SUCCESS: visit "${visit.timepoint}" Form Is Saved`;
                      this.toastOptions.msg='The form is successfully saved';
                      this.toastyService.success(this.toastOptions);
                  }
                 let i= this.readingList.indexOf(this.currentReading)
                  this.readingList[i].visit = response['data']
                  if (completeReading) {
                    this.currentReading.timeSpent = this.spentSeconds;
                    this.readingPSMAService.completeReading(
                        this.study.id, this.currentReading
                    ).subscribe(response => {
                        if (response['responseCode'] === 200) {
                            const index = this.getIndexOfCurrentReading();
                            this.readingList.splice(index, 1);
                            if (this.readingList.length) {
                                this.skipCurrentReading(index);    // ind == nextIndex after splice
                            } else {
                                this.currentReading = undefined;
                                this.showModalSpinnerLoadingData = false;
                            }
                        } else {
                            this.showErrorMessage(response);
                        }
                    });
                  }
              }
              else{
                  this.toastOptions.title=`ERROR: ${response['responseCode']}`;
                  this.toastOptions.msg=response['responseMessage'];
                  this.toastyService.error(this.toastOptions);
              }
              this.showModalSpinnerLoadingData= false;
          },
          error=>{
              this.showErrorMessage(error);
          }
      )
    }
    submitResult() {
        this.submitted = true;
        const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
            width: '800px',
            disableClose: true,
            data: {
                title: 'Submit',
                 message: 'Do you want to submit the eligibility form?',
                  html: false,
                  showOk: true,
                  showCancel: true,
                }
          });
      
        dialogMessaegRef.afterClosed().subscribe(result => {
            if(result==='ok'){
                if (this.isReadingValid()) {
                this.showModalSpinnerLoadingData = true;
                let visits = (this.visitForm.value.visits as any[]);
                let i =1;
                visits.forEach(visit => {
                    let data = {
                        lesionPresent: visit.lesionPresent,
                        comment: visit.comment,
                        lock : true
                    }
                    this.saveVisitData(visit,data,true,i == visits.length);
                    i++
                });
                }
            }
            else
              this.submitted = false;
        });  
    }
  
    showErrorMessage(response: any): void {
        this.showModalSpinnerLoadingData = false;
        this.toastOptions.title = 'ERROR ' + response['responseCode'];
        this.toastOptions.msg = response['responseMessage'];
        this.toastyService.error(this.toastOptions);
    }
  
    isReadingValid() {
         this.validationMessages = [];
         return !this.validationMessages.length;
    }
    getValidity(i){
        return (<FormArray>this.visitForm.get('visits')).controls[i].get('comment').invalid;
    }
  
       ModalSpinnerChangeState(state:boolean){
           this.showModalSpinnerLoadingData=state;
       }
       openLTViewerFromChildren(data){
           if(data['seriesId'] && this.newLTViewer){
               if (this.newLTViewer.instance.seriesOpened && this.newLTViewer.instance.seriesOpened[data['seriesId']] != null) {
                   if (data['sliceNumber'] != undefined) {
                    this.newLTViewer.instance.viewer.layout.get_items().toArray().forEach(cell => {
                           if(cell.seriesId === data['seriesId']){
                               cell.currentOffset = parseInt(data['sliceNumber'], 10)-1;
                           }
                       });
                   }
               }
               else{
                    let sliceNumber =data['sliceNumber']?data['sliceNumber']-1:data['sliceNumber']
                    let seriesId =data['seriesId']
                    this.openLTViewer([seriesId],sliceNumber);
               }
           }
       }
  }
  