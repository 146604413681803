import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {CompactJwt} from "./CompactJwt";

const helper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class JwtUtilService {

  globals: any[] = [];
  activities: any = [];
  jsontoken: any = {};

  constructor() {
    this.jsontoken = CompactJwt.decodeActivities(helper.decodeToken(localStorage.getItem('currentUser')));
    this.globals = JSON.parse(localStorage.getItem('globals'));
  }


  isUserHasActivity(activityName: string, projectId: number): boolean {
    let result = false;
    if ( this.jsontoken == null ) {
      this.jsontoken = CompactJwt.decodeActivities(helper.decodeToken(localStorage.getItem('currentUser')));
      this.globals = JSON.parse(localStorage.getItem('globals'));
    }
    this.activities = this.jsontoken.permissions.activities;
    if (this.activities) {
      this.activities.forEach(act => {
        if (act.projectId === projectId) {
          localStorage.setItem('activities', JSON.stringify(act.activities));
          this.activities = JSON.parse(JSON.stringify(act.activities));
          if (this.activities.length > 0) {
            result = this.activities.indexOf(activityName) >= 0;
          }
        }
      });
    }
    if (!result) {
      if (this.globals) {
        result = this.globals.indexOf(activityName) >= 0;
      }
    }
    return result;
  }
}
