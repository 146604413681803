import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {LuganoReadingModel} from '../_models/Lugano/lugano-reading-model';
import {BasicReadingVisit} from '../_models/basic-reading';

const SERVICE_API_URL = 'https://' + environment.MSReadingRecistVersion + 'msreadingrecist-dot-' + environment.apiUrl + '/reading';
const SERVICE_API_URL_VISIT = 'https://' + environment.MSReadingRecistVersion + 'msreadingrecist-dot-' + environment.apiUrl + '/visit';

// const SERVICE_API_URL = 'http://localhost:8080/api/reading';

@Injectable({
  providedIn: 'root'
})
export class ReadingRecistService {

  constructor(private http: HttpClient) {
  }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getReadingByStudyIdAndReaderId(studyId, readerId): Observable<BasicResponse<LuganoReadingModel>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/get-available-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId): Observable<BasicResponse<LuganoReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds): Observable<BasicResponse<LuganoReadingModel>> {
    const submitData = {
      id: readingId,
      timeSpent: spentSeconds
    };
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/${studyId}/${readingId}`, submitData);
  }

  completeReading(studyId, data): Observable<BasicResponse<LuganoReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/complete/${studyId}`, data);
  }

  lockTimepoint(studyId: number, visitId: number, locked: boolean): Observable<BasicResponse<BasicReadingVisit>> {
    return this.http.put<BasicResponse<BasicReadingVisit>>(`${SERVICE_API_URL_VISIT}/${studyId}/${visitId}`,
      {id: visitId, locked: locked});
  }

}
