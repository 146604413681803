import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppRouterMessageQueueObjectService {

  data: any;

  constructor() { }

  public saveObject(obj) {
    this.data = obj;
  }

  public retrieveObject() {
    return this.data;
  }

}
