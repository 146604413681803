import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {
  CohortDialogMode,
  CohortDialogModel,
  ImagingProjectCohortsDialogComponent
} from '../imaging-project-cohorts-dialog/imaging-project-cohorts-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Store} from '@ngxs/store';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {CohortsModel, CohortsStatus} from '../../../../_models/ImagingProject/ImagimgProjectCohorts/cohorts-model';
import {StudyModel} from '../../../../_models/ImagingProject/study-model';
import {CohortsService} from '../../../../_services';
import {ImagingProjectCohortStatusDialogComponent} from "../imaging-project-cohort-status-dialog/imaging-project-cohort-status-dialog.component";
import { NINE } from '@angular/cdk/keycodes';
import { isDefined } from '@angular/compiler/src/util';

@Component({
  selector: 'app-imaging-project-cohorts-list',
  templateUrl: './imaging-project-cohorts-list.component.html',
  styleUrls: ['./imaging-project-cohorts-list.component.css']
})
export class ImagingProjectCohortsListComponent implements OnInit {

  studyId: number;
  cohorts: CohortsModel[];

  displayedColumns: string[] = ['name', 'initialTreatment', 'patients', 'status'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(
    private cohortsService: CohortsService,
    public dialog: MatDialog,
    private store: Store,
    private toastyService: ToastyService
  ) { }

  ngOnInit(): void {
    let project = JSON.parse(localStorage.getItem('project'));
    if (project != null) {
      this.studyId = +project.id;
      /*this.dataSource = new MatTableDataSource<any>([]);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;*/
      this.getCohorts(this.studyId);
    }
  }

  getCohorts(studId: number) {
    this.cohortsService.findAllByStudyId(studId).subscribe(cohorts => {
      if (cohorts.data != null) {
        this.cohorts = cohorts.data;
        this.dataSource = new MatTableDataSource<CohortsModel>(cohorts.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    });
  }

  addCohort() {
    console.log('--- addCohort');
    const data: CohortDialogModel = {
      mode: CohortDialogMode.NEW,
      cohort: <CohortsModel> {
        status: CohortsStatus.ACTIVE,
        name: '',
        study: <StudyModel> {
          id: this.studyId,
        }
      },
      studyCohortsList: this.cohorts
    };

    const dialogRef = this.dialog.open(ImagingProjectCohortsDialogComponent, {
      width: '600px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if ( result.responseCode === 200 ) {
        this.toastOptions.title = 'Cohort has been created successfully';
        this.toastOptions.msg = 'Cohort is now available for all the patients';
        this.toastyService.success(this.toastOptions);
      }
      else {
        this.toastOptions.title = 'Cohort creation failure';
        this.toastOptions.msg = 'Cohort is not created due to some reason. Resolve issues on the form or contact support team';
        this.toastyService.error(this.toastOptions);
      }
      this.getCohorts(this.studyId);
    });
  }

  editCohortName(element) {
    console.log('--- editCohortName');
    console.log('--- addCohort');
    const data: CohortDialogModel = {
      mode: CohortDialogMode.EDIT,
      cohort: <CohortsModel> {
        id: element.id,
        status: element.status,
        name: element.name,
        study: <StudyModel> {
          id: this.studyId,
        }
      },
      studyCohortsList: this.cohorts
    };

    const dialogRef = this.dialog.open(ImagingProjectCohortsDialogComponent, {
      width: '600px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.showUpdateCohortToaster(result);
      this.getCohorts(this.studyId);
    });
  }

  editInitialTreatment(element) {
    console.log('--- editInitialTreatment');
  }

  editList(element) {
    console.log('--- editList');
  }

  activateDisableCohort(action, element) {
    const data: CohortDialogModel = {
      mode: action,
      cohort: <CohortsModel> {
        id: element.id,
        status: element.status,
        name: element.name,
        defaults: element.defaults,
        study: <StudyModel> {
          id: this.studyId,
        }
      },
      studyCohortsList: this.cohorts
    };

    const dialogRef = this.dialog.open(ImagingProjectCohortStatusDialogComponent, {
      width: '600px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if ( result != null ) {
        this.showUpdateCohortToaster(result);
      }
      this.getCohorts(this.studyId);
    });
  }

  showUpdateCohortToaster(result){
    if ( result.responseCode === 200 ) {
      this.toastOptions.title = 'Cohort has been updated successfully';
      this.toastOptions.msg = 'Cohort is updated';
      this.toastyService.success(this.toastOptions);
    } else {
      this.toastOptions.title = 'Cohort updating failure';
      this.toastOptions.msg = 'Cohort is not updated due to some reasons. Resolve issues on the form or contact support team';
      this.toastyService.error(this.toastOptions);
    }
  }
}
