import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { CachableRoutePatterns } from '../interfaces/api.interface';
import { RefreshComponentsUrl } from 'src/app/_models/refresh-components-url';
import { Router } from '@angular/router';
import * as Route from 'route-parser';

abstract class HttpCache {
	abstract get(urlWithParams: string): HttpResponse<any> | null;
	abstract put(urlWithParams: string, res: HttpResponse<any>): void;
	abstract delete(urlWithParams: string): boolean;
}

@Injectable({
	providedIn: 'root'
})
export class HttpCacheService implements HttpCache {
	cache: { [key: string]: HttpResponse<any> } = {};
	cachableRoutes = CachableRoutePatterns;
	routeURLs: string[] = Object.values(RefreshComponentsUrl);

	constructor(private router: Router) { }

	/**
	 * Get an item from the cache
	 * @param urlWithParams
	 */
	get(urlWithParams: string): HttpResponse<any> {
		const cachedItem = this.cache[urlWithParams];
		if (cachedItem) {
			return cachedItem;
		}
	}

	/**
	 * Put an item in the cache
	 * @param urlWithParams
	 * @param res
	 */
	put(urlWithParams: string, res: HttpResponse<any>): void {
		const shouldCache = this.shouldCache(urlWithParams);
		if (shouldCache) {
			this.cacheToLocal(urlWithParams, res);
		}
	}

	/**
	 * Delete an item from the cache
	 * @param urlWithParams
	 */
	delete(urlWithParams: string): boolean {
		const cachedRequest = this.get(urlWithParams);
		let returnVal = false;
		if (cachedRequest) {
			delete this.cache[urlWithParams];
			returnVal = true;
		}
		return returnVal;
	}

	/**
	 * Clear Whole cache
	 */
	clearCache(): void {
		this.cache = {}
	}

	/**
	 * Determine if a url SHOULD be cached or not. It must match a route pattern provided in
	 * @link(CachableRoutePatterns)
	 *
	 * @param urlWithParams
	 */
	shouldCache(urlWithParams: string) {
		let shouldCache = false;
		Object.keys(this.cachableRoutes).forEach((pattern) => {
			const route = new Route(pattern);
			const routeMatch = route.match(urlWithParams);
			if (routeMatch && this.routeURLs.includes(this.router.url)) {
				shouldCache = !!routeMatch;
			}
		});
		return shouldCache;
	}

	/**
	 * Place the response in the local `cache` variable
	 *
	 * @param urlWithParams
	 * @param res
	 */
	cacheToLocal(urlWithParams: string, res: HttpResponse<any>) {
		this.cache[urlWithParams] = res;
	}

	/**
	 * Determine if a urlWithParams belongs to thisPage argument or not.
	 *
	 * @param urlWithParams
	 * @param whichPage
	 */
	isCachedUrlForThisPage(thisPage: string, urlWithParams:string): boolean {
		let returnVal = false;
		Object.keys(this.cachableRoutes).forEach((pattern) => {
			const route = new Route(pattern);
			const routeMatch = route.match(urlWithParams);
			if (routeMatch) {
				if(this.cachableRoutes[pattern].find(page => page === thisPage))
					returnVal = true;
			}
		});
		return returnVal;
	}
}
