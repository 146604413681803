export enum ReadingStatus {
  NEW_READING_STATUS_CODE = 100,
  READER_PENDING_STATUS_CODE = 200,
  READER_ASSIGNED_STATUS_CODE = 300,
  BATCH_PENDING_STATUS_CODE = 400,
  BATCH_ASSIGNED_STATUS_CODE = 500,
  READING_PENDING_STATUS_CODE = 600,
  IN_PROGRESS_STATUS_CODE = 700,
  ADJUDICATION_CHECK_PENDING_STATUS_CODE = 800,
  COMPLETE_STATUS_CODE = 900,
  TERMINATED_STATUS_CODE = 1000,
}
