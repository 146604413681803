/**
 * @description
 * Series manager service interface for Dynamika image visualization component.
 *
 */

export abstract class SeriesManagerService {
  public silentPropertyChange: boolean;
  public activeCellChanging: any;
  public allowSavingActiveContainer: boolean;

  /**
   * Get cell of a series from the id.
   * @param id
   */
  abstract getSeriesCellById(id: string): any;

  /**
   * Cache ROI data by cell ID
   * @param cell
   * @param data
   */
  abstract setROIData(cell: any, data: Array<any>): void;

  /**
   * Get ROI data by cell ID
   * @param cell
   */
  abstract getROIData(cell: any): Array<any>;

  /**
   * Reset cached ROI data
   */
  abstract resetROIData(): void;

  /**
   * Cache VOI data by cell ID
   * @param cell
   * @param data
   */
  abstract setVOIData(cell: any, data: Array<any>): void;

  /**
   * Get VOI data by cell ID
   * @param cell
   */
  abstract getVOIData(cell: any): Array<any>;

  /**
   * Reset cached VOI data
   */
  abstract resetVOIData(): void;

  /**
   * Get instances of a series as an array of SopInstanceUID.
   * @param seriesInstanceUID
   * @param id
   */
  abstract getInstances(seriesInstanceUID: string, id: string): any[];

  /**
   * Set instances of a series as an array of SopInstanceUID.
   * @param seriesInstanceUID
   * @param id
   * @param instances
   * @param stackInstanceUID
   */
  abstract setInstances(seriesInstanceUID: string, id: string, instances: string[], stackInstanceUID);

  /**
   * Get cell frame of a series as a Frame Object
   * @param seriesInstanceUID
   * @param id
   * @param sopInstanceUID
   * @param getNew
   */
  abstract getCellFrame(seriesInstanceUID: string, id: string, sopInstanceUID: string, getNew?: boolean);

  /**
   * Add cell to the series stack.
   * @param cell
   */
  abstract addSeriesCell(cell: any): void;

  /**
   * Set label of a series
   * @param cell
   * @param value
   */
  abstract removeSeriesCell(seriesInstanceUID: string);

  /**
   * Remove cell from viewer
   */
  abstract setSeriesInfo(cell: any, value: any): void;

  /**
   * Get label of a series
   * @param cell
   */
  abstract getSeriesInfo(cell: any): any;

  /**
   * Get Active Sub Cell Index
   * @param cell
   */
  abstract getActiveSubCellIndex(cell: any): number;

  /**
   *
   * @param cell
   * @param frameFunction
   * @param cell3DFunction
   */
  abstract enumerateFrames(cell: any, frameFunction: Function, cell3DFunction?: Function);

  /**
   * Get Active Cell Frame
   */
  abstract getActiveCellFrame(cell?): any;

  /**
   * Get Active Cell
   */
  abstract getActiveCell(): any;

  /**
   * Set Active Cell
   * @param id
   */
  abstract setActiveCell(id: string, silent?: boolean): void;

  /**
   * Get active item for cell
   * @param cell
   */
  abstract getActiveItemForCell(cell: any): any;

  /**
   * Get active viewer
   */
  abstract getActiveViewer(): any;

  /**
   * Remove cell from the series stack
   */
  abstract removeCell(cell);

  // remove/disable annotations
  abstract deleteSelectedAnnotations(subject?);

  abstract undoAction();

  abstract clearAnnotations();

  abstract clearAllAnnotations();

  abstract setThumbPreferences(renderers, skipnumber);

  abstract captureCurrentFrame(burn: any);

  /**
   * Get cell annotations
   * @param cell
   */
  abstract getCellAnnotations(cell);

  /**
   * Get Annotations IDs
   * @param seriesInstanceUID
   * @param id
   */
  abstract getAnnotationIDs(seriesInstanceUID: string, id: string);

  /**
   * Set Annotations IDs
   * @param seriesInstanceUID
   * @param id
   * @param annotationIds
   */
  abstract setAnnotationIDs(seriesInstanceUID: string, id: string, annotationIds: Array<any>);

  /**
   * Add Annotations to the cell
   * @param cell
   * @param annotations
   */
  abstract addAnnotations(cell, annotations, info?);

  /**
   * Save all ROIs of an active cell
   * @param info
   */
  abstract saveAllROIs(info): any;

  /**
   * Delete ROIs of an active cell
   * @param info
   */
  abstract deleteROIs(info);

  /**
   * Delete Cell ROIs of an active cell
   * @param cell
   * @param info
   */
  abstract deleteCellROIs(cell, rows, info);

  /**
   * Group slice ROIs as a volume ROI
   * @param info
   */
  abstract groupAsVolume(info);

   /**
    * Load pixel data for corresponding cell frames within a cell
    * @param cell
    */
  abstract loadPixelData(cell: any)

  /**
   * +     * Create Volumes from ROIs Automaticlly
   * @param info
   */
  abstract autoVolumes(info, indexedCell?, voiName?);

  /**
   * Get SopInstanceUIDs
   * @param seriesInstanceUID
   * @param id
   */
  abstract getSopInstanceUIDs(seriesInstanceUID: string, id: string);

  /**
   * Set sopInstanceUIDs
   * @param seriesInstanceUID
   * @param id
   * @param sopInstanceUIDs
   */
  abstract setSopInstanceUIDs(seriesInstanceUID: string, id: string, sopInstanceUIDs: Array<any>);

  /**
   * ungroup a volume of interest (VOI) to regions of interest (ROIS)
   * @param info
   */
  abstract ungroupToSlice(info);

  /**
   * hide or show ROIs
   * @param info
   */
  abstract hideOrShowROIs(info)

  /**
   * hide or show VOIs
   * @param info
   */
  abstract hideOrShowVOIs(info);

  /**
   * show ROIs settings
   * @param info
   */
  abstract showROIsSettings(info);

  /**
   * show ROIs CopyDelete
   * @param info
   */
  abstract showROICopyDelete(info);

  /**
   * show VOIs settings
   * @param info
   */
  abstract showVOIsSettings(info);

  /**
   * event handler for empty cells
   * @param e
   * @param args
   * @param info
   */
  abstract onEmptyDivsCollectionChanged(e, args, info);

  /**
   * enable drop target
   * @param elem
   * @param info
   */
  abstract enableDropTarget(elem, info);

  /**
   * Save all ROIs of an active cell
   * @param cell
   * @param info
   */
  abstract saveCellROIs(cell, info);

  /**
   * Add Annotations to the cell
   * @param cellFrame
   */
  abstract getImageSize(cellFrame);

  /**
   * Handle click on rows of ROI panel
   * @param row
   * @param info
   */
  abstract onROIRowClick(row, info);

  /**
   * Toggle cell ROIs selection
   * @param row
   * @param info
   */
  abstract toggleROISelections(info, currentSlice: boolean): void;

  /**
   * get series grouped by frameOfReferenceUID
   */
  abstract getSeriesByFOR(): {};

  /**
   * get series from frameOfReferenceUID
   * @param frameOfReferenceUID
   */
  abstract getSeriesFromFOR(frameOfReferenceUID);

  /**
   * add cell to SeriesByFOR
   * @param cell
   */
  abstract addCellToSeriesByFOR(cell);

  /**
   * set SeriesByFOR
   * @param value
   */
  abstract setSeriesByFOR(value);
  /**
   * saveActiveContainerROIs
   * @param info
   * @param roiGUID
   */
  abstract saveActiveContainerROIs(info, roiGUID?): Promise<any>;

  /**
   * Render and Load VOIs
   * @param info
   */
  abstract renderingVolumes(info);

  /**
   * auto Volume Calculations
   * @param info
   * @param indexedCell
   * @param voiName
   */
   abstract autoVolumeCalculations(info, indexedCell?, voiName?);
}

