import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-ftb-edit-dialog',
  templateUrl: './ftb-edit-dialog.component.html',
  styleUrls: ['./ftb-edit-dialog.component.css']
})
export class FtbEditDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<FtbEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number
  ) {}

  ngOnInit(): void {
  }

  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46, 190];
    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  onEditFTBValue(){
    this.dialogRef.close(this.data);
  }

  onCloseFTBDialog(){
    this.dialogRef.close();
  }

}
