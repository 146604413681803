import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ImagingProjectService, SiteConfigService} from '../../../_services';
import {Select, Store} from '@ngxs/store';
import {forkJoin, Observable, Subject, Subscription} from 'rxjs';
import {StudyDashboardModel} from '../../../_models/ImagingProject/study-model';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ProjectsState} from '../../../core/data-management/states/projects.state';
import {ToastService} from '../../../_services/internal/toast.service';
import {SignStudyReportModel, StudyType} from "../../../_models/ImagingProject/sign-model";
import {ReportGeneratorService} from "../../../_services/report-generator.service";
import {map, mergeMap, takeUntil} from "rxjs/operators";
import {PatientService} from "../../../_services/patient.service";
import {RefreshAPIService} from "../../../_services/refresh-api.service";
import {ReadingGuerbetService} from "../../../_services/reading-guerbet.service";

const readerTypeOrder = {
  'level1_reader': 1,
  'consensus_reader': 2,
  'adjudication_reader': 3,
};

@Component({
    selector: 'app-sign-study-reports-list',
    templateUrl: './sign-study-reports-list.component.html',
    styleUrls: ['./sign-study-reports-list.component.css']
})
export class SignStudyReportsListComponent implements OnInit, OnDestroy  {
  readonly COLUMNS = ['row-num', 'patientID', 'visitName', 'endpoint', 'readingID', 'report'];
  readonly GUERBET_COLUMNS = ['row-num', 'patientID', 'visitName', 'endpoint', 'readingID', 'readerType', 'report'];


  @Select(ProjectsState.getDashboardProjectIds) projectIdsObservable: Observable<number[]>;
  @Select(ProjectsState.getDashboardProjects) projectsObservable: Observable<StudyDashboardModel[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  reportsList: SignStudyReportModel[] = [];
  reportsDataSource: MatTableDataSource<SignStudyReportModel>;
  loading = true;
  studyId: number;
  studyType: StudyType;
  protected unsubscribe = new Subject<void>();
  refreshAPISubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private reportGeneratorService: ReportGeneratorService,
    private guerbetService: ReadingGuerbetService,
    private router: Router,
    private imagingProjectService: ImagingProjectService,
    private toastService: ToastService,
    private patientService: PatientService,
    private store: Store,
    private refreshAPIService: RefreshAPIService
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Signing Reports'));
    this.reportsDataSource = new MatTableDataSource<SignStudyReportModel>();

    this.studyId = this.activatedRoute.snapshot.params.studyId;
    this.studyType = this.activatedRoute.snapshot.data.studyType;
    this.loadReports();
    this.initRefresh();
  }

  ngOnDestroy(): void {
    if (this.refreshAPISubscription) {
      this.refreshAPIService.clearRefreshAPIData();
      this.refreshAPISubscription.unsubscribe();
    }
  }

  compare(a: SignStudyReportModel, b: SignStudyReportModel) {
      // Signed reports goes end
      if (a.signDate && !b.signDate) {
        return 1;
      }
      if (!a.signDate && b.signDate) {
        return -1;
      }

      // First, compare by readerType order - has sence for guerbet only
      if (readerTypeOrder[a.readerType] < readerTypeOrder[b.readerType]) {
        return -1;
      }
      if (readerTypeOrder[a.readerType] > readerTypeOrder[b.readerType]) {
        return 1;
      }

      // If readerType is the same, compare by id
      return a.id - b.id;
  }

  loadReports() {
    const getReports = this.studyType === 'GUERBET' ? this.guerbetService.getSignReportsByStudyId(this.studyId) : this.reportGeneratorService.getReportsByStudyId(this.studyId);
    getReports
      .pipe(
        map(response => {
          const reportsList = response as SignStudyReportModel[];
          this.reportsList = reportsList.map(r => ({
            ...r,
            isCsv: r.mimeType === 'text/csv'
          }));

          return this.reportsList;
        }),
        mergeMap(reportsList => {
          const visitConfigIds = this.getVisitConfigIds(reportsList);
          if (visitConfigIds.size > 0) {
            return forkJoin([
              this.patientService.getPatientsByStudyIdBrief(this.studyId),
              this.imagingProjectService.getVisitConfigsByIdsBrief(visitConfigIds),
            ]);
          }
          return forkJoin([
            this.patientService.getPatientsByStudyIdBrief(this.studyId),
          ]);
        })
      )
      .subscribe(
        ([patients, visitConfigs]) => {
          this.reportsList.forEach(report => {
            const visit = (visitConfigs?.data ?? []).find(visitConfig => visitConfig.id === report.visitConfigId);
            report.visitName = visit?.visitName ?? 'N/A';
            const patient = (patients?.data ?? []).find(patient => patient.id === report.patientId);

            report.patientCode = patient?.patientCode ?? 'N/A';
          });

          this.reportsList = this.reportsList.sort((a, b) => this.compare(a,b));
          console.log('reportsList: ', this.reportsList);
          this.reportsDataSource.data = this.reportsList;
          this.reportsDataSource.paginator = this.paginator;
          this.reportsDataSource.sort = this.sort;
          this.loading = false;
        },
        error => {
          console.error('error: ', error);
          this.loading = false;
          this.toastService.respError(error);
        }
      );
  }

  private getVisitConfigIds(reports: SignStudyReportModel[]): Set<any> {
    // Some imports does not have relaation to visit - so no visit config is defined
    const visitConfigIds = reports.map(report => report.visitConfigId).filter(f => f != null);
    return new Set<number>(visitConfigIds);
  }

  getUrlForReaderType(readerType: string) {
    switch (readerType) {
      case 'consensus_reader': return 'consensus';
      case 'adjudication_reader': return 'adjudication';
      case 'level1_reader': return 'level-one';
    }
  }
  navigateToReport(report: SignStudyReportModel): void {
    if (report.isCsv) {
      return;
    }

    if (this.studyType === 'GUERBET') {
      const urlPath = this.getUrlForReaderType(report.readerType);
      this.router.navigate([`/reading/guerbet/${urlPath}/${report.id}`]).then(r => {});
      return;
    }

    this.router.navigate([`/sign/${this.studyId}/${report.id}`]).then(r => {});
  }
  navigateToSigned(report: SignStudyReportModel): void {

    if (this.studyType === 'GUERBET') {
      const urlPath = this.getUrlForReaderType(report.readerType);
      this.router.navigate([`/reading/guerbet/${urlPath}/${report.id}`]).then(r => {});
      return;
    }

    this.router.navigate([`/sign/${this.studyId}/${report.id}`], {queryParams: { signed: true }});
  }

  applyFilter(filterValue: string): void {
    if (this.reportsDataSource !== undefined) {
      this.reportsDataSource.filter = filterValue.trim().toLowerCase();
      if (this.reportsDataSource.paginator) {
        this.reportsDataSource.paginator.firstPage();
      }
    }
  }

  handleBack() {
    this.router.navigate(['/sign']);
  }

  initRefresh() {
    // refresh APIs
    this.refreshAPISubscription = this.refreshAPIService.refreshAPIData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          if (response.componentUrl === this.router.url) {
            window.location.reload();
          }
        }
      });
  }

  get columns() {
    if (this.studyType === 'GUERBET') {
      return this.GUERBET_COLUMNS;
    }

    return this.COLUMNS;
  }

  getReaderType(row: SignStudyReportModel): string {
    switch (row.readerType) {
      case 'consensus_reader': return 'Consensus';
      case 'adjudication_reader': return 'Adjudication';
      case 'level1_reader': return 'Level 1';
    }

    return '';
  }

  getReadingId(row): number {
    if (this.studyType === 'GUERBET') {
      return row.id;
    }

    return row.readingId;
  }
}
