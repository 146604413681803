import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {
  BodySublocationDialogMode,
  PrimaryBodySublocationDialogComponent
} from '../primary-body-sublocation-dialog/primary-body-sublocation-dialog.component';
import {
  PrimaryBodyLocationModel,
} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-model';
import {
  BodySubLocationModel,
  BodySubLocationStatus
} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/body-sub-location-model';
import {BodySubLocationService} from '../../../../_services';
import {PrimaryBodySublocationStatusDialogComponent} from '../primary-body-sublocation-status-dialog/primary-body-sublocation-status-dialog.component';
import {BasicResponse} from '../../../../core/interfaces/basic-response';

@Component({
  selector: 'app-primary-body-location-with-sublocation-list-dialog',
  templateUrl: './primary-body-location-with-sublocation-list-dialog.component.html',
  styleUrls: ['./primary-body-location-with-sublocation-list-dialog.component.css']
})
export class PrimaryBodyLocationWithSublocationListDialogComponent implements OnInit {

  displayedColumns: string[] = ['sublocationName', 'status'];
  dataSource: MatTableDataSource<BodySubLocationModel>;
  subLocations: BodySubLocationModel[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  activeLocations: number;
  disableLocations: number;

  constructor(
    private bodySubLocationService: BodySubLocationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PrimaryBodyLocationModel>,
    @Inject(MAT_DIALOG_DATA) public data: PrimaryBodyLocationModel
  ) { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource<BodySubLocationModel>(this.data.bodySubLocations);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    const active = this.data.bodySubLocations.filter(item => item.status.toUpperCase()  === 'ACTIVE');
    const diabled = this.data.bodySubLocations.filter(item => item.status.toUpperCase() === 'DISABLED');
    this.activeLocations = active.length;
    this.disableLocations = diabled.length;
  }

  sortData(sort: Sort) {
    const data = this.data.bodySubLocations.slice();
    if (!sort.active || sort.direction === '') {
      sort.direction = 'asc';
      sort.active = 'name';
    }

    this.data.bodySubLocations = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'sublocationName': return compare(a.name, b.name, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource<BodySubLocationModel>(this.data.bodySubLocations);
  }

  close() {
    this.dialogRef.close();
  }

    private processSubLocations(locations: BasicResponse<any>) {
        if (locations.data != null) {
            this.subLocations = locations.data;
            this.dataSource = new MatTableDataSource<BodySubLocationModel>(locations.data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;

            const active = locations.data.filter(item => item.status.toUpperCase() === 'ACTIVE');
            const diabled = locations.data.filter(item => item.status.toUpperCase() === 'DISABLED');
            this.activeLocations = active.length;
            this.disableLocations = diabled.length;
        }
    }

    openAddSublocationDialog() {
        this.bodySubLocationService.getAllByPrimaryLocationId(this.data.id).subscribe(locations => {
            this.processSubLocations(locations);
            const dialogRef = this.dialog.open(PrimaryBodySublocationDialogComponent, {
                width: '600px',
                data: {
                    mode: BodySublocationDialogMode.NEW,
                    primaryLocation: this.data,
                    subLocation: {
                        id: null,
                        name: '',
                        status: BodySubLocationStatus.ACTIVE
                    },
                    subLocationList: this.subLocations
                }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                this.bodySubLocationService.getAllByPrimaryLocationId(this.data.id).subscribe(loc => {
                    this.processSubLocations(loc);
                });
            });
        });
    }

    editName(element: BodySubLocationModel) {
        this.bodySubLocationService.getAllByPrimaryLocationId(this.data.id).subscribe(locations => {
            this.processSubLocations(locations);
            const dialogRef = this.dialog.open(PrimaryBodySublocationDialogComponent, {
                width: '600px',
                data: {
                    mode: BodySublocationDialogMode.EDIT,
                    primaryLocation: <PrimaryBodyLocationModel>this.data,
                    subLocation: element,
                    subLocationList: this.subLocations
                }
            });

            dialogRef.afterClosed().subscribe((result: any) => {
                this.bodySubLocationService.getAllByPrimaryLocationId(this.data.id).subscribe(loc => {
                    this.processSubLocations(loc);
                });
            });
        });
    }

    activateDisableSublocation(element: BodySubLocationModel) {
        this.bodySubLocationService.getAllByPrimaryLocationId(this.data.id).subscribe(locations => {
            this.processSubLocations(locations);
            const dialogRef = this.dialog.open(PrimaryBodySublocationStatusDialogComponent, {
                width: '600px',
                data: {
                    mode: BodySublocationDialogMode.EDIT,
                    primaryLocation: <PrimaryBodyLocationModel>this.data,
                    subLocation: element,
                    subLocationList: this.subLocations
                }
            });
            dialogRef.afterClosed().subscribe((result: any) => {
                this.bodySubLocationService.getAllByPrimaryLocationId(this.data.id).subscribe(loc => {
                    this.processSubLocations(loc);
                });
            });
        });
    }
}

function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
  return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
}
