import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ReadingDashboardService, StudyUserService} from '../../../_services';
import {ReadingStatusModel} from '../../../_models';
import {ReadingStatus} from '../../../core/constants/reading-status';
import {BatchConfigModel} from '../../../_models/BatchLogic/batch-config-model';
import {ConfirmTerminationDialogComponent} from './confirm-termination-dialog/confirm-termination-dialog.component';

export interface ReadingDetailsDialogData {
  endpoint: string;
  studyId: number;
  readingId: number;
  readingStatus: ReadingStatusModel;
  readerId: number;
}

@Component({
  selector: 'app-reading-details',
  templateUrl: './reading-details-dialog.component.html',
  styleUrls: ['./reading-details-dialog.component.css']
})
export class ReadingDetailsDialogComponent implements OnInit {

  readonly readingStatuses = ReadingStatus;

  constructor(public dialogRef: MatDialogRef<ReadingDetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ReadingDetailsDialogData,
              private readingService: ReadingDashboardService,
              private studyUserService: StudyUserService,
              private dialog: MatDialog) {
  }

  selectedReaderId: number;

  readers: any[] = [];

  ngOnInit(): void {
    this.selectedReaderId = this.data.readerId;
    this.studyUserService.getReadersByStudyId(this.data.studyId).subscribe(response => {
      this.readers = response.data;
    });
  }

  openTerminateReadingConfirmDialog(): void {

    const data: ReadingDetailsDialogData = {...this.data};

    const dialogRef = this.dialog.open(ConfirmTerminationDialogComponent, {
      width: '400px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.close(result);
    });
  }

  save(): void {
    if (this.selectedReaderId !== this.data.readerId) {
      this.readingService.assignReader(this.data.readingId, this.selectedReaderId, this.data.studyId, this.data.endpoint)
        .subscribe(resp => {
          this.close(resp.responseCode);
        });
    } else {
      this.close(null);
    }
  }

  close(resp: any) {
    this.dialogRef.close(resp);
  }

}
