import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {SiteService} from '../../../_services';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ToastService} from '../../../_services/internal/toast.service';


@Component({
  selector: 'app-imaging-project-site',
  templateUrl: './imaging-project-site.component.html',
  styleUrls: ['./imaging-project-site.component.css']
})
export class ImagingProjectSiteComponent implements OnInit {
  piCtrl = new FormControl('', [
    Validators.required
  ]);
  requiredFormControl = new FormControl('', [
    Validators.required
  ]);

  site: any = {};
  sites: any[] = [];
  showTable: boolean = false;
  showSite: boolean = false;

  displayedColumns: string[] = ['row-num', 'name', 'piUserName', 'imagingFacilityAddress', 'operations'];
  dataSource: MatTableDataSource<any>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private siteService: SiteService,
              private toastService: ToastService,
              private store: Store) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Sites'));
    this.siteService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.sites = response.data;
          this.dataSource = new MatTableDataSource(this.sites);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.showTable = true;
        } else {
          this.toastService.error('ERROR ' + response.responseCode, response.responseMessage);
        }
      }
    );
  }

  saveSite() {
    if (this.site.id) {
      this.siteService.update(this.site).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.siteService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.sites = response.data;
                  this.dataSource.data = this.sites;
                  this.toastService.success('Success', 'Site successfully updated');
                  this.hideSites();
                } else {
                  this.toastService.error('ERROR ' + response.responseCode, response.responseMessage);
                }
              }
            );
          } else {
            this.toastService.error('ERROR ' + response.responseCode, response.responseMessage);
          }
        }
      );
    } else {
      this.siteService.create(this.site).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.siteService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.sites = response.data;
                  this.dataSource.data = this.sites;
                  this.toastService.success('Success', 'Site successfully created');
                  this.hideSites();
                } else {
                  this.toastService.respError(response);
                }
              }
            );
          } else {
            this.toastService.respError(response);
          }
        }
      );
    }
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  showSites(element) {
    this.piCtrl.clearValidators();
    this.requiredFormControl.clearValidators();
    this.site = {};
    this.showTable = false;
    this.showSite = true;
    this.piCtrl.setValue(null);
    if (element) {
      this.siteService.getById(element.id).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.site = response.data;
            this.store.dispatch(new SetPageHeaderTitle('Edit Site'));
          } else {
            this.toastService.respError(response);
          }
        }
      );
    } else {
      this.store.dispatch(new SetPageHeaderTitle('Create Site'));
    }
  }

  deleteSite(element) {
    this.siteService.delete(element.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.siteService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                this.sites = response.data;
                this.dataSource.data = this.sites;
              } else {
                this.toastService.respError(response);
              }
            }
          );
          this.toastService.success('Success', 'Site successfully deleted');
        } else {
          this.toastService.respError(response);
        }
      }
    );
  }

  hideSites() {
    this.showTable = true;
    this.showSite = false;
  }

  sortData(sort: Sort) {
    const data = this.sites.slice();
    if (!sort.active || sort.direction === '') {
      sort.direction = 'asc';
      sort.active = 'name';
    }

    this.sites = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'piUserName': return compare(a.piUserName, b.piUserName, isAsc);
        case 'imagingFacilityAddress': return compare(a.postalAddress, b.postalAddress, isAsc);
        default: return 0;
      }
    });
    this.dataSource.data = this.sites;
  }
}

function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
  return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
}
