import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DicomTagRow, DicomData } from '../../../../_models/viewer/DataModels';
import { DicomHelperService } from '../../../../_services/interfaces/viewer/dicom-helper-service';
import { SeriesManagerService } from '../../../../_services/interfaces/viewer/series-manager.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';

@Component({
  selector: 'app-dicom-information-dialog',
  templateUrl: './dicom-information-dialog.component.html',
  styleUrls: ['./dicom-information-dialog.component.css']
})
export class DicomInformationDialogComponent implements OnInit {
  rowData: DicomTagRow[] = [];
  dataSource: MatTableDataSource<DicomTagRow>;
  displayedColumns: string[] = ['tag', 'name', 'value'];
  dataSourceXAr: MatTableDataSource<DicomTagRow>[];
  dicomLabels: [];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(public dialogRef: MatDialogRef<DicomInformationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dataSourceXAr = this.data.dataSources;
    this.dicomLabels = this.data.dicomLabels;
  }

  ngOnInit(): void {
    if(this.dataSourceXAr != null) {
      this.dataSourceXAr.forEach(dicomTagDataSource => {
        dicomTagDataSource.filterPredicate = (data: DicomTagRow, filter: string) => {
            return (data.data.tag && data.data.tag.toLowerCase().includes(filter.toLowerCase()) === true) || (data.data.name && data.data.name.toLowerCase().includes(filter.toLowerCase()) === true) || (data.data.value && data.data.value.toLowerCase().includes(filter.toLowerCase()) === true);
        };
        dicomTagDataSource.sort = this.sort;
        dicomTagDataSource.paginator = this.paginator;
      });
    }
  }

  onClickOk(): void {
    this.dialogRef.close();
  }

  applyFilter(filterValue: string) {
    this.dataSourceXAr.forEach(dicomTagDataSource => {
      if (dicomTagDataSource != null) {
        dicomTagDataSource.filter = filterValue.trim().toLowerCase();
        if (dicomTagDataSource.paginator) {
          dicomTagDataSource.paginator.firstPage();
        }
      }
    });
  }
}
