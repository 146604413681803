import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import {
  ClearProjectsData,
  PushAssignedProjectId,
  PushAuditTrailsProjectId,
  PushAuditTrailsProjects,
  PushDashboardProjectId,
  PushDashboardProjects,
  SetAssignedProjectIds,
  SetPageHeaderTitle,
  SetSelectedProject
} from '../actions/projects.action';
import { StudyModel, StudyDashboardModel } from '../../../_models/ImagingProject/study-model';


export class ProjectsModel {
  assignedProjectIds: number[];
  dashboardProjectIds: number[];
  dashboardProjects: StudyDashboardModel[];
  auditTrailsProjectIds: number[];
  auditTrailsProjects: StudyModel[];
  pageHeaderTitle: string;
  selectedProject: StudyModel;
}

const defaultProjectsState: ProjectsModel = {
  assignedProjectIds: [],
  dashboardProjectIds: [],
  dashboardProjects: [],
  auditTrailsProjectIds: [],
  auditTrailsProjects: [],
  pageHeaderTitle: null,
  selectedProject: null
};

@State<ProjectsModel>({
  name: 'studiesState',
  defaults: defaultProjectsState
})
export class ProjectsState {

  @Selector()
  static getAssignedProjectIds(state: ProjectsModel): number[] {
    return state.assignedProjectIds;
  }

  @Selector()
  static getDashboardProjectIds(state: ProjectsModel): number[] {
    return state.dashboardProjectIds;
  }

  @Selector()
  static getDashboardProjects(state: ProjectsModel): StudyDashboardModel[] {
    return state.dashboardProjects;
  }

  static getDashboardProject(studyId: number) {
    return createSelector([ProjectsState], (state: ProjectsModel) => {
      return state.dashboardProjects.find(p => p.id === studyId);
    });
  }

  @Selector()
  static getAuditTrailsProjectIds(state: ProjectsModel): number[] {
    return state.auditTrailsProjectIds;
  }

  @Selector()
  static getAuditTrailsProjects(state: ProjectsModel): StudyModel[] {
    return state.auditTrailsProjects;
  }

  @Selector()
  static getSelectedProject(state: ProjectsModel): StudyModel {
    return state.selectedProject;
  }

  @Selector()
  static getPageHeaderTitle(state: ProjectsModel): string {
    return state.pageHeaderTitle;
  }

  @Action(SetAssignedProjectIds)
  setAssignedProjectIds({ getState, setState }: StateContext<ProjectsModel>, { payload }: SetAssignedProjectIds) {
    const state = getState();
    setState({
      ...state,
      assignedProjectIds: payload,
    });
  }

  @Action(PushAssignedProjectId)
  pushAssignedProjectIds({ getState, setState }: StateContext<ProjectsModel>, { payload }: PushAssignedProjectId) {
    const state = getState();
    setState({
      ...state,
      assignedProjectIds: [...new Set([...state.assignedProjectIds, payload])],
    });
  }

  @Action(PushDashboardProjectId)
  pushDashboardProjectId({ getState, setState }: StateContext<ProjectsModel>, { payload }: PushDashboardProjectId) {
    const state = getState();
    setState({
      ...state,
      dashboardProjectIds: [...new Set([...state.dashboardProjectIds, payload])],
    });
  }

  @Action(PushDashboardProjects)
  pushDashboardProjects({ getState, setState }: StateContext<ProjectsModel>, { payload }: PushDashboardProjects) {
    const state = getState();
    setState({
      ...state,
      dashboardProjects: [
        ...state.dashboardProjects,
        ...payload.filter(p => !state.dashboardProjects.find(target => target.id === p.id))
      ],
    });
  }

  @Action(PushAuditTrailsProjectId)
  pushAuditTrailsProjectId({ getState, setState }: StateContext<ProjectsModel>, { payload }: PushAuditTrailsProjectId) {
    const state = getState();
    setState({
      ...state,
      auditTrailsProjectIds: [...new Set([...state.auditTrailsProjectIds, payload])],
    });
  }

  @Action(PushAuditTrailsProjects)
  pushAuditTrailsProjects({ getState, setState }: StateContext<ProjectsModel>, { payload }: PushAuditTrailsProjects) {
    const state = getState();
    setState({
      ...state,
      auditTrailsProjects: [
        ...state.auditTrailsProjects,
        ...payload.filter(p => !state.auditTrailsProjects.find(target => target.id === p.id))
      ],
    });
  }

  @Action(SetSelectedProject)
  setSelectedProject({ getState, setState }: StateContext<ProjectsModel>, { payload }: SetSelectedProject) {
    const state = getState();
    setState({
      ...state,
      selectedProject: payload,
    });
  }

  @Action(SetPageHeaderTitle)
  setPageHeaderTitle({ getState, setState }: StateContext<ProjectsModel>, { payload }: SetPageHeaderTitle) {
    const state = getState();
    setState({
      ...state,
      pageHeaderTitle: payload,
    });
  }

  @Action(ClearProjectsData)
  clearProjectsData({ patchState }: StateContext<ProjectsModel>) {
    patchState(defaultProjectsState);
  }

}
