import { ChangeDetectorRef, Component, ComponentFactoryResolver, ComponentRef, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import { Subject } from 'rxjs';
import { ViewerCoreLtComponent } from '../Viewer';
import { takeUntil } from 'rxjs/operators';
import { EventNames, EventService } from '../../_services';
import { Utils } from '../../_services/leadtools/lead-tools-utils';

@Component({ template: '' })
export class ReadingFormBaseComponent {
  protocolType: string = null;
  protected unsubscribe = new Subject<void>();
  protected newLTViewer: ComponentRef<ViewerCoreLtComponent> = null;

  constructor(public route: ActivatedRoute,
              public cdr: ChangeDetectorRef,
              public componentFactoryResolver: ComponentFactoryResolver,
              public eventService: EventService,
              public utils: Utils) {

    this.route.data.subscribe((data: Data): void => {
      this.protocolType = data.protocolType;
    });

    // enable drag-n-drop functionality on empty cell
    this.eventService.subscribe(EventNames.VIEWER_READY, viewer => {
      const emptyDivs = viewer.get_emptyDivs().get_items().toArray();
      if (emptyDivs.length) {
        const emptyCellDiv = emptyDivs[0].get_div();
        this.utils.enableDropTarget(emptyCellDiv, { viewer, viewerComponent: this.newLTViewer.instance });
      }
    });
  }

  protected onResize(): void {
    window.dispatchEvent(new Event('resize'));
  }

  protected viewerHasItems(viewer: lt.Controls.Medical.MedicalViewer): boolean {
    if (!(viewer instanceof lt.Controls.Medical.MedicalViewer)) {
      return false;
    }

    return viewer.layout.get_items().get_count() > 0;
  }

  protected createViewerCmpInstance(element: ViewContainerRef): void {
    if (!(this.newLTViewer instanceof ComponentRef)) {
      const compFactory = this.componentFactoryResolver.resolveComponentFactory(
        ViewerCoreLtComponent
      );

      this.newLTViewer = element.createComponent(compFactory);
      this.newLTViewer.instance.preventClose = true;
      this.newLTViewer.instance.protocolType = this.protocolType;

      this.newLTViewer.instance.onCellReady
      .pipe(takeUntil(this.unsubscribe)).subscribe(() => {
          this._updateViewerToolbar();
      });

      this.newLTViewer.instance.onClose
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(() => {
          this._updateViewerToolbar();
        });
    }
  }

  protected _updateViewerToolbar() {
    if (this.newLTViewer?.instance?.isDemriqProject === true) {
      const viewerComponent = this.newLTViewer?.instance;
      const viewerActionsAllowed = this.viewerHasItems(viewerComponent?.viewer);

      if (viewerActionsAllowed) {
        viewerComponent.viewerToolbar.restoreState();
      } else {
        if (!viewerComponent.viewerToolbar.hasState()) {
          viewerComponent.viewerToolbar.saveState();
        }

        const excludedItems = viewerComponent.viewerToolbar.getItem('ViewerLayout');
        viewerComponent.viewerToolbar.setReadOnly(true, null, ['ViewerLayout', ...excludedItems.items.map(i => i.id)]);
      }
      this.cdr.detectChanges();
    }
  }

  // call it manually in child component hook (ngOnDestroy)
  protected destroy() {
    this.newLTViewer = null;
    this.eventService.unsubscribe(EventNames.VIEWER_READY);
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
