/**
 * @description
 * Image overlay manager service interface for Dynamika image visualization component.
 *
 */

import { ViewerAuditableContext } from "src/app/_models/viewer/ViewerConfigModel";

export abstract class OverlayManagerService {
  public cellOverlays: any;
  public name2OverlayIds: any;
  public overlayId2names: any;
  public removeSeriesCell: any;
  public sliceSyncStatus: boolean;
  public cellsliceChanged: any;
  public toolbarItems: any;

  /**
   * Clear Remove Button Subscription
   */
  abstract clearRemoveButtonSubscription(): void;

  /**
   * Get overlay type
   * @param option
   */
  abstract getOverlayType(option: string): any;

  /**
   * Get overlay type name
   * @param type
   */
  abstract getOverlayTypeName(type: any): string;

  /**
   * Add overlay text to the cell
   * @param cell
   * @param text
   * @param alignment
   * @param overlayType
   * @param positionIndex
   * @param color
   */
  abstract addTag(cell, text, alignment, overlayType, positionIndex, color?);

  /**
   * Check if cell has overlays
   * @param cell
   */
  abstract cellHasOverlays(cell): boolean;

  /**
   * Set cell overlays
   * @param cell
   */
  abstract setCellOverlays(cell, metadata, isWaveForm);

  /**
   * Set Motion Correction overlay text
   * @param cell
   * @param text
   */
  abstract setCellMCOverlayText(cell, text: string): void;

  /**
   * Add custom overlays allowing user's interactions: slices navigator, frames navigator, etc.
   * @param cell
   */
  abstract addCustomOverlays(cell): void;

  /**
   * Initialize cell overlay
   * @param cell
   * @param cellOverlay
   */
  abstract initializeCellOverlay(cell): any;

  /**
   * Apply parametric map
   * @param event
   */
  abstract applyParametricMap(info: any);

  /**
   * Apply motion correction
   * @param event
   */
  abstract applyMotionCorrection(info: any);

  /**
   * Enable the probe tool on a cell
   * @param cell
   */
  abstract actionProbeTool(cell);

  /**
   *  Initialize the parametric maps menu
   */
  abstract initializeParametricMapsSubMenu(cell: any, audit?: ViewerAuditableContext);

  /**
   *  Get parametric maps
   */
  abstract getParametricMaps(cell);

  /**
   *  Set parametric maps
   */
  abstract setParametricMaps(cell, pm);

  abstract manageFrameChange(seriesInfo, cell, frame_tracker_id, timeline_id, newFrameNumb): void;

  abstract remove_mapped_canvas(cell): void;

  abstract redraw_mapped_canvas(cell): void;

  abstract redraw_gradient_canvas(cell): void;
}

