import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {MatDialog} from '@angular/material/dialog';
import {FormMode} from '../../../../../core/constants/form-mode';
import {StudyService} from '../../../../../_services';
import {ActivatedRoute} from '@angular/router';
import {StudyModel} from '../../../../../_models/ImagingProject/study-model';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
  selector: 'app-imaging-project-reading-psamris-wrapper',
  templateUrl: './imaging-project-reading-psamris-wrapper.component.html',
  styleUrls: ['./imaging-project-reading-psamris-wrapper.component.css']
})
export class ImagingProjectReadingPsamrisWrapperComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  readonly VISITS_ALLOWED = 3;

  readonly readingVersions = ReadingVersion;

  versionFormControl = new FormControl('', [
    Validators.required
  ]);

  readingVersion: ReadingVersion;
  study: StudyModel;

  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();

  @Input() selectedConfig: any;
  @Input() configModel: any;

  @Input()
  public mode: FormMode;

  readonly formModes = FormMode;

  constructor(private route: ActivatedRoute,
              private flowViewer: MatDialog,
              private studyService: StudyService) {
    super();
  }

  ngOnInit() {
    if (this.mode === FormMode.EDIT) {
      if (this.selectedConfig && this.selectedConfig.config && this.selectedConfig.config.readingVersion) {
        this.readingVersion = this.selectedConfig.config.readingVersion;
      }
    } else {
      this.studyService.getByIdWithVisits(parseInt(this.route.snapshot.params.id, 10)).subscribe(response => {
        this.study = response.data;
      });
    }
  }

  handleCancelClick(event) {
    this.clickCancel.emit(event);
  }

  handleSubmitClick(event) {
    this.clickSubmit.emit(event);
  }

  adjudicationAllowed(): boolean {
    if (this.mode === FormMode.NEW) {
      if (this.readingVersion === ReadingVersion.PSAMRIS_ADJUDICATION) {
        if (this.study.visits.length !== this.VISITS_ALLOWED || this.study.unscheduledAllowed === true) {
          return false;
        }
      }
    }
    return true;
  }

  cancel(): void {
    this.clickCancel.emit({result: 'cancel'});
  }

}
