import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {FormMode} from '../../../../../core/constants/form-mode';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {StudyModel} from '../../../../../_models/ImagingProject/study-model';
import {StudyService} from '../../../../../_services';
import {map} from 'rxjs/operators';
import {ResponseCode} from '../../../../../core/constants/response-code';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
  selector: 'app-imaging-project-reading-wbmri-wrapper',
  templateUrl: './imaging-project-reading-wbmri-wrapper.component.html',
  styleUrls: ['./imaging-project-reading-wbmri-wrapper.component.css']
})
export class ImagingProjectReadingWbmriWrapperComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  readonly VISITS_ALLOWED = 3;
  readonly readingVersions = ReadingVersion;
  readonly formModes = FormMode;

  @Input() public mode: FormMode;
  @Input() selectedConfig: any;
  @Input() visitsQuantity: number;
  @Input() public studySequenceLabels: SequenceLabelModel[];
  @Output() public clickCancel = new EventEmitter<any>();
  @Output() public clickSubmit = new EventEmitter<any>();

  versionFormControl = new FormControl('', [Validators.required]);
  canAdjudicationBeChosen;
  study: StudyModel;

  constructor(private flowViewer: MatDialog,
              private studyService: StudyService,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.initStudy().subscribe(() => {
      this.versionFormControl.valueChanges.subscribe(() => {
        this.updateCanAdjudicationBeChosen();
      });
      this.updateCanAdjudicationBeChosen();
    });
    if (this.mode === FormMode.EDIT && this.selectedConfig?.config?.readingVersion) {
      this.versionFormControl.setValue(this.selectedConfig.config.readingVersion);
    }
  }

  handleCancelClick(event) {
    this.clickCancel.emit(event);
  }

  handleSubmitClick(event) {
    this.clickSubmit.emit(event);
  }

  private updateCanAdjudicationBeChosen(): void {
    this.canAdjudicationBeChosen = this.mode === FormMode.EDIT
      || (this.study.visits.length === this.VISITS_ALLOWED && !this.study.unscheduledAllowed);
  }

  private initStudy(): Observable<void> {
    return this.studyService.getByIdWithVisits(this.getCurrentStudyId()).pipe(map(resp => {
      if (resp.responseCode === ResponseCode.OK) {
        this.study = resp.data;
      } else {
        throw new Error(`${resp.responseCode}: ${resp.responseMessage}`);
      }
    }));
  }

  private getCurrentStudyId(): number {
    return parseInt(this.route.snapshot.params.id, 10);
  }
}

export const FactorsToScoreWbmri = [
  {id: 'FEET_SYN_BME_STI', name: 'Feet (SYN, BME, STI)'},
  {id: 'HANDS_SYN_BME', name: 'Hands (BME, STI)'},
  {id: 'KNEES_SYN_BME_STI', name: 'Knees (SYN, BME, STI)'},
  {id: 'PELVIS_SYN_BME_STI', name: 'Pelvis (SYN, BME, STI)'},
  {id: 'SHOULDERS_SYN_BME_STI', name: 'Shoulders (SYN, BME, STI)'}
];

export interface Reader {
  id: number;
  firstName: string;
  lastName: string;
}
