import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BasicResponse } from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private http: HttpClient) {
  }

  create(comment: any) {
    return this.http.post(requestURL + `/comment`, comment);
  }

  update(comment: any) {
    return this.http.put(requestURL + `/comment`, comment);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/comment/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/comment/` + id);
  }

  getSeriesQcComments() {
    return this.http.get(requestURL + `/comment/get-series-qc-comments`);
  }

  getModalityQcComments() {
    return this.http.get(requestURL + `/comment/get-modality-qc-comments`);
  }

  getAll() {
    return this.http.get<BasicResponse<any>>(requestURL + `/comment/retrieve-all`);
  }
}
