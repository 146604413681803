import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

const API_URL = environment.apiUrl;
const EP_PSAMRIS = 'PSAMRIS';
const EP_KOALAKANDL = 'KOALA K&L';
const EP_MOAKS = 'MOAKS';
const EP_WBMRI = 'WBMRI';
const EP_CANDEN_SPARCC = 'CANDEN&SPARCC';
const EP_IF = 'INCIDENTAL FINDINGS';
const EP_NOVADIP5 = 'NOVADIP5';
const EP_K_AND_L = 'K&L';
const EP_GENANT = 'GENANT';
const HEEL_ENTHESITIS = 'HEEL';
const EP_RANO = 'RANO';
const EP_PSMA_PET_ELIGIBILITY = 'PSMA PET ELIGIBILITY';
const EP_DXA = 'DXA';
const EP_JSW = 'JSW';
const EP_OARSI = 'OARSI';
const EP_L3SMI = 'L3SMI';
const DEMRIQ = 'DEMRIQ';
const MERGER = 'MERGER';
const EP_ADVANCED_ANALYSIS = 'Advanced Analysis';
const EP_RECIST = 'RECIST';
const EP_ATYR = 'ATYR';
const EP_RAPNO = 'RAPNO';
const EP_REPORT = 'REPORT';

@Injectable({
  providedIn: 'root'
})

export class ResourceService {
  constructor(private http: HttpClient
  ) {
  }

  buildPsamrisResourceLink(studyId: number, readingId: number, type: string): string {
    return this.buildResourceLink(EP_PSAMRIS, `/resources/reading-${type}?readingId=${readingId}&studyId=${studyId}`);
  }

  buildMoaksResourceLink(studyId: number, readingId: number, type: string): string {
    return this.buildResourceLink(EP_MOAKS, `/resources/${type}?readingId=${readingId}&studyId=${studyId}`);
  }

  buildWbmriResourceLink(studyId: number, readingId: number, type: string): string {
    return this.buildResourceLink(EP_WBMRI, `/resources/${type}?readingId=${readingId}&studyId=${studyId}`);
  }

  buildCandenSparccResourceLink(studyId: number, readingId: number, type: string, csvType: string): string {
    return this.buildResourceLink(EP_CANDEN_SPARCC, `/resources/${type}?readingId=${readingId}&type=${csvType}&studyId=${studyId}`);
  }

  buildResourceLink(endpoint: string, subLink: string): string {
    console.log('endpoint: ', endpoint);
    let serviceName = null;
    let apiVersion = null;
    switch (endpoint.toUpperCase()) {
      case EP_PSAMRIS:
        serviceName = 'msreadingpsamris-dot-';
        apiVersion = environment.MSReadingPSAMRISVersion;
        break;
      case EP_KOALAKANDL:
        serviceName = 'msreadingkandlauto-dot-';
        apiVersion = environment.MSReadingKandLAutoVersion;
        break;
      case EP_MOAKS: {
        serviceName = 'msreadingmoaks-dot-';
        apiVersion = environment.MSReadingMOAKSVersion;
        break;
      }
      case EP_WBMRI: {
        serviceName = 'msreadingwbmri-dot-';
        apiVersion = environment.MSReadingWBMRIVersion;
        break;
      }
      case EP_CANDEN_SPARCC: {
        serviceName = 'msreadingcandensparcc-dot-';
        apiVersion = environment.MSReadingCandenSparccVersion;
        break;
      }
      case EP_IF: {
        serviceName = 'msreadingif-dot-';
        apiVersion = environment.MSReadingIFVersion;
        break;
      }
      case EP_NOVADIP5: {
        serviceName = 'msreadingnovadip5-dot-';
        apiVersion = environment.MSReadingNovadip5Version;
        break;
      }
      case EP_K_AND_L : {
        serviceName = 'msreadingkandl-dot-';
        apiVersion = environment.MSReadingKandLVersion;
        break;
      }
      case EP_GENANT : {
        serviceName = 'msreadinggenant-dot-';
        apiVersion = environment.MSReadingGENANTVersion;
        break;
      }
      case HEEL_ENTHESITIS : {
        serviceName = 'msreadingheel-dot-';
        apiVersion = environment.MSReadingHEELVersion;
        break;
      }
      case EP_RANO : {
        serviceName = 'msreadingmrano-dot-';
        apiVersion = environment.MSReadingMRANOVersion;
        break;
      }
      case EP_PSMA_PET_ELIGIBILITY : {
        serviceName = 'msreadingpsmapet-dot-';
        apiVersion = environment.MSReadingPSMAVersion;
        break;
      }
      case EP_DXA: {
        serviceName = 'msreadingdxa-dot-';
        apiVersion = environment.MSReadingDXAVersion;
        break;
      }
      case EP_JSW: {
        serviceName = 'msreadingjsw-dot-';
        apiVersion = environment.MSReadingJSWVersion;
        break;
      }
      case EP_OARSI: {
        serviceName = 'msreadingoarsi-dot-';
        apiVersion = environment.MSReadingOARSIVersion;
        break;
      }
      case EP_L3SMI: {
        serviceName = 'msreadingl3smi-dot-';
        apiVersion = environment.MSReadingL3SMIVersion;
        break;
      }
      case DEMRIQ: {
        serviceName = 'msreadingdemriq-dot-';
        apiVersion = environment.MSReadingDEMRIQVersion;
        break;
      }
      case MERGER: {
        serviceName = 'msmerger-dot-';
        apiVersion = environment.MSMerger;
        break;
      }
      case EP_ADVANCED_ANALYSIS: {
        serviceName = 'msreadingadvanalysis-dot-';
        apiVersion = environment.MSReadingAdvancedAnalysisVersion;
        break;
      }
      case EP_RECIST: {
        serviceName = 'msreadingrecist-dot-';
        apiVersion = environment.MSReadingRecistVersion;
        break;
      }
      case EP_ATYR: {
        serviceName = 'msreadingatyr-dot-';
        apiVersion = environment.MSReadingATYRVersion;
        break;
      }
      case EP_RAPNO: {
        serviceName = 'msreadingrapno-dot-';
        apiVersion = environment.MSReadingRAPNOVersion;
        break;
      }
      case EP_REPORT: {
        serviceName = 'msreportgenerator-dot-';
        apiVersion = environment.MSReportGeneratorVersion;
        break;
      }
    }
    return 'https://' + apiVersion + serviceName + API_URL + subLink;
  }

  getResource(link: string) {
    return this.http.get(link, {responseType: 'blob', observe: 'response'});
  }
}
