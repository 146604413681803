import {Component, OnInit} from '@angular/core';
import {Select, Store} from '@ngxs/store';
import {AppState} from '../../core/data-management/states/app.state';
import {Observable, Subscription} from 'rxjs';
import {ClearDownloadProcess} from '../../core/data-management/actions/app.action';

export interface DownloadMonitoringItem {
  uuid: string;
  filename: string;
  startDate: Date;
  patientCode: string;
  timepointName: string;
  status: 'ASSEMBLING_ARCHIVE' | 'READY_FOR_DOWNLOAD' | 'DOWNLOADING_SUCCESS' | 'ARCHIVE_DOWNLOADING' | 'ASSEMBLING_ARCHIVE_FAILURE' | 'ARCHIVE_DOWNLOAD_FAILURE';
  loaded: number;
  total: number;
  progresses: {
    uuid: string;
    fileId: number;
    loadedPercent: number;
    done: boolean;
  }[];
  subscriptions: Subscription[];
}

@Component({
  selector: 'download-monitoring',
  templateUrl: './download-monitoring.component.html',
  styleUrls: ['./download-monitoring.component.css']
})
export class DownloadMonitoringComponent implements OnInit {

  @Select(AppState.getDownloadState) downloadState: Observable<DownloadMonitoringItem[]>;

  downloadData: DownloadMonitoringItem[] = [];

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.downloadState.subscribe(state => {
      this.downloadData = state;
    });
  }

  clear(): void {
    this.store.dispatch(new ClearDownloadProcess());
  }

}
