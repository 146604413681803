import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import * as lodash from 'lodash';
import { VisitType } from 'src/app/_models/ImagingProject/patient-model';

export class ConfigurationObject {
visits: VisitModel[]
}

export  interface VisitModel {
  id?: number;
  created?: number;
  name: string;
  durationTimeUnit?: string;
  durationTimeValue?: number;
  baseline?: boolean;
  repeatAllowed?: boolean;
  modalities: ModalityMoldel[];
  questions?: any[];
}

export interface ModalityMoldel {
  id: number;
  created?: Date | null;
  name: string;
  sequenceLabels?: SequenceLabelModel[];
  selected?: boolean;
}

@Component({
  selector: 'app-visit-modality-configurator-dialog',
  templateUrl: './visit-modality-configurator-dialog.component.html',
  styleUrls: ['./visit-modality-configurator-dialog.component.css']
})
export class VisitModalityConfiguratorDialogComponent implements OnInit {
  configuration:  ConfigurationObject 
  configurationSelected: {visits: any[]} = {visits : []}
  disableSubmit = true;
  public get VisitType(): typeof VisitType {
    return VisitType;
  }
 
  constructor(public dialogRef: MatDialogRef<VisitModalityConfiguratorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { visits: any[], modalities: any[], unscheduledAllowed: boolean }) { }

  ngOnInit(): void {
    this.configuration = new ConfigurationObject()
    this.configuration.visits = lodash.cloneDeep(this.data.visits)
    this.data.visits.forEach((visit, visitIndex) => {
      this.configurationSelected.visits.push({ modalities: [] });
      this.data.modalities.forEach(modality => {
        const selected = this.data.visits[visitIndex].modalities.findIndex(m => m.id === modality.id) !== -1
        if (visit.type !== VisitType.UNSCHEDULED_REGULAR || this.data.unscheduledAllowed)
          this.configurationSelected.visits[visitIndex].modalities.push(selected)
      })
    })
  }
  
  onModalityChange(visitIndex: number, modality: ModalityMoldel, event) {
    this.disableSubmit = false;
    if (event.checked) {
      this.configuration.visits[visitIndex].modalities.push(modality)
    } else {
      this.configuration.visits[visitIndex].modalities = this.configuration.visits[visitIndex].modalities.filter(m => m.id !== modality.id)
    }
  }

  onSubmit() {
    this.dialogRef.close(this.configuration)
  }

  closeModalityDialog() {
    this.dialogRef.close();
  }
}
