import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import remove from 'lodash/remove';

const ROOT_URL = environment.Leadtools.rootURL;
const SERVICES = environment.Leadtools.services;

export enum EventNames {
  VIEWER_READY = 'VIEWER.ready',
  VIEWER_LOADING = 'VIEWER.setLoading',
  RESIZE_VIEWER = 'VIEWER.AsyncResize',
  SELECTION_CHANGED = 'VIEWER.selectionChanged',
  OFFSET_CHANGED = 'VIEWER.currentOffsetChanged',
  TOOLBAR_SET_PROPERTY = 'VIEWER.TOOLBAR.setProperty',
  TOOLBAR_SET_READONLY = 'VIEWER.TOOLBAR.setReadonly'
}

@Injectable()
export class EventService {
  listeners: Array<any> = [];

  /**
   * Create event subscription
   * @param name string
   * @param callback Function
   * @example eventService.subscribe('CONTEXT.MyEvent', text => { console.log(text);});
   */
  subscribe(name: EventNames | string, callback: Function): void {
    const listenerIdx = this.listeners.findIndex(l => l.name === name);
    if (listenerIdx !== -1) {
      this.listeners[listenerIdx].subscriptions.push(callback);
    } else {
      this.listeners.push({
        name,
        subscriptions: [callback]
      });
    }
  }

  /**
   * Unsubscribe from event by name, also unsubscribe all events
   * @example eventService.unsubscribe('VIEWER_LOADING');
   * @example eventService.unsubscribe();
   * @param prefix string
   * @param name string
   */
  unsubscribe(name: EventNames | string = null): void {
    if (name !== null) {
      const listenerIdx = this.listeners.findIndex(l => l.name === name);
      if (listenerIdx !== -1) {
        this.listeners.splice(1, listenerIdx);
      }
    } else {
      this.listeners = [];
    }
  }

  /**
   * Unsubscribe from events by prefix (event name starts with)
   * @example eventService.unsubscribeByPrefix('VIEWER');
   * @param prefix string
   */
  unsubscribeByPrefix(prefix: string) {
    remove(this.listeners, l => l.name.startsWith(`${prefix}.`));
  }

  /**
   * Publish event by name with optional parameters
   * @param name string
   * @param args any
   */
  publish(name: EventNames | string, ...args: any): void {
    const event = this.getEvent(name);
    if (event) {
      event.subscriptions.forEach(callback => callback(args.length === 1 ? args[0] : args));
    }
  }

  /**
   * Get event by name
   * @param name string
   */
  getEvent(name: EventNames | string): any {
    return this.listeners.find(l => l.name === name);
  }

  /**
   * Get event names array
   */
  get events(): Array<string> {
    return this.listeners.map(l => l.name);
  }
}
