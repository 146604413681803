export const PERMISSIONS = {
  "!": "configuration.study.delete",
  "#": "configuration.study.edit",
  "$": "configuration.study.create",
  "%": "configuration.site.create",
  "&": "configuration.site.delete",
  "'": "configuration.site.edit",
  "*": "configuration.user.deactivate",
  "+": "configuration.user.delete",
  "0": "configuration.imaging.delete",
  "1": "configuration.reading.create",
  "2": "configuration.imaging.create",
  "3": "configuration.imaging.edit",
  "4": "configuration.reading.edit",
  "5": "configuration.reading.delete",
  "6": "upload.view.all.site.data",
  "7": "upload.data",
  "8": "upload.create.or.upload.eDTF",
  "9": "upload.create.patient",
  ":": "qc.view.all.uploaded.data",
  "<": "qc.select.uploaded.visit",
  ">": "upload.view.eDTF",
  "?": "qc.view.results",
  "@": "reader.central",
  "A": "configuration.sponsor.create",
  "B": "configuration.sponsor.edit",
  "C": "configuration.sponsor.delete",
  "D": "configuration.user.edit",
  "E": "configuration.user.create",
  "F": "configuration.scanner.create",
  "G": "configuration.scanner.edit",
  "H": "configuration.scanner.delete",
  "I": "queries.update.eDCF",
  "J": "queries.view.eDCF",
  "K": "queries.resolve.eDCF",
  "L": "queries.create.eDCF",
  "M": "configuration.upload.view.all.site.data",
  "N": "upload.patient.discontinued",
  "O": "reader.dx.kl",
  "P": "reader.mri.if",
  "Q": "reader.wb-mri.if",
  "R": "reader.dce-mri.if",
  "S": "reader.dx.if",
  "T": "configuration.upload.edit",
  "U": "configuration.study.overall.viewer",
  "V": "qc.create.eDCF",
  "W": "dashboard.list.batches",
  "X": "dashboard.read-details.batch",
  "Y": "dashboard.read-details.reading",
  "Z": "dashboard.create-edit.reading",
  "^": "dashboard.create-edit.batch",
  "`": "dashboard.list.projects",
  "a": "dashboard.access",
  "b": "dashboard.access.sponsor",
  "c": "clinicaltrial.access",
  "d": "audittrails.access",
  "e": "dashboard.list.readings",
  "f": "dashboard.sponsor.read"
}
