import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {AuthenticationService} from '../../_services';
import {HttpCacheService} from '../../_services/http-cache/http-cache.service';
import { RefreshAPIService } from 'src/app/_services/refresh-api.service';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {

    constructor(
        private authenticationService: AuthenticationService,
        private _cache: HttpCacheService,
        private refreshAPI: RefreshAPIService
    ) { }

    /**
	 * @param {HttpRequest} request
	 * @param {HttpHandler} next
	 * @returns {Observable<HttpEvent<any>>}
	 */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request and replace the original headers with
        // cloned headers, updated with the X-Wrap-Mode.
        // request = request.headers.get("X-Wrap-Mode") === null
        //           ? request.clone({setHeaders: {'X-Wrap-Mode': 'enabled'}}) : request;

        if (request.method === 'POST' ||
            request.method === 'PUT' ||
            request.method === 'PATCH' ||
            request.method === 'DELETE' ||
            this._cache.isCachedUrlForThisPage(this.refreshAPI.whichPage, request.urlWithParams)) {
            this._cache.delete(request.urlWithParams);
        }

        let cachedResponse = this._cache.get(request.urlWithParams);

        if(cachedResponse){
            return of(cachedResponse);
        } else {
            return next.handle(request).pipe(
                tap<HttpEvent<any>>(
                    (response: HttpEvent<any>) => {
                        if (response instanceof HttpResponse) {
                            this._cache.put(request.urlWithParams, response);
                            if (response.body && [1011, 2002].includes(response.body.responseCode)) { // jwt auth error
                                this.authenticationService.logout();
                            }
                            // https://image-analysis-group.atlassian.net/browse/D6-1878
                            return response.clone({
                               headers: response.headers
                                   .set('X-Content-Type-Options', 'nosniff')
                                   .set('Strict-Transport-Security', 'max-age=31536000; includeSubdomains')
                                   .set('Content-Security-Policy', "default-src 'self'")
                                   .set('Cache-control','no-cache, no-store, must-revalidate')
                                   .set('Pragma','no-cache')
                                   .set('X-Frame-Options','SAMEORIGIN')
                                   .set('X-Permitted-Cross-Domain-Policies','none')
                            });
                        }
                    },
                    (error: any) => {
                        if (error instanceof HttpErrorResponse) {
                            if ([403].includes(error.status) && error.url && !error.url.includes('/login')) {
                                // auto logout if 403 Forbidden response returned from api
                                this.authenticationService.logout();
                            }
                        }
                    }
                )
            );
        }
    }
}
