import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { SeriesNavigatorComponent } from 'src/app/components/controls/series-navigator/series-navigator.component';
import { forkJoin, Observable, Subscription } from 'rxjs';
import {
  ImagingProjectService,
  ReadingConfigFlexibleService,
  StudySequenceLabelService,
  StudyUserService,
  ViewerAuthenticationService
} from 'src/app/_services';
import { ReadingMranoService } from 'src/app/_services/reading-mrano.service';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from 'src/app/core/data-management/actions/projects.action';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import { BaseFlexibleConfig } from 'src/app/_models/ImagingProject/base-flexible-config';
import { ViewerCoreLtComponent } from '../../../Viewer/viewer-core-lt/viewer-core-lt.component';
import { MranoReadingModel } from 'src/app/_models/MRANO/mrano-reading-model';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
import * as _ from 'lodash';

interface ViewsLayoutConfiguration {
  col: number;
  row: number;
  sizex: number;
  sizey: number;
}

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.css']
})
export class QuestionFormComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChildren('viewerLT', { read: ViewContainerRef }) viewerLTs: QueryList<ViewContainerRef>;
  viewerLT: ViewContainerRef;
  @ViewChild('viewercontiner', { read: ElementRef }) viewercontiner: ElementRef;
  @ViewChild('seriesNavigator') seriesNavigator: SeriesNavigatorComponent;
  selectedTab = 0;
  newViewer: any = null;
  newLTViewer: any = null;
  isActiveLTViewer = false;
  openedNumberOfImages = 0;
  viewsLayoutConfigurations: ViewsLayoutConfiguration[] = [];
  readingList: Array<MranoReadingModel> = [];
  currentReading: MranoReadingModel;
  userId: number;
  study: any;
  spentSecondsSubsctiption: Subscription;
  ltViewersSubsctiption: Subscription;
  ltViewerSubsctiption: Subscription;
  showViewer = false;
  showModalSpinnerLoadingData = true;
  visitedSeriesCounter = 0;
  visitConfig: any;
  readingSeries = [];
  allseries = [];
  readingSeriesDetails = [];
  isExpanded = true;
  readingLevel: string;
  validationMessages: string[];
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  validUids: string[] = [];
  availableSequenceLabels: SequenceLabelModel[] = [];
  defaultSequenceLabelIds: number[] = [];

  viewerData = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;

  readingConfigResponse: any;
  resizeInterval: any = null;
  mode = 'answer';
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  questionForm: FormGroup;
  answerFormJson: any = []
  answerFrom;
  answers = [[]];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private studySequenceLabelService: StudySequenceLabelService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private readingMranoService: ReadingMranoService,
    private changeDetector: ChangeDetectorRef,
    private toastyService: ToastyService,
    private store: Store,
    private readingConfigService: ReadingConfigFlexibleService,
    private authenticationService: ViewerAuthenticationService,
    private imagingProjectService: ImagingProjectService,
    private fb: FormBuilder,
    private studyUserService: StudyUserService,
    private utils: Utils,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit(): void {

    this.store.dispatch(new SetPageHeaderTitle('Question form'));
    this.userId = +JSON.parse(localStorage.getItem('userId'));
    this.study = JSON.parse(localStorage.getItem('project'));
    this.configureLayouts();
    const serviceSources = [
      this.readingMranoService.getReadingByStudyIdAndReaderId(this.study.id, this.userId),
      this.readingConfigFlexibleService.getByStudyId(this.study['id'])
    ];
    forkJoin(serviceSources).subscribe(
      values => {
        const [readingResponse, readingConfigResponse] = values as any;
        if (readingResponse['responseCode'] === 200 && readingConfigResponse['responseCode'] === 200) {
          this.readingConfigResponse = readingConfigResponse;
          this.refreshReadingsList(readingResponse);
        } else if (readingResponse['responseCode'] !== 200) {
          this.showErrorMessage(readingResponse);
        } else if (readingConfigResponse['responseCode'] !== 200) {
          this.showErrorMessage(readingConfigResponse);
        }
      },
      (error) => this.showErrorMessage(error.error)
    );
    let item = localStorage.getItem('tmp.upload.patient.visit');
    console.log(JSON.parse(item))
    this.initiateQuestionForm();
  }

  dragStart(e) {
    // console.log(e)
    // console.log("dragStart...");
    this.resizeInterval = setInterval(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  dragEnd(e) {
    // console.log(e)
    // console.log("dragEnd...");
    clearInterval(this.resizeInterval)
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  ngOnDestroy(): void {
    if (this.spentSecondsSubsctiption) {
      this.spentSecondsSubsctiption.unsubscribe();
    }
    if (this.ltViewerSubsctiption) {
      this.ltViewerSubsctiption.unsubscribe();
    }
    if (this.ltViewersSubsctiption) {
      this.ltViewersSubsctiption.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.ltViewersSubsctiption = this.viewerLTs.changes.subscribe((comps: QueryList<ViewContainerRef>) => {
      if (!this.viewerLT && comps.first) {
        this.viewerLT = comps.first;
        this.preOpenLTViewer();
      }
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges(); // fix ExpressionChangedAfterItHasBeenCheckedError
  }

  configureFromConfigFlexible(response) {
    let existed = false;
    const data = response['data'];
    if (data) {
      for (let i1 = 0; i1 < data.length; i1++) {
        const i = data[i1];
        if (i.config['endpoint'].name.toLowerCase() === 'mrano' && this.currentReading.configId === i['id'] && i.status['name'].toLowerCase() === 'active') {
          this.readingLevel = i.config.readingLevel;
          existed = true;
          break;
        }
      }
    }
    if (!existed) {
      this.toastOptions.title = 'ERROR configuration not existed'
      this.toastOptions.msg = 'There is no active configuration for this webinar'
      this.toastyService.error(this.toastOptions);
    }
  }

  refreshReadingsList(response) {
    this.readingList = [];
    const read = response['data'];
    if (read != null) {
      this.readingList = read;
      if (this.readingList.length) {
        this.initReading(this.readingList[0]);
      }
    }
  }

  initReading(reading) {
    this.showModalSpinnerLoadingData = true;
    this.currentReading = reading;
    this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
      this.initDefaultSequenceLabels(configResp.data);
    });
    this.currentReading.visits = reading['visits'];
    this.visitedSeriesCounter = 0;
    this.visitConfig = this.currentReading.visits;
    this.currentReading.readingLevel = this.readingLevel;
    if (this.currentReading.visits) {
      this.readingSeries = [];
      this.readingSeriesDetails = [];
      const seriesIds = [];
      this.currentReading.visits.forEach(v => {
        v.series.forEach(s => {
          this.readingSeries.push(s);
          seriesIds.push(s.seriesId);
        });
      });
      this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).subscribe(response => {
        if (response['responseCode'] === 200) {
          if (response['data']) {
            this.readingSeriesDetails = response['data'];
            this.readingSeriesDetails.forEach(sd => {
              const visit = this.getVisitInfo(sd.id);
              if (visit != null) {
                sd['seriesVisitName'] = visit.timepoint;
                sd['visitConfigId'] = visit.visitConfigId;
              }
            });
          }
        } else {
          this.showErrorMessage(response);
        }
      });
    }
    if (this.currentReading.status.code < 700) {       // in_progress
      this.readingMranoService.startReading(this.currentReading.studyId, this.currentReading.id).subscribe(response => {
        if (response['responseCode'] === 200) {
          this.currentReading.status = response['data'].status;
        } else {
          this.showErrorMessage(response);
        }
      });
    }
    ;

    const viewerDataResults: Array<Observable<any>> = [];

    this.visitConfig.forEach(visits => {
      this.imagingProjectService.getVisitConfig(visits.visitConfigId).subscribe(imagProjResp => {
        let visitConfig = imagProjResp['data'];
        if (visitConfig != null) {
          visitConfig.series.forEach(visitSeries => {
            let series = visitSeries
            if (series != null) {
              this.allseries.push(series)
              series.seriesId = visitSeries.id;
              series.seriesInstanceUID = visitSeries.seriesUID;

              const info = { "selectedPage": "Reading" };
              viewerDataResults.push(this.utils.initViewerData(series, this, info));
            }
          });
        }
      });
    });

    if (viewerDataResults.length) {
      this.viewerDataIsLoading = true;
      forkJoin(viewerDataResults).subscribe(() => {
        this.viewerDataIsReady = true;
        this.viewerDataIsLoading = false;
        this.cdr.detectChanges();

        if (this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
          this.viewerData.toolbarExcludeItems = ['ShowDicom'];
        }
      });
    }

    this.configureFromConfigFlexible(this.readingConfigResponse);
    this.showModalSpinnerLoadingData = false;
  }

  initDefaultSequenceLabels(readingConfig: FlexibleConfig<BaseFlexibleConfig>): void {
    const configSequenceLabels: SequenceLabelModel[] = [];
    readingConfig.config.modalities.forEach(m => {
      m.sequenceLabels.forEach(s => configSequenceLabels.push(s));
    });
    this.availableSequenceLabels = configSequenceLabels;
    if (!!readingConfig.config.anatomySelector) {
      this.defaultSequenceLabelIds = (readingConfig.config.anatomySelector as { id: number }[])
        .filter(label => this.availableSequenceLabels.find(studyLabel => studyLabel.id === label.id)).map(l => l.id);
    } else {
      this.defaultSequenceLabelIds = this.availableSequenceLabels.map(l => l.id);
    }
  }

  getVisitInfo(seriesId) {
    let visit = null;
    this.currentReading.visits.forEach(v => {
      const series = v.series.find(s => s.seriesId === seriesId);
      if (series) {
        visit = v;
      }
    });
    return visit;
  }

  configureLayouts() {
    /* layout configuration for multiple viewers */
    this.viewsLayoutConfigurations.push({ 'col': 0, 'row': 0, 'sizex': 12, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 0, 'sizex': 6, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
  }

  /* --------------------------- navigator methods --------------------------- */

  onVisitedSeriesCounter(event) {
    this.visitedSeriesCounter = event;
  }

  onCloseAllViewers(event) {
    console.log('--- onCloseAllViewers');

    this.newLTViewer.instance.removeAllCells();
  }

  onOpenViewer(event) {
    if (event != null) {
      const seriesArr = [];
      event.forEach((seriesId) => {
        localStorage.setItem('visitConfigId', this.getVisitInfo(seriesId).visitConfigId)
        const series = this.readingSeries.find(s => s.seriesId === seriesId);
        seriesArr.push(series.seriesId);
      });
      this.openLTViewer(seriesArr);
    }
  }

  /* --------------------------- viewer methods --------------------------- */

  openNewLTViewer(seriesInstanceUIDs) {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(
      ViewerCoreLtComponent
    );

    this.newLTViewer = this.viewerLT.createComponent(compFactory);
    this.newLTViewer.instance.seriesIds = seriesInstanceUIDs;
    this.newLTViewer.instance.shouldShowDICOM = true;//show scan date
    this.ltViewerSubsctiption = this.newLTViewer.instance.onClose.subscribe(({ serID, shouldCloseViewer }) => {
      this.closeLTViewerSeries(serID, shouldCloseViewer);
    });
  }

  preOpenLTViewer() {
    this.openNewLTViewer(null);
  }

  openLTViewer(seriesInstanceUIDs: string[] = [], sliceNumber?: number, locked?: boolean) {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1500);

    this.viewercontiner.nativeElement.style.display = '';

    if (this.newLTViewer && this.viewerDataIsReady) {
      this.viewerData['sliceNumber'] = sliceNumber;
      this.viewerData['readingID'] = this.currentReading.id;
      this.viewerData['locked'] = locked;
      this.newLTViewer.instance.openNewSer(seriesInstanceUIDs, this.viewerData);
      this.openedNumberOfImages = this.newLTViewer.instance.getNumberOfImages();
      return;
    } else {
      this.openNewLTViewer(seriesInstanceUIDs);
    }

    const toolbarExcludeItems = _.get(this.viewerData, 'toolbarExcludeItems', []);
    if (toolbarExcludeItems.length > 0) {
      _.each(toolbarExcludeItems, i => {
        this.newLTViewer.instance.viewerToolbar.setItemProperty(i, 'disabled', true);
      });
    }
  }

  closeLTViewerSeries(serID, shouldCloseViewer) {
    this.seriesNavigator.unmarkSeriesAsOpenedBySeriesId(serID);

    if (shouldCloseViewer) {
      this.viewercontiner.nativeElement.style.display = 'none';
    }
  }

  isReadingValid() {
    return true;
  }

  //Question form functions
  initiateQuestionForm() {
    if (this.mode === 'question') {
      this.questionForm = new FormGroup({
        questions: new FormArray([]),
        descriptions: new FormArray([])
      })
    } else {
      this.answerFrom = this.fb.group({});
      this.answerFormJson = [{
        id: 1,
        type: 'radio',
        title: 'what is your gender?',
        answers: 'man,woman,other'
      },
        {
          id: 2,
          type: 'multichoice',
          title: 'what is your gender?',
          answers: 'man,woman,other'
        },
        {
          id: 3,
          type: 'paragraph',
          title: 'what is your gender?',
          answers: 'man,woman,other'
        }
      ];
      this.createControl(this.answerFormJson);
    }
  }

  createControl(controls: Array<any>) {
    for (let control of controls) {
      const newFormControl = new FormControl();
      this.answerFrom.addControl(control.id, newFormControl)
    }
  }

  getQuestionsControls() {
    return (this.questionForm.get('questions') as FormArray).controls;
  }

  getDescriptionControls() {
    return (this.questionForm.get('descriptions') as FormArray).controls;
  }

  onAddQuestion() {
    const control = new FormGroup({
      title: new FormControl('', [Validators.required]),
      questionType: new FormControl('', [Validators.required]),
      answers: new FormArray([])
    });
    (<FormArray>this.questionForm.get('questions')).push(control);
    this.answers.push([])
  }

  onAddDescription() {
    const control = new FormGroup({
      description: new FormControl('', [Validators.required]),
      attachFile: new FormControl(null)
    });
    (<FormArray>this.questionForm.get('descriptions')).push(control);
  }

  selectFiles(event, index) {
    console.log()
    if (event.target.files) {
      (<FormGroup>(<FormGroup>this.questionForm.get('descriptions')).controls[index]).get('attachFile').setValue(event.target.files[0])
    }
    //this.uploadImagingManualFileToCloud();
    event.preventDefault();
  }

  add(event: MatChipInputEvent, index: number): void {
    const value = event.value;
    const input = event.input;
    if ((value || '').trim()) {
      this.answers[index].push(value);
      this.questionForm.get('questions').value[index].answers.push(event.value);
    }
    if (input) {
      input.value = '';
    }
  }

  remove(answer: string, index: number): void {
    const i = this.answers[index].indexOf(answer);
    if (index >= 0) {
      this.answers[index].splice(i, 1);
    }
  }

  removeQuestion(index: number) {
    (<FormArray>this.questionForm.get('questions')).removeAt(index);
    this.answers.splice(index, 1)
  }

  removeDescription(index: number) {
    (<FormArray>this.questionForm.get('descriptions')).removeAt(index);
  }


  submitResult() {
    if (this.mode === 'question')
      console.log(this.questionForm.value);
    if (this.mode === 'answer')
      console.log(this.answerFrom.value);
    // if (this.isReadingValid()) {
    //     this.selectedTab=0;
    //     this.currentReading.timeSpent = this.spentSeconds;
    //     this.showModalSpinnerLoadingData = true;
    //     this.readingMranoService.completeReading(
    //         this.study.id, this.currentReading
    //     ).subscribe(response => {
    //         if (response['responseCode'] === 200) {
    //             const index = this.getIndexOfCurrentReading();
    //             this.readingList.splice(index, 1);
    //             if (this.readingList.length) {
    //                 this.skipCurrentReading(index);    // ind == nextIndex after splice
    //             } else {
    //                 this.currentReading = undefined;
    //             }
    //         } else {
    //             this.showErrorMessage(response);
    //         }
    //     });
    // }
  }

  showErrorMessage(response: any): void {
    this.showModalSpinnerLoadingData = false;
    this.toastOptions.title = 'ERROR ' + response['responseCode'];
    this.toastOptions.msg = response['responseMessage'];
    this.toastyService.error(this.toastOptions);
  }
}
