import { Pipe, PipeTransform, OnDestroy } from '@angular/core';

@Pipe({
  name: 'timeFormat',
  pure: false
})
export class TimeFormatPipe implements PipeTransform, OnDestroy {
  private handle: any;
  private elaplsed: string;

  transform(startValue: Date): string {
    if (!this.handle) {
      this.handle = setInterval(() => {
        // get the time zone offset
        let offSet = startValue.getTimezoneOffset();
        let timeNow = new Date().getTime() - startValue.getTime();
        // apply time zone offset
        timeNow += (offSet > 0 ? -offSet : offSet) * 60 * 1000;
        let step = new Date(timeNow);
        this.elaplsed = (step.getHours() < 10 ? '0' : '') + step.getHours() +
          (step.getMinutes() < 10 ? ':0' : ':') + step.getMinutes() +
          (step.getSeconds() < 10 ? ':0' : ':') + step.getSeconds();
        return this.elaplsed;
      }, 1000);
    }
    return this.elaplsed;
  }

  ngOnDestroy(): void {
    if (this.handle) {
      clearInterval(this.handle);
    }
  }
}
