import {AnnJswObject} from "./ann-jsw-object";

export class AnnJswDrawDesigner extends lt.Annotations.Designers.AnnDrawDesigner {

  // tslint:disable-next-line:max-line-length
  constructor(automationControl: lt.Annotations.Engine.IAnnAutomationControl, container: lt.Annotations.Engine.AnnContainer, annJswObject: lt.Annotations.Engine.AnnObject) {
    super(automationControl, container, annJswObject);
  }

  // override the onPointerDown method and add 3 points for our triangle
  onPointerDown(sender: lt.Annotations.Engine.AnnContainer, e: lt.Annotations.Engine.AnnPointerEventArgs): boolean {
    let handled = super.onPointerDown(sender, e);

    if (this.targetObject.points.count < 3) {
      this.targetObject.tag = 'drawing';
      if (e.button === lt.Annotations.Engine.AnnMouseButton.left) {
        const t = this.targetObject as AnnJswObject;

        for (let i = 0; i < 7; i++) {
          const newFirstPoint = e.location.clone();
          newFirstPoint.x += i * 200;
          t.addFirstLinePoint(this.snapPointToGrid(newFirstPoint, false));


          const newSecondPoint = e.location.clone();
          newSecondPoint.x += i * 200;
          newSecondPoint.y += 200;
          t.addSecondLinePoint(this.snapPointToGrid(newSecondPoint, false));
        }

        const metadata = t.metadata;
        t.CalcVerticalDistance = t.metadata['CalcVerticalDistance'] !== 'false';
        this.startWorking();
        handled = true;
      }
    }
    this.invalidate(lt.LeadRectD.empty);
    return handled;
  }

  // override the onPointerUp method and end the drawing when we have our 3 points
  onPointerUp(sender: lt.Annotations.Engine.AnnContainer, e: lt.Annotations.Engine.AnnPointerEventArgs): boolean {
    let handled = super.onPointerUp(sender, e);
    handled = true;
    if (this.targetObject.points.count >= 3) {
      this.targetObject.tag = null;
      this.endWorking();
    }
    return handled;
  }
}
