import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, RequiredValidator, ValidatorFn, Validators } from '@angular/forms';
import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BasicResponse } from 'src/app/core/interfaces/basic-response';
import { ReadingFormScoringComponent, ReadingFormScoring } from '../reading-form-scoring.component';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from 'src/app/core/data-management/actions/projects.action';
import { ReadingLevel } from 'src/app/core/constants/reading-level';
import { VisitStatus } from 'src/app/_models/ImagingProject/IF/incidental-findings-config-model';
import { MatDialog } from '@angular/material/dialog';
import { ReadingMEPOService } from 'src/app/_services/reading-mepo.service';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { switchMap } from 'rxjs/operators';
import { ConfirmSigningDialogComponent } from '../../ReportSign/confirm-signing-dialog/confirm-signing-dialog.component';


interface MEPOScore {

  // image quality
  imageQuality: string;
  imageQualityComment: string;


  // TODO: add other properties here
}

@Component({
  selector: 'app-mepo-reading-form',
  templateUrl: './mepo-reading-form.component.html',
  styleUrls: ['./mepo-reading-form.component.css']
})
export class MEPOReadingFormComponent extends ReadingFormScoringComponent implements OnInit, OnDestroy, ReadingFormScoring {

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private readingService: ReadingMEPOService,
    private dialog: MatDialog,
    private toastService: ToastyService
  ) {
    super();
  }

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  currentReading: any = {
    studyId: 'NA',
    patientId: 'NA',
    visit: {id: 'NA'},
    aquisitionDate: 'NA'
  };

  hiddenForm = false;
  readingForm: FormGroup = this.fb.group({});
  visitsArrayForm: FormArray = this.fb.array([]);
  selectedVisit = 0;
  selectedTab = 0;
  previousImageQuality: string;
  formChanged = false;

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('MEPO OA Scoring Form'));
    this.newTabScoringFormEnabledSubject.next(true);


    this.initOnCurrentReading();
    this.readingSeriesInitiated.subscribe(resp => {
      if (resp) {
        this.chooseActiveVisitByStatus();
      }
    });
    this.readingForm.valueChanges.subscribe(() => {
      this.formChanged = true; // Set flag to true when form changes
    });
    this.hideScoringFormSubject.subscribe(hidden => {
      this.hiddenForm = hidden;
    });
  }

  ngOnDestroy(): void {
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      if (currentReading.readingLevel === ReadingLevel.LONGITUDINAL) {
        this.switchSubmitBtnDisabledSubject.next(false);
      }
      this.readingForm = new FormGroup({});
      this.visitsArrayForm = new FormArray([]);
      this.currentReading = currentReading;

      this.initReadingForm();
    });
  }

  initReadingForm(): void {
    this.currentReading.visits = this.currentReading.visits.sort((a, b) => a.visitOrder - b.visitOrder);
    this.currentReading.visits.forEach((v, visitIndex) => {
      // create visit form
      const disabled = this.isReadonlyVisit(visitIndex);

      const kneeMalRight = v.scoring?.kneeMalalignment?.isRight;
      const kneeMalRightEnabled = kneeMalRight == 'Yes';
      const kneeMalLeft = v.scoring?.kneeMalalignment?.isLeft;
      const kneeMalLeftEnabled = kneeMalLeft == 'Yes';
      const kneeMalalignmentDisabled = disabled || !this.currentReading.visits[visitIndex]?.baseline;

      const visitFormControl = new FormGroup({
        id: new FormControl(v.id),
        visitConfigId: new FormControl(v.visitConfigId),
        status: new FormControl(v.status),
        visitOrder: new FormControl(v.visitOrder),
        scoring: new FormGroup({
          id: new FormControl(v.scoring?.id),
          imageQuality: new FormControl({value: v.scoring?.imageQuality, disabled}, [Validators.required]),
          imageQualityComment: new FormControl({value: v.scoring?.imageQualityComment, disabled},
            v.scoring?.imageQuality === 'Poor' || v.scoring?.imageQuality === 'Suboptimal' ?
              [Validators.required, Validators.maxLength(500)] : [Validators.maxLength(500)]),
          imageQualityCommentOthers: new FormControl({value: v.scoring?.imageQualityCommentOthers, disabled}),
          kneeMalalignment: new FormGroup({
            isRight: new FormControl({value: kneeMalRight, disabled: kneeMalalignmentDisabled}, [Validators.required]),
            right: new FormControl({value: v.scoring?.kneeMalalignment?.right,
              disabled: !kneeMalRightEnabled || kneeMalalignmentDisabled},
              kneeMalRightEnabled ? [Validators.required, Validators.min(1)] : []),
            rightKneeAngulation: new FormControl({value: v.scoring?.kneeMalalignment?.rightKneeAngulation,
              disabled: !kneeMalRightEnabled || kneeMalalignmentDisabled},
              kneeMalRightEnabled ? [Validators.required] : []),
            isLeft: new FormControl({value: kneeMalLeft, disabled: kneeMalalignmentDisabled}, [Validators.required]),
            left: new FormControl({value: v.scoring?.kneeMalalignment?.left,
              disabled: !kneeMalLeftEnabled || kneeMalalignmentDisabled},
              kneeMalLeftEnabled ? [Validators.required, Validators.min(1)] : []),
            leftKneeAngulation: new FormControl({value: v.scoring?.kneeMalalignment?.leftKneeAngulation,
              disabled: !kneeMalLeftEnabled || kneeMalalignmentDisabled},
              kneeMalLeftEnabled ? [Validators.required] : []),
          }),
          kAndL: new FormGroup({
            rightKnee: new FormControl({value: v.scoring?.kAndL?.rightKnee, disabled}, [Validators.required]),
            leftKnee: new FormControl({value: v.scoring?.kAndL?.leftKnee, disabled}, [Validators.required]),
          }),
          oarsi: new FormGroup({
            jointSpaceNarrowing: new FormGroup({
              right: new FormGroup({
                lateral: new FormControl({value: v.scoring?.oarsi?.jointSpaceNarrowing?.right?.lateral, disabled}, [Validators.required]),
                medial: new FormControl({value: v.scoring?.oarsi?.jointSpaceNarrowing?.right?.medial, disabled}, [Validators.required]),
              }),
              left: new FormGroup({
                lateral: new FormControl({value: v.scoring?.oarsi?.jointSpaceNarrowing?.left?.lateral, disabled}, [Validators.required]),
                medial: new FormControl({value: v.scoring?.oarsi?.jointSpaceNarrowing?.left?.medial, disabled}, [Validators.required]),
              }),
            }),
            osteophytosisFemur: new FormGroup({
              right: new FormGroup({
                lateral: new FormControl({value: v.scoring?.oarsi?.osteophytosisFemur?.right?.lateral, disabled}, [Validators.required]),
                medial: new FormControl({value: v.scoring?.oarsi?.osteophytosisFemur?.right?.medial, disabled}, [Validators.required]),
              }),
              left: new FormGroup({
                lateral: new FormControl({value: v.scoring?.oarsi?.osteophytosisFemur?.left?.lateral, disabled}, [Validators.required]),
                medial: new FormControl({value: v.scoring?.oarsi?.osteophytosisFemur?.left?.medial, disabled}, [Validators.required]),
              }),
            }),
            osteophytosisTibia: new FormGroup({
              right: new FormGroup({
                lateral: new FormControl({value: v.scoring?.oarsi?.osteophytosisTibia?.right?.lateral, disabled}, [Validators.required]),
                medial: new FormControl({value: v.scoring?.oarsi?.osteophytosisTibia?.right?.medial, disabled}, [Validators.required]),
              }),
              left: new FormGroup({
                lateral: new FormControl({value: v.scoring?.oarsi?.osteophytosisTibia?.left?.lateral, disabled}, [Validators.required]),
                medial: new FormControl({value: v.scoring?.oarsi?.osteophytosisTibia?.left?.medial, disabled}, [Validators.required]),
              }),
            }),
            sclerosis: new FormGroup({ // Sclerosis FormGroup
              right: new FormControl({value: v.scoring?.oarsi?.sclerosis?.right, disabled}, [Validators.required]),
              left: new FormControl({value: v.scoring?.oarsi?.sclerosis?.left, disabled}, [Validators.required]),
            }),
            attrition: new FormGroup({ // Attrition FormGroup
              right: new FormControl({value: v.scoring?.oarsi?.attrition?.right, disabled}, [Validators.required]),
              left: new FormControl({value: v.scoring?.oarsi?.attrition?.left, disabled}, [Validators.required]),
            }),
          }),
          findings: new FormGroup({
            subchondralInsufficiencyFracture: new FormGroup({ // SubchondralInsufficiencyFracture FormGroup
              isSubchondralInsufficiencyFracture: new FormControl({value: v.scoring?.findings?.subchondralInsufficiencyFracture?.isSubchondralInsufficiencyFracture, disabled}, []),
              isRight: new FormControl({value: v.scoring?.findings?.subchondralInsufficiencyFracture?.isRight, disabled}, []),
              right: new FormControl({value: v.scoring?.findings?.subchondralInsufficiencyFracture?.right, disabled}, []),
              isLeft: new FormControl({value: v.scoring?.findings?.subchondralInsufficiencyFracture?.isLeft, disabled}, []),
              left: new FormControl({value: v.scoring?.findings?.subchondralInsufficiencyFracture?.left, disabled}, []),
              comment: new FormControl({value: v.scoring?.findings?.subchondralInsufficiencyFracture?.comment, disabled}, []),
            }, this.validateFindings("isSubchondralInsufficiencyFracture")),
            significantArticularBoneLoss: new FormGroup({ // SignificantArticularBoneLoss FormGroup
              isSignificantArticularBoneLoss: new FormControl({value: v.scoring?.findings?.significantArticularBoneLoss?.isSignificantArticularBoneLoss, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.significantArticularBoneLoss?.isRight, disabled}),
              right: new FormControl({value: v.scoring?.findings?.significantArticularBoneLoss?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.significantArticularBoneLoss?.isLeft, disabled}),
              left: new FormControl({value: v.scoring?.findings?.significantArticularBoneLoss?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.significantArticularBoneLoss?.comment, disabled}, []),
            }, this.validateFindings("isSignificantArticularBoneLoss")),
            articularBoneFragmentationOrCollapse: new FormGroup({ // ArticularBoneFragmentationOrCollapse FormGroup
              isArticularBoneFragmentationOrCollapse: new FormControl({value: v.scoring?.findings?.articularBoneFragmentationOrCollapse?.isArticularBoneFragmentationOrCollapse, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.articularBoneFragmentationOrCollapse?.isRight, disabled}),
              right: new FormControl({value: v.scoring?.findings?.articularBoneFragmentationOrCollapse?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.articularBoneFragmentationOrCollapse?.isLeft, disabled}),
              left: new FormControl({value: v.scoring?.findings?.articularBoneFragmentationOrCollapse?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.articularBoneFragmentationOrCollapse?.comment, disabled}, []),
            }, this.validateFindings("isArticularBoneFragmentationOrCollapse")),
            osteonecrosis: new FormGroup({ // Osteonecrosis FormGroup
              isOsteonecrosis: new FormControl({value: v.scoring?.findings?.osteonecrosis?.isOsteonecrosis, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.osteonecrosis?.isRight, disabled}),
              right: new FormControl({value: v.scoring?.findings?.osteonecrosis?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.osteonecrosis?.isLeft, disabled}),
              left: new FormControl({value: v.scoring?.findings?.osteonecrosis?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.osteonecrosis?.comment, disabled}, []),
            }, this.validateFindings("isOsteonecrosis")),
            pathologicFracture: new FormGroup({ // PathologicFracture FormGroup
              isPathologicFracture: new FormControl({value: v.scoring?.findings?.pathologicFracture?.isPathologicFracture, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.pathologicFracture?.isRight, disabled}),
              right: new FormControl({value: v.scoring?.findings?.pathologicFracture?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.pathologicFracture?.isLeft, disabled}),
              left: new FormControl({value: v.scoring?.findings?.pathologicFracture?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.pathologicFracture?.comment, disabled}, []),
            }, this.validateFindings("isPathologicFracture")),
            primaryOrMetastaticTumor: new FormGroup({ // PrimaryOrMetastaticTumor FormGroup
              isPrimaryOrMetastaticTumor: new FormControl({value: v.scoring?.findings?.primaryOrMetastaticTumor?.isPrimaryOrMetastaticTumor, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.primaryOrMetastaticTumor?.isRight, disabled}),
              right: new FormControl({value: v.scoring?.findings?.primaryOrMetastaticTumor?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.primaryOrMetastaticTumor?.isLeft, disabled}),
              left: new FormControl({value: v.scoring?.findings?.primaryOrMetastaticTumor?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.primaryOrMetastaticTumor?.comment, disabled}, []),
            }, this.validateFindings("isPrimaryOrMetastaticTumor")),
            extensiveSubchondralCysts: new FormGroup({ // ExtensiveSubchondralCysts FormGroup
              isExtensiveSubchondralCysts: new FormControl({value: v.scoring?.findings?.extensiveSubchondralCysts?.isExtensiveSubchondralCysts, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.extensiveSubchondralCysts?.isRight, disabled}),
              right: new FormControl({value: v.scoring?.findings?.extensiveSubchondralCysts?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.extensiveSubchondralCysts?.isLeft, disabled}),
              left: new FormControl({value: v.scoring?.findings?.extensiveSubchondralCysts?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.extensiveSubchondralCysts?.comment, disabled}, []),
            }, this.validateFindings("isExtensiveSubchondralCysts")),
            recentFracture: new FormGroup({ // RecentFracture FormGroup
              isRecentFracture: new FormControl({value: v.scoring?.findings?.recentFracture?.isRecentFracture, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.recentFracture?.isRight, disabled}),
              right: new FormControl({value: v.scoring?.findings?.recentFracture?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.recentFracture?.isLeft, disabled}),
              left: new FormControl({value: v.scoring?.findings?.recentFracture?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.recentFracture?.comment, disabled}, []),
            }, this.validateFindings("isRecentFracture")),
            excessiveMalalignmentOfTheKnee: new FormGroup({ // ExcessiveMalalignmentOfTheKnee FormGroup
              isExcessiveMalalignmentOfTheKnee: new FormControl({value: v.scoring?.findings?.excessiveMalalignmentOfTheKnee?.isExcessiveMalalignmentOfTheKnee, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.excessiveMalalignmentOfTheKnee?.isRight, disabled}),
              right: new FormControl({value: v.scoring?.findings?.excessiveMalalignmentOfTheKnee?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.excessiveMalalignmentOfTheKnee?.isLeft, disabled}),
              left: new FormControl({value: v.scoring?.findings?.excessiveMalalignmentOfTheKnee?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.excessiveMalalignmentOfTheKnee?.comment, disabled}, []),
            }, this.validateFindings("isExcessiveMalalignmentOfTheKnee")),
            pesudogout: new FormGroup({ // Pesudogout FormGroup
              isPesudogout: new FormControl({value: v.scoring?.findings?.pesudogout?.isPesudogout, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.pesudogout?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.pesudogout?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.pesudogout?.comment, disabled}, []),
            }, this.validateFindings("isPesudogout")),
            inflammatoryJointDisease: new FormGroup({ // InflammatoryJointDisease FormGroup
              isInflammatoryJointDisease: new FormControl({value: v.scoring?.findings?.inflammatoryJointDisease?.isInflammatoryJointDisease, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.inflammatoryJointDisease?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.inflammatoryJointDisease?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.inflammatoryJointDisease?.comment, disabled}, []),
            }, this.validateFindings("isInflammatoryJointDisease")),
            jointInfection: new FormGroup({ // JointInfection FormGroup
              isJointInfection: new FormControl({value: v.scoring?.findings?.jointInfection?.isJointInfection, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.jointInfection?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.jointInfection?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.jointInfection?.comment, disabled}, []),
            }, this.validateFindings("jointInfection")),
            pageatsDisease: new FormGroup({ // PageatsDisease FormGroup
              isPageatsDisease: new FormControl({value: v.scoring?.findings?.pageatsDisease?.isPageatsDisease, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.pageatsDisease?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.pageatsDisease?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.pageatsDisease?.comment, disabled}, []),
            }, this.validateFindings("isPageatsDisease")),
            atrophicoa: new FormGroup({ // Atrophicoa FormGroup
              isAtrophicoa: new FormControl({value: v.scoring?.findings?.atrophicoa?.isAtrophicoa, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.atrophicoa?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.atrophicoa?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.atrophicoa?.comment, disabled}, []),
            }, this.validateFindings("isAtrophicoa")),
            stressFracture: new FormGroup({ // StressFracture FormGroup
              isStressFracture: new FormControl({value: v.scoring?.findings?.stressFracture?.isStressFracture, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.stressFracture?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.stressFracture?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.stressFracture?.comment, disabled}, []),
            }, this.validateFindings("isStressFracture")),
            osteochondritisDissecans: new FormGroup({ // OsteochondritisDissecans FormGroup
              isOsteochondritisDissecans: new FormControl({value: v.scoring?.findings?.osteochondritisDissecans?.isOsteochondritisDissecans, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.osteochondritisDissecans?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.osteochondritisDissecans?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.osteochondritisDissecans?.comment, disabled}, []),
            }, this.validateFindings("isOsteochondritisDissecans")),
            rpoaType1: new FormGroup({ // Rapid Progressive OA (RPOA) Type 1 FormGroup
              isRpoaType1: new FormControl({value: v.scoring?.findings?.rpoaType1?.isRpoaType1, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.rpoaType1?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.rpoaType1?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.rpoaType1?.comment, disabled}, []),
            }, this.validateFindings("isRpoaType1")),
            rpoaType2: new FormGroup({ // Rapid Progressive OA (RPOA) Type 1 FormGroup
              isRpoaType2: new FormControl({value: v.scoring?.findings?.rpoaType2?.isRpoaType2, disabled}),
              isRight: new FormControl({value: v.scoring?.findings?.rpoaType2?.isRight, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.rpoaType2?.isLeft, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.rpoaType2?.comment, disabled}, []),
            }, this.validateFindings("isRpoaType2")),
            other: new FormGroup({ // Other FormGroup
              isOther: new FormControl({value: v.scoring?.findings?.other?.isOther, disabled}),
              other: new FormControl({value: v.scoring?.findings?.other?.other, disabled}, v.scoring?.findings?.other?.isOther ?
                [Validators.required, Validators.maxLength(500)] : [Validators.maxLength(500)]),
              isRight: new FormControl({value: v.scoring?.findings?.other?.isRight, disabled}),
              //right: new FormControl({value: v.scoring?.findings?.other?.right, disabled}),
              isLeft: new FormControl({value: v.scoring?.findings?.other?.isLeft, disabled}),
              //left: new FormControl({value: v.scoring?.findings?.other?.left, disabled}),
              comment: new FormControl({value: v.scoring?.findings?.other?.comment, disabled}, []),
            }, this.validateFindings("isOther")),
          }),
          otherFindings: new FormGroup({ // Findings FormGroup
            otherFindingsComments: new FormControl({value: v.scoring?.otherFindings?.otherFindingsComments, disabled}),
          }),
          comment: new FormGroup({ // Findings FormGroup
            comments: new FormControl({value: v.scoring?.comment?.comments, disabled}),
          })
          // TODO: add controlss initialization here
        }),
      });

      (<FormArray>this.visitsArrayForm).push(visitFormControl);
      if (!disabled){
        this.addChangeListeners(visitFormControl);
        this.addAdditionalChangeListeners(visitFormControl);

        visitFormControl.valueChanges.subscribe(() => {
          this.checkValidity(visitFormControl);
        });
      }
    });

    this.readingForm.addControl('visits', this.visitsArrayForm);
    this.checkValidity(this.readingForm);
  }

  addAdditionalChangeListeners(visitFormControl: any) {
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.subchondralInsufficiencyFracture') as FormGroup, 'isSubchondralInsufficiencyFracture');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.significantArticularBoneLoss') as FormGroup, 'isSignificantArticularBoneLoss');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.articularBoneFragmentationOrCollapse') as FormGroup, 'isArticularBoneFragmentationOrCollapse');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.osteonecrosis') as FormGroup, 'isOsteonecrosis');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.pathologicFracture') as FormGroup, 'isPathologicFracture');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.primaryOrMetastaticTumor') as FormGroup, 'isPrimaryOrMetastaticTumor');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.extensiveSubchondralCysts') as FormGroup, 'isExtensiveSubchondralCysts');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.recentFracture') as FormGroup, 'isRecentFracture');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.excessiveMalalignmentOfTheKnee') as FormGroup, 'isExcessiveMalalignmentOfTheKnee');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.pesudogout') as FormGroup, 'isPesudogout');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.inflammatoryJointDisease') as FormGroup, 'isInflammatoryJointDisease');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.jointInfection') as FormGroup, 'isJointInfection');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.pageatsDisease') as FormGroup, 'isPageatsDisease');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.atrophicoa') as FormGroup, 'isAtrophicoa');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.stressFracture') as FormGroup, 'isStressFracture');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.osteochondritisDissecans') as FormGroup, 'isOsteochondritisDissecans');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.rpoaType1') as FormGroup, 'isRpoaType1');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.rpoaType2') as FormGroup, 'isRpoaType2');
    this.addConditionalEnabling(visitFormControl.get('scoring.findings.other') as FormGroup, 'isOther');

    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.subchondralInsufficiencyFracture') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.significantArticularBoneLoss') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.articularBoneFragmentationOrCollapse') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.osteonecrosis') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.pathologicFracture') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.primaryOrMetastaticTumor') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.extensiveSubchondralCysts') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.recentFracture') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.excessiveMalalignmentOfTheKnee') as FormGroup);
    this.addRightLeftEnabling(visitFormControl.get('scoring.findings.other') as FormGroup);
  }

  getControlByVisit(visitIndex, name) {
    return (<FormControl>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]).get(name));
  }

  isReadonlyVisit(visitIndex) {
    const visit = this.currentReading.visits[visitIndex];
    return visit.status !== 'IN_PROGRESS' && visit.status !== 'NEW_EDITABLE';
  }

  imageQualityChange(visitFormIndex, event) {
    const control = this.getControlByVisit(visitFormIndex, 'scoring.imageQualityComment');

    control.setValidators(event.source.value === 'Suboptimal' || event.value === 'Poor'
      ? [Validators.required, Validators.maxLength(500)] : [Validators.maxLength(500)]);
    control.updateValueAndValidity();

    this.previousImageQuality = event.value;
  }


  getEndpointName(): string {
    return 'MEPO';
  }

  chooseActiveVisitByStatus() {
    const activeVisitIndex = this.currentReading.visits.findIndex(visit => visit.status === VisitStatus.IN_PROGRESS);
    if (activeVisitIndex !== -1) {
      this.onChangeActivatedVisit(activeVisitIndex);
    }
  }

  onChangeActivatedVisit(index) {
    this.selectedVisit = index;
    this.selectedTab = 0;
    this.activedVisitSubject.next(this.currentReading.visits[index]);
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingService.getAvailableReadings(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(readingId);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number; }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    this.updateReadingNavigator(this.currentReading);
    const readingData = {
      spentSeconds: data.spentSeconds,
    };
    return this.readingService.updateReading(readingId, readingData);
  }

  updateReadingNavigator(reading: any) {
    if (!this.readingListUpdatedSubject.closed) {
      this.readingListUpdatedSubject.next(reading);
    }
  }

  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.currentReading.studyId,
        patientId: this.currentReading.patientId,
      }
    });
    return dialogRef.afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          if (!dialogResponse?.jwt) {
            if (!dialogResponse?.canceled) {
              this.toastOptions.title = 'ERROR: Sign Report Failed.<br/>Please enter valid credentials';
              this.toastService.error(this.toastOptions);
            }

            return of(null);
          }

          return this.readingService.completeReading(this.currentReading.id, data, dialogResponse.jwt);
        })
      );
  }

  private checkValidity(formControl) {
    this.switchSubmitBtnDisabledSubject.next(formControl.invalid);
  }

  isImageQualityValid(visitIndex, scoring) {
    scoring = scoring ?? this.buildDatasObject(visitIndex, VisitStatus.IN_PROGRESS).scoring;

    return !!scoring.imageQuality
      && ((scoring.imageQuality !== 'Poor' && scoring.imageQuality !== 'Suboptimal') || !!scoring.imageQualityComment);
  }

  isValidVisit(visitIndex: number) {
    const visitForm = this.visitsArrayForm.at(visitIndex) as FormGroup;
    return this.checkFormValidity(visitForm, true);
  }

  checkFormValidity(formGroup: FormGroup, checkInChilds: boolean): boolean {
    const keys = Object.keys(formGroup.controls);
    return keys.filter(key => {
      const control = formGroup.get(key);
      // in child controls of findings form group, we need to check using custom form validator
      var checkInSubs = checkInChilds && key != 'findings';
      if (control instanceof FormGroup && checkInSubs) {
        const isValid = this.checkFormValidity(control, true);
        console.log('Validate group:', key, isValid);
        return isValid; // Recursively check validity for nested form groups
      } else {
        //console.log('Validate;', key, control.valid);
        return control.valid || control.disabled;
      }
    }).length == keys.length;
  }

  submitVisit(visitIndex: number) {
    const model = this.buildDatasObject(visitIndex, VisitStatus.DONE);
    const visit = this.currentReading.visits[visitIndex];

    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.currentReading.studyId,
        patientId: this.currentReading.patientId,
        visitConfigId: visit.visitConfigId,
      }
    });

    return dialogRef.afterClosed().subscribe(dialogResponse => {
        if (!dialogResponse?.jwt) {
          if (!dialogResponse?.canceled) {
            this.toastOptions.title = 'ERROR: Sign Report Failed.<br/>Please enter valid credentials';
            this.toastService.error(this.toastOptions);
          }
          return of(null);
        }
        return this.readingService.lockTimepoint(this.currentReading.studyId, visit.id, model, dialogResponse.jwt).subscribe(() => {
          this.readingService.getReading(this.currentReading.studyId, this.currentReading.readerId, this.currentReading.id).subscribe((data) => {
            if (data.responseCode === 200) {
              this.readingForm = new FormGroup({});
              this.visitsArrayForm = new FormArray([]);

              this.currentReading = data.data;
              this.initReadingForm();

              this.toastOptions.title = 'SUCCESS: Timepoint is successfully saved and locked';
              this.toastService.success(this.toastOptions);

              const nextVisit = this.currentReading.visits.find(v => v.visitOrder > visit.visitOrder && !v.noUpload && v.status !== VisitStatus.NOT_AVAILABLE);
              if (nextVisit) {
                this.chooseActiveVisitByStatus();
              } else {
                // we need to reload reading to allow reader to lock the reading
                this.updateReadingListAfterAllVisitDone(this.currentReading);
              }
            } else {
              this.toastOptions.title = 'ERROR: Can not reload reading';
              this.toastService.error(this.toastOptions);
            }
          }, (error => {
            this.toastOptions.title = 'ERROR: ' + error.message;
            this.toastService.error(this.toastOptions);
          }));
        }, (error => {
          this.toastOptions.title = 'ERROR: ' + error.message;
          this.toastService.error(this.toastOptions);
        }));
      });
  }

  saveScoring(visitIndex: number) {
    const model = this.buildDatasObject(visitIndex, VisitStatus.IN_PROGRESS);
    const visit = this.currentReading.visits[visitIndex];
    return this.readingService.updateTimepoint(this.currentReading.studyId, visit.id, model).subscribe(() => {
      visit.scoring = model.scoring;

      // after save we need to repopulate that strange collections
      this.toastOptions.title = 'SUCCESS: Timepoint is successfully saved';
      this.toastService.success(this.toastOptions);
      this.formChanged = false;
    }, (error => {
      this.toastOptions.title = 'ERROR: ' + error.message;
      this.toastService.error(this.toastOptions);
    }));
  }

  addChangeListeners(formGroup: FormGroup): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.get(key);
      if (control instanceof FormGroup) {
        this.addChangeListeners(control); // Recursively add listeners to nested form groups
      } else {
        control.valueChanges.subscribe(() => {
          this.formChanged = true;
        });
      }
    });
  }

  updateReadingListAfterAllVisitDone(reading) {
    if (!this.readingListUpdatedSubject.closed) {
      this.readingListUpdatedSubject.next(reading);
    }
  }

  buildScoring(visitIndex: number, scoreControls: any) {
    const visit = this.currentReading.visits[visitIndex];

    return {
      //id: scoreControls.id.value,
      imageQuality: scoreControls.imageQuality.value,
      imageQualityComment: scoreControls.imageQualityComment.value,
      imageQualityCommentOthers: scoreControls.imageQualityCommentOthers.value,
      kneeMalalignment: visit.baseline ? {
        isRight: scoreControls.kneeMalalignment.get('isRight').value,
        right: scoreControls.kneeMalalignment.get('right').value,
        rightKneeAngulation: scoreControls.kneeMalalignment.get('rightKneeAngulation').value,
        isLeft: scoreControls.kneeMalalignment.get('isLeft').value,
        left: scoreControls.kneeMalalignment.get('left').value,
        leftKneeAngulation: scoreControls.kneeMalalignment.get('leftKneeAngulation').value,
      } : {},
      kAndL: {
        rightKnee: scoreControls.kAndL.get('rightKnee').value,
        leftKnee: scoreControls.kAndL.get('leftKnee').value,
      },
      oarsi: {
        jointSpaceNarrowing: {
          right: {
            lateral: scoreControls.oarsi.get('jointSpaceNarrowing').get('right').get('lateral').value,
            medial: scoreControls.oarsi.get('jointSpaceNarrowing').get('right').get('medial').value,
          },
          left: {
            lateral: scoreControls.oarsi.get('jointSpaceNarrowing').get('left').get('lateral').value,
            medial: scoreControls.oarsi.get('jointSpaceNarrowing').get('left').get('medial').value,
          },
        },
        osteophytosisFemur: {
          right: {
            lateral: scoreControls.oarsi.get('osteophytosisFemur').get('right').get('lateral').value,
            medial: scoreControls.oarsi.get('osteophytosisFemur').get('right').get('medial').value,
          },
          left: {
            lateral: scoreControls.oarsi.get('osteophytosisFemur').get('left').get('lateral').value,
            medial: scoreControls.oarsi.get('osteophytosisFemur').get('left').get('medial').value,
          },
        },
        osteophytosisTibia: {
          right: {
            lateral: scoreControls.oarsi.get('osteophytosisTibia').get('right').get('lateral').value,
            medial: scoreControls.oarsi.get('osteophytosisTibia').get('right').get('medial').value,
          },
          left: {
            lateral: scoreControls.oarsi.get('osteophytosisTibia').get('left').get('lateral').value,
            medial: scoreControls.oarsi.get('osteophytosisTibia').get('left').get('medial').value,
          },
        },
        sclerosis: {
          right: scoreControls.oarsi.get('sclerosis').get('right').value,
          left: scoreControls.oarsi.get('sclerosis').get('left').value,
        },
        attrition: {
          right: scoreControls.oarsi.get('attrition').get('right').value,
          left: scoreControls.oarsi.get('attrition').get('left').value,
        },
      },
      findings: {
        subchondralInsufficiencyFracture: {
          isSubchondralInsufficiencyFracture: scoreControls.findings.get('subchondralInsufficiencyFracture').get('isSubchondralInsufficiencyFracture').value,
          isRight: scoreControls.findings.get('subchondralInsufficiencyFracture').get('isRight').value,
          right: scoreControls.findings.get('subchondralInsufficiencyFracture').get('right').value,
          isLeft: scoreControls.findings.get('subchondralInsufficiencyFracture').get('isLeft').value,
          left: scoreControls.findings.get('subchondralInsufficiencyFracture').get('left').value,
          comment: scoreControls.findings.get('subchondralInsufficiencyFracture').get('comment').value,
        },
        significantArticularBoneLoss: {
          isSignificantArticularBoneLoss: scoreControls.findings.get('significantArticularBoneLoss').get('isSignificantArticularBoneLoss').value,
          isRight: scoreControls.findings.get('significantArticularBoneLoss').get('isRight').value,
          right: scoreControls.findings.get('significantArticularBoneLoss').get('right').value,
          isLeft: scoreControls.findings.get('significantArticularBoneLoss').get('isLeft').value,
          left: scoreControls.findings.get('significantArticularBoneLoss').get('left').value,
          comment: scoreControls.findings.get('significantArticularBoneLoss').get('comment').value,
        },
        articularBoneFragmentationOrCollapse: {
          isArticularBoneFragmentationOrCollapse: scoreControls.findings.get('articularBoneFragmentationOrCollapse').get('isArticularBoneFragmentationOrCollapse').value,
          isRight: scoreControls.findings.get('articularBoneFragmentationOrCollapse').get('isRight').value,
          right: scoreControls.findings.get('articularBoneFragmentationOrCollapse').get('right').value,
          isLeft: scoreControls.findings.get('articularBoneFragmentationOrCollapse').get('isLeft').value,
          left: scoreControls.findings.get('articularBoneFragmentationOrCollapse').get('left').value,
          comment: scoreControls.findings.get('articularBoneFragmentationOrCollapse').get('comment').value,
        },
        osteonecrosis: {
          isOsteonecrosis: scoreControls.findings.get('osteonecrosis').get('isOsteonecrosis').value,
          isRight: scoreControls.findings.get('osteonecrosis').get('isRight').value,
          right: scoreControls.findings.get('osteonecrosis').get('right').value,
          isLeft: scoreControls.findings.get('osteonecrosis').get('isLeft').value,
          left: scoreControls.findings.get('osteonecrosis').get('left').value,
          comment: scoreControls.findings.get('osteonecrosis').get('comment').value,
        },
        pathologicFracture: {
          isPathologicFracture: scoreControls.findings.get('pathologicFracture').get('isPathologicFracture').value,
          isRight: scoreControls.findings.get('pathologicFracture').get('isRight').value,
          right: scoreControls.findings.get('pathologicFracture').get('right').value,
          isLeft: scoreControls.findings.get('pathologicFracture').get('isLeft').value,
          left: scoreControls.findings.get('pathologicFracture').get('left').value,
          comment: scoreControls.findings.get('pathologicFracture').get('comment').value,
        },
        primaryOrMetastaticTumor: {
          isPrimaryOrMetastaticTumor: scoreControls.findings.get('primaryOrMetastaticTumor').get('isPrimaryOrMetastaticTumor').value,
          isRight: scoreControls.findings.get('primaryOrMetastaticTumor').get('isRight').value,
          right: scoreControls.findings.get('primaryOrMetastaticTumor').get('right').value,
          isLeft: scoreControls.findings.get('primaryOrMetastaticTumor').get('isLeft').value,
          left: scoreControls.findings.get('primaryOrMetastaticTumor').get('left').value,
          comment: scoreControls.findings.get('primaryOrMetastaticTumor').get('comment').value,
        },
        extensiveSubchondralCysts: {
          isExtensiveSubchondralCysts: scoreControls.findings.get('extensiveSubchondralCysts').get('isExtensiveSubchondralCysts').value,
          isRight: scoreControls.findings.get('extensiveSubchondralCysts').get('isRight').value,
          right: scoreControls.findings.get('extensiveSubchondralCysts').get('right').value,
          isLeft: scoreControls.findings.get('extensiveSubchondralCysts').get('isLeft').value,
          left: scoreControls.findings.get('extensiveSubchondralCysts').get('left').value,
          comment: scoreControls.findings.get('extensiveSubchondralCysts').get('comment').value,
        },
        recentFracture: {
          isRecentFracture: scoreControls.findings.get('recentFracture').get('isRecentFracture').value,
          isRight: scoreControls.findings.get('recentFracture').get('isRight').value,
          right: scoreControls.findings.get('recentFracture').get('right').value,
          isLeft: scoreControls.findings.get('recentFracture').get('isLeft').value,
          left: scoreControls.findings.get('recentFracture').get('left').value,
          comment: scoreControls.findings.get('recentFracture').get('comment').value,
        },
        excessiveMalalignmentOfTheKnee: {
          isExcessiveMalalignmentOfTheKnee: scoreControls.findings.get('excessiveMalalignmentOfTheKnee').get('isExcessiveMalalignmentOfTheKnee').value,
          isRight: scoreControls.findings.get('excessiveMalalignmentOfTheKnee').get('isRight').value,
          right: scoreControls.findings.get('excessiveMalalignmentOfTheKnee').get('right').value,
          isLeft: scoreControls.findings.get('excessiveMalalignmentOfTheKnee').get('isLeft').value,
          left: scoreControls.findings.get('excessiveMalalignmentOfTheKnee').get('left').value,
          comment: scoreControls.findings.get('excessiveMalalignmentOfTheKnee').get('comment').value,
        },
        pesudogout: {
          isPesudogout: scoreControls.findings.get('pesudogout').get('isPesudogout').value,
          isRight: scoreControls.findings.get('pesudogout').get('isRight').value,
          isLeft: scoreControls.findings.get('pesudogout').get('isLeft').value,
          comment: scoreControls.findings.get('pesudogout').get('comment').value,
        },
        inflammatoryJointDisease: {
          isInflammatoryJointDisease: scoreControls.findings.get('inflammatoryJointDisease').get('isInflammatoryJointDisease').value,
          isRight: scoreControls.findings.get('inflammatoryJointDisease').get('isRight').value,
          isLeft: scoreControls.findings.get('inflammatoryJointDisease').get('isLeft').value,
          comment: scoreControls.findings.get('inflammatoryJointDisease').get('comment').value,
        },
        jointInfection: {
          isJointInfection: scoreControls.findings.get('jointInfection').get('isJointInfection').value,
          isRight: scoreControls.findings.get('jointInfection').get('isRight').value,
          isLeft: scoreControls.findings.get('jointInfection').get('isLeft').value,
          comment: scoreControls.findings.get('jointInfection').get('comment').value,
        },
        pageatsDisease: {
          isPageatsDisease: scoreControls.findings.get('pageatsDisease').get('isPageatsDisease').value,
          isRight: scoreControls.findings.get('pageatsDisease').get('isRight').value,
          isLeft: scoreControls.findings.get('pageatsDisease').get('isLeft').value,
          comment: scoreControls.findings.get('pageatsDisease').get('comment').value,
        },
        atrophicoa: {
          isAtrophicoa: scoreControls.findings.get('atrophicoa').get('isAtrophicoa').value,
          isRight: scoreControls.findings.get('atrophicoa').get('isRight').value,
          isLeft: scoreControls.findings.get('atrophicoa').get('isLeft').value,
          comment: scoreControls.findings.get('atrophicoa').get('comment').value,
        },
        stressFracture: {
          isStressFracture: scoreControls.findings.get('stressFracture').get('isStressFracture').value,
          isRight: scoreControls.findings.get('stressFracture').get('isRight').value,
          isLeft: scoreControls.findings.get('stressFracture').get('isLeft').value,
          comment: scoreControls.findings.get('stressFracture').get('comment').value,
        },
        osteochondritisDissecans: {
          isOsteochondritisDissecans: scoreControls.findings.get('osteochondritisDissecans').get('isOsteochondritisDissecans').value,
          isRight: scoreControls.findings.get('osteochondritisDissecans').get('isRight').value,
          isLeft: scoreControls.findings.get('osteochondritisDissecans').get('isLeft').value,
          comment: scoreControls.findings.get('osteochondritisDissecans').get('comment').value,
        },
        rpoaType1: {
          isRpoaType1: scoreControls.findings.get('rpoaType1').get('isRpoaType1').value,
          isRight: scoreControls.findings.get('rpoaType1').get('isRight').value,
          isLeft: scoreControls.findings.get('rpoaType1').get('isLeft').value,
          comment: scoreControls.findings.get('rpoaType1').get('comment').value,
        },
        rpoaType2: {
          isRpoaType2: scoreControls.findings.get('rpoaType2').get('isRpoaType2').value,
          isRight: scoreControls.findings.get('rpoaType2').get('isRight').value,
          isLeft: scoreControls.findings.get('rpoaType2').get('isLeft').value,
          comment: scoreControls.findings.get('rpoaType2').get('comment').value,
        },
        other: {
          isOther: scoreControls.findings.get('other').get('isOther').value,
          other: scoreControls.findings.get('other').get('other').value,
          isRight: scoreControls.findings.get('other').get('isRight').value,
          isLeft: scoreControls.findings.get('other').get('isLeft').value,
          comment: scoreControls.findings.get('other').get('comment').value,
        },
      },
      otherFindings: {
        otherFindingsComments: scoreControls.otherFindings.get('otherFindingsComments').value,
      },
      comment: {
        comments: scoreControls.comment.get('comments').value,
      },
      //visit: visit.id
    };
  }

  buildDatasObject(visitIndex: number, status: string): any {

    const controls = this.getVisitControls(visitIndex);
    const scoreControls = (<FormGroup>controls.scoring).controls;
    const data = {
      id: controls.id.value,
      visitConfigId: controls.visitConfigId.value,
      status: status,
      doneVisit: status === VisitStatus.DONE,
      visitOrder: controls.visitOrder.value,

      scoring: this.buildScoring(visitIndex, scoreControls),
    };

    return data;
  }

  getVisitControls(visitIndex: number) {
    return (<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex])).controls;
  }

  toTitleCase(str) {
    return str?.toLowerCase().split('_').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ') ?? '';
  }

  onImageQualityChange(value: string, visitIndex: any) {
    var control = this.getVisitControls(visitIndex).scoring.get('imageQualityComment');
    if (control) {
      if (value === 'Optimal') {
        control.clearValidators();
        control.setValue('NA');
      } else {
        control.setValidators(Validators.required);
        control.setValue(null);
      }
      control.updateValueAndValidity();
      this.onImageQualityCommentChange(null,visitIndex);
    }
  }
  onImageQualityCommentChange(value: string, visitIndex: any) {
    var commentControl = this.getVisitControls(visitIndex).scoring.get('imageQualityCommentOthers');
    if (commentControl) {
      if (value === 'Other') {
        commentControl.setValidators(Validators.required);
      } else {
        commentControl.clearValidators();
      }
      commentControl.updateValueAndValidity();
    }
  }

  addConditionalEnabling(formGroup: FormGroup, checkboxName: string): void {

    // remove validation for isLeft, isRight if one of them checked
    /*var rightCheckbox = formGroup.get('isRight');
    var leftCheckbox = formGroup.get('isLeft');
    rightCheckbox.valueChanges.subscribe((checked: boolean) => {
      this.updateOppositeControlRequiring(checked, leftCheckbox);
    });
    leftCheckbox.valueChanges.subscribe((checked: boolean) => {
      this.updateOppositeControlRequiring(checked, rightCheckbox);
    });*/

    formGroup.get(checkboxName).valueChanges.subscribe((checked: boolean) => {
      this.setFindingControlsVisibility(formGroup, checked)
    });

    this.setFindingControlsVisibility(formGroup, formGroup.get(checkboxName).value);
  }

  updateOppositeControlValidators(checked: boolean, visitIndex: number, controlName: string) {
    var control = this.getControlByVisit(visitIndex, controlName);
    if (control) {
      if (checked) {
        control.clearValidators();      
      } else {
        control.setValidators(Validators.requiredTrue);
      }
      control.updateValueAndValidity();
    }
  }

  validateFindings(name: string) {

    const validator: ValidatorFn = (formGroup: FormGroup) => {
      // main checkbox checked
      var valid = true;

      var mainCheckbox = formGroup.get(name);
      var isRightCheckbox = formGroup.get('isRight');
      var isLeftCheckbox = formGroup.get('isLeft');
      var rightControl = formGroup.get('right');
      var leftControl = formGroup.get('left');
      var otherControl = formGroup.get('other');

      if (mainCheckbox?.value) {

        valid = valid && (!!isRightCheckbox?.value || !!isLeftCheckbox?.value);

        valid = valid && (!isRightCheckbox?.value || !rightControl || !!rightControl.value);
        valid = valid && (!isLeftCheckbox?.value || !leftControl || !!leftControl.value);
        valid = valid && (!otherControl || !!otherControl.value);
      }
      
      return valid ? null : { required: true };
    };
  
    return validator;
  }

  setFindingControlsVisibility(formGroup: FormGroup, checked: boolean): void {

      const controlsToToggle = ['isRight', 'right', 'isLeft', 'left', 'comment', 'other'];
      const controlsToValidate = ['right', 'left', 'other'];
      const controlsToCheckboxTrue = ['isRight', 'isLeft' ];
      controlsToToggle.forEach(controlName => {
        const control = formGroup.get(controlName);
        if (control) {
          if (checked) {
            control.enable();
            if (controlsToValidate.find(f => f === controlName)) {
              //control.setValidators(Validators.required);
            }
            if (controlsToCheckboxTrue.find(f => f === controlName)) {
              //control.setValidators(Validators.requiredTrue);
            }
          } else {
            control.disable();
            if (controlsToValidate.find(f => f === controlName)) {
              //control.clearValidators();
            }
            if (controlsToCheckboxTrue.find(f => f === controlName)) {
              //control.clearValidators();
            }
            control.setValue(null);
          }
          control.updateValueAndValidity();
        }
      });
      this.setFingingControlEnabled(formGroup, 'right', formGroup.get('isRight').value);
      this.setFingingControlEnabled(formGroup, 'left', formGroup.get('isLeft').value);
  }

  setFingingControlEnabled(formGroup: FormGroup, name: string, checked: boolean) {
    const control = formGroup.get(name);
    if (control) {
      if (checked) {
        control.enable();
        //control.setValidators(Validators.required);
      } else {
        control.disable();
        //control.clearValidators();
      }
      control.updateValueAndValidity();
    }
  }

  addRightLeftEnabling(formGroup: FormGroup): void {
    formGroup.get('isRight').valueChanges.subscribe((checked: boolean) => {
      this.setFingingControlEnabled(formGroup, 'right', checked);
    });

    formGroup.get('isLeft').valueChanges.subscribe((checked: boolean) => {
      this.setFingingControlEnabled(formGroup, 'left', checked);
    });

    this.setFingingControlEnabled(formGroup, 'right', formGroup.get('isRight').value);
    this.setFingingControlEnabled(formGroup, 'left', formGroup.get('isLeft').value);
  }

  onKneeMalalignmentChange(event, label: string, visitIndex: number) {
    const visitForm = this.visitsArrayForm.at(visitIndex) as FormGroup;
    const kneeMalalignmentGroup = visitForm.get('scoring.kneeMalalignment') as FormGroup;

    if (kneeMalalignmentGroup) {
      if (label == 'isLeft') {
        const left = kneeMalalignmentGroup.get('left');
        const leftKneeAngulation = kneeMalalignmentGroup.get('leftKneeAngulation');
        if (event.value == 'Yes') {
          left?.enable();
          left?.setValidators([Validators.required, Validators.min(1)])
          leftKneeAngulation?.enable();
          leftKneeAngulation?.setValidators(Validators.required);
        } else {
          left.disable();
          left?.clearValidators();
          left?.setValue(null);
          leftKneeAngulation?.disable();
          leftKneeAngulation?.clearValidators();
          leftKneeAngulation?.setValue(null);
        }
        left?.updateValueAndValidity();
        leftKneeAngulation?.updateValueAndValidity();
      }
      if (label == 'isRight') {
        const right = kneeMalalignmentGroup.get('right');
        const rightKneeAngulation = kneeMalalignmentGroup.get('rightKneeAngulation');
        if (event.value == 'Yes') {
          right?.enable();
          right?.setValidators([Validators.required, Validators.min(1)])
          rightKneeAngulation?.enable();
          rightKneeAngulation?.setValidators(Validators.required);
        } else {
          right?.disable();
          right?.clearValidators()
          right?.setValue(null);
          rightKneeAngulation?.disable();
          rightKneeAngulation?.clearValidators();
          rightKneeAngulation?.setValue(null);
        }
        right?.updateValueAndValidity();
        rightKneeAngulation?.updateValueAndValidity();
      }
    }
  }

  clearForm(): void {
  }
}
