import {Component, HostBinding, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-reading-form-block',
  templateUrl: './reading-form-block.component.html',
  styleUrls: ['./reading-form-block.component.css']
})
export class ReadingFormBlockComponent implements OnInit {

  readonly MAX_WIDTH = '475px';

  @Input() title: string;
  @Input() tooltip: string;
  @Input() fullWidth = false;

  hasTooltip: boolean;

  @HostBinding('style.max-width')
  maxWidth = this.MAX_WIDTH;

  constructor() {
  }

  ngOnInit(): void {
    this.hasTooltip = !!this.tooltip && this.tooltip !== '';
    this.maxWidth = this.fullWidth ? 'none' : this.MAX_WIDTH;
  }
}
