import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Demriq_Regions } from 'src/app/_models/DEMRIQ/demriq-regions.model';


export interface fingerJointData{
  visitName:string,
  details: { region: string, location: string, rois: number}[],
  availableRegionsToScore: any[],
  primaryLocations: any[],
  title: string
}

@Component({
  selector: 'app-demriq-region-location-details-dialog',
  templateUrl: './demriq-region-location-details-dialog.component.html',
  styleUrls: ['./demriq-region-location-details-dialog.component.css']
})
export class DemriqRegionLocationDetailsDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<DemriqRegionLocationDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: fingerJointData) { }
  ngOnInit(): void {
  }
  closeClick(){
    this.dialogRef.close();
  }
  getNumberOfROIByRegionAndLocation(region: string, location: string): number{
    const regionLocation = this.data.details.find(d => d.region.toLowerCase() === region.toLowerCase()
      && d.location.toLowerCase() === location.toLowerCase());
    return regionLocation ? regionLocation.rois : 0;
  }
}
