export class AnnKmaObject extends lt.Annotations.Engine.AnnPolylineObject {
  public static kmaObjectId = -200;

  constructor() {
    super();
    this.setId(AnnKmaObject.kmaObjectId); // Set the object id

    this.tag = null;

    this.labels['Angle'] = new lt.Annotations.Engine.AnnLabel();
    const femurLineLabel = new lt.Annotations.Engine.AnnLabel();
    femurLineLabel.text = 'Femur Line';
    this.labels['FemurLine'] = femurLineLabel;

    const tibiaLineLabel = new lt.Annotations.Engine.AnnLabel();
    tibiaLineLabel.text = 'Tibia Line';
    this.labels['TibiaLine'] = tibiaLineLabel;
  }

  create(): AnnKmaObject {
    return new AnnKmaObject();
  }

  public get_friendlyName(): string {
    return 'Kma';
  }


  get intersection() {
    const x1 = this.points.item(0).x, y1 = this.points.item(0).y;
    const x2 = this.points.item(1).x, y2 = this.points.item(1).y;
    const x3 = this.points.item(2).x, y3 = this.points.item(2).y;
    const x4 = this.points.item(3).x, y4 = this.points.item(3).y;

    const dx1 = x2 - x1;
    const dy1 = y2 - y1;
    const dx2 = x4 - x3;
    const dy2 = y4 - y3;

    const det = dx1 * dy2 - dy1 * dx2;

    if (det === 0) {
      // Lines are parallel
      return null;
    }

    const t = ((x3 - x1) * dy2 - (y3 - y1) * dx2) / det;
    const intersectionX = x1 + t * dx1;
    const intersectionY = y1 + t * dy1;

    return lt.LeadPointD.create(intersectionX, intersectionY);
  }

  get angle() {
    // Calculate direction vectors
    const vector1 = {
      x: this.points.item(1).x - this.points.item(0).x,
      y: this.points.item(1).y - this.points.item(0).y
    };

    const vector2 = {
      x: this.points.item(3).x - this.points.item(2).x,
      y: this.points.item(3).y - this.points.item(2).y
    };

    // Calculate the dot product of the vectors
    const dotProduct = vector1.x * vector2.x + vector1.y * vector2.y;

    // Calculate the magnitudes of the vectors
    const magnitude1 = Math.sqrt(vector1.x * vector1.x + vector1.y * vector1.y);
    const magnitude2 = Math.sqrt(vector2.x * vector2.x + vector2.y * vector2.y);

    // Calculate the cosine of the angle
    const cosTheta = dotProduct / (magnitude1 * magnitude2);

    // Calculate the angle in radians
    const angleInRadians = Math.acos(cosTheta);

    return angleInRadians;
  }
}

(<any>window).AnnKmaObject = AnnKmaObject;

