class ReadingStatusCodes {
  private static instance: ReadingStatusCodes;

  private codeDescriptionsMap: Map<number, string>;

  public static getInstance(): ReadingStatusCodes {
    if (!this.instance) {
      this.instance = new ReadingStatusCodes();
    }
    return this.instance;
  }

  private constructor() {
    this.codeDescriptionsMap = new Map<number, string>();
    this.init();
  }

  private init() {
    this.codeDescriptionsMap.set(100, 'NEW READING');
    this.codeDescriptionsMap.set(200, 'READER PENDING');
    this.codeDescriptionsMap.set(300, 'READER ASSIGNED');
    this.codeDescriptionsMap.set(400, 'BATCH PENDING');
    this.codeDescriptionsMap.set(500, 'BATCH ASSIGNED');
    this.codeDescriptionsMap.set(600, 'READING PENDING');
    this.codeDescriptionsMap.set(700, 'IN PROGRESS');
    this.codeDescriptionsMap.set(800, 'ADJUDICATION CHECK PENDING');
    this.codeDescriptionsMap.set(900, 'COMPLETE');
    this.codeDescriptionsMap.set(1000, 'TERMINATED');
  }

  public getDescription(code: number): string {
    let description = this.codeDescriptionsMap.get(code);
    if (!description) {
      description = 'UNDEFINED';
    }
    return description;
  }

  public isInProgress(code: number): boolean {
    return code === 700;
  }

  public isComplete(code: number): boolean {
    return code === 900;
  }

  public isAdjudicationCheckPending(code: number): boolean {
    return code === 800;
  }
}

export const READING_STATUS_CODES = ReadingStatusCodes.getInstance();

