import get from 'lodash/get';

export class Transformation {
  order: number;
  type: string;

  constructor(data?: any) {
    if (data) {
      this._parse(data);
    }
  }

  private _parse(data: any) {
    const order = get(data, 'Order._text', 0);

    this.type = get(data, 'TransformationType._text', null);
    this.order = +order;
  }
}
