import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastOptions } from 'ng2-toasty';
import { AutoCreationBatchConfigDialogComponent } from 'src/app/components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import { ImageViewerConfigDialogComponent } from 'src/app/components/controls/image-viewer-config-dialog/image-viewer-config-dialog.component';
import { FormMode } from 'src/app/core/constants/form-mode';
import { ReadingType } from 'src/app/core/constants/reading-type';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import { AutoBatchConfigPayload } from 'src/app/core/payload/auto-batch-config-payload';
import { BatchConfigModel } from 'src/app/_models/BatchLogic/batch-config-model';
import { ModalityModel } from 'src/app/_models/ImagingProject/modality-model';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import {StudySequenceLabelService, StudyUserService } from 'src/app/_services';
import { defaultBasicAutoBatchConfig } from '../imaging-project-reading-selector/imaging-project-reading-selector.component';
import { BatchLogicService } from 'src/app/_services/batch-logic.service';
import { PSMAPETConfigModel } from 'src/app/_models/ImagingProject/PSMA-PET/psma-pet-config-model';
import { ImagingProjectReadingBasicComponent } from '../../imaging-project-reading-basic.component';

export interface ImagingProjectSubmitEvent {
  result: 'submit' | 'cancel';
  data: any;
  level1BatchConfig?: AutoBatchConfigPayload;
  adjudicationBatchConfig?: AutoBatchConfigPayload;
  basicBatchConfig?: AutoBatchConfigPayload;
}

@Component({
  selector: 'app-imaging-project-reading-psma-pet-eligibility',
  templateUrl: './imaging-project-reading-psma-pet-eligibility.component.html',
  styleUrls: ['./imaging-project-reading-psma-pet-eligibility.component.css']
})
export class ImagingProjectReadingPSMAPETEligibilityComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();
  @Input() public mode:FormMode;
  readonly formModes = FormMode;
  @Input('configModel')
  set configModel(value: any) {
    if (value != null) {
      this._configModel = value;
      if (this._configModel.readingLevel === 'patient') {
        this.modalities = this._configModel.modalities;
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        this.selectedModality =this._configModel.modalities;
      } else {
        this.selectedModality = this._configModel.modalities;
      }
      this.imageViewerConfigurationType = this._configModel.imageViewerConfigurationType;
    }
  }

  @Input() selectedConfig: FlexibleConfig<PSMAPETConfigModel>;
  get configModel(): any {
    return this._configModel;
  }
    get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true && ((!!this.basicBatchConfig && !this.basicBatchConfig?.numberOfReadings) || !this.basicBatchConfig)
        ? {norError: 'Number of readings is required'} : null;
    };
  }

  readingVersionField = new FormControl('', [
    Validators.required
  ]);
  readingLevelField = new FormControl('', [
    Validators.required
  ]);
  modalityField = new FormControl('', [
    Validators.required
  ]);
  readingTypeField = new FormControl('', [
    Validators.required
  ]);
  hideVisitChronologyField = new FormControl('', [
    Validators.required
  ]);
  batchManagementField = new FormControl('', [Validators.required, this.batchManagementValidator]);
  imageViewerConfigurationField = new FormControl('', [
    Validators.required
  ]);
  readersField = new FormControl('', [
    Validators.required
  ]);
  studySequenceLabels: SequenceLabelModel[] = [];
  anatomySelectorControl: FormControl = new FormControl('', [Validators.required]);
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];

  basicBatchConfig:any;

  readingVersion: any;
  readingLevel: string;
  selectedModality: ModalityModel[];
  readingType: string;
  hideVisitChronologyType:string;
  batchManagementType=false;
  imageViewerConfigurationType:any = 'eCRF_Only';
  dataSourceReaders: any;
  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];

  displayedScreenshotOptions: string [] = ['id', 'optionText', 'actionDelete'];
  minReaders =1;
  maxReaders = 20;
  readers: any[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  studyId: number;
  _configModel = {
    endpointReadingVersion: 'basic',
    modalities: [] as ModalityModel[],
    readingLevel: 'visit',
    readingType: 'parallel',
    hideVisitHistory: null,
    readers: [],
    readingVersion: ReadingVersion.PSMA_PET_ELIGIBILITY_BASIC,
    batchManagement: false,
    imageViewerConfigurationType:'eCRF_Only',
    endpoint:null,
    anatomySelector: []
  };


  formValid = false;
  selectedReadersids = [];
  modalities: ModalityModel[];
  selectedReaders = [];
  selectedQuestionTemplate: string;
  visitReadingLevelModality: any = {};


  constructor(private router: Router,
              private route: ActivatedRoute,
              private studySequenceLabelService: StudySequenceLabelService,
              private studyUserService: StudyUserService,
              private flowViewer: MatDialog,
              private dialog: MatDialog,
              private batchLogicService: BatchLogicService) {
    super();
  }

  ngOnInit() {
    this.formValid = false;
    this.selectedReaders = [];

    this._configModel.readers.forEach(ri => {
      this.selectedReadersids.push(ri['id']);
    });
    this.readingVersion ='ReadingVersion-BasicPSMAPET';
    this.readingLevel = 'visit';
    this.readingType='parallel';
    this.hideVisitChronologyType='hide_scan_dates';

    if(this.mode == FormMode.EDIT){
    this.readingVersion = this._configModel.readingVersion
    this.readingLevel = this._configModel.readingLevel;
    this.readingType=this._configModel.readingType;
    this.hideVisitChronologyType = this._configModel.hideVisitHistory;
  }

    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    this.prepareSequenceLabels();

    this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId).subscribe(respgroupedModalitiesResp => {
      if (respgroupedModalitiesResp.data != null) {
           this.modalities = respgroupedModalitiesResp.data;
           this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        if(this.mode==FormMode.EDIT)
        this.selectedModality = respgroupedModalitiesResp.data.filter(x => this._configModel.modalities.find(m => m.name == x.name))
      }

      this.studyUserService.getReadersByStudyId(this.studyId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.readers = response.data;
            const activeReader: any[] = [];
            this.readers.forEach(r => {

              if (this.selectedReadersids.includes(r.id)) {
                activeReader.push(r);
              }
            });

            this._configModel.readers = activeReader;
            this.dataSourceReaders = this._configModel.readers;
            this.selectedReaders = this._configModel.readers;
            this.selectedReadersids = [];
            this._configModel.readers.forEach(r => {
              const cur = this.readers.indexOf(r);
              this.readers.splice(cur, 1);
            });
          }
        });
    });
    if (this.selectedConfig) {
      this.batchManagementType = this.selectedConfig.config.batchManagement;
      this.loadBatchConfigs();
    }
  }
  loadBatchConfigs(): void {
    if (this.mode === FormMode.EDIT) {
      this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id).subscribe(response => {
        const batchConfigs = response.data;
        batchConfigs.forEach(c => {
          this.basicBatchConfig = c;
        });
        this.batchManagementField.updateValueAndValidity()
      });
    }
  }
  prepareSequenceLabels() {
    this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId).subscribe(studySequenceResp => {
      this.studySequenceLabels = studySequenceResp.data;
      if (this.mode === FormMode.EDIT) {
        if (this._configModel.anatomySelector) {
          this.initSequenceLabels(this._configModel.anatomySelector);
        } else {
          this.initDefaultSequenceLabels();
        }
      }
    });
  }

  initDefaultSequenceLabels() {
    this.availableSequenceLabels = this.getAvailableSequenceLabels();
    this.selectedSequenceLabels = this.availableSequenceLabels;
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels();
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  getAvailableSequenceLabels(): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const modality of this._configModel.modalities) {
      for (const label of modality.sequenceLabels) {
        configLabels.add(label);
      }
    }
    console.log(configLabels)
    return this.studySequenceLabels
      .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this.validateForm();
  }

  onModalityTypeChange(event) {
    this.selectedModality = [];
    this._configModel.modalities = [];
    event.value.forEach(element=>{
      this.selectedModality.push(element);
     this._configModel.modalities.push(element);
    })
    this.initDefaultSequenceLabels();
    this.validateForm();
  }

  onBatchManagementSelected(event: MatSelectChange): void {
    if (this.basicBatchConfig) {
        this.basicBatchConfig.numberOfReadings = null;
        this.batchManagementField.updateValueAndValidity();
   }
   this.validateForm();
}

openAutoCreationBatchConfigDialog(config: BatchConfigModel): void {
  let inputConfig = config as AutoBatchConfigPayload;
  const val = this.batchManagementField.value;
  if (!val) {
      inputConfig = {...defaultBasicAutoBatchConfig};
  } else {
      inputConfig = this.basicBatchConfig ? { ...this.basicBatchConfig } : null;
  }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
        width: '500px',
        data: {
            reading: 'PSMA PET Eligibility',
            readingType: ReadingType.BASIC_READING,
            configInput: inputConfig,
            readonlyDialog: !val
        }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
        if (result) {
            this.basicBatchConfig = {...result};
            this.batchManagementField.updateValueAndValidity();
            this.validateForm();
        }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
}

  onReadingLevelChange(event: { value: any; }) {
    this._configModel.readingLevel = event.value;

    if (this._configModel.readingLevel === 'visit') {
      this.setVisitModalityText(this.modalities);
      this.modalities.unshift(this.visitReadingLevelModality);
      this.selectedModality = this.visitReadingLevelModality;
    } else {
      if (this.modalities[0].id === -1) {
        this.modalities.shift();
      }
      this.selectedModality = null;
    }
    this.validateForm();
  }
  onImageConfigurationTypeChange(event: { value: any; }){
    this._configModel.imageViewerConfigurationType = event.value;
    this.validateForm();
  }
  onReadingTypeChange(event: { value: any; }) {
    this.formValid = this._configModel.readingType !== event.value;
    this._configModel.readingType = event.value;
    this.validateForm();
  }
  onReaderSelectionChange(event: { value: any[]; }) {
    this.selectedReadersids = event.value;
  }
  objectComparatorById = function (obj1, obj2): boolean {
    return obj1.id === obj2.id;
  }
  // this code must check and make sure the Reader is only added to the list if it is not already in it!
  onAddSelectedReaders() {

    if (this.selectedReadersids != null) {
      let counter = this._configModel.readers.length;
      this.selectedReadersids.forEach(readerId => {
        if (counter < this.maxReaders) {
          const reader = this.readers.find(r => r.id === readerId);
          if ((reader != null) && (!this._configModel.readers.includes(reader))) {
            this._configModel.readers.push(reader);
            const index = this.readers.indexOf(reader);
            this.readers.splice(index, 1);
            this.validateForm();
          }
          counter += 1;
        }
      });

      this.selectedReadersids = [];
    }
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
    this.selectedReaders = this._configModel.readers;
    if (this._configModel.readers.length > 0) {
      this.readersField.clearValidators();
    }
  }


  deleteReader(reader: any) {
    const index = this._configModel.readers.indexOf(reader);
    this._configModel.readers.splice(index, 1);
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
    this.selectedReadersids = this._configModel.readers;
    this.readers.unshift(reader);
    this.selectedReadersids = [];

    if (this._configModel.readers.length === 0) {
      this.readersField.validator = Validators.required;
    }
    if (this._configModel.readers.length > 0) {
      this.validateForm();
    } else {
      this.formValid = false;
    }

  }

  clickSubmitBtn() {
    const readers = [];
    this._configModel.readers.forEach(r => {
      readers.push({id: r.id});
    });

    this._configModel.anatomySelector = this.selectedSequenceLabels.map(l => {
      return {id: l.id};
    });

    this._configModel.modalities = [];
    if (this.selectedModality == null) {
      this._configModel.modalities = this.modalities;
    } else {
      this.modalities.forEach(m => {
        if (this.selectedModality.find(x=>x.id === m.id) ){
          this._configModel.modalities.push(m);
        }
      });
    }
    this._configModel.batchManagement =this.batchManagementType;
    this._configModel.hideVisitHistory=this.hideVisitChronologyType;
    this._configModel['endpointReadingVersion'] = 'basic';
    this._configModel.readers = readers;
    this._configModel.anatomySelector = this.selectedSequenceLabels;
    if(!this.basicBatchConfig)
      this.basicBatchConfig = {
        batchCount: "1",
        endpoint: 'PSMA PET Eligibility',
        flexibleConfigId: null,
        id: null,
        initiationMethod: "auto",
        initiationOption: "by_quantity",
        numberOfReadings: 1,
        readingType: "basic_reading",
        studyId: this.studyId
      };
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: this._configModel,
      basicBatchConfig: this.basicBatchConfig
    };
    //console.log(submitEvent)
    this.clickSubmit.emit(submitEvent);
  }

  clickCancelBtn() {
    this.clickCancel.emit({result: 'cancel'});
  }

  validateForm() {
    let ans = this.formValid = true;

    if (this._configModel.readingLevel == null) {
      ans = false;
    }

    if (this._configModel.readingType == null) {
      ans = false;
    }

    if (this._configModel.readingLevel !== 'visit') {
      ans = (this.selectedModality != null);
    }

    if (this._configModel.readers.length < this.minReaders || this._configModel.readers.length >this.maxReaders) {
      ans = false;
    }

    if (this.anatomySelectorControl.invalid) {
      ans = false;
    }
    if(this.hideVisitChronologyField.invalid)
      ans = false;

    if(this.imageViewerConfigurationField.invalid)
      ans = false;

    if(this.batchManagementField.invalid)
      ans = false;

    this.formValid = ans;
  }

  setVisitModalityText(modalities) {
    let mText = '';
    modalities.forEach(m => {
      mText += m.name + ', ';
    });

    this.visitReadingLevelModality.id = -1;
    this.visitReadingLevelModality.created = null;
    this.visitReadingLevelModality.name = mText.substr(0, mText.length - 2);
    this.visitReadingLevelModality.sequenceLabels = [];
  }

  public objectComparator = function (option, value): boolean {
    return option.id === value.id;
  };

}
