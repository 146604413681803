import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface LesionsData {
  lesions:[]
}
@Component({
  selector: 'app-lesions-list-dialog',
  templateUrl: './lesions-list-dialog.component.html',
  styleUrls: ['./lesions-list-dialog.component.css']
})
export class LesionsListDialogComponent implements OnInit {
  selectedLesion:string;
  constructor(public dialogRef: MatDialogRef<LesionsListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LesionsData) { }
  ngOnInit(): void {
  }
  closeClick(){
    this.dialogRef.close();
  }
  nextClick(){
    this.dialogRef.close(this.selectedLesion)
  }
}
