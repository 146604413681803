import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-reading-navigator',
  templateUrl: './reading-navigator.component.html',
  styleUrls: ['./reading-navigator.component.css']
})
export class ReadingNavigatorComponent implements OnInit, AfterViewInit {

  defaultColumns: string[] = ['row-num-reading-id', 'readingId', 'timeSpent', 'visitsNumber', 'selectReadings'];
  signColumns: string[] = ['row-num-reading-id', 'readingId', 'readerType', 'selectReadings'];
  dataSource: MatTableDataSource<any[]>;
  list: Array<any>;
  signList: false;

  @ViewChild('paginator') paginator: MatPaginator;
  @ViewChild('sort') sort: MatSort;


  constructor(public dialogRef: MatDialogRef<ReadingNavigatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {
    if (Array.isArray(this.data)) {
      this.list = this.data;
    } else {
      this.list = this.data.list;
      this.signList = this.data.signList;
    }

    this.dataSource = new MatTableDataSource<any>(this.list);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.data = this.list.filter(d => d.id.toString().indexOf(filterValue) !== -1);
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  secondsToHHMMSS(timeSpent: number) {
    const currentSeconds = timeSpent;
    let hours: number | string = Math.floor(currentSeconds / 3600);
    let minutes: number | string = Math.floor((currentSeconds - (hours * 3600)) / 60);
    let seconds: number | string = currentSeconds - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  selectReading(reading) {
    this.dialogRef.close(reading.id);
  }

  closeReadingListDialog() {
    this.dialogRef.close();
  }

  get columns() {
    if (this.signList) {
      return this.signColumns;
    } else {
      return this.defaultColumns;
    }
  }

  getReaderType(readerType: string): string {
    switch (readerType) {
      case 'consensus_reader': return 'Consensus';
      case 'adjudication_reader': return 'Adjudication';
      case 'level1_reader': return 'Level 1';
    }

    return '';
  }
}
