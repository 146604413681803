import {Action, Selector, State, StateContext} from '@ngxs/store';
import {ClearAuthInfo, GetAuthInfo, SetAuthInfo} from '../actions/auth-info.action';
import {AuthActivity} from '../../interfaces/auth-activity';
import {GetCurrentUserInfo} from '../actions/user-info.action';
import {Role} from '../../interfaces/role';

export class AuthInfoStateModel {
  userId: number;
  token: any;
  activities: AuthActivity[];
  roles: Role[];
}

@State<AuthInfoStateModel>({
  name: 'authInfo',
  defaults: {
    userId: null,
    token: null,
    activities: [],
    roles: []
  }
})
export class AuthInfoState {

  @Selector()
  static getAuthInfo(state: AuthInfoStateModel) {
    return state;
  }

  @Selector()
  static isAuthorised(state: AuthInfoStateModel) {
    return !!state.token;
  }

  @Action(SetAuthInfo)
  setAuthInfo(ctx: StateContext<AuthInfoStateModel>, { payload }: SetAuthInfo) {
    ctx.patchState({
      userId: payload.userId,
      token: payload.token,
      activities: payload.activities,
      roles: payload.roles
    });

    ctx.dispatch(new GetCurrentUserInfo());
  }

  @Action(GetAuthInfo)
  getAuthInfo({ getState, setState }: StateContext<AuthInfoStateModel>) {
    const state = getState();
    setState({
      ...state
    });
  }

  @Action(ClearAuthInfo)
  clearAuthInfo({ getState, patchState }: StateContext<AuthInfoStateModel>) {
    const state = getState();
    patchState({
      userId: null,
      token: null,
      activities: [],
      roles: []
    });
  }

}
