import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {environment} from 'src/environments/environment';

const GBM_API_URL = environment.GBM_API_URL;

@Injectable({
  providedIn: 'root'
})
export class GBMService {

  constructor(private http: HttpClient) { }

  registration(registrationData: any, headerX: any) {
    return this.http.post(GBM_API_URL+'/registration/', registrationData, { headers: headerX });
  }

  // segmentation(segmentationData: any, headerX: any) {
  //   return this.http.post('https://gbm-compute.imageanalysisgroup.com/gbmsegmentation/', segmentationData, { headers: headerX });
  // }

  segmentationforQC(segmentationforQCData: any, headerX: any) {
    return this.http.post(GBM_API_URL+'/gbmsegmentationforqc/', segmentationforQCData, { headers: headerX });
  }

  status(statusData: any, headerX: any) {
    return this.http.post(GBM_API_URL+'/status/', statusData, { headers: headerX });
  }

  getGBMJsonContent(jsonUrl: string, headerX: any) {
    return this.http.get(jsonUrl, { headers: headerX });
  }
}
