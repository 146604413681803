import {Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { Router } from '@angular/router';

import { PatientService } from '../../../../_services/patient.service';
import { QueriesService } from '../../../../_services/queries.service';
import { UserService } from '../../../../_services/user.service';
import { ImagingProjectService } from '../../../../_services/imaging-project.service';
import { SiteConfigService } from '../../../../_services/site-config.service';


import * as _moment from 'moment';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
const moment = _moment;


@Component({
  selector: 'app-edcf-list',
  templateUrl: './edcf-list.component.html',
  styleUrls: ['./edcf-list.component.css']
})
export class EdcfListComponent implements OnInit {
  currentImageProjectId: number;
  dataSource: any;
  detailedData = [];
  displayedColumns: string[] = ['id', 'status', 'siteCode', 'patientCode', 'visitName', 'modalities',  'createdBy', 'createdDate','resolvedBy', 'resolutionDate'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;


  constructor(
    private queriesService: QueriesService,
    private patientService: PatientService,
    private userService: UserService,
    private imagingProjectService: ImagingProjectService,
    private siteConfigService: SiteConfigService,
    private router: Router,
    private store: Store
  ) { }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('List of eDCFs'));
    let project = JSON.parse(localStorage.getItem('project'));
    this.currentImageProjectId = project.id;

    this.queriesService.getQueriesByStudyId(this.currentImageProjectId).subscribe( queriesResponse =>{
     let queriesData = queriesResponse['data'];
      if(queriesData != null) {
        let usersIds= [];
        queriesData.forEach(edcfs => {
          usersIds.push(edcfs.createUserId);
        });

        this.userService.getByListIds(usersIds).subscribe(usersResponse => {
          this.patientService.getPatientsByStudyId(this.currentImageProjectId).subscribe(patientsResponse => {
            this.siteConfigService.getSiteConfigsByStudyIdBrief(this.currentImageProjectId).subscribe(siteConfResp => {
              this.imagingProjectService.getModalities().subscribe(modalitiesResp => {
                let usersData = usersResponse['data'];
                let patientData = patientsResponse['data'];
                let siteConfData = siteConfResp['data'];
                let modalitiesData = modalitiesResp['data'];

                queriesData.forEach(edcf => {
                  let userCreat = usersData.filter(user => user.id == edcf.createUserId)[0];
                  let userResolv = usersData.filter(user => user.id == edcf.resolveUserId)[0];
                  let patient = patientData.filter(pat => pat.visitConfigs.filter(vconf => vconf.id == edcf.visitConfigId).length >0)[0];
                  let patientVisitConfig = patient.visitConfigs.filter(vconf =>vconf.id == edcf.visitConfigId)[0];
                  let siteConfig = siteConfData.filter(sconf => sconf.id == edcf.siteConfigId)[0];
                  let q = {
                    id: edcf.id,
                    numberInStudy: edcf.numberInStudy,
                    created: edcf.created,
                    siteCode: siteConfig.siteCode,
                    patientCode: patient.patientCode,
                    visitName: patientVisitConfig.visit.name,
                    modalities: [],
                    status: edcf.queriesStatus.status,
                    createdByUser: edcf.createUserId,
                    createdByFirstName: null,
                    createdByLastName: null,
                    createdDate: moment(edcf.created).format('DD/MM/YYYY'),
                    resolvedByUser: edcf.createUserId,
                    resolvedByFirstName: null,
                    resolvedByLastName: null,
                    resolutionDate: null,
                  };

                  edcf.modalities.forEach(mod =>{
                    let modal = modalitiesData.filter(m => m.id == mod.modalityId);
                    modal.forEach(m => {
                      q.modalities.push(m.name);
                    });
                  });

                  if (userCreat != null) {
                    q.createdByFirstName = userCreat.firstName;
                    q.createdByLastName = userCreat.lastName;
                  };
                  if(edcf.resolved){
                    q.resolutionDate = moment(edcf.resolved).format('DD/MM/YYYY');
                  }
                  if(userResolv != null){
                    q.resolvedByFirstName = userResolv.firstName;
                    q.resolvedByLastName = userResolv.lastName;
                  }

                  this.detailedData.push(q);
                });
                //revers sort.
                this.detailedData.sort((a, b) => (a.created > b.created) ? -1 : 1);
                this.dataSource = new MatTableDataSource<any>(this.detailedData);

                if (this.dataSource != null) {
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort;
                }
              });
            });
          });
        });
      }
    });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  clickTableCell(element){
    let edcf = {
      id: element.id,
    };
    localStorage.setItem("query.edcf",JSON.stringify(edcf));
    localStorage.setItem('query.edcf.fromQcTaskList',JSON.stringify(false));
    this.router.navigate(['/queries/edcf/detail']);
  }

}
