import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
export interface AnnotationSaveData {
  description: string;
  container: any;
  seriesManagerService: any;
  objectStoreService: any;
}

@Component({
  selector: 'app-annotation-save-dialog',
  templateUrl: './annotation-save-dialog.component.html',
  styleUrls: ['./annotation-save-dialog.component.css']
})
export class AnnotationSaveDialogComponent implements OnInit {
  public showAlert = false;
  public typeAlert = "success";
  public contAlert = "";

  constructor(public dialogRef: MatDialogRef<AnnotationSaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AnnotationSaveData) { }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickOk(): void 
  {
    let cell: lt.Controls.Medical.Cell = this.data.seriesManagerService.getActiveCell();
    if (cell) {
      let seriesInstanceUID = cell.get_seriesInstanceUID();
      let annotationsData = this.data.seriesManagerService.getCellAnnotations(cell);

      if (annotationsData.length <= 0) {
        alert('No annotations found to save');
        return;
      }
      
      let userData = JSON.stringify({
        SourceDpiX: this.data.container.mapper.sourceDpiX,
        SourceDpiY: this.data.container.mapper.sourceDpiY,
        TargetDpiX: this.data.container.mapper.targetDpiX,
        TargetDpiY: this.data.container.mapper.targetDpiY
      });

      this.data.objectStoreService.storeAnnotations(
        seriesInstanceUID, annotationsData,
        this.data.description, userData).toPromise().then((data: any) => {
          // console.log("storeAnnotations, data: ", data);
          if (data && data.Message){
            this.triggerAlert("danger", "An error occured", 2000, false, data.Message);
          }
          else {
            this.triggerAlert("success", "Annotations saved", 2000, true, data);
          }
        },
        (error: any) => {
          this.triggerAlert("danger", "An error occured", 2000, false, error);
        }
      );
    }
  }

  triggerAlert(type, msg, timeout, close, data?){
    this.typeAlert = type;
    this.showAlert = true;
    this.contAlert = msg;
    if (type == "danger")
      console.log("Error found, data: ", data);
    setTimeout(() => {
      this.showAlert = false;
      if (close)
        this.dialogRef.close();
    }, timeout);
  }
}
