import {Component, OnInit} from '@angular/core';
import {ReadingCandenSparccService} from '../../../../_services/reading-canden-sparcc.service';
import {CandenSparccReadingModel} from '../../../../_models/CandenSparcc/canden-sparcc-reading-model';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {DynamikaWebSocket, ReadingConfigFlexibleService, UserService} from '../../../../_services';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../../reading-form-scoring.component';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';
import {ReadingStatus} from '../../../../core/constants/reading-status';
import {ActivatedRoute} from '@angular/router';
import {ToastService} from '../../../../_services/internal/toast.service';

const ZITELAB_URL = environment.ziteLabUrl;

interface CandenSparccScoringItem {
  timepoint: string;
  anteriorSliceControl: FormControl;
  posteriorSliceControl: FormControl;
}

@Component({
  selector: 'app-canden-sparcc-reading-form',
  templateUrl: './canden-sparcc-reading-form.component.html',
  styleUrls: ['./canden-sparcc-reading-form.component.css']
})
export class CandenSparccReadingFormComponent extends ReadingFormScoringComponent
  implements OnInit, ReadingFormScoring<CandenSparccReadingModel> {

  studyId: number;
  currentReading: CandenSparccReadingModel;
  currentReadingConfig;
  moderatorReading: CandenSparccReadingModel;
  moderationMode = false;
  form: FormGroup;
  dataSource: MatTableDataSource<CandenSparccScoringItem> = new MatTableDataSource();
  isReadingComplete = false;
  initialisingZiteLab = false;
  initiatingStatus: boolean | 'loading' | 'error' = false;
  socketSubscription: Subscription;

  hint = '';

  sliceError = false;

  readonly siJointTableColumns = ['Visit', 'Most anterior slice', 'Most posterior slice'];
  readonly readingStatuses = ReadingStatus;

  constructor(private store: Store,
              private readingService: ReadingCandenSparccService,
              private userService: UserService,
              private configService: ReadingConfigFlexibleService,
              private dynamikaWebSocket: DynamikaWebSocket,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private toastService: ToastService) {
    super();
  }

  ngOnInit(): void {
    this.moderationMode = Boolean(this.route.snapshot.params['moderation']);
    if (this.moderationMode) {
      this.hint = 'Review the images and perform the initial setup of the slices so that other readers receive their reading forms pre-configured';
    } else {
      this.hint = 'To perform CANDEN&SPARCC reading:\n1.Use series navigator to view Patient\'s images;\n2.Initiate reading by clicking \'Initiate button\';\n3.Wait until reading is initiated successfully;\n4.Use URL to navigate to ZiteLab Scoreboard form;\n5.Complete the scoring form on ZiteLab site and submit it;\n6.When Dynamika receives confirmation that scoring is done on ZiteLab site then proceed with \'Submit\'';
    }
    this.store.dispatch(new SetPageHeaderTitle('CANDEN & SPARCC Scoring Form'));
    this.studyId = +JSON.parse(localStorage.getItem('project')).id;
    this.socketSubscription = this.dynamikaWebSocket.listen('reading_canden_sparcc_message', null, 'canden-sparcc-reading-form').subscribe((data) => {
      const receivedMsg = JSON.parse(data);
      if (this.currentReading.readerId === receivedMsg.readerId && this.currentReading.id === receivedMsg.patientId) {
        this.switchSubmitBtnDisabledSubject.next(false);
        this.currentReading.ziteLabDataReceived = true;
      }
    });
    this.initOnCurrentReading();
  }

  initForm(reading: CandenSparccReadingModel): void {
    const dataSource: CandenSparccScoringItem[] = [];
    this.form = this.fb.group({});
    this.form.addControl('comment', new FormControl(reading.comment, [Validators.maxLength(200)]));
    if (this.isSIJFormEnabled()) {
      for (const visit of reading.visits) {
        const form = this.fb.group({
          timepoint: new FormControl(visit.timepoint, [Validators.required]),
          anteriorSlice: new FormControl(visit.anteriorSlice,
            [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(999)]),
          posteriorSlice: new FormControl(visit.posteriorSlice,
            [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1), Validators.max(999)])
        });
        if ((!!this.moderatorReading && !this.moderationMode) || visit.noUploads) {
          form.controls.anteriorSlice.clearValidators();
          form.controls.posteriorSlice.clearValidators();
          form.controls.anteriorSlice.disable();
          form.controls.posteriorSlice.disable();
          form.clearValidators();
        }
        this.form.addControl(visit.timepoint, form);
        dataSource.push({
          timepoint: visit.timepoint,
          anteriorSliceControl: form.controls.anteriorSlice as FormControl,
          posteriorSliceControl: form.controls.posteriorSlice as FormControl
        });
        this.dataSource.data = dataSource;
      }
    }
    this.form.valueChanges.subscribe(() => {
      this.validateModeration();
    });
  }

  validateModeration(): void {
    if (this.moderationMode && !this.form.invalid) {
      this.switchSubmitBtnDisabledSubject.next(false);
    } else if (this.form.invalid) {
      this.switchSubmitBtnDisabledSubject.next(true);
    }
    this.sliceError = Object.keys(this.form.controls).some(key => this.form.get(key).hasError('slicesError'));
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(async currentReading => {
      this.currentReading = currentReading;
      await this.initCurrentReadingConfig(this.currentReading.flexibleConfigId);
      this.initiatingStatus = this.currentReading.ziteLabInitiated;
      this.setModeratorReading();
      this.isReadingComplete = !!this.currentReading.ziteLabDataReceived;
      this.switchSubmitBtnDisabledSubject.next(!this.isReadingComplete);
      this.initForm(this.currentReading);
    });
  }

  async initCurrentReadingConfig(flexibleConfigId: number) {
    const response = await this.configService.getById(flexibleConfigId).toPromise();
    this.currentReadingConfig = response.data.config;
  }

  setModeratorReading(): void {
    this.moderatorReading = !!this.currentReading.moderatorReading ? this.currentReading.moderatorReading : null;
  }

  controlSamriView(): void {
    this.readingService.loadOnSamri(this.studyId, this.currentReading.id).subscribe(() => {
      this.toastService.success('Sync Patient Request', 'Sync request has been sent to other devices. Make sure that you are authorized on ZiteLab app');
    });
  }

  openZiteLabTab(): void {
    forkJoin([
      this.userService.getById(this.currentReading.readerId),
      this.readingService.loadOnSamri(this.studyId, this.currentReading.id)
    ]).subscribe(
      values => {
        const [userResponse, samriResponse] = values as any;
        if (userResponse['responseCode'] === 200 && samriResponse['responseCode'] === 200) {
          const userName = userResponse['data'].userName as string;
          const dbName = environment.zitelab_canden_sparcc_db_prefix;
          window.open(`${ZITELAB_URL}/samrisystem/${userName}/${dbName}${this.currentReading.flexibleConfigId}/${this.currentReading.id}`, '_blank');
        }
      });
  }

  clearForm(): void {
    this.initialisingZiteLab = false;
  }

  getEndpointName(): string {
    return 'Canden&Sparcc';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    if (Boolean(this.route.snapshot.params['moderation'])) {
      return this.readingService.getModerationReadings(studyId, readerId);
    } else {
      return this.readingService.getReading(studyId, readerId);
    }
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    this.applyFormValuesToCurrentReading();
    this.currentReading.timeSpent = data.spentSeconds;
    return this.readingService.completeReading(studyId, this.currentReading);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.updateReading(studyId, readingId, data.spentSeconds, this.form.controls.comment.value);
  }

  ziteLabInitiateReading(): void {
    this.initiatingStatus = 'loading';
    this.applyFormValuesToCurrentReading();
    this.initialisingZiteLab = true;
    this.readingService.ziteLabReadingInitiation(this.studyId, this.currentReading).subscribe(
      response => {
        if (response.responseCode === 200) {
          this.currentReading.ziteLabInitiated = true;
          this.initiatingStatus = true;
        } else {
          this.initiatingStatus = 'error';
        }
      }, () => {
        this.initiatingStatus = 'error';
      });
  }

  applyFormValuesToCurrentReading(): void {
    if (this.isSIJFormEnabled()) {
      this.currentReading.visits.forEach(v => {
        v.anteriorSlice = this.form.controls[v.timepoint].value.anteriorSlice;
        v.posteriorSlice = this.form.controls[v.timepoint].value.posteriorSlice;
      });
    }
    this.currentReading.comment = this.form.controls.comment.value;
  }

  isSIJFormEnabled(): boolean {
    return !!this.currentReadingConfig && !!this.currentReadingConfig['factorstoScoreSIJ']
      && this.currentReadingConfig['factorstoScoreSIJ'].length > 0;
  }
}
