import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {FormMode} from '../../../../../core/constants/form-mode';
import {LuganoConfigModel} from '../../../../../_models/ImagingProject/lugano-config-model';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {Reader} from '../../LUGANO/imaging-project-reading-lugano-wrapper/imaging-project-reading-lugano-wrapper.component';
import {MatTableDataSource} from '@angular/material/table';
import {forkJoin} from 'rxjs';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {StudySequenceLabelService, StudyUserService} from '../../../../../_services';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {
  defaultBasicAutoBatchConfig,
  ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {ReadingLevelOptionsDialogComponent} from '../../../../../components/controls/reading-level-options-dialog/reading-level-options-dialog.component';
import {ReadingTypeSequentialOptionsDialogComponent} from '../../../../../components/controls/reading-type-sequential-options-dialog/reading-type-sequential-options-dialog.component';
import {ReadingLevel} from '../../../../../core/constants/reading-level';
import {ReadingTypeSequentialOptions} from "../../../../../core/constants/reading-type-sequential-options";
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';


@Component({
  selector: 'app-imaging-project-reading-lugano',
  templateUrl: './imaging-project-reading-lugano.component.html',
  styleUrls: ['./imaging-project-reading-lugano.component.css']
})
export class ImagingProjectReadingLuganoComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  @Input() selectedConfig: FlexibleConfig<LuganoConfigModel>;
  @Input() mode: FormMode;
  @Output() public clickCancel = new EventEmitter<any>();
  @Output() public clickSubmit = new EventEmitter<any>();

  readonly formModes = FormMode;
  modalities: ModalityModel[];
  studyId: number;
  basicBatchConfig: BatchConfigModel = null;
  readersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  MIN_READERS = 1;
  MAX_READERS = 20;
  readerSelectOptions: Reader[];
  readers: Reader[];
  readersTableColumns: string[] = ['username', 'email', 'action'];

  luganoForm = this.fb.group({
    readingLevel: [ReadingLevel.LONGITUDINAL, [Validators.required]],
    readingLevelLongitudinalOptions: [],
    modalities: [[], [Validators.required]],
    readingType: [ReadingType.SEQUENTIAL, [Validators.required]],
    readingTypeSeqentialOption: [ReadingTypeSequentialOptions.AUTO, [Validators.required]],
    hideVisitHistory: ['hide_visits_chronology', [Validators.required]],
    anatomySelector: ['', [Validators.required]],
    batchManagement: [false, [Validators.required, this.batchManagementValidator]],
    imageViewerConfiguration: ['default', [Validators.required]],
    readers: ['', [this.readersValidator]]
  });

  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private studySequenceLabelService: StudySequenceLabelService,
    private studyUserService: StudyUserService,
    private batchLogicService: BatchLogicService,
    private toastyService: ToastyService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    this.studyId = +this.route.snapshot.params.id;

    const serviceSources = [
      this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
      this.studyUserService.getReadersByStudyId(this.studyId)
    ];
    if (this.mode === this.formModes.EDIT) {
      serviceSources.push(
        this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id)
      );
    }

    forkJoin(serviceSources).subscribe(values => {
        const [groupedModalitiesResponse, readersResponse, batchConfigsResponse] = values as any;
        this.initModalities(groupedModalitiesResponse);
        this.initReaders(readersResponse);
        if (this.mode === this.formModes.EDIT) {
          this.initBatchConfigs(batchConfigsResponse);
          this.initFormValues(this.selectedConfig.config);
        }
      },
      (error) => this.showErrorMessage(error.error));
  }

  showErrorMessage(response: any): void {
    this.toastOptions.title = 'ERROR ' + response['responseCode'];
    this.toastOptions.msg = response['responseMessage'];
    this.toastyService.error(this.toastOptions);
  }

  initFormValues(config: LuganoConfigModel): void {
    if (config) {
      this.luganoForm.patchValue(config);
      this.luganoForm.patchValue({
        readers: config.readers.map(reader => reader.id)
      });
      this.pushReaders();
    }
  }

  initBatchConfigs(batchConfigsResponse): void {
    if (batchConfigsResponse.responseCode === 200 && batchConfigsResponse.data) {
      this.basicBatchConfig = (batchConfigsResponse.data as BatchConfigModel[])
        .find(batchConfig => batchConfig.readingType === ReadingType.BASIC_READING);
    }
  }

  initModalities(groupedModalitiesResponse): void {
    if (groupedModalitiesResponse.responseCode === 200 && groupedModalitiesResponse.data) {
      this.modalities = [];
      groupedModalitiesResponse.data.forEach(m => {
        if (m.name.toLowerCase() === 'ct' || m.name.toLowerCase() === 'pet-ct') {
          this.modalities.push(m);
        }
      });
      this.modalities.sort((a, b) => a.name.localeCompare(b.name));
      if (this.mode === this.formModes.NEW) {
        this.luganoForm.patchValue({modalities: this.modalities});
      }
      this.availableSequenceLabels = [];
      this.modalities.forEach(m => {
        this.availableSequenceLabels  = this.availableSequenceLabels.concat(m.sequenceLabels);
      });
      this.prepareSequenceLabels();
    }
  }

  initReaders(readersResponse): void {
    if (readersResponse.responseCode === 200 && readersResponse.data) {
      this.readers = readersResponse.data;
      if (this.mode === this.formModes.NEW) {
        this.readerSelectOptions =
          (readersResponse.data as Reader[]).sort(this.compareReadersByName);
      }
    }
  }

  compareModalities(m1: any, m2: any): boolean {
    return m1 && m2 ? m1.name === m2.name : m1 === m2;
  }

  getFormControl(path: string): FormControl {
    return this.luganoForm.get(path) as FormControl;
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
  }

  prepareSequenceLabels() {
    if (this.mode === FormMode.EDIT) {
      if (this.selectedConfig.config.anatomySelector) {
        this.initSequenceLabels(this.selectedConfig.config.anatomySelector);
      } else {
        this.initDefaultSequenceLabels();
      }
    } else {
      this.initDefaultSequenceLabels();
    }
  }

  initDefaultSequenceLabels(): void {
    this.selectedSequenceLabels = [...this.availableSequenceLabels];
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    this.selectedSequenceLabels = this.availableSequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }


  get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value && !this.basicBatchConfig?.numberOfReadings ? {norError: 'Number of readings is required'} : null;
    };
  }

  onBatchManagementSelected(event: MatSelectChange): void {
    if (this.basicBatchConfig) {
      this.basicBatchConfig.numberOfReadings = null;
      this.luganoForm.get('batchManagement').updateValueAndValidity();
    }
  }

  openAutoCreationBatchConfigDialog(): void {
    let inputConfig: AutoBatchConfigPayload;
    const val = this.luganoForm.get('batchManagement').value;
    if (!val) {
      inputConfig = {...defaultBasicAutoBatchConfig};
    } else {
      inputConfig = this.basicBatchConfig;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        readingType: ReadingType.BASIC_READING,
        configInput: inputConfig,
        readonlyDialog: !val
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        this.basicBatchConfig = {...result};
        this.luganoForm.get('batchManagement').updateValueAndValidity();
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  openReadingLevelOptionsDialog(): void {
    let inputOptions: string[];
    const val = this.luganoForm.get('readingLevelLongitudinalOptions').value;
    if ( !val ) {
      inputOptions = [];
    } else {
      inputOptions = [...val];
    }
    const dialogRef = this.dialog.open(ReadingLevelOptionsDialogComponent, {
      width: '500px',
      data: inputOptions
    });

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result) {
        if ( !result ) {
          result = [];
        }
        this.luganoForm.get('readingLevelLongitudinalOptions').setValue(result);
      }
    });
  }

  openReadingTypeOptionDialog(): void {
    let inputOptions: string[];
    const val = this.luganoForm.get('readingTypeSeqentialOption').value;
    if ( !val ) {
      inputOptions = null;
    } else {
      inputOptions = val;
    }
    const dialogRef = this.dialog.open(ReadingTypeSequentialOptionsDialogComponent, {
      width: '500px',
      data: inputOptions
    });

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result) {
        this.luganoForm.get('readingTypeSeqentialOption').setValue(result);
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  compareReaders(r1: any, r2: any): boolean {
    return r1 && r2 ? r1.id === r2.id : r1 === r2;
  }

  get readersValidator() {   // computed property suppress property is used before its initialization error
    return (): ValidationErrors | null => {
      return this.isReadersValid() ? null : {
        tableError: `${this.MIN_READERS}..${this.MAX_READERS} users must be in the list`,
        selectError: `please ${this.readersDataSource.data.length < this.MAX_READERS ? 'select' : 'remove'} option`
      };
    };
  }

  isReadersValid(): boolean {
    return this.readersDataSource.data.length >= this.MIN_READERS && this.readersDataSource.data.length <= this.MAX_READERS;
  }

  compareReadersByName(r1: Reader, r2: Reader): number {
    return (r1.firstName + r1.lastName).localeCompare(r2.firstName + r2.lastName);
  }

  pushReaders(): void {
    const selectedReaderIds = [...this.luganoForm.get('readers').value, ...this.readersDataSource.data.map(reader => reader.id)];
    this.readersDataSource.data =
      this.readers
        .filter(reader => selectedReaderIds.includes(reader.id))
        .sort(this.compareReadersByName);
    this.readerSelectOptions = this.readers
      .filter(reader => !selectedReaderIds.includes(reader.id))
      .sort(this.compareReadersByName);
    this.luganoForm.patchValue({readers: []});
  }

  removeReader(id: number): void {
    this.readersDataSource.data = this.readersDataSource.data.filter(reader => reader.id !== id);
    this.readerSelectOptions = [...this.readerSelectOptions, this.readers.find(r => r.id === id)].sort(this.compareReadersByName);
    this.luganoForm.get('readers').updateValueAndValidity();
  }

  cancel(): void {
    this.clickCancel.emit({result: 'cancel'});
  }

  prepareBasicBatchConfigPayload(): AutoBatchConfigPayload {
    let configPayload: AutoBatchConfigPayload = null;
    if (this.basicBatchConfig) {
      const configId: number = this.basicBatchConfig.id ? this.basicBatchConfig.id : null;
      if (this.luganoForm.get('batchManagement').value) {
        configPayload = {
          numberOfReadings: this.basicBatchConfig.numberOfReadings || undefined,
          readingType: ReadingType.BASIC_READING,
          initiationMethod: this.basicBatchConfig.initiationMethod,
          initiationOption: this.basicBatchConfig.initiationOption,
          batchCount: this.basicBatchConfig.batchCount
        };
      } else {
        configPayload = {...defaultBasicAutoBatchConfig};
        configPayload.readingType = ReadingType.BASIC_READING;
      }
      configPayload.id = configId;
    }
    return configPayload;
  }

  submit(): void {
    const payload: LuganoConfigModel = this.luganoForm.value;
    payload.readers = this.readersDataSource.data.map(reader => {
      return {id: reader.id};
    });
    payload.readingVersion = ReadingVersion.LUGANO_BASIC;
    payload.endpointReadingVersion = 'basic';
    payload.anatomySelector = this.selectedSequenceLabels.map(l => {
      return {id: l.id};
    });

    const imagingProjectSubmitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: payload,
      basicBatchConfig: this.prepareBasicBatchConfigPayload()
    };

    // console.warn(JSON.stringify(imagingProjectSubmitEvent));
    this.clickSubmit.emit(imagingProjectSubmitEvent);
  }

}
