import {Router} from '@angular/router';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {
  ReadingConfigFlexibleService,
  ReadingIncidentalFindingsService,
  ReadingKandlService,
  ReadingPsamrisService,
  ReadingWbmriService,
  StudyService,
  StudyUserService,
  UserRoleMetaDataService
} from '../../../_services';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ReadingVersion} from '../../../core/constants/reading-version';
import {ReadingMoaksService} from '../../../_services/reading-moaks.service';
import {ReadingCandenSparccService} from '../../../_services/reading-canden-sparcc.service';
import {ReadingNovadip5Service} from '../../../_services/reading-novadip5.service';
import {IconService} from '../../../_services/icons/icon.service';
import {ReadingLuganoService} from '../../../_services/reading-lugano.service';
import {ReadingRecistService} from '../../../_services/reading-recist.service';
import {ReadingMranoService} from 'src/app/_services/reading-mrano.service';
import {ReadingHeelService} from 'src/app/_services/reading-heel.service';
import {CompactJwt} from "../../../_helpers/CompactJwt";

export interface ProjectData {
  id: number;
  sponsor: string;
  name: string;
  dashboardUrl: string;
  status: boolean;
  numberOfAvailableReadingsForIF: number;
  numberOfAvailableReadingsForKandL: number;
  numberOfAvailableReadingsForKandLAdjudication: number;
  numberOfAvailableReadingsForPSAMRIS: number;
  numberOfAvailableReadingsForPSAMRISAdjudication: number;
  numberOfAvailableReadingsForWBMRI: number;
  numberOfAvailableReadingsForMOAKS: number;
  numberOfAvailableReadingsForCandenSparcc: number;
  numberOfAvailableReadingsForWBMRIAdjudication: number;
  numberOfAvailableReadingsForNovadip5: number;
  numberOfAvailableReadingsForLugano: number;
  numberOfAvailableReadingsForRecist: number;
  numberOfAvailableReadingsForMRANO:number;
  numberOfAvailableReadingsForHeel:number;
  hasReadingAccessForIF: boolean;
  hasReadingAccessForKandL: boolean;
  hasReadingAccessForKandLAdjudication: boolean;
  hasReadingAccessForPsAMRIS: boolean;
  hasReadingAccessForPsAMRISAdjudication: boolean;
  hasReadingAccessForWBMRI: boolean;
  hasReadingAccessForWBMRIAdjudication: boolean;
  hasReadingAccessForMOAKS: boolean;
  hasReadingAccessForCandenSparcc: boolean;
  hasReadingAccessForNovadip5: boolean;
  hasReadingAccessForLugano: boolean;
  hasReadingAccessForRecist: boolean;
  hasReadingAccessForMRANO:boolean;
  hasReadingAccessForHeel: boolean;
}

const helper = new JwtHelperService();

const VERSION_KL_BASIC = 'ReadingVersion-BasicKL';
const VERSION_KL_ADJUDICATION = 'ReadingVersion-AdjudicationKL';
const VERSION_PSAMRIS_BASIC = 'ReadingVersion-BasicPsAMRIS';
const VERSION_PSAMRIS_ADJUDICATION = 'ReadingVersion-AdjudicationPsAMRIS';

@Component({
  selector: 'app-webinar-project-list',
  templateUrl: './webinar-project-list.component.html',
  styleUrls: ['./webinar-project-list.component.css']
})
export class WebinarProjectListComponent implements OnInit {

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  displayedColumns: string[] = ['row-num', 'sponsor', 'name', 'status', 'operations'];
  dataSource = new MatTableDataSource();
  activities: any = [];
  globals: any[] = [];
  projectList: ProjectData[] = [];
  projectIds: number[];
  metaDatas: any = [];
  jsontoken: any = {};
  userId: number;
  readingConfigs: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router,
              private studyService: StudyService,
              private studyUserService: StudyUserService,
              private readingIncidentialFindingsService: ReadingIncidentalFindingsService,
              private readingKandlService: ReadingKandlService,
              private readingPsamrisService: ReadingPsamrisService,
              private readingWbmriService: ReadingWbmriService,
              private readingMoaksService: ReadingMoaksService,
              private readingCandenSparccService: ReadingCandenSparccService,
              private readingNovadip5Service: ReadingNovadip5Service,
              private readingLuganoService: ReadingLuganoService,
              protected readingRecistService: ReadingRecistService,
              private readingMRANOService: ReadingMranoService,
              private readingHeelService:ReadingHeelService,
              private userRoleMetaDataService: UserRoleMetaDataService,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private toastyService: ToastyService,
              private store: Store,
              private iconService: IconService) {
    this.jsontoken = CompactJwt.decodeActivities(helper.decodeToken(localStorage.getItem('currentUser')));
    this.globals = JSON.parse(localStorage.getItem('globals'));
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Select Imaging Project'));
    this.iconService.registerIcons();
    this.userId = parseInt(JSON.parse(localStorage.getItem('userId')), 10);
    this.activities = JSON.parse(localStorage.getItem('activities'));
    localStorage.removeItem('project');
    if (this.isUserHasActivity('configuration.study.create', null)) {
      this.studyService.getAllWithoutRelations().subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const studies = response.data;
            studies.forEach(study => {
              const x = {
                id: study.id,
                sponsor: study.sponsor?.name,
                name: study.name,
                dashboardUrl: study.dashboardUrl,
                status: study.status,
                numberOfAvailableReadingsForIF: 0,
                numberOfAvailableReadingsForKandL: 0,
                numberOfAvailableReadingsForKandLAdjudication: 0,
                numberOfAvailableReadingsForPSAMRIS: 0,
                numberOfAvailableReadingsForPSAMRISAdjudication: 0,
                numberOfAvailableReadingsForWBMRI: 0,
                numberOfAvailableReadingsForMOAKS: 0,
                numberOfAvailableReadingsForCandenSparcc: 0,
                numberOfAvailableReadingsForWBMRIAdjudication: 0,
                numberOfAvailableReadingsForNovadip5: 0,
                numberOfAvailableReadingsForLugano: 0,
                numberOfAvailableReadingsForRecist: 0,
                numberOfAvailableReadingsForMRANO:0,
                numberOfAvailableReadingsForHeel:0,
                hasReadingAccessForIF: false,
                hasReadingAccessForKandL: false,
                hasReadingAccessForKandLAdjudication: false,
                hasReadingAccessForPsAMRIS: false,
                hasReadingAccessForPsAMRISAdjudication: false,
                hasReadingAccessForWBMRI: false,
                hasReadingAccessForMOAKS: false,
                hasReadingAccessForCandenSparcc: false,
                hasReadingAccessForWBMRIAdjudication: false,
                hasReadingAccessForNovadip5: false,
                hasReadingAccessForLugano: false,
                hasReadingAccessForRecist: false,
                hasReadingAccessForMRANO:false,
                hasReadingAccessForHeel:false
              };
              this.projectList.push(x);
            });
            this.updateNumberOfAvailableReadings();
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.projectIds = [];
      // Changed from unsecure by email to secure by Id
      this.studyUserService.getStudies(this.userId).subscribe(
        (sweResponse: any) => {
          if (sweResponse.responseCode === 200) {
            this.projectIds = sweResponse.data;
            this.studyService.getAllWithoutRelations().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.projectList = [];
                  const studies = response.data;
                  studies.forEach(study => {
                    if (this.projectIds.includes(study.id)) {
                      const x = {
                        id: study.id,
                        sponsor: study.sponsor?.name,
                        name: study.name,
                        dashboardUrl: study.dashboardUrl,
                        status: study.status,
                        numberOfAvailableReadingsForIF: 0,
                        numberOfAvailableReadingsForKandL: 0,
                        numberOfAvailableReadingsForKandLAdjudication: 0,
                        numberOfAvailableReadingsForPSAMRIS: 0,
                        numberOfAvailableReadingsForPSAMRISAdjudication: 0,
                        numberOfAvailableReadingsForWBMRI: 0,
                        numberOfAvailableReadingsForMOAKS: 0,
                        numberOfAvailableReadingsForCandenSparcc: 0,
                        numberOfAvailableReadingsForWBMRIAdjudication: 0,
                        numberOfAvailableReadingsForNovadip5: 0,
                        numberOfAvailableReadingsForLugano: 0,
                        numberOfAvailableReadingsForRecist: 0,
                        numberOfAvailableReadingsForMRANO:0,
                        numberOfAvailableReadingsForHeel:0,
                        hasReadingAccessForIF: false,
                        hasReadingAccessForKandL: false,
                        hasReadingAccessForKandLAdjudication: false,
                        hasReadingAccessForPsAMRIS: false,
                        hasReadingAccessForPsAMRISAdjudication: false,
                        hasReadingAccessForWBMRI: false,
                        hasReadingAccessForMOAKS: false,
                        hasReadingAccessForCandenSparcc: false,
                        hasReadingAccessForWBMRIAdjudication: false,
                        hasReadingAccessForNovadip5: false,
                        hasReadingAccessForLugano: false,
                        hasReadingAccessForRecist: false,
                        hasReadingAccessForMRANO:false,
                        hasReadingAccessForHeel:false
                      };
                      this.projectList.push(x);
                    }
                  });
                  this.updateNumberOfAvailableReadings();
                } else {
                  this.toastOptions.title = 'ERROR ' + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + sweResponse.responseCode;
            this.toastOptions.msg = sweResponse.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  addProject() {
    this.router.navigate(['/webinar/create/-1']);
  }

  recalculateActivities(element) {
    localStorage.setItem('project', JSON.stringify(element));
    this.activities = this.jsontoken.permissions.activities;
    this.activities.forEach(act => {
      if (act.projectId === element.id) {
        localStorage.setItem('activities', JSON.stringify(act.activities));
      }
    });
    this.activities = localStorage.getItem('activities');

    this.userRoleMetaDataService.getMetaDataWithEmail(JSON.parse(localStorage.getItem('project')).id, JSON.parse(localStorage.getItem('subject'))).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.metaDatas = JSON.parse(response.data);
          this.metaDatas.forEach(metaData => {
            if (metaData.site_id) {
              localStorage.setItem('siteId', metaData.site_id);
            }
          });
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  isUserHasActivity(activityName: string, projectId: number): boolean {
    let result = false;
    this.activities = this.jsontoken.permissions.activities;
    if (this.activities) {
      this.activities.forEach(act => {
        if (act.projectId === projectId) {
          localStorage.setItem('activities', JSON.stringify(act.activities));
          this.activities = JSON.parse(JSON.stringify(act.activities));
          if (this.activities.length > 0) {
            result = this.activities.indexOf(activityName) >= 0;
          }
        }
      });
    }
    if (!result) {
      if (this.globals) {
        result = this.globals.indexOf(activityName) >= 0;
      }
    }
    return result;
  }

  navigateTo(url: string, element) {
    this.recalculateActivities(element);
    this.router.navigate([url]);
  }

  updateNumberOfAvailableReadings() {
    this.readingConfigFlexibleService.getAll().subscribe(
      (allConfigsResponse: any) => {
        if (allConfigsResponse.responseCode === 200) {
          this.readingConfigs = allConfigsResponse.data;
          this.projectList.forEach(project => {
            if (this.isUserHasActivity('reader.central', project.id)) {
              const studyReadingConfigs = this.readingConfigs.filter(readingConfig => readingConfig.studyId === project.id);
              // project info
              studyReadingConfigs.forEach(studyReadingConfig => {
                const configActive = studyReadingConfig.statusName === 'Active';
                const config = studyReadingConfig.config;
                const isUserIdentified = studyReadingConfig.config.readers.some(reader => reader.id === this.userId);
                const isUserAdjudicationIdentified = config.adjudicationReader === this.userId;
                if (isUserIdentified || isUserAdjudicationIdentified) {
                  switch (config.endpoint.name) {
                    case 'Incidental Findings': {
                      this.updateIFProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'K&L': {
                      this.updateKLProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'PSAMRIS': {
                      this.updatePasmrisProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'WBMRI': {
                      this.updateWbmriProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'MOAKS': {
                      this.updateMoaksProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'CANDEN&SPARCC': {
                      this.updateCASProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'NOVADIP5': {
                      this.updateNovadipProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'LUGANO': {
                      this.updateLuganoProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'RECIST': {
                      this.updateRecistProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'MRANO': {
                      this.updateMRanoProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                    case 'HEEL': {
                      this.updateHeelProjectNumberOfReadings(project, config, configActive);
                      break;
                    }
                  }
                }
              });
            }
          });
          this.dataSource = new MatTableDataSource(this.projectList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.processError(allConfigsResponse);
        }
      }
    );
  }

  updateIFProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForIF > 0)) {
      this.readingIncidentialFindingsService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForIF = readingsCount;
            project.hasReadingAccessForIF =
              project.hasReadingAccessForIF || (
              project.numberOfAvailableReadingsForIF > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateKLProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    // KANDL_BASIC
    if (!(project.numberOfAvailableReadingsForKandL > 0) && !!config.readers.find(r => r.id === this.userId)) {
      this.readingKandlService.getNumberOfAvailableBasicReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForKandL = readingsCount;
            project.hasReadingAccessForKandL =
              project.hasReadingAccessForKandL ||
              (project.numberOfAvailableReadingsForKandL > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
    // KANDL_ADJUDICATION
    if (!(project.numberOfAvailableReadingsForKandLAdjudication > 0)) {
      if (ReadingVersion.KANDL_ADJUDICATION === config.readingVersion && config.adjudicationReader === this.userId) {
        this.readingKandlService.getNumberOfAvailableAdjudicationReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              const readingsCount = response.data;
              project.numberOfAvailableReadingsForKandLAdjudication = readingsCount;
              project.hasReadingAccessForKandLAdjudication =
                project.hasReadingAccessForKandLAdjudication ||
                (project.numberOfAvailableReadingsForKandLAdjudication > 0 || isConfigActive);
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
  }

  updatePasmrisProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    // PSAMRIS_BASIC
    if (!(project.numberOfAvailableReadingsForPSAMRIS > 0)) {
      this.readingPsamrisService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForPSAMRIS = readingsCount;
            project.hasReadingAccessForPsAMRIS =
              project.hasReadingAccessForPsAMRIS ||
              (project.numberOfAvailableReadingsForPSAMRIS > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
    // PSAMRIS_ADJUDICATION
    if (!(project.numberOfAvailableReadingsForPSAMRISAdjudication > 0)) {
      if (ReadingVersion.PSAMRIS_ADJUDICATION === config.readingVersion && config.adjudicationReader === this.userId) {
        this.readingPsamrisService.getNumberOfAvailableAdjudicationReadings(project.id, this.userId).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              const readingsCount = response.data;
              project.numberOfAvailableReadingsForPSAMRISAdjudication = readingsCount;
              project.hasReadingAccessForPsAMRISAdjudication =
                project.hasReadingAccessForPsAMRISAdjudication ||
                (project.numberOfAvailableReadingsForPSAMRISAdjudication > 0 || isConfigActive);
            } else {
              this.processError(response);
            }
          }
        );
      }
    }
  }

  // TODO development & testing
  updateWbmriProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    // WBMRI_BASIC
    project.hasReadingAccessForWBMRI = true;
    project.numberOfAvailableReadingsForWBMRI = 1;

    // WBMRI_ADJUDICATION
    project.hasReadingAccessForWBMRIAdjudication = true;
    project.numberOfAvailableReadingsForWBMRIAdjudication = 1;
  }

  updateMoaksProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForMOAKS > 0)) {
      this.readingMoaksService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForMOAKS = readingsCount;
            project.hasReadingAccessForMOAKS =
              project.hasReadingAccessForMOAKS ||
              (project.numberOfAvailableReadingsForMOAKS > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateCASProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForCandenSparcc > 0)) {
      this.readingCandenSparccService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForCandenSparcc = readingsCount;
            project.hasReadingAccessForCandenSparcc =
              project.hasReadingAccessForCandenSparcc ||
              (project.numberOfAvailableReadingsForCandenSparcc > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateNovadipProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForNovadip5 > 0)) {
      this.readingNovadip5Service.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForNovadip5 = readingsCount;
            project.hasReadingAccessForNovadip5 =
              project.hasReadingAccessForNovadip5 ||
              (project.numberOfAvailableReadingsForNovadip5 > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateLuganoProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForLugano > 0)) {
      this.readingLuganoService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForLugano = readingsCount;
            project.hasReadingAccessForLugano =
              project.hasReadingAccessForLugano ||
              (project.numberOfAvailableReadingsForLugano > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  updateRecistProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForRecist > 0)) {
      this.readingRecistService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForRecist = readingsCount;
            project.hasReadingAccessForRecist =
              project.hasReadingAccessForRecist ||
              (project.numberOfAvailableReadingsForRecist > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }
  updateMRanoProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForMRANO > 0)) {
      this.readingMRANOService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForMRANO = readingsCount;
            project.hasReadingAccessForMRANO =
              project.hasReadingAccessForMRANO ||
              (project.numberOfAvailableReadingsForMRANO > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }
  updateHeelProjectNumberOfReadings(project: ProjectData, config: any, isConfigActive: boolean) {
    if (!(project.numberOfAvailableReadingsForHeel > 0)) {
      this.readingHeelService.getNumberOfAvailableReadings(project.id, this.userId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readingsCount = response.data;
            project.numberOfAvailableReadingsForHeel = readingsCount;
            project.hasReadingAccessForHeel =
              project.hasReadingAccessForHeel ||
              (project.numberOfAvailableReadingsForHeel > 0 || isConfigActive);
          } else {
            this.processError(response);
          }
        }
      );
    }
  }

  processError(response: any) {
    this.toastOptions.title = 'ERROR ' + response.responseCode;
    this.toastOptions.msg = response.responseMessage;
    this.toastyService.error(this.toastOptions);
  }
}
