import {AnswerType} from './imaging-project-reading-if.component';

export class QuestionFormat {

  uuid?: string;
  questionText: string;
  answerType: AnswerType;
  questionTab: string;
  answeringOption1: string;
  answeringOption2: string;
  commentText: string;
  multiSelectAnswers: MultiselectAnswers[];
  qIndex?: number;
  constructor() {

  }

}
export interface MultiselectAnswers {
  title: string;
  commentRequired: boolean;
}
