import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {HttpClient, HttpParams} from '@angular/common/http';
import {CohortsModel} from '../_models/ImagingProject/ImagimgProjectCohorts/cohorts-model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/cohort';

@Injectable({
  providedIn: 'root'
})
export class CohortsService {

  constructor(private http: HttpClient) { }

  public findAll(): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public findAllByStudyId(studyId: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all/${studyId}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public findAllActiveByStudyId(studyId: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all-active/${studyId}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public getById(id: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find/${id}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public create(studyId: number, cohort: CohortsModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/${studyId}`;
    return this.http.post<BasicResponse<any>>(requestURL, cohort);
  }

  public updateStatus(cohort: CohortsModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/update-status/${cohort.id}?status=${cohort.status}`;
    const params = new HttpParams().set('status', cohort.status);
    return this.http.put<BasicResponse<any>>(requestURL,  { status: cohort.status});
  }

  public updateCohort(cohort: CohortsModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/${cohort.id}`;
    return this.http.put<BasicResponse<any>>(requestURL,  cohort);
  }

  public updateReadingConfigFlexibles(id: number, ids: number[]): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/update-reading-config-flexibles/${id}?ids=${ids}`;
    return this.http.put<BasicResponse<any>>(requestURL,  ids);
  }
}
