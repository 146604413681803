import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSQueriesVersion;
const SERVICE = 'msqueries-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class MissingSequenceLabelsService {

  constructor(private http: HttpClient) { }

  create(missingSequenceLabels: any, studyId: number) {
    return this.http.post(requestURL + '/missingSequenceLabels/' + studyId, missingSequenceLabels);
  }

  update(missingSequenceLabels: any, studyId: number) {
    return this.http.put(requestURL + '/missingSequenceLabels/' + studyId, missingSequenceLabels);
  }

  delete(missingSequenceLabelsId: number, studyId: number) {
    return this.http.delete(requestURL + '/missingSequenceLabels/' + missingSequenceLabelsId + '/' + studyId);
  }

  getMissingSequenceLabels(missingSequenceLabelsId: number, studyId: number) {
    return this.http.get(requestURL + '/missingSequenceLabels/' + missingSequenceLabelsId + '/' + studyId);
  }

  getMissingSequenceLabelsBySequenceLabelId(sequenceLabelId: number, studyId: number) {
    return this.http.get(requestURL + '/missingSequenceLabels/get-missing-sequence-labels-by-sequence-label-id/' + sequenceLabelId + '/' + studyId);
  }

  getMissingSequenceLabelsByIds(missingSequenceLabelsIds: number[], studyId: number) {
    return this.http.put(requestURL + '/missingSequenceLabels/get-missing-sequence-labels-by-ids/' + studyId, missingSequenceLabelsIds);
  }

  getAll(studyId: number) {
    return this.http.get(requestURL + '/missingSequenceLabels/retrieve-all/' + studyId);
  }
  
}
