import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObjectStoreService } from '../interfaces/viewer/object-store-service';
import { ViewerAuthenticationService } from '../interfaces/viewer/viewer-authentication-service';
import { ResponseContentType } from '@angular/http';
import { ViewerAuditableContext } from 'src/app/_models/viewer/ViewerConfigModel';

const ROOT_URL = environment.Leadtools.rootURL;
const SERVICES = environment.Leadtools.services;

@Injectable({
  providedIn: 'root'
})
export class LeadToolsObjectStoreService implements ObjectStoreService {
  private _storeLocalUrl;

  constructor(private http: HttpClient, private authenticationService: ViewerAuthenticationService) {
    this._storeLocalUrl = `${ROOT_URL}/${SERVICES.objectStoreLocalServiceName}`;
  }

  public storeAnnotations(
    seriesInstanceUID: string, psSOPInstanceUID: string,
    annotationData: string, description: string, userData = null, person?,
    audit?: ViewerAuditableContext): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
    const data = {
      authenticationCookie: this.authenticationService.authenticationCode,
      seriesInstanceUID,
      psSOPInstanceUID,
      annotationData,
      description,
      userData,
      audit
    };

    if (person) {
      data['person'] = person;
    }

    return this.http.post(`${this._storeLocalUrl}/StoreAnnotationsToFile`, JSON.stringify(data), { headers });
  }

  public storeCurrentSliceAnnotations(
    seriesInstanceUID: string, psSOPInstanceUID: string, pageNumber: string,
    annotationData: string, description: string, sopInstanceUID: string, sliceCount: string, sliceNumber: string,
    frameCount: string, userData = null, person?, audit?: ViewerAuditableContext): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
    const data = {
      authenticationCookie: this.authenticationService.authenticationCode,
      seriesInstanceUID,
      psSOPInstanceUID,
      pageNumber,
      annotationData,
      description,
      sopInstanceUID,
      sliceCount,
      sliceNumber,
      frameCount,
      userData,
      person,
      audit
    };

    if (person) {
      data['person'] = person;
    }

    return this.http.post(`${this._storeLocalUrl}/StoreCurrentSliceAnnotationsToFile`, JSON.stringify(data), { headers });
  }

  public deleteAnnotations(sopInstanceUID: string, userData = null, person?, audit?: ViewerAuditableContext): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
    const data = {
      authenticationCookie: this.authenticationService.authenticationCode,
      sopInstanceUID,
      userData,
      audit
    };

    if (person) {
      data['person'] = person;
    }

    return this.http.post(`${this._storeLocalUrl}/DeletePresentationState`, JSON.stringify(data), { headers });
  }

}
