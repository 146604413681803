import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {AuditTrailsService, ImagingProjectService, UserService} from '../../../_services';
import {ResponseCode} from '../../../core/constants/response-code';
import {PatientService} from '../../../_services/patient.service';
import {forkJoin, Observable} from 'rxjs';
import {User} from '../../../_models/user';
import {StudyModel} from '../../../_models/ImagingProject/study-model';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';
import {FormBuilder, FormGroup} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';

export class AuditTrailsRequest {
  studyId?: number;
  category?: string;
  patientId?: number;
  objectName?: string;
  page?: number;
  size?: number;
}

@Component({
  selector: 'app-audit-trails-patient-view',
  templateUrl: './audit-trails-patient-view.component.html',
  styleUrls: ['./audit-trails-patient-view.component.css']
})
export class AuditTrailsPatientViewComponent implements OnInit {

  @Input() studyId: number;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  dataSource: MatTableDataSource<EventListItem> = new MatTableDataSource<EventListItem>();
  patients: any[];
  tags: any[];
  objects: any[];
  loading: boolean;
  study: StudyModel;
  filterForm: FormGroup;
  filteredOptions: Observable<any[]>;
  totalRows = 0;
  pageSize = 10;
  currentPage = 0;
  isFiltersSpecified = false;

  displayedColumnsSeries: string[] = ['patientCode', 'created', 'username', 'businessProcess', 'object', 'tag', 'action', 'status', 'details'];
  processes: string[] = ['Upload', 'QC', 'Reading', 'Report', 'Sponsor'];
  pageSizeOptions = [10, 15, 20, 50, 100];

  constructor(
    private auditTrailsService: AuditTrailsService,
    private patientService: PatientService,
    private userService: UserService,
    private store: Store,
    private imagingProjectService: ImagingProjectService,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.imagingProjectService.getStudy(this.studyId).subscribe(studyResult => {
      this.study = studyResult.data;
      localStorage.setItem('project', JSON.stringify(this.study));
    });
    this.store.dispatch(new SetPageHeaderTitle('Audit Trails'));
    this.resetFilters();
    this.dataSource.paginator = this.paginator;
    this.filterForm.valueChanges.subscribe(value => {
      this.isFiltersSpecified = value.patient || (value.object && value.object.length > 0);
    });
    this.initData(this.getBasicRequest());
  }

  resetFilters() {
    this.filterForm.reset();
    this.isFiltersSpecified = false;
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.onApplyFilters();
  }

  createForm() {
    this.filterForm = this.fb.group({
      patient: {},
      process: {},
      object: {},
      tag: {},
    });
  }

  private setLoading(loading) {
    this.loading = loading;
  }

  initData(request: any) {
    const data: EventListItem[] = [];
    this.setLoading(true);
    const serviceSources = [
      this.patientService.getPatientsByStudyId(this.studyId),
      this.auditTrailsService.getEvents(request),
      this.auditTrailsService.getDistinctObjectNames(this.studyId)
    ];

    forkJoin(serviceSources).subscribe(([patientsData, eventsData, objectNamesData]) => {
      if (patientsData.responseCode === ResponseCode.OK && eventsData.responseCode === ResponseCode.OK
        && objectNamesData.responseCode === ResponseCode.OK) {
        this.patients = patientsData.data;
        if (eventsData.data.data && eventsData.data.data.length > 0) {
          this.objects = objectNamesData.data;
          const usersIds = eventsData.data.data.map(event => event.userId);
          this.userService.getByListIds(usersIds).subscribe((userData) => {
            eventsData.data.data.forEach((event) => {
              const e: EventListItem = {
                id: event.id,
                patientCode: this.getPatientCode(event.patientId, patientsData.data),
                action: event.action?.name,
                businessProcess: '',
                created: event.created,
                object: event.objectName,
                status: event.status?.name,
                tag: '',
                username: this.getUsername(event.userId, userData.data)
              };
              data.push(e);
            });
            this.dataSource = new MatTableDataSource<EventListItem>(data.sort(this.sortByPatientCode));
            this.totalRows = eventsData.data.total;
          });
        } else {
          this.dataSource = new MatTableDataSource<EventListItem>();
          this.paginator.pageIndex = 0;
          this.totalRows = 0;
        }
        this.filteredOptions = this.filterForm.controls.patient.valueChanges.pipe(
          startWith(''),
          map(value => (typeof value === 'string' ? value : value.patientCode)),
          map(name => (name ? this._filter(name) : this.patients.slice())),
        );
      }
      this.setLoading(false);
    });
  }

  sortByPatientCode(a: any, b: any) {
    if (!a.patientCode && !b.patientCode) {
      return 0;
    }
    if (!a.patientCode) {
      return 1;
    }
    if (!b.patientCode) {
      return -1;
    }
    const patientCodeA = a.patientCode.toUpperCase();
    const patientCodeB = b.patientCode.toUpperCase();
    if (patientCodeA < patientCodeB) {
      return -1;
    }
    if (patientCodeA > patientCodeB) {
      return 1;
    }
    return 0;
  }

  private getPatientCode(patientId: number, patientsData) {
    let patientCode;
    patientsData.forEach((patient) => {
      if (patient.id === patientId) {
        patientCode = patient.patientCode;
      }
    });
    return patientCode;
  }

  navigateToDetails(id) {

  }

  private getUsername(userId: number, userData: User[]) {
    let userName;
    userData.forEach((user) => {
      if (user.id === userId) {
        userName = user.userName;
      }
    });
    return userName;
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.patients.filter(option => option.patientCode.toLowerCase().includes(filterValue));
  }

  displayFn(patient: any): string {
    return patient && patient.patinetCode ? patient.patinetCode : '';
  }

  onApplyFilters() {
    const request: AuditTrailsRequest = this.getBasicRequest();
    if (this.filterForm.get('patient').value) {
      const patient = this.patients.filter(p => p.patientCode === this.filterForm.controls.patient.value)[0];
      request.patientId = patient.id;
    }
    if (this.filterForm.get('object').value && this.filterForm.get('object').value.length > 0) {
      request.objectName = this.filterForm.get('object').value.join();
    }
    this.initData(request);
  }

  onResetFilters() {
    this.resetFilters();
    this.initData(this.getBasicRequest());
  }

  getBasicRequest() {
    const request: AuditTrailsRequest = {
      studyId: this.studyId,
      page: this.currentPage,
      size: this.pageSize
    };
    return request;
  }
}

interface EventListItem {
  id: number;
  patientCode: string;
  created: Date;
  username: string;
  businessProcess: string;
  object: string;
  status: string;
  tag: string;
  action: string;
}
