import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {StudyService} from '../../../_services';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';


@Component({
  selector: 'app-dashboard-container',
  templateUrl: './dashboard-container.component.html',
  styleUrls: ['./dashboard-container.component.css']
})
export class DashboardContainerComponent implements OnInit {
  studyId: number;
  dashboardUrl: SafeResourceUrl;

  constructor(private route: ActivatedRoute,
              private studyService: StudyService,
              private sanitizer: DomSanitizer,
              private store: Store) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Project dashboard'));
    this.studyId = parseInt(this.route.snapshot.params.studyId, 10);
    this.studyService.getByIdWithoutRelations(this.studyId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.dashboardUrl = this.sanitizer.bypassSecurityTrustResourceUrl(response.data.dashboardUrl);
        }
      }
    );
  }

}
