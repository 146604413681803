import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MoaksVisitModel} from '../../../../../_models/MOAKS/moaks-visit-model';
import {MoaksOsteophytesScoringModel} from '../../../../../_models/MOAKS/moaks-osteophytes-scoring-model';
import { SharedService } from '../../moaks-basic-form/shared.service';

@Component({
    selector: 'app-moaks-osteophytes-scoring-form',
    templateUrl: './moaks-osteophytes-scoring-form.component.html',
    styleUrls: ['./moaks-osteophytes-scoring-form.component.css']
})
export class MoaksOsteophytesScoringFormComponent implements OnInit, OnDestroy {

    ostScores = ['0', '1', '2', '3', 'U'];
    form: FormGroup;
    tableDataSource: MatTableDataSource<MoaksOsteophytesScoringModel>;
    tableColumns = ['Name', 'Score'];
    @Input()
    get visit(): MoaksVisitModel { return this._visit; }
    set visit(visit: MoaksVisitModel) {
        this._visit = visit;
        if (this.form) { // ngOnInit() will init first time
            this.init();
        }
    }
    private _visit: MoaksVisitModel;

    @Input() uid: string;

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
    }

    ngOnInit(): void {
        this.tableDataSource = new MatTableDataSource<MoaksOsteophytesScoringModel>();
        const scoringFormControl = this.fb.array([]);
        this._visit.osteophytesScorings.forEach((scoring) => {
            scoringFormControl.push(this.fb.group({
                id: ['', [Validators.required]],
                code: ['', [Validators.required]],
                region: ['', [Validators.required]],
                score: ['', [Validators.required]]
            }));
        });

        this.form = this.fb.group({
            scorings: scoringFormControl
        });
        this.init();
        this.fillEmptyCellsWithZero();

        this.sharedService.sync(this.uid, (rowIndex, valueName, value) => {
          this._visit.osteophytesScorings[rowIndex][valueName] = value;
          if (this.form) {
            (this.form.get('scorings') as FormArray).controls[rowIndex].get(valueName).setValue(value);
          }
        });
    }

    shouldDisable(){
      return (this._visit.status ||'') .toLowerCase() === 'done';
    }


    ngOnDestroy(): void {
        this.setSubmitData();
        this.sharedService.messageSource.next(null);
    }

    init(): void {
        this.tableDataSource.data = this._visit.osteophytesScorings;

        let index = 0;
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.tableDataSource.data[index];
            formGroup.get('id').setValue(scoring.id);
            formGroup.get('code').setValue(scoring.code);
            formGroup.get('region').setValue(scoring.region);
            formGroup.get('score').setValue(scoring.score);
            if (this.shouldDisable()) {
              formGroup.disable();
            }
            index++;
        });
    }

    fillEmptyCellsWithZero(): void {
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            if (!formGroup.get('score').value) {
                formGroup.get('score').setValue('0');
            }
        });
    }

    reset(): void {
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            formGroup.get('score').reset();
            formGroup.get('id').reset();
            formGroup.get('code').reset();
            formGroup.get('region').reset();
        });
    }

    setSubmitData() {
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.visit.osteophytesScorings.find(value => value.id === formGroup.get('id').value);
            scoring.score = formGroup.get('score').value;
        });
    }

    duplicateValue(rowIndex, item) {
      this.setSubmitData();
      const uidIndex = this.uid.replace(/\D+/g, '');
      const val = this._visit.osteophytesScorings[rowIndex][item];
      this.sharedService.messageSource.next([rowIndex, item, val, uidIndex]);
    }
}
