export enum DicomTag {

    // *****************************************************************************************************************************
    // *** 0002
    // *****************************************************************************************************************************
        FileMetaInformationGroupLength                         ="00020000",
        FileMetaInformationVersion                             ="00020001",
        MediaStorageSOPClassUID                                ="00020002",
        MediaStorageSOPInstanceUID                             ="00020003",
        TransferSyntaxUID                                      ="00020010",
        ImplementationClassUID                                 ="00020012",
        ImplementationVersionName                              ="00020013",
        SourceApplicationEntityTitle                           ="00020016",
        SendingApplicationEntityTitle                          ="00020017",
        ReceivingApplicationEntityTitle                        ="00020018",
        PrivateInformationCreatorUID                           ="00020100",
        PrivateInformation                                     ="00020102",
    
    // *****************************************************************************************************************************
    // *** 0004
    // *****************************************************************************************************************************
        FileSetID                                              ="00041130",
        FileSetDescriptorFileID                                ="00041141",
        SpecificCharacterSetOfFileSetDescriptorFile            ="00041142",
        OffsetFirstRootDirectory                               ="00041200",
        OffsetLastRootDirectory                                ="00041202",
        FileSetConsistencyFlag                                 ="00041212",
        DirectoryRecordSequence                                ="00041220",
        OffsetNextDirectory                                    ="00041400",
        RecordInUseFlag                                        ="00041410",
        OffsetChildDirectory                                   ="00041420",
        DirectoryRecordType                                    ="00041430",
        PrivateRecordUID                                       ="00041432",
        ReferencedFileID                                       ="00041500",
        MRDRDirectoryRecordOffset                              ="00041504",   // Retired
        ReferencedSOPClassUIDInFile                            ="00041510",
        ReferencedSOPInstanceUIDInFile                         ="00041511",
        ReferencedTransferSyntaxUIDInFile                      ="00041512",
        ReferencedRelatedGeneralSOPClassUIDInFile              ="0004151A",
        NumberOfReferences                                     ="00041600",   // Retired
    
    // *****************************************************************************************************************************
    // *** 0008
    // *****************************************************************************************************************************
        LengthToEnd                                            ="00080001",   // Retired
        SpecificCharacterSet                                   ="00080005",
        LanguageCodeSequence                                   ="00080006",
        ImageType                                              ="00080008",
        RecognitionCode                                        ="00080010",   // Retired
        InstanceCreationDate                                   ="00080012",
        InstanceCreationTime                                   ="00080013",
        InstanceCreatorUID                                     ="00080014",
        InstanceCoercionDateTime                               ="00080015",
        SOPClassUID                                            ="00080016",
        SOPInstanceUID                                         ="00080018",
        RelatedGeneralSOPClassUID                              ="0008001A",
        OriginalSpecializedSOPClassUID                         ="0008001B",
        StudyDate                                              ="00080020",
        SeriesDate                                             ="00080021",
        AcquisitionDate                                        ="00080022",
        ContentDate                                            ="00080023",
        OverlayDate                                            ="00080024",   // Retired
        CurveDate                                              ="00080025",   // Retired
        AcquisitionDateTime                                    ="0008002A",
        StudyTime                                              ="00080030",
        SeriesTime                                             ="00080031",
        AcquisitionTime                                        ="00080032",
        ContentTime                                            ="00080033",
        OverlayTime                                            ="00080034",   // Retired
        CurveTime                                              ="00080035",   // Retired
        DataSetType                                            ="00080040",   // Retired
        DataSetSubtype                                         ="00080041",   // Retired
        NuclearMedicineSeriesType                              ="00080042",   // Retired
        AccessionNumber                                        ="00080050",
        IssuerOfAccessionNumberSequence                        ="00080051",
        QueryRetrieveLevel                                     ="00080052",
        QueryRetrieveView                                      ="00080053",
        RetrieveAETitle                                        ="00080054",
        InstanceAvailability                                   ="00080056",
        FailedSOPInstanceUIDList                               ="00080058",
        Modality                                               ="00080060",
        ModalitiesInStudy                                      ="00080061",
        SOPClassesInStudy                                      ="00080062",
        ConversionType                                         ="00080064",
        PresentationIntentType                                 ="00080068",
        Manufacturer                                           ="00080070",
        InstitutionName                                        ="00080080",
        InstitutionAddress                                     ="00080081",
        InstitutionCodeSequence                                ="00080082",
        ReferringPhysicianName                                 ="00080090",
        ReferringPhysicianAddress                              ="00080092",
        ReferringPhysicianTelephoneNumbers                     ="00080094",
        ReferringPhysicianIdentificationSequence               ="00080096",
        ConsultingPhysicianName                                ="0008009C",
        ConsultingPhysicianIdentificationSequence              ="0008009D",
        CodeValue                                              ="00080100",
        ExtendedCodeValue                                      ="00080101",
        CodingSchemeDesignator                                 ="00080102",
        CodingSchemeVersion                                    ="00080103",
        CodeMeaning                                            ="00080104",
        MappingResource                                        ="00080105",
        ContextGroupVersion                                    ="00080106",
        ContextGroupLocalVersion                               ="00080107",
        ExtendedCodeMeaning                                    ="00080108",
        ContextGroupExtensionFlag                              ="0008010B",
        CodingSchemeUID                                        ="0008010C",
        ContextGroupExtensionCreatorUID                        ="0008010D",
        ContextIdentifier                                      ="0008010F",
        CodingSchemeIdentificationSequence                     ="00080110",
        CodingSchemeRegistry                                   ="00080112",
        CodingSchemeExternalID                                 ="00080114",
        CodingSchemeName                                       ="00080115",
        CodingSchemeResponsibleOrganization                    ="00080116",
        ContextUID                                             ="00080117",
        MappingResourceUID                                     ="00080118",
        LongCodeValue                                          ="00080119",
        URNCodeValue                                           ="00080120",
        EquivalentCodeSequence                                 ="00080121",
        TimezoneOffsetFromUTC                                  ="00080201",
        PrivateDataElementCharacteristicsSequence              ="00080300",
        PrivateGroupReference                                  ="00080301",
        PrivateCreatorReference                                ="00080302",
        BlockIdentifyingInformationStatus                      ="00080303",
        NonidentifyingPrivateElements                          ="00080304",
        DeidentificationActionSequence                         ="00080305",
        IdentifyingPrivateElements                             ="00080306",
        DeidentificationAction                                 ="00080307",
        NetworkID                                              ="00081000",   // Retired
        StationName                                            ="00081010",
        StudyDescription                                       ="00081030",
        ProcedureCodeSequence                                  ="00081032",
        SeriesDescription                                      ="0008103E",
        SeriesDescriptionCodeSequence                          ="0008103F",
        InstitutionalDepartmentName                            ="00081040",
        PhysicianOfRecord                                      ="00081048",
        PhysicianOfRecordIdentificationSequence                ="00081049",
        PerformingPhysicianName                                ="00081050",
        PerformingPhysicianIdentificationSequence              ="00081052",
        NameOfPhysicianReadingStudy                            ="00081060",
        PhysicianReadingStudyIdentificationSequence            ="00081062",
        OperatorName                                           ="00081070",
        OperatorIdentificationSequence                         ="00081072",
        AdmittingDiagnosesDescription                          ="00081080",
        AdmittingDiagnosesCodeSequence                         ="00081084",
        ManufacturerModelName                                  ="00081090",
        ReferencedResultsSequence                              ="00081100",   // Retired
        ReferencedStudySequence                                ="00081110",
        ReferencedPerformedProcedureStepSequence               ="00081111",
        ReferencedSeriesSequence                               ="00081115",
        ReferencedPatientSequence                              ="00081120",
        ReferencedVisitSequence                                ="00081125",
        ReferencedOverlaySequence                              ="00081130",   // Retired
        ReferencedStereometricInstanceSequence                 ="00081134",
        ReferencedWaveformSequence                             ="0008113A",
        ReferencedImageSequence                                ="00081140",
        ReferencedCurveSequence                                ="00081145",   // Retired
        ReferencedInstanceSequence                             ="0008114A",
        ReferencedRealWorldValueMappingInstanceSequence        ="0008114B",
        ReferencedSOPClassUID                                  ="00081150",
        ReferencedSOPInstanceUID                               ="00081155",
        SOPClassesSupported                                    ="0008115A",
        ReferencedFrameNumber                                  ="00081160",
        SimpleFrameList                                        ="00081161",
        CalculatedFrameList                                    ="00081162",
        TimeRange                                              ="00081163",
        FrameExtractionSequence                                ="00081164",
        MultiFrameSourceSOPInstanceUID                         ="00081167",
        RetrieveURL                                            ="00081190",
        TransactionUID                                         ="00081195",
        WarningReason                                          ="00081196",
        FailureReason                                          ="00081197",
        FailedSOPSequence                                      ="00081198",
        ReferencedSOPSequence                                  ="00081199",
        StudiesContainingOtherReferencedInstancesSequence      ="00081200",
        RelatedSeriesSequence                                  ="00081250",
        LossyImageCompressionRetired                           ="00082110",   // Retired
        DerivationDescription                                  ="00082111",
        SourceImageSequence                                    ="00082112",
        StageName                                              ="00082120",
        StageNumber                                            ="00082122",
        NumberOfStages                                         ="00082124",
        ViewName                                               ="00082127",
        ViewNumber                                             ="00082128",
        NumberOfEventTimers                                    ="00082129",
        NumberOfViewsInStage                                   ="0008212A",
        EventElapsedTime                                       ="00082130",
        EventTimerName                                         ="00082132",
        EventTimerSequence                                     ="00082133",
        EventTimeOffset                                        ="00082134",
        EventCodeSequence                                      ="00082135",
        StartTrim                                              ="00082142",
        StopTrim                                               ="00082143",
        RecommendedDisplayFrameRate                            ="00082144",
        TransducerPosition                                     ="00082200",   // Retired
        TransducerOrientation                                  ="00082204",   // Retired
        AnatomicStructure                                      ="00082208",   // Retired
        AnatomicRegionSequence                                 ="00082218",
        AnatomicRegionModifierSequence                         ="00082220",
        PrimaryAnatomicStructureSequence                       ="00082228",
        AnatomicStructureSpaceOrRegionSequence                 ="00082229",
        PrimaryAnatomicStructureModifierSequence               ="00082230",
        TransducerPositionSequence                             ="00082240",   // Retired
        TransducerPositionModifierSequence                     ="00082242",   // Retired
        TransducerOrientationSequence                          ="00082244",   // Retired
        TransducerOrientationModifierSequence                  ="00082246",   // Retired
        AnatomicStructureSpaceOrRegionCodeSequenceTrial        ="00082251",   // Retired
        AnatomicPortalOfEntranceCodeSequenceTrial              ="00082253",   // Retired
        AnatomicApproachDirectionCodeSequenceTrial             ="00082255",   // Retired
        AnatomicPerspectiveDescriptionTrial                    ="00082256",   // Retired
        AnatomicPerspectiveCodeSequenceTrial                   ="00082257",   // Retired
        AnatomicLocationOfExaminingInstrumentDescriptionTrial  ="00082258",   // Retired
        AnatomicLocationOfExaminingInstrumentCodeSequenceTrial ="00082259",   // Retired
        AnatomicStructureSpaceOrRegionModifierCodeSequenceTrial ="0008225A",   // Retired
        OnAxisBackgroundAnatomicStructureCodeSequenceTrial     ="0008225C",   // Retired
        AlternateRepresentationSequence                        ="00083001",
        IrradiationEventUID                                    ="00083010",
        SourceIrradiationEventSequence                         ="00083011",
        RadiopharmaceuticalAdministrationEventUID              ="00083012",
        IdentifyingComments                                    ="00084000",   // Retired
        FrameType                                              ="00089007",
        ReferencedImageEvidenceSequence                        ="00089092",
        ReferencedRawDataSequence                              ="00089121",
        CreatorVersionUID                                      ="00089123",
        DerivationImageSequence                                ="00089124",
        SourceImageEvidenceSequence                            ="00089154",
        PixelPresentation                                      ="00089205",
        VolumetricProperties                                   ="00089206",
        VolumeBasedCalculationTechnique                        ="00089207",
        ComplexImageComponent                                  ="00089208",
        AcquisitionContrast                                    ="00089209",
        DerivationCodeSequence                                 ="00089215",
        ReferencedPresentationStateSequence                    ="00089237",
        ReferencedOtherPlaneSequence                           ="00089410",
        FrameDisplaySequence                                   ="00089458",
        RecommendedDisplayFrameRateInFloat                     ="00089459",
        SkipFrameRangeFlag                                     ="00089460",
    
    // *****************************************************************************************************************************
    // *** 0010
    // *****************************************************************************************************************************
        PatientName                                            ="00100010",
        PatientID                                              ="00100020",
        IssuerOfPatientID                                      ="00100021",
        TypeOfPatientID                                        ="00100022",
        IssuerOfPatientIDQualifiersSequence                    ="00100024",
        PatientBirthDate                                       ="00100030",
        PatientBirthTime                                       ="00100032",
        PatientSex                                             ="00100040",
        PatientInsurancePlanCodeSequence                       ="00100050",
        PatientPrimaryLanguageCodeSequence                     ="00100101",
        PatientPrimaryLanguageModifierCodeSequence             ="00100102",
        QualityControlSubject                                  ="00100200",
        QualityControlSubjectTypeCodeSequence                  ="00100201",
        OtherPatientIDs                                        ="00101000",
        OtherPatientNames                                      ="00101001",
        OtherPatientIDsSequence                                ="00101002",
        PatientBirthName                                       ="00101005",
        PatientAge                                             ="00101010",
        PatientSize                                            ="00101020",
        PatientSizeCodeSequence                                ="00101021",
        PatientWeight                                          ="00101030",
        PatientAddress                                         ="00101040",
        InsurancePlanIdentification                            ="00101050",   // Retired
        PatientMotherBirthName                                 ="00101060",
        MilitaryRank                                           ="00101080",
        BranchOfService                                        ="00101081",
        MedicalRecordLocator                                   ="00101090",
        ReferencedPatientPhotoSequence                         ="00101100",
        MedicalAlerts                                          ="00102000",
        Allergies                                              ="00102110",
        CountryOfResidence                                     ="00102150",
        RegionOfResidence                                      ="00102152",
        PatientTelephoneNumbers                                ="00102154",
        PatientTelecomInformation                              ="00102155",
        EthnicGroup                                            ="00102160",
        Occupation                                             ="00102180",
        SmokingStatus                                          ="001021A0",
        AdditionalPatientHistory                               ="001021B0",
        PregnancyStatus                                        ="001021C0",
        LastMenstrualDate                                      ="001021D0",
        PatientReligiousPreference                             ="001021F0",
        PatientSpeciesDescription                              ="00102201",
        PatientSpeciesCodeSequence                             ="00102202",
        PatientSexNeutered                                     ="00102203",
        AnatomicalOrientationType                              ="00102210",
        PatientBreedDescription                                ="00102292",
        PatientBreedCodeSequence                               ="00102293",
        BreedRegistrationSequence                              ="00102294",
        BreedRegistrationNumber                                ="00102295",
        BreedRegistryCodeSequence                              ="00102296",
        ResponsiblePerson                                      ="00102297",
        ResponsiblePersonRole                                  ="00102298",
        ResponsibleOrganization                                ="00102299",
        PatientComments                                        ="00104000",
        ExaminedBodyThickness                                  ="00109431",
    
    // *****************************************************************************************************************************
    // *** 0012
    // *****************************************************************************************************************************
        ClinicalTrialSponsorName                               ="00120010",
        ClinicalTrialProtocolID                                ="00120020",
        ClinicalTrialProtocolName                              ="00120021",
        ClinicalTrialSiteID                                    ="00120030",
        ClinicalTrialSiteName                                  ="00120031",
        ClinicalTrialSubjectID                                 ="00120040",
        ClinicalTrialSubjectReadingID                          ="00120042",
        ClinicalTrialTimePointID                               ="00120050",
        ClinicalTrialTimePointDescription                      ="00120051",
        ClinicalTrialCoordinatingCenterName                    ="00120060",
        PatientIdentityRemoved                                 ="00120062",
        DeIdentificationMethod                                 ="00120063",
        DeIdentificationMethodCodeSequence                     ="00120064",
        ClinicalTrialSeriesID                                  ="00120071",
        ClinicalTrialSeriesDescription                         ="00120072",
        ClinicalTrialProtocolEthicsCommitteeName               ="00120081",
        ClinicalTrialProtocolEthicsCommitteeApprovalNumber     ="00120082",
        ConsentForClinicalTrialUseSequence                     ="00120083",
        DistributionType                                       ="00120084",
        ConsentForDistributionFlag                             ="00120085",
    
    // *****************************************************************************************************************************
    // *** 0014
    // *****************************************************************************************************************************
        CADFileFormat                                          ="00140023",   // Retired
        ComponentReferenceSystem                               ="00140024",   // Retired
        ComponentManufacturingProcedure                        ="00140025",
        ComponentManufacturer                                  ="00140028",
        MaterialThickness                                      ="00140030",
        MaterialPipeDiameter                                   ="00140032",
        MaterialIsolationDiameter                              ="00140034",
        MaterialGrade                                          ="00140042",
        MaterialPropertiesDescription                          ="00140044",
        MaterialPropertiesFileFormat                           ="00140045",   // Retired
        MaterialNotes                                          ="00140046",
        ComponentShape                                         ="00140050",
        CurvatureType                                          ="00140052",
        OuterDiameter                                          ="00140054",
        InnerDiameter                                          ="00140056",
        ActualEnvironmentalConditions                          ="00141010",
        ExpiryDate                                             ="00141020",
        EnvironmentalConditions                                ="00141040",
        EvaluatorSequence                                      ="00142002",
        EvaluatorNumber                                        ="00142004",
        EvaluatorName                                          ="00142006",
        EvaluationAttempt                                      ="00142008",
        IndicationSequence                                     ="00142012",
        IndicationNumber                                       ="00142014",
        IndicationLabel                                        ="00142016",
        IndicationDescription                                  ="00142018",
        IndicationType                                         ="0014201A",
        IndicationDisposition                                  ="0014201C",
        IndicationROISequence                                  ="0014201E",
        IndicationPhysicalPropertySequence                     ="00142030",
        PropertyLabel                                          ="00142032",
        CoordinateSystemNumberOfAxes                           ="00142202",
        CoordinateSystemAxesSequence                           ="00142204",
        CoordinateSystemAxisDescription                        ="00142206",
        CoordinateSystemDataSetMapping                         ="00142208",
        CoordinateSystemAxisNumber                             ="0014220A",
        CoordinateSystemAxisType                               ="0014220C",
        CoordinateSystemAxisUnits                              ="0014220E",
        CoordinateSystemAxisValues                             ="00142210",
        CoordinateSystemTransformSequence                      ="00142220",
        TransformDescription                                   ="00142222",
        TransformNumberOfAxes                                  ="00142224",
        TransformOrderOfAxes                                   ="00142226",
        TransformedAxisUnits                                   ="00142228",
        CoordinateSystemTransformRotationAndScaleMatrix        ="0014222A",
        CoordinateSystemTransformTranslationMatrix             ="0014222C",
        InternalDetectorFrameTime                              ="00143011",
        NumberOfFramesIntegrated                               ="00143012",
        DetectorTemperatureSequence                            ="00143020",
        SensorName                                             ="00143022",
        HorizontalOffsetOfSensor                               ="00143024",
        VerticalOffsetOfSensor                                 ="00143026",
        SensorTemperature                                      ="00143028",
        DarkCurrentSequence                                    ="00143040",
        DarkCurrentCounts                                      ="00143050",
        GainCorrectionReferenceSequence                        ="00143060",
        AirCounts                                              ="00143070",
        KVUsedInGainCalibration                                ="00143071",
        MAUsedInGainCalibration                                ="00143072",
        NumberOfFramesUsedForIntegration                       ="00143073",
        FilterMaterialUsedInGainCalibration                    ="00143074",
        FilterThicknessUsedInGainCalibration                   ="00143075",
        DateOfGainCalibration                                  ="00143076",
        TimeOfGainCalibration                                  ="00143077",
        BadPixelImage                                          ="00143080",
        CalibrationNotes                                       ="00143099",
        PulserEquipmentSequence                                ="00144002",
        PulserType                                             ="00144004",
        PulserNotes                                            ="00144006",
        ReceiverEquipmentSequence                              ="00144008",
        AmplifierType                                          ="0014400A",
        ReceiverNotes                                          ="0014400C",
        PreAmplifierEquipmentSequence                          ="0014400E",
        PreAmplifierNotes                                      ="0014400F",
        TransmitTransducerSequence                             ="00144010",
        ReceiveTransducerSequence                              ="00144011",
        NumberOfElements                                       ="00144012",
        ElementShape                                           ="00144013",
        ElementDimensionA                                      ="00144014",
        ElementDimensionB                                      ="00144015",
        ElementPitchA                                          ="00144016",
        MeasuredBeamDimensionA                                 ="00144017",
        MeasuredBeamDimensionB                                 ="00144018",
        LocationOfMeasuredBeamDiameter                         ="00144019",
        NominalFrequency                                       ="0014401A",
        MeasuredCenterFrequency                                ="0014401B",
        MeasuredBandwidth                                      ="0014401C",
        ElementPitchB                                          ="0014401D",
        PulserSettingsSequence                                 ="00144020",
        PulseWidth                                             ="00144022",
        ExcitationFrequency                                    ="00144024",
        ModulationType                                         ="00144026",
        Damping                                                ="00144028",
        ReceiverSettingsSequence                               ="00144030",
        AcquiredSoundpathLength                                ="00144031",
        AcquisitionCompressionType                             ="00144032",
        AcquisitionSampleSize                                  ="00144033",
        RectifierSmoothing                                     ="00144034",
        DACSequence                                            ="00144035",
        DACType                                                ="00144036",
        DACGainPoints                                          ="00144038",
        DACTimePoints                                          ="0014403A",
        DACAmplitude                                           ="0014403C",
        PreAmplifierSettingsSequence                           ="00144040",
        TransmitTransducerSettingsSequence                     ="00144050",
        ReceiveTransducerSettingsSequence                      ="00144051",
        IncidentAngle                                          ="00144052",
        CouplingTechnique                                      ="00144054",
        CouplingMedium                                         ="00144056",
        CouplingVelocity                                       ="00144057",
        ProbeCenterLocationX                                   ="00144058",
        ProbeCenterLocationZ                                   ="00144059",
        SoundPathLength                                        ="0014405A",
        DelayLawIdentifier                                     ="0014405C",
        GateSettingsSequence                                   ="00144060",
        GateThreshold                                          ="00144062",
        VelocityOfSound                                        ="00144064",
        CalibrationSettingsSequence                            ="00144070",
        CalibrationProcedure                                   ="00144072",
        ProcedureVersion                                       ="00144074",
        ProcedureCreationDate                                  ="00144076",
        ProcedureExpirationDate                                ="00144078",
        ProcedureLastModifiedDate                              ="0014407A",
        CalibrationTime                                        ="0014407C",
        CalibrationDate                                        ="0014407E",
        ProbeDriveEquipmentSequence                            ="00144080",
        DriveType                                              ="00144081",
        ProbeDriveNotes                                        ="00144082",
        DriveProbeSequence                                     ="00144083",
        ProbeInductance                                        ="00144084",
        ProbeResistance                                        ="00144085",
        ReceiveProbeSequence                                   ="00144086",
        ProbeDriveSettingsSequence                             ="00144087",
        BridgeResistors                                        ="00144088",
        ProbeOrientationAngle                                  ="00144089",
        UserSelectedGainY                                      ="0014408B",
        UserSelectedPhase                                      ="0014408C",
        UserSelectedOffsetX                                    ="0014408D",
        UserSelectedOffsetY                                    ="0014408E",
        ChannelSettingsSequence                                ="00144091",
        ChannelThreshold                                       ="00144092",
        ScannerSettingsSequence                                ="0014409A",
        ScanProcedure                                          ="0014409B",
        TranslationRateX                                       ="0014409C",
        TranslationRateY                                       ="0014409D",
        ChannelOverlap                                         ="0014409F",
        ImageQualityIndicatorType                              ="001440A0",
        ImageQualityIndicatorMaterial                          ="001440A1",
        ImageQualityIndicatorSize                              ="001440A2",
        LINACEnergy                                            ="00145002",
        LINACOutput                                            ="00145004",
        ActiveAperture                                         ="00145100",
        TotalAperture                                          ="00145101",
        ApertureElevation                                      ="00145102",
        MainLobeAngle                                          ="00145103",
        MainRoofAngle                                          ="00145104",
        ConnectorType                                          ="00145105",
        WedgeModelNumber                                       ="00145106",
        WedgeAngleFloat                                        ="00145107",
        WedgeRoofAngle                                         ="00145108",
        WedgeElement1Position                                  ="00145109",
        WedgeMaterialVelocity                                  ="0014510A",
        WedgeMaterial                                          ="0014510B",
        WedgeOffsetZ                                           ="0014510C",
        WedgeOriginOffsetX                                     ="0014510D",
        WedgeTimeDelay                                         ="0014510E",
        WedgeName                                              ="0014510F",
        WedgeManufacturerName                                  ="00145110",
        WedgeDescription                                       ="00145111",
        NominalBeamAngle                                       ="00145112",
        WedgeOffsetX                                           ="00145113",
        WedgeOffsetY                                           ="00145114",
        WedgeTotalLength                                       ="00145115",
        WedgeInContactLength                                   ="00145116",
        WedgeFrontGap                                          ="00145117",
        WedgeTotalHeight                                       ="00145118",
        WedgeFrontHeight                                       ="00145119",
        WedgeRearHeight                                        ="0014511A",
        WedgeTotalWidth                                        ="0014511B",
        WedgeInContactWidth                                    ="0014511C",
        WedgeChamferHeight                                     ="0014511D",
        WedgeCurve                                             ="0014511E",
        RadiusAlongTheWedge                                    ="0014511F",
    
    // *****************************************************************************************************************************
    // *** 0018
    // *****************************************************************************************************************************
        ContrastBolusAgent                                     ="00180010",
        ContrastBolusAgentSequence                             ="00180012",
        ContrastBolusT1Relaxivity                              ="00180013",
        ContrastBolusAdministrationRouteSequence               ="00180014",
        BodyPartExamined                                       ="00180015",
        ScanningSequence                                       ="00180020",
        SequenceVariant                                        ="00180021",
        ScanOptions                                            ="00180022",
        MRAcquisitionType                                      ="00180023",
        SequenceName                                           ="00180024",
        AngioFlag                                              ="00180025",
        InterventionDrugInformationSequence                    ="00180026",
        InterventionDrugStopTime                               ="00180027",
        InterventionDrugDose                                   ="00180028",
        InterventionDrugCodeSequence                           ="00180029",
        AdditionalDrugSequence                                 ="0018002A",
        Radionuclide                                           ="00180030",   // Retired
        Radiopharmaceutical                                    ="00180031",
        EnergyWindowCenterline                                 ="00180032",   // Retired
        EnergyWindowTotalWidth                                 ="00180033",   // Retired
        InterventionDrugName                                   ="00180034",
        InterventionDrugStartTime                              ="00180035",
        InterventionSequence                                   ="00180036",
        TherapyType                                            ="00180037",   // Retired
        InterventionStatus                                     ="00180038",
        TherapyDescription                                     ="00180039",   // Retired
        InterventionDescription                                ="0018003A",
        CineRate                                               ="00180040",
        InitialCineRunState                                    ="00180042",
        SliceThickness                                         ="00180050",
        KVP                                                    ="00180060",
        CountsAccumulated                                      ="00180070",
        AcquisitionTerminationCondition                        ="00180071",
        EffectiveDuration                                      ="00180072",
        AcquisitionStartCondition                              ="00180073",
        AcquisitionStartConditionData                          ="00180074",
        AcquisitionTerminationConditionData                    ="00180075",
        RepetitionTime                                         ="00180080",
        EchoTime                                               ="00180081",
        InversionTime                                          ="00180082",
        NumberOfAverages                                       ="00180083",
        ImagingFrequency                                       ="00180084",
        ImagedNucleus                                          ="00180085",
        EchoNumber                                             ="00180086",
        MagneticFieldStrength                                  ="00180087",
        SpacingBetweenSlices                                   ="00180088",
        NumberOfPhaseEncodingSteps                             ="00180089",
        DataCollectionDiameter                                 ="00180090",
        EchoTrainLength                                        ="00180091",
        PercentSampling                                        ="00180093",
        PercentPhaseFieldOfView                                ="00180094",
        PixelBandwidth                                         ="00180095",
        DeviceSerialNumber                                     ="00181000",
        DeviceUID                                              ="00181002",
        DeviceID                                               ="00181003",
        PlateID                                                ="00181004",
        GeneratorID                                            ="00181005",
        GridID                                                 ="00181006",
        CassetteID                                             ="00181007",
        GantryID                                               ="00181008",
        SecondaryCaptureDeviceID                               ="00181010",
        HardcopyCreationDeviceID                               ="00181011",   // Retired
        DateOfSecondaryCapture                                 ="00181012",
        TimeOfSecondaryCapture                                 ="00181014",
        SecondaryCaptureDeviceManufacturer                     ="00181016",
        HardcopyDeviceManufacturer                             ="00181017",   // Retired
        SecondaryCaptureDeviceManufacturerModelName            ="00181018",
        SecondaryCaptureDeviceSoftwareVersions                 ="00181019",
        HardcopyDeviceSoftwareVersion                          ="0018101A",   // Retired
        HardcopyDeviceManufacturerModelName                    ="0018101B",   // Retired
        SoftwareVersion                                        ="00181020",
        VideoImageFormatAcquired                               ="00181022",
        DigitalImageFormatAcquired                             ="00181023",
        ProtocolName                                           ="00181030",
        ContrastBolusRoute                                     ="00181040",
        ContrastBolusVolume                                    ="00181041",
        ContrastBolusStartTime                                 ="00181042",
        ContrastBolusStopTime                                  ="00181043",
        ContrastBolusTotalDose                                 ="00181044",
        SyringeCounts                                          ="00181045",
        ContrastFlowRate                                       ="00181046",
        ContrastFlowDuration                                   ="00181047",
        ContrastBolusIngredient                                ="00181048",
        ContrastBolusIngredientConcentration                   ="00181049",
        SpatialResolution                                      ="00181050",
        TriggerTime                                            ="00181060",
        TriggerSourceOrType                                    ="00181061",
        NominalInterval                                        ="00181062",
        FrameTime                                              ="00181063",
        CardiacFramingType                                     ="00181064",
        FrameTimeVector                                        ="00181065",
        FrameDelay                                             ="00181066",
        ImageTriggerDelay                                      ="00181067",
        MultiplexGroupTimeOffset                               ="00181068",
        TriggerTimeOffset                                      ="00181069",
        SynchronizationTrigger                                 ="0018106A",
        SynchronizationChannel                                 ="0018106C",
        TriggerSamplePosition                                  ="0018106E",
        RadiopharmaceuticalRoute                               ="00181070",
        RadiopharmaceuticalVolume                              ="00181071",
        RadiopharmaceuticalStartTime                           ="00181072",
        RadiopharmaceuticalStopTime                            ="00181073",
        RadionuclideTotalDose                                  ="00181074",
        RadionuclideHalfLife                                   ="00181075",
        RadionuclidePositronFraction                           ="00181076",
        RadiopharmaceuticalSpecificActivity                    ="00181077",
        RadiopharmaceuticalStartDateTime                       ="00181078",
        RadiopharmaceuticalStopDateTime                        ="00181079",
        BeatRejectionFlag                                      ="00181080",
        LowRRValue                                             ="00181081",
        HighRRValue                                            ="00181082",
        IntervalsAcquired                                      ="00181083",
        IntervalsRejected                                      ="00181084",
        PVCRejection                                           ="00181085",
        SkipBeats                                              ="00181086",
        HeartRate                                              ="00181088",
        CardiacNumberOfImages                                  ="00181090",
        TriggerWindow                                          ="00181094",
        ReconstructionDiameter                                 ="00181100",
        DistanceSourceToDetector                               ="00181110",
        DistanceSourceToPatient                                ="00181111",
        EstimatedRadiographicMagnificationFactor               ="00181114",
        GantryDetectorTilt                                     ="00181120",
        GantryDetectorSlew                                     ="00181121",
        TableHeight                                            ="00181130",
        TableTraverse                                          ="00181131",
        TableMotion                                            ="00181134",
        TableVerticalIncrement                                 ="00181135",
        TableLateralIncrement                                  ="00181136",
        TableLongitudinalIncrement                             ="00181137",
        TableAngle                                             ="00181138",
        TableType                                              ="0018113A",
        RotationDirection                                      ="00181140",
        AngularPosition                                        ="00181141",   // Retired
        RadialPosition                                         ="00181142",
        ScanArc                                                ="00181143",
        AngularStep                                            ="00181144",
        CenterOfRotationOffset                                 ="00181145",
        RotationOffset                                         ="00181146",   // Retired
        FieldOfViewShape                                       ="00181147",
        FieldOfViewDimension                                   ="00181149",
        ExposureTime                                           ="00181150",
        XRayTubeCurrent                                        ="00181151",
        Exposure                                               ="00181152",
        ExposureInUAs                                          ="00181153",
        AveragePulseWidth                                      ="00181154",
        RadiationSetting                                       ="00181155",
        RectificationType                                      ="00181156",
        RadiationMode                                          ="0018115A",
        ImageAndFluoroscopyAreaDoseProduct                     ="0018115E",
        FilterType                                             ="00181160",
        TypeOfFilters                                          ="00181161",
        IntensifierSize                                        ="00181162",
        ImagerPixelSpacing                                     ="00181164",
        Grid                                                   ="00181166",
        GeneratorPower                                         ="00181170",
        CollimatorGridName                                     ="00181180",
        CollimatorType                                         ="00181181",
        FocalDistance                                          ="00181182",
        XFocusCenter                                           ="00181183",
        YFocusCenter                                           ="00181184",
        FocalSpot                                              ="00181190",
        AnodeTargetMaterial                                    ="00181191",
        BodyPartThickness                                      ="001811A0",
        CompressionForce                                       ="001811A2",
        PaddleDescription                                      ="001811A4",
        DateOfLastCalibration                                  ="00181200",
        TimeOfLastCalibration                                  ="00181201",
        DateTimeOfLastCalibration                              ="00181202",
        ConvolutionKernel                                      ="00181210",
        UpperLowerPixelValues                                  ="00181240",   // Retired
        ActualFrameDuration                                    ="00181242",
        CountRate                                              ="00181243",
        PreferredPlaybackSequencing                            ="00181244",
        ReceiveCoilName                                        ="00181250",
        TransmitCoilName                                       ="00181251",
        PlateType                                              ="00181260",
        PhosphorType                                           ="00181261",
        ScanVelocity                                           ="00181300",
        WholeBodyTechnique                                     ="00181301",
        ScanLength                                             ="00181302",
        AcquisitionMatrix                                      ="00181310",
        InPlanePhaseEncodingDirection                          ="00181312",
        FlipAngle                                              ="00181314",
        VariableFlipAngleFlag                                  ="00181315",
        SAR                                                    ="00181316",
        DBDt                                                   ="00181318",
        AcquisitionDeviceProcessingDescription                 ="00181400",
        AcquisitionDeviceProcessingCode                        ="00181401",
        CassetteOrientation                                    ="00181402",
        CassetteSize                                           ="00181403",
        ExposuresOnPlate                                       ="00181404",
        RelativeXRayExposure                                   ="00181405",
        ExposureIndex                                          ="00181411",
        TargetExposureIndex                                    ="00181412",
        DeviationIndex                                         ="00181413",
        ColumnAngulation                                       ="00181450",
        TomoLayerHeight                                        ="00181460",
        TomoAngle                                              ="00181470",
        TomoTime                                               ="00181480",
        TomoType                                               ="00181490",
        TomoClass                                              ="00181491",
        NumberOfTomosynthesisSourceImages                      ="00181495",
        PositionerMotion                                       ="00181500",
        PositionerType                                         ="00181508",
        PositionerPrimaryAngle                                 ="00181510",
        PositionerSecondaryAngle                               ="00181511",
        PositionerPrimaryAngleIncrement                        ="00181520",
        PositionerSecondaryAngleIncrement                      ="00181521",
        DetectorPrimaryAngle                                   ="00181530",
        DetectorSecondaryAngle                                 ="00181531",
        ShutterShape                                           ="00181600",
        ShutterLeftVerticalEdge                                ="00181602",
        ShutterRightVerticalEdge                               ="00181604",
        ShutterUpperHorizontalEdge                             ="00181606",
        ShutterLowerHorizontalEdge                             ="00181608",
        CenterOfCircularShutter                                ="00181610",
        RadiusOfCircularShutter                                ="00181612",
        VerticesOfThePolygonalShutter                          ="00181620",
        ShutterPresentationValue                               ="00181622",
        ShutterOverlayGroup                                    ="00181623",
        ShutterPresentationColorCIELabValue                    ="00181624",
        CollimatorShape                                        ="00181700",
        CollimatorLeftVerticalEdge                             ="00181702",
        CollimatorRightVerticalEdge                            ="00181704",
        CollimatorUpperHorizontalEdge                          ="00181706",
        CollimatorLowerHorizontalEdge                          ="00181708",
        CenterOfCircularCollimator                             ="00181710",
        RadiusOfCircularCollimator                             ="00181712",
        VerticesOfThePolygonalCollimator                       ="00181720",
        AcquisitionTimeSynchronized                            ="00181800",
        TimeSource                                             ="00181801",
        TimeDistributionProtocol                               ="00181802",
        NTPSourceAddress                                       ="00181803",
        PageNumberVector                                       ="00182001",
        FrameLabelVector                                       ="00182002",
        FramePrimaryAngleVector                                ="00182003",
        FrameSecondaryAngleVector                              ="00182004",
        SliceLocationVector                                    ="00182005",
        DisplayWindowLabelVector                               ="00182006",
        NominalScannedPixelSpacing                             ="00182010",
        DigitizingDeviceTransportDirection                     ="00182020",
        RotationOfScannedFilm                                  ="00182030",
        BiopsyTargetSequence                                   ="00182041",
        TargetUID                                              ="00182042",
        LocalizingCursorPosition                               ="00182043",
        CalculatedTargetPosition                               ="00182044",
        TargetLabel                                            ="00182045",
        DisplayedZValue                                        ="00182046",
        IVUSAcquisition                                        ="00183100",
        IVUSPullbackRate                                       ="00183101",
        IVUSGatedRate                                          ="00183102",
        IVUSPullbackStartFrameNumber                           ="00183103",
        IVUSPullbackStopFrameNumber                            ="00183104",
        LesionNumber                                           ="00183105",
        AcquisitionComments                                    ="00184000",   // Retired
        OutputPower                                            ="00185000",
        TransducerData                                         ="00185010",
        FocusDepth                                             ="00185012",
        ProcessingFunction                                     ="00185020",
        PostprocessingFunction                                 ="00185021",   // Retired
        MechanicalIndex                                        ="00185022",
        BoneThermalIndex                                       ="00185024",
        CranialThermalIndex                                    ="00185026",
        SoftTissueThermalIndex                                 ="00185027",
        SoftTissueFocusThermalIndex                            ="00185028",
        SoftTissueSurfaceThermalIndex                          ="00185029",
        DynamicRange                                           ="00185030",   // Retired
        TotalGain                                              ="00185040",   // Retired
        DepthOfScanField                                       ="00185050",
        PatientPosition                                        ="00185100",
        ViewPosition                                           ="00185101",
        ProjectionEponymousNameCodeSequence                    ="00185104",
        ImageTransformationMatrix                              ="00185210",   // Retired
        ImageTranslationVector                                 ="00185212",   // Retired
        Sensitivity                                            ="00186000",
        SequenceOfUltrasoundRegions                            ="00186011",
        RegionSpatialFormat                                    ="00186012",
        RegionDataType                                         ="00186014",
        RegionFlags                                            ="00186016",
        RegionLocationMinX0                                    ="00186018",
        RegionLocationMinY0                                    ="0018601A",
        RegionLocationMaxX1                                    ="0018601C",
        RegionLocationMaxY1                                    ="0018601E",
        ReferencePixelX0                                       ="00186020",
        ReferencePixelY0                                       ="00186022",
        PhysicalUnitsXDirection                                ="00186024",
        PhysicalUnitsYDirection                                ="00186026",
        ReferencePixelPhysicalValueX                           ="00186028",
        ReferencePixelPhysicalValueY                           ="0018602A",
        PhysicalDeltaX                                         ="0018602C",
        PhysicalDeltaY                                         ="0018602E",
        TransducerFrequency                                    ="00186030",
        TransducerType                                         ="00186031",
        PulseRepetitionFrequency                               ="00186032",
        DopplerCorrectionAngle                                 ="00186034",
        SteeringAngle                                          ="00186036",
        DopplerSampleVolumeXPositionRetired                    ="00186038",   // Retired
        DopplerSampleVolumeXPosition                           ="00186039",
        DopplerSampleVolumeYPositionRetired                    ="0018603A",   // Retired
        DopplerSampleVolumeYPosition                           ="0018603B",
        TMLinePositionX0Retired                                ="0018603C",   // Retired
        TMLinePositionX0                                       ="0018603D",
        TMLinePositionY0Retired                                ="0018603E",   // Retired
        TMLinePositionY0                                       ="0018603F",
        TMLinePositionX1Retired                                ="00186040",   // Retired
        TMLinePositionX1                                       ="00186041",
        TMLinePositionY1Retired                                ="00186042",   // Retired
        TMLinePositionY1                                       ="00186043",
        PixelComponentOrganization                             ="00186044",
        PixelComponentMask                                     ="00186046",
        PixelComponentRangeStart                               ="00186048",
        PixelComponentRangeStop                                ="0018604A",
        PixelComponentPhysicalUnits                            ="0018604C",
        PixelComponentDataType                                 ="0018604E",
        NumberOfTableBreakPoints                               ="00186050",
        TableOfXBreakPoints                                    ="00186052",
        TableOfYBreakPoints                                    ="00186054",
        NumberOfTableEntries                                   ="00186056",
        TableOfPixelValues                                     ="00186058",
        TableOfParameterValues                                 ="0018605A",
        RWaveTimeVector                                        ="00186060",
        DetectorConditionsNominalFlag                          ="00187000",
        DetectorTemperature                                    ="00187001",
        DetectorType                                           ="00187004",
        DetectorConfiguration                                  ="00187005",
        DetectorDescription                                    ="00187006",
        DetectorMode                                           ="00187008",
        DetectorID                                             ="0018700A",
        DateOfLastDetectorCalibration                          ="0018700C",
        TimeOfLastDetectorCalibration                          ="0018700E",
        ExposuresOnDetectorSinceLastCalibration                ="00187010",
        ExposuresOnDetectorSinceManufactured                   ="00187011",
        DetectorTimeSinceLastExposure                          ="00187012",
        DetectorActiveTime                                     ="00187014",
        DetectorActivationOffsetFromExposure                   ="00187016",
        DetectorBinning                                        ="0018701A",
        DetectorElementPhysicalSize                            ="00187020",
        DetectorElementSpacing                                 ="00187022",
        DetectorActiveShape                                    ="00187024",
        DetectorActiveDimension                                ="00187026",
        DetectorActiveOrigin                                   ="00187028",
        DetectorManufacturerName                               ="0018702A",
        DetectorManufacturerModelName                          ="0018702B",
        FieldOfViewOrigin                                      ="00187030",
        FieldOfViewRotation                                    ="00187032",
        FieldOfViewHorizontalFlip                              ="00187034",
        PixelDataAreaOriginRelativeToFOV                       ="00187036",
        PixelDataAreaRotationAngleRelativeToFOV                ="00187038",
        GridAbsorbingMaterial                                  ="00187040",
        GridSpacingMaterial                                    ="00187041",
        GridThickness                                          ="00187042",
        GridPitch                                              ="00187044",
        GridAspectRatio                                        ="00187046",
        GridPeriod                                             ="00187048",
        GridFocalDistance                                      ="0018704C",
        FilterMaterial                                         ="00187050",
        FilterThicknessMinimum                                 ="00187052",
        FilterThicknessMaximum                                 ="00187054",
        FilterBeamPathLengthMinimum                            ="00187056",
        FilterBeamPathLengthMaximum                            ="00187058",
        ExposureControlMode                                    ="00187060",
        ExposureControlModeDescription                         ="00187062",
        ExposureStatus                                         ="00187064",
        PhototimerSetting                                      ="00187065",
        ExposureTimeInUS                                       ="00188150",
        XRayTubeCurrentInUA                                    ="00188151",
        ContentQualification                                   ="00189004",
        PulseSequenceName                                      ="00189005",
        MRImagingModifierSequence                              ="00189006",
        EchoPulseSequence                                      ="00189008",
        InversionRecovery                                      ="00189009",
        FlowCompensation                                       ="00189010",
        MultipleSpinEcho                                       ="00189011",
        MultiPlanarExcitation                                  ="00189012",
        PhaseContrast                                          ="00189014",
        TimeOfFlightContrast                                   ="00189015",
        Spoiling                                               ="00189016",
        SteadyStatePulseSequence                               ="00189017",
        EchoPlanarPulseSequence                                ="00189018",
        TagAngleFirstAxis                                      ="00189019",
        MagnetizationTransfer                                  ="00189020",
        T2Preparation                                          ="00189021",
        BloodSignalNulling                                     ="00189022",
        SaturationRecovery                                     ="00189024",
        SpectrallySelectedSuppression                          ="00189025",
        SpectrallySelectedExcitation                           ="00189026",
        SpatialPreSaturation                                   ="00189027",
        Tagging                                                ="00189028",
        OversamplingPhase                                      ="00189029",
        TagSpacingFirstDimension                               ="00189030",
        GeometryOfKSpaceTraversal                              ="00189032",
        SegmentedKSpaceTraversal                               ="00189033",
        RectilinearPhaseEncodeReordering                       ="00189034",
        TagThickness                                           ="00189035",
        PartialFourierDirection                                ="00189036",
        CardiacSynchronizationTechnique                        ="00189037",
        ReceiveCoilManufacturerName                            ="00189041",
        MRReceiveCoilSequence                                  ="00189042",
        ReceiveCoilType                                        ="00189043",
        QuadratureReceiveCoil                                  ="00189044",
        MultiCoilDefinitionSequence                            ="00189045",
        MultiCoilConfiguration                                 ="00189046",
        MultiCoilElementName                                   ="00189047",
        MultiCoilElementUsed                                   ="00189048",
        MRTransmitCoilSequence                                 ="00189049",
        TransmitCoilManufacturerName                           ="00189050",
        TransmitCoilType                                       ="00189051",
        SpectralWidth                                          ="00189052",
        ChemicalShiftReference                                 ="00189053",
        VolumeLocalizationTechnique                            ="00189054",
        MRAcquisitionFrequencyEncodingSteps                    ="00189058",
        DeCoupling                                             ="00189059",
        DeCoupledNucleus                                       ="00189060",
        DeCouplingFrequency                                    ="00189061",
        DeCouplingMethod                                       ="00189062",
        DeCouplingChemicalShiftReference                       ="00189063",
        KSpaceFiltering                                        ="00189064",
        TimeDomainFiltering                                    ="00189065",
        NumberOfZeroFills                                      ="00189066",
        BaselineCorrection                                     ="00189067",
        ParallelReductionFactorInPlane                         ="00189069",
        CardiacRRIntervalSpecified                             ="00189070",
        AcquisitionDuration                                    ="00189073",
        FrameAcquisitionDateTime                               ="00189074",
        DiffusionDirectionality                                ="00189075",
        DiffusionGradientDirectionSequence                     ="00189076",
        ParallelAcquisition                                    ="00189077",
        ParallelAcquisitionTechnique                           ="00189078",
        InversionTimes                                         ="00189079",
        MetaboliteMapDescription                               ="00189080",
        PartialFourier                                         ="00189081",
        EffectiveEchoTime                                      ="00189082",
        MetaboliteMapCodeSequence                              ="00189083",
        ChemicalShiftSequence                                  ="00189084",
        CardiacSignalSource                                    ="00189085",
        DiffusionBValue                                        ="00189087",
        DiffusionGradientOrientation                           ="00189089",
        VelocityEncodingDirection                              ="00189090",
        VelocityEncodingMinimumValue                           ="00189091",
        VelocityEncodingAcquisitionSequence                    ="00189092",
        NumberOfKSpaceTrajectories                             ="00189093",
        CoverageOfKSpace                                       ="00189094",
        SpectroscopyAcquisitionPhaseRows                       ="00189095",
        ParallelReductionFactorInPlaneRetired                  ="00189096",   // Retired
        TransmitterFrequency                                   ="00189098",
        ResonantNucleus                                        ="00189100",
        FrequencyCorrection                                    ="00189101",
        MRSpectroscopyFOVGeometrySequence                      ="00189103",
        SlabThickness                                          ="00189104",
        SlabOrientation                                        ="00189105",
        MidSlabPosition                                        ="00189106",
        MRSpatialSaturationSequence                            ="00189107",
        MRTimingAndRelatedParametersSequence                   ="00189112",
        MREchoSequence                                         ="00189114",
        MRModifierSequence                                     ="00189115",
        MRDiffusionSequence                                    ="00189117",
        CardiacSynchronizationSequence                         ="00189118",
        MRAveragesSequence                                     ="00189119",
        MRFOVGeometrySequence                                  ="00189125",
        VolumeLocalizationSequence                             ="00189126",
        SpectroscopyAcquisitionDataColumns                     ="00189127",
        DiffusionAnisotropyType                                ="00189147",
        FrameReferenceDateTime                                 ="00189151",
        MRMetaboliteMapSequence                                ="00189152",
        ParallelReductionFactorOutOfPlane                      ="00189155",
        SpectroscopyAcquisitionOutOfPlanePhaseSteps            ="00189159",
        BulkMotionStatus                                       ="00189166",   // Retired
        ParallelReductionFactorSecondInPlane                   ="00189168",
        CardiacBeatRejectionTechnique                          ="00189169",
        RespiratoryMotionCompensationTechnique                 ="00189170",
        RespiratorySignalSource                                ="00189171",
        BulkMotionCompensationTechnique                        ="00189172",
        BulkMotionSignalSource                                 ="00189173",
        ApplicableSafetyStandardAgency                         ="00189174",
        ApplicableSafetyStandardDescription                    ="00189175",
        OperatingModeSequence                                  ="00189176",
        OperatingModeType                                      ="00189177",
        OperatingMode                                          ="00189178",
        SpecificAbsorptionRateDefinition                       ="00189179",
        GradientOutputType                                     ="00189180",
        SpecificAbsorptionRateValue                            ="00189181",
        GradientOutput                                         ="00189182",
        FlowCompensationDirection                              ="00189183",
        TaggingDelay                                           ="00189184",
        RespiratoryMotionCompensationTechniqueDescription      ="00189185",
        RespiratorySignalSourceID                              ="00189186",
        ChemicalShiftMinimumIntegrationLimitInHz               ="00189195",   // Retired
        ChemicalShiftMaximumIntegrationLimitInHz               ="00189196",   // Retired
        MRVelocityEncodingSequence                             ="00189197",
        FirstOrderPhaseCorrection                              ="00189198",
        WaterReferencedPhaseCorrection                         ="00189199",
        MRSpectroscopyAcquisitionType                          ="00189200",
        RespiratoryCyclePosition                               ="00189214",
        VelocityEncodingMaximumValue                           ="00189217",
        TagSpacingSecondDimension                              ="00189218",
        TagAngleSecondAxis                                     ="00189219",
        FrameAcquisitionDuration                               ="00189220",
        MRImageFrameTypeSequence                               ="00189226",
        MRSpectroscopyFrameTypeSequence                        ="00189227",
        MRAcquisitionPhaseEncodingStepsInPlane                 ="00189231",
        MRAcquisitionPhaseEncodingStepsOutOfPlane              ="00189232",
        SpectroscopyAcquisitionPhaseColumns                    ="00189234",
        CardiacCyclePosition                                   ="00189236",
        SpecificAbsorptionRateSequence                         ="00189239",
        RFEchoTrainLength                                      ="00189240",
        GradientEchoTrainLength                                ="00189241",
        ArterialSpinLabelingContrast                           ="00189250",
        MRArterialSpinLabelingSequence                         ="00189251",
        ASLTechniqueDescription                                ="00189252",
        ASLSlabNumber                                          ="00189253",
        ASLSlabThickness                                       ="00189254",
        ASLSlabOrientation                                     ="00189255",
        ASLMidSlabPosition                                     ="00189256",
        ASLContext                                             ="00189257",
        ASLPulseTrainDuration                                  ="00189258",
        ASLCrusherFlag                                         ="00189259",
        ASLCrusherFlowLimit                                    ="0018925A",
        ASLCrusherDescription                                  ="0018925B",
        ASLBolusCutOffFlag                                     ="0018925C",
        ASLBolusCutOffTimingSequence                           ="0018925D",
        ASLBolusCutOffTechnique                                ="0018925E",
        ASLBolusCutOffDelayTime                                ="0018925F",
        ASLSlabSequence                                        ="00189260",
        ChemicalShiftMinimumIntegrationLimitInPpm              ="00189295",
        ChemicalShiftMaximumIntegrationLimitInPpm              ="00189296",
        WaterReferenceAcquisition                              ="00189297",
        EchoPeakPosition                                       ="00189298",
        CTAcquisitionTypeSequence                              ="00189301",
        AcquisitionType                                        ="00189302",
        TubeAngle                                              ="00189303",
        CTAcquisitionDetailsSequence                           ="00189304",
        RevolutionTime                                         ="00189305",
        SingleCollimationWidth                                 ="00189306",
        TotalCollimationWidth                                  ="00189307",
        CTTableDynamicsSequence                                ="00189308",
        TableSpeed                                             ="00189309",
        TableFeedPerRotation                                   ="00189310",
        SpiralPitchFactor                                      ="00189311",
        CTGeometrySequence                                     ="00189312",
        DataCollectionCenterPatient                            ="00189313",
        CTReconstructionSequence                               ="00189314",
        ReconstructionAlgorithm                                ="00189315",
        ConvolutionKernelGroup                                 ="00189316",
        ReconstructionFieldOfView                              ="00189317",
        ReconstructionTargetCenterPatient                      ="00189318",
        ReconstructionAngle                                    ="00189319",
        ImageFilter                                            ="00189320",
        CTExposureSequence                                     ="00189321",
        ReconstructionPixelSpacing                             ="00189322",
        ExposureModulationType                                 ="00189323",
        EstimatedDoseSaving                                    ="00189324",
        CTXRayDetailsSequence                                  ="00189325",
        CTPositionSequence                                     ="00189326",
        TablePosition                                          ="00189327",
        ExposureTimeInMs                                       ="00189328",
        CTImageFrameTypeSequence                               ="00189329",
        XRayTubeCurrentInMA                                    ="00189330",
        ExposureInMAs                                          ="00189332",
        ConstantVolumeFlag                                     ="00189333",
        FluoroscopyFlag                                        ="00189334",
        DistanceSourceToDataCollectionCenter                   ="00189335",
        ContrastBolusAgentNumber                               ="00189337",
        ContrastBolusIngredientCodeSequence                    ="00189338",
        ContrastAdministrationProfileSequence                  ="00189340",
        ContrastBolusUsageSequence                             ="00189341",
        ContrastBolusAgentAdministered                         ="00189342",
        ContrastBolusAgentDetected                             ="00189343",
        ContrastBolusAgentPhase                                ="00189344",
        CTDIvol                                                ="00189345",
        CTDIPhantomTypeCodeSequence                            ="00189346",
        CalciumScoringMassFactorPatient                        ="00189351",
        CalciumScoringMassFactorDevice                         ="00189352",
        EnergyWeightingFactor                                  ="00189353",
        CTAdditionalXRaySourceSequence                         ="00189360",
        ProjectionPixelCalibrationSequence                     ="00189401",
        DistanceSourceToIsocenter                              ="00189402",
        DistanceObjectToTableTop                               ="00189403",
        ObjectPixelSpacingInCenterOfBeam                       ="00189404",
        PositionerPositionSequence                             ="00189405",
        TablePositionSequence                                  ="00189406",
        CollimatorShapeSequence                                ="00189407",
        PlanesInAcquisition                                    ="00189410",
        XAXRFFrameCharacteristicsSequence                      ="00189412",
        FrameAcquisitionSequence                               ="00189417",
        XRayReceptorType                                       ="00189420",
        AcquisitionProtocolName                                ="00189423",
        AcquisitionProtocolDescription                         ="00189424",
        ContrastBolusIngredientOpaque                          ="00189425",
        DistanceReceptorPlaneToDetectorHousing                 ="00189426",
        IntensifierActiveShape                                 ="00189427",
        IntensifierActiveDimension                             ="00189428",
        PhysicalDetectorSize                                   ="00189429",
        PositionOfIsocenterProjection                          ="00189430",
        FieldOfViewSequence                                    ="00189432",
        FieldOfViewDescription                                 ="00189433",
        ExposureControlSensingRegionsSequence                  ="00189434",
        ExposureControlSensingRegionShape                      ="00189435",
        ExposureControlSensingRegionLeftVerticalEdge           ="00189436",
        ExposureControlSensingRegionRightVerticalEdge          ="00189437",
        ExposureControlSensingRegionUpperHorizontalEdge        ="00189438",
        ExposureControlSensingRegionLowerHorizontalEdge        ="00189439",
        CenterOfCircularExposureControlSensingRegion           ="00189440",
        RadiusOfCircularExposureControlSensingRegion           ="00189441",
        VerticesOfThePolygonalExposureControlSensingRegion     ="00189442",
        ColumnAngulationPatient                                ="00189447",
        BeamAngle                                              ="00189449",
        FrameDetectorParametersSequence                        ="00189451",
        CalculatedAnatomyThickness                             ="00189452",
        CalibrationSequence                                    ="00189455",
        ObjectThicknessSequence                                ="00189456",
        PlaneIdentification                                    ="00189457",
        FieldOfViewDimensionInFloat                            ="00189461",
        IsocenterReferenceSystemSequence                       ="00189462",
        PositionerIsocenterPrimaryAngle                        ="00189463",
        PositionerIsocenterSecondaryAngle                      ="00189464",
        PositionerIsocenterDetectorRotationAngle               ="00189465",
        TableXPositionToIsocenter                              ="00189466",
        TableYPositionToIsocenter                              ="00189467",
        TableZPositionToIsocenter                              ="00189468",
        TableHorizontalRotationAngle                           ="00189469",
        TableHeadTiltAngle                                     ="00189470",
        TableCradleTiltAngle                                   ="00189471",
        FrameDisplayShutterSequence                            ="00189472",
        AcquiredImageAreaDoseProduct                           ="00189473",
        CArmPositionerTabletopRelationship                     ="00189474",
        XRayGeometrySequence                                   ="00189476",
        IrradiationEventIdentificationSequence                 ="00189477",
        XRay3DFrameTypeSequence                                ="00189504",
        ContributingSourcesSequence                            ="00189506",
        XRay3DAcquisitionSequence                              ="00189507",
        PrimaryPositionerScanArc                               ="00189508",
        SecondaryPositionerScanArc                             ="00189509",
        PrimaryPositionerScanStartAngle                        ="00189510",
        SecondaryPositionerScanStartAngle                      ="00189511",
        PrimaryPositionerIncrement                             ="00189514",
        SecondaryPositionerIncrement                           ="00189515",
        StartAcquisitionDateTime                               ="00189516",
        EndAcquisitionDateTime                                 ="00189517",
        PrimaryPositionerIncrementSign                         ="00189518",
        SecondaryPositionerIncrementSign                       ="00189519",
        ApplicationName                                        ="00189524",
        ApplicationVersion                                     ="00189525",
        ApplicationManufacturer                                ="00189526",
        AlgorithmType                                          ="00189527",
        AlgorithmDescription                                   ="00189528",
        XRay3DReconstructionSequence                           ="00189530",
        ReconstructionDescription                              ="00189531",
        PerProjectionAcquisitionSequence                       ="00189538",
        DetectorPositionSequence                               ="00189541",
        XRayAcquisitionDoseSequence                            ="00189542",
        XRaySourceIsocenterPrimaryAngle                        ="00189543",
        XRaySourceIsocenterSecondaryAngle                      ="00189544",
        BreastSupportIsocenterPrimaryAngle                     ="00189545",
        BreastSupportIsocenterSecondaryAngle                   ="00189546",
        BreastSupportXPositionToIsocenter                      ="00189547",
        BreastSupportYPositionToIsocenter                      ="00189548",
        BreastSupportZPositionToIsocenter                      ="00189549",
        DetectorIsocenterPrimaryAngle                          ="00189550",
        DetectorIsocenterSecondaryAngle                        ="00189551",
        DetectorXPositionToIsocenter                           ="00189552",
        DetectorYPositionToIsocenter                           ="00189553",
        DetectorZPositionToIsocenter                           ="00189554",
        XRayGridSequence                                       ="00189555",
        XRayFilterSequence                                     ="00189556",
        DetectorActiveAreaTLHCPosition                         ="00189557",
        DetectorActiveAreaOrientation                          ="00189558",
        PositionerPrimaryAngleDirection                        ="00189559",
        DiffusionBMatrixSequence                               ="00189601",
        DiffusionBValueXX                                      ="00189602",
        DiffusionBValueXY                                      ="00189603",
        DiffusionBValueXZ                                      ="00189604",
        DiffusionBValueYY                                      ="00189605",
        DiffusionBValueYZ                                      ="00189606",
        DiffusionBValueZZ                                      ="00189607",
        DecayCorrectionDateTime                                ="00189701",
        StartDensityThreshold                                  ="00189715",
        StartRelativeDensityDifferenceThreshold                ="00189716",
        StartCardiacTriggerCountThreshold                      ="00189717",
        StartRespiratoryTriggerCountThreshold                  ="00189718",
        TerminationCountsThreshold                             ="00189719",
        TerminationDensityThreshold                            ="00189720",
        TerminationRelativeDensityThreshold                    ="00189721",
        TerminationTimeThreshold                               ="00189722",
        TerminationCardiacTriggerCountThreshold                ="00189723",
        TerminationRespiratoryTriggerCountThreshold            ="00189724",
        DetectorGeometry                                       ="00189725",
        TransverseDetectorSeparation                           ="00189726",
        AxialDetectorDimension                                 ="00189727",
        RadiopharmaceuticalAgentNumber                         ="00189729",
        PETFrameAcquisitionSequence                            ="00189732",
        PETDetectorMotionDetailsSequence                       ="00189733",
        PETTableDynamicsSequence                               ="00189734",
        PETPositionSequence                                    ="00189735",
        PETFrameCorrectionFactorsSequence                      ="00189736",
        RadiopharmaceuticalUsageSequence                       ="00189737",
        AttenuationCorrectionSource                            ="00189738",
        NumberOfIterations                                     ="00189739",
        NumberOfSubsets                                        ="00189740",
        PETReconstructionSequence                              ="00189749",
        PETFrameTypeSequence                                   ="00189751",
        TimeOfFlightInformationUsed                            ="00189755",
        ReconstructionType                                     ="00189756",
        DecayCorrected                                         ="00189758",
        AttenuationCorrected                                   ="00189759",
        ScatterCorrected                                       ="00189760",
        DeadTimeCorrected                                      ="00189761",
        GantryMotionCorrected                                  ="00189762",
        PatientMotionCorrected                                 ="00189763",
        CountLossNormalizationCorrected                        ="00189764",
        RandomsCorrected                                       ="00189765",
        NonUniformRadialSamplingCorrected                      ="00189766",
        SensitivityCalibrated                                  ="00189767",
        DetectorNormalizationCorrection                        ="00189768",
        IterativeReconstructionMethod                          ="00189769",
        AttenuationCorrectionTemporalRelationship              ="00189770",
        PatientPhysiologicalStateSequence                      ="00189771",
        PatientPhysiologicalStateCodeSequence                  ="00189772",
        DepthOfFocus                                           ="00189801",
        ExcludedIntervalsSequence                              ="00189803",
        ExclusionStartDateTime                                 ="00189804",
        ExclusionDuration                                      ="00189805",
        USImageDescriptionSequence                             ="00189806",
        ImageDataTypeSequence                                  ="00189807",
        DataType                                               ="00189808",
        TransducerScanPatternCodeSequence                      ="00189809",
        AliasedDataType                                        ="0018980B",
        PositionMeasuringDeviceUsed                            ="0018980C",
        TransducerGeometryCodeSequence                         ="0018980D",
        TransducerBeamSteeringCodeSequence                     ="0018980E",
        TransducerApplicationCodeSequence                      ="0018980F",
        ZeroVelocityPixelValue                                 ="00189810",
        ContributingEquipmentSequence                          ="0018A001",
        ContributionDateTime                                   ="0018A002",
        ContributionDescription                                ="0018A003",
    
    // *****************************************************************************************************************************
    // *** 0020
    // *****************************************************************************************************************************
        StudyInstanceUID                                       ="0020000D",
        SeriesInstanceUID                                      ="0020000E",
        StudyID                                                ="00200010",
        SeriesNumber                                           ="00200011",
        AcquisitionNumber                                      ="00200012",
        InstanceNumber                                         ="00200013",
        IsotopeNumber                                          ="00200014",   // Retired
        PhaseNumber                                            ="00200015",   // Retired
        IntervalNumber                                         ="00200016",   // Retired
        TimeSlotNumber                                         ="00200017",   // Retired
        AngleNumber                                            ="00200018",   // Retired
        ItemNumber                                             ="00200019",
        PatientOrientation                                     ="00200020",
        OverlayNumber                                          ="00200022",   // Retired
        CurveNumber                                            ="00200024",   // Retired
        LUTNumber                                              ="00200026",   // Retired
        ImagePosition                                          ="00200030",   // Retired
        ImagePositionPatient                                   ="00200032",
        ImageOrientation                                       ="00200035",   // Retired
        ImageOrientationPatient                                ="00200037",
        Location                                               ="00200050",   // Retired
        FrameOfReferenceUID                                    ="00200052",
        Laterality                                             ="00200060",
        ImageLaterality                                        ="00200062",
        ImageGeometryType                                      ="00200070",   // Retired
        MaskingImage                                           ="00200080",   // Retired
        ReportNumber                                           ="002000AA",   // Retired
        TemporalPositionIdentifier                             ="00200100",
        NumberOfTemporalPositions                              ="00200105",
        TemporalResolution                                     ="00200110",
        SynchronizationFrameOfReferenceUID                     ="00200200",
        SOPInstanceUIDOfConcatenationSource                    ="00200242",
        SeriesInStudy                                          ="00201000",   // Retired
        AcquisitionsInSeries                                   ="00201001",   // Retired
        ImagesInAcquisition                                    ="00201002",
        ImagesInSeries                                         ="00201003",   // Retired
        AcquisitionsInStudy                                    ="00201004",   // Retired
        ImagesInStudy                                          ="00201005",   // Retired
        Reference                                              ="00201020",   // Retired
        PositionReferenceIndicator                             ="00201040",
        SliceLocation                                          ="00201041",
        OtherStudyNumbers                                      ="00201070",   // Retired
        NumberOfPatientRelatedStudies                          ="00201200",
        NumberOfPatientRelatedSeries                           ="00201202",
        NumberOfPatientRelatedInstances                        ="00201204",
        NumberOfStudyRelatedSeries                             ="00201206",
        NumberOfStudyRelatedInstances                          ="00201208",
        NumberOfSeriesRelatedInstances                         ="00201209",
        SourceImageIDs                                         ="00203100",   // Retired
        ModifyingDeviceID                                      ="00203401",   // Retired
        ModifiedImageID                                        ="00203402",   // Retired
        ModifiedImageDate                                      ="00203403",   // Retired
        ModifyingDeviceManufacturer                            ="00203404",   // Retired
        ModifiedImageTime                                      ="00203405",   // Retired
        ModifiedImageDescription                               ="00203406",   // Retired
        ImageComments                                          ="00204000",
        OriginalImageIdentification                            ="00205000",   // Retired
        OriginalImageIdentificationNomenclature                ="00205002",   // Retired
        StackID                                                ="00209056",
        InStackPositionNumber                                  ="00209057",
        FrameAnatomySequence                                   ="00209071",
        FrameLaterality                                        ="00209072",
        FrameContentSequence                                   ="00209111",
        PlanePositionSequence                                  ="00209113",
        PlaneOrientationSequence                               ="00209116",
        TemporalPositionIndex                                  ="00209128",
        NominalCardiacTriggerDelayTime                         ="00209153",
        NominalCardiacTriggerTimePriorToRPeak                  ="00209154",
        ActualCardiacTriggerTimePriorToRPeak                   ="00209155",
        FrameAcquisitionNumber                                 ="00209156",
        DimensionIndexValues                                   ="00209157",
        FrameComments                                          ="00209158",
        ConcatenationUID                                       ="00209161",
        InConcatenationNumber                                  ="00209162",
        InConcatenationTotalNumber                             ="00209163",
        DimensionOrganizationUID                               ="00209164",
        DimensionIndexPointer                                  ="00209165",
        FunctionalGroupPointer                                 ="00209167",
        UnassignedSharedConvertedAttributesSequence            ="00209170",
        UnassignedPerFrameConvertedAttributesSequence          ="00209171",
        ConversionSourceAttributesSequence                     ="00209172",
        DimensionIndexPrivateCreator                           ="00209213",
        DimensionOrganizationSequence                          ="00209221",
        DimensionIndexSequence                                 ="00209222",
        ConcatenationFrameOffsetNumber                         ="00209228",
        FunctionalGroupPrivateCreator                          ="00209238",
        NominalPercentageOfCardiacPhase                        ="00209241",
        NominalPercentageOfRespiratoryPhase                    ="00209245",
        StartingRespiratoryAmplitude                           ="00209246",
        StartingRespiratoryPhase                               ="00209247",
        EndingRespiratoryAmplitude                             ="00209248",
        EndingRespiratoryPhase                                 ="00209249",
        RespiratoryTriggerType                                 ="00209250",
        RRIntervalTimeNominal                                  ="00209251",
        ActualCardiacTriggerDelayTime                          ="00209252",
        RespiratorySynchronizationSequence                     ="00209253",
        RespiratoryIntervalTime                                ="00209254",
        NominalRespiratoryTriggerDelayTime                     ="00209255",
        RespiratoryTriggerDelayThreshold                       ="00209256",
        ActualRespiratoryTriggerDelayTime                      ="00209257",
        ImagePositionVolume                                    ="00209301",
        ImageOrientationVolume                                 ="00209302",
        UltrasoundAcquisitionGeometry                          ="00209307",
        ApexPosition                                           ="00209308",
        VolumeToTransducerMappingMatrix                        ="00209309",
        VolumeToTableMappingMatrix                             ="0020930A",
        VolumeToTransducerRelationship                         ="0020930B",
        PatientFrameOfReferenceSource                          ="0020930C",
        TemporalPositionTimeOffset                             ="0020930D",
        PlanePositionVolumeSequence                            ="0020930E",
        PlaneOrientationVolumeSequence                         ="0020930F",
        TemporalPositionSequence                               ="00209310",
        DimensionOrganizationType                              ="00209311",
        VolumeFrameOfReferenceUID                              ="00209312",
        TableFrameOfReferenceUID                               ="00209313",
        DimensionDescriptionLabel                              ="00209421",
        PatientOrientationInFrameSequence                      ="00209450",
        FrameLabel                                             ="00209453",
        AcquisitionIndex                                       ="00209518",
        ContributingSOPInstancesReferenceSequence              ="00209529",
        ReconstructionIndex                                    ="00209536",
    
    // *****************************************************************************************************************************
    // *** 0022
    // *****************************************************************************************************************************
        LightPathFilterPassThroughWavelength                   ="00220001",
        LightPathFilterPassBand                                ="00220002",
        ImagePathFilterPassThroughWavelength                   ="00220003",
        ImagePathFilterPassBand                                ="00220004",
        PatientEyeMovementCommanded                            ="00220005",
        PatientEyeMovementCommandCodeSequence                  ="00220006",
        SphericalLensPower                                     ="00220007",
        CylinderLensPower                                      ="00220008",
        CylinderAxis                                           ="00220009",
        EmmetropicMagnification                                ="0022000A",
        IntraOcularPressure                                    ="0022000B",
        HorizontalFieldOfView                                  ="0022000C",
        PupilDilated                                           ="0022000D",
        DegreeOfDilation                                       ="0022000E",
        StereoBaselineAngle                                    ="00220010",
        StereoBaselineDisplacement                             ="00220011",
        StereoHorizontalPixelOffset                            ="00220012",
        StereoVerticalPixelOffset                              ="00220013",
        StereoRotation                                         ="00220014",
        AcquisitionDeviceTypeCodeSequence                      ="00220015",
        IlluminationTypeCodeSequence                           ="00220016",
        LightPathFilterTypeStackCodeSequence                   ="00220017",
        ImagePathFilterTypeStackCodeSequence                   ="00220018",
        LensesCodeSequence                                     ="00220019",
        ChannelDescriptionCodeSequence                         ="0022001A",
        RefractiveStateSequence                                ="0022001B",
        MydriaticAgentCodeSequence                             ="0022001C",
        RelativeImagePositionCodeSequence                      ="0022001D",
        CameraAngleOfView                                      ="0022001E",
        StereoPairsSequence                                    ="00220020",
        LeftImageSequence                                      ="00220021",
        RightImageSequence                                     ="00220022",
        StereoPairsPresent                                     ="00220028",
        AxialLengthOfTheEye                                    ="00220030",
        OphthalmicFrameLocationSequence                        ="00220031",
        ReferenceCoordinates                                   ="00220032",
        DepthSpatialResolution                                 ="00220035",
        MaximumDepthDistortion                                 ="00220036",
        AlongScanSpatialResolution                             ="00220037",
        MaximumAlongScanDistortion                             ="00220038",
        OphthalmicImageOrientation                             ="00220039",
        DepthOfTransverseImage                                 ="00220041",
        MydriaticAgentConcentrationUnitsSequence               ="00220042",
        AcrossScanSpatialResolution                            ="00220048",
        MaximumAcrossScanDistortion                            ="00220049",
        MydriaticAgentConcentration                            ="0022004E",
        IlluminationWaveLength                                 ="00220055",
        IlluminationPower                                      ="00220056",
        IlluminationBandwidth                                  ="00220057",
        MydriaticAgentSequence                                 ="00220058",
        OphthalmicAxialMeasurementsRightEyeSequence            ="00221007",
        OphthalmicAxialMeasurementsLeftEyeSequence             ="00221008",
        OphthalmicAxialMeasurementsDeviceType                  ="00221009",
        OphthalmicAxialLengthMeasurementsType                  ="00221010",
        OphthalmicAxialLengthSequence                          ="00221012",
        OphthalmicAxialLength                                  ="00221019",
        LensStatusCodeSequence                                 ="00221024",
        VitreousStatusCodeSequence                             ="00221025",
        IOLFormulaCodeSequence                                 ="00221028",
        IOLFormulaDetail                                       ="00221029",
        KeratometerIndex                                       ="00221033",
        SourceOfOphthalmicAxialLengthCodeSequence              ="00221035",
        TargetRefraction                                       ="00221037",
        RefractiveProcedureOccurred                            ="00221039",
        RefractiveSurgeryTypeCodeSequence                      ="00221040",
        OphthalmicUltrasoundMethodCodeSequence                 ="00221044",
        OphthalmicAxialLengthMeasurementsSequence              ="00221050",
        IOLPower                                               ="00221053",
        PredictedRefractiveError                               ="00221054",
        OphthalmicAxialLengthVelocity                          ="00221059",
        LensStatusDescription                                  ="00221065",
        VitreousStatusDescription                              ="00221066",
        IOLPowerSequence                                       ="00221090",
        LensConstantSequence                                   ="00221092",
        IOLManufacturer                                        ="00221093",
        LensConstantDescription                                ="00221094",   // Retired
        ImplantName                                            ="00221095",
        KeratometryMeasurementTypeCodeSequence                 ="00221096",
        ImplantPartNumber                                      ="00221097",
        ReferencedOphthalmicAxialMeasurementsSequence          ="00221100",
        OphthalmicAxialLengthMeasurementsSegmentNameCodeSequence ="00221101",
        RefractiveErrorBeforeRefractiveSurgeryCodeSequence     ="00221103",
        IOLPowerForExactEmmetropia                             ="00221121",
        IOLPowerForExactTargetRefraction                       ="00221122",
        AnteriorChamberDepthDefinitionCodeSequence             ="00221125",
        LensThicknessSequence                                  ="00221127",
        AnteriorChamberDepthSequence                           ="00221128",
        LensThickness                                          ="00221130",
        AnteriorChamberDepth                                   ="00221131",
        SourceOfLensThicknessDataCodeSequence                  ="00221132",
        SourceOfAnteriorChamberDepthDataCodeSequence           ="00221133",
        SourceOfRefractiveMeasurementsSequence                 ="00221134",
        SourceOfRefractiveMeasurementsCodeSequence             ="00221135",
        OphthalmicAxialLengthMeasurementModified               ="00221140",
        OphthalmicAxialLengthDataSourceCodeSequence            ="00221150",
        OphthalmicAxialLengthAcquisitionMethodCodeSequence     ="00221153",   // Retired
        SignalToNoiseRatio                                     ="00221155",
        OphthalmicAxialLengthDataSourceDescription             ="00221159",
        OphthalmicAxialLengthMeasurementsTotalLengthSequence   ="00221210",
        OphthalmicAxialLengthMeasurementsSegmentalLengthSequence ="00221211",
        OphthalmicAxialLengthMeasurementsLengthSummationSequence ="00221212",
        UltrasoundOphthalmicAxialLengthMeasurementsSequence    ="00221220",
        OpticalOphthalmicAxialLengthMeasurementsSequence       ="00221225",
        UltrasoundSelectedOphthalmicAxialLengthSequence        ="00221230",
        OphthalmicAxialLengthSelectionMethodCodeSequence       ="00221250",
        OpticalSelectedOphthalmicAxialLengthSequence           ="00221255",
        SelectedSegmentalOphthalmicAxialLengthSequence         ="00221257",
        SelectedTotalOphthalmicAxialLengthSequence             ="00221260",
        OphthalmicAxialLengthQualityMetricSequence             ="00221262",
        OphthalmicAxialLengthQualityMetricTypeCodeSequence     ="00221265",   // Retired
        OphthalmicAxialLengthQualityMetricTypeDescription      ="00221273",   // Retired
        IntraocularLensCalculationsRightEyeSequence            ="00221300",
        IntraocularLensCalculationsLeftEyeSequence             ="00221310",
        ReferencedOphthalmicAxialLengthMeasurementQCImageSequence ="00221330",
        OphthalmicMappingDeviceType                            ="00221415",
        AcquisitionMethodCodeSequence                          ="00221420",
        AcquisitionMethodAlgorithmSequence                     ="00221423",
        OphthalmicThicknessMapTypeCodeSequence                 ="00221436",
        OphthalmicThicknessMappingNormalsSequence              ="00221443",
        RetinalThicknessDefinitionCodeSequence                 ="00221445",
        PixelValueMappingToCodedConceptSequence                ="00221450",
        MappedPixelValue                                       ="00221452",
        PixelValueMappingExplanation                           ="00221454",
        OphthalmicThicknessMapQualityThresholdSequence         ="00221458",
        OphthalmicThicknessMapThresholdQualityRating           ="00221460",
        AnatomicStructureReferencePoint                        ="00221463",
        RegistrationToLocalizerSequence                        ="00221465",
        RegisteredLocalizerUnits                               ="00221466",
        RegisteredLocalizerTopLeftHandCorner                   ="00221467",
        RegisteredLocalizerBottomRightHandCorner               ="00221468",
        OphthalmicThicknessMapQualityRatingSequence            ="00221470",
        RelevantOPTAttributesSequence                          ="00221472",
        TransformationMethodCodeSequence                       ="00221512",
        TransformationAlgorithmSequence                        ="00221513",
        OphthalmicAxialLengthMethod                            ="00221515",
        OphthalmicFOV                                          ="00221517",
        TwoDimensionalToThreeDimensionalMapSequence            ="00221518",
        WideFieldOphthalmicPhotographyQualityRatingSequence    ="00221525",
        WideFieldOphthalmicPhotographyQualityThresholdSequence ="00221526",
        WideFieldOphthalmicPhotographyThresholdQualityRating   ="00221527",
        XCoordinatesCenterPixelViewAngle                       ="00221528",
        YCoordinatesCenterPixelViewAngle                       ="00221529",
        NumberOfMapPoints                                      ="00221530",
        TwoDimensionalToThreeDimensionalMapData                ="00221531",
    
    // *****************************************************************************************************************************
    // *** 0024
    // *****************************************************************************************************************************
        VisualFieldHorizontalExtent                            ="00240010",
        VisualFieldVerticalExtent                              ="00240011",
        VisualFieldShape                                       ="00240012",
        ScreeningTestModeCodeSequence                          ="00240016",
        MaximumStimulusLuminance                               ="00240018",
        BackgroundLuminance                                    ="00240020",
        StimulusColorCodeSequence                              ="00240021",
        BackgroundIlluminationColorCodeSequence                ="00240024",
        StimulusArea                                           ="00240025",
        StimulusPresentationTime                               ="00240028",
        FixationSequence                                       ="00240032",
        FixationMonitoringCodeSequence                         ="00240033",
        VisualFieldCatchTrialSequence                          ="00240034",
        FixationCheckedQuantity                                ="00240035",
        PatientNotProperlyFixatedQuantity                      ="00240036",
        PresentedVisualStimuliDataFlag                         ="00240037",
        NumberOfVisualStimuli                                  ="00240038",
        ExcessiveFixationLossesDataFlag                        ="00240039",
        ExcessiveFixationLosses                                ="00240040",
        StimuliRetestingQuantity                               ="00240042",
        CommentsOnPatientPerformanceOfVisualField              ="00240044",
        FalseNegativesEstimateFlag                             ="00240045",
        FalseNegativesEstimate                                 ="00240046",
        NegativeCatchTrialsQuantity                            ="00240048",
        FalseNegativesQuantity                                 ="00240050",
        ExcessiveFalseNegativesDataFlag                        ="00240051",
        ExcessiveFalseNegatives                                ="00240052",
        FalsePositivesEstimateFlag                             ="00240053",
        FalsePositivesEstimate                                 ="00240054",
        CatchTrialsDataFlag                                    ="00240055",
        PositiveCatchTrialsQuantity                            ="00240056",
        TestPointNormalsDataFlag                               ="00240057",
        TestPointNormalsSequence                               ="00240058",
        GlobalDeviationProbabilityNormalsFlag                  ="00240059",
        FalsePositivesQuantity                                 ="00240060",
        ExcessiveFalsePositivesDataFlag                        ="00240061",
        ExcessiveFalsePositives                                ="00240062",
        VisualFieldTestNormalsFlag                             ="00240063",
        ResultsNormalsSequence                                 ="00240064",
        AgeCorrectedSensitivityDeviationAlgorithmSequence      ="00240065",
        GlobalDeviationFromNormal                              ="00240066",
        GeneralizedDefectSensitivityDeviationAlgorithmSequence ="00240067",
        LocalizedDeviationFromNormal                           ="00240068",
        PatientReliabilityIndicator                            ="00240069",
        VisualFieldMeanSensitivity                             ="00240070",
        GlobalDeviationProbability                             ="00240071",
        LocalDeviationProbabilityNormalsFlag                   ="00240072",
        LocalizedDeviationProbability                          ="00240073",
        ShortTermFluctuationCalculated                         ="00240074",
        ShortTermFluctuation                                   ="00240075",
        ShortTermFluctuationProbabilityCalculated              ="00240076",
        ShortTermFluctuationProbability                        ="00240077",
        CorrectedLocalizedDeviationFromNormalCalculated        ="00240078",
        CorrectedLocalizedDeviationFromNormal                  ="00240079",
        CorrectedLocalizedDeviationFromNormalProbabilityCalculated ="00240080",
        CorrectedLocalizedDeviationFromNormalProbability       ="00240081",
        GlobalDeviationProbabilitySequence                     ="00240083",
        LocalizedDeviationProbabilitySequence                  ="00240085",
        FovealSensitivityMeasured                              ="00240086",
        FovealSensitivity                                      ="00240087",
        VisualFieldTestDuration                                ="00240088",
        VisualFieldTestPointSequence                           ="00240089",
        VisualFieldTestPointXCoordinate                        ="00240090",
        VisualFieldTestPointYCoordinate                        ="00240091",
        AgeCorrectedSensitivityDeviationValue                  ="00240092",
        StimulusResults                                        ="00240093",
        SensitivityValue                                       ="00240094",
        RetestStimulusSeen                                     ="00240095",
        RetestSensitivityValue                                 ="00240096",
        VisualFieldTestPointNormalsSequence                    ="00240097",
        QuantifiedDefect                                       ="00240098",
        AgeCorrectedSensitivityDeviationProbabilityValue       ="00240100",
        GeneralizedDefectCorrectedSensitivityDeviationFlag     ="00240102",
        GeneralizedDefectCorrectedSensitivityDeviationValue    ="00240103",
        GeneralizedDefectCorrectedSensitivityDeviationProbabilityValue ="00240104",
        MinimumSensitivityValue                                ="00240105",
        BlindSpotLocalized                                     ="00240106",
        BlindSpotXCoordinate                                   ="00240107",
        BlindSpotYCoordinate                                   ="00240108",
        VisualAcuityMeasurementSequence                        ="00240110",
        RefractiveParametersUsedOnPatientSequence              ="00240112",
        MeasurementLaterality                                  ="00240113",
        OphthalmicPatientClinicalInformationLeftEyeSequence    ="00240114",
        OphthalmicPatientClinicalInformationRightEyeSequence   ="00240115",
        FovealPointNormativeDataFlag                           ="00240117",
        FovealPointProbabilityValue                            ="00240118",
        ScreeningBaselineMeasured                              ="00240120",
        ScreeningBaselineMeasuredSequence                      ="00240122",
        ScreeningBaselineType                                  ="00240124",
        ScreeningBaselineValue                                 ="00240126",
        AlgorithmSource                                        ="00240202",
        DataSetName                                            ="00240306",
        DataSetVersion                                         ="00240307",
        DataSetSource                                          ="00240308",
        DataSetDescription                                     ="00240309",
        VisualFieldTestReliabilityGlobalIndexSequence          ="00240317",
        VisualFieldGlobalResultsIndexSequence                  ="00240320",
        DataObservationSequence                                ="00240325",
        IndexNormalsFlag                                       ="00240338",
        IndexProbability                                       ="00240341",
        IndexProbabilitySequence                               ="00240344",
    
    // *****************************************************************************************************************************
    // *** 0028
    // *****************************************************************************************************************************
        SamplesPerPixel                                        ="00280002",
        SamplesPerPixelUsed                                    ="00280003",
        PhotometricInterpretation                              ="00280004",
        ImageDimensions                                        ="00280005",   // Retired
        PlanarConfiguration                                    ="00280006",
        NumberOfFrames                                         ="00280008",
        FrameIncrementPointer                                  ="00280009",
        FrameDimensionPointer                                  ="0028000A",
        Rows                                                   ="00280010",
        Columns                                                ="00280011",
        Planes                                                 ="00280012",   // Retired
        UltrasoundColorDataPresent                             ="00280014",
        PixelSpacing                                           ="00280030",
        ZoomFactor                                             ="00280031",
        ZoomCenter                                             ="00280032",
        PixelAspectRatio                                       ="00280034",
        ImageFormat                                            ="00280040",   // Retired
        ManipulatedImage                                       ="00280050",   // Retired
        CorrectedImage                                         ="00280051",
        CompressionRecognitionCode                             ="0028005F",   // Retired
        CompressionCode                                        ="00280060",   // Retired
        CompressionOriginator                                  ="00280061",   // Retired
        CompressionLabel                                       ="00280062",   // Retired
        CompressionDescription                                 ="00280063",   // Retired
        CompressionSequence                                    ="00280065",   // Retired
        CompressionStepPointers                                ="00280066",   // Retired
        RepeatInterval                                         ="00280068",   // Retired
        BitsGrouped                                            ="00280069",   // Retired
        PerimeterTable                                         ="00280070",   // Retired
        PerimeterValue                                         ="00280071",   // Retired
        PredictorRows                                          ="00280080",   // Retired
        PredictorColumns                                       ="00280081",   // Retired
        PredictorConstants                                     ="00280082",   // Retired
        BlockedPixels                                          ="00280090",   // Retired
        BlockRows                                              ="00280091",   // Retired
        BlockColumns                                           ="00280092",   // Retired
        RowOverlap                                             ="00280093",   // Retired
        ColumnOverlap                                          ="00280094",   // Retired
        BitsAllocated                                          ="00280100",
        BitsStored                                             ="00280101",
        HighBit                                                ="00280102",
        PixelRepresentation                                    ="00280103",
        SmallestValidPixelValue                                ="00280104",   // Retired
        LargestValidPixelValue                                 ="00280105",   // Retired
        SmallestImagePixelValue                                ="00280106",
        LargestImagePixelValue                                 ="00280107",
        SmallestPixelValueInSeries                             ="00280108",
        LargestPixelValueInSeries                              ="00280109",
        SmallestImagePixelValueInPlane                         ="00280110",   // Retired
        LargestImagePixelValueInPlane                          ="00280111",   // Retired
        PixelPaddingValue                                      ="00280120",
        PixelPaddingRangeLimit                                 ="00280121",
        FloatPixelPaddingValue                                 ="00280122",
        DoubleFloatPixelPaddingValue                           ="00280123",
        FloatPixelPaddingRangeLimit                            ="00280124",
        DoubleFloatPixelPaddingRangeLimit                      ="00280125",
        ImageLocation                                          ="00280200",   // Retired
        QualityControlImage                                    ="00280300",
        BurnedInAnnotation                                     ="00280301",
        RecognizableVisualFeatures                             ="00280302",
        LongitudinalTemporalInformationModified                ="00280303",
        ReferencedColorPaletteInstanceUID                      ="00280304",
        TransformLabel                                         ="00280400",   // Retired
        TransformVersionNumber                                 ="00280401",   // Retired
        NumberOfTransformSteps                                 ="00280402",   // Retired
        SequenceOfCompressedData                               ="00280403",   // Retired
        DetailsOfCoefficients                                  ="00280404",   // Retired
        RowsForNthOrderCoefficients                            ="00280400",   // Retired
        ColumnsForNthOrderCoefficients                         ="00280401",   // Retired
        CoefficientCoding                                      ="00280402",   // Retired
        CoefficientCodingPointers                              ="00280403",   // Retired
        DCTLabel                                               ="00280700",   // Retired
        DataBlockDescription                                   ="00280701",   // Retired
        DataBlock                                              ="00280702",   // Retired
        NormalizationFactorFormat                              ="00280710",   // Retired
        ZonalMapNumberFormat                                   ="00280720",   // Retired
        ZonalMapLocation                                       ="00280721",   // Retired
        ZonalMapFormat                                         ="00280722",   // Retired
        AdaptiveMapFormat                                      ="00280730",   // Retired
        CodeNumberFormat                                       ="00280740",   // Retired
        CodeLabel                                              ="00280800",   // Retired
        NumberOfTables                                         ="00280802",   // Retired
        CodeTableLocation                                      ="00280803",   // Retired
        BitsForCodeWord                                        ="00280804",   // Retired
        ImageDataLocation                                      ="00280808",   // Retired
        PixelSpacingCalibrationType                            ="00280A02",
        PixelSpacingCalibrationDescription                     ="00280A04",
        PixelIntensityRelationship                             ="00281040",
        PixelIntensityRelationshipSign                         ="00281041",
        WindowCenter                                           ="00281050",
        WindowWidth                                            ="00281051",
        RescaleIntercept                                       ="00281052",
        RescaleSlope                                           ="00281053",
        RescaleType                                            ="00281054",
        WindowCenterWidthExplanation                           ="00281055",
        VOILUTFunction                                         ="00281056",
        GrayScale                                              ="00281080",   // Retired
        RecommendedViewingMode                                 ="00281090",
        GrayLookupTableDescriptor                              ="00281100",   // Retired
        RedPaletteColorLookupTableDescriptor                   ="00281101",
        GreenPaletteColorLookupTableDescriptor                 ="00281102",
        BluePaletteColorLookupTableDescriptor                  ="00281103",
        AlphaPaletteColorLookupTableDescriptor                 ="00281104",
        LargeRedPaletteColorLookupTableDescriptor              ="00281111",   // Retired
        LargeGreenPaletteColorLookupTableDescriptor            ="00281112",   // Retired
        LargeBluePaletteColorLookupTableDescriptor             ="00281113",   // Retired
        PaletteColorLookupTableUID                             ="00281199",
        GrayLookupTableData                                    ="00281200",   // Retired
        RedPaletteColorLookupTableData                         ="00281201",
        GreenPaletteColorLookupTableData                       ="00281202",
        BluePaletteColorLookupTableData                        ="00281203",
        AlphaPaletteColorLookupTableData                       ="00281204",
        LargeRedPaletteColorLookupTableData                    ="00281211",   // Retired
        LargeGreenPaletteColorLookupTableData                  ="00281212",   // Retired
        LargeBluePaletteColorLookupTableData                   ="00281213",   // Retired
        LargePaletteColorLookupTableUID                        ="00281214",   // Retired
        SegmentedRedPaletteColorLookupTableData                ="00281221",
        SegmentedGreenPaletteColorLookupTableData              ="00281222",
        SegmentedBluePaletteColorLookupTableData               ="00281223",
        BreastImplantPresent                                   ="00281300",
        PartialView                                            ="00281350",
        PartialViewDescription                                 ="00281351",
        PartialViewCodeSequence                                ="00281352",
        SpatialLocationsPreserved                              ="0028135A",
        DataFrameAssignmentSequence                            ="00281401",
        DataPathAssignment                                     ="00281402",
        BitsMappedToColorLookupTable                           ="00281403",
        BlendingLUT1Sequence                                   ="00281404",
        BlendingLUT1TransferFunction                           ="00281405",
        BlendingWeightConstant                                 ="00281406",
        BlendingLookupTableDescriptor                          ="00281407",
        BlendingLookupTableData                                ="00281408",
        EnhancedPaletteColorLookupTableSequence                ="0028140B",
        BlendingLUT2Sequence                                   ="0028140C",
        BlendingLUT2TransferFunction                           ="0028140D",
        DataPathID                                             ="0028140E",
        RGBLUTTransferFunction                                 ="0028140F",
        AlphaLUTTransferFunction                               ="00281410",
        ICCProfile                                             ="00282000",
        LossyImageCompression                                  ="00282110",
        LossyImageCompressionRatio                             ="00282112",
        LossyImageCompressionMethod                            ="00282114",
        ModalityLUTSequence                                    ="00283000",
        LUTDescriptor                                          ="00283002",
        LUTExplanation                                         ="00283003",
        ModalityLUTType                                        ="00283004",
        LUTData                                                ="00283006",
        VOILUTSequence                                         ="00283010",
        SoftcopyVOILUTSequence                                 ="00283110",
        ImagePresentationComments                              ="00284000",   // Retired
        BiPlaneAcquisitionSequence                             ="00285000",   // Retired
        RepresentativeFrameNumber                              ="00286010",
        FrameNumbersOfInterestFOI                              ="00286020",
        FrameOfInterestDescription                             ="00286022",
        FrameOfInterestType                                    ="00286023",
        MaskPointer                                            ="00286030",   // Retired
        RWavePointer                                           ="00286040",
        MaskSubtractionSequence                                ="00286100",
        MaskOperation                                          ="00286101",
        ApplicableFrameRange                                   ="00286102",
        MaskFrameNumbers                                       ="00286110",
        ContrastFrameAveraging                                 ="00286112",
        MaskSubPixelShift                                      ="00286114",
        TIDOffset                                              ="00286120",
        MaskOperationExplanation                               ="00286190",
        EquipmentAdministratorSequence                         ="00287000",
        NumberOfDisplaySubsystems                              ="00287001",
        CurrentConfigurationID                                 ="00287002",
        DisplaySubsystemID                                     ="00287003",
        DisplaySubsystemName                                   ="00287004",
        DisplaySubsystemDescription                            ="00287005",
        SystemStatus                                           ="00287006",
        SystemStatusComment                                    ="00287007",
        TargetLuminanceCharacteristicsSequence                 ="00287008",
        LuminanceCharacteristicsID                             ="00287009",
        DisplaySubsystemConfigurationSequence                  ="0028700A",
        ConfigurationID                                        ="0028700B",
        ConfigurationName                                      ="0028700C",
        ConfigurationDescription                               ="0028700D",
        ReferencedTargetLuminanceCharacteristicsID             ="0028700E",
        QAResultsSequence                                      ="0028700F",
        DisplaySubsystemQAResultsSequence                      ="00287010",
        ConfigurationQAResultsSequence                         ="00287011",
        MeasurementEquipmentSequence                           ="00287012",
        MeasurementFunctions                                   ="00287013",
        MeasurementEquipmentType                               ="00287014",
        VisualEvaluationResultSequence                         ="00287015",
        DisplayCalibrationResultSequence                       ="00287016",
        DDLValue                                               ="00287017",
        CIExyWhitePoint                                        ="00287018",
        DisplayFunctionType                                    ="00287019",
        GammaValue                                             ="0028701A",
        NumberOfLuminancePoints                                ="0028701B",
        LuminanceResponseSequence                              ="0028701C",
        TargetMinimumLuminance                                 ="0028701D",
        TargetMaximumLuminance                                 ="0028701E",
        LuminanceValue                                         ="0028701F",
        LuminanceResponseDescription                           ="00287020",
        WhitePointFlag                                         ="00287021",
        DisplayDeviceTypeCodeSequence                          ="00287022",
        DisplaySubsystemSequence                               ="00287023",
        LuminanceResultSequence                                ="00287024",
        AmbientLightValueSource                                ="00287025",
        MeasuredCharacteristics                                ="00287026",
        LuminanceUniformityResultSequence                      ="00287027",
        VisualEvaluationTestSequence                           ="00287028",
        TestResult                                             ="00287029",
        TestResultComment                                      ="0028702A",
        TestImageValidation                                    ="0028702B",
        TestPatternCodeSequence                                ="0028702C",
        MeasurementPatternCodeSequence                         ="0028702D",
        VisualEvaluationMethodCodeSequence                     ="0028702E",
        PixelDataProviderURL                                   ="00287FE0",
        DataPointRows                                          ="00289001",
        DataPointColumns                                       ="00289002",
        SignalDomainColumns                                    ="00289003",
        LargestMonochromePixelValue                            ="00289099",   // Retired
        DataRepresentation                                     ="00289108",
        PixelMeasuresSequence                                  ="00289110",
        FrameVOILUTSequence                                    ="00289132",
        PixelValueTransformationSequence                       ="00289145",
        SignalDomainRows                                       ="00289235",
        DisplayFilterPercentage                                ="00289411",
        FramePixelShiftSequence                                ="00289415",
        SubtractionItemID                                      ="00289416",
        PixelIntensityRelationshipLUTSequence                  ="00289422",
        FramePixelDataPropertiesSequence                       ="00289443",
        GeometricalProperties                                  ="00289444",
        GeometricMaximumDistortion                             ="00289445",
        ImageProcessingApplied                                 ="00289446",
        MaskSelectionMode                                      ="00289454",
        LUTFunction                                            ="00289474",
        MaskVisibilityPercentage                               ="00289478",
        PixelShiftSequence                                     ="00289501",
        RegionPixelShiftSequence                               ="00289502",
        VerticesOfTheRegion                                    ="00289503",
        MultiFramePresentationSequence                         ="00289505",
        PixelShiftFrameRange                                   ="00289506",
        LUTFrameRange                                          ="00289507",
        ImageToEquipmentMappingMatrix                          ="00289520",
        EquipmentCoordinateSystemIdentification                ="00289537",
    
    // *****************************************************************************************************************************
    // *** 0032
    // *****************************************************************************************************************************
        StudyStatusID                                          ="0032000A",   // Retired
        StudyPriorityID                                        ="0032000C",   // Retired
        StudyIDIssuer                                          ="00320012",   // Retired
        StudyVerifiedDate                                      ="00320032",   // Retired
        StudyVerifiedTime                                      ="00320033",   // Retired
        StudyReadDate                                          ="00320034",   // Retired
        StudyReadTime                                          ="00320035",   // Retired
        ScheduledStudyStartDate                                ="00321000",   // Retired
        ScheduledStudyStartTime                                ="00321001",   // Retired
        ScheduledStudyStopDate                                 ="00321010",   // Retired
        ScheduledStudyStopTime                                 ="00321011",   // Retired
        ScheduledStudyLocation                                 ="00321020",   // Retired
        ScheduledStudyLocationAETitle                          ="00321021",   // Retired
        ReasonForStudy                                         ="00321030",   // Retired
        RequestingPhysicianIdentificationSequence              ="00321031",
        RequestingPhysician                                    ="00321032",
        RequestingService                                      ="00321033",
        RequestingServiceCodeSequence                          ="00321034",
        StudyArrivalDate                                       ="00321040",   // Retired
        StudyArrivalTime                                       ="00321041",   // Retired
        StudyCompletionDate                                    ="00321050",   // Retired
        StudyCompletionTime                                    ="00321051",   // Retired
        StudyComponentStatusID                                 ="00321055",   // Retired
        RequestedProcedureDescription                          ="00321060",
        RequestedProcedureCodeSequence                         ="00321064",
        RequestedContrastAgent                                 ="00321070",
        StudyComments                                          ="00324000",   // Retired
    
    // *****************************************************************************************************************************
    // *** 0038
    // *****************************************************************************************************************************
        ReferencedPatientAliasSequence                         ="00380004",
        VisitStatusID                                          ="00380008",
        AdmissionID                                            ="00380010",
        IssuerOfAdmissionID                                    ="00380011",   // Retired
        IssuerOfAdmissionIDSequence                            ="00380014",
        RouteOfAdmissions                                      ="00380016",
        ScheduledAdmissionDate                                 ="0038001A",   // Retired
        ScheduledAdmissionTime                                 ="0038001B",   // Retired
        ScheduledDischargeDate                                 ="0038001C",   // Retired
        ScheduledDischargeTime                                 ="0038001D",   // Retired
        ScheduledPatientInstitutionResidence                   ="0038001E",   // Retired
        AdmittingDate                                          ="00380020",
        AdmittingTime                                          ="00380021",
        DischargeDate                                          ="00380030",   // Retired
        DischargeTime                                          ="00380032",   // Retired
        DischargeDiagnosisDescription                          ="00380040",   // Retired
        DischargeDiagnosisCodeSequence                         ="00380044",   // Retired
        SpecialNeeds                                           ="00380050",
        ServiceEpisodeID                                       ="00380060",
        IssuerOfServiceEpisodeID                               ="00380061",   // Retired
        ServiceEpisodeDescription                              ="00380062",
        IssuerOfServiceEpisodeIDSequence                       ="00380064",
        PertinentDocumentsSequence                             ="00380100",
        PertinentResourcesSequence                             ="00380101",
        ResourceDescription                                    ="00380102",
        CurrentPatientLocation                                 ="00380300",
        PatientInstitutionResidence                            ="00380400",
        PatientState                                           ="00380500",
        PatientClinicalTrialParticipationSequence              ="00380502",
        VisitComments                                          ="00384000",
    
    // *****************************************************************************************************************************
    // *** 003A
    // *****************************************************************************************************************************
        WaveformOriginality                                    ="003A0004",
        NumberOfWaveformChannels                               ="003A0005",
        NumberOfWaveformSamples                                ="003A0010",
        SamplingFrequency                                      ="003A001A",
        MultiplexGroupLabel                                    ="003A0020",
        ChannelDefinitionSequence                              ="003A0200",
        WaveformChannelNumber                                  ="003A0202",
        ChannelLabel                                           ="003A0203",
        ChannelStatus                                          ="003A0205",
        ChannelSourceSequence                                  ="003A0208",
        ChannelSourceModifiersSequence                         ="003A0209",
        SourceWaveformSequence                                 ="003A020A",
        ChannelDerivationDescription                           ="003A020C",
        ChannelSensitivity                                     ="003A0210",
        ChannelSensitivityUnitsSequence                        ="003A0211",
        ChannelSensitivityCorrectionFactor                     ="003A0212",
        ChannelBaseline                                        ="003A0213",
        ChannelTimeSkew                                        ="003A0214",
        ChannelSampleSkew                                      ="003A0215",
        ChannelOffset                                          ="003A0218",
        WaveformBitsStored                                     ="003A021A",
        FilterLowFrequency                                     ="003A0220",
        FilterHighFrequency                                    ="003A0221",
        NotchFilterFrequency                                   ="003A0222",
        NotchFilterBandwidth                                   ="003A0223",
        WaveformDataDisplayScale                               ="003A0230",
        WaveformDisplayBackgroundCIELabValue                   ="003A0231",
        WaveformPresentationGroupSequence                      ="003A0240",
        PresentationGroupNumber                                ="003A0241",
        ChannelDisplaySequence                                 ="003A0242",
        ChannelRecommendedDisplayCIELabValue                   ="003A0244",
        ChannelPosition                                        ="003A0245",
        DisplayShadingFlag                                     ="003A0246",
        FractionalChannelDisplayScale                          ="003A0247",
        AbsoluteChannelDisplayScale                            ="003A0248",
        MultiplexedAudioChannelsDescriptionCodeSequence        ="003A0300",
        ChannelIdentificationCode                              ="003A0301",
        ChannelMode                                            ="003A0302",
    
    // *****************************************************************************************************************************
    // *** 0040
    // *****************************************************************************************************************************
        ScheduledStationAETitle                                ="00400001",
        ScheduledProcedureStepStartDate                        ="00400002",
        ScheduledProcedureStepStartTime                        ="00400003",
        ScheduledProcedureStepEndDate                          ="00400004",
        ScheduledProcedureStepEndTime                          ="00400005",
        ScheduledPerformingPhysicianName                       ="00400006",
        ScheduledProcedureStepDescription                      ="00400007",
        ScheduledProtocolCodeSequence                          ="00400008",
        ScheduledProcedureStepID                               ="00400009",
        StageCodeSequence                                      ="0040000A",
        ScheduledPerformingPhysicianIdentificationSequence     ="0040000B",
        ScheduledStationName                                   ="00400010",
        ScheduledProcedureStepLocation                         ="00400011",
        PreMedication                                          ="00400012",
        ScheduledProcedureStepStatus                           ="00400020",
        OrderPlacerIdentifierSequence                          ="00400026",
        OrderFillerIdentifierSequence                          ="00400027",
        LocalNamespaceEntityID                                 ="00400031",
        UniversalEntityID                                      ="00400032",
        UniversalEntityIDType                                  ="00400033",
        IdentifierTypeCode                                     ="00400035",
        AssigningFacilitySequence                              ="00400036",
        AssigningJurisdictionCodeSequence                      ="00400039",
        AssigningAgencyOrDepartmentCodeSequence                ="0040003A",
        ScheduledProcedureStepSequence                         ="00400100",
        ReferencedNonImageCompositeSOPInstanceSequence         ="00400220",
        PerformedStationAETitle                                ="00400241",
        PerformedStationName                                   ="00400242",
        PerformedLocation                                      ="00400243",
        PerformedProcedureStepStartDate                        ="00400244",
        PerformedProcedureStepStartTime                        ="00400245",
        PerformedProcedureStepEndDate                          ="00400250",
        PerformedProcedureStepEndTime                          ="00400251",
        PerformedProcedureStepStatus                           ="00400252",
        PerformedProcedureStepID                               ="00400253",
        PerformedProcedureStepDescription                      ="00400254",
        PerformedProcedureTypeDescription                      ="00400255",
        PerformedProtocolCodeSequence                          ="00400260",
        PerformedProtocolType                                  ="00400261",
        ScheduledStepAttributesSequence                        ="00400270",
        RequestAttributesSequence                              ="00400275",
        CommentsOnThePerformedProcedureStep                    ="00400280",
        PerformedProcedureStepDiscontinuationReasonCodeSequence ="00400281",
        QuantitySequence                                       ="00400293",
        Quantity                                               ="00400294",
        MeasuringUnitsSequence                                 ="00400295",
        BillingItemSequence                                    ="00400296",
        TotalTimeOfFluoroscopy                                 ="00400300",
        TotalNumberOfExposures                                 ="00400301",
        EntranceDose                                           ="00400302",
        ExposedArea                                            ="00400303",
        DistanceSourceToEntrance                               ="00400306",
        DistanceSourceToSupport                                ="00400307",   // Retired
        ExposureDoseSequence                                   ="0040030E",
        CommentsOnRadiationDose                                ="00400310",
        XRayOutput                                             ="00400312",
        HalfValueLayer                                         ="00400314",
        OrganDose                                              ="00400316",
        OrganExposed                                           ="00400318",
        BillingProcedureStepSequence                           ="00400320",
        FilmConsumptionSequence                                ="00400321",
        BillingSuppliesAndDevicesSequence                      ="00400324",
        ReferencedProcedureStepSequence                        ="00400330",   // Retired
        PerformedSeriesSequence                                ="00400340",
        CommentsOnTheScheduledProcedureStep                    ="00400400",
        ProtocolContextSequence                                ="00400440",
        ContentItemModifierSequence                            ="00400441",
        ScheduledSpecimenSequence                              ="00400500",
        SpecimenAccessionNumber                                ="0040050A",   // Retired
        ContainerIdentifier                                    ="00400512",
        IssuerOfTheContainerIdentifierSequence                 ="00400513",
        AlternateContainerIdentifierSequence                   ="00400515",
        ContainerTypeCodeSequence                              ="00400518",
        ContainerDescription                                   ="0040051A",
        ContainerComponentSequence                             ="00400520",
        SpecimenSequence                                       ="00400550",   // Retired
        SpecimenIdentifier                                     ="00400551",
        SpecimenDescriptionSequenceTrial                       ="00400552",   // Retired
        SpecimenDescriptionTrial                               ="00400553",   // Retired
        SpecimenUID                                            ="00400554",
        AcquisitionContextSequence                             ="00400555",
        AcquisitionContextDescription                          ="00400556",
        SpecimenDescriptionSequence                            ="00400560",
        IssuerOfTheSpecimenIdentifierSequence                  ="00400562",
        SpecimenTypeCodeSequence                               ="0040059A",
        SpecimenShortDescription                               ="00400600",
        SpecimenDetailedDescription                            ="00400602",
        SpecimenPreparationSequence                            ="00400610",
        SpecimenPreparationStepContentItemSequence             ="00400612",
        SpecimenLocalizationContentItemSequence                ="00400620",
        SlideIdentifier                                        ="004006FA",   // Retired
        ImageCenterPointCoordinatesSequence                    ="0040071A",
        XOffsetInSlideCoordinateSystem                         ="0040072A",
        YOffsetInSlideCoordinateSystem                         ="0040073A",
        ZOffsetInSlideCoordinateSystem                         ="0040074A",
        PixelSpacingSequence                                   ="004008D8",   // Retired
        CoordinateSystemAxisCodeSequence                       ="004008DA",   // Retired
        MeasurementUnitsCodeSequence                           ="004008EA",
        VitalStainCodeSequenceTrial                            ="004009F8",   // Retired
        RequestedProcedureID                                   ="00401001",
        ReasonForTheRequestedProcedure                         ="00401002",
        RequestedProcedurePriority                             ="00401003",
        PatientTransportArrangements                           ="00401004",
        RequestedProcedureLocation                             ="00401005",
        PlacerOrderNumberProcedure                             ="00401006",   // Retired
        FillerOrderNumberProcedure                             ="00401007",   // Retired
        ConfidentialityCode                                    ="00401008",
        ReportingPriority                                      ="00401009",
        ReasonForRequestedProcedureCodeSequence                ="0040100A",
        NamesOfIntendedRecipientsOfResults                     ="00401010",
        IntendedRecipientsOfResultsIdentificationSequence      ="00401011",
        ReasonForPerformedProcedureCodeSequence                ="00401012",
        RequestedProcedureDescriptionTrial                     ="00401060",   // Retired
        PersonIdentificationCodeSequence                       ="00401101",
        PersonAddress                                          ="00401102",
        PersonTelephoneNumbers                                 ="00401103",
        PersonTelecomInformation                               ="00401104",
        RequestedProcedureComments                             ="00401400",
        ReasonForTheImagingServiceRequest                      ="00402001",   // Retired
        IssueDateOfImagingServiceRequest                       ="00402004",
        IssueTimeOfImagingServiceRequest                       ="00402005",
        PlacerOrderNumberImagingServiceRequestRetired          ="00402006",   // Retired
        FillerOrderNumberImagingServiceRequestRetired          ="00402007",   // Retired
        OrderEnteredBy                                         ="00402008",
        OrderEntererLocation                                   ="00402009",
        OrderCallbackPhoneNumber                               ="00402010",
        OrderCallbackTelecomInformation                        ="00402011",
        PlacerOrderNumberImagingServiceRequest                 ="00402016",
        FillerOrderNumberImagingServiceRequest                 ="00402017",
        ImagingServiceRequestComments                          ="00402400",
        ConfidentialityConstraintOnPatientDataDescription      ="00403001",
        GeneralPurposeScheduledProcedureStepStatus             ="00404001",   // Retired
        GeneralPurposePerformedProcedureStepStatus             ="00404002",   // Retired
        GeneralPurposeScheduledProcedureStepPriority           ="00404003",   // Retired
        ScheduledProcessingApplicationsCodeSequence            ="00404004",   // Retired
        ScheduledProcedureStepStartDateTime                    ="00404005",
        MultipleCopiesFlag                                     ="00404006",   // Retired
        PerformedProcessingApplicationsCodeSequence            ="00404007",
        HumanPerformerCodeSequence                             ="00404009",
        ScheduledProcedureStepModificationDateTime             ="00404010",
        ExpectedCompletionDateTime                             ="00404011",
        ResultingGeneralPurposePerformedProcedureStepsSequence ="00404015",   // Retired
        ReferencedGeneralPurposeScheduledProcedureStepSequence ="00404016",   // Retired
        ScheduledWorkitemCodeSequence                          ="00404018",
        PerformedWorkitemCodeSequence                          ="00404019",
        InputAvailabilityFlag                                  ="00404020",
        InputInformationSequence                               ="00404021",
        RelevantInformationSequence                            ="00404022",   // Retired
        ReferencedGeneralPurposeScheduledProcedureStepTransactionUID ="00404023",   // Retired
        ScheduledStationNameCodeSequence                       ="00404025",
        ScheduledStationClassCodeSequence                      ="00404026",
        ScheduledStationGeographicLocationCodeSequence         ="00404027",
        PerformedStationNameCodeSequence                       ="00404028",
        PerformedStationClassCodeSequence                      ="00404029",
        PerformedStationGeographicLocationCodeSequence         ="00404030",
        RequestedSubsequentWorkitemCodeSequence                ="00404031",   // Retired
        NonDICOMOutputCodeSequence                             ="00404032",   // Retired
        OutputInformationSequence                              ="00404033",
        ScheduledHumanPerformersSequence                       ="00404034",
        ActualHumanPerformersSequence                          ="00404035",
        HumanPerformerOrganization                             ="00404036",
        HumanPerformerName                                     ="00404037",
        RawDataHandling                                        ="00404040",
        InputReadinessState                                    ="00404041",
        PerformedProcedureStepStartDateTime                    ="00404050",
        PerformedProcedureStepEndDateTime                      ="00404051",
        ProcedureStepCancellationDateTime                      ="00404052",
        EntranceDoseInMGy                                      ="00408302",
        ParametricMapFrameTypeSequence                         ="00409092",
        ReferencedImageRealWorldValueMappingSequence           ="00409094",
        RealWorldValueMappingSequence                          ="00409096",
        PixelValueMappingCodeSequence                          ="00409098",
        LUTLabel                                               ="00409210",
        RealWorldValueLastValueMapped                          ="00409211",
        RealWorldValueLUTData                                  ="00409212",
        RealWorldValueFirstValueMapped                         ="00409216",
        QuantityDefinitionSequence                             ="00409220",
        RealWorldValueIntercept                                ="00409224",
        RealWorldValueSlope                                    ="00409225",
        FindingsFlagTrial                                      ="0040A007",   // Retired
        RelationshipType                                       ="0040A010",
        FindingsSequenceTrial                                  ="0040A020",   // Retired
        FindingsGroupUIDTrial                                  ="0040A021",   // Retired
        ReferencedFindingsGroupUIDTrial                        ="0040A022",   // Retired
        FindingsGroupRecordingDateTrial                        ="0040A023",   // Retired
        FindingsGroupRecordingTimeTrial                        ="0040A024",   // Retired
        FindingsSourceCategoryCodeSequenceTrial                ="0040A026",   // Retired
        VerifyingOrganization                                  ="0040A027",
        DocumentingOrganizationIdentifierCodeSequenceTrial     ="0040A028",   // Retired
        VerificationDateTime                                   ="0040A030",
        ObservationDateTime                                    ="0040A032",
        ValueType                                              ="0040A040",
        ConceptNameCodeSequence                                ="0040A043",
        MeasurementPrecisionDescriptionTrial                   ="0040A047",   // Retired
        ContinuityOfContent                                    ="0040A050",
        UrgencyOrPriorityAlertsTrial                           ="0040A057",   // Retired
        SequencingIndicatorTrial                               ="0040A060",   // Retired
        DocumentIdentifierCodeSequenceTrial                    ="0040A066",   // Retired
        DocumentAuthorTrial                                    ="0040A067",   // Retired
        DocumentAuthorIdentifierCodeSequenceTrial              ="0040A068",   // Retired
        IdentifierCodeSequenceTrial                            ="0040A070",   // Retired
        VerifyingObserverSequence                              ="0040A073",
        ObjectBinaryIdentifierTrial                            ="0040A074",   // Retired
        VerifyingObserverName                                  ="0040A075",
        DocumentingObserverIdentifierCodeSequenceTrial         ="0040A076",   // Retired
        AuthorObserverSequence                                 ="0040A078",
        ParticipantSequence                                    ="0040A07A",
        CustodialOrganizationSequence                          ="0040A07C",
        ParticipationType                                      ="0040A080",
        ParticipationDateTime                                  ="0040A082",
        ObserverType                                           ="0040A084",
        ProcedureIdentifierCodeSequenceTrial                   ="0040A085",   // Retired
        VerifyingObserverIdentificationCodeSequence            ="0040A088",
        ObjectDirectoryBinaryIdentifierTrial                   ="0040A089",   // Retired
        EquivalentCDADocumentSequence                          ="0040A090",   // Retired
        ReferencedWaveformChannels                             ="0040A0B0",
        DateOfDocumentOrVerbalTransactionTrial                 ="0040A110",   // Retired
        TimeOfDocumentCreationOrVerbalTransactionTrial         ="0040A112",   // Retired
        DateTime                                               ="0040A120",
        Date                                                   ="0040A121",
        Time                                                   ="0040A122",
        PersonName                                             ="0040A123",
        UID                                                    ="0040A124",
        ReportStatusIDTrial                                    ="0040A125",   // Retired
        TemporalRangeType                                      ="0040A130",
        ReferencedSamplePositions                              ="0040A132",
        ReferencedFrameNumbers                                 ="0040A136",
        ReferencedTimeOffsets                                  ="0040A138",
        ReferencedDateTime                                     ="0040A13A",
        TextValue                                              ="0040A160",
        FloatingPointValue                                     ="0040A161",
        RationalNumeratorValue                                 ="0040A162",
        RationalDenominatorValue                               ="0040A163",
        ObservationCategoryCodeSequenceTrial                   ="0040A167",   // Retired
        ConceptCodeSequence                                    ="0040A168",
        BibliographicCitationTrial                             ="0040A16A",   // Retired
        PurposeOfReferenceCodeSequence                         ="0040A170",
        ObservationUID                                         ="0040A171",
        ReferencedObservationUIDTrial                          ="0040A172",   // Retired
        ReferencedObservationClassTrial                        ="0040A173",   // Retired
        ReferencedObjectObservationClassTrial                  ="0040A174",   // Retired
        AnnotationGroupNumber                                  ="0040A180",
        ObservationDateTrial                                   ="0040A192",   // Retired
        ObservationTimeTrial                                   ="0040A193",   // Retired
        MeasurementAutomationTrial                             ="0040A194",   // Retired
        ModifierCodeSequence                                   ="0040A195",
        IdentificationDescriptionTrial                         ="0040A224",   // Retired
        CoordinatesSetGeometricTypeTrial                       ="0040A290",   // Retired
        AlgorithmCodeSequenceTrial                             ="0040A296",   // Retired
        AlgorithmDescriptionTrial                              ="0040A297",   // Retired
        PixelCoordinatesSetTrial                               ="0040A29A",   // Retired
        MeasuredValueSequence                                  ="0040A300",
        NumericValueQualifierCodeSequence                      ="0040A301",
        CurrentObserverTrial                                   ="0040A307",   // Retired
        NumericValue                                           ="0040A30A",
        ReferencedAccessionSequenceTrial                       ="0040A313",   // Retired
        ReportStatusCommentTrial                               ="0040A33A",   // Retired
        ProcedureContextSequenceTrial                          ="0040A340",   // Retired
        VerbalSourceTrial                                      ="0040A352",   // Retired
        AddressTrial                                           ="0040A353",   // Retired
        TelephoneNumberTrial                                   ="0040A354",   // Retired
        VerbalSourceIdentifierCodeSequenceTrial                ="0040A358",   // Retired
        PredecessorDocumentsSequence                           ="0040A360",
        ReferencedRequestSequence                              ="0040A370",
        PerformedProcedureCodeSequence                         ="0040A372",
        CurrentRequestedProcedureEvidenceSequence              ="0040A375",
        ReportDetailSequenceTrial                              ="0040A380",   // Retired
        PertinentOtherEvidenceSequence                         ="0040A385",
        HL7StructuredDocumentReferenceSequence                 ="0040A390",
        ObservationSubjectUIDTrial                             ="0040A402",   // Retired
        ObservationSubjectClassTrial                           ="0040A403",   // Retired
        ObservationSubjectTypeCodeSequenceTrial                ="0040A404",   // Retired
        CompletionFlag                                         ="0040A491",
        CompletionFlagDescription                              ="0040A492",
        VerificationFlag                                       ="0040A493",
        ArchiveRequested                                       ="0040A494",
        PreliminaryFlag                                        ="0040A496",
        ContentTemplateSequence                                ="0040A504",
        IdenticalDocumentsSequence                             ="0040A525",
        ObservationSubjectContextFlagTrial                     ="0040A600",   // Retired
        ObserverContextFlagTrial                               ="0040A601",   // Retired
        ProcedureContextFlagTrial                              ="0040A603",   // Retired
        ContentSequence                                        ="0040A730",
        RelationshipSequenceTrial                              ="0040A731",   // Retired
        RelationshipTypeCodeSequenceTrial                      ="0040A732",   // Retired
        LanguageCodeSequenceTrial                              ="0040A744",   // Retired
        UniformResourceLocatorTrial                            ="0040A992",   // Retired
        WaveformAnnotationSequence                             ="0040B020",
        TemplateIdentifier                                     ="0040DB00",
        TemplateVersion                                        ="0040DB06",   // Retired
        TemplateLocalVersion                                   ="0040DB07",   // Retired
        TemplateExtensionFlag                                  ="0040DB0B",   // Retired
        TemplateExtensionOrganizationUID                       ="0040DB0C",   // Retired
        TemplateExtensionCreatorUID                            ="0040DB0D",   // Retired
        ReferencedContentItemIdentifier                        ="0040DB73",
        HL7InstanceIdentifier                                  ="0040E001",
        HL7DocumentEffectiveTime                               ="0040E004",
        HL7DocumentTypeCodeSequence                            ="0040E006",
        DocumentClassCodeSequence                              ="0040E008",
        RetrieveURI                                            ="0040E010",
        RetrieveLocationUID                                    ="0040E011",
        TypeOfInstances                                        ="0040E020",
        DICOMRetrievalSequence                                 ="0040E021",
        DICOMMediaRetrievalSequence                            ="0040E022",
        WADORetrievalSequence                                  ="0040E023",
        XDSRetrievalSequence                                   ="0040E024",
        WADORSRetrievalSequence                                ="0040E025",
        RepositoryUniqueID                                     ="0040E030",
        HomeCommunityID                                        ="0040E031",
    
    // *****************************************************************************************************************************
    // *** 0042
    // *****************************************************************************************************************************
        DocumentTitle                                          ="00420010",
        EncapsulatedDocument                                   ="00420011",
        MIMETypeOfEncapsulatedDocument                         ="00420012",
        SourceInstanceSequence                                 ="00420013",
        ListOfMIMETypes                                        ="00420014",
    
    // *****************************************************************************************************************************
    // *** 0044
    // *****************************************************************************************************************************
        ProductPackageIdentifier                               ="00440001",
        SubstanceAdministrationApproval                        ="00440002",
        ApprovalStatusFurtherDescription                       ="00440003",
        ApprovalStatusDateTime                                 ="00440004",
        ProductTypeCodeSequence                                ="00440007",
        ProductName                                            ="00440008",
        ProductDescription                                     ="00440009",
        ProductLotIdentifier                                   ="0044000A",
        ProductExpirationDateTime                              ="0044000B",
        SubstanceAdministrationDateTime                        ="00440010",
        SubstanceAdministrationNotes                           ="00440011",
        SubstanceAdministrationDeviceID                        ="00440012",
        ProductParameterSequence                               ="00440013",
        SubstanceAdministrationParameterSequence               ="00440019",
    
    // *****************************************************************************************************************************
    // *** 0046
    // *****************************************************************************************************************************
        LensDescription                                        ="00460012",
        RightLensSequence                                      ="00460014",
        LeftLensSequence                                       ="00460015",
        UnspecifiedLateralityLensSequence                      ="00460016",
        CylinderSequence                                       ="00460018",
        PrismSequence                                          ="00460028",
        HorizontalPrismPower                                   ="00460030",
        HorizontalPrismBase                                    ="00460032",
        VerticalPrismPower                                     ="00460034",
        VerticalPrismBase                                      ="00460036",
        LensSegmentType                                        ="00460038",
        OpticalTransmittance                                   ="00460040",
        ChannelWidth                                           ="00460042",
        PupilSize                                              ="00460044",
        CornealSize                                            ="00460046",
        AutorefractionRightEyeSequence                         ="00460050",
        AutorefractionLeftEyeSequence                          ="00460052",
        DistancePupillaryDistance                              ="00460060",
        NearPupillaryDistance                                  ="00460062",
        IntermediatePupillaryDistance                          ="00460063",
        OtherPupillaryDistance                                 ="00460064",
        KeratometryRightEyeSequence                            ="00460070",
        KeratometryLeftEyeSequence                             ="00460071",
        SteepKeratometricAxisSequence                          ="00460074",
        RadiusOfCurvature                                      ="00460075",
        KeratometricPower                                      ="00460076",
        KeratometricAxis                                       ="00460077",
        FlatKeratometricAxisSequence                           ="00460080",
        BackgroundColor                                        ="00460092",
        Optotype                                               ="00460094",
        OptotypePresentation                                   ="00460095",
        SubjectiveRefractionRightEyeSequence                   ="00460097",
        SubjectiveRefractionLeftEyeSequence                    ="00460098",
        AddNearSequence                                        ="00460100",
        AddIntermediateSequence                                ="00460101",
        AddOtherSequence                                       ="00460102",
        AddPower                                               ="00460104",
        ViewingDistance                                        ="00460106",
        VisualAcuityTypeCodeSequence                           ="00460121",
        VisualAcuityRightEyeSequence                           ="00460122",
        VisualAcuityLeftEyeSequence                            ="00460123",
        VisualAcuityBothEyesOpenSequence                       ="00460124",
        ViewingDistanceType                                    ="00460125",
        VisualAcuityModifiers                                  ="00460135",
        DecimalVisualAcuity                                    ="00460137",
        OptotypeDetailedDefinition                             ="00460139",
        ReferencedRefractiveMeasurementsSequence               ="00460145",
        SpherePower                                            ="00460146",
        CylinderPower                                          ="00460147",
        CornealTopographySurface                               ="00460201",
        CornealVertexLocation                                  ="00460202",
        PupilCentroidXCoordinate                               ="00460203",
        PupilCentroidYCoordinate                               ="00460204",
        EquivalentPupilRadius                                  ="00460205",
        CornealTopographyMapTypeCodeSequence                   ="00460207",
        VerticesOfTheOutlineOfPupil                            ="00460208",
        CornealTopographyMappingNormalsSequence                ="00460210",
        MaximumCornealCurvatureSequence                        ="00460211",
        MaximumCornealCurvature                                ="00460212",
        MaximumCornealCurvatureLocation                        ="00460213",
        MinimumKeratometricSequence                            ="00460215",
        SimulatedKeratometricCylinderSequence                  ="00460218",
        AverageCornealPower                                    ="00460220",
        CornealISValue                                         ="00460224",
        AnalyzedArea                                           ="00460227",
        SurfaceRegularityIndex                                 ="00460230",
        SurfaceAsymmetryIndex                                  ="00460232",
        CornealEccentricityIndex                               ="00460234",
        KeratoconusPredictionIndex                             ="00460236",
        DecimalPotentialVisualAcuity                           ="00460238",
        CornealTopographyMapQualityEvaluation                  ="00460242",
        SourceImageCornealProcessedDataSequence                ="00460244",
        CornealPointLocation                                   ="00460247",
        CornealPointEstimated                                  ="00460248",
        AxialPower                                             ="00460249",
        TangentialPower                                        ="00460250",
        RefractivePower                                        ="00460251",
        RelativeElevation                                      ="00460252",
        CornealWavefront                                       ="00460253",
    
    // *****************************************************************************************************************************
    // *** 0048
    // *****************************************************************************************************************************
        ImagedVolumeWidth                                      ="00480001",
        ImagedVolumeHeight                                     ="00480002",
        ImagedVolumeDepth                                      ="00480003",
        TotalPixelMatrixColumns                                ="00480006",
        TotalPixelMatrixRows                                   ="00480007",
        TotalPixelMatrixOriginSequence                         ="00480008",
        SpecimenLabelInImage                                   ="00480010",
        FocusMethod                                            ="00480011",
        ExtendedDepthOfField                                   ="00480012",
        NumberOfFocalPlanes                                    ="00480013",
        DistanceBetweenFocalPlanes                             ="00480014",
        RecommendedAbsentPixelCIELabValue                      ="00480015",
        IlluminatorTypeCodeSequence                            ="00480100",
        ImageOrientationSlide                                  ="00480102",
        OpticalPathSequence                                    ="00480105",
        OpticalPathIdentifier                                  ="00480106",
        OpticalPathDescription                                 ="00480107",
        IlluminationColorCodeSequence                          ="00480108",
        SpecimenReferenceSequence                              ="00480110",
        CondenserLensPower                                     ="00480111",
        ObjectiveLensPower                                     ="00480112",
        ObjectiveLensNumericalAperture                         ="00480113",
        PaletteColorLookupTableSequence                        ="00480120",
        ReferencedImageNavigationSequence                      ="00480200",
        TopLeftHandCornerOfLocalizerArea                       ="00480201",
        BottomRightHandCornerOfLocalizerArea                   ="00480202",
        OpticalPathIdentificationSequence                      ="00480207",
        PlanePositionSlideSequence                             ="0048021A",
        ColumnPositionInTotalImagePixelMatrix                  ="0048021E",
        RowPositionInTotalImagePixelMatrix                     ="0048021F",
        PixelOriginInterpretation                              ="00480301",
    
    // *****************************************************************************************************************************
    // *** 0050
    // *****************************************************************************************************************************
        CalibrationImage                                       ="00500004",
        DeviceSequence                                         ="00500010",
        ContainerComponentTypeCodeSequence                     ="00500012",
        ContainerComponentThickness                            ="00500013",
        DeviceLength                                           ="00500014",
        ContainerComponentWidth                                ="00500015",
        DeviceDiameter                                         ="00500016",
        DeviceDiameterUnits                                    ="00500017",
        DeviceVolume                                           ="00500018",
        IntermarkerDistance                                    ="00500019",
        ContainerComponentMaterial                             ="0050001A",
        ContainerComponentID                                   ="0050001B",
        ContainerComponentLength                               ="0050001C",
        ContainerComponentDiameter                             ="0050001D",
        ContainerComponentDescription                          ="0050001E",
        DeviceDescription                                      ="00500020",
    
    // *****************************************************************************************************************************
    // *** 0052
    // *****************************************************************************************************************************
        ContrastBolusIngredientPercentByVolume                 ="00520001",
        OCTFocalDistance                                       ="00520002",
        BeamSpotSize                                           ="00520003",
        EffectiveRefractiveIndex                               ="00520004",
        OCTAcquisitionDomain                                   ="00520006",
        OCTOpticalCenterWavelength                             ="00520007",
        AxialResolution                                        ="00520008",
        RangingDepth                                           ="00520009",
        ALineRate                                              ="00520011",
        ALinesPerFrame                                         ="00520012",
        CatheterRotationalRate                                 ="00520013",
        ALinePixelSpacing                                      ="00520014",
        ModeOfPercutaneousAccessSequence                       ="00520016",
        IntravascularOCTFrameTypeSequence                      ="00520025",
        OCTZOffsetApplied                                      ="00520026",
        IntravascularFrameContentSequence                      ="00520027",
        IntravascularLongitudinalDistance                      ="00520028",
        IntravascularOCTFrameContentSequence                   ="00520029",
        OCTZOffsetCorrection                                   ="00520030",
        CatheterDirectionOfRotation                            ="00520031",
        SeamLineLocation                                       ="00520033",
        FirstALineLocation                                     ="00520034",
        SeamLineIndex                                          ="00520036",
        NumberOfPaddedALines                                   ="00520038",
        InterpolationType                                      ="00520039",
        RefractiveIndexApplied                                 ="0052003A",
    
    // *****************************************************************************************************************************
    // *** 0054
    // *****************************************************************************************************************************
        EnergyWindowVector                                     ="00540010",
        NumberOfEnergyWindows                                  ="00540011",
        EnergyWindowInformationSequence                        ="00540012",
        EnergyWindowRangeSequence                              ="00540013",
        EnergyWindowLowerLimit                                 ="00540014",
        EnergyWindowUpperLimit                                 ="00540015",
        RadiopharmaceuticalInformationSequence                 ="00540016",
        ResidualSyringeCounts                                  ="00540017",
        EnergyWindowName                                       ="00540018",
        DetectorVector                                         ="00540020",
        NumberOfDetectors                                      ="00540021",
        DetectorInformationSequence                            ="00540022",
        PhaseVector                                            ="00540030",
        NumberOfPhases                                         ="00540031",
        PhaseInformationSequence                               ="00540032",
        NumberOfFramesInPhase                                  ="00540033",
        PhaseDelay                                             ="00540036",
        PauseBetweenFrames                                     ="00540038",
        PhaseDescription                                       ="00540039",
        RotationVector                                         ="00540050",
        NumberOfRotations                                      ="00540051",
        RotationInformationSequence                            ="00540052",
        NumberOfFramesInRotation                               ="00540053",
        RRIntervalVector                                       ="00540060",
        NumberOfRRIntervals                                    ="00540061",
        GatedInformationSequence                               ="00540062",
        DataInformationSequence                                ="00540063",
        TimeSlotVector                                         ="00540070",
        NumberOfTimeSlots                                      ="00540071",
        TimeSlotInformationSequence                            ="00540072",
        TimeSlotTime                                           ="00540073",
        SliceVector                                            ="00540080",
        NumberOfSlices                                         ="00540081",
        AngularViewVector                                      ="00540090",
        TimeSliceVector                                        ="00540100",
        NumberOfTimeSlices                                     ="00540101",
        StartAngle                                             ="00540200",
        TypeOfDetectorMotion                                   ="00540202",
        TriggerVector                                          ="00540210",
        NumberOfTriggersInPhase                                ="00540211",
        ViewCodeSequence                                       ="00540220",
        ViewModifierCodeSequence                               ="00540222",
        RadionuclideCodeSequence                               ="00540300",
        AdministrationRouteCodeSequence                        ="00540302",
        RadiopharmaceuticalCodeSequence                        ="00540304",
        CalibrationDataSequence                                ="00540306",
        EnergyWindowNumber                                     ="00540308",
        ImageID                                                ="00540400",
        PatientOrientationCodeSequence                         ="00540410",
        PatientOrientationModifierCodeSequence                 ="00540412",
        PatientGantryRelationshipCodeSequence                  ="00540414",
        SliceProgressionDirection                              ="00540500",
        ScanProgressionDirection                               ="00540501",
        SeriesType                                             ="00541000",
        Units                                                  ="00541001",
        CountsSource                                           ="00541002",
        ReprojectionMethod                                     ="00541004",
        SUVType                                                ="00541006",
        RandomsCorrectionMethod                                ="00541100",
        AttenuationCorrectionMethod                            ="00541101",
        DecayCorrection                                        ="00541102",
        ReconstructionMethod                                   ="00541103",
        DetectorLinesOfResponseUsed                            ="00541104",
        ScatterCorrectionMethod                                ="00541105",
        AxialAcceptance                                        ="00541200",
        AxialMash                                              ="00541201",
        TransverseMash                                         ="00541202",
        DetectorElementSize                                    ="00541203",
        CoincidenceWindowWidth                                 ="00541210",
        SecondaryCountsType                                    ="00541220",
        FrameReferenceTime                                     ="00541300",
        PrimaryPromptsCountsAccumulated                        ="00541310",
        SecondaryCountsAccumulated                             ="00541311",
        SliceSensitivityFactor                                 ="00541320",
        DecayFactor                                            ="00541321",
        DoseCalibrationFactor                                  ="00541322",
        ScatterFractionFactor                                  ="00541323",
        DeadTimeFactor                                         ="00541324",
        ImageIndex                                             ="00541330",
        CountsIncluded                                         ="00541400",   // Retired
        DeadTimeCorrectionFlag                                 ="00541401",   // Retired
    
    // *****************************************************************************************************************************
    // *** 0060
    // *****************************************************************************************************************************
        HistogramSequence                                      ="00603000",
        HistogramNumberOfBins                                  ="00603002",
        HistogramFirstBinValue                                 ="00603004",
        HistogramLastBinValue                                  ="00603006",
        HistogramBinWidth                                      ="00603008",
        HistogramExplanation                                   ="00603010",
        HistogramData                                          ="00603020",
    
    // *****************************************************************************************************************************
    // *** 0062
    // *****************************************************************************************************************************
        SegmentationType                                       ="00620001",
        SegmentSequence                                        ="00620002",
        SegmentedPropertyCategoryCodeSequence                  ="00620003",
        SegmentNumber                                          ="00620004",
        SegmentLabel                                           ="00620005",
        SegmentDescription                                     ="00620006",
        SegmentAlgorithmType                                   ="00620008",
        SegmentAlgorithmName                                   ="00620009",
        SegmentIdentificationSequence                          ="0062000A",
        ReferencedSegmentNumber                                ="0062000B",
        RecommendedDisplayGrayscaleValue                       ="0062000C",
        RecommendedDisplayCIELabValue                          ="0062000D",
        MaximumFractionalValue                                 ="0062000E",
        SegmentedPropertyTypeCodeSequence                      ="0062000F",
        SegmentationFractionalType                             ="00620010",
        SegmentedPropertyTypeModifierCodeSequence              ="00620011",
        UsedSegmentsSequence                                   ="00620012",
    
    // *****************************************************************************************************************************
    // *** 0064
    // *****************************************************************************************************************************
        DeformableRegistrationSequence                         ="00640002",
        SourceFrameOfReferenceUID                              ="00640003",
        DeformableRegistrationGridSequence                     ="00640005",
        GridDimensions                                         ="00640007",
        GridResolution                                         ="00640008",
        VectorGridData                                         ="00640009",
        PreDeformationMatrixRegistrationSequence               ="0064000F",
        PostDeformationMatrixRegistrationSequence              ="00640010",
    
    // *****************************************************************************************************************************
    // *** 0066
    // *****************************************************************************************************************************
        NumberOfSurfaces                                       ="00660001",
        SurfaceSequence                                        ="00660002",
        SurfaceNumber                                          ="00660003",
        SurfaceComments                                        ="00660004",
        SurfaceProcessing                                      ="00660009",
        SurfaceProcessingRatio                                 ="0066000A",
        SurfaceProcessingDescription                           ="0066000B",
        RecommendedPresentationOpacity                         ="0066000C",
        RecommendedPresentationType                            ="0066000D",
        FiniteVolume                                           ="0066000E",
        Manifold                                               ="00660010",
        SurfacePointsSequence                                  ="00660011",
        SurfacePointsNormalsSequence                           ="00660012",
        SurfaceMeshPrimitivesSequence                          ="00660013",
        NumberOfSurfacePoints                                  ="00660015",
        PointCoordinatesData                                   ="00660016",
        PointPositionAccuracy                                  ="00660017",
        MeanPointDistance                                      ="00660018",
        MaximumPointDistance                                   ="00660019",
        PointsBoundingBoxCoordinates                           ="0066001A",
        AxisOfRotation                                         ="0066001B",
        CenterOfRotation                                       ="0066001C",
        NumberOfVectors                                        ="0066001E",
        VectorDimensionality                                   ="0066001F",
        VectorAccuracy                                         ="00660020",
        VectorCoordinateData                                   ="00660021",
        TrianglePointIndexList                                 ="00660023",
        EdgePointIndexList                                     ="00660024",
        VertexPointIndexList                                   ="00660025",
        TriangleStripSequence                                  ="00660026",
        TriangleFanSequence                                    ="00660027",
        LineSequence                                           ="00660028",
        PrimitivePointIndexList                                ="00660029",
        SurfaceCount                                           ="0066002A",
        ReferencedSurfaceSequence                              ="0066002B",
        ReferencedSurfaceNumber                                ="0066002C",
        SegmentSurfaceGenerationAlgorithmIdentificationSequence ="0066002D",
        SegmentSurfaceSourceInstanceSequence                   ="0066002E",
        AlgorithmFamilyCodeSequence                            ="0066002F",
        AlgorithmNameCodeSequence                              ="00660030",
        AlgorithmVersion                                       ="00660031",
        AlgorithmParameters                                    ="00660032",
        FacetSequence                                          ="00660034",
        SurfaceProcessingAlgorithmIdentificationSequence       ="00660035",
        AlgorithmName                                          ="00660036",
        RecommendedPointRadius                                 ="00660037",
        RecommendedLineThickness                               ="00660038",
        LongPrimitivePointIndexList                            ="00660040",
        LongTrianglePointIndexList                             ="00660041",
        LongEdgePointIndexList                                 ="00660042",
        LongVertexPointIndexList                               ="00660043",
    
    // *****************************************************************************************************************************
    // *** 0068
    // *****************************************************************************************************************************
        ImplantSize                                            ="00686210",
        ImplantTemplateVersion                                 ="00686221",
        ReplacedImplantTemplateSequence                        ="00686222",
        ImplantType                                            ="00686223",
        DerivationImplantTemplateSequence                      ="00686224",
        OriginalImplantTemplateSequence                        ="00686225",
        EffectiveDateTime                                      ="00686226",
        ImplantTargetAnatomySequence                           ="00686230",
        InformationFromManufacturerSequence                    ="00686260",
        NotificationFromManufacturerSequence                   ="00686265",
        InformationIssueDateTime                               ="00686270",
        InformationSummary                                     ="00686280",
        ImplantRegulatoryDisapprovalCodeSequence               ="006862A0",
        OverallTemplateSpatialTolerance                        ="006862A5",
        HPGLDocumentSequence                                   ="006862C0",
        HPGLDocumentID                                         ="006862D0",
        HPGLDocumentLabel                                      ="006862D5",
        ViewOrientationCodeSequence                            ="006862E0",
        ViewOrientationModifier                                ="006862F0",
        HPGLDocumentScaling                                    ="006862F2",
        HPGLDocument                                           ="00686300",
        HPGLContourPenNumber                                   ="00686310",
        HPGLPenSequence                                        ="00686320",
        HPGLPenNumber                                          ="00686330",
        HPGLPenLabel                                           ="00686340",
        HPGLPenDescription                                     ="00686345",
        RecommendedRotationPoint                               ="00686346",
        BoundingRectangle                                      ="00686347",
        ImplantTemplate3DModelSurfaceNumber                    ="00686350",
        SurfaceModelDescriptionSequence                        ="00686360",
        SurfaceModelLabel                                      ="00686380",
        SurfaceModelScalingFactor                              ="00686390",
        MaterialsCodeSequence                                  ="006863A0",
        CoatingMaterialsCodeSequence                           ="006863A4",
        ImplantTypeCodeSequence                                ="006863A8",
        FixationMethodCodeSequence                             ="006863AC",
        MatingFeatureSetsSequence                              ="006863B0",
        MatingFeatureSetID                                     ="006863C0",
        MatingFeatureSetLabel                                  ="006863D0",
        MatingFeatureSequence                                  ="006863E0",
        MatingFeatureID                                        ="006863F0",
        MatingFeatureDegreeOfFreedomSequence                   ="00686400",
        DegreeOfFreedomID                                      ="00686410",
        DegreeOfFreedomType                                    ="00686420",
        TwoDMatingFeatureCoordinatesSequence                   ="00686430",
        ReferencedHPGLDocumentID                               ="00686440",
        TwoDMatingPoint                                        ="00686450",
        TwoDMatingAxes                                         ="00686460",
        TwoDDegreeOfFreedomSequence                            ="00686470",
        ThreeDDegreeOfFreedomAxis                              ="00686490",
        RangeOfFreedom                                         ="006864A0",
        ThreeDMatingPoint                                      ="006864C0",
        ThreeDMatingAxes                                       ="006864D0",
        TwoDDegreeOfFreedomAxis                                ="006864F0",
        PlanningLandmarkPointSequence                          ="00686500",
        PlanningLandmarkLineSequence                           ="00686510",
        PlanningLandmarkPlaneSequence                          ="00686520",
        PlanningLandmarkID                                     ="00686530",
        PlanningLandmarkDescription                            ="00686540",
        PlanningLandmarkIdentificationCodeSequence             ="00686545",
        TwoDPointCoordinatesSequence                           ="00686550",
        TwoDPointCoordinates                                   ="00686560",
        ThreeDPointCoordinates                                 ="00686590",
        TwoDLineCoordinatesSequence                            ="006865A0",
        TwoDLineCoordinates                                    ="006865B0",
        ThreeDLineCoordinates                                  ="006865D0",
        TwoDPlaneCoordinatesSequence                           ="006865E0",
        TwoDPlaneIntersection                                  ="006865F0",
        ThreeDPlaneOrigin                                      ="00686610",
        ThreeDPlaneNormal                                      ="00686620",
    
    // *****************************************************************************************************************************
    // *** 0070
    // *****************************************************************************************************************************
        GraphicAnnotationSequence                              ="00700001",
        GraphicLayer                                           ="00700002",
        BoundingBoxAnnotationUnits                             ="00700003",
        AnchorPointAnnotationUnits                             ="00700004",
        GraphicAnnotationUnits                                 ="00700005",
        UnformattedTextValue                                   ="00700006",
        TextObjectSequence                                     ="00700008",
        GraphicObjectSequence                                  ="00700009",
        BoundingBoxTopLeftHandCorner                           ="00700010",
        BoundingBoxBottomRightHandCorner                       ="00700011",
        BoundingBoxTextHorizontalJustification                 ="00700012",
        AnchorPoint                                            ="00700014",
        AnchorPointVisibility                                  ="00700015",
        GraphicDimensions                                      ="00700020",
        NumberOfGraphicPoints                                  ="00700021",
        GraphicData                                            ="00700022",
        GraphicType                                            ="00700023",
        GraphicFilled                                          ="00700024",
        ImageRotationRetired                                   ="00700040",   // Retired
        ImageHorizontalFlip                                    ="00700041",
        ImageRotation                                          ="00700042",
        DisplayedAreaTopLeftHandCornerTrial                    ="00700050",   // Retired
        DisplayedAreaBottomRightHandCornerTrial                ="00700051",   // Retired
        DisplayedAreaTopLeftHandCorner                         ="00700052",
        DisplayedAreaBottomRightHandCorner                     ="00700053",
        DisplayedAreaSelectionSequence                         ="0070005A",
        GraphicLayerSequence                                   ="00700060",
        GraphicLayerOrder                                      ="00700062",
        GraphicLayerRecommendedDisplayGrayscaleValue           ="00700066",
        GraphicLayerRecommendedDisplayRGBValue                 ="00700067",   // Retired
        GraphicLayerDescription                                ="00700068",
        ContentLabel                                           ="00700080",
        ContentDescription                                     ="00700081",
        PresentationCreationDate                               ="00700082",
        PresentationCreationTime                               ="00700083",
        ContentCreatorName                                     ="00700084",
        ContentCreatorIdentificationCodeSequence               ="00700086",
        AlternateContentDescriptionSequence                    ="00700087",
        PresentationSizeMode                                   ="00700100",
        PresentationPixelSpacing                               ="00700101",
        PresentationPixelAspectRatio                           ="00700102",
        PresentationPixelMagnificationRatio                    ="00700103",
        GraphicGroupLabel                                      ="00700207",
        GraphicGroupDescription                                ="00700208",
        CompoundGraphicSequence                                ="00700209",
        CompoundGraphicInstanceID                              ="00700226",
        FontName                                               ="00700227",
        FontNameType                                           ="00700228",
        CSSFontName                                            ="00700229",
        RotationAngle                                          ="00700230",
        TextStyleSequence                                      ="00700231",
        LineStyleSequence                                      ="00700232",
        FillStyleSequence                                      ="00700233",
        GraphicGroupSequence                                   ="00700234",
        TextColorCIELabValue                                   ="00700241",
        HorizontalAlignment                                    ="00700242",
        VerticalAlignment                                      ="00700243",
        ShadowStyle                                            ="00700244",
        ShadowOffsetX                                          ="00700245",
        ShadowOffsetY                                          ="00700246",
        ShadowColorCIELabValue                                 ="00700247",
        Underlined                                             ="00700248",
        Bold                                                   ="00700249",
        Italic                                                 ="00700250",
        PatternOnColorCIELabValue                              ="00700251",
        PatternOffColorCIELabValue                             ="00700252",
        LineThickness                                          ="00700253",
        LineDashingStyle                                       ="00700254",
        LinePattern                                            ="00700255",
        FillPattern                                            ="00700256",
        FillMode                                               ="00700257",
        ShadowOpacity                                          ="00700258",
        GapLength                                              ="00700261",
        DiameterOfVisibility                                   ="00700262",
        RotationPoint                                          ="00700273",
        TickAlignment                                          ="00700274",
        ShowTickLabel                                          ="00700278",
        TickLabelAlignment                                     ="00700279",
        CompoundGraphicUnits                                   ="00700282",
        PatternOnOpacity                                       ="00700284",
        PatternOffOpacity                                      ="00700285",
        MajorTicksSequence                                     ="00700287",
        TickPosition                                           ="00700288",
        TickLabel                                              ="00700289",
        CompoundGraphicType                                    ="00700294",
        GraphicGroupID                                         ="00700295",
        ShapeType                                              ="00700306",
        RegistrationSequence                                   ="00700308",
        MatrixRegistrationSequence                             ="00700309",
        MatrixSequence                                         ="0070030A",
        FrameOfReferenceTransformationMatrixType               ="0070030C",
        RegistrationTypeCodeSequence                           ="0070030D",
        FiducialDescription                                    ="0070030F",
        FiducialIdentifier                                     ="00700310",
        FiducialIdentifierCodeSequence                         ="00700311",
        ContourUncertaintyRadius                               ="00700312",
        UsedFiducialsSequence                                  ="00700314",
        GraphicCoordinatesDataSequence                         ="00700318",
        FiducialUID                                            ="0070031A",
        FiducialSetSequence                                    ="0070031C",
        FiducialSequence                                       ="0070031E",
        GraphicLayerRecommendedDisplayCIELabValue              ="00700401",
        BlendingSequence                                       ="00700402",
        RelativeOpacity                                        ="00700403",
        ReferencedSpatialRegistrationSequence                  ="00700404",
        BlendingPosition                                       ="00700405",
    
    // *****************************************************************************************************************************
    // *** 0072
    // *****************************************************************************************************************************
        HangingProtocolName                                    ="00720002",
        HangingProtocolDescription                             ="00720004",
        HangingProtocolLevel                                   ="00720006",
        HangingProtocolCreator                                 ="00720008",
        HangingProtocolCreationDateTime                        ="0072000A",
        HangingProtocolDefinitionSequence                      ="0072000C",
        HangingProtocolUserIdentificationCodeSequence          ="0072000E",
        HangingProtocolUserGroupName                           ="00720010",
        SourceHangingProtocolSequence                          ="00720012",
        NumberOfPriorsReferenced                               ="00720014",
        ImageSetsSequence                                      ="00720020",
        ImageSetSelectorSequence                               ="00720022",
        ImageSetSelectorUsageFlag                              ="00720024",
        SelectorAttribute                                      ="00720026",
        SelectorValueNumber                                    ="00720028",
        TimeBasedImageSetsSequence                             ="00720030",
        ImageSetNumber                                         ="00720032",
        ImageSetSelectorCategory                               ="00720034",
        RelativeTime                                           ="00720038",
        RelativeTimeUnits                                      ="0072003A",
        AbstractPriorValue                                     ="0072003C",
        AbstractPriorCodeSequence                              ="0072003E",
        ImageSetLabel                                          ="00720040",
        SelectorAttributeVR                                    ="00720050",
        SelectorSequencePointer                                ="00720052",
        SelectorSequencePointerPrivateCreator                  ="00720054",
        SelectorAttributePrivateCreator                        ="00720056",
        SelectorATValue                                        ="00720060",
        SelectorCSValue                                        ="00720062",
        SelectorISValue                                        ="00720064",
        SelectorLOValue                                        ="00720066",
        SelectorLTValue                                        ="00720068",
        SelectorPNValue                                        ="0072006A",
        SelectorSHValue                                        ="0072006C",
        SelectorSTValue                                        ="0072006E",
        SelectorUTValue                                        ="00720070",
        SelectorDSValue                                        ="00720072",
        SelectorFDValue                                        ="00720074",
        SelectorFLValue                                        ="00720076",
        SelectorULValue                                        ="00720078",
        SelectorUSValue                                        ="0072007A",
        SelectorSLValue                                        ="0072007C",
        SelectorSSValue                                        ="0072007E",
        SelectorUIValue                                        ="0072007F",
        SelectorCodeSequenceValue                              ="00720080",
        NumberOfScreens                                        ="00720100",
        NominalScreenDefinitionSequence                        ="00720102",
        NumberOfVerticalPixels                                 ="00720104",
        NumberOfHorizontalPixels                               ="00720106",
        DisplayEnvironmentSpatialPosition                      ="00720108",
        ScreenMinimumGrayscaleBitDepth                         ="0072010A",
        ScreenMinimumColorBitDepth                             ="0072010C",
        ApplicationMaximumRepaintTime                          ="0072010E",
        DisplaySetsSequence                                    ="00720200",
        DisplaySetNumber                                       ="00720202",
        DisplaySetLabel                                        ="00720203",
        DisplaySetPresentationGroup                            ="00720204",
        DisplaySetPresentationGroupDescription                 ="00720206",
        PartialDataDisplayHandling                             ="00720208",
        SynchronizedScrollingSequence                          ="00720210",
        DisplaySetScrollingGroup                               ="00720212",
        NavigationIndicatorSequence                            ="00720214",
        NavigationDisplaySet                                   ="00720216",
        ReferenceDisplaySets                                   ="00720218",
        ImageBoxesSequence                                     ="00720300",
        ImageBoxNumber                                         ="00720302",
        ImageBoxLayoutType                                     ="00720304",
        ImageBoxTileHorizontalDimension                        ="00720306",
        ImageBoxTileVerticalDimension                          ="00720308",
        ImageBoxScrollDirection                                ="00720310",
        ImageBoxSmallScrollType                                ="00720312",
        ImageBoxSmallScrollAmount                              ="00720314",
        ImageBoxLargeScrollType                                ="00720316",
        ImageBoxLargeScrollAmount                              ="00720318",
        ImageBoxOverlapPriority                                ="00720320",
        CineRelativeToRealTime                                 ="00720330",
        FilterOperationsSequence                               ="00720400",
        FilterByCategory                                       ="00720402",
        FilterByAttributePresence                              ="00720404",
        FilterByOperator                                       ="00720406",
        StructuredDisplayBackgroundCIELabValue                 ="00720420",
        EmptyImageBoxCIELabValue                               ="00720421",
        StructuredDisplayImageBoxSequence                      ="00720422",
        StructuredDisplayTextBoxSequence                       ="00720424",
        ReferencedFirstFrameSequence                           ="00720427",
        ImageBoxSynchronizationSequence                        ="00720430",
        SynchronizedImageBoxList                               ="00720432",
        TypeOfSynchronization                                  ="00720434",
        BlendingOperationType                                  ="00720500",
        ReformattingOperationType                              ="00720510",
        ReformattingThickness                                  ="00720512",
        ReformattingInterval                                   ="00720514",
        ReformattingOperationInitialViewDirection              ="00720516",
        ThreeDRenderingType                                    ="00720520",
        SortingOperationsSequence                              ="00720600",
        SortByCategory                                         ="00720602",
        SortingDirection                                       ="00720604",
        DisplaySetPatientOrientation                           ="00720700",
        VOIType                                                ="00720702",
        PseudoColorType                                        ="00720704",
        PseudoColorPaletteInstanceReferenceSequence            ="00720705",
        ShowGrayscaleInverted                                  ="00720706",
        ShowImageTrueSizeFlag                                  ="00720710",
        ShowGraphicAnnotationFlag                              ="00720712",
        ShowPatientDemographicsFlag                            ="00720714",
        ShowAcquisitionTechniquesFlag                          ="00720716",
        DisplaySetHorizontalJustification                      ="00720717",
        DisplaySetVerticalJustification                        ="00720718",
    
    // *****************************************************************************************************************************
    // *** 0074
    // *****************************************************************************************************************************
        ContinuationStartMeterset                              ="00740120",
        ContinuationEndMeterset                                ="00740121",
        ProcedureStepState                                     ="00741000",
        ProcedureStepProgressInformationSequence               ="00741002",
        ProcedureStepProgress                                  ="00741004",
        ProcedureStepProgressDescription                       ="00741006",
        ProcedureStepCommunicationsURISequence                 ="00741008",
        ContactURI                                             ="0074100A",
        ContactDisplayName                                     ="0074100C",
        ProcedureStepDiscontinuationReasonCodeSequence         ="0074100E",
        BeamTaskSequence                                       ="00741020",
        BeamTaskType                                           ="00741022",
        BeamOrderIndexTrial                                    ="00741024",   // Retired
        AutosequenceFlag                                       ="00741025",
        TableTopVerticalAdjustedPosition                       ="00741026",
        TableTopLongitudinalAdjustedPosition                   ="00741027",
        TableTopLateralAdjustedPosition                        ="00741028",
        PatientSupportAdjustedAngle                            ="0074102A",
        TableTopEccentricAdjustedAngle                         ="0074102B",
        TableTopPitchAdjustedAngle                             ="0074102C",
        TableTopRollAdjustedAngle                              ="0074102D",
        DeliveryVerificationImageSequence                      ="00741030",
        VerificationImageTiming                                ="00741032",
        DoubleExposureFlag                                     ="00741034",
        DoubleExposureOrdering                                 ="00741036",
        DoubleExposureMetersetTrial                            ="00741038",   // Retired
        DoubleExposureFieldDeltaTrial                          ="0074103A",   // Retired
        RelatedReferenceRTImageSequence                        ="00741040",
        GeneralMachineVerificationSequence                     ="00741042",
        ConventionalMachineVerificationSequence                ="00741044",
        IonMachineVerificationSequence                         ="00741046",
        FailedAttributesSequence                               ="00741048",
        OverriddenAttributesSequence                           ="0074104A",
        ConventionalControlPointVerificationSequence           ="0074104C",
        IonControlPointVerificationSequence                    ="0074104E",
        AttributeOccurrenceSequence                            ="00741050",
        AttributeOccurrencePointer                             ="00741052",
        AttributeItemSelector                                  ="00741054",
        AttributeOccurrencePrivateCreator                      ="00741056",
        SelectorSequencePointerItems                           ="00741057",
        ScheduledProcedureStepPriority                         ="00741200",
        WorklistLabel                                          ="00741202",
        ProcedureStepLabel                                     ="00741204",
        ScheduledProcessingParametersSequence                  ="00741210",
        PerformedProcessingParametersSequence                  ="00741212",
        UnifiedProcedureStepPerformedProcedureSequence         ="00741216",
        RelatedProcedureStepSequence                           ="00741220",   // Retired
        ProcedureStepRelationshipType                          ="00741222",   // Retired
        ReplacedProcedureStepSequence                          ="00741224",
        DeletionLock                                           ="00741230",
        ReceivingAE                                            ="00741234",
        RequestingAE                                           ="00741236",
        ReasonForCancellation                                  ="00741238",
        SCPStatus                                              ="00741242",
        SubscriptionListStatus                                 ="00741244",
        UnifiedProcedureStepListStatus                         ="00741246",
        BeamOrderIndex                                         ="00741324",
        DoubleExposureMeterset                                 ="00741338",
        DoubleExposureFieldDelta                               ="0074133A",
    
    // *****************************************************************************************************************************
    // *** 0076
    // *****************************************************************************************************************************
        ImplantAssemblyTemplateName                            ="00760001",
        ImplantAssemblyTemplateIssuer                          ="00760003",
        ImplantAssemblyTemplateVersion                         ="00760006",
        ReplacedImplantAssemblyTemplateSequence                ="00760008",
        ImplantAssemblyTemplateType                            ="0076000A",
        OriginalImplantAssemblyTemplateSequence                ="0076000C",
        DerivationImplantAssemblyTemplateSequence              ="0076000E",
        ImplantAssemblyTemplateTargetAnatomySequence           ="00760010",
        ProcedureTypeCodeSequence                              ="00760020",
        SurgicalTechnique                                      ="00760030",
        ComponentTypesSequence                                 ="00760032",
        ComponentTypeCodeSequence                              ="00760034",
        ExclusiveComponentType                                 ="00760036",
        MandatoryComponentType                                 ="00760038",
        ComponentSequence                                      ="00760040",
        ComponentID                                            ="00760055",
        ComponentAssemblySequence                              ="00760060",
        Component1ReferencedID                                 ="00760070",
        Component1ReferencedMatingFeatureSetID                 ="00760080",
        Component1ReferencedMatingFeatureID                    ="00760090",
        Component2ReferencedID                                 ="007600A0",
        Component2ReferencedMatingFeatureSetID                 ="007600B0",
        Component2ReferencedMatingFeatureID                    ="007600C0",
    
    // *****************************************************************************************************************************
    // *** 0078
    // *****************************************************************************************************************************
        ImplantTemplateGroupName                               ="00780001",
        ImplantTemplateGroupDescription                        ="00780010",
        ImplantTemplateGroupIssuer                             ="00780020",
        ImplantTemplateGroupVersion                            ="00780024",
        ReplacedImplantTemplateGroupSequence                   ="00780026",
        ImplantTemplateGroupTargetAnatomySequence              ="00780028",
        ImplantTemplateGroupMembersSequence                    ="0078002A",
        ImplantTemplateGroupMemberID                           ="0078002E",
        ThreeDImplantTemplateGroupMemberMatchingPoint          ="00780050",
        ThreeDImplantTemplateGroupMemberMatchingAxes           ="00780060",
        ImplantTemplateGroupMemberMatching2DCoordinatesSequence ="00780070",
        TwoDImplantTemplateGroupMemberMatchingPoint            ="00780090",
        TwoDImplantTemplateGroupMemberMatchingAxes             ="007800A0",
        ImplantTemplateGroupVariationDimensionSequence         ="007800B0",
        ImplantTemplateGroupVariationDimensionName             ="007800B2",
        ImplantTemplateGroupVariationDimensionRankSequence     ="007800B4",
        ReferencedImplantTemplateGroupMemberID                 ="007800B6",
        ImplantTemplateGroupVariationDimensionRank             ="007800B8",
    
    // *****************************************************************************************************************************
    // *** 0080
    // *****************************************************************************************************************************
        SurfaceScanAcquisitionTypeCodeSequence                 ="00800001",
        SurfaceScanModeCodeSequence                            ="00800002",
        RegistrationMethodCodeSequence                         ="00800003",
        ShotDurationTime                                       ="00800004",
        ShotOffsetTime                                         ="00800005",
        SurfacePointPresentationValueData                      ="00800006",
        SurfacePointColorCIELabValueData                       ="00800007",
        UVMappingSequence                                      ="00800008",
        TextureLabel                                           ="00800009",
        UValueData                                             ="00800010",
        VValueData                                             ="00800011",
        ReferencedTextureSequence                              ="00800012",
        ReferencedSurfaceDataSequence                          ="00800013",
    
    // *****************************************************************************************************************************
    // *** 0088
    // *****************************************************************************************************************************
        StorageMediaFileSetID                                  ="00880130",
        StorageMediaFileSetUID                                 ="00880140",
        IconImageSequence                                      ="00880200",
        TopicTitle                                             ="00880904",   // Retired
        TopicSubject                                           ="00880906",   // Retired
        TopicAuthor                                            ="00880910",   // Retired
        TopicKeywords                                          ="00880912",   // Retired
    
    // *****************************************************************************************************************************
    // *** 0100
    // *****************************************************************************************************************************
        SOPInstanceStatus                                      ="01000410",
        SOPAuthorizationDateTime                               ="01000420",
        SOPAuthorizationComment                                ="01000424",
        AuthorizationEquipmentCertificationNumber              ="01000426",
    
    // *****************************************************************************************************************************
    // *** 0400
    // *****************************************************************************************************************************
        MACIDNumber                                            ="04000005",
        MACCalculationTransferSyntaxUID                        ="04000010",
        MACAlgorithm                                           ="04000015",
        DataElementsSigned                                     ="04000020",
        DigitalSignatureUID                                    ="04000100",
        DigitalSignatureDateTime                               ="04000105",
        CertificateType                                        ="04000110",
        CertificateOfSigner                                    ="04000115",
        Signature                                              ="04000120",
        CertifiedTimestampType                                 ="04000305",
        CertifiedTimestamp                                     ="04000310",
        DigitalSignaturePurposeCodeSequence                    ="04000401",
        ReferencedDigitalSignatureSequence                     ="04000402",
        ReferencedSOPInstanceMACSequence                       ="04000403",
        MAC                                                    ="04000404",
        EncryptedAttributesSequence                            ="04000500",
        EncryptedContentTransferSyntaxUID                      ="04000510",
        EncryptedContent                                       ="04000520",
        ModifiedAttributesSequence                             ="04000550",
        OriginalAttributesSequence                             ="04000561",
        AttributeModificationDateTime                          ="04000562",
        ModifyingSystem                                        ="04000563",
        SourceOfPreviousValues                                 ="04000564",
        ReasonForTheAttributeModification                      ="04000565",
    
    // *****************************************************************************************************************************
    // *** 1000
    // *****************************************************************************************************************************
        EscapeTriplet                                          ="10000000",   // Retired
        RunLengthTriplet                                       ="10000001",   // Retired
        HuffmanTableSize                                       ="10000002",   // Retired
        HuffmanTableTriplet                                    ="10000003",   // Retired
        ShiftTableSize                                         ="10000004",   // Retired
        ShiftTableTriplet                                      ="10000005",   // Retired
    
    // *****************************************************************************************************************************
    // *** 1010
    // *****************************************************************************************************************************
        ZonalMap                                               ="10100000",   // Retired
    
    // *****************************************************************************************************************************
    // *** 2000
    // *****************************************************************************************************************************
        NumberOfCopies                                         ="20000010",
        PrinterConfigurationSequence                           ="2000001E",
        PrintPriority                                          ="20000020",
        MediumType                                             ="20000030",
        FilmDestination                                        ="20000040",
        FilmSessionLabel                                       ="20000050",
        MemoryAllocation                                       ="20000060",
        MaximumMemoryAllocation                                ="20000061",
        ColorImagePrintingFlag                                 ="20000062",   // Retired
        CollationFlag                                          ="20000063",   // Retired
        AnnotationFlag                                         ="20000065",   // Retired
        ImageOverlayFlag                                       ="20000067",   // Retired
        PresentationLUTFlag                                    ="20000069",   // Retired
        ImageBoxPresentationLUTFlag                            ="2000006A",   // Retired
        MemoryBitDepth                                         ="200000A0",
        PrintingBitDepth                                       ="200000A1",
        MediaInstalledSequence                                 ="200000A2",
        OtherMediaAvailableSequence                            ="200000A4",
        SupportedImageDisplayFormatsSequence                   ="200000A8",
        ReferencedFilmBoxSequence                              ="20000500",
        ReferencedStoredPrintSequence                          ="20000510",   // Retired
    
    // *****************************************************************************************************************************
    // *** 2010
    // *****************************************************************************************************************************
        ImageDisplayFormat                                     ="20100010",
        AnnotationDisplayFormatID                              ="20100030",
        FilmOrientation                                        ="20100040",
        FilmSizeID                                             ="20100050",
        PrinterResolutionID                                    ="20100052",
        DefaultPrinterResolutionID                             ="20100054",
        MagnificationType                                      ="20100060",
        SmoothingType                                          ="20100080",
        DefaultMagnificationType                               ="201000A6",
        OtherMagnificationTypesAvailable                       ="201000A7",
        DefaultSmoothingType                                   ="201000A8",
        OtherSmoothingTypesAvailable                           ="201000A9",
        BorderDensity                                          ="20100100",
        EmptyImageDensity                                      ="20100110",
        MinDensity                                             ="20100120",
        MaxDensity                                             ="20100130",
        Trim                                                   ="20100140",
        ConfigurationInformation                               ="20100150",
        ConfigurationInformationDescription                    ="20100152",
        MaximumCollatedFilms                                   ="20100154",
        Illumination                                           ="2010015E",
        ReflectedAmbientLight                                  ="20100160",
        PrinterPixelSpacing                                    ="20100376",
        ReferencedFilmSessionSequence                          ="20100500",
        ReferencedImageBoxSequence                             ="20100510",
        ReferencedBasicAnnotationBoxSequence                   ="20100520",
    
    // *****************************************************************************************************************************
    // *** 2020
    // *****************************************************************************************************************************
        ImageBoxPosition                                       ="20200010",
        Polarity                                               ="20200020",
        RequestedImageSize                                     ="20200030",
        RequestedDecimateCropBehavior                          ="20200040",
        RequestedResolutionID                                  ="20200050",
        RequestedImageSizeFlag                                 ="202000A0",
        DecimateCropResult                                     ="202000A2",
        BasicGrayscaleImageSequence                            ="20200110",
        BasicColorImageSequence                                ="20200111",
        ReferencedImageOverlayBoxSequence                      ="20200130",   // Retired
        ReferencedVOILUTBoxSequence                            ="20200140",   // Retired
    
    // *****************************************************************************************************************************
    // *** 2030
    // *****************************************************************************************************************************
        AnnotationPosition                                     ="20300010",
        TextString                                             ="20300020",
    
    // *****************************************************************************************************************************
    // *** 2040
    // *****************************************************************************************************************************
        ReferencedOverlayPlaneSequence                         ="20400010",   // Retired
        ReferencedOverlayPlaneGroups                           ="20400011",   // Retired
        OverlayPixelDataSequence                               ="20400020",   // Retired
        OverlayMagnificationType                               ="20400060",   // Retired
        OverlaySmoothingType                                   ="20400070",   // Retired
        OverlayOrImageMagnification                            ="20400072",   // Retired
        MagnifyToNumberOfColumns                               ="20400074",   // Retired
        OverlayForegroundDensity                               ="20400080",   // Retired
        OverlayBackgroundDensity                               ="20400082",   // Retired
        OverlayMode                                            ="20400090",   // Retired
        ThresholdDensity                                       ="20400100",   // Retired
        ReferencedImageBoxSequenceRetired                      ="20400500",   // Retired
    
    // *****************************************************************************************************************************
    // *** 2050
    // *****************************************************************************************************************************
        PresentationLUTSequence                                ="20500010",
        PresentationLUTShape                                   ="20500020",
        ReferencedPresentationLUTSequence                      ="20500500",
    
    // *****************************************************************************************************************************
    // *** 2100
    // *****************************************************************************************************************************
        PrintJobID                                             ="21000010",   // Retired
        ExecutionStatus                                        ="21000020",
        ExecutionStatusInfo                                    ="21000030",
        CreationDate                                           ="21000040",
        CreationTime                                           ="21000050",
        Originator                                             ="21000070",
        DestinationAE                                          ="21000140",   // Retired
        OwnerID                                                ="21000160",
        NumberOfFilms                                          ="21000170",
        ReferencedPrintJobSequencePullStoredPrint              ="21000500",   // Retired
    
    // *****************************************************************************************************************************
    // *** 2110
    // *****************************************************************************************************************************
        PrinterStatus                                          ="21100010",
        PrinterStatusInfo                                      ="21100020",
        PrinterName                                            ="21100030",
        PrintQueueID                                           ="21100099",   // Retired
    
    // *****************************************************************************************************************************
    // *** 2120
    // *****************************************************************************************************************************
        QueueStatus                                            ="21200010",   // Retired
        PrintJobDescriptionSequence                            ="21200050",   // Retired
        ReferencedPrintJobSequence                             ="21200070",   // Retired
    
    // *****************************************************************************************************************************
    // *** 2130
    // *****************************************************************************************************************************
        PrintManagementCapabilitiesSequence                    ="21300010",   // Retired
        PrinterCharacteristicsSequence                         ="21300015",   // Retired
        FilmBoxContentSequence                                 ="21300030",   // Retired
        ImageBoxContentSequence                                ="21300040",   // Retired
        AnnotationContentSequence                              ="21300050",   // Retired
        ImageOverlayBoxContentSequence                         ="21300060",   // Retired
        PresentationLUTContentSequence                         ="21300080",   // Retired
        ProposedStudySequence                                  ="213000A0",   // Retired
        OriginalImageSequence                                  ="213000C0",   // Retired
    
    // *****************************************************************************************************************************
    // *** 2200
    // *****************************************************************************************************************************
        LabelUsingInformationExtractedFromInstances            ="22000001",
        LabelText                                              ="22000002",
        LabelStyleSelection                                    ="22000003",
        MediaDisposition                                       ="22000004",
        BarcodeValue                                           ="22000005",
        BarcodeSymbology                                       ="22000006",
        AllowMediaSplitting                                    ="22000007",
        IncludeNonDICOMObjects                                 ="22000008",
        IncludeDisplayApplication                              ="22000009",
        PreserveCompositeInstancesAfterMediaCreation           ="2200000A",
        TotalNumberOfPiecesOfMediaCreated                      ="2200000B",
        RequestedMediaApplicationProfile                       ="2200000C",
        ReferencedStorageMediaSequence                         ="2200000D",
        FailureAttributes                                      ="2200000E",
        AllowLossyCompression                                  ="2200000F",
        RequestPriority                                        ="22000020",
    
    // *****************************************************************************************************************************
    // *** 3002
    // *****************************************************************************************************************************
        RTImageLabel                                           ="30020002",
        RTImageName                                            ="30020003",
        RTImageDescription                                     ="30020004",
        ReportedValuesOrigin                                   ="3002000A",
        RTImagePlane                                           ="3002000C",
        XRayImageReceptorTranslation                           ="3002000D",
        XRayImageReceptorAngle                                 ="3002000E",
        RTImageOrientation                                     ="30020010",
        ImagePlanePixelSpacing                                 ="30020011",
        RTImagePosition                                        ="30020012",
        RadiationMachineName                                   ="30020020",
        RadiationMachineSAD                                    ="30020022",
        RadiationMachineSSD                                    ="30020024",
        RTImageSID                                             ="30020026",
        SourceToReferenceObjectDistance                        ="30020028",
        FractionNumber                                         ="30020029",
        ExposureSequence                                       ="30020030",
        MetersetExposure                                       ="30020032",
        DiaphragmPosition                                      ="30020034",
        FluenceMapSequence                                     ="30020040",
        FluenceDataSource                                      ="30020041",
        FluenceDataScale                                       ="30020042",
        PrimaryFluenceModeSequence                             ="30020050",
        FluenceMode                                            ="30020051",
        FluenceModeID                                          ="30020052",
    
    // *****************************************************************************************************************************
    // *** 3004
    // *****************************************************************************************************************************
        DVHType                                                ="30040001",
        DoseUnits                                              ="30040002",
        DoseType                                               ="30040004",
        SpatialTransformOfDose                                 ="30040005",
        DoseComment                                            ="30040006",
        NormalizationPoint                                     ="30040008",
        DoseSummationType                                      ="3004000A",
        GridFrameOffsetVector                                  ="3004000C",
        DoseGridScaling                                        ="3004000E",
        RTDoseROISequence                                      ="30040010",
        DoseValue                                              ="30040012",
        TissueHeterogeneityCorrection                          ="30040014",
        DVHNormalizationPoint                                  ="30040040",
        DVHNormalizationDoseValue                              ="30040042",
        DVHSequence                                            ="30040050",
        DVHDoseScaling                                         ="30040052",
        DVHVolumeUnits                                         ="30040054",
        DVHNumberOfBins                                        ="30040056",
        DVHData                                                ="30040058",
        DVHReferencedROISequence                               ="30040060",
        DVHROIContributionType                                 ="30040062",
        DVHMinimumDose                                         ="30040070",
        DVHMaximumDose                                         ="30040072",
        DVHMeanDose                                            ="30040074",
    
    // *****************************************************************************************************************************
    // *** 3006
    // *****************************************************************************************************************************
        StructureSetLabel                                      ="30060002",
        StructureSetName                                       ="30060004",
        StructureSetDescription                                ="30060006",
        StructureSetDate                                       ="30060008",
        StructureSetTime                                       ="30060009",
        ReferencedFrameOfReferenceSequence                     ="30060010",
        RTReferencedStudySequence                              ="30060012",
        RTReferencedSeriesSequence                             ="30060014",
        ContourImageSequence                                   ="30060016",
        PredecessorStructureSetSequence                        ="30060018",
        StructureSetROISequence                                ="30060020",
        ROINumber                                              ="30060022",
        ReferencedFrameOfReferenceUID                          ="30060024",
        ROIName                                                ="30060026",
        ROIDescription                                         ="30060028",
        ROIDisplayColor                                        ="3006002A",
        ROIVolume                                              ="3006002C",
        RTRelatedROISequence                                   ="30060030",
        RTROIRelationship                                      ="30060033",
        ROIGenerationAlgorithm                                 ="30060036",
        ROIGenerationDescription                               ="30060038",
        ROIContourSequence                                     ="30060039",
        ContourSequence                                        ="30060040",
        ContourGeometricType                                   ="30060042",
        ContourSlabThickness                                   ="30060044",
        ContourOffsetVector                                    ="30060045",
        NumberOfContourPoints                                  ="30060046",
        ContourNumber                                          ="30060048",
        AttachedContours                                       ="30060049",
        ContourData                                            ="30060050",
        RTROIObservationsSequence                              ="30060080",
        ObservationNumber                                      ="30060082",
        ReferencedROINumber                                    ="30060084",
        ROIObservationLabel                                    ="30060085",
        RTROIIdentificationCodeSequence                        ="30060086",
        ROIObservationDescription                              ="30060088",
        RelatedRTROIObservationsSequence                       ="300600A0",
        RTROIInterpretedType                                   ="300600A4",
        ROIInterpreter                                         ="300600A6",
        ROIPhysicalPropertiesSequence                          ="300600B0",
        ROIPhysicalProperty                                    ="300600B2",
        ROIPhysicalPropertyValue                               ="300600B4",
        ROIElementalCompositionSequence                        ="300600B6",
        ROIElementalCompositionAtomicNumber                    ="300600B7",
        ROIElementalCompositionAtomicMassFraction              ="300600B8",
        AdditionalRTROIIdentificationCodeSequence              ="300600B9",
        FrameOfReferenceRelationshipSequence                   ="300600C0",   // Retired
        RelatedFrameOfReferenceUID                             ="300600C2",   // Retired
        FrameOfReferenceTransformationType                     ="300600C4",   // Retired
        FrameOfReferenceTransformationMatrix                   ="300600C6",
        FrameOfReferenceTransformationComment                  ="300600C8",
    
    // *****************************************************************************************************************************
    // *** 3008
    // *****************************************************************************************************************************
        MeasuredDoseReferenceSequence                          ="30080010",
        MeasuredDoseDescription                                ="30080012",
        MeasuredDoseType                                       ="30080014",
        MeasuredDoseValue                                      ="30080016",
        TreatmentSessionBeamSequence                           ="30080020",
        TreatmentSessionIonBeamSequence                        ="30080021",
        CurrentFractionNumber                                  ="30080022",
        TreatmentControlPointDate                              ="30080024",
        TreatmentControlPointTime                              ="30080025",
        TreatmentTerminationStatus                             ="3008002A",
        TreatmentTerminationCode                               ="3008002B",
        TreatmentVerificationStatus                            ="3008002C",
        ReferencedTreatmentRecordSequence                      ="30080030",
        SpecifiedPrimaryMeterset                               ="30080032",
        SpecifiedSecondaryMeterset                             ="30080033",
        DeliveredPrimaryMeterset                               ="30080036",
        DeliveredSecondaryMeterset                             ="30080037",
        SpecifiedTreatmentTime                                 ="3008003A",
        DeliveredTreatmentTime                                 ="3008003B",
        ControlPointDeliverySequence                           ="30080040",
        IonControlPointDeliverySequence                        ="30080041",
        SpecifiedMeterset                                      ="30080042",
        DeliveredMeterset                                      ="30080044",
        MetersetRateSet                                        ="30080045",
        MetersetRateDelivered                                  ="30080046",
        ScanSpotMetersetsDelivered                             ="30080047",
        DoseRateDelivered                                      ="30080048",
        TreatmentSummaryCalculatedDoseReferenceSequence        ="30080050",
        CumulativeDoseToDoseReference                          ="30080052",
        FirstTreatmentDate                                     ="30080054",
        MostRecentTreatmentDate                                ="30080056",
        NumberOfFractionsDelivered                             ="3008005A",
        OverrideSequence                                       ="30080060",
        ParameterSequencePointer                               ="30080061",
        OverrideParameterPointer                               ="30080062",
        ParameterItemIndex                                     ="30080063",
        MeasuredDoseReferenceNumber                            ="30080064",
        ParameterPointer                                       ="30080065",
        OverrideReason                                         ="30080066",
        CorrectedParameterSequence                             ="30080068",
        CorrectionValue                                        ="3008006A",
        CalculatedDoseReferenceSequence                        ="30080070",
        CalculatedDoseReferenceNumber                          ="30080072",
        CalculatedDoseReferenceDescription                     ="30080074",
        CalculatedDoseReferenceDoseValue                       ="30080076",
        StartMeterset                                          ="30080078",
        EndMeterset                                            ="3008007A",
        ReferencedMeasuredDoseReferenceSequence                ="30080080",
        ReferencedMeasuredDoseReferenceNumber                  ="30080082",
        ReferencedCalculatedDoseReferenceSequence              ="30080090",
        ReferencedCalculatedDoseReferenceNumber                ="30080092",
        BeamLimitingDeviceLeafPairsSequence                    ="300800A0",
        RecordedWedgeSequence                                  ="300800B0",
        RecordedCompensatorSequence                            ="300800C0",
        RecordedBlockSequence                                  ="300800D0",
        TreatmentSummaryMeasuredDoseReferenceSequence          ="300800E0",
        RecordedSnoutSequence                                  ="300800F0",
        RecordedRangeShifterSequence                           ="300800F2",
        RecordedLateralSpreadingDeviceSequence                 ="300800F4",
        RecordedRangeModulatorSequence                         ="300800F6",
        RecordedSourceSequence                                 ="30080100",
        SourceSerialNumber                                     ="30080105",
        TreatmentSessionApplicationSetupSequence               ="30080110",
        ApplicationSetupCheck                                  ="30080116",
        RecordedBrachyAccessoryDeviceSequence                  ="30080120",
        ReferencedBrachyAccessoryDeviceNumber                  ="30080122",
        RecordedChannelSequence                                ="30080130",
        SpecifiedChannelTotalTime                              ="30080132",
        DeliveredChannelTotalTime                              ="30080134",
        SpecifiedNumberOfPulses                                ="30080136",
        DeliveredNumberOfPulses                                ="30080138",
        SpecifiedPulseRepetitionInterval                       ="3008013A",
        DeliveredPulseRepetitionInterval                       ="3008013C",
        RecordedSourceApplicatorSequence                       ="30080140",
        ReferencedSourceApplicatorNumber                       ="30080142",
        RecordedChannelShieldSequence                          ="30080150",
        ReferencedChannelShieldNumber                          ="30080152",
        BrachyControlPointDeliveredSequence                    ="30080160",
        SafePositionExitDate                                   ="30080162",
        SafePositionExitTime                                   ="30080164",
        SafePositionReturnDate                                 ="30080166",
        SafePositionReturnTime                                 ="30080168",
        PulseSpecificBrachyControlPointDeliveredSequence       ="30080171",
        PulseNumber                                            ="30080172",
        BrachyPulseControlPointDeliveredSequence               ="30080173",
        CurrentTreatmentStatus                                 ="30080200",
        TreatmentStatusComment                                 ="30080202",
        FractionGroupSummarySequence                           ="30080220",
        ReferencedFractionNumber                               ="30080223",
        FractionGroupType                                      ="30080224",
        BeamStopperPosition                                    ="30080230",
        FractionStatusSummarySequence                          ="30080240",
        TreatmentDate                                          ="30080250",
        TreatmentTime                                          ="30080251",
    
    // *****************************************************************************************************************************
    // *** 300A
    // *****************************************************************************************************************************
        RTPlanLabel                                            ="300A0002",
        RTPlanName                                             ="300A0003",
        RTPlanDescription                                      ="300A0004",
        RTPlanDate                                             ="300A0006",
        RTPlanTime                                             ="300A0007",
        TreatmentProtocols                                     ="300A0009",
        PlanIntent                                             ="300A000A",
        TreatmentSites                                         ="300A000B",
        RTPlanGeometry                                         ="300A000C",
        PrescriptionDescription                                ="300A000E",
        DoseReferenceSequence                                  ="300A0010",
        DoseReferenceNumber                                    ="300A0012",
        DoseReferenceUID                                       ="300A0013",
        DoseReferenceStructureType                             ="300A0014",
        NominalBeamEnergyUnit                                  ="300A0015",
        DoseReferenceDescription                               ="300A0016",
        DoseReferencePointCoordinates                          ="300A0018",
        NominalPriorDose                                       ="300A001A",
        DoseReferenceType                                      ="300A0020",
        ConstraintWeight                                       ="300A0021",
        DeliveryWarningDose                                    ="300A0022",
        DeliveryMaximumDose                                    ="300A0023",
        TargetMinimumDose                                      ="300A0025",
        TargetPrescriptionDose                                 ="300A0026",
        TargetMaximumDose                                      ="300A0027",
        TargetUnderdoseVolumeFraction                          ="300A0028",
        OrganAtRiskFullVolumeDose                              ="300A002A",
        OrganAtRiskLimitDose                                   ="300A002B",
        OrganAtRiskMaximumDose                                 ="300A002C",
        OrganAtRiskOverdoseVolumeFraction                      ="300A002D",
        ToleranceTableSequence                                 ="300A0040",
        ToleranceTableNumber                                   ="300A0042",
        ToleranceTableLabel                                    ="300A0043",
        GantryAngleTolerance                                   ="300A0044",
        BeamLimitingDeviceAngleTolerance                       ="300A0046",
        BeamLimitingDeviceToleranceSequence                    ="300A0048",
        BeamLimitingDevicePositionTolerance                    ="300A004A",
        SnoutPositionTolerance                                 ="300A004B",
        PatientSupportAngleTolerance                           ="300A004C",
        TableTopEccentricAngleTolerance                        ="300A004E",
        TableTopPitchAngleTolerance                            ="300A004F",
        TableTopRollAngleTolerance                             ="300A0050",
        TableTopVerticalPositionTolerance                      ="300A0051",
        TableTopLongitudinalPositionTolerance                  ="300A0052",
        TableTopLateralPositionTolerance                       ="300A0053",
        RTPlanRelationship                                     ="300A0055",
        FractionGroupSequence                                  ="300A0070",
        FractionGroupNumber                                    ="300A0071",
        FractionGroupDescription                               ="300A0072",
        NumberOfFractionsPlanned                               ="300A0078",
        NumberOfFractionPatternDigitsPerDay                    ="300A0079",
        RepeatFractionCycleLength                              ="300A007A",
        FractionPattern                                        ="300A007B",
        NumberOfBeams                                          ="300A0080",
        BeamDoseSpecificationPoint                             ="300A0082",
        BeamDose                                               ="300A0084",
        BeamMeterset                                           ="300A0086",
        BeamDosePointDepth                                     ="300A0088",   // Retired
        BeamDosePointEquivalentDepth                           ="300A0089",   // Retired
        BeamDosePointSSD                                       ="300A008A",   // Retired
        BeamDoseMeaning                                        ="300A008B",
        BeamDoseVerificationControlPointSequence               ="300A008C",
        AverageBeamDosePointDepth                              ="300A008D",
        AverageBeamDosePointEquivalentDepth                    ="300A008E",
        AverageBeamDosePointSSD                                ="300A008F",
        NumberOfBrachyApplicationSetups                        ="300A00A0",
        BrachyApplicationSetupDoseSpecificationPoint           ="300A00A2",
        BrachyApplicationSetupDose                             ="300A00A4",
        BeamSequence                                           ="300A00B0",
        TreatmentMachineName                                   ="300A00B2",
        PrimaryDosimeterUnit                                   ="300A00B3",
        SourceAxisDistance                                     ="300A00B4",
        BeamLimitingDeviceSequence                             ="300A00B6",
        RTBeamLimitingDeviceType                               ="300A00B8",
        SourceToBeamLimitingDeviceDistance                     ="300A00BA",
        IsocenterToBeamLimitingDeviceDistance                  ="300A00BB",
        NumberOfLeafJawPairs                                   ="300A00BC",
        LeafPositionBoundaries                                 ="300A00BE",
        BeamNumber                                             ="300A00C0",
        BeamName                                               ="300A00C2",
        BeamDescription                                        ="300A00C3",
        BeamType                                               ="300A00C4",
        BeamDeliveryDurationLimit                              ="300A00C5",
        RadiationType                                          ="300A00C6",
        HighDoseTechniqueType                                  ="300A00C7",
        ReferenceImageNumber                                   ="300A00C8",
        PlannedVerificationImageSequence                       ="300A00CA",
        ImagingDeviceSpecificAcquisitionParameters             ="300A00CC",
        TreatmentDeliveryType                                  ="300A00CE",
        NumberOfWedges                                         ="300A00D0",
        WedgeSequence                                          ="300A00D1",
        WedgeNumber                                            ="300A00D2",
        WedgeType                                              ="300A00D3",
        WedgeID                                                ="300A00D4",
        WedgeAngle                                             ="300A00D5",
        WedgeFactor                                            ="300A00D6",
        TotalWedgeTrayWaterEquivalentThickness                 ="300A00D7",
        WedgeOrientation                                       ="300A00D8",
        IsocenterToWedgeTrayDistance                           ="300A00D9",
        SourceToWedgeTrayDistance                              ="300A00DA",
        WedgeThinEdgePosition                                  ="300A00DB",
        BolusID                                                ="300A00DC",
        BolusDescription                                       ="300A00DD",
        EffectiveWedgeAngle                                    ="300A00DE",
        NumberOfCompensators                                   ="300A00E0",
        MaterialID                                             ="300A00E1",
        TotalCompensatorTrayFactor                             ="300A00E2",
        CompensatorSequence                                    ="300A00E3",
        CompensatorNumber                                      ="300A00E4",
        CompensatorID                                          ="300A00E5",
        SourceToCompensatorTrayDistance                        ="300A00E6",
        CompensatorRows                                        ="300A00E7",
        CompensatorColumns                                     ="300A00E8",
        CompensatorPixelSpacing                                ="300A00E9",
        CompensatorPosition                                    ="300A00EA",
        CompensatorTransmissionData                            ="300A00EB",
        CompensatorThicknessData                               ="300A00EC",
        NumberOfBoli                                           ="300A00ED",
        CompensatorType                                        ="300A00EE",
        CompensatorTrayID                                      ="300A00EF",
        NumberOfBlocks                                         ="300A00F0",
        TotalBlockTrayFactor                                   ="300A00F2",
        TotalBlockTrayWaterEquivalentThickness                 ="300A00F3",
        BlockSequence                                          ="300A00F4",
        BlockTrayID                                            ="300A00F5",
        SourceToBlockTrayDistance                              ="300A00F6",
        IsocenterToBlockTrayDistance                           ="300A00F7",
        BlockType                                              ="300A00F8",
        AccessoryCode                                          ="300A00F9",
        BlockDivergence                                        ="300A00FA",
        BlockMountingPosition                                  ="300A00FB",
        BlockNumber                                            ="300A00FC",
        BlockName                                              ="300A00FE",
        BlockThickness                                         ="300A0100",
        BlockTransmission                                      ="300A0102",
        BlockNumberOfPoints                                    ="300A0104",
        BlockData                                              ="300A0106",
        ApplicatorSequence                                     ="300A0107",
        ApplicatorID                                           ="300A0108",
        ApplicatorType                                         ="300A0109",
        ApplicatorDescription                                  ="300A010A",
        CumulativeDoseReferenceCoefficient                     ="300A010C",
        FinalCumulativeMetersetWeight                          ="300A010E",
        NumberOfControlPoints                                  ="300A0110",
        ControlPointSequence                                   ="300A0111",
        ControlPointIndex                                      ="300A0112",
        NominalBeamEnergy                                      ="300A0114",
        DoseRateSet                                            ="300A0115",
        WedgePositionSequence                                  ="300A0116",
        WedgePosition                                          ="300A0118",
        BeamLimitingDevicePositionSequence                     ="300A011A",
        LeafJawPositions                                       ="300A011C",
        GantryAngle                                            ="300A011E",
        GantryRotationDirection                                ="300A011F",
        BeamLimitingDeviceAngle                                ="300A0120",
        BeamLimitingDeviceRotationDirection                    ="300A0121",
        PatientSupportAngle                                    ="300A0122",
        PatientSupportRotationDirection                        ="300A0123",
        TableTopEccentricAxisDistance                          ="300A0124",
        TableTopEccentricAngle                                 ="300A0125",
        TableTopEccentricRotationDirection                     ="300A0126",
        TableTopVerticalPosition                               ="300A0128",
        TableTopLongitudinalPosition                           ="300A0129",
        TableTopLateralPosition                                ="300A012A",
        IsocenterPosition                                      ="300A012C",
        SurfaceEntryPoint                                      ="300A012E",
        SourceToSurfaceDistance                                ="300A0130",
        AverageBeamDosePointSourceToExternalContourSurfaceDistance ="300A0131",
        SourceToExternalContourDistance                        ="300A0132",
        ExternalContourEntryPoint                              ="300A0133",
        CumulativeMetersetWeight                               ="300A0134",
        TableTopPitchAngle                                     ="300A0140",
        TableTopPitchRotationDirection                         ="300A0142",
        TableTopRollAngle                                      ="300A0144",
        TableTopRollRotationDirection                          ="300A0146",
        HeadFixationAngle                                      ="300A0148",
        GantryPitchAngle                                       ="300A014A",
        GantryPitchRotationDirection                           ="300A014C",
        GantryPitchAngleTolerance                              ="300A014E",
        PatientSetupSequence                                   ="300A0180",
        PatientSetupNumber                                     ="300A0182",
        PatientSetupLabel                                      ="300A0183",
        PatientAdditionalPosition                              ="300A0184",
        FixationDeviceSequence                                 ="300A0190",
        FixationDeviceType                                     ="300A0192",
        FixationDeviceLabel                                    ="300A0194",
        FixationDeviceDescription                              ="300A0196",
        FixationDevicePosition                                 ="300A0198",
        FixationDevicePitchAngle                               ="300A0199",
        FixationDeviceRollAngle                                ="300A019A",
        ShieldingDeviceSequence                                ="300A01A0",
        ShieldingDeviceType                                    ="300A01A2",
        ShieldingDeviceLabel                                   ="300A01A4",
        ShieldingDeviceDescription                             ="300A01A6",
        ShieldingDevicePosition                                ="300A01A8",
        SetupTechnique                                         ="300A01B0",
        SetupTechniqueDescription                              ="300A01B2",
        SetupDeviceSequence                                    ="300A01B4",
        SetupDeviceType                                        ="300A01B6",
        SetupDeviceLabel                                       ="300A01B8",
        SetupDeviceDescription                                 ="300A01BA",
        SetupDeviceParameter                                   ="300A01BC",
        SetupReferenceDescription                              ="300A01D0",
        TableTopVerticalSetupDisplacement                      ="300A01D2",
        TableTopLongitudinalSetupDisplacement                  ="300A01D4",
        TableTopLateralSetupDisplacement                       ="300A01D6",
        BrachyTreatmentTechnique                               ="300A0200",
        BrachyTreatmentType                                    ="300A0202",
        TreatmentMachineSequence                               ="300A0206",
        SourceSequence                                         ="300A0210",
        SourceNumber                                           ="300A0212",
        SourceType                                             ="300A0214",
        SourceManufacturer                                     ="300A0216",
        ActiveSourceDiameter                                   ="300A0218",
        ActiveSourceLength                                     ="300A021A",
        SourceModelID                                          ="300A021B",
        SourceDescription                                      ="300A021C",
        SourceEncapsulationNominalThickness                    ="300A0222",
        SourceEncapsulationNominalTransmission                 ="300A0224",
        SourceIsotopeName                                      ="300A0226",
        SourceIsotopeHalfLife                                  ="300A0228",
        SourceStrengthUnits                                    ="300A0229",
        ReferenceAirKermaRate                                  ="300A022A",
        SourceStrength                                         ="300A022B",
        SourceStrengthReferenceDate                            ="300A022C",
        SourceStrengthReferenceTime                            ="300A022E",
        ApplicationSetupSequence                               ="300A0230",
        ApplicationSetupType                                   ="300A0232",
        ApplicationSetupNumber                                 ="300A0234",
        ApplicationSetupName                                   ="300A0236",
        ApplicationSetupManufacturer                           ="300A0238",
        TemplateNumber                                         ="300A0240",
        TemplateType                                           ="300A0242",
        TemplateName                                           ="300A0244",
        TotalReferenceAirKerma                                 ="300A0250",
        BrachyAccessoryDeviceSequence                          ="300A0260",
        BrachyAccessoryDeviceNumber                            ="300A0262",
        BrachyAccessoryDeviceID                                ="300A0263",
        BrachyAccessoryDeviceType                              ="300A0264",
        BrachyAccessoryDeviceName                              ="300A0266",
        BrachyAccessoryDeviceNominalThickness                  ="300A026A",
        BrachyAccessoryDeviceNominalTransmission               ="300A026C",
        ChannelSequence                                        ="300A0280",
        ChannelNumber                                          ="300A0282",
        ChannelLength                                          ="300A0284",
        ChannelTotalTime                                       ="300A0286",
        SourceMovementType                                     ="300A0288",
        NumberOfPulses                                         ="300A028A",
        PulseRepetitionInterval                                ="300A028C",
        SourceApplicatorNumber                                 ="300A0290",
        SourceApplicatorID                                     ="300A0291",
        SourceApplicatorType                                   ="300A0292",
        SourceApplicatorName                                   ="300A0294",
        SourceApplicatorLength                                 ="300A0296",
        SourceApplicatorManufacturer                           ="300A0298",
        SourceApplicatorWallNominalThickness                   ="300A029C",
        SourceApplicatorWallNominalTransmission                ="300A029E",
        SourceApplicatorStepSize                               ="300A02A0",
        TransferTubeNumber                                     ="300A02A2",
        TransferTubeLength                                     ="300A02A4",
        ChannelShieldSequence                                  ="300A02B0",
        ChannelShieldNumber                                    ="300A02B2",
        ChannelShieldID                                        ="300A02B3",
        ChannelShieldName                                      ="300A02B4",
        ChannelShieldNominalThickness                          ="300A02B8",
        ChannelShieldNominalTransmission                       ="300A02BA",
        FinalCumulativeTimeWeight                              ="300A02C8",
        BrachyControlPointSequence                             ="300A02D0",
        ControlPointRelativePosition                           ="300A02D2",
        ControlPoint3DPosition                                 ="300A02D4",
        CumulativeTimeWeight                                   ="300A02D6",
        CompensatorDivergence                                  ="300A02E0",
        CompensatorMountingPosition                            ="300A02E1",
        SourceToCompensatorDistance                            ="300A02E2",
        TotalCompensatorTrayWaterEquivalentThickness           ="300A02E3",
        IsocenterToCompensatorTrayDistance                     ="300A02E4",
        CompensatorColumnOffset                                ="300A02E5",
        IsocenterToCompensatorDistances                        ="300A02E6",
        CompensatorRelativeStoppingPowerRatio                  ="300A02E7",
        CompensatorMillingToolDiameter                         ="300A02E8",
        IonRangeCompensatorSequence                            ="300A02EA",
        CompensatorDescription                                 ="300A02EB",
        RadiationMassNumber                                    ="300A0302",
        RadiationAtomicNumber                                  ="300A0304",
        RadiationChargeState                                   ="300A0306",
        ScanMode                                               ="300A0308",
        VirtualSourceAxisDistances                             ="300A030A",
        SnoutSequence                                          ="300A030C",
        SnoutPosition                                          ="300A030D",
        SnoutID                                                ="300A030F",
        NumberOfRangeShifters                                  ="300A0312",
        RangeShifterSequence                                   ="300A0314",
        RangeShifterNumber                                     ="300A0316",
        RangeShifterID                                         ="300A0318",
        RangeShifterType                                       ="300A0320",
        RangeShifterDescription                                ="300A0322",
        NumberOfLateralSpreadingDevices                        ="300A0330",
        LateralSpreadingDeviceSequence                         ="300A0332",
        LateralSpreadingDeviceNumber                           ="300A0334",
        LateralSpreadingDeviceID                               ="300A0336",
        LateralSpreadingDeviceType                             ="300A0338",
        LateralSpreadingDeviceDescription                      ="300A033A",
        LateralSpreadingDeviceWaterEquivalentThickness         ="300A033C",
        NumberOfRangeModulators                                ="300A0340",
        RangeModulatorSequence                                 ="300A0342",
        RangeModulatorNumber                                   ="300A0344",
        RangeModulatorID                                       ="300A0346",
        RangeModulatorType                                     ="300A0348",
        RangeModulatorDescription                              ="300A034A",
        BeamCurrentModulationID                                ="300A034C",
        PatientSupportType                                     ="300A0350",
        PatientSupportID                                       ="300A0352",
        PatientSupportAccessoryCode                            ="300A0354",
        FixationLightAzimuthalAngle                            ="300A0356",
        FixationLightPolarAngle                                ="300A0358",
        MetersetRate                                           ="300A035A",
        RangeShifterSettingsSequence                           ="300A0360",
        RangeShifterSetting                                    ="300A0362",
        IsocenterToRangeShifterDistance                        ="300A0364",
        RangeShifterWaterEquivalentThickness                   ="300A0366",
        LateralSpreadingDeviceSettingsSequence                 ="300A0370",
        LateralSpreadingDeviceSetting                          ="300A0372",
        IsocenterToLateralSpreadingDeviceDistance              ="300A0374",
        RangeModulatorSettingsSequence                         ="300A0380",
        RangeModulatorGatingStartValue                         ="300A0382",
        RangeModulatorGatingStopValue                          ="300A0384",
        RangeModulatorGatingStartWaterEquivalentThickness      ="300A0386",
        RangeModulatorGatingStopWaterEquivalentThickness       ="300A0388",
        IsocenterToRangeModulatorDistance                      ="300A038A",
        ScanSpotTuneID                                         ="300A0390",
        NumberOfScanSpotPositions                              ="300A0392",
        ScanSpotPositionMap                                    ="300A0394",
        ScanSpotMetersetWeights                                ="300A0396",
        ScanningSpotSize                                       ="300A0398",
        NumberOfPaintings                                      ="300A039A",
        IonToleranceTableSequence                              ="300A03A0",
        IonBeamSequence                                        ="300A03A2",
        IonBeamLimitingDeviceSequence                          ="300A03A4",
        IonBlockSequence                                       ="300A03A6",
        IonControlPointSequence                                ="300A03A8",
        IonWedgeSequence                                       ="300A03AA",
        IonWedgePositionSequence                               ="300A03AC",
        ReferencedSetupImageSequence                           ="300A0401",
        SetupImageComment                                      ="300A0402",
        MotionSynchronizationSequence                          ="300A0410",
        ControlPointOrientation                                ="300A0412",
        GeneralAccessorySequence                               ="300A0420",
        GeneralAccessoryID                                     ="300A0421",
        GeneralAccessoryDescription                            ="300A0422",
        GeneralAccessoryType                                   ="300A0423",
        GeneralAccessoryNumber                                 ="300A0424",
        SourceToGeneralAccessoryDistance                       ="300A0425",
        ApplicatorGeometrySequence                             ="300A0431",
        ApplicatorApertureShape                                ="300A0432",
        ApplicatorOpening                                      ="300A0433",
        ApplicatorOpeningX                                     ="300A0434",
        ApplicatorOpeningY                                     ="300A0435",
        SourceToApplicatorMountingPositionDistance             ="300A0436",
        NumberOfBlockSlabItems                                 ="300A0440",
        BlockSlabSequence                                      ="300A0441",
        BlockSlabThickness                                     ="300A0442",
        BlockSlabNumber                                        ="300A0443",
        DeviceMotionControlSequence                            ="300A0450",
        DeviceMotionExecutionMode                              ="300A0451",
        DeviceMotionObservationMode                            ="300A0452",
        DeviceMotionParameterCodeSequence                      ="300A0453",
    
    // *****************************************************************************************************************************
    // *** 300C
    // *****************************************************************************************************************************
        ReferencedRTPlanSequence                               ="300C0002",
        ReferencedBeamSequence                                 ="300C0004",
        ReferencedBeamNumber                                   ="300C0006",
        ReferencedReferenceImageNumber                         ="300C0007",
        StartCumulativeMetersetWeight                          ="300C0008",
        EndCumulativeMetersetWeight                            ="300C0009",
        ReferencedBrachyApplicationSetupSequence               ="300C000A",
        ReferencedBrachyApplicationSetupNumber                 ="300C000C",
        ReferencedSourceNumber                                 ="300C000E",
        ReferencedFractionGroupSequence                        ="300C0020",
        ReferencedFractionGroupNumber                          ="300C0022",
        ReferencedVerificationImageSequence                    ="300C0040",
        ReferencedReferenceImageSequence                       ="300C0042",
        ReferencedDoseReferenceSequence                        ="300C0050",
        ReferencedDoseReferenceNumber                          ="300C0051",
        BrachyReferencedDoseReferenceSequence                  ="300C0055",
        ReferencedStructureSetSequence                         ="300C0060",
        ReferencedPatientSetupNumber                           ="300C006A",
        ReferencedDoseSequence                                 ="300C0080",
        ReferencedToleranceTableNumber                         ="300C00A0",
        ReferencedBolusSequence                                ="300C00B0",
        ReferencedWedgeNumber                                  ="300C00C0",
        ReferencedCompensatorNumber                            ="300C00D0",
        ReferencedBlockNumber                                  ="300C00E0",
        ReferencedControlPointIndex                            ="300C00F0",
        ReferencedControlPointSequence                         ="300C00F2",
        ReferencedStartControlPointIndex                       ="300C00F4",
        ReferencedStopControlPointIndex                        ="300C00F6",
        ReferencedRangeShifterNumber                           ="300C0100",
        ReferencedLateralSpreadingDeviceNumber                 ="300C0102",
        ReferencedRangeModulatorNumber                         ="300C0104",
        OmittedBeamTaskSequence                                ="300C0111",
        ReasonForOmission                                      ="300C0112",
        ReasonForOmissionDescription                           ="300C0113",
    
    // *****************************************************************************************************************************
    // *** 300E
    // *****************************************************************************************************************************
        ApprovalStatus                                         ="300E0002",
        ReviewDate                                             ="300E0004",
        ReviewTime                                             ="300E0005",
        ReviewerName                                           ="300E0008",
    
    // *****************************************************************************************************************************
    // *** 4000
    // *****************************************************************************************************************************
        Arbitrary                                              ="40000010",   // Retired
        TextComments                                           ="40004000",   // Retired
    
    // *****************************************************************************************************************************
    // *** 4008
    // *****************************************************************************************************************************
        ResultsID                                              ="40080040",   // Retired
        ResultsIDIssuer                                        ="40080042",   // Retired
        ReferencedInterpretationSequence                       ="40080050",   // Retired
        ReportProductionStatusTrial                            ="400800FF",   // Retired
        InterpretationRecordedDate                             ="40080100",   // Retired
        InterpretationRecordedTime                             ="40080101",   // Retired
        InterpretationRecorder                                 ="40080102",   // Retired
        ReferenceToRecordedSound                               ="40080103",   // Retired
        InterpretationTranscriptionDate                        ="40080108",   // Retired
        InterpretationTranscriptionTime                        ="40080109",   // Retired
        InterpretationTranscriber                              ="4008010A",   // Retired
        InterpretationText                                     ="4008010B",   // Retired
        InterpretationAuthor                                   ="4008010C",   // Retired
        InterpretationApproverSequence                         ="40080111",   // Retired
        InterpretationApprovalDate                             ="40080112",   // Retired
        InterpretationApprovalTime                             ="40080113",   // Retired
        PhysicianApprovingInterpretation                       ="40080114",   // Retired
        InterpretationDiagnosisDescription                     ="40080115",   // Retired
        InterpretationDiagnosisCodeSequence                    ="40080117",   // Retired
        ResultsDistributionListSequence                        ="40080118",   // Retired
        DistributionName                                       ="40080119",   // Retired
        DistributionAddress                                    ="4008011A",   // Retired
        InterpretationID                                       ="40080200",   // Retired
        InterpretationIDIssuer                                 ="40080202",   // Retired
        InterpretationTypeID                                   ="40080210",   // Retired
        InterpretationStatusID                                 ="40080212",   // Retired
        Impressions                                            ="40080300",   // Retired
        ResultsComments                                        ="40084000",   // Retired
    
    // *****************************************************************************************************************************
    // *** 4010
    // *****************************************************************************************************************************
        LowEnergyDetectors                                     ="40100001",
        HighEnergyDetectors                                    ="40100002",
        DetectorGeometrySequence                               ="40100004",
        ThreatROIVoxelSequence                                 ="40101001",
        ThreatROIBase                                          ="40101004",
        ThreatROIExtents                                       ="40101005",
        ThreatROIBitmap                                        ="40101006",
        RouteSegmentID                                         ="40101007",
        GantryType                                             ="40101008",
        OOIOwnerType                                           ="40101009",
        RouteSegmentSequence                                   ="4010100A",
        PotentialThreatObjectID                                ="40101010",
        ThreatSequence                                         ="40101011",
        ThreatCategory                                         ="40101012",
        ThreatCategoryDescription                              ="40101013",
        ATDAbilityAssessment                                   ="40101014",
        ATDAssessmentFlag                                      ="40101015",
        ATDAssessmentProbability                               ="40101016",
        Mass                                                   ="40101017",
        Density                                                ="40101018",
        ZEffective                                             ="40101019",
        BoardingPassID                                         ="4010101A",
        CenterOfMass                                           ="4010101B",
        CenterOfPTO                                            ="4010101C",
        BoundingPolygon                                        ="4010101D",
        RouteSegmentStartLocationID                            ="4010101E",
        RouteSegmentEndLocationID                              ="4010101F",
        RouteSegmentLocationIDType                             ="40101020",
        AbortReason                                            ="40101021",
        VolumeOfPTO                                            ="40101023",
        AbortFlag                                              ="40101024",
        RouteSegmentStartTime                                  ="40101025",
        RouteSegmentEndTime                                    ="40101026",
        TDRType                                                ="40101027",
        InternationalRouteSegment                              ="40101028",
        ThreatDetectionAlgorithmAndVersion                     ="40101029",
        AssignedLocation                                       ="4010102A",
        AlarmDecisionTime                                      ="4010102B",
        AlarmDecision                                          ="40101031",
        NumberOfTotalObjects                                   ="40101033",
        NumberOfAlarmObjects                                   ="40101034",
        PTORepresentationSequence                              ="40101037",
        ATDAssessmentSequence                                  ="40101038",
        TIPType                                                ="40101039",
        DICOSVersion                                           ="4010103A",
        OOIOwnerCreationTime                                   ="40101041",
        OOIType                                                ="40101042",
        OOISize                                                ="40101043",
        AcquisitionStatus                                      ="40101044",
        BasisMaterialsCodeSequence                             ="40101045",
        PhantomType                                            ="40101046",
        OOIOwnerSequence                                       ="40101047",
        ScanType                                               ="40101048",
        ItineraryID                                            ="40101051",
        ItineraryIDType                                        ="40101052",
        ItineraryIDAssigningAuthority                          ="40101053",
        RouteID                                                ="40101054",
        RouteIDAssigningAuthority                              ="40101055",
        InboundArrivalType                                     ="40101056",
        CarrierID                                              ="40101058",
        CarrierIDAssigningAuthority                            ="40101059",
        SourceOrientation                                      ="40101060",
        SourcePosition                                         ="40101061",
        BeltHeight                                             ="40101062",
        AlgorithmRoutingCodeSequence                           ="40101064",
        TransportClassification                                ="40101067",
        OOITypeDescriptor                                      ="40101068",
        TotalProcessingTime                                    ="40101069",
        DetectorCalibrationData                                ="4010106C",
        AdditionalScreeningPerformed                           ="4010106D",
        AdditionalInspectionSelectionCriteria                  ="4010106E",
        AdditionalInspectionMethodSequence                     ="4010106F",
        AITDeviceType                                          ="40101070",
        QRMeasurementsSequence                                 ="40101071",
        TargetMaterialSequence                                 ="40101072",
        SNRThreshold                                           ="40101073",
        ImageScaleRepresentation                               ="40101075",
        ReferencedPTOSequence                                  ="40101076",
        ReferencedTDRInstanceSequence                          ="40101077",
        PTOLocationDescription                                 ="40101078",
        AnomalyLocatorIndicatorSequence                        ="40101079",
        AnomalyLocatorIndicator                                ="4010107A",
        PTORegionSequence                                      ="4010107B",
        InspectionSelectionCriteria                            ="4010107C",
        SecondaryInspectionMethodSequence                      ="4010107D",
        PRCSToRCSOrientation                                   ="4010107E",
    
    // *****************************************************************************************************************************
    // *** 4FFE
    // *****************************************************************************************************************************
        MACParametersSequence                                  ="4FFE0001",
    
    // *****************************************************************************************************************************
    // *** 50xx
    // *****************************************************************************************************************************
        CurveDimensions                                        ="50000005",   // Retired
        NumberOfPoints                                         ="50000010",   // Retired
        TypeOfData                                             ="50000020",   // Retired
        CurveDescription                                       ="50000022",   // Retired
        AxisUnits                                              ="50000030",   // Retired
        AxisLabels                                             ="50000040",   // Retired
        DataValueRepresentation                                ="50000103",   // Retired
        MinimumCoordinateValue                                 ="50000104",   // Retired
        MaximumCoordinateValue                                 ="50000105",   // Retired
        CurveRange                                             ="50000106",   // Retired
        CurveDataDescriptor                                    ="50000110",   // Retired
        CoordinateStartValue                                   ="50000112",   // Retired
        CoordinateStepValue                                    ="50000114",   // Retired
        CurveActivationLayer                                   ="50001001",   // Retired
        AudioType                                              ="50002000",   // Retired
        AudioSampleFormat                                      ="50002002",   // Retired
        NumberOfChannels                                       ="50002004",   // Retired
        NumberOfSamples                                        ="50002006",   // Retired
        SampleRate                                             ="50002008",   // Retired
        TotalTime                                              ="5000200A",   // Retired
        AudioSampleData                                        ="5000200C",   // Retired
        AudioComments                                          ="5000200E",   // Retired
        CurveLabel                                             ="50002500",   // Retired
        CurveReferencedOverlaySequence                         ="50002600",   // Retired
        CurveReferencedOverlayGroup                            ="50002610",   // Retired
        CurveData                                              ="50003000",   // Retired
    
    // *****************************************************************************************************************************
    // *** 5200
    // *****************************************************************************************************************************
        SharedFunctionalGroupsSequence                         ="52009229",
        PerFrameFunctionalGroupsSequence                       ="52009230",
    
    // *****************************************************************************************************************************
    // *** 5400
    // *****************************************************************************************************************************
        WaveformSequence                                       ="54000100",
        ChannelMinimumValue                                    ="54000110",
        ChannelMaximumValue                                    ="54000112",
        WaveformBitsAllocated                                  ="54001004",
        WaveformSampleInterpretation                           ="54001006",
        WaveformPaddingValue                                   ="5400100A",
        WaveformData                                           ="54001010",
    
    // *****************************************************************************************************************************
    // *** 5600
    // *****************************************************************************************************************************
        FirstOrderPhaseCorrectionAngle                         ="56000010",
        SpectroscopyData                                       ="56000020",
    
    // *****************************************************************************************************************************
    // *** 60xx
    // *****************************************************************************************************************************
        OverlayRows                                            ="60000010",
        OverlayColumns                                         ="60000011",
        OverlayPlanes                                          ="60000012",   // Retired
        NumberOfFramesInOverlay                                ="60000015",
        OverlayDescription                                     ="60000022",
        OverlayType                                            ="60000040",
        OverlaySubtype                                         ="60000045",
        OverlayOrigin                                          ="60000050",
        ImageFrameOrigin                                       ="60000051",
        OverlayPlaneOrigin                                     ="60000052",   // Retired
        OverlayCompressionCode                                 ="60000060",   // Retired
        OverlayCompressionOriginator                           ="60000061",   // Retired
        OverlayCompressionLabel                                ="60000062",   // Retired
        OverlayCompressionDescription                          ="60000063",   // Retired
        OverlayCompressionStepPointers                         ="60000066",   // Retired
        OverlayRepeatInterval                                  ="60000068",   // Retired
        OverlayBitsGrouped                                     ="60000069",   // Retired
        OverlayBitsAllocated                                   ="60000100",
        OverlayBitPosition                                     ="60000102",
        OverlayFormat                                          ="60000110",   // Retired
        OverlayLocation                                        ="60000200",   // Retired
        OverlayCodeLabel                                       ="60000800",   // Retired
        OverlayNumberOfTables                                  ="60000802",   // Retired
        OverlayCodeTableLocation                               ="60000803",   // Retired
        OverlayBitsForCodeWord                                 ="60000804",   // Retired
        OverlayActivationLayer                                 ="60001001",
        OverlayDescriptorGray                                  ="60001100",   // Retired
        OverlayDescriptorRed                                   ="60001101",   // Retired
        OverlayDescriptorGreen                                 ="60001102",   // Retired
        OverlayDescriptorBlue                                  ="60001103",   // Retired
        OverlaysGray                                           ="60001200",   // Retired
        OverlaysRed                                            ="60001201",   // Retired
        OverlaysGreen                                          ="60001202",   // Retired
        OverlaysBlue                                           ="60001203",   // Retired
        ROIArea                                                ="60001301",
        ROIMean                                                ="60001302",
        ROIStandardDeviation                                   ="60001303",
        OverlayLabel                                           ="60001500",
        OverlayData                                            ="60003000",
        OverlayComments                                        ="60004000",   // Retired
    
    // *****************************************************************************************************************************
    // *** 7FE0
    // *****************************************************************************************************************************
        FloatPixelData                                         ="7FE00008",
        DoubleFloatPixelData                                   ="7FE00009",
        PixelData                                              ="7FE00010",
        CoefficientsSDVN                                       ="7FE00020",   // Retired
        CoefficientsSDHN                                       ="7FE00030",   // Retired
        CoefficientsSDDN                                       ="7FE00040",   // Retired
    
    // *****************************************************************************************************************************
    // *** 7Fxx
    // *****************************************************************************************************************************
        VariablePixelData                                      ="7F000010",   // Retired
        VariableNextDataGroup                                  ="7F000011",   // Retired
        VariableCoefficientsSDVN                               ="7F000020",   // Retired
        VariableCoefficientsSDHN                               ="7F000030",   // Retired
        VariableCoefficientsSDDN                               ="7F000040",   // Retired
    
    // *****************************************************************************************************************************
    // *** FFFA
    // *****************************************************************************************************************************
        DigitalSignaturesSequence                              ="FFFAFFFA",
    
    // *****************************************************************************************************************************
    // *** FFFC
    // *****************************************************************************************************************************
        DataSetTrailingPadding                                 ="FFFCFFFC",
    
    // *****************************************************************************************************************************
    // *** FFFE
    // *****************************************************************************************************************************
        Item                                                   ="FFFEE000",
        ItemDelimitationItem                                   ="FFFEE00D",
        SequenceDelimitationItem                               ="FFFEE0DD"
    
    }