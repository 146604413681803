import {ActivatedRoute, Router} from '@angular/router';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {
  EndpointService,
  ModalityService,
  ReadingConfigService,
  ScannerService,
  SiteConfigService,
  SiteService,
  SponsorService,
  StudySequenceLabelService,
  StudyService,
  StudyUserService,
  TherapeuticAreaService,
  TrialPhaseService,
  UserService,
  VisitService
} from '../../../_services';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {EdtfEditComponent} from '../../DataUpload/edtf-edit/edtf-edit.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {SponsorModel} from '../../../_models/ImagingProject/sponsor-model';
import {ResponseCode} from '../../../core/constants/response-code';
import {StudyModel} from '../../../_models/ImagingProject/study-model';

interface Study extends StudyModel {
  bucketShort: string;
  ctGovReferenceLink?: any;
}
@Component({
  selector: 'app-webinar-project-create',
  templateUrl: './webinar-project-create.component.html',
  styleUrls: ['./webinar-project-create.component.css']
})
export class WebinarProjectCreateComponent implements OnInit {

  study: Study = {} as Study;
  studyId: number;
  sponsors: SponsorModel[] = [];
  sites: any[] = [];
  siteConfigs: any[] = [];
  scanners: any[] = [];
  trialPhases: any[] = [];
  therapeuticAreas: any[] = [];
  therapeuticArea: any = {};
  siteConfig: any = {
    scanners: []
  };
  modality: any = {};
  modalities: any[] = [];
  visit: any = {};
  studySequenceLabel: any = {};
  studySequenceLabels: any[] = [];
  readingConfigs: any[] = [];
  readingSequenceLabels: any[] = [];
  readers: any[] = [];
  dataSourceSequenceLabel: MatTableDataSource<any>;
  dataSourceReading: MatTableDataSource<any>;
  dataSourceVisit: MatTableDataSource<any>;
  dataSourceSite: MatTableDataSource<any>;
  showSequenceLabelTable = true;
  showSequenceLabel = false;
  showVisitTable = true;
  showVisit = false;
  showSiteTable = true;
  showSite = false;
  showEdtfConfig = false;
  eDtfVisits: any[] = [];
  hasBaselineVisit = false;
  studyUser: any = {};
  user: any = {};
  endpoints: any[] = [];
  adjudicationReader: any = {};
  endpoint: any = {};
  bucketLocations: any[] = ['EU', 'US', 'ASIA'];
  displayedColumnsSequenceLabel: string[] = ['row-num', 'sequence-label', 'sequence-label-description', 'operations'];
  displayedColumnsVisit: string[] = ['row-num', 'name', 'duration-time-unit', 'duration-time-value', 'baseline', 'operations'];
  displayedColumnsSite: string[] = ['row-num', 'site-code', 'site-name', 'project-name', 'operations'];
  sponsorCtrl = new FormControl('', [
    Validators.required
  ]);
  startDateCtrl = new FormControl('', [
    Validators.required
  ]);
  endDateCtrl = new FormControl('', [
    Validators.required
  ]);
  nameCtrl = new FormControl('', [
    Validators.required
  ]);
  iagCodeCtrl = new FormControl('DYN6_WEBINAR', [
    Validators.required
  ]);
  mailingListCtrl = new FormControl('', [
    Validators.required
  ]);
  siteCodeCtrl = new FormControl('', [
    Validators.required
  ]);
  siteCtrl = new FormControl('', [
    Validators.required
  ]);
  sequenceLabelCtrl = new FormControl('', [
    Validators.required
  ]);
  visitNameCtrl = new FormControl('', [
    Validators.required
  ]);
  readingSequenceLabelCtrl = new FormControl('', [
    Validators.required
  ]);
  readingReadersCtrl = new FormControl('', [
    Validators.required
  ]);
  visitDurationTimeUnitCtrl = new FormControl('', [
    Validators.required
  ]);
  visitDurationTimeValueCtrl = new FormControl('', [
    Validators.required
  ]);
  bucketShortCtrl = new FormControl('', [
    Validators.required
  ]);
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  scannerCtrl = new FormControl();
  filteredSponsors: Observable<any[]>;
  filteredSites: Observable<any[]>;
  filteredScanners: Observable<any[]>;
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  filteredReadingSequenceLabels: Observable<any[]>;
  filteredReaders: Observable<any[]>;

  canSaveVisitConfig = false;

  @ViewChild('sequenceLabelPaginator') sequenceLabelPaginator: MatPaginator;
  @ViewChild('sequenceLabelSort') sequenceLabelSort: MatSort;
  @ViewChild('visitPaginator') visitPaginator: MatPaginator;
  @ViewChild('visitSort') visitSort: MatSort;
  @ViewChild('sitePaginator') sitePaginator: MatPaginator;
  @ViewChild('readingPaginator') readingPaginator: MatPaginator;
  @ViewChild('readingSort') readingSort: MatSort;
  @ViewChild('siteSort') siteSort: MatSort;
  @ViewChild('edtfDataForm') edtfData: EdtfEditComponent;
  @ViewChild('scannerInput') scannerInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoScanner') matAutocompleteScanner: MatAutocomplete;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('autoReaders') matAutocompleteReaders: MatAutocomplete;
  @ViewChild('autoReadingSequenceLabel') matAutocompleteReadingSequenceLabel: MatAutocomplete;
  @ViewChild('readingSequenceLabelInput') readingSequenceLabelInput: ElementRef<HTMLInputElement>;
  @ViewChild('readingReadersInput') readingReadersInput: ElementRef<HTMLInputElement>;

  block1: any = {};
  block2: any = {};
  block3: any = {};

  genericBlockAnswer: string;
  genericBlock: any = {
    question: '',
    description: '',
    group: []
  };
  genericBlocks: any[] = [];
  imagingManualFile: any;

  DEFAULT_EDTF_DATA_TEMPLATE = {
    version: '1.1.0',
    blocks: []
  };

  visitConfig: any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private sponsorService: SponsorService,
              private studyService: StudyService,
              private siteService: SiteService,
              private studySequenceLabelService: StudySequenceLabelService,
              private readingConfigService: ReadingConfigService,
              private modalityService: ModalityService,
              private trialPhaseService: TrialPhaseService,
              private therapeuticAreaService: TherapeuticAreaService,
              private scannerService: ScannerService,
              private visitService: VisitService,
              private siteConfigService: SiteConfigService,
              private userService: UserService,
              private studyUserService: StudyUserService,
              private endpointService: EndpointService,
              private toastyService: ToastyService,
              private store: Store) {
    this.filteredScanners = this.scannerCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filterScanners(value) : this.scanners.slice()));
    this.filteredReadingSequenceLabels = this.readingSequenceLabelCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filterReadingSequenceLabels(value) : this.readingSequenceLabels.slice()));
    this.filteredReaders = this.readingReadersCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filterReaders(value) : this.readers.slice()));
  }

  ngOnInit() {
    this.genericBlockAnswer = '';
    this.studyId = parseInt(this.route.snapshot.params.id, 10);
    this.dataSourceSequenceLabel = new MatTableDataSource(this.studySequenceLabels);
    this.dataSourceSequenceLabel.paginator = this.sequenceLabelPaginator;
    this.dataSourceSequenceLabel.sort = this.sequenceLabelSort;
    this.dataSourceReading = new MatTableDataSource(this.readingConfigs);
    this.dataSourceReading.paginator = this.readingPaginator;
    this.dataSourceReading.sort = this.readingSort;
    if (this.studyId !== -1) {
      this.store.dispatch(new SetPageHeaderTitle('Create Imaging Project'));
      this.siteConfigService.getSiteConfigsByStudyId(this.studyId).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.siteConfigs = response.data;
            this.dataSourceSite = new MatTableDataSource(this.siteConfigs);
            this.dataSourceSite.paginator = this.sitePaginator;
            this.dataSourceSite.sort = this.siteSort;
            this.dataSourceSite.filterPredicate = (data: any, filter: string) => {
              return data.site.name.toLowerCase()
                .includes(filter.toLowerCase()) || data.siteCode.toLowerCase().includes(filter.toLowerCase());
            };
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
      this.studySequenceLabelService.getByStudyId(this.studyId).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.studySequenceLabels = response.data;
            this.dataSourceSequenceLabel.data = response.data;
            this.dataSourceSequenceLabel.filterPredicate = (data: any, filter: string) => {
              return data.sequenceLabel.name.toLowerCase()
                .includes(filter.toLowerCase()) || data.sequenceLabel.description.toLowerCase().includes(filter.toLowerCase());
            };
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
      this.studyService.getByIdWithVisitsAndAponsors(this.studyId).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.study = response.data;
            this.sponsorCtrl.setValue(this.study.sponsor);
            this.startDateCtrl.setValue(new Date(this.study.startDate));
            this.endDateCtrl.setValue(new Date(this.study.endDate));

            if (this.study.bucketLocation.indexOf('-eu') >= 0) {
              this.study.bucketShort = 'EU';
            } else if (this.study.bucketLocation.indexOf('-us') >= 0) {
              this.study.bucketShort = 'US';
            } else if (this.study.bucketLocation.indexOf('-asia') >= 0) {
              this.study.bucketShort = 'ASIA';
            }

            if (this.study.visits) {
              this.convertToEdtfVisit();
              this.dataSourceVisit = new MatTableDataSource(this.study.visits);
              this.dataSourceVisit.paginator = this.visitPaginator;
              this.dataSourceVisit.sort = this.visitSort;
              this.hasBaselineVisit = this.study.visits.filter(visit => visit.baseline === true).length > 0;
            }
            if (typeof this.study.eDTF === 'string') {
              this.study.eDTF = JSON.parse(this.study.eDTF);
            }
            if (this.study.eDTF) {
              this.block1 = this.study.eDTF.blocks[0];
              this.block2 = this.study.eDTF.blocks[this.study.eDTF.blocks.length - 2];
              this.block3 = this.study.eDTF.blocks[this.study.eDTF.blocks.length - 1];
              this.DEFAULT_EDTF_DATA_TEMPLATE.blocks = [];
              this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block1);
              for (let index = 1; index < this.study.eDTF.blocks.length - 2; index++) {
                this.genericBlocks.push(this.study.eDTF.blocks[index]);
              }
              this.genericBlocks.forEach(block => this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(block));
              this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block2);
              this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block3);
              this.edtfData.setEdtf(this.DEFAULT_EDTF_DATA_TEMPLATE);
            }

            this.therapeuticAreaService.getAll().subscribe((resp: any) => {
                if (resp.responseCode === ResponseCode.OK) {
                  this.therapeuticAreas = resp.data;
                  this.therapeuticAreas.forEach(therapeuticArea => {
                    if (therapeuticArea.name === this.study.therapeuticArea) {
                      this.therapeuticArea = therapeuticArea;
                    }
                  });
                } else {
                  this.toastOptions.title = 'ERROR ' + resp.responseCode;
                  this.toastOptions.msg = resp.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
      this.studyUserService.getReadersByStudyId(this.studyId).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.readers = response.data;
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
      this.readingConfigService.getAllReadingConfigsByStudyId(this.studyId).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.readingConfigs = response.data;
            this.dataSourceReading.data = response.data;
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.store.dispatch(new SetPageHeaderTitle('Edit Imaging Project'));
    }
    this.sponsorService.getAll().subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.sponsors = response.data;
          this.filteredSponsors = this.sponsorCtrl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    this.siteService.getAll().subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.sites = response.data;
          this.filteredSites = this.siteCtrl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filterSite(value))
            );
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    this.trialPhaseService.getAll().subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.trialPhases = response.data;
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    if (this.studyId === -1) {
      this.store.dispatch(new SetPageHeaderTitle('Create Imaging Project'));
      this.therapeuticAreaService.getAll().subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.therapeuticAreas = response.data;
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.store.dispatch(new SetPageHeaderTitle('Edit Imaging Project'));
    }
    this.modalityService.getAll().subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.modalities = response.data;
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    this.scannerService.getAll().subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.scanners = response.data;
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    this.endpointService.getAll().subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.endpoints = response.data;
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  private _filter(value: any): any[] {
    if (value instanceof Object || value === null) {
      return;
    }
    const filterValue = value.toLowerCase();
    return this.sponsors.filter(sponsor => sponsor.name.toLowerCase().includes(filterValue));
  }

  private _filterSite(value: any): any[] {
    if (value instanceof Object || value === null) {
      return;
    }
    const filterValue = value.toLowerCase();
    return this.sites.filter(site => site.name.toLowerCase().includes(filterValue));
  }

  navigateToProjects() {
    this.router.navigate(['/webinar/list']);
  }

  saveStudy(navigateToProjects: boolean) {
    this.study.therapeuticArea = this.therapeuticArea.name;
    this.study.visitsHypothesis = 1000;
    this.study.patientsHypothesis = 1000;
    this.study.sitesHypothesis = 1000;
    this.study.phase = "1";
    this.study.patientCodePattern = "CASE-%%%%";
    this.study.protocol = "DYN6_WEBINAR_";
    this.study.therapeuticArea="Oncology";
    this.study.disease="Cancer";
    this.study.drugMolecule ="No Drug";
    this.study.unscheduledAllowed=false;
    this.study.unscheduledLimit = 0;
    if (this.study.id) {
      this.studyService.update(this.study).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.study = response.data;
            this.toastOptions.title = '';
            this.toastOptions.msg = 'Webinar successfully updated';
            this.toastyService.success(this.toastOptions);
            if (navigateToProjects) {
              this.navigateToProjects();
            }
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.studyService.create(this.study).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.study = response.data;
            const userId = parseInt(JSON.parse(localStorage.getItem('userId')), 10);
            this.userService.getById(userId).subscribe((resp: any) => {
            //this.userService.getEmailUser(JSON.parse(localStorage.getItem('subject'))).subscribe((resp: any) => {
                if (resp.responseCode === ResponseCode.OK) {
                  this.user = resp.data;
                  this.studyUser.studyId = this.study.id;
                  this.studyUser.user = this.user;
                  this.studyUserService.create(this.studyUser).subscribe((studyResponse: any) => {
                      if (studyResponse.responseCode !== ResponseCode.OK) {
                        this.toastOptions.title = 'ERROR ' + studyResponse.responseCode;
                        this.toastOptions.msg = studyResponse.responseMessage;
                        this.toastyService.error(this.toastOptions);
                      }
                    }
                  );
                } else {
                  this.toastOptions.title = 'ERROR ' + resp.responseCode;
                  this.toastOptions.msg = resp.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
            this.toastOptions.title = '';
            this.toastOptions.msg = 'Webinar successfully created';
            this.toastyService.success(this.toastOptions);
            this.uploadImagingManualFileToCloud();
            if (navigateToProjects) {
              this.navigateToProjects();
            }
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  studyEmailListChange(event) {
    this.study.externalMailingList = event;
  }

  showSequenceLabels(element) {
    this.modality = {};
    this.showSequenceLabelTable = false;
    this.showSequenceLabel = true;
    this.sequenceLabelCtrl.clearValidators();
    this.sequenceLabelCtrl.setValue(null);
    this.studySequenceLabel = {};
    if (element) {
      this.studySequenceLabel = element;
      this.modality = this.modalities
        .filter(modality => modality.sequenceLabels
          .filter(sequenceLabel => sequenceLabel.id === this.studySequenceLabel.sequenceLabel.id).length > 0)[0];
    }
  }

  showVisits(element) {
    if(this.dataSourceVisit.data.length>=5){
      this.toastOptions.title = "ERROR: Maximum visit number";
      this.toastOptions.msg = "Maximum number of visit is up to 5";
      this.toastyService.error(this.toastOptions);
      return;
    }
    this.visit = {};
    this.visit.baseline = false;
    this.visit.repeatAllowed = false;
    this.showVisitTable = false;
    this.showVisit = true;
    this.visitNameCtrl.clearValidators();
    this.visitNameCtrl.setValue(null);
    this.visitDurationTimeUnitCtrl.clearValidators();
    this.visitDurationTimeUnitCtrl.setValue(null);
    this.visitDurationTimeValueCtrl.clearValidators();
    this.visitDurationTimeValueCtrl.setValue(null);
    if (element) {
      this.visit = element;
      this.visitNameCtrl.setValue(this.visit.name);
      this.visitDurationTimeUnitCtrl.setValue(this.visit.durationTimeUnit);
      this.visitDurationTimeValueCtrl.setValue(this.visit.durationTimeValue);
    }
  }

  showSites(element) {
    this.siteConfig = {
      scanners: [],
      site: {
        id: null
      }
    };
    this.showSiteTable = false;
    this.showSite = true;
    this.siteCodeCtrl.setValue(null);
    this.siteCtrl.setValue(null);
    this.scannerCtrl.clearValidators();
    this.scannerCtrl.setValue(null);
    if (element) {
      this.siteConfig = element;
      this.siteCtrl.setValue(this.siteConfig.site.id);
    }
  }

  compareSelectOption(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  hideSequenceLabels() {
    this.showSequenceLabelTable = true;
    this.showSequenceLabel = false;
  }

  hideVisits() {
    this.showVisitTable = true;
    this.showVisit = false;
  }

  hideSites() {
    this.showSiteTable = true;
    this.showSite = false;
  }

  applyFilterSequenceLabel(filterValue: string) {
    if (this.dataSourceSequenceLabel !== undefined) {
      this.dataSourceSequenceLabel.filter = filterValue.trim().toLowerCase();
      if (this.dataSourceSequenceLabel.paginator) {
        this.dataSourceSequenceLabel.paginator.firstPage();
      }
    }
  }

  applyFilterVisit(filterValue: string) {
    if (this.dataSourceVisit !== undefined) {
      this.dataSourceVisit.filter = filterValue.trim().toLowerCase();
      if (this.dataSourceVisit.paginator) {
        this.dataSourceVisit.paginator.firstPage();
      }
    }
  }

  applyFilterSite(filterValue: string) {
    if (this.dataSourceSite !== undefined) {
      this.dataSourceSite.filter = filterValue.trim().toLowerCase();
      if (this.dataSourceSite.paginator) {
        this.dataSourceSite.paginator.firstPage();
      }
    }
  }

  showLabelParameters(parameters) {
    this.toastOptions.msg = JSON.stringify(parameters);
    this.toastyService.info(this.toastOptions);
  }

  deleteSequenceLabel(element) {
    this.studySequenceLabelService.delete(element.id).subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.studySequenceLabelService.getByStudyId(this.study.id).subscribe((resp: any) => {
              if (resp.responseCode === ResponseCode.OK) {
                this.studySequenceLabels = resp.data;
                this.dataSourceSequenceLabel.data = resp.data;
              } else {
                this.toastOptions.title = 'ERROR ' + resp.responseCode;
                this.toastOptions.msg = resp.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
          this.toastOptions.title = '';
          this.toastOptions.msg = 'Sequence label successfully deleted.';
          this.toastyService.success(this.toastOptions);
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  deleteVisit(element) {
    this.visitService.delete(element.id).subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.studyService.getByIdWithVisitsAndAponsors(this.study.id).subscribe((resp: any) => {
              if (resp.responseCode === ResponseCode.OK) {
                this.study = resp.data;
                this.dataSourceVisit.data = this.study.visits;
                this.hasBaselineVisit = this.study.visits.filter(visit => visit.baseline === true).length > 0;
              } else {
                this.toastOptions.title = 'ERROR ' + resp.responseCode;
                this.toastOptions.msg = resp.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
          this.toastOptions.title = '';
          this.toastOptions.msg = 'Visit successfully deleted.';
          this.toastyService.success(this.toastOptions);
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  deleteSiteConfig(element) {
    this.siteConfigService.delete(element.id).subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.siteConfigService.getSiteConfigsByStudyId(this.study.id).subscribe((siteConfigResponse: any) => {
              if (siteConfigResponse.responseCode === ResponseCode.OK) {
                this.siteConfigs = siteConfigResponse.data;
                this.dataSourceSite.data = this.siteConfigs;
                this.toastOptions.title = '';
                this.toastOptions.msg = 'Site configuration successfully deleted';
                this.toastyService.success(this.toastOptions);
                this.studyService.getByIdWithVisitsAndAponsors(this.study.id).subscribe((studyResponse: any) => {
                    if (studyResponse.responseCode === ResponseCode.OK) {
                      this.study = studyResponse.data;
                      this.saveStudy(false);
                    } else {
                      this.toastOptions.title = 'ERROR ' + studyResponse.responseCode;
                      this.toastOptions.msg = studyResponse.responseMessage;
                      this.toastyService.error(this.toastOptions);
                    }
                  }
                );
              } else {
                this.toastOptions.title = 'ERROR ' + siteConfigResponse.responseCode;
                this.toastOptions.msg = siteConfigResponse.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  saveSiteConfig() {
    if (!this.study.id) {
      this.saveStudy(false);
    }
    if (this.siteConfig.id) {
      this.siteConfig.study = this.study;
      this.siteConfigService.update(this.siteConfig).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.siteConfigService.getSiteConfigsByStudyId(this.study.id).subscribe((resp: any) => {
                if (resp.responseCode === ResponseCode.OK) {
                  this.siteConfigs = resp.data;
                  this.dataSourceSite.data = this.siteConfigs;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'Site configuration successfully updated';
                  this.toastyService.success(this.toastOptions);
                  this.hideSites();
                } else {
                  this.toastOptions.title = 'ERROR ' + resp.responseCode;
                  this.toastOptions.msg = resp.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.siteConfig.study = this.study;
      this.siteConfigService.create(this.siteConfig).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.siteConfig = response.data;
            this.saveStudy(false);
            this.siteConfigService.getSiteConfigsByStudyId(this.study.id).subscribe((resp: any) => {
                if (resp.responseCode === ResponseCode.OK) {
                  this.siteConfigs = resp.data;
                  this.dataSourceSite.data = this.siteConfigs;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'Site configuration successfully created';
                  this.toastyService.success(this.toastOptions);
                  this.hideSites();
                } else {
                  this.toastOptions.title = 'ERROR ' + resp.responseCode;
                  this.toastOptions.msg = resp.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  studySiteConfigEmailListChange(event) {
    this.siteConfig.externalMailingList = event;
  }

  cancelSequenceLabel() {
    this.studySequenceLabelService.getByStudyId(this.studyId).subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.studySequenceLabels = response.data;
          this.dataSourceSequenceLabel.data = response.data;
          this.dataSourceSequenceLabel.filterPredicate = (data: any, filter: string) => {
            return data.sequenceLabel.name.toLowerCase()
              .includes(filter.toLowerCase()) || data.sequenceLabel.description.toLowerCase().includes(filter.toLowerCase());
          };
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
        this.hideSequenceLabels();
      }
    );
  }

  studySequencLabelParametersChange(event) {
    this.studySequenceLabel['parameters'] = event;
  }

  saveSequenceLabel() {
    if (!this.study.id) {
      this.saveStudy(false);
    }
    if (this.studySequenceLabel.id) {
      this.studySequenceLabel.study = this.study;
      this.studySequenceLabelService.update(this.studySequenceLabel).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.studySequenceLabelService.getByStudyId(this.study.id).subscribe((resp: any) => {
                if (resp.responseCode === ResponseCode.OK) {
                  this.studySequenceLabels = resp.data;
                  this.dataSourceSequenceLabel.data = resp.data;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'Sequence label successfully updated';
                  this.toastyService.success(this.toastOptions);
                  this.hideSequenceLabels();
                } else {
                  this.toastOptions.title = 'ERROR ' + resp.responseCode;
                  this.toastOptions.msg = resp.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.studySequenceLabel.study = this.study;
      this.studySequenceLabelService.create(this.studySequenceLabel).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.studySequenceLabelService.getByStudyId(this.study.id).subscribe((resp: any) => {
                if (resp.responseCode === ResponseCode.OK) {
                  this.studySequenceLabels = resp.data;
                  this.dataSourceSequenceLabel.data = resp.data;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'Sequence label successfully created';
                  this.toastyService.success(this.toastOptions);
                  this.hideSequenceLabels();
                } else {
                  this.toastOptions.title = 'ERROR ' + resp.responseCode;
                  this.toastOptions.msg = resp.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  onDurationTimeUnitChange(event) {
    this.visit.durationTimeUnit = event.value;
    this.validateVisitConfig();
  }

  onDurationTimeValueChange(event) {
    this.visit.durationTimeValue = 0;
    const newValue = parseInt(this.visitDurationTimeValueCtrl.value, 10);
    // check to see if the control value is no a number
    if (isNaN(this.visitDurationTimeValueCtrl.value)) {
      this.visitDurationTimeValueCtrl.setValue('');
    } else {

      this.visit.durationTimeValue = newValue;
    }
    this.validateVisitConfig();
  }

  onVisitNameChange(event) {
    this.visit.name = this.visitNameCtrl.value;
    this.validateVisitConfig();
  }

  validateVisitConfig() {

    this.canSaveVisitConfig = (this.visit.name ? this.visit.name.length > 0 : false) &&
      (this.visit.durationTimeValue >= 0) &&
      (this.visit.durationTimeUnit.length > 0);
  }

  saveVisit() {
    if (!this.study.id) {
      this.saveStudy(false);
    }
    if (this.visit.id) {
      this.visitService.update(this.visit).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.studyService.getByIdWithVisitsAndAponsors(this.study.id).subscribe((resp: any) => {
                if (resp.responseCode === ResponseCode.OK) {
                  this.study = resp.data;
                  this.dataSourceVisit.data = this.study.visits;
                  this.hasBaselineVisit = this.study.visits.filter(visit => visit.baseline === true).length > 0;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'Visit successfully updated';
                  this.toastyService.success(this.toastOptions);
                  this.hideVisits();
                } else {
                  this.toastOptions.title = 'ERROR ' + resp.responseCode;
                  this.toastOptions.msg = resp.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.visit.studies = [];
      this.visit.studies.push(this.study);
      this.visitService.create(this.visit, this.studyId).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.studyService.getByIdWithVisitsAndAponsors(this.study.id).subscribe((resp: any) => {
                if (resp.responseCode === ResponseCode.OK) {
                  this.study = resp.data;
                  this.dataSourceVisit.data = this.study.visits;
                  this.hasBaselineVisit = this.study.visits.filter(visit => visit.baseline === true).length > 0;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'Visit successfully created';
                  this.toastyService.success(this.toastOptions);
                  this.hideVisits();
                } else {
                  this.toastOptions.title = 'ERROR ' + resp.responseCode;
                  this.toastOptions.msg = resp.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  onUnscheduledLimitChange() {
    this.saveStudy(false);
  }

  convertToEdtfVisit() {
    this.study.visits.forEach(visit => {
      this.eDtfVisits.push({
        config: {
          visitConfigsId: visit.id,
          name: visit.name
        }
      });
    });
  }

  showEdtfConfigs() {
    this.showEdtfConfig = true;
  }

  hideEdtfConfig() {
    this.showEdtfConfig = false;
    this.genericBlock = {
      question: '',
      description: '',
      group: []
    };
  }

  addGenericBlockAnswer() {
    this.genericBlock.group.push({
      question: this.genericBlockAnswer,
      checkbox: {
        value: false,
      },
    });
    this.genericBlockAnswer = '';
  }

  addEdtfConfig() {
    if (this.study.visits && this.eDtfVisits.length === 0) {
      this.convertToEdtfVisit();
    }
    this.genericBlocks.push(this.genericBlock);
    this.DEFAULT_EDTF_DATA_TEMPLATE.blocks = [];
    this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block1);
    this.genericBlocks.forEach(block => this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(block));
    this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block2);
    this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block3);
    this.edtfData.setEdtf(this.DEFAULT_EDTF_DATA_TEMPLATE);
    this.hideEdtfConfig();
  }

  saveEdtfConfig() {
    this.study.eDTF = JSON.stringify(this.DEFAULT_EDTF_DATA_TEMPLATE);
    this.saveStudy(false);
    this.genericBlocks = [];
    this.studyService.getByIdWithVisitsAndAponsors(this.studyId).subscribe((response: any) => {
        if (response.responseCode === ResponseCode.OK) {
          this.study = response.data;
          if (this.study.visits) {
            this.convertToEdtfVisit();
          }
          this.block1 = this.study.eDTF.blocks[0];
          this.block2 = this.study.eDTF.blocks[this.study.eDTF.blocks.length - 2];
          this.block3 = this.study.eDTF.blocks[this.study.eDTF.blocks.length - 1];
          this.DEFAULT_EDTF_DATA_TEMPLATE.blocks = [];
          this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block1);
          for (let index = 1; index < this.study.eDTF.blocks.length - 2; index++) {
            this.genericBlocks.push(this.study.eDTF.blocks[index]);
          }
          this.genericBlocks.forEach(block => this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(block));
          this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block2);
          this.DEFAULT_EDTF_DATA_TEMPLATE.blocks.push(this.block3);
          this.edtfData.setEdtf(this.DEFAULT_EDTF_DATA_TEMPLATE);
          this.hideEdtfConfig();
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  private _filterScanners(value: any): any[] {
    if (value instanceof Object || value === null) {
      return;
    }
    const filterValue = value.toLowerCase();
    return this.scanners
      .filter(scanner => (scanner.manufacturer + scanner.model + scanner.version).toLowerCase().indexOf(filterValue) === 0);
  }


  private _filterReadingSequenceLabels(value: any): any[] {
    if (value instanceof Object || value === null) {
      return;
    }
    const filterValue = value.toLowerCase();
    return this.readingSequenceLabels.filter(readingSequenceLabel => (readingSequenceLabel.name).toLowerCase().indexOf(filterValue) === 0);
  }

  private _filterReaders(value: any): any[] {
    if (value instanceof Object || value === null) {
      return;
    }
    const filterValue = value.toLowerCase();
    return this.readers.filter(reader => (reader.firstName + reader.lastName + reader.userName).toLowerCase().indexOf(filterValue) === 0);
  }

  removeScanner(scanner: string): void {
    const index = this.siteConfig.scanners.indexOf(scanner);
    if (index >= 0) {
      this.siteConfig.scanners.splice(index, 1);
    }
  }

  addScanner(event: MatChipInputEvent): void {
    if (!this.matAutocompleteScanner.isOpen) {
      const input = event.input;
      if (input) {
        input.value = '';
      }
      this.scannerCtrl.setValue(null);
    }
  }

  selectedScanner(event: MatAutocompleteSelectedEvent): void {
    this.siteConfig.scanners.push(event.option.value);
    this.scannerInput.nativeElement.value = '';
    this.scannerCtrl.setValue(null);
  }

  selectFiles(event) {
    if (event.target.files) {
      this.imagingManualFile = event.target.files[0];
    }
    this.uploadImagingManualFileToCloud();
    event.preventDefault();
  }

  uploadImagingManualFileToCloud() {
    if (this.study.id && this.imagingManualFile) {
      this.studyService.getImagingManualUploadUrl(this.study.id).subscribe((response: any) => {
          if (response.responseCode === ResponseCode.OK) {
            this.studyService.uploadFileToCloud(response.data, this.imagingManualFile).subscribe((resp: any) => {
                this.toastOptions.title = '';
                this.toastOptions.msg = 'Imaging manual file successfully uploded';
                this.toastyService.success(this.toastOptions);
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  compareSponsors(s1, s2) {
      return s1 && s2 ? s1.id === s2.id : s1 === s2;
    // return s1.id === s2.id;
  }

}
