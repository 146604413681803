import { Component, Input , OnInit } from '@angular/core';

@Component({
  selector: 'app-drag-drop-files',
  templateUrl: './drag-drop-files.component.html',
  styleUrls: ['./drag-drop-files.component.css'],
  preserveWhitespaces: true
})
export class DragDropFilesComponent implements OnInit {
  emptyFileContainer=true;
  dropOver=false;
  files = new Array();
  _zipOnly = true;

  @Input('title') areaTitle: string;
  @Input('multiselect') multiselect:boolean;
  @Input('disabled') disabled: boolean;


  @Input('zipOnly')
  set parameters(value: boolean) {
    if(value ==  null)
      value = true;
    this._zipOnly = value;
  }
  get parameters(): boolean {
    return this._zipOnly;
  }

  showZipOnlyWarning = false;

  constructor() {
    this.showZipOnlyWarning = false;
    this._zipOnly = true;
  }

  ngOnInit() {
  }

  readFiles(files){
    this.showZipOnlyWarning = false;
    for (var i = 0; i < files.length; i++) {
      if(this._zipOnly == true) {
        let fileName = files[i].name;
        if(fileName.toLowerCase().endsWith(".zip") == true) {
          this.files.push(files[i]);
        }
        else {
          this.showZipOnlyWarning = true;
        }
      }
      else {
        this.files.push(files[i]);
      };
    }
  }

  dragOver(e){
    if(this.disabled != true) {
      this.dropOver = true;
    }
    return false;
  }

  dragEnded(){
    if(this.disabled != true) {
      this.dropOver = false;
    }
  }

  dragLeave(){
    if(this.disabled != true) {
      this.dropOver = false;
    }
  }

  dropFile(e) {
    if (this.disabled !== true) {
      e.preventDefault();
      e.stopPropagation();
      this.dropOver = false;
      this.readFiles(e.dataTransfer.files);
    }
    return false;
  }

  clearFiles(event) {
    this.files = [];
  }

  selectFiles(event) {
    this.showZipOnlyWarning = false;
    if (event.target.files) {
      for (var i = 0; i < event.target.files.length; i++) {
        if (this._zipOnly === true) {
          let fileName = event.target.files[i].name;
          if (fileName.toLowerCase().endsWith('.zip') === true) {
            this.files.push(event.target.files[i]);
          } else {
            this.showZipOnlyWarning = true;
          }
        } else {
          this.files.push(event.target.files[i]);
        }
      }
    }
    event.preventDefault();
    event.target.value = '';
  }
}
