import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-kl-level1-result',
  templateUrl: './kl-level1-result.component.html',
  styleUrls: ['./kl-level1-result.component.css']
})
export class KlLevel1ResultComponent implements OnInit {

  @Input() readings: {
    rightScore: number, leftScore: number, rightKneePredominantlyMedial: boolean,
    leftKneePredominantlyMedial: boolean, rightKneePredominantlyLateral: boolean,
    leftKneePredominantlyLateral: boolean, rightKneeMalalginment: boolean,
    leftKneeMalalignment: boolean, comment: string
  }[] & any[];

  @Input() enableOAPredominantly: boolean;
  @Input() enableOAPredominantlyLateral: boolean;
  @Input() enableKneeMalalaignment: boolean;

  constructor() {
  }

  ngOnInit(): void {
  }

}
