import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSQueriesVersion;
const SERVICE = 'msqueries-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class QueriesChatlogService {

  constructor(private http: HttpClient) { }


  createMessage(studyId:number, queriesId: number, userId: number, comment: string ) {
    const message = {
      userId: userId,
      comment: comment,
      queries: {
        id: queriesId,
      }
    };
    return this.http.post(requestURL + '/chat-log/' + studyId, message);
  }

  getChatlogByQueriesId(queriesId: number, studyId: number){
    return this.http.get(requestURL + '/chat-log/get-chat-log-by-queries-id/' + queriesId + '/' + studyId);
  }
}
