import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AccessPermissionsService} from '../_services/access-permissions.service';
import {Path} from '../core/constants/path';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router,
              private accessPermissionsService: AccessPermissionsService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean | UrlTree {
    if (localStorage.getItem('maintenance')) {
      if (true === JSON.parse(localStorage.getItem('maintenance'))) {
        window.location.href = '/maintenance';
        return false;
      }
    }

    if (!localStorage.getItem('currentUser')) {
      window.location.href = '/login?returnUrl=' + state.url;
      return false;
    }

    for (const pathKey of Object.keys(Path)) {
      if (this.routerContainsPath(route, Path[pathKey])) {
        this.redirectIfNeeded(Path[pathKey]);
        return this.hasPermission(Path[pathKey]);
      }
    }
    return true;
  }

  private hasPermission(path: Path) {
    return this.accessPermissionsService.permissions[path] ?? false;
  }

  private routerContainsPath(route: ActivatedRouteSnapshot, path: string) {
    const pathSegments = path.split('/');
    return pathSegments.every(segment => route.url.map(segment_ => segment_.path).includes(segment));
  }

  private redirectIfNeeded(path: Path) {
    if (!this.hasPermission(path) && !!this.accessPermissionsService.redirect(path)) {
      window.location.href = `/${this.accessPermissionsService.redirect(path)}`;
    }
  }
}
