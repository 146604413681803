import { FormGroup } from '@angular/forms';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlexibleConfig} from '../../../../core/interfaces/flexible-config';
import {FormMode} from '../../../../core/constants/form-mode';
import { ModalityModel } from 'src/app/_models/ImagingProject/modality-model';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import {DXAConfigModel, DXAVisitModel} from '../../../../_models/ImagingProject/DXA/dxa-config-model';
import {FormControl, Validators, AbstractControl, ValidationErrors,} from '@angular/forms';
import {ImagingProjectReadingBasicComponent} from '../../imaging-project-reading-basic.component';
import {MatDialog} from '@angular/material/dialog';
import {SequenceLabelModel} from '../../../../_models/ImagingProject/sequence-label-model';
import {BatchConfigModel} from '../../../../_models/BatchLogic/batch-config-model';
import {AutoBatchConfigPayload} from '../../../../core/payload/auto-batch-config-payload';
import {
  defaultBasicAutoBatchConfig, ImagingProjectSubmitEvent
} from '../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {
  AutoCreationBatchConfigDialogComponent
} from '../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {ReadingType} from '../../../../core/constants/reading-type';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute} from '@angular/router';
import {StudySequenceLabelService, StudyService, StudyUserService} from '../../../../_services';
import {VisitType} from "../../../../_models/ImagingProject/patient-model";
import * as moment from "moment";
import {forkJoin} from "rxjs";
import {BatchLogicService} from "../../../../_services/batch-logic.service";
import { VisitModalityConfiguratorDialogComponent } from '../visit-modality-configurator-dialog/visit-modality-configurator-dialog.component';


@Component({
  selector: 'app-imaging-project-reading-dxa',
  templateUrl: 'imaging-project-reading-dxa.component.html',
  styleUrls: ['imaging-project-reading-dxa.component.css']
})
export class ImagingProjectReadingDxaComponent implements OnInit {
  // @Inputs and @Outputs
  @Input('configModel')
  set configModel(value: any) {
    if (value != null) {
      this.configForm.patchValue(value);
    }
  }
  get configModel(): any {
    return this.configForm;
  }
  @Input() selectedConfig: FlexibleConfig<DXAConfigModel>;
  @Input() public mode: FormMode;
  @Output() public clickCancel = new EventEmitter<any>();
  @Output() public clickSubmit = new EventEmitter<any>();
  // end @Inputs and @Outputs

  // Models and Variables
  readonly formModes = FormMode;
  readonly EARLY_TERMINATION_VISIT = 'Early Termination';
  studyId: number;
  dxaConfig: FormGroup =  new FormGroup( {
    DXAWholeBody: new FormControl('All_Regions', [Validators.required]),
    DXAWholeBodyIsBMDResult: new FormControl(true, [Validators.required]),
    DXAWholeBodyIsIQCQuestion: new FormControl(true, [Validators.required]),
    DXABMDbodyRegions: new FormControl(['Hip'], [Validators.required]),
    DXABMDIsTScoreCalculation: new FormControl(true, [Validators.required]),
    DXABMDIsZScoreCalculation: new FormControl(false, [Validators.required]),
    DXABMDIsLumbarVertaAnalysis: new FormControl(true, [Validators.required]),
    DXABMDIsIQCQuestion: new FormControl(true, [Validators.required]),
  });
  configForm: FormGroup = new FormGroup({
    endpointReadingVersion: new FormControl('basic'),
    modalities: new FormControl([], [Validators.required]),
    readingLevel: new FormControl('visit', [Validators.required]),
    readingType: new FormControl('parallel', [Validators.required]),
    hideVisitHistory: new FormControl('hide_scan_dates', [Validators.required]),
    readers: new FormControl([], [Validators.required]),
    readingVersion: new FormControl(ReadingVersion.DXA_SIMPLE_READ, [Validators.required]),
    batchManagement: new FormControl(false, [Validators.required, this.batchManagementValidator]),
    imageViewerConfiguration: new FormControl('eCRF_Only'),
    endpoint: new FormControl(null),
    anatomySelector: new FormControl([], [Validators.required]),
    dxaConfig: this.dxaConfig,

    // DXA2.0 scores map
    TZScoreCalculationReferencePopulation: new FormGroup({
      holigic: new FormGroup({
        femaleSpineCuacusian: new FormGroup({
          TScoreRef: new FormControl(1.047),
          sigma: new FormControl(0.110),
          yr25: new FormControl(),
          yr35: new FormControl(),
          yr45: new FormControl(),
          yr55: new FormControl(),
          yr65: new FormControl(),
          yr75: new FormControl(),
          yr85: new FormControl(),
        }),
        femaleSpineBlack: new FormGroup({
          TScoreRef: new FormControl(1.150),
          sigma: new FormControl(0.110),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleSpineAsian: new FormGroup({
          TScoreRef: new FormControl(1.047),
          sigma: new FormControl(0.110),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleHipCuacusian: new FormGroup({
          TScoreRef: new FormControl(0.942),
          sigma: new FormControl(0.122),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleHipBlack: new FormGroup({
          TScoreRef: new FormControl(1.031),
          sigma: new FormControl(0.156),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleHipAsian: new FormGroup({
          TScoreRef: new FormControl(0.962),
          sigma: new FormControl(0.134),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleFemNeckCuacusian: new FormGroup({
          TScoreRef: new FormControl(0.849),
          sigma: new FormControl(0.111),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleFemNeckBlack: new FormGroup({
          TScoreRef: new FormControl(0.951),
          sigma: new FormControl(0.142),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleFemNeckAsian: new FormGroup({
          TScoreRef: new FormControl(0.874),
          sigma: new FormControl(0.118),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleForearmCuacusian: new FormGroup({
          TScoreRef: new FormControl(0.579),
          sigma: new FormControl(0.054),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleForearmBlack: new FormGroup({
          TScoreRef: new FormControl(0.579),
          sigma: new FormControl(0.054),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleForearmAsian: new FormGroup({
          TScoreRef: new FormControl(0.579),
          sigma: new FormControl(0.054),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleTotalBodyCuacusian: new FormGroup({
          TScoreRef: new FormControl(1.106),
          sigma: new FormControl(0.081),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleTotalBodyBlack: new FormGroup({
          TScoreRef: new FormControl(1.181),
          sigma: new FormControl(0.091),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleTotalBodyAsian: new FormGroup({
          TScoreRef: new FormControl(1.097),
          sigma: new FormControl(0.081),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleSpineCuacusian: new FormGroup({
          TScoreRef: new FormControl(1.091),
          sigma: new FormControl(0.110),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleSpineBlack: new FormGroup({
          TScoreRef: new FormControl(1.197),
          sigma: new FormControl(0.110),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleSpineAsian: new FormGroup({
          TScoreRef: new FormControl(1.091),
          sigma: new FormControl(0.110),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleHipCuacusian: new FormGroup({
          TScoreRef: new FormControl(1.033),
          sigma: new FormControl(0.151),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleHipBlack: new FormGroup({
          TScoreRef: new FormControl(1.177),
          sigma: new FormControl(0.172),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleHipAsian: new FormGroup({
          TScoreRef: new FormControl(1.055),
          sigma: new FormControl(0.132),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleFemNeckCuacusian: new FormGroup({
          TScoreRef: new FormControl(0.930),
          sigma: new FormControl(0.136),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleFemNeckBlack: new FormGroup({
          TScoreRef: new FormControl(1.073),
          sigma: new FormControl(0.156),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleFemNeckAsian: new FormGroup({
          TScoreRef: new FormControl(0.977),
          sigma: new FormControl(0.131),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleForearmCuacusian: new FormGroup({
          TScoreRef: new FormControl(0.687),
          sigma: new FormControl(0.052),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleForearmBlack: new FormGroup({
          TScoreRef: new FormControl(0.687),
          sigma: new FormControl(0.052),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleForearmAsian: new FormGroup({
          TScoreRef: new FormControl(0.687),
          sigma: new FormControl(0.052),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleTotalBodyCuacusian: new FormGroup({
          TScoreRef: new FormControl(1.196),
          sigma: new FormControl(0.100),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleTotalBodyBlack: new FormGroup({
          TScoreRef: new FormControl(1.281),
          sigma: new FormControl(0.117),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleTotalBodyAsian: new FormGroup({
          TScoreRef: new FormControl(1.156),
          sigma: new FormControl(0.095),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
      }),
      ge: new FormGroup({
        femaleTotalBody: new FormGroup({
          TScoreRef: new FormControl(1.080),
          sigma: new FormControl(0.100),
          yr25: new FormControl(1.0800),
          yr35: new FormControl(1.0800),
          yr45: new FormControl(1.0800),
          yr55: new FormControl(1.0170),
          yr65: new FormControl(0.9540),
          yr75: new FormControl(0.9290),
          yr85: new FormControl(0.9040),
        }),
        femaleForearm: new FormGroup({
          TScoreRef: new FormControl(0.6830),
          sigma: new FormControl(0.061),
          yr25: new FormControl(0.6830),
          yr35: new FormControl(0.6830),
          yr45: new FormControl(0.6830),
          yr55: new FormControl(0.6550),
          yr65: new FormControl(0.6000),
          yr75: new FormControl(0.5450),
          yr85: new FormControl(0.4890),
        }),
        femaleFemNeck: new FormGroup({
          TScoreRef: new FormControl(1.038),
          sigma: new FormControl(0.139),
          yr25: new FormControl(1.0380),
          yr35: new FormControl(0.9990),
          yr45: new FormControl(0.9600),
          yr55: new FormControl(0.898),
          yr65: new FormControl(0.8360),
          yr75: new FormControl(0.772),
          yr85: new FormControl(0.708),
        }),
        femaleHip: new FormGroup({
          TScoreRef: new FormControl(1.007),
          sigma: new FormControl(0.126),
          yr25: new FormControl(1.0080),
          yr35: new FormControl(0.9900),
          yr45: new FormControl(0.9720),
          yr55: new FormControl(0.9270),
          yr65: new FormControl(0.8580),
          yr75: new FormControl(0.7900),
          yr85: new FormControl(0.7220),
        }),
        femaleL1L2: new FormGroup({
          TScoreRef: new FormControl(1.150),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleL1L3: new FormGroup({
          TScoreRef: new FormControl(1.170),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleL1L4: new FormGroup({
          TScoreRef: new FormControl(1.180),
          sigma: new FormControl(0.120),
          yr25: new FormControl(1.1800),
          yr35: new FormControl(1.1800),
          yr45: new FormControl(1.1800),
          yr55: new FormControl(1.0850),
          yr65: new FormControl(0.9900),
          yr75: new FormControl(0.9700),
          yr85: new FormControl(0.9500),
        }),
        femaleL2L3: new FormGroup({
          TScoreRef: new FormControl(1.200),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleL2L4: new FormGroup({
          TScoreRef: new FormControl(1.200),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        femaleL3L4: new FormGroup({
          TScoreRef: new FormControl(1.200),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleTotalBody: new FormGroup({
          TScoreRef: new FormControl(1.200),
          sigma: new FormControl(0.100),
          yr25: new FormControl(1.2000),
          yr35: new FormControl(1.2000),
          yr45: new FormControl(1.2000),
          yr55: new FormControl(1.2000),
          yr65: new FormControl(1.1690),
          yr75: new FormControl(1.1370),
          yr85: new FormControl(1.1060),
        }),
        maleForearm: new FormGroup({
          TScoreRef: new FormControl(0.762),
          sigma: new FormControl(0.069),
          yr25: new FormControl(0.7620),
          yr35: new FormControl(0.0691),
          yr45: new FormControl(0.7620),
          yr55: new FormControl(0.7490),
          yr65: new FormControl(0.7210),
          yr75: new FormControl(0.6940),
          yr85: new FormControl(0.6670),
        }),
        maleFemNeck: new FormGroup({
          TScoreRef: new FormControl(1.070),
          sigma: new FormControl(0.130),
          yr25: new FormControl(1.0900),
          yr35: new FormControl(1.0500),
          yr45: new FormControl(1.0100),
          yr55: new FormControl(0.9700),
          yr65: new FormControl(0.9300),
          yr75: new FormControl(0.8900),
          yr85: new FormControl(0.8500),
        }),
        maleHip: new FormGroup({
          TScoreRef: new FormControl(1.101),
          sigma: new FormControl(0.144),
          yr25: new FormControl(1.1010),
          yr35: new FormControl(1.0820),
          yr45: new FormControl(1.0620),
          yr55: new FormControl(1.0430),
          yr65: new FormControl(1.0240),
          yr75: new FormControl(0.9750),
          yr85: new FormControl(0.9050),
        }),
        maleL1L2: new FormGroup({
          TScoreRef: new FormControl(1.200),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleL1L3: new FormGroup({
          TScoreRef: new FormControl(1.210),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleL1L4: new FormGroup({
          TScoreRef: new FormControl(1.220),
          sigma: new FormControl(0.120),
          yr25: new FormControl(1.2200),
          yr35: new FormControl(1.2200),
          yr45: new FormControl(1.2100),
          yr55: new FormControl(1.1890),
          yr65: new FormControl(1.1680),
          yr75: new FormControl(1.1470),
          yr85: new FormControl(1.1260),
        }),
        maleL2L3: new FormGroup({
          TScoreRef: new FormControl(1.240),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleL2L4: new FormGroup({
          TScoreRef: new FormControl(1.240),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
        maleL3L4: new FormGroup({
          TScoreRef: new FormControl(1.240),
          sigma: new FormControl(0.120),
          yr25: new FormControl(null),
          yr35: new FormControl(null),
          yr45: new FormControl(null),
          yr55: new FormControl(null),
          yr65: new FormControl(null),
          yr75: new FormControl(null),
          yr85: new FormControl(null),
        }),
      })
    }),
    // DXA1.0 scores map
    referencePopulation: new FormGroup({
      holigic: new FormGroup({
        cuacusianFemale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.047),
          lSpinSigma: new FormControl(0.110),
          hipPeakBMD: new FormControl(0.942),
          hipSigma: new FormControl(0.122),
          femNeckPeakBMD: new FormControl(0.849),
          femNeckSigma: new FormControl(0.111),
        }),
        blackFemale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.150),
          lSpinSigma: new FormControl(0.110),
          hipPeakBMD: new FormControl(1.031),
          hipSigma: new FormControl(0.156),
          femNeckPeakBMD: new FormControl(0.951),
          femNeckSigma: new FormControl(0.142),
        }),
        asianFemale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.047),
          lSpinSigma: new FormControl(0.110),
          hipPeakBMD: new FormControl(0.962),
          hipSigma: new FormControl(0.134),
          femNeckPeakBMD: new FormControl(0.874),
          femNeckSigma: new FormControl(0.118),
        }),
        cuacusianMale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.091),
          lSpinSigma: new FormControl(0.110),
          hipPeakBMD: new FormControl(1.033),
          hipSigma: new FormControl(0.151),
          femNeckPeakBMD: new FormControl(0.930),
          femNeckSigma: new FormControl(0.136),
        }),
        blackMale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.197),
          lSpinSigma: new FormControl(0.110),
          hipPeakBMD: new FormControl(1.177),
          hipSigma: new FormControl(0.172),
          femNeckPeakBMD: new FormControl(1.073),
          femNeckSigma: new FormControl(0.156),
        }),
        asianMale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.091),
          lSpinSigma: new FormControl(0.110),
          hipPeakBMD: new FormControl(1.055),
          hipSigma: new FormControl(0.132),
          femNeckPeakBMD: new FormControl(0.977),
          femNeckSigma: new FormControl(0.131),
        }),
        referencePopulationDataset: new FormControl("Spine Hologic & Hip NHANES",[Validators.required])
      }),
      ge: new FormGroup({
        cuacusianFemale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.180),
          lSpinSigma: new FormControl(0.120),
          hipPeakBMD: new FormControl(1.007),
          hipSigma: new FormControl(0.126),
          femNeckPeakBMD: new FormControl(1.038),
          femNeckSigma: new FormControl(0.139),
        }),
        blackFemale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.180),
          lSpinSigma: new FormControl(0.120),
          hipPeakBMD: new FormControl(1.007),
          hipSigma: new FormControl(0.126),
          femNeckPeakBMD: new FormControl(1.038),
          femNeckSigma: new FormControl(0.139),
        }),
        asianFemale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.180),
          lSpinSigma: new FormControl(0.120),
          hipPeakBMD: new FormControl(1.007),
          hipSigma: new FormControl(0.126),
          femNeckPeakBMD: new FormControl(1.038),
          femNeckSigma: new FormControl(0.139),
        }),
        cuacusianMale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.220),
          lSpinSigma: new FormControl(0.120),
          hipPeakBMD: new FormControl(1.101),
          hipSigma: new FormControl(0.144),
          femNeckPeakBMD: new FormControl(1.070),
          femNeckSigma: new FormControl(0.130),
        }),
        blackMale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.220),
          lSpinSigma: new FormControl(0.120),
          hipPeakBMD: new FormControl(1.101),
          hipSigma: new FormControl(0.144),
          femNeckPeakBMD: new FormControl(1.070),
          femNeckSigma: new FormControl(0.130),
        }),
        asianMale: new FormGroup({
          lSpinPeakBMD: new FormControl(1.220),
          lSpinSigma: new FormControl(0.120),
          hipPeakBMD: new FormControl(1.101),
          hipSigma: new FormControl(0.144),
          femNeckPeakBMD: new FormControl(1.070),
          femNeckSigma: new FormControl(0.130),
        }),
        referencePopulationDataset: new FormControl("Spine Lunar & Hip NHANES", [Validators.required])
      })
    }),
    visit: new FormControl([])
  });

  modalities: ModalityModel[];

  basicBatchConfig: any;
  batchManagementType = false;

  selectedReadersids = [];
  readers: any[] = [];
  minReaders = 1;
  maxReaders = 20;

  dataSourceReaders: any;
  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];
  selectedReaders = [];

  // formValid = false;

  studyVisits: DXAVisitModel[] = [];
  unscheduledAllowed: boolean;
  removedStudyVisits: DXAVisitModel[] = [];
  eligibilityVersion = false;
  simpleReadVersion = false;
  // end Models and Variables

  anatomySelectorControl = new FormControl(null, [Validators.required]);
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];
  studySequenceLabels: SequenceLabelModel[] = [];
  readersField = new FormControl('', [
    Validators.required
  ]);
  bodyRegionList: string[] = ['Lumbar Spine', 'Hip', 'Femoral Neck', 'Forearm', 'Whole Body'];

  constructor(private dialog: MatDialog,
              private activatedRoute: ActivatedRoute,
              private studySequenceLabelService: StudySequenceLabelService,
              private studyUserService: StudyUserService,
              private studyService: StudyService,
              private batchLogicService: BatchLogicService) { }

  ngOnInit(): void {
    // this.formValid = false;
    this.selectedReaders = [];
    this.configForm.get('readers').value.forEach(ri => {
      this.selectedReadersids.push(ri['id']);
    });

    this.studyId = parseInt(this.activatedRoute.snapshot.params.id, 10);

    const serviceCalls = [
      this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
      this.studyUserService.getReadersByStudyId(this.studyId),
      this.studyService.getVisitsByStudyId(this.studyId),
      this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId),
      this.studyService.getById(this.studyId)
    ];
    forkJoin(serviceCalls).subscribe(([modalityResp, readerResp, visitsResp, sequenceLabelResp, studyResp]) => {

      if (modalityResp['data'] != null) {
        this.modalities = modalityResp['data'];
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        if (this.mode === FormMode.EDIT) {
          this.configForm.get('modalities').setValue(modalityResp['data'].filter(x => this.configForm.get('modalities').value.find(m => m.name === x.name)));
        } else {
          this.configForm.get('modalities').setValue(this.modalities);
        }
      }

      if (readerResp['responseCode'] === 200) {
        this.readers = readerResp['data'];
        const activeReader: any[] = [];
        this.readers.forEach(r => {
          if (this.selectedReadersids.includes(r.id)) {
            activeReader.push(r);
          }
        });
        this.configForm.get('readers').setValue(activeReader);
        this.dataSourceReaders = this.configForm.get('readers').value;
        this.selectedReaders = this.configForm.get('readers').value;
        this.selectedReadersids = [];
        this.configForm.get('readers').value.forEach(r => {
          const cur = this.readers.indexOf(r);
          this.readers.splice(cur, 1);
        });
      }


      this.studyVisits = [...visitsResp['data']];
      this.initStudyVisits(studyResp['data']);
      if (this.mode === FormMode.NEW) {
        this.addVisitsToConfigForm();
      }

      this.prepareSequenceLabels(sequenceLabelResp);
    });
    this.onChangeReadingVersion(false);
    this.loadBatchConfigs();
  }

  loadBatchConfigs(): void {
    if (this.mode === FormMode.EDIT) {
      this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id).subscribe(response => {
        const batchConfigs = response.data;
        batchConfigs.forEach(c => {
          this.basicBatchConfig = c;
        });
      });
    }
  }

  onChangeReadingVersion(fromUser: boolean = true) {
    this.eligibilityVersion = this.configForm.get('readingVersion').value === ReadingVersion.DXA_ELIGIBILITY;
    this.simpleReadVersion = this.configForm.get('readingVersion').value === ReadingVersion.DXA_SIMPLE_READ;
    if (fromUser === false) {
      return;
    }
    this.prepareSequenceLabels(this.modalities);
    this.addVisitsToConfigForm();
  }

  openModalitiesDialog() {
    ImagingProjectReadingBasicComponent.clearFocus();

    const dialogRef = this.dialog.open(VisitModalityConfiguratorDialogComponent, {
      data: {
        visits: this.configForm.get('visit').value,
        modalities: this.modalities,
        unscheduledAllowed: this.unscheduledAllowed
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.filterVisits(result.visits);
        const uniqueModality = this.getAllVisitsUniqueModalities(result.visits);
        this.configForm.get('modalities').setValue(uniqueModality);
        this.initDefaultSequenceLabels(this.configForm.get('modalities').value);
      }
    });
  }

  private filterVisits(visits) {
    visits.forEach(visit => {
      if (!visit.modalities || visit.modalities.length < 1) {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = false;
      } else {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = true;
      }
    });
  }

  getAllVisitsUniqueModalities(visits: any[]) {
    let modalities = [];
    visits.forEach((visit, visitIndex) => {

      let visitTemp = this.configForm.get('visit').value;
      visitTemp[visitIndex].modalities = [...visit.modalities];
      this.configForm.get('visit').setValue(visitTemp);

      modalities = [...modalities, ...visit.modalities];
    });
    const uniqueModality = [];
    modalities.forEach((modality) => {
      let index = uniqueModality.findIndex((m) => m.id === modality.id) === -1;
      if (index) uniqueModality.push(modality);
    });
    return uniqueModality;
  }

  initStudyVisits(study: any) {
    //sort study visits by id
    this.studyVisits = this.sortVisits(this.studyVisits);
    this.unscheduledAllowed = study.unscheduledAllowed;
    // add unschaduled visits and early termination
    const unscheduled: DXAVisitModel = new (class implements DXAVisitModel {
      id?: number;
      created?: number;
      name: string;
      type?: VisitType;
      durationTimeUnit?: string;
      durationTimeValue?: number;
      baseline?: boolean;
      repeatAllowed?: boolean;
      modalities?: ModalityModel[];
      isAvailable = true;
    })();
    unscheduled.name = 'Unscheduled';
    unscheduled.type = VisitType.UNSCHEDULED_REGULAR;
    this.studyVisits.push(unscheduled);

    const earlyTermination: DXAVisitModel = new (class implements DXAVisitModel {
      id?: number;
      created?: number;
      name: string;
      type?: VisitType;
      durationTimeUnit?: string;
      durationTimeValue?: number;
      baseline?: boolean;
      repeatAllowed?: boolean;
      modalities?: ModalityModel[];
      isAvailable = true;
    })();
    earlyTermination.name = this.EARLY_TERMINATION_VISIT;
    earlyTermination.type = VisitType.UNSCHEDULED_EARLY_TERMINATION;
    this.studyVisits.forEach(visit => visit.isAvailable = true);
    this.studyVisits.push(earlyTermination);

    if (this.mode != FormMode.EDIT) {
      this.configForm.get('visit').setValue(this.studyVisits);
      this.configForm.get('visit').value.forEach(v => v['modalities'] = !this.unscheduledAllowed && v.type === VisitType.UNSCHEDULED_REGULAR ? [] : this.modalities)
    }
  }

  sortVisits(visits) {
    visits.forEach(visit => {
      let visitDate = moment(visit.create);
      if (visit.durationTimeUnit == 'D') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'd');
      } else if (visit.durationTimeUnit == 'W') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'w');
      } else if (visit.durationTimeUnit == 'M') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'M');
      }
      visit.visitDate = visitDate;
    });
    visits.sort((a, b) => {
      if (a.visitDate < b.visitDate) {
        return -1;
      }
      if (a.visitDate > b.visitDate) {
        return 1;
      }
      return 0;
    });
    let baselineIndex = visits.indexOf(visits.find(this.isBaseline));
    visits.unshift(visits.find(this.isBaseline));
    visits.splice(baselineIndex + 1, 1);
    return visits;
  }
  isBaseline(visit) {
    return visit.baseline === true;
  }

  addVisitsToConfigForm() {
    const visitTemp = [];
    this.getModifiedVisit().forEach(visit => {
      visit.modalities = !this.unscheduledAllowed && visit.type === VisitType.UNSCHEDULED_REGULAR ? [] : this.modalities;
      visitTemp.push(visit);
    });
    this.configForm.get('visit').setValue(visitTemp);
  }

  getModifiedVisit() {
    if (this.eligibilityVersion) {
      return [this.studyVisits[0]];
    }

    if (this.simpleReadVersion) {
      return this.studyVisits.filter(v => v.type !== VisitType.UNSCHEDULED_REGULAR || this.unscheduledAllowed).slice(1);
    }

    return this.studyVisits.filter(v => v.type !== VisitType.UNSCHEDULED_REGULAR || this.unscheduledAllowed);
  }
  // end Visit and modality configuration

  // Sequence Label
  prepareSequenceLabels(sequenceLabelResp) {
    this.studySequenceLabels = sequenceLabelResp.data;
    if (this.mode === FormMode.EDIT) {
      if (!!this.configForm.get('anatomySelector').value) {
        this.initSequenceLabels(this.configForm.get('anatomySelector').value);
      } else {
        this.initDefaultSequenceLabels(this.modalities);
      }
    } else if (this.mode === FormMode.NEW) {
      this.initDefaultSequenceLabels(this.modalities);
    }
  }

  initDefaultSequenceLabels(modalities) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectSequenceLabels(this.availableSequenceLabels);
  }

  selectSequenceLabels(sequences: SequenceLabelModel[]) {
    this.selectedSequenceLabels = sequences;
    this.configForm.get('anatomySelector').setValue(sequences);
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    const modalities = this.modalities.filter(m => this.selectedConfig.config.modalities.find(cm => cm.name === m.name));
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  getAvailableSequenceLabels(modalities): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const m of modalities) {
      for (const label of m.sequenceLabels) {
        configLabels.add(label);
      }
    }
    return Array.from(configLabels);
  }
  // end Sequence Label

  // Batch management and config
  get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true && ((!!this.basicBatchConfig && !this.basicBatchConfig?.numberOfReadings) || !this.basicBatchConfig)
        ? {norError: 'Number of readings is required'} : null;
    };
  }

  onBatchManagementSelected(event: Event): void {
    if (this.basicBatchConfig) {
      this.basicBatchConfig.numberOfReadings = null;
      this.configForm.get('batchManagement').updateValueAndValidity();
    }
  }

  openAutoCreationBatchConfigDialog(config: BatchConfigModel): void {
    let inputConfig = config as AutoBatchConfigPayload;
    const val = this.configForm.get('batchManagement').value;
    if (!val) {
      inputConfig = {...defaultBasicAutoBatchConfig};
    } else {
      inputConfig = this.basicBatchConfig ? { ...this.basicBatchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        reading: 'DXA',
        readingType: ReadingType.BASIC_READING,
        configInput: inputConfig,
        readonlyDialog: !val
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        this.basicBatchConfig = {...result};
        this.configForm.get('batchManagement').updateValueAndValidity();
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }
  // end Batch management and config

  // Readers
  onReaderSelectionChange(event: { value: any[]; }) {
    this.selectedReadersids = event.value;
  }

  onAddSelectedReaders() {
    if (this.selectedReadersids != null) {
      let counter = this.configForm.get('readers').value.length;
      this.selectedReadersids.forEach(readerId => {
        if (counter < this.maxReaders) {
          const reader = this.readers.find(r => r.id === readerId);
          if ((reader != null) && (!this.configForm.get('readers').value.includes(reader))) {

            let visitTemp = this.configForm.get('readers').value;
            visitTemp.push(reader);
            this.configForm.get('readers').setValue(visitTemp);

            const index = this.readers.indexOf(reader);
            this.readers.splice(index, 1);
          }
          counter += 1;
        }
      });

      this.selectedReadersids = [];
    }
    this.dataSourceReaders = new MatTableDataSource<any>(this.configForm.get('readers').value);
    this.selectedReaders = this.configForm.get('readers').value;
    if (this.configForm.get('readers').value.length > 0) {
      this.readersField.clearValidators();
    }
  }

  deleteReader(reader: any) {
    const index = this.configForm.get('readers').value.indexOf(reader);
    this.configForm.get('readers').value.splice(index, 1);
    this.dataSourceReaders = new MatTableDataSource<any>(this.configForm.get('readers').value);
    this.selectedReadersids = this.configForm.get('readers').value;
    this.readers.unshift(reader);
    this.selectedReadersids = [];

    if (this.configForm.get('readers').value.length === 0) {
      this.readersField.validator = Validators.required;
    }
  }
  // end Readers

  clickSubmitBtn() {
    const readers = [];
    this.configForm.get('readers').value.forEach(r => {
      readers.push({id: r.id});
    });

    this.configForm.get('anatomySelector').setValue(this.selectedSequenceLabels.map(l => {
      return {id: l.id};
    }));
    this.configForm['endpointReadingVersion'] = 'basic';
    this.configForm.get('readers').setValue(readers);
    if (!this.basicBatchConfig) {
      this.basicBatchConfig = {
        batchCount: '1',
        endpoint: 'DXA',
        flexibleConfigId: null,
        id: null,
        initiationMethod: 'auto',
        initiationOption: 'by_quantity',
        numberOfReadings: 1,
        readingType: 'basic_reading',
        studyId: this.studyId
      };
    }
    this.checkPopulationDataset();
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: this.configForm.value,
      basicBatchConfig: this.basicBatchConfig
    };
    this.clickSubmit.emit(submitEvent);
  }

  checkPopulationDataset() {
    const holigicValue = JSON.parse(JSON.stringify((<FormGroup>this.configForm.get('referencePopulation')).get('holigic').value));
    const geValue = JSON.parse(JSON.stringify((<FormGroup>this.configForm.get('referencePopulation')).get('ge').value));

    if (holigicValue.referencePopulationDataset === 'Spine Lunar & Hip NHANES') {
      (<FormGroup>this.configForm.get('referencePopulation')).get('holigic').setValue(geValue);
    }
    if (geValue.referencePopulationDataset === 'Spine Hologic & Hip NHANES') {
      (<FormGroup>this.configForm.get('referencePopulation')).get('ge').setValue(holigicValue);
    }
  }

  clickCancelBtn() {
    this.clickCancel.emit({result: 'cancel'});
  }

  get validateDXAReferencePopulation() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value && (control.value.toString().includes('.'))
      && (control.value.toString().substring(control.value.toString().indexOf('.')+1).length < 3)
        ? {norError: 'Please enter at least 3 decimal after floating point.'}
        : (isNaN(control.value) ? {norError: 'Please enter a number.'} : null);
    };
  }

  get validateGenantFacturesThreshold() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value && !Number.isInteger(control.value)
        ? {norError: 'Please enter an integer number.'} : null;
    };
  }
}
