import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface AnnotationSaveData {
  label: string;
  viewerComponent: any;
  row: any;
  event: any;
  oldValue: string;
}

@Component({
  selector: 'app-roi-labelling-dialog',
  templateUrl: './roi-labelling-dialog.component.html',
  styleUrls: ['./roi-labelling-dialog.component.css']
})
export class ROILabellingDialogComponent {
  public showAlert = false;
  public typeAlert = 'success';
  public contAlert = '';

  constructor(public dialogRef: MatDialogRef<ROILabellingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AnnotationSaveData) {
  }

  onClickCancel(): void {       
    this.resetSelectInput(this.data.event, this.data.oldValue);
    this.dialogRef.close();
  }

  resetSelectInput(element:any, value: string): void{
    element.source.value = value;
    this.data.label = value;
    this.data.row.child.get_metadata()['Label'] = value;
  }

  onClickOk(): void {
    if (this.data.label !== null && this.data.label !== '') {
      if (!this.data.viewerComponent.roiLabels.includes(this.data.label)) {
        this.data.viewerComponent.roiLabels.push(this.data.label);
      }
      this.data.row.label = this.data.label;
      this.data.row.isChanged = true;

      // Apply child Label if one exists
      if (this.data.row.child) {
        this.data.row.child.get_metadata()['Label'] = this.data.label;
      }
    }
    this.dialogRef.close();
  }

  triggerAlert(type, msg, timeout, close, data?): void {
    this.typeAlert = type;
    this.showAlert = true;
    this.contAlert = msg;

    if (type === 'danger') {
      console.log('Error found, data: ', data);
    }

    setTimeout(() => {
      this.showAlert = false;
      if (close) {
        this.dialogRef.close();
      }
    }, timeout);
  }
}
