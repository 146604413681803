class BatchStatusCodes {
  private static instance: BatchStatusCodes;

  private codeDescriptionsMap: Map<number, string>;

  public static getInstance(): BatchStatusCodes {
    if (!this.instance) {
      this.instance = new BatchStatusCodes();
    }
    return this.instance;
  }

  private constructor() {
    this.codeDescriptionsMap = new Map<number, string>();
    this.init();
  }

  private init() {
    this.codeDescriptionsMap.set(100100, 'NEW BATCH');
    this.codeDescriptionsMap.set(200200, 'ASSEMBLING READINGS');
    this.codeDescriptionsMap.set(300300, 'BATCH READY');
    this.codeDescriptionsMap.set(400400, 'BATCH IN PROGRESS');
    this.codeDescriptionsMap.set(500500, 'BATCH COMPLETE');
    this.codeDescriptionsMap.set(600600, 'BATCH TERMINATED');
  }

  getDescription(code: number): string {
    let description = this.codeDescriptionsMap.get(code);
    if (!description) {
      description = 'UNDEFINED';
    }
    return description;
  }
}

export const BATCH_STATUS_CODES = BatchStatusCodes.getInstance();
