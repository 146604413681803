import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

import {environment} from 'src/environments/environment';
import {KLUploadData} from '../_models/KAndL/k-l-upload-data';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingKandLVersion;
const SERVICE = 'msreadingkandl-dot-';
const BASE_URL = `https://${API_VERSION}${SERVICE}${API_URL}`;
// const BASE_URL = `http://localhost:8080/api`;

export interface KLReadingActionRequest {
  rightScore: number;
  leftScore: number;
  rightKneePredominantlyMedial?: boolean;
  leftKneePredominantlyMedial?: boolean;
  rightKneePredominantlyLateral?: boolean;
  leftKneePredominantlyLateral?: boolean;
  rightKneeMalalginment?: boolean;
  leftKneeMalalignment?: boolean;
  rightKneeMalalginmentComment?: boolean;
  leftKneeMalalignmentComment?: boolean;
  comment: string;
  spentSeconds: number;
}

@Injectable({
  providedIn: 'root'
})
export class ReadingKandlService {

  constructor(private http: HttpClient) {
  }

  getNumberOfAvailableBasicReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/count-available-basic/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getNumberOfAvailableAdjudicationReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/count-available-adjudication/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getAvailableBasicReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/basic/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getAvailableAdjudicationReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/adjudication/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getReading(studyId, readingId) {
    const url = `${BASE_URL}/reading/${studyId}/${readingId}`;
    return this.http.get(url);
  }

  getNotDoneReadsByReaderIdBasicKL(studyId, userId) {
    const url = `${BASE_URL}/reading/get-by-reader-id/basickl/${studyId}/${userId}`;
    return this.http.get(url);
  }

  getNotDoneReadsByReaderIdAdjudicationKL(studyId, userId): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/get-by-reader-id/adjudicationkl/${studyId}/${userId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  updateReading(studyId, reading: KLUploadData) {
    const url = `${BASE_URL}/reading/${studyId}`;
    return this.http.post(url, reading);
  }

  updateReadingData(studyId: number, readingId: number, data: KLReadingActionRequest): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/${readingId}/${studyId}`;
    return this.http.put<BasicResponse<any>>(url, data);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/start/${readingId}/${studyId}`;
    return this.http.put<BasicResponse<any>>(url, null);
  }

  submitReading(studyId: number, readingId: number, data: KLReadingActionRequest, jwtToken: string): Observable<BasicResponse<any>> {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${jwtToken}`
    });

    const url = `${BASE_URL}/reading/complete/${readingId}/${studyId}`;
    return this.http.put<BasicResponse<any>>(url, data,{ headers });
  }

}
