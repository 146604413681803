import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../../reading-form-scoring.component';
import {Observable} from 'rxjs';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {Store} from '@ngxs/store';
import {DynamikaWebSocket, ReadingPsamrisService, UserService} from '../../../../_services';
import {BasicReading} from '../../../../_models/basic-reading';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
import {PsamrisReadingModel} from '../../../../_models';
import {EndpointReadingVersion} from '../../../../core/types/endpoint-reading-version';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {PsamrisFormControls, PsamrisFormGroup} from './psamris-form-model';

const ZITELAB_URL = environment.ziteLabUrl;

@Component({
  selector: 'app-psamris',
  templateUrl: './psamris-form.component.html',
  styleUrls: ['./psamris-form.component.css']
})
export class PsamrisFormComponent extends ReadingFormScoringComponent implements OnInit, AfterViewInit, ReadingFormScoring {

  studyId: number;
  endpointReadingVersion: EndpointReadingVersion;
  currentReading: BasicReading & PsamrisReadingModel;
  isReadingComplete = false;
  initiatingStatus: boolean | 'loading' | 'error' = false;
  form: PsamrisFormGroup;

  zitelabInitConfiguration: any = {
    'scoring': null,
    'featuresToScore': null,
    'fingersToScore': null,
    'regionsToScore': null,
    'handSide': null,
    'patientId': null,
    'visitId': null,
    'timepoint': null,
    'reset': function (init: any) {
      this.handSide = null;
      init.reset();
    }
  };

  constructor(private store: Store,
              private readingService: ReadingPsamrisService,
              private userService: UserService,
              private webSocketService: DynamikaWebSocket,
              private route: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.stepSaveEnabled = false;
    this.studyId = +JSON.parse(localStorage.getItem('project')).id;
    this.endpointReadingVersion = this.route.snapshot.params.type as EndpointReadingVersion;
    this.store.dispatch(new SetPageHeaderTitle(`PsAMRIS ${this.endpointReadingVersion.toString()} Scoring Form`));
    this.initForm();
    this.initOnCurrentReading();
    this.subscribeSiteLabReadingMessage();
  }

  subscribeSiteLabReadingMessage() {
    this.webSocketService.listen('reading_psamris_message', null, 'psamris-form')
      .subscribe((data) => {
        const msg = JSON.parse(data);
        if (this.currentReading.readerId === msg.readerId && this.currentReading.id === msg.patientId) {
          this.isReadingComplete = true;
          this.switchSubmitBtnDisabledSubject.next(false);
        }
      });
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      this.currentReading = currentReading as BasicReading & PsamrisReadingModel;
      this.initiatingStatus = this.currentReading.ziteLabInitiated;
      if (!!this.currentReading.handSide) {
        this.form.controls.handSide.setValue(this.currentReading.handSide);
        this.form.controls.handSide.updateValueAndValidity();
      }
      this.isReadingComplete = this.currentReading.ziteLabDataReceived;
      this.switchSubmitBtnDisabledSubject.next(!this.isReadingComplete);
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      handSide: new FormControl('LEFT', [Validators.required]),
      comment: new FormControl(null, [Validators.maxLength(200)]),
      initiated: new FormControl()
    } as PsamrisFormControls) as PsamrisFormGroup;
  }

  clearForm(): void {
    this.form.reset();
  }

  getEndpointName(): string {
    return 'PSAMRIS';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    if (this.route.snapshot.params.type as EndpointReadingVersion === 'basic') {
      return this.readingService.getReadingByStudyIdAndReaderId(studyId, readerId);
    } else {
      return this.readingService.getAdjudicationReadingsByStudyIdAndReaderId(studyId, readerId);
    }
  }

  ngAfterViewInit(): void {
  }

  saveReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.updateReading(studyId, this.currentReading.id, this.form.controls.comment.value, data.spentSeconds);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.completeReading(studyId, this.currentReading.id, this.form.controls.comment.value, data.spentSeconds);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.updateReading(studyId, readingId, '', data.spentSeconds);
  }

  ziteLabInitiateReading() {
    this.currentReading.handSide = this.zitelabInitConfiguration.handSide;
    this.initiatingStatus = 'loading';

    this.readingService
      .ziteLabReadingInitiation(this.studyId, this.currentReading.id, this.form.controls.handSide.value).subscribe(response => {
      if (response.responseCode === 200) {
        this.currentReading.ziteLabInitiated = true;
        this.initiatingStatus = true;
        this.controlSamriView();
      } else {
        this.initiatingStatus = 'error';
      }
    }, error => {
      this.initiatingStatus = 'error';
    });
  }

  controlSamriView() {
    this.readingService.laodOnSamri(this.studyId, this.currentReading.id).subscribe();
  }

  openZiteLabTab() {
    let userName: string;
    const dbName = environment.zitelab_psamris_db_prefix;
    this.userService.getById(this.currentReading.readerId).subscribe(response => {
      if (response) {
        if (response['responseCode'] === 200) {
          userName = response['data'].userName as string;
          this.readingService.laodOnSamri(this.studyId, this.currentReading.id)
            .subscribe(resp => {
              window.open(`${ZITELAB_URL}/samrisystem/${userName}/${dbName}${this.currentReading.flexibleConfigId}/${this.currentReading.id}`, '_blank');
            });
        }
      }
    });
  }

}
