import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';


@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  constructor(private http: HttpClient) { }

  getAll() {
    return this.http.get('https://' + API_VERSION + SERVICE + API_URL + '/maintenance/retrieve-all');
  }

}
