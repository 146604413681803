import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    console.log("login guard",state.url);
    // return true;
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['imagingproject/projects'], {
        queryParams: {
          return: state.url
        }
      })
    }
    return true;
  }
}
