import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';

const helper = new JwtHelperService();
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingKandLAutoVersion;
const SERVICE = 'msreadingkandlauto-dot-';

@Injectable({
  providedIn: 'root'
})
export class ReadingKandlAutoServiceService {

  constructor(private http: HttpClient) { }


  getReading(studyId, readingId){
    let url = 'https://' + API_VERSION + SERVICE + API_URL +'/reading/' + studyId + '/' + readingId;
    return this.http.get(url);
  }
}
