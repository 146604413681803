import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSUserVersion;
const SERVICE = 'msimagingproject-dot-';
const REQUEST_URL = `https://${API_VERSION}${SERVICE}${API_URL}/support`;

@Injectable()
export class SupportService {

  constructor(private http: HttpClient) {
  }

  testStudyMailingList(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${REQUEST_URL}/test-study-mailing-list/${studyId}`);
  }
}
