import { ViewerImageGridModel } from './viewer-image-grid.model';
import { ViewerConfigOptionsModel } from './viewer-config-options.model';
import { SeriesLabelListModel } from './series-label-list.model';
import { VisitConfigurationModel } from './visit-configuration.model';
import get from 'lodash/get';

export class HangingProtocolModel {
  status: string = null;
  name: string;
  description: string;
  imageGrid: ViewerImageGridModel;
  imageViewerConfigOptions: ViewerConfigOptionsModel;
  seriesLabelList: SeriesLabelListModel;
  visitConfiguration: VisitConfigurationModel;

  constructor(data: any) {
    if (data) {
      this._parse(data);
    }
  }

  private _parse(data: any) {
    const imageGrid = get(data, 'ImageGrid', null);
    const imageViewerConfigurationOptions = get(data, 'ImageViewerConfigurationOptions', null);
    const seriesLabelList = get(data, 'SeriesLabelList', null);
    const visitConfiguration = get(data, 'VisitConfiguration', null);

    this.status = get(data, 'DefaultStatus._text', null);
    this.name = get(data, 'HangingProtocolInfo.HangingProtocolName._text', null);
    this.description = get(data, 'HangingProtocolInfo.HangingProtocolDescription._text', null);

    if (imageGrid) {
      this.imageGrid = new ViewerImageGridModel();
      this.imageGrid.columns = get(imageGrid, 'NumberOfColumns._text', null);
      this.imageGrid.rows = get(imageGrid, 'NumberOfRows._text', null);
    }

    if (imageViewerConfigurationOptions) {
      this.imageViewerConfigOptions = new ViewerConfigOptionsModel(imageViewerConfigurationOptions);
    }

    if (seriesLabelList) {
      const SeriesLabel = seriesLabelList.SeriesLabel instanceof Array ? seriesLabelList.SeriesLabel : [seriesLabelList.SeriesLabel];
      this.seriesLabelList = new SeriesLabelListModel({...seriesLabelList, ...{SeriesLabel}});
    }

    if (visitConfiguration) {
      this.visitConfiguration = new VisitConfigurationModel();
      this.visitConfiguration.visitDisplayConfiguration = get(visitConfiguration, 'VisitDisplayConfiguration._text', null);
      this.visitConfiguration.visitOrder = get(visitConfiguration, 'VisitOrder._text', null);
      this.visitConfiguration.visitType = get(visitConfiguration, 'VisitType._text', null);
    }
  }

  get default() {
    return this.status === 'DefaultAllVisits';
  }
}
