import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Frame, FramePosition, FramePresentationSizeMode, FrameRotation} from '../../_models/viewer/TemplateModels';
import {MedicalViewerSeries} from '../../_models/viewer/MedicalViewer';
import {toInteger} from 'lodash';
import {MatTableDataSource} from '@angular/material/table';
import {ROIRow} from '../../_models/viewer/RoiRowModel';
import {MatPaginator} from '@angular/material/paginator';
import debounce from 'lodash/debounce';
import {AnnoTypeEnum} from '../../_models/viewer/AnnoTypeEnum';
import {AnnoSubjectEnum} from '../../_models/viewer/AnnoSubjectEnum';
import {OverlayTag} from '../../_models/viewer/OverlayTagModel';
import {forkJoin, Observable, of} from 'rxjs';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Select} from '@ngxs/store';
import {CurrentUserInfoState, CurrentUserInfoStateModel} from '../../core/data-management/states/user-info.state';
import {CompactJwt} from "../../_helpers/CompactJwt";
import {ViewerToolbar} from "../interfaces/viewer/toolbar";
import {AnnJswObject} from "./ann-jsw-object/ann-jsw-object";

const ROOT_URL = environment.Leadtools.rootURL;
const SERVICES = environment.Leadtools.services;
const OBJECT_TO_ROI_TYPE = {
  'Leadtools.Annotations.Engine.AnnRectangleObject': AnnoTypeEnum.RECTANGLE,
  'Leadtools.Annotations.Engine.AnnEllipseObject': AnnoTypeEnum.ELLIPSE,
  'Leadtools.Annotations.Engine.AnnCurveObject': AnnoTypeEnum.CURVE,
  'Leadtools.Annotations.Engine.AnnFreehandHotspotObject': AnnoTypeEnum.FREEHAND
};
const helper = new JwtHelperService();
const EVENT_DELAYS = {
  "default": 500
}

@Injectable()
export class LeadToolsUtils {
  constructor(private http: HttpClient) {
  }

  findFirst(arr: Array<any>, callback, context?) {
    let el;
    for (let i = 0, l = arr.length; i < l; i++) {
      el = arr[i];
      if (callback.call(context, el, i, arr)) {
        return el;
      }
    }
    return null; // to return null and not undefined
  }
}


@Injectable()
export class OverlayTags {
  public topLeft: Array<OverlayTag>;
  public topRight: Array<OverlayTag>;
  public bottomLeft: Array<OverlayTag>;
  public bottomRight: Array<OverlayTag>;
  public centerLeft: Array<OverlayTag>;
  public centerTop: Array<OverlayTag>;
  public centerRight: Array<OverlayTag>;
  public centerBottom: Array<OverlayTag>;

  constructor() {
    this.topRight = new Array<OverlayTag>();
    this.topLeft = new Array<OverlayTag>();
    this.bottomLeft = new Array<OverlayTag>();
    this.bottomRight = new Array<OverlayTag>();
    this.centerLeft = new Array<OverlayTag>();
    this.centerTop = new Array<OverlayTag>();
    this.centerRight = new Array<OverlayTag>();
    this.centerBottom = new Array<OverlayTag>();
  }
}

@Injectable({
  providedIn: 'root'
})
export class Utils {
  currentUser: CurrentUserInfoStateModel;
  static countit = 0;
  statusCodes: any = null;
  canvas: HTMLCanvasElement = null;
  setVisibilityEvent: EventEmitter<{ row: ROIRow, visibility: boolean }> = new EventEmitter();
  allowtoCallSelection: boolean = true;
  lastDrawnROI: string[] = [];
  allowAddingObject: boolean = true;
  allowRemovingObject: boolean = true;
  syncResizeOn = null;
  MRanoActiveVisits: string[] = [];
  isMranoEfficancy: boolean = false;
  readingId: number = null;
  isAdvancedAnalysis: boolean = false;
  projectIncludesRanoEndpoint = false;
  viewerToolbar: ViewerToolbar = ViewerToolbar.getInstance();

  HttpStatus = {
    ACCEPTED: 202,
    BAD_GATEWAY: 502,
    BAD_REQUEST: 400,
    CONFLICT: 409,
    CONTINUE: 100,
    CREATED: 201,
    EXPECTATION_FAILED: 417,
    FORBIDDEN: 403,
    GATEWAY_TIMEOUT: 504,
    GONE: 410,
    HTTP_VERSION_NOT_SUPPORTED: 505,
    INSUFFICIENT_SPACE_ON_RESOURCE: 419,
    INSUFFICIENT_STORAGE: 507,
    INTERNAL_SERVER_ERROR: 500,
    LENGTH_REQUIRED: 411,
    LOCKED: 423,
    METHOD_FAILURE: 420,
    METHOD_NOT_ALLOWED: 405,
    MOVED_PERMANENTLY: 301,
    MOVED_TEMPORARILY: 302,
    MULTI_STATUS: 207,
    MULTIPLE_CHOICES: 300,
    NO_CONTENT: 204,
    NON_AUTHORITATIVE_INFORMATION: 203,
    NOT_ACCEPTABLE: 406,
    NOT_FOUND: 404,
    NOT_IMPLEMENTED: 501,
    NOT_MODIFIED: 304,
    OK: 200,
    PARTIAL_CONTENT: 206,
    PAYMENT_REQUIRED: 402,
    PRECONDITION_FAILED: 412,
    PROCESSING: 102,
    PROXY_AUTHENTICATION_REQUIRED: 407,
    REQUEST_TIMEOUT: 408,
    REQUEST_TOO_LONG: 413,
    REQUEST_URI_TOO_LONG: 414,
    REQUESTED_RANGE_NOT_SATISFIABLE: 416,
    RESET_CONTENT: 205,
    SEE_OTHER: 303,
    SERVICE_UNAVAILABLE: 503,
    SWITCHING_PROTOCOLS: 101,
    TEMPORARY_REDIRECT: 307,
    UNAUTHORIZED: 401,
    UNPROCESSABLE_ENTITY: 422,
    UNSUPPORTED_MEDIA_TYPE: 415,
    USE_PROXY: 305
  };
  JWTData = null;
  @Select(CurrentUserInfoState.getCurrentUserInfo) currentUserInfo: Observable<CurrentUserInfoStateModel>;

  constructor() {
    this.currentUserInfo.subscribe((user: CurrentUserInfoStateModel) => {
      this.currentUser = user;
    });
  }

  clearAllShutter(frame: lt.Controls.Medical.Frame) {
    const automation: lt.Annotations.Automation.AnnAutomation = frame.parentCell.automation;
    const annotations: lt.LeadCollection = frame.shutter.objects;

    let index = 0;
    const length = annotations.count;
    const container: lt.Annotations.Engine.AnnContainer = frame.container;

    for (index = 0; index < length; index++) {
      container.children.remove(annotations.get_item(index));
    }

    frame.get_shutter().get_objects().clear();
    automation.automationControl.automationInvalidate(lt.LeadRectD.empty);
  }

  dateFormatter(cellValue, newFormat) {
    const DateJS: IDateJSStatic = <any>Date;
    let parsedDate: IDateJS = DateJS.parse(cellValue);

    if (parsedDate == null) {
      if (cellValue) {
        return cellValue;
      }

      parsedDate = <any>new Date(cellValue);
    }

    // if parsed date is null, just used the passed cell value; otherwise,
    // transform the date to desired format
    const formattedDate = parsedDate ? parsedDate.toString(newFormat) : cellValue;

    return formattedDate;
  }

  countRenderer(evt) {
    return evt.rowIndex + 1;
  }

  clearMrti(api, rowData: Array<any>, seriesInstanceUID: string) {
    // let updatedNodes = [];

    // api.forEachNode(function (node) {
    //    let data = node.data;

    //    if (data.InstanceUID == seriesInstanceUID) {
    //        data.CompleteMRTI = true;
    //        updatedNodes.push(node);
    //    }
    // });
    // api.refreshRows(updatedNodes);
  }


  GetDateLength(text: string): number {
    if (!this.canvas) {
      this.canvas = document.createElement('canvas');
    }
    let ctx = this.canvas.getContext('2d');
    ctx.font = '14px Arial';
    return ctx.measureText(text).width + 10;
  }

  sortableDateRenderer(evt) {
    const str: string = evt.value;

    if (str.trim() == '')
      return '';
    let timeArray = str.split('_', 20);

    let timeFormat = 'AM';
    let hours = parseInt(timeArray[3]);
    if (hours == 0) {
      hours = 12;
    }
    if (hours > 12) {
      hours -= 12;
      timeFormat = 'PM';
    }


    return timeArray[1] + '/' + timeArray[2] + '/' + timeArray[0] + ' ' + hours + ':' + timeArray[4] + ':' + timeArray[5] + ' ' + timeFormat;
  }

  mrtiRenderer(evt) {
    if (!evt.data.CompleteMRTI) {
      const eContainer = document.createElement('i');
      const span = document.createElement('span');
      const id: string = UUID.generate();

      eContainer.style.maxWidth = '100%';
      eContainer.style.maxHeight = '100%';
      eContainer.style.display = 'block';
      eContainer.style.margin = '0 auto';
      eContainer.style.verticalAlign = 'middle';
      evt.data.element = eContainer;

      $(eContainer).attr('id', id);
      evt.data.mrtiCell = id;
      $(eContainer).addClass('fa fa-hourglass fa-fw');
      return eContainer;
    }

    return null;
  }

  createButton(eContainer) {
    const button = document.createElement('button');

    $(button).addClass('btn btn-default');
    button.appendChild(eContainer);
    return button;
  }

  dateComparator(date1, date2) {
    let d1: Date;
    let d2: Date;

    if (!date1 && !date2) {
      return 0;
    }

    if (!date1) {
      return -1;
    }

    if (!date2) {
      return 1;
    }

    d1 = new Date(date1);
    d2 = new Date(date2);

    return <any>d1 - <any>d2;
  }

  patientIDComparator(date1, date2) {
    let d1: Date;
    let d2: Date;

    if (!date1 && !date2) {
      return 0;
    }

    if (!date1) {
      return -1;
    }

    if (!date2) {
      return 1;
    }

    d1 = new Date(date1);
    d2 = new Date(date2);

    return <any>d1 - <any>d2;
  }

  createLeadRect(left, top, right, bottom): lt.LeadRectD {
    return lt.LeadRectD.create(left, top, Math.abs(right - left), Math.abs(bottom - top));
  }

  clone(src) {
    return this.myclone(src, null);
  }

  myclone(src, excludedItems) {
    function mixin(dest, source, copyFunc) {
      let name, s, i, empty = {};
      for (name in source) {

        let found = false;
        let index = 0;
        const length = excludedItems == null ? 0 : excludedItems.length;
        for (index = 0; index < length; index++) {
          if (name == excludedItems[index]) {
            found = true;
          }
        }

        // the (!(name in empty) || empty[name] !== s) condition avoids copying properties in "source"
        // inherited from Object.prototype.	 For example, if dest has a custom toString() method,
        // don't overwrite it with the toString() method that source inherited from Object.prototype

        if (!found) {
          s = source[name];
          if (!(name in dest) || (dest[name] !== s && (!(name in empty) || empty[name] !== s))) {
            dest[name] = copyFunc ? copyFunc(s, excludedItems) : s;
          }
        }
      }
      return dest;
    }

    if (!src || typeof src != 'object' || Object.prototype.toString.call(src) === '[object Function]') {
      // null, undefined, any non-object, or function
      return src;	// anything
    }
    if (src.nodeType && 'cloneNode' in src) {
      // DOM Node
      return src.cloneNode(true); // Node
    }
    if (src instanceof Date) {
      // Date
      return new Date(src.getTime());	// Date
    }
    if (src instanceof RegExp) {
      // RegExp
      return new RegExp(<any>src);   // RegExp
    }
    let r, i, l;
    if (src instanceof Array) {
      // array
      r = [];
      for (i = 0, l = src.length; i < l; ++i) {
        if (i in src) {
          r.push(this.myclone(src[i], excludedItems));
        }
      }
      // we don't clone functions for performance reasons
      // 		}else if(d.isFunction(src)){
      // 			// function
      // 			r = function(){ return src.apply(this, arguments); };
    } else {
      // generic objects
      r = src.constructor ? new src.constructor() : {};
    }
    return mixin(r, src, this.myclone);
  }

  get_httpStatusText(statusCode: number) {
    if (this.statusCodes == null) {
      this.statusCodes = {};
      this.statusCodes[this.HttpStatus.ACCEPTED] = 'Accepted';
      this.statusCodes[this.HttpStatus.BAD_GATEWAY] = 'Bad Gateway';
      this.statusCodes[this.HttpStatus.BAD_REQUEST] = 'Bad Request';
      this.statusCodes[this.HttpStatus.CONFLICT] = 'Conflict';
      this.statusCodes[this.HttpStatus.CONTINUE] = 'Continue';
      this.statusCodes[this.HttpStatus.CREATED] = 'Created';
      this.statusCodes[this.HttpStatus.EXPECTATION_FAILED] = 'Expectation Failed';
      this.statusCodes[this.HttpStatus.FORBIDDEN] = 'Forbidden';
      this.statusCodes[this.HttpStatus.GATEWAY_TIMEOUT] = 'Gateway Timeout';
      this.statusCodes[this.HttpStatus.GONE] = 'Gone';
      this.statusCodes[this.HttpStatus.HTTP_VERSION_NOT_SUPPORTED] = 'HTTP Version Not Supported';
      this.statusCodes[this.HttpStatus.INSUFFICIENT_SPACE_ON_RESOURCE] = 'Insufficient Space on Resource';
      this.statusCodes[this.HttpStatus.INSUFFICIENT_STORAGE] = 'Insufficient Storage';
      this.statusCodes[this.HttpStatus.INTERNAL_SERVER_ERROR] = 'Server Error';
      this.statusCodes[this.HttpStatus.LENGTH_REQUIRED] = 'Length Required';
      this.statusCodes[this.HttpStatus.LOCKED] = 'Locked';
      this.statusCodes[this.HttpStatus.METHOD_FAILURE] = 'Method Failure';
      this.statusCodes[this.HttpStatus.METHOD_NOT_ALLOWED] = 'Method Not Allowed';
      this.statusCodes[this.HttpStatus.MOVED_PERMANENTLY] = 'Moved Permanently';
      this.statusCodes[this.HttpStatus.MOVED_TEMPORARILY] = 'Moved Temporarily';
      this.statusCodes[this.HttpStatus.MULTI_STATUS] = 'Multi-Status';
      this.statusCodes[this.HttpStatus.MULTIPLE_CHOICES] = 'Multiple Choices';
      this.statusCodes[this.HttpStatus.NO_CONTENT] = 'No Content';
      this.statusCodes[this.HttpStatus.NON_AUTHORITATIVE_INFORMATION] = 'Non Authoritative Information';
      this.statusCodes[this.HttpStatus.NOT_ACCEPTABLE] = 'Not Acceptable';
      this.statusCodes[this.HttpStatus.NOT_FOUND] = 'Not Found';
      this.statusCodes[this.HttpStatus.NOT_IMPLEMENTED] = 'Not Implemented';
      this.statusCodes[this.HttpStatus.NOT_MODIFIED] = 'Not Modified';
      this.statusCodes[this.HttpStatus.OK] = 'OK';
      this.statusCodes[this.HttpStatus.PARTIAL_CONTENT] = 'Partial Content';
      this.statusCodes[this.HttpStatus.PAYMENT_REQUIRED] = 'Payment Required';
      this.statusCodes[this.HttpStatus.PRECONDITION_FAILED] = 'Precondition Failed';
      this.statusCodes[this.HttpStatus.PROCESSING] = 'Processing';
      this.statusCodes[this.HttpStatus.PROXY_AUTHENTICATION_REQUIRED] = 'Proxy Authentication Required';
      this.statusCodes[this.HttpStatus.REQUEST_TIMEOUT] = 'Request Timeout';
      this.statusCodes[this.HttpStatus.REQUEST_TOO_LONG] = 'Request Entity Too Large';
      this.statusCodes[this.HttpStatus.REQUEST_URI_TOO_LONG] = 'Request-URI Too Long';
      this.statusCodes[this.HttpStatus.REQUESTED_RANGE_NOT_SATISFIABLE] = 'Requested Range Not Satisfiable';
      this.statusCodes[this.HttpStatus.RESET_CONTENT] = 'Reset Content';
      this.statusCodes[this.HttpStatus.SEE_OTHER] = 'See Other';
      this.statusCodes[this.HttpStatus.SERVICE_UNAVAILABLE] = 'Service Unavailable';
      this.statusCodes[this.HttpStatus.SWITCHING_PROTOCOLS] = 'Switching Protocols';
      this.statusCodes[this.HttpStatus.TEMPORARY_REDIRECT] = 'Temporary Redirect';
      this.statusCodes[this.HttpStatus.UNAUTHORIZED] = 'Unauthorized';
      this.statusCodes[this.HttpStatus.UNPROCESSABLE_ENTITY] = 'Unprocessable Entity';
      this.statusCodes[this.HttpStatus.UNSUPPORTED_MEDIA_TYPE] = 'Unsupported Media Type';
      this.statusCodes[this.HttpStatus.USE_PROXY] = 'Use Proxy';
    }

    if (this.statusCodes.hasOwnProperty(statusCode)) {
      return this.statusCodes[statusCode];
    } else {
      throw new Error('Status code does not exist: ' + statusCode);
    }
  }

  get_splitterSize(): number {
    const ua: string = navigator.userAgent.toLowerCase();

    if (lt.LTHelper.device == lt.LTDevice.tablet || ((ua.indexOf('tablet pc') > -1) && ua.indexOf('windows nt 10.0') == -1)) {
      return 21;
    }

    if (lt.LTHelper.device == lt.LTDevice.mobile) {
      return 27;
    }

    return 7;
  }

  get_spacingSize() {
    return this.isTabletOrMobile() ? 10 : 6;
  }

  isTabletOrMobile() {
    return lt.LTHelper.device == lt.LTDevice.mobile || lt.LTHelper.device == lt.LTDevice.tablet;
  }

  isNumber(event, element) {
    if (event) {
      let max_chars = element.getAttribute('max') ? element.getAttribute('max').length : null;
      let charCode = (event.which) ? event.which : event.keyCode;

      if (charCode != 190 && charCode != 189 && charCode != 109 && charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode < 96 || charCode > 105) &&
        (charCode < 37 || charCode > 40) &&
        charCode != 110 && charCode != 8 && charCode != 46) {
        return false;
      }

      if (max_chars && element.value.length >= max_chars && charCode > 47) {
        return false;
      } else {
        return true;
      }
    }
  }

  transformRectangle(from, to, src: lt.LeadPointD): lt.LeadPointD {
    const dst: lt.LeadPointD = new lt.LeadPointD();

    dst.x = (((src.x - from.left) / from.width) * to.width) + to.left;
    dst.y = (((src.y - from.top) / from.height) * to.height) + to.top;

    return dst;
  }

  accessProperty(object, keys: string, array?: Array<any>) {
    array = array || keys.split('.');

    if (array.length > 1) {
      const item = array.shift();

      if (object[item] == null) {
        return null;
      }

      if (!object[item]) {
        return undefined;
      }
      return this.accessProperty(object[item], null, array);
    } else {
      return object[array.toString()] || undefined;
    }
  }

  roundNumber(n: number, decimalPlaces) {
    return parseFloat(n.toFixed(decimalPlaces));
  }

  getPosition(rect: lt.LeadRectD, width: number, height: number): FramePosition {
    const position: FramePosition = new FramePosition();

    position.leftTop = new lt.LeadPointD();
    position.leftTop.x = rect.left / width;
    position.leftTop.y = 1 - (rect.top / height);

    position.rightBottom = new lt.LeadPointD();
    position.rightBottom.x = position.leftTop.x + rect.width / width;
    position.rightBottom.y = position.leftTop.y - rect.height / height;

    return position;
  }

  getRotation(rotation: number): string {
    switch (rotation) {
      case FrameRotation.Rotate180:
        return '180\xB0';
      case FrameRotation.Rotate270:
        return '270\xB0';
      case FrameRotation.Rotate90:
        return '90\xB0';
    }
    return '0\xB0';
  }

  resetSeriesArrangement(medicalViewer: lt.Controls.Medical.MedicalViewer, layout) {
    const cellLength = Math.min(layout.length, medicalViewer.layout.get_items().get_count());

    for (let index = 0; index < cellLength; index++) {
      medicalViewer.layout.get_items().get_item(index).set_position(index);
    }

    const length = medicalViewer.get_emptyDivs().get_items().get_count();

    for (let index = 0; index < length; index++) {
      medicalViewer.get_emptyDivs().get_items().get_item(index).set_position(index + cellLength);
    }
  }

  rearrangeSeries(medicalViewer: lt.Controls.Medical.MedicalViewer, layout) {
    medicalViewer.layout.beginUpdate();

    let length = medicalViewer.get_emptyDivs().get_items().get_count();

    for (let index = 0; index < length; index++) {
      const positionIndex = medicalViewer.get_emptyDivs().get_items().get_item(index).get_position();

      medicalViewer.get_emptyDivs().get_items().get_item(index).set_bounds(this.createLeadRect(layout[positionIndex][0], layout[positionIndex][1], layout[positionIndex][2], layout[positionIndex][3]));
      medicalViewer.get_emptyDivs().get_items().get_item(index).onSizeChanged();
    }

    length = Math.min(layout.length, medicalViewer.layout.get_items().get_count());
    for (let index = 0; index < length; index++) {
      const positionIndex = medicalViewer.layout.get_items().get_item(index).get_position();

      medicalViewer.layout.get_items().get_item(index).set_bounds(this.createLeadRect(layout[positionIndex][0], layout[positionIndex][1], layout[positionIndex][2], layout[positionIndex][3]));
      medicalViewer.layout.get_items().get_item(index).onSizeChanged();
    }


    length = medicalViewer.layout.get_items().get_count();

    for (let index = 0; index < length; index++) {
      medicalViewer.layout.get_items().get_item(index).set_bounds(this.createLeadRect(0, 0, 0, 0));
    }

    medicalViewer.layout.endUpdate();
  }

  findFirst(arr: Array<any>, callback, context?) {
    let el;

    for (let i = 0, l = arr.length; i < l; i++) {
      el = arr[i];
      if (callback.call(context, el, i, arr)) {
        return el;
      }
    }
    return null; // to return null and not undefined
  }

  findAll(arr: Array<any>, callback, context?): Array<any> {
    const el: Array<any> = new Array<any>();

    for (let i = 0, l = arr.length; i < l; i++) {
      if (callback.call(context, arr[i], i, arr)) {
        el.push(arr[i]);
      }
    }
    return el;
  }

  isValidNumber(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  get_Modalities(): Array<any> {
    const modalities: Array<any> = new Array<any>();

    modalities.push({ name: 'CR', description: 'Computed Radiography' });
    modalities.push({ name: 'CT', description: 'Computed Tomography' });
    modalities.push({ name: 'MR', description: 'Magnetic Resonance' });
    modalities.push({ name: 'NM', description: 'Nuclear Medicine' });
    modalities.push({ name: 'US', description: 'Ultrasound' });
    modalities.push({ name: 'OT', description: 'Other' });
    modalities.push({ name: 'BI', description: 'Biomagnetic imaging' });
    modalities.push({ name: 'DG', description: 'Diaphanography' });
    modalities.push({ name: 'ES', description: 'Endoscopy' });
    modalities.push({ name: 'LS', description: 'Laser surface scan' });
    modalities.push({ name: 'PT', description: 'Positron emission tomography (PET)' });
    modalities.push({ name: 'RG', description: 'Radiographic imaging (conventional film/screen)' });
    modalities.push({ name: 'TG', description: 'Thermography' });
    modalities.push({ name: 'XA', description: 'X-Ray Angiographyy' });
    modalities.push({ name: 'RF', description: 'Radio Fluoroscopy' });
    modalities.push({ name: 'DX', description: 'Digital Radiography' });
    modalities.push({ name: 'MG', description: 'Mammography' });
    modalities.push({ name: 'IO', description: 'Intra-oral Radiography' });
    modalities.push({ name: 'PX', description: 'Panoramic X-Ray' });
    modalities.push({ name: 'GM', description: 'General Microscopy' });
    modalities.push({ name: 'SM', description: 'Slide Microscopy' });
    modalities.push({ name: 'XC', description: 'External-camera Photography' });

    return modalities;
  }

  is_equal(v1, v2): boolean {

    if (typeof (v1) !== typeof (v2)) {
      return false;
    }

    if (typeof (v1) === 'function') {
      return v1.toString() === v2.toString();
    }

    if (v1 instanceof Object && v2 instanceof Object) {
      if (this.count_props(v1) !== this.count_props(v2)) {
        return false;
      }
      let r = true;
      for (const k in v1) {
        r = this.is_equal(v1[k], v2[k]);
        if (!r) {
          return false;
        }
      }
      return true;
    } else {
      return v1 === v2;
    }
  }

  count_props(obj): number {
    let count = 0;
    for (const k in obj) {
      if (obj.hasOwnProperty(k)) {
        count++;
      }
    }
    return count;
  }

  splitCamelCaseToString(s): string {
    if (!s) {
      return '';
    }

    return s.replace
    (/(^[a-z]+)|[0-9]+|[A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|[0-9])/g
      , function (match, first) {
        if (first) {
          match = match[0].toUpperCase() + match.substr(1);
        }
        return match + ' ';
      }
    ).trim();
  }

  Contains(values: Array<MedicalViewerSeries>, testValue: MedicalViewerSeries): boolean {
    for (let i = 0; i < values.length; i++) {
      if (values[i].id == testValue.id) {
        return true;
      }
    }
    return false;
  }

  RemoveDuplicates(mainList: Array<MedicalViewerSeries>, pruneList: Array<MedicalViewerSeries>) {
    const returnList: Array<MedicalViewerSeries> = new Array<MedicalViewerSeries>();

    for (let i = 0; i < pruneList.length; i++) {
      if (!this.Contains(mainList, pruneList[i])) {
        returnList.push(pruneList[i]);
      }
    }
    return returnList;
  }

  GetNumericPrefix(n: number): string {
    if (n == 1)
      return '1st';
    if (n == 2)
      return '2nd';
    if (n == 3)
      return '3rd';
    return (n.toString() + 'th');
  }


  isArrayNumeric(numbersArray: Array<string>): boolean {
    const numeric = false;

    for (let i = 0; i < numbersArray.length; i++) {
      if ($.isNumeric(numbersArray[i]) == false) {
        return false;
      }
    }
    return true;
  }

  // lengthToCheck = 0: means determine number of items in array
  verifyNumeric(selectorValue: string, lengthToCheck: number): string {

    // Split into an array and remove the empty values
    let selectorValues: Array<string> = selectorValue.split('\\', 5).filter(function (el) {
      return el.length != 0
    });
    let errorMessage: string = '';
    if (lengthToCheck == 0) {
      lengthToCheck = selectorValues.length;
    }

    if (!this.isArrayNumeric(selectorValues)) {
      if (lengthToCheck > 1)
        errorMessage = 'Values must be numeric. (Example: 1\\3)';
      else
        errorMessage = 'Value must be numeric';
    }

    return errorMessage;
  }

  isNumericVr(vr: string): boolean {
    let isNumeric = false;
    switch (vr) {
      case 'DS': // decimal string
      case 'FL': // floating point single
      case 'FD': // floating point double
      case 'IS': // integer string
      case 'SL': // signed long
      case 'SS': // signed short
      case 'UL': // unsigned long
      case 'US': // unsgined short
        isNumeric = true;
        break;
    }
    return isNumeric;
  }

  isStringEmpty(s: string): boolean {
    if (s == null) {
      return true;
    }

    if (s.trim().length == 0) {
      return true;
    }
  }

  verifySelectorCount(selectorValue: string, requiredCount: number): string {
    let selectorValues: Array<string> = selectorValue.split('\\', 5).filter(function (el) {
      return el.length != 0
    });
    let separatorCount: number = selectorValue.split('\\', 5).length - 1;
    let selectorValueCount: number = selectorValues.length;

    let errorMessage: string = '';

    // means at least one value
    if (requiredCount == 0) {
      if (selectorValueCount == 0)
        errorMessage = 'Must have at least one value.'
    } else if (selectorValueCount != requiredCount || separatorCount != (requiredCount - 1)) {
      if (requiredCount == 2)
        errorMessage = 'Must have exactly two values. (Example: 1\\3)';
      else if (requiredCount == 1)
        errorMessage = 'Must have exactly one value.';
      else
        errorMessage = 'Must have exactly ' + requiredCount.toString() + ' values.';
    }

    return errorMessage;
  }

  insert(value: string, index: number, string: string) {
    return value.substring(0, index) + string + value.substring(index);
  }

  subCell_setPresentationMode(subCell: lt.Controls.Medical.MRTISubCell) {
    if ((<any>subCell).templateFrame) {
      const templateFrame: Frame = (<any>subCell).templateFrame;

      if (templateFrame.PresentationSizeMode === FramePresentationSizeMode.ScaleToFit) {
        subCell.attachedFrame.zoom(lt.Controls.Medical.MedicalViewerSizeMode.fit, 1);
      } else if (templateFrame.PresentationSizeMode === FramePresentationSizeMode.TrueSize) {
        subCell.attachedFrame.zoom(lt.Controls.Medical.MedicalViewerSizeMode.trueSize, 1);
      } else if (templateFrame.PresentationSizeMode === FramePresentationSizeMode.Magnify) {
        if (templateFrame.Magnification !== 1) {
          subCell.attachedFrame.zoom(
            subCell.attachedFrame.get_scaleMode(),
            subCell.attachedFrame.get_scale() * templateFrame.Magnification
          );
        }
      }
    }
  }

  buildROIRowData(cell: lt.Controls.Medical.Cell, info): void {
    const viewerComponent = info.viewerComponent;
    const frames = cell.get_frames();
    const mprType = this.getCellMPRType(cell);
    viewerComponent.roiLabels = ['New Label', 'None'];

    frames.toArray().forEach(frame => {
      const container: lt.Annotations.Engine.AnnContainer = frame.container;
      const children = container.get_children().toArray();
      const childrenCount = children.length;
      for (let index = 0; index < childrenCount; index++) {
        const child = children[index];
        const childMetadata = child ? child.get_metadata() : null;
        if (!childMetadata || !childMetadata.Name || childMetadata.Name === '' || childMetadata.Name == null) {
          continue;
        }
        const row = this.createROIRowData(child, cell);
        const userData = container.get_userData();
        try {
          // rowData.push(row);
          if (!info.viewerComponent.roiLabels.includes(row.label)) {
            info.viewerComponent.roiLabels.push(row.label);
          }
          row.mprType = (userData && userData.MPRType) ? userData.MPRType : mprType;

          // add row to ROI panel
          viewerComponent.rowData1.push(row);

          if (index === childrenCount - 1) {
            viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
            viewerComponent.dataSource1.filterPredicate = this.getRoiFilterPredicate();
            viewerComponent.dataSource1.filter = [];
            viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
            viewerComponent.dataSource1.sort = viewerComponent.sort1;

            // link rowData to child
            const rowIndex = viewerComponent.dataSource1.filteredData.length;
            (<any>child).rowData = viewerComponent.dataSource1.filteredData[rowIndex - 1];

          }
        } catch (error) {

        }
      }
    });

  }

  createROIRowData(child: ltAnnotationsEngine.AnnObject, cell: any, isChanged: boolean = false): ROIRow {
    const row = new ROIRow(child);
    row.isChanged = row.isChanged || isChanged;
    if (row.parentCell === null && cell !== null) {
      row.parentCell = cell;
    }
    return row;
  }

  getCurrentDatetime(): string {
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
    return date + ' ' + time;
  }

  getROIsCount(xmlAnnotation: XMLDocument): number {
    const codecs: lt.Annotations.Engine.AnnCodecs = new lt.Annotations.Engine.AnnCodecs();
    const containers = codecs.loadAllFromXML(xmlAnnotation);

    return containers.reduce((acc, container) => {
      return acc + container.get_children().count;
    }, 0);
  }

  getSlicesCount(xmlAnnotation: XMLDocument, cell?): number {
    // TODO: use metadata to count slice
    return 1;
  }

  getSliceIndex(cell, sopInstanceUID): number {
    const frameArray: Array<lt.Controls.Medical.Frame> = cell.frames.toArray();
    return frameArray.findIndex(frame => (<any>frame).Instance.SOPInstanceUID === sopInstanceUID);
  }

  onObjectAdded(cell: lt.Controls.Medical.Cell, new_child, frameIndex, info?): any {
    console.log('0. onObjectAdded...');
    const viewerComponent = info.viewerComponent;
    const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(frameIndex);

    if(!this.allowAddingObject && this.isDemriqProject()) {
      return;
    }

    this.toggleJswTools(new_child.id !== AnnJswObject.jswObjectId);

    //Calculate the image scale to be stored in ROIs
    const frameX: lt.Controls.Medical.Frame = cell.get_frames().get_item(0);
    const containerSize = frameX.get_container().get_size();
    const scale_H = frameX.get_height() / containerSize.get_height();
    const scale_W = frameX.get_width() / containerSize.get_width();
    let scale = scale_H > scale_W ? scale_H : scale_W;
    scale = scale > 1 ? 1 : scale;
    info.scale = scale;

    let isNewChild: boolean = false;
    if ((<any>cell).annType) {
      info.annType = (<any>cell).annType;
    }
    info.mprType = this.getCellMPRType(cell);
    const seriesManagerService = info['seriesManagerService'];
    const seriesInfo = seriesManagerService.getSeriesInfo(cell);
    let cobbAngle = seriesInfo && seriesInfo['cobbAngle'] ? seriesInfo['cobbAngle'] : null;
    const newObjects = [];
    const objectsToDel = [];

    if (this.isNewAnnoObject(new_child, info)
      || this.isNewRulerObject(new_child, info)
      || this.isNewCobbAngle(cobbAngle, new_child, info)
      || new_child.get_metadata()['Label'] === 'Marker_MARKER') {
      console.log('1. onObjectAdded...');
      isNewChild = true;
      if (this.isCobbAngle(cobbAngle)) {
        const uuid = UUID.generate();
        cobbAngle.firstLine.get_metadata()['CobbAngleGUID'] = uuid;
        cobbAngle.secondLine.get_metadata()['CobbAngleGUID'] = uuid;
        objectsToDel.push(cobbAngle.firstLine);

        // create cobbAngleObject
        const automation: lt.Annotations.Automation.AnnAutomation = cell.get_automation();
        const cobbAngleObj = new lt.Controls.Medical.CobbAngle(automation, cobbAngle.firstLine, cobbAngle.secondLine);

        let transVector = [0, 0];
        let centerPoint = null;
        let firstPoint = null;
        let secondPoint = null;
        if (cobbAngle.secondLine.points.get_item(0).x < cobbAngle.secondLine.points.get_item(1).x) {
          transVector = [
            cobbAngle.firstLine.points.get_item(1).x - cobbAngle.secondLine.points.get_item(1).x,
            cobbAngle.firstLine.points.get_item(1).y - cobbAngle.secondLine.points.get_item(1).y
          ];
          centerPoint = cobbAngle.firstLine.points.get_item(1);
          firstPoint = cobbAngle.firstLine.points.get_item(0);
          secondPoint = lt.LeadPointD.create(
            cobbAngle.secondLine.points.get_item(0).x + transVector[0],
            cobbAngle.secondLine.points.get_item(0).y + transVector[1]);
        } else {
          transVector = [
            cobbAngle.firstLine.points.get_item(0).x - cobbAngle.secondLine.points.get_item(1).x,
            cobbAngle.firstLine.points.get_item(0).y - cobbAngle.secondLine.points.get_item(1).y
          ];
          centerPoint = cobbAngle.firstLine.points.get_item(0);
          firstPoint = cobbAngle.firstLine.points.get_item(1);
          secondPoint = lt.LeadPointD.create(
            cobbAngle.secondLine.points.get_item(0).x + transVector[0],
            cobbAngle.secondLine.points.get_item(0).y + transVector[1]);
        }
        const protractorObj = this.createProtractorObject(firstPoint, centerPoint, secondPoint);
        newObjects.push({ child: protractorObj, isLoaded: false });
        newObjects.push({ child: cobbAngle.secondLine, isLoaded: true, annoType: AnnoTypeEnum.LINE });
        cobbAngle = { firstLine: null, secondLine: null };
      } else {
        newObjects.push({ child: new_child, isLoaded: true });
      }
    }

    objectsToDel.forEach(child => {
      const index = frame.get_container().get_children().toArray()
        .findIndex(item => item.get_guid() === child.get_guid());

      frame.get_container().get_children().removeItem(index);
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 30);
    });

    newObjects.forEach(async item => {
      let child = item.child;
      child = this.initializeROIChild(child, frameIndex, true, info);
      const childMetadata: any = child.get_metadata();
      childMetadata['SOPInstanceUID'] = (<any>frame).Instance ? (<any>frame).Instance.SOPInstanceUID : '';
      childMetadata['SeriesInstanceUID'] = (<any>frame).Instance ? (<any>frame).Instance.SeriesInstanceUID : '';
      if (childMetadata['SeriesInstanceUID'] === '' && (<any>frame).SeriesInstanceUID) {
        childMetadata['SeriesInstanceUID'] = (<any>frame).SeriesInstanceUID
      }
      if (childMetadata['Subject'] === AnnoSubjectEnum.DIAM_RULER_L14) {
        item.annoType = AnnoTypeEnum.CROSS_RULER;
      } else if (childMetadata['Subject'] === AnnoSubjectEnum.GBMROI) {
        item.annoType = AnnoTypeEnum.FREEHAND;
      }
      if (item.annoType) {
        childMetadata['AnnoType'] = item.annoType;
      }
      if (childMetadata['AnnoType'] === AnnoTypeEnum.RULER) {
        childMetadata['RulerLength'] = child.labels.RulerLength.text;
      }
      if (childMetadata['AnnoType'] === AnnoTypeEnum.CROSS_RULER) {
        childMetadata['FirstLineLength'] = child.labels.RulerLength.text;
        childMetadata['SecondLineLength'] = child.labels.SecondaryRulerLength.text;
      }

      childMetadata['isChanged'] = 'true';

      if (childMetadata.Subject === 'primaryLocationArea') {
        try {
          const currentSlice = parseInt((document.getElementById(cell.divID + '_slice-tracker_current') as HTMLInputElement).value);
          const currentOffset = cell.get_currentOffset();
          childMetadata['SliceNumber'] = currentSlice;
          childMetadata['_sliceNumber'] = currentSlice;
          childMetadata['CurrentOffset'] = currentOffset;
          const currentTime = new Date().getTime();

          childMetadata['Name'] = childMetadata['Label'] + '_' + childMetadata['SeriesId'] + '_' + childMetadata['ReadingID'] + '_' + currentOffset + '_' + currentTime;;

          child.add_propertyChanged((sender, e) => {
            if (!seriesManagerService.silentPropertyChange) {
              this.onPropertyChanged(sender, e, child, info);
              if(this.isDemriqProject()) {
                if (!child.isSelected && !e.newValue && e.oldValue && seriesManagerService.allowSavingActiveContainer) {
                  // Saving annotation after any change for simple ROIs and right after unselect for complex ROIs
                  info['currentOffset'] = childMetadata['CurrentOffset'];
                  seriesManagerService.saveActiveContainerROIs(info);
                  seriesManagerService.autoVolumeCalculations(info, cell, childMetadata['Label']).then((rowData2) => {
                    viewerComponent.rowData2 = rowData2;
                  });
                }
              }
            }
          });
          (<any>child).is_add_propertyChanged = true;
          child.isClosed = true;

        } catch (error) {
          console.log(">>> error ... ", error);
        }
      }

      if (info.actionManagerService.isAutoClosed !== undefined && info.actionManagerService.isAutoClosed === true) {
        child.isClosed = true;
        info.actionManagerService.isAutoClosed = false;
      }

      if (new_child.get_metadata()['Subject'] === 'RadioBoticROI' || new_child.get_metadata()['Subject'] === 'JSWROI') {
        try {
          viewerComponent.isJSWProject = true;
          if (new_child.get_guid() === null) {
            const uuid = UUID.generate();
            new_child.set_guid(uuid);
          }
        } catch (error) {

        }
      }
      // Reducing Freehand points to be more easy to amend
      if (new_child.get_metadata()['AnnoType'] === 'Freehand' && new_child.get_metadata()['CreateMethod'] === 'Manual'
            && new_child.get_metadata()['Subject'] !== 'RadioBoticROI' && (childMetadata['pointReduced'] === undefined || childMetadata['pointReduced'] === null)) {
        childMetadata['pointReduced'] = 'true';
        let counter = 0;
        let pointSkiped = 0;
        let childPoints: any = new_child.points.toArray();
        let childNewPoints: any = [];
        let skipValue = (childPoints.length / 200) + 1;
        childNewPoints.add(lt.LeadPointD.create(childPoints[0].x, childPoints[0].y));
        for (let i = 1; i < (childPoints.length) - 1; i++) {
          const X1 = childPoints[i - 1].x;
          const X2 = childPoints[i].x;
          const Y1 = childPoints[i - 1].y;
          const Y2 = childPoints[i].y;
          if (pointSkiped >= skipValue) {
            if ((Math.abs((X2 - X1)) >= 70 && Math.abs((Y2 - Y1)) >= 70)) {
              childNewPoints.add(lt.LeadPointD.create(X2, Y2));
              pointSkiped = 0;
              counter = 0;
            } else if (counter >= 1) {
              childNewPoints.add(lt.LeadPointD.create(X2, Y2));
              pointSkiped = 0;
              counter = 0;
            } else {
              counter++;
            }
          } else {
            pointSkiped++;
          }
        }
        childNewPoints.add(lt.LeadPointD.create(childPoints[childPoints.length - 1].x, childPoints[childPoints.length - 1].y));
        new_child.points.W = childNewPoints;
      }

      const row = this.createROIRowData(child, cell, true);
      this.lastDrawnROI.push(row.name);
      try {
        if (viewerComponent.isJSWProject !== true) {
          await info.seriesManagerService.computeVOIVolumeData(row, info).then((volData) => {
            row.area = volData.area_mm2 ? volData.area_mm2 : '0';
            childMetadata.Area = row.area;
          });
        } else {
          row.area = '0';
          childMetadata.Area = row.area;
        }
      } catch (error) {

      }
      // link the child object to the lastest rowData added
      child.rowData = row;
      // update table dataSource & set roi as 1st item
      viewerComponent.rowData1.push(row);
      if (viewerComponent.dataSource1 instanceof MatTableDataSource) {
        viewerComponent.dataSource1.data = viewerComponent.rowData1;
      } else {
        viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
      }
      viewerComponent.dataSource1.filterPredicate = this.getRoiFilterPredicate();
      try {
        if (viewerComponent.isJSWProject === true && viewerComponent.dataSource1.filter) {
          viewerComponent.dataSource1.filter.push('None');
        } else {
          viewerComponent.dataSource1.filter = [];
        }
      } catch (error) {
        console.log(error);
      }

      viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
      viewerComponent.dataSource1.sort = viewerComponent.sort1;

      if (!item.isLoaded) {
        frame.get_container().get_children().add(child);
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 50);
      }

      if (!(<any>child).is_add_propertyChanged) {
        child.add_propertyChanged(debounce((sender, e) => {
          this.onPropertyChanged(sender, e, child, info);
        }), 100);

        (<any>child).is_add_propertyChanged = true;
      }

      if (!(<any>child).is_onPointsChanged) {
        (<any>child).is_onPointsChanged = true;
      }

      // Update paginator and highlight corresponding ROI row
      this._updatePaginator(child, info);
      child.rowData.isSelected = true;
    });

    seriesInfo['cobbAngle'] = cobbAngle;
    seriesManagerService.setSeriesInfo(cell, seriesInfo);

    // set the list of labels if empty
    if (viewerComponent.roiLabels.length == 0) {
      viewerComponent.roiLabels = ['New Label', 'None'];
    }

    return isNewChild;
  }

  layoutHasItem(cell: lt.Controls.Medical.Cell): boolean {
    const selectedItems = cell.get_viewer().layout.items.toArray();
    return !!selectedItems.find(i => i.divID === cell.divID);
  }

  createProtractorObject(firstPoint, centerPoint, secondPoint) {
    const protractorObj = new ltAnnotationsEngine.AnnProtractorObject();
    protractorObj.firstPoint = firstPoint;
    protractorObj.centerPoint = centerPoint;
    protractorObj.secondPoint = secondPoint;

    protractorObj.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;

    const stroke = lt.Annotations.Engine.AnnStroke.create(
      lt.Annotations.Engine.AnnSolidColorBrush.create('orange'),
      lt.LeadLengthD.create(0.5));
    protractorObj.set_stroke(stroke);

    const tickMarkstroke = lt.Annotations.Engine.AnnStroke.create(
      lt.Annotations.Engine.AnnSolidColorBrush.create('orange'),
      lt.LeadLengthD.create(0));
    protractorObj.set_tickMarksStroke(tickMarkstroke);
    protractorObj.tickMarksLength = lt.LeadLengthD.create(0);

    const protractorLabels: any = protractorObj.labels;
    protractorLabels.AngleText.foreground.color = 'orange';
    protractorLabels.AngleText.font.fontWeight = 3;
    protractorLabels.AngleText.offset = lt.LeadPointD.create(0, -30);
    // protractorLabels.AngleText.offsetHeight = false;
    protractorLabels.FirstRulerLength.foreground.color = 'orange';
    protractorLabels.FirstRulerLength.font.fontWeight = 3;
    protractorLabels.FirstRulerLength.offset = lt.LeadPointD.create(20, 20);
    protractorLabels.FirstRulerLength.offsetHeight = false;
    protractorLabels.SecondRulerLength.foreground.color = 'orange';
    protractorLabels.SecondRulerLength.font.fontWeight = 3;
    protractorLabels.SecondRulerLength.offset = lt.LeadPointD.create(20, 20);
    protractorLabels.SecondRulerLength.offsetHeight = false;

    return protractorObj;
  }

  isNewCobbAngle(cobbAngle, child, info) {
    console.log('isNewCobbAngle...');
    let isCobbAngle = false;
    const actionManagerService = info['actionManagerService'];

    if (child.get_guid() == null && info.annType == 'CobbAngle' && cobbAngle
      && (cobbAngle.firstLine == null || cobbAngle.secondLine == null)
      && actionManagerService.LastCommand.ButtonID == 'CobbAngle') {
      // console.log("cobbAngle:", cobbAngle);
      child.set_guid(UUID.generate());
      if (cobbAngle.firstLine == null) {
        child.firstLine = true;
        cobbAngle.firstLine = child;
        // console.log("cobbAngle.firstLine SET");
        setTimeout(() => {
          actionManagerService.setAnnTool(9, info); // MedicalViewerAction.AnnLine: 9
        }, 30);
      } else if (cobbAngle.firstLine != null && cobbAngle.secondLine == null && !child.firstLine) {
        child.secondLine = true;
        cobbAngle.secondLine = child;
        actionManagerService.LastCommand.ButtonID = '';
        isCobbAngle = true;
        // console.log("cobbAngle.secondLine SET");
      }
    }
    return isCobbAngle;
  }

  isCobbAngle(cobbAngle) {
    return (cobbAngle && cobbAngle.firstLine != null && cobbAngle.secondLine != null);
  }

  isNewAnnoObject(child, info) {
    console.log('isNewAnnoObject...');
    return child.get_guid() == null && info.annType !== 'CobbAngle'
      && !this.isAnnoRuler(info) && !this.isAnnoImage(child)
      && (child.getArea() > 0 || child.bounds.height > 0);
  }

  isAnnoImage(child){
    return child.picture !== null && child.picture !== undefined;
  }

  isNewRulerObject(child, info) {
    console.log('isNewRulerObject...');
    return child.get_guid() == null
      && this.isAnnoRuler(info)
      && (typeof child.getRulerLength === 'function')
      && child.getRulerLength(1) > 0;
  }

  isAnnoRuler(info) {
    const buttonIds = ['Ruler', 'PolyRuler', 'Angle', 'CrossRuler'];
    return buttonIds.some(item => info.annType === item);
  }

  onObjectRemoved(cell: lt.Controls.Medical.Cell, frameIndex, info?, event?) {
    if (event) {
      const viewerComponent = info.viewerComponent;
      const actionManagerService = info.actionManagerService;
      const annObject = event?.Ng;
      const activeContainer = viewerComponent.seriesManagerService._activeCell?.automation?.activeContainer;

      if (annObject && activeContainer) {
        if (annObject.get_metadata()['Subject'] && annObject.get_metadata()['Subject'] === 'JSWROI' && annObject.get_metadata()['AnnoType'] !== 'Ruler') {
          activeContainer.children.W.forEach(child => {
            if (annObject.get_metadata().ContourIndex === child.get_metadata().ContourIndex) {
              activeContainer.children.remove(child);
            }
          });
        }

        const rowIndex = viewerComponent.rowData1.findIndex(a => a.name === annObject.hb.Name);
        if (rowIndex > -1) {

          viewerComponent.rowData1.splice(rowIndex, 1);
          if (viewerComponent.dataSource1 instanceof MatTableDataSource) {
            viewerComponent.dataSource1.data = viewerComponent.rowData1;
          } else {
            viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
            viewerComponent.dataSource1.filter = [];
          }
          viewerComponent.dataSource1.filterPredicate = this.getRoiFilterPredicate();
          viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
          viewerComponent.dataSource1.sort = viewerComponent.sort1;

          if(this.isDemriqProject() && this.allowRemovingObject) {
            // Saving annotation after any change for simple ROIs and right after unselect for complex ROIs
            info.seriesManagerService.saveActiveContainerROIs(info, annObject.guid);
            info.seriesManagerService.autoVolumeCalculations(info, cell, annObject.metadata.Label).then((rowData2) => {
              viewerComponent.rowData2 = rowData2;
            });

            if (actionManagerService.isRapidAction && viewerComponent.lastAction === 'OnAnnotationFreehand') {
              actionManagerService.drawCustomFreehand(actionManagerService.activeJoint.color, actionManagerService.activeJoint.regionToScore, actionManagerService.activeJoint.primaryLocation, info);
            }
          } else {
            if (activeContainer && activeContainer.userData && typeof activeContainer.userData === 'object') {
              activeContainer.userData['IsChanged'] = true;
            }
          }
        }
      }
    }
    console.log('onObjectRemoved...');
  }

  onCollectionChanged(e, cell: lt.Controls.Medical.Cell, frameIndex, info?) {
    // console.log("onCollectionChanged...");
    // let viewerComponent = info.viewerComponent;
    // let newItems = e.newItems;
    // let oldItems = e.oldItems;

    const frame = cell.get_frames().get_item(frameIndex);
    const children = frame.get_container().get_children();
    const count = children.get_count();
  }

  onSelectionChanged(child, e, info): void {
    if (!this.allowtoCallSelection || this.isDemriqProject()) {
      return;
    }
    console.log('onSelectionChanged...');
    const viewerComponent = info.viewerComponent;
    let newValue = e.newValue;
    let rowData = child.rowData;
    let filteredData = info.viewerComponent._dataSource1.filteredData;

    if (!rowData) {
      if (!newValue) {
        return;
      }

      const rowIndex = filteredData
        .findIndex(rowData => rowData.name === child.get_metadata().Name);
      if (rowIndex !== -1) {
        let restoredRowData = filteredData[rowIndex];
        child.rowData = restoredRowData;
        rowData = restoredRowData;
      }
    }

    if (child.isSelected == false && (child.get_metadata()['VOIStatus'] === 'UnGrouped' || child.get_metadata()['VOIStatus'] === undefined)) {
      const rowIndex = filteredData
        .findIndex(rowData => {
          return rowData._name === child.get_metadata().Name
        })
      if (rowIndex !== -1) {
        filteredData[rowIndex].isSelected = newValue;
      }
    }

    // highlight the new selected one in ROIs table
    viewerComponent.selectedRow = newValue ? rowData : null;

    const isChanged_old = rowData.isChanged;
    const scale = info.scale;

    // apply style to selected/unselected objects
    if ((!(<any>child).oldStrokeColor || !(<any>child).oldStrokeThickness)) {
      (<any>child).oldStrokeColor = child.get_stroke().stroke.get_color();
      (<any>child).oldStrokeThickness = child.get_stroke().get_strokeThickness().get_value();
    }
    if (child.get_metadata().CreateMethod === 'GBM AI') {
      let annStroke_gbm;
      if (newValue) {
        annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
          lt.Annotations.Engine.AnnSolidColorBrush.create('orange'),
          lt.LeadLengthD.create(4));
      } else {
        switch (child.get_metadata()['Label']) {
          case 'Necrosis':
            annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
              lt.Annotations.Engine.AnnSolidColorBrush.create('blue'),
              lt.LeadLengthD.create(1));
            break;
          case 'Edema':
            annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
              lt.Annotations.Engine.AnnSolidColorBrush.create('green'),
              lt.LeadLengthD.create(1));
            break;
          case 'Enhancing':
            annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
              lt.Annotations.Engine.AnnSolidColorBrush.create('red'),
              lt.LeadLengthD.create(1));
            break;
          default:
            annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
              lt.Annotations.Engine.AnnSolidColorBrush.create('red'),
              lt.LeadLengthD.create(1));
            break;
        }
      }

      child.set_stroke(annStroke_gbm);
    }

    // reset old value as the selection is not a change
    if (newValue) {
      rowData.isChanged = isChanged_old;
    }

    // update VOI panel
    info.viewerComponent.dataSource2 = new MatTableDataSource(info.viewerComponent.rowData2);
    info.viewerComponent.dataSource2.paginator = info.viewerComponent.paginator2;

    const rowIndex = info.viewerComponent._dataSource1._renderData._value
      .findIndex(rowData => rowData.name === child.get_metadata().Name);
    if (rowIndex !== -1) {
      let restoredRowData = info.viewerComponent._dataSource1._renderData._value[rowIndex];
      this.allowtoCallSelection = false;
      restoredRowData.isSelected = restoredRowData.child.isSelected;
      this.allowtoCallSelection = true;
    }

    if ((child.get_metadata().Subject === 'RadioBoticROI' || child.get_metadata().Subject === 'JSWROI') && child.get_metadata().AnnoType !== 'Ruler' && !newValue) {
      let firstObjectName: string = child.get_metadata().Name;
      let secondObjectName: string = '';
      let isItHip: boolean = false;
      let contourIndex: string = '';
      if (firstObjectName.includes('Tibia')) {
        secondObjectName = firstObjectName.replace('Tibia' , 'Femur');
      } else if (firstObjectName.includes('Femur')) {
        secondObjectName = firstObjectName.replace('Femur' , 'Tibia');
      } else if (firstObjectName.includes('First')) {
        secondObjectName = firstObjectName.replace('First' , 'Second');
      } else if (firstObjectName.includes('Second')) {
        secondObjectName = firstObjectName.replace('Second' , 'First');
      }
      if (secondObjectName !== '') {
        if (child.get_metadata()["Subject"] === 'JSWROI') {
            isItHip = true;
        }
        contourIndex = child.get_metadata().ContourIndex;
        viewerComponent.selection1.clear();
        viewerComponent.rowData1.forEach(row => {
          if (row.name === firstObjectName || row.name === secondObjectName) {
            viewerComponent.selection1.selected.push(row);
          }
        });
        if (viewerComponent.selection1.selected.length >= 2) {
          viewerComponent.actionManagerService.ShowShortestDistance(info, isItHip, contourIndex);
        }
      }
    }

    this.toggleJswTools(child.id !== AnnJswObject.jswObjectId || !e.newValue);

    // set related page on the ROI panel
    this._updatePaginator(child, info, newValue);

    this.asyncResizeTrigger();
  }

  private toggleJswTools(hipToolDisabled: boolean) {
    this.viewerToolbar.setItemProperty('FillRightHip', 'readonly', hipToolDisabled);
    this.viewerToolbar.setItemProperty('FillLeftHip', 'readonly', hipToolDisabled);
    this.viewerToolbar.setItemProperty('FillRightKneeLateral', 'readonly', hipToolDisabled);
    this.viewerToolbar.setItemProperty('FillRightKneeMedial', 'readonly', hipToolDisabled);
    this.viewerToolbar.setItemProperty('FillLeftKneeMedial', 'readonly', hipToolDisabled);
    this.viewerToolbar.setItemProperty('FillLeftKneeLateral', 'readonly', hipToolDisabled);

    this.viewerToolbar.setItemProperty('ToggleJswKneeDistanceCalculation', 'readonly', hipToolDisabled);
    this.viewerToolbar.setItemProperty('ToggleJswHipDistanceCalculation', 'readonly', hipToolDisabled);
  }

  private _updatePaginator(child: lt.Annotations.Engine.AnnObject, info: any, newSelectedROI?: boolean): void {
    if (newSelectedROI != undefined) {
      if (!newSelectedROI) {
        return;
      }
    }
    const viewerComponent = info.viewerComponent;
    const paginator: MatPaginator = viewerComponent.dataSource1.paginator;
    const rowIndex = viewerComponent.dataSource1.filteredData
      .findIndex(rowData => rowData.name === child.get_metadata().Name);

    if (rowIndex !== -1 && newSelectedROI) {
      const pageSize = paginator.pageSize;
      let curPage = rowIndex / pageSize;
      curPage = parseInt(curPage.toString(), 10);

      if (curPage >= 0 && curPage < paginator.getNumberOfPages()) {
        paginator.pageIndex = curPage;
        paginator._changePageSize(paginator.pageSize);
      } else {
        console.log('Error > incorrect curPage found:', curPage);
      }
    }
  }

  firePaginatorEvent(child: any, info: any) {
    this._updatePaginator(child, info);
  }

  async onPropertyChanged(sender, e, child: ltAnnotationsEngine.AnnObject, info?) {
    try {
      // console.log("1. evt:", e);
      if (sender.last_evt
        && e.propertyName == sender.last_evt.propertyName
        && e.newValue == sender.last_evt.newValue
        && e.oldValue == sender.last_evt.oldValue) {
        return;
      }
      sender.last_evt = e;

      if (e.propertyName === 'IsSelected') {
        this.onSelectionChanged(sender, e, info);
      }

      const scale = info.scale;
      const viewerComponent = info.viewerComponent;
      const area = toInteger(child.getArea() * (scale ** 2));
      const lastEditedOn = this.getCurrentDatetime();
      const childMetadata = child.get_metadata();
      const old_area = childMetadata.Area;
      const old_length = childMetadata.RulerLength;

      // isSettingsChanged: is set inside the roi-settings-dialog
      if ((childMetadata.AnnoType !== AnnoTypeEnum.RULER &&
        childMetadata.AnnoType !== AnnoTypeEnum.CROSS_RULER && area === old_area && !(<any>child).isSettingsChanged) ||
        (childMetadata.AnnoType === AnnoTypeEnum.CROSS_RULER &&
          child.labels.RulerLength.text === childMetadata.FirstLineLength &&
          child.labels.SecondaryRulerLength.text === childMetadata.SecondLineLength && !(<any>child).isSettingsChanged) ||
        (childMetadata.AnnoType === AnnoTypeEnum.RULER
          && child.labels.RulerLength.text === old_length && !(<any>child).isSettingsChanged)) {

        return;
      }
      console.log('onPropertyChanged...');
      const rowData = sender.rowData;

      if (rowData == undefined) {
        const filteredData = viewerComponent._dataSource1.filteredData;
        const rowIndex = filteredData
          .findIndex(rowData => rowData.name === sender.get_metadata().Name);
        if (rowIndex !== -1) {
          const restoredRowData = filteredData[rowIndex];
          sender.rowData = restoredRowData;
        }
      }
      if (rowData != null && rowData != 'undefined') {
        // update child metadata
        childMetadata.LastEditedOn = lastEditedOn;
        childMetadata.CreateMethod =
          childMetadata.Subject === AnnoSubjectEnum.DIAM_RULER_L14 || childMetadata.Subject === AnnoSubjectEnum.GBMROI
            ? 'GBM AI' : 'Manual';

        if (childMetadata.AnnoType === AnnoTypeEnum.RULER) {
          // console.log(child.labels.RulerLength.text); // @TODO remove
          childMetadata.RulerLength = child.labels.RulerLength.text;
        }

        if (childMetadata.AnnoType === AnnoTypeEnum.CROSS_RULER) {
          childMetadata.FirstLineLength = child.labels.RulerLength.text;
          childMetadata.SecondLineLength = child.labels.SecondaryRulerLength.text;
        }
        try {
          setTimeout(() => {
            if (childMetadata.Subject === AnnoSubjectEnum.DIAM_RULER_L14 && child.labels.RulerLength.text !== null && child.labels.SecondaryRulerLength.text !== null) {
              console.log('>>> Diameter is Changing ...');
              const major = (child['labels']['RulerLength']['Of'] as string).replace(' mm', '');
              const minor = (child['labels']['SecondaryRulerLength']['Of'] as string).replace(' mm', '');
              const spdp = (<any>major as number) * (<any>minor as number);
              const volume = 0;

              viewerComponent.dataSourceDiam['data'][0]['major'] = major;
              viewerComponent.dataSourceDiam['data'][0]['minor'] = minor;
              viewerComponent.dataSourceDiam['data'][0]['spdp'] = spdp;
              viewerComponent.dataSourceDiam['data'][0]['volume'] = volume;

              this._updatePaginator(child, info);
            }
          }, 300);
        } catch (error) {

        }

        setTimeout(() => {
          const activeContainer = viewerComponent.seriesManagerService._activeCell?.automation?.activeContainer;

          if (activeContainer) {
            let firstSelected;
            activeContainer.children.toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
              if (item.metadata.AnnoType.includes('AnnJswObject') && item.isSelected) {
                firstSelected = item;
              }
            });

            this.toggleJswTools(!firstSelected);
          }
        }, 300);

        rowData.isChanged = true;
        rowData.lastEditedOn = lastEditedOn;

        try {
          let seriesManagerService;
          if(info.seriesManagerService != undefined) {
            seriesManagerService = info.seriesManagerService;
          } else if (info.viewerComponent.seriesManagerService != undefined) {
            seriesManagerService = info.viewerComponent.seriesManagerService;
          }
          if (viewerComponent.isJSWProject !== true) {
            await seriesManagerService.computeVOIVolumeData(rowData, info).then((volData) => {
              rowData.area = volData.area_mm2 ? volData.area_mm2 : '0';
              childMetadata.Area = rowData.area;
            });
          } else {
            rowData.area = '0';
            childMetadata.Area = rowData.area;
          }
        } catch (error) {

        }

        // trick to force datasource to update on child property changes
        const paginator: MatPaginator = viewerComponent.dataSource1.paginator;
        paginator._changePageSize(paginator.pageSize);


      }
    } catch (error) {

    }
  }

  toggleROISelections(cell: lt.Controls.Medical.Cell, info: any): void {
    const viewerComponent = info.viewerComponent;
    const cellIndex = cell.get_currentOffset() + 1;

    viewerComponent.dataSource1.filteredData.forEach((row: ROIRow) => {
      const isSelected = viewerComponent.selection1.selected.indexOf(row) !== -1;
      row.isSelected = isSelected;

      if (row.child) {
        row.child.isSelected = isSelected;
      }
    });

    // trigger an event to refresh the viewer
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  onROIRowClick(cell: lt.Controls.Medical.Cell, row, info): void {
    // console.log('onROIRowClick...');
    const viewerComponent = info.viewerComponent;

    // reset ROI panel if no lastSelChild found
    if (!(<any>cell).lastSelChild || (<any>cell).lastSelChild == null) {
      viewerComponent.dataSource1.filteredData.forEach(item => {
        if (item.isSelected) {
          item.isSelected = false;

          if (item.child) {
            item.child.isSelected = false;
          }
        }
      });
    }

    const child: ltAnnotationsEngine.AnnObject = this.getSelectedChild(cell, row);

    // show the selected row on the viewer
    const slice_index = parseInt(row.slice, 10) - 1;
    cell.set_currentOffset(slice_index);
    if ((<any>cell).lastSelChild !== undefined && (<any>cell).lastSelChild !== null){
      if ((<any>cell).lastSelChild.get_metadata().Name !== child.get_metadata().Name){
        console.log('1.lastSelChild found:', (<any>cell).lastSelChild.get_metadata().Name);
        (<any>cell).lastSelChild.isSelected = false;
        viewerComponent.selectedRow = null;
      }
    }
    (<any>cell).lastSelChild = (<any>cell).lastSelChild
    && (<any>cell).lastSelChild.get_metadata().Name === child.get_metadata().Name ? null : child;
    viewerComponent.selectedRow = row;

    if (child) {
      child.isSelected = true;
      const frameIndex = toInteger(row.slice) - 1;
      const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(frameIndex);
      const automation: lt.Annotations.Automation.AnnAutomation = frame.parentCell.automation;
      const objects = new lt.Annotations.Engine.AnnObjectCollection();
      objects.add(child);
      automation.selectObjects(objects);
    }

    // trigger an event to refresh the viewer
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  initializeROIChild(child: ltAnnotationsEngine.AnnObject, frameIndex, resetId: boolean = false, info) {
    const viewerComponent = info.viewerComponent;
    const childMetadata = child.get_metadata();
    const scale = info.scale;
    if (childMetadata.Name === undefined || childMetadata.Name === '' || childMetadata.Name === null) {
      let prefix_name = this.isAnnoRuler(info) ? 'RUL' : 'ROI';
      if (childMetadata.Subject === AnnoSubjectEnum.DIAM_RULER_L14) {
        prefix_name = 'Diam';
        info.annType = AnnoTypeEnum.CROSS_RULER;

      } else if (childMetadata.Subject === AnnoSubjectEnum.GBMROI) {
        prefix_name = 'GBM';
        info.annType = AnnoTypeEnum.FREEHAND;
      }

      if (child.get_guid() == null || resetId) {
        const uuid = UUID.generate();
        console.log('new uuid: ', uuid);
        child.set_guid(uuid);
        childMetadata.Name = prefix_name + '_' + uuid.substring(uuid.length - 4).toUpperCase();
      }
    }
    childMetadata.AnnoType = info.annType;
    childMetadata.SliceNumber = (frameIndex + 1).toString();
    if (childMetadata.Subject === AnnoSubjectEnum.DIAM_RULER_L14 || childMetadata.Subject === AnnoSubjectEnum.GBMROI) {
      childMetadata.CreateMethod = 'GBM AI';
    } else {
      childMetadata.CreateMethod = 'Manual';
    }
    try {
      if (viewerComponent.viewerData && viewerComponent.viewerData.studyUserRoles) {
          const JWTData = this.JWTData ? this.JWTData : this.getJWTData();
          let userRoles = <Array<any>>viewerComponent.studyUserRoles;
          if (userRoles == undefined) {
            userRoles = <Array<any>>viewerComponent.viewerData.studyUserRoles;
          }
          const userRoleNames = userRoles.map(item => item.roleName);
          childMetadata['LastEditedBy'] = JWTData.subject;
          childMetadata['CreatedBy'] = JWTData.subject;
          childMetadata['Institution'] = JWTData.institution;
          childMetadata['UserRoleNames'] = userRoleNames.toString();
          if (viewerComponent.viewerData.selectedPage === 'Reading' && viewerComponent.viewerData.readingID !== undefined && viewerComponent.viewerData.readingID !== '') {
            childMetadata['ReadingID'] = viewerComponent.viewerData.readingID;
          }
      }
    } catch (error) {

    }
    childMetadata['LastEditedOn'] = this.getCurrentDatetime();
    childMetadata['ImageScale'] = scale.toString();
    childMetadata['MPRType'] = info.mprType;
    if (childMetadata['AnnoType'] === AnnoTypeEnum.RULER) {
      childMetadata['RulerLength'] = child.labels.RulerLength.text;
    }
    if (childMetadata.AnnoType === AnnoTypeEnum.CROSS_RULER) {
      childMetadata.FirstLineLength = child.labels.RulerLength.text;
      childMetadata.SecondLineLength = child.labels.SecondaryRulerLength.text;
    }

    if (childMetadata.Subject === AnnoSubjectEnum.primaryLocationArea) {
      try {
        childMetadata['Name'] = childMetadata['_name'];
        childMetadata['SliceNumber'] = childMetadata['_sliceNumber'];

      } catch (error) {
        console.log(">>> error ... ", error);
      }
    }

    return child;
  }

  computeRoiVolume(areas: number[], cell: lt.Controls.Medical.Cell) {
    let frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(0);
    let volume = 0;
    // console.log("frame.JSON['00180050']:", frame.JSON['00180050']);
    if (frame.JSON === null) {
      frame = cell.get_frames().get_item(cell.get_frames().get_count() - 1);
    }
    if (frame.JSON['00180050'] && frame.JSON['00180050']['Value']) {
      const sliceThickness = frame.JSON['00180050']['Value'];
      const spacing = frame.JSON['00280030']['Value'];

      // volume[cm3] = pixelsCount*(sliceThickness*pixelSpacingX*pixelSpacingY)*0.001
      areas.forEach(area => {
        volume += area * sliceThickness * spacing[0] * spacing[1] * 0.001;
      });
      volume = parseFloat(volume.toFixed(2));
    } else {
      return -1;
    }

    return volume;
  }

  getSelectedChild(cell: lt.Controls.Medical.Cell, row: ROIRow) {
    const frameIndex = toInteger(row.slice) - 1;
    const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(frameIndex);
    const child = frame.get_container().get_children().toArray()
      .find(item => item.get_metadata().Name === row.name);
    return child;
  }

  emptyDivsCollectionChanged(e, args, info) {
    let index = 0;
    const length = args.newItems.length;
    let div = null;
    if (args.get_action() === lt.NotifyLeadCollectionChangedAction.add) {
      for (; index < length; index++) {
        const div11 = args.newItems[index];
        div = div11.div;
        if (div11.parent.viewer.cellsArrangement === lt.Controls.Medical.CellsArrangement.random) {
          div11.backgroundColor = 'rgba(30, 30, 30, 1)';
        }
        this.enableDropTarget(div, info);
        div.addEventListener('mousedown', ev => {
          // console.log("ev:", ev);
          // if (ev.button == 2 && (ev.ctrlKey || ev.metaKey))
          if (ev.button === 2) {
            this.showSelectSeriesContextMenu(div11, ev.offsetX, ev.offsetY, info);
          }
        });
      }
    } else if (args.get_action() === lt.NotifyLeadCollectionChangedAction.remove) {
      for (; index < length; index++) {
        div = args.newItems[index].get_div();
        this.disableDropTarget(div, info);
      }
    }
  }

  enableDropTarget(elem, info): void {
    // console.log("enableDropTarget...");
    // console.log("elem:", elem);
    elem.addEventListener('dragover', this.dragOverFunction, true);
    elem.addEventListener('dragenter', this.emptyFunction, true);
    elem.addEventListener('dragleave', this.emptyFunction, true);
    elem.addEventListener('drop', e => {
      this.dropFunction(e, elem, info);
    }, true);
  }

  disableDropTarget(elem, info): void {
    // console.log("disableDropTarget...");
    elem.removeEventListener('dragover', this.dragOverFunction, false);
    elem.removeEventListener('dragenter', this.emptyFunction, false);
    elem.removeEventListener('dragleave', this.emptyFunction, false);
    elem.removeEventListener('drop', e => {
      this.dropFunction(e, elem, info);
    }, false);
  }

  dragOverFunction(e) {
    // console.log("dragOverFunction...");
    if (e.preventDefault) {
      e.preventDefault();
    }

    e.dataTransfer.dropEffect = 'copy';
    return false;
  }

  emptyFunction(e) {
  }

  dropFunction(e, elem, info) {
    e.preventDefault();
    // console.log("dropFunction...");
    const seriesId = e.dataTransfer.getData('seriesId');
    const seriesInstanceUID = e.dataTransfer.getData('seriesInstanceUID');
    const sourceCellID = e.dataTransfer.getData('sourceCellID') || null;
    console.log('seriesId:', seriesId);
    console.log('seriesInstanceUID:', seriesInstanceUID);
    const viewer = info.viewer;

    if (seriesInstanceUID.indexOf('overflow') !== -1) {
      return false;
    } else {
      if (e.stopPropagation) {
        e.stopPropagation();
      }
      if (e.preventDefault) {
        e.preventDefault();
      }

      if (null != seriesInstanceUID) {
        let length = viewer.layout.get_items().get_count();
        let cellDiv = null;
        let items = viewer.layout.get_items();
        let item;
        const emptyItems = viewer.get_emptyDivs().get_items();
        let position;
        let rowPosition;
        let colPosition;
        let bounds;
        let oldSeriesInstanceUID = null;
        for (let index = 0; index < length; index++) {
          const item = items.get_item(index);
          const divId = item.get_divID();
          cellDiv = document.getElementById(divId);
          if (cellDiv === elem) {
            position = item.get_position();
            rowPosition = item.get_rowPosition();
            colPosition = item.get_columnsPosition();
            bounds = item.get_bounds();
            oldSeriesInstanceUID = $('#' + divId).attr('seriesInstanceUID');
            break;
          }
        }

        if (position === undefined) {
          length = emptyItems.get_count();
          items = emptyItems;
          for (let index = 0; index < length; index++) {
            item = emptyItems.get_item(index);
            if (item.div.id === e.target.id) {
              position = item.get_position();
              rowPosition = item.get_rowPosition();
              colPosition = item.get_columnsPosition();
              bounds = item.get_bounds();
              break;
            }
          }
        }
        this.onSeriesDropped({
          viewer: viewer,
          viewerComponent: info.viewerComponent,
          oldSeriesInstanceUID: oldSeriesInstanceUID,
          seriesId: seriesId,
          seriesInstanceUID: seriesInstanceUID,
          position: position,
          rowPosition: rowPosition,
          colPosition: colPosition,
          bounds: bounds,
          seriesManagerService: info.seriesManagerService,
          sourceCellID
        });
      }
    }
    return false;
  }

  showSelectSeriesContextMenu(sender, x, y, info): void {
    // console.log("showSelectSeriesContextMenu...");
    const menu = new lt.Controls.Medical.Menu('Select Series');
    const cell = sender;
    let index = 0;
    const viewer = info.viewer;
    const length = viewer.layout.get_items().count;

    for (index = 0; index < length; index++) {
      const item = viewer.layout.get_items().get_item(index);
      menu.items.add(new lt.Controls.Medical.MenuItem(item.name, null, item));
    }
    menu.show(cell, x, y, lt.LeadRectD.empty);

    // Add menu item click handler
    menu.add_menuItemSelected(function (sender, e) {
      viewer.layout.highlightedItems.clear();
      const selectedCell = e.item.userData;

      viewer.layout.beginUpdate();
      viewer.emptyDivs.beginUpdate();

      if (viewer.cellsArrangement === lt.Controls.Medical.CellsArrangement.grid) {
        const selectedCellRowPosition = selectedCell.rowPosition;
        const selectedCellColumnsPosition = selectedCell.columnsPosition;

        selectedCell.rowPosition = cell.rowPosition;
        selectedCell.columnsPosition = cell.columnsPosition;
        cell.rowPosition = selectedCellRowPosition;
        cell.columnsPosition = selectedCellColumnsPosition;
      } else {
        const selectedCellPosition = selectedCell.position;
        const selectedBounds = selectedCell.bounds;

        selectedCell.bounds = cell.bounds;
        cell.bounds = selectedBounds;
        selectedCell.position = cell.position;
        cell.position = selectedCellPosition;
      }

      viewer.layout.endUpdate();
      viewer.emptyDivs.endUpdate();
      cell.selected = false;
      selectedCell.selected = true;
      viewer.layoutCells();
    });

    menu.add_menuItemHover(function (sender, e) {
      viewer.layout.highlightedItems.clear();
      viewer.layout.highlightedItems.add(e.item.userData);
    });

    menu.add_menuItemLeave(function (sender, e) {
      viewer.layout.highlightedItems.clear();
    });
  }

  onSeriesDropped(data): void {
    const viewer = data.viewer;
    const viewerComponent = data.viewerComponent;

    viewer.layout.beginUpdate();

    if (data.sourceCellID !== null) {
      const sourceCell = viewer.layout.get_items().toArray().find(i => i.get_divID() === data.sourceCellID);
      if (sourceCell) {
        viewer.layout.get_items().remove(sourceCell);
        this.disposeAutomation(sourceCell.get_automation());
        data.seriesManagerService.removeCell(sourceCell);
        sourceCell.dispose();
      }
    }

    // remove cell if it existing on the selected position
    const oldCell = this.getCellWithPosition(viewer, data.rowPosition, data.colPosition, data.position);
    if (oldCell) {
      viewer.layout.get_items().remove(oldCell);
      this.disposeAutomation(oldCell.get_automation());
      data.seriesManagerService.removeCell(oldCell);
      oldCell.dispose();
    }

    viewerComponent.openSingleSer(data.seriesId, data);
  }

  getCellWithPosition(viewer, rowPosition, colPosition, position) {
    let index = 0;
    const length = viewer.layout.items.count;
    let cell;
    const grid = viewer.cellsArrangement == lt.Controls.Medical.CellsArrangement.grid;
    for (index = 0; index < length; index++) {
      cell = viewer.layout.items.get_item(index);
      if (grid) {
        if (rowPosition != -1 && colPosition != -1) {
          if (cell.rowPosition == rowPosition &&
            cell.columnsPosition == colPosition) {
            return cell;
          }
        }
      } else {
        if (position !== -1) {
          if (cell.position === position) {
            return cell;
          }
        }
      }
    }
    return null;
  }

  disposeAutomation(automation) {
    if (automation == null) {
      return;
    }
    const container = automation.get_container();
    if (container) {
      container.get_children().clear();
      automation.detach();
    }
  }

  getCellMPRType(cell): string {
    let mprType = null;
    switch (cell.mprType) {
      case lt.Controls.Medical.CellMPRType.sagittal:
        mprType = 'Sagittal';
        break;
      case lt.Controls.Medical.CellMPRType.coronal:
        mprType = 'Coronal';
        break;
      case lt.Controls.Medical.CellMPRType.axial:
        mprType = 'Axial';
        break;
    }
    return mprType;
  }

  getCellMPRTypeFromString(mprType) {
    let mprTypeObj = null;
    switch (mprType) {
      case 'Sagittal':
        mprTypeObj = lt.Controls.Medical.CellMPRType.sagittal;
        break;
      case 'Coronal':
        mprTypeObj = lt.Controls.Medical.CellMPRType.coronal;
        break;
      case 'Axial':
        mprTypeObj = lt.Controls.Medical.CellMPRType.axial;
        break;
    }
    return mprTypeObj;
  }

  getRealId(fullID: string): string {
    const index = fullID.lastIndexOf('_');
    return index === -1 ? fullID : fullID.substr(0, index);
  }

  generateUID(from_uid): string {
    let uid_part1 = from_uid.substr(0, 40);
    uid_part1 = uid_part1[-1] == '.' ? uid_part1.substr(0, 39) : uid_part1;
    // let uid_part2 = Math.floor(Math.random() * Date.now());
    // return uid_part1+"."+uid_part2;
    return uid_part1;
  }

  buildMPRCellInstance(mprCell): void {
    // console.log("buildMPRCellInstance...");
    const seriesInstanceUID = mprCell.get_seriesInstanceUID();
    const frames: Array<lt.Controls.Medical.Frame> = mprCell.get_frames().toArray();
    const mprType = this.getCellMPRType(mprCell);

    const sopInstanceUID = this.generateUID(seriesInstanceUID);
    const sopClassUID = this.generateUID(seriesInstanceUID);

    frames.forEach((frame, index) => {
      (<any>frame).Instance = {
        SOPClassUID: sopClassUID + '_' + mprType + '_SCUID_' + index,
        SOPInstanceUID: sopInstanceUID + '_' + mprType + '_SIUID_' + index
      };
    });
  }

  initViewerData(series, _this, info): Observable<any> {
    this.setupTimepointForSeries(series);

    if (info.selectedPage === 'QC') {
      const qcSelectedTask = JSON.parse(localStorage.getItem('qc.selectTask'));
      series.timepoint = qcSelectedTask.visitName ? qcSelectedTask.visitName : 'Missing';
    }

    if (_this.viewerData === null) {
      _this.viewerData = {};
      const userId = JSON.parse(localStorage.getItem('userId'));

      let project: any = {};
      if (info.selectedPage === 'Operations Dashboard') {
        project.id = _this.studyId;
      } else {
        project = this.getCurrentProject();
      }
      const auth = _this.authenticationService.authenticate();
      const patient = _this.imagingProjectService.getPatientFromSeriesID(series.seriesId);
      const bucket = _this.imagingProjectService.getStudy(project.id);
      const studyUserRoles = _this.studyUserService.getStudyUserRoles(project.id, userId);

      // series data
      _this.viewerData[series.seriesId] = series;

      const result = forkJoin({ auth, patient, bucket, studyUserRoles });

      return new Observable<any>(subscriber => {
        result.subscribe(response => {
          _this.viewerData.authenticationCode = response.auth;
          _this.viewerData.patient = response.patient['data'];
          _this.viewerData.bucket = response.bucket['data'].bucketLocation;
          _this.viewerData.studyUserRoles = response.studyUserRoles['data'];
          _this.viewerData.selectedPage = info.selectedPage;


          if (_this.viewerData.studyUserRoles && _this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
            _this.viewerData.toolbarExcludeItems = ['ShowDicom'];
          }

          subscriber.next(_this.viewerData);
          subscriber.complete();
        }, err => subscriber.error(err));
      });
    } else {
      _this.viewerData[series.seriesId] = series;

      return of(_this.viewerData);
    }
  }

  asyncResizeTrigger() {
    this.syncResizeOn = Date.now();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  getCurrentProject() {
    return JSON.parse(localStorage.getItem('project'));
  }

  isMranoProject(): boolean {
    const project = this.getCurrentProject();
    const activities = JSON.parse(localStorage.getItem('activities')) as string[];
    return (project && project.hasReadingAccessForMRANO) || (activities?.includes("qc.view.all.uploaded.data") && this.projectIncludesRanoEndpoint);
  }

  isIFProject(): boolean {
    const project = this.getCurrentProject();
    return project && project.hasReadingAccessForIF;
  }


  isDemriqProject(): boolean {
    const project = this.getCurrentProject();
    return project && project.hasReadingAccessForDEMRIQ;
  }

  isRanoAdudicationProject(): boolean {
    const project = this.getCurrentProject();

    return project && project.isRANOAdjudication;
  }

  getRanoAdudicationPerson() {
    if (this.isRanoAdudicationProject()) {
      return this.getPerson();
    }

    return undefined;
  }


  isUserHasRole(roleNames: any[], roleNameToMatch: string):boolean {
    return roleNames.map(item => item.roleName).includes(roleNameToMatch);
  }

  private _setRowVisibility(row: ROIRow, visibility: boolean): void {
    if (row.child) {
      this.setVisibilityEvent.emit({ row, visibility });
    }
  }

  getRoiFilterPredicate() {
    return (row: ROIRow, filters: string): boolean => {
      const filterArray = filters;

      if (!filterArray.length) {
        this._setRowVisibility(row, true);
        return true;
      }

      const isExists = filterArray.includes(row.label);
      this._setRowVisibility(row, isExists);
      return isExists;
    };
  }

  getRemainder(divident, divisor) {
    return divident % divisor;
  }

  isInstanceAscending(cell) {
    let isAscending = false
    const cellFrames = cell.get_frames().toArray();
    if (cellFrames.length > 1){
        isAscending = cellFrames[0].instanceNumber < cellFrames[1].instanceNumber;
    }
    return isAscending;
  }

  getJWTData() {
    const jwtString = localStorage.getItem('currentUser');
    const jwt = CompactJwt.decodeActivities(helper.decodeToken(jwtString));
    this.JWTData = {};

    if (jwtString && jwt) {
        this.JWTData['userId'] = jwt.jti;
        this.JWTData['activities'] = jwt.permissions.activities;
        this.JWTData['dataglobals'] = jwt.permissions.globals;
        this.JWTData['subject'] = jwt.sub;
        this.JWTData['institution'] = jwt.iss;
    }
    return this.JWTData;
  }

  getPerson() {
    const jwtData = this.getJWTData();

    return jwtData.subject;
  }
  private setupTimepointForSeries(series) {
    if (!series.timepoint) {
      const timepoint = series?.visitConfig?.visitName;
      series.timepoint = !!timepoint ? timepoint : 'Missing';
      if (series.timepoint === 'Missing') {
        series.timepoint = series?.studyInfo?.visitName;
      }
    }
    const hideVisitChronology = series?.visitConfig?.hideVisitChronology;
    if (hideVisitChronology) {
      series.timepoint = series.visitConfig.visitBlindName;
      if (series.timepoint === 'Missing' || series.timepoint === 'BlindName' || series.timepoint === undefined) {
        series.timepoint = series?.studyInfo?.visitBlindName;
      }
    }
  }
}
