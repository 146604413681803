import {Component, OnInit, ViewChild} from '@angular/core';

import {BatchLogicService} from '../../../../_services/batch-logic.service';
import {ActivatedRoute, Router} from '@angular/router';

import {MatDialog} from '@angular/material/dialog';
import {
  BatchReaderDialogComponent,
  BatchReaderDialogData,
  BatchReaderDialogResult
} from '../batch-reader-dialog/batch-reader-dialog.component';
import {FormControl} from '@angular/forms';
import {ImagingProjectService, ReadingConfigFlexibleService, UserService} from '../../../../_services';
import {PatientService} from '../../../../_services/patient.service';
import {MessageDialogComponent} from '../../../../components/controls/message-dialog/message-dialog.component';
import {FormMode} from '../../../../core/constants/form-mode';
import {BatchInitiationMethod} from '../../../../core/constants/BatchLogic/batch-initiation-method';
import {BatchInitiationOption} from '../../../../core/constants/BatchLogic/batch-initiation-option';
import {BatchStatus} from '../../../../core/constants/BatchLogic/batch-status';
import {ReaderType} from '../../../../core/constants/reader-type';
import {ReadingType} from '../../../../core/constants/reading-type';
import {Store} from '@ngxs/store';
import {BatchReadingModel} from '../../../../_models/BatchLogic/batch-reading-model';
import {FlexibleConfig} from '../../../../core/interfaces/flexible-config';
import {StudyModel} from '../../../../_models/ImagingProject/study-model';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
import {BatchStatusModel} from '../../../../_models/BatchLogic/batch-status-model';
import {
  BatchReadingsSelectorComponent,
  BatchReadingsSelectorDialogData
} from '../batch-readings-selector/batch-readings-selector.component';
import {User} from '../../../../_models/user';

interface BatchConfigModel {
  initiationMethod: BatchInitiationMethod;
  initiationOption: BatchInitiationOption;
  readingType?: ReadingType;
  flexibleConfigId: number;
  numberOfReadings: number;
  batchCount?: string;
}

export interface BatchRequestPayload {
  id: number;
  studyId: number;
  readerId: number;
  adjudication: boolean;
  moderation: boolean;
  numberOfReadings: number;
  batchConfig: BatchConfigModel;
  batchCount: number;
  initiated: boolean;
  cancel: boolean;
  status: BatchStatusModel;
  readings: BatchReadingModel[];
}

@Component({
  selector: 'app-batch',
  templateUrl: './batch.component.html',
  styleUrls: ['./batch.component.css']
})

export class BatchComponent implements OnInit {

  readonly initiationMethods = BatchInitiationMethod;
  readonly initiationOptions = BatchInitiationOption;
  readonly batchStatuses = BatchStatus;

  displayedColumns: string[] = ['readingId', 'patientId', 'visitId', 'modality', 'status', 'action'];
  readingDataSource: MatTableDataSource<BatchReadingModel>;

  batch: BatchRequestPayload;

  mode: FormMode = FormMode.NEW;
  studyId: number;
  study: StudyModel;
  selectedReadingsConfigsFilter: any;
  selectedReader: any;
  selectedFlexibleConfig: FlexibleConfig;
  numberOfReadings: number;

  formControlQuantity = new FormControl();
  formControlDate = new FormControl();

  readonly formModes = FormMode;
  readonly readingTypes = ReadingType;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private batchLogicService: BatchLogicService,
    private userService: UserService,
    private imagingProjectService: ImagingProjectService,
    private patientService: PatientService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    public dialog: MatDialog,
    private store: Store
  ) {
    this.batch = this.createNewBatch();
  }

  ngOnInit() {
    this.readingDataSource = new MatTableDataSource<BatchReadingModel>();
    this.studyId = parseInt(this.route.snapshot.params.projectId, 10);
    this.imagingProjectService.getStudy(this.studyId).subscribe(studyResult => {
      this.study = studyResult.data;
      this.store.dispatch(new SetPageHeaderTitle(`Details: ${this.study.name}`));
    });
    const batchId = parseInt(this.route.snapshot.params.batchId, 10);
    this.mode = !batchId ? FormMode.NEW : FormMode.EDIT;
    if (this.mode === FormMode.NEW) {
      const batch = this.createNewBatch();
      this.initBatchReadingInfo(batch);
    } else {
      this.batchLogicService.getBatch(batchId, this.studyId).subscribe(batchResp => {
        const batch = batchResp.data;
        batch['adjudication'] = batch.readerType === ReaderType.ADJUDICATION_READER;
        batch['moderation'] = batch.readerType === ReaderType.MODERATION_READER;
        this.numberOfReadings = batch.batchConfig.numberOfReadings;
        this.initBatchReadingInfo(batch);
        this.readingConfigFlexibleService.getById(batch.batchConfig.flexibleConfigId).subscribe(response => {
          this.selectedFlexibleConfig = response.data;
        });
      });
    }
    this.prepareReadingVisits();
  }

  createNewBatch(): BatchRequestPayload {
    return {
      id: null,
      batchConfig: {
        numberOfReadings: 0,
        // batchCount: 'by user only',
        readingType: null,
        flexibleConfigId: null,
        initiationMethod: BatchInitiationMethod.MANUALLY,
        initiationOption: BatchInitiationOption.BY_USER_ONLY
      },
      readings: [],
      initiated: false,
      status: null,
      adjudication: null,
      moderation: null,
      batchCount: null,
      cancel: false,
      numberOfReadings: 0,
      readerId: null,
      studyId: null
    };

    // const batch = {} as BatchRequestPayload;
    // batch.batchConfig = {} as BatchConfigModel;
    //
    // batch.batchConfig.initiationMethod = BatchInitiationMethod.MANUALLY;
    // batch.batchConfig.initiationOption = BatchInitiationOption.BY_USER_ONLY;
    // batch.batchConfig.flexibleConfigId = null;
    // batch.batchConfig.batchCount = 'by user only';
    //
    // batch.adjudication = null;
    // batch.batchCount = 0;
    // batch.cancel = false;
    // batch.initiated = false;
    // batch.numberOfReadings = 0;
    // batch.readerId = null;
    // batch.studyId = this.studyId;
    // batch.readings = [];
    // return batch;
  }

  initBatchReadingInfo(batch): void {
    this.batch = batch;

    let patientIds = [];
    let userIds = [];
    let visitConfigsIds = [];

    if (this.batch.readerId != null) {
      userIds.push(this.batch.readerId);
    }
    this.batch.readings.forEach(reading => {
      patientIds.push(reading.patientId);
      userIds.push(reading.readerId);
      reading.visits.forEach(visit => {
        visitConfigsIds.push(visit.visitConfigId);
      });
    });
    patientIds = Array.from(new Set(patientIds));
    userIds = Array.from(new Set(userIds));
    visitConfigsIds = Array.from(new Set(visitConfigsIds));

    if (this.batch.batchConfig.flexibleConfigId != null) {
      this.readingConfigFlexibleService.getById(this.batch.batchConfig.flexibleConfigId).subscribe(configResp => {
        const configData = configResp['data'];
        if (configData != null) {
          configData['name'] = configData.config.endpoint.name;
          this.selectedReadingsConfigsFilter = configData;
        }
      });
    }

    if (userIds.length > 0) {
      this.userService.getByListIds(userIds).subscribe(usersResp => {
        const usersData = usersResp.data;
        if (!!usersData) {
          this.selectedReader = usersData.find(u => u.id === this.batch.readerId);
          this.selectedReader.name = `${this.selectedReader.firstName} ${this.selectedReader.lastName}`;
        } else {
          this.selectedReader = {id: 0, name: 'SYSTEM'};
        }

        this.imagingProjectService.getVisitConfigsByIds(visitConfigsIds).subscribe(visitConfigsResp => {
          const visitConfigsData = visitConfigsResp.data;
          this.patientService.getByListIds(patientIds).subscribe(patientResp => {
            const patients = patientResp.data;
            this.batch.readings.forEach(reading => {
              let user: any = {id: 0, firstName: 'SYSTEM'};
              if (!!usersData) {
                user = usersData.find(u => u.id === reading.readerId);
              }
              const patient = patients.find(p => p.id === reading.patientId);

              reading.patientCode = patient.patientCode;
              reading.readerUser = user as User;
              reading.visits.forEach(visit => {
                const visitConfig = visitConfigsData.find(targetVisitConfig => targetVisitConfig.id === visit.visitConfigId);
                visit.visitName = visitConfig.visit.name;
              });
            });
            this.prepareReadingVisits();
            this.updateReadingsTable();
          });
        });
      });
    }
  }

  onEditReader(): void {
    const dialogData: BatchReaderDialogData = {
      studyId: this.studyId,
      readingType: this.batch.batchConfig.readingType,
      selectedConfig: this.selectedFlexibleConfig,
      selectedReader: this.selectedReader
    };
    const dialogRef = this.dialog.open(BatchReaderDialogComponent, {
      height: '300px',
      width: '600px',
      disableClose: true,
      data: dialogData
    });

    dialogRef.afterClosed().subscribe((result: BatchReaderDialogResult) => {
      if (result.result === 'ok') {
        if (result.readingType === ReadingType.BASIC_READING || result.readingType === ReadingType.LEVEL1_READING) {
          this.batch.adjudication = false;
          this.batch.moderation = false;
        } else if (result.readingType === ReadingType.ADJUDICATION_READING) {
          this.batch.adjudication = true;
            this.batch.moderation = false;
        } else if (result.readingType === ReadingType.MODERATION_READING) {
            this.batch.adjudication = false;
            this.batch.moderation = true;
        }
        this.batch.batchConfig.flexibleConfigId = result.config.id;
        this.batch.batchConfig.readingType = result.readingType;
        this.batch.readerId = result.reader.id;
        this.selectedReader = result.reader;
        this.selectedFlexibleConfig = result.config;
        this.selectedReadingsConfigsFilter = result.config;
      }
    });

  }

  onInitiationMethodChanged($event): void {
    if ($event.value === BatchInitiationMethod.MANUALLY) {
      this.batch.batchConfig.initiationOption = BatchInitiationOption.BY_USER_ONLY;
    } else if ($event.value === BatchInitiationMethod.AUTO) {
      this.batch.batchConfig.initiationOption = BatchInitiationOption.BY_QUANTITY;
    }
  }


  initiateBatch(): void {
    this.batchLogicService.initiateBatch(this.studyId, this.batch.id, true).subscribe(batchResp => {
      this.initBatchReadingInfo(batchResp['data']);
      this.router.navigate([`operationdashboard/${this.studyId}`]).then(r => {
      });
    });
  }

  onCancelBatch(): void {
    const message = 'Batch will be closed. Are you sure? YES/NO';

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '200px',
      width: '600px',
      disableClose: true,
      data: {title: 'Cancel batch', message: message, showOk: true, showCancel: true, textOK: 'YES', textCancel: 'NO', html: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.batchLogicService.cancelBatch(this.studyId, this.batch.id, true).subscribe(batchResp => {
          this.initBatchReadingInfo(batchResp['data']);
          this.router.navigate([`operationdashboard/${this.studyId}`]).then(r => {
          });
        });
      }
    });
  }

  onInitiateBatch(): void {
    const message = 'Please confirm initiation of Batch.';

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '200px',
      width: '600px',
      disableClose: true,
      data: {title: 'Initiate batch', message: message, showOk: true, showCancel: true, textOk: 'YES', textCancel: 'NO', html: false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.initiateBatch();
      }
    });
  }

  onAddReadingToList(): void {
    const data: BatchReadingsSelectorDialogData = {
      studyId: this.studyId,
      flexibleConfig: this.selectedFlexibleConfig,
      reader: this.selectedReader,
      batch: this.batch,
      readings: this.batch.readings,
      mode: this.mode,
      readingType: this.batch.batchConfig.readingType,
    };

    this.prepareReadingVisits();

    const dialogRef = this.dialog.open(BatchReadingsSelectorComponent, {
      height: '800px',
      width: '1200px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result: BatchReadingModel[]) => {
      if (result) {
        this.batch.readings = [...this.batch.readings,
          ...result.filter(reading => !this.batch.readings.find(target => target.id === reading.id))];
        this.updateReadingsTable();
      }
    });
  }

  onCancel(): void {
    this.router.navigate([`operationdashboard/${this.studyId}`]).then(r => {
    });
  }

  onSubmit() {
    const manualBatch = {
      studyId: this.studyId,
      readerId: this.selectedReader.id,
      adjudication: this.batch.adjudication,
      moderation: this.batch.moderation,
      numberOfReadings: null,
      initiationMethod: this.batch.batchConfig.initiationMethod,
      initiationOption: this.batch.batchConfig.initiationOption,
      batchCount: null,
      initiated: this.batch.initiated,
      cancel: this.batch.cancel,
      flexibleConfigId: this.selectedReadingsConfigsFilter.id,
      readings: [],

      readingType: this.batch.batchConfig.readingType,
      readerType: null,
    };

    switch (this.batch.batchConfig.readingType) {
      case ReadingType.BASIC_READING:
        manualBatch.readerType = ReaderType.BASIC_READER;
        break;
      case ReadingType.LEVEL1_READING:
        manualBatch.readerType = ReaderType.LEVEL1_READER;
        break;
      case ReadingType.ADJUDICATION_READING:
        manualBatch.readerType = ReaderType.ADJUDICATION_READER;
        break;
      case ReadingType.MODERATION_READING:
        manualBatch.readerType = ReaderType.MODERATION_READER;
        break;
    }

    if (this.mode === FormMode.EDIT) {
      if (this.batch['id'] != null) {
        manualBatch['id'] = this.batch.id;
      }
    }

    if (this.batch.batchConfig.initiationMethod === BatchInitiationMethod.AUTO) {
      if (this.batch.batchConfig.initiationOption === BatchInitiationOption.BY_QUANTITY) {
        manualBatch.batchCount = this.formControlQuantity.value;
      } else if (this.batch.batchConfig.initiationOption === BatchInitiationOption.BY_DATE_AND_TIME) {
        manualBatch.batchCount = this.formControlDate.value.toUTCString();
      }
    } else if (this.batch.batchConfig.initiationMethod === BatchInitiationMethod.MANUALLY) {
      if (this.batch.batchConfig.initiationOption === BatchInitiationOption.BY_USER_ONLY) {
        manualBatch.batchCount = BatchInitiationOption.BY_USER_ONLY;
      }
    }

    if (this.batch.readings != null) {
      this.batch.readings.forEach((r, index) => {
        const reading = {
          queueNumber: index,
          id: r.id,
        };
        manualBatch.readings.push(reading);
      });
    }
    manualBatch.numberOfReadings = manualBatch.readings.length;


    if (this.mode === FormMode.NEW) {
      this.batchLogicService.createBatch(manualBatch).subscribe(batchResp => {
        this.router.navigate([`/operationdashboard/${this.studyId}`]).then(r => {
        });
      });
    } else if (this.mode === FormMode.EDIT) {
      this.batchLogicService.updateBatch(this.batch.id, manualBatch).subscribe(batchResp => {
        this.router.navigate([`/operationdashboard/${this.studyId}`]).then(r => {
        });
      });
    }
  }

  onRemoveReading(reading): void {
    this.batch.readings = this.batch.readings.filter(r => r.id !== reading.id);
    this.updateReadingsTable();
  }

  updateReadingsTable(): void {
    this.prepareReadingVisits();
    this.readingDataSource.data = this.batch.readings;
    this.readingDataSource.paginator = this.paginator;
    this.readingDataSource.sort = this.sort;
  }

  prepareReadingVisits(): void {
    for (const reading of this.batch.readings) {
      const uniqueVisitNames = new Set<string>();
      for (const visit of reading.visits) {
        for (const modality of visit.modalities) {
          uniqueVisitNames.add(modality.name);
        }
      }
      reading.uniqueVisitNames = Array.from(uniqueVisitNames);
    }
  }

}
