import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {formatDate, Location} from '@angular/common';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import * as _moment from 'moment';
import {JwtUtilService} from '../../../_helpers/jwt-util.service';
import {CommentService} from '../../../_services/comment.service';
import {DataUploadService} from '../../../_services/data-upload.service';
import {ImagingAnalysisService} from '../../../_services/imaging-analysis.service';
import {ImagingProjectService} from '../../../_services/imaging-project.service';
import {PatientService} from '../../../_services/patient.service';
import {QualityControlService} from '../../../_services/quality-control.service';
import {SeriesService} from '../../../_services/series.service';
import {StudySequenceLabelService} from '../../../_services/study-sequence-label.service';
import {StudyService} from '../../../_services/study.service';
import {UserService} from '../../../_services/user.service';
import {MessageDialogComponent} from '../../../components/controls/message-dialog/message-dialog.component';
import {ViewerCoreLtComponent} from '../../Viewer/viewer-core-lt/viewer-core-lt.component';
import {QCDicomchecksDialogComponent} from '../qcdicomchecks-dialog/qcdicomchecks-dialog.component';
import {QCModalitysubmitDialogComponent} from '../qcmodalitysubmit-dialog/qcmodalitysubmit-dialog.component';
import {QCPatienteditDialogComponent} from '../qcpatientedit-dialog/qcpatientedit-dialog.component';
import {
  QCPatientBirthDateEditDialogComponent
} from '../qcpatientbirthdateedit-dialog/qcpatientbirthdateedit-dialog.component';
import {QcChangeVisitDialogComponent} from '../qc-change-visit-dialog/qc-change-visit-dialog.component';
import {UploadStatusName} from '../../../core/constants/upload-status-name';
import {UploadStatus} from '../../../core/interfaces/upload-status';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import * as _ from 'lodash';
import {ViewerAuthenticationService} from 'src/app/_services/interfaces/viewer/viewer-authentication-service';
import {ModalityService, ReadingConfigFlexibleService, StudyUserService} from '../../../_services';
import {FormControl} from '@angular/forms';
import {QCTask} from '../../../_models/QualityControl/qs-task';
import {forkJoin, Observable, Subject, Subscription} from 'rxjs';
import {Utils} from 'src/app/_services/leadtools/lead-tools-utils';
import {ResponseCode} from '../../../core/constants/response-code';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {NoUploadDialogComponent, NoUploadDialogData} from '../no-upload-dialog/no-upload-dialog.component';
import {
  PushDownloadProcess,
  SetDownloading,
  UpdateDownloadProcess
} from '../../../core/data-management/actions/app.action';
import {DownloadService} from '../../../_services/download.service';
import {DownloadPayload} from '../../../core/payload/download-payload';
import {DownloadMonitoringItem} from '../../../components/download-monitoring/download-monitoring.component';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {DownloadModel} from '../../../_models/Download/download.model';
import {BasicResponse} from '../../../core/interfaces/basic-response';
import {
  QcLateralitycorrectionDialogComponent
} from '../qc-lateralitycorrection-dialog/qc-lateralitycorrection-dialog.component';
import { ReadingDXAService } from 'src/app/_services/reading-dxa.service';
import {CompactJwt} from "../../../_helpers/CompactJwt";
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { EdtfEditComponent } from '../../DataUpload/edtf-edit/edtf-edit.component';
import { HttpCacheService } from 'src/app/_services/http-cache/http-cache.service';
import { environment } from 'src/environments/environment';
import { RefreshAPIService } from 'src/app/_services/refresh-api.service';
import { takeUntil, tap } from 'rxjs/operators';
import { QcPatientTasksDialogComponent } from '../qc-patient-tasks-dialog/qc-patient-tasks-dialog.component';

const moment = _moment;
const helper = new JwtHelperService();

interface SeriesElement {
  id: number;
  image: number;
  label: any;
  scanDate: string;
  dicomChecks: any;
  comment: string;
  status: boolean;
  repeat: boolean;
}

interface ModalityElement {
  id: number;
  name: string;
}

interface PatientUploadFile {
  id: number;
  created: string;
  originalFileName: string;
  storageFileName: string;
  storagePath: string;
  bucketName: string;
  status: UploadStatus;
  uploadResourceType: UploadStatus;
  fileType: string;
  fileSize: number;
  processingFlag: any;
  url: string | null;
  changeVisitConfigIdProcess: boolean | null;
  username?: string;
}

interface PatientUploadGeneralInfo {
  id: number;
  created: string;
  imageProjectId: number;
  siteConfigId: number;
  modalities: { modality: any[] };
  patientId: number;
  visitConfigId: number;
  visitId: number;
  edtf: { blocks: any[], version: string };
  edcf: any;
  comment: string;
  status: UploadStatus;
  files: PatientUploadFile[];
  uploadUserId: number;
  reasonChangeModalityComment: any;
  unscheduled: any;
  executionId: any;
  changeVisitConfigIdProcess: any;
}

interface BackgroundProcessesMonitoringInfo {
  id: number;
  dateStart: string;
  dateEnd: string;
  workload: string;
  visitConfigId: number;
  patientId: number;
  state: string;
  parentExecutionId: number;
  stateMsg: string;
}

// radiobotics processes interfaces
interface RawRequest {
  studyUid: string;
  product: string;
  resources: string[];
}

interface Data {
  jswLateralMm: number;
  osteophyteFemurMedialOarsi: number;
  jswLateralOarsi: number;
  oaPresence?: any;
  jswMedialValid?: any;
  jsnPresence?: any;
  osteophyteFemurLateralOarsi: number;
  osteophytePresence?: any;
  sclerosisFemurMedialOarsi: number;
  sclerosisPresence?: any;
  osteophyteTibiaLateralOarsi: number;
  sclerosisFemurLateralOarsi: number;
  jswMedialMm: number;
  jswMedialOarsi: number;
  osteophyteTibiaMedialOarsi: number;
  sclerosisTibiaMedialOarsi: number;
  sclerosisTibiaLateralOarsi: number;
  jswLateralValid?: any;
}

interface Knee {
  side: string;
  data: Data;
  dicomInstanceUid: string;
  error: string;
  dicomKneeView: string;
  view: string;
}

interface Results {
  repeatInstances: number;
  knees: Knee[];
  version: string;
  containsDoubleKnees?: any;
  numberInstances: number;
  error: string;
}

interface RawResponse {
  analysisId: string;
  status: string;
  product: string;
  message: string;
  results: Results;
  resources: string[];
  est_completion_time: string;
  completion_time: string;
}

interface FileSize {
}

interface ResultZipFile {
  storageBucket: string;
  storageFilePath: string;
  fileSize: FileSize;
}

interface RadioboticsProcessesInfo {
  id: number;
  visitConfigId: number;
  seriesId: number;
  analysisId: string;
  state: string;
  statusUrl: string;
  rawRequest: RawRequest;
  rawResponse: RawResponse;
  resultZipFile: ResultZipFile;
  executionId: number;
  createdDate?: any;
  lastModifiedDate?: any;
}
// end radiobotics processes interfaces

// dxa processes interfaces
interface DXAProcessesInfo {
  id: number;
  visitConfigId: number;
  seriesId: number;
  analysisId: string;
  status: string;
  statusUrl: string;
  rawRequest: RawRequest;
  rawResponse: RawResponse;
  resultZipFile: ResultZipFile;
  executionId: number;
  createdDate?: any;
  lastModifiedDate?: any;
}
// end dxa processes interfaces

enum QcEventType {
  MODALITY = 'MODALITY',
  VISIT = 'VISIT'
}

@Component({
  selector: 'app-qcdetails',
  templateUrl: './qcdetails.component.html',
  styleUrls: ['./qcdetails.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QCDetailsComponent implements OnInit, OnDestroy {

  public get QcEventType(): typeof QcEventType{
    return QcEventType;
  }

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  @ViewChild('viewer', {read: ViewContainerRef}) viewer: ViewContainerRef;
  @ViewChild('viewerLT', {read: ViewContainerRef}) viewerLT: ViewContainerRef;
  @ViewChild('tablecontiner', {read: ElementRef}) tablecontiner: ElementRef;
  @ViewChild('viewercontiner', {read: ElementRef}) viewercontiner: ElementRef;
  newLTViewer: any = null;

  @ViewChild('containersPaginator') containersPaginator: MatPaginator;
  @ViewChild('containersSort', {read: MatSort, static: false}) containersSort: MatSort;
  @ViewChild('edtfDataForm') edtfData: EdtfEditComponent;

  currentImageProjectId: number;

  isEnableToldMRI: boolean;
  selectedQCTask: QCTask;
  uploadGeneralInfo: PatientUploadGeneralInfo;
  isAdvancedAnalysisDisabled: boolean = true
  isGBMSegmentationDisabled: boolean = true
  qcVisitId = null;
  qcVisitStatus = 'not_set';
  qcVisitLock = false;
  qcVisitModalityPending = true;
  qcVisitComment = '';
  patient: any; // @TODO important entity, model type should be created
  flexibleConfigs: any[];
  selectedFlexibleConfigControl = new FormControl();
  uniqueEndpointNameFlexibleConfigs: any[];
  selectedFlexibleConfigControlForLock  = new FormControl();
  DynamikaAIFlexibleConfigControl = new FormControl();
  DynamikaAIControl = new FormControl();
  DynamikaAIConfig = new FormControl();

  patientUploadGeneralInfoFilesDataSources: MatTableDataSource<PatientUploadFile>[] = [];
  patientUploadGeneralInfos: PatientUploadGeneralInfo[] = [];
  backgroundProcessesMonitoringInfoDataSources: MatTableDataSource<BackgroundProcessesMonitoringInfo>;
  backgroundProcessesMonitoringInfos: BackgroundProcessesMonitoringInfo[] = [];

  // radiobotics processes variables
  radioboticsProcessesInfoDataSources: MatTableDataSource<RadioboticsProcessesInfo>;
  radioboticsProcessesInfos: RadioboticsProcessesInfo[] = [];
  @ViewChild('containersPaginatorForRadioboticsProcesses') containersPaginatorForRadioboticsProcesses: MatPaginator;
  // end radiobotics processes variables

  // dxa processes variables
  dXAProcessesInfoDataSources: MatTableDataSource<DXAProcessesInfo>;
  dXAProcessesInfos: DXAProcessesInfo[] = [];
  @ViewChild('containersPaginatorForDXAProcesses') containersPaginatorForDXAProcesses: MatPaginator;
  // end dxa processes variables

  visits: { id: number, name: string, locked: boolean }[] = [];

  iagProjectManager = false;
  allPatientsCodes = [];
  patientCodePatternRegEx = '';
  patientCodePatternMessage = '';

  tempSeriesComment = '';

  modalities = [];
  seriesCombined = [];
  detailedDataModality = [];
  dataSourceDetailedDataModality: any;

  detailedDataModalityOther = [];
  dataSourceDetailedDataModalityOther: any;

  label_counter_state = 0;
  label_counter_state_was_updated = false;

  detailedDataLabels = [];
  detailedDataComments = [];

  displayedColumnsSeries: string[] = ['download', 'image', 'label', 'scanDate', 'dicomChecks', 'modificationType', 'comment', 'status', 'repeat'];
  displaydColumnsOtherSeries: string[] = ['image-other', 'seriesDescription-other', 'label-other', 'scanDate-other', 'modificationType-other'];
  displayedColumnsVisit: string[] = ['modality', 'uploadDate', 'qcModalityDate', 'technologist', 'status', 'lock', 'comment'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('modalityInput') modalityInput: ElementRef<HTMLInputElement>;


  tabControlValue = 0;
  tabControlValueBeforeSeriesChanged = null;
  blockButtonsUntilModalityUpdate = true;

  waitVisitResponse = false;
  isAllModalitiesLocked = false;

  showModalSpinner = false;
  showModalSpinnerTitleOther = false;
  showModalSpinnerTitleLoadingData = false;

  timeTrackData = {
    series: null
  };

  separatorKeysCodes: number[] = [ENTER, COMMA];
  allModalities = [];
  modalitiesSelected: any[] = [];
  downloadSeries: any[] = [];
  showViewer = false;
  shouldShowDICOM = true;
  newVar = false;
  viewerData = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;
  backgroundProcessLoading = false;
  radioboticsProcessLoading = false;
  dXAProcessLoading = false;

  visitNoUpload = false;
  downloading = false;

  qcVisit;
  updatingStatusSeries: any = {};

  debOpenViewer = _.debounce(seriesId => {
    if (this.viewerDataIsReady) {
      this.openViewer(seriesId);
    }
  }, 500);

  initViewerDataReady = false;
  lateralityCorrection: boolean;
  radiobotics: boolean;
  protected unsubscribe = new Subject<void>();
  refreshAPISubscription: Subscription;
  selectedConfigsForLock: string[] = [];
  qcVisitLockFlag: boolean;
  generatedEndpoints: any[];
  containerErrorMessage: string[] = ["module 'lib' has no attribute 'OpenSSL_add_all_algorithms'", 'Time-out'];
  retryButtonLoading = [];
  patientTaskList: QCTask[] = [];
  selectedQCTaskFromDialog: QCTask;

  constructor(
    public dialog: MatDialog,
    public dialogMessage: MatDialog,
    public dialogModalityWarning: MatDialog,
    public dialogPatientEdit: MatDialog,
    public dialogPatientBirthDateEdit: MatDialog,
    private imagingProjectSerivce: ImagingProjectService,
    private studySequenceLabelservice: StudySequenceLabelService,
    private commentService: CommentService,
    private seriesService: SeriesService,
    private imagingAnalysisService: ImagingAnalysisService,
    private imagingProjectService: ImagingProjectService,
    private qualityControlService: QualityControlService,
    private dataUploadService: DataUploadService,
    private userService: UserService,
    private patientService: PatientService,
    private studyService: StudyService,
    private jwtUtilService: JwtUtilService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private store: Store,
    private authenticationService: ViewerAuthenticationService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private studyUserService: StudyUserService,
    private utils: Utils,
    private toastyService: ToastyService,
    private modalityService: ModalityService,
    private downloadService: DownloadService,
    private location: Location,
    private dxaReadingService: ReadingDXAService,
    private _cache: HttpCacheService,
    private refreshAPIService: RefreshAPIService
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('QC series'));
    this.initQcDetailsList();

    setTimeout(() => {
      this.preOpenLTViewer();
    }, 200);

    this.qualityControlService.patientTasklist.subscribe((data: any[]) => {
      if (data) {
        this.patientTaskList = [...new Map(data.map((item) => [item["visitConfigId"], item])).values()];
      }
    })

    // refresh APIs
    this.refreshAPISubscription = this.refreshAPIService.refreshAPIData
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(response => {
      if (response) {
        if(response.componentUrl = this.router.url) {
            this.initQcDetailsList();
          }
        }
    });
  }

  onOpenTaskListDialog() {
    const dialogRef = this.dialog.open(QcPatientTasksDialogComponent, {
      data: {
        taskList: this.patientTaskList.filter(task => { return task.visitConfigId !== this.selectedQCTask.visitConfigId }),
        patientCode: this.selectedQCTask.patientCode
      },
      disableClose: true,
      minWidth: '900px',
      minHeight: '300px',
      maxHeight: '800px'
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.selectedQCTaskFromDialog = data;
        this.downloadSeries = [];
        this.DynamikaAIConfig.setValue(null);
        this.DynamikaAIControl.setValue(null);
        this.initQcDetailsList();
      }
    })
  }

  initQcDetailsList() {
    this.timeTrackData.series = null;
    this.qcVisitId = null;
    this.qcVisitStatus = 'not_set';
    this.qcVisitLock = false;
    this.qcVisitModalityPending = true;
    this.qcVisitComment = '';
    this.tempSeriesComment = '';
    this.blockButtonsUntilModalityUpdate = true;
    this.showModalSpinner = false;
    this.showModalSpinnerTitleOther = false;
    const project = JSON.parse(localStorage.getItem('project')); // @TODO replace localstorage approach with NGXS store
    this.currentImageProjectId = project.id;
    this.iagProjectManager = this.jwtUtilService.isUserHasActivity('configuration.upload.edit', project.id);
    this.updateAllPatientsCodes();
    this.selectedQCTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : new QCTask(JSON.parse(localStorage.getItem('qc.selectTask')));
    this.studyService.getStudyPatientCodePatternAsRegEx(this.currentImageProjectId).subscribe(({data}) => {
      const pattern = data.replace('<sitecode>', this.selectedQCTask.siteCode);
      this.patientCodePatternRegEx = pattern;
      this.patientCodePatternMessage = data.replace(new RegExp(/\\d/, 'g'), '%');
    });

    // @TODO replace localstorage approach with NGXS store
    if (this.selectedQCTask.status === 'pending') {
      this.qualityControlService.updateQcTaskStatus(this.selectedQCTask.id, 'inprogress').subscribe();

      this.updateCachedQCTaskList('inprogress');
    }

    this.qualityControlService.updateQCUploadTask(this.selectedQCTask.id, 'inprogress', this.currentImageProjectId);

    this.readingConfigFlexibleService.getActiveConfigsByStudyId(project.id).pipe(tap(flexibleConfig => {
      this.isEnableToldMRI = flexibleConfig.data.some(({config}) => config.enableToldMRI);
    })).subscribe(configs => {
      if (configs.data != null) {
        this.flexibleConfigs = configs.data;
        this.uniqueEndpointNameFlexibleConfigs = this.flexibleConfigs.filter((config, index) => index === this.flexibleConfigs.findIndex(
          other => config.config.endpoint.name === other.config.endpoint.name
        ));
        this.selectedConfigsForLock = this.uniqueEndpointNameFlexibleConfigs.map(config => config.config.endpoint.name);

        if (!this.uniqueEndpointNameFlexibleConfigs.length) {
          this.selectedConfigsForLock = ["None"];
        }

        //to enable GBM
        if (this.uniqueEndpointNameFlexibleConfigs.find(el => el.config.endpoint.name === 'RANO'))
          this.utils.projectIncludesRanoEndpoint = true;
        else
          this.utils.projectIncludesRanoEndpoint = false;

        try {
          this.flexibleConfigs.forEach((item: any) => {
            item.config.readingVersion = (item.config.readingVersion as string).replace('ReadingVersion-', '');
            item.config.readingVersion = (item.config.readingVersion as string).replace('ReadingVersion', '');
          });
        } catch (error) {
          console.log('==> Error: ', error);
        }
      }
    });

    if (!!this.selectedQCTask.generalInfoId) {
      this.dataUploadService.getUploadGeneralInfoById(this.selectedQCTask.studyId, this.selectedQCTask.generalInfoId)
        .subscribe(success => {
          const data = success['data'];
          if (data != null) {
            this.uploadGeneralInfo = data;
          }
          this.cdr.detectChanges();
        });
    } else {
      const qcTask = JSON.parse(localStorage.getItem('qc.selectTask'));
      this.uploadGeneralInfo = {
        changeVisitConfigIdProcess: null,
        comment: null,
        created: null,
        edcf: null,
        edtf: null,
        executionId: null,
        files: [],
        id: qcTask.id,
        imageProjectId: qcTask.studyId,
        modalities: {modality: []},
        patientId: qcTask.patientId,
        reasonChangeModalityComment: null,
        siteConfigId: qcTask.siteConfigId,
        status: {id: null, created: null, name: qcTask.status, code: 200},
        unscheduled: true,
        uploadUserId: null,
        visitConfigId: qcTask.visitConfigId,
        visitId: null,
      };
    }

    this.initSeriesData();

    // set labels
    this.studySequenceLabelservice.getSequenceLabelsByStudyId(this.selectedQCTask.studyId).subscribe(({data}) => {
      this.detailedDataLabels = data;
      this.cdr.detectChanges();
    });

    // set predefined comments
    this.commentService.getAll().subscribe(({data}) => {
      this.detailedDataComments = data;
      this.cdr.detectChanges();
    });

    this.initVisitLogData();
    this.backgroundProcessLoading = true;
    this.initProcessesMonitoringTab();
    this.radioboticsProcessLoading = true;
    this.initRadioboticsProcesses();
    this.dXAProcessLoading = true;
    this.initDXAProcesses();
    this.getHistoryOfGeneratedEvents();
  }

  ngOnDestroy() {
    if (this.timeTrackData.series != null) {
      this.timeTrackUpdateAllEvents(this.timeTrackData.series);
    }
    if (this.refreshAPISubscription) {
      this.refreshAPIService.clearRefreshAPIData();
      this.refreshAPISubscription.unsubscribe();
    }
  }

  initVisitLogData(): void {

    this.patientUploadGeneralInfoFilesDataSources = [];
    this.patientUploadGeneralInfos = [];
    this.visits = [];

    this.dataUploadService
      .getUploadGeneralInfoByVisitConfigIdWithDownloadLinks(this.currentImageProjectId, this.selectedQCTask.visitConfigId)
      .subscribe((resp: any) => {
        if (resp.responseCode === 200) {
          this.patientUploadGeneralInfos = resp.data;
          this.patientUploadGeneralInfos.forEach(info => {
            const files = info.files.filter(f => f.status.name === UploadStatusName.SUCCESS && f.changeVisitConfigIdProcess !== true);
            files.map(file => {
              this.getFileUserName(file.id);
            });
            if (files.length > 0) {
              this.patientUploadGeneralInfoFilesDataSources.push(new MatTableDataSource<PatientUploadFile>(files));
            }
          });
        }
      });

    const self = this;
    this.patientService.getByIdWithVisitConfig(this.selectedQCTask.patientId).subscribe((res: any) => {

      const configIds: number[] = [];
      res.data.visitConfigs.forEach(conf => {
        this.visits.push({id: conf.id, name: conf.visitName, locked: false});
        configIds.push(conf.id);
      });

      self.qualityControlService.getVisitsByConfigIds(this.selectedQCTask.studyId, configIds).subscribe((resp: any) => {
        if (resp.responseCode === 200) {
          resp.data.forEach(item => {
            self.visits.forEach(visit => {
              if (item.visitConfigId === visit.id) {
                visit.locked = item.lockFlag === true;
              }
            });
            self.visits = self.visits.filter(visit => visit.locked !== true);
          });
        }
      });
    });

  }

  changeConfig() {
    this.DynamikaAIControl.reset();
    const {config: { isAdvancedAnalysis, isGBMSegmentation }} =  this.flexibleConfigs.find((config) => config.id.toString() === this.DynamikaAIConfig.value);
    this.isAdvancedAnalysisDisabled = !isAdvancedAnalysis && this.flexibleConfigs.find((config) => config.id.toString() === this.DynamikaAIConfig.value)?.config?.endpoint?.name !== 'Advanced Analysis';
    this.isGBMSegmentationDisabled = !isGBMSegmentation;
  }

  getMatchedConfig(id?: number) {
    return this.flexibleConfigs.find(config => config.id.toString() === id);
  }

  getFileUserName(fileId: number): void {
    const info = this.patientUploadGeneralInfos.filter(info => info.files.filter(file => file.id === fileId).length === 1);
    if (info && info.length === 1) {
      this.userService.getById(info[0].uploadUserId).subscribe((resp: any) => {
        if (resp.responseCode === 200) {
          this.patientUploadGeneralInfoFilesDataSources.map(dataSource => {
            dataSource.data.map(file => {
              if (file.id === fileId) {
                file.username = resp.data.firstName + ' ' + resp.data.lastName;
              }
            });
          });
        }
      });
    }
  }

  initSeriesData() {
    this.showModalSpinner = true;
    this.modalities = [];
    this.seriesCombined = [];
    // TODO: remove spinner_VARIABLE_NAME in future when change label algorithm will changed and speed will be increased.
    let spinner_modalities = [];
    const spinner_seriesCombined = [];
    let spinner_detailedDataModality = [];
    let spinner_detailedDataModalityOther = [];

    // @TODO merge into forkJoin
    this.patientService.getByIdWithVisitConfig(this.selectedQCTask.patientId).subscribe(patientResp => {
      this.patient = patientResp.data;
      this.visitNoUpload = this.patient.visitConfigs.find(c => c.id === this.selectedQCTask.visitConfigId).noUploads;

      this.studySequenceLabelservice.getGroupedStudyModalitiesByStudyId(this.selectedQCTask.studyId).subscribe(modalityResponse => {
        const modalityResponseData = modalityResponse.data;
        spinner_modalities = modalityResponse.data;
        this.initDropDownLabels(modalityResponse.data);
        if (modalityResponseData != null) {
          modalityResponseData.forEach(mData => {
            // new modality data
            const m = {
              modalityCode: 'expectedResult',
              modalityId: mData.id,
              modalityName: mData.name,
              uploadDate: null,
              sequenceLabels: mData.sequenceLabels,
              status: 'not_set',
              comment: '',
              series: [],
              dataSourceSeries: {},
              missingLabels: [],
              qcModalityId: null,
              qcModalityLock: null,
              qcModalityUserId: null,
              qcModalityStatusTime: null,
              qcModalityLockTime: null,
              qcModalitySeriesPending: true,
              qcUserFirstName: null,
              qcUserLastName: null,
              waitModalityResponse: false,
            };
            spinner_detailedDataModality.push(m);
          });

          // add unexpected modality
          const unexpectedModality = {
            modalityCode: 'unexpectedResult',
            modalityId: null,
            modalityName: 'Other',
            uploadDate: null,
            sequenceLabels: [],
            status: 'not_set',
            comment: '',
            series: [],
            dataSourceSeries: {},
            missingLabels: [],
            qcModalityId: null,
            qcModalityLock: null,
            qcModalityUserId: null,
            qcModalityStatusTime: null,
            qcModalityLockTime: null,
            qcModalitySeriesPending: true,
            qcUserFirstName: null,
            qcUserLastName: null,
            waitModalityResponse: false,
          };
          spinner_detailedDataModality.push(unexpectedModality);


          this.imagingAnalysisService.getSeriesListByExecutionID(this.selectedQCTask.imageAnalysisExecutionId)
            .subscribe(execResult => {
              let seriesIds = [];
              if (!!execResult) {
                const execResultData = execResult['result'] ? execResult['result'] as any[] : [];

                execResultData.forEach(eResult => {
                  if (eResult['pk'] === this.selectedQCTask.imageAnalysisExecutionId) {
                    const fields = eResult['fields'];
                    if (fields['workloadOutput'].length > 0) {
                      const workloadOutput = JSON.parse(fields['workloadOutput']);
                      const workloadOutputResult = workloadOutput['result'];
                      workloadOutputResult.forEach(sid => {
                        seriesIds.push(sid);
                      });
                    }
                  }
                });
              }

              this.qualityControlService.getSeriesByVisitConfigId(this.selectedQCTask.studyId, this.selectedQCTask.visitConfigId)
                .subscribe(seriesByvConfIDResp => {
                  const seriesByVconfId = seriesByvConfIDResp['data'];
                  if (seriesByVconfId != null) {

                    // exclude duplicated series
                    const allSeriesIds = seriesByVconfId.concat(seriesIds);
                    seriesIds = Array.from(new Set(allSeriesIds));
                    const seriesIdsAndStudyId = seriesIds.map((s) => ({ seriesId: s, studyId: this.selectedQCTask.studyId }));
                    this.imagingProjectSerivce.getSeriesPreviewBySeriesIds(seriesIdsAndStudyId).subscribe(previewSeriesResp => {

                      if (seriesIds.length > 0) {
                        this.studySequenceLabelservice.getStudySeriesAndParentsBySeriesIds(seriesIds).subscribe(seriesDetailsResp => {
                          // sort series details response from API baased on id key (seriesID)
                          try {
                            seriesDetailsResp['data'].sort((a,b) => (a.id > b.id ? 1 : -1));
                          } catch (error) {
                            console.log('ERROR... ', error);
                          }
                          this.qualityControlService.getSeriesBySeriesIds(this.selectedQCTask.studyId, seriesIds)
                            .subscribe(seriesDetailsQCResp => {
                              this.imagingProjectSerivce.getSeriesInstanceUID(seriesIds).subscribe(seriesInstanceUIDData => {
                                // sort series InstanceUIDs response from API baased on id key (seriesID)
                                try {
                                  seriesInstanceUIDData['data'].sort((a,b) => (a.id > b.id ? 1 : -1));
                                } catch (error) {
                                  console.log('ERROR... ', error);
                                }
                                const allSeriesDetailsResp = seriesDetailsResp['data'];
                                const allSeriesQCDetailsResp = seriesDetailsQCResp['data'];
                                const previewSeriesData = previewSeriesResp['data'];
                                const seriesInstanceUID = seriesInstanceUIDData['data'];

                                // combine series from msimageproject and msqc
                                if (allSeriesDetailsResp != null) {
                                  const viewerDataResults: Array<Observable<any>> = [];
                                  allSeriesDetailsResp.forEach((seriesDetails, index) => {
                                    const seriesQcDetails = allSeriesQCDetailsResp.filter(sqcd => sqcd.seriesId == seriesDetails.id)[0];

                                    const preview = previewSeriesData.filter(p => p.serialId === seriesQcDetails.seriesId)[0];

                                    if (seriesQcDetails != null) {
                                      const s = {
                                        created: seriesQcDetails.created,
                                        seriesId: seriesDetails.id,
                                        seriesInstanceUID: seriesInstanceUID[index]['seriesUID'],
                                        viewerName: seriesInstanceUID[index]['viewerName'],
                                        seriesQcId: seriesQcDetails.id,
                                        seriesPreviewUrl: preview['url'],
                                        label: seriesDetails.label,
                                        modality: seriesDetails.modality,
                                        projectModality: seriesDetails.projectModality,
                                        seriesDescription: seriesDetails.seriesDescription,
                                        scanDate: null,
                                        repeat: seriesQcDetails.repeatFlag,
                                        required: seriesQcDetails.requiredFlag,
                                        executionId: seriesQcDetails.executionId,
                                        dicomChecks: seriesQcDetails.dicomChecks,
                                        dicomChecksValue: null,
                                        status: seriesQcDetails.status,
                                        comment: seriesQcDetails.comment,
                                        qcSeriesUserId: seriesQcDetails.qcSeriesUserId,
                                        qcSeriesStatusTime: seriesQcDetails.qcSeriesStatusTime,

                                        qcModalityId: seriesQcDetails.qcModalityId,
                                        qcModalityStatus: seriesQcDetails.qcModalityStatus,
                                        qcModalityLock: seriesQcDetails.qcModalityLock,
                                        qcModalityUserId: seriesQcDetails.qcModalityUserId,
                                        qcModalityStatusTime: seriesQcDetails.qcModalityStatusTime,
                                        qcModalityLockTime: seriesQcDetails.qcModalityLockTime,
                                        qcModalityComment: seriesQcDetails.qcModalityComment,

                                        qcVisitId: seriesQcDetails.qcVisitId,
                                        visitConfigId: seriesQcDetails.visitConfigId,
                                        qcVisitStatus: seriesQcDetails.qcVisitStatus,
                                        qcVisitLock: seriesQcDetails.qcVisitLock,
                                        qcVisitUserId: seriesQcDetails.qcVisitUserId,
                                        qcVisitStatusTime: seriesQcDetails.qcVisitStatusTime,
                                        qcVisitComment: seriesQcDetails.qcVisitComment,
                                        modificationType: this.convertSeriesType(seriesDetails)
                                      };

                                      if (seriesDetails.scanDate != null) {
                                        s.scanDate = formatDate(seriesDetails.scanDate, 'dd/MM/yyyy', 'en-GB', 'UTC');
                                      }

                                      if (s.dicomChecks == null) {
                                        s.dicomChecks = 'not_set';
                                      }
                                      if (s.repeat == null) {
                                        s.repeat = false;
                                      }

                                      if (s.comment == null) {
                                        s.comment = '';
                                      }

                                      if (s.qcModalityLock == null) {
                                        s.qcModalityLock = false;
                                      }
                                      if (s.qcVisitLock == null) {
                                        s.qcVisitLock = false;
                                      }
                                      spinner_seriesCombined.push(s);

                                      // Get general viewer data
                                      const info = {'selectedPage': 'QC'};
                                      viewerDataResults.push(this.utils.initViewerData(s, this, info));
                                    }
                                  });

                                  if (viewerDataResults.length) {
                                    this.viewerDataIsLoading = true;
                                    forkJoin(viewerDataResults).subscribe(() => {
                                      this.viewerDataIsReady = true;
                                      this.viewerDataIsLoading = false;
                                      this.cdr.detectChanges();
                                    }, err => {
                                      this.toastOptions.title = 'ERROR';
                                      this.toastOptions.msg = 'Failed to initialize Viewer';
                                      this.toastyService.error(this.toastOptions);
                                      this.viewerDataIsLoading = false;
                                      this.cdr.detectChanges();
                                    });
                                  }
                                }

                                // sort series by modalities
                                spinner_detailedDataModality.forEach(modality => {
                                  if (modality.modalityCode === 'expectedResult') {
                                    // expected modalities
                                    modality.series = spinner_seriesCombined
                                      .filter(series => series.projectModality === modality.modalityName);
                                  } else {
                                    // unexpected modality series
                                    modality.series = spinner_seriesCombined.filter(series => series.projectModality == null);
                                  }
                                  // create dataSource
                                  if (modality.series == null) {
                                    modality.series = [];
                                  }
                                  // set modality qc id from qcSeries data
                                  if (modality.series.length > 0) {
                                    modality.qcVisitId = modality.series[0].qcVisitId;
                                    modality.qcModalityId = modality.series[0].qcModalityId;

                                    if (modality.series[0].qcModalityStatus != null) {
                                      modality.status = modality.series[0].qcModalityStatus;
                                    } else {
                                      modality.status = 'not_set';
                                    }

                                    if (modality.series[0].qcVisitId != null) {
                                      this.qcVisitId = modality.series[0].qcVisitId;
                                    }
                                    if (modality.series[0].qcVisitComment != null) {
                                      this.qcVisitComment = modality.series[0].qcVisitComment;
                                    }
                                    if (modality.series[0].qcVisitStatus != null) {
                                      this.qcVisitStatus = modality.series[0].qcVisitStatus;
                                    }
                                    if (modality.series[0].qcVisitLock != null) {
                                      this.qcVisitLock = modality.series[0].qcVisitLock;
                                    }
                                    if (modality.series[0].qcModalityLock != null) {
                                      modality.qcModalityLock = modality.series[0].qcModalityLock;
                                    }
                                    if (modality.series[0].qcModalityUserId != null) {
                                      modality.qcModalityUserId = modality.series[0].qcModalityUserId;
                                    }
                                    if (modality.series[0].qcModalityComment != null) {
                                      modality.comment = modality.series[0].qcModalityComment;
                                    }
                                    if (modality.series[0].qcModalityStatusTime != null) {
                                      modality.qcModalityStatusTime = moment(new Date(modality.series[0].qcModalityStatusTime)).format('DD/MM/YYYY');
                                    }
                                    if (modality.series[0].qcModalityLockTime != null) {
                                      modality.qcModalityLockTime = moment(new Date(modality.series[0].qcModalityLockTime)).format('DD/MM/YYYY');
                                    }

                                    // add upload date
                                    const latestDate = modality.series.map(function (e) {
                                      return e.created;
                                    }).sort().reverse()[0];
                                    if (latestDate != null) {
                                      modality.uploadDate = moment(latestDate).format('DD/MM/YYYY');
                                    }
                                  }
                                  modality = this.setModalitySeriesPendingStateFlag(modality);
                                  // add missing labels
                                  modality.sequenceLabels.forEach(label => {
                                    const existLabel = modality.series.filter(se => se.label == label.name);
                                    if (existLabel.length === 0) {
                                      modality.missingLabels.push(label);
                                    } else {
                                      existLabel.forEach(requiredSeries => {
                                        requiredSeries.required = true;
                                      });
                                    }
                                  });


                                  modality.dataSourceSeries = new MatTableDataSource<SeriesElement>(modality.series);
                                });


                                this.modalities = spinner_modalities;
                                this.seriesCombined = spinner_seriesCombined;

                                // remove unexpected results from all modality list
                                spinner_detailedDataModalityOther = spinner_detailedDataModality.filter(mod => mod.modalityCode === 'unexpectedResult');
                                spinner_detailedDataModality = spinner_detailedDataModality.filter(mod => mod.modalityCode === 'expectedResult');

                                this.detailedDataModalityOther = spinner_detailedDataModalityOther;
                                this.detailedDataModality = spinner_detailedDataModality;

                                this.updateUserInformation();

                                this.changeSeriesModalityOnServer();
                                this.setVisitModalityPendingStateFlag();
                                this.countModalitiesLock();

                                this.dataSourceDetailedDataModality = new MatTableDataSource<SeriesElement>(this.detailedDataModality);
                                this.dataSourceDetailedDataModalityOther = new MatTableDataSource<SeriesElement>(this.detailedDataModalityOther);

                                this.showModalSpinner = false;
                                this.showModalSpinnerTitleOther = false;
                                // set right tab selector
                                if (this.tabControlValueBeforeSeriesChanged != null) {
                                  this.tabControlValue = this.tabControlValueBeforeSeriesChanged;
                                }

                                this.cdr.detectChanges();

                              });
                            });
                        });
                      } else {
                        // there is no need to call this API.
                        // this.modalityService.getByVisitConfig(this.selectedQCTask.visitConfigId).subscribe(resp => {
                          this.qualityControlService.getQcVisit(this.selectedQCTask.visitConfigId).subscribe(qcResp => {
                            this.qcVisit = qcResp.data;
                            this.qcVisitStatus = this.qcVisit.visitStatus.name;
                            this.qcVisitLock = this.qcVisit.lockFlag;
                            spinner_detailedDataModalityOther = spinner_detailedDataModality
                              .filter(mod => mod.modalityCode === 'unexpectedResult');
                            spinner_detailedDataModality = spinner_detailedDataModality
                              .filter(mod => mod.modalityCode === 'expectedResult');

                            this.detailedDataModalityOther = spinner_detailedDataModalityOther;
                            this.detailedDataModality = spinner_detailedDataModality;

                            const initNoUploadModalityInfo = (m) => {
                              const modality = this.qcVisit.modalities
                                .find(md => md.modalityName.toLowerCase() === m.modalityName.toLowerCase());
                              if (!!modality) {
                                m.qcModalityLock = modality.lockFlag;
                                m.status = modality.modalityStatus.name;
                                if (!!modality.qcEndTime) {
                                  m.qcModalityLockTime = moment(new Date(modality.qcEndTime)).format('DD/MM/YYYY');
                                  m.qcModalityUserId = this.selectedQCTask.modalities.find(m => m.qcModalityId === modality.id).qcUserId;
                                  this.getModalityUserInfo(modality);
                                }
                              }
                            };

                            this.detailedDataModality.forEach(m => {
                              initNoUploadModalityInfo(m);
                            });
                            this.detailedDataModalityOther.forEach(m => {
                              initNoUploadModalityInfo(m);
                            });

                            this.updateUserInformation();

                            this.changeSeriesModalityOnServer();
                            this.setVisitModalityPendingStateFlag();
                            this.dataSourceDetailedDataModality = new MatTableDataSource<SeriesElement>(this.detailedDataModality);
                            this.dataSourceDetailedDataModalityOther =
                              new MatTableDataSource<SeriesElement>(this.detailedDataModalityOther);
                            this.showModalSpinner = false;
                            this.showModalSpinnerTitleOther = false;
                            if (this.tabControlValueBeforeSeriesChanged != null) {
                              this.tabControlValue = this.tabControlValueBeforeSeriesChanged;
                            }
                            this.cdr.detectChanges();
                          // });
                        });
                      }
                    });
                  }
                });
            });
        }
        this.cdr.detectChanges();
      });
    });

  }

  convertSeriesType(seriesDetails: any): string {
    const version = seriesDetails.generatedSeriesVersion ? ', v' + seriesDetails.generatedSeriesVersion : ', v0';
    if (seriesDetails.mapName) {
      switch (seriesDetails.mapName) {
        case '2D Motion Correction' : return 'MC' + version;
        case 'Area Under the Curve': return 'AUC' + version;
        case 'Gadolinium': return 'GD' + version;
        case 'GBM Segmentation': return 'GBM' + version;
        case 'Initial Rate of Enhancement': return 'IRE' + version;
        case 'Initial Rate of Washout': return 'IRW' + version;
        case 'Maximum Enhancement': return 'ME' + version;
        case 'Time of Onset': return 'Tonset' + version;
        case 'Time To Peak': return 'TTP' + version;
        case 'Time to Washout': return 'Twashout' + version;
      }
    } else {
      if (seriesDetails.parents === 0) {
        return 'original' + version;
      } else {
        return 'modified' + version;
      }
    }
  }

  initDropDownLabels(modalities) {
    const mAll: ModalityElement = {
      name: 'All',
      id: -1,
    };
    this.allModalities.push(mAll);
    modalities.forEach(item => {
      const m: ModalityElement = {
        name: item.name,
        id: item.id,
      };
      this.allModalities.push(m);
    });
    const qcTaskModalities = this.selectedQCTask.modalities;
    this.modalitiesSelected = [];
    qcTaskModalities.forEach(item => {
      const m: ModalityElement = {
        name: item.name,
        id: item.id,
      };
      this.modalitiesSelected.push(m);
    });
  }

  setModalitySeriesPendingStateFlag(modality) {
    let isPending = false;
    modality.series.forEach(s => {
      if (s.status == 'not_set') {
        isPending = true;
      }
    });
    modality.qcModalitySeriesPending = isPending;
    return modality;
  }

  setVisitModalityPendingStateFlag() {
    let isPending = false;
    this.detailedDataModality.forEach(m => {
      if (m.status == 'not_set') {
        isPending = true;
      }
    });
    this.qcVisitModalityPending = isPending;
  }

  startGetDicomCheksResult(element) {
    if (element.dicomChecksValue == null) {
      if (element.executionId != null) {
        this.imagingAnalysisService.getDicomCheksResult(element.executionId).subscribe(dicomres => {
          if (dicomres['pk'] == element.executionId) {
            if ((dicomres['fields'] != null)
              && (dicomres['fields']['workloadOutput'] != null)
              && (dicomres['fields']['workloadOutput']['result'] != null)) {
              element.dicomChecksValue = dicomres['fields']['workloadOutput']['result'];
              this.openDickomcheksDialog(element);
            }
          }
        });
      }
    } else {
      this.openDickomcheksDialog(element);
    }
  }

  openDickomcheksDialog(element) {
    const dialogRef = this.dialog.open(QCDicomchecksDialogComponent, {
      height: '500px',
      width: '600px',
      disableClose: true,
      data: {
        studyId: this.selectedQCTask.studyId,
        seriesId: element.seriesId,
        dicomChecks: element.dicomChecksValue
      }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
    this.qualityControlService.newSeriesTimeTrackEvent(this.selectedQCTask.studyId, element.seriesId, 'view_dicom_checks')
      .subscribe(success => {
      });
  }

  removeFile(uploadGeneralInfoId: number, fileId: number): void {
    this.patientUploadGeneralInfos = this.patientUploadGeneralInfos.map(info => {
      if (info.id === uploadGeneralInfoId) {
        info.files.filter(file => file.id !== fileId);
      }
      return info;
    });
  }

  removeEdtf(uploadGeneralInfoId: number): void {
    this.patientUploadGeneralInfos = this.patientUploadGeneralInfos.filter(info => info.id !== uploadGeneralInfoId);
  }

  containsInProcessFiles(files: PatientUploadFile[]): boolean {
    return files.filter(file => file.processingFlag === true).length > 0;
  }

  openChangeVisitDialog(fileId: number, moveAll: boolean, files: PatientUploadFile[]): void {

    if (this.containsInProcessFiles(files) || this.qcVisitLock) {
      return;
    }

    const self = this;
    let uploadSrc: { uploadGeneralInfoId: number, srcVisitConfigId: number };

    if (!moveAll) {
      this.patientUploadGeneralInfos.forEach(info => {
        const file = info.files.filter(file => file.id === fileId);
        if (file && file.length === 1 && info.status.name === UploadStatusName.SUCCESS) {
          uploadSrc = ({uploadGeneralInfoId: info.id, srcVisitConfigId: this.selectedQCTask.visitConfigId});
        }
      });
    } else {
      files.forEach(file => {
        const tempArray = this.patientUploadGeneralInfos.filter(info => info.files.filter(f => f.id === file.id).length === 1 && info.status.name === UploadStatusName.SUCCESS);
        if (tempArray && tempArray.length === 1) {
          uploadSrc = {uploadGeneralInfoId: tempArray[0].id, srcVisitConfigId: this.selectedQCTask.visitConfigId};
        }
      });
    }

    if (uploadSrc) {
      const visits = this.visits.filter(v => v.name !== this.selectedQCTask.visitName);
      const dialogRef = this.dialog.open(QcChangeVisitDialogComponent, {
        height: '300px',
        width: '400px',
        disableClose: false,
        data: {
          fileId: fileId,
          uploadSrc: uploadSrc,
          visits: visits,
          moveAll: moveAll,
        }
      });
      dialogRef.afterClosed().subscribe(() => {
        if (moveAll) {
          self.removeEdtf(uploadSrc.uploadGeneralInfoId);
        } else {
          self.removeFile(uploadSrc.uploadGeneralInfoId, fileId);
        }
        this.initVisitLogData();
      });
    }
  }

  updateUserInformation() {
    this.detailedDataModality.forEach(modality => {
      this.getModalityUserInfo(modality);
    });
  }

  getModalityUserInfo(modality) {
    if (modality.qcModalityUserId != null) {
      this.userService.getById(modality.qcModalityUserId).subscribe(userInfoResponse => {
        const data = userInfoResponse['data'];
        if (data != null) {
          modality.qcUserFirstName = data.firstName;
          modality.qcUserLastName = data.lastName;
          if (modality.qcUserFirstName == null) {
            modality.qcUserFirstName = '';
          }
          if (modality.qcUserLastName == null) {
            modality.qcUserFirstName = '';
          }
        }
        this.cdr.detectChanges();
      });
    }
  }

  changeSeriesModalityOnServer() {
    const modal = this.detailedDataModality.concat(this.detailedDataModalityOther);
    const updatedSeries = [];
    modal.forEach(mod => {
      const m = {
        qcVisitId: mod.qcVisitId,
        qcPatientDiscontinued: this.patient['discontinued'],
        visitConfigId: this.selectedQCTask.visitConfigId,
        generalInfoId: this.selectedQCTask.generalInfoId,
        modalityId: mod.modalityId,
        modalityName: mod.modalityName,
        modalityCode: mod.modalityCode,
        series: [],
      };
      mod.series.forEach(ser => {
        const s = {
          seriesId: ser.seriesId,
          seriesQcId: ser.seriesQcId,
          executionId: ser.executionId,
          projectModality: ser.projectModality,
          requiredFlag: ser.required,
        };
        m.series.push(s);
      });
      if (m.qcVisitId == null) {
        m.qcVisitId = this.qcVisitId;
      }
      updatedSeries.push(m);
    });

    this.qualityControlService.updateSeriesVisitAndModality(this.selectedQCTask.studyId, updatedSeries).subscribe(success => {
      const qcUpdateSeries = success['data'];
      if (qcUpdateSeries != null) {
        qcUpdateSeries.forEach(qcs => {
          if (qcs.qcVisitId != null) {
            this.qcVisitId = qcs.qcVisitId;
          }
          // update qcModality params for series and for
          this.detailedDataModality.forEach(dM => {
            this.updateModalityInfo(qcs, dM);
          });
          this.detailedDataModalityOther.forEach(dMOther => {
            this.updateModalityInfo(qcs, dMOther);
          });
        });
        this.blockButtonsUntilModalityUpdate = false;
        this.cdr.detectChanges();
      }
    });
  }

  updateModalityInfo(qcSeriesInfo, modality) {
    const series = modality.series.filter(s => s.seriesId == qcSeriesInfo.seriesId);
    if (series.length > 0) {
      if (modality.qcModalityId != qcSeriesInfo.qcModalityId) {
        modality.qcModalityId = qcSeriesInfo.qcModalityId;
        modality.qcModalityLock = false;
        modality.qcModalityUserId = qcSeriesInfo.qcModalityUserId;
        modality.status = 'not_set';
        modality.comment = '';
        modality.qcModalityStatusTime = qcSeriesInfo.qcModalityStatusTime;
        modality.qcModalityLockTime = qcSeriesInfo.qcModalityLockTime;

        if (qcSeriesInfo.qcModalityLock != null) {
          modality.qcModalityLock = qcSeriesInfo.qcModalityLock;
        }
        if (qcSeriesInfo.qcModalityStatus != null) {
          modality.status = qcSeriesInfo.qcModalityStatus;
        }
        if (qcSeriesInfo.qcModalityComment != null) {
          modality.comment = qcSeriesInfo.qcModalityComment;
        }
        if (qcSeriesInfo.qcModalityStatusTime != null) {
          modality.qcModalityStatusTime = moment(new Date(qcSeriesInfo.qcModalityStatusTime)).format('DD/MM/YYYY');
        }
        if (qcSeriesInfo.qcModalityLockTime != null) {
          modality.qcModalityLockTime = moment(new Date(qcSeriesInfo.qcModalityLockTime)).format('DD/MM/YYYY');
        }

        this.getModalityUserInfo(modality);
      }
    }
  }

  onIndexTabChanged(event) {
    this.tabControlValue = event;
  }

  onTabChanged(event) {
    this.selectedFlexibleConfigControl.setValue(null);
    this.DynamikaAIFlexibleConfigControl.setValue(null);
    this.viewercontiner.nativeElement.style.display = 'none';
  }

  getTabControlValue() {
    return this.tabControlValue;
  }

  clickCancel() {
    if (this.timeTrackData.series != null) {
      this.qualityControlService.updateSeriesTimeTrackEvent(this.selectedQCTask.studyId, this.timeTrackData.series)
        .subscribe(success => {
        });

      this.qualityControlService
        .updateLastByTypeSeriesTimeTrackEvent(this.selectedQCTask.studyId, this.timeTrackData.series, 'view_series_image')
        .subscribe(success => {
        });
    }
    this.router.navigate(['/imagingproject/qc']);
  }

  onSeriesLabelChange(series, e) {
    this.tabControlValueBeforeSeriesChanged = this.tabControlValue;
    let label = null;
    let selectedLabel = null;
    if (e.value == 'Other') {
      label = 'Other';
      this.showModalSpinnerTitleOther = true;
    } else {
      selectedLabel = this.detailedDataLabels.filter(label => label.name == e.value)[0];
      label = selectedLabel.name;
      this.showModalSpinnerTitleOther = false;
    }
    const m = this.getModalityByLabel(selectedLabel);
    if (m != null && m['qcModalityLock'] == true) {
      this.showMessage('Notification', 'The modality is locked. You can\'t select label of the locked modality');
    } else {
      this.showModalSpinner = true;
      this.seriesService.updateLabel(this.selectedQCTask.studyId, series.seriesId, label).subscribe(result => {
        this.initSeriesData();
      });
    }
    this.qualityControlService.newSeriesTimeTrackEvent(this.selectedQCTask.studyId, series.seriesId, 'change_label')
      .subscribe(success => {
      });
  }


  onSeriesLabelChangeWithoutBlockDialog(series, e) {
    this.tabControlValueBeforeSeriesChanged = this.tabControlValue;
    let label = null;
    let selectedLabel = null;
    if (e.value == 'Other') {
      label = 'Other';
      this.showModalSpinnerTitleOther = true;
    } else {
      selectedLabel = this.detailedDataLabels.filter(label => label.name == e.value)[0];
      label = selectedLabel.name;
      this.showModalSpinnerTitleOther = false;
    }
    const m = this.getModalityByLabel(selectedLabel);
    if (m != null && m['qcModalityLock'] == true) {
      this.showMessage('Notification', 'The modality is locked. You can\'t select label of the locked modality');
    } else {
      this.label_counter_state++;
      this.seriesService.updateLabel(this.selectedQCTask.studyId, series.seriesId, label).subscribe(result => {
        this.label_counter_state--;
        this.label_counter_state_was_updated = true;
        this.cdr.detectChanges();
      });
    }
    this.qualityControlService.newSeriesTimeTrackEvent(this.selectedQCTask.studyId, series.seriesId, 'change_label').subscribe(success => {
    });
  }

  onUpdateOtherLabels() {
    this.label_counter_state_was_updated = false;
    this.cdr.detectChanges();
    this.initSeriesData();
  }

  getModalityByLabel(label) {
    let result = null;
    if (label != null) {
      this.detailedDataModality.forEach(modality => {
        const sLabels = modality.sequenceLabels.filter(l => l.id == label.id);
        if (sLabels.length > 0) {
          result = modality;
        }
      });
    }
    return result;
  }

  showMessage(title, message) {
    const dialogMessaegRef = this.dialogMessage.open(MessageDialogComponent, {
      height: '190px',
      width: '600px',
      disableClose: true,
      data: {title: title, message: message}
    });

    dialogMessaegRef.afterClosed().subscribe(result => {
    });
  }

  onSelectFlexibleConfig(event) {
    console.log(this.selectedFlexibleConfigControl);
  }

  onSelectFlexibleConfigForDynamikaAI(event) {
    this.DynamikaAIControl.reset();
    const configId = this.DynamikaAIFlexibleConfigControl.value;
    const selectedConfig = this.flexibleConfigs.find(item => item.id === configId);
    this.lateralityCorrection = selectedConfig.config.lateralityAIcheck;
    this.radiobotics = selectedConfig.config.radioboticsAIcheck;
  }

  onSelectDynamikaAICheck(modality) {
    const selectedTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : this.selectedQCTask;
    switch (this.DynamikaAIControl.value) {
      case 'lateriltycorrection': {
        const dialogRef = this.dialog.open(QcLateralitycorrectionDialogComponent, {
          width: '800px',
        });
        dialogRef.afterClosed().subscribe((result) => {
          if (result) {
            const lateralityCorrectionServices = [];
            this.downloadSeries.forEach(sid => {
            lateralityCorrectionServices.push(this.qualityControlService.lateralityCorrection(selectedTask.studyId, sid));
            });
            forkJoin(lateralityCorrectionServices).subscribe((resp : any[]) => {
            if(resp.filter(item => item['responseCode'] !== 200).length !== 0) {
              this.toastOptions.title = 'ERROR laterality correction';
              this.toastOptions.msg = 'Laterality correction has not executed';
              this.toastyService.error(this.toastOptions);
            } else {
              this.toastOptions.title = 'Success';
              this.toastOptions.msg = 'Laterality correction has executed successfully';
              this.toastyService.success(this.toastOptions);
            }
            })
          }
        });

        break;
      }

      case 'radiobatics': {
        const radioboticsServices = [];
        this.downloadSeries.forEach(sid => {
        radioboticsServices.push(this.qualityControlService.radiobotics(selectedTask.studyId, sid));
        });
        forkJoin(radioboticsServices).subscribe((response : any[]) => {
          if(response.filter(item => item['responseCode'] !== 200).length !== 0) {
            this.toastOptions.title = 'Radiobotics ERROR';
            this.toastOptions.msg = 'Radiobotics does not executed';
            this.toastyService.error(this.toastOptions);
          } else {
            this.toastOptions.title = 'In Progress';
            this.toastOptions.msg = 'Radiobotics execution is in progress...';
            this.toastyService.info(this.toastOptions);
          }
        })

        break
      }

      case 'GBMSegmentation': {
        this.onStartGBM()
        break;
      }

      case 'IBClinic': {
        this.onStartIBClinic(modality)
        break;
      }

      case 'GBMRegistration': {
        this.onStartGBMRegisteration(modality);
        break;
      }

      default:
        break;
    }


  }

  islateralityCorrectionEndpointNotExisted() {
    return this.flexibleConfigs.find(fl => fl.config.endpoint.name === 'K&L' ||
      (fl.config.endpoint.name === 'Incidental Findings' && fl.config.readingVersion === ReadingVersion.IF_Multi_Tab)) === undefined
  }

  onStartGBM() {
    const locStorageQC = JSON.parse(localStorage.getItem('qc.selectTask'));
    const selectedTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : locStorageQC;
    const patientId: number = selectedTask.patientId;
    const visitConfigID = (selectedTask['visitConfigId'] as any);
    const GBMState = {
      done: false,
      ongoing: false,
      failed: false,
      none: true
    }

    this.imagingAnalysisService.getContainerResultsWithChildsByVisitConfigId([visitConfigID], [])
      .subscribe((resp: any) => {
        (resp.executions as any).forEach((execution: any) => {
          if (execution.workload === 'GBMSegmentation') {
            if(execution.state === 'DONE') {
              GBMState.done = true;
              GBMState.none = false;
            }
            if(execution.state === 'ONGOING') {
              GBMState.ongoing = true;
              GBMState.none = false;
            }
          }
        });

        if (GBMState.none) {
          if (this.DynamikaAIConfig.value != null) {
            const flexConfig = parseInt(this.DynamikaAIConfig.value);
            const gbmDataSeriesIds = this.downloadSeries;
            const gbmVisitConfigId = this.selectedQCTask.visitConfigId;
            this.imagingAnalysisService.startGBMMapGenerationMRano(this.currentImageProjectId, patientId,
              gbmVisitConfigId, flexConfig, gbmDataSeriesIds).subscribe(resp => {
              console.log('Start GBM');
            });
          }
        } else if (GBMState.ongoing) {
          this.toastOptions.title = 'ID 502: GBM segmentation is not Finished yet';
          this.toastOptions.msg = 'Please wait for it and come back in 15 minutes';
          this.toastyService.warning(this.toastOptions);
        } else if (GBMState.done) {
          this.toastOptions.title = 'ID 503: GBM segmentation is already Done';
          this.toastOptions.msg = 'you can not Start GBM again when it is already Done';
          this.toastyService.warning(this.toastOptions);
        }
    });
  }

  onStartIBClinic(modality) {
    const project = JSON.parse(localStorage.getItem('project'));
    const selectedTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : this.selectedQCTask;
    this.imagingProjectService.getStudy(project.id).subscribe(response =>{
      if (response.responseCode === 200) {
        const seriesData = this.downloadSeries.map(id => {
          return {
            id: id,
            label: modality.dataSourceSeries.data.find(s => s.seriesId === id).label
          }
        })
        this.imagingAnalysisService.advancedAnalysisIBClinic(seriesData, selectedTask.visitConfigId,
          selectedTask.patientId, response.data.bucketLocation, project.id, this.DynamikaAIConfig.value)
          .subscribe((resp: any) => {
              if(resp) {
                this.toastOptions.title = 'In Progress';
                this.toastOptions.msg = 'IB Clinic execution is in progress...';
                this.toastyService.info(this.toastOptions);
              } else {
                this.toastOptions.title = 'IB Clinic ERROR';
                this.toastOptions.msg = 'IB Clinic did not execute';
                this.toastyService.error(this.toastOptions);
              }
          }, () => {
            this.toastOptions.title = 'IB Clinic ERROR';
            this.toastOptions.msg = 'IB Clinic did not execute';
            this.toastyService.error(this.toastOptions);
          })
      }
    })
  }

  onStartGBMRegisteration(modality) {
    const project = JSON.parse(localStorage.getItem('project'));
    const selectedTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : this.selectedQCTask;
    this.imagingProjectService.getStudy(project.id).subscribe(response =>{
      if (response.responseCode === 200) {
        const seriesData = this.downloadSeries.map(id => {
          return {
            id: id,
            label: modality.dataSourceSeries.data.find(s => s.seriesId === id).label
          }
        })
        this.imagingAnalysisService.GBMRegisteration(seriesData, selectedTask.visitConfigId,
          selectedTask.patientId, response.data.bucketLocation, project.id, this.DynamikaAIConfig.value)
          .subscribe((resp: any) => {
              if(resp) {
                this.toastOptions.title = 'In Progress';
                this.toastOptions.msg = 'GBM registeration execution is in progress...';
                this.toastyService.info(this.toastOptions);
              } else {
                this.toastOptions.title = 'GBM registeration ERROR';
                this.toastOptions.msg = 'GBM registeration did not execute';
                this.toastyService.error(this.toastOptions);
              }
          }, () => {
            this.toastOptions.title = 'GBM registeration ERROR';
            this.toastOptions.msg = 'GBM registeration did not execute';
            this.toastyService.error(this.toastOptions);
          })
      }
    })
  }

  onSeriesStatusUpdate(modality, series, status) {
    this.updatingStatusSeries['seriesId'] = series.seriesId;
    const data = {
      seriesId: series.seriesId,
      status: status,
      studyProjectId: this.selectedQCTask.studyId,
    };
    series.status = status;
    modality = this.setModalitySeriesPendingStateFlag(modality);
    this.qualityControlService.setSeriesStatus(data).subscribe(success => {
      this.updatingStatusSeries = {};
      this.cdr.detectChanges();
    });
    this.qualityControlService.newSeriesTimeTrackEvent(this.selectedQCTask.studyId, series.seriesId, 'change_status')
    .subscribe(success => {
    });
  }

  onModalitySeriesStatusUpdate(modality, status) {
    this.updatingStatusSeries['modalityId'] = modality.qcModalityId;
    const data = {
      event: 'change_status',
      status: status,
      studyId: this.selectedQCTask.studyId,
    };

    modality = this.setModalitySeriesPendingStateFlag(modality);
    this.qualityControlService.setModalitySeriesStatus(modality.qcModalityId, data).subscribe(success => {
      this.updatingStatusSeries = {};
      modality.dataSourceSeries.data.forEach(f => f.status = status);
      this.cdr.detectChanges();
    }, e => {
      this.updatingStatusSeries = {};
    });
  }

  onUpdatingStatus(series) {
    return this.updatingStatusSeries?.seriesId === series.seriesId;
  }

  onUpdatingAllSeriesStatus() {
    return !!this.updatingStatusSeries?.modalityId;
  }

  isDiableUpdateAllSeriesButton(modality, status) {
    // we should not enable that button if all statuses are set to 'succees' or visit/modality locked
    return modality.qcModalityLock  || this.qcVisitLock === true || this.blockButtonsUntilModalityUpdate
      || this.onUpdatingAllSeriesStatus() || !modality.dataSourceSeries?.data?.find(f => f.status !== status);
  }

  onSeriesRepeatChanged(element) {
    this.qualityControlService.updateSeriesNotNullProperties(this.selectedQCTask.studyId, element.seriesId, element.status, element.repeat, null, null).subscribe(success => {
    });
    this.qualityControlService.newSeriesTimeTrackEvent(this.selectedQCTask.studyId, element.seriesId, 'click_repeat').subscribe(success => {
    });
  }

  onSeriesDownloadChanged(element) {
    if (this.downloadSeries.includes(element.seriesId)) {
      this.downloadSeries.splice(this.downloadSeries.indexOf(element.seriesId), 1);
    } else {
      this.downloadSeries.push(element.seriesId);
    }
  }

  onSeriesCommentChange(series) {
    this.qualityControlService.setSeriesComment(this.selectedQCTask.studyId, series.seriesId, series.comment).subscribe(success => {
    });
    this.qualityControlService.newSeriesTimeTrackEvent(this.selectedQCTask.studyId, series.seriesId, 'write_comment').subscribe(success => {
    });
  }

  onModalityCommentChange(modality) {
    modality.waitModalityResponse = true;
    this.waitVisitResponse = true;
    this.qualityControlService.setModalityComment(this.selectedQCTask.studyId, modality.qcModalityId, modality.comment)
      .subscribe(success => {
        modality.waitModalityResponse = false;
        this.waitVisitResponse = false;
        this.cdr.detectChanges();
      });
  }

  onVisitCommentChange() {
    this.waitVisitResponse = true;
    this.qualityControlService.setVisitComment(this.selectedQCTask.studyId, this.qcVisitId, this.qcVisitComment).subscribe(success => {
      this.waitVisitResponse = false;
      this.cdr.detectChanges();
    });
  }

  onModalityStatusUpdate(modality, status) {
    // If modality has already that status - revert status to 'not_set'
    if (modality.status === status) {
      status = 'not_set';
    };

    modality.waitModalityResponse = true;
    if (this.visitNoUpload) {
      modality.qcModalityId = this.qcVisit.modalities
        .find(m => m.modalityName.toLowerCase() === modality.modalityName.toLowerCase()).id;
    }

    this.waitVisitResponse = true;
    this.setVisitModalityPendingStateFlag();
    // If no any series was assigned to QC modality - QC modality is not created in DB, need to ensure it
    if (!modality.qcModalityId) {
      const data = {
        studyProjectId: this.selectedQCTask.studyId,
        visitConfigId: this.selectedQCTask.visitConfigId,
        qcVisitId: this.qcVisitId,
        modalityId: modality.modalityId,
        modalityName: modality.modalityName,
        qcPatientDiscontinued: this.patient['discontinued'],
      }
      this.qualityControlService.ensureQcModality(data).subscribe(success => {
        modality.qcModalityId = success['data']['id'];
        this.updateModalityStatus(modality, this.selectedQCTask.studyId, status);
      }, err => {
        modality.waitModalityResponse = false;
        this.waitVisitResponse = false;
        this.setVisitModalityPendingStateFlag();
        console.error('Failed to ensure modality during set modality status', err);
        this.toastOptions.title = 'Error';
        this.toastOptions.msg = 'Set modality status failed';
        this.toastyService.error(this.toastOptions);
      });
    } else {
      this.updateModalityStatus(modality, this.selectedQCTask.studyId, status);
    }
  }

  updateModalityStatus(modality: any, studyId: number, status: string) {
    const data = {
      studyProjectId: studyId,
      qcModalityId: modality.qcModalityId,
      status: status
    };

    this.qualityControlService.setModalityStatus(data).subscribe(success => {
      const currentUser = localStorage.getItem('currentUser'); // @TODO move into NGXS store
      const jsonToken = CompactJwt.decodeActivities(helper.decodeToken(currentUser));
      modality.qcModalityUserId = parseInt(jsonToken.jti, 10);

      modality.status = success['data'] != null ? success['data']['status'] || status : status;
      modality.waitModalityResponse = false;
      this.waitVisitResponse = false;
      this.setVisitModalityPendingStateFlag();

      this.getModalityUserInfo(modality);
      this.cdr.detectChanges();
    }, err => {
      modality.waitModalityResponse = false;
      this.waitVisitResponse = false;
      this.setVisitModalityPendingStateFlag();
      console.error('Failed to set modality status', err);
      this.toastOptions.title = 'Error';
      this.toastOptions.msg = 'Set modality status failed';
      this.toastyService.error(this.toastOptions);
    });
  }

  onVisitStatusUpdate(status) {
    if (this.qcVisitStatus === status) {
      status = 'not_set';
    };

    if (!this.qcVisitId) {
      this.qcVisitId = this.qcVisit.id;
    }
    const data = {
      qcVisitId: this.qcVisitId,
      visitConfigId: this.selectedQCTask.visitConfigId,
      status: status,
      studyProjectId: this.selectedQCTask.studyId,
      patientId: this.selectedQCTask.patientId,
    };
    this.qcVisitStatus = status;
    this.waitVisitResponse = true;
    this.qualityControlService.setVisitStatus(data).subscribe(success => {
      this.waitVisitResponse = false;
      this.cdr.detectChanges();

      //console.log(this.isVisitLockButton1Visible(), this.isVisitLockButton2Visible(), this.isVisitLockButton3Visible(), this.isVisitLockButton4Visible(), this.isVisitLockDisabled());
    });
  }

  isVisitLockDisabled() {
    return (this.qcVisitStatus == 'not_set' || this.qcVisitLock == true || this.waitVisitResponse || this.selectedConfigsForLock.length === 0) ? true : null;
  }

  isVisitLockButton1Visible() {
    return (this.qcVisitLock == true || this.qcVisitModalityPending == true || this.qcVisitStatus == 'not_set' || this.waitVisitResponse == true ) && this.patient?.discontinued != true && !this.visitNoUpload;
  }

  isVisitLockButton2Visible() {
    return ((this.qcVisitLock == true || this.qcVisitStatus == 'not_set' || this.waitVisitResponse == true) && this.patient?.discontinued == true && !this.visitNoUpload) || (this.visitNoUpload && !this.isAllModalitiesLocked);
  }

  isVisitLockButton3Visible() {
    return this.qcVisitLock == false && this.qcVisitModalityPending == false && this.qcVisitStatus != 'not_set' && this.patient?.discontinued != true && this.waitVisitResponse == false && !this.visitNoUpload;
  }

  isVisitLockButton4Visible() {
    return (this.qcVisitLock == false && this.qcVisitStatus != 'not_set' && this.patient?.discontinued == true && this.waitVisitResponse == false) || (this.visitNoUpload && this.isAllModalitiesLocked);
  }

  getHistoryOfGeneratedEvents() {
    const selectedTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : this.selectedQCTask;
    this.qualityControlService.generatEventHistory(selectedTask.studyId, selectedTask.visitConfigId).subscribe(response => {
      this.generatedEndpoints = response['data'];
     })
  }

  generetedEndpointsEvent(type: QcEventType) {
    if(this.generatedEndpoints !== undefined) {
      const endpointNames = this.generatedEndpoints.filter(items => items.type === type).map(item => item.endpointName);
      const generatedEndpointsEvent = this.selectedConfigsForLock.filter(item => {
        return endpointNames.indexOf(item) !== -1;
      });
      return generatedEndpointsEvent
    } return []
  }

  onChangeReadingConfig(event) {
    if (event.value.includes('None')) {
      this.selectedConfigsForLock = this.selectedConfigsForLock.filter(endpointName => endpointName === 'None')
    }
  }

  onModalityLock(modality) {
    modality.qcModalityLock = modality.status === 'generate_event' ? false : true;
    this.waitVisitResponse = true;
    this.closeLTViewer(null, true);
    if (!modality.qcModalityId) {
      modality.qcModalityId = this.qcVisit.modalities.find(m => m.modalityName.toLowerCase() === modality.modalityName.toLowerCase()).id;
    }
     modality.lockFlag = modality.status === 'generate_event' ? false : true;
    this.qualityControlService.setModalityLock(modality.lockFlag, this.selectedQCTask.id,
      this.selectedQCTask.studyId,
      modality.qcModalityId,
      this.patient['discontinued'],
      this.patient['phantomData'],
      this.selectedQCTask.patientId,
      this.uploadGeneralInfo['uploadUserId'], this.selectedConfigsForLock.filter(endpoint => { return endpoint !== "None" })).subscribe(success => {
      const updatedModality = success['data'];
      this.waitVisitResponse = false;
      if (updatedModality != null) {
        modality.qcModalityLock = updatedModality.qcModalityLock;
        if (updatedModality.qcModalityLockTime != null) {
          modality.qcModalityLockTime = moment(new Date(updatedModality.qcModalityLockTime)).format('DD/MM/YYYY');
        }
        if (modality.qcModalityLock) {
          this.countModalitiesLock();
        }
      }
      if (modality.status === 'generate_event') {
        this.getHistoryOfGeneratedEvents();
        this.toastOptions.title = 'Success';
        this.toastOptions.msg = 'Modality events generated successfully';
        this.toastyService.success(this.toastOptions);
      }
      this.cdr.detectChanges();
    });
  }

  countModalitiesLock(): void {
    let lockedModalities = true;
    this.detailedDataModality.forEach(m => {
      if (!m.qcModalityLock) {
        lockedModalities = false;
      }
    });
    if (lockedModalities) {
      this.isAllModalitiesLocked = true;
    }
  }

  openNoUploadsDialog(): void {
    const noUploadVisit = this.patient.visitConfigs.find(c => c.id === this.selectedQCTask.visitConfigId);
    let noUpload = false;
    let noUploadComment = null;
    if (!!noUploadVisit) {
      noUpload = noUploadVisit.noUploads;
      noUploadComment = noUploadVisit.noUploadsComment;
    }
    this.dialog.open(NoUploadDialogComponent, {
      width: '600px',
      disableClose: true,
      data: <NoUploadDialogData>{
        patientCode: this.patient.patientCode,
        visitName: this.selectedQCTask.visitName,
        noUpload: this.patient.visitConfigs.find(c => c.id === this.selectedQCTask.visitConfigId).noUploads,
        hasUploads: noUpload,
        noUploadsComment: noUploadComment
      }
    }).afterClosed().subscribe((result: { noUpload: boolean, comment: string }) => {
      this.updateNoUploadVisit(result.noUpload, result.comment);
    });
  }

  downloadFile(fileInfo) {
    if (fileInfo.url != null) {
      window.open(fileInfo.url, '_blank');
    }
  }

  manuallyOpenViewer(serIds: string[]) {
    this.openLTViewer(serIds);

    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1000);

    this.viewercontiner.nativeElement.style.display = '';
    this.viewercontiner.nativeElement.style.zIndex = '2';
  }

  openNewLTViewer(seriesIds) {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(
      ViewerCoreLtComponent
    );

    this.newLTViewer = this.viewerLT.createComponent(compFactory);

    this.newLTViewer.instance.seriesIds = seriesIds;
    this.newLTViewer.instance.shouldShowDICOM = false;
    this.newLTViewer.instance.onClose.subscribe(({serID, shouldCloseViewer}) => {
      this.closeLTViewer(serID, shouldCloseViewer);
    });
  }

  preOpenLTViewer() {
    this.openNewLTViewer(null);
  }

  openLTViewer(seriesIds: string[] = []) {
    if (this.newLTViewer) {
      this.newLTViewer.instance.openNewSer(seriesIds, this.viewerData);
      return;
    }

    this.openNewLTViewer(seriesIds);
  }

  closeLTViewer(serID, shouldCloseViewer) {
    this.removeHighlight(serID);

    if (shouldCloseViewer) {
      this.viewercontiner.nativeElement.style.zIndex = '-10';
      this.viewercontiner.nativeElement.style.display = 'none';
    }
  }

  // @TODO avoid DOM manipulation here, change corresponded model property instead
  removeHighlight(serID): void {
    const $serThumbnail = document.querySelector('[data-seriesId="' + serID + '"] div');

    $serThumbnail?.classList.remove('viewer-highlighted');
    setTimeout(() => {
      $serThumbnail?.classList.remove('viewer-highlighted');
    }, 10);
  }

  // @TODO avoid DOM manipulation here, change corresponded model property instead
  highlightThumbnail(seriesId): void {
    const $serThumbnail = document.querySelector('[data-seriesId="' + seriesId + '"] div');
    $serThumbnail?.classList.add('viewer-highlighted');
  }

  openViewer(seriesId): void {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1000);

    this.highlightThumbnail(seriesId);
    this.openLTViewer([seriesId]);

    this.viewercontiner.nativeElement.style.display = '';
    this.viewercontiner.nativeElement.style.zIndex = '2';
  }

  timeTrackOpenViewer(seriesId) {
    this.timeTrackData.series = seriesId;
    this.qualityControlService.newSeriesTimeTrackEvent(this.selectedQCTask.studyId, seriesId, 'view_series_image');
  }

  timeTrackCloseViewer(seriesId) {
    this.timeTrackData.series = null;
    this.qualityControlService.updateLastByTypeSeriesTimeTrackEvent(this.selectedQCTask.studyId, seriesId, 'view_series_image');
  }

  timeTrackUpdateAllEvents(seriesId): void {
    this.qualityControlService.updateAllSeriesTimeTrackEvent(this.selectedQCTask.studyId, seriesId);
  }

  onVisitLock(): void {
    const isET = this.visits.find(v => v.id === this.uploadGeneralInfo.visitConfigId).name.toLowerCase() === 'et';
    this.qcVisitLock = this.qcVisitStatus === 'generate_event' ? false : true;
    // @TODO too many params, this is a call to move params into one object
    if (!this.qcVisitId) {
      this.qcVisitId = this.qcVisit.id;
    }
    this.qcVisitLockFlag = this.qcVisitStatus === 'generate_event' ? false : true;
    this.qualityControlService.setVisitLock(this.qcVisitLockFlag, this.selectedQCTask.id,
      this.selectedQCTask.studyId,
      this.selectedQCTask.visitConfigId,
      this.qcVisitId,
      this.patient['discontinued'],
      this.patient['phantomData'],
      this.selectedQCTask.patientId,
      this.uploadGeneralInfo['uploadUserId'], isET, this.selectedConfigsForLock.filter(endpoint => { return endpoint !== "None" })).subscribe(lockResp => {
      const lockData = lockResp['data'];
      if (lockData['lockFlag'] !== true) {
        this.qcVisitLock = false;
        this.cdr.detectChanges();
      } else {
        this.router.navigate(['/imagingproject/qc']);

        this.updateCachedQCTaskList('done', new Date());
      }
        if (this.qcVisitStatus === 'generate_event') {
          this.getHistoryOfGeneratedEvents();
          this.toastOptions.title = 'Success';
          this.toastOptions.msg = 'Visit events generated successfully';
          this.toastyService.success(this.toastOptions);
        }
    });
  }

  updateNoUploadVisit(noUpload: boolean, comment: string): void {
    this.imagingProjectSerivce
      .updateNoUploads(this.currentImageProjectId, this.selectedQCTask.visitConfigId, noUpload,
        comment, Number(JSON.parse(localStorage.getItem('userId'))))
      .subscribe(resp => {
        if (resp.responseCode !== ResponseCode.OK) {
          this.toastOptions.title = 'The upload flag change failure';
          this.toastOptions.msg = 'Process has failed due to some reason. Contact support team';
          this.toastyService.error(this.toastOptions);
        } else {
          this.toastOptions.title = 'The upload flag is changed successfully';
          this.toastOptions.msg = 'No upload flag is changed successfully. System will act in regards with the submitted parameter';
          this.toastyService.success(this.toastOptions);
          this.router.navigate(['/imagingproject/qc']);
        }
      });
  }

  onCreateEDCF() {
    const modalities = [];
    this.detailedDataModality.forEach(modality => {
      const m = {
        modalityId: modality.modalityId,
        modalityName: modality.modalityName,
        qcModalityLock: modality.qcModalityLock,
        comment: modality.comment,
        series: modality.series,
        missingLabels: modality.missingLabels,
      };
      modalities.push(m);
    });
    localStorage.setItem('qc.queries.edcf.modalities', JSON.stringify(modalities));
    this.router.navigate(['queries/edcf/new']);
  }

  onOpenQCManual() {
    this.studyService.getImagingManualDownloadUrl(this.currentImageProjectId).subscribe(urlResult => {
      if (urlResult['data'] != null) {
        window.open(urlResult['data'], '_blank');
      }
    });
  }

  onDownloadImages() {
    this.store.dispatch(new SetDownloading({isDownloading: true}));
    this.updateDownloading(true);
    this.downloadService.sendDownloadRequest(this.buildDownloadPayload()).subscribe(resp => {
      this.startDownload(resp);
    }, error => {
      this.updateDownloading(false);
      this.store.dispatch(new SetDownloading({isDownloading: false}));
    });
  }

  updateDownloading(newValue: boolean) {
    this.downloading = newValue;
    this.cdr.detectChanges();
  }

  private startDownload(download: BasicResponse<DownloadModel>): void {
    const item: DownloadMonitoringItem = {
      uuid: UUID.generate(),
      filename: '',
      startDate: new Date(),
      status: 'ASSEMBLING_ARCHIVE',
      patientCode: this.patient.patientCode,
      timepointName: this.patient.timepointName,
      total: 0,
      loaded: 0,
      progresses: [],
      subscriptions: []
    };
    if (download.responseCode !== 200) {
      this.updateDownloading(false);
      item.status = 'ARCHIVE_DOWNLOAD_FAILURE';
      this.onFileDownloadFailed(download.data.id);
      this.store.dispatch(new UpdateDownloadProcess(item));

    } else {
      this.checkStatusAndWait(download.data, item);
    }
  }

  checkStatusAndWait(downloadModel: DownloadModel, item: DownloadMonitoringItem) {
    if (downloadModel.status === 'ASSEMBLING_ARCHIVE') {
      const intervalId = setInterval(() => {
        this.downloadService.getDownloadRequest(downloadModel.id)
          .subscribe(resp => {
            if (resp.data.status !== 'ASSEMBLING_ARCHIVE') {
              this.updateDownloading(false);
              clearInterval(intervalId);
              this.processFileDownload(resp.data, item);
            }
          });
      }, 3000);
    } else {
      this.updateDownloading(false);
    }
  }

  processFileDownload(downloadModel: DownloadModel, item: DownloadMonitoringItem): void {
    item.total += 100;
    const progressUuid = UUID.generate();
    item.status = 'READY_FOR_DOWNLOAD';
    item.filename = downloadModel.fileName;
    const request = this.downloadService.downloadFile(downloadModel.storageLink).subscribe(event => {
      if (event.type === HttpEventType.Sent) {
        item.progresses.push({uuid: progressUuid, fileId: downloadModel.id, loadedPercent: 0, done: false});
        this.onFileDownloadStart(item.progresses.find(p => p.uuid === progressUuid).fileId);
      }
      if (event.type === HttpEventType.DownloadProgress) {
        item.progresses.find(p => p.uuid === progressUuid).loadedPercent = (event.loaded / event.total) * 100;
        let loaded = 0;
        for (const p of item.progresses) {
          loaded += p.loadedPercent;
        }
        item.loaded = loaded;
        this.store.dispatch(new UpdateDownloadProcess(item));
      }
      if (event.type === HttpEventType.Response) {
        if ((<HttpResponse<any>>event).status !== ResponseCode.OK) {
          item.status = 'ARCHIVE_DOWNLOAD_FAILURE';
          this.onFileDownloadFailed(item.progresses.find(p => p.uuid === progressUuid).fileId);
          this.store.dispatch(new UpdateDownloadProcess(item));
        } else {
          item.progresses.find(p => p.uuid === progressUuid).done = true;
          this.onFileDownloadSuccess(item.progresses.find(p => p.uuid === progressUuid).fileId);
          if (item.progresses.every(p => p.done)) {
            item.status = 'DOWNLOADING_SUCCESS';
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(event.body);
            a.href = objectUrl;
            a.download = 'archive.zip';
            a.click();
            URL.revokeObjectURL(objectUrl);
            this.store.dispatch(new UpdateDownloadProcess(item));
            this.updateDownloading(false);
          }
        }
      }
    }, error => {
      item.status = 'ARCHIVE_DOWNLOAD_FAILURE';
      this.store.dispatch(new UpdateDownloadProcess(item));
    });
    item.subscriptions.push(request);
    this.store.dispatch(new PushDownloadProcess(item));
  }

  onFileDownloadStart(downloadId: number): void {
    this.downloadService.updateDownloadStatus(downloadId, 'ARCHIVE_DOWNLOADING').subscribe();
  }

  onFileDownloadSuccess(downloadId: number): void {
    this.downloadService.updateDownloadStatus(downloadId, 'DOWNLOADING_SUCCESS').subscribe();
    this.updateDownloading(false);
  }

  onFileDownloadFailed(downloadId: number): void {
    this.downloadService.updateDownloadStatus(downloadId, 'ARCHIVE_DOWNLOAD_FAILURE').subscribe();
    this.updateDownloading(false);
  }

  back(): void {
    this.location.back();
  }

  buildDownloadPayload(): DownloadPayload {
    return {
      studyId: this.currentImageProjectId,
      patientId: this.patient.id,
      seriesIds: this.downloadSeries
    };
  }

  dragStarted(event) {
    const target = event.source.element.nativeElement;
    target.classList.add('z-index-max');
  }

  dragStopped(event) {
    const target = event.source.element.nativeElement;
    target.classList.remove('z-index-max');
  }

  removeModality(item): void {
    const dialogRef = this.dialogModalityWarning.open(QCModalitysubmitDialogComponent, {
      height: '300px',
      width: '600px',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result['status'] == 'ok') {
        if (this.modalitiesSelected.length > 1) {
          const index = this.modalitiesSelected.indexOf(item);
          if (index >= 0) {
            this.modalitiesSelected.splice(index, 1);
            this.updateTaskModalities(result);
          }
        }
      }
    });
  }

  selectedModality(event: MatAutocompleteSelectedEvent): void {
    const m = event.option.value;

    const dialogRef = this.dialogModalityWarning.open(QCModalitysubmitDialogComponent, {
      height: '300px',
      width: '600px',
      disableClose: true,
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result['status'] === 'ok') {
        if (m.name === 'All' && m.id === -1) {
          this.modalitiesSelected = [];
          // add all modalities except  modality in drop down with name 'ALL'
          this.allModalities.forEach(mod => {
            if (mod.name !== 'All' && mod.id !== -1) {
              this.modalitiesSelected.push(mod);

            }
          });
        } else {
          this.modalitiesSelected.push(event.option.value);
        }
        this.updateTaskModalities(result);
      }
    });
  }

  updateTaskModalities(data) {
    const mods = [];

    this.modalitiesSelected.map((mod, index) => {
      const mInfo = this.detailedDataModality.filter(m => m.modalityName == mod.name)[0];
      if (mInfo != null) {
        const taskModality = {
          name: mod.name,
          qcModalityId: mInfo.qcModalityId,
          qcModalityLock: mInfo.qcModalityLock,
          patientDiscontinued: this.patient.discontinued,
        };
        mods.push(taskModality);

      }
    });
    this.dataUploadService
      .updateModalities(this.currentImageProjectId, this.selectedQCTask.generalInfoId, this.modalitiesSelected, data['data']);
    this.qualityControlService.updateQCTaskModalities(this.currentImageProjectId, this.selectedQCTask.id, mods);
  }

  // @TODO need better naming here
  removeViewerFromArray(arr, value): Array<any> {
    return arr.filter(ele => ele !== value);
  }

  itemChange(item, itemComponent): void {
    item.resizeTrigger();
  }

  itemResize(item, itemComponent): void {
    item.resizeTrigger();
  }

  onPatientEditClick() {
    const dialogRef = this.dialogModalityWarning.open(QCPatienteditDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        projectId: this.currentImageProjectId,
        allPatientsCodes: this.allPatientsCodes,
        patientCodePatternRegEx: this.patientCodePatternRegEx,
        patientCodePatternMessage: this.patientCodePatternMessage
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result['status'] === 'ok') {
        const data = result['data'];
        const userId = localStorage.getItem('userId');
        this.patientService.updatePatientCode(this.currentImageProjectId,
          this.selectedQCTask.patientId,
          data['patientCode'],
          +userId.replace(/"/g, ''),
          data['reasonComment'],
          this.patient.patientBirthDate)
          .subscribe(patientResult => {
            this.selectedQCTask.patientCode = patientResult['data'].patientCode;
            this.cdr.detectChanges();
            localStorage.setItem('qc.selectTask', JSON.stringify(this.selectedQCTask));
            this.updateAllPatientsCodes();
          });
      }
    });
  }

  onPatientBirthDateEditClick() {
    const dialogRef = this.dialogModalityWarning.open(QCPatientBirthDateEditDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        projectId: this.currentImageProjectId,
        date: this.patient.patientBirthDate
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result['status'] === 'ok') {
        const data = result['data'];
        const userId = localStorage.getItem('userId');
        this.patientService.updatePatientBirthDate(this.currentImageProjectId,
          this.selectedQCTask.patientId,
          data['patientBirthDate'],
          +userId.replace(/"/g, ''),
          data['reasonComment'],
          this.selectedQCTask.patientCode)
          .subscribe(patientResult => {
            this.patient.patientBirthDate = patientResult['data'].patientBirthDate;
            this.cdr.detectChanges();
          });
      }
    });
  }

  updateAllPatientsCodes() {
    this.patientService.getPatientsByStudyId(this.currentImageProjectId).subscribe(({data}) => {
      const patients = data;
      if (patients) {
        this.allPatientsCodes = [];
        patients.forEach(patient => {
          this.allPatientsCodes.push(patient.patientCode);
        });
      }
    });
  }

  // TODO should be moved into utilites
  calculateFileSize(bytes: number): number {
    return Math.floor((bytes / 100000)) / 10;
  }

  dragstart(ev, seriesId, seriesInstanceUID) {
    ev.dataTransfer.setData('seriesId', seriesId);
    ev.dataTransfer.setData('seriesInstanceUID', seriesInstanceUID);
  }

  private initFailedContainerProcessTab(ContainerId: number) {
    this.imagingAnalysisService.refreshContainerResults(ContainerId)
      .subscribe((response: any) => {
        this.initProcessesMonitoringTab(ContainerId);
      });
  }

  private initProcessesMonitoringTab(containerId?: number) {
    const selectedTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : this.selectedQCTask;
    this.imagingAnalysisService.getContainerResultsWithChildsByVisitConfigId([selectedTask.visitConfigId], [])
      .subscribe((resp: any) => {
        this.backgroundProcessesMonitoringInfos = resp.executions;
        if (containerId !== null) {
          this.retryButtonLoading = this.retryButtonLoading.filter(a => a !== containerId);
        }
        this.backgroundProcessLoading = false;
        if (this.backgroundProcessesMonitoringInfos && this.backgroundProcessesMonitoringInfos.length > 0) {
          this.sortData({ active: 'start-time', direction: 'desc' });
        }
      });
  }

  sortData(sort: Sort) {
    const data = this.backgroundProcessesMonitoringInfos.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSource();
      return;
    }

    this.backgroundProcessesMonitoringInfos = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'container-id':
          return compare(a.id, b.id, isAsc);
        case 'container-name':
          return compare(a.workload, b.workload, isAsc);
        case 'start-time':
          return compare(a.dateStart, b.dateStart, isAsc);
        case 'finish-time':
          return compare(a.dateEnd, b.dateEnd, isAsc);
        case 'status':
          return compare(a.state, b.state, isAsc);
        case 'container-details':
          return compare(a.stateMsg, b.stateMsg, isAsc);
        default:
          return 0;
      }
    });
    this.initDataSource();
  }

  private initDataSource() {
    this.backgroundProcessesMonitoringInfoDataSources = new MatTableDataSource<BackgroundProcessesMonitoringInfo>(this.backgroundProcessesMonitoringInfos);
    this.backgroundProcessesMonitoringInfoDataSources.paginator = this.containersPaginator;
    this.backgroundProcessesMonitoringInfoDataSources.sort = this.containersSort;
  }

  // radiobotics processes functions
  private initRadioboticsProcesses() {
    const selectedTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : this.selectedQCTask;
    this.qualityControlService.getRadioboticsProcesses(selectedTask.studyId, selectedTask.visitConfigId)
      .subscribe((response: any) => {
        this.radioboticsProcessesInfos = response.elements;
        this.radioboticsProcessLoading = false;
        if (this.radioboticsProcessesInfos && this.radioboticsProcessesInfos.length > 0) {
          this.sortDataForRadioboticsProcesses({active: 'series-id', direction: 'desc'});
        }
        else {
          if(this.radioboticsProcessesInfoDataSources)
            this.radioboticsProcessesInfoDataSources.data = [];
        }
      }, (error: any) => {
        console.error(error.error);
      }
    );
  }

  sortDataForRadioboticsProcesses(sort: Sort) {
    const data = this.radioboticsProcessesInfos.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSourceForRadioboticsProcesses();
      return;
    }

    this.radioboticsProcessesInfos = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'series-id':
          return compare(a.seriesId, b.seriesId, isAsc);
        case 'container-status':
          return compare(a.state, b.state, isAsc);
        default:
          return 0;
      }
    });
    this.initDataSourceForRadioboticsProcesses();
  }

  private initDataSourceForRadioboticsProcesses() {
    this.radioboticsProcessesInfoDataSources = new MatTableDataSource<RadioboticsProcessesInfo>(this.radioboticsProcessesInfos);
    this.radioboticsProcessesInfoDataSources.paginator = this.containersPaginatorForRadioboticsProcesses;
    this.radioboticsProcessesInfoDataSources.sort = this.containersSort;
  }
  // end radiobotics processes functions

  // DXA processes functions
  private initDXAProcesses() {
    const selectedTask = this.selectedQCTaskFromDialog ? this.selectedQCTaskFromDialog : this.selectedQCTask;
    this.dxaReadingService.getAIPending(selectedTask.studyId, selectedTask.visitConfigId)
      .subscribe((response: any) => {
        this.dXAProcessesInfos = response.data;
        this.dXAProcessLoading = false;
        if (this.dXAProcessesInfos && this.dXAProcessesInfos.length > 0) {
          this.sortDataForDXAProcesses({active: 'execution-id', direction: 'desc'});
        }
        else {
          if(this.dXAProcessesInfoDataSources)
            this.dXAProcessesInfoDataSources.data = [];
        }
      }, (error: any) => {
        console.error(error.error);
      }
    );
  }

  sortDataForDXAProcesses(sort: Sort) {
    const data = this.dXAProcessesInfos.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSourceForDXAProcesses();
      return;
    }

    this.dXAProcessesInfos = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'execution-id':
          return compare(a.id, b.id, isAsc);
        case 'container-status':
          return compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
    this.initDataSourceForDXAProcesses();
  }

  private initDataSourceForDXAProcesses() {
    this.dXAProcessesInfoDataSources = new MatTableDataSource<DXAProcessesInfo>(this.dXAProcessesInfos);
    this.dXAProcessesInfoDataSources.paginator = this.containersPaginatorForDXAProcesses;
    this.dXAProcessesInfoDataSources.sort = this.containersSort;
  }

  getDXARightStatusName(statusName: string) {
    if (['NEW_READING', 'READER_PENDING', 'READER_ASSIGNED', 'BATCH_PENDING', 'BATCH_ASSIGNED', 'EXTERNAL_AI_PENDING'].includes(statusName))
      return 'ONGOING'
    return 'SUCCESS'
  }
  // DXA radiobotics processes functions

  backgroundProcessRefresh() {
    this.backgroundProcessLoading = true;
    this.initProcessesMonitoringTab();
    this.radioboticsProcessLoading = true;
    this.initRadioboticsProcesses()
    this.dXAProcessLoading = true;
    this.initDXAProcesses()
  }

  failedContainerProcessRefresh(containerId: number) {
    this.retryButtonLoading.push(containerId);
    this.initFailedContainerProcessTab(containerId);
  }

  isUpdateContainer(containerId) {
    return this.retryButtonLoading.includes(containerId);
  }

  updateValidationRules() {
    if (this.uploadGeneralInfo?.edtf != null) {
      if (this.patient?.patientCode != null && this.uploadGeneralInfo.visitConfigId != null && this.edtfData) {
        this.edtfData.initValidation(this.patient?.patientCode, this.uploadGeneralInfo.visitConfigId, this.patient?.patientBirthDate);
      }
    }
  }

  updateEDFT(){
    const project = JSON.parse(localStorage.getItem('project'));
    this.imagingProjectService.getStudy(project.id).subscribe(response =>{
      if(response.responseCode === 200){
        const uploadDataInfo = {
          bucketLocation: response.data.bucketLocation,
          imageProjectId: project.id,
          visitConfigId: this.uploadGeneralInfo.visitConfigId,
          edtf: this.edtfData.edtf,
          edtfFile: [],
        };
        this.dataUploadService.getUploadEdtfFileInformation(uploadDataInfo).subscribe((resp)=>{
          if(resp['responseCode'] === 200){
            this.toastOptions.title = 'eDTF UPDATE SUCCESSFULLY';
            this.toastOptions.msg = 'the eDTF has been updated successfully';
            this.toastyService.success(this.toastOptions);
            this.uploadGeneralInfo.edtf = this.edtfData.edtf;
          }
        })
      }
    })
  }

  updateCachedQCTaskList(status: string, date?: Date) {
    interface IQCTaskList {
      id: number;
      generalInfoId: number;
      imageAnalysisExecutionId: number;
      status: string;
      qcDate: Date;
      qcUserId: number;
      visitConfigId: number;
      numberInStudy: number;
      patientId: number;
      studyId: number;
      modality: any[];
    }

    let cachedQCTasksList = this._cache.get(
      'https://'
      + environment.MSQualityControlVersion
      + 'msqualitycontrol-dot-' + environment.apiUrl + '/task/list/'
      + this.currentImageProjectId);

    if(cachedQCTasksList) {
      (cachedQCTasksList.body.data as Array<IQCTaskList>).find(task => task.id === +this.selectedQCTask.id).status = status;
      if(date) {
        (cachedQCTasksList.body.data as Array<IQCTaskList>).find(task => task.id === +this.selectedQCTask.id).qcDate = date;
      }
    }
  }

}


function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
