import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MessageDialogComponent} from 'src/app/components/controls/message-dialog/message-dialog.component';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {PrimaryBodyLocationService} from 'src/app/_services';
import {Subscription} from 'rxjs';
import {HeaderNames, LestionTableColumns} from 'src/app/_models/Oncology/lesion-display-columns-model';
import {EventKey, FormEvent} from 'src/app/_models/Oncology/local-storage-keys.model';
import {
  AddLesionRequest,
  GlobalLesionModel,
  LesionTypeMarkers,
  OncologyAddLesionData,
  OncologyLesionEditType,
  OncologyLesionType,
  OncologyResponseEnum,
  OncologyResponseType,
  prefixLesionID,
  ResponseOptions,
  StatusOptions
} from 'src/app/_models/Oncology/global-lesion-model';
import {OncologyAssessmentVisitModel} from 'src/app/_models/Oncology/oncology-assessment-visit-model';
import {OncologyAssessmentService} from 'src/app/_services/oncology-assessment.service';
import {OncologyLesionDialogComponent} from '../../oncology-lesion-dialog/oncology-lesion-dialog.component';
import {FormPage, MessagingService} from "../../../../../_services/messaging.service";
import { LesionPpdMetric, LesionSettings, OncologyCriteria } from 'src/app/_models/Oncology/criteria-model';
import { ReadingVersion } from 'src/app/core/constants/reading-version';

export enum APIType {
  POST,
  PUT,
  DELETE
}

@Component({
  selector: 'app-dynamic-brain-lesion-table',
  templateUrl: './dynamic-brain-lesion-table.component.html',
  styleUrls: ['./dynamic-brain-lesion-table.component.css'],
  providers: [MessagingService]
})
export class DynamicBrainLesionTableComponent implements OnInit, OnDestroy, OnChanges {
//parameters for localstorage
  eventSubscription: Subscription;
  readerId: number;
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<any>();
  lesionsDataSource: GlobalLesionModel[] = [];
  primaryLocations = [];
  subLocations = [];
  locationSubscription;
  statusOptions = StatusOptions;
  responseType = OncologyResponseType;
  reaponseOptions = ResponseOptions;
  criteriaSubscription: Subscription;
  criteria: OncologyCriteria;


  public get editType(): typeof OncologyLesionEditType {
    return OncologyLesionEditType;
  }

  public get OncologyLesionType(): typeof OncologyLesionType {
    return OncologyLesionType;
  }

  @ViewChild(MatSort) sort: MatSort;
  @Input() endpointName:string;
  @Input() studyId:number;
  @Input() readingId: number;
  @Input() patientId: number;
  @Input() lesionType:OncologyLesionType;
  @Input() visit: OncologyAssessmentVisitModel;
  @Input() sequentialLock: boolean;
  @Input() eCRFOnly:boolean;
  @Input() lesionList: GlobalLesionModel[] = [];
  @Output('changeResponse') changeResponse: EventEmitter<{type:OncologyResponseType,value:string}>= new EventEmitter<{type:OncologyResponseType,value:string}>();
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  baselineNadirSubscription: Subscription;
  baselineSPPD: any;
  nadirSPPD: any;
  historicalNadir: any[];
  nadirVisitConfigId: number;
  allLesionSubscription: Subscription;
  readingAllLesions: any[];

  constructor(private dialog: MatDialog,
              private oncologyAssessmentService: OncologyAssessmentService,
              private toastyService: ToastyService,
              private dialogMessagePreview: MatDialog,
              private primaryBodyLocationService: PrimaryBodyLocationService,
              private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    this.criteriaSubscription = this.oncologyAssessmentService.criteriaSetting.subscribe(criteria => {
      if (criteria) {
        this.criteria = new OncologyCriteria(criteria, this.visit.baseline);
        this.displayedColumns = LestionTableColumns[this.lesionType];
        this.shapeDisplayColumns();
      }
   });

    this.readerId = +JSON.parse(localStorage.getItem('userId'));

    //get all locations
    this.locationSubscription = this.primaryBodyLocationService.findAll().subscribe(
      (response) => {
        if (response.responseCode === 200) {
          this.primaryLocations = response.data;
        }
      }
    );

    //baseline/nadir sppdChanges
    this.baselineNadirSubscription = this.oncologyAssessmentService.baselineNadirSPPD.subscribe(data => {
      if (data && (data?.readingId === this.readingId || !data?.readingId)) {
        this.nadirSPPD = data.nadirSPPD;
        this.baselineSPPD = data.baselineSPPD;
        this.historicalNadir = data.historicalNadir;
        this.nadirVisitConfigId = data.nadirVisitConfigId;
      }
    });

    //get all lesions
    this.allLesionSubscription = this.oncologyAssessmentService.allLesions.subscribe(response => {
      if (response) {
        this.readingAllLesions = response;
      }
    });

    //TODO This may need fix. This calls multiple times in viewer mode of MRANO form.
    const roomIdPostfix = `${this.randomInt()}`;
    this.eventSubscription = this.messagingService.init(this.readerId, this.readingId, FormPage.DYNAMIC_BRAIN_LESION, roomIdPostfix)
      .subscribe(this.handleEvents.bind(this));
  }

  randomInt(max: number = 999_999_999) {
    return Math.floor(Math.random() * max);
  }

  //get new lesion list if changed from dynamic scoring form
  ngOnChanges(changes: SimpleChanges) {
    if (changes.lesionList) {
      this.lesionList = changes.lesionList.currentValue;
      this.refreshLesionSource();
    }
  }

  //shape display columns dynamically
  shapeDisplayColumns() {
    if (this.visit.baseline) {
      this.displayedColumns = this.displayedColumns
        .filter(c => {
          return c !== 'ppdChgNadir' &&
                 c !== 'status' &&
                 c !== 'changeStatus' &&
                 c !== 'notMeasurabilityReason';
        });
    } else if (this.lesionType !== OncologyLesionType.NEW_LESION) {
      this.displayedColumns = this.displayedColumns.filter(c => {return c !== 'operations'})
    }

    if (!this.criteria.LesionAllowFreeTextEntry())
      this.displayedColumns = this.displayedColumns
        .filter(c => { return c !== 'comment'});
  }

  ngOnDestroy(): void {
    if (this.eventSubscription)
      this.eventSubscription.unsubscribe();

    if (this.baselineNadirSubscription)
      this.baselineNadirSubscription.unsubscribe();

    if (this.criteriaSubscription)
      this.criteriaSubscription.unsubscribe();

    if (this.allLesionSubscription)
      this.allLesionSubscription.unsubscribe();
  }

  //get lesion data from viewer
  handleEvents(event: FormEvent) {
    if (!event.value)
      return;
    switch (event.key) {
      case EventKey.ADD_LESION:
        this.onOpenBrainLesion(event.value)
        break;
    }
  }
  get isRecistEligibility() {
    return this.endpointName === ReadingVersion.RECIST_ELIGIBILITY;
  }

  //get proper table header names
  getHeaderName(key: string) {
    const column = HeaderNames.find(c => c[0] === key)
    switch (column?.[0]) {
      case 'bidim':
        return this.isRecistEligibility ? 'Measurements' : column[1].split('dynamicUnit').join(this.criteria.ALDUnit())
      case 'comment':
        return this.isRecistEligibility ? 'Comment' : column[1];
      case 'ppd':
        return this.criteria.PPDName() + ' (' + column[1].split('dynamicUnit').join(this.criteria.PPDUnit()) + ')';
      case 'ppdChgNadir':
        return column[1].split('dynamicUnit').join(this.criteria.PPDNadirName())
      default:
      return column[1];
    }
  }

  getType(lesion: GlobalLesionModel) {
    const location = this.primaryLocations.find(el => { return el.name == lesion.primaryLocation });

    if (location) {
      return location.nodal ? 'Nodal' : 'Non-nodal';
    }
    return '';
  }

  //set lesions to table
  refreshLesionSource() {
    if (this.lesionList) {
      this.lesionsDataSource =  this.filterLesions(this.lesionList);
      this.dataSource = new MatTableDataSource(this.lesionsDataSource);
      this.dataSource.sort = this.sort;

      this.oncologyAssessmentService.modalSpinnerChangeState(false);
      this.oncologyAssessmentService.onEditLesionMeasurement(null);
      this.checkAllLesionsReviewed();
    }

  }

  //check if all lesions are reviewed
  checkAllLesionsReviewed() {
    let reviewedLesion = false ;
    if (!this.visit.baseline) {
      if (this.lesionType === OncologyLesionType.TARGET_LESION) {
        reviewedLesion = this.lesionsDataSource.filter(lesion => { return (lesion.snapshot == null || lesion.shortAxis == null || lesion.axialLongDiameter == null || lesion.ppd == null) && lesion.ppd !== 'NOT_EVALUABLE' }).length === 0;
      }
      else {
        reviewedLesion = this.lesionsDataSource.filter(lesion => { return lesion.snapshot == null && lesion.status !== 'ABSENT' && lesion.status !== 'NOT_EVALUABLE' }).length === 0;
      }
      this.oncologyAssessmentService.sendLesionReviewed({ type: this.lesionType, reviewed: reviewedLesion });
    }
  }

  //filter lesions by unique constraints
  filterLesions(lesions:GlobalLesionModel[]):GlobalLesionModel[]{
    const filtered = lesions.filter(
      (item) => {

        if (this.isRecistEligibility) {
          return item.type == OncologyLesionType.TARGET_LESION &&
          item.visitConfigId == this.visit.visitConfigId &&
          item.readingId == this.readingId &&
          item.endpointName == this.endpointName
        }
        return item.type == this.lesionType &&
        item.visitConfigId == this.visit.visitConfigId &&
        item.readingId == this.readingId &&
        item.endpointName == this.endpointName
      }
    )
    return filtered;
  }

  //find sub-location name by id
  subLocationName(primaryId:number,subId:number){
    let location;
    let subLocation;
     location= this.primaryLocations.find(el=>{return el.id == primaryId}) as any;
    if(location)
       subLocation = location.bodySubLocations.find(el=>{return el.id == subId})
       if(subLocation)
       return subLocation.name
    return
  }

  //get all sub-locations for table dropdown
  getSubLocations(primaryId: number) {
    if(this.primaryLocations.length > 0)
      return this.primaryLocations.find(el => { return el.id == primaryId })?.bodySubLocations;
  }

  editBodyLocations(lesion: GlobalLesionModel) {
    const dialogRef = this.dialog.open(OncologyLesionDialogComponent, {
      width: '500px',
      data: {
        lesionName: lesion.lesionName,
        studyId: this.studyId,
        readingId: this.readingId,
        readerId: this.readerId,
        patientId: this.patientId,
        endpointName: this.endpointName,
        visitConfigId: this.visit.visitConfigId,
        isBaseline: this.visit.baseline,
        markerType: LesionTypeMarkers[this.lesionType],
        editDialog: true,
        lesionData: lesion,
        screenShotData: lesion.snapshot,
        editType: this.editType.LOCATION,
        lesionType: this.isRecistEligibility ? OncologyLesionType.TARGET_LESION : this.lesionType,
        viewerData: null,
        eCRFOnly: this.eCRFOnly,
      },
      hasBackdrop:false,
      disableClose:false
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.addOrUpdateLesion(result, true)
      else {
        this.toastOptions.title = 'INFO Edit mode is disabled';
        this.toastOptions.msg = 'To edit object try to click edit button';
        this.toastyService.info(this.toastOptions);
      }
      //clear local storage causes DOM exception
      // this.clearAllLocalStorageKeys();
    });
  }

  onChangeSublocation(event,lesion:GlobalLesionModel) {
    lesion.subLocation = event.value;
    this.addOrUpdateLesionService(lesion, true);
  }

  //send request (to viewer side) to add lesion
  requestToAddOrEditLesion() {
    const data: AddLesionRequest = {
      readingId: this.readingId,
      visitId: this.visit.id,
      visitConfigId: this.visit.visitConfigId,
      lesionType: this.lesionType === OncologyLesionType.MEASURABLE_LESION ? OncologyLesionType.TARGET_LESION : this.lesionType,
      markerType: LesionTypeMarkers[this.lesionType],
      lesionName: this.generateLesionName()
    }
    const message: FormEvent = {key: EventKey.ADD_LESION_REQUEST, value: data};
    this.messagingService.sendRequest(message, FormPage.MRANO)
  }

  //edit measurement of lesion
  onEditMeasurement(editType?:string,currentLesionData?:any){
    let data;
    let tempSnapshot;
    const tempCurrentLesionData = { ...currentLesionData };
    if (currentLesionData) {
      if (currentLesionData.snapshot) {
        tempSnapshot = {
          seriesId: currentLesionData.snapshot.seriesId,
          sliceNumber: currentLesionData.snapshot.sliceNumber
        }
        tempCurrentLesionData.snapshot = tempSnapshot;
      }
       data= {
        editMode:true,
        editType:editType,
        currentLesionData: tempCurrentLesionData,
         requestData: {}
       }
    }

    if (!this.eCRFOnly) {
      const requestData: AddLesionRequest = {
      readingId: this.readingId,
      visitId: this.visit.id,
      visitConfigId: this.visit.visitConfigId,
      lesionType: this.lesionType,
      markerType: LesionTypeMarkers[this.lesionType],
      lesionName: currentLesionData.lesionName
      }
      data.requestData = requestData;
      const message: FormEvent = {key: EventKey.EDIT_LESION_DATA, value: data};
      this.messagingService.sendRequest(message, FormPage.MRANO)
      return;
    } else
      this.onOpenBrainLesion(
        {
          edit: true,
          data: currentLesionData,
          editType: this.editType.MEASUREMENT,
          viewerData: null,
          requestedData: null
        })
  }

  //request (to viewer) to go to lesion
  goToLesion(lesion?: GlobalLesionModel) {
    const message: FormEvent = {key: EventKey.GO_TO_LESION, value: {currentLesionData: lesion}};
    this.messagingService.sendRequest(message, FormPage.MRANO)
    return;
  }

  //open brain lesion dialog
  onOpenBrainLesion(sentData: OncologyAddLesionData) {
    //check match with table:
    if (sentData.editType == this.editType.MEASUREMENT || !sentData.editType)
      if (sentData.requestedData?.visitId != this.visit.id ||
          sentData.requestedData?.readingId != this.readingId ||
          (sentData.requestedData?.lesionType != this.lesionType && !this.isRecistEligibility))
        return;
    const maxLesionNo = +this.criteria.LesionIDMaxNumber();
    if (this.lesionsDataSource.length >= maxLesionNo && this.visit.baseline
      && !sentData.edit && this.lesionType === OncologyLesionType.TARGET_LESION) {
      this.toastOptions.title = 'ERROR Cannot add lesion';
      this.toastOptions.msg = `Cannot add more than ${maxLesionNo} target lesions`;
      this.toastyService.error(this.toastOptions);
      return;
    }
    const dialogRef = this.dialog.open(OncologyLesionDialogComponent, {
      width: '500px',
      data: {
        lesionName: sentData.edit ? sentData.data.lesionName : this.generateLesionName(),
        studyId: this.studyId,
        readingId: this.readingId,
        readerId: this.readerId,
        patientId: this.patientId,
        endpointName: this.endpointName,
        visitConfigId: this.visit.visitConfigId,
        isBaseline: this.visit.baseline,
        markerType: LesionTypeMarkers[this.lesionType],
        editDialog: sentData.edit,
        lesionData: (sentData.data ? sentData.data : {}),
        screenShotData: sentData?.data?.snapshot,
        editType: sentData.editType,
        lesionType: this.isRecistEligibility ? OncologyLesionType.TARGET_LESION : this.lesionType,
        viewerData: sentData.viewerData,
        eCRFOnly: this.eCRFOnly,
      },
      hasBackdrop:false,
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.addOrUpdateLesion(result, sentData.edit)
      else {
        if (sentData.edit) {
          this.toastOptions.title = 'INFO Edit mode is disabled';
          this.toastOptions.msg = 'To edit object try to click edit button';
          this.toastyService.info(this.toastOptions);
        }
      }
      //clear local storage causes DOM exception
      // this.clearAllLocalStorageKeys();
    });
  }

  //generate proper lesion name
  generateLesionName() {
    const objectLength =
      this.lesionsDataSource.length + 1;
    return objectLength < 10 ?
      (`${this.getPrefixLesionID()}0${objectLength}`).toString() :
      (`${this.getPrefixLesionID()}${objectLength}`).toString();
  }
  getDecimalValue(value: number) {
    return this.isRecistEligibility ? value.toFixed(1) : value;
  }
  getPrefixLesionID() {
    if (this.visit.baseline && this.lesionType === this.OncologyLesionType.TARGET_LESION)
      return this.criteria.LesionIDPrefix();

    if (this.isRecistEligibility)
      return 'T';

    if (!this.visit.baseline && this.lesionType === this.OncologyLesionType.TARGET_LESION)
      return this.criteria.LesionIDCopyFromBaseline() ? this.criteria.BaselineLesionIDPrefix() : this.criteria.LesionIDPrefix();

    return prefixLesionID[this.lesionType];
  }

  //prepare to update/add lesion
  addOrUpdateLesion(data: any, edit: boolean) {
    let lesion: GlobalLesionModel = data;
    if (!edit)
      lesion.lesionName = this.generateLesionName();

    this.addOrUpdateLesionService(lesion, edit)
  }

  sortedScreenshots() {
    const screenshots = this.lesionsDataSource.sort((l1, l2) => l1.id - l2.id);
    return screenshots;
  }

  onShowImage(lesion: GlobalLesionModel) {
    let title = "There is no image to preview";
    let message = "";
    if (lesion.snapshot) {
      title = 'Lesion: ' + lesion.lesionName + ',  Modality: ' + lesion.snapshot.modalityName +', Label: ' + lesion.snapshot.sequenceLabelName + ', Slice: '
              + lesion.snapshot.sliceNumber;
      message = '<img width="100%" src="' + lesion.snapshot.fileUrl + '"/>';
    }
    const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
      disableClose: true,
      data: {title: title, message: message, html: true}
    });

    dialogMessaegRef.afterClosed().subscribe(result => {
    });
  }

  onDelete(row) {
    const message ='Do you want to delete the current lesion?';

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '200px',
      width: '600px',
      disableClose: true,
      data: {
          title: 'Confirmation',
          message: message,
          showOk: true,
          showCancel: true,
          html : false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result === 'ok') {
          this.deleteLesionService(row);
        }
    });
  }
  addLesionEnable() {
    if (this.lesionType === this.OncologyLesionType.TARGET_LESION)
      return this.criteria.AddLesions();

    if (this.visit.baseline || this.lesionType === this.OncologyLesionType.NEW_LESION)
      return true;

    return false
  }
  disableModifyLesionLocation(lesion: GlobalLesionModel) {
    if (this.lesionType === this.OncologyLesionType.TARGET_LESION)
      return !this.criteria.LesionLocationAllowModify();

    if (!this.visit.baseline)
      return this.disableEditNewLesion(lesion);

    return false
  }
  //add/update lesion service
  addOrUpdateLesionService(data:any,edit:boolean){
    this.oncologyAssessmentService.modalSpinnerChangeState(true)
      this.oncologyAssessmentService
      .updateLesionData(this.studyId,data,edit).subscribe(
        response=>{
          if (response['responseCode'] == 200) {
            response['data'].snapshot = data.snapshot;
            if(edit)
            {
              this.updateLesionList(APIType.PUT,response['data']);
              this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
              this.toastOptions.msg = 'Lesion is updated';
            }
            else
            {
              this.updateLesionList(APIType.POST,response['data']);
              this.toastOptions.title = 'ID 42: Lesion has been created successfully';
              this.toastOptions.msg = 'Lesion is now available in the list of lesions';
            }
            // TODO: What the reason to call this method here?
            // think it is not necessary for recist eligibility
            this.oncologyAssessmentService.baselineNadirSPPDRequest(true);
            this.toastyService.success(this.toastOptions);
          }
          else{
            if(edit){
              this.toastOptions.title = 'ID 45: Lesion updating failure';
              this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
            }
            else {
              this.toastOptions.title = 'ID 43: Lesion creation failure';
              this.toastOptions.msg = 'Lesion is not created due to some reason';
            }
            this.toastyService.error(this.toastOptions);
          }
        }
      )
  }

  //delete lesion service
  deleteLesionService(lesion:any){
   this.oncologyAssessmentService.modalSpinnerChangeState(true)
    this.oncologyAssessmentService
      .deleteLesion(this.studyId, lesion.id, this.getPrefixLesionID()).subscribe(
      response=>{
        if(response['responseCode']==200){
          this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
          this.toastOptions.msg = 'Lesion is updated';
          this.toastyService.success(this.toastOptions);
          this.lesionList = this.lesionList.filter(l => { return l.id !== lesion.id });
          this.oncologyAssessmentService.baselineNadirSPPDRequest(true);
          this.updateLesionList(APIType.DELETE, response['data'], lesion);
          this.oncologyAssessmentService.triggerLesionList(lesion.visitConfigId);
        }
       this.oncologyAssessmentService.modalSpinnerChangeState(false)
      },
      error =>{
          this.toastOptions.title = 'ID 45: Lesion updating failure';
          this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
         this.toastyService.error(this.toastOptions);
       this.oncologyAssessmentService.modalSpinnerChangeState(false)
      }
    )
  }

  //update lesion Data source after api call
  updateLesionList(type: APIType, data: any, deletedLesion?: any) {
    switch (type) {
      case APIType.POST:
        this.lesionList.push(data);
        this.refreshLesionSource();
        this.oncologyAssessmentService.addToAllLesions([data]);
        break;
      case APIType.PUT:
        for (let i = 0; i < this.lesionList.length; i++) {
          if (this.lesionList[i].id === data.id) {
            this.lesionList[i] = data;
            break;
          }
        }
        this.refreshLesionSource();
        this.oncologyAssessmentService.updateAllLesions(data);
        break;
      case APIType.DELETE:
        (data as GlobalLesionModel[]).forEach(d => {
          d.snapshot = this.lesionList.find(l => l.id === d.id).snapshot
        })
        this.lesionList = data;
        this.refreshLesionSource();
        this.oncologyAssessmentService.deleteFromAllLesions(deletedLesion, data);
        break;

      default:
        break;
    }
  }

  // get list of lesion to merge
  getOtherOptionsForMeasurabilityStatus(lesion:GlobalLesionModel){
    return this.lesionsDataSource.filter(l => {
      return l.lesionName !== lesion.lesionName
    }).map(l => {return l.lesionName})
  }

  onChangeMeasurabilityStatus(event, lesion: GlobalLesionModel) {
    lesion.notMeasurabilityReason = event.value;
    lesion.mergedWith = null;
    lesion.isMerged = null;
    lesion.snapshot = null;
    lesion.isMeasurable = event.value == 'PRESENT';
    switch (event.value) {
      case 'RESOLVED':
        lesion.axialLongDiameter = 0;
        lesion.shortAxis = 0;
        lesion.ppd = 0;
        break;
      case 'TOO_SMALL_TO_MEASURE':
        lesion.axialLongDiameter = 5;
        lesion.shortAxis = 5;
        lesion.ppd = 0.25;
        break;
      case 'MERGED_WITH_ANOTHER_LESION':
        lesion.mergedWith = event.source.triggerValue.split(' ')[2];
        lesion.isMerged = true;
        lesion.axialLongDiameter = 0;
        lesion.shortAxis = 0;
        lesion.ppd = 'NA';
        break;
      case 'NOT_EVALUABLE':
        lesion.axialLongDiameter = 'NE';
        lesion.shortAxis = 'NE';
        lesion.ppd = 'NE';
        break;
      default:
        break;
    }
    this.addOrUpdateLesionService(lesion, true);
  }
  showStatusOptions() {
    return this.statusOptions[this.lesionType];
  }
  onChangeStatus(event,lesion:GlobalLesionModel){
    lesion.status = event.value;
    lesion.snapshot = null;
    if(this.visit.baseline)
      lesion.status = 'NA';
    this.addOrUpdateLesionService(lesion, true);
  }
  showResponseOptions() {
    return this.reaponseOptions[this.responseType[this.lesionType]];
  }

  onChangeChangeStatus(event, lesion: GlobalLesionModel) {
    lesion.changeStatus = event.value;
    this.addOrUpdateLesionService(lesion, true);
  }
  correctPPD(nadir:boolean, sppd?: string | number) {
    let result = +sppd;
     if (nadir)
       result = +sppd;

    switch (sppd) {
      case "NA":
      case 'NE':
      case null:
        result = +sppd
      break;
    }
    return result
  }
  calculateSummary(){
    return this.sppdCalculation(this.lesionsDataSource);
  }

  comparisonSPPD(baselineSPPD: any){
    let sppdComparison = 0;
    if (baselineSPPD === 'NA' || !baselineSPPD) {
      return 'NA';
    }
    if (baselineSPPD === 'NE') {
      return 'NE';
    }
    baselineSPPD = +baselineSPPD.toFixed(2);

    const currentSPPD = this.sppdCalculation(this.lesionsDataSource);
    if (currentSPPD >= 0) {
      sppdComparison = (currentSPPD - baselineSPPD) * 100 / baselineSPPD;
    }

    return  +sppdComparison.toFixed(2);
  }
  comparisonNadirSPPD(sppd: any) {
    if (this.nadirVisitConfigId === this.visit.visitConfigId)
      return 0;
    if (!this.historicalNadir)
      return;

    let sppdComparison = 0;
    const nadir = this.historicalNadir.find(hn => hn.visitConfigId === this.visit.visitConfigId);

    if (sppd == 'NA' || sppd === null)
      return 'NA';
    if (sppd == 'NE')
      return 'NE';
    sppd = +sppd.toFixed(2);

    const currentSPPD = this.sppdCalculation(this.lesionsDataSource);
    if (currentSPPD >= 0) {
      if (nadir) {
        sppd = nadir.nadirVisitTotalPpd;
        if (nadir.nadirVisitConfigId === this.visit.visitConfigId)
          return 0;
      }
      if (sppd === 0)
        return 0;
      sppdComparison = (currentSPPD - sppd) * 100 / sppd;
    }

    return  +sppdComparison.toFixed(2);
  }

  sppdCalculation(lesionSource:GlobalLesionModel[]){
    let sppd = 0;
    if(lesionSource)
      lesionSource.forEach(l => {
        let ppd = l.ppd;
        if(ppd && ppd !== 'NA' && ppd !== 'NE')
          sppd += +ppd;
      });
    return +sppd;
  }
  onChangeResponse(event){
    this.changeResponse.emit({type:this.responseType[this.lesionType],value:event.value})
  }
  checkLocked() {
   return this.visit.lock || this.sequentialLock
  }

  getWarningForDimentions(lesion: GlobalLesionModel) {
    if (lesion.type === OncologyLesionType.TARGET_LESION) {
      const aldUnit = this.criteria.ALDUnit();
      const saxUnit = this.criteria.SAXUnit();
      const devideBy = aldUnit === 'mm' && saxUnit === 'mm' ? 1 : 10;
      return lesion.axialLongDiameter < (10 / devideBy) || lesion.shortAxis < (10 / devideBy);
    }
    return false;
  }

  disableEditNewLesion(lesion: GlobalLesionModel) {
    return this.readingAllLesions.filter(l => l.lesionName === lesion.lesionName).length > 1
  }
}
