import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LesionDialogFormComponent } from 'src/app/components/controls/lesion-dialog-form/lesion-dialog-form.component';
import { MessageDialogComponent } from 'src/app/components/controls/message-dialog/message-dialog.component';
import { MranoLesionModel } from 'src/app/_models/MRANO/mrano-lesion-model';
import { ReadingMranoService } from 'src/app/_services/reading-mrano.service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import { MRanoLesionEditType, MRanoLesionObject, MRanoLesionType } from 'src/app/_models/MRANO/mrano-scoring-model';
import { LesionsListDialogComponent } from '../lesions-list-dialog/lesions-list-dialog.component';
import { PrimaryBodyLocationService } from 'src/app/_services';
import { forkJoin } from 'rxjs';
import { MranoHistoricalScoringModel } from 'src/app/_models/MRANO/mrano-historical-scoring-model';

@Component({
  selector: 'brain-lesions-table',
  templateUrl: './brain-lesions-table.component.html',
  styleUrls: ['./brain-lesions-table.component.css']
})
export class BrainLesionsTableComponent implements OnInit {

  constructor(private dialog: MatDialog,
    private readingMranoService:ReadingMranoService,
    private toastyService:ToastyService,
    private dialogMessagePreview:MatDialog,
    private primaryBodyLocationService:PrimaryBodyLocationService) { }
  displayedColumns: string[] = [ 'recordNumber','location','locationComment','editLocation', 'bidim', 'ppd','volume','comment','operations'];
  dataSource = new MatTableDataSource<any>();
  lesionsDataSource:MranoLesionModel[]=[];
  RoiSeriesStatus=[];
  primaryLocations=[];
  subLocations=[];
  locationSubscription;
  public get mranoEditType(): typeof MRanoLesionEditType {
    return MRanoLesionEditType;
  }
  public get lesionObject(): typeof MRanoLesionObject {
    return MRanoLesionObject;
  }
  public get mranoLesionType(): typeof MRanoLesionType{
    return MRanoLesionType;
  }
  @ViewChild(MatSort) sort: MatSort;
  @Input('studyId') studyId:number;
  @Input('readingId') readingId:number;
  @Input('historicalScan') historicalScan: MranoHistoricalScoringModel;
  @Input('historicalScanId') historicalScanId: number;
  @Input('eligibilityScan') eligibilityScan:any;
  @Input('lesionType') lesionType: string;
  @Input('objectType') objectType: MRanoLesionObject;
  @Input('lesionsData') lesionsData:any[];
  @Input('screenShotData') screenShotData:any;
  @Input('locked') locked?:boolean;
  @Input('eCRFOnly') eCRFOnly: boolean;
  @Input('canAddLesionOnBaseline') canAddLesionOnBaseline: boolean;
  @Input('WPD') WPD: boolean = false;
  @Output('updateLesions') updateLesions: EventEmitter<MranoLesionModel[]> = new EventEmitter<MranoLesionModel[]>();
  @Output('historicalLesions') historicalLesions:EventEmitter<MranoLesionModel[]>= new EventEmitter<MranoLesionModel[]>();
  @Output('midlineshiftRemove') midlineshiftRemove:EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('midlineshiftData') midlineshiftData:EventEmitter<any> = new EventEmitter<any>();
  @Output('lesionsLoaded') lesionsLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output('lesionDeleted') lesionDeleted: EventEmitter<any> = new EventEmitter<any>();
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
};
  ngOnInit(): void {
    // if(this.historicalScan){
    //   this.displayedColumns.splice(6,1)
    // }
    if ([MRanoLesionObject.NON_TARGET_LESION, MRanoLesionObject.NEW_LESION, MRanoLesionObject.TARGET_LESION].includes(this.objectType) && !this.WPD ) {
      this.displayedColumns.splice(this.displayedColumns.length -1, 0 , 'change')
    }
    this.locationSubscription = this.primaryBodyLocationService.findAll().subscribe(
      (response)=>{
        if(response.responseCode==200){
          this.primaryLocations = response.data;
          this.getAllLesion();
        }
      }
    )
  }
  getAllLesion(){
    if(this.historicalScan!=null){
      this.getHistoricalLesions(this.historicalScan)
    }
    if(this.eligibilityScan!=null){
      this.getEligibilityLesions(this.eligibilityScan)
    }
  }
  getHistoricalLesions(historicalScan:any){
    this.readingMranoService.getHistoricalLesions(this.studyId, historicalScan.id).subscribe(
      (response)=>{
        if(response['responseCode']==200){
          this.updateLesions.emit(response['data'])
          this.lesionsDataSource =response['data'];
          this.refreshLesionSource();
        }
      }
    )
  }
  getEligibilityLesions(eligibilitiScan:any){
    const serviceSources = [
      this.readingMranoService.getHistoricalLesions(this.studyId, this.historicalScanId),
      this.readingMranoService.getEligibilityLesions(this.studyId,eligibilitiScan.id)
    ];
    forkJoin(serviceSources).subscribe(
      (values)=>{
        const [historical, eligibility] = values as any;
        if(historical['responseCode']==200 && eligibility['responseCode']==200){
          this.updateLesions.emit(eligibility['data']);
          this.lesionsDataSource = eligibility['data'];
          this.historicalLesions.emit(historical['data']);
          let midlineshift = this.lesionsDataSource.find((object) => { return object.lesionObject === MRanoLesionObject.MIDLINESHIFT && object.status==='AAA111'})
          if(midlineshift && this.objectType === MRanoLesionObject.TARGET_LESION)
            this.midlineshiftData.emit(midlineshift)
          this.refreshLesionSource();
        }
      }
    )
  }
  onAddingLesion(mranoLesionObject?: MRanoLesionObject) {
    const data = {
      editMode: false,
      editType: null,
      type: mranoLesionObject ? mranoLesionObject : this.objectType
    }
    this.readingMranoService.onEditLesionMeasurement(data);
  }
  onEditMeasurement(editType?:string,currentLesionData?:any){
    let data;
    if(currentLesionData){
       data= {
        editMode:true,
        editType: editType,
        type: this.objectType,
        currentLesionData:currentLesionData
      }
    }
    if(!this.eCRFOnly){
      this.goToLesion(currentLesionData,true,true)
      this.readingMranoService.onEditLesionMeasurement(data);
    }
    else
      this.onOpenBrainLesion(true,currentLesionData,this.mranoEditType.MEASUREMENT,currentLesionData.lesionObject,null)
  }
  goToLesion(lesion?:MranoLesionModel,toast?:boolean,edit?:boolean){
    if(lesion){
      let seriesIdAndSliceNumber ={
        seriesId:lesion?.screenShot?lesion.screenShot.seriesId:lesion?.rois[0]?.seriesId,
        sliceNumber:lesion?.screenShot ? lesion.screenShot.sliceNumber : lesion?.rois[0]?.sliceNumber + 1
      }
      this.readingMranoService.openLTViewer(seriesIdAndSliceNumber);
      if(!edit)
          this.readingMranoService.onEditLesionMeasurement(null);
      if(toast){
        this.toastOptions.title ='ID 60: '+lesion.recordNumber+' is loaded successfully and available in the viewer'
        this.toastOptions.msg ='Reader can see the associated ROI in the new slot in the image viewer'
        this.toastOptions.timeout =15000;
        this.toastyService.success(this.toastOptions)
      }
    }
    else{
      this.readingMranoService.onEditLesionMeasurement(null);
      if(toast){
        this.toastOptions.title = 'ID 61: ' + lesion.recordNumber +' loading failure'
        this.toastOptions.msg ='Lesion is not loaded due to some reasons. Try again or contact support team'
        this.toastyService.error(this.toastOptions)
      }
    }
  }
  onOpenBrainLesion(edit: boolean, data: any, editType: string, lesionObject: string, viewerData?: any) {
    if (this.lesionsDataSource.filter(lesion => { return lesion.status === 'AAA111' && lesion.lesionObject === MRanoLesionObject.MIDLINESHIFT }).length >= 1 && lesionObject === MRanoLesionObject.MIDLINESHIFT && !edit){
        this.toastOptions.title = 'ERROR Cannot add midline shift';
        this.toastOptions.msg ='Cannot add more than one midline shift';
        this.toastyService.error(this.toastOptions);
        return;
    }
    const dialogRef = this.dialog.open(LesionDialogFormComponent, {
      width: (viewerData?.objectType ===MRanoLesionObject.MIDLINESHIFT || lesionObject ===MRanoLesionObject.MIDLINESHIFT)?  '500px':'750px',
      data: {
        //recordNumber:this.generateLesionName(lesionObject),
        studyId :this.studyId,
        readingId:this.readingId,
        editDialog:edit,
        lesionData: (data? data: {}),
        screenShotData:this.screenShotData,
        editType:editType,
        lesionObject: viewerData ? (viewerData.objectType === MRanoLesionObject.MIDLINESHIFT ? MRanoLesionObject.MIDLINESHIFT : this.objectType) : data.lesionObject,
        viewerData:viewerData,
        eCRFOnly: this.eCRFOnly,
        locationEnabled: this.objectType === MRanoLesionObject.NEW_LESION || this.canAddLesionOnBaseline || this.historicalScan || !edit,
        WPD:this.WPD
      },
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result)
       this.updateLesion(result,edit)
       else{
        if(edit){
          this.toastOptions.title = 'INFO Edit mode is disabled';
          this.toastOptions.msg ='To edit object try to click edit button';
          this.toastyService.info(this.toastOptions);
         }
       }
     });
  }
  generateLesionName(objectType:string){
    if(objectType==MRanoLesionObject.MIDLINESHIFT){
    const objectLength = this.lesionsDataSource.filter((object) =>{return object.lesionObject === MRanoLesionObject.MIDLINESHIFT}).length+1;
     return objectLength < 10 ? ('MLS0'+objectLength).toString():('MLS'+objectLength).toString()
    }
    else{
      const objectLength = this.lesionsDataSource.filter((object) => { return object.lesionObject === this.objectType }).length + 1;
      let prefix;
      switch (objectType) {
        case MRanoLesionObject.MEASURABLE:
          prefix = 'M';
          //for WPD
          if(this.WPD)
            prefix = 'T';
          break;
        case MRanoLesionObject.NON_MEASURABLE:
          prefix = 'NM'
          break;
        case MRanoLesionObject.TARGET_LESION:
          prefix = 'T'
          break;
        case MRanoLesionObject.NON_TARGET_LESION:
          prefix = 'NT'
          break;
        case MRanoLesionObject.NEW_LESION:
          prefix = 'NL'
          break;
      }
      return objectLength < 10 ? (`${(prefix +'0') + objectLength}`).toString() : (`${prefix + objectLength}`).toString()
    }
  }
  onchangeLesionType(event, lesion: MranoLesionModel) {
    lesion.changeLesionType = event.value;
    this.updateLesion(lesion, true);
  }
  updateLesion(data:any,edit:boolean){
    const lesion :MranoLesionModel = data;
    lesion.lesionType=this.lesionType;
    if(!edit)
      lesion.recordNumber = this.generateLesionName(lesion.lesionObject);
    if(this.historicalScan)
    {
      lesion.historicalScan.id = this.historicalScan.id;
      lesion.historicalScan.lock = this.historicalScan.lock;
      lesion.eligibilityScan=null;
    }
    else{
      lesion.eligibilityScan.id = this.eligibilityScan.id;
      lesion.eligibilityScan.lock = this.eligibilityScan.lock;
      if (!edit && lesion.lesionObject === MRanoLesionObject.TARGET_LESION
        && !this.canAddLesionOnBaseline)
        lesion.lesionObject = MRanoLesionObject.NEW_LESION;
      lesion.historicalScan=null;
    }
  this.updateLesionService(lesion,edit)
  }

   onShowImage(screenshot) {
     let title="There is no image to preview";
     let message="";
    if(screenshot){
      title = 'Modality: ' + screenshot.modalityName + ' Label: ' + screenshot.sequenceLabelName + ' Slice: '
      + screenshot.sliceNumber;
      message = '<img width="100%" src="'+ screenshot.fileUrl + '"/>';
    }
    const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {title: title, message: message, html: true}
    });

    dialogMessaegRef.afterClosed().subscribe(result => {
    });
  }
  onDeleteMidlineShift(midlineshift:any){
    if(midlineshift)
      this.onDelete(midlineshift);
      else
      this.midlineshiftRemove.emit(false)
  }
   onDelete(row){
	let message ='Do you want to delete the current lesion?';
	if(row.lesionObject === MRanoLesionObject.MIDLINESHIFT)
		message = 'Do you want to remove the current Linear measurement?';
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '200px',
      width: '600px',
      disableClose: true,
      data: {
          title: 'Confirmation',
          message: message,
          showOk: true,
          showCancel: true,
          html : false
      }
  });

  dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.deleteLesionService(row);
      }
  });
   }

   refreshLesionSource() {
    // console.log(this.lesionsDataSource)
      let Lesions=  this.lesionsDataSource.filter((object)=> {
        return object.lesionObject === this.objectType ;
    });
    this.dataSource = new MatTableDataSource(Lesions);
    this.dataSource.sort= this.sort;
    this.lesionsDataSource.forEach(object =>{
      object.rois.forEach(rois=>{
        this.RoiSeriesStatus.push([rois.seriesId,this.eligibilityScan?'ELIGIBILITY':'HISTORICAL',this.locked])
      })
    })
    this.readingMranoService.addSeriestoSeriesLockedStatus(this.RoiSeriesStatus)
   this.readingMranoService.modalSpinnerChangeState(false);
   this.readingMranoService.onEditLesionMeasurement(null);
  }
  primaryLocationName(id:number){
    let location =
       this.primaryLocations.find(el=>{return el.id == id});
       if(location)
           return location.name
  }
  subLocationName(primaryId:number,subId:number){
    let location;
    let subLocation;
     location= this.primaryLocations.find(el=>{return el.id == primaryId}) as any;
    if(location)
       subLocation = location.bodySubLocations.find(el=>{return el.id == subId})
       if(subLocation)
       return subLocation.name
    return
  }

  updateLesionService(data:any,edit:boolean){
    this.readingMranoService.modalSpinnerChangeState(true)
      this.readingMranoService
      .updateLesionData(this.studyId,data).subscribe(
        response=>{
          if(response['responseCode']==200){
            if(edit)
            {
             this.getAllLesion();
             this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
              this.toastOptions.msg = 'Lesion is updated';
              if (data.lesionObject === MRanoLesionObject.MIDLINESHIFT){
                this.toastOptions.title = 'ID 72: Midline shift measurement has been updated successfully';
                this.toastOptions.msg = 'Midline shift measurment is updated';
              }
            }
            else
              {

                this.getAllLesion();
                this.toastOptions.title = 'ID 42: Lesion has been created successfully';
                this.toastOptions.msg = 'Lesion is now available in the list of lesions';
              if (data.lesionObject === MRanoLesionObject.MIDLINESHIFT){
                  this.toastOptions.title = 'ID 42: Midline shift measurement has been created successfully';
                  this.toastOptions.msg = 'Midline shift measurement is now available in the eligibility form';
                }
              }
            this.toastyService.success(this.toastOptions);
          }
          else{
            if(edit){
              this.toastOptions.title = 'ID 45: Lesion updating failure';
              this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
              if (data.lesionObject === MRanoLesionObject.MIDLINESHIFT){
                this.toastOptions.title = 'ID 73: Midline shift measurement updating failure';
                this.toastOptions.msg = 'Midline shift is not updated due to some reasons. Resolve issues on the form or contact support team';
              }
            }
            else {
              this.toastOptions.title = 'ID 43: Lesion creation failure';
              this.toastOptions.msg = 'Lesion is not created due to some reason';
              if (data.lesionObject === MRanoLesionObject.MIDLINESHIFT){
                this.toastOptions.title = 'ID 43: Midline shift measurement creation failure';
                this.toastOptions.msg = 'Midline shift measurement is not created due to some reason';
              }
            }
            this.toastyService.error(this.toastOptions);
          }
        }
      )
  }
  deleteLesionService(lesion:any){
   this.readingMranoService.modalSpinnerChangeState(true)
    this.readingMranoService
    .deleteLesion(this.studyId,lesion.id).subscribe(
      response=>{
        if(response['responseCode']==200){
          this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
          this.toastOptions.msg = 'Lesion is updated';
          if (lesion.lesionObject === MRanoLesionObject.MIDLINESHIFT){
            this.toastOptions.title = 'ID 72: Midline shift measurement has been updated successfully';
            this.toastOptions.msg = 'Midline shift measurment is updated';
            this.midlineshiftRemove.emit(true)
          }
          this.toastyService.success(this.toastOptions);
          this.readingMranoService.deleteAnnObjectsSource(lesion.rois);
          this.lesionDeleted.emit(lesion);
          this.getAllLesion();
        }
       this.readingMranoService.modalSpinnerChangeState(false)
      },
      error =>{
          this.toastOptions.title = 'ID 45: Lesion updating failure';
          this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
        if (lesion.lesionObject === MRanoLesionObject.MIDLINESHIFT){
            this.toastOptions.title = 'ID 73: Midline shift measurement updating failure';
            this.toastOptions.msg = 'Midline shift is not updated due to some reasons. Resolve issues on the form or contact support team';
          }
         this.toastyService.error(this.toastOptions);
       this.readingMranoService.modalSpinnerChangeState(false)
      }
    )
  }
}

