import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {AppRouterMessageQueueObjectService} from '../../../../_services/internal/app-router-message-queue-object.service';
import {ImagingProjectService, ReadingConfigFlexibleService, UserService} from '../../../../_services';
import {BatchLogicService} from '../../../../_services/batch-logic.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {FlexibleConfig} from '../../../../core/interfaces/flexible-config';
import {ReadingType} from '../../../../core/constants/reading-type';
import {BatchConfigModel} from '../../../../_models/BatchLogic/batch-config-model';
import {FormMode} from '../../../../core/constants/form-mode';
import {
  AutoCreationBatchConfigDialogComponent,
  AutoCreationBatchDialogData
} from '../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {AutoBatchConfigPayload} from '../../../../core/payload/auto-batch-config-payload';
import {ReaderType} from '../../../../core/constants/reader-type';
import {User} from '../../../../_models/user';
import {BatchStatusModel} from '../../../../_models/BatchLogic/batch-status-model';
import {BatchModel} from '../../../../_models/BatchLogic/batch-model';
import {StudyModel, StudyDashboardModel} from '../../../../_models/ImagingProject/study-model';
import {Store} from '@ngxs/store';
import {ProjectsState} from '../../../../core/data-management/states/projects.state';
import {PushDashboardProjects} from '../../../../core/data-management/actions/projects.action';
import {forkJoin} from 'rxjs';

interface ModifiedBatchData {
  id: number;
  config: BatchConfigModel;
  created: Date;
  createdBy: number;
  createdByUser: User;
  initiationDate: Date;
  modalities: string[];
  readerUser: User;
  readings: string[];
  status: BatchStatusModel;
  updated: Date;
  updatedByUser: User;
  visits: string[];
}

@Component({
  selector: 'app-batch-list',
  templateUrl: './batch-list.component.html',
  styleUrls: ['./batch-list.component.css']
})
export class BatchListComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = ['no', 'batchId', 'created', 'initiated', 'count', 'visit', 'endpoint', 'modality', 'reader', 'author', 'status', 'action'];
  batchDataSource: MatTableDataSource<ModifiedBatchData>;

  displayedColumnsConfig: string[] = ['endpoint', 'modality', 'readerType', 'action'];
  dataSourceConfig: MatTableDataSource<any>;

  detailedBatchData: BatchModel[] = [];
  modifiedBatchData: ModifiedBatchData[] = [];

  showModalSpinnerLoadingData = false;

  studyId: number;
  study: StudyDashboardModel;
  showConfigList = false;

  EXTERNAL_AI = 'External AI';

  constructor(
    private appRouterMessageQueueObjectService: AppRouterMessageQueueObjectService,
    private imagingProjectService: ImagingProjectService,
    private userService: UserService,
    private batchLogicService: BatchLogicService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private store: Store,
  ) {
  }

  ngOnInit() {

    this.batchDataSource = new MatTableDataSource<ModifiedBatchData>();
    this.dataSourceConfig = new MatTableDataSource<any>();
    this.showConfigList = false;
    this.studyId = parseInt(this.route.snapshot.params.projectId, 10);
    this.store.select(ProjectsState.getDashboardProject(this.studyId)).subscribe(study => {
      this.study = study;
    });
    this.showModalSpinnerLoadingData = true;

    this.batchLogicService.getBatches({studyId: this.studyId}).subscribe(batchRes => {
      this.detailedBatchData = batchRes.data;
      let visitConfigIds = [];
      let usersIds = [];
      this.prepareDetailedBatchData(usersIds, visitConfigIds);
      visitConfigIds = Array.from(new Set(visitConfigIds));
      usersIds = Array.from(new Set(usersIds));

      forkJoin([
          this.userService.getByListIds(usersIds),
          this.imagingProjectService.getVisitConfigsByIdsBrief(visitConfigIds),
          this.readingConfigFlexibleService.getByStudyId(this.studyId)
      ])
      .subscribe(([usersIdsResp, visitConfigsResp, flexibleConfigResp]) => {
        // TODO: add store to storage
        //this.store.dispatch(new PushDashboardProjects());
        this.modifyBatchData(usersIdsResp.data, visitConfigsResp.data, flexibleConfigResp.data);
        this.applyBatchDataSource();
        this.showModalSpinnerLoadingData = false;
      });      
    });

  }

  modifyBatchData(users, visitConfigsData, studyFlexibleConfigs) {
    this.modifiedBatchData = this.detailedBatchData.map(batch => {
      const mBatch = {
        id: batch.id,
        config: batch.batchConfig,
        created: batch.created,
        createdBy: batch.createdBy,
        createdByUser: null,
        initiationDate: batch.initiationDate,
        updated: batch.updated,
        updatedBy: batch.updatedBy,
        updatedByUser: null,
        readerUser: null,
        status: batch.status,
        readings: [],
        visits: [],
        modalities: [],
      };

      // convert time
      // add users info
      let createdByUser = 'SYSTEM';
      let updateDbByUser = 'SYSTEM';
      let readerUser = 0;
      if (users) {
        createdByUser = users.find(u => u.id === batch.createdBy);
        updateDbByUser = users.find(u => u.id === batch.updatedBy);
        readerUser = users.find(u => u.id === batch.readerId);
      }

      mBatch.createdByUser = createdByUser;
      mBatch.updatedByUser = updateDbByUser;
      mBatch.readerUser = readerUser;

      // add endpoints, visits, modalities names
      const arrayReadingsNames = [];
      let arrayVisitNames = [];
      let arrayModalitiesNames = [];

      batch.readings.forEach(reading => {
        arrayReadingsNames.push(reading.endpoint);
        reading.visits.forEach(visit => {
          const vConf = visitConfigsData.find(vc => vc.id === visit.visitConfigId);
          if (vConf != null) {
            // original name is name from visitConfig.visit
            arrayVisitNames.push(vConf.visitOriginalName);
          }
          visit.modalities.forEach(vModality => {
            arrayModalitiesNames.push(vModality.name);
          });
        });
      });
      arrayVisitNames = Array.from(new Set(arrayVisitNames));
      arrayModalitiesNames = Array.from(new Set(arrayModalitiesNames));

      mBatch.readings = arrayReadingsNames;
      mBatch.visits = arrayVisitNames;
      mBatch.modalities = arrayModalitiesNames;

      if (studyFlexibleConfigs) {
        mBatch.config.flexibleConfig = studyFlexibleConfigs.find(c => c.id === mBatch.config.flexibleConfigId);
      } 

      return mBatch;
    });
  }

  prepareDetailedBatchData(usersIds, visitConfigIds) {
    this.detailedBatchData.forEach(batch => {
      if (batch.readerId != null) {
        usersIds.push(batch.readerId);
      }
      if (batch.createdBy != null) {
        usersIds.push(batch.createdBy);
      }
      if (batch.updatedBy != null) {
        usersIds.push(batch.updatedBy);
      }

      batch.readings.forEach(reading => {
        reading.visits.forEach(visit => {
          visitConfigIds.push(visit.visitConfigId);
        });
      });

    });
  }

  onEditBatch(element) {
    // clean buffer when edit batch
    this.appRouterMessageQueueObjectService.saveObject(null);
    this.router.navigate([`/operationdashboard/batch/${this.studyId}/${element.id}`]).then(r => {
    });
  }

  onCreateBatchManually() {
    this.appRouterMessageQueueObjectService.saveObject(null);
    this.router.navigate([`/operationdashboard/batch/${this.studyId}`]).then(r => {
    });
  }

  openAutoConfig() {
    this.showConfigList = true;
    this.showModalSpinnerLoadingData = true;
    this.readingConfigFlexibleService.getActiveConfigsByStudyId(this.studyId).subscribe(flexConfigsResp => {
      const flexConfigs: FlexibleConfig[] = flexConfigsResp.data;
      const splitFlexConfigs: FlexibleConfig[] = [];
      for (const fc of flexConfigs) {
        // for configs where endpointReadingVersion is not present in config json section
        if (fc.config.endpointReadingVersion == null) {
          if (fc.config.adjudicationReader != null) {
            fc.config.endpointReadingVersion = 'adjudication';
            fc.config.endpointReaderType = 'basic';
            const fcAdjudicator: FlexibleConfig = JSON.parse(JSON.stringify(fc));
            fcAdjudicator.config.endpointReaderType = 'adjudication';
            splitFlexConfigs.push(fc);
            splitFlexConfigs.push(fcAdjudicator);
          } else if (fc.config.moderationReader != null) {
              fc.config.endpointReadingVersion = 'moderation';
              fc.config.endpointReaderType = 'basic';
              const fcAdjudicator: FlexibleConfig = JSON.parse(JSON.stringify(fc));
              fcAdjudicator.config.endpointReaderType = 'moderation';
              splitFlexConfigs.push(fc);
              splitFlexConfigs.push(fcAdjudicator);
          } else {
            fc.config.endpointReadingVersion = 'basic';
            // fc.config.endpointReaderType = 'basic';
            splitFlexConfigs.push(fc);
          }
        } else {
          if (fc.config.endpointReadingVersion === 'adjudication') {
            fc.config.endpointReaderType = 'adjudication';
            const fcBasic = JSON.parse(JSON.stringify(fc));
            fcBasic.config.endpointReaderType = 'basic';
            splitFlexConfigs.push(fcBasic);
            splitFlexConfigs.push(fc); // adjudication config
          } else if (fc.config.endpointReadingVersion === 'moderation') {
              fc.config.endpointReaderType = 'moderation';
              const fcBasic = JSON.parse(JSON.stringify(fc));
              fcBasic.config.endpointReaderType = 'basic';
              splitFlexConfigs.push(fcBasic);
              splitFlexConfigs.push(fc); // moderation config
          } else if (fc.config.endpointReadingVersion === 'basic') {
            splitFlexConfigs.push(fc); // only basic
          } else if (fc.config.endpointReadingVersion === this.EXTERNAL_AI) {
            splitFlexConfigs.push(fc); // JSW config
          }
        }
      }

      this.dataSourceConfig.data = splitFlexConfigs;
      this.showModalSpinnerLoadingData = false;
    });
  }

  onOK() {
    this.showConfigList = false;
  }

  configureAutoBatch(flexConfig: FlexibleConfig) {
    const readingType: ReadingType = this.defineReadingType(flexConfig);

    this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, flexConfig.id).subscribe(response => {
      let batchConfig: BatchConfigModel = null;
      response.data.forEach(c => {
        if (c.readingType === readingType) {
          batchConfig = c;
        }
      });

      let formMode: FormMode;
      if (batchConfig == null) {
        formMode = FormMode.NEW;
      } else {
        formMode = FormMode.EDIT;
      }

      let readonlyDialog = false;
      if (readingType === ReadingType.BASIC_READING && flexConfig.config?.batchManagement === false) {
        readonlyDialog = true;
      } else if (readingType === ReadingType.LEVEL1_READING && flexConfig.config?.batchManagement === false) {
        readonlyDialog = true;
      } else if (readingType === ReadingType.ADJUDICATION_READING && flexConfig.config?.adjudicationBatchManagement === false) {
        readonlyDialog = true;
      } else if (readingType === ReadingType.MODERATION_READING && flexConfig.config?.moderationBatchManagement === false) {
        readonlyDialog = true;
      }

      const data: AutoCreationBatchDialogData = {
        readingType: readingType,
        configInput: batchConfig,
        readonlyDialog: readonlyDialog
      };

      const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
        width: '500px',
        data: data
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.createOrUpdateBatchConfig(flexConfig.id, result, formMode);
        }
      });

    });

  }

  createOrUpdateBatchConfig(flexConfigId: number, batchConfigPayload: AutoBatchConfigPayload, formMode: FormMode) {
    if (formMode === FormMode.NEW) {
      this.batchLogicService.createBatchConfig(this.studyId, flexConfigId, batchConfigPayload).subscribe();
    } else {
      this.batchLogicService.updateBatchConfig(this.studyId, flexConfigId, batchConfigPayload).subscribe();
    }
  }

  defineReadingType(flexConfig: FlexibleConfig): ReadingType {
    let readingType: ReadingType;
    if (flexConfig.config.endpointReaderType) {
      if (flexConfig.config.endpointReaderType === 'adjudication') {
        readingType = ReadingType.ADJUDICATION_READING;
      } else if (flexConfig.config.endpointReaderType === 'moderation') {
        readingType = ReadingType.MODERATION_READING;
      } else if (flexConfig.config.endpointReaderType === 'basic') {
        readingType = ReadingType.LEVEL1_READING;
      }
    } else {
      readingType = ReadingType.BASIC_READING;
    }
    return readingType;
  }

  defineReadingTypeName(flexConfig: FlexibleConfig): string {
    if (flexConfig.config.endpointReaderType) {
        if (flexConfig.config.endpointReaderType === 'adjudication') {
            return 'Adjudication';
        } else if (flexConfig.config.endpointReaderType === 'basic') {
            return 'Basic';
        } else if (flexConfig.config.endpointReaderType === 'moderation') {
            return 'Moderation';
        }
    } else {
      return 'Basic';
    }
  }

  defineReaderTypeName(flexConfig: FlexibleConfig): ReaderType {
    let readerType: ReaderType;
    if (flexConfig.config.endpointReaderType) {
      if (flexConfig.config.endpointReaderType === 'adjudication') {
        readerType = ReaderType.ADJUDICATION_READER_NAME;
      } else if (flexConfig.config.endpointReaderType === 'basic') {
        readerType = ReaderType.LEVEL1_READER_NAME;
      } else if (flexConfig.config.endpointReaderType === 'moderation') {
        readerType = ReaderType.MODERATION_READER_NAME;
      }
    } else {
      readerType = ReaderType.BASIC_READER_NAME;
    }
    return readerType;
  }

  applyBatchDataSource() {
    this.batchDataSource.data = this.modifiedBatchData;
    this.batchDataSource.paginator = this.paginator;
    this.batchDataSource.sort = this.sort;
    this.showModalSpinnerLoadingData = false;
  }

}
