import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface ImageConfigurationDialogData {
  reading : string,
  readonlyDialog:boolean,
  imageConfig:any
}
export interface ImageViewerConfigurationModel{
  parametricMaps:any[],
  motionCorrection:any,
  statisticalInformation:any[]
}
@Component({
  selector: 'app-image-viewer-config-dialog',
  templateUrl: './image-viewer-config-dialog.component.html',
  styleUrls: ['./image-viewer-config-dialog.component.css']
})
export class ImageViewerConfigDialogComponent implements OnInit {

  imageViewerConfig:ImageViewerConfigurationModel;
  constructor(public dialogRef: MatDialogRef<ImageViewerConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ImageConfigurationDialogData) {
}

parametricMapsControl:FormControl = new FormControl('',Validators.required)
motionCorrectionControl:FormControl = new FormControl('',Validators.required)
statisticalInformationControl:FormControl = new FormControl('',Validators.required)

  ngOnInit(): void {
  }
  okClick(){
    this.imageViewerConfig = {
      parametricMaps:this.parametricMapsControl.value,
      motionCorrection:this.motionCorrectionControl.value,
      statisticalInformation:this.statisticalInformationControl.value
    }
    this.dialogRef.close(this.imageViewerConfig)
  }
}
