export class AlphabetEncoder {
  public static ALPHABET = AlphabetEncoder.getAlphabet();
  private static ENCODE_LENGTH = AlphabetEncoder.ALPHABET.length;

  private static getAlphabet(): string[] {
    const alphabet: string[] = Array.from("0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzœ∑´†¥¨ˆπ“‘åß∂ƒ©˙∆˚¬…Ω≈ç√∫˜≤≥µ¡™£¢∞§¶•ªº≠`!@#$%^&*+'?<>:");
    alphabet.sort()

    return alphabet;
  }

  public static encode(victim: number): string {
    const list = []

    do {
      list.push(AlphabetEncoder.ALPHABET[(victim % AlphabetEncoder.ENCODE_LENGTH)]);
      victim /= AlphabetEncoder.ENCODE_LENGTH;
    } while (victim > 0);

    list.reverse()
    return list.join('')
  }

  public static decode(encoded: string): number {
    let ret = 0;
    let c;
    for (let index = 0; index < encoded.length; index++) {
      c = encoded.charAt(index);
      ret *= AlphabetEncoder.ENCODE_LENGTH;
      ret += binarySearch(AlphabetEncoder.ALPHABET, c);
    }
    return ret;
  }
}

export function binarySearch(array, targetValue) {
  let min = 0;
  let max = array.length - 1;
  let guess;

  while (min <= max) {
    guess = Math.floor((max + min) / 2);

    if (array[guess] === targetValue) {
      return guess;
    } else if (array[guess] < targetValue) {
      min = guess + 1;
    } else {
      max = guess - 1;
    }

  }

  return -1;
}
