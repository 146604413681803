import {BasicReading, BasicReadingVisit} from '../../../_models/basic-reading';

export const areAllVisitsLocked = <T extends BasicReadingVisit>(visits: T[]): boolean => {
  return !visits.find(v => v.locked === false);
};

export const hasReadingNotLockedVisits = <T extends BasicReading>(reading: T): boolean => {
  return areAllVisitsLocked(reading.visits);
};

export const setVisitsLockAvailability = (visits: BasicReadingVisit[]): BasicReadingVisit[] => {
  const visitsCopy = visits.map(v => Object.assign({}, v));
  visitsCopy.forEach((value, index, array) => {
    if (value.baseline && !value.locked) {
      value.lockAvailable = true;
    } else if (value.baseline && value.locked) {
      value.lockAvailable = true;
    }
    if (value.locked && !!array[index + 1]) {
      array[index + 1].lockAvailable = true;
    }
  });
  return visitsCopy;
};
