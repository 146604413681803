import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MranoEligibilityScoringModel } from 'src/app/_models/MRANO/mrano-eligibility-scoring-model';
import { MranoLesionModel } from 'src/app/_models/MRANO/mrano-lesion-model';
import { MRanoLesionEditType, MRanoLesionObject, MRanoLesionType, MRanoScoringType } from 'src/app/_models/MRANO/mrano-scoring-model';
import { BrainLesionsTableComponent } from '../brain-lesions-table/brain-lesions-table.component';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ReadingMranoService } from 'src/app/_services/reading-mrano.service';
import { DataUploadService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { MranoHistoricalScoringModel } from 'src/app/_models/MRANO/mrano-historical-scoring-model';

@Component({
  selector: 'app-mrano-eligibility-form',
  templateUrl: './mrano-eligibility-form.component.html',
  styleUrls: ['./mrano-eligibility-form.component.css']
})
export class MranoEligibilityFormComponent implements OnInit, OnDestroy {

  constructor(private toastyService: ToastyService,
    private readingMranoService: ReadingMranoService,
    private dataUploadService: DataUploadService) { }
  mranoLesionType = MRanoLesionType;
  mranoLesionObject = MRanoLesionObject;
  mranoScoringType=MRanoScoringType;
  eligibilityForm: FormGroup;
  historicalLesions: MranoLesionModel[];
  @Input('studyId') studyId:number;
  @Input('readingId') readingId:number;
  @Input('readerId') readerId:number;
  @Output('scorLocked') scorelocked: EventEmitter<any>= new EventEmitter<any>();
  @Input('locked') formLocked:boolean;
  @Input('screenShotData') screenShotData:any;
  @Input('eCRFOnly') eCRFOnly:boolean;
  @Input('eligibilityScan') eligibilityScan:MranoEligibilityScoringModel;
  @Input('historicalScan') historicalScan: MranoHistoricalScoringModel;
  @Input('enableVolume') enableVolume: boolean;
  @Input('timepoint') timepoint: string;
  @Input('previousTimepoint') previousTimepoint: string;
  @Input('protocolVersion') protocolVersion: string;
  @Input('visitConfigId') visitConfigId: number;
  @Input('enableWidespreadOrFocal') enableWidespreadOrFocal: boolean;
  @Output('changedEligibilityManually') changedEligibilityManually: EventEmitter<string>= new EventEmitter<string>();
  @ViewChild('targetLesionTable') targetLesionTable: BrainLesionsTableComponent;
  @ViewChild('nonTargetLesionTable') nonTargetLesionTable: BrainLesionsTableComponent;
  @ViewChild('newLesionTable') newLesionTable: BrainLesionsTableComponent;

  scoringLockData:MranoEligibilityScoringModel;
  listOfLesions:MranoLesionModel[]=[];
  midlineshift:boolean = false;
  midlineshiftObject:any;
  eligibilityStatus:string='';
  notMeasuredLesions = [];
  sppd;
  sppdComparison;
  totalVolume;
  generalInfoSubscription: Subscription;
  public get mranoEditType(): typeof MRanoLesionEditType {
    return MRanoLesionEditType;
  }
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  ngOnInit(): void {
    this.initForm();
  }

  ngOnDestroy(): void {
    if(this.generalInfoSubscription)
    this.generalInfoSubscription.unsubscribe();
  }

  initForm(){
    this.eligibilityForm = new FormGroup({
      imageQuality : new FormControl(null,[Validators.required]),
      imageQualityComment:new FormControl({value: '', disabled: this.eligibilityScan.lock}),
      locatedSupratentorially : new FormControl(null,[Validators.required]),
      locatedSupratentoriallyComment:new FormControl({value: '', disabled: this.eligibilityScan.lock},[Validators.maxLength(200)]),
      midlineShift: new FormControl(null, [Validators.required]),
      eligibleDueToMidlineShift: new FormControl(null, [Validators.required]),
      massEffectAssessmentComment: new FormControl(null),
      massEffectAssessment: new FormControl(null, [Validators.required]),
      midlineShiftAssessmentComment: new FormControl({ value: null, disabled: this.eligibilityScan.lock }),
      eligibleDueToMassEffect: new FormControl(null, [Validators.required]),
      leptomeningeal: new FormControl(null),
      eligibleDueToLeptomeningeal: new FormControl(null),
      csfMetastases: new FormControl(null),
      eligibleDueToCsfMetastases: new FormControl(null),
      ventricularInvasion: new FormControl(null),
      eligibleDueToVentricularInvasion: new FormControl(null),
      widespreadOrFocal: new FormControl(null),
      widespreadOrFocalComment: new FormControl(null),
      substantialIncrease: new FormControl(null),
      substantialIncreaseComment: new FormControl(null),
      eligibleDueToWidespreadOrFocal: new FormControl(null),
      eligible: new FormControl(null),
      comment: new FormControl({ value: null, disabled: this.eligibilityScan.lock }),
      totalVolume: new FormControl({ value: null, disabled: this.eligibilityScan.lock }, [Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')])
    })
    if(this.eligibilityScan){
      this.eligibilityForm.get('imageQuality').setValue(this.eligibilityScan.imageQualityAssessment);
      this.eligibilityForm.get('imageQualityComment').setValue(this.eligibilityScan.imageQualityAssessmentComment);
      this.eligibilityForm.get('locatedSupratentorially').setValue(this.eligibilityScan.diseaseLocationAssessment);
      this.eligibilityForm.get('locatedSupratentoriallyComment').setValue(this.eligibilityScan.diseaseLocationAssessmentComment);
      this.eligibilityForm.get('midlineShift').setValue(this.eligibilityScan.midlineShiftAssessment);
      this.eligibilityForm.get('eligibleDueToMidlineShift').setValue(this.eligibilityScan.eligibleDueToMidlineShift);
      this.eligibilityForm.get('midlineShiftAssessmentComment').setValue(this.eligibilityScan.midlineShiftAssessmentComment);
      this.eligibilityForm.get('massEffectAssessment').setValue(this.eligibilityScan.massEffectAssessment);
      this.eligibilityForm.get('massEffectAssessmentComment').setValue(this.eligibilityScan.massEffectAssessmentComment);
      this.eligibilityForm.get('eligibleDueToMassEffect').setValue(this.eligibilityScan.eligibleDueToMassEffect);
      this.eligibilityForm.get('leptomeningeal').setValue(this.eligibilityScan.leptomeningeal);
      this.eligibilityForm.get('eligibleDueToLeptomeningeal').setValue(this.eligibilityScan.eligibleDueToLeptomeningeal);
      this.eligibilityForm.get('csfMetastases').setValue(this.eligibilityScan.csfMetastases);
      this.eligibilityForm.get('eligibleDueToCsfMetastases').setValue(this.eligibilityScan.eligibleDueToCsfMetastases);
      this.eligibilityForm.get('ventricularInvasion').setValue(this.eligibilityScan.ventricularInvasion);
      this.eligibilityForm.get('eligibleDueToVentricularInvasion').setValue(this.eligibilityScan.eligibleDueToVentricularInvasion);
      this.eligibilityForm.get('widespreadOrFocal').setValue(this.eligibilityScan.widespreadOrFocal);
      this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').setValue(this.eligibilityScan.eligibleDueToWidespreadOrFocal);
      this.eligibilityForm.get('widespreadOrFocalComment').setValue(this.eligibilityScan.widespreadOrFocalComment);
      this.eligibilityForm.get('substantialIncrease').setValue(this.eligibilityScan.substantialIncrease);
      this.eligibilityForm.get('substantialIncreaseComment').setValue(this.eligibilityScan.substantialIncreaseComment);
      this.eligibilityForm.get('comment').setValue(this.eligibilityScan.comment);
      this.eligibilityForm.get('totalVolume').setValue(this.eligibilityScan.totalVolume);
      this.eligibilityForm.updateValueAndValidity();
      this.eligibilityStatus = this.eligibilityScan.eligibilityStatus;
      this.changeCommentValidator(this.eligibilityStatus);
    }
    if(this.protocolVersion === 'V5'){
      this.eligibilityForm.get('leptomeningeal').validator = Validators.required;
      this.eligibilityForm.get('leptomeningeal').updateValueAndValidity();
      this.eligibilityForm.get('eligibleDueToLeptomeningeal').validator = Validators.required;
      this.eligibilityForm.get('eligibleDueToLeptomeningeal').updateValueAndValidity();
      this.eligibilityForm.get('csfMetastases').validator = Validators.required;
      this.eligibilityForm.get('csfMetastases').updateValueAndValidity();
      this.eligibilityForm.get('eligibleDueToCsfMetastases').validator = Validators.required;
      this.eligibilityForm.get('eligibleDueToCsfMetastases').updateValueAndValidity();
      this.eligibilityForm.get('ventricularInvasion').validator = Validators.required;
      this.eligibilityForm.get('ventricularInvasion').updateValueAndValidity();
      this.eligibilityForm.get('eligibleDueToVentricularInvasion').validator = Validators.required;
      this.eligibilityForm.get('eligibleDueToVentricularInvasion').updateValueAndValidity();
    }
    if (this.enableWidespreadOrFocal && this.protocolVersion === 'V5') {
      this.eligibilityForm.get('widespreadOrFocal').validator = Validators.required;
      this.eligibilityForm.get('widespreadOrFocal').updateValueAndValidity();
    }
    if(this.protocolVersion === 'WPD'){
      this.eligibilityForm.get('widespreadOrFocal').validator = Validators.required;
      this.eligibilityForm.get('widespreadOrFocal').updateValueAndValidity();
      this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').validator = Validators.required;
      this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').updateValueAndValidity();
      this.eligibilityForm.get('substantialIncrease').validator = Validators.required;
      this.eligibilityForm.get('substantialIncrease').updateValueAndValidity();
    }

    this.eligibilityForm.get('totalVolume').valueChanges.subscribe(() => {
      this.clearEligibilityStatus();
    })
  }

  clearEligibilityStatus() {
    this.eligibilityStatus = '';
    this.changeCommentValidator(this.eligibilityStatus);
  }

  onChangeMidlienshift(event){
    this.eligibilityForm.get('eligibleDueToMidlineShift').reset();
    if(event.value === 'No' && this.protocolVersion === 'WPD')
      this.eligibilityForm.get('eligibleDueToMidlineShift').setValue(true);

    this.eligibilityForm.get('eligibleDueToMidlineShift').validator = event.value !== 'Not_Evaluable' ? Validators.required : null;
    this.eligibilityForm.get('eligibleDueToMidlineShift').updateValueAndValidity();
    this.onChangeAnswer(event, 'midlineShiftAssessmentComment');
    this.clearEligibilityStatus();
  }

  onChangeWidespreadOrFocal(event) {
    this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').reset();
    if(event.value === 'No' && this.protocolVersion === 'V5')
      this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').setValue(false);

    this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').validator =
    (event.value === 'Yes' || (event.value !== 'Not_Evaluable' && this.protocolVersion ==='WPD')) ? Validators.required : null;
    this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').updateValueAndValidity();
    this.onChangeAnswer(event, 'widespreadOrFocalComment');
  }

  onChangeMassEffect(event){
    this.eligibilityForm.get('eligibleDueToMassEffect').reset();
    this.eligibilityForm.get('eligibleDueToMassEffect').validator = event.value !== 'Not_Evaluable' ? Validators.required : null;
    this.eligibilityForm.get('eligibleDueToMassEffect').updateValueAndValidity();
    this.onChangeAnswer(event, 'massEffectAssessmentComment');
  }

  changeCommentValidator(value: string | undefined) {
    if (typeof value === 'string') {
      this.eligibilityForm.get('comment').validator = value.toLowerCase() === 'no' ? Validators.required : null;
      this.eligibilityForm.get('comment').updateValueAndValidity();
      this.eligibilityForm.get('comment').markAsTouched();
    }
  }

  validateNumber(event, maxNumber) {
    const keyCode = event.keyCode;
    if (keyCode == 16)
      event.preventDefault()

    if (event.target.value.length == maxNumber && keyCode != 8)
      event.preventDefault()

    const excludedKeys = [8, 37, 39, 46, 190];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode > 64 && keyCode < 91) ||
      (keyCode == 32) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }
  onChangeAnswer(event, formControlName: string){
    this.eligibilityForm.get(formControlName).validator = event.value == 'Not_Evaluable' ? Validators.required : null;
    this.eligibilityForm.get(formControlName).updateValueAndValidity();
    this.eligibilityForm.get(formControlName).markAsTouched();
    this.clearEligibilityStatus();
  }
  onUpdateLesions(data) {
    data.forEach((l, i) => {
      const lesionindex = this.listOfLesions.findIndex(lesion => lesion.id === l.id);
      if (lesionindex >= 0) {
        this.listOfLesions[lesionindex] = l;
      }
      else
        this.listOfLesions.push(l)
    })
    this.notMeasuredLesions = this.listOfLesions.filter(l => l.lesionObject !== this.mranoLesionObject.MIDLINESHIFT && !l.maxBiDimension && l.status === 'AAA111')
    this.eligibilityStatus = '';
    this.changeCommentValidator(this.eligibilityStatus);
    this.calculateSummary();
  }
  onDeletedLesion(lesion: MranoLesionModel) {
    this.listOfLesions = this.listOfLesions.filter(l => l.id !== lesion.id);
    this.calculateSummary();
  }
  getHistoricalLesions(data) {
    this.historicalLesions = data;
    this.calculateSummary();
  }
  checkMandatoryFields(){
    let invalid = false;
    if(!this.eligibilityForm.valid ||
      this.formLocked ||
      this.eligibilityScan.lock ||
      this.notMeasuredLesions.length != 0 ||
      (this.protocolVersion === 'WPD' &&
      !this.midlineshiftObject &&
      this.eligibilityForm.get('midlineShift').value === 'Yes')
         )
            invalid = true;
    return invalid;
  }
  onScoreLock(){

    this.scoringLockData = {
      id:this.eligibilityScan.id,
      imageQualityAssessment:this.eligibilityForm.get('imageQuality').value,
      imageQualityAssessmentComment:this.eligibilityForm.get('imageQualityComment').value,
      diseaseLocationAssessment:this.eligibilityForm.get('locatedSupratentorially').value,
      diseaseLocationAssessmentComment:this.eligibilityForm.get('locatedSupratentoriallyComment').value,
      midlineShiftAssessment: this.eligibilityForm.get('midlineShift').value,
      eligibleDueToMidlineShift: this.eligibilityForm.get('eligibleDueToMidlineShift').value,
      midlineShiftAssessmentComment: this.eligibilityForm.get('midlineShiftAssessmentComment').value,
      massEffectAssessment: this.eligibilityForm.get('massEffectAssessment').value,
      massEffectAssessmentComment: this.eligibilityForm.get('massEffectAssessmentComment').value,
      eligibleDueToMassEffect: this.eligibilityForm.get('eligibleDueToMassEffect').value,
      leptomeningeal: this.eligibilityForm.get('leptomeningeal').value,
      eligibleDueToLeptomeningeal: this.eligibilityForm.get('eligibleDueToLeptomeningeal').value,
      csfMetastases: this.eligibilityForm.get('csfMetastases').value,
      eligibleDueToCsfMetastases: this.eligibilityForm.get('eligibleDueToCsfMetastases').value,
      ventricularInvasion: this.eligibilityForm.get('ventricularInvasion').value,
      eligibleDueToVentricularInvasion: this.eligibilityForm.get('eligibleDueToVentricularInvasion').value,
      widespreadOrFocal: this.eligibilityForm.get('widespreadOrFocal').value,
      eligibleDueToWidespreadOrFocal: this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').value,
      widespreadOrFocalComment: this.eligibilityForm.get('widespreadOrFocalComment').value,
      substantialIncrease: this.eligibilityForm.get('substantialIncrease').value,
      substantialIncreaseComment: this.eligibilityForm.get('substantialIncreaseComment').value,
      comment: this.eligibilityForm.get('comment').value,
      lesions:this.listOfLesions,
      lock: true,
      sumPPD: this.sppd / 100,
      totalVolume: this.eligibilityForm.get('totalVolume').value,
      changeSPPD: this.sppdComparison,
      useBevacizumab: this.eligibilityScan.useBevacizumab
    }
    let emitData = {
      type:this.mranoScoringType.ELIGIBILITY,
      data:this.scoringLockData
    }
    this.scorelocked.emit(emitData)
  }
  addObjectFromLeadtools(data:any,edit:boolean,editType:string,editData, objectType?: MRanoLesionObject){
    if(editData){
      if((data.objectType == 'MIDLINESHIFT' && editData.lesionObject !='MIDLINESHIFT') ||
      (editData.lesionObject == 'MIDLINESHIFT' && data.objectType !='MIDLINESHIFT') ){
        this.toastOptions.title='ERORR Editing wrong object'
        this.toastOptions.msg='trying to edit '+editData.lesionObject+' with '+data.objectType;
        this.toastyService.error(this.toastOptions);
        setTimeout(() => {
          this.toastOptions.title = 'INFO Edit mode is disabled';
          this.toastOptions.msg ='To edit object try to click edit button';
          this.toastyService.info(this.toastOptions);
        }, 1500);
        return
      }
    }
    if (this.listOfLesions.filter(lesion => { return lesion.status === 'AAA111' && lesion.lesionObject !== MRanoLesionObject.MIDLINESHIFT }).length >= 5 && !edit && data?.objectType !== MRanoLesionObject.MIDLINESHIFT) {
      this.toastOptions.title = 'ERROR Cannot add lesion';
      this.toastOptions.msg = 'Cannot add more than 5 lesions';
      this.toastyService.error(this.toastOptions);
      return;
    }
    if(data.objectType == 'MIDLINESHIFT' && this.eligibilityForm.get('midlineShift').value !== 'Yes'){
      this.toastOptions.title='ERORR Cannot amend midline shif'
      this.toastOptions.msg='midline shift cannot be amended while "Yes" option is not chosen for midline shift assessment question';
      this.toastyService.error(this.toastOptions);
      return;
    }
    if (data.objectType === MRanoLesionObject.MIDLINESHIFT || objectType === MRanoLesionObject.TARGET_LESION)
      this.targetLesionTable.onOpenBrainLesion(edit, editData, editType, data.objectType, data)
    switch (objectType) {
      case MRanoLesionObject.NON_TARGET_LESION:
        this.nonTargetLesionTable.onOpenBrainLesion(edit, editData, editType, data.objectType, data)
        break;
      case MRanoLesionObject.NEW_LESION:
        this.newLesionTable.onOpenBrainLesion(edit, editData, editType, data.objectType, data)
      default:
        break;
    }
  }
  onRemoveMidlineShift(midlineshift:any){
    this.targetLesionTable.onDeleteMidlineShift(midlineshift);
  }
  onMidlishiftRemoved(event){
    this.midlineshift = !event;
    this.midlineshiftObject=null;
  }
  onMidlineshiftData(event){
      this.midlineshiftObject= event
      this.midlineshift = true
  }
  onAddMidlineshift(event){
    if(!this.eCRFOnly)
      this.readingMranoService.onEditLesionMeasurement(event);
    else
      this.targetLesionTable.onOpenBrainLesion(false, null, null, MRanoLesionObject.MIDLINESHIFT,null);
  }
  onAddMidlineshiftGBM() {
    this.targetLesionTable.onAddingLesion(this.mranoLesionObject.MIDLINESHIFT);
  }
  onEditMidlineshiftMeasurement(event){
    if(event){
      let data= {
        editMode:true,
        editType: 'MEASUREMENT',
        type: MRanoLesionObject.MIDLINESHIFT,
        currentLesionData:event
      }
      if(!this.eCRFOnly){
        this.goToLesion(event, true, true);
        this.readingMranoService.onEditLesionMeasurement(data);
      }
      else
        this.targetLesionTable.onOpenBrainLesion(true,event,this.mranoEditType.MEASUREMENT,event.lesionObject,null)
    }
  }
  goToLesion(lesion?: MranoLesionModel, toast?: boolean, edit?: boolean) {
    if(lesion){
      let seriesIdAndSliceNumber ={
        seriesId:lesion?.screenShot?lesion.screenShot.seriesId:lesion?.rois[0]?.seriesId,
        sliceNumber: lesion?.screenShot ? lesion.screenShot.sliceNumber : lesion?.rois[0]?.sliceNumber + 1
      }
      this.readingMranoService.openLTViewer(seriesIdAndSliceNumber);
      if(!edit)
           this.readingMranoService.onEditLesionMeasurement()
      if(toast){
        this.toastOptions.title ='ID 60: Lesion is loaded successfully and available in the viewer'
        this.toastOptions.msg ='Reader can see the associated ROI in the new slot in the image viewer'
        this.toastOptions.timeout =15000;
        this.toastyService.success(this.toastOptions)
      }
    }
    else{
      this.readingMranoService.onEditLesionMeasurement()
      if(toast){
        this.toastOptions.title ='ID 61: Lesion loading failure'
        this.toastOptions.msg ='Lesion is not loaded due to some reasons. Try again or contact support team'
        this.toastyService.error(this.toastOptions)
      }
    }
  }
  checkEligibilityStatus(){
    this.changedEligibilityManually.emit(null);
    this.toastOptions.title='ID 93: Patient eligibility evaluation started successfully';
    this.toastOptions.msg ='Patient eligibility is to be computed. When process is done then you can see the result on the screen';
    this.toastOptions.timeout = 5000;
    this.toastyService.success(this.toastOptions);
    this.readingMranoService.modalSpinnerChangeState(true)
    setTimeout(() => {
      const data = {
        diseaseLocation: this.eligibilityForm.get('locatedSupratentorially').value,
        eligibleDueToMidlineShift: this.eligibilityForm.get('eligibleDueToMidlineShift').value,
        eligibleDueToMassEffect: this.eligibilityForm.get('eligibleDueToMassEffect').value,
        eligibleDueToLeptomeningeal: this.eligibilityForm.get('eligibleDueToLeptomeningeal').value,
        eligibleDueToCsfMetastases: this.eligibilityForm.get('eligibleDueToCsfMetastases').value,
        eligibleDueToVentricularInvasion: this.eligibilityForm.get('eligibleDueToVentricularInvasion').value,
        eligibleDueToWidespreadOrFocal: this.eligibilityForm.get('eligibleDueToWidespreadOrFocal').value,
        substantialIncrease: this.eligibilityForm.get('substantialIncrease').value,
        totalVolume: this.eligibilityForm.get('totalVolume').value
      }
      this.readingMranoService.getEligibilityStatus(this.studyId,this.readingId,data).subscribe(
        response=>{
          if(response['responseCode']===200){
            this.toastOptions.title='ID 95: Patient eligibility evaluation is finished successfully';
            this.toastOptions.msg ='You can find the eligibility status of the patient on the reading form. Timepoint can be locked and reading can be submitted';
            this.toastOptions.timeout = 10000;
            this.toastyService.success(this.toastOptions);
            this.eligibilityStatus = response['data'];
            this.changeCommentValidator(this.eligibilityStatus);
            this.eligibilityForm.get('eligible').setValue(this.eligibilityStatus);
            this.eligibilityForm.get('eligible').updateValueAndValidity();
          }
          else{
            this.toastOptions.title='ID 96: Patient eligibility evaluation failure';
            this.toastOptions.msg ='Patient eligibility check is failed due to some reason. Resolve issues on the form or contact support team';
            this.toastOptions.timeout = 10000;
            this.toastyService.error(this.toastOptions);
            this.eligibilityStatus = null;
            this.eligibilityForm.get('eligible').setValue(null);
            this.eligibilityForm.get('eligible').updateValueAndValidity();
          }
          this.readingMranoService.modalSpinnerChangeState(false);
        },
        error=>{
          this.eligibilityStatus = null;
          this.showErrorMessage(error);
        }
      )
    }, 1000);
  }

  onChangeFinalStatus(event: any){
    const value = event.value === 'Yes' ? 'ELIGIBLE' : 'INELIGIBLE';
    this.changedEligibilityManually.emit(value);
    this.changeCommentValidator(event.value);
    if (this.protocolVersion === 'WPD') {
      this.eligibilityForm.get('comment').validator = Validators.required;
      this.eligibilityForm.get('comment').updateValueAndValidity();
      this.eligibilityForm.get('comment').markAsTouched();
    }
  }

  showErrorMessage(response: any): void {
    this.toastOptions.title = 'ERROR ' + response['responseCode'];
    this.toastOptions.msg = response['responseMessage'];
    this.toastyService.error(this.toastOptions);
    this.readingMranoService.modalSpinnerChangeState(false);
  }

  calculateSummary() {
    this.sppd = this.sppdCalculation(this.listOfLesions).toFixed(2);
    //this.totalVolumeCalculation();
    if (this.eligibilityScan) {
      this.comparisonCalculation();
    }
  }
  sppdCalculation(lesionSource: MranoLesionModel[]) {
    if (lesionSource) {
      let sppd = 0;
      let activeLesions = lesionSource.filter((object) => {
        return ((([MRanoLesionObject.MEASURABLE, MRanoLesionObject.TARGET_LESION].includes(object.lesionObject)) && (!object.changeLesionType || this.protocolVersion === 'WPD'))
          || (([MRanoLesionObject.NON_MEASURABLE, MRanoLesionObject.NON_TARGET_LESION, MRanoLesionObject.NEW_LESION ].includes(object.lesionObject)) && (object.changeLesionType || this.protocolVersion === 'WPD')))
          && object.status === 'AAA111'
      });
      activeLesions.forEach(l => {
        let ppd = (l.maxBiDimension?.Long * l.maxBiDimension?.Short);
        if (ppd)
          sppd += ppd;
      })
      return (isNaN(sppd) ? 0 : sppd);
    }
  }
  totalVolumeCalculation() {
    let totalVolume = 0;
    let activeLesions = this.listOfLesions.filter((object) => { return object.lesionObject !== MRanoLesionObject.MIDLINESHIFT && object.status === 'AAA111' });
    activeLesions.forEach(l => {
      let volume = (l.voiValue?.value);
      if (volume)
        totalVolume += volume;
    })
    this.totalVolume = totalVolume.toFixed(2);
  }
  comparisonCalculation() {
    let sppdComparison = 0;
    let [historicalSPPD, eligibilitySPPD] =
      [this.historicalScan.uploadResourceType === 'DOCUMENT' ? (this.historicalScan.sumPPD * 100) : this.sppdCalculation(this.historicalLesions),
        this.sppdCalculation(this.listOfLesions)];
    sppdComparison = (eligibilitySPPD - historicalSPPD) * 100 / historicalSPPD
    this.sppdComparison = ((sppdComparison === 0 || isNaN(sppdComparison)) ? 0 : sppdComparison.toFixed(2));
  }
  checkSPPDInfinity() {
    return isFinite(this.sppdComparison) ? this.sppdComparison + '%' : 'N/A';
  }
}
