import { User } from './../../../_models/user';
import { BasicResponse } from './../../../core/interfaces/basic-response';
import {Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {
  EndpointService,
  RoleService,
  SiteConfigService,
  StudyService,
  StudyUserService,
  UserRoleMetaDataService,
  UserService
} from '../../../_services';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';

@Component({
  selector: 'app-imaging-project-user',
  templateUrl: './imaging-project-user.component.html',
  styleUrls: ['./imaging-project-user.component.css']
})
export class ImagingProjectUserComponent implements OnInit {
  requiredEmailCtrl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  requiredUserNameCtrl = new FormControl('', [
    Validators.required
  ]);
  requiredFirstNameCtrl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[a-zA-Z]+$')
  ]);
  requiredLastNameCtrl = new FormControl('', [
    Validators.required,
    Validators.pattern('^[a-zA-Z]+$')
  ]);
  user: any = {};
  users: any[] = [];
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  displayedColumns: string[] = ['row-num-user', 'userName', 'firstName', 'lastName', 'emailAddress', 'institution', 'phone', 'operations'];
  displayedStudyUserRoleColumns: string[] = ['row-num-study-user', 'firstName', 'lastName', 'studyId', 'role'];
  dataSource: MatTableDataSource<any>;
  studyUserDataSource: MatTableDataSource<any>;
  studyUser: any = {};
  studyUsers: any[] = [];
  study: any = {};
  studies: any[] = [];
  filteredStudies: any[];
  role: any = {};
  roles: any[] = [];
  filteredRoles: any[];
  siteConfig: any = {};
  siteConfigs: any[] = [];
  filteredSiteConfigs: any[];
  endpoint: any = {};
  endpoints: any[] = [];
  filteredEndpoints: any[];
  showTable: boolean = true;
  showCreateUserPage: boolean = false;
  showUpdateUserPage: boolean = false;
  showUserRolesPage: boolean = false;

  // @ViewChild(MatPaginator) paginator: MatPaginator;
  // @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) rolePaginator: MatPaginator;
  // @ViewChild(MatSort) roleSort: MatSort;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChildren(MatSort) sort = new QueryList<MatSort>();

  constructor(private userService: UserService,
              private toastyService: ToastyService,
              private studyService: StudyService,
              private roleService: RoleService,
              private studyUserService: StudyUserService,
              private userRoleMetaDataService: UserRoleMetaDataService,
              private siteConfigService: SiteConfigService,
              private endpointService: EndpointService, private store: Store) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Users'));
    this.userService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.users = response.data;
          this.dataSource = new MatTableDataSource(this.users);
          this.dataSource.paginator = this.paginator.toArray()[0];
          this.dataSource.sort = this.sort.toArray()[0];
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    this.studyService.getAllWithoutRelations().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.studies = response.data;
          this.initFilteredStudies('');
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    this.roleService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.roles = response.data;
          this.initFilteredRoles('');
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  createUser() {
    if (this.user.userName) {
      this.user.userName = this.user.userName.toLowerCase().trim();
    }
    if (this.user.emailAddress) {
      this.user.emailAddress = this.user.emailAddress.toLowerCase().trim();
    }
    this.userService.create(this.user).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.userService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                this.users = response.data;
                this.dataSource.data = response.data;
                this.toastOptions.title = '';
                this.toastOptions.msg = 'User successfully created';
                this.toastyService.success(this.toastOptions);
                this.hideUsers();
              } else {
                this.toastOptions.title = 'ERROR ' + response.responseCode;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  updateUser() {
    if (this.user.userName) {
      this.user.userName = this.user.userName.toLowerCase().trim();
    }
    if (this.user.emailAddress) {
      this.user.emailAddress = this.user.emailAddress.toLowerCase().trim();
    }
    this.userService.update(this.user).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.userService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                this.users = response.data;
                this.dataSource.data = response.data;
                this.toastOptions.title = '';
                this.toastOptions.msg = 'User successfully updated';
                this.toastyService.success(this.toastOptions);
                this.hideUsers();
              } else {
                this.toastOptions.title = 'ERROR ' + response.responseCode;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  saveUserRoles() {
    if (!this.isSaveUserRoleFormValid()) {
      return;
    }
    this.studyUserService.getStudyUser(this.study.id, this.user.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          if (response.data) {
            this.studyUser = response.data;
            if (this.studyUser.roles.filter(role => role.id === this.role.id).length === 0) {
              this.studyUser.roles.push(this.role);
            }
            if (this.studyUser.studyUserRoleMetaDatas && this.studyUser.studyUserRoleMetaDatas.filter(studyUserRoleMetaData => studyUserRoleMetaData.role.id === this.role.id).length > 0) {
              this.studyUser.studyUserRoleMetaDatas.forEach(studyUserRoleMetaData => {
                if (studyUserRoleMetaData.role.id === this.role.id) {
                  let metadata = studyUserRoleMetaData.metaData;
                  if (this.siteConfig.site && this.endpoint && this.siteConfig.site.id && this.endpoint.id) {
                    if (metadata.site_id === 'ALL') {
                      metadata.site_id = [];
                    }
                    if (metadata.endpoint_id === 'ALL') {
                      metadata.endpoint_id = [];
                    }
                    if (metadata.site_id.indexOf(this.siteConfig.site.id) === -1) {
                      metadata.site_id.push(this.siteConfig.site.id);
                    }
                    if (metadata.endpoint_id.indexOf(this.endpoint.id) === -1) {
                      metadata.endpoint_id.push(this.endpoint.id);
                    }
                  } else if (this.siteConfig.site && this.siteConfig.site.id) {
                    if (metadata.site_id === 'ALL') {
                      metadata.site_id = [];
                    }
                    if (metadata.site_id.indexOf(this.siteConfig.site.id) === -1) {
                      metadata.site_id.push(this.siteConfig.site.id);
                    }
                  } else if (this.endpoint && this.endpoint.id) {
                    if (metadata.endpoint_id === 'ALL') {
                      metadata.endpoint_id = [];
                    }
                    if (metadata.endpoint_id.indexOf(this.endpoint.id) === -1) {
                      metadata.endpoint_id.push(this.endpoint.id);
                    }
                  } else {
                    metadata.site_id = 'ALL';
                    metadata.endpoint_id = 'ALL';
                  }
                  studyUserRoleMetaData.metaData = metadata;
                }
              });
            } else {
              if (this.siteConfig.site && this.endpoint && this.siteConfig.site.id && this.endpoint.id) {
                this.studyUser.studyUserRoleMetaDatas.push({
                  'role': this.role,
                  'metaData': {'site_id': [this.siteConfig.site.id], 'endpoint_id': [this.endpoint.id]}
                });
              } else if (this.siteConfig.site && this.siteConfig.site.id) {
                this.studyUser.studyUserRoleMetaDatas.push({
                  'role': this.role,
                  'metaData': {'site_id': [this.siteConfig.site.id], 'endpoint_id': 'ALL'}
                });
              } else if (this.endpoint && this.endpoint.id) {
                this.studyUser.studyUserRoleMetaDatas.push({
                  'role': this.role,
                  'metaData': {'site_id': 'ALL', 'endpoint_id': [this.endpoint.id]}
                });
              } else {
                this.studyUser.studyUserRoleMetaDatas.push({
                  'role': this.role,
                  'metaData': {'site_id': 'ALL', 'endpoint_id': 'ALL'}
                });
              }
            }
            this.studyUserService.update(this.studyUser).subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.studyUser = response.data;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'User role successfully updated';
                  this.toastyService.success(this.toastOptions);
                  this.studyUserService.getStudyUserByUserId(this.user.id).subscribe(
                    (response: any) => {
                      if (response.responseCode === 200) {
                        this.studyUsers = response.data;
                        this.studyUsers = this.studyUsers.filter(studyUser => studyUser.roles.length > 0);
                        this.studyUserDataSource.data = this.studyUsers;
                      } else {
                        this.toastOptions.title = 'ERROR ' + response.responseCode;
                        this.toastOptions.msg = response.responseMessage;
                        this.toastyService.error(this.toastOptions);
                      }
                    }
                  );
                } else {
                  this.toastOptions.title = 'ERROR ' + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.studyUser.studyId = this.study.id;
            this.studyUser.user = this.user;
            this.studyUser.roles = [];
            this.studyUser.id = null;
            this.studyUser.studyUserRoleMetaDatas = [];
            this.studyUser.roles.push(this.role);
            if (this.studyUser.studyUserRoleMetaDatas && this.studyUser.studyUserRoleMetaDatas.filter(studyUserRoleMetaData => studyUserRoleMetaData.role.id === this.role.id).length > 0) {
              this.studyUser.studyUserRoleMetaDatas.forEach(studyUserRoleMetaData => {
                if (studyUserRoleMetaData.role.id === this.role.id) {
                  let metadata = studyUserRoleMetaData.metaData;
                  if (this.siteConfig.site && this.endpoint && this.siteConfig.site.id && this.endpoint.id) {
                    if (metadata.site_id === 'ALL') {
                      metadata.site_id = [];
                    }
                    if (metadata.endpoint_id === 'ALL') {
                      metadata.endpoint_id = [];
                    }
                    metadata.site_id.push(this.siteConfig.site.id);
                    metadata.endpoint_id.push(this.endpoint.id);
                  } else if (this.siteConfig.site && this.siteConfig.site.id) {
                    if (metadata.site_id === 'ALL') {
                      metadata.site_id = [];
                    }
                    metadata.site_id.push(this.siteConfig.site.id);
                  } else if (this.endpoint && this.endpoint.id) {
                    if (metadata.endpoint_id === 'ALL') {
                      metadata.endpoint_id = [];
                    }
                    metadata.endpoint_id.push(this.endpoint.id);
                  }
                  studyUserRoleMetaData.metaData = metadata;
                }
              });
            } else {
              if (this.siteConfig.site && this.endpoint && this.siteConfig.site.id && this.endpoint.id) {
                this.studyUser.studyUserRoleMetaDatas.push({
                  'role': this.role,
                  'metaData': {'site_id': [this.siteConfig.site.id], 'endpoint_id': [this.endpoint.id]}
                });
              } else if (this.siteConfig.site && this.siteConfig.site.id) {
                this.studyUser.studyUserRoleMetaDatas.push({
                  'role': this.role,
                  'metaData': {'site_id': [this.siteConfig.site.id], 'endpoint_id': 'ALL'}
                });
              } else if (this.endpoint && this.endpoint.id) {
                this.studyUser.studyUserRoleMetaDatas.push({
                  'role': this.role,
                  'metaData': {'site_id': 'ALL', 'endpoint_id': [this.endpoint.id]}
                });
              } else {
                this.studyUser.studyUserRoleMetaDatas.push({
                  'role': this.role,
                  'metaData': {'site_id': 'ALL', 'endpoint_id': 'ALL'}
                });
              }
            }
            this.studyUserService.create(this.studyUser).subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.studyUser = response.data;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'User role successfully created';
                  this.toastyService.success(this.toastOptions);
                  this.studyUserService.getStudyUserByUserId(this.user.id).subscribe(
                    (response: any) => {
                      if (response.responseCode === 200) {
                        this.studyUsers = response.data;
                        this.studyUsers = this.studyUsers.filter(studyUser => studyUser.roles.length > 0);
                        this.studyUserDataSource.data = this.studyUsers;
                      } else {
                        this.toastOptions.title = 'ERROR ' + response.responseCode;
                        this.toastOptions.msg = response.responseMessage;
                        this.toastyService.error(this.toastOptions);
                      }
                    }
                  );
                } else {
                  this.toastOptions.title = 'ERROR ' + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          }
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  deactivateUser(element) {
    this.userService
      .deactivate(element.id)
      .subscribe((response: BasicResponse<User[]>) => {
        if (response.responseCode === 200) {
          this.userService.getAll().subscribe((response: any) => {
            if (response.responseCode === 200) {
              this.users = response.data;
              this.dataSource.data = response.data;
              this.toastOptions.title = "";
              this.toastOptions.msg = "User successfully deactivated";
              this.toastyService.success(this.toastOptions);
            } else {
              this.toastOptions.title = "ERROR " + response.responseCode;
              this.toastOptions.msg = response.responseMessage;
              this.toastyService.error(this.toastOptions);
            }
          });
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      });
  }

  reactivateUser(element) {
    this.userService
      .activate(element.id)
      .subscribe((response: BasicResponse<User>) => {
        if (response.responseCode === 200) {
          this.userService
            .getAll()
            .subscribe((response: BasicResponse<User[]>) => {
              if (response.responseCode === 200) {
                this.users = response.data;
                this.dataSource.data = response.data;
                this.toastOptions.title = "";
                this.toastOptions.msg = "User successfully activated";
                this.toastyService.success(this.toastOptions);
              } else {
                this.toastOptions.title = `ERROR ${response.responseCode}`;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            });
        } else {
          this.toastOptions.title = `ERROR ${response.responseCode}`;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      });
  }

  resetPassword(element) {
    this.userService.resetPassword(element.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.toastOptions.title = '';
          this.toastOptions.msg = 'User password successfully reset';
          this.toastyService.success(this.toastOptions);
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  showCreateUser() {
    this.clearFields();
    this.store.dispatch(new SetPageHeaderTitle('Create User'));
    this.showTable = false;
    this.showUpdateUserPage = false;
    this.showUserRolesPage = false;
    this.showCreateUserPage = true;
  }

  showUpdateUser(element) {
    this.clearFields();
    this.store.dispatch(new SetPageHeaderTitle('Edit User'));
    this.showTable = false;
    this.showCreateUserPage = false;
    this.showUserRolesPage = false;
    this.showUpdateUserPage = true;
    if (element) {
      this.user = {...element};
    }
  }

  showUpdateUserRoles(element) {
    this.clearFields();
    this.store.dispatch(new SetPageHeaderTitle('Edit User Permission'));
    this.user = element;
    this.studyUserService.getStudyUserByUserId(element.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.studyUsers = response.data;
          this.studyUsers = this.studyUsers.filter(studyUser => studyUser.roles.length > 0);
          this.studyUserDataSource = new MatTableDataSource(this.studyUsers);
          this.studyUserDataSource.paginator = this.paginator.toArray()[1];
          this.studyUserDataSource.sort = this.sort.toArray()[1];
          this.showTable = false;
          this.showCreateUserPage = false;
          this.showUpdateUserPage = false;
          this.showUserRolesPage = true;
          this.siteConfig = {siteCode: 'ALL'};
          this.siteConfigs.push(this.siteConfig);
          this.endpoint = {name: 'ALL'};
          this.endpoints.push(this.endpoint);

        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }
  
  deleteStudyUserRole(studyUserId: number, element, studyId: number) {
    this.studyUserService.getStudyUser(studyId, this.user.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.studyUser = response.data;
          this.studyUser.roles = this.studyUser.roles.filter(role => role.id !== element.id);
          this.studyUser.studyUserRoleMetaDatas = this.studyUser.studyUserRoleMetaDatas.filter(metadata => metadata.role.id !== element.id);
          this.userRoleMetaDataService.deleteByStudyUserIdAndRoleId(studyUserId, element.id).subscribe((resp: any) => {
            if (resp.responseCode === 200) {
              this.studyUserService.update(this.studyUser).subscribe(
                (updateResp: any) => {
                  if (updateResp.responseCode === 200) {
                    this.studyUser = updateResp.data;
                    this.toastOptions.title = '';
                    this.toastOptions.msg = 'User role successfully deleted';
                    this.toastyService.success(this.toastOptions);
                    this.studyUserService.getStudyUserByUserId(this.user.id).subscribe(
                      (userResp: any) => {
                        if (userResp.responseCode === 200) {
                          this.studyUsers = userResp.data;
                          this.studyUsers = this.studyUsers.filter(studyUser => studyUser.roles.length > 0);
                          this.studyUserDataSource.data = this.studyUsers;
                        } else {
                          this.toastOptions.title = 'ERROR ' + userResp.responseCode;
                          this.toastOptions.msg = userResp.responseMessage;
                          this.toastyService.error(this.toastOptions);
                        }
                      }
                    );
                  } else {
                    this.toastOptions.title = 'ERROR ' + updateResp.responseCode;
                    this.toastOptions.msg = updateResp.responseMessage;
                    this.toastyService.error(this.toastOptions);
                  }
                }
              );
            } else {
              this.toastOptions.title = 'ERROR ' + resp.responseCode;
              this.toastOptions.msg = resp.responseMessage;
              this.toastyService.error(this.toastOptions);
            }
          });
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  hideUsers() {
    this.clearFields();
    this.showTable = true;
    this.showCreateUserPage = false;
    this.showUpdateUserPage = false;
    this.showUserRolesPage = false;
  }

  clearFields() {
    this.requiredEmailCtrl.updateValueAndValidity();
    this.requiredUserNameCtrl.updateValueAndValidity();
    this.requiredFirstNameCtrl.updateValueAndValidity();
    this.requiredLastNameCtrl.updateValueAndValidity();
    this.store.dispatch(new SetPageHeaderTitle('Create User'));
    this.user = {};
    this.study = {};
    this.studyUser = {};
    this.role = {};
    this.siteConfig = {};
    this.endpoint = {};
    this.studyUsers = [];
  }

  getStudyName(id: number): string {
    let studyName: string;
    this.studies.forEach(study => {
      if (study.id === id) {
        studyName = study.name;
      }
    });
    return studyName;
  }

  showMetaData(studyUserId: number, roleId: number, studyId: number) {
    let userRoleMetaData: any;
    let siteCodes: string = 'ALL';
    let endpointNames: string = 'ALL';
    let endpoints: any[] = [];
    let siteConfigs: any[] = [];
    this.userRoleMetaDataService.getStudyUserRole(studyUserId, roleId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          if (response.data) {
            userRoleMetaData = response.data.metaData;
            if(userRoleMetaData !== undefined) {
              if ('ALL' !== userRoleMetaData.site_id) {
                this.siteConfigService.getSiteConfigsByStudyIdBrief(studyId).subscribe(
                  (response: any) => {
                    if (response.responseCode === 200) {
                      siteConfigs = response.data;
                      siteCodes = '';
                      siteConfigs.forEach(siteConfig => {
                        userRoleMetaData.site_id.forEach(siteId => {
                          if (siteConfig.site.id === siteId) {
                            siteCodes = siteCodes + siteConfig.siteCode + ' ';
                          }
                        });
                      });
                      this.toastOptions.title = '';
                      this.toastOptions.msg = 'Sites: ' + siteCodes;
                      this.toastyService.info(this.toastOptions);
                    } else {
                      this.toastOptions.title = 'ERROR ' + response.responseCode;
                      this.toastOptions.msg = response.responseMessage;
                      this.toastyService.error(this.toastOptions);
                    }
                  }
                );
              } else {
                this.toastOptions.title = '';
                this.toastOptions.msg = 'Sites: ALL';
                this.toastyService.info(this.toastOptions);
              }
              if ('ALL' !== userRoleMetaData.endpoint_id) {
                this.endpointService.getAll().subscribe(
                  (response: any) => {
                    if (response.responseCode === 200) {
                      endpoints = response.data;
                      endpointNames = '';
                      endpoints.forEach(endpoint => {
                        userRoleMetaData.endpoint_id.forEach(endpointId => {
                          if (endpoint.id === endpointId) {
                            endpointNames = endpointNames + (endpointNames.length > 0 ? ', ' : '') + endpoint.name;
                          }
                        });
                      });
                      this.toastOptions.title = '';
                      this.toastOptions.msg = 'Endpoints: ' + endpointNames;
                      this.toastyService.info(this.toastOptions);
                    } else {
                      this.toastOptions.title = 'ERROR ' + response.responseCode;
                      this.toastOptions.msg = response.responseMessage;
                      this.toastyService.error(this.toastOptions);
                    }
                  }
                );
              } else {
                this.toastOptions.title = '';
                this.toastOptions.msg = 'Endpoints: ALL';
                this.toastyService.info(this.toastOptions);
              }
            }
          } else {
            this.toastOptions.title = '';
            this.toastOptions.msg = 'Meta-Data not defined for role.';
            this.toastyService.info(this.toastOptions);
          }
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  changeSelectedStudy() {
    this.siteConfigService.getSiteConfigsByStudyIdBrief(this.study.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.siteConfig = {siteCode: 'ALL'};
          this.siteConfigs = [this.siteConfig];
          this.siteConfigs = this.siteConfigs.concat(response.data);
          this.initFilteredSiteConfigs('');
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    this.studyService.getEndpointsByStudyId(this.study.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.endpoint = {name: 'ALL'};
          this.endpoints = [this.endpoint];
          this.endpoints = this.endpoints.concat(response.data);
          this.initFilteredEndpoints('');
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  clearSelectedSiteAndEndpoint() {
    this.siteConfig = this.siteConfigs[0];
    this.endpoint = this.endpoints[0];
  }

  isSaveUserRoleFormValid(): boolean {
    return !!this.study?.id && !!this.role?.id && !!this.siteConfig?.siteCode && !!this.endpoint?.name;
  }

  getNameOfStudy(study) {
    return study?.name;
  }

  getNameOfRole(role) {
    return role?.roleName;
  }

  getNameOfSiteConfig(siteConfig) {
    return siteConfig?.siteCode;
  }

  getNameOfEndpoint(endpoint) {
    return endpoint?.name;
  }

  onStudySearchInput(event) {
    const studyToSearch = event.target.value.toLowerCase();
    this.initFilteredStudies(studyToSearch);
  }

  onRoleSearchInput(event) {
    const roleToSearch = event.target.value.toLowerCase();
    this.initFilteredRoles(roleToSearch);
  }

  onSiteConfigSearchInput(event) {
    const siteConfigToSearch = event.target.value.toLowerCase();
    this.initFilteredSiteConfigs(siteConfigToSearch);
  }

  onEndpointSearchInput(event) {
    const endpointToSearch = event.target.value.toLowerCase();
    this.initFilteredEndpoints(endpointToSearch);
  }

  private initFilteredStudies(studyToSearch) {
    this.filteredStudies = this.buildFilteredValues(this.studies, this.getNameOfStudy, studyToSearch);
  }

  private initFilteredRoles(roleToSearch) {
    this.filteredRoles = this.buildFilteredValues(this.roles, this.getNameOfRole, roleToSearch);
  }

  private initFilteredSiteConfigs(siteConfigToSearch) {
    this.filteredSiteConfigs = this.buildFilteredValues(this.siteConfigs, this.getNameOfSiteConfig, siteConfigToSearch);
  }

  private initFilteredEndpoints(endpointToSearch) {
    this.filteredEndpoints = this.buildFilteredValues(this.endpoints, this.getNameOfEndpoint, endpointToSearch);
  }

  private buildFilteredValues(source: any[], getValue: (value) => string, valueToSearch: string): any[] {
    valueToSearch = valueToSearch.toLowerCase();
    return source
      .filter(value_ => getValue(value_).toLowerCase().includes(valueToSearch))
      .sort((a, b) => getValue(a).toLowerCase() > getValue(b).toLowerCase() ? 1 : -1);
  }
}
