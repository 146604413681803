import { Transformation } from './transformation.model';

export class SeriesLabelModel {
  name: string;
  transformations: Array<Transformation> = [];
  private _position: number;

  get position(): number {
    return this._position;
  }

  set position(value: number) {
    this._position = +value;
  }
}
