import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  PsamrisDisagreement,
  PsamrisReadingModel,
  PsamrisRuleDelta,
  PsamrisScoringTotalDiffModel,
  PsamrisScoringTotalModel
} from '../../../../_models';
import {MatTableDataSource} from '@angular/material/table';
import {ResourceService} from '../../../../_services/resource.service';
import {ReadingConfigFlexibleService, ReadingPsamrisService} from '../../../../_services';
import {PsamrisAdjudicationConfigModel} from '../../../../_models/ImagingProject/PSAMRIS/psamris-adjudication-config-model';
import {Router} from '@angular/router';
import {EXTERNAL_ADJ_RULE} from '../../../ImagingProject';

const PASSED = 'Passed';
const FAILED = 'Failed';
const COLOR_RED = 'red';
const SIDE_RIGHT = 'RIGHT';

@Component({
  selector: 'app-psamris-level1-result',
  templateUrl: './psamris-level1-result.component.html',
  styleUrls: ['./psamris-level1-result.component.css']
})
export class PsamrisLevel1ResultComponent implements OnInit, OnChanges {

  @Input() adjReading: PsamrisReadingModel;

    readonly EXTERNAL_ADJ_RULE = EXTERNAL_ADJ_RULE;

  flexibleConfig: PsamrisAdjudicationConfigModel;

  studyId: number;

  scoringColumns: string[] = [
    'VISIT',
    'HAND',
    'sTotalSum',
    'ftTotalSum',
    'piTotalSum',
    'oTotalSum',
    'infTotalSum',
    'beTotalSum',
    'bpTotalSum'
  ];

  adjudicationScoringColumns: string[] = [
    'VISIT',
    // 'HAND',
    // 'CHECK',
    'sTotalSum',
    'ftTotalSum',
    'piTotalSum',
    'oTotalSum',
    'infTotalSum',
    'beTotalSum',
    'bpTotalSum'
  ];

  firstReaderDataSource = new MatTableDataSource<PsamrisScoringTotalModel>();
  secondReaderDataSource = new MatTableDataSource<PsamrisScoringTotalModel>();
  disagreementDataSource = new MatTableDataSource<PsamrisScoringTotalModel>();

  constructor(private dialog: MatDialog,
              private resourceService: ResourceService,
              private readingService: ReadingPsamrisService,
              private readingConfigService: ReadingConfigFlexibleService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.studyId = +JSON.parse(localStorage.getItem('project')).id;
    this.initAdjudicationReadingData(this.adjReading);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initAdjudicationReadingData(this.adjReading);
  }

    initAdjudicationReadingData(reading) {
        this.adjReading = reading;
        this.readingConfigService.getById(this.adjReading.flexibleConfigId).subscribe(value => {
            this.flexibleConfig = value.data.config;
            if (this.adjReading.level1Readings.length === 2) {
                if (!this.flexibleConfig.adjudicationBlind) {
                    // scorings 1
                    this.populateReadingScoringsTable(this.adjReading.level1Readings[0], this.firstReaderDataSource);
                    // scorings 2
                    this.populateReadingScoringsTable(this.adjReading.level1Readings[1], this.secondReaderDataSource);
                }
                // disagreement
                if (this.adjReading?.disagreement && this.flexibleConfig?.adjudicationRule !== this.EXTERNAL_ADJ_RULE) {
                    this.readingService.getDisagreement(this.studyId, this.adjReading.id).subscribe(disagreement => {
                        if (disagreement) {
                            this.populateDisagreementTable(disagreement['data']);
                        }
                    });
                }
            }
        });
    }

  populateDisagreementTable(disagreement: PsamrisDisagreement) {
    const rule: PsamrisScoringTotalModel = disagreement.rule as PsamrisScoringTotalModel;
    const scorings = new Array<PsamrisScoringTotalModel>();
    const differences = disagreement.difference;
    const keys = Object.keys(differences);
    for (const key of keys) {
      const currentVisits = this.adjReading.level1Readings
        .flatMap(r => r.visits).filter(v => key.toLowerCase().includes(v.timepoint.toLowerCase()));
      if (!!currentVisits && currentVisits.some(v => v.noUploads === true)) {
        continue;
      }

      const scoringDiff: PsamrisScoringTotalDiffModel = differences[key];

      const scoring: PsamrisScoringTotalModel = {
        beTotalSum: scoringDiff.beTotalSum?.delta?.toString(),
        bpTotalSum: scoringDiff.bpTotalSum?.delta?.toString(),
        ftTotalSum: scoringDiff.ftTotalSum?.delta?.toString(),
        infTotalSum: scoringDiff.infTotalSum?.delta?.toString(),
        oTotalSum: scoringDiff.oTotalSum?.delta?.toString(),
        piTotalSum: scoringDiff.piTotalSum?.delta?.toString(),
        sTotalSum: scoringDiff.sTotalSum?.delta?.toString(),
        beTotalSumDir: scoringDiff.beTotalSum?.sameDirection,
        bpTotalSumDir: scoringDiff.bpTotalSum?.sameDirection,
        ftTotalSumDir: scoringDiff.ftTotalSum?.sameDirection,
        infTotalSumDir: scoringDiff.infTotalSum?.sameDirection,
        oTotalSumDir: scoringDiff.oTotalSum?.sameDirection,
        piTotalSumDir: scoringDiff.piTotalSum?.sameDirection,
        sTotalSumDir: scoringDiff.sTotalSum?.sameDirection
      };

      scoring.timepoint = key;
      scoring.check = PASSED;
      const scoringKeys = Object.keys(scoring);
      for (const scoringKey of scoringKeys) {
        const scoringValue = scoringDiff[scoringKey];
        if (scoringValue
          && (typeof scoringValue !== 'number')
          && ('delta' in scoringValue)) {
          const scoringRuleDelta: PsamrisRuleDelta = scoringValue;
          const scoringDelta = scoringRuleDelta.delta;
          let ruleValue = rule[scoringKey];
          if (scoringRuleDelta.sameDirection) {
            ruleValue *= 2;
          }
          if (ruleValue) {
            const keyDis = scoringKey + 'Red';
            if (scoringDelta >= ruleValue) {
              scoring.check = FAILED;
              scoring[keyDis] = COLOR_RED;
              scoring.timepointRed = true;
            }
          }
        } else {
          if (!scoring[scoringKey]) {
            scoring[scoringKey] = 'NA';
          }
        }
      }
      scorings.push(scoring);
    }
    this.disagreementDataSource.data = scorings;
  }

  populateReadingScoringsTable(reading: PsamrisReadingModel, dataSource: MatTableDataSource<PsamrisScoringTotalModel>) {
    const scorings = new Array<PsamrisScoringTotalModel>();
    const visits = reading.visits;
    for (const visit of visits) {
      const handSide = reading.handSide === SIDE_RIGHT ? 'R' : 'L';
      const scoring: PsamrisScoringTotalModel = {
        id: visit.scoringId,
        hand: handSide,
        timepoint: visit.timepoint,
        beTotalSum: 'n/a',
        bpTotalSum: 'n/a',
        ftTotalSum: 'n/a',
        infTotalSum: 'n/a',
        oTotalSum: 'n/a',
        piTotalSum: 'n/a',
        sTotalSum: 'n/a',
      };
      const beTotalSum: PsamrisRuleDelta = {};

      if (!visit.noUploads) {
        scoring.timepoint = visit.timepoint;
        scoring.beTotalSum = visit.scoring.BE_TOTAL_SUM;
        scoring.bpTotalSum = visit.scoring.BP_TOTAL_SUM;
        scoring.ftTotalSum = visit.scoring.FT_TOTAL_SUM;
        scoring.infTotalSum = visit.scoring.INF_TOTAL_SUM;
        scoring.oTotalSum = visit.scoring.O_TOTAL_SUM;
        scoring.piTotalSum = visit.scoring.PI_TOTAL_SUM;
        scoring.sTotalSum = visit.scoring.S_TOTAL_SUM;
      }

      scorings.push(scoring);
    }
    dataSource.data = scorings;
  }

  onCsvReport(readingNumber: number, type: string) {
    const reading = this.adjReading.level1Readings[readingNumber - 1];
    const resourceLink = this.resourceService.buildPsamrisResourceLink(this.studyId, reading.id, type);
    this.resourceService.getResource(resourceLink).subscribe(csvResource => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(csvResource.body);
      a.href = objectUrl;
      a.download = `report_${readingNumber}.csv`;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  onImageComparisonClick() {
    const url = this.router.url.replace('reading/psamris/adjudication', 'psamris/reading-image-comparison');
    localStorage.setItem('reading1', JSON.stringify(this.adjReading.level1Readings[0]));
    localStorage.setItem('reading2', JSON.stringify(this.adjReading.level1Readings[1]));
    window.open(url, '_blank');
    // const dialogRef = this.dialog.open(ImageComparisonDialogComponent, {
    //   width: '1300px',
    //   data: {
    //     message: 'Are you sure?',
    //     cancelButtonName: 'No',
    //     okButtonName: 'Yes',
    //     reading1: this.adjReading.level1Readings[0],
    //     reading2: this.adjReading.level1Readings[1],
    //   }
    // });
  }

}
