import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormMode} from '../../../../../core/constants/form-mode';
import {defaultBasicAutoBatchConfig, ImagingProjectSubmitEvent} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {ActivatedRoute} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {User} from '../../../../../_models/user';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {MatSelectChange} from '@angular/material/select';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {StudySequenceLabelService, UserService} from '../../../../../_services';
import {forkJoin} from 'rxjs';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {KoalaConfigModel} from '../../../../../_models/ImagingProject/KOALA/koala-config-model';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {EndpointModel} from '../../../../../_models/ImagingProject/endpoint-model';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';
import { AutoBatchConfigPayload } from 'src/app/core/payload/auto-batch-config-payload';


interface Config {
  endpointReadingVersion: 'basic';
  readingLevel: 'modality';
  modalities: ModalityModel[];
  readers: User[];
  batchManagement: boolean;
  hideVisitHistory: string;
}

type ConfigControls = { [key in keyof Config]: FormControl };
type KoalaConfigFormGroup = FormGroup & { value: Config, controls: ConfigControls };

@Component({
  selector: 'app-imaging-project-reading-koala',
  templateUrl: './imaging-project-reading-koala.component.html',
  styleUrls: ['./imaging-project-reading-koala.component.css']
})
export class ImagingProjectReadingKoalaComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  readonly readingTypes = ReadingType;

  @Input() selectedConfig: FlexibleConfig<KoalaConfigModel>;

  @Input() public mode: FormMode;

  @Input() public endpoints: EndpointModel[];

  @Output() public clickCancel = new EventEmitter<ImagingProjectSubmitEvent>();
  @Output() public clickSubmit = new EventEmitter<ImagingProjectSubmitEvent>();

  get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true && !this.batchConfig?.numberOfReadings ? {norError: 'Number of readings is required'} : null;
    };
  }

  studyId: number;
  koalaForm: KoalaConfigFormGroup;
  batchConfig: BatchConfigModel;
  modalities: ModalityModel[];

  invalidModalitiesSet = false;

  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private studySequenceLabelService: StudySequenceLabelService,
              private userService: UserService,
              private batchLogicService: BatchLogicService) {
    super();
  }

  ngOnInit(): void {
    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    const serviceCalls: any[] = [
      this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
    ];

    if (this.mode === FormMode.EDIT) {
      serviceCalls.push(this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id));
    }

    forkJoin(serviceCalls).subscribe(([groupedModalitiesResponse, batchConfigsResponse]) => {
      this.initModalities(groupedModalitiesResponse);
      if (this.mode === FormMode.EDIT) {
        this.initBatchConfig(batchConfigsResponse);
        this.initKoalaFormValues();
      }
    });

    this.initKoalaForm();
  }

  initKoalaForm() {
    this.koalaForm = this.fb.group({
      endpointReadingVersion: new FormControl('basic', [Validators.required]),
      readingLevel: new FormControl('modality', [Validators.required]),
      modalities: new FormControl([], [Validators.required]),
      batchManagement: new FormControl(false, [Validators.required, this.batchManagementValidator]),
      hideVisitHistory: new FormControl('hide_visits_chronology', [Validators.required]),
    } as ConfigControls) as KoalaConfigFormGroup;

    this.koalaForm.controls.batchManagement.registerOnChange(value => this.onBatchManagementSelected(value));
  }

  initModalities(groupedModalitiesResponse): void {
    if (groupedModalitiesResponse.data) {
      this.modalities = <ModalityModel[]> groupedModalitiesResponse.data;
      this.modalities.sort((a, b) => a.name.localeCompare(b.name));
      if (this.mode === FormMode.NEW) {
        this.koalaForm.controls.modalities.setValue(this.modalities);
        this.koalaForm.controls.modalities.updateValueAndValidity();
      }
    } else {
      this.invalidModalitiesSet = true;
    }
  }

  initBatchConfig(batchConfigsResponse): void {
    if (batchConfigsResponse.data) {
      this.batchConfig = (batchConfigsResponse.data as BatchConfigModel[])[0];
    }
  }

  initKoalaFormValues(): void {
    const inputConfig = this.selectedConfig?.config;

    this.koalaForm.patchValue({
      modalities: inputConfig.modalities,
      batchManagement: inputConfig.batchManagement
    });

    this.koalaForm.markAllAsTouched();
    this.koalaForm.markAsDirty();
  }

  onModalityTypeChange(event: MatSelectChange): void {
    this.koalaForm.patchValue({modalities: event.value});
  }

  onBatchManagementSelected(enabled: boolean): void {
    if (!enabled) {
      this.batchConfig.numberOfReadings = null;
    }
  }

  openAutoCreationBatchConfigDialog(config: BatchConfigModel): void {
    let inputConfig = config as AutoBatchConfigPayload;
    const val: boolean = this.koalaForm.controls.batchManagement.value;

    if (!val) {
      inputConfig = { ...defaultBasicAutoBatchConfig };
    } else {
      inputConfig = this.batchConfig ? { ...this.batchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        readingVersion: ReadingVersion.KANDL_KOALA,
        readingType: ReadingType.BASIC_READING,
        configInput: inputConfig,
        readonlyDialog: !val
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        this.batchConfig = result;
        this.koalaForm.controls.batchManagement.updateValueAndValidity();
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  onSubmitClick() {
    this.propagateSubmitEvent(this.prepareConfigModel());
  }

  propagateSubmitEvent(configModel: KoalaConfigModel): void {
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: configModel,
      basicBatchConfig: this.batchConfig
    };

    this.clickSubmit.emit(submitEvent);
  }

  prepareConfigModel(): KoalaConfigModel {
    const val: Config = this.koalaForm.value;

    return {
      endpoint: this.endpoints.find(e => e.name === 'KOALA K&L'),
      modalities: val.modalities,
      endpointReadingVersion: 'basic',
      batchManagement: val.batchManagement,
      readingLevel: val.readingLevel,
      readingVersion: ReadingVersion.KANDL_KOALA,
      readers: [{id: 0}]
    };
  }

  onCancelClick() {
    this.clickCancel.emit({result: 'cancel', data: null});
  }

  objectComparatorById = function (obj1, obj2): boolean {
    return obj1.id === obj2.id;
  };

}
