import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { routing } from 'src/app/app.routing';
import { toNumber } from 'lodash';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';

export interface AnnotationLoadData {
  cell: any;
  viewerComponent: any,
  annotations: any;
  container: any;
  objectRetrieveService: any;
  objectStoreService: any;
  seriesManagerService: any;
}

export class AnnotationRow {
  public description: string;
  public instance: number;
  public createDate: any;
  public creatorName: string;
  public sopInstanceUID: string;
  public name: string;
  public type: string;
  public numberROIs: number;
  public numberSlices: number;

  constructor() {
      this.description = "";
      this.instance = -1;
      this.createDate = null;
      this.creatorName = "";
      this.sopInstanceUID = "";
      this.name = "";
      this.type = "";
      this.numberROIs = -1;
      this.numberSlices = -1;
  }
}

@Component({
  selector: 'app-annotation-load-dialog',
  templateUrl: './annotation-load-dialog.component.html',
  styleUrls: ['./annotation-load-dialog.component.css']
})
export class AnnotationLoadDialogComponent implements OnInit {
  public showAlert = false;
  public typeAlert = "success";
  public contAlert = "";

  rowData: AnnotationRow[] = [];
  displayedColumns: string[] = ['select', 'name', 'description', 'numberrois', 'createdon'];
  dataSource: MatTableDataSource<AnnotationRow>;
  selection: SelectionModel<AnnotationRow>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    public dialogRef: MatDialogRef<AnnotationLoadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AnnotationLoadData,
    private utils: Utils) {
      this.rowData = this.buildRowData();
      this.dataSource = new MatTableDataSource(this.rowData);

      const initialSelection = [];
      const allowMultiSelect = true;
      this.selection = new SelectionModel<AnnotationRow>(allowMultiSelect, initialSelection);
    }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected == numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  buildRowData(){
    let rowData = [];
    this.data.annotations.forEach((item, idx) => {
      let row = new AnnotationRow();
      let sopIUID = item.SOPInstanceUID;
      row.description = item.ContentDescription;
      row.instance = item.InstanceNumber;
      row.createDate = item.CreationDate;
      row.creatorName = item.ContentCreatorName;
      row.sopInstanceUID = sopIUID;

      row.name = 'ANNO_' + sopIUID.substring(sopIUID.length - 4);
      row.type = 'Slice';
      row.numberROIs = this.utils.getROIsCount(item.XMLData);
      row.numberSlices = this.utils.getSlicesCount(item.XMLData);
      rowData.push(row);
    });
    return rowData;
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickLoad(): void {
    if (this.selection.selected.length <= 0)
      return;

    let selXMLAnnotations = [];
    let info = {
      // "cell": this.data.cell,
      "viewerComponent": this.data.viewerComponent,
      "creatorName": this.selection.selected[0].creatorName,
      "createDate": this.selection.selected[0].createDate
    };
    let resp = "";

    this.selection.selected.forEach((item) => {
      let annotation = this.data.annotations.find(a => a.SOPInstanceUID === item.sopInstanceUID);
      selXMLAnnotations.push(annotation.XMLData);

      resp += this.data.seriesManagerService.addAnnotations(this.data.cell, annotation.XMLData, info);
    });

    if (resp == "")
    {
      this.triggerAlert("success", "Annotations loaded", 1000, true, "");
      this.data.viewerComponent.rowData1 = this.utils.buildROIRowData(this.data.cell, info);
      this.data.viewerComponent.dataSource1 = new MatTableDataSource(this.data.viewerComponent.rowData1);
      this.data.viewerComponent.dataSource1.paginator = this.data.viewerComponent.paginator1;
      this.data.viewerComponent.dataSource1.sort = this.data.viewerComponent.sort1;

      let headerElt = document.getElementById("header-slice-rois") as HTMLBaseElement;
      let paginatorElt = document.getElementById("paginator-slice-rois") as HTMLBaseElement;
      let header_W = headerElt.offsetWidth.toString();
      paginatorElt.setAttribute("style", "width: "+header_W+"px");
    }
    else {
      this.triggerAlert("danger", "An error occured", 2000, false, resp);
    }
  }

  onClickDelete(): void
  {
    let counter = 0;
    this.selection.selected.forEach((item) => {
      this.data.objectStoreService.deleteAnnotations(item.sopInstanceUID)
      .toPromise().then((result) => {
        // console.log("result: ", result);
        counter++;
        if (counter == this.selection.selected.length){
          this.triggerAlert("success", "Annotations deleted", 1000, false, "");
        }

        let sel_idx = this.rowData.findIndex(a => a.sopInstanceUID === item.sopInstanceUID);
        if (sel_idx > -1) {
          this.rowData.splice(sel_idx, 1);
          // update datasource and clear selection
          this.dataSource = new MatTableDataSource(this.rowData);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.selection.clear();

          this.data.annotations.splice(sel_idx, 1);
          this.data.seriesManagerService.setAnnotationIDs(
            this.data.cell.seriesInstanceUID, this.data.cell.divID, this.data.annotations);
        }
      },
      (error: any) => {
        counter++;
        console.log("Error found, response: ", error);
      }
      );
    });
  }

  triggerAlert(type, msg, timeout, close, data?){
    this.typeAlert = type;
    this.showAlert = true;
    this.contAlert = msg;
    if (type == "danger")
      console.log("Error found, data: ", data);
    setTimeout(() => {
      this.showAlert = false;
      if (close)
        this.dialogRef.close();
    }, timeout);
  }
}
