import {ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ImagingProjectService, StudySequenceLabelService, StudyUserService, ViewerAuthenticationService} from '../../../_services';
import * as _ from 'lodash';
import {MatTableDataSource} from '@angular/material/table';
import {ViewerCoreLtComponent} from '../../Viewer';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import * as _moment from 'moment';
import {forkJoin, Observable} from 'rxjs';
import {QualityControlService} from '../../../_services/quality-control.service';
import {Utils} from '../../../_services/leadtools/lead-tools-utils';
import {PatientService} from '../../../_services/patient.service';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { isDefined } from '@angular/compiler/src/util';
import {formatDate} from "@angular/common";

const moment = _moment;

@Component({
  selector: 'app-sponsor-series-list',
  templateUrl: './sponsor-series-list.component.html',
  styleUrls: ['./sponsor-series-list.component.css']
})
export class SponsorSeriesListComponent implements OnInit {

  @ViewChild('viewerContainer', {read: ElementRef}) viewerContainer: ElementRef;
  @ViewChild('viewerLT', {read: ViewContainerRef}) viewerLT: ViewContainerRef;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  patientId: number;
  visitConfigId: number;
  studyId: number;
  seriesDataSource: MatTableDataSource<SeriesListItem>;
  studyInfo: StudyInfo = {
    siteCode: '',
    studyName: '',
    patientCode: '',
    dateOfBirth: '',
    visitName: '',
    visitBlindName: ''
  };

  viewerDataIsReady = false;
  newViewer: any = null;
  newLTViewer: any = null;
  viewerData = null;
  viewerDataIsLoading = false;
  shouldShowDICOM = true;
  displayedColumnsSeries: string[] = ['image', 'modality', 'label', 'scanDate', 'modificationType', 'comment', 'status'];

  debOpenViewer = _.debounce(seriesId => {
    if (this.viewerDataIsReady) {
      this.openViewer(seriesId);
    }
  }, 500);

  constructor(
    private route: ActivatedRoute,
    private imagingProjectService: ImagingProjectService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private studySequenceLabelService: StudySequenceLabelService,
    private qualityControlService: QualityControlService,
    private patientService: PatientService,
    private cdr: ChangeDetectorRef,
    private utils: Utils,
    private studyUserService: StudyUserService,
    private authenticationService: ViewerAuthenticationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.patientId = parseInt(this.route.snapshot.params.patientId, 10);
    this.visitConfigId = parseInt(this.route.snapshot.params.visitConfigId, 10);
    this.studyId = parseInt(this.route.snapshot.params.studyId, 10);
    this.initNewLTViewer();
    localStorage.setItem('project', JSON.stringify({'id': this.studyId}));
    let seriesIds;
    const series: SeriesListItem[] = [];
    this.seriesDataSource = new MatTableDataSource<SeriesListItem>();
    let patientId;
    this.patientService.getById(this.patientId).subscribe(resp => {
      const patient = resp.data;
      if (patient) {
        patientId = patient.id;
        this.studyInfo.patientCode = patient.patientCode;
        this.studyInfo.dateOfBirth = patient.patientBirthDate;
      }
    });
    this.imagingProjectService.getStudyForConfig(this.studyId).subscribe(resp => {
      const study = resp.data;
      if (study) {
        this.studyInfo.studyName = study.name;
        study.siteConfigs.forEach(s => {
          s.patients.forEach(p => {
            if (p.id === this.patientId) {
              this.studyInfo.siteCode = s.siteCode;
            }
          });
        });
      }
    });

    this.imagingProjectService.getVisitConfig(this.visitConfigId.toString()).subscribe(resp => {
      const visitConfig = resp['data'];
      if (visitConfig != null) {
        this.studyInfo.visitName = visitConfig.visitName;
        this.studyInfo.visitBlindName = visitConfig.visitBlindName;
        seriesIds = visitConfig.series.map(s => s.id);
        this.studySequenceLabelService.getStudySeriesAndParentsBySeriesIds(seriesIds).subscribe(seriesDetailsResp => {
          const filteredSeriesDetailsResp = this.filterSeriesDetails(seriesDetailsResp['data']);
          let filteredSeriesIds = filteredSeriesDetailsResp.map(s => s.id);
          if (filteredSeriesIds.length > 0) {
            const seriesIdsAndStudyId = filteredSeriesIds.map((s) => ({ seriesId: s, studyId: this.studyId }));
            this.imagingProjectService.getSeriesPreviewBySeriesIds(seriesIdsAndStudyId).subscribe(previewSeriesResp => {
              this.qualityControlService.getSeriesBySeriesIds(this.studyId, filteredSeriesIds)
                .subscribe(seriesDetailsQCResp => {
                  this.imagingProjectService.getSeriesInstanceUID(filteredSeriesIds).subscribe(seriesInstanceUIDData => {
                    const allSeriesQCDetailsResp = seriesDetailsQCResp['data'];
                    const previewSeriesData = previewSeriesResp['data'];
                    const seriesInstanceUID = seriesInstanceUIDData['data'];

                    if (filteredSeriesDetailsResp != null) {
                      const viewerDataResults: Array<Observable<any>> = [];
                      filteredSeriesDetailsResp.forEach((seriesDetails, index) => {
                        const seriesQcDetails = allSeriesQCDetailsResp.filter(sqcd => sqcd.seriesId === seriesDetails.id)[0];
                        const preview = previewSeriesData.filter(p => p.serialId === seriesQcDetails.seriesId)[0];
                        if (seriesQcDetails != null) {
                          const siUIDDetails = seriesInstanceUID.find(f => f.id == seriesDetails.id);

                          const s = {
                            created: seriesQcDetails.created,
                            seriesId: seriesDetails.id,
                            seriesInstanceUID: siUIDDetails.seriesUID,
                            viewerName: siUIDDetails.viewerName,
                            seriesPreviewUrl: preview['url'],
                            seriesDescription: seriesDetails.seriesDescription,
                            label: seriesDetails.label,
                            modality: seriesDetails.projectModality,
                            scanDate: null,
                            status: seriesQcDetails.status,
                            comment: seriesQcDetails.comment,
                            visitConfigId: seriesQcDetails.visitConfigId,
                            modificationType: this.convertSeriesType(seriesDetails),
                            studyInfo: this.studyInfo
                          };

                          if (seriesDetails.scanDate != null) {
                            s.scanDate = formatDate(seriesDetails.scanDate, 'dd/MM/yyyy', 'en-GB', 'UTC');
                          }

                          if (s.comment == null) {
                            s.comment = '';
                          }

                          series.push(s);
                          const info = {'selectedPage': 'Operations Dashboard'};
                          viewerDataResults.push(this.utils.initViewerData(s, this, info));
                        }
                      });

                      if (viewerDataResults.length) {
                        this.viewerDataIsLoading = true;
                        forkJoin(viewerDataResults).subscribe(() => {
                          this.viewerDataIsReady = true;
                          this.viewerDataIsLoading = false;
                          this.cdr.detectChanges();
                        });
                      }

                      this.seriesDataSource.paginator = this.paginator;
                      this.seriesDataSource.sort = this.sort;
                      this.seriesDataSource.data = series;
                    }
                  });
                });
            });
          }
        });
      }
    });
  }

  dragstart(ev, seriesId, seriesInstanceUID) {
    ev.dataTransfer.setData('seriesId', seriesId);
    ev.dataTransfer.setData('seriesInstanceUID', seriesInstanceUID);
  }

  openViewer(seriesId): void {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1000);

    this.highlightThumbnail(seriesId);
    this.openLTViewer([seriesId]);

    this.viewerContainer.nativeElement.style.display = '';
    this.viewerContainer.nativeElement.style.zIndex = '2';
  }

  highlightThumbnail(seriesId): void {
    const $serThumbnail = document.querySelector('[data-seriesId="' + seriesId + '"] div');
    $serThumbnail?.classList.add('viewer-highlighted');
  }

  openLTViewer(seriesIds: string[] = []) {
    if (this.newLTViewer) {
      this.newLTViewer.instance.openNewSer(seriesIds, this.viewerData);
      return;
    }

    this.openNewLTViewer(seriesIds);
  }

  openNewLTViewer(seriesIds) {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(
      ViewerCoreLtComponent
    );

    this.newLTViewer = this.viewerLT.createComponent(compFactory);

    this.newLTViewer.instance.seriesIds = seriesIds;
    this.newLTViewer.instance.shouldShowDICOM = false;
    this.newLTViewer.instance.onClose.subscribe(({serID, shouldCloseViewer}) => {
      this.closeLTViewer(serID, shouldCloseViewer);
    });
  }

  private initNewLTViewer(): void {
    setTimeout(() => {
      this.openNewLTViewer(null);
    }, 200);
  }

  closeLTViewer(serID, shouldCloseViewer) {
    this.removeHighlight(serID);

    if (shouldCloseViewer) {
      this.viewerContainer.nativeElement.style.zIndex = '-10';
      this.viewerContainer.nativeElement.style.display = 'none';
    }
  }

  removeHighlight(serID): void {
    const $serThumbnail = document.querySelector('[data-seriesId="' + serID + '"] div');

    $serThumbnail?.classList.remove('viewer-highlighted');
    setTimeout(() => {
      $serThumbnail?.classList.remove('viewer-highlighted');
    }, 10);
  }

  onCancel(): void {
    this.router.navigate([`operationdashboard/${this.studyId}`]).then(r => {
    });
  }

  sortData(sort: Sort) {
    const data = this.seriesDataSource.data.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSource(data);
      return;
    }

    const sortedData = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'scanDate':
          return compare(a.scanDate, b.scanDate, isAsc);
        default:
          return 0;
      }
    });
    this.initDataSource(sortedData);
  }

  initDataSource(series: SeriesListItem[]) {
    this.seriesDataSource.paginator = this.paginator;
    this.seriesDataSource.sort = this.sort;
    this.seriesDataSource.data = series;
  }

  filterSeriesDetails(seriesDetails: any) {
    const filteredDetails = [];
    const details = new Map();
    seriesDetails.forEach(detail => {
      if (!detail.mapName) {
        filteredDetails.push(detail);
      } else {
        let info = details.get(detail.mapName);
        if (!!info) {
          if (info.generatedSeriesVersion < detail.generatedSeriesVersion) {
            details.set(detail.mapName, detail);
          }
        } else {
          details.set(detail.mapName, detail);
        }
      }
    });
    details.forEach(value => filteredDetails.push(value));
    return filteredDetails;
  }

  convertSeriesType(seriesDetails: any): string {
    const version = seriesDetails.generatedSeriesVersion ? ', v' + seriesDetails.generatedSeriesVersion : ', v0';
    if (seriesDetails.mapName) {
      switch (seriesDetails.mapName) {
        case '2D Motion Correction' :
          return 'MC' + version;
        case 'Area Under the Curve':
          return 'AUC' + version;
        case 'Gadolinium':
          return 'GD' + version;
        case 'GBM Segmentation':
          return 'GBM' + version;
        case 'Initial Rate of Enhancement':
          return 'IRE' + version;
        case 'Initial Rate of Washout':
          return 'IRW' + version;
        case 'Maximum Enhancement':
          return 'ME' + version;
        case 'Time of Onset':
          return 'Tonset' + version;
        case 'Time To Peak':
          return 'TTP' + version;
        case 'Time to Washout':
          return 'Twashout' + version;
      }
    } else {
      if (seriesDetails.parents === 0) {
        return 'original' + version;
      } else {
        return 'modified' + version;
      }
    }
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
}

interface SeriesListItem {
  created: string;
  seriesId: number;
  seriesInstanceUID: string;
  viewerName: string;
  seriesPreviewUrl: string;
  seriesDescription: string;
  label: string;
  modality: string;
  scanDate: string;
  status: string;
  comment: string;
  visitConfigId: number;
  modificationType: string;
}

interface StudyInfo {
  studyName: string;
  siteCode: string;
  patientCode: string;
  visitName: string;
  dateOfBirth: string;
  visitBlindName: string;
}
