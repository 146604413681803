import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {environment} from 'src/environments/environment';
import { BasicResponse } from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingGuerbetVersion;
const SERVICE = 'msreadingguerbet-dot-';
const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class ReadingGuerbetService {

  constructor(private http: HttpClient) { }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/readings/available-count/studyId-${studyId}/readerId-${readerId}?readerType=LEVEL1_READER`);
  }

  getNumberOfAvailableAdjudicationReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/readings/available-count/studyId-${studyId}/readerId-${readerId}?readerType=ADJUDICATION_READER`);
  }

  getNumberOfAvailableConsensusReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/readings/available-count/studyId-${studyId}/readerId-${readerId}?readerType=CONSENSUS_READER`);
  }

  getAvailableReadings(studyId, readerId, readerType): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/readings/available/studyId-${studyId}/readerId-${readerId}?readerType=${readerType}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  startReading(readingId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/readings/${readingId}/start`;
    return this.http.put<BasicResponse<any>>(url, null);
  }

  updateReadingOld(studyId, readingId, data) {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/readings/${readingId}/log-time`, data);
  }

  completeReading(studyId, data, readerType, jwtToken: string): Observable<BasicResponse<any>> {
    const customHeader = new HttpHeaders({
      'Authorization': `Bearer ${jwtToken}`
    });

    return this.http.put<BasicResponse<any>>(
      `${BASE_URL}/readings/${studyId}/complete?readerType=${readerType}`,
      data,
      {
        headers: customHeader
      }
    );
  }

  signReading(studyId, readingId, jwtToken) {
    const customHeader = new HttpHeaders({
      'Authorization': `Bearer ${jwtToken}`
    });

    return this.http.post<BasicResponse<any>>(
      `${BASE_URL}/readings/sign/${studyId}/${readingId}`,
      {},
      {
        headers: customHeader
      }
    );
  }

  getLevelOneReadingByReadingId(readingId: number): Observable<BasicResponse<any>>  {
    const url = `${BASE_URL}/readings/level-one/${readingId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getConsensusReadingByReadingId(readingId: number): Observable<BasicResponse<any>>  {
    const url = `${BASE_URL}/readings/consensus/${readingId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getAdjudicationReadingByReadingId(readingId: number): Observable<BasicResponse<any>>  {
    const url = `${BASE_URL}/readings/adjudication/${readingId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getReadingByReadingId(readingId: number): Observable<BasicResponse<any>>  {
    const url = `${BASE_URL}/readings/${readingId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  storeScoring(visitId: number, scoring: any) {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/visits/score/${visitId}`, scoring);
  }

  getSignSummary() {
    return this.http.get(`${BASE_URL}/readings/sign/summary`);
  }

  getSignReportsByStudyId(studyId: number) {
    return this.http.get<Array<any>>(`${BASE_URL}/readings/sign/${studyId}`);
  }
}
