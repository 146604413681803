import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingMRANOVersion;
const SERVICE = 'msreadingmrano-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/reading/';
const LOCAL_API_URL = 'http://localhost:8080/api/reading/';

@Injectable({
  providedIn: 'root'
})
export class ReadingMranoService {
  private seriesLockedStatusSource = new BehaviorSubject([]);
  seriesLockedStatus = this.seriesLockedStatusSource.asObservable();

  private deletedAnnObjectsSource = new BehaviorSubject([]);
  deletedAnnObjects = this.deletedAnnObjectsSource.asObservable();

  private modalSpinnerSource  = new BehaviorSubject<boolean>(true);
   modalSpinner = this.modalSpinnerSource.asObservable();

   private openLTViewerSource = new BehaviorSubject<any>(null);
   openLTViewerData = this.openLTViewerSource.asObservable();

   private editLesionMeasurementSource = new BehaviorSubject<any>(null);
   editLesionMeasurement = this.editLesionMeasurementSource.asObservable();

  constructor(private http: HttpClient) {
  }
  modalSpinnerChangeState(state: boolean) {
    this.modalSpinnerSource.next(state);
  }
  openLTViewer(data: any) {
    this.openLTViewerSource.next(data);
  }
  onEditLesionMeasurement(data?: any) {
    this.editLesionMeasurementSource.next(data);
  }
  addSeriestoSeriesLockedStatus(list: any) {
    this.seriesLockedStatusSource.next(list);
  }
  deleteAnnObjectsSource(AnnObjects: any) {
    this.deletedAnnObjectsSource.next(AnnObjects);
  }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getNumberOfAvailableAdjudicationReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}adjudication/get-number-of-available-readings/${studyId}/${readerId}`);
  }
  getReadingByStudyIdAndReaderId(studyId, readerId) {
      return this.http.get(`${SERVICE_API_URL}get-available-readings/${studyId}/${readerId}`);
  }

  getAvailableAdjudicationReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}adjudication/get-available-readings/${studyId}/${readerId}`);
  }

  updateAdjudicationTimepointStatus(studyId, readingId, visitConfigId, status) {
    return this.http.put(`${SERVICE_API_URL}adjudication/status/${studyId}/${readingId}/${visitConfigId}`, {
      status: status
    });
  }

  startReading(studyId, readingId): Observable<BasicResponse<any>> {
      return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}start/${studyId}/${readingId}`, null);
  }
  lockHistoricalReading(studyId, readingId) {
    return this.http.put(`${SERVICE_API_URL}complete/historical/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds) {
      // return this.http.put(`${LOCAL_API_URL}${studyId}/${readingId}`, {spentSeconds});
      return this.http.put(`${SERVICE_API_URL}${studyId}/${readingId}`, {spentSeconds});
  }
  readingScoreLock(studyId, ScoringData) {
      return this.http.post(`${SERVICE_API_URL}${ScoringData.type.toLowerCase()}/${studyId}`, ScoringData.data);
  }

  completeReading(studyId, data, eligibilityStatusSelectedByUser) {
    // return this.http.put(`${LOCAL_API_URL}complete/${studyId}`, data);
    let url = `${SERVICE_API_URL}complete/${studyId}`;
    if (eligibilityStatusSelectedByUser) {
      url += `?eligibilityStatusSelectedByUser=${eligibilityStatusSelectedByUser}`;
    }
    return this.http.put(url, data);
  }
  completeRecistEligibilityReading(studyId, readingId, data, eligibilityStatusSelectedByUser, jwtToken) {
    const customHeader = new HttpHeaders({
      'Authorization': `Bearer ${jwtToken}`
    });
    let url = `${SERVICE_API_URL}recist-eligibility/complete/${studyId}/${readingId}`;
    if (eligibilityStatusSelectedByUser) {
      url += `?eligibilityStatusSelectedByUser=${eligibilityStatusSelectedByUser}`;
    }

      return this.http.put(url, data, {
        headers: customHeader
      });
  }

  lockAdjudicationVisit(studyId, readingId, visitConfigId, data) {
    return this.http.post(`${SERVICE_API_URL}adjudication/visit/lock/${studyId}/${readingId}/${visitConfigId}`, data);
  }
  updateLesionData(studyId: number, data: any) {
     return this.http.post(`${SERVICE_API_URL}lesion/${studyId}`, data);
  }
  deleteLesion(studyId: number, lesionId: number) {
    return this.http.delete(`${SERVICE_API_URL}lesion/${studyId}/${lesionId}`);
 }
 getHistoricalLesions(studyId: number, historicalId: number) {
    return this.http.get(`${SERVICE_API_URL}lesion/historical_id/${studyId}/${historicalId}`);
 }

 getEligibilityLesions(studyId: number, eligibility: number) {
    return this.http.get(`${SERVICE_API_URL}lesion/eligibility_id/${studyId}/${eligibility}`);
 }
 getLesionImageUploadLink(studyId, readingId, seriesId, sliceNumber) {
    const url = `${SERVICE_API_URL}lesionscreenshot/upload-link/${studyId}/${readingId}/${seriesId}/${sliceNumber}`;
    return this.http.get(url);
  }
  uplodFileToCloud(uploadUrl, formData) {
    return this.http.put(uploadUrl, formData);
  }
  getEligibilityStatus(studyId: number, readingId: number, data: any) {
    const url = `${SERVICE_API_URL}eligibility/status/${studyId}/${readingId}`;
    return this.http.post(url, data);
  }

  storeStatisticDataByVisit(scoring: any, visitId: number) {
    const data = scoring;
    const url = `${SERVICE_API_URL}visit/${visitId}/scoring`;
    return this.http.put(url, data);
  }

  lockTimepoint(studyId, visitId, model) {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/visit/${studyId}/${visitId}`, model);
  }
}
