import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl} from '@angular/forms';
import {ReadingJSWService} from '../../../../_services/reading-jsw.service';
import {ToastService} from '../../../../_services/internal/toast.service';

export enum Asset {
  ZIP_DICOM_JPEG = 'ZIP_DICOM_JPEG',
  ZIP_DICOM_JSPS = 'ZIP_DICOM_GSPS',
  JSON_REPORT = 'JSON Report'
}

export enum FileExtension {
  ZIP = 'zip',
  JSON = 'json'
}

export interface FileElement {
  asset: Asset;
  acceptExtension: FileExtension;
  name?: string;
}

@Component({
  selector: 'app-jsw-reading-upload-files-dialog',
  templateUrl: './jsw-reading-upload-files-dialog.component.html',
  styleUrls: ['./jsw-reading-upload-files-dialog.component.css']
})
export class JswReadingUploadFilesDialogComponent implements OnInit {

  isLoading = false;
  isFilesUploaded = false;

  uploadObservable;
  filesForm = new FormData();

  fileElementsTableColumnNames = ['asset', 'file_selected', 'select'];
  fileElements: FileElement[] = [
    {
      asset: Asset.ZIP_DICOM_JPEG,
      acceptExtension: FileExtension.ZIP
    },
    {
      asset: Asset.ZIP_DICOM_JSPS,
      acceptExtension: FileExtension.ZIP
    },
    {
      asset: Asset.JSON_REPORT,
      acceptExtension: FileExtension.JSON
    }
  ];

  constructor(@Inject(MAT_DIALOG_DATA)
              public form: FormControl,
              public readingService: ReadingJSWService,
              public dialogRef: MatDialogRef<JswReadingUploadFilesDialogComponent>,
              private toastService: ToastService) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close(this.isFilesUploaded);
  }

  selectFile(files: FileList, fileElement: FileElement) {
    const file = files.item(0);
    if (this.getFileExtension(file.name) !== fileElement.acceptExtension) {
      return console.error(`File [${file.name}] has wrong extension [${this.getFileExtension(file.name)}], but should be [${fileElement.acceptExtension}]`);
    }
    fileElement.name = file.name;
    const fileCategoryName = this.getFormDataNameByAsset(fileElement.asset);
    this.filesForm.set(fileCategoryName, file);
  }

  uploadFiles() {
    this.isLoading = true;
    this.toastService.success('Import process started successfully', 'Files are uploading. When uploading is done and measurements remain retrievd then you see the measurement table updated');
    this.uploadObservable = this.readingService.uploadFiles(this.form['studyId'], this.form['seriesId'], this.filesForm)
      .subscribe(() => {
        this.isLoading = false;
        this.isFilesUploaded = true;
        this.toastService.success('Import process finished successfully', 'Files are successfully uploaded and measurements are retrieved. Find the results in the measurement table');
      }, () => {
        this.isLoading = false;
        this.toastService.error('Import process failed', 'Uploading of files failed due to some reason. Try again or contact support team');
      });
  }

  cancelFilesUploading() {
    this.uploadObservable?.unsubscribe();
    this.isLoading = false;
    this.dialogRef.close(this.isFilesUploaded);
    this.toastService.error('Import process failed', 'Uploading of files is manually stopped');
  }

  canUpload(): boolean {
    return this.isAllFilesSelected();
  }

  private isAllFilesSelected() {
    for (const fileElement of this.fileElements) {
      if (!fileElement.name) {
        return false;
      }
    }
    return true;
  }

  private getFileExtension(filename: string) {
    return filename.split('.').pop();
  }

  private getFormDataNameByAsset(asset: Asset): string {
    switch (asset) {
      case Asset.JSON_REPORT:
        return 'jsonFile';
      case Asset.ZIP_DICOM_JSPS:
        return 'jspsFile';
      case Asset.ZIP_DICOM_JPEG:
        return 'imageFile';
      default:
        throw new Error(`Unexpected 'asset' [${asset}]`);
    }
  }
}
