import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import {
  allLesionTypes,
  GlobalLesionModel,
  OncologyLesionType,
  OncologyResponseEnum,
  OncologyResponseModel,
  OncologyResponseType,
  ResponseOptions
} from 'src/app/_models/Oncology/global-lesion-model';
import { OncologyAssessmentVisitModel } from 'src/app/_models/Oncology/oncology-assessment-visit-model';
import { OncologyAssessmentService } from 'src/app/_services/oncology-assessment.service';
import { forkJoin, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { OncologyResponseAssessmentDialogComponent } from '../oncology-response-assessment-dialog/oncology-response-assessment-dialog.component';
import { PrimaryBodyLocationService } from 'src/app/_services';
import { OncologyCriteria } from 'src/app/_models/Oncology/criteria-model';
import { ReadingVersion } from 'src/app/core/constants/reading-version';

@Component({
  selector: 'app-dynamic-scoring-form',
  templateUrl: './dynamic-scoring-form.component.html',
  styleUrls: ['./dynamic-scoring-form.component.css']
})
export class DynamicScoringFormComponent implements OnInit ,OnDestroy {

  constructor(
    private toastyService: ToastyService,
    private oncologyAssessmentService: OncologyAssessmentService,
    private primaryBodyLocationService: PrimaryBodyLocationService,
    private dialog: MatDialog
  ) { }

  visitForm: FormGroup;
  overallResponseResult: string;
  listOfLesions: GlobalLesionModel[];
  readerId: number;
  responseType = OncologyResponseType;
  responseEnum = OncologyResponseEnum;
  reaponseOptions = ResponseOptions;
  allResponses: any = {};
  lesionsReviewed: any = {};
  criteriaMinSize = 10;
  isVerified = false;
  allLesionTypes = allLesionTypes;
  @Input() studyId: number;
  @Input() readingId: number;
  @Input() patientId: number;
  @Input() eCRFOnly: boolean;
  @Input() visit: OncologyAssessmentVisitModel;
  @Input() visits: OncologyAssessmentVisitModel[];
  @Input() sequentialLock: boolean;
  @Input() imageQualityCheck: boolean;
  @Input() endpointName: string;
  @Input() readonly = false;
  @Output() visitLock: EventEmitter<any> = new EventEmitter<any>();
  @Output() skipLockAndSubmit: EventEmitter<any> = new EventEmitter<any>();
  lesionListSubscription: Subscription;
  lesionReviewedSubscription: Subscription;
  canComputeResponse: boolean = false;
  baselineNadirSubscription: Subscription;
  nadirSPPD;
  baselineSPPD;
  historicalNadir: any[];
  nadirVisitConfigId: number;
  criteriaSubscription: Subscription;
  criteria: OncologyCriteria;

  public get OncologyLesionType(): typeof OncologyLesionType {
    return OncologyLesionType;
  }

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };




  ngOnInit(): void {
    // get criteria setting for assessment
    this.criteriaSubscription = this.oncologyAssessmentService.criteriaSetting.subscribe(criteria => {
      if (criteria) {
        this.criteria = new OncologyCriteria(criteria, this.visit.baseline);
      }
    });
    // tslint:disable-next-line:max-line-length
    if (this.endpointName === ReadingVersion.RECIST_ELIGIBILITY) {
      this.allLesionTypes = allLesionTypes.filter(
        (type) => type === OncologyLesionType.MEASURABLE_LESION
      );
    } else {
      this.allLesionTypes = allLesionTypes.filter(
        (type) => type !== OncologyLesionType.MEASURABLE_LESION
      );
    }

    this.readerId = +JSON.parse(localStorage.getItem('userId'));
    this.visitForm = new FormGroup({
      id: new FormControl(this.visit.visitConfigId),
      imageQualityAssessment: new FormControl('', [Validators.required]),
      imageQualityAssessmentComment: new FormControl({ value: '', disabled: this.visit?.lock })
    });

    if (this.isReadingRecistEligibility()) {
      this.visitForm.addControl('eligibilityStatus', new FormControl('', [Validators.required]));
      this.visitForm.addControl('generalComment', new FormControl({ value: '', disabled: !this.isVerified }));
    }

    if (this.visit) {
      this.visitForm.get('imageQualityAssessment').setValue(this.visit.imageQualityAssessment);
      this.visitForm.get('imageQualityAssessmentComment').setValue(this.visit.imageQualityAssessmentComment);
    }
    this.oncologyAssessmentService.modalSpinnerChangeState(true);
    this.getLesionLists();

    this.visitForm.get('imageQualityAssessment').valueChanges.subscribe(value => {
      this.updateCommentRequired(value);
    });

    // this.visitForm.get('imageQualityAssessmentComment').valueChanges.subscribe(value => {
    //   this.visitForm.get('imageQualityAssessmentComment').updateValueAndValidity();
    // });

    this.lesionListSubscription = this.oncologyAssessmentService.refereshLesionList.subscribe(visitConfigId => {
      if (visitConfigId === this.visit.visitConfigId) {
        this.getLesionLists();
      }
    });

    this.lesionReviewedSubscription = this.oncologyAssessmentService.lesionReviewed.subscribe(result => {
      if (result) {
        this.lesionsReviewed[result.type] = result.reviewed;
      }
    });

    this.baselineNadirSubscription = this.oncologyAssessmentService.baselineNadirSPPD.subscribe(data => {
      if (data) {
        this.nadirSPPD = data.nadirSPPD;
        this.baselineSPPD = data.baselineSPPD;
        this.historicalNadir = data.historicalNadir;
        this.nadirVisitConfigId = data.nadirVisitConfigId;
      }
    });
  }

  isReadingRecistEligibility() {
    return this.endpointName === ReadingVersion.RECIST_ELIGIBILITY;
  }
  updateCommentRequired(value: string) {
    if (!this.isReadingRecistEligibility()) {
      return;
    }

    if (['Sub_Optimal', 'Poor'].includes(value)) {
      this.visitForm.get('imageQualityAssessmentComment').setValidators([Validators.required]);
    } else {
      this.visitForm.get('imageQualityAssessmentComment').clearValidators();
    }

    this.visitForm.get('imageQualityAssessmentComment').updateValueAndValidity();
  }
  get questionOptions() {
    if (this.isReadingRecistEligibility()) {
      return ['Optimal', 'Sub_Optimal', 'Poor'];
    }

    return this.criteria.QuestionOptions();
  }

  get isCommentRequired() {
    return ['Sub_Optimal', 'Poor'].includes(this.visitForm.get('imageQualityAssessment').value);
  }

  generalCommentShouldBeRequired() {
    if (this.isVerified) {
      if (this.checkEligibilityStatus(true)) {
        return this.visitForm.get('eligibilityStatus').value !== 'ELIGIBLE';
      }
      return this.visitForm.get('eligibilityStatus').value !== 'INELIGIBLE';
    }
    return false;
  }

  checkEligibilityStatus(dontSetValue?: boolean) {
    // tslint:disable-next-line:max-line-length
   const isEligible = this.listOfLesions.some(lesion => lesion.axialLongDiameter > this.criteriaMinSize && lesion.shortAxis > this.criteriaMinSize);
   if (!dontSetValue) {
     this.isVerified = true;
     if (isEligible) {
       this.visitForm.get('eligibilityStatus').setValue('ELIGIBLE');
      } else {
        this.visitForm.get('eligibilityStatus').setValue('INELIGIBLE');
      }
      this.visitForm.get('generalComment').enable();
    }

    return isEligible;
  }

  ngOnDestroy(): void{
    if (this.lesionListSubscription) {
      this.lesionListSubscription.unsubscribe();
    }
    if (this.lesionReviewedSubscription) {
      this.lesionReviewedSubscription.unsubscribe();
    }
    if (this.criteriaSubscription) {
      this.criteriaSubscription.unsubscribe();
    }
  }

  showResponseOptions() {
    return this.reaponseOptions[this.responseType.OVERALL];
  }
  getLesionLists() {
    this.oncologyAssessmentService.modalSpinnerChangeState(true);
    this.oncologyAssessmentService.getLesionListByVisitConfigId(
      this.studyId,
      this.readingId,
      this.endpointName,
      this.visit.visitConfigId
    ).subscribe(resp => {
      this.listOfLesions = resp['data'] as GlobalLesionModel[];
      this.oncologyAssessmentService.addToAllLesions(this.listOfLesions);
      this.oncologyAssessmentService.modalSpinnerChangeState(false);
      this.isVerified = false;
    });
  }
  getAllResponses(generate: boolean) {
    this.oncologyAssessmentService.modalSpinnerChangeState(true);
    // tslint:disable-next-line:max-line-length
    this.oncologyAssessmentService.getResponsesByVisitConfigId(this.studyId, this.endpointName, this.readingId, this.readerId, this.visit.visitConfigId, generate).subscribe(resp => {
      this.allResponses = resp['data'];
      this.oncologyAssessmentService.modalSpinnerChangeState(false);
    });
  }
  onChangeResponse(event, type?: OncologyResponseType) {
    if (!this.allResponses.id) {
      this.toastOptions.title = 'INFO: Responses are not generated';
      this.toastOptions.msg = 'Responses must be generated before change';
      this.toastyService.info(this.toastOptions);
      return;
    }
    this.oncologyAssessmentService.modalSpinnerChangeState(true);
    const data: OncologyResponseModel = {
      id: this.allResponses.id,
      endpointName: this.endpointName,
      readingId: this.readingId,
      response: event.value,
      type: type ? type : this.responseType.OVERALL,
      userId: this.readerId,
      visitConfigId: this.visit.visitConfigId
    };
    this.oncologyAssessmentService.updateResponsesByVisitConfigId(this.studyId, data).subscribe(_response => {
      this.oncologyAssessmentService.modalSpinnerChangeState(false);
    });
  }
  onChangeTableResponse(event) {
    this.onChangeResponse(event.value, event.type);
  }
  checkAllLesionsReviewed() {
    let count = 0;
    this.allLesionTypes.forEach(type => {
      if (this.lesionsReviewed[type] === false) {
        count++;
      }
    });
    return count === 0;
  }
  onGenerateAllResponses() {
    this.getAllResponses(true);
  }

  skipLockAndSubmitReading() {
    const data = {
      imageQualityAssessment: this.visitForm.get('imageQualityAssessment').value,
      imageQualityAssessmentComment: this.visitForm.get('imageQualityAssessmentComment').value,
      generalComment: this.visitForm.get('generalComment')?.value,
      eligibilityStatus: this.visitForm.get('eligibilityStatus')?.value,
      measurableBrain: this.listOfLesions,
    };
    this.skipLockAndSubmit.emit(data);
  }

  onScoreLock() {
    if (!this.visit.baseline) {
      this.oncologyAssessmentService.modalSpinnerChangeState(true);
      // compute current follow up response
      // tslint:disable-next-line:max-line-length
      this.oncologyAssessmentService.getResponsesByVisitConfigId(this.studyId, this.endpointName, this.readingId, this.readerId, this.visit.visitConfigId, true).subscribe(_resp => {
        const serviceSources = [
          this.oncologyAssessmentService.getAllResponses(this.studyId, this.readingId, this.endpointName),
          this.oncologyAssessmentService.getAllLesions(this.studyId, this.readingId, this.endpointName),
          this.primaryBodyLocationService.findAll(),
        ];
        forkJoin(serviceSources).subscribe(
          values => {
            const [allResponses, allLesions , primaryLocations] = values as any[];
            if (allResponses['responseCode'] === 200 && allLesions['responseCode'] === 200 && primaryLocations['responseCode'] === 200) {
              this.oncologyAssessmentService.modalSpinnerChangeState(false);
              const dialogRef = this.dialog.open(OncologyResponseAssessmentDialogComponent, {
                width: '800px',
                height: '612.05px',
                panelClass: 'response-dialog-container',
                data: {
                  allLesionTypes: this.allLesionTypes,
                  studyId: this.studyId,
                  readingId: this.readingId,
                  readerId: this.readerId,
                  endpointName: this.endpointName,
                  visit: this.visit,
                  visits: this.visits,
                  baselineSPPD: this.baselineSPPD,
                  nadirSPPD: this.nadirSPPD,
                  historicalNadir: this.historicalNadir,
                  allResponses: allResponses['data'],
                  allLesions: allLesions['data'],
                  primaryLocations: primaryLocations['data'],
                  nadirVisitConfigId: this.nadirVisitConfigId,
                  criteria: this.criteria
                },
                disableClose: true,
                hasBackdrop: false
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result.submit) {
                  this.lockVisit(result.multiTP);
                }
              });
            }
          });
      });
    } else {
      this.lockVisit();
    }
  }
  lockVisit(multiTP: boolean = false) {
    const data = {
      visitConfigId: this.visit.visitConfigId,
      imageQualityAssessment: this.visitForm.get('imageQualityAssessment').value,
      imageQualityAssessmentComment: this.visitForm.get('imageQualityAssessmentComment').value,
      measurableBrain: this.listOfLesions,
      lock: true,
      multiTP: multiTP
    };
    this.visitLock.emit(data);
  }
  checkLocked() {
    return this.visit.lock || this.sequentialLock;
   }
}
