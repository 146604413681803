import { Injectable } from "@angular/core";
import { FormArray } from "@angular/forms";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SharedService {
  messageSource: BehaviorSubject<any> = new BehaviorSubject("");

  constructor() {}

  sync(
    uid: string,
    callback: (
      rowIndex: number,
      valueName: string,
      value,
      formIndex: number
    ) => void
  ) {
    const uidIndex = uid.replace(/\D+/g, "");
    this.messageSource.subscribe((message) => {
      if (message) {
        const [rowIndex, valueName, value, formIndex] = message;
        if (formIndex < uidIndex) {
          callback(rowIndex, valueName, value, formIndex);
        }
      }
    });
  }
}
