import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {AdjudicationExternalServiceName} from '../upload-adj-file-dialog/upload-adj-file-dialog.component';
import {ReadingPsamrisService, ReadingWbmriService} from '../../../_services';
import {BasicResponse} from '../../../core/interfaces/basic-response';

export interface UploadAdjFileDataItem {
  patientCode: string;
  adjudicationReadingStatus: boolean;
}

export interface AdjudicationDataImportInfo {
  fileData: UploadAdjFileDataItem[];
  status: string;
  studyId: number;
  service: AdjudicationExternalServiceName;
}

@Component({
  selector: 'app-adjudication-external-config-dialog',
  templateUrl: './adjudication-external-config-dialog.component.html',
  styleUrls: ['./adjudication-external-config-dialog.component.css']
})
export class AdjudicationExternalConfigDialogComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  importingStatus: string;
  IMPORTING_STATUS_SUCCESS = 'getting adjudication results success';
  IMPORTING_STATUS_FAILURE = 'getting adjudication failure';
  displayedColumns = ['patientCode', 'status'];
  fileData: UploadAdjFileDataItem[] = [];
  dataSource: MatTableDataSource<UploadAdjFileDataItem> = new MatTableDataSource<UploadAdjFileDataItem>();
  totalRows = 0;
  showModalSpinner = false;

  constructor(public dialogRef: MatDialogRef<AdjudicationExternalConfigDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: AdjudicationDataImportInfo,
              private toastyService: ToastyService,
              private readingWbmriService: ReadingWbmriService,
              private readingPsamrisService: ReadingPsamrisService) {
  }

  ngOnInit(): void {
    this.fileData = this.data.fileData;
    this.importingStatus = this.data.status;
    this.init();
  }

  init(): void {
    this.totalRows = this.fileData.length;
    this.dataSource = new MatTableDataSource<UploadAdjFileDataItem>(this.fileData);
    this.dataSource.paginator = this.paginator;
  }

  close(resp: any) {
    this.dialogRef.close();
  }

  send() {
    this.showModalSpinner = true;
    this.showToast('ID 126: Applying results process started', 'System is applying results retrieved from the file', true);
    if (this.data.service === AdjudicationExternalServiceName.WBMRI) {
      this.readingWbmriService.importAdjudicationFile(this.data.studyId, this.fileData).subscribe((resp) => {
        this.processResults(resp);
        this.close(resp);
      });
    }
    if (this.data.service === AdjudicationExternalServiceName.PSAMRIS) {
      this.readingPsamrisService.importAdjudicationFile(this.data.studyId, this.fileData).subscribe((resp) => {
        this.processResults(resp);
        this.close(resp);
      });
    }
  }

  showToast(title: string, description: string, isSuccess: boolean) {
    this.toastOptions.title = title;
    this.toastOptions.msg = description;
    if (isSuccess) {
      this.toastyService.success(this.toastOptions);
    } else {
      this.toastyService.error(this.toastOptions);
    }
  }

  private processResults(resp: BasicResponse<any>) {
    if (resp.responseCode === 200) {
      const isValid = resp.data.valid;
      if (isValid) {
        this.showToast('ID 127: Applying results process finished successfully', 'Process is finished successfully. Adjudication readings are created for the list of patients', true);
      } else {
        this.showToast('ID 128: Applying results process finished with errors', 'There are some errors occurred during the applying results process. Contact support team. Do apply results again before system administrator approved the action.', false);
      }
    } else {
      this.showToast('ID 128: Applying results process finished with errors', 'There are some errors occurred during the applying results process. Contact support team. Do apply results again before system administrator approved the action.', false);
    }
    this.showModalSpinner = false;
  }

}
