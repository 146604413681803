import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-no-upload-dialog',
  templateUrl: './no-upload-dialog.component.html',
  styleUrls: ['./no-upload-dialog.component.css']
})
export class NoUploadDialogComponent implements OnInit {

  noUploadControl: FormControl;
  commentControl: FormControl;

  constructor(public dialogRef: MatDialogRef<any>,
              @Inject(MAT_DIALOG_DATA) public data: NoUploadDialogData) {
  }

  ngOnInit(): void {
    this.noUploadControl = new FormControl(this.data.noUpload, Validators.required);
    this.commentControl = new FormControl(this.data.noUploadsComment, [Validators.required, Validators.maxLength(200)]);
  }

  close(): void {
    this.dialogRef.close();
  }

  save(): void {
    this.dialogRef.close({noUpload: this.noUploadControl.value, comment: this.commentControl.value});
  }

}

export interface NoUploadDialogData {
  patientCode: string;
  visitName: string;
  noUpload: boolean;
  noUploadsComment: string;
  hasUploads: boolean;
}
