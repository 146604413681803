import {Component, Input, OnInit} from '@angular/core';
import {JswReadingKneeFormComponent} from '../jsw-reading-knee-form/jsw-reading-knee-form.component';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {JSW_OARSI_ReadingModel} from '../../../../_models/JSW/jsw-reading-model';
import {
  ImageViewerConfigurations,
  JSW_OARSI_BasicConfigModel
} from '../../../../_models/ImagingProject/JSW/jsw-basic-config-model';
import {ReadingJSWService} from '../../../../_services/reading-jsw.service';
import {ReadingConfigFlexibleService} from '../../../../_services';
import {JswReadingForm} from '../jsw-reading-form/jsw-reading-form.component';
import {Observable, of} from 'rxjs';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {map, switchMap} from 'rxjs/operators';
import {ToastService} from '../../../../_services/internal/toast.service';
import {JSW_OARSI_VisitModel} from '../../../../_models/JSW/jsw-visit-model';
import * as lodash from 'lodash';
import {ConfirmSigningDialogComponent} from "../../../ReportSign/confirm-signing-dialog/confirm-signing-dialog.component";
import {MatDialog} from "@angular/material/dialog";

interface DynamikaAIResultsTableRow {
  timepoint: string;
  visitId: number;
}

@Component({
  selector: 'app-jsw-reading-form-basic',
  templateUrl: './jsw-reading-form-basic.component.html',
  styleUrls: ['./jsw-reading-form-basic.component.css']
})
export class JswReadingFormBasicComponent implements OnInit, JswReadingForm {

  @Input()
  switchSubmitBtnDisabledSubject;

  @Input()
  viewerEnabledSubject;

  @Input()
  currentReadingSubject;

  @Input()
  readingListUpdatedSubject;

  reading: JSW_OARSI_ReadingModel;
  readingConfig: JSW_OARSI_BasicConfigModel;
  currentReadingSubjectSubscription;

  questionsForm;
  kneeFormArray;
  clonedReading;
  commentControl: FormControl = new FormControl('');
  imageQualityControl : FormGroup = new FormGroup({});
  wholeReadingRejection : FormControl = new FormControl(false);
  adverseEventQuestionControl : FormControl = new FormControl();
  dynamikaAIResultsTableRows: DynamikaAIResultsTableRow[];
  dynamikaAIResultsTableDisplayedColumns = ['visit_name', 'report'];
  changeCompleted: boolean = true;
  dataloaded: boolean = false;

  constructor(private fb: FormBuilder,
              private readingService: ReadingJSWService,
              private readingConfigService: ReadingConfigFlexibleService,
              private toastService: ToastService,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.subscribeReading();
  }

  clearForm() {
    this.checkCurrentReadingSubscription();
  }

  submitForm(studyId: number, spentSeconds: number): Observable<BasicResponse<any>> {
    if (this.reading.visits.find(v => v.scoring === null && !v.noUploads) === undefined) {
      this.applyFormValuesToCurrentReading(true);
    }
    this.reading.timeSpent = spentSeconds;
    this.reading.comment = this.commentControl.value;
    this.reading.isRejected = this.wholeReadingRejection.value;
    this.reading.observedAdverseEvent = this.adverseEventQuestionControl.value;


    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.reading.studyId,
        patientId: this.reading.patientId,
        visitConfigId: this.reading.flexibleConfigId
      }
    });

    return dialogRef.afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          if (!dialogResponse?.jwt) {
            if (!dialogResponse?.canceled) {
              this.toastService.error('ERROR', 'Sign Report Failed.<br/>Please enter valid credentials');
            }

            return of(null);
          }

          return this.readingService.completeReading(studyId, this.reading, dialogResponse.jwt)
            .pipe(map(resp => this.handleCompleteReadingResponse(resp)));
        })
      );
  }

  skipForm(studyId: number, spentSeconds: number): Observable<BasicResponse<any>> {
    if (this.reading.visits.find(v => v.scoring === null && !v.noUploads) === undefined) {
      this.applyFormValuesToCurrentReading(false);
    }
    this.reading.timeSpent = spentSeconds;
    this.reading.comment = this.commentControl.value;
    this.reading.isRejected = this.wholeReadingRejection.value;
    this.reading.observedAdverseEvent = this.adverseEventQuestionControl.value;
    this.updateReadingNavigator(this.reading);
    return this.readingService.updateReadingWithSaveAllData(studyId, this.reading)
      .pipe(map(resp => this.handleUpdateSkipReadingResponse(resp)));
  }

  updateReadingNavigator(reading: JSW_OARSI_ReadingModel) {
    this.readingListUpdatedSubject.next(reading);
  }

  initForm(): void {
    this.initImageQualityControl();
    this.initQuestionsForm();
    this.initDynamikaAIResultsTable();
    this.initKneeFormArray();
    this.initCommentControl();
    this.initadverseEventQuestionControl();
    this.initSubmitButtonStatusListener();
    this.dataloaded = true;
  }

  onDownloadMedicalReport(visitId: number): void {
    const currentVisit = this.reading.visits.find(cVisit => cVisit.id === visitId);
    window.open(currentVisit.medicalReportLink);
  }

  private subscribeReading(): void {
    this.currentReadingSubjectSubscription = this.currentReadingSubject.subscribe(async reading => {
      this.reading = reading;
      this.readingConfig = await this.getReadingConfig(reading);
      this.setupViewerMode(this.readingConfig);
      this.initForm();
    });
  }

  private async getReadingConfig(reading: JSW_OARSI_ReadingModel): Promise<JSW_OARSI_BasicConfigModel> {
    const configResp = await this.readingConfigService.getById(reading['flexibleConfigId']).toPromise();
    return configResp.data.config;
  }

  private initKneeFormArray(): void {
    this.kneeFormArray = JswReadingKneeFormComponent.generateKneeFormArray(this.reading, this.fb);
    this.clonedReading = lodash.cloneDeep(this.reading);
  }

  private initQuestionsForm(): void {
    const questionsForm = this.fb.array([]);
    // jsw visits do not have order -  sort by id
    this.reading.visits.sort((a, b) => a.id - b.id).filter(currentVisit => !currentVisit.noUploads).forEach(currentVisit => {
      const questionForm = this.generateQuestionFromForVisit(currentVisit);
      if ((currentVisit.status || '').toLowerCase() === 'done'){
        questionForm.disable();
      }
      questionsForm.push(questionForm);
    });
    this.questionsForm = questionsForm;
  }

  private generateQuestionFromForVisit(currentVisit: JSW_OARSI_VisitModel): FormGroup {
    return this.fb.group({
      visitId: new FormControl(currentVisit.id, [Validators.required]),
      visitName: new FormControl(currentVisit.timepoint, [Validators.required]),
      question1: new FormControl(currentVisit.jsmCorrectKnee !== null ? currentVisit.jsmCorrectKnee : true, [Validators.required]),
      question2: new FormControl(currentVisit.jsmAppropriate !== null ? currentVisit.jsmAppropriate : true, [Validators.required]),
    });
  }

  private initCommentControl(): void {
    this.commentControl = new FormControl(this.reading.comment, this.reading.isRejected ? [Validators.required] : []);
    this.wholeReadingRejection = new FormControl(this.reading.isRejected, []);
    this.readingRejection({ value: this.reading.isRejected })
  }

  private initadverseEventQuestionControl(): void {
    this.adverseEventQuestionControl = new FormControl(this.reading.observedAdverseEvent !== null ? this.reading.observedAdverseEvent : false, [Validators.required]);
  }

  private initImageQualityControl(): void {
    this.imageQualityControl = new FormGroup({
      imageQualityAssessment: new FormControl(this.reading.imageQualityAssessment, [Validators.required]),
      imageQualityAssessmentComment: new FormControl(this.reading.imageQualityAssessmentComment, [])
    });
    this.imageQualityControl.valueChanges.subscribe(value => {
      this.reading.imageQualityAssessment = value.imageQualityAssessment;
      this.reading.imageQualityAssessmentComment = value.imageQualityAssessmentComment
    })

  }

  private initSubmitButtonStatusListener() {
    const isReadingFormValid = () => this.questionsForm.valid && this.kneeFormArray.valid && this.commentControl.valid && this.imageQualityControl.valid;
    const switchSubmitButton = () => this.switchSubmitBtnDisabledSubject.next(!isReadingFormValid());

    this.questionsForm.statusChanges.subscribe(switchSubmitButton);
    this.kneeFormArray.statusChanges.subscribe(switchSubmitButton);
    this.commentControl.statusChanges.subscribe(switchSubmitButton);
    this.imageQualityControl.statusChanges.subscribe(switchSubmitButton);
    switchSubmitButton();
  }

  private checkCurrentReadingSubscription(): void {
    if (!this.isCurrentReadingSubjectSubscribed()) {
      this.subscribeReading();
    }
  }

  private isCurrentReadingSubjectSubscribed(): boolean {
    return this.currentReadingSubject.observers.length !== 0;
  }

  private setupViewerMode(readingConfig: JSW_OARSI_BasicConfigModel) {
    if (readingConfig.imageViewerConfiguration === ImageViewerConfigurations.eCRF_MODE_ONLY) {
      this.trySetViewerEnabled(false);
    } else if (readingConfig.imageViewerConfiguration === ImageViewerConfigurations.DEFAULT) {
      this.trySetViewerEnabled(true);
    }
  }

  private trySetViewerEnabled(mode: boolean): void {
    if (!!this.viewerEnabledSubject) {
      this.viewerEnabledSubject.next(mode);
    }
  }

  private handleCompleteReadingResponse(resp: BasicResponse<JSW_OARSI_ReadingModel>): BasicResponse<JSW_OARSI_ReadingModel> {
    if (resp.responseCode !== 200) {
      this.toastService.error('Reading form is not submitted', 'There are some errors on the form must be resolved');
    } else {
      this.toastService.success('Reading form has been submitted successfully', 'The reading is completed. Report is available.');
    }
    return resp;
  }

  private handleUpdateSkipReadingResponse(resp: BasicResponse<JSW_OARSI_ReadingModel>): BasicResponse<JSW_OARSI_ReadingModel> {
    if (resp.responseCode !== 200) {
      this.toastService.error('Reading form is not updated', 'There are some errors on the form must be resolved');
    } else {
      this.toastService.success('Reading form has been updated successfully');
    }
    return resp;
  }

  private applyFormValuesToCurrentReading(isSubmitted: boolean): void {
    this.reading.visits.filter(currentVisit => !currentVisit.noUploads && currentVisit.status != "DONE").forEach(currentVisit => {
      this.applyQuestionFormForCurrentVisit(currentVisit);
      this.applyKneeFormForCurrentVisit(currentVisit, isSubmitted);
    });
  }

  private applyKneeFormForCurrentVisit(currentVisit: JSW_OARSI_VisitModel, isSubmitted: boolean) {
    const rejecttionAndSkip = this.wholeReadingRejection.value && !isSubmitted;
    const currentKneeForm = rejecttionAndSkip ? this.clonedReading.visits.find(v => v.id === currentVisit.id).scoring : this.kneeFormArray.value.find(kneeForm => kneeForm.visitId === currentVisit.id);
    if (!currentVisit.scoring)
      return
    currentVisit.scoring.rightKneeLateralValid = currentKneeForm.RKL || false;
    currentVisit.scoring.rightKneeMedialValid = currentKneeForm.RKM || false;
    currentVisit.scoring.leftKneeLateralValid = currentKneeForm.LKL || false;
    currentVisit.scoring.leftKneeMedialValid = currentKneeForm.LKM || false;
    currentVisit.scoring.rightKneeLateralMm = currentKneeForm.rightKneeLateralMm;
    currentVisit.scoring.rightKneeMedialMm = currentKneeForm.rightKneeMedialMm;
    currentVisit.scoring.leftKneeLateralMm = currentKneeForm.leftKneeLateralMm;
    currentVisit.scoring.leftKneeMedialMm = currentKneeForm.leftKneeMedialMm;
    currentVisit.scoring.leftKneeComment = rejecttionAndSkip ? currentKneeForm.leftKneeComment : currentKneeForm.Lcomment;
    currentVisit.scoring.rightKneeComment = rejecttionAndSkip ? currentKneeForm.rightKneeComment : currentKneeForm.Rcomment;
  }

  private applyQuestionFormForCurrentVisit(currentVisit: JSW_OARSI_VisitModel): void {
    const currentQuestionForm = this.questionsForm.value.find(questionForm => questionForm.visitId === currentVisit.id);
    currentVisit.jsmCorrectKnee = currentQuestionForm.question1;
    currentVisit.jsmAppropriate = currentQuestionForm.question2;
  }

  private initDynamikaAIResultsTable() {
    const tableRows = [] as DynamikaAIResultsTableRow[];
    this.reading.visits.filter(currentVisit => !currentVisit.noUploads).forEach(currentVisit => {
      const tableRow = this.visitToDynamikaAIResultTableRow(currentVisit);
      tableRows.push(tableRow);
    });
    this.dynamikaAIResultsTableRows = tableRows;
  }

  private visitToDynamikaAIResultTableRow(currentVisit: JSW_OARSI_VisitModel) {
    return {
      visitId: currentVisit.id,
      timepoint: currentVisit.timepoint
    } as DynamikaAIResultsTableRow;
  }

  readingRejection(event) {
    this.changeCompleted = false;
    if (event.value) {
      this.applyFormValuesToCurrentReading(true);
      this.clonedReading = lodash.cloneDeep(this.reading)
    }
    setTimeout(() => {
      this.reading.visits.filter(currentVisit => !currentVisit.noUploads).forEach(v => {
        const clonedVisit = this.clonedReading.visits.find(cv => cv.id === v.id);
        const kneeFormIndex = this.kneeFormArray.value.findIndex(kneeForm => kneeForm.visitId === v.id);
        const currentKneeForm = this.kneeFormArray.controls[kneeFormIndex] as FormGroup;
        currentKneeForm.patchValue({
          RKL: !event.value,
          RKM: !event.value,
          LKL: !event.value,
          LKM: !event.value,
          rightKneeLateralMm: event.value ? "NE" : clonedVisit.scoring.rightKneeLateralMm,
          rightKneeMedialMm: event.value ? "NE" : clonedVisit.scoring.rightKneeMedialMm,
          leftKneeLateralMm: event.value ? "NE" : clonedVisit.scoring.leftKneeLateralMm,
          leftKneeMedialMm: event.value ? "NE" : clonedVisit.scoring.leftKneeMedialMm,
        });

        v.scoring.rightKneeLateralValid = !event.value;
        v.scoring.rightKneeMedialValid = !event.value;
        v.scoring.leftKneeLateralValid = !event.value;
        v.scoring.leftKneeMedialValid = !event.value;
        v.scoring.rightKneeLateralMm = event.value ? "NE" : clonedVisit.scoring.rightKneeLateralMm,
        v.scoring.rightKneeMedialMm = event.value ? "NE" : clonedVisit.scoring.rightKneeMedialMm,
        v.scoring.leftKneeLateralMm = event.value ? "NE" : clonedVisit.scoring.leftKneeLateralMm,
        v.scoring.leftKneeMedialMm = event.value ? "NE" : clonedVisit.scoring.leftKneeMedialMm
      })
      if (!event.value) {
        this.reading = lodash.cloneDeep(this.clonedReading);
        this.initKneeFormArray();
      }
      this.commentControl.validator = event.value ? Validators.required : null;
      this.commentControl.updateValueAndValidity();
      this.changeCompleted = true;
    }, 50);
  }

}
