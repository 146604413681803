import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastOptions } from 'ng2-toasty';
import { FormMode } from 'src/app/core/constants/form-mode';
import { ReadingType } from 'src/app/core/constants/reading-type';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import { AutoBatchConfigPayload } from 'src/app/core/payload/auto-batch-config-payload';
import { ModalityModel } from 'src/app/_models/ImagingProject/modality-model';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import { ReadingConfigFlexibleService, StudySequenceLabelService, StudyUserService } from 'src/app/_services';
import { BatchLogicService } from 'src/app/_services/batch-logic.service';
import { WebinarBasicConfigModel } from 'src/app/_models/ImagingProject/Webinar/webinar-basic-config-model';
import { ImagingProjectReadingBasicComponent } from '../../imaging-project-reading-basic.component';

export interface ImagingProjectSubmitEvent {
  result: 'submit' | 'cancel';
  data: any;
  level1BatchConfig?: AutoBatchConfigPayload;
  adjudicationBatchConfig?: AutoBatchConfigPayload;
  basicBatchConfig?: AutoBatchConfigPayload;
}

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.css']
})
export class WebinarComponent extends ImagingProjectReadingBasicComponent implements OnInit {


  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();
  @Input() public mode:FormMode;
  readonly formModes = FormMode;
  @Input('configModel')
  set configModel(value: any) {
    if (value != null) {
      this._configModel = value;
      if (this._configModel.readingLevel === 'patient') {
        this.modalities = this._configModel.modalities;
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        this.selectedModality =this._configModel.modalities;
      } else {
        this.selectedModality = this._configModel.modalities;
      }
    }
  }

  @Input() selectedConfig: FlexibleConfig<WebinarBasicConfigModel>;
  get configModel(): any {
    return this._configModel;
  }

  demriqtypes:any[];
  readingVersionField = new FormControl('', [
    Validators.required
  ]);
  readingLevelField = new FormControl('', [
    Validators.required
  ]);
  modalityField = new FormControl('', [
    Validators.required
  ]);
  readingTypeField = new FormControl('', [
    Validators.required
  ]);
  hideVisitChronologyField = new FormControl('', [
    Validators.required
  ]);
  imageViewerConfigurationField = new FormControl('', [
    Validators.required
  ]);
  readersField = new FormControl('', [
    Validators.required
  ]);
  studySequenceLabels: SequenceLabelModel[] = [];
  anatomySelectorControl: FormControl = new FormControl('', [Validators.required]);
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];

  basicBatchConfig:any;

  readingVersion: any;
  readingLevel: string;
  selectedModality: ModalityModel[];
  readingType: string;
  hideVisitChronologyType:string;
  batchManagementType=false;
  imageViewerConfigurationType:string;
  fingersToScore:any;
  regionsToScore:any;
  dataSourceReaders: any;
  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];

  displayedScreenshotOptions: string [] = ['id', 'optionText', 'actionDelete'];
  minReaders =1;
  maxReaders = 20;
  readers: any[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  studyId: number;
  _configModel = {
    endpointReadingVersion: 'basic',
    modalities: [] as ModalityModel[],
    readingLevel: 'patient',
    readingTypeConfig:["AUTO"],
    readingType: 'parallel',
    hideVisitHistory: null,
    readers: [],
    readingVersion: ReadingVersion.WEBINAR_BASIC,
    batchManagement: false,
    imageViewerConfigurationType:'default',
    endpoint:null,
    anatomySelector: []
  };


  formValid = false;
  selectedReadersids = [];
  modalities: ModalityModel[];
  selectedReaders = [];
  selectedQuestionTemplate: string;
  visitReadingLevelModality: any = {};


  constructor(private router: Router,
              private route: ActivatedRoute,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private studySequenceLabelService: StudySequenceLabelService,
              private studyUserService: StudyUserService,
              private flowViewer: MatDialog,
              private dialog: MatDialog,
              private batchLogicService: BatchLogicService) {
    super();
  }

  ngOnInit() {
    this.formValid = false;
    this.selectedReaders = [];

    this._configModel.readers.forEach(ri => {
      this.selectedReadersids.push(ri['id']);
    });

    this.readingVersion = 'ReadingVersion-BasicWebinar';
    this.readingLevel = 'parallel';
    this.readingType = 'patient';
    this.hideVisitChronologyType = 'hide_scan_dates';
    this.imageViewerConfigurationType = 'default';

    if (this.mode === FormMode.EDIT) {
      this.readingVersion = this._configModel.readingVersion;
      this.readingLevel = this._configModel.readingLevel;
      this.readingType = this._configModel.readingType;
      this.imageViewerConfigurationType = this._configModel.imageViewerConfigurationType;
      this.hideVisitChronologyType = this._configModel.hideVisitHistory;
  }

    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    this.prepareSequenceLabels();

    this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId).subscribe(respgroupedModalitiesResp => {
      if (respgroupedModalitiesResp.data != null) {
        if(this.mode!=FormMode.EDIT)
        this.modalities = respgroupedModalitiesResp.data;
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        if(this.mode==FormMode.EDIT)
        this.selectedModality = this.modalities.filter((modality)=> modality.name =='MRI' || modality.name =='CT' || modality.name == 'X-ray');
      }

      this.studyUserService.getReadersByStudyId(this.studyId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.readers = response.data;
            const activeReader: any[] = [];
            this.readers.forEach(r => {

              if (this.selectedReadersids.includes(r.id)) {
                activeReader.push(r);
              }
            });

            this._configModel.readers = activeReader;
            this.dataSourceReaders = this._configModel.readers;
            this.selectedReaders = this._configModel.readers;
            this.selectedReadersids = [];
            this._configModel.readers.forEach(r => {
              const cur = this.readers.indexOf(r);
              this.readers.splice(cur, 1);
            });
          }
        });
    });
    if (this.selectedConfig) {
      this.batchManagementType = this.selectedConfig.config.batchManagement;
      this.loadBatchConfigs();
    }
  }
  loadBatchConfigs(): void {
    if (this.mode === FormMode.EDIT) {
      this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id).subscribe(response => {
        const batchConfigs = response.data;
        batchConfigs.forEach(c => {
          this.basicBatchConfig = c;
        });
      });
    }
  }
  prepareSequenceLabels() {
    this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId).subscribe(studySequenceResp => {
      this.studySequenceLabels = studySequenceResp.data;
      if (this.mode === FormMode.EDIT) {
        if (this._configModel.anatomySelector) {
          this.initSequenceLabels(this._configModel.anatomySelector);
        } else {
          this.initDefaultSequenceLabels();
        }
      }
    });
  }

  initDefaultSequenceLabels() {
    this.availableSequenceLabels = this.getAvailableSequenceLabels();
    this.selectedSequenceLabels = this.availableSequenceLabels;
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels();
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  getAvailableSequenceLabels(): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const modality of this._configModel.modalities) {
      for (const label of modality.sequenceLabels) {
        configLabels.add(label);
      }
    }
    console.log(configLabels)
    return this.studySequenceLabels
      .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this.validateForm();
  }

  onModalityTypeChange(event) {
    this.selectedModality = [];
    this._configModel.modalities = [];
    event.value.forEach(element=>{

      this.selectedModality.push(element);
     this._configModel.modalities.push(element);
    })
   this.initDefaultSequenceLabels();
    this.validateForm();
  }

  onReadingLevelChange(event: { value: any; }) {
    this._configModel.readingLevel = event.value;

    // if (this._configModel.readingLevel === 'patient') {
    //   this.setVisitModalityText(this.modalities);
    //   this.modalities.unshift(this.visitReadingLevelModality);
    //   this.selectedModality = this.visitReadingLevelModality;
    // } else {
    //   if (this.modalities[0].id === -1) {
    //     this.modalities.shift();
    //   }
    //   this.selectedModality = null;
    // }
    this.modalities = this.modalities.filter((modality)=> modality.name =='MRI' || modality.name =='CT'  || modality.name == 'X-ray');
    this.modalities.sort((a, b) => a.name.localeCompare(b.name));
    this.validateForm();
  }

  onReadingTypeChange(event: { value: any; }) {
    this.formValid = this._configModel.readingType !== event.value;
    this._configModel.readingType = event.value;
    this.validateForm();
  }

  onReaderSelectionChange(event: { value: any[]; }) {
    this.selectedReadersids = event.value;
  }
  onChangeImageConfiguration(){
    this.validateForm();
  }

  // this code must check and make sure the Reader is only added to the list if it is not already in it!
  onAddSelectedReaders() {

    if (this.selectedReadersids != null) {
      let counter = this._configModel.readers.length;
      this.selectedReadersids.forEach(readerId => {
        if (counter < this.maxReaders) {
          const reader = this.readers.find(r => r.id === readerId);
          if ((reader != null) && (!this._configModel.readers.includes(reader))) {
            this._configModel.readers.push(reader);
            const index = this.readers.indexOf(reader);
            this.readers.splice(index, 1);
            this.validateForm();
          }
          counter += 1;
        }
      });

      this.selectedReadersids = [];
    }
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
    this.selectedReaders = this._configModel.readers;
    if (this._configModel.readers.length > 0) {
      this.readersField.clearValidators();
    }
  }


  deleteReader(reader: any) {
    const index = this._configModel.readers.indexOf(reader);
    this._configModel.readers.splice(index, 1);
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
    this.selectedReadersids = this._configModel.readers;
    this.readers.unshift(reader);
    this.selectedReadersids = [];

    if (this._configModel.readers.length === 0) {
      this.readersField.validator = Validators.required;
    }
    if (this._configModel.readers.length > 0) {
      this.validateForm();
    } else {
      this.formValid = false;
    }

  }

  clickSubmitBtn() {
    const readers = [];
    this._configModel.readers.forEach(r => {
      readers.push({id: r.id});
    });

    this._configModel.anatomySelector = this.selectedSequenceLabels.map(l => {
      return {id: l.id};
    });

    this._configModel.modalities = [];
    if (this.selectedModality == null) {
      this._configModel.modalities = this.modalities;
    } else {
      this.modalities.forEach(m => {
        if (this.selectedModality.find(x=>x.id === m.id) ){
          this._configModel.modalities.push(m);
        }
      });
    }
    this._configModel.batchManagement =this.batchManagementType;
    this._configModel.hideVisitHistory=this.hideVisitChronologyType;
    this._configModel['endpointReadingVersion'] = 'basic';
    this._configModel.readers = readers;
    this._configModel.anatomySelector = this.selectedSequenceLabels;
    this._configModel.imageViewerConfigurationType = this.imageViewerConfigurationType;
    if(!this.basicBatchConfig)
      this.basicBatchConfig = {
        batchCount: "1",
        endpoint: 'Webinar',
        flexibleConfigId: null,
        id: null,
        initiationMethod: "auto",
        initiationOption: "by_quantity",
        numberOfReadings: 1,
        readingType: "basic_reading",
        studyId: this.studyId
      };
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: this._configModel,
      basicBatchConfig: this.basicBatchConfig
    };
    //console.log(submitEvent)
    this.clickSubmit.emit(submitEvent);
  }

  clickCancelBtn() {
    this.clickCancel.emit({result: 'cancel'});
  }

  validateForm() {
    let ans = this.formValid = true;

    if (this._configModel.readingLevel == null) {
      ans = false;
    }

    if (this._configModel.readingType == null) {
      ans = false;
    }

    if (this._configModel.readingLevel !== 'patient') {
      ans = (this.selectedModality != null);
    }

    if (this._configModel.readers.length < this.minReaders || this._configModel.readers.length >this.maxReaders) {
      ans = false;
    }

    if (this.anatomySelectorControl.invalid) {
      ans = false;
    }
    if(this.hideVisitChronologyField.invalid)
      ans = false;

    if(this.imageViewerConfigurationField.invalid)
      ans = false;

    this.formValid = ans;
  }

  setVisitModalityText(modalities) {
    let mText = '';
    modalities.forEach(m => {
      mText += m.name + ', ';
    });

    this.visitReadingLevelModality.id = -1;
    this.visitReadingLevelModality.created = null;
    this.visitReadingLevelModality.name = mText.substr(0, mText.length - 2);
    this.visitReadingLevelModality.sequenceLabels = [];
  }

  public objectComparator = function (option, value): boolean {
    return option.id === value.id;
  };

}

