import { VisitType } from './../patient-model';
import {BaseFlexibleConfig} from '../base-flexible-config';
import {SequenceLabelModel} from '../sequence-label-model';
import {QuestionFormat} from '../../../modules/ImagingProject/ImagingProjectReading/IF/imaging-project-reading-if/model.questionformat';
import { ModalityModel } from '../modality-model';

export interface IncidentalFindingsConfigModel extends BaseFlexibleConfig {
  readingLevelOptions: { smartRead: boolean, sequentialLock: boolean };
  screenshotComments: string[];
  visit: IncidentalFindingsVisitModel[];
  screenshotFeature: boolean;
  amiScreenshotFeature?: boolean;
  enableCommentOptionsForScreenshot: boolean;
  anatomySelector: SequenceLabelModel[] | {id: number}[];
  endpointReadingVersion: 'basic' | 'adjudication';
  hideVisitChronology: string;
}

export class IncidentalFindingsVisitModel{
  id?: number;
  created?: number;
  name: string;
  type?: VisitType;
  durationTimeUnit?: string;
  durationTimeValue?: number;
  baseline?: boolean;
  repeatAllowed?: boolean;
  questions?: QuestionFormat[];
  modalities?: ModalityModel[];
  isAvailable = true;
}

export enum VisitStatus {
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NEW_NOT_EDITABLE = 'NEW_NOT_EDITABLE',
  NEW_EDITABLE = 'NEW_EDITABLE',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE'
}
