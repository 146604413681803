import { SeriesLabelModel } from './series-label.model';
import get from 'lodash/get';
import { Transformation } from './transformation.model';

export class SeriesLabelListModel {
  seriesLabels: Array<SeriesLabelModel> = [];
  seriesOrder: string;

  constructor(data: any) {
    if (data) {
      this._parse(data);
    }
  }

  private _parse(data: any) {
    const seriesLabels = get(data, 'SeriesLabel', []);
    this.seriesOrder = get(data, 'SeriesOrder._text', null);

    if (seriesLabels.length) {
      const series = [];
      seriesLabels.forEach(l => {
        const label = new SeriesLabelModel();
        let transformations = get(l, 'TransformationList.Transformation', []);
        const reconstruction = get(l, 'ReconstructionType._text', null);
        label.name = get(l, 'LabelName._text', null);
        label.position = get(l, 'SeriesPosition._text', null);
        transformations = transformations instanceof Array ? transformations : [transformations];
        transformations = transformations.sort((a, b) => a.order - b.order);
        label.transformations = transformations.map(t => new Transformation(t));

        if (reconstruction) {
          const recObject = new Transformation();
          recObject.type = reconstruction;
          label.transformations.unshift(recObject);
        }

        series.push(label);
      });

      this.seriesLabels = series.sort((a, b) => a.position - b.position);
    }
  }
}
