import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface WindowLevelData {
  W: string;
  C: string;
}

@Component({
  selector: 'app-window-level-dialog',
  templateUrl: './window-level-dialog.component.html',
  styleUrls: ['./window-level-dialog.component.css']
})
export class WindowLevelDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<WindowLevelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WindowLevelData) { }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickOk(): void {
    this.dialogRef.close(this.data);
  }

}
