import { StudyDashboardModel, StudyModel } from '../../../_models/ImagingProject/study-model';

export class PushDashboardProjects {
  static readonly type = '[Projects] PushDashboardProjects';

  constructor(public payload: StudyDashboardModel[]) {
  }
}

export class SetAssignedProjectIds {
  static readonly type = '[Projects] SetAssignedProjectIds';

  constructor(public payload: number[]) {
  }
}

export class PushAssignedProjectId {
  static readonly type = '[Projects] PushAssignedProjectId';

  constructor(public payload: number) {
  }
}

export class PushDashboardProjectId {
  static readonly type = '[Projects] PushDashboardProjectId';

  constructor(public payload: number) {
  }
}

export class PushAuditTrailsProjectId {
  static readonly type = '[Projects] PushAuditTrailsProjectId';

  constructor(public payload: number) {
  }
}

export class PushAuditTrailsProjects {
  static readonly type = '[Projects] PushAuditTrailsProjects';

  constructor(public payload: StudyModel[]) {
  }
}

export class SetSelectedProject {
  static readonly type = '[Projects] SetSelectedProject';

  constructor(public payload: StudyModel) {
  }
}

export class SetPageHeaderTitle {
  static readonly type = '[Projects] SetPageHeaderTitle';

  constructor(public payload: string) {
  }
}

export class ClearProjectsData {
  static readonly type = '[Projects] GetDashboardProjects';
}
