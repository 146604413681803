import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ViewerConfigService } from '../../../_services/viewer-config.service';
import { ViewerConfigModel } from '../../../_models/viewer/ViewerConfigModel';

@Injectable()
export class ViewerConfigResolver implements Resolve<any> {
  constructor(private viewerConfigService: ViewerConfigService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return new Observable<any>(subscriber => {
      const protocolType: string = route.data.protocolType;
      this.viewerConfigService.load(protocolType).subscribe((config: ViewerConfigModel) => {
        subscriber.next(config);
        subscriber.complete();
      }, err => {
        subscriber.error(err);
      });
    });
  }
}
