import {SimpleDateRanged, SimpleTimeUnit} from '../../core/interfaces/simple-time-unit';

export const timeUnitToDaysCount = (unit: SimpleTimeUnit): number => {
  switch (unit) {
    case 'D':
      return 1;
    case 'W':
      return 7;
    case 'M':
      return 30;
  }
};

export const compareDateRange = <T extends SimpleDateRanged>(r1: T, r2: T): number => {
  const baseDaysCount1 = timeUnitToDaysCount(r1.durationTimeUnit);
  const baseDaysCount2 = timeUnitToDaysCount(r2.durationTimeUnit);
  if (baseDaysCount1 * r1.durationTimeValue > baseDaysCount2 * r2.durationTimeValue) {
    return 1;
  } else if (baseDaysCount1 * r1.durationTimeValue < baseDaysCount2 * r2.durationTimeValue) {
    return -1;
  } else {
    return 0;
  }
};

export const sortDateRanged = <T extends SimpleDateRanged>(rangesList: T[]): T[] => {
  return rangesList.sort(compareDateRange);
};
