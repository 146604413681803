// tslint:disable-next-line:max-line-length
import {AnnKmaObject} from './ann-kma-object';
import {first} from "rxjs/operators";

export class AnnKmaRenderer extends lt.Annotations.Rendering.AnnPolylineObjectRenderer implements lt.Annotations.Engine.IAnnObjectRenderer {
  private annAutoManager: lt.Annotations.Automation.AnnAutomationManager;

  constructor(annAutoManager) {
    super();
    this.annAutoManager = annAutoManager;
  }

  // Override the Render method in order to draw the 3 points as the user creates them.
  render(mapper: lt.Annotations.Engine.AnnContainerMapper, annObject: lt.Annotations.Engine.AnnObject) {
    // tslint:disable-next-line:max-line-length
    const engine: lt.Annotations.Rendering.AnnHtml5RenderingEngine = <lt.Annotations.Rendering.AnnHtml5RenderingEngine>super.renderingEngine;
    if (!engine) {
      return;
    }

    const context = engine.context;
    if (!context) {
      return;
    }

    const kmaObject = annObject as AnnKmaObject;

    const firstLine = new lt.Annotations.Engine.AnnPolylineObject();
    firstLine.stroke = kmaObject.stroke.clone();
    // firstLine['get_labels']()['RulerLength'] = annCrossProductObject['get_labels']()['RulerLength'];
    // firstLine.labels['RulerLength'] = kmaObject.labels['RulerLength'];
    firstLine.fixedStateOperations = kmaObject.fixedStateOperations;
    firstLine.opacity = kmaObject.opacity;

    firstLine.mapper = mapper;
    firstLine.points.clear();
    firstLine.points.add(kmaObject.points.item(0));
    firstLine.points.add(kmaObject.points.item(1));

    super.render(mapper, firstLine);


    const secondLine = new lt.Annotations.Engine.AnnPolylineObject();
    secondLine.stroke = kmaObject.stroke.clone();
    // secondLine['get_labels']()['RulerLength'] = annCrossProductObject['get_labels']()['RulerLength'];
    // secondLine.labels['RulerLength'] = kmaObject.labels['RulerLength'];
    secondLine.fixedStateOperations = kmaObject.fixedStateOperations;
    secondLine.opacity = kmaObject.opacity;

    secondLine.mapper = mapper;
    secondLine.points.clear();
    secondLine.points.add(kmaObject.points.item(2));
    secondLine.points.add(kmaObject.points.item(3));

    super.render(mapper, secondLine);

    const intersectionPoint = kmaObject.intersection;
    const angleLabel = kmaObject.labels['Angle'];
    if (intersectionPoint && angleLabel) {
      const tibiaLabelPoint = kmaObject.points.item(2);
      angleLabel.originalPosition =  lt.LeadPointD.create(tibiaLabelPoint.x * 1.02, tibiaLabelPoint.y * 1.02);
      const angle = kmaObject.angle;
      const angleInDegrees = lt.Annotations.Engine.Utils.radianToDegrees(angle);
      angleLabel.text = angleInDegrees.toFixed(2) + '°';
      angleLabel.foreground = lt.Annotations.Engine.AnnSolidColorBrush.create('White');
      angleLabel.background = lt.Annotations.Engine.AnnSolidColorBrush.create('Green');

    }

    const femurLineLabel = kmaObject.labels['FemurLine'];
    if (femurLineLabel) {
      const femurLineLabelPoint = kmaObject.points.item(0);
      femurLineLabel.originalPosition = lt.LeadPointD.create(femurLineLabelPoint.x * 1.01, femurLineLabelPoint.y * 1.01);
    }
    const tibiaLineLabel = kmaObject.labels['TibiaLine'];
    if (tibiaLineLabel) {
      const tibiaLineLabelPoint = kmaObject.points.item(3);
      tibiaLineLabel.originalPosition = lt.LeadPointD.create(tibiaLineLabelPoint.x * 1.01, tibiaLineLabelPoint.y * 1.01);

    }
  }
}

