import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ImagingProjectService } from '../../../_services/imaging-project.service';
import { QualityControlService } from '../../../_services/quality-control.service';
import { DataUploadService } from '../../../_services/data-upload.service';
import { UserService } from '../../../_services/user.service';
import { SiteConfigService } from '../../../_services/site-config.service';
import { PatientService } from '../../../_services/patient.service';
import { QueriesService } from '../../../_services/queries.service';

import * as _moment from 'moment';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from '../../../core/data-management/actions/projects.action';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { RefreshAPIService } from 'src/app/_services/refresh-api.service';
import { takeUntil } from 'rxjs/operators';
import {formatDate} from "@angular/common";

const moment = _moment;

export interface PeriodicElement {
  id: number;
  uploadDate: number;
  expectedVisitDate: number;
  series: any;
  visit: any;
}

@Component({
  selector: 'app-data-upload-list',
  templateUrl: './qctask-list.component.html',
  styleUrls: ['./qctask-list.component.css']
})
export class QCTaskListComponent implements OnInit {
  currentImageProjectId: number;
  dataSource: any;
  detailedData = [];
  displayedColumns: string[] = ['id', 'status', 'siteCode', 'patientCode', 'visitName', 'scanDate', 'uploadDate', 'qcDate', 'queries'];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  showModalSpinnerLoadingData = false;
  protected unsubscribe = new Subject<void>();
  refreshAPISubscription: Subscription;

  constructor(
    private imagingProjectSerivce: ImagingProjectService,
    private qualityControlService: QualityControlService,
    private dataUploadService: DataUploadService,
    private userService: UserService,
    private siteConfigService: SiteConfigService,
    private patientService: PatientService,
    private queriesService: QueriesService,
    private router: Router,
    private route: ActivatedRoute,
    private toastyService: ToastyService,
    private store: Store,
    private refreshAPIService: RefreshAPIService
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Pending QCs'));
    const project = JSON.parse(localStorage.getItem('project'));
    this.qualityControlService.clearPateintTaskList();
    this.currentImageProjectId = project.id;

    this.loadQcTaskData();

    // refresh APIs
    this.refreshAPISubscription = this.refreshAPIService.refreshAPIData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          if(response.componentUrl = this.router.url) {
            this.loadQcTaskData();
          }
        }
    });
  }

  loadQcTaskData() {
    this.detailedData = [];
    this.showModalSpinnerLoadingData = true;

    forkJoin([
      this.qualityControlService.getQCUploadTaskList(this.currentImageProjectId),
      this.dataUploadService.getUploadGeneralInfo(this.currentImageProjectId),
      this.queriesService.getQueriesByStudyId(this.currentImageProjectId),
      this.siteConfigService.getSiteConfigsByStudyIdForDashboard(this.currentImageProjectId),
    ])
    .subscribe(([qcUploadTasks, uploadGeneralInfo, queries, siteConfigs]) => {
      const listQC = qcUploadTasks['data'] || [];
      let usersIds = [];
      let visitConfigs = [];
      listQC.forEach(qcItem => {
        if (qcItem.qcUserId != null) {
          usersIds.push(qcItem.qcUserId);
        }
        visitConfigs.push(qcItem.visitConfigId);
      });
      usersIds = Array.from(new Set(usersIds));
      visitConfigs = Array.from(new Set(visitConfigs));

      const uploadsData = uploadGeneralInfo['data'] || [];
      const queriesData = queries['data'] || [];
      const siteConfigsData = siteConfigs.data || [];

      forkJoin([
        this.imagingProjectSerivce.getStudy(this.currentImageProjectId),
        this.imagingProjectSerivce.getScanDatesByVisitConfigIds(visitConfigs),
        this.userService.getByListIds(usersIds),
      ])
      .subscribe(([imageProjectResponse, scanDatesRes, usersInfoRes]) => {
        const study = imageProjectResponse.data;
        const scanDates = scanDatesRes['data'] || [];
        const userslist = usersInfoRes['data'] || [];

        try {
          this.detailedData = this.createDetailedData(listQC, uploadsData, study, userslist, queriesData, siteConfigsData, scanDates);
          this.setTableData();
        } catch (err) {
          console.error(err);
          this.showModalSpinnerLoadingData = false;
          const options = new ToastOptions();
          options.title = 'Error';
          options.msg = 'Something went wrong when populaating table with data...';

          this.showModalSpinnerLoadingData = false;
          this.toastyService.error(options);
        }

      }, (err) => {
        // @TODO generic error handling should be implemented via interceptors, remove this piece after
        console.error(err);
        this.showModalSpinnerLoadingData = false;
        const options = new ToastOptions();
        options.title = 'Error';
        options.msg = 'Something went wrong...';

        this.showModalSpinnerLoadingData = false;
        this.toastyService.error(options);
      });
    }, (err) => {
      console.error(err);
      // @TODO generic error handling should be implemented via interceptors, remove this piece after
      this.showModalSpinnerLoadingData = false;
      const options = new ToastOptions();
      options.title = 'Error';
      options.msg = 'Something went wrong...';

      this.showModalSpinnerLoadingData = false;
      this.toastyService.error(options);
    });
  }

  createDetailedData(listQC, uploadsData, study, userslist, queriesData, siteConfigsData, scanDates) : any[] {
    const detailedData = [];

    listQC.forEach(qcItem => {
      const generalInfo = uploadsData.filter(uploadItem => uploadItem.id === qcItem.generalInfoId);
      const userInfo = userslist.find(user => user.id === qcItem.qcUserId);
      const queriesEdcf = queriesData.find(q => q.qcTaskId === qcItem.id);

      const gInfo = generalInfo && generalInfo.length > 0 ? generalInfo[generalInfo.length - 1] : null;

      const patientId = gInfo?.patientId || qcItem.patientId;
      const visitConfigId = gInfo?.visitConfigId || qcItem.visitConfigId;

      const site = siteConfigsData.find(s => s.patients.find(p => p.id === patientId) != null);
      const patient = site.patients.find(p => p.id === patientId)
      const visit = patient.visits.find(v => v.visitConfigId === visitConfigId)

      const scanDate = !!scanDates[visitConfigId] ? formatDate(scanDates[visitConfigId], 'dd/MM/yyyy', 'en-GB', 'UTC') : '';
      const uploadDate = !!(gInfo?.created) ? moment(gInfo.created).format('DD/MM/YYYY HH:mm:ss') : '';
      const qcDate = !!qcItem.qcDate ? moment(qcItem.qcDate).format('DD/MM/YYYY') : '';

      let qct = {
        id: qcItem.id,
        numberInStudy: qcItem.numberInStudy,
        generalInfoId: gInfo?.id,
        imageAnalysisExecutionId: qcItem.imageAnalysisExecutionId,
        status: qcItem.status,
        studyId: this.currentImageProjectId,
        studyName: study?.name,
        siteConfigId: site?.id, // id - this is site config id value
        siteId: null,
        siteCode: site?.siteCode,
        patientId: patientId,
        patientCode: patient?.patientCode,
        visitConfigId: visitConfigId,
        visitName: visit?.visitName,
        modalities: qcItem.modality,
        uploadDate: uploadDate,
        scanDate: scanDate,
        qcUserId: qcItem.qcUserId,
        qcUserFullName: userInfo != null ? userInfo.firstName + ' ' + userInfo.lastName : '',
        qcDate: qcDate,
        queriesId: queriesEdcf != null ? queriesEdcf.id : null,
      };

      detailedData.push(qct);
    });

    /*const groupedData : any[] = Object.values(detailedData.reduce((accumulator, x) => {
      (accumulator[x.visitConfigId] = accumulator[x.visitConfigId] || []).push(x);
      return accumulator;
    }, {}));

    // take last upload for the same visit
    return groupedData.map(group => group.reduce((a, b) => a.id > b.id ? a : b))
    // sort by newest upload to oldest
      .sort((a, b) => a.id > b.id ? -1 : 1);
      */
     return detailedData.sort((a, b) => a.id > b.id ? -1 : 1);
  }

  setTableData() {
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.detailedData);

    if (this.dataSource != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    this.showModalSpinnerLoadingData = false;
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  clickTableCell(element) {
    localStorage.setItem('qc.selectTask', JSON.stringify(element));
    const patientTasks = this.dataSource.data.filter(task => task.patientCode === element.patientCode);
    this.qualityControlService.fillPateintTaskList(patientTasks);
    this.router.navigate(['/imagingproject/qc/details']);
  }

  clickTableCellEdcf(element) {
    if (element.queriesId != null) {
      const edcf = {
        id: element.queriesId,
      };
      localStorage.setItem('query.edcf', JSON.stringify(edcf));
      localStorage.setItem('query.edcf.fromQcTaskList', JSON.stringify(true));
      this.router.navigate(['/queries/edcf/detail']);
    }
  }

  private getQcDate(qcItem) {
    return !!qcItem.qcDate ? moment(qcItem.qcDate).format('DD/MM/YYYY') : '';
  }

  ngOnDestroy(): void {
    if (this.refreshAPISubscription) {
      this.refreshAPIService.clearRefreshAPIData();
      this.refreshAPISubscription.unsubscribe();
    }
  }
}
