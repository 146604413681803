import {
  VisitModalityConfiguratorDialogComponent
} from './modules/ImagingProject/ImagingProjectReading/visit-modality-configurator-dialog/visit-modality-configurator-dialog.component';
import {LoginGuard} from './_guards/login.guard';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {ToastyModule} from 'ng2-toasty';

import {routing} from './app.routing';
import {environment} from 'src/environments/environment';

import {JwtModule} from '@auth0/angular-jwt';
import {LoginComponent} from './modules/login';
import {
  ActionManagerService,
  AuthenticationService,
  DataUploadService,
  DicomHelperService,
  DicomLoaderService,
  EventService,
  LeadToolsActionManagerService,
  LeadToolsAuthenticationService,
  LeadToolsDicomHelperService,
  LeadToolsDicomLoaderService,
  LeadToolsObjectRetrieveService,
  LeadToolsObjectStoreService,
  LeadToolsOverlayManagerService,
  LeadToolsQueryArchiveService,
  LeadToolsSeriesManagerService,
  ObjectRetrieveService,
  ObjectStoreService,
  OverlayManagerService,
  QueryArchiveService,
  SeriesManagerService,
  UserService,
  ViewerAuthenticationService
} from './_services';
import {DynamikaWebSocket} from './_services/dynamika-web-socket';
import {WebSocketService} from './_services/web-socket.service';

import {AlertComponent} from './_directives';
import {AuthGuard} from './_guards';

import {MatIconModule} from '@angular/material/icon';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatNativeDateModule} from '@angular/material/core';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DemoMaterialModule} from './material-module';
import {
  ImagingProjectListComponent
} from './modules/ImagingProject/imaging-project-list/imaging-project-list.component';
import {UploadListComponent} from './modules/DataUpload/upload-list/upload-list.component';
import {UploadVisitFormComponent} from './modules/DataUpload/upload-visit-form/upload-visit-form.component';
import {UploadDialogComponent} from './modules/DataUpload/upload-dialog/upload-dialog.component';
import {DragDropFilesComponent} from './modules/DataUpload/drag-drop-files/drag-drop-files.component';
import {VisitStatusComponent} from './modules/DataUpload/visit-status/visit-status.component';
import {EdtfEditComponent} from './modules/DataUpload/edtf-edit/edtf-edit.component';
import {NewPatientComponent} from './modules/Patient/new-patient/new-patient.component';
import {
  ImagingProjectCreateComponent
} from './modules/ImagingProject/imaging-project-create/imaging-project-create.component';
import {
  ImagingProjectSponsorComponent
} from './modules/ImagingProject/imaging-project-sponsor/imaging-project-sponsor.component';
import {
  ImagingProjectSiteComponent
} from './modules/ImagingProject/imaging-project-site/imaging-project-site.component';
import {
  ImagingProjectImagingComponent
} from './modules/ImagingProject/imaging-project-imaging/imaging-project-imaging.component';
import {
  ImagingProjectSequenceComponent
} from './modules/ImagingProject/imaging-project-sequence/imaging-project-sequence.component';
import {
  ImagingProjectEndpointComponent
} from './modules/ImagingProject/imaging-project-endpoint/imaging-project-endpoint.component';
import {
  ImagingProjectUserComponent
} from './modules/ImagingProject/imaging-project-user/imaging-project-user.component';
import {
  ImagingProjectRoleComponent
} from './modules/ImagingProject/imaging-project-role/imaging-project-role.component';
import {
  ImagingProjectActivityComponent
} from './modules/ImagingProject/imaging-project-activity/imaging-project-activity.component';
import {MonthDatapickerComponent} from './components/datapickers/month-datapicker/month-datapicker.component';
import {
  ImagingProjectScannerComponent
} from './modules/ImagingProject/imaging-project-scanner/imaging-project-scanner.component';
import {QCTaskListComponent} from './modules/QualityControl/qctask-list/qctask-list.component';
import {QualityControlService} from './_services/quality-control.service';
import {ViewerData} from './_services/share-viewer-data.service';
import {QCDetailsComponent} from './modules/QualityControl/qcdetails/qcdetails.component';
import {
  QCDicomchecksDialogComponent
} from './modules/QualityControl/qcdicomchecks-dialog/qcdicomchecks-dialog.component';
import {
  QcChangeVisitDialogComponent
} from './modules/QualityControl/qc-change-visit-dialog/qc-change-visit-dialog.component';
import {MessageDialogComponent} from './components/controls/message-dialog/message-dialog.component';
import {ViewerThumbnailComponent} from './modules/Viewer/viewer-thumbnail/viewer-thumbnail.component';
import {StatusIconComponent} from './components/controls/status-icon/status-icon.component';
import {EdtfViewComponent} from './components/edtf/edtf-view/edtf-view.component';
import {ChangePasswordComponent} from './modules/UserManagement/change-password/change-password.component';
import {ResetPasswordComponent} from './modules/UserManagement/reset-password/reset-password.component';
import {GridsterModule} from 'angular-gridster2';
import {NewEdcfComponent} from './modules/Queries/edcf/new-edcf/new-edcf.component';
import {EdcfListComponent} from './modules/Queries/edcf/edcf-list/edcf-list.component';
import {EdcfDetailComponent} from './modules/Queries/edcf/edcf-detail/edcf-detail.component';
import {HttpModule} from '@angular/http';
import {NgIdleKeepaliveModule} from '@ng-idle/keepalive';
import {ViewerChart} from './components/charts/viewer-chart/viewer-chart.component';
import {GoogleChartsModule} from 'angular-google-charts';
import {
  QCModalitysubmitDialogComponent
} from './modules/QualityControl/qcmodalitysubmit-dialog/qcmodalitysubmit-dialog.component';
import {
  QCPatienteditDialogComponent
} from './modules/QualityControl/qcpatientedit-dialog/qcpatientedit-dialog.component';
import {
  QCPatientBirthDateEditDialogComponent
} from './modules/QualityControl/qcpatientbirthdateedit-dialog/qcpatientbirthdateedit-dialog.component';
import {StudySequenceLabelComponent} from './components/controls/study-sequence-label/study-sequence-label.component';
import {
  EmailDistributionListComponent
} from './components/controls/email-distribution-list/email-distribution-list.component';
import {DashboardContainerComponent} from './modules/Dashboard/dashboard-container/dashboard-container.component';
import {
  DownloadReportPageComponent
} from './modules/Reading/Download/download-report-page/download-report-page.component';
import {
  OverallStudyFinderComponent
} from './modules/Reading/OverallStudyFinder/overall-study-finder/overall-study-finder.component';
import {
  ImagingProjectReadingListComponent
} from './modules/ImagingProject/ImagingProjectReading/imaging-project-reading-list/imaging-project-reading-list.component';
import {
  ImagingProjectReadingSelectorComponent
} from './modules/ImagingProject/ImagingProjectReading/imaging-project-reading-selector/imaging-project-reading-selector.component';
import {
  ImagingProjectReadingKLBasicComponent
} from './modules/ImagingProject/ImagingProjectReading/KandL/imaging-project-reading-klbasic/imaging-project-reading-klbasic.component';
import {
  ImagingProjectReadingKLAdjudicationComponent
} from './modules/ImagingProject/ImagingProjectReading/KandL/imaging-project-reading-kladjudication/imaging-project-reading-kladjudication.component';
import {
  ImagingProjectReadingKLVersionSelectorComponent
} from './modules/ImagingProject/ImagingProjectReading/KandL/imaging-project-reading-klversion-selector/imaging-project-reading-klversion-selector.component';
import {
  ImagingProjectKLFlowDialogComponent
} from './modules/ImagingProject/ImagingProjectReading/KandL/imaging-project-klflow-dialog/imaging-project-klflow-dialog.component';

import {
  ImagingProjectReadingIfComponent
} from './modules/ImagingProject/ImagingProjectReading/IF/imaging-project-reading-if/imaging-project-reading-if.component';
import {MaintenancePageComponent} from './modules/Maintenance/maintenance-page/maintenance-page.component';
import {SeriesNavigatorComponent} from './components/controls/series-navigator/series-navigator.component';
import {TimeFormatPipe} from './_directives/time-format.pipe';
import {
  ImagingProjectReadingPsamrisBasicComponent
} from './modules/ImagingProject/ImagingProjectReading/PSAMRIS/imaging-project-reading-psamris-basic/imaging-project-reading-psamris-basic.component';
import {
  OperationDashboardComponent
} from './modules/OperationsDashboard/operation-dashboard-component/operation-dashboard.component';
import {BatchListComponent} from './modules/OperationsDashboard/BatchLogic/batch-list/batch-list.component';
import {BatchComponent} from './modules/OperationsDashboard/BatchLogic/batch/batch.component';
import {
  BatchReaderDialogComponent
} from './modules/OperationsDashboard/BatchLogic/batch-reader-dialog/batch-reader-dialog.component';
import {ProjectDetailsComponent} from './modules/OperationsDashboard/project-details/project-details.component';
import {ReadingsListComponent} from './modules/OperationsDashboard/readings-list/readings-list.component';
import {
  BatchReadingsSelectorComponent
} from './modules/OperationsDashboard/BatchLogic/batch-readings-selector/batch-readings-selector.component';
import {
  ImagingProjectReadingPsamrisAdjudicationComponent
} from './modules/ImagingProject/ImagingProjectReading/PSAMRIS/imaging-project-reading-psamris-adjudication/imaging-project-reading-psamris-adjudication.component';
import {
  ImagingProjectReadingPsamrisWrapperComponent
} from './modules/ImagingProject/ImagingProjectReading/PSAMRIS/imaging-project-reading-psamris-wrapper/imaging-project-reading-psamris-wrapper.component';
import {
  AutoCreationBatchConfigDialogComponent
} from './components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsModule} from '@ngxs/store';
import {AuthInfoState} from './core/data-management/states/auth-info.state';
import {CurrentUserInfoState} from './core/data-management/states/user-info.state';

import {ViewerCoreLtComponent, ViewerToolbarComponent} from './modules/Viewer';
import {LeadToolsUtils, Utils} from './_services/leadtools/lead-tools-utils';

import {
  WindowLevelDialogComponent
} from './modules/Viewer/viewer-core-lt/window-level-dialog/window-level-dialog.component';
import {
  CalibrateRulerDialogComponent
} from './modules/Viewer/viewer-core-lt/calibrate-ruler-dialog/calibrate-ruler-dialog.component';
import {
  AnnotationSaveDialogComponent
} from './modules/Viewer/viewer-core-lt/annotation-save-dialog/annotation-save-dialog.component';
import {
  AnnotationLoadDialogComponent
} from './modules/Viewer/viewer-core-lt/annotation-load-dialog/annotation-load-dialog.component';
import {
  ImagingProjectReadingAtyrWrapperComponent
} from './modules/ImagingProject/ImagingProjectReading/ATYR/imaging-project-reading-atyr-wrapper/imaging-project-reading-atyr-wrapper.component';
import {
  ImagingProjectReadingAtyrBasicComponent
} from './modules/ImagingProject/ImagingProjectReading/ATYR/imaging-project-reading-atyr-basic/imaging-project-reading-atyr-basic.component';
import {
  ROILabellingDialogComponent
} from './modules/Viewer/viewer-core-lt/roi-labelling-dialog/roi-labelling-dialog.component';
import {
  ROISettingsDialogComponent
} from './modules/Viewer/viewer-core-lt/roi-settings-dialog/roi-settings-dialog.component';
import {
  ROICopyDeleteDialogComponent
} from './modules/Viewer/viewer-core-lt/roi-copy-delete-dialog/roi-copy-delete-dialog.component';
import {
  CustomLayoutDialogComponent
} from './modules/Viewer/viewer-core-lt/custom-layout-dialog/custom-layout-dialog.component';
import {
  ImagingProjectReadingWbmriWrapperComponent
} from './modules/ImagingProject/ImagingProjectReading/WBMRI/imaging-project-reading-wbmri-wrapper/imaging-project-reading-wbmri-wrapper.component';
import {
  ImagingProjectReadingWbmriBasicComponent
} from './modules/ImagingProject/ImagingProjectReading/WBMRI/imaging-project-reading-wbmri-basic/imaging-project-reading-wbmri-basic.component';
import {
  ImagingProjectReadingWbmriAdjudicationComponent
} from './modules/ImagingProject/ImagingProjectReading/WBMRI/imaging-project-reading-wbmri-adjudication/imaging-project-reading-wbmri-adjudication.component';
import {AuthErrorInterceptor} from './core/interceptors/auth-error-interceptor';
import {ProjectsState} from './core/data-management/states/projects.state';
import {
  ImagingProjectReadingMoaksWrapperComponent
} from './modules/ImagingProject/ImagingProjectReading/MOAKS/imaging-project-reading-moaks-wrapper/imaging-project-reading-moaks-wrapper.component';
import {
  ImagingProjectReadingMoaksBasicComponent
} from './modules/ImagingProject/ImagingProjectReading/MOAKS/imaging-project-reading-moaks-basic/imaging-project-reading-moaks-basic.component';
import {MoaksBasicFormComponent} from './modules/Reading/MOAKS/moaks-basic-form/moaks-basic-form.component';
import {
  MoaksBmlScoringFormComponent
} from './modules/Reading/MOAKS/scoring-forms/moaks-bml-scoring-form/moaks-bml-scoring-form.component';
import {
  MoaksCartilageScoringFormComponent
} from './modules/Reading/MOAKS/scoring-forms/moaks-cartilage-scoring-form/moaks-cartilage-scoring-form.component';
import {
  MoaksOsteophytesScoringFormComponent
} from './modules/Reading/MOAKS/scoring-forms/moaks-osteophytes-scoring-form/moaks-osteophytes-scoring-form.component';
import {
  MoaksMeniscalScoringFormComponent
} from './modules/Reading/MOAKS/scoring-forms/moaks-meniscal-scoring-form/moaks-meniscal-scoring-form.component';
import {
  MoaksPeriarticularScoringFormComponent
} from './modules/Reading/MOAKS/scoring-forms/moaks-periarticular-scoring-form/moaks-periarticular-scoring-form.component';
import {
  ImagingProjectReadingCandenSparccWrapperComponent
} from './modules/ImagingProject/ImagingProjectReading/CANDEN-SPARCC/imaging-project-reading-canden-sparcc-wrapper/imaging-project-reading-canden-sparcc-wrapper.component';
import {
  ImagingProjectReadingCandenSparccBasicComponent
} from './modules/ImagingProject/ImagingProjectReading/CANDEN-SPARCC/imaging-project-reading-canden-sparcc-basic/imaging-project-reading-canden-sparcc-basic.component';
import {
  AnatomySelectorComponent
} from './modules/ImagingProject/ImagingProjectReading/anatomy-selector/anatomy-selector.component';
import {ClickDebouncerDirective} from './_directives/ClickDebouncerDirective';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {
  ImagingProjectReadingKoalaComponent
} from './modules/ImagingProject/ImagingProjectReading/KandL/imaging-project-reading-koala/imaging-project-reading-koala.component';
import {
  ImagingProjectReadingDemriqComponent
} from './modules/ImagingProject/ImagingProjectReading/DEMRIQ/imaging-project-reading-demriq.component';
import {
  ImageViewerConfigDialogComponent
} from './components/controls/image-viewer-config-dialog/image-viewer-config-dialog.component';
import {
  ImagingProjectReadingNovadipComponent
} from './modules/ImagingProject/ImagingProjectReading/Novadip5/imaging-project-reading-novadip.component';
import {Novadip5Component} from './modules/Reading/NOVADIP5/novadip5.component';
import {ReadingFormComponent} from './modules/Reading/ReadingForm/reading-form/reading-form.component';
import {ReadingFormBlockComponent} from './modules/Reading/ReadingForm/reading-form-block/reading-form-block.component';
import {ReadingFormScoringComponent} from './modules/Reading/reading-form-scoring.component';
import {
  InnerSeriesNavigatorComponent
} from './components/controls/inner-series-navigator/inner-series-navigator.component';
import {AngularSplitModule} from 'angular-split';
import {ColorPickerModule} from 'ngx-color-picker';
import {
  DicomInformationDialogComponent
} from './modules/Viewer/viewer-core-lt/dicom-information-dialog/dicom-information-dialog.component';
import {PatientListComponent} from './modules/OperationsDashboard/patient-list/patient-list.component';
import {SiteListComponent} from './modules/OperationsDashboard/site-list/site-list.component';
import {KlLevel1ResultComponent} from './modules/Reading/KL/kl-level1-result/kl-level1-result.component';
import {KlFormComponent} from './modules/Reading/KL/kl-form/kl-form.component';
import {
  ImagingProjectReadingLuganoComponent
} from './modules/ImagingProject/ImagingProjectReading/LUGANO/imaging-project-reading-lugano/imaging-project-reading-lugano.component';
import {
  ImagingProjectReadingLuganoWrapperComponent
} from './modules/ImagingProject/ImagingProjectReading/LUGANO/imaging-project-reading-lugano-wrapper/imaging-project-reading-lugano-wrapper.component';
import {MatTableModule} from '@angular/material/table';
import {
  ReadingLevelOptionsDialogComponent
} from './components/controls/reading-level-options-dialog/reading-level-options-dialog.component';
import {
  ReadingTypeSequentialOptionsDialogComponent
} from './components/controls/reading-type-sequential-options-dialog/reading-type-sequential-options-dialog.component';
import {
  ImagingProjectReadingMRANOComponent
} from './modules/ImagingProject/ImagingProjectReading/mRANO/imaging-project-reading-mrano.component';
import {
  RanoImageViewerConfigDialogComponent
} from './components/controls/rano-image-config-dialog/rano-image-config-dialog.component';
import {
  ImagingProjectReadingRecistWrapperComponent
} from './modules/ImagingProject/ImagingProjectReading/RECIST/imaging-project-reading-recist-wrapper/imaging-project-reading-recist-wrapper.component';
import {
  ImagingProjectReadingRecistComponent
} from './modules/ImagingProject/ImagingProjectReading/RECIST/imaging-project-reading-recist/imaging-project-reading-recist.component';
import {LuganoComponent} from './modules/Reading/Lugano/lugano.component';
import {RecistComponent} from './modules/Reading/RECIST/recist.component';
import {
  MranoEligibilityFormComponent
} from './modules/Reading/MRANO/mrano-eligibility-form/mrano-eligibility-form.component';
import {
  MranoHistoricalFormComponent
} from './modules/Reading/MRANO/mrano-historical-form/mrano-historical-form.component';
import {MRANOFormComponent} from './modules/Reading/MRANO/mrano-form.component';
import {LesionDialogFormComponent} from './components/controls/lesion-dialog-form/lesion-dialog-form.component';
import {BrainLesionsTableComponent} from './modules/Reading/MRANO/brain-lesions-table/brain-lesions-table.component';
import {
  ObjectConvertDialogComponent
} from './components/controls/object-convert-dialog/object-convert-dialog.component';
import {VoiListComponent} from './modules/Reading/MRANO/voi-list/voi-list.component';
import {
  ReadingDetailsDialogComponent
} from './modules/OperationsDashboard/reading-details-dialog/reading-details-dialog.component';
import {
  ConfirmTerminationDialogComponent
} from './modules/OperationsDashboard/reading-details-dialog/confirm-termination-dialog/confirm-termination-dialog.component';
import {
  PrimaryBodyLocationListComponent
} from './modules/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-list/primary-body-location-list.component';
import {
  PrimaryBodyLocationDialogComponent
} from './modules/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-dialog/primary-body-location-dialog.component';
import {
  ConfirmationBodyLocationDialogComponent
} from './modules/ImagingProject/ImagingProjectBodypartDataset/confirmation-body-location-dialog/confirmation-body-location-dialog.component';
import {
  PrimaryBodySublocationDialogComponent
} from './modules/ImagingProject/ImagingProjectBodypartDataset/primary-body-sublocation-dialog/primary-body-sublocation-dialog.component';
import {
  PrimaryBodyLocationWithSublocationListDialogComponent
} from './modules/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-with-sublocation-list-dialog/primary-body-location-with-sublocation-list-dialog.component';
import {
  PrimaryBodySublocationStatusDialogComponent
} from './modules/ImagingProject/ImagingProjectBodypartDataset/primary-body-sublocation-status-dialog/primary-body-sublocation-status-dialog.component';
import {
  PrimaryBodyLocationStatusDialogComponent
} from './modules/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-status-dialog/primary-body-location-status-dialog.component';
import {
  ImagingProjectCohortsListComponent
} from './modules/ImagingProject/ImagingProjectCohorts/imaging-project-cohorts-list/imaging-project-cohorts-list.component';
import {PsamrisFormComponent} from './modules/Reading/PsAMRIS/psamris-form/psamris-form.component';
import {
  PsamrisLevel1ResultComponent
} from './modules/Reading/PsAMRIS/psamris-level1-result/psamris-level1-result.component';
import {
  ImageComparisonDialogComponent
} from './modules/Reading/PsAMRIS/psamris-level1-result/dialog/image-comparison-dialog.component';
import {HeelEnthesitisBasicFormComponent} from './modules/Reading/HEEL/heel-enthesitis-basic-form.component';
import {
  ImaginProjectReadingHeelComponent
} from './modules/ImagingProject/ImagingProjectReading/HEEL-ENTHESITIS/imaging-project-reading-heel.component';
import {LesionsListDialogComponent} from './modules/Reading/MRANO/lesions-list-dialog/lesions-list-dialog.component';
import {WbmriFormComponent} from './modules/Reading/WBMRI/wbmri-form/wbmri-form.component';
import {WbmriLevel1ResultComponent} from './modules/Reading/WBMRI/wbmri-level1-result/wbmri-level1-result.component';
import {
  WbmriImgCompareDialogComponent
} from './modules/Reading/WBMRI/wbmri-level1-result/wbmri-img-compare-dialog/wbmri-img-compare-dialog.component';
import {
  WebinarProjectListComponent
} from './modules/WebinarProject/webinar-project-list/webinar-project-list.component';
import {WebinarUploadListComponent} from './modules/WebinarProject/DataUpload/upload-list/upload-list.component';
import {
  WebinarProjectCreateComponent
} from './modules/WebinarProject/webinar-project-create/webinar-project-create.component';
import {WebinarQCTaskListComponent} from './modules/WebinarProject/QualityControl/qctask-list/qctask-list.component';
import {
  WebinarUploadVisitFormComponent
} from './modules/WebinarProject/DataUpload/upload-visit-form/upload-visit-form.component';
import {NewCaseComponent} from './modules/WebinarProject/Case/new-case/new-case.component';
import {CaseListComponent} from './modules/WebinarProject/Case/case-list/case-list.component';
import {QuestionFormComponent} from './modules/WebinarProject/Case/question-form/question-form.component';
import {WebinarComponent} from './modules/ImagingProject/ImagingProjectReading/webinar/webinar.component';
import {
  CandenSparccReadingFormComponent
} from './modules/Reading/CANDEN-SPARCC/canden-sparcc-reading-form/canden-sparcc-reading-form.component';
import {
  ImagingProjectCohortsDialogComponent
} from './modules/ImagingProject/ImagingProjectCohorts/imaging-project-cohorts-dialog/imaging-project-cohorts-dialog.component';
import {
  ImagingProjectCohortStatusDialogComponent
} from './modules/ImagingProject/ImagingProjectCohorts/imaging-project-cohort-status-dialog/imaging-project-cohort-status-dialog.component';
import {DEMRIQFromComponent} from './modules/Reading/DEMRIQ/demriq-form.component';
import {
  DemriqRegionLocationTableComponent
} from './modules/Reading/DEMRIQ/demriq-region-location-table/demriq-region-location-table.component';
import {DemriqRegionLocationDetailsDialogComponent} from './modules/Reading/DEMRIQ/demriq-region-location-details-dialog/demriq-region-location-details-dialog.component';
import {
  ImagingProjectReadingPSMAPETEligibilityComponent
} from './modules/ImagingProject/ImagingProjectReading/PSMA-PET-Eligibility/imaging-project-reading-psma-pet-eligibility.component';
import {PSMAPETFormComponent} from './modules/Reading/PSMA-PET/psma-pet-form.component';
import {PSMALesionTableComponent} from './modules/Reading/PSMA-PET/lesion-table/psma-lesion-table.component';
import {PSMALesionDialogComponent} from './modules/Reading/PSMA-PET/lesion-dialog/psma-lesion-dialog.component';
import {
  UnscheduledVisitDialogComponent
} from './modules/DataUpload/upload-list/unscheduled-visit-dialog/unscheduled-visit-dialog.component';
import {ReadingFormBaseComponent} from './modules/Reading/reading-form-base.component';
import {NoUploadDialogComponent} from './modules/QualityControl/no-upload-dialog/no-upload-dialog.component';
import {RoiFiltersPanelComponent} from './modules/Viewer/viewer-core-lt/roi-filters-panel/roi-filters-panel.component';
import {
  ImagingProjectAnonymizationComponent
} from './modules/ImagingProject/imaging-project-anonymization/imaging-project-anonymization.component';
import {DownloadMonitoringComponent} from './components/download-monitoring/download-monitoring.component';
import {
  DownloadMonitoringItemComponent
} from './components/download-monitoring/download-monitoring-item/download-monitoring-item.component';
import {DownloadService} from './_services/download.service';
import {IncidentalFindingsComponent} from './modules/Reading/IF/incidental-findings.component';
import {ScreenshotDialogComponent} from './modules/Reading/IF/screenshot-dialog/screenshot-dialog.component';
import {
  DynamicBrainLesionTableComponent
} from './modules/Reading/Oncology/dynamic-scoring-form/dynamic-brain-lesion-table/dynamic-brain-lesion-table.component';
import {
  DynamicScoringFormComponent
} from './modules/Reading/Oncology/dynamic-scoring-form/dynamic-scoring-form.component';
import {
  ImagingProjectReadingJswVersrionSelectorComponent
} from './modules/ImagingProject/ImagingProjectReading/JSW/imaging-project-reading-jsw-versrion-selector/imaging-project-reading-jsw-versrion-selector.component';
import {
  ImagingProjectReadingJswBasicComponent
} from './modules/ImagingProject/ImagingProjectReading/JSW/imaging-project-reading-jsw-basic/imaging-project-reading-jsw-basic.component';
import {
  OncologyLesionDialogComponent
} from './modules/Reading/Oncology/oncology-lesion-dialog/oncology-lesion-dialog.component';
import {
  MotionCorrectionPanelComponent
} from './modules/Viewer/viewer-core-lt/motion-correction-panel/motion-correction-panel.component';
import {JswReadingFormComponent} from './modules/Reading/JSW/jsw-reading-form/jsw-reading-form.component';
import {JswReadingKneeFormComponent} from './modules/Reading/JSW/jsw-reading-knee-form/jsw-reading-knee-form.component';
import {
  JswReadingFormBasicComponent
} from './modules/Reading/JSW/jsw-reading-form-basic/jsw-reading-form-basic.component';
import {
  JswReadingFormModerationComponent
} from './modules/Reading/JSW/jsw-reading-form-moderation/jsw-reading-form-moderation.component';
import {
  JswReadingUploadFilesDialogComponent
} from './modules/Reading/JSW/jsw-reading-upload-files-dialog/jsw-reading-upload-files-dialog.component';
import {
  OncologyResponseAssessmentDialogComponent
} from './modules/Reading/Oncology/oncology-response-assessment-dialog/oncology-response-assessment-dialog.component';
import {ImagesRepositoryComponent} from './modules/OperationsDashboard/images-repository/images-repository.component';
import {SupportService} from './_services/support.service';
import {
  SponsorSeriesListComponent
} from './modules/OperationsDashboard/sponsor-series-list-component/sponsor-series-list.component';
import {AppVersionsComponent} from './modules/app-versions/app-versions.component';
import {
  AuditTrailsProjectsListComponent
} from './modules/AuditTrails/projects-list/audit-trails-projects-list.component';
import {
  ImagingProjectCriteriaComponent
} from './modules/ImagingProject/imaging-project-criteria/imaging-project-criteria.component';
import {
  MultiselectAnswersDialogComponent
} from './modules/ImagingProject/ImagingProjectReading/IF/multiselect-answers-dialog/multiselect-answers-dialog.component';
import {VisitsStatusDialogComponent} from './modules/Reading/IF/visits-status-dialog/visits-status-dialog.component';
import {ReadingNavigatorComponent} from './modules/Reading/reading-navigator/reading-navigator.component';
import {
  UploadAdjFileDialogComponent
} from './modules/OperationsDashboard/upload-adj-file-dialog/upload-adj-file-dialog.component';
import {
  AdjudicationExternalConfigDialogComponent
} from './modules/OperationsDashboard/adjudication-external-config-dialog/adjudication-external-config-dialog.component';
import {
  AuditTrailsViewModeSelectorComponent
} from './modules/AuditTrails/view-mode-selector/audit-trails-view-mode-selector.component';
import {
  AuditTrailsPatientViewComponent
} from './modules/AuditTrails/audit-trails-patient-view/audit-trails-patient-view.component';
import {
  ImagingProjectReadingAdvancedAnalysisComponent
} from './modules/ImagingProject/ImagingProjectReading/Advanced-Analysis/imaging-project-reading-advanced-analysis/imaging-project-reading-advanced-analysis.component';
import {
  FtbEditDialogComponent
} from './modules/ImagingProject/ImagingProjectReading/Advanced-Analysis/FTB-edit-dialog/ftb-edit-dialog/ftb-edit-dialog.component';
import {
  AdvancedAnalysisReadingFormComponent
} from './modules/Reading/Advanced-Analysis/advanced-analysis-reading-form/advanced-analysis-reading-form.component';
import * as viewerResolvers from './modules/Viewer/resolvers';
import { MranoEfficacyDynamicScoringFormComponent } from './modules/Reading/Oncology/MranoEfficacy/mrano-efficacy-dynamic-scoring-form/mrano-efficacy-dynamic-scoring-form.component';
import { MranoEfficacyDynamicBrainLesionTableComponent } from './modules/Reading/Oncology/MranoEfficacy/mrano-efficacy-dynamic-scoring-form/mrano-efficacy-dynamic-brain-lesion-table/mrano-efficacy-dynamic-brain-lesion-table.component';
import { MranoEfficacyOncologyLesionDialogComponent } from './modules/Reading/Oncology/MranoEfficacy/mrano-efficacy-oncology-lesion-dialog/mrano-efficacy-oncology-lesion-dialog.component';
import { MranoEfficacyOncologyResponseAssessmentDialogComponent } from './modules/Reading/Oncology/MranoEfficacy/mrano-efficacy-oncology-response-assessment-dialog/mrano-efficacy-oncology-response-assessment-dialog.component';
import { MranoefficacyresponseoptionsComponent } from './modules/Reading/Oncology/MranoEfficacy/mrano-efficacy-oncology-response-assessment-dialog/mrano-efficacy-response-options/mrano-efficacy-response-options.component';
import { MranoEfficacyResponseCommentDialogComponent } from './modules/Reading/Oncology/MranoEfficacy/mrano-efficacy-oncology-response-assessment-dialog/mrano-efficacy-response-comment-dialog/mrano-efficacy-response-comment-dialog.component';
import { SelectCheckAllComponent } from './modules/DataUpload/upload-visit-form/select-check-all/select-check-all.component';
import { CustomInputDirective } from './_directives/custom-input.directive';
import { QcLateralitycorrectionDialogComponent } from './modules/QualityControl/qc-lateralitycorrection-dialog/qc-lateralitycorrection-dialog.component';
import { ImagingProjectReadingOarsiBasicComponent } from './modules/ImagingProject/ImagingProjectReading/OARSI/imaging-project-reading-oarsi-basic/imaging-project-reading-oarsi-basic.component';
import { OarsiReadingFormBasicComponent } from './modules/Reading/OARSI/oarsi-reading-form-basic/oarsi-reading-form-basic.component';
import { OasriReadingKneeFormComponent } from './modules/Reading/OARSI/oarsi-reading-knee-form/oasri-reading-knee-form/oasri-reading-knee-form.component';
import { ImagingProjectReadingGenantComponent } from './modules/ImagingProject/ImagingProjectReading/GENANT/imaging-project-reading-genant/imaging-project-reading-genant.component';
import { ImagingProjectReadingDxaComponent } from './modules/ImagingProject/ImagingProjectReading/DXA/imaging-project-reading-dxa.component';
import { GenantLevel1ReadingFormComponent } from './modules/Reading/GENANT/genant-level1-form/genant-level1-reading-form/genant-level1-reading-form.component';
import { GenantAdjudicationReadingFormComponent } from './modules/Reading/GENANT/genant-adjudication-form/genant-adjudication-reading-form.component';
import { DXAComponent } from './modules/Reading/DXA/dxa.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ImagingProjectReadingGuerbetComponent } from './modules/ImagingProject/ImagingProjectReading/GUERBET/imaging-project-reading-guerbet/imaging-project-reading-guerbet.component';
import { GuerbetComponent } from './modules/Reading/GUERBET/guerbet.component';
import {
  RegionLocationConfigDialogComponent
} from './modules/ImagingProject/ImagingProjectReading/DEMRIQ/region-location-config-dialog/region-location-config-dialog.component';
import {BulkUploadComponent} from './modules/ImagingProject/bulk-upload/bulk-upload.component';
import { QcPatientTasksDialogComponent } from './modules/QualityControl/qc-patient-tasks-dialog/qc-patient-tasks-dialog.component';
import { ImagingProjectReadingL3smiComponent } from './modules/ImagingProject/ImagingProjectReading/L3SMI/imaging-project-reading-l3smi/imaging-project-reading-l3smi.component';
import { L3SMIComponent } from './modules/Reading/l3-smi/l3-smi.component';
import { AtyrComponent } from './modules/Reading/ATYR/atyr.component';
import {MranoAdjudicationFormComponent} from './modules/Reading/MRANO/mrano-adjudication-form/mrano-adjudication-form.component';
import {RanoAdjudicationReadingFormComponent} from './modules/Reading/MRANO/rano-adjudication-form/rano-adjudication-reading-form.component';
import {SignDetailsComponent} from './modules/ReportSign/sign-details/sign-details.component';
import {ConfirmSigningDialogComponent} from './modules/ReportSign/confirm-signing-dialog/confirm-signing-dialog.component';
import {SignStudiesListComponent} from './modules/ReportSign/sign-studies-list/sign-studies-list.component';
import {SignStudyReportsListComponent} from "./modules/ReportSign/sign-study-reports-list/sign-study-reports-list.component";

import { ImagingProjectReadingMEPOComponent } from './modules/ImagingProject/ImagingProjectReading/MEPO/imaging-project-reading-mepo/imaging-project-reading-mepo.component';
import { MEPOReadingFormComponent } from './modules/Reading/MEPO/mepo-reading-form.component';
import {MoaksMocartScoringFormComponent} from "./modules/Reading/MOAKS/scoring-forms/moaks-mocart-scoring-form/moaks-mocart-scoring-form.component";

console.log('environment.blacklistedRoutes: ', environment.blacklistedRoutes);

export const tokenGetter = () => {
  return JSON.parse(localStorage.getItem('currentUser'));
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AlertComponent,
    ClickDebouncerDirective,
    ImagingProjectListComponent,
    UploadListComponent,
    UploadVisitFormComponent,
    UploadDialogComponent,
    QCDicomchecksDialogComponent,
    QcChangeVisitDialogComponent,
    MessageDialogComponent,
    DragDropFilesComponent,
    VisitStatusComponent,
    EdtfEditComponent,
    NewPatientComponent,
    ImagingProjectCreateComponent,
    ImagingProjectSponsorComponent,
    ImagingProjectSiteComponent,
    ImagingProjectImagingComponent,
    ImagingProjectSequenceComponent,
    ImagingProjectEndpointComponent,
    ImagingProjectUserComponent,
    ImagingProjectRoleComponent,
    ImagingProjectActivityComponent,
    MonthDatapickerComponent,
    ImagingProjectScannerComponent,
    QCTaskListComponent,
    QCDetailsComponent,
    VisitStatusComponent,
    UploadDialogComponent,
    ViewerThumbnailComponent,
    StatusIconComponent,
    EdtfViewComponent,
    ChangePasswordComponent,
    ResetPasswordComponent,
    NewEdcfComponent,
    EdcfListComponent,
    EdcfDetailComponent,
    ViewerChart,
    QCModalitysubmitDialogComponent,
    QCPatienteditDialogComponent,
    QCPatientBirthDateEditDialogComponent,
    StudySequenceLabelComponent,
    EmailDistributionListComponent,
    DashboardContainerComponent,
    DownloadReportPageComponent,
    OverallStudyFinderComponent,
    ImagingProjectReadingListComponent,
    ImagingProjectReadingSelectorComponent,
    ImagingProjectReadingKLBasicComponent,
    ImagingProjectReadingKLAdjudicationComponent,
    ImagingProjectReadingKLVersionSelectorComponent,
    ImagingProjectKLFlowDialogComponent,
    ImagingProjectReadingIfComponent,
    MaintenancePageComponent,
    SeriesNavigatorComponent,
    InnerSeriesNavigatorComponent,
    ImagingProjectReadingPsamrisBasicComponent,
    TimeFormatPipe,
    PsamrisFormComponent,
    ImageComparisonDialogComponent,
    OperationDashboardComponent,
    BatchListComponent,
    BatchComponent,
    BatchReaderDialogComponent,
    ViewerCoreLtComponent,
    RoiFiltersPanelComponent,
    ProjectDetailsComponent,
    ReadingsListComponent,
    ImagingProjectReadingPsamrisAdjudicationComponent,
    ImagingProjectReadingPsamrisWrapperComponent,
    AutoCreationBatchConfigDialogComponent,
    BatchReaderDialogComponent,
    BatchReadingsSelectorComponent,
    WindowLevelDialogComponent,
    CalibrateRulerDialogComponent,
    AnnotationSaveDialogComponent,
    AnnotationLoadDialogComponent,
    ImagingProjectReadingAtyrWrapperComponent,
    ImagingProjectReadingAtyrBasicComponent,
    ROILabellingDialogComponent,
    ROISettingsDialogComponent,
    ROICopyDeleteDialogComponent,
    CustomLayoutDialogComponent,
    ViewerToolbarComponent,
    ImagingProjectReadingWbmriWrapperComponent,
    ImagingProjectReadingWbmriBasicComponent,
    ImagingProjectReadingWbmriAdjudicationComponent,
    ImagingProjectReadingMoaksWrapperComponent,
    ImagingProjectReadingMoaksBasicComponent,
    MoaksBasicFormComponent,
    MoaksBmlScoringFormComponent,
    MoaksCartilageScoringFormComponent,
    MoaksOsteophytesScoringFormComponent,
    MoaksMeniscalScoringFormComponent,
    MoaksPeriarticularScoringFormComponent,
    MoaksMocartScoringFormComponent,
    AnatomySelectorComponent,
    ImagingProjectReadingCandenSparccWrapperComponent,
    ImagingProjectReadingCandenSparccBasicComponent,
    SpinnerComponent,
    ImagingProjectReadingKoalaComponent,
    ImagingProjectReadingDemriqComponent,
    ImageViewerConfigDialogComponent,
    WbmriFormComponent,
    WbmriLevel1ResultComponent,
    WbmriImgCompareDialogComponent,
    Novadip5Component,
    ReadingFormComponent,
    LuganoComponent,
    ReadingFormBlockComponent,
    ReadingFormScoringComponent,
    ImagingProjectReadingNovadipComponent,
    PatientListComponent,
    SiteListComponent,
    DicomInformationDialogComponent,
    ImagingProjectReadingLuganoComponent,
    ImagingProjectReadingLuganoWrapperComponent,
    ReadingLevelOptionsDialogComponent,
    ReadingTypeSequentialOptionsDialogComponent,
    ImagingProjectReadingMRANOComponent,
    RanoImageViewerConfigDialogComponent,
    KlLevel1ResultComponent,
    KlFormComponent,
    ImagingProjectReadingRecistWrapperComponent,
    ImagingProjectReadingRecistComponent,
    RecistComponent,
    ReadingDetailsDialogComponent,
    RecistComponent,
    MranoEligibilityFormComponent,
    MranoHistoricalFormComponent,
    MRANOFormComponent,
    MranoAdjudicationFormComponent,
    RanoAdjudicationReadingFormComponent,
    LesionDialogFormComponent,
    BrainLesionsTableComponent,
    ObjectConvertDialogComponent,
    VoiListComponent,
    LesionsListDialogComponent,
    ConfirmTerminationDialogComponent,
    PrimaryBodyLocationListComponent,
    PrimaryBodyLocationDialogComponent,
    ConfirmationBodyLocationDialogComponent,
    PrimaryBodySublocationDialogComponent,
    PrimaryBodyLocationWithSublocationListDialogComponent,
    PrimaryBodySublocationStatusDialogComponent,
    ImagingProjectCohortsListComponent,
    PrimaryBodyLocationStatusDialogComponent,
    ImagingProjectCohortsListComponent,
    ImagingProjectCohortsDialogComponent,
    PsamrisFormComponent,
    PsamrisLevel1ResultComponent,
    ImaginProjectReadingHeelComponent,
    HeelEnthesitisBasicFormComponent,
    CandenSparccReadingFormComponent,
    HeelEnthesitisBasicFormComponent,
    WebinarProjectListComponent,
    WebinarProjectCreateComponent,
    WebinarUploadListComponent,
    WebinarUploadVisitFormComponent,
    WebinarQCTaskListComponent,
    NewCaseComponent,
    CaseListComponent,
    QuestionFormComponent,
    WebinarComponent,
    ImagingProjectCohortStatusDialogComponent,
    DEMRIQFromComponent,
    DemriqRegionLocationTableComponent,
    DemriqRegionLocationDetailsDialogComponent,
    ImagingProjectReadingPSMAPETEligibilityComponent,
    PSMAPETFormComponent,
    PSMALesionTableComponent,
    PSMALesionDialogComponent,
    ReadingFormBaseComponent,
    PSMALesionDialogComponent,
    UnscheduledVisitDialogComponent,
    NoUploadDialogComponent,
    ImagingProjectAnonymizationComponent,
    DownloadMonitoringComponent,
    DownloadMonitoringItemComponent,
    ImagingProjectAnonymizationComponent,
    IncidentalFindingsComponent,
    ScreenshotDialogComponent,
    DynamicBrainLesionTableComponent,
    DynamicScoringFormComponent,
    ImagingProjectReadingJswVersrionSelectorComponent,
    ImagingProjectReadingJswBasicComponent,
    OncologyLesionDialogComponent,
    MotionCorrectionPanelComponent,
    ImagingProjectReadingJswBasicComponent,
    JswReadingFormComponent,
    JswReadingKneeFormComponent,
    JswReadingFormBasicComponent,
    JswReadingFormModerationComponent,
    JswReadingUploadFilesDialogComponent,
    OncologyResponseAssessmentDialogComponent,
    ImagesRepositoryComponent,
    SponsorSeriesListComponent,
    AppVersionsComponent,
    ImagingProjectCriteriaComponent,
    MultiselectAnswersDialogComponent,
    VisitModalityConfiguratorDialogComponent,
    VisitsStatusDialogComponent,
    UploadAdjFileDialogComponent,
    ReadingNavigatorComponent,
    AdjudicationExternalConfigDialogComponent,
    MranoEfficacyDynamicScoringFormComponent,
    MranoEfficacyDynamicBrainLesionTableComponent,
    MranoEfficacyOncologyLesionDialogComponent,
    MranoEfficacyOncologyResponseAssessmentDialogComponent,
    AuditTrailsProjectsListComponent,
    AuditTrailsViewModeSelectorComponent,
    AuditTrailsPatientViewComponent,
    SelectCheckAllComponent,
    MranoefficacyresponseoptionsComponent,
    MranoEfficacyResponseCommentDialogComponent,
    CustomInputDirective,
    QcLateralitycorrectionDialogComponent,
    ImagingProjectReadingOarsiBasicComponent,
    OarsiReadingFormBasicComponent,
    OasriReadingKneeFormComponent,
    ImagingProjectReadingGenantComponent,
    ImagingProjectReadingDxaComponent,
    GenantLevel1ReadingFormComponent,
    GenantAdjudicationReadingFormComponent,
    DXAComponent,
    ImagingProjectReadingAdvancedAnalysisComponent,
    FtbEditDialogComponent,
    AdvancedAnalysisReadingFormComponent,
    RegionLocationConfigDialogComponent,
    ImagingProjectReadingGuerbetComponent,
    GuerbetComponent,
    BulkUploadComponent,
    QcPatientTasksDialogComponent,
    ImagingProjectReadingL3smiComponent,
    L3SMIComponent,
    AtyrComponent,
    SignStudiesListComponent,
    SignStudyReportsListComponent,
    SignDetailsComponent,
    ConfirmSigningDialogComponent,
    ImagingProjectReadingMEPOComponent,
    MEPOReadingFormComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule, // Origami requires the Angular Forms module
    ToastyModule.forRoot(),
    AngularSplitModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.whitelistedDomains,
        blacklistedRoutes: environment.blacklistedRoutes,
      }
    }),
    HttpClientModule,
    MatIconModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    ReactiveFormsModule,
    routing,
    MatNativeDateModule,
    MatAutocompleteModule,
    DemoMaterialModule,
    GridsterModule,
    HttpModule,
    NgIdleKeepaliveModule.forRoot(),
    GoogleChartsModule.forRoot(),
    NgxsModule.forRoot([
      AuthInfoState,
      ProjectsState,
      CurrentUserInfoState
    ]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot({disabled: true}),
    ColorPickerModule,
    MatTableModule,
    MatTableModule,
    ScrollingModule
  ],
  entryComponents: [
    AppComponent,
    UploadDialogComponent,
    QCDicomchecksDialogComponent,
    QcChangeVisitDialogComponent,
    QCModalitysubmitDialogComponent,
    MessageDialogComponent,
    QCPatienteditDialogComponent,
    QCPatientBirthDateEditDialogComponent,
    ImagingProjectKLFlowDialogComponent,
    BatchReaderDialogComponent,
    AutoCreationBatchConfigDialogComponent
  ],
  providers: [
    AuthGuard,
    LoginGuard,
    AuthenticationService,
    UserService,
    DataUploadService,
    QualityControlService,
    ViewerData,
    LeadToolsUtils,
    Utils,
    DownloadService,
    SupportService,
    EventService,
    {provide: DynamikaWebSocket, useClass: WebSocketService},
    {provide: ViewerAuthenticationService, useClass: LeadToolsAuthenticationService},
    {provide: ObjectRetrieveService, useClass: LeadToolsObjectRetrieveService},
    {provide: ObjectStoreService, useClass: LeadToolsObjectStoreService},
    {provide: OverlayManagerService, useClass: LeadToolsOverlayManagerService},
    {provide: DicomHelperService, useClass: LeadToolsDicomHelperService},
    {provide: DicomLoaderService, useClass: LeadToolsDicomLoaderService},
    {provide: QueryArchiveService, useClass: LeadToolsQueryArchiveService},
    {provide: SeriesManagerService, useClass: LeadToolsSeriesManagerService},
    {provide: ActionManagerService, useClass: LeadToolsActionManagerService},
    {provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true},
    viewerResolvers.ViewerConfigResolver,
    viewerResolvers.StudyUserRolesResolver
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
