import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ReadingConfigFlexibleService, StudySequenceLabelService, StudyUserService} from 'src/app/_services';
import {ToastyService} from 'ng2-toasty';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {
  defaultBasicAutoBatchConfig,
  ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {FormMode} from '../../../../../core/constants/form-mode';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {PsamrisAdjudicationConfigModel} from '../../../../../_models/ImagingProject/PSAMRIS/psamris-adjudication-config-model';
import {PsamrisBasicConfigModel} from '../../../../../_models/ImagingProject/PSAMRIS/psamris-basic-config-model';
import {MatSelectChange} from '@angular/material/select';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {forkJoin} from 'rxjs';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
  selector: 'app-imaging-project-reading-psamris-basic',
  templateUrl: './imaging-project-reading-psamris-basic.component.html',
  styleUrls: ['./imaging-project-reading-psamris-basic.component.css']
})
export class ImagingProjectReadingPsamrisBasicComponent extends ImagingProjectReadingBasicComponent implements OnInit {
  @Output() public clickCancel = new EventEmitter<any>();
  @Output() public clickSubmit = new EventEmitter<any>();

  @Input() mode: FormMode;

  readonly formModes = FormMode;
  readonly readingTypes = ReadingType;

  _configModel: PsamrisBasicConfigModel = {
    endpointReadingVersion: 'basic',
    modalities: [],
    readingLevel: null,
    readingType: null,
    hideVisitHistory: null,
    featuresToScore: null,
    fingersToScore: null,
    regionsToScore: null,
    readers: [],
    readingVersion: ReadingVersion.PSAMRIS_BASIC,
    batchManagement: false,
    endpoint: null,
    anatomySelector: [],
    imageViewerConfiguration: 'Default (KL, IF, PsAMRIS)'
  };

  basicBatchConfig: AutoBatchConfigPayload = null;

  selectedReadingType: any;
  selectedVisitHistoryOption: any;
  selectedFeaturesToScore: any[];
  selectedFingersToScore: any;
  selectedRegionsToScore: any;
  selectedModalityType: ModalityModel[];
  selectedReadingLevel: any;

  @Input('configModel')
  set configModel(value: any) {
    if (value != null) {
      this._configModel = value;
      this.selectedReadingLevel = this._configModel.readingLevel;
      this.selectedModalityType = this._configModel.modalities;
      this.selectedReadingType = this._configModel.readingType;
      this.selectedVisitHistoryOption = this._configModel.hideVisitHistory;
      this.selectedFeaturesToScore = this._configModel.featuresToScore;
      this.selectedFingersToScore = this._configModel.fingersToScore;
      this.selectedRegionsToScore = this._configModel.regionsToScore;
    }
  }

  @Input() selectedConfig: FlexibleConfig<PsamrisAdjudicationConfigModel>;

  get configModel(): any {
    return this._configModel;
  }

  readingLevelField = new FormControl('', [Validators.required]);
  modalityTypeField = new FormControl('', [Validators.required]);
  readingTypeField = new FormControl('', [Validators.required]);
  hideVisitHistoryField = new FormControl('', [Validators.required]);
  batchManagementForBasicControl: FormControl = new FormControl('', [Validators.required]);
  imageViewerConfigurationControl: FormControl = new FormControl('Default (KL, IF, PsAMRIS)', [Validators.required]);

  features2ScoreField = new FormControl('', [Validators.required]);
  finger2ScoreField = new FormControl('', [Validators.required]);
  regions2ScoreField = new FormControl('', [Validators.required]);
  readersField = new FormControl('', [Validators.required]);
  anatomySelectorControl: FormControl = new FormControl('', [Validators.required]);

  studySequenceLabels: SequenceLabelModel[] = [];
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];

  dataSourceReaders: any;
  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];
  maxReaders = 20;

  readers: any[] = [];
  studyId: number;
  isFormValid = false;
  selectedReadersids = [];
  modalities: any [];
  selectedReaders = [];

  batchManagementForBasic = false;
  imageViewerConfiguration = 'Default (KL, IF, PsAMRIS)';

  constructor(private router: Router,
              private route: ActivatedRoute,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private studyUserService: StudyUserService,
              private toastyService: ToastyService,
              private studySequenceLabelService: StudySequenceLabelService,
              private flowViewer: MatDialog,
              private dialog: MatDialog,
              private batchLogicService: BatchLogicService) {
    super();
  }

  ngOnInit() {

    this.selectedReaders = [];
    this._configModel.readers.forEach(ri => {
      this.selectedReadersids.push(ri['id']);
    });


    this.selectedReadingLevel = 'Patient';
    this._configModel.readingLevel = this.selectedReadingLevel;
    this.selectedReadingType = 'parallel';
    this._configModel.readingType = this.selectedReadingType;
    this.selectedVisitHistoryOption = 'hide_scan_dates';
    this._configModel.hideVisitHistory = this.selectedVisitHistoryOption;

    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    forkJoin([
      this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId),
      this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId)
    ]).subscribe(([secLabelsResp, groupedModalitiesResp]) => {
      if (groupedModalitiesResp['data'] != null) {
        this.modalities = groupedModalitiesResp['data'];
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
      }

      if (this.mode === FormMode.EDIT) {
        this.selectedModalityType = this.modalities.filter(m => this.selectedConfig.config.modalities.some(sm => sm.name === m.name));
      }
      this.prepareSequenceLabels(secLabelsResp);

      this.studyUserService.getReadersByStudyId(this.studyId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.readers = response.data;
            const activeReader: any[] = [];
            this.readers.forEach(r => {

              if (this.selectedReadersids.includes(r.id)) {
                activeReader.push(r);
              }
            });

            this._configModel.readers = activeReader;
            this.dataSourceReaders = this._configModel.readers.sort(this.compareFirstNames);
            this.selectedReaders = this._configModel.readers;
            this.selectedReadersids = [];
            this._configModel.readers.forEach(r => {
              const cur = this.readers.indexOf(r);
              this.readers.splice(cur, 1);
            });
          }
        });
    });

    if (this.selectedConfig) {
      this.batchManagementForBasic = this.selectedConfig.config.batchManagement;
      this.loadBatchConfigs();
    }

  }

  loadBatchConfigs(): void {
    if (this.mode === FormMode.EDIT) {
      this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id).subscribe(response => {
        const batchConfigs = response.data;
        batchConfigs.forEach(c => {
          this.basicBatchConfig = c;
        });
      });
    }
  }

  prepareSequenceLabels(studySequenceResp) {
    this.studySequenceLabels = studySequenceResp.data;
    if (this.mode === FormMode.EDIT) {
      if (this._configModel.anatomySelector) {
        this.initSequenceLabels(this._configModel.anatomySelector);
      } else {
        this.initDefaultSequenceLabels();
      }
    } else {
      this.initDefaultSequenceLabels();
    }
  }

  initDefaultSequenceLabels() {
    this.availableSequenceLabels = this.getAvailableSequenceLabels();
    this.selectedSequenceLabels = this.availableSequenceLabels;
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels();
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  getAvailableSequenceLabels(): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const modality of this._configModel.modalities) {
      for (const label of modality.sequenceLabels) {
        configLabels.add(label);
      }
    }

    return this.studySequenceLabels
      .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
  }

  onReadingLevelChange(event: { value: any; }) {
    this._configModel.readingLevel = event.value;
    this.validateForm();
  }

  onModalityTypeChange(event: MatSelectChange) {
    this._configModel.modalities = event.value;
    this.availableSequenceLabels = (<ModalityModel[]>event.value).flatMap(m => m.sequenceLabels);
    this.selectedSequenceLabels = [...this.availableSequenceLabels];
    this.validateForm();
  }

  onReadingTypeChange(event: { value: any; }) {
    this._configModel.readingType = event.value;
    this.validateForm();
  }

  onHideVisitChoronolyChange(event) {
    this._configModel.hideVisitHistory = event.value;
    this.validateForm();
  }

  onFeaturesToScoreChange(event: { value: any[]; }) {
    this.selectedFeaturesToScore = event.value;
    this._configModel.featuresToScore = this.selectedFeaturesToScore;
    this.validateForm();
  }

  onFingersToScoreChange(event) {
    this._configModel.fingersToScore = event.value;
    this.validateForm();
  }

  onRegionsToScoreChange(event) {
    this._configModel.regionsToScore = event.value;
    this.validateForm();
  }

  onReaderSelectionChange(event: { value: any[]; }) {
    this.selectedReadersids = event.value;
  }

  onAddSelectedReaders() {

    if (this.selectedReadersids != null) {
      let counter = this._configModel.readers.length;
      this.selectedReadersids.forEach(readerId => {
        if (counter < this.maxReaders) {
          const reader = this.readers.find(r => r.id === readerId);
          if ((reader != null) && (!this._configModel.readers.includes(reader))) {
            // here we are certian that this reader is only added once!
            this._configModel.readers.push(reader);
            const index = this.readers.indexOf(reader);
            this.readers.splice(index, 1);
            this.validateForm();
          }
          counter += 1;
        }
      });

      this.selectedReadersids = [];
    }
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers.sort(this.compareFirstNames));
    this.selectedReaders = this._configModel.readers;
    if (this._configModel.readers.length > 0) {
      this.readersField.clearValidators();
    }
  }

  onBatchManagementSelected(event: MatSelectChange) {
    if (event.value === false) {
      this.basicBatchConfig.numberOfReadings = null;
    }
    this.validateForm();
  }

  deleteReader(reader: any) {
    const index = this._configModel.readers.indexOf(reader);
    this._configModel.readers.splice(index, 1);
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers.sort(this.compareFirstNames));
    this.selectedReadersids = this._configModel.readers;
    this.readers.unshift(reader);
    this.selectedReadersids = [];

    if (this._configModel.readers.length === 0) {
      this.readersField.validator = Validators.required;
    } else {
      this.readersField.clearValidators();
    }

    this.validateForm();
  }

  openAutoCreationBatchConfigDialog(readingType: ReadingType): void {
    const readonlyDialog = !this.batchManagementForBasic;
    let inputConfig: AutoBatchConfigPayload;
    const val: boolean = this.batchManagementForBasicControl.value;
    if (!val) {
      inputConfig = { ...defaultBasicAutoBatchConfig };
    } else {
      inputConfig = this.basicBatchConfig ? { ...this.basicBatchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        readingType: readingType,
        configInput: inputConfig,
        readonlyDialog: readonlyDialog
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.basicBatchConfig = result;
        this.validateForm();
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this.validateForm();
  }

  clickSubmitBtn() {
    const readers = [];
    this._configModel.readers.forEach(r => {
      readers.push({id: r.id});
    });

    this._configModel.endpointReadingVersion = 'basic';
    this._configModel.anatomySelector = this.selectedSequenceLabels;
    this._configModel.readers = readers;
    this._configModel.batchManagement = this.batchManagementForBasic;
    this._configModel.imageViewerConfiguration = 'Default (KL, IF, PsAMRIS)';
    this._configModel.anatomySelector = this.selectedSequenceLabels.map(l => {
      return {id: l.id};
    });
    this.logConfiguration();
    const imagingProjectSubmitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: this._configModel,
      basicBatchConfig: this.basicBatchConfig
    };
    this.clickSubmit.emit(imagingProjectSubmitEvent);
  }

  clickCancelBtn() {
    this.clickCancel.emit({result: 'cancel'});
  }

  validateForm(): boolean {
    this.isFormValid = !(!this._configModel.readingLevel ||
      this._configModel.modalities.length === 0 ||
      !this._configModel.readingType ||
      !this._configModel.hideVisitHistory ||
      !this.selectedSequenceLabels || this.selectedSequenceLabels.length === 0 ||
      !this._configModel.featuresToScore || this._configModel.featuresToScore.length === 0 ||
      !this._configModel.fingersToScore ||
      !this._configModel.regionsToScore ||
      this._configModel.readers.length < 1);
    if (this.batchManagementForBasic && (!this.basicBatchConfig || !this.basicBatchConfig.numberOfReadings)) {
      this.isFormValid = false;
    }
    return this.isFormValid;
  }

  compareFirstNames(f: any, s: any): number {
    const first = f.firstName.toLowerCase();
    const second = s.firstName.toLowerCase();
    if (first > second) {
      return 1;
    }
    if (first < second) {
      return -1;
    }
    return 0;
  }

  logConfiguration() {
    const logstr = JSON.stringify(this._configModel);
    console.log(logstr);
  }

}
