import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {BatchConfigModel} from '../_models/BatchLogic/batch-config-model';
import {AutoBatchConfigPayload} from '../core/payload/auto-batch-config-payload';
import {BatchModel,ReadingBatchModel} from '../_models/BatchLogic/batch-model';
import {environment} from '../../environments/environment';

const BASE_URL = `https://${environment.MSBatchLogicVersion}msbatchlogic-dot-${environment.apiUrl}`;

// const BASE_URL = `http://localhost:8080/api`;

@Injectable({
  providedIn: 'root'
})
export class BatchLogicService {

  constructor(private http: HttpClient) {
  }

  public getBatch(id, studyId): Observable<BasicResponse<BatchModel>> {
    const params = {
      studyId: studyId,
    };
    const requestURL = `${BASE_URL}/batches/${id}`;
    return this.http.get<BasicResponse<BatchModel>>(requestURL, {params: params});
  }

  public getBatches(params): Observable<BasicResponse<BatchModel[]>> {
    const requestURL = `${BASE_URL}/batches`;
    return this.http.get<BasicResponse<BatchModel[]>>(requestURL, {params: params});
  }

  public getBatchForReading(studyId: number, readingId: number): Observable<BasicResponse<BatchModel>> {
    const requestURL = `${BASE_URL}/readings/${readingId}/batch`;
    return this.http.get<BasicResponse<BatchModel>>(requestURL, {params: {studyId: studyId.toString()}});
  }

  public getReadingWithBatch(studyId: number): Observable<BasicResponse<ReadingBatchModel[]>> {
    const requestURL = `${BASE_URL}/readings/for-dashboard`;
    return this.http.get<BasicResponse<ReadingBatchModel[]>>(requestURL, {params: {studyId: studyId.toString()}});
  }

  public initiateBatch(studyId, batchId, initiated): Observable<BasicResponse<BatchModel>> {
    const requestURL = `${BASE_URL}/batches/${batchId}`;
    const params = {
      studyId: studyId,
      initiated: initiated,
    };
    return this.http.put<BasicResponse<BatchModel>>(requestURL, params);
  }

  public cancelBatch(studyId, batchId, cancel): Observable<BasicResponse<BatchModel>> {
    const requestURL = `${BASE_URL}/batches/${batchId}`;
    const params = {
      studyId: studyId,
      cancel: cancel,
    };
    return this.http.put<BasicResponse<BatchModel>>(requestURL, params);
  }

  public createBatch(batch) {
    const requestURL = `${BASE_URL}/batches`;
    return this.http.post(requestURL, batch);
  }

  public updateBatch(batchId, batch) {
    const requestURL = `${BASE_URL}/batches/${batchId}`;
    return this.http.put(requestURL, batch);
  }

  public getReadings(studyId, params): Observable<BasicResponse<any>> {
    const requestURL = `${BASE_URL}/readings`;
    params['studyId'] = studyId;
    return this.http.get<BasicResponse<any>>(requestURL, {params: params});
  }

  public getBatchConfigsForReadingConfig(studyId: number, flexibleConfigId: number): Observable<BasicResponse<BatchConfigModel[]>> {
    const url = `${BASE_URL}/batch-configs?studyId=${studyId}&flexibleConfigId=${flexibleConfigId}`;
    const params: HttpParams = new HttpParams();
    params.append('studyId', studyId.toString());
    params.append('flexibleConfigId', flexibleConfigId.toString());
    return this.http.get<BasicResponse<BatchConfigModel[]>>(url, {params: params});
  }

  public createBatchConfig(studyId: number, flexibleConfigId: number,
                           configPayload: AutoBatchConfigPayload): Observable<BasicResponse<BatchConfigModel>> {
    const requestURL = `${BASE_URL}/batch-configs`;
    const data = {
      studyId: studyId,
      numberOfReadings: configPayload.numberOfReadings,
      readingType: configPayload.readingType,
      initiationMethod: configPayload.initiationMethod,
      initiationOption: configPayload.initiationOption,
      batchCount: configPayload.batchCount,
      flexibleConfigId: flexibleConfigId,
      readerIds: configPayload.readerIds,
    };
    return this.http.post<BasicResponse<BatchConfigModel>>(requestURL, data);
  }

  public updateBatchConfig(studyId: number, flexibleConfigId: number,
                           configPayload: AutoBatchConfigPayload): Observable<BasicResponse<BatchConfigModel>> {
    const requestURL = `${BASE_URL}/batch-configs/${configPayload.id}`;
    const data = {
      studyId: studyId,
      numberOfReadings: configPayload.numberOfReadings,
      readingType: configPayload.readingType,
      initiationMethod: configPayload.initiationMethod,
      initiationOption: configPayload.initiationOption,
      batchCount: configPayload.batchCount,
      flexibleConfigId: flexibleConfigId,
      readerIds: configPayload.readerIds,
    };
    return this.http.put<BasicResponse<BatchConfigModel>>(requestURL, data);
  }

}
