import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ImagingAnalysisService} from '../../../../_services/imaging-analysis.service';
import {SeriesManagerService, StudyService} from '../../../../_services';
import {ToastService} from '../../../../_services/internal/toast.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import get from 'lodash/get';

interface MCFormModel {
  algorithm;
  frame;
}

interface PMFormModel {
  firstFrame;
  lastFrame;
}

interface Container {
  version: number;
  status: string;
  startDate;
}

interface MCContainer extends Container {
  algorithm: Algorithm;
  referenceFrame: number;
}

interface PMContainer extends Container {
  firstFrame: number;
  lastFrame: number;
}

enum SeriesTypes {
  ORIGINAL = 'original',
  MC = 'mc',
  OTHER = ''
}

@Component({
  selector: 'app-motion-correction-panel',
  templateUrl: './motion-correction-panel.component.html',
  styleUrls: ['./motion-correction-panel.component.css']
})
export class MotionCorrectionPanelComponent implements OnInit {

  isShowMCForm: boolean;
  isShowPMForm: boolean;

  frames: number[];
  availableFramesForLastFrameOption: number[];
  algorithms: Algorithm[];

  formMC: FormGroup | {
    controls: MCFormModel
  };
  formPM: FormGroup | {
    controls: PMFormModel
  };

  loadedMCContainer: MCContainer;
  loadedPMContainer: PMContainer;
  _series;

  @Input()
  set series(series) {
    this._series = series;
    this.updateIsShowFormControls();
  }

  @Input() onClose: EventEmitter<any>;

  constructor(private fb: FormBuilder,
              private imagingAnalysisService: ImagingAnalysisService,
              private toastService: ToastService,
              public studyService: StudyService,
              public seriesManagerService: SeriesManagerService) {
  }

  ngOnInit() {
    this.algorithms = [{value: 'MOTION_CORRECTION_2D', name: '2D rigid body registration'}];
    this.initAvailableFrames();
    this.initAvailableFramesForLastFrameOption(1);
    this._series.currentPMFirstFrame = this._series.baselineRange ? this._series.baselineRange[0] : 0;
    this._series.currentPMLastFrame = this._series.baselineRange ? this._series.baselineRange[this._series.baselineRange.length - 1] : 0;
    this.subscribeOnClose();
    this.loadProcess().subscribe(() => {
      this.initMCForm();
      this.initPMForm();
    });
  }

  clickPerformMCBtn() {
    this.studyService.getStudyWithoutRelationsByPatientId(this._series.patient.id).subscribe(studyResult => {
      const bucket = studyResult.data.bucketLocation;
      const onError = () => this.toastService.error('Motion correction process failure', 'Motion correction failed due to some reasons. Try again or contact support team');
      this.toastService.success('Motion correction started successfully', 'Motion correction process is in progress. When process is done then QC user can see new motion corrected series in the list');
      this.imagingAnalysisService
        .startMotionCorrection(this._series, this.formMC.controls.algorithm.value.value, this.formMC.controls.frame.value, bucket)
        .subscribe(() => null, onError);
    });
  }

  clickCancelMCBtn() {

  }

  clickCancelPMBtn() {

  }

  clickGeneratePMBtn() {
    this.studyService.getStudyWithoutRelationsByPatientId(this._series.patient.id).subscribe(studyResult => {
      const bucket = studyResult.data.bucketLocation;
      const data = {
        patientId: this._series.patient.id,
        seriesId: this._series.seriesId,
        visitConfigId: this._series.visitConfigId,
        firstBaselineFrame: this.formPM.controls.firstFrame.value,
        lastBaselineFrame: this.formPM.controls.firstFrame.value,
        bucket: bucket
      };
      const onError = () => this.toastService.error('ID 75: Parametric maps generation failed on start',
        'Process failed on start due to some reasons. Try again or contact support team');
      this.imagingAnalysisService.generateParametricMap(data).subscribe(containerId =>
          this.toastService.success('ID 74: Parametric maps generation has started successfully',
            'Process has been executed. Wait for the notification confirming that the process is done: Container ID:' + containerId),
        onError);
    });
  }

  compareAlgorithms(algorithm1, algorithm2) {
    return algorithm1.value === algorithm2.value;
  }

  private initMCForm(): void {
    if (!!this.loadedMCContainer) {
      const processAlgorithm = this.loadedMCContainer.algorithm;
      const processFrame = this.loadedMCContainer.referenceFrame;
      this.formMC = this.buildMCFormGroup(processAlgorithm, processFrame);
    } else {
      this.formMC = this.buildMCFormGroup(this.algorithms[0], null);
    }
  }

  private initPMForm(): void {
    if (!!this.loadedPMContainer) {
      const firstFrame = this.loadedPMContainer.firstFrame;
      const lastFrame = this.loadedPMContainer.lastFrame;
      this.formPM = this.buildPMFormGroup(firstFrame, lastFrame);
    } else {
      this.formPM = this.buildPMFormGroup(1, null);
    }
    this.formPM.controls.firstFrame.valueChanges.subscribe(newValue => {
      this.initAvailableFramesForLastFrameOption(newValue);
      if (newValue >= this.formPM.controls.lastFrame.value) {
        this.formPM.controls.lastFrame.setValue(null);
      }
    });
  }

  private buildMCFormGroup(algorithmValue, frameValue) {
    return this.fb.group({
      algorithm: new FormControl(algorithmValue, [Validators.required]),
      frame: new FormControl(frameValue, [Validators.required])
    } as MCFormModel);
  }

  private buildPMFormGroup(firstFrame, lastFrame) {
    return this.fb.group({
      firstFrame: new FormControl(firstFrame, [Validators.required]),
      lastFrame: new FormControl(lastFrame, [Validators.required])
    } as PMFormModel);
  }

  private loadProcess(): Observable<void> {
    return this.imagingAnalysisService.getContainerResultsWithChildsByVisitConfigId([this._series.visitConfigId], []).pipe(map(resp => {
      const containers = resp['executions'];

      const mcContainer = this.getMCDoneSuccessfulContainer(containers);
      if (!!mcContainer) {
        this.loadedMCContainer = this.buildMCContainer(mcContainer);
      }

      const pmContainer = this.getPMDoneSuccessfulContainer(containers);
      if (!!pmContainer) {
        this.loadedPMContainer = this.buildPMContainer(pmContainer);
      }
    }));
  }

  private getMCDoneSuccessfulContainer(containers): MCContainer {
    return containers
      .filter(c => c.workload === 'MotionCorrection' && c.state === 'DONE' && c.stateMsg === 'SUCCESSFUL')
      .sort((c1, c2) => this.compareProcessesByStartDate(c1, c2))[0];
  }

  private getPMDoneSuccessfulContainer(containers): Container {
    return containers
      .filter(c => (c.workload === 'ParametricMaps' && c.workload === 'ReCalculatePM') && c.state === 'DONE' && c.stateMsg === 'SUCCESSFUL')
      .sort((c1, c2) => this.compareProcessesByStartDate(c1, c2))[0];
  }

  private buildMCContainer(containerResp): MCContainer {
    return {
      referenceFrame: containerResp.workloadOutput.extra.reference_frame,
      version: containerResp.workloadOutput.extra.version_number,
      algorithm: this.algorithms.find(a => a.value === containerResp.workloadOutput.extra.algorithm_type),
      startDate: containerResp.dateStart,
      status: containerResp.stateMsg
    } as MCContainer;
  }

  private buildPMContainer(containerResp): PMContainer {
    return {
      version: containerResp.workloadOutput.extra.version_number,
      firstFrame: containerResp.workloadOutput.extra.baseline_range[0],
      lastFrame: containerResp.workloadOutput.extra.baseline_range[1],
      startDate: containerResp.dateStart,
      status: containerResp.stateMsg
    } as PMContainer;
  }

  private compareProcessesByStartDate(process1, process2) {
    const date1 = new Date(process1.dateStart);
    const date2 = new Date(process2.dateStart);
    return date1.getTime() < date2.getTime();
  }

  private updateIsShowFormControls(): void {
    const seriesType = this.getSeriesType(this._series);
    this.isShowMCForm = seriesType === SeriesTypes.ORIGINAL;
    this.isShowPMForm = seriesType === SeriesTypes.MC;
  }

  private getSeriesType(series): SeriesTypes {
    const isSeriesOfType = (type) => get(series, 'modificationType', '').toLowerCase().includes(type);
    if (isSeriesOfType(SeriesTypes.ORIGINAL)) {
      return SeriesTypes.ORIGINAL;
    } else if (isSeriesOfType(SeriesTypes.MC)) {
      return SeriesTypes.MC;
    } else {
      return SeriesTypes.OTHER;
    }
  }

  private subscribeOnClose() {
    this.onClose.subscribe(() => {
      this.isShowMCForm = false;
      this.isShowPMForm = false;
    });
  }

  private initAvailableFrames() {
    this.frames = this._series.numbOfFrames ? [...Array(this._series.numbOfFrames).keys()] : [0];
    this.frames.forEach(function (part, index) {
      this[index] += 1;
    }, this.frames);
  }

  private initAvailableFramesForLastFrameOption(firstFrame) {
    this.availableFramesForLastFrameOption = this.frames.filter(frame => frame > firstFrame ?? 0);
  }
}

export interface Algorithm {
  name: string;
  value: string;
}
