import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {
  CohortDialogMode,
  CohortDialogModel
} from '../imaging-project-cohorts-dialog/imaging-project-cohorts-dialog.component';
import {CohortsService} from '../../../../_services';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {CohortsModel} from "../../../../_models/ImagingProject/ImagimgProjectCohorts/cohorts-model";

@Component({
  selector: 'app-imaging-project-cohort-status-dialog',
  templateUrl: './imaging-project-cohort-status-dialog.component.html',
  styleUrls: ['./imaging-project-cohort-status-dialog.component.css']
})
export class ImagingProjectCohortStatusDialogComponent implements OnInit {

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  otherCohortsDisabled = false;

  constructor(
    private cohortsService: CohortsService,
    private toastyService: ToastyService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<CohortDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: CohortDialogModel
  ) { }

  ngOnInit(): void {
    const disabled = this.data.studyCohortsList.filter( c => c.id !== this.data.cohort.id && c.status === 'DISABLED');
    if (disabled.length === this.data.studyCohortsList.length - 1) {
      this.otherCohortsDisabled = true;

      this.toastOptions.title = 'Cohort updating failure';
      this.toastOptions.msg = 'Last cohort in the list. At least one active cohort must stay in the list. Create more cohorts and then disable the current one.\n';
      this.toastyService.error(this.toastOptions);
    }
  }

  save(action: string): void {
    const cohort = {
      id: this.data.cohort.id,
      status: action
    };

    this.cohortsService.updateStatus(<CohortsModel>cohort).subscribe(cResp => {
      this.dialogRef.close(cResp);
    });
  }

  close() {
    this.dialogRef.close();
  }
}
