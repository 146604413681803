import { Template } from './TemplateModels';
import { StudyLayout } from './DataModels';
import { HangingProtocol } from './DataModels';
import { Utils } from '../../_services/leadtools/lead-tools-utils';

export class MedicalViewerConfig {
    public rows: number;
    public columns: number;
    public studyLayout: StudyLayout;
    public hangingProtocol: HangingProtocol;
    public customLayout: any;
    public splitterSize: number;
    public OnApiReady: any;
    public OnSelectionChanged: (selectionCount: number) => {};

    constructor(private utils: Utils) {
        this.rows = 1;
        this.columns = 2;
        this.splitterSize = this.utils.get_splitterSize();
        this.studyLayout = null;
        this.customLayout = null;
        this.hangingProtocol = null;
    }
}

export class MedicalViewerCellConfig {
    public arrangement: number;
    public unselectedBorderColor: string;
    public selectedBorderColor: string;
    public highlightedSubCellBorderColor: string;
}

export class MedicalViewerSeries {
    public seriesInstanceUID: string;
    public patientID: string;
    public rows: number;
    public columns: number;
    public forCompare: boolean;
    public link: boolean;
    public id: string;
    public sopInstanceUIDS: Array<string>;
    public template: Template;
    public dislaySetNumber: number;
    public view: any;
    public loadSeriesLayout: boolean;

    constructor(seriesInstanceUID: string, patientID: string, rows?: number, columns?: number) {
        this.seriesInstanceUID = seriesInstanceUID;
        this.patientID = patientID;
        this.rows = rows || 1;
        this.columns = columns || 1;
        this.forCompare = false;
        this.link = true;
        this.id = UUID.genV4().toString();
        this.sopInstanceUIDS = new Array<string>();
        this.template = null;
        this.dislaySetNumber = undefined;
        this.loadSeriesLayout = true;
    }
}