import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { ViewerCoreLtComponent } from '../../../Viewer';
import { SeriesNavigatorComponent } from '../../../../components/controls/series-navigator/series-navigator.component';
import { BehaviorSubject, forkJoin, interval, Observable, Subject, Subscription } from 'rxjs';
import {
  ImagingProjectService,
  ReadingConfigFlexibleService,
  StudySequenceLabelService,
  StudyUserService,
  ViewerAuthenticationService
} from '../../../../_services';
import { ReadingFormScoring, ReadingFormScoringComponent } from '../../reading-form-scoring.component';
import { map, mergeMap } from 'rxjs/operators';
import { SequenceLabelModel } from '../../../../_models/ImagingProject/sequence-label-model';
import { FlexibleConfig } from '../../../../core/interfaces/flexible-config';
import { BaseFlexibleConfig } from '../../../../_models/ImagingProject/base-flexible-config';
import { NavigatorSeriesModel } from '../../../../components/controls/inner-series-navigator/inner-series-navigator.component';
import { BasicReading } from '../../../../_models/basic-reading';
import { ReadingStatus } from '../../../../core/constants/reading-status';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
import * as _ from 'lodash';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import { ToastService } from '../../../../_services/internal/toast.service';
import { MatDialog } from '@angular/material/dialog';
import { VisitsStatusDialogComponent } from '../../IF/visits-status-dialog/visits-status-dialog.component';
import { ReadingLevel } from 'src/app/core/constants/reading-level';
import { ReadingNavigatorComponent } from '../../reading-navigator/reading-navigator.component';
import { PageMode } from 'src/app/core/constants/page-mode';
import { applyMixins } from 'src/app/_helpers/apply-mixins';
import { BroadcastChannelMixin } from 'src/app/_mixins/broadcast-channel.mixin';
import { distinctUntilChanged, filter, pairwise, skipWhile, startWith, takeUntil } from 'rxjs/operators';
import { ReadingFormService } from 'src/app/_services/reading-form.service';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import {BasicResponse} from "../../../../core/interfaces/basic-response";

@Component({
  selector: 'app-reading-form',
  templateUrl: './reading-form.component.html',
  styleUrls: ['./reading-form.component.css']
})
export class ReadingFormComponent implements OnInit, OnDestroy {
  readonly PageMode: typeof PageMode = PageMode;
  @ViewChild('seriesNavigator') seriesNavigator: SeriesNavigatorComponent;
  @ViewChild('viewerLT', { read: ViewContainerRef }) viewerLT: ViewContainerRef;
  @ViewChild('viewerContainer', { read: ElementRef }) viewerContainer: ElementRef;

  readonly UPDATE_PERIOD_SEC = 30;

  private spentSecSubscription: Subscription;
  private updateTimeSpentSubscription: Subscription;

  readingFormReference: ReadingFormScoring & ReadingFormScoringComponent;

  newLTViewer: any = null;

  submitDisabledSubject = new BehaviorSubject(true);
  viewerEnabledSubject = new BehaviorSubject(true);
  activateVisitSubject = new BehaviorSubject(null);
  filterSeriesNavigatorContentSubject = new BehaviorSubject(null);
  readingListUpdatedSubject = new BehaviorSubject(null);
  readingListUpdatedAfterVisitsDoneSubject = new BehaviorSubject(null);
  newTabScoringFormEnabledSubject = new BehaviorSubject(false);


  expanded = true;
  isViewerEnabled = true;
  isNewTabScoringFormEnabled = false;

  userId: number;
  studyId: number;

  currentReading: BasicReading;
  readingList: Array<BasicReading>;

  uncompletedReadingsCount: number;

  availableSequenceLabels: SequenceLabelModel[] = [];
  defaultSequenceLabelIds: number[] = [];

  openedNumberOfImages;

  visitConfig;
  visitedSeriesCount = 0;
  readingSeriesDetails = [];
  filteredReadingSeriesDetails = [];
  availableSeries = [];

  spentSeconds: number;

  showSpinner = true;

  endpointName = '';

  viewerData = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;

  isFormValid = true;
  submitDisabled = true;
  protocolType: string = null;
  private paramSubscription: Subscription;
  private HIDE_VISIT_CHRONOLOGY = ['hide_visits_chronology', 'hide_scan_dates'];
  private hideVisitChronologyEndpoints: string[] = ['K&L', 'Incidental Findings', 'JSW', 'OARSI', 'GENANT'];
  showSeriesScanDate: boolean;

  private unsubscribeForm = new Subject<void>();
  pageMode = new BehaviorSubject<PageMode>(PageMode.FULL);

  constructor(private authenticationService: ViewerAuthenticationService,
    private imagingProjectService: ImagingProjectService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private studySequenceLabelService: StudySequenceLabelService,
    private readingConfigService: ReadingConfigFlexibleService,
    private studyUserService: StudyUserService,
    private route: ActivatedRoute,
    private utils: Utils,
    private cdr: ChangeDetectorRef,
    private toastService: ToastService,
    private dialog: MatDialog,
    private readingFormService: ReadingFormService,
    private router: Router,
  ) {
    if (this.route.children.length) {
      this.paramSubscription = this.route.children[0].data.subscribe(
        (data: Data): void => {
          this.protocolType = data.protocolType;
        }
        );
      }
  }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.isViewerEnabled && [PageMode.FULL, PageMode.FORM_DETACHED].includes(this.pageMode.value)) {
        this.preOpenLTViewer();
      }
    }, 500);

    this.submitDisabledSubject.subscribe(disabled => {
      this.isFormValid = !disabled;
      this.submitDisabled = !this.isFormValid || this.readingFormReference.openSeriesCountRestriction > this.visitedSeriesCount;
    });

    this.subscribeViewerEnabledSubject();
    this.subscribeNewTabScoringFormEnabledSubject();

    this.activateVisitSubject.subscribe(visit => {
      if (visit) {
        this.activateVisit(visit.id);
        this.broadcastData('activateVisit', [visit.id]);
      }
    });

    this.filterSeriesNavigatorContentSubject.subscribe(filter => {
      this.filteredReadingSeriesDetails = filter ? this.readingSeriesDetails.filter(series => series.projectModality.includes(filter.modality) === filter.condition) : this.readingSeriesDetails;
      this.visitedSeriesCount = 0;
    });

    this.readingListUpdatedSubject.subscribe(reading => {
      const index = this.readingList.findIndex(r => reading.id === r.id);
      this.readingList[index] = reading;
    });

    this.readingListUpdatedAfterVisitsDoneSubject.subscribe(reading => {
      if (reading) {
        this.updateReadingListAfterSubmit(reading.id);
        this.broadcastData('updateReadingListAfterSubmit', [this.currentReading.id]);
      }
    });

    this.handlePageMode();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribeForm))
      .subscribe(queryParams => {
        this.broadcastChannelName = queryParams['channelName'];
        this.pageMode.next(String(queryParams['pageMode']).toUpperCase() as PageMode);
      });
  }

  get submitButtonLabel(): string {
    if (this.readingFormReference.getSubmitButtonLabel) {
      const label =  this.readingFormReference.getSubmitButtonLabel();
      if (label) {
        return label;
      }
    }

    return 'Submit the result';
  }

  updateCurrentRoute(queryParams: Params, openNewTab?: boolean): void {
    const url = this.router.createUrlTree([], { relativeTo: this.route, queryParams }).toString();

    if (openNewTab) {
      window.open(url, '_blank');
    } else {
      this.router.navigateByUrl(url);
    }
  }

  getFormSize() {
    switch (this.pageMode.value) {
      case PageMode.FULL:
        return this.isViewerEnabled ? 470 : 80;
      case PageMode.FORM:
        return this.isViewerEnabled ? 100 : 80;
      case PageMode.FORM_DETACHED:
        return 240;
      default:
        return '';
    }
  }

  getFormMinSize() {
    switch (this.pageMode.value) {
      case PageMode.FULL:
        return this.isViewerEnabled ? 450 : 80;
      case PageMode.FORM:
        return this.isViewerEnabled ? 100 : 80;
      case PageMode.FORM_DETACHED:
        return 230;
      default:
        return '';
    }
  }

  getFormMaxSize() {
    switch (this.pageMode.value) {
      case PageMode.FULL:
        return 720;
      case PageMode.FORM:
        return 100;
      case PageMode.FORM_DETACHED:
        return 230;
      default:
        return '';
    }
  }

  handlePageMode(): void {
    this.pageMode
      .pipe(
        takeUntil(this.unsubscribeForm),
        distinctUntilChanged(),
      )
      .subscribe(pageMode => {
        if (!(pageMode in PageMode)) {
          this.pageMode.next(PageMode.FULL);
          return;
        }

        if (pageMode === PageMode.FULL) {
          this.broadcastData('closeWindow');
          this.readingFormService.shutdownBroadcastChannel();
          this.shutdownBroadcastChannel();
          this.updateCurrentRoute({});
          return;
        }

        if ([PageMode.FORM, PageMode.FORM_DETACHED].includes(pageMode)) {
          this.readingFormService.startBroadcastChannel(this.broadcastChannelName);
          this.broadcastChannelName = this.readingFormService.broadcastChannelName;
          this.startBroadcastChannel(this.broadcastChannelName);
        }

        const queryParams = {
          readingIndex: this.getIndexOfCurrentReading().toString() === '-1' ? this.route.snapshot.queryParams.readingIndex : this.getIndexOfCurrentReading().toString(),
          pageMode: String(pageMode).toLowerCase(),
          ...(this.broadcastChannelName && { channelName: this.broadcastChannelName })
        };

        this.updateCurrentRoute(queryParams);

        if (pageMode === PageMode.FORM_DETACHED) {
          this.broadcastData('closeWindow');
          const formQueryParams = { ...queryParams, pageMode: PageMode.FORM };
          this.updateCurrentRoute(formQueryParams, true);
        }
      });
  }

  closeWindow(): void {
    window.close();
  }

  detachScoringForm(): void {
    if (this.pageMode.value === PageMode.FULL) {
      this.pageMode.next(PageMode.FORM_DETACHED);
      this.readingFormReference.hideScoringFormSubject.next(true);
    }
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  reattachScoringForm(): void {
    if ([PageMode.FORM, PageMode.FORM_DETACHED].includes(this.pageMode.value)) {
      this.pageMode.next(PageMode.FULL);
      this.readingFormReference.hideScoringFormSubject.next(false);
    }
    setTimeout(() => {
      this.onResize();
    }, 100);
  }
  openReadingsIdListDialog() {
    const dialogRef = this.dialog.open(ReadingNavigatorComponent, {
      width: '650px',
      height: '556px',
      data: {
        list: this.readingList,
        signList: this.readingFormReference.getSignList ? this.readingFormReference.getSignList() : false,
      },
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((readingId: any) => {
      if (readingId && readingId !== this.currentReading.id) {
        this.skipReading(this.spentSeconds, readingId);
      }
    });
  }


  ngOnDestroy(): void {
    if (!!this.currentReading) {
      this.updateTimeSpent(this.spentSeconds);
    }
    if (this.spentSecSubscription) {
      this.spentSecSubscription.unsubscribe();
    }
    if (this.updateTimeSpentSubscription) {
      this.updateTimeSpentSubscription.unsubscribe();
    }
    this.utils.isAdvancedAnalysis = false;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event): void {
    this.updateTimeSpent(this.spentSeconds);
  }

  onActivateForm(reference: ReadingFormScoring & ReadingFormScoringComponent): void {
    this.userId = +JSON.parse(localStorage.getItem('userId'));
    const project = localStorage.getItem('project');
    if (project) {
      this.studyId = +JSON.parse(project).id;
    }

    this.readingFormReference = reference;
    this.submitDisabledSubject = this.readingFormReference.switchSubmitBtnDisabledSubject;
    this.activateVisitSubject = this.readingFormReference.activedVisitSubject;
    this.filterSeriesNavigatorContentSubject = this.readingFormReference.filterSeriesNavigatorContentSubject;
    this.viewerEnabledSubject = this.readingFormReference.viewerEnabledSubject;
    this.newTabScoringFormEnabledSubject = this.readingFormReference.newTabScoringFormEnabledSubject;
    this.readingListUpdatedSubject = this.readingFormReference.readingListUpdatedSubject;
    this.readingListUpdatedAfterVisitsDoneSubject = this.readingFormReference.readingListUpdatedAfterVisitsDoneSubject;
    this.endpointName = this.readingFormReference.getEndpointName();
    this.loadReadings();
  }

  hideReadingForm(): void {
    this.expanded = false;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  activateVisit(visitId) {
    const fn = series => series.visitId === visitId;
    if(this.currentReading.readingVersion === ReadingVersion.IF_Historical) {
      // looks like it is necessary to show series for visits: current and all before current
      const indexOfSelectedVisit = this.readingSeriesDetails.findIndex(fn);
      this.filteredReadingSeriesDetails = indexOfSelectedVisit === -1 
            ? this.readingSeriesDetails 
            : this.readingSeriesDetails.filter((f, index) => index <= indexOfSelectedVisit || fn(f));
    } else {
      this.filteredReadingSeriesDetails = this.readingSeriesDetails.filter(fn);

    }
    this.visitedSeriesCount = 0;
  }

  loadReadings(): void {
    this.readingList = [];
    this.readingFormReference.loadReadings(this.studyId, this.userId).pipe(
      map((readingsResp: BasicResponse<any>) => {
        let data = readingsResp.data;
        if (!Array.isArray(data)) {
          this.studyId = (data as any).studyId;
          data = [data];
        }

        if (readingsResp?.studyId) {
          this.studyId = readingsResp?.studyId;
        }

        this.uncompletedReadingsCount = data.length;
        this.initReadings(data);
      }),
      mergeMap(readingResp => this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId))
    ).subscribe(sequenceLabelsResp => {
      this.availableSequenceLabels = [...sequenceLabelsResp.data];
    }, () => {
      this.showSpinner = false;
      this.uncompletedReadingsCount = 0;
    });
  }

  showReadingForm(): void {
    this.expanded = true;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  onResize(): void {
    window.dispatchEvent(new Event('resize'));
  }

  initReadings(readings: any[]): void {
    const readingList = readings.filter(r => !this.readingFormReference?.filterReading || this.readingFormReference.filterReading(r));
    this.readingList = readingList.sort((a, b) => a.id - b.id);

    if (readings.length) {
      let reading = this.readingList[0];

      const readingIndex = this.route.snapshot.queryParams.readingIndex;
      if (readingIndex && readingIndex < this.readingList.length) {
        reading = this.readingList[readingIndex];
      } else {
        const readingId = this.readingFormReference.getReadingId ? this.readingFormReference.getReadingId() : null;

        if (readingId) {
          const foundReading = this.readingList.find(r => +r.id === +readingId);
          if (foundReading) {
            reading = foundReading;
          }
        } else {
          reading = this.readingList[0];
        }
      }

      this.initCurrentReading(reading);
    } else {
      this.showSpinner = false;
      this.unsubscribe();
    }
  }

  initCurrentReading(reading: any): void {
    this.showSeriesScanDate = true;
    this.showSpinner = true;
    this.currentReading = reading;
    this.spentSeconds = this.currentReading.timeSpent;
    this.visitedSeriesCount = 0;
    this.initTimeSpentCounter();
    this.initUpdateInterval();
    this.readingFormReference.currentReadingSubject.next(this.currentReading);
    const seriesIds = [];

    let visits = this.currentReading.visits;
    if (!visits && this.currentReading.visit) {
      visits = [this.currentReading.visit];
    }
    if (!this.currentReading.series && !!visits) {
      this.currentReading.series = visits.map(v => v.series).flat();
    }

    if (this.currentReading.series) {
      this.currentReading.series.forEach(s => {
        if (!!s) {
          seriesIds.push(s.seriesId);
        }
      });
    }


    let flexConfigId: number;
    if (!!this.currentReading.configId) {
      flexConfigId = this.currentReading.configId;
    }

    if (!!this.currentReading.flexibleConfigId) {
      flexConfigId = this.currentReading.flexibleConfigId;
    }

    this.startReading(this.currentReading);
    forkJoin([
      this.studySequenceLabelService.getStudySeriesAndvisitConfigBySeriesIds(seriesIds),
      this.readingConfigService.getById(flexConfigId)
    ]).subscribe(([seriesResp, readingConfigResp]) => {
      this.readingSeriesDetails =
        this.initReadingSeries(this.currentReading, seriesResp.data as (NavigatorSeriesModel & { visitConfig: any })[]);
      if (this.hideVisitChronologyEndpoints.includes(readingConfigResp.data.config.endpoint.name)) {
        if (readingConfigResp.data.config.endpointReadingVersion === 'adjudication') {
          if (readingConfigResp.data.config.adjHideVisitChronology
            && this.HIDE_VISIT_CHRONOLOGY.includes(readingConfigResp.data.config.adjHideVisitChronology)) {
            this.readingSeriesDetails.forEach(series => {
              if (series.visitConfig) {
                series.visitConfig.hideVisitChronology = true;
              }
            });
          }
        } else if (
          (readingConfigResp.data.config.hideVisitChronology &&
            this.HIDE_VISIT_CHRONOLOGY.includes(readingConfigResp.data.config.hideVisitChronology))
          ||
          (readingConfigResp.data.config.hideVisitHistory &&
            this.HIDE_VISIT_CHRONOLOGY.includes(readingConfigResp.data.config.hideVisitHistory))
        ) {
          this.showSeriesScanDate = false;
          this.readingSeriesDetails.forEach(series => {
            if (series.visitConfig) {
              series.visitConfig.hideVisitChronology = true;
            }
          });
        }
      }
      this.filteredReadingSeriesDetails = this.readingSeriesDetails;
      this.readingFormReference.readingSeriesInitiated.next(true);
      this.availableSeries = seriesIds;
      this.visitedSeriesCount = 0;
      this.initDefaultSequenceLabels(readingConfigResp.data);
      if (this.isViewerEnabled) {
        this.initViewerForReading();
      }
      this.showSpinner = false;
    });
  }

  initReadingSeries(reading: BasicReading, series: (NavigatorSeriesModel & { visitConfig: any })[]): NavigatorSeriesModel[] {
    if (!!reading.visits) {
      return this.initReadingSeriesFromVisit(reading.visits, series);
    }

    if (!!reading.visit) {
      return this.initReadingSeriesFromVisit([reading.visit], series);
    }

    return this.initReadingSeriesNative(reading, series);
  }

  initReadingSeriesFromVisit(visits: any[], series: NavigatorSeriesModel[]): NavigatorSeriesModel[] {
    const readingSeries: NavigatorSeriesModel[] = [];
    visits.forEach(visit => {
      if (!!visit.series) {
        visit.series.forEach(s => {
          const ser = series.find(se => se.id === s.seriesId);
          if (!!ser) {
            ser.visitId = visit.id;
            ser.visitConfigId = visit.visitConfigId;
            ser.timepoint = visit.timepoint;
            readingSeries.push(ser);
          }
        });
      }
    });
    return readingSeries;
  }

  initReadingSeriesNative(reading: BasicReading & any, series: (NavigatorSeriesModel & { visitConfig: any })[]): NavigatorSeriesModel[] {
    const readingSeries: NavigatorSeriesModel[] = [];

    if (reading.series) {
      reading.series.forEach(s => {
        const ser = series.find(se => se.id === s.seriesId);
        ser.visitId = null;
        ser.visitConfigId = reading.visitConfigId;
        ser.timepoint = ser.visitConfig.visitName;
        readingSeries.push(ser);
      });
    }

    return readingSeries;
  }

  startReading(reading: BasicReading): void {
    if (reading.status.code === ReadingStatus.READING_PENDING_STATUS_CODE || (reading.status + '') === 'READING_PENDING') {
      this.readingFormReference.startReading(this.studyId, reading.id).subscribe();
    }
  }

  initDefaultSequenceLabels(readingConfig: FlexibleConfig<BaseFlexibleConfig>): void {
    const configSequenceLabels: SequenceLabelModel[] = [];
    readingConfig.config.modalities.forEach(m => {
      m.sequenceLabels.forEach(s => configSequenceLabels.push(s));
    });
    this.availableSequenceLabels = configSequenceLabels;
    if (!!readingConfig.config.anatomySelector) {
      this.defaultSequenceLabelIds = (readingConfig.config.anatomySelector as { id: number }[])
        .filter(label => this.availableSequenceLabels.find(studyLabel => studyLabel.id === label.id)).map(l => l.id);
    }
  }

  skipReading(spentSeconds: number, readingId: number): void {
    this.showSpinner = true;
    this.broadcastData('showSpinner', true);
    this.readingFormReference.updateReading(this.studyId, this.currentReading.id, { spentSeconds: spentSeconds })
      .subscribe(updateResponse => {
        this.showSpinner = false;
        this.broadcastData('showSpinner', false);
        const arg = readingId ? this.readingList.findIndex(reading => reading.id === readingId) : this.getIndexOfCurrentReading() + 1;
        this.broadcastData('nextReading', [arg]);
        this.nextReading(arg);
      }, () => {
        this.showSpinner = false;
        this.broadcastData('showSpinner', false);
      });
  }

  getIndexOfCurrentReading() {
    return this.readingList.findIndex(r => r.id === this.currentReading.id);
  }

  nextReading(index: number): void {

    if (this.readingFormReference.getNextReadingUrl) {
      const url = this.readingFormReference.getNextReadingUrl(this.readingList[index]);
      if (url) {
        window.location.href = url;
        return;
      }
    }

    const queryParams = {
      readingIndex: !!this.readingList[index] ? index : 0 ,
      pageMode: String(this.pageMode.value).toLowerCase(),
      ...(this.broadcastChannelName && { channelName: this.broadcastChannelName })
    };

    this.updateCurrentRoute(queryParams);

    if (!!this.readingList[index]) {
      this.onCloseAllViewers();
      this.initCurrentReading(this.readingList[index]);
    } else {
      this.loadReadings();
    }
    this.readingFormReference.clearForm();
  }

  updateTimeSpent(spentSeconds): void {
    if (this.currentReading) {
      this.readingFormReference.updateReading(this.studyId, this.currentReading.id, { spentSeconds: spentSeconds }, true)
        .subscribe(resp => {
        });
    }
  }

  submitReading(spentSeconds: number): void {
    if (this.currentReading.readingLevel?.toUpperCase() === ReadingLevel.LONGITUDINAL
      && this.endpointName !== 'GENANT' && this.endpointName !== 'MOAKS' && this.endpointName !== 'JSW') {
      let visits = this.currentReading.visits;
      if (!visits && this.currentReading.visit) {
        visits = [this.currentReading.visit];
      }

      const dialogRef = this.dialog.open(VisitsStatusDialogComponent, {
        width: '600px',
        data: visits
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.submitAndNexReading(spentSeconds);
        }
      });
    } else {
      this.submitAndNexReading(spentSeconds);
    }
  }

  submitAndNexReading(spentSeconds: number) {
    this.showSpinner = true;
    this.readingFormReference.submitReading(this.studyId, { spentSeconds: spentSeconds }).subscribe(submitResponse => {
      if (['MOAKS', 'JSW'].includes(this.endpointName) && submitResponse === null) {
        this.showSpinner = false;
        return;
      }

      if (submitResponse?.data?.canceled) {
        this.showSpinner = false;
        return;
      }

      if (this.endpointName === 'GENANT') {
        this.toastService.success('SUCCESS: Timepoint is successfully saved and locked' );
      }
      const readingId = this.currentReading.id;
      this.updateReadingListAfterSubmit(readingId);
      this.broadcastData('updateReadingListAfterSubmit', [readingId]);
    }, () => {
      this.showSpinner = false;
    });
  }

  updateReadingListAfterSubmit(readingId: number) {
    this.uncompletedReadingsCount--;
    const nextIndex = this.getIndexOfCurrentReading();
    this.showSpinner = false;
    this.readingList = this.readingList.filter(reading => reading.id !== readingId);
    this.nextReading(nextIndex);
  }

  saveReading(spentSeconds: number): void {
    this.showSpinner = true;
    this.readingFormReference.saveReading(this.studyId, { spentSeconds: spentSeconds }).subscribe(submitResponse => {
      this.showSpinner = false;
    }, () => {
      this.showSpinner = false;
    });
  }

  initTimeSpentCounter(): void {
    if (!!this.spentSecSubscription) {
      this.spentSecSubscription.unsubscribe();
    }
    this.spentSecSubscription = interval(1000).subscribe(() => {
      this.spentSeconds++;
    });
  }

  initUpdateInterval(): void {
    if (!!this.updateTimeSpentSubscription) {
      this.updateTimeSpentSubscription.unsubscribe();
    }
    this.updateTimeSpentSubscription = interval(this.UPDATE_PERIOD_SEC * 1000).subscribe(() => {
      if (!!this.spentSeconds) {
        this.updateTimeSpent(this.spentSeconds);
      }
    });
  }

  unsubscribe(): void {
    if (!!this.updateTimeSpentSubscription) {
      this.updateTimeSpentSubscription.unsubscribe();
    }
    if (!!this.spentSecSubscription) {
      this.spentSecSubscription.unsubscribe();
    }
  }

  /*--- navigator/viewer methods ---*/

  initViewerForReading(): void {
    const viewerDataResults: Array<Observable<any>> = [];

    this.readingSeriesDetails.forEach(item => {
      // Get general viewer data
      item.seriesId = item.id;
      item.seriesInstanceUID = item.seriesUID;

      const info = { 'selectedPage': 'Reading' };
      viewerDataResults.push(this.utils.initViewerData(item, this, info));
    });

    if (viewerDataResults.length && [PageMode.FULL, PageMode.FORM_DETACHED].includes(this.pageMode.value)) {
      this.viewerDataIsLoading = true;
      forkJoin(viewerDataResults).subscribe(() => {
        this.viewerDataIsReady = true;
        this.viewerDataIsLoading = false;
        let visits = this.currentReading.visits;
        if (!visits && this.currentReading.visit) {
          visits = [this.currentReading.visit];
        }

        this.viewerData.visitOrder = visits ? visits
          .sort((a, b) => a.viewerOrder - b.viewerOrder).map(v => v.visitConfigId) : [];

        this.openLTViewer(['-1']);
        this._updateViewerToolbar();
        if (this.viewerData.studyUserRoles && this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
          this.viewerData.toolbarExcludeItems = ['ShowDicom'];
        }

        this.cdr.detectChanges();
      });
    }
  }

  onOpenViewer(seriesIds: number[]): void {
    if (!this.isViewerEnabled) {
      return;
    }
    if (seriesIds) {
      if (!this.newLTViewer) {
        this.preOpenLTViewer();
      }
      const ids = [];
      seriesIds.forEach((seriesId) => {
        ids.push(this.availableSeries.find(s => s === seriesId));
      });

      this.openLTViewer(ids);
    }
  }

  onVisitedSeriesCounter(event): void {
    this.visitedSeriesCount = event;
    this.submitDisabled = !this.isFormValid || this.readingFormReference.openSeriesCountRestriction > this.visitedSeriesCount;
  }

  onCloseAllViewers(): void {
    if (!!this.newLTViewer) {
      this.newLTViewer.instance.removeAllCells();
    }
  }

  onOpenImage(value: boolean): void {
    if (this.readingFormReference.onOpenImage) {
      this.readingFormReference.onOpenImage(value);
    }
  }

  openNewLTViewer(seriesInstanceUIDs) {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(
      ViewerCoreLtComponent
    );

    this.newLTViewer = this.viewerLT.createComponent(compFactory);
    this.newLTViewer.instance.seriesInstanceUIDs = seriesInstanceUIDs;
    this.newLTViewer.instance.shouldShowDICOM = this.showSeriesScanDate;
    this.newLTViewer.instance.preventClose = true;
    this.newLTViewer.instance.protocolType = this.protocolType;
    this.newLTViewer.instance.useHangingProtocol = true;
    this.newLTViewer.instance.shouldApplyProtocol = true;

    this.newLTViewer.instance.onCellReady.subscribe(() => {
      this._updateViewerToolbar();
    });

    this.newLTViewer.instance.onClose.subscribe(({ serID, shouldCloseViewer }) => {
      this._updateViewerToolbar();
      this.closeLTViewerSeries(serID, shouldCloseViewer);
    });

    // this.newLTViewer.instance.onmJSWScoringChange.subscribe((data) => {
    //   this.readingFormReference.changedmJSWScoringDataSubject.next(data);
    // });

    this.newLTViewer.instance.onMakeSnapShotListner.subscribe((data) => {

      if (this.pageMode.value === PageMode.FORM_DETACHED) {
        this.broadcastData('takeSnapshot', [data]);
        return;
      }
      this.takeSnapshot(data);
    });

    this.newLTViewer.instance.onDemriqStatData.subscribe((data) => {
      if (data !== null) {
        this.readingFormReference.viewerDataForStatisticDataSubject.next(data);
      } else {
        this.toastService.error('ERROR: Something went wrong, Please try again later');
      }

      this.showSpinner = false;
    });

    this.newLTViewer.instance.OnConfirmContours.subscribe((data) => {
      if (this.pageMode.value === PageMode.FORM_DETACHED) {
        this.broadcastData('confirmContours', [data]);
        return;
      }
      this.confirmContours(data);
    });
  }

  confirmContours(data) {
    this.readingFormReference.contoursDataSubject.next(data);
  }

  takeSnapshot(data) {
    if (this.readingFormReference.onTakeScreenshot) {
      try {
        this.showSpinner = true;
        this.readingFormReference.onTakeScreenshot(data).subscribe(() => {
          this.showSpinner = false;
        });
      } catch (e) {
        this.showSpinner = false;
      }
    } else {
      this.toastService.error('Unable to take a screenshot', 'Not supported!');
    }
  }

  preOpenLTViewer(): void {
    this.openNewLTViewer(null);
  }

  openLTViewer(seriesInstanceUIDs: string[] = []): void {
    if (!this.isViewerEnabled) {
      return;
    }
    this.viewerContainer.nativeElement.style.display = 'block';

    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1500);

    if (this.newLTViewer) {
      this.utils.isAdvancedAnalysis = this.endpointName === 'Advanced Analysis';
      this.newLTViewer.instance.shouldShowDICOM = this.showSeriesScanDate;
      this.viewerData['readingID'] = this.currentReading.id;
      this.newLTViewer.instance.openNewSer(seriesInstanceUIDs, this.viewerData);
      this.openedNumberOfImages = this.newLTViewer.instance.getNumberOfImages();
      return;
    } else {
      this.openNewLTViewer(seriesInstanceUIDs);
    }

    const toolbarExcludeItems = _.get(this.viewerData, 'toolbarExcludeItems', []);
    if (toolbarExcludeItems.length > 0) {
      _.each(toolbarExcludeItems, i => {
        this.newLTViewer.instance.viewerToolbar.setItemProperty(i, 'disabled', true);
      });
    }
  }

  private _updateViewerToolbar() {
    const viewerActionsAllowed =
      this.newLTViewer && this.newLTViewer.instance.viewer && this.newLTViewer.instance.viewer.layout.get_items().get_count() > 0;
    if (viewerActionsAllowed) {
      this.newLTViewer.instance.viewerToolbar.setReadOnly(false);
    } else {
      this.newLTViewer.instance.viewerToolbar.setReadOnly(true);
      this.newLTViewer.instance.viewerToolbar.setItemProperty('ViewerLayout', 'readonly', false, true);
    }
    this.cdr.detectChanges();
  }

  closeLTViewerSeries(serID, shouldCloseViewer): void {
    this.seriesNavigator.unmarkSeriesAsOpenedBySeriesId(serID);

    if (shouldCloseViewer) {
      this.viewerContainer.nativeElement.style.display = 'none';
    }
  }

  private subscribeViewerEnabledSubject(): void {
    this.viewerEnabledSubject.subscribe(enabled => {
      this.isViewerEnabled = enabled;
    });
  }

  private subscribeNewTabScoringFormEnabledSubject(): void {
    this.newTabScoringFormEnabledSubject.subscribe(enabled => {
      this.isNewTabScoringFormEnabled = enabled;
      if (enabled === false && [PageMode.FORM_DETACHED, PageMode.FULL].includes(this.pageMode.value)) {
        this.reattachScoringForm();
      }
    });
  }
}

export interface ReadingFormComponent extends BroadcastChannelMixin { }
applyMixins(ReadingFormComponent, [BroadcastChannelMixin]);
