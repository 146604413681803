import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {PatientService} from '../../../_services/patient.service';
import {ActivatedRoute} from '@angular/router';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import { PatientModel } from 'src/app/_models/ImagingProject/patient-model';

@Component({
    selector: 'app-patient-list',
    templateUrl: './patient-list.component.html',
    styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit {

    displayedColumns: string[] = ['patientCode', 'created', 'phase', 'cohort', 'treatment'];
    dataSource: MatTableDataSource<PatientModel>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    showModalSpinner = false;

    toastOptions: ToastOptions = {
        title: '',
        showClose: true,
        timeout: 10000,
        theme: 'material',
    };

    constructor(
        private patientService: PatientService,
        private route: ActivatedRoute,
        private toastyService: ToastyService
    ) {
    }

    ngOnInit(): void {
        const studyId = parseInt(this.route.snapshot.params.projectId, 10);
        this.showModalSpinner = true;
        this.patientService.getPatientsByStudyIdBrief(studyId).subscribe(
            response => {
                if (response['responseCode'] === 200) {
                    this.dataSource = new MatTableDataSource<PatientModel>(response['data']);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                } else {
                    this.showErrorMessage(response);
                }
                this.showModalSpinner = false;
            }, error => {
                this.showErrorMessage(error);
                this.showModalSpinner = false;
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    showErrorMessage(response: any): void {
        this.toastOptions.title = 'ERROR ' + response['responseCode'];
        this.toastOptions.msg = response['responseMessage'];
        this.toastyService.error(this.toastOptions);
    }
}

interface Patient {
    id: number;
    created: number;
    patientCode: string;
    patientBirthDate: string;
    gender: string;
    phantomData: boolean;
    discontinued: boolean;
    discontinuedUpdated: number;
    modified: number;
    modifyUser: number;
    modifyComment: string;
    visitConfigs: VisitConfig[];
}

interface VisitConfig {
    id: number;
    created: number;
    uploadDate: number;
    expectedVisitDate: number;
    updateUserId: number;
    updateReasonComment: string;
    visit: Visit;
}

interface Visit {
    id: number;
    created: number;
    name: string;
    durationTimeUnit: string;
    durationTimeValue: number;
    baseline: boolean;
    repeatAllowed: boolean;
}
