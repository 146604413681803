import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BasicResponse } from 'src/app/core/interfaces/basic-response';
import { ReadingFormScoringComponent, ReadingFormScoring } from '../../reading-form-scoring.component';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from 'src/app/core/data-management/actions/projects.action';
import { ReadingLevel } from 'src/app/core/constants/reading-level';
import { VisitStatus } from 'src/app/_models/ImagingProject/IF/incidental-findings-config-model';
import { ReadingAdvancedAnalysisService } from 'src/app/_services/reading-advanced-analysis.service';
import { ResponseCode } from 'src/app/core/constants/response-code';
import { ReadingConfigFlexibleService } from 'src/app/_services';
import { ToastOptions, ToastyService } from 'ng2-toasty';

@Component({
  selector: 'app-advanced-analysis-reading-form',
  templateUrl: './advanced-analysis-reading-form.component.html',
  styleUrls: ['./advanced-analysis-reading-form.component.css']
})
export class AdvancedAnalysisReadingFormComponent extends ReadingFormScoringComponent implements OnInit, OnDestroy, ReadingFormScoring {

  currentReading;
  readingForm: FormGroup = this.fb.group({});
  visitsArrayForm: FormArray = this.fb.array([]);
  selectedVisit = 0;
  advancedAnalysisViewerData: any;
  sequentialLock: boolean = false;
  smartRead: boolean = false;
  flexibleSubscription: Subscription;
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private readingAdvancedAnalysisService: ReadingAdvancedAnalysisService,
    private toastService: ToastyService,
    private flexibleConfigService: ReadingConfigFlexibleService
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Advanced Analysis'));
    this.initOnCurrentReading();
    this.readingSeriesInitiated.subscribe(resp => {
      if (resp)
        this.chooseActiveVisitByStatus();
    });
    this.viewerDataForStatisticDataSubject.subscribe(response => {
      if (response) {
        const modifiedData = this.modifyStatisticsData(response);
        this.submitVisit(modifiedData)
      }
    })
  }

  modifyStatisticsData(data: { rois: any[], vois: any[] }) {
    data.rois.forEach(roi => {
      roi.visit.id = this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === roi.seriesId)).id;
    });
    data.vois.forEach(voi => {
      voi.visit.id = this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === voi.seriesId)).id;
      voi.rois.forEach(roi => {
        roi.visit.id = this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === voi.seriesId)).id;
      });
    });
    return data;
  }

  ngOnDestroy(): void {
    if (this.flexibleSubscription)
      this.flexibleSubscription.unsubscribe();
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      if (currentReading.readingLevel === ReadingLevel.LONGITUDINAL) {
        this.switchSubmitBtnDisabledSubject.next(false);
      }
      this.readingForm = new FormGroup({});
      this.visitsArrayForm = new FormArray([]);
      this.currentReading = currentReading;
      this.initReadingForm();
      this.getFlexibleConfig();
    });
  }

  initReadingForm(): void {
    this.currentReading.visits = this.currentReading.visits.sort((a, b) => a.visitOrder - b.visitOrder);

    this.currentReading.visits.forEach((v, visitIndex) => {
      //create visit form
      const visitFormControl = new FormGroup({
        id: new FormControl(v.id),
        visitConfigId: new FormControl(v.visitConfigId),
        status: new FormControl(v.status),
        visitOrder: new FormControl(v.visitOrder),
      });

      (<FormArray>this.visitsArrayForm).push(visitFormControl);
      visitFormControl.valueChanges.subscribe(() => {
        this.checkValidity(visitFormControl);
      });
    });
    this.readingForm.addControl('visits', this.visitsArrayForm);
    this.checkValidity(this.readingForm);
  }

  getFlexibleConfig() {
    this.flexibleSubscription = this.flexibleConfigService.getById(this.currentReading.flexibleConfigId).subscribe(response => {
      if (response.responseCode === ResponseCode.OK) {
        if (response.data.config.readingLevelConfig.includes('SMART')) {
          this.smartRead = true;
        }
        if (response.data.config.readingLevelConfig.includes('SEQUENTIAL')) {
          this.sequentialLock = true;
        }
      }
    })
  }

  chooseActiveVisitByStatus() {
    const activeVisitIndex = this.currentReading.visits.findIndex(visit => [VisitStatus.IN_PROGRESS, VisitStatus.NEW_EDITABLE].includes(visit.status));
    if (activeVisitIndex !== -1)
      this.onChangeActivatedVisit(activeVisitIndex);
  }

  onChangeActivatedVisit(index) {
    this.selectedVisit = index;
    this.activedVisitSubject.next(this.currentReading.visits[index]);
  }

  disableBySequntialLockAndSmartRead(changeStatus: boolean = false, visitIndex: number) {
    const reading = this.currentReading;
    const noUpload = reading.visits[visitIndex].noUpload;
    const PreviouseVisitNotLocked = visitIndex === 0 ? false : reading.visits.findIndex(v => v.status !== VisitStatus.DONE && !v.noUpload) < visitIndex;

    return noUpload ? true : (!this.sequentialLock ? false : (changeStatus ? PreviouseVisitNotLocked :
      ((!this.smartRead &&
        reading.visits[visitIndex].status !== VisitStatus.IN_PROGRESS &&
        reading.visits[visitIndex].status !== VisitStatus.NEW_EDITABLE
      ) ||
        reading.visits[visitIndex].status === VisitStatus.NOT_AVAILABLE ||
        (reading.visits[visitIndex].status !== VisitStatus.DONE &&
          visitIndex > 1 &&
          (reading.visits.findIndex(v => v.status !== VisitStatus.DONE && !v.noUpload) < visitIndex))
      )))
  }

  private checkValidity(formControl) {
    this.switchSubmitBtnDisabledSubject.next(formControl.invalid);
  }

  getEndpointName(): string {
    return 'Advanced Analysis';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingAdvancedAnalysisService.getReading(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingAdvancedAnalysisService.startReading(readingId)
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number; }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    this.updateReadingNavigator(this.currentReading);
    const readingData = {
      spentSeconds: data.spentSeconds,
      comment: ""
    }
    return this.readingAdvancedAnalysisService.updateReading(readingId, readingData);
  }

  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    return this.readingAdvancedAnalysisService.completeReading(studyId, this.currentReading);
  }

  submitVisit(statData?: any) {
    const visitIdForStat = statData ? this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === statData?.vois[0]?.seriesId)).id : null;
    const visit = this.currentReading.visits[this.selectedVisit];
    const data = {
      doneVisit: !statData,
      comment: "",
      scoring: statData || visit.scoring
    }

    this.readingAdvancedAnalysisService.lockTimepoint(this.currentReading.studyId, statData ? visitIdForStat : visit.id, data).subscribe(() => {
      if (!statData) {
        this.toastOptions.title = 'SUCCESS: Timepoint is successfully saved and locked';
        this.toastService.success(this.toastOptions);
        visit.status = VisitStatus.DONE;
        const nextVisit = this.currentReading.visits.find(v => v.visitOrder > visit.visitOrder && !v.noUpload && v.status !== VisitStatus.NOT_AVAILABLE);
        if (nextVisit) {
          nextVisit.status = VisitStatus.IN_PROGRESS;
          this.chooseActiveVisitByStatus();
        }
        else
          this.updateReadingListAfterAllVisitDone(this.currentReading);
      }
      else {
        this.toastOptions.title = 'SUCCESS: Statistic data successfully stored!';
        this.toastService.success(this.toastOptions);
        this.currentReading.visits.find(v => v.id === visitIdForStat).scoring = statData;
      }
    }, (error => {
      this.toastOptions.title = 'ERROR: ' + error.message;
      this.toastService.error(this.toastOptions);
    }))
  }

  updateReadingNavigator(reading: any) {
    if (!this.readingListUpdatedSubject.closed)
      this.readingListUpdatedSubject.next(reading);
  }

  updateReadingListAfterAllVisitDone(reading) {
    if (!this.readingListUpdatedAfterVisitsDoneSubject.closed)
      this.readingListUpdatedAfterVisitsDoneSubject.next(reading);
  }

  getVisitControls(visitIndex: number) {
    return (<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex])).controls;
  }

  clearForm(): void {
  }

}
