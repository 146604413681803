import {Component, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {StudyModel} from '../../../_models/ImagingProject/study-model';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmSigningDialogComponent} from '../confirm-signing-dialog/confirm-signing-dialog.component';
import {ActivatedRoute, Router} from '@angular/router';
import {ReportGeneratorService} from '../../../_services/report-generator.service';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {SignStudyReportModel} from '../../../_models/ImagingProject/sign-model';
import {ToastService} from '../../../_services/internal/toast.service';
import {map, mergeMap, takeUntil} from 'rxjs/operators';
import {ResourceService} from '../../../_services/resource.service';
import {AuthenticationService, ImagingProjectService, UserService} from '../../../_services';
import {PatientService} from '../../../_services/patient.service';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-sign-details',
  templateUrl: './sign-details.component.html',
  styleUrls: ['./sign-details.component.css']
})
export class SignDetailsComponent implements OnInit {
  projectId: number;
  study: StudyModel;
  studyId: number;
  reportId: number;
  pdfUrl: SafeResourceUrl;
  loading = false;
  report: SignStudyReportModel;
  showSigned = false;
  allowToSign?: boolean;
  nextReportId ;

  constructor(
    private route: ActivatedRoute,
    private reportGeneratorService: ReportGeneratorService,
    private resourceService: ResourceService,
    private imagingProjectService: ImagingProjectService,
    private patientService: PatientService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toastService: ToastService,
    private dialog: MatDialog,
    private store: Store,
  ) {
  }

  ngOnInit() {
    console.log('ngOnInit');
    this.store.dispatch(new SetPageHeaderTitle('Signing Report'));
    this.route.queryParams.subscribe(queryParams => {
      console.log('queryParams: ', queryParams);
      this.showSigned = queryParams.hasOwnProperty('signed');

      if (this.studyId && this.reportId) {
        this.loadReportDetails();
      }
    });
    this.route.params.subscribe(params => {
      this.studyId = params.studyId;
      this.reportId = +params.reportId;

      this.loadReportDetails();
    });
  }

  loadReportDetails() {
    console.log('loadReportDetails this.reportId: ', this.reportId);
    this.loading = true;
    this.pdfUrl = undefined;
    this.report = undefined;
    this.reportGeneratorService.getReportByStudyIdAndId(this.studyId, this.reportId)
      .pipe(
        map((report) => {
          console.log('report: ', report);
          this.report = report as SignStudyReportModel;

          if (this.report.mimeType === 'text/csv') {
            this.navigateToList();
            return null;
          }

          this.getReports();
          this.getStudyData();
          this.getPatientData();

          if (this.report.visitConfigId) {
            this.getVisitConfigData();
          }
          return this.report;
        }),
        mergeMap(report => {
          if (!report) {
            return [];
          }
          let reportLink;
          this.allowToSign = !report.signDate;
          if (this.showSigned && !this.allowToSign && report.signedResourceLink) {
            reportLink = this.resourceService.buildResourceLink('REPORT', report.signedResourceLink);
          } else {
            reportLink = this.resourceService.buildResourceLink(report.endpoint, report.resourceLink);
          }

          return this.resourceService.getResource(reportLink);
        })
      )
      .subscribe(
        (pdfResource) => {
          const unsafeUrl = URL.createObjectURL(pdfResource.body);
          this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(unsafeUrl);
          this.loading = false;
        },
        error => {
          console.error('error: ', error);
          this.loading = false;
          this.toastService.respError(error);
        }
      );
  }

  compare(a: SignStudyReportModel, b: SignStudyReportModel) {
    // Signed reports goes end
    if (a.signDate && !b.signDate) {
      return 1;
    }
    if (!a.signDate && b.signDate) {
      return -1;
    }
    // If readerType is the same, compare by id
    return a.id - b.id;
}

  getReports() {
    if (!this.studyId) {
      return;
    }

    this.reportGeneratorService.getReportsByStudyId(this.studyId).subscribe(
      (response) => {
        let reportsList = response as SignStudyReportModel[];
        // move signed to end
        reportsList = reportsList.sort((a, b) => this.compare(a, b));
        const reportIndex = reportsList.findIndex(r => r.id === this.reportId);
        if (reportIndex >= 0 && reportIndex < reportsList.length - 1) {
          this.nextReportId = reportsList[reportIndex + 1].id;
        } else {
          this.reportId = undefined;
          this.nextReportId = undefined;
        }
      },
      error => {
        console.error('Error fetching report list: ', error);
        this.toastService.respError(error);
        this.nextReportId = undefined;
      }
    );
  }

  getStudyData() {
    if (!this.studyId) {
      return;
    }

    this.imagingProjectService.getStudy(this.studyId).subscribe(
      (studyResponse) => {
        this.study = studyResponse.data;
      },
      error => {
        console.error('Error fetching report list: ', error);
        this.toastService.respError(error);
      }
    );
  }
  getPatientData() {
    if (!this.report.studyId) {
      return;
    }

    this.patientService.getPatientsByStudyIdBrief(this.studyId).subscribe(
      (patientsResponse) => {
        const patient = (patientsResponse?.data ?? []).find(p => p.id === this.report.patientId);
        this.report.patientCode = patient?.patientCode || 'N/A';
      },
      error => {
        console.error('Error fetching report list: ', error);
        this.toastService.respError(error);
        this.report.patientCode = 'N/A';
      }
    );
  }

  getVisitConfigData() {
    if (!this.report.visitConfigId) {
      return;
    }

    this.imagingProjectService.getVisitConfig(this.report.visitConfigId.toString()).subscribe(
      (visitConfigResponse) => {
        this.report.visitName = visitConfigResponse?.data?.visitName || 'N/A';
      },
      error => {
        console.error('Error fetching report list: ', error);
        this.toastService.respError(error);
        this.report.visitName = 'N/A';
      }
    ); }

  openSignConfirmation() {

    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.studyId,
        patientId: this.report.patientId,
        visitConfigId: this.report.visitConfigId
      }
    });


    dialogRef.afterClosed().subscribe(data => {
      if (!data?.jwt) {
        if (!data?.canceled) {
          this.toastService.error('ERROR', 'Sign Report Failed.<br/>Please enter valid credentials');
        }
        return;
      }
      this.loading = true;

      this.reportGeneratorService.signReport(this.studyId, this.reportId, data.jwt).subscribe(
        (signResponse) => {
          this.router
            .navigate([`/sign/${this.studyId}/${this.reportId}`], {queryParams: { signed: true }});
          this.loading = false;
        },
        error => {
          this.toastService.respError(error);
          this.loading = false;
        }
      );
    });
  }

  navigateToOriginal() {
    this.router.navigate([`/sign/${this.studyId}/${this.reportId}`]);
  }
  navigateToSigned() {
    this.router.navigate([`/sign/${this.studyId}/${this.reportId}`], {queryParams: { signed: true }});
  }
  navigateToList() {
    this.router.navigate([`/sign/${this.studyId}`]).then(r => {});
  }

  handleGoToNext() {
    if (this.nextReportId >= 0) {
      this.router.navigate([`/sign/${this.studyId}/${this.nextReportId}`]).then(r => {});
    }
  }
}
