import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ImagingProjectKLFlowDialogComponent} from '../imaging-project-klflow-dialog/imaging-project-klflow-dialog.component';
import {ImagingProjectReadingKLBasicComponent} from '../imaging-project-reading-klbasic/imaging-project-reading-klbasic.component';
import {ImagingProjectReadingKLAdjudicationComponent} from '../imaging-project-reading-kladjudication/imaging-project-reading-kladjudication.component';
import {FormMode} from '../../../../../core/constants/form-mode';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {EndpointModel} from '../../../../../_models/ImagingProject/endpoint-model';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';


@Component({
  selector: 'app-imaging-project-reading-klversion-selector',
  templateUrl: './imaging-project-reading-klversion-selector.component.html',
  styleUrls: ['./imaging-project-reading-klversion-selector.component.css']
})
export class ImagingProjectReadingKLVersionSelectorComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();

  @ViewChild('formBasicKL') formBasicKL: ImagingProjectReadingKLBasicComponent;
  @ViewChild('formAdjudicationKL') formAdjudicationKL: ImagingProjectReadingKLAdjudicationComponent;

  kandlVersionFiled = new FormControl('', [
    Validators.required
  ]);

  readingVersion = null;
  showFlow = false;

  _configModel: any;

  _configModelBasic = {
    readingVersion: null,
    readingLevel: null,
    readingType: null,
    modalities: [],
    readers: [],
  };

  _configModelAdjudication = {
    readingVersion: null,
    readingLevel: null,
    readingType: null,
    modalities: [],
    readers: [],
    adjudicationRule: null,
    adjudicationBlind: null,
    adjudicationFinalReportWithoutDisagreementRule: null,
    adjudicationFinalReportWithDisagreementRule: null,
    adjudicationReader: null,
  };


  @Input() public mode: FormMode;
  @Input() public endpoints: EndpointModel[];

  @Input() selectedConfig: any;

  @Input('configModel')
  set configModel(value: any) {
    if (value != null) {
      this.readingVersion = value.readingVersion;
    }
    this._configModel = value;
  }

  get configModel(): any {
    return this._configModel;
  }

  readonly readingVersions = ReadingVersion;
  readonly formModes = FormMode;

  constructor(public dialogKLFlow: MatDialog) {
    super();
  }

  ngOnInit() {
    this.showFlow = false;
  }

  onKLVersionChange(event) {
    this.readingVersion = event.value;
    // reset models when switch reading type.
    if (event.value === this.readingVersions.KANDL_BASIC) {
      this._configModel = JSON.parse(JSON.stringify(this._configModelBasic));
      this._configModel['readingVersion'] = event.value;
    } else if (event.value === this.readingVersions.KANDL_ADJUDICATION) {
      this._configModel = JSON.parse(JSON.stringify(this._configModelAdjudication));
      this._configModel['readingVersion'] = event.value;
    }

    if (this._configModel != null && this._configModel['readingVersion'] != null) {
      this._configModel['readingVersion'] = event.value;
    }
  }

  showHideFlow() {
    const dialogKLFlowRef = this.dialogKLFlow.open(ImagingProjectKLFlowDialogComponent, {
      height: '600px',
      width: '1024px',
      disableClose: true,
      data: {readingVersion: this.readingVersion}
    });

    dialogKLFlowRef.afterClosed().subscribe(result => {
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  formSubmitEvent(event) {
    this.clickSubmit.emit(event);
  }

  formCancelEvent(event) {
    this.clickCancel.emit(event);
  }

}
