export class PatientQueryOptions {
    PatientName: string;
    PatientID: string;
    Sex: string;
    BirthDate: Date;
}

export class StudyQueryOptions {
    StudyID: string;
    ModalitiesInStudy: string[];
    AccessionNumber: string;
    ReferDoctorName: string;
    StudyStartDate: Date;
    StudyEndDate: Date;
    StudyTimeStart: Date;
    StudyTimeEnd: Date;
    StudyInstanceUID: string;
}

export class SeriesQueryOptions {
    SeriesDescription: string;
    SeriesDateStart: Date;
    SeriesDateEnd: Date;
    SeriesInstanceUID: string;
}

export class QueryOptions {
    PatientsOptions: PatientQueryOptions;
    StudiesOptions: StudyQueryOptions;
    SeriesOptions: SeriesQueryOptions;

    constructor() {
        this.PatientsOptions = new PatientQueryOptions();
        this.StudiesOptions = new StudyQueryOptions();
        this.SeriesOptions = new SeriesQueryOptions();
    }
}

export class MapData {
    name: string;
    sopInstanceUID: string;
}

export class PointROI {
    x: number;
    y: number;
}

export class QueryGOI {
    seriesInstanceUID: string;
    isChanged: boolean;
    imageScale: number;
    seriesId: number;
    jointType: string;
    primaryLocation: string;
    visitId: number;
    pixelSpacing: number[];
    sliceThickness: number;

    constructor() {
        this.isChanged = true;
    }
}

export class QueryROI extends QueryGOI{
    sopInstanceUID: string;
    roiName: string;
    roiType: string;
    roiPoints: PointROI[];
    maps: MapData[];
    baselines: string[];
    sliceNumber: number;

    constructor() {
        super();
        this.maps = [];
        this.roiPoints = [];
        this.baselines = [];
        this.isChanged = true;
    }
}

export class QueryVOI extends QueryGOI {
    voiName: string;
    queryROIs: QueryROI[];

    constructor() {
        super();
        this.queryROIs = [];
    }
}

export class PMInfo {
    mapName: string;
    seriesInstanceUID: string;
    sopInstanceUIDs: string[];
}

export class QueryCell {
    seriesId: number;
    seriesInstanceUID: string;
    numFrames: number;
    pixelSpacing: number[];
    sliceThickness: number;
    numOfSlices: number;
    pmInfos: PMInfo[];
    orderType: string;
}

export class QueryContainer {
    height: number;
    width: number;
}

export class QueryFrame {
    sopInstanceUID: string;
    containter: QueryContainer;
    height: number;
    width: number;
}

export class QueryDEMRIQ {
    cell: QueryCell;
    frames: QueryFrame[];
    psSopInstanceUID: string;
    subject: string;
    readingId: number;
    institution: string;
}

export class MapInfo {
    code: string;
    name: string;
    seriesInstanceUID: string;
    sopInstanceUIDs: string[];
}

export class SeriesData {
    seriesId: number;
    divId: string;
    mapInfo: MapInfo
    visitName: string;
}

export class QueryGradient {
    seriesLabel: string;
    seriesData: SeriesData[];
    rootURL: string;
    gradientWidth: number;
    gradientHeight: number;
    gradientMethod: string;
    isChanged: boolean;

    constructor() {
        this.seriesData = []
        this.gradientWidth = 100;
        this.gradientHeight = 400;
        this.gradientMethod = '95th-percentile'; // or maximum
        this.isChanged = false;
    }
}
