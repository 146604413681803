import {
  AfterViewChecked,
  AfterViewInit, ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { SeriesNavigatorComponent } from 'src/app/components/controls/series-navigator/series-navigator.component';
import { forkJoin, interval, Observable, Subject, Subscription } from 'rxjs';
import {
  EventService,
  ImagingProjectService,
  LeadToolsActionManagerService,
  ReadingConfigFlexibleService,
  ReadingDemriqService,
  StudySequenceLabelService,
  StudyUserService,
  ViewerAuthenticationService
} from 'src/app/_services';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from 'src/app/core/data-management/actions/projects.action';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import { BaseFlexibleConfig } from 'src/app/_models/ImagingProject/base-flexible-config';
import { ViewerCoreLtComponent } from '../../Viewer/viewer-core-lt/viewer-core-lt.component';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DemriqRegionLocationDetailsDialogComponent } from './demriq-region-location-details-dialog/demriq-region-location-details-dialog.component';
import { DEMRIQReadingModel } from 'src/app/_models/DEMRIQ/demriq-reading-model';
import { DEMRIQVOIModel } from 'src/app/_models/DEMRIQ/demriq-voi-model';
import { DEMRIQROIModel } from 'src/app/_models/DEMRIQ/demriq-roi-model';
import { ActivatedRoute, Data } from '@angular/router';
import * as _ from 'lodash';
import { Demriq_Regions } from 'src/app/_models/DEMRIQ/demriq-regions.model';
import { MessageDialogComponent } from 'src/app/components/controls/message-dialog/message-dialog.component';
import { takeUntil } from 'rxjs/operators';
import { ReadingNavigatorComponent } from '../reading-navigator/reading-navigator.component';
import { ReadingFormBaseComponent } from '../reading-form-base.component';

interface ViewsLayoutConfiguration {
  col: number;
  row: number;
  sizex: number;
  sizey: number;
}

@Component({
  selector: 'app-demriq',
  templateUrl: './demriq-form.component.html',
  styleUrls: ['./demriq-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DEMRIQFromComponent extends ReadingFormBaseComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChildren('viewerLT', { read: ViewContainerRef }) viewerLTs: QueryList<ViewContainerRef>;
  viewerLT: ViewContainerRef;
  @ViewChild('viewerContainer', { read: ElementRef }) viewerContainer: ElementRef;
  @ViewChild('seriesNavigator') seriesNavigator: SeriesNavigatorComponent;
  selectedTab = 0;
  newViewer: any = null;
  newLTViewer: any = null;
  isActiveLTViewer = false;
  openedNumberOfImages = 0;
  viewsLayoutConfigurations: ViewsLayoutConfiguration[] = [];
  readingList: Array<DEMRIQReadingModel> = [];
  currentReading: DEMRIQReadingModel;
  userId: number;
  study: any;
  spentSecondsSubscription: Subscription;
  jointFingerDetailsSubscription: Subscription;
  selectedAreaSubscription: Subscription;
  selectedRegionSubscription: Subscription;
  deleteLastROISubscription: Subscription;
  spentSeconds = 0;
  showModalSpinnerLoadingData = true;
  visitedSeriesCounter = 0;
  visitConfig: any;
  readingSeries = [];
  allseries = [];
  readingSeriesDetails = [];
  isExpanded = true;
  readingLevel: string;
  validationMessages: string[];
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  validUids: string[] = [];
  availableSequenceLabels: SequenceLabelModel[] = [];
  defaultSequenceLabelIds: number[] = [];
  readingConfigResponse: any;
  resizeInterval: any = null;
  regionsToScore: any = [];
  primaryLocations: any = [];
  bodyLocationToScore: string;
  visitsForm: FormGroup;

  viewerData = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;
  submitMode: boolean;
  allRegions = [];

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    private studySequenceLabelService: StudySequenceLabelService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private readingDemrigService: ReadingDemriqService,
    private toastyService: ToastyService,
    private store: Store,
    private readingConfigService: ReadingConfigFlexibleService,
    private authenticationService: ViewerAuthenticationService,
    private imagingProjectService: ImagingProjectService,
    private studyUserService: StudyUserService,
    public utils: Utils,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    private dialog: MatDialog,
    private actionManagerService: LeadToolsActionManagerService,
    public eventService: EventService
  ) {
    super(route, cdr, componentFactoryResolver, eventService, utils);
  }

  ngOnInit(): void {
    // modal spinner
    this.showModalSpinnerLoadingData = true;
    this.store.dispatch(new SetPageHeaderTitle('DEMRIQ Scoring Form'));
    this.userId = +JSON.parse(localStorage.getItem('userId'));
    this.study = JSON.parse(localStorage.getItem('project'));
    this.configureLayouts();
    const serviceSources = [
      this.readingDemrigService.getReadingByStudyIdAndReaderId(this.study.id, this.userId),
      this.readingConfigFlexibleService.getByStudyId(this.study['id'])
    ];
    forkJoin(serviceSources).subscribe(
      values => {
        this.showModalSpinnerLoadingData = false;
        const [readingResponse, readingConfigResponse] = values as any;
        if (readingResponse['responseCode'] === 200 && readingConfigResponse['responseCode'] === 200) {
          this.readingConfigResponse = readingConfigResponse;
          this.refreshReadingsList(readingResponse);
        } else if (readingResponse['responseCode'] !== 200) {
          this.showErrorMessage(readingResponse);
        } else if (readingConfigResponse['responseCode'] !== 200) {
          this.showErrorMessage(readingConfigResponse);
        }
      },
      (error) => this.showErrorMessage(error.error)
    );
    this.spentSecondsSubscription = interval(1000)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
      this.spentSeconds++;
    });
    this.jointFingerDetailsSubscription = this.readingDemrigService.fingerJointDetails
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
      if (data) {
        const openedCell = this.newLTViewer.instance.divIDs;
        if (openedCell.length === 0) {
          this._showNoImageDialog();
          return;
        }
        const roiData = this.newLTViewer.instance.detailsOfROIs();
        this.onOpenJoinFingerDialog(roiData);
        }
      }
    );
    this.selectedAreaSubscription = this.readingDemrigService.selectedJoint
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          const info = { viewerComponent: this.newLTViewer.instance };
          this.actionManagerService.drawCustomFreehand(
            data.color,
            data.region,
            data.primaryLocation,
            info
          );
        }
      }
    );

    this.readingDemrigService.selectedRegions
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          this.actionManagerService.setSelectedRegions(data.selectedRegions);
        }
      }
    );

    this.deleteLastROISubscription = this.readingDemrigService.deleteLastROI
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          const info = { viewerComponent: this.newLTViewer.instance };
          this.newLTViewer.instance.deleteLastROI(info);
        }
      }
    );
  }

  showReadingForm(): void {
    this.isExpanded = true;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  hideReadingForm(): void {
    this.isExpanded = false;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  openReadingsIdListDialog() {
    const dialogRef = this.dialog.open(ReadingNavigatorComponent, {
      width: '650px',
      height: '556px',
      data: this.readingList,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((readingId: any) => {
      if (readingId && readingId !== this.currentReading.id)
        this.skipCurrentReadingAndUpdate(readingId);
    });
  }

  ngOnDestroy(): void {
    if (this.currentReading) {
      this.readingDemrigService.updateReading(
        this.study.id, this.currentReading.id, this.spentSeconds
      ).subscribe(response => {
      });
    }

    this.destroy();

    this.readingDemrigService.onSelectedJoint(null);
    this.readingDemrigService.onSelectedRegions(null);
    this.readingDemrigService.deleteLastDrawnROI(false);
    this.readingDemrigService.onOpenFingerJointDetails(false);
    try {
      this.actionManagerService.activeJoint.visitConfigId = 0;
      this.actionManagerService.activeJoint.seriesId = 0;
      this.actionManagerService.activeJoint.regionToScore = '';
      this.actionManagerService.activeJoint.primaryLocation = '';
      this.actionManagerService.activeJoint.color = '';
      this.actionManagerService.activeJoint.timepoint = '';
      this.newLTViewer.instance.actionManagerService.activeJoint = this.actionManagerService.activeJoint;
    } catch (error) {

    }
  }

  ngAfterViewInit(): void {
    this.viewerLTs.changes
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((comps: QueryList<ViewContainerRef>) => {
      if (!this.viewerLT && comps.first) {
        this.viewerLT = comps.first;
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges(); // fix ExpressionChangedAfterItHasBeenCheckedError
  }

  configureFromConfigFlexible(response) {
    if (response['data']) {
      const currentConfig = response['data'].find(d => d.config['endpoint'].name.toLowerCase() === 'demriq'
        && this.currentReading.configId === d['id'])
      if (currentConfig) {
        this.readingLevel = currentConfig.config.readingLevel;
        this.regionsToScore = currentConfig.config.regionsToScore;
        this.primaryLocations = currentConfig.config.bodyLocationToScore ? currentConfig.config.primaryLocations : ['Thumb', 'Index', 'Middle', 'Ring', 'Little'];
        this.bodyLocationToScore = currentConfig.config.bodyLocationToScore ? currentConfig.config.bodyLocationToScore : 'hand';
        this.allRegions = Demriq_Regions.getAllRegions(this.primaryLocations, this.regionsToScore);
      }
      else{
        this.toastOptions.title = 'ERROR Reading configuration not existed'
        this.toastOptions.msg = 'There is no active reading configuration for this reading'
        this.toastyService.error(this.toastOptions);
      }
    }    
  }

  refreshReadingsList(response) {
    this.readingList = [];
    const read = response['data'];
    if (read != null) {
      this.readingList = read;
      if (this.readingList.length) {
        this.initReading(this.readingList[0]);
      }
    }
  }

  initReading(reading) {
    this.resetScoringForms();
    this.currentReading = reading;
    this.showModalSpinnerLoadingData = true;
    this.currentReading.visits = reading['visits'];
    this.visitedSeriesCounter = 0;
    this.visitConfig = this.currentReading.visits.filter(visit => !visit['noUpload']);
    this.createScoringForm();
    this.currentReading.readingLevel = this.readingLevel;
    this.spentSeconds = this.currentReading.timeSpent || 0;
    if (this.currentReading.status.code < 700) {       // in_progress
      this.readingDemrigService.startReading(this.currentReading.studyId, this.currentReading.id).subscribe(response => {
        if (response['responseCode'] === 200) {
          this.currentReading.status = response['data'].status;
        } else {
          this.showErrorMessage(response);
        }
      });
    }
    this.configureFromConfigFlexible(this.readingConfigResponse);
    const viewerDataResults: Array<Observable<any>> = [];
    this.visitConfig.forEach((visits, index) => {
      this.imagingProjectService.getVisitConfig(visits.visitConfigId).subscribe(({data}) => {
        const visitConfig = data;
        if (visitConfig != null) {
          visitConfig.series.forEach(visitSeries => {
            const series = visitSeries;
            if (series != null) {
              this.allseries.push(series);
              series.seriesId = visitSeries.id;
              series.seriesInstanceUID = visitSeries.seriesUID;
              series.visitConfigId = visits.visitConfigId;
              series.seriesVisitName = visitConfig.visitName;
              const visitInfo = this.getVisitInfo(series.seriesId);
              series.timepoint = (visitInfo && visitInfo.timepoint) ? visitInfo.timepoint : 'Missing';
              const info = { selectedPage: 'Reading' };
              viewerDataResults.push(this.utils.initViewerData(series, this, info));
            }
          });
        }

        if (viewerDataResults.length) {
          this.viewerDataIsLoading = true;
          forkJoin(viewerDataResults).subscribe(() => {
            this.viewerData['shouldHidePatient'] = true;
            this.viewerDataIsReady = true;
            this.viewerDataIsLoading = false;

            this.openLTViewer(['-1']);
            if (this.viewerData.studyUserRoles && this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
              this.viewerData.toolbarExcludeItems = ['ShowDicom'];
            }

            // this._updateViewerToolbar();
          });
        }
      });
    });
    if (this.currentReading.visits) {
      this.readingSeries = [];
      this.readingSeriesDetails = [];
      const seriesIds = [];
      this.currentReading.visits.forEach(v => {
        v.series.forEach(s => {
          this.readingSeries.push(s);
          seriesIds.push(s.seriesId);
        });
      });
      this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
        this.initDefaultSequenceLabels(configResp.data);
        this.showModalSpinnerLoadingData = false;
      });
      this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).subscribe(response => {
        this.showModalSpinnerLoadingData = false;
        if (response['responseCode'] === 200) {
          if (response['data']) {
            this.readingSeriesDetails = response['data'];
            this.readingSeriesDetails.forEach(sd => {
              const visit = this.getVisitInfo(sd.id);
              if (visit != null) {
                sd['seriesVisitName'] = visit.timepoint;
                sd['visitConfigId'] = visit.visitConfigId;
              }
            });
          }
        } else {
          this.showErrorMessage(response);
        }
      });
    }
  }

  get visitControls() {
    return (this.visitsForm.get('visits') as FormArray).controls;
  }

  getValidity(i) {
    return this.visitControls[i].get('comment').invalid;
  }

  createScoringForm() {
    this.visitsForm = new FormGroup({
      visits: new FormArray([]),
    });

    this.currentReading.visits.forEach(v => {
      const control = new FormGroup({
        vcid: new FormControl(v.visitConfigId),
        vid: new FormControl(v.id),
        data: new FormControl(),
        comment: new FormControl( {value: v['noUpload'] === true ? 'n/a' : v.comment, disabled: v['noUpload'] }, [Validators.maxLength(200)]),
        timepoint: new FormControl(v.timepoint),
      });
      (<FormArray>this.visitsForm.get('visits')).push(control);
    });
  }

  initDefaultSequenceLabels(readingConfig: FlexibleConfig<BaseFlexibleConfig>): void {
    const configSequenceLabels: SequenceLabelModel[] = [];
    readingConfig.config.modalities.forEach(m => {
      m.sequenceLabels.forEach(s => configSequenceLabels.push(s));
    });
    this.availableSequenceLabels = configSequenceLabels;
    if (!!readingConfig.config.anatomySelector) {
      this.defaultSequenceLabelIds = (readingConfig.config.anatomySelector as { id: number }[])
        .filter(label => this.availableSequenceLabels.find(studyLabel => studyLabel.id === label.id)).map(l => l.id);
    } else {
      this.defaultSequenceLabelIds = this.availableSequenceLabels.map(l => l.id);
    }
  }

  getVisitInfo(seriesId) {
    let series;
    this.currentReading.visits.forEach(v => {
      const serie = v.series.find(s => s.seriesId === seriesId);
      if (serie) {
        series = v;
      }
    });
    return series;
  }

  configureLayouts() {
    /* layout configuration for multiple viewers */
    this.viewsLayoutConfigurations.push({ 'col': 0, 'row': 0, 'sizex': 12, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 0, 'sizex': 6, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
  }

  /* --------------------------- navigator methods --------------------------- */

  onVisitedSeriesCounter(event) {
    this.visitedSeriesCounter = event;
  }

  onCloseAllViewers(event) {
    console.log('--- onCloseAllViewers');

    this.newLTViewer.instance.removeAllCells();
  }

  onOpenViewer(event) {
    if (event != null) {
      const seriesArr = [];
      event.forEach((seriesId) => {
        localStorage.setItem('visitConfigId', this.getVisitInfo(seriesId).visitConfigId);
        const series = this.readingSeries.find(s => s.seriesId === seriesId);
        seriesArr.push(series.seriesId);
      });
      this.openLTViewer(seriesArr);
    }
  }

  /* --------------------------- viewer methods --------------------------- */

  openNewLTViewer(seriesInstanceUIDs) {
    this.createViewerCmpInstance(this.viewerLT);
    this.newLTViewer.instance.seriesIds = seriesInstanceUIDs;
    this.newLTViewer.instance.shouldShowDICOM = false; // show scan date
    this.newLTViewer.instance.useHangingProtocol = false;
    this.newLTViewer.instance.shouldApplyProtocol = false;

    this.newLTViewer.instance.onClose
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ serID, shouldCloseViewer }) => {
      this.closeLTViewerSeries(serID, shouldCloseViewer);
    });

    this.newLTViewer.instance.onChangeActiveCell
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
      (objectData) => {
        this.readingDemrigService.onChangeViewerSelectedCell(true);
      });

    this.newLTViewer.instance.onDemriqStatData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if(data !== null){
          const mapData = this.modifyStatisticsData(data);
          let missedArea = false;
          this.allRegions.forEach(r => {
            r.primaryLocations.forEach(location => {
              if (mapData.vois.filter(voi => voi.primaryLocation.toLowerCase() === location.name.toLowerCase() && voi.regionToScore === r.region.split('/').join('_')).length === 0) {
                missedArea = true;
              }
            });
          });
          if (missedArea) {
            this.showModalSpinnerLoadingData = false;
            const dialogMessaegRef = this.dialog.open(MessageDialogComponent, {
              width: '800px',
              disableClose: true,
              data: {
                title: 'Submit',
                message: 'There some areas with missing VOI, are you sure you want to proceed? (see auxiliary table for more info)',
                html: false,
                showOk: true,
                showCancel: true,
              }
            });
            dialogMessaegRef.afterClosed().subscribe(result => {
              if (result === 'ok') {
                this.saveVOIs(mapData);
              }
            });
          } else {
            this.saveVOIs(mapData);
          }
        } else {
          this.showModalSpinnerLoadingData = false;
          this.toastOptions.title = 'Something went wrong.';
          this.toastOptions.msg = 'Please try again later.';
          this.toastyService.error(this.toastOptions);
        }
    });
  }

  saveVOIs(data: any) {
    this.showModalSpinnerLoadingData = true;
    this.readingDemrigService.saveVisitVOIList(data.vois, this.study.id, this.currentReading.id).subscribe(
      resp => {
        if (resp['responseCode'] === 200) {
          //submit reading
          if (this.submitMode)
            this.submitResult();
          else this.showModalSpinnerLoadingData = false;
        }
      });
  }
  modifyStatisticsData(data :{rois: DEMRIQROIModel[], vois: DEMRIQVOIModel[]}) {
    data.rois.forEach(roi => {
      roi.visit.id = this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === roi.seriesId)).id;
      roi.regionToScore = roi.regionToScore.split('/').join('_');
    });
    data.vois.forEach(voi => {
      voi.visit.id = this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === voi.seriesId)).id;
      voi.regionToScore = voi.regionToScore.split('/').join('_');
      voi.rois.forEach(roi => {
        roi.visit.id = this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === roi.seriesId)).id;
        roi.regionToScore = roi.regionToScore.split('/').join('_');
      });
    });
    return data;
  }

  preOpenLTViewer() {
    this.openNewLTViewer(null);
  }

  openLTViewer(seriesInstanceUIDs: string[] = [], sliceNumber?: number) {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1500);

    this.viewerContainer.nativeElement.style.display = '';

    if (this.newLTViewer && this.viewerDataIsReady) {
      this.viewerData['sliceNumber'] = sliceNumber;
      this.viewerData['readingID'] = this.currentReading.id;
      this.newLTViewer.instance.openNewSer(seriesInstanceUIDs, this.viewerData);
      this.openedNumberOfImages = this.newLTViewer.instance.getNumberOfImages();
      return;
    } else {
      this.openNewLTViewer(seriesInstanceUIDs);
    }

    const toolbarExcludeItems = _.get(this.viewerData, 'toolbarExcludeItems', []);
    if (toolbarExcludeItems.length > 0) {
      _.each(toolbarExcludeItems, i => {
        this.newLTViewer.instance.viewerToolbar.setItemProperty(i, 'disabled', true);
      });
    }
  }

  closeLTViewerSeries(serID, shouldCloseViewer) {
    this.seriesNavigator.unmarkSeriesAsOpenedBySeriesId(serID);

    if (shouldCloseViewer) {
      this.viewerContainer.nativeElement.style.display = 'none';
    }
  }

  secondsToHHMMSS() {
    const currentSeconds = this.spentSeconds;
    let hours: number | string = Math.floor(currentSeconds / 3600);
    let minutes: number | string = Math.floor((currentSeconds - (hours * 3600)) / 60);
    let seconds: number | string = currentSeconds - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  skipCurrentReadingAndUpdate(readingId: number) {
    // saving cell's active container's ROIs before removing the cell
    try {
      if (this.newLTViewer.instance.lastInfo && this.newLTViewer.instance.lastInfo !== null) {
        (this.actionManagerService as any).seriesManagerService.saveActiveContainerROIs(this.newLTViewer.instance.lastInfo);
      }
    } catch (error) {
      console.log('Error... ', error);
    }

    this.selectedTab = 0;
    this.showModalSpinnerLoadingData = true;
    this.currentReading.timeSpent = this.spentSeconds;
    this.readingDemrigService.updateReading(
      this.study.id, this.currentReading.id, this.spentSeconds
    ).subscribe(response => {
      this.showModalSpinnerLoadingData = false;
      if (response['responseCode'] === 200) {
        this.skipCurrentReading(readingId ? this.readingList.findIndex(reading => reading.id === readingId) : this.getIndexOfCurrentReading() + 1);
      } else {
        this.showErrorMessage(response);
      }
    });
  }

  getIndexOfCurrentReading() {
    return this.readingList.indexOf(this.currentReading);
  }

  skipCurrentReading(nextIndex: number) {
    if (this.newLTViewer) {
      this.newLTViewer.instance.removeAllCells();
    }
    this.visitedSeriesCounter = 0;
    if (nextIndex >= this.readingList.length) {  // circle
      nextIndex = 0;
    }
    this.initReading(this.readingList[nextIndex]);
  }

  resetScoringForms() {

  }
  onOpenJoinFingerDialog(viewerData: { visitConfigId: number, roisCount: any[]}) {
    let visit = this.visitConfig.find(v => v.visitConfigId == viewerData.visitConfigId)
    const dialogRef = this.dialog.open(DemriqRegionLocationDetailsDialogComponent, {
        width:  '700px',
        data: {
          visitName: visit.timepoint,
          details: viewerData.roisCount,
          availableRegionsToScore: this.regionsToScore,
          primaryLocations: this.primaryLocations,
          title: this.bodyLocationToScore
        },
        disableClose:true
      });
  }

  private _showNoImageDialog(): void{
    this.toastOptions.title = 'INFO Image not found';
    this.toastOptions.msg = 'At least one image must be opened on image viewer';
    this.toastyService.info(this.toastOptions);
  }

  saveReults(submitReading: boolean) {
    const openedCell = this.newLTViewer.instance.divIDs;
    if (openedCell.length === 0) {
      this._showNoImageDialog();
      return;
    }
    this.submitMode = submitReading;
    this.showModalSpinnerLoadingData = true;
    const commentData = (this.visitsForm.get('visits').value as any[]).map(value => {
      return {
        visitId: value.vid,
        comment: value.comment
      }
    });
    this.readingDemrigService.saveVisitComment(commentData, this.study.id).subscribe((response) => {
      if (!this.checkOpenedTimepoints()) {
        this.showModalSpinnerLoadingData = false;
        this.toastOptions.title = 'All timepoints should be open';
        this.toastOptions.msg = 'Please open the DCE-MRI series of all timepoints to submit the reading';
        this.toastyService.warning(this.toastOptions);
      } else {
        this.newLTViewer.instance.demriqStatData();
      }
    }, (error) => {
      this.showModalSpinnerLoadingData = false;
      this.toastOptions.title = 'Something went wrong.';
      this.toastOptions.msg = 'Please try again later.';
      this.toastyService.error(this.toastOptions);
    })
  }

  submitResult() {
     if (this.isReadingValid()) {
      this.showModalSpinnerLoadingData = true;
      this.currentReading.timeSpent = this.spentSeconds;
      this.showModalSpinnerLoadingData = true;
      this.readingDemrigService.completeReading(this.study.id, this.currentReading).subscribe(response => {
        this.showModalSpinnerLoadingData = false;
        if (response['responseCode'] === 200) {
          const index = this.getIndexOfCurrentReading();
          this.readingList.splice(index, 1);
          if (this.readingList.length) {
            this.skipCurrentReading(index);    // ind == nextIndex after splice
          } else {
            this.currentReading = undefined;
          }
        } else {
          this.showErrorMessage(response);
        }
      }, (error) => {
        this.showErrorMessage(error)
      });
    }
  }

  showErrorMessage(response: any): void {
    this.showModalSpinnerLoadingData = false;
    this.toastOptions.title = 'ERROR ' + response['responseCode'];
    this.toastOptions.msg = response['responseMessage'];
    this.toastyService.error(this.toastOptions);
  }

  isReadingValid() {
    this.validationMessages = [];
    return !this.validationMessages.length;
  }

  trackByFn(index: number): number {
    return index;
  }

  openLTViewerFromChildren(data) {
    if (data['seriesId']) {
      if (this.newLTViewer.instance.seriesOpened && this.newLTViewer.instance.seriesOpened[data['seriesId']] != null) {
        if (data['sliceNumber'] != undefined) {
          this.newLTViewer.instance.viewer.layout.get_items().toArray().forEach(cell => {
            if (cell.seriesId === data['seriesId']) {
              cell.currentOffset = parseInt(data['sliceNumber'], 10) - 1;
            }
          });
        }
      } else {
        let sliceNumber = data['sliceNumber'] ? data['sliceNumber'] - 1 : data['sliceNumber'];
        let seriesId = data['seriesId'];
        this.openLTViewer([seriesId], sliceNumber);
      }
    }
  }

  checkOpenedTimepoints(): boolean {
    try {
      if (!this.newLTViewer.instance) {
        return false;
      }

      const openedTimepoints: any[] = [];
      const viewer: any = this.newLTViewer.instance;
      const min_expected_series = this.currentReading.visits.filter(v => v["noUpload"] !== true).length;

      if (viewer.seriesOpened.length < min_expected_series) {
        return false;
      }
      else {
        viewer.viewer.layout.get_items().toArray().forEach(cellItem => {
          const cell: any = cellItem;
          const seriesInfo = viewer.seriesManagerService.getSeriesInfo(cell);
          if (viewer.viewerData[cell.seriesId]) {
            if (!openedTimepoints.includes(viewer.viewerData[cell.seriesId].timepoint) && seriesInfo.isDCE) {
              openedTimepoints.push(viewer.viewerData[cell.seriesId].timepoint);
            }
          }
        });
        return (openedTimepoints.length >= min_expected_series);
      }
    } catch (error) {
      console.log('Error... ', error);
    }
  }
}

