import {Component, OnInit, ViewChild} from '@angular/core';
import {PushAuditTrailsProjects, SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {Select, Store} from '@ngxs/store';
import {ProjectsState} from '../../../core/data-management/states/projects.state';
import {Observable} from 'rxjs';
import {ImagingProjectService} from '../../../_services';
import {ResponseCode} from '../../../core/constants/response-code';
import {ToastService} from '../../../_services/internal/toast.service';
import {MatTableDataSource} from '@angular/material/table';
import {StudyModel} from '../../../_models/ImagingProject/study-model';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {flatMap, map, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Path} from '../../../core/constants/path';

@Component({
  selector: 'app-projects-list',
  templateUrl: './audit-trails-projects-list.component.html',
  styleUrls: ['./audit-trails-projects-list.component.css']
})
export class AuditTrailsProjectsListComponent implements OnInit {

  @Select(ProjectsState.getAuditTrailsProjectIds) projectIdsObservable: Observable<number[]>;
  @Select(ProjectsState.getAuditTrailsProjects) projectsObservable: Observable<StudyModel[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  projectsDataSource: MatTableDataSource<ProjectModel>;
  projects: StudyModel[] = [];
  loading: boolean;

  constructor(private store: Store,
              private imagingProjectService: ImagingProjectService,
              private toastService: ToastService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Audit Trails'));
    this.setLoading(true);
    this.loadLocalProjects()
      .pipe(
        switchMap(() => this.checkAndDownloadNewProject()),
        switchMap(() => this.buildProjectsDataSource())
      )
      .subscribe(() => this.setLoading(false));
  }

  public navigateToDetails(projectId: number): void {
    if (!!projectId) {
      this.router.navigate([`${Path.AUDIT_TRAILS}/${projectId}`]).then(() => {
      });
    }
  }

  private loadLocalProjects(): Observable<void> {
    return this.projectsObservable.pipe(map((p => {
      if (!!p) {
        this.projects = [...this.projects, ...(p)];
      }
    })));
  }

  private checkAndDownloadNewProject(): Observable<void> {
    return this.store.select(ProjectsState.getAuditTrailsProjectIds).pipe(
      map(assignedProjectsIds => this.projectsIdsToDownload(assignedProjectsIds)),
      flatMap(projectsIdsToDownload => this.downloadProjects(projectsIdsToDownload))
    );
  }

  private downloadProjects(projectsIds: number[]): Observable<void> {
    if (!projectsIds || projectsIds.length < 1) {
      return Observable.of(null);
    }
    return this.imagingProjectService.getStudyFullInfoByIds(projectsIds).pipe(map(resp => {
      if (resp.responseCode === ResponseCode.OK) {
        this.saveLocally(resp.data);
        this.projects = [...this.projects, ...resp.data];
      } else if (resp.responseCode === ResponseCode.INTERNAL_SERVER_ERROR) {
        this.toastService.respError(resp);
      }
    }));
  }

  private projectsIdsToDownload(projectsIds: number[]): number[] {
    return projectsIds?.filter(id => !this.projects.find(p => p.id === id));
  }

  private saveLocally(studies: StudyModel[]) {
    this.store.dispatch(new PushAuditTrailsProjects(studies));
  }

  private setLoading(loading) {
    this.loading = loading;
  }

  private buildProjectsDataSource(): Observable<void> {
    const projects = this.projects.map(project => this.mapToProjectModel(project));
    this.projectsDataSource = new MatTableDataSource<ProjectModel>(projects);
    this.projectsDataSource.paginator = this.paginator;
    this.projectsDataSource.sort = this.sort;
    return Observable.of(null);
  }

  private mapToProjectModel(study: StudyModel): ProjectModel {
    return <ProjectModel>{
      projectId: study.id,
      protocolId: study.protocol,
      sponsorName: !!study.sponsor ? study.sponsor.name : 'None'
    };
  }
}

interface ProjectModel {
  projectId: number;
  protocolId: string;
  sponsorName: string;
}


