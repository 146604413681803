import { Injectable } from '@angular/core';
import { DicomHelperService } from '../interfaces/viewer/dicom-helper-service';
import { DicomTag } from '../interfaces/viewer/dicom-tag';
import { CodeSequence } from '../../_models/viewer/TemplateModels';

@Injectable({
  providedIn: 'root'
})
export class LeadToolsDicomHelperService implements DicomHelperService {

  constructor() { }

  supportWindowLevel(cell: any, index: any): boolean {
    if (cell.supportWindowLevel)
      return cell.supportWindowLevel(index);
    else
      return true;
  }

  getBitsPerPixel(dicomDataSet: any): number {
    let samplesPerPixelTag = this.getDicomTag(dicomDataSet, DicomTag.SamplesPerPixel);
    let bitsAllocatedTag = this.getDicomTag(dicomDataSet, DicomTag.BitsAllocated);

    let bpp;
    if (samplesPerPixelTag != null && samplesPerPixelTag.Value.length > 0) {
      bpp = parseInt(this.getTagText(samplesPerPixelTag), 10);
    }

    return bpp * parseInt(this.getTagText(bitsAllocatedTag), 10);
  }

  getTagText(tag: any): any {
    if (!tag)
      return '';

    if (tag.Value && tag.Value.length > 0)
      return tag.Value.join("\\");

    return '';
  }

  tagExists(metadata: any, tagNumber: string): boolean {
    let tag = metadata[tagNumber];
    let noExists: boolean = (typeof tag === "undefined");
    return !noExists;
  }

  isDicomImage(metadata: any): boolean {
    let imageTags = [
      DicomTag.Columns,
      DicomTag.Rows,
      DicomTag.BitsAllocated,
      DicomTag.BitsStored];

    let len = imageTags.length;

    for (let i = 0; i < len; i++) {
      if (!this.tagExists(metadata, imageTags[i]))
        return false;
    }
    return true;
  }

  getTagTextValue(metadata: any, tag: any): any {
    let tagId = this.findTag(metadata, tag);

    if (null != tagId && tagId.Value && tagId.Value.length > 0) {
      return this.getTagText(tagId);
    }
  }

  getDicomImageInformation(metadata: any, frameIndex?: number): any {
    let imageInfo: any = new lt.Controls.Medical.DICOMImageInformation();
    let imageWidthTag = metadata[DicomTag.Columns];
    let imageHeightTag = metadata[DicomTag.Rows];
    let windowWidthTag = metadata[DicomTag.WindowWidth];
    let windowCenterTag = metadata[DicomTag.WindowCenter];
    let smallestTag = metadata[DicomTag.SmallestImagePixelValue]; //Smallest Image Pixel Value
    let largestTag = metadata[DicomTag.LargestImagePixelValue]; //Largest Image Pixel Value
    let hightBitTag = metadata[DicomTag.HighBit]; //hight bit
    let bitsStoredTag = metadata[DicomTag.BitsStored]; //bit Stored
    let interceptTag = this.findTag(metadata, DicomTag.RescaleIntercept); //Rescale Intercept
    let SlopeTag = this.findTag(metadata, DicomTag.RescaleSlope); //Rescale Slope
    let viewPosition = this.findTag(metadata, DicomTag.ViewPosition);
    let patientOrientationTag = this.findTag(metadata, "00200020"); //Paitent orientation, this is just incase the image orientation is not present
    let orientationTag = this.findTag(metadata, "00200037"); //Image Patient Orientation.
    let positionTag = this.findTag(metadata, "00200032"); //Image Patient Position.
    let frameOfReferenceUID = metadata["00200052"]; //Frame of reference UID
    let voiLutSequence = metadata["00283010"]; //VOI LUT Sequence
    let waveFormSequence = metadata["54000100"]; //Wave Form Sequence
    let imageTypeTag = metadata["00080008"]; //Image Type
    let lossyImageCompressionTag = metadata["00282110"]; //The Lossy Image Compression
    let lutDescriptor = null;
    let lutData = null;

    if (voiLutSequence && voiLutSequence.Value && voiLutSequence.Value.length > 0) {
      let seqItem = voiLutSequence.Value[0];
      if (seqItem) {
        lutDescriptor = seqItem['00283002']; //LUT Descriptor
        lutData = seqItem['00283006'];  //LUT Data
      }
    }

    let signedTag = metadata["00280103"]; //Pixel Representation
    let mimeType = metadata["00420012"]; //Imager Pixel Spacing
    let photometricInterpretationTag = metadata["00280004"]; //Photometric Interpretation
    let imagerPixelSpacingTag = metadata["00181164"]; //Imager Pixel Spacing
    let pixelSpacingTag = this.findTag(metadata, "00280030"); //Pixel Spacing
    let nominalScannedPixelSpacingTag = metadata["00182010"]; //Nominal Scanned Pixel Spacing
    let detectorElementSpacingTag = metadata["00187022"]; //Detector Element Spacing
    let perFrameArray = metadata[DicomTag.PerFrameFunctionalGroupsSequence];
    let width;
    let height;
    let bpp = 1;
    let bitsStored = 0;
    let highBit;
    let lowBit = 0;
    let signed = false;

    if (imageWidthTag && imageWidthTag.Value.length) {
      width = this.getTagText(imageWidthTag);
    }
    else {
      width = 0;
    }

    if (imageHeightTag && imageHeightTag.Value.length) {
      height = this.getTagText(imageHeightTag);
    }
    else {
      height = 0;
    }

    bpp = this.getBitsPerPixel(metadata);

    if (null != bitsStoredTag && bitsStoredTag.Value && bitsStoredTag.Value.length > 0) {
      bitsStored = parseInt(this.getTagText(bitsStoredTag), 10);
    }
    else {
      bitsStored = bpp;
    }

    if (perFrameArray != null && perFrameArray.Value && perFrameArray.Value.length > 0) {
      let perFrameItem = perFrameArray.Value[frameIndex];
      if (perFrameItem != null) {
        if (perFrameItem[DicomTag.PlanePositionSequence]) {
          let positionItem = perFrameItem[DicomTag.PlanePositionSequence].Value[0];
          if (positionItem != null) {
            positionTag = positionItem[DicomTag.ImagePositionPatient];
          }
          if (perFrameItem[DicomTag.PlaneOrientationSequence]) {
            let orientationItem = perFrameItem[DicomTag.PlaneOrientationSequence].Value[0];
            if (orientationItem != null) {
              orientationTag = orientationItem[DicomTag.ImageOrientationPatient];
            }
          }
        }
      }

    }

    imageInfo.set_width(parseInt(width, 10));
    imageInfo.set_height(parseInt(height, 10));
    imageInfo.set_bitsPerPixel(bpp);

    if (hightBitTag != null && hightBitTag.Value && hightBitTag.Value.length > 0) {
      highBit = parseInt(this.getTagText(hightBitTag), 10);
      lowBit = highBit - bitsStored + 1;
    } else {
      highBit = bpp - 1;
      imageInfo.set_lowBit(0);
    }

    if (viewPosition != null && viewPosition.Value && viewPosition.Value.length > 0) {
      imageInfo.viewPosition = this.getTagText(viewPosition);
    }

    imageInfo.set_highBit(highBit);
    imageInfo.set_lowBit(lowBit);

    let interceptValue = 0;
    if (interceptTag && interceptTag.Value && interceptTag.Value.length > 0) {
      interceptValue = parseFloat(this.getTagText(interceptTag));
      imageInfo.set_modalityIntercept(interceptValue);
    }
    else {
      imageInfo.set_modalityIntercept(0);
    }

    if (null != SlopeTag && SlopeTag.Value && SlopeTag.Value.length > 0) {
      let slope = parseFloat(this.getTagText(SlopeTag));
      imageInfo.set_modalitySlope(slope == 0 ? 1 : slope);
    }
    else {
      imageInfo.set_modalitySlope(1);
    }

    if (lutData != null && lutData.Value && lutData.Value.length) {
      let lutDescValues = this.getTagText(lutDescriptor).split("\\");

      imageInfo.set_firstStoredPixelValueMapped(lutDescValues[1] | 0);

      let myArray = this.getTagText(lutData).split("\\");
      for (let i = myArray.length; i--;) myArray[i] = myArray[i] | 0;
      imageInfo.set_lutDescriptor(myArray);
    }

    if (signedTag != null && signedTag.Value && signedTag.Value.length) {
      signed = (parseInt(this.getTagText(signedTag), 10) === 1) ? true : false;
      imageInfo.set_signed(signed);
    }
    else {
      imageInfo.set_signed(false);
    }

    let offset = 0;//(signed === true) ? 1 << highBit : 0;
    let mask = ((1 << bitsStored) - 1);

    let minValue = 0;
    if (smallestTag != null && smallestTag.Value && smallestTag.Value.length) {
      let smallestPixel = parseInt(this.getTagText(smallestTag), 10);
      if (smallestPixel < 0) {
        if (smallestPixel > offset) {
          minValue = 0;
        }
        else {
          minValue = smallestPixel + offset;
        }
      }
      else {
        minValue = Math.min(smallestPixel + offset, mask);
      }
    }
    else {
      minValue = 0;
    }

    imageInfo.set_minValue(minValue);

    let maxValue = -1;

    if (largestTag != null && largestTag.Value && largestTag.Value.length) {
      let largestPixel = parseInt(this.getTagText(largestTag), 10);
      if (largestPixel < 0) {
        if (largestPixel > offset) {
          maxValue = 0;
        }
        else {
          maxValue = (largestPixel + offset);
        }
      }
      else {
        maxValue = Math.min(largestPixel + offset, mask);
      }
    }
    else {
      maxValue = 0;
    }

    imageInfo.set_maxValue(maxValue);

    if (windowWidthTag == null)
      windowWidthTag = this.findTag(metadata, DicomTag.WindowWidth);

    if (null != windowWidthTag && windowWidthTag.Value && windowWidthTag.Value.length > 0) {
      imageInfo.set_windowWidth(windowWidthTag.Value[0] >> 0);
    }
    else {
      imageInfo.set_windowWidth(0);
    }

    if (windowCenterTag == null)
      windowCenterTag = this.findTag(metadata, DicomTag.WindowCenter);

    if (null != windowCenterTag && windowCenterTag.Value && windowCenterTag.Value.length > 0) {
      imageInfo.set_windowCenter(windowCenterTag.Value[0] >> 0);
    }
    else {
      imageInfo.set_windowCenter(0);
    }

    if (null != mimeType && mimeType.Value && mimeType.Value.length > 0) {
      imageInfo.set_photometricInterpretation("RGB");
    }
    else {
      if (null != photometricInterpretationTag && photometricInterpretationTag.Value && photometricInterpretationTag.Value.length > 0) {
        imageInfo.set_photometricInterpretation(this.getTagText(photometricInterpretationTag));
      }
      else {
        imageInfo.set_photometricInterpretation("MONOCHROME2");
      }

    }
    let spacing = null;
    let spacingType = null;

    if (null != nominalScannedPixelSpacingTag && nominalScannedPixelSpacingTag.Value && nominalScannedPixelSpacingTag.Value.length > 0) {
      spacing = this.getTagText(nominalScannedPixelSpacingTag);
      spacingType = "detector";
    }

    if (null != imagerPixelSpacingTag && imagerPixelSpacingTag.Value && imagerPixelSpacingTag.Value.length > 0) {
      spacing = this.getTagText(imagerPixelSpacingTag);
      spacingType = "detector";
    }

    if (null != pixelSpacingTag && pixelSpacingTag.Value && pixelSpacingTag.Value.length > 0) {
      spacing = this.getTagText(pixelSpacingTag);

      spacingType = "calibrated";

      let pixelSpacingCalibrationTypeTag = metadata["00280A02"]; //Pixel Spacing Calibration Type

      if (null != pixelSpacingCalibrationTypeTag && pixelSpacingCalibrationTypeTag.Value && pixelSpacingCalibrationTypeTag.Value.length > 0) {
        spacingType = this.getTagText(pixelSpacingCalibrationTypeTag);
      }
    }

    if (null != spacing) {
      let values = spacing.split('\\');
      imageInfo.rowSpacing = parseFloat(values[0]);
      imageInfo.columnSpacing = parseFloat(values[1]);
      imageInfo.spacingType = spacingType;
    }
    else {
      imageInfo.rowSpacing = 0;
      imageInfo.columnSpacing = 0;
    }

    if (null != orientationTag && orientationTag.Value && orientationTag.Value.length > 0) {

      let orientation = this.getTagText(orientationTag);// getElementsByTagName("Image Orientation (Patient)"); // textContent;
      let values = orientation.split('\\');
      if (values.length == 6)
        imageInfo.orientation = values;
    }
    else if (null != patientOrientationTag && patientOrientationTag.Value && patientOrientationTag.Value.length > 0) {
      let orientation = this.getTagText(patientOrientationTag);
      let values = orientation.split('\\');
      if (values.length == 6)
        imageInfo.orientation = values;
    }

    if (null != positionTag && positionTag.Value && positionTag.Value.length > 0) {
      let position = this.getTagText(positionTag);
      let values = position.split('\\');
      imageInfo.position = values;
    }
    else
      imageInfo.position = [1, 1, 1];

    if (frameOfReferenceUID != null && frameOfReferenceUID.Value && frameOfReferenceUID.Value.length > 0) {
      imageInfo.frameOfReferenceUID = this.getTagText(frameOfReferenceUID);
    }
    else
      imageInfo.frameOfReferenceUID = "";

    imageInfo.isWaveForm = (typeof waveFormSequence !== "undefined") && waveFormSequence.Value.length != 0;
    if (imageInfo.isWaveForm) {
      imageInfo.set_photometricInterpretation("RGB");
      imageInfo.backGroundColor = "0xffffff";
    }

    if (null != imageTypeTag && imageTypeTag.Value && imageTypeTag.Value.length > 0) {
      let imageType = this.getTagText(imageTypeTag);
      let values = imageType.split('\\');
      imageInfo.imageType = values;
    }
    else
      imageInfo.imageType = "unknown";

    if (null != lossyImageCompressionTag) {
      let lossyImageCompression = this.getTagText(lossyImageCompressionTag);
      if (lossyImageCompression == "01") {
        imageInfo.lossyImageCompression = true;
      } else {
        imageInfo.lossyImageCompression = false;
      }
    }
    else
      imageInfo.lossyImageCompression = false;

    return imageInfo;
  }

  getDicomTag(metadata: any, tag: string): any {
    let data = metadata[tag];
    return data;
  }

  findTag(dicom: any, tag: string): any {
    for (let key in dicom) {
      if (dicom[key].vr != 'SQ') {
        if (key == tag) {
          return dicom[key];
        }
      }
      else {
        if (dicom[key].Value && dicom[key].Value.length > 0) {
          let length: number = dicom[key].Value.length;

          for (let index = 0; index < length; index++) {
            let value = this.findTag(dicom[key].Value[0], tag);
            if (value != null)
              return value;
          }
        }
      }
    }
    return null;
  }

  getDicomTagValue(metadata: any, tag: string, index?: number): any {
    let dcmTag = this.getDicomTag(metadata, tag);

    index = index || 0;
    if (dcmTag != undefined && dcmTag.Value) {
      if (index < dcmTag.Value.length) {
        let value = dcmTag.Value[index];

        if (dcmTag.vr == 'DA') {
          if (value) {
            let DateJS: IDateJS = <any>(new Date(this.parseDicomDate(value)));

            value = DateJS;
          }
        }

        if (dcmTag.vr == 'TM') {
          if (value) {
            let DateJS: IDateJS = (<any>Date).today().at(this.parseDicomTime(value));

            value = DateJS;
          }
        }
        return value;
      }
    }

    if (dcmTag) {
      return '';
    }
    return undefined;
  }

  cloneDicomTagValue(metadata: any, tag: string): string {
    let retValue: string = "";
    retValue = this.getConvertValue(metadata[tag]);
    return retValue;
  }

  getStudyDateTime(metadata: any, ignoreStudyTime: boolean): IDateJS {
    let studyDateJS: IDateJS = this.getDicomTagValue(metadata, DicomTag.StudyDate);
    let studyTimeJS: IDateJS = this.getDicomTagValue(metadata, DicomTag.StudyTime);
    if (studyTimeJS != null && !ignoreStudyTime) {
      studyDateJS.addHours(studyTimeJS.getHours());
      studyDateJS.addMinutes(studyTimeJS.getMinutes());
      studyDateJS.addSeconds(studyTimeJS.getSeconds());
    }
    return studyDateJS;
  }

  getStudyDateTimeString(metadata: any, ignoreStudyTime: boolean, includeParenthesis: boolean): string {
    let studyDateTimeJS: IDateJS = this.getStudyDateTime(metadata, ignoreStudyTime);
    let studyDateString: string = "";

    if (studyDateTimeJS != null) {
      if (studyDateTimeJS.getMonth() == 0 && studyDateTimeJS.getDay() == 0 && studyDateTimeJS.getFullYear() == 0) {
        studyDateString = studyDateTimeJS.toString("yyyy-MMM-dd");
      }
      else {
        studyDateString = studyDateTimeJS.toString("yyyy-MMM-dd HH:mm");
      }

      if (includeParenthesis) {
        if (studyDateString.length > 0) {
          studyDateString = "(" + studyDateString + ")";
        }
      }
    }
    return studyDateString;
  }

  getTagValue(metadata: any, tag: string, index?: number): any {
    let dcmTag = this.getDicomTag(metadata, tag);
    index = index || 0;
    if (dcmTag != undefined && dcmTag.Value) {
      if (index < dcmTag.Value.length)
        return dcmTag.Value[index];
    }
    if (dcmTag) {
      return '';
    }
    return undefined;
  }

  getAllValues(metadata: any, tag: string): any[] {
    let dcmTag = this.getDicomTag(metadata, tag);
    let values: Array<any> = new Array<any>();

    if (dcmTag != undefined && dcmTag.Value) {
      for (let i = 0; i < dcmTag.Value.length; i++) {
        let value = dcmTag.Value[i];

        if (dcmTag.vr == 'DA') {
          if (value) {
            let DateJS: IDateJS = <any>(new Date(this.parseDicomDate(value)));

            value = DateJS;
          }
        }

        if (dcmTag.vr == 'TM') {
          if (value) {
            let DateJS: IDateJS = (<any>Date).today().at(this.parseDicomTime(value));

            value = DateJS;
          }
        }
        values.push(value);
      }
    }
    return values;
  }

  getConvertValue(dcmTag: any): any {
    if (dcmTag != undefined && dcmTag.Value) {
      if (dcmTag.Value.length)
        return dcmTag.Value.join('\\');
    }
    return undefined;
  }

  getStringIfAvailable(tag: string, para: boolean): string {
    if ((tag != null) && (tag != ""))
      return para ? "=" + tag : tag;

    return "";
  }

  getPatientName(metadata: any, tag: string, index?: number): string {
    let dcmTag = this.getDicomTag(metadata, tag);
    let output = "";

    index = index || 0;
    if (dcmTag != undefined && dcmTag.Value) {
      if (index < dcmTag.Value.length) {
        output += this.getStringIfAvailable(dcmTag.Value[index].Alphabetic, false);
        output += this.getStringIfAvailable(dcmTag.Value[index].Ideographic, true);
        output += this.getStringIfAvailable(dcmTag.Value[index].Phonetic, true);
      }
    }
    return output;
  }

  getPatientNameFromTag(dcmTag: any, index?: number): string {
    index = index || 0;
    if (dcmTag != undefined && dcmTag.Value) {
      if (index < dcmTag.Value.length)
        return dcmTag.Value[index].Alphabetic;
    }
    return undefined;
  }

  parseDicomDate(dateString: string, settings = {month: true, day: true, year: true}): string {
    if (!dateString)
      return '';
    if (dateString.length === 8) {
      const year = dateString.substring(0, 4);
      const month = dateString.substring(4, 6);
      const day = dateString.substring(6);
      const parsed = {year, month, day};
      const parts = [];

      Object.keys(settings).forEach((k, i) => {
        // tslint:disable-next-line:no-unused-expression
        settings[k] && parts.push(parsed[k]);
      });

      return parts.join('/');
    } else {
      return dateString;
    }
  }

  parseDicomTime(timeString: string): string {
    if (timeString.indexOf(':') != -1) {
      timeString = timeString.replace(new RegExp(':', 'g'), '');
    }

    if (timeString.length >= 6) {
      let hour = parseInt(timeString.substring(0, 2));
      let minutes = timeString.substring(2, 4);
      let seconds = timeString.substring(4, 6);

      hour = hour % 12;
      hour = hour ? hour : 12; // the hour '0' should be '12'
      let ampm = hour >= 12 ? 'PM' : 'AM';

      return hour + ":" + minutes + ":" + seconds + " " + ampm;
    }
    return timeString;
  }

  getCodeSequenceList(metadata, tagList: string, itemNumberList: string): Array<any> {
    let list: Array<CodeSequence> = new Array<CodeSequence>();

    let tagArray = tagList.split("\\");
    for (let i = 0; i < tagArray.length; i++) {
      tagArray[i] = tagArray[i].replace(':', '');
    }

    let itemNumberArray = [];
    let itemNumber = null;
    if (itemNumberList != null) {
      itemNumberArray = itemNumberList.split("\\");

      let itemNumberArrayCount: number = itemNumberArray.length;
      for (let i = 0; i < itemNumberArrayCount; i++) {
        itemNumberArray[i] = parseInt(itemNumberArray[i]);
      }
      itemNumber = itemNumberArray[itemNumberArrayCount - 1];
    }

    let sequence = metadata[tagArray[0]];

    // tagArray and itemNumberArray must have the same number of items
    if (tagArray.length == itemNumberArray.length) {
      // Only enter this loop if more than one tag in tagArray
      for (let i = 1; i < tagArray.length; i++) {
        let index: number = itemNumberArray[i] - 1;
        let tag: string = tagArray[i];
        sequence = sequence.Value[index][tag];
      }
    }

    if (sequence && sequence.Value && sequence.Value.length > 0) {
      if (itemNumber != null) {
        if (itemNumber <= sequence.Value.length) {
          let sequenceItem = sequence.Value[itemNumber - 1];
          let codeSequence: CodeSequence = this.getCodeSequence(sequenceItem);

          let invalid: boolean = ((!codeSequence.CodeMeaning || codeSequence.CodeMeaning.length == 0) || (!codeSequence.CodeValue || codeSequence.CodeValue.length == 0));
          if (!invalid) {
            list.push(codeSequence);
          }
        }
      }

      else {
        for (let i = 0; i < sequence.Value.length; i++) {
          let sequenceItem = sequence.Value[i];
          let codeSequence: CodeSequence = this.getCodeSequence(sequenceItem);

          if ((!codeSequence.CodeMeaning || codeSequence.CodeMeaning.length == 0) || (!codeSequence.CodeValue || codeSequence.CodeValue.length == 0))
            continue;

          list.push(codeSequence);
        }
      }
    }

    return list;
  }

  getCodeSequence(sequenceItem): any {
    let codeSequence: CodeSequence = new CodeSequence();

    codeSequence.CodeValue = sequenceItem[DicomTag.CodeValue] && sequenceItem[DicomTag.CodeValue].Value ? sequenceItem[DicomTag.CodeValue].Value[0] : '';
    codeSequence.CodeMeaning = sequenceItem[DicomTag.CodeMeaning] && sequenceItem[DicomTag.CodeMeaning].Value ? sequenceItem[DicomTag.CodeMeaning].Value[0] : '';
    codeSequence.CodeSchemeDesignator = sequenceItem[DicomTag.CodingSchemeDesignator] && sequenceItem[DicomTag.CodingSchemeDesignator].Value ? sequenceItem[DicomTag.CodingSchemeDesignator].Value[0] : '';
    codeSequence.CodingSchemeVersion = sequenceItem[DicomTag.CodingSchemeVersion] && sequenceItem[DicomTag.CodingSchemeVersion].Vale ? sequenceItem[DicomTag.CodingSchemeVersion].Value[0] : '';

    return codeSequence;
  }
}
