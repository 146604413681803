import {Injectable} from '@angular/core';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {BasicResponse} from '../../core/interfaces/basic-response';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  private toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 9000,
    theme: 'material',
  };

  constructor(private toastyService: ToastyService) {
  }

  unexpectedError(): void {
    this.toastOptions.title = 'Unexpected error';
    this.toastyService.error(this.toastOptions);
  }

  error(title: string, msg: string = ''): void {
    this.toastOptions.title = title;
    this.toastOptions.msg = msg;
    this.toastyService.error(this.toastOptions);
  }

  respError(response: BasicResponse<any>): void {
    this.toastOptions.title = 'ERROR ' + response.responseCode;
    this.toastOptions.msg = response.responseMessage;
    this.toastyService.error(this.toastOptions);
  }

  success(title: string = 'Success', msg: string = ''): void {
    this.toastOptions.title = title;
    this.toastOptions.msg = msg;
    this.toastyService.success(this.toastOptions);
  }

  info(title: string, msg: string = ''): void {
    this.toastOptions.title = title;
    this.toastOptions.msg = msg;
    this.toastyService.info(this.toastOptions);
  }

}
