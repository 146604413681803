import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

@Injectable({
  providedIn: 'root'
})
export class QualityControlService {

  private patientTaskListSource = new BehaviorSubject<any>(null);
  patientTasklist = this.patientTaskListSource.asObservable();

  constructor(private http: HttpClient) {
  }

  clearPateintTaskList() {
    this.patientTaskListSource.next(null);
  }

  fillPateintTaskList(data: any[]) {
    this.patientTaskListSource.next(data);
  }

  getQCUploadTaskList(imgProjectId) {
    const requestURL = this.serviceBaseUrl + '/task/list/' + imgProjectId;
    return this.http.get(requestURL);
  }

  updateQCUploadTask(qcTaskId, status, imgProjectId) {
    const data = {
      id: qcTaskId,
      status: status,
      imageProjectId: imgProjectId
    };

    const requestURL = this.serviceBaseUrl + '/task';
    return this.http.post(requestURL, data);
  }

  getSeriesBySeriesIds(studyProjectId, seriesIds) {
    const requestUrl = this.serviceBaseUrl + '/series/get-series-by-seriesids/' + studyProjectId;
    return this.http.put(requestUrl, seriesIds);
  }

  getSeriesByVisitConfigId(studyProjectId, visitConfigId) {
    const requestUrl =
      this.serviceBaseUrl + '/series/get-seriesids-by-visitconfig/' + studyProjectId + '/' + visitConfigId;
    return this.http.get(requestUrl);
  }

  updateSeriesVisitAndModality(studyProjectId, data) {
    const requestUrl = this.serviceBaseUrl + '/series/update-series-modality/' + studyProjectId;
    return this.http.post(requestUrl, data);
  }

  updateSeriesNotNullProperties(studyProjectId, seriesId, status, repeatFlag, requiredFlag, Comment) {
    const data = {
      seriesId,
      status,
      repeatFlag,
      requiredFlag,
      Comment,
      studyProjectId
    };
    const requestUrl = this.serviceBaseUrl + '/series';
    return this.http.post(requestUrl, data);
  }

  setSeriesStatus(data) {
    const requestUrl = this.serviceBaseUrl + '/series';
    return this.http.post(requestUrl, data);
  }

  setModalitySeriesStatus(modalityId: number, data: any) {
    const requestUrl = this.serviceBaseUrl + '/series/modality/' + modalityId + '/status';
    return this.http.post(requestUrl, data);
  }

  setModalityStatus(data) {
    const requestUrl = this.serviceBaseUrl + '/modality';
    return this.http.post(requestUrl, data);
  }

  ensureQcModality(data) {
    const requestUrl = this.serviceBaseUrl + '/modality/ensure-modality';
    return this.http.post(requestUrl, data);
  }

  setVisitStatus(data) {
    const requestUrl = this.serviceBaseUrl + '/visit';
    return this.http.post(requestUrl, data);
  }

  generatEventHistory(studyId: number, visitConfigId: number) {
    const requestUrl = this.serviceBaseUrl + '/event-history/' + studyId + '?visitConfigId=' + visitConfigId;
    return this.http.get(requestUrl);
  }

  setModalityLock(lockFlag: boolean, qcTaskId, studyProjectId, qcModalityId, patientDiscontinued, patientPhantom, patientId, uploadUserId, destinations: string[]) {
    const data = {
      qcTaskId,
      qcModalityId,
      lockFlag,
      studyProjectId,
      patientDiscontinued,
      patientPhantom,
      patientId,
      uploadUserId,
      destinations
    };
    const requestUrl = this.serviceBaseUrl + '/modality';
    return this.http.post(requestUrl, data);
  }

  setVisitLock(lockFlag: boolean, qcTaskId, studyProjectId, visitConfigId, qcVisitId, patientDiscontinued, patientPhantom, patientId,
               uploadUserId, earlyTerminationVisit: boolean, destinations: string[]) {
    const data = {
      qcTaskId,
      qcVisitId,
      visitConfigId,
      lockFlag,
      studyProjectId,
      patientDiscontinued,
      patientPhantom,
      patientId,
      uploadUserId,
      earlyTerminationVisit,
      destinations
    };
    const requestUrl = this.serviceBaseUrl + '/visit';
    return this.http.post(requestUrl, data);
  }

  setSeriesComment(studyProjectId, seriesId, comment) {
    const data = {
      seriesId,
      comment,
      studyProjectId
    };
    const requestUrl = this.serviceBaseUrl + '/series';
    return this.http.post(requestUrl, data);
  }

  setModalityComment(studyProjectId, qcModalityId, comment) {
    const data = {
      qcModalityId,
      comment,
      studyProjectId
    };
    const requestUrl = this.serviceBaseUrl + '/modality';
    return this.http.post(requestUrl, data);
  }

  setVisitComment(studyProjectId, qcModalityId, comment) {
    const data = {
      qcVisitId: qcModalityId,
      comment,
      studyProjectId,
    };
    const requestUrl = this.serviceBaseUrl + '/visit';
    return this.http.post(requestUrl, data);
  }

  newSeriesTimeTrackEvent(studyProjectId, seriesId, type) {
    const data = {
      seriesId,
      studyProjectId,
      type
    };
    const requestUrl = this.serviceBaseUrl + '/series/event';
    return this.http.put(requestUrl, data);
  }


  updateSeriesTimeTrackEvent(studyProjectId, seriesId) {
    const data = {
      seriesId,
      studyProjectId
    };
    const requestUrl = this.serviceBaseUrl + '/series/event';
    return this.http.post(requestUrl, data);
  }

  updateLastByTypeSeriesTimeTrackEvent(studyProjectId, seriesId, type) {
    const data = {
      seriesId: seriesId,
      studyProjectId: studyProjectId,
      type: type,
    };
    const requestUrl = this.serviceBaseUrl + '/series/event/last-with-type';
    return this.http.post(requestUrl, data);
  }

  updateAllSeriesTimeTrackEvent(studyProjectId, seriesId) {
    const data = {
      seriesId: seriesId,
      studyProjectId: studyProjectId,
    };
    const requestUrl = this.serviceBaseUrl + '/series/event/all';
    return this.http.post(requestUrl, data);
  }

  getModalitiesByVisitConfigId(imageProjectId, visitConfigId) {
    const requestUrl = this.serviceBaseUrl + '/modality/by-visit-config-id/' + imageProjectId + '/' + visitConfigId;
    return this.http.get(requestUrl);
  }

  getQCVisitByVisitWithoutRelations(imageProjectId, visitConfigId) {
    const requestUrl = this.serviceBaseUrl + '/visit/by-visit-config-id/' + imageProjectId + '/' + visitConfigId;
    return this.http.get(requestUrl);
  }

  updateQCTaskModalities(imageProjectId, qcTaskId, modalities) {
    const requestUrl = this.serviceBaseUrl + '/task/update-modalities-list/' + imageProjectId + '/' + qcTaskId;
    return this.http.post(requestUrl, modalities);
  }


  getQCVisitsByStudyId(imageProjectId) {
    const requestUrl = this.serviceBaseUrl + '/visit/with-modalities-by-study-id/' + imageProjectId;
    return this.http.get<BasicResponse<any>>(requestUrl);
  }

  getQCVisitsByPatientId(imageProjectId, patientId) {
    const requestUrl = this.serviceBaseUrl + '/visit/by-patient-id/' + imageProjectId + '/' + patientId;
    return this.http.get(requestUrl);
  }

  getVisitsByConfigIds(studyId: number, ids: number[]) {
    const requestUrl = this.serviceBaseUrl + '/visit/by-visit-config-ids-without-relations/' + studyId;
    return this.http.post(requestUrl, ids);
  }

  getQcVisit(visitConfigId: number): Observable<BasicResponse<any>> {
    const url = `${this.serviceBaseUrl}/visit/with-modalities-by-visit-config/${visitConfigId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  updateQcTaskStatus(taskId: number, status: string): Observable<BasicResponse<any>> {
    const url = `${this.serviceBaseUrl}/task/${taskId}`;
    return this.http.put<BasicResponse<any>>(url, {status: status});
  }

  get serviceBaseUrl() {
    return 'https://' + environment.MSQualityControlVersion + 'msqualitycontrol-dot-' + environment.apiUrl;
  }

  lateralityCorrection(studyId: number, mipSeriesId: number) {
    const requestUrl = `${this.serviceBaseUrl}/laterality-correction/execute`;
    const data = {
      studyId: studyId,
      mipSeriesId: mipSeriesId,
      action: 'FLIP_LEFT_RIGHT',
    };
    return this.http.post(requestUrl,data);
  }

  radiobotics(studyId: number, mipSeriesId: number) {
    const requestUrl = `${this.serviceBaseUrl}/radiobotics/analyze`;
    const data = {
      studyId: studyId,
      mipSeriesId: mipSeriesId,
      action: 'RBKNEE_FDA',
    };
    return this.http.post(requestUrl, data);
  }

  getRadioboticsProcesses(studyId: number, visitConfigId: number) {
    const requestUrl = `${this.serviceBaseUrl}/radiobotics/studyId-${studyId}/visitConfigId-${visitConfigId}/list`;
    return this.http.get(requestUrl);
  }

}
