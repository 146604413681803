import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObjectRetrieveService } from '../interfaces/viewer/object-retrieve-service';
import { ViewerAuthenticationService } from '../interfaces/viewer/viewer-authentication-service';
import { ResponseContentType } from '@angular/http';
import { ViewerAuditableContext } from 'src/app/_models/viewer/ViewerConfigModel';

const ROOT_URL = environment.Leadtools.rootURL;
const SERVICES = environment.Leadtools.services;

@Injectable({
  providedIn: 'root'
})
export class LeadToolsObjectRetrieveService implements ObjectRetrieveService {
    private _retrieveLocalUrl;
    private _threedLocalUrl;
    private _querylocalUrl;

    constructor(private http: HttpClient, private authenticationService: ViewerAuthenticationService) {
        this._retrieveLocalUrl = ROOT_URL+"/"+SERVICES.objectRetrieveLocalServiceName;
        this._threedLocalUrl = ROOT_URL+"/"+SERVICES.threeDServiceName;
        this._querylocalUrl = ROOT_URL+"/"+SERVICES.queryLocalServiceName;
    }

    public getDicomJSON(studyInstanceUID: string, seriesInstanceUID: string, sopInstanceUID: string) {
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode) + "&study=" + studyInstanceUID + "&series=" + seriesInstanceUID + "&instance=" + sopInstanceUID;

        return this.http.get(this._retrieveLocalUrl + "/GetDicomJSON?" + parameter, { headers: { 'Content-Type': 'application/json'} });
    }

    public getSeriesStacks(seriesInstanceUID: string, userData?) {
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode) + "&seriesinstanceUID=" + seriesInstanceUID + "&data=" + userData;

        return this.http.get(this._retrieveLocalUrl + "/GetSeriesStacks?" + parameter);
    }

    public customUpload(bucket: string, seriesId: string, seriesInstanceUID: string, audit?: ViewerAuditableContext) {
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode) + "&bucket=" + bucket + "&seriesId=" + seriesId + "&seriesInstanceUID=" + seriesInstanceUID;
        if (audit) {
            parameter += audit.userId ? ("&userId=" + audit.userId) : "";
            parameter += audit.userName ? ("&userName=" + audit.userName) : "";
            parameter += audit.patientId ? ("&patientId=" + audit.patientId) : "";
            parameter += audit.studyId ? ("&studyId=" + audit.studyId) : "";
            parameter += audit.visitConfigId ? ("&visitConfigId=" + audit.visitConfigId) : "";
        }
        return this.http.get(this._retrieveLocalUrl + "/CustomUpload?" + parameter);
    }

    public getInstanceStacks(seriesInstanceUID: string, orderBy: string = "RemotePath, InstanceNumber", orderType: string = "ASC", userData?) {
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode) + "&seriesinstanceUID=" + seriesInstanceUID;
        parameter += "&orderBy="+orderBy+"&orderType="+orderType+"&data=" + userData;

        return this.http.get(this._retrieveLocalUrl + "/GetInstanceStacks?" + parameter);
    }

    public getDCEStacks(bucket: string, seriesId: string, userData?) {
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode) + "&bucket=" + bucket +"&seriesId="+seriesId + "&data=" + userData;

        return this.http.get(this._retrieveLocalUrl + "/GetDCEStacks?" + parameter);
    }

    public getImage(sopInstanceUID: string, frame: number, mime: string, width: number, height: number=0, bitsPerPixel: number=0, qualityFactor: number=0, userData=null): Observable<Blob>{
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode) + "&instance=" + sopInstanceUID;
        parameter += "&frame=" + frame +"&mime=" + mime +"&bp=" + bitsPerPixel +"&qf=" + qualityFactor +"&cx=" + width +"&cy=" + height+ "&data=" + userData;

        return this.http.get(this._retrieveLocalUrl + "/GetImage?" + parameter, { responseType: 'blob' });
    }

    public getImagePixelData(sopInstanceUID: string, width: number, height: number) {
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode) + "&instance=" + sopInstanceUID;
        parameter += '&frame=' + 0 + '&x=0&y=0&w=0&h=0' + '&xr=' + width + '&yr=' + height + '&wldata=true';
        return this.http.get(this._retrieveLocalUrl + "/GetImagePixelData?" + parameter, { responseType: 'json' });
    }

    public getPixelData(sopInstanceUID: string, useScale: boolean){
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode);
        parameter += "&instance=" + sopInstanceUID + '&useScale=' + useScale ;
        return this.http.get(this._retrieveLocalUrl + "/GetPixelData?" + parameter, { responseType: 'json' });
    }

    public getMPRFrame(id: string, mprType: string, index: string){
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode);
        parameter += "&id=" + id + "&mprType=" + mprType + "&index=" + index;
        return this._threedLocalUrl + "/GetMPRImage?" + parameter;
    }

    public getPresentationAnnotations(sopInstanceUID: string, userData, person?: string) {
        const parameter = 'auth=' + encodeURIComponent(this.authenticationService.authenticationCode)
          + '&instance=' + sopInstanceUID
          + '&data=' + userData
          + (person ? '&person=' + encodeURIComponent(person) : '');
        return this.http.get(this._retrieveLocalUrl + '/GetPresentationAnnotationsFromFile?' + parameter, { headers: { 'Content-Type': 'application/xml' } });
    }
}
