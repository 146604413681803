import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {JSW_OARSI_ReadingModel} from '../_models/JSW/jsw-reading-model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingOARSIVersion;
const SERVICE = 'msreadingoarsi-dot-';
const SERVICE_API_URL = `https://${API_VERSION}${SERVICE}${API_URL}/reading/`;

// const SERVICE_API_URL = 'http://localhost:8080/api/reading/';

@Injectable({
  providedIn: 'root'
})
export class ReadingOarsiService {

  constructor(private http: HttpClient) { }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getReading(studyId, readerId): Observable<BasicResponse<JSW_OARSI_ReadingModel[]>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-available-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds): Observable<BasicResponse<JSW_OARSI_ReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}${studyId}/${readingId}`, {spentSeconds});
  }

  updateReadingWithSaveAllData(studyId, reading): Observable<BasicResponse<JSW_OARSI_ReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}update/${studyId}`, reading);
  }

  completeReading(studyId, reading: JSW_OARSI_ReadingModel): Observable<BasicResponse<JSW_OARSI_ReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}complete/${studyId}`, reading);
  }

  uploadFiles(studyId, seriesId, filesForm): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${SERVICE_API_URL}upload-files/${studyId}/${seriesId}`, filesForm);
  }
}
