import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute} from '@angular/router';
import {SiteService} from '../../../_services';
import {ToastService} from '../../../_services/internal/toast.service';

@Component({
    selector: 'app-site-list',
    templateUrl: './site-list.component.html',
    styleUrls: ['./site-list.component.css']
})
export class SiteListComponent implements OnInit {

    displayedColumns: string[] = ['siteCode', 'examsUploaded', 'examsTotal', 'progress', 'patientsCount', 'recentUpload'];
    dataSource: MatTableDataSource<Site>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    showModalSpinner = false;

    constructor(
        private siteService: SiteService,
        private route: ActivatedRoute,
        private toastService: ToastService
    ) {
    }

    ngOnInit(): void {
        const studyId = parseInt(this.route.snapshot.params.projectId, 10);
        this.showModalSpinner = true;
        this.siteService.findAllForDashboard(studyId).subscribe(
            response => {
                if (response['responseCode'] === 200) {
                    this.dataSource = new MatTableDataSource<Site>(response['data']);
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                } else {
                    this.showErrorMessage(response);
                }
                this.showModalSpinner = false;
            }, error => {
                this.showErrorMessage(error);
                this.showModalSpinner = false;
            });
    }

    applyFilter(event: Event) {
        const filterValue = (event.target as HTMLInputElement).value;
        this.dataSource.filter = filterValue.trim().toLowerCase();

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    showErrorMessage(response: any): void {
        this.toastService.error('ERROR ' + response['responseCode'], response['responseMessage']);
    }

}

interface Site {
    siteCode: string;
    examsUploaded: number;
    examsTotal: number;
    progress: string;
    patientsCount: number;
    recentUpload: string;
}
