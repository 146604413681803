import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormMode} from '../../../../../core/constants/form-mode';
import {ActivatedRoute} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {StudySequenceLabelService, StudyService, StudyUserService} from '../../../../../_services';
import {forkJoin, of} from 'rxjs';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {MatSelectChange} from '@angular/material/select';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {
    defaultBasicAutoBatchConfig,
    ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {MoaksConfigModel} from '../../../../../_models/ImagingProject/moaks-config-model';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {Reader} from '../imaging-project-reading-moaks-wrapper/imaging-project-reading-moaks-wrapper.component';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';
import { VisitModalityConfiguratorDialogComponent } from '../../visit-modality-configurator-dialog/visit-modality-configurator-dialog.component';
import { VisitType } from 'src/app/_models/ImagingProject/patient-model';
import * as moment from 'moment';

export class MoadksVisitModel{
  id?: number;
  created?: number;
  name: string;
  type?: VisitType;
  durationTimeUnit?: string;
  durationTimeValue?: number;
  baseline?: boolean;
  repeatAllowed?: boolean;
  modalities?: ModalityModel[];
  isAvailable = true;
}

@Component({
    selector: 'app-imaging-project-reading-moaks-basic',
    templateUrl: './imaging-project-reading-moaks-basic.component.html',
    styleUrls: ['./imaging-project-reading-moaks-basic.component.css']
})
export class ImagingProjectReadingMoaksBasicComponent extends ImagingProjectReadingBasicComponent implements OnInit {

    @Input() selectedConfig: FlexibleConfig<MoaksConfigModel>;
    @Input() selectedReadingVersion: String;
    @Input() mode: FormMode;
    @Output() public clickCancel = new EventEmitter<any>();
    @Output() public clickSubmit = new EventEmitter<any>();

    readonly formModes = FormMode;
    modalities: ModalityModel[] = [];
    studyId: number;
    basicBatchConfig: BatchConfigModel = null;
    readersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    patientClinicalDataDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    patientClinicalDataColumns: string[] = ['patientCode', 'clinicalData', 'action'];
    MIN_READERS = 1;
    MAX_READERS = 20;
    readerSelectOptions: Reader[];
    readers: Reader[];
    readersTableColumns: string[] = ['username', 'email', 'action'];
    readonly readingVersions = ReadingVersion;
    editingPatientClinicalData: any = null;
    oldPatientClinicalData: any = null;

    moaksForm = this.fb.group({
        readingLevel: ['Patient', [Validators.required]],
        modalities: [-1, [Validators.required]],
        readingType: ['Parallel', [Validators.required]],
        hideVisitHistory: ['hide_visits_chronology', [Validators.required]],
        anatomySelector: ['', [Validators.required]],
        batchManagement: [false, [Validators.required, this.batchManagementValidator]],
        imageViewerConfiguration: ['default', [Validators.required]],
        readers: ['', [this.readersValidator]],
        patientClinicalData: ["", []]
    });

    availableSequenceLabels: SequenceLabelModel[] = [];
    selectedSequenceLabels: SequenceLabelModel[] = [];

    toastOptions: ToastOptions = {
        title: '',
        showClose: true,
        timeout: 10000,
        theme: 'material',
    };

    studyVisits: any[] = [];
    unscheduledAllowed: boolean;

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private studySequenceLabelService: StudySequenceLabelService,
        private studyUserService: StudyUserService,
        private batchLogicService: BatchLogicService,
        private toastyService: ToastyService,
        private dialog: MatDialog,
        private studyService: StudyService,
    ) {
      super();
    }

    ngOnInit(): void {
        this.studyId = +this.route.snapshot.params.id;

        const serviceSources = [
            this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
            this.studyUserService.getReadersByStudyId(this.studyId),
            this.studyService.getVisitsByStudyId(this.studyId),
            this.studyService.getById(this.studyId)
        ];
        if (this.mode === this.formModes.EDIT) {
            serviceSources.push(
                this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id)
            );
        }

        forkJoin(serviceSources).subscribe(values => {
                const [groupedModalitiesResponse, readersResponse, studyVisitsResponse, studyServiceResponse, batchConfigsResponse] = values as any;
                this.initModalities(groupedModalitiesResponse);
                this.initReaders(readersResponse);

                this.studyVisits = [...studyVisitsResponse['data']];
                this.initStudyVisits(studyServiceResponse['data']);
                if (this.mode === this.formModes.EDIT) {
                    this.initBatchConfigs(batchConfigsResponse);
                    this.initFormValues(this.selectedConfig.config);
                }
            },
            (error) => this.showErrorMessage(error.error));
    }

    showErrorMessage(response: any): void {
        this.toastOptions.title = 'ERROR ' + response['responseCode'];
        this.toastOptions.msg = response['responseMessage'];
        this.toastyService.error(this.toastOptions);
    }

    initFormValues(config: MoaksConfigModel): void {
        if (config) {
          this.studyVisits = config.visit;
          this.moaksForm.patchValue(config);
          this.moaksForm.patchValue({
              modalities: config.modalities[0].id,
              readers: config.readers.map(reader => reader.id),
              mocartClinicalData: config.mocartClinicalData?.map((m, i) => { 
                return { index: i, patientCode: m.patientCode, clinicalData: m.clinicalData }
             })
          });
          this.pushReaders();
          this.patientClinicalDataDataSource.data =
            config.mocartClinicalData?.map((m, i) => { 
                return { index: i, patientCode: m.patientCode, clinicalData: m.clinicalData }
             });
        }
    }

    initBatchConfigs(batchConfigsResponse): void {
        if (batchConfigsResponse.responseCode === 200 && batchConfigsResponse.data) {
            this.basicBatchConfig = (batchConfigsResponse.data as BatchConfigModel[])
                .find(batchConfig => batchConfig.readingType === ReadingType.BASIC_READING);
        }
    }

    initReaders(readersResponse): void {
        if (readersResponse.responseCode === 200 && readersResponse.data) {
            this.readers = readersResponse.data;
            if (this.mode === this.formModes.NEW) {
                this.readerSelectOptions =
                    (readersResponse.data as Reader[]).sort(this.compareReadersByName);
            }
        }
    }

    initModalities(groupedModalitiesResponse): void {
        if (groupedModalitiesResponse.responseCode === 200 && groupedModalitiesResponse.data) {
            this.modalities = [groupedModalitiesResponse.data.find(m => m.name.toLowerCase() === 'mri')];
            if (this.mode === this.formModes.NEW) {
                this.moaksForm.patchValue({modalities: this.modalities[0].id});
            }
            this.availableSequenceLabels = this.modalities[0].sequenceLabels;
            this.prepareSequenceLabels();
        }
    }

    getFormControl(path: string): FormControl {
        return this.moaksForm.get(path) as FormControl;
    }

    compareModalities(m1: any, m2: any): boolean {
        return m1 && m2 ? m1.name === m2.name : m1 === m2;
    }

    prepareSequenceLabels() {
        if (this.mode === FormMode.EDIT) {
            if (this.selectedConfig.config.anatomySelector) {
                this.initSequenceLabels(this.selectedConfig.config.anatomySelector);
            } else {
                this.initDefaultSequenceLabels();
            }
        } else {
            this.initDefaultSequenceLabels();
        }
    }

    initDefaultSequenceLabels(): void {
        this.selectedSequenceLabels = [...this.availableSequenceLabels];
    }

    initSequenceLabels(sequenceLabels: { id: number }[]) {
        this.selectedSequenceLabels = this.availableSequenceLabels
            .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
    }

    get batchManagementValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value && !this.basicBatchConfig?.numberOfReadings ? {norError: 'Number of readings is required'} : null;
        };
    }

    onSequenceLabelSelect(e: SequenceLabelModel[]) {
        this.selectedSequenceLabels = e;
    }

    onBatchManagementSelected(event: MatSelectChange): void {
        if (this.basicBatchConfig) {
            this.basicBatchConfig.numberOfReadings = null;
            this.moaksForm.get('batchManagement').updateValueAndValidity();
        }
    }

    openAutoCreationBatchConfigDialog(): void {
        let inputConfig: AutoBatchConfigPayload;
        const val = this.moaksForm.get('batchManagement').value;
        if (!val) {
            inputConfig = {...defaultBasicAutoBatchConfig};
        } else {
            inputConfig = this.basicBatchConfig;
        }

        const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
            width: '500px',
            data: {
                readingType: ReadingType.BASIC_READING,
                configInput: inputConfig,
                readonlyDialog: !val
            }
        });

        dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
            if (result) {
                this.basicBatchConfig = {...result};
                this.moaksForm.get('batchManagement').updateValueAndValidity();
            }
          ImagingProjectReadingBasicComponent.clearFocus();
        });
    }

    compareReaders(r1: any, r2: any): boolean {
        return r1 && r2 ? r1.id === r2.id : r1 === r2;
    }

    isReadersValid(): boolean {
        return this.readersDataSource.data.length >= this.MIN_READERS && this.readersDataSource.data.length <= this.MAX_READERS;
    }

    get readersValidator() {   // computed property suppress property is used before its initialization error
        return (): ValidationErrors | null => {
            return this.isReadersValid() ? null : {
                tableError: `${this.MIN_READERS}..${this.MAX_READERS} users must be in the list`,
                selectError: `please ${this.readersDataSource.data.length < this.MAX_READERS ? 'select' : 'remove'} option`
            };
        };
    }

    compareReadersByName(r1: Reader, r2: Reader): number {
        return (r1.firstName + r1.lastName).localeCompare(r2.firstName + r2.lastName);
    }

    pushReaders(): void {
        const selectedReaderIds = [...this.moaksForm.get('readers').value, ...this.readersDataSource.data.map(reader => reader.id)];
        this.readersDataSource.data =
            this.readers
                .filter(reader => selectedReaderIds.includes(reader.id))
                .sort(this.compareReadersByName);
        this.readerSelectOptions = this.readers
            .filter(reader => !selectedReaderIds.includes(reader.id))
            .sort(this.compareReadersByName);
        this.moaksForm.patchValue({readers: []});
    }

    removeReader(id: number): void {
        this.readersDataSource.data = this.readersDataSource.data.filter(reader => reader.id !== id);
        this.readerSelectOptions = [...this.readerSelectOptions, this.readers.find(r => r.id === id)].sort(this.compareReadersByName);
        this.moaksForm.get('readers').updateValueAndValidity();
    }

    cancel(): void {
        this.clickCancel.emit({result: 'cancel'});
    }

    prepareBasicBatchConfigPayload(): AutoBatchConfigPayload {
        let configPayload: AutoBatchConfigPayload = null;
        if (this.basicBatchConfig) {
            const configId: number = this.basicBatchConfig.id ? this.basicBatchConfig.id : null;
            if (this.moaksForm.get('batchManagement').value) {
                configPayload = {
                    numberOfReadings: this.basicBatchConfig.numberOfReadings || undefined,
                    readingType: ReadingType.BASIC_READING,
                    initiationMethod: this.basicBatchConfig.initiationMethod,
                    initiationOption: this.basicBatchConfig.initiationOption,
                    batchCount: this.basicBatchConfig.batchCount
                };
            } else {
                configPayload = {...defaultBasicAutoBatchConfig};
                configPayload.readingType = ReadingType.BASIC_READING;
            }
            configPayload.id = configId;
        }
        return configPayload;
    }

    submit(): void {
        const payload: MoaksConfigModel = this.moaksForm.value;
        payload.modalities = [this.modalities.find(m => m.id === this.moaksForm.value.modalities) || {
            id: -1,
            name: 'MRI'
      }];
      console.log(this.modalities)
      console.log(payload.modalities)
      console.log(this.moaksForm.value.modalities)
        payload.readers = this.readersDataSource.data.map(reader => {
            return {id: reader.id};
        });
        payload.readingVersion = 
            this.selectedReadingVersion == this.readingVersions.MOAKS_3Visits 
            ? ReadingVersion.MOAKS_3Visits
            : this.selectedReadingVersion == this.readingVersions.MOAKS_MOCART
                ? ReadingVersion.MOAKS_MOCART
                : ReadingVersion.MOAKS_BASIC;
        payload.endpointReadingVersion = 'basic';
        payload.anatomySelector = this.selectedSequenceLabels.map(l => {
            return {id: l.id};
        });
        payload.visit = this.studyVisits;

        payload.mocartClinicalData = this.patientClinicalDataDataSource.data
            .filter(data => !!data.patientCode && !!data.clinicalData)
            .map(data => {
            return {patientCode: data.patientCode, clinicalData: data.clinicalData };
        });

        const imagingProjectSubmitEvent: ImagingProjectSubmitEvent = {
            result: 'submit',
            data: payload,
            basicBatchConfig: this.prepareBasicBatchConfigPayload()
        };

        this.clickSubmit.emit(imagingProjectSubmitEvent);
    }

    initStudyVisits(study: any) {
      //sort study visits by id
      this.studyVisits = this.sortVisits(this.studyVisits);
      this.unscheduledAllowed = study.unscheduledAllowed;
      //add unschaduled visits and early termination
      const unscheduled = new MoadksVisitModel();
      unscheduled.name = 'Unscheduled';
      unscheduled.type = VisitType.UNSCHEDULED_REGULAR;
      this.studyVisits.push(unscheduled);

      this.studyVisits.forEach(visit => visit.isAvailable = true);
      if(this.mode === FormMode.NEW) {
        this.studyVisits.forEach(v => v['modalities'] = !this.unscheduledAllowed && v.type === VisitType.UNSCHEDULED_REGULAR ? [] : this.modalities)
      }
    }

    sortVisits(visits) {
      visits.forEach(visit => {
        let visitDate = moment(visit.create);
        if (visit.durationTimeUnit == 'D') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'd');
        } else if (visit.durationTimeUnit == 'W') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'w');
        } else if (visit.durationTimeUnit == 'M') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'M');
        }
        visit.visitDate = visitDate;
      });
      visits.sort((a, b) => {
        if (a.visitDate < b.visitDate) {
          return -1;
        }
        if (a.visitDate > b.visitDate) {
          return 1;
        }
        return 0;
      });
      let baselineIndex = visits.indexOf(visits.find(this.isBaseline));
      visits.unshift(visits.find(this.isBaseline));
      visits.splice(baselineIndex + 1, 1);
      return visits;
    }

    isBaseline(visit) {
      return visit.baseline === true;
    }

    openModalitiesDialog() {
      ImagingProjectReadingBasicComponent.clearFocus();
      const dialogRef = this.dialog.open(VisitModalityConfiguratorDialogComponent, {
        data: {
          visits: this.studyVisits,
          modalities: this.modalities,
          unscheduledAllowed: this.unscheduledAllowed
        }
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.filterVisits(result.visits);
          const uniqueModality = this.getAllVisitsUniqueModalities(result.visits);
          this.moaksForm.controls.modalities.setValue(uniqueModality.find(m => m.name.toLowerCase() === 'mri').id);
          // this.initDefaultSequenceLabels(this.configForm.controls.modality.value);
        }
      });
    }

    getModifiedVisit() {
      return this.studyVisits.filter(v => v.type !== VisitType.UNSCHEDULED_REGULAR || this.unscheduledAllowed)
    }

    private filterVisits(visits) {
      visits.forEach(visit => {
        if (!visit.modalities || visit.modalities.length < 1) {
          this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = false;
        } else {
          this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = true;
        }
      });
    }

    getAllVisitsUniqueModalities(visits: any[]) {
      let modalities = [];
      visits.forEach((visit, visitIndex) => {
        this.studyVisits[visitIndex]
          .modalities = [...visit.modalities];
        modalities = [...modalities, ...visit.modalities];
      });
      const uniqueModality = [];
      modalities.forEach((modality) => {
        let index = uniqueModality.findIndex((m) => m.id === modality.id) === -1;
        if (index) uniqueModality.push(modality);
      });
      return uniqueModality;
    }

    patientCodeCtrl = new FormControl('', [ Validators.required ]);
    clinicalDataCtrl = new FormControl('', [ Validators.required ]);
    
    addClinicalData() {
        this.oldPatientClinicalData = null;
        var addData = { index: this.patientClinicalDataDataSource.data.length, patientCode:'', clinicalData: '' };
        this.editingPatientClinicalData = addData;
        this.patientClinicalDataDataSource.data = [addData, ...this.patientClinicalDataDataSource.data];
    }
    
    editClinicalData(element) {
        this.oldPatientClinicalData = { ...element };
        this.editingPatientClinicalData = element;
    }

    removeClinicalData(element) {
        this.patientClinicalDataDataSource.data = this.patientClinicalDataDataSource.data
            .filter(f => f.index !== element.index);
    }

    updateClinicalData(element) {
        let value = this.patientClinicalDataDataSource.data.find(f => f.index === element.index);
        if (value) {
            value.patientCode = this.editingPatientClinicalData.patientCode;
            value.clinicalData = this.editingPatientClinicalData.clinicalData;
        }
        this.editingPatientClinicalData = null;
        this.oldPatientClinicalData = null;
    }

    cancelUpdateClinicalData(element) {
        if (this.oldPatientClinicalData == null) {
            this.removeClinicalData(element);
        } else {
            element.patientCode = this.oldPatientClinicalData?.patientCode;
            element.clinicalData = this.oldPatientClinicalData?.clinicalData;
        }
        this.editingPatientClinicalData = null;
        this.oldPatientClinicalData = null;
    }
}
