import {AfterViewChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {AuthenticationService, UserService} from './_services';
import {ActivatedRoute, Router} from '@angular/router';
import {MaintenanceService} from './_services/maintenance.service';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import * as _moment from 'moment';
import {JwtUtilService} from './_helpers/jwt-util.service';
import {Select, Store} from '@ngxs/store';
import {AuthInfoState, AuthInfoStateModel} from './core/data-management/states/auth-info.state';
import {Observable, of, Subject} from 'rxjs';
import {GetAuthInfo, SetAuthInfo} from './core/data-management/actions/auth-info.action';
import {AuthActivity} from './core/interfaces/auth-activity';
import {JwtHelperService} from '@auth0/angular-jwt';
import {RoleActivityName} from './core/constants/role-activity-name';
import {
  PushAssignedProjectId,
  PushAuditTrailsProjectId,
  PushDashboardProjectId
} from './core/data-management/actions/projects.action';
import {ProjectsState} from './core/data-management/states/projects.state';
import {StudyModel} from './_models/ImagingProject/study-model';
import {ScreenWidths} from './core/constants/screen-sizes';
import {MatListItem} from '@angular/material/list';
import {CurrentUserInfoState, CurrentUserInfoStateModel} from './core/data-management/states/user-info.state';
import {Role} from './core/interfaces/role';
import {Base64} from './_helpers/base64';
import {AccessPermissionsService} from './_services/access-permissions.service';
import {Path} from './core/constants/path';
import {CompactJwt} from "./_helpers/CompactJwt";
import {RefreshAPIService} from './_services/refresh-api.service';
import {RefreshComponentsUrl, SIGN_REPORTS_LIST_PAGE} from './_models/refresh-components-url';

const moment = _moment;
const helper = new JwtHelperService();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [AuthenticationService]
})
export class AppComponent implements AfterViewChecked, OnInit {

  @Select(CurrentUserInfoState.getCurrentUserInfo) currentUserInfo: Observable<CurrentUserInfoStateModel>;
  @Select(AuthInfoState.getAuthInfo) authInfo: Observable<AuthInfoStateModel>;
  @Select(ProjectsState.getPageHeaderTitle) headerTitleObservable: Observable<string>;
  @Select(ProjectsState.getSelectedProject) selectedProjectObservable: Observable<StudyModel>;
  @ViewChild('clinicalTrialMenuItem', {read: MatListItem}) clinicalTrialMenuItem: MatListItem;

  readonly Path = Path;

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  currentUrl: string;
  title = 'Dynamika Canvas';
  isExpanded = true;
  element: HTMLElement;
  activities: any;
  globals: object = [];
  project: any = {name: '', id: -1};
  selectedProject: StudyModel;
  previousLogin: string;
  firstName: string;
  lastName: string;
  userId: number;
  authenticated = false;
  hasClinicalTrialsAccess = false;
  hasOperationDashboardAccess = false;
  hasSignDashboardAccess = false;
  hasAppVersionsAccess = false;
  hasAuditTrailsAccess = false;
  hasMenuAccess = true;
  refreshComponentsUrlList = RefreshComponentsUrl;

  constructor(private cdr: ChangeDetectorRef,
              private authenticationService: AuthenticationService,
              private userService: UserService,
              private toastyService: ToastyService,
              private maintenanceService: MaintenanceService,
              private router: Router,
              private jwtUtilService: JwtUtilService,
              private store: Store,
              private accessPermissionsService: AccessPermissionsService,
              private refreshAPIService: RefreshAPIService,
              private route: ActivatedRoute,) {
  }

  ngOnInit() {
    this.checkScreen();
    this.subscribeAuthData();
    this.subscribeSelectedProject();
    this.subscribeCurrentUser();
    this.subscribeRouterEvents();

    // check maintenance state of the application
    this.maintenanceService.getAll().subscribe(maintenanceResp => {
      let maintenance = maintenanceResp['data'];
      if (maintenance != null && maintenance.length > 0) {
        maintenance = maintenance.pop();
        if (maintenance['startMaintenance'] === true) {
          localStorage.clear();
          localStorage.setItem('maintenance', JSON.stringify(true));
          localStorage.setItem('maintenanceFinishTime', JSON.stringify(maintenance['finishMaintenance']));
          this.router.navigate(['/maintenance']);
        } else {
          // old value in storage
          if (localStorage.getItem('maintenance') === 'true') {
            localStorage.clear();
            this.router.navigate(['/']);
          } else {
            this.isAuthenticated();
          }
        }
      } else {
        localStorage.removeItem('maintenance');
        localStorage.removeItem('maintenanceFinishTime');
        this.isAuthenticated();
      }
    });

    this.dispatchAuthInfo();
    this.store.dispatch(new GetAuthInfo());
  }

  switchSidebar(): void {
    this.isExpanded = !this.isExpanded;
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
  }

  checkScreen(): void {
    this.isExpanded = window.innerWidth > ScreenWidths.SMALL_SCREEN;
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  isFullscreen(): boolean {
    return (window.innerWidth === screen.width && window.innerHeight === screen.height);
  }

  isAuthenticated(): boolean {
    if (!this.authenticated) {
      if (localStorage.getItem('currentUser')) {
        this.activities = JSON.parse(localStorage.getItem('activities'));
        this.globals = JSON.parse(localStorage.getItem('globals'));
        this.authenticated = true;
      }
    }
    this.project = localStorage.getItem('project') ? JSON.parse(localStorage.getItem('project')) : {name: '', id: -1};
    return this.authenticated;
  }

  toggleActive(event: any): void {
    event.preventDefault();
    if (this.element !== undefined) {
      this.element.style.backgroundColor = '#ec6d2600';
    }
    const target = event.currentTarget;
    target.style.backgroundColor = '#ec6d26b8';
    this.element = target;

    this.router.navigate([this.element.getAttribute('url')]);
  }

  refreshComponentsAPIs() {
    this.refreshAPIService.whichPage = this.currentUrl;
    this.refreshAPIService.onRefreshAPIs(true, this.currentUrl);
  }

  isComponentsUrlNotExistedInList() {
    let i = 0;
    for (const key in this.refreshComponentsUrlList) {
      if (this.refreshComponentsUrlList[key] === this.currentUrl) {
        i++;
      }
    }

    return i === 0 && !SIGN_REPORTS_LIST_PAGE.test(this.currentUrl);
  }

  logoClick(event: any): void {
    if (!!this.clinicalTrialMenuItem) {
      this.clinicalTrialMenuItem._getHostElement().click();
    }
  }

  logout(): void {
    this.authenticationService.logout();
  }

  dispatchAuthInfo(): void {
    const jwtString = localStorage.getItem('currentUser');
    const jwt = CompactJwt.decodeActivities(helper.decodeToken(jwtString));
    if (jwtString && jwt) {
      const userId = JSON.stringify(jwt.jti);
      const activities = JSON.stringify(jwt.permissions.activities);
      const globals = JSON.stringify(jwt.permissions.globals);
      const subject = JSON.stringify(jwt.sub);

      localStorage.setItem('activities', activities);
      localStorage.setItem('globals', globals);
      localStorage.setItem('subject', subject);
      localStorage.setItem('userId', userId);

      this.store.dispatch(new SetAuthInfo({
        userId: +userId.replace(/"/g, ''),
        activities: JSON.parse(activities) as AuthActivity[],
        roles: Array.from(this.fetchRolesFromLocalStorage()),
        token: jwt
      }));
    }
  }

  subscribeAuthData(): void {
    this.accessPermissionsService.permissionsSubject.subscribe(permissions => {
      this.hasClinicalTrialsAccess = permissions[Path.IMAGING_PROJECT];
      this.hasOperationDashboardAccess = permissions[Path.OPERATIONAL_DASHBOARD];
      this.hasAppVersionsAccess = permissions[Path.APP_VERSION];
      this.hasAuditTrailsAccess = permissions[Path.AUDIT_TRAILS];
    });
    this.authInfo.subscribe(authInfo =>
    {
      this.hasSignDashboardAccess = authInfo.roles.findIndex(i => i.roleType === 'READER') >= 0;

      authInfo.activities.forEach(a => {
        if (a.activities.includes(RoleActivityName.DASHBOARD_ACCESS)) {
          this.store.dispatch(new PushDashboardProjectId(a.projectId));
        }
        if (a.activities.includes(RoleActivityName.AUDITTRAILS_ACCESS)) {
          this.store.dispatch(new PushAuditTrailsProjectId(a.projectId));
        }
        if (a.activities.includes('dashboard.access.sponsor')) {
          this.hasMenuAccess = false
        }
        this.store.dispatch(new PushAssignedProjectId(a.projectId));
      })
    });
  }

  subscribeSelectedProject(): void {
    this.selectedProjectObservable.subscribe(project => {
      this.selectedProject = project;
    });
  }

  subscribeCurrentUser(): void {
    this.currentUserInfo.subscribe((user: CurrentUserInfoStateModel) => {
      this.previousLogin = moment(user.previousLogin).format('DD/MM/YYYY HH:mm:ss');
      this.firstName = user.firstName;
      this.lastName = user.lastName;
    }, response => {
      this.toastOptions.title = 'ERROR ' + response.responseCode;
      this.toastOptions.msg = response.responseMessage;
      this.toastyService.error(this.toastOptions);
    });
  }

  private fetchRolesFromLocalStorage(): Set<Role> {
    const rolesString = localStorage.getItem(Base64.encode('roles'));
    if (!!rolesString) {
      return JSON.parse(Base64.decode(rolesString)) as Set<Role>;
    } else {
      return new Set<Role>();
    }
  }

  onSeeAppVersionClick() {
    if (this.element !== undefined) {
      this.element.style.backgroundColor = '#ec6d2600';
    }
    this.router.navigate(['/app-versions']);
  }

  private subscribeRouterEvents() {
    this.router.events.subscribe(event => {
      if (!!event['url'] && event['url'] !== this.currentUrl) {
        this.currentUrl = event['url'];
      }
    });
  }

  isCurrentUrlInclude(url: string): boolean {
    if (!!this.currentUrl) {
      return this.currentUrl.includes(url);
    }
  }

  check(flag: boolean): Observable<boolean> {
    return of(flag);
  }

  log(b: boolean | null) {
    return b;
  }
}
