import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';
import {DataUploadService} from '../../../_services';


@Component({
  selector: 'app-qcdicomchecks-dialog',
  templateUrl: './qc-change-visit-dialog.component.html',
  styleUrls: ['./qc-change-visit-dialog.component.css']
})
export class QcChangeVisitDialogComponent implements OnInit {

  moveFilesHandler: Function;
  fileId: number;
  uploadSrc: { uploadGeneralInfoId: number, srcVisitConfigId: number };
  visits: { id: number, name: string, locked: boolean }[];
  moveAll: boolean;

  destVisitConfig: number;
  reason: string;

  visitCtrl = new FormControl('', [
    Validators.required
  ]);

  reasonCtrl = new FormControl('', [
    Validators.required
  ]);

  constructor(
    public dialogRef: MatDialogRef<QcChangeVisitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object,
    private dataUploadService: DataUploadService) {

    this.moveFilesHandler = data['moveFilesHandler'];
    this.fileId = data['fileId'];
    this.uploadSrc = data['uploadSrc'];
    this.visits = data['visits'];
    this.moveAll = data['moveAll'];

    this.visits.filter(visit => !visit.locked);

  }

  ngOnInit() {
  }

  onSaveClick(): void {
    if (!this.visitCtrl.hasError('required') && !this.reasonCtrl.hasError('required')) {
      const project = localStorage.getItem('project') ? JSON.parse(localStorage.getItem('project')) : {
        name: '',
        id: -1
      };

      const self = this;

      if (this.moveAll) {
        this.dataUploadService.moveFilesToAnotherVisit(project.id, {
          srcVisitConfigId: self.uploadSrc.srcVisitConfigId,
          destVisitConfigId: +self.destVisitConfig,
          srcUploadGeneralInfoId: self.uploadSrc.uploadGeneralInfoId,
          reasonForVisitChangeComment: self.reason
        }).subscribe((res: any) => {
          if(res.responseCode === 200) {
            self.dialogRef.close();
          }
        });
      } else {
        this.dataUploadService.moveFileToAnotherVisit(project.id, {
          srcUploadFileId: self.fileId,
          srcVisitConfigId: self.uploadSrc.srcVisitConfigId,
          destVisitConfigId: +self.destVisitConfig,
          reasonForVisitChangeComment: self.reason
        }).subscribe((res: any) => {
          if(res.responseCode === 200) {
            self.dialogRef.close();
          }
        });
      }

    }
  }

  onCloseClick(): void {
    this.dialogRef.close();
  }

}
