import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Store} from '@ngxs/store';
import {ClearAuthInfo} from '../core/data-management/actions/auth-info.action';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {ClearCurrentUserInfo} from '../core/data-management/actions/user-info.action';
import {Role} from '../core/interfaces/role';
import {AuthActivities} from '../core/interfaces/auth-activities';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSUserVersion;
const SERVICE = 'msuser-dot-';
const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable()
export class AuthenticationService {
  private compact = environment.compactToken; //needs to be true after jwt is released
  constructor(private http: HttpClient, private store: Store) {
  }

  login(emailAddress: string, password: string, grecaptcha: string) {
    const requestURL = `${BASE_URL}/user/authenticate-with-email-address?compact=${this.compact}`;
    return this.http.post<BasicResponse<LoginResponseData>>(requestURL, {
      emailAddress: emailAddress,
      password: password,
      grecaptcha: grecaptcha
    });
  }

  loginWithEmailNoCaptcha(emailAddress: string, password: string) {
    const requestURL = `${BASE_URL}/user/authenticate-with-email-address-no-captcha?compact=${this.compact}`;
    return this.http.post<BasicResponse<LoginResponseData>>(requestURL, {emailAddress: emailAddress, password: password});
  }

  loginWithUserNameNoCaptcha(userName: string, password: string) {
    const requestURL = `${BASE_URL}/user/authenticate-with-user-name-no-captcha?compact=${this.compact}`;
    return this.http.post(requestURL, {userName: userName, password: password});
  }

  getUserPasswordHistory(userId: number): Observable<BasicResponse<any>> {
    const requestURL = `${BASE_URL}/password-history/retrieve-with-user-id/${userId}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.clear();
    this.store.dispatch(new ClearAuthInfo());
    window.location.href = '/login';
    this.store.dispatch(new ClearCurrentUserInfo());
  }

  createToken(emailAddress: string, password: string, useCase: string, context: object) {
    const requestURL = `${BASE_URL}/user/token/generate?compact=${this.compact}`;
    return this.http.post<BasicResponse<LoginResponseData>>(requestURL, {
      emailAddress,
      password,
      useCase,
      context
    });
  }
}

export interface LoginResponseData {
  jwt: string;
  roles: Set<Role>;
  activities: AuthActivities;
}
