import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import 'rxjs-compat/add/observable/empty';
import 'rxjs-compat/add/observable/of';

const API_URL = environment.url;
const API_VERSION = environment.MSImageAnalysisVersion;
const SERVICE = 'msimageanalysis-dot-';
const SERVICE_API_URL = `https://${API_VERSION}${SERVICE}${API_URL}`;

@Injectable({
  providedIn: 'root'
})
export class ImagingAnalysisService {

  constructor(private http: HttpClient) {
  }

  getSeriesListByExecutionID(executtionId) {
    if (!!executtionId) {
      return this.http.get(`${SERVICE_API_URL}/executions/0/${executtionId}`);
    } else {
      return Observable.of(null);
    }
  }

  getDicomCheksResult(executionId) {
    return this.http.get(`${SERVICE_API_URL}/executions/4/${executionId}`);
  }

  startGBMMapGenerationMRano(studyId: number, patientId: number, visitConfigId: number, flexibleConfigId: number, seriesIds: number[]) {
    const data = {
      input: {
          studyId: studyId,
          patientId: patientId,
          flexibleConfigId: flexibleConfigId,
          seriesIds: seriesIds,
          visitConfigId: visitConfigId
        }
      };
    return this.http.post('https://' + API_VERSION + SERVICE + API_URL + '/executions/dataprocessing.GBMSegmentation', data);
  }

  startMotionCorrection(series: any, algorithm: string, referenceFrameNumber: number, bucket: string) {
    const data = {
      input: {
        patient_id: series.patient.id,
        series_id: series.seriesId,
        visit_config_id: series.visitConfigId,
        algorithm: algorithm,
        referenceFrameNumber: referenceFrameNumber,
        bucket: bucket
      }
    };
    return this.http.post(`${SERVICE_API_URL}/executions/diamondjs.MotionCorrection`, data);
  }

  generateParametricMap(input: any) {
    const data = {
      input: {
        patient_id: input.patientId,
        series_id: input.seriesId,
        visit_config_id: input.visitConfigId,
        activeIndices: 'All',
        minimumTonset: null,
        maximumTwashout: null,
        firstBaselineFrame: input.firstBaselineFrame,
        lastBaselineFrame: input.lastBaselineFrame,
        bucket: input.bucket
      }
    };
    return this.http.post(`${SERVICE_API_URL}/executions/diamondjs.ReCalculatePM`, data);
  }

  getContainerResultsByExecutionId(executionId: number) {
    return this.http.get(`${SERVICE_API_URL}/execution/info/${executionId}`);
  }

  getContainerResultsWithChilds(parentExecutionId: number) {
    return this.http.get(`${SERVICE_API_URL}/execution/with-childs/${parentExecutionId}`);
  }

  getContainerResultsWithChildsByPatientId(patientId: number) {
    return this.http.get(`${SERVICE_API_URL}/execution/by-patient-id-with-childs/${patientId}`);
  }

  getContainerResultsWithChildsByVisitConfigId(visitConfigIds: number[], excludes: string[]) {
    const data = {
      excludes: excludes,
      visitConfigs: visitConfigIds
    };
    return this.http.post(`${SERVICE_API_URL}/execution/by-visit-config-ids`, data);
  }

  advancedAnalysisIBClinic(seriesData: any[], visitConfigId: number, patientId: number, bucket: string, studyId: number, flexibleConfigId: number) {
    const data = {
      input: {
        seriesids: seriesData,
        visit_config_id: visitConfigId,
        patient_id: patientId,
        bucket: bucket,
        study_id: studyId,
        flexible_config_id: flexibleConfigId
      }
    };
    return this.http.post(`${SERVICE_API_URL}/executions/imageprocessing.thirdparty.ibneuro.IBNeuro`, data);
  }

  GBMRegisteration(seriesData: any[], visitConfigId: number, patientId: number, bucket: string, studyId: number, flexibleConfigId: number) {
    const data = {
      input: {
        seriesInfo: seriesData,
        visit_config_id: visitConfigId,
        patient_id: patientId,
        bucket: bucket,
        study_id: studyId,
        flexible_config_id: flexibleConfigId
      }
    };
    return this.http.post(`${SERVICE_API_URL}/executions/imageprocessing.features.GBMRegistration`, data);
  }

  refreshContainerResults(containerId: number) {
    return this.http.put(`${SERVICE_API_URL}/reexecutions/${containerId}`, null);
  }

}
