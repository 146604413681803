import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MoaksVisitModel} from '../../../../../_models/MOAKS/moaks-visit-model';
import {MoaksCartilageScoringModel} from '../../../../../_models/MOAKS/moaks-cartilage-scoring-model';
import { SharedService } from '../../moaks-basic-form/shared.service';

@Component({
    selector: 'app-moaks-cartilage-scoring-form',
    templateUrl: './moaks-cartilage-scoring-form.component.html',
    styleUrls: ['./moaks-cartilage-scoring-form.component.css']
})
export class MoaksCartilageScoringFormComponent implements OnInit, OnDestroy {
    _cartLosses = ['0.0', '1.0', '1.1', '2.0', '2.1', '2.2', '3.0', '3.1', '3.2', '3.3', 'U'];
    _cartLossesMocart = ['0.0', '1.0', '1.1', '2.0', '2.1', '2.2', '3.0', '3.1', '3.2', '3.3', 'U', '+0.5', '-0.5', '+5.0', '-5.0', '+5.5', '-5.5'];
    cartLosses = this._cartLosses;

    form: FormGroup;
    tableDataSource: MatTableDataSource<MoaksCartilageScoringModel>;
    tableColumns = ['Name', 'Loss'];

    @Input()
    get visit(): MoaksVisitModel {
        return this._visit;
    }

    set visit(visit: MoaksVisitModel) {
        this._visit = visit;
        if (this.form) { // ngOnInit() will init first time
            this.init();
        }
    }

    private _visit: MoaksVisitModel;

    @Input() uid: string;
    @Input() isMocart: boolean;

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
    }

    ngOnInit(): void {
        this.tableDataSource = new MatTableDataSource<MoaksCartilageScoringModel>();
        const scoringFormControl = this.fb.array([]);
        this._visit.cartilageScorings.filter(scoring => scoring.loss !== 'N/A').forEach((scoring) => {
            scoringFormControl.push(this.fb.group({
                id: ['', [Validators.required]],
                code: ['', [Validators.required]],
                region: ['', [Validators.required]],
                loss: ['', [Validators.required]]
            }));
        });

        this.form = this.fb.group({
            scorings: scoringFormControl
        });
        this.init();
        this.fillEmptyCellsWithZero();

        this.sharedService.sync(this.uid, (rowIndex, valueName, value) => {
          this._visit.cartilageScorings[rowIndex][valueName] = value;
          if (this.form) {
            (this.form.get('scorings') as FormArray).controls[rowIndex].get(valueName).setValue(value);
          }
        });
    }

    ngOnDestroy(): void {
        this.setSubmitData();
        this.sharedService.messageSource.next(null);
    }

    init(): void {
        this.tableDataSource.data = this._visit.cartilageScorings.filter(scoring => scoring.loss !== 'N/A');

        this.cartLosses = this.isMocart ? this._cartLossesMocart : this._cartLosses;
        let index = 0;
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.tableDataSource.data[index];
            formGroup.get('id').setValue(scoring.id);
            formGroup.get('code').setValue(scoring.code);
            formGroup.get('region').setValue(scoring.region);
            formGroup.get('loss').setValue(scoring.loss);
            if (this.shouldDisable()) {
              formGroup.disable();
            }
            index++;
        });
    }

    fillEmptyCellsWithZero(): void {
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            if (!formGroup.get('loss').value) {
                formGroup.get('loss').setValue('0.0');
            }
        });
    }

    reset(): void {
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            formGroup.get('loss').reset();
            formGroup.get('id').reset();
            formGroup.get('code').reset();
            formGroup.get('region').reset();
        });
    }
    shouldDisable(){
      return (this._visit.status ||'') .toLowerCase() === 'done';
    }


    setSubmitData() {
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this._visit.cartilageScorings
            .filter(scoring => scoring.loss !== 'N/A')
            .find(value => value.id === formGroup.get('id').value);
            if (scoring) {
              const newLoss = formGroup.get('loss').value;
              scoring.loss = newLoss;
            }
        });
    }

    duplicateValue(rowIndex, item) {
      this.setSubmitData();
      const uidIndex = this.uid.replace(/\D+/g, '');
      const val = this._visit.cartilageScorings[rowIndex][item];
      this.sharedService.messageSource.next([rowIndex, item, val, uidIndex]);
    }
}
