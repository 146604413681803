import {AnnKmaObject} from './ann-kma-object';

export class AnnKmaEditDesigner extends lt.Annotations.Designers.AnnPolylineEditDesigner {
  // tslint:disable-next-line:max-line-length
  constructor(automationControl: lt.Annotations.Engine.IAnnAutomationControl, container: lt.Annotations.Engine.AnnContainer, annJswObject: AnnKmaObject) {
    super(automationControl, container, annJswObject);
  }

  onEdit(e: lt.Annotations.Engine.AnnEditDesignerEventArgs): void {
    super.onEdit(e);
  }
}

