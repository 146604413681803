import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PrimaryBodyLocationModel} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-model';
import {PrimaryBodyLocationService} from '../../../../_services';
import {PrimaryBodyLocationDialogModel} from '../primary-body-location-dialog/primary-body-location-dialog.component';
import {AbstractControl, FormControl, ValidationErrors} from '@angular/forms';

@Component({
  selector: 'app-primary-body-location-status-dialog',
  templateUrl: './primary-body-location-status-dialog.component.html',
  styleUrls: ['./primary-body-location-status-dialog.component.css']
})
export class PrimaryBodyLocationStatusDialogComponent implements OnInit {

  currentLocation: PrimaryBodyLocationDialogModel;

  statusFormControl =  new FormControl('ACTIVE', [this.validateUniqueName]);

  constructor(
    private primaryBodyLocationService: PrimaryBodyLocationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PrimaryBodyLocationDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: PrimaryBodyLocationDialogModel
  ) {
    this.currentLocation = JSON.parse(JSON.stringify(this.data));
  }

  ngOnInit(): void {
    this.statusFormControl.setValue(this.data.bodyLocation.status.toUpperCase());
  }

  get validateUniqueName() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.currentLocation != null) {
        let names = this.data.bodyLocationsList.filter(p => p.name === this.currentLocation.bodyLocation.name
          && 'ACTIVE' === p.status.toUpperCase());
        if (names.length === 1) {
          if (names[0].id === this.data.bodyLocation.id) {
            names = [];
          }
        }
        return names.length > 0 ? {duplicateValueError: 'Duplicate.'} : null;
      }
      return null;
    };
  }

  onStatusChanged(event) {
    this.statusFormControl.updateValueAndValidity();
  }

  save(): void {
    const location = {
      id: this.currentLocation.bodyLocation.id,
      status: this.statusFormControl.value
    };
    this.primaryBodyLocationService.updateStatus(<PrimaryBodyLocationModel> location).subscribe(presp => {
      this.dialogRef.close(presp.data);
    });
  }

  close() {
    this.dialogRef.close();
  }

}
