import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastOptions} from 'ng2-toasty';
import {ReadingConfigFlexibleService, StudySequenceLabelService, StudyService, StudyUserService} from '../../../../../_services';
import {MatTableDataSource} from '@angular/material/table';

import {MultiselectAnswers, QuestionFormat} from './model.questionformat';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {FormMode} from '../../../../../core/constants/form-mode';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {User} from '../../../../../_models/user';
import {MatSelectChange} from '@angular/material/select';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {IncidentalFindingsConfigModel, IncidentalFindingsVisitModel} from '../../../../../_models/ImagingProject/IF/incidental-findings-config-model';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {defaultBasicAutoBatchConfig, ImagingProjectSubmitEvent} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';
import { MultiselectAnswersDialogComponent } from '../multiselect-answers-dialog/multiselect-answers-dialog.component';
import { VisitModalityConfiguratorDialogComponent } from '../../visit-modality-configurator-dialog/visit-modality-configurator-dialog.component';
import { ReadingLevelOptionsDialogComponent } from 'src/app/components/controls/reading-level-options-dialog/reading-level-options-dialog.component';
import * as moment from 'moment';
import { VisitType } from 'src/app/_models/ImagingProject/patient-model';
interface Config {
  endpointReadingVersion: 'basic';
  readingVersion: string;
  readingLevel: 'modality' | 'visit' | 'longitudinal';
  readingLevelOptions: { smartRead: boolean, sequentialLock: boolean };
  modality: ModalityModel;
  readingType: 'parallel' | 'sequential';
  screenshotFeature: boolean;
  enableCommentOptionsForScreenshot: boolean;
  screenshotComments: string[];
  readers: User[];
  visit: IncidentalFindingsVisitModel[];
  anatomySelector: SequenceLabelModel[];
  batchManagement: boolean;
  optionText: string;
  hideVisitChronology: string;
}

type ConfigControls = { [key in keyof Config]: FormControl | FormArray };
type ConfigFormGroup = FormGroup & { value: Config, controls: ConfigControls };

export type AnswerType = 'textArea' | 'radioButtonWithComment' | 'radioButtonWithoutComment' | 'radioButtonMultiselectWithComment';

export class ReaderErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    const readersIsInvalid = form.form.controls.readers.invalid;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted) ||
              (readersIsInvalid && (control.dirty || control.touched || isSubmitted)));
  }
}

@Component({
  selector: 'app-imaging-project-reading-if',
  templateUrl: './imaging-project-reading-if.component.html',
  styleUrls: ['./imaging-project-reading-if.component.css']
})
export class ImagingProjectReadingIfComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  readonly selected = 'enabled';

  readonly SHOW_VISITS_CHRONOLOGY = 'show_visits_chronology';
  readonly HIDE_VISITS_CHRONOLOGY = 'hide_visits_chronology';
  readonly EARLY_TERMINATION_VISIT = 'Early Termination';

  readonly MAX_COMMENTS = 50;
  readonly MAX_QUESTIONS = 20;
  readonly MAX_READERS = 20;

  @Output() public clickCancel = new EventEmitter<ImagingProjectSubmitEvent>();
  @Output() public clickSubmit = new EventEmitter<ImagingProjectSubmitEvent>();

  @Input() public mode: FormMode;

  @Input() set configModel(configModel: IncidentalFindingsConfigModel) {
    if (configModel) {
      this.updateETVisit(configModel);
      this._configModel = configModel;
    }
  }

  @Input() selectedConfig: FlexibleConfig<IncidentalFindingsConfigModel>;

  get configModel(): IncidentalFindingsConfigModel {
    return this._configModel;
  }

  get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true && ((!!this.batchConfig && !this.batchConfig?.numberOfReadings) || !this.batchConfig)
        ? { norError: 'Number of readings is required' } : null;
    };
  }

  get questionValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!control.value && this.configForm?.controls.questions.value.length < 1 ? { questionRequired: '' } : null;
    };
  }

  questionAnswerValidator(formArrayIndex: number, formControlName: string) {
    const formGroup = (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex] as FormGroup;

    if ((formGroup.get('answerType').value as AnswerType) !== 'textArea' &&
      (!formGroup.get(formControlName).value || formGroup.get(formControlName).value === '')) {
      formGroup.get(formControlName).setErrors({ message: 'Answer option is required' })
    }
    else if ((formGroup.get('answerType').value as AnswerType) !== 'textArea' &&
      (formGroup.get('answeringOption1').value === formGroup.get('answeringOption2').value)) {
      formGroup.get(formControlName).setErrors({ message: 'The answering option must be unique across one question' })
      formGroup.get(formControlName)
    }
    else if ((formGroup.get('answerType').value as AnswerType) === 'radioButtonMultiselectWithComment'
      && (formGroup.get('multiSelectAnswers').value === '' || !formGroup.get('multiSelectAnswers').value ) && formControlName === 'answeringOption2') {
      formGroup.get(formControlName).setErrors({ message: 'For this type of question there must be at least one sub answer for option 2' })
    }
    else {
      formGroup.get(formControlName).setErrors(null);
    }

  }

  get optionTextValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return this.configForm?.controls.enableCommentOptionsForScreenshot.value === true &&
        this.configForm?.controls.screenshotComments.value.length < 1 ? { requiredScreenshotComment: true } : null;
    };
  }

  readonly formModes = FormMode;

  _configModel: IncidentalFindingsConfigModel;
  batchConfig: BatchConfigModel;

  availableSequenceLabels: SequenceLabelModel[] = [];

  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];
  displayedScreenshotOptions: string[] = ['id', 'optionText', 'actionDelete'];
  displayQuestionDetails: string[] = ['id', 'questionText', 'answerType', 'answeringOption1', 'answeringOption2', 'commentText', 'actionDelete'];

  questionsDataSource: MatTableDataSource<IncidentalFindingsVisitModel>;
  screenshotCommentsDataSource: MatTableDataSource<string>;
  readersDataSource: MatTableDataSource<User>;

  availableReaders: User[] = [];
  readerSelectOptions: User[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  studyId: number;

  configForm: ConfigFormGroup;
  questionsForm: FormGroup;

  selectedReaderIds: number[] = [];
  readerMatcher = new ReaderErrorStateMatcher();
  modalities: ModalityModel[];
  selectedQuestionTemplate: AnswerType;
  questionFormat: QuestionFormat;

  readingLevel: string;
  readingType: string;

  studyVisits: IncidentalFindingsVisitModel[] = [];
  unscheduledAllowed: boolean;

  eligibilityVersion: boolean = false;
  multiTabVersion: boolean = false;

  @ViewChild('questionText') questionText: ElementRef;
  @ViewChild('answerType') answerType: ElementRef;
  @ViewChild('answeringOption1') answeringOption1: ElementRef;
  @ViewChild('answeringOption2') answeringOption2: ElementRef;
  @ViewChild('screenshotOption') screenshotOption: ElementRef;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private studySequenceLabelService: StudySequenceLabelService,
    private studyUserService: StudyUserService,
    private studyService: StudyService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private batchLogicService: BatchLogicService) {
    super();
  }

  ngOnInit() {
    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    this.screenshotCommentsDataSource = new MatTableDataSource<string>();
    this.questionsDataSource = new MatTableDataSource<IncidentalFindingsVisitModel>();
    this.readersDataSource = new MatTableDataSource<User>();

    this.initConfigForm();
    const serviceCalls = [
      this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
      this.studyUserService.getReadersByStudyId(this.studyId),
      this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId),
      this.studyService.getVisitsByStudyId(this.studyId),
      this.studyService.getById(this.studyId)
    ];

    if (this.mode === FormMode.EDIT) {
      serviceCalls.push(this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id));
    }

    forkJoin(serviceCalls)
      .subscribe(([respGroupedModalitiesResp, readersResponse, studySequenceResp, studyVisitsResponse, studyServiceResponse, batchConfigResponse]) => {
        this.studyVisits = [...studyVisitsResponse['data']];
        this.initModalities(respGroupedModalitiesResp);
        this.initStudyVisits(studyServiceResponse['data']);

        this.initConfigFormValues(readersResponse['data']);
        this.initReaders(readersResponse);
        this.prepareSequenceLabels(studySequenceResp);

        if(this.mode === FormMode.NEW) {
          this.addVisitsToConfigForm();
        }

        if (this.mode === FormMode.EDIT) {
          this.initBatchConfig(batchConfigResponse['data']);
          this.filterVisits(this.configForm.controls.visit.value);
        }
      });
  }

  private updateETVisit(configModel: IncidentalFindingsConfigModel) {
    configModel.visit.forEach(visit => {
      if (!visit.id && visit.name === this.EARLY_TERMINATION_VISIT) {
        visit.durationTimeValue = null;
        visit.durationTimeUnit = null;
      }
    });
  }

  onChangeReadingVersion(event: any) {
    this.eligibilityVersion = false;
    this.multiTabVersion = false;
    if (event.value === ReadingVersion.IF_GENANT_ELIGIBILITY) {
      this.eligibilityVersion = true;
    } else if(event.value === ReadingVersion.IF_Multi_Tab) {
      this.multiTabVersion = true;
    } 
    if (this.mode === FormMode.EDIT) {
      this.filterVisits(this.configModel.visit);
    }
    this.addVisitsToConfigForm();
    if(this.mode === FormMode.NEW || this.configModel.readingVersion != ReadingVersion.IF_Multi_Tab) {
      this.initQuestionsForm();
    }
  }

  openReadingLevelConfiguration() {
    ImagingProjectReadingBasicComponent.clearFocus();
    let inputOptions: string[];
    const val = this.configForm.controls.readingLevelOptions.value;
    if (!val) {
      inputOptions = ["SMART", "SEQUENTIAL"];
    } else {
      inputOptions = [];
      if(val.smartRead)
        inputOptions.push("SMART");
      if (val.sequentialLock)
        inputOptions.push("SEQUENTIAL");
    }
    const dialogRef = this.dialog.open(ReadingLevelOptionsDialogComponent, {
      width: '500px',
      data: inputOptions
    });

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result) {
        if ( !result ) {
          result = [];
        }
        const options = {
          smartRead: result.includes("SMART"),
          sequentialLock: result.includes("SEQUENTIAL"),
        }
        this.configForm.patchValue({
          readingLevelOptions : options
        })
      }
    });
  }

  initBatchConfig(batchConfigData): void {
    this.batchConfig = batchConfigData[0];
    this.configForm.controls.batchManagement.updateValueAndValidity();
  }

  initModalities(respGroupedModalitiesResp): void {
    if (respGroupedModalitiesResp.data != null) {
      this.modalities = respGroupedModalitiesResp.data;
      this.modalities.sort((a, b) => a.name.localeCompare(b.name));
      this.configForm.controls.modality.setValue(this.modalities)
      this.initDefaultSequenceLabels(this.configForm.controls.modality.value);
    }
  }

  openModalitiesDialog() {
    ImagingProjectReadingBasicComponent.clearFocus();
    const dialogRef = this.dialog.open(VisitModalityConfiguratorDialogComponent, {
      data: {
        visits: this.configForm.controls.visit.value,
        modalities: this.modalities,
        unscheduledAllowed: this.unscheduledAllowed
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.filterVisits(result.visits);
        const uniqueModality = this.getAllVisitsUniqueModalities(result.visits);
        this.configForm.controls.modality.setValue(uniqueModality);
        this.initDefaultSequenceLabels(this.configForm.controls.modality.value);
      }
    });
  }

  getAllVisitsUniqueModalities(visits: any[]) {
    let modalities = [];
    visits.forEach((visit, visitIndex) => {
      (<FormArray>this.configForm.controls.visit).controls[visitIndex]
        .get("modalities")
        .setValue(visit.modalities);
      modalities = [...modalities, ...visit.modalities];
    });
    if (!!this.configModel)
      this.configModel.visit = visits;
     const uniqueModality = [];
     modalities.forEach((modality) => {
       let index = uniqueModality.findIndex((m) => m.id === modality.id) === -1;
       if (index) uniqueModality.push(modality);
     });
     return uniqueModality;
  }

  initReaders(readersResponse): void {
    if (readersResponse.responseCode === 200) {
      if (readersResponse.data) {
        this.availableReaders = readersResponse.data;
        if (this.mode === this.formModes.NEW) {
          this.readerSelectOptions =
            (readersResponse.data as User[]).sort(this.compareReadersByName);
        }
      }
    }
  }

  initConfigForm(): void {
    this.configForm = this.fb.group({
      endpointReadingVersion: new FormControl('basic', [Validators.required]),
      readingVersion: new FormControl(null, [Validators.required]),
      readingLevel: new FormControl(null, [Validators.required]),
      modality: new FormControl([], [Validators.required]),
      readingType: new FormControl(null, [Validators.required]),
      readingLevelOptions: new FormControl({smartRead: true, sequentialLock: true}, [Validators.required]),
      screenshotFeature: new FormControl(true, [Validators.required]),
      enableCommentOptionsForScreenshot: new FormControl(false, [this.optionTextValidator]),
      screenshotComments: new FormControl([]),
      readers: new FormControl([], [Validators.required]),
      visit: new FormArray([], [Validators.required]),
      anatomySelector: new FormControl(null, [Validators.required]),
      batchManagement: new FormControl(false, [Validators.required, this.batchManagementValidator]),
      optionText: new FormControl(''),
      hideVisitChronology: new FormControl(this.SHOW_VISITS_CHRONOLOGY, [Validators.required]),
    } as ConfigControls) as ConfigFormGroup;
  }

  initStudyVisits(study: any) {
    //sort study visits by id
    this.studyVisits = this.sortVisits(this.studyVisits);
    this.unscheduledAllowed = study.unscheduledAllowed;
    //add unschaduled visits and early termination
    const unscheduled = new IncidentalFindingsVisitModel();
    unscheduled.name = 'Unscheduled';
    unscheduled.type = VisitType.UNSCHEDULED_REGULAR;
    this.studyVisits.push(unscheduled);

    const earlyTermination = new IncidentalFindingsVisitModel();
    earlyTermination.name = this.EARLY_TERMINATION_VISIT;
    earlyTermination.type = VisitType.UNSCHEDULED_EARLY_TERMINATION;
    this.studyVisits.forEach(visit => visit.isAvailable = true);
    this.studyVisits.push(earlyTermination);
  }

  sortVisits(visits) {
    visits.forEach(visit => {
      let visitDate = moment(visit.create);
      if (visit.durationTimeUnit == 'D') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'd');
      } else if (visit.durationTimeUnit == 'W') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'w');
      } else if (visit.durationTimeUnit == 'M') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'M');
      }
      visit.visitDate = visitDate;
    });
    visits.sort((a, b) => {
      if (a.visitDate < b.visitDate) {
        return -1;
      }
      if (a.visitDate > b.visitDate) {
        return 1;
      }
      return 0;
    });
    let baselineIndex = visits.indexOf(visits.find(this.isBaseline));
    visits.unshift(visits.find(this.isBaseline));
    visits.splice(baselineIndex + 1, 1);
    return visits;
  }
  isBaseline(visit) {
    return visit.baseline === true;
  }

  addVisitsToConfigForm() {
    (<FormArray>this.configForm.controls.visit) = new FormArray([], [Validators.required]);

    this.getModifiedVisit().forEach(visit => {
      const visitControl = new FormGroup({
        id: new FormControl(visit.id),
        name: new FormControl(visit.name, [Validators.required]),
        created: new FormControl(visit.created),
        durationTimeUnit: new FormControl(visit.durationTimeUnit),
        durationTimeValue: new FormControl(visit.durationTimeValue),
        baseline: new FormControl(visit.baseline),
        repeatAllowed: new FormControl(visit.repeatAllowed),
        type: new FormControl(visit.type ? visit.type : (visit.baseline ? VisitType.BASELINE : VisitType.REGULAR)),
        questions: new FormControl([]),
        modalities: new FormControl(!this.unscheduledAllowed && visit.type === VisitType.UNSCHEDULED_REGULAR ? [] : this.configModel != null ? this.configModel.visit.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).modalities : this.modalities)
      });

      (<FormArray>this.configForm.controls.visit).push(visitControl);
    })
  }

  initConfigFormValues(availableReaders: User[]): void {
    const inputConfig = this.configModel;
    if (this.mode === FormMode.EDIT) {

      
      const readers = availableReaders.filter(r => !!inputConfig.readers.find(rId => rId.id === r.id));
      this.readerSelectOptions = availableReaders.filter(r => !readers.find(rId => rId.id === r.id));

      let screenshotFeature: boolean;
      if (!!inputConfig.amiScreenshotFeature) {
        screenshotFeature = inputConfig.amiScreenshotFeature;
      } else if (!!inputConfig.screenshotFeature) {
        screenshotFeature = inputConfig.screenshotFeature;
      }
      this.configForm.patchValue({
        endpointReadingVersion: inputConfig.endpointReadingVersion,
        readingVersion: inputConfig.readingVersion,
        readingLevel: inputConfig.readingLevel,
        readingLevelOptions: inputConfig.readingLevelOptions,
        readingType: inputConfig.readingType,
        screenshotFeature: screenshotFeature,
        enableCommentOptionsForScreenshot: inputConfig.enableCommentOptionsForScreenshot,
        screenshotComments: inputConfig.screenshotComments,
        batchManagement: inputConfig.batchManagement,
        readers: readers,
        modality: inputConfig.modalities,
        hideVisitChronology: inputConfig.hideVisitChronology
      });
      
      this.onChangeReadingVersion({ value: inputConfig.readingVersion });

      if(this.configModel.readingVersion != ReadingVersion.IF_Multi_Tab) {
        this.patchVisitQuestionsAndModalities();
      }

      if (!inputConfig.hideVisitChronology) {
        this.configForm.patchValue({ hideVisitChronology: this.SHOW_VISITS_CHRONOLOGY });
      }

      this.screenshotCommentsDataSource.data = inputConfig.screenshotComments;
      this.readersDataSource.data = readers;

      this.configForm.markAllAsTouched();
      this.configForm.markAsDirty();

      this.configForm.controls.enableCommentOptionsForScreenshot.updateValueAndValidity();
    }
  }

  patchVisitQuestionsAndModalities() {
    for (const [index, formGroup] of this.toEntries((<FormArray>this.configForm.controls.visit).controls)) {
      const value = formGroup.value;
      const existedVisit = this.configModel.visit.find(v => { return (value.id && v.id === value.id) || (!value.id && v.type === value.type) });
      if (existedVisit !== undefined) {
        formGroup.setValue(existedVisit);
        const qDSIndex = this.getExactVisitIndex(this.questionsDataSource.data, existedVisit);
        if (qDSIndex !== -1)
          this.questionsDataSource.data[qDSIndex].questions = existedVisit.questions.sort((a, b) => b.qIndex - a.qIndex);
      } else {
        formGroup.get('modalities').setValue([]);
      }
    }
  }

  private getExactVisitIndex(visitArray: IncidentalFindingsVisitModel[], visit: IncidentalFindingsVisitModel) {
    return visitArray.findIndex(v => { return (visit.id && v.id === visit.id) || (!visit.id && v.type === visit.type) })
  }

  toEntries<T>(a: T[]) {
    return a.map((value, index) => [index, value] as const);
  }

  getQuestionDataSource(visitIndex) {
    const questionsDataSource = new MatTableDataSource<QuestionFormat>(
      this.questionsDataSource.data[visitIndex]?.questions
    );
    return questionsDataSource;
  }

  initQuestionsForm(): void {
    this.questionsDataSource.data = [];

    this.questionsForm = new FormGroup({
      questions: new FormArray([]),
    });

    this.getModifiedVisit().forEach((visit, index) => {

      const control = new FormGroup({
        questionText: new FormControl('', [Validators.required]),
        answerType: new FormControl('', [Validators.required]),
        answeringOption1: new FormControl(''),
        answeringOption2: new FormControl(''),
        multiSelectAnswers:new FormControl(''),
        visitType: new FormControl(visit.type),
        visitId: new FormControl(visit.id),
        qIndex: new FormControl(0)
      });

      (<FormArray>this.questionsForm.get('questions')).push(control);
      this.questionsDataSource.data.push(visit);
    })

    this.initQuestions();
  }

  getValidity(formArrayIndex: number, formControlName: string) {
    return (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].get(formControlName);
  }

  initQuestions(): void {
    if (!!this.configModel && !!this.configModel.visit) {
      this.configModel.visit.forEach((v, index) => {

        const questions = this.configModel.visit[index].questions as QuestionFormat[];
        questions.forEach(question => {
          if (question.answerType === 'radioButtonWithComment') {
            question.commentText = 'Comment required';
          }
        });
      })
    }
  }

  prepareSequenceLabels(studySequenceResp) {
    if (this.mode === FormMode.EDIT) {
      this.initDefaultSequenceLabels(this.configModel?.modalities);
      if (!!this.configModel?.anatomySelector) {
        this.selectSequenceLabels(this.configModel.anatomySelector as SequenceLabelModel[]);
      }
    } else if (this.mode === FormMode.NEW) {
      this.initDefaultSequenceLabels(this.configForm.controls.modality.value);
    }
  }

  initDefaultSequenceLabels(modalities) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectSequenceLabels(this.availableSequenceLabels);
  }

  selectSequenceLabels(sequences: SequenceLabelModel[]) {
    this.configForm.controls.anatomySelector.setValue(sequences);
  }

  getAvailableSequenceLabels(modalities): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const m of modalities) {
      for (const label of m.sequenceLabels) {
        configLabels.add(label);
      }
    }
    return Array.from(configLabels);
  }

  onSequenceLabelSelect(sequence: SequenceLabelModel[]) {
    this.selectSequenceLabels(sequence);
  }

  onModalityTypeChange(event: MatSelectChange) {
    this.initDefaultSequenceLabels(this.configForm.controls.modality.value);
  }

  onAddSelectedReadersClick() {
    const selectedReaderIds = [...this.selectedReaderIds, ...this.readersDataSource.data.map(reader => reader.id)];
    this.readersDataSource.data = this.availableReaders
      .filter(reader => selectedReaderIds.includes(reader.id))
      .sort(this.compareReadersByName);
    this.configForm.controls.readers.setValue(this.readersDataSource.data);
    this.configForm.controls.readers.updateValueAndValidity();
    this.selectedReaderIds = [];
    this.readerSelectOptions = this.availableReaders
      .filter(reader => !selectedReaderIds.includes(reader.id))
      .sort(this.compareReadersByName);
    this.configForm.patchValue({ readers: this.readersDataSource.data });
  }

  deleteSelectedReader(reader: User): void {
    this.readersDataSource.data = this.readersDataSource.data.filter(r => r.id !== reader.id);
    this.readerSelectOptions = [...this.readerSelectOptions, this.availableReaders
      .find(r => r.id === reader.id)].sort(this.compareReadersByName);
    this.configForm.controls.readers.setValue(this.readersDataSource.data);
    this.configForm.controls.readers.updateValueAndValidity();
  }

  onAnswerTypeChange(formArrayIndex: number, event: { value: AnswerType }): void {
    this.resetAndValidateAnswerOptions(formArrayIndex);
    this.selectedQuestionTemplate = event.value;
    this.questionFormat = new QuestionFormat();
  }

  resetAndValidateAnswerOptions(formArrayIndex: number): void {
    (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].get('answeringOption1').updateValueAndValidity();
    (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].get('answeringOption2').updateValueAndValidity();
    (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].get('answeringOption1').reset();
    (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].get('answeringOption2').reset();
    (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].get('multiSelectAnswers').reset();
  }

  onAddQuestionClick(formArrayIndex: number): void {
    const question: QuestionFormat = { uuid: UUID.genV4().toString(), ...(<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].value as QuestionFormat };
    question.qIndex = 0;
    if (this.questionsDataSource.data[formArrayIndex].questions)
    question.qIndex = this.questionsDataSource.data[formArrayIndex].questions.reduce((a, b) => {
      return Math.max(a, b.qIndex);
    }, 0) + 1;
    const visitIndex = this.getExactVisitIndex((<FormArray>this.configForm.controls.visit).value, this.questionsDataSource.data[formArrayIndex]);
    (<FormArray>this.configForm.controls.visit).controls[visitIndex].get('questions').setValue([...(<FormArray>this.configForm.controls.visit).controls[formArrayIndex].get('questions').value, question]);
    (<FormArray>this.configForm.controls.visit).controls[visitIndex].get('questions').updateValueAndValidity();

    this.questionsDataSource.data[formArrayIndex].questions = (<FormArray>this.configForm.controls.visit).controls[visitIndex].get('questions').value;
    this.questionsDataSource.data[formArrayIndex].questions = this.questionsDataSource.data[formArrayIndex].questions.sort((a, b) => b.qIndex - a.qIndex);
    this.questionIsEmpty()
  }
  getCommentRequiredText(question: QuestionFormat) {
    if (question.answerType === 'radioButtonWithComment')
      return 'Obligatory'
    if (question.answerType === 'radioButtonMultiselectWithComment') {
      if (question.multiSelectAnswers?.findIndex(m => m.commentRequired) !== -1)
        return 'Obligatory'
    }
    return;
  }
  onOpenMultiSelectDialog({ question, formArrayIndex }) {
    ImagingProjectReadingBasicComponent.clearFocus();
    const multiAnswers = question ? question.multiSelectAnswers :(<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].get('multiSelectAnswers').value;
    const dialogRef = this.dialog.open(MultiselectAnswersDialogComponent, {
      width:'700px',
      data: {
        data: multiAnswers,
        hasCommentRequiredField: true,
        title: 'multiselect',
      },
      disableClose: false
    });
    dialogRef.afterClosed().subscribe((result: MultiselectAnswers[]) => {
      if (result) {
        if (question) {
          this.questionsDataSource.data[formArrayIndex].questions.find(q => q.uuid === question.uuid).multiSelectAnswers = [...result];
          (<FormArray>this.configForm.controls.visit).controls[formArrayIndex].get('questions').setValue(this.questionsDataSource.data[formArrayIndex].questions);
          (<FormArray>this.configForm.controls.visit).controls[formArrayIndex].get('questions').updateValueAndValidity();
        } else
          (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].get('multiSelectAnswers').setValue(result)
      }

    });
  }

  onAddScreenshotOption() {
    const comment = {optionText: this.screenshotOption.nativeElement.value};
    const counter = this.configForm.controls.screenshotComments.value.length;

    if (counter < this.MAX_COMMENTS) {
      const screenshotComments = [...this.configForm.controls.screenshotComments.value, comment];
      this.configForm.controls.screenshotComments.setValue(screenshotComments);
      this.screenshotCommentsDataSource.data = screenshotComments;
      this.configForm.controls.optionText.updateValueAndValidity();
    }
    this.configForm.controls.enableCommentOptionsForScreenshot.updateValueAndValidity();
  }

  onDeleteScreenshotComment(comment: string) {
    const comments = (this.configForm.controls.screenshotComments.value as string[]).filter(c => c !== comment);
    this.screenshotCommentsDataSource.data = comments;
    this.configForm.patchValue({screenshotComments: comments});
    this.configForm.controls.enableCommentOptionsForScreenshot.updateValueAndValidity();
  }

  onBatchManagementSelected(): void {
    if (this.batchConfig) {
      this.batchConfig.numberOfReadings = null;
    }
    this.configForm.controls.batchManagement.updateValueAndValidity();
  }

  openAutoCreationBatchConfigDialog(config: BatchConfigModel): void {
    ImagingProjectReadingBasicComponent.clearFocus();
    let inputConfig = config as AutoBatchConfigPayload;
    const val = this.configForm.controls.batchManagement.value;
    if (!val) {
      inputConfig = { ...defaultBasicAutoBatchConfig };
    } else {
      inputConfig = this.batchConfig ? { ...this.batchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        readingType: ReadingType.BASIC_READING,
        configInput: inputConfig,
        readonlyDialog: !val
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        this.batchConfig = {...result};
        this.configForm.get('batchManagement').updateValueAndValidity();
      }

      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  deleteQuestion(question: QuestionFormat, formArrayIndex: number) {
    const visitIndex = this.getExactVisitIndex((<FormArray>this.configForm.controls.visit).value, this.questionsDataSource.data[formArrayIndex]);
    const filteredQuestions = ((<FormArray>this.configForm.controls.visit).controls[visitIndex].get('questions').value as QuestionFormat[]).filter(q => q.uuid !== question.uuid);
    (<FormArray>this.configForm.controls.visit).controls[visitIndex].get('questions').setValue(filteredQuestions);
     this.questionsDataSource.data[formArrayIndex].questions = filteredQuestions;
    (<FormArray>this.configForm.controls.visit).controls[visitIndex].get('questions').updateValueAndValidity();
  }

  onSubmitClick() {
    this.propagateSubmitEvent(this.prepareConfigModel());
  }

  propagateSubmitEvent(configModel): void {
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: configModel,
      basicBatchConfig: this.batchConfig
    };
    this.clickSubmit.emit(submitEvent);
  }

  prepareConfigModel() {
    const val: Config = this.configForm.value;
    const modalities = this.configForm.controls.modality.value;
    return {
      readers: val.readers.map(r => {
        return {id: r.id};
      }),
      anatomySelector: val.anatomySelector.map(a => {
        return {id: a.id};
      }),
      modalities: modalities,
      endpointReadingVersion: 'basic',
      endpoint: null,
      batchManagement: val.batchManagement,
      enableCommentOptionsForScreenshot: val.enableCommentOptionsForScreenshot,
      visit: this.fillQuestions(val.visit),
      readingVersion: <ReadingVersion>val.readingVersion,
      readingLevel: val.readingLevel,
      readingLevelOptions: val.readingLevelOptions,
      readingType: val.readingType,
      screenshotComments: val.screenshotComments,
      screenshotFeature: val.screenshotFeature,
      hideVisitChronology: val.hideVisitChronology
    };
  }

  fillQuestions(visits: IncidentalFindingsVisitModel[]) {
    if(this.configForm.controls.readingVersion.value != 'IFMultiTab'){
      visits.forEach((v,i) => {
        v.questions = this.questionsDataSource.data[i].questions
      })
    }
    return visits;
  }

  questionIsEmpty() {
    let index = 0;
    this.questionsDataSource.data?.forEach(visit => {
      if (visit.isAvailable) {
        if (visit.questions) {
          if (visit.questions.length === 0) {
            index++;
          }
        } else {
          index++;
        }
      }
    });
    return index > 0;
  }

  onCancelClick() {
    this.clickCancel.emit({result: 'cancel', data: null});
  }

  objectComparatorById = function (obj1, obj2): boolean {
    return obj1.id === obj2.id;
  };

  compareReadersByName(r1: User, r2: User): number {
    return (r1.firstName + r1.lastName).localeCompare(r2.firstName + r2.lastName);
  }

  updateValidity(formArrayIndex: number, formControlName: string) {
    this.questionAnswerValidator(formArrayIndex, formControlName);
  }
  getQuestionFormValidity(formArrayIndex: number) {
    this.questionAnswerValidator(formArrayIndex, 'answeringOption1');
    this.questionAnswerValidator(formArrayIndex, 'answeringOption2');
    return (<FormArray>this.questionsForm.get('questions')).controls[formArrayIndex].invalid
  }
  getMaxQuestionsByVisit(formArrayIndex: number) {
    return (<FormArray>this.configForm.controls.visit).controls[formArrayIndex].get('questions').value.length
  }
  noQuestion(formArrayIndex: number) {
    return !(<FormArray>this.configForm.controls.visit).controls[formArrayIndex].get('questions').value || (<FormArray>this.configForm.controls.visit).controls[formArrayIndex].get('questions').value.length === 0
  }

  private filterVisits(visits) {
    visits.forEach(visit => {
      if (!visit.modalities || visit.modalities.length < 1) {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = false;
      } else {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = true;
      }
    });
  }
  getModifiedVisit() {
    if (this.eligibilityVersion){
      return [this.studyVisits[0]];
    } else if (this.multiTabVersion) {
      if(this.configForm.controls.readingLevel.value === 'modality') {
        return [this.studyVisits[0]];
      } else if(this.configForm.controls.readingLevel.value === 'visit'){
        return [this.studyVisits.find(v => v.type === VisitType.UNSCHEDULED_REGULAR)];
      }
    }
    return this.studyVisits.filter(v => v.type !== VisitType.UNSCHEDULED_REGULAR || this.unscheduledAllowed)
  }
}
