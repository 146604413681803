import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ActionManagerService } from '../interfaces/viewer/action-manager-service';
import { SeriesManagerService } from '../interfaces/viewer/series-manager.service';
import { DicomHelperService } from '../interfaces/viewer/dicom-helper-service';
import { DicomTag } from '../interfaces/viewer/dicom-tag';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { VoiType } from '../../_models/viewer/DataModels';
import { Utils } from './lead-tools-utils';
import { MatDialog } from '@angular/material/dialog';
import { WindowLevelDialogComponent } from '../../modules/Viewer/viewer-core-lt/window-level-dialog/window-level-dialog.component';
import { CalibrateRulerDialogComponent } from '../../modules/Viewer/viewer-core-lt/calibrate-ruler-dialog/calibrate-ruler-dialog.component';
import { AnnotationSaveDialogComponent } from '../../modules/Viewer/viewer-core-lt/annotation-save-dialog/annotation-save-dialog.component';
import { AnnotationLoadDialogComponent } from '../../modules/Viewer/viewer-core-lt/annotation-load-dialog/annotation-load-dialog.component';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { ObjectRetrieveService } from '../interfaces/viewer/object-retrieve-service';
import { ObjectStoreService } from '../interfaces/viewer/object-store-service';
import { MedicalViewerSeries } from '../../_models/viewer/MedicalViewer';
import { QueryOptions } from 'src/app/_models/viewer/QueryOptions';
import { QueryArchiveService } from '../interfaces/viewer/query-archive.service';
import { OverlayManagerService } from '../interfaces/viewer/overlay-manager-service';
import { MatTableDataSource } from '@angular/material/table';
import { DicomData, DicomTagRow } from 'src/app/_models/viewer/DataModels';
import { CustomLayoutDialogComponent } from 'src/app/modules/Viewer/viewer-core-lt/custom-layout-dialog/custom-layout-dialog.component';
import { DicomInformationDialogComponent } from '../../modules/Viewer/viewer-core-lt/dicom-information-dialog/dicom-information-dialog.component';
import { DataUploadService } from './../../_services/data-upload.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StudySequenceLabelService } from '../../_services/study-sequence-label.service';
import { QualityControlService } from '../../_services/quality-control.service';
import { ViewerToolbar } from '../../_services/interfaces/viewer/toolbar';
import { GBMService } from './../../_services/gbm.service';
import { ReadingConfigFlexibleService } from '../reading-config-flexible.service';
import isFunction from 'lodash/isFunction';
import get from 'lodash/get';
import values from 'lodash/values';
import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';
import { Transformation } from '../../_models/viewer/hangingProtocol';
import { I } from '@angular/cdk/keycodes';
import { CurrentUserInfoState, CurrentUserInfoStateModel } from '../../core/data-management/states/user-info.state';
import { Select } from '@ngxs/store';
import {ImagingAnalysisService} from 'src/app/_services/imaging-analysis.service';
import { MarkerType } from 'src/app/_models/Oncology/global-lesion-model';
import {ReadingJSWService} from './../../_services/reading-jsw.service';
import {AnnJswObject} from './ann-jsw-object/ann-jsw-object';
import {AnnJswDrawDesigner} from './ann-jsw-object/ann-jsw-draw-designer';
import {AnnJswEditDesigner} from './ann-jsw-object/ann-jsw-edit-designer';
import {AnnJswRenderer} from './ann-jsw-object/ann-jsw-renderer';

import {AnnKmaObject} from './ann-kma-object/ann-kma-object';
import {AnnKmaDrawDesigner} from './ann-kma-object/ann-kma-draw-designer';
import {AnnKmaEditDesigner} from './ann-kma-object/ann-kma-edit-designer';
import {AnnKmaRenderer} from './ann-kma-object/ann-kma-renderer';


export interface DiameterRow {
  name: string;
  color: string;
  value: number;
  major: number;
  minor: number;
  spdp: number;
  volume: number;
}

const enum MedicalViewerAction {
  Offset = 0,
  Scale = 1,
  Magnify = 2,
  WindowLevel = 3,
  Stack = 4,
  AnnRectangle = 5,
  AnnEllipse = 6,
  AnnPointer = 7,
  AnnCurve = 8,
  AnnLine = 9,
  AnnText = 10,
  AnnHighlight = 11,
  AnnRuler = 12,
  AnnPolyRuler = 13,
  AnnProtractor = 14,
  AnnSelect = 15,
  AnnFreeHand = 16,
  SpyGlass = 17,
  ProbeTool = 18,
  AnnPoint = 19,
  AnnPolyline = 20,
  AnnPolygon = 21,
  AnnTextPointer = 22,
  AnnNote = 23,
  CrossHair = 24,
  DragItem = 25,
  LineProfile = 26,
  Cursor3D = 27,
  ShutterRect = 28,
  ShutterEllipse = 29,
  ShutterPolygon = 30,
  ShutterFreeHand = 31,
  Rotate3D = 32,
  PanoramicPolygon = 33,
  AnnCrossRuler = 34,
  AnnCobbAngle = 35,
  AnnJswObject = 36,
  AnnKmaObject = 37
}

@Injectable({
  providedIn: 'root'
})
export class LeadToolsActionManagerService implements ActionManagerService {
  @Select(CurrentUserInfoState.getCurrentUserInfo) currentUserInfo: Observable<CurrentUserInfoStateModel>;
  currentUser: CurrentUserInfoStateModel;
  mouseDown = false;
  lineProfileHistogram = null;
  currentLineProfileFrame: lt.Controls.Medical.Frame = null;
  lineProfileInteractiveMode = null;
  colorType = lt.Controls.Medical.ColorType.auto;
  cellNameCounter = 0;
  Spyglass = {
    Default: 0,
    Invert: 1,
    CLAHE: 2,
    Equalization: 3
  };
  SpyglassEffect: number = this.Spyglass.Default;
  stretchIntensityLow = 0;
  stretchIntensityHigh = 0;
  lineProfileSliderPosition = 0;
  currentPosition = 0;
  lineProfileSliderSize = 0;
  LastCommand = { Action: MedicalViewerAction.WindowLevel, ButtonID: '' };
  Last3DCommand = { Action: lt.Controls.Medical.Interactive3DAction.rotate3D, ButtonID: '' };
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  _engineDictionary = {};
  viewerGridState = 'automatic';
  shouldSplitViewer = true;
  autoSplitted = false;
  isVolumeRendering = false;
  public viewerComponent;

  private snapeShotSubj = new Subject<any>();
  public takeSnapShot = this.snapeShotSubj.asObservable();
  private newCellSubj = new Subject();
  public newCell = this.newCellSubj.asObservable();
  private MPRObjectSubj$ = new Subject<any>();

  private newCellSubjDiam = new Subject<any>();
  public newCellDiam = this.newCellSubjDiam.asObservable();

  qcVisitId = null;
  qcVisitStatus = 'not_set';
  qcVisitLock = false;
  qcVisitModalityPending = true;
  qcVisitComment = '';
  modalities: any;
  seriesCombined: any[];
  detailedDataModalityOther: any;
  detailedDataModality: any[];
  dataSourceDetailedDataModality: MatTableDataSource<any>;
  dataSourceDetailedDataModalityOther: MatTableDataSource<any>;
  showModalSpinner: boolean;
  showModalSpinnerTitleOther: boolean;
  tabControlValueBeforeSeriesChanged: any;
  tabControlValue: any;
  patient: any;
  blockButtonsUntilModalityUpdate = true;
  locStorage = JSON.parse(localStorage.getItem('project'));
  patientStudy: any;
  patientStudyID: any = this.locStorage['id'];
  selectedQCTask: any;
  visitConfigID: any = localStorage.getItem('visitConfigId');
  visitID: any;
  viewer: lt.Controls.Medical.MedicalViewer;
  cellOverlays: any = {};
  seriesOpened: any = {};
  viewerToolbar: ViewerToolbar = ViewerToolbar.getInstance();
  diameterStatus = false;
  rowData: DicomTagRow[] = [];
  dataSourceAr: Observable<any>[] = [];
  dataSourceDiam: MatTableDataSource<DiameterRow>;
  displayedColumnsDiam: string[] = ['name', 'color', 'value', 'major', 'minor', 'spdp', 'volume'];
  diameters: DiameterRow[] = [];
  loadedSeriesID = '';
  scaleX = 0.0;
  scaleY = 0.0;
  gbmSeries: number[] = [];
  conImage_w = 0.0;
  conImage_h = 0.0;
  gbmStatus: any;
  showOverlays = true;
  sliceSync = false;
  visualComparison = false;
  seriesConfigStat: any[] = [];

  isReferenceLine = false;
  referenceLineStatus = '';
  matchedPack: lt.Controls.Medical.Cell[] = [];
  refrenceCellSliceCount;
  axialImageHeight;
  axialImageWidth;
  sagitalImageHeight;
  sagitalImageWidth;
  coronalImageHeight;
  coronalImageWidth;
  isRapidAction = false;
  isAddingMarker = false;
  markerType = '';
  lesionName = '';
  clickLocationX = 0;
  clickLocationY = 0;

  isNewJoint = false;
  annoTracker = {
    mousedown: false,
    mouseup: false,
    objectAdded: [],
    objectRemoved: []
  };

  _seriesFound = [];
  _cellData = [];
  _gbmAction = '';

  activeJoint = {
    visitConfigId: 0,
    seriesId: 0,
    regionToScore: '',
    primaryLocation: '',
    color: '',
    timepoint: ''
  };

  demriqVisitConfigId: number;
  isGBMStatus = false;
  radioboticsActionID: any = 0;
  isAutoClosed = false;
  public sortData = {};
  JSWCounter = 0;

  private removeSeriesCellSubj = new Subject<any>();
  public removeSeriesCell = this.removeSeriesCellSubj.asObservable();

  private actions = {
    InteractiveMagnifyGlass: () => {
      this.runCommand(MedicalViewerAction.Magnify);
    },
    Pan: () => {
      this.runCommand(MedicalViewerAction.Offset);
    },
    ZoomToInteractiveMode: () => {
      this.runCommand(MedicalViewerAction.Scale);
    },
    WindowLevelInteractiveMode: () => {
      this.runCommand(MedicalViewerAction.WindowLevel);
    },
    InteractiveStack: () => {
      this.runCommand(MedicalViewerAction.Stack);
    },
    ApplyHangingProtocol: info => this.applyHangingProtocol(info),
    OnReload: this.onReload,
    RotateClockwise: this.onRotateClockwise,
    RotateCounterclockwise: this.onRotateCounterclockwise,
    Flip: this.onFlip,
    Reverse: this.onReverse,
    FitImage: this.onFitImage,
    OneToOne: this.onOneToOne,
    TrueSizeDisplay: this.onTrueSizeDisplay,
    ZoomIn: this.onZoomIn,
    ZoomOut: this.onZoomOut,
    ToggleFullScreen: info => this.onToggleFullScreen(info),
    OnCalibrateRuler: this.onCalibrateRuler,
    ShowDicom: this.onShowDicom,
    OnSecondaryCapturePopup: info => {
      this.popupCapture();
    },
    OnNoVolume: this.onNoVolume,
    ToggleReferenceLine: this.toggleReferenceLine,
    OnRunReg: this.OnRunRegistration,
    OnRunSeg: this.OnTumorDetection,
    OnFullDownload: () => {
      this.onFullDownload();
    },
    onAddToBrainLesion: this.onAddToBrainLesion,
    SetEnableSeriesSynchronization: this.setEnableSeriesSynchronization,
    OnSaveAnnotations: this.onSaveAnnotations,
    OnSlicePositionSynch: info => {
      this.OnSlicePositionSynch(info);
    },
    OnPanZoomSynch: info => {
      this.OnPanZoomSynch(info);
    },
    OnWindowLevelSynch: info => {
      this.OnWindowLevelSynch(info);
    },
    OnIntraVisitSynch: info => {
      this.OnIntraVisitSynch(info);
    },
    OnDisableAllSynch: info => {
      this.OnDisableAllSynch(info);
    },
    OnAutoSynchronization: info => {
      this.OnAutoSynchronization(info);
    },
    OnManualSynchronization: info => {
      this.OnManualSynchronization(info);
    },
    OnLoadAnnotations: info => {
      this.onLoadAnnotations(info);
    },
    WindowLevelCustom: info => {
      this.onWindowLevelCustom(info);
    },
    OnAnnotationRuler: info => {
      this.runCommand(MedicalViewerAction.AnnRuler, 'Ruler', info);
    },
    OnAnnotationPolyRuler: info => {
      this.runCommand(MedicalViewerAction.AnnPolyRuler, 'PolyRuler', info);
    },
    OnAnnotationProtractor: info => {
      this.runCommand(MedicalViewerAction.AnnProtractor, 'Angle', info);
    },
    OnAnnotationCrossRuler: info => {
      this.runCommand(MedicalViewerAction.AnnCrossRuler, 'CrossRuler', info);
    },
    OnAnnotationCobbAngle: info => {
      info.annType = 'CobbAngle';
      this.onAnnotationCobbAngle(info);
    },
    ImageAlignLeft: () => {
      this.horImageAlign('ImageAlignLeft');
    },
    ImageAlignRight: () => {
      this.horImageAlign('ImageAlignRight');
    },
    ImageAlignCenter: () => {
      this.horImageAlign('ImageAlignCenter');
    },
    ImageAlignTop: () => {
      this.vertImageAlign('ImageAlignTop');
    },
    ImageAlignBottom: () => {
      this.vertImageAlign('ImageAlignBottom');
    },
    ImageAlignMiddle: () => {
      this.vertImageAlign('ImageAlignMiddle');
    },
    OnVRT: info => {
      this.onVolumeRendering(lt.Controls.Medical.VolumeType.VRT, info, true);
    },
    OnMIP: info => {
      this.onVolumeRendering(lt.Controls.Medical.VolumeType.MIP, info, true);
    },
    OnMPRVolumeWithout3D: info => {
      this.onVolumeRendering(lt.Controls.Medical.VolumeType.MPR, info, false);
    },
    OnMPRVolumeWith3D: info => {
      this.onVolumeRendering(lt.Controls.Medical.VolumeType.MPR, info, true);
    },
    OnAxialOrientation: info => {
      this.changeMPROrientation(lt.Controls.Medical.CellMPRType.axial, info);
    },
    OnCoronalOrientation: info => {
      this.changeMPROrientation(lt.Controls.Medical.CellMPRType.coronal, info);
    },
    OnSagitalOrientation: info => {
      this.changeMPROrientation(lt.Controls.Medical.CellMPRType.sagittal, info);
    },
    OnAnnotationRectangle: info => {
      info.annType = 'Rectangle';
      this.setAnnTool(MedicalViewerAction.AnnRectangle, info);
    },
    OnAnnotationEllipse: info => {
      info.annType = 'Ellipse';
      this.setAnnTool(MedicalViewerAction.AnnEllipse, info);
    },
    OnAnnotationCurve: info => {
      info.annType = 'Curve';
      this.setAnnTool(MedicalViewerAction.AnnCurve, info);
    },
    OnAnnotationLine: info => {
      info.annType = 'Line';
      this.setAnnTool(MedicalViewerAction.AnnLine, info);
    },
    OnAnnotationFreehand: info => {
      info.annType = 'Freehand';
      this.setAnnTool(MedicalViewerAction.AnnFreeHand, info);
    },
    OnAnnotationAutoFreehand: info => {
      info.annType = 'Freehand';
      this.isAutoClosed = true;
      this.setAnnTool(MedicalViewerAction.AnnFreeHand, info);
    },
    OnAnnotationPolygon: info => {
      info.annType = 'Polygon';
      this.setAnnTool(MedicalViewerAction.AnnPolygon, info);
    },
    OnCrossHairLine: info => {
      this.onCrossHairLine(info);
    },
    OnCursor3D: info => {
      this.runCommand(MedicalViewerAction.Cursor3D, '', info);
    },
    OnAnnotationText: () => {
      this.setAnnTool(MedicalViewerAction.AnnText);
    },
    OnAnnotationNote: () => {
      this.setAnnTool(MedicalViewerAction.AnnNote);
    },
    OnAnnotationHighlight: () => {
      this.setAnnTool(MedicalViewerAction.AnnHighlight);
    },
    OnProbeTool: () => {
      // this.setAnnTool(MedicalViewerAction.ProbeTool);
    },
    OnDeleteAnnotation: () => {
      this.seriesManagerService.deleteSelectedAnnotations();
    },
    OnClearAnnotation: () => {
      this.seriesManagerService.clearAnnotations();
    },
    OnClearAllAnnotation: () => {
      this.seriesManagerService.clearAllAnnotations();
    },
    OnUndoAction: () => {
      this.seriesManagerService.undoAction();
    },
    ApplyParametricMap: info => {
      this.overlayManagerService.applyParametricMap(info);
    },
    ApplyMotionCorrection: info => {
      this.overlayManagerService.applyMotionCorrection(info);
    },
    OnCustomViewerLayout: info => {
      this.onCustomViewerLayout(info);
    },
    OnGBMStation: info => {
      this.OnGBMStation(info);
    },
    On2D3DDiam: info => {
      this._gbmAction = 'On2D3DDiam';
      this.On2D3DMeasurments(info);
    },
    OnTumorCorrection: info => {
      this._gbmAction = 'OnTumorCorrection';
      this.OnTumorCorrection(info);
    },
    OnViewerLayout3x3: (info) => {
      this.onViewerLayout(3, 3, info);
    },
    OnViewerLayout2x4: (info) => {
      this.onViewerLayout(2, 4, info);
    },
    OnViewerLayout2x3: (info) => {
      this.onViewerLayout(2, 3, info);
    },
    OnViewerLayout2x2: (info) => {
      this.onViewerLayout(2, 2, info);
    },
    OnViewerLayout2x1: (info) => {
      this.onViewerLayout(2, 1, info);
    },
    OnViewerLayout1x1: (info) => {
      this.onViewerLayout(1, 1, info);
    },
    OnViewerLayout1x2: (info) => {
      this.onViewerLayout(1, 2, info);
    },
    OnViewerLayout1x3: (info) => {
      this.onViewerLayout(1, 3, info);
    },
    OnViewerLayout1x4: (info) => {
      this.onViewerLayout(1, 4, info);
    },
    OnViewerLayoutAuto: (info) => {
      this.OnViewerLayoutAuto(info);
    },
    OnLoadObjects: info => {
      this.onLoadObjects(info);
    },
    OnAnnotationSelect: info => {
      info.annType = 'Select';
      this.runCommand(MedicalViewerAction.AnnSelect, '', info);
    },
    OnShowHideOverlays: () => {
      this._gbmAction = 'OnShowHideOverlays';
      this.OnShowHideOverlays();
    },
    OnSliceSync: () => {
      this._gbmAction = 'OnSliceSync';
      this.OnSliceSync();
    },
    OnVisualComparison: () => {
      this._gbmAction = 'OnVisualComparison';
      this.OnVisualComparison('OnVisualComparison');
    },
    OnRapidAction: info => {
      this._gbmAction = 'OnRapidAction';
      this.OnRapidAction(info);
    },
    OnSortByAcquisitionTime: (info) => {
      this.sortSeries('AcquisitionTime', info);
    },
    OnSortByAxis: (info) => {
      this.sortSeries('Axis', info);
    },
    OnSortByInstanceNumber: (info) => {
      this.sortSeries('InstanceNumber', info);
    },
    OnLoadStatistics: (info) => {
      this.OnLoadStatistics(info);
    },
    OnLoadIntChart: (info) => {
      this.OnLoadIntChart(info);
    },
    OnConfirmMarker: (info) => {
      this.OnConfirmMarker(info);
    },
    OnRemoveMarker: (info) => {
      this.OnRemoveMarker(info);
    },
    OnAddCrossRuler: (info) => {
      this.OnAddMarker(info, MarkerType.BI_RULER);
    },
    OnAddRuler: (info) => {
      this.OnAddMarker(info, MarkerType.RULER);
    },
    OnAddMarker: (info) => {
      this.OnAddMarker(info, MarkerType.MARKER);
    },
    OnShowAIContours: (info) => {
      this.showJSWContours(info);
    },
    OnResetAIResult: (info) => {
      this.showJSWContours(info, true);
    },
    OnConfirmContours: (info) => {
      this.confirmContours(info);
    },
    OnDrawFirstContour: (info) => {
      this.DrawFirstContour(info, false);
    },
    OnDrawSecondContour: (info) => {
      this.DrawSecondContour(info, false);
    },
    OnDrawFirstContourLeft: (info) => {
      this.DrawFirstContour(info, true);
    },
    OnDrawSecondContourLeft: (info) => {
      this.DrawSecondContour(info, true);
    },
    OnCustomUpload: (info) => {
      this.CustomUpload(info);
    },
    OnDrawKneeContours: (info) => {
      this.DrawContours(info, true);
    },
    OnDrawHipContours: (info) => {
      this.DrawContours(info, false);
    },
    OnFillLeftHip: (info) => {
      this.confirmContours2(info, true, 'Left_Hip_ShortestDistance');
      this.saveROI();
    },
    OnFillRightHip: (info) => {
      this.confirmContours2(info, true, 'Right_Hip_ShortestDistance');
      this.saveROI();
    },
    OnFillRightKneeLateral: (info) => {
      this.confirmContours2(info, false, 'lateral_rightKneeShortestDistance');
      this.saveROI();
    },
    OnFillRightKneeMedial: (info) => {
      this.confirmContours2(info, false, 'medial_rightKneeShortestDistance');
      this.saveROI();
    },
    OnFillLeftKneeMedial: (info) => {
      this.confirmContours2(info, false, 'medial_leftKneeShortestDistance');
      this.saveROI();
    },
    OnFillLeftKneeLateral: (info) => {
      this.confirmContours2(info, false, 'lateral_leftKneeShortestDistance');
      this.saveROI();
    },
    OnToggleJswDistanceCalculation: (info) => {
      this.toggleJswDistanceCalculation(info);
    },
    OnShowAIContours2: (info) => {
      this.showJSWContours2(info);
    },
    OnResetAIResult2: (info) => {
      this.showJSWContours2(info, true);
    },
    OnSaveROI: (info) => {
      this.saveROI();
    },
    OnDrawJsw: (info) => {
      info.annType = 'AnnJswObject';
      this.setAnnTool(MedicalViewerAction.AnnJswObject, info);
    },
    OnDrawKmaContours: (info) => {
      this.DrawKma(info);
    },
    OnDrawKma: (info) => {
      info.annType = 'AnnKmaObject';
      this.setAnnTool(MedicalViewerAction.AnnKmaObject, info);
    },
  };

  constructor(private utils: Utils,
              private iconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer,
              private seriesManagerService: SeriesManagerService,
              private dicomHelperService: DicomHelperService,
              private objectRetrieveService: ObjectRetrieveService,
              private objectStoreService: ObjectStoreService,
              private queryArchiveService: QueryArchiveService,
              private overlayManagerService: OverlayManagerService,
              public dialog: MatDialog,
              private toastyService: ToastyService,
              private dataUploadService: DataUploadService,
              private studySequenceLabelservice: StudySequenceLabelService,
              private qualityControlService: QualityControlService,
              private http: HttpClient,
              private gbmService: GBMService,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private imagingAnalysisService: ImagingAnalysisService,
              private readingJSWService: ReadingJSWService,
  ) {

    this.currentUserInfo.subscribe((user: CurrentUserInfoStateModel) => {
      this.currentUser = user;
    });

  }

  OnLoadStatistics(info) {
    // console.log('OnLoadStatistics...');
    const viewerComponent = info.viewerComponent;
    const isStatisticsPanel = viewerComponent.viewerSettings.toolbar.isStatisticsPanel;
    viewerComponent.viewerSettings.toolbar.isStatisticsPanel = !isStatisticsPanel;

    if (viewerComponent.viewerSettings.toolbar.isStatisticsPanel) {
      this.viewerToolbar.setItemProperty('LoadStatistics', 'tooltip', 'Disable Statistics');
    } else {
      this.viewerToolbar.setItemProperty('LoadStatistics', 'tooltip', 'Enable Statistics');
    }
  }

  OnLoadIntChart(info) {
    // console.log('OnLoadIntChart...');
    const viewerComponent = info.viewerComponent;
    const isIntChartPanel = viewerComponent.viewerSettings.toolbar.isIntChartPanel;
    viewerComponent.viewerSettings.toolbar.isIntChartPanel = !isIntChartPanel;

    if (viewerComponent.viewerSettings.toolbar.isIntChartPanel) {
      this.viewerToolbar.setItemProperty('LoadIntChart', 'tooltip', 'Disable Intensity Chart');
    } else {
      this.viewerToolbar.setItemProperty('LoadIntChart', 'tooltip', 'Enable Intensity Chart');
    }
    // update let-panel areas
    this.updateLeftPanel(viewerComponent);
  }

  getSortingOperation(sortType, dicomTag) {
    const sortingOp = new lt.Controls.Medical.SortingOperation();
    sortingOp.sortByCategory = sortType;
    sortingOp.selectorAttribute = parseInt(dicomTag, 16);
    return sortingOp;
  }

  sortSeries(sort_type = 'Axis', _info) {
    let info;
    const cellFrame = this.seriesManagerService.getActiveCellFrame();
    const cell = this.seriesManagerService.getActiveCell();
    const viewerComponent = _info.viewerComponent;

    if (this.sortData[cell.divID][sort_type].enabled) {
      const order = this.sortData[cell.divID][sort_type].order;
      this.sortData[cell.divID][sort_type].order = order === 'ASC' ? 'DESC' : 'ASC';
      cell.SortOrderAcsending = !cell.SortOrderAcsending;
    } else {
      cell.CurrentSelectedSortOrder = sort_type;
    }

    const order_mark = cell.SortOrderAcsending ? '\u21E7' : '\u21E9';

    switch (sort_type) {
      case 'AcquisitionTime':
        info = this.getSortingOperation(lt.Controls.Medical.SortType.byAcquisitionTime, '');
        this.sortData[cell.divID]['AcquisitionTime'].enabled = true;
        this.sortData[cell.divID]['Axis'].enabled = false;
        this.sortData[cell.divID]['InstanceNumber'].enabled = false;
        // update toolbar item
        this.viewerToolbar.setItemProperty('SortByAcquisitionTime', 'tooltip', '\u2713 Acquisition Time ' + order_mark);
        this.viewerToolbar.setItemProperty('SortByAxis', 'tooltip', 'Axis');
        this.viewerToolbar.setItemProperty('SortByInstanceNumber', 'tooltip', 'Instance Number');
        this.viewerToolbar.setItemProperty('SortSeries', 'tooltip', '\u2713 Acquisition Time ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'caption', 'By Acq. Time ' + order_mark);
        break;

      case 'Axis':
        info = this.getSortingOperation(lt.Controls.Medical.SortType.byAxis, '');
        this.sortData[cell.divID]['AcquisitionTime'].enabled = false;
        this.sortData[cell.divID]['Axis'].enabled = true;
        this.sortData[cell.divID]['InstanceNumber'].enabled = false;
        // update toolbar item
        this.viewerToolbar.setItemProperty('SortByAcquisitionTime', 'tooltip', 'Acquisition Time');
        this.viewerToolbar.setItemProperty('SortByAxis', 'tooltip', '\u2713 Axis ' + order_mark);
        this.viewerToolbar.setItemProperty('SortByInstanceNumber', 'tooltip', 'Instance Number');
        this.viewerToolbar.setItemProperty('SortSeries', 'tooltip', '\u2713 Axis ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'caption', 'By Axis ' + order_mark);
        break;

      case 'InstanceNumber':
        info = this.getSortingOperation(lt.Controls.Medical.SortType.none, DicomTag.InstanceNumber);
        this.sortData[cell.divID]['AcquisitionTime'].enabled = false;
        this.sortData[cell.divID]['Axis'].enabled = false;
        this.sortData[cell.divID]['InstanceNumber'].enabled = true;
        // update toolbar item
        this.viewerToolbar.setItemProperty('SortByAcquisitionTime', 'tooltip', 'Acquisition Time');
        this.viewerToolbar.setItemProperty('SortByAxis', 'tooltip', 'Axis');
        this.viewerToolbar.setItemProperty('SortByInstanceNumber', 'tooltip', '\u2713 Instance Number ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'tooltip', '\u2713 Instance Number ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'caption', 'By Inst. Number ' + order_mark);
        break;

      default:
        info = this.getSortingOperation(lt.Controls.Medical.SortType.byAxis, '');
        this.sortData[cell.divID]['AcquisitionTime'].enabled = false;
        this.sortData[cell.divID]['Axis'].enabled = true;
        this.sortData[cell.divID]['InstanceNumber'].enabled = false;
        // update toolbar item
        this.viewerToolbar.setItemProperty('SortByAcquisitionTime', 'tooltip', 'Acquisition Time');
        this.viewerToolbar.setItemProperty('SortByAxis', 'tooltip', '\u2713 Axis ' + order_mark);
        this.viewerToolbar.setItemProperty('SortByInstanceNumber', 'tooltip', 'Instance Number');
        this.viewerToolbar.setItemProperty('SortSeries', 'tooltip', '\u2713 Axis ' + order_mark);
        this.viewerToolbar.setItemProperty('SortSeries', 'caption', 'By Axis ' + order_mark);
        break;
    }

    info.order = !cell.SortOrderAcsending ?
      lt.Controls.Medical.SortOrder.descending : lt.Controls.Medical.SortOrder.ascending;

    cell.fullDownload = true;
    cell._sort_timer_event = window.setInterval(() => {
      this.seriesManagerService.enumerateFrames(cell, function (frame, index) {
        if (frame.JSON == null) {
          return;
        }
      });
      const sortingOp = [info];
      try {
        cell.sortingOperationsSequence = sortingOp;
        window.clearInterval(cell._sort_timer_event);
      } catch (error) {
        // console.log('Warning:', error);
      }
    }, 500);

    // update synchronisation mapping indexes
    if (viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
      this.OnDisableAllSynch(_info);
      this.OnManualSynchronization(_info);
    } else if (viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled) {
      this.OnDisableAllSynch(_info);
      this.OnAutoSynchronization(_info);
    }
  }

  private _transformAction_FlipHorizontal(cell: lt.Controls.Medical.Cell, useTransaction = true) {
    if (this.utils.layoutHasItem(cell)) {
      this.seriesManagerService.setActiveCell(cell.divID, false);
    }
    this.onFlip(null, cell, useTransaction);
    return new Promise(resolve => resolve(true));
  }

  private _transformAction_FlipVertical(cell: lt.Controls.Medical.Cell, useTransaction = true) {
    if (this.utils.layoutHasItem(cell)) {
      this.seriesManagerService.setActiveCell(cell.divID, false);
    }
    this.onReverse(null, cell, useTransaction);
    return new Promise(resolve => resolve(true));
  }

  private _transformAction_Rotate90CW(cell: lt.Controls.Medical.Cell, useTransaction = true) {
    return new Promise(resolve => {
      if (this.utils.layoutHasItem(cell)) {
        this.seriesManagerService.setActiveCell(cell.divID, false);
      }
      setTimeout(() => {
        this.onRotateClockwise(null, cell, useTransaction);
        resolve(true);
      }, 0);
    });
  }

  private _transformAction_Rotate90CCW(cell: lt.Controls.Medical.Cell, useTransaction = false) {
    if (this.utils.layoutHasItem(cell)) {
      this.seriesManagerService.setActiveCell(cell.divID, false);
    }
    this.onRotateCounterclockwise(null, cell, useTransaction);
    return new Promise(resolve => setTimeout(() => resolve(true), 0));
  }

  private _transformAction_MPRAxial(cell: lt.Controls.Medical.Cell, info, useTransaction = true) {
    this.changeMPROrientation(lt.Controls.Medical.CellMPRType.axial, info, cell);
    return new Promise<any>(subscriber => {
      const subscription = this.MPRObjectSubj$.subscribe(id => {
        if (cell['cell3D'].object3D.id === id) {
          subscription.unsubscribe();
          subscriber(id);
        }
      }, err => err);
    });
  }

  private _transformAction_MPRSagittal(cell: lt.Controls.Medical.Cell, info, useTransaction = false) {
    this.changeMPROrientation(lt.Controls.Medical.CellMPRType.sagittal, info, cell);
    return new Promise<any>(subscriber => {
      const subscription = this.MPRObjectSubj$.subscribe(id => {
        if (cell['cell3D'].object3D.id === id) {
          subscription.unsubscribe();
          subscriber(id);
        }
      }, err => err);
    });
  }

  /**
   * MPR Orientation(Coronal) magick method
   * @param cell
   * @param info
   * @param useTransaction
   * @private
   */
  private _transformAction_MPRCoronal(cell: lt.Controls.Medical.Cell, info, useTransaction = false) {
    console.log('MPRCoronal');
    this.changeMPROrientation(lt.Controls.Medical.CellMPRType.coronal, info, cell);
    return new Promise<any>(subscriber => {
      const subscription = this.MPRObjectSubj$.subscribe(id => {
        if (cell['cell3D'].object3D.id === id) {
          subscription.unsubscribe();
          subscriber(id);
        }
      }, err => err);
    });
  }

  /**
   * Apply the list of transformations (Hanging Protocol)
   * @param cell Viewer Cell Instance
   * @param transformations List of Transformation
   * @param info Additional data
   * @private
   */
  private async _applyCellTransformations(cell: lt.Controls.Medical.Cell, transformations: Array<Transformation> = [], info: any) {
    if (cell && transformations.length) {
      for (const transformation of transformations) {
        if (typeof this[`_transformAction_${transformation.type}`] === 'function') {
          const result = await this[`_transformAction_${transformation.type}`].call(this, cell, info);
          info.viewerComponent.asyncResizeTrigger();
        }
      }
    }
    return new Promise(resolve => resolve(true));
  }

  applyHangingProtocol({ viewerComponent, protocol }) {
    // viewerComponent.applyHangingProtocol(protocol);
    const imageGrid = protocol.imageGrid;
    const { visitDisplayConfiguration, visitOrder } = protocol.visitConfiguration;
    const { seriesLabels, seriesOrder } = protocol.seriesLabelList;
    const visits = groupBy(filter(values(viewerComponent.viewerData), d => d ? d.visitConfigId : null), 'visitConfigId');
    const visitsOrder = get(viewerComponent, 'viewerData.visitOrder', []);
    const series = [];
    const transformations = {};
    const sortedVisits = [];

    visitsOrder.forEach(v => {
      sortedVisits.push(visits[v]);
    });

    // remove all cells
    viewerComponent.viewer.layout.items.toArray().forEach(i => {
      const isMprCell = i instanceof lt.Controls.Medical.MPRCell;
      viewerComponent.removeCell(i, isMprCell);
    });

    // apply layout
    this.onViewerLayout(imageGrid.rows, imageGrid.columns, { viewerComponent });

    // this.toggleReferenceLine(viewerConfig.referenceLinesOn);

    // visits direction "ColumnWise" | "RowWise"
    if (visitDisplayConfiguration === 'RowWise') {
      sortedVisits.forEach((v: any) => {
        seriesLabels.forEach(sl => {
          const seriesItem = v.find(s => s && s.label === sl.name);
          series.push(seriesItem ? seriesItem.id : '-1');
          if (seriesItem) {
            transformations[seriesItem.id] = sl.transformations;
          }
        });
      });
    } else if (visitDisplayConfiguration === 'ColumnWise') {
      seriesLabels.forEach(sl => {
        Object.values(visits).forEach((v: any) => {
          const seriesItem = v.find(s => s && s.label === sl.name);
          series.push(seriesItem ? seriesItem.id : '-1');
          if (seriesItem) {
            transformations[seriesItem.id] = sl.transformations;
          }
        });
      });
    }

    if (series.length) {
      const tryIntervals = {};
      const stopTransformations = id => {
        clearInterval(tryIntervals[id]);
      };

      const startTransformations = (cellInstance, transforms) => {
        return new Promise((resolve, reject) => {
          if (cellInstance) {
            stopTransformations(cellInstance.seriesId);
            tryIntervals[cellInstance.seriesId] = setInterval(() => {
              const progress = cellInstance.get_progress().get_progressPercent();
              const visible = cellInstance.get_progress().get_visible();
              if (progress === 100) {
                stopTransformations(cellInstance.seriesId);
                this._applyCellTransformations(cellInstance, transforms, { viewerComponent }).then(resolve).catch(reject);
              }
            }, 500);
          } else {
            resolve(false);
          }
        });
      };

      viewerComponent.openNewSer(series, null, false, true, true).subscribe(result => {
        const transforms = [];
        forkJoin(result).subscribe(items => {
          // disable autosync
          this.OnDisableAllSynch({ viewerComponent });

          forkJoin(items).subscribe(async (results: Array<any>) => {
            for (const r of results) {
              await startTransformations(r['cell'], transformations[r['seriesId']]);
            }

            if (protocol.imageViewerConfigOptions.defaultSliceDisplay === 'middle') {
              results.map(r => {
                const { numbOfSlices } = this.seriesManagerService.getSeriesInfo(r.cell);
                if (numbOfSlices) {
                  r.cell.set_currentOffset(Math.round((numbOfSlices + 1) / 2));
                }
              });
            }

            if (protocol.imageViewerConfigOptions.defaultFrameDisplay === 'middle') {
              results.map(r => {
                const seriesInfo = this.seriesManagerService.getSeriesInfo(r.cell);
                if (seriesInfo.numbOfFrames) {
                  this.overlayManagerService.manageFrameChange(
                    seriesInfo,
                    r.cell,
                    this.overlayManagerService.cellOverlays[r.cell.divID].frameTrackerId,
                    1,
                    Math.round((seriesInfo.numbOfFrames + 1) / 2));
                }
              });
            }
          });

        });
      });
    }
  }

  initializeAnnAutomationManager(cell) {
    const annAutoManager: lt.Annotations.Automation.AnnAutomationManager = cell.get_automationManager();
    const rulerObj = annAutoManager.findObjectById(lt.Annotations.Engine.AnnObject.rulerObjectId);
    const rulerTemplate: any = rulerObj.objectTemplate;
    rulerTemplate.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
    rulerTemplate.stroke.stroke.color = 'orange';
    rulerTemplate.stroke.set_strokeThickness(lt.LeadLengthD.create(2));
    const rulerLabels: any = rulerTemplate.labels;
    rulerLabels.RulerLength.set_background(rulerLabels.RulerLength.foreground.clone());
    rulerLabels.RulerLength.background.color = 'rgba(0, 0, 0, 0.25)';
    rulerLabels.RulerLength.font.fontWeight = 5;
    rulerLabels.RulerLength.foreground.color = 'orange';
    // rulerLabels.RulerLength.offset = lt.LeadPointD.create(0, 0);
    rulerLabels.RulerLength.offsetHeight = false;

    const protractorObj = annAutoManager.findObjectById(lt.Annotations.Engine.AnnObject.protractorObjectId);
    const protractorTemplate: any = protractorObj.objectTemplate;
    // console.log('protractorTemplate:', protractorTemplate)
    protractorTemplate.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
    protractorTemplate.stroke.stroke.color = 'orange';
    protractorTemplate.stroke.set_strokeThickness(lt.LeadLengthD.create(2));
    const protractorLabels: any = protractorTemplate.labels;
    protractorLabels.AngleText.set_background(protractorLabels.AngleText.foreground.clone());
    protractorLabels.AngleText.background.color = 'rgba(0, 0, 0, 0.25)';
    protractorLabels.AngleText.foreground.color = 'orange';
    protractorLabels.AngleText.font.fontWeight = 5;
    // protractorLabels.AngleText.offset = lt.LeadPointD.create(70, 70);
    protractorLabels.AngleText.offsetHeight = false;
    protractorLabels.FirstRulerLength.set_background(protractorLabels.FirstRulerLength.foreground.clone());
    protractorLabels.FirstRulerLength.background.color = 'rgba(0, 0, 0, 0.25)';
    protractorLabels.FirstRulerLength.foreground.color = 'orange';
    protractorLabels.FirstRulerLength.font.fontWeight = 5;
    // protractorLabels.FirstRulerLength.offset = lt.LeadPointD.create(20, 20);
    protractorLabels.FirstRulerLength.offsetHeight = false;
    protractorLabels.SecondRulerLength.set_background(protractorLabels.SecondRulerLength.foreground.clone());
    protractorLabels.SecondRulerLength.background.color = 'rgba(0, 0, 0, 0.25)';
    protractorLabels.SecondRulerLength.foreground.color = 'orange';
    protractorLabels.SecondRulerLength.font.fontWeight = 5;
    // protractorLabels.SecondRulerLength.offset = lt.LeadPointD.create(20, 20);
    protractorLabels.SecondRulerLength.offsetHeight = false;

    const polyRulerObj: lt.Annotations.Automation.AnnAutomationObject = annAutoManager.findObjectById(lt.Annotations.Engine.AnnObject.polyRulerObjectId);
    const polyRulerTemplate: any = polyRulerObj.objectTemplate;
    // console.log('polyRulerTemplate:', polyRulerTemplate)
    polyRulerTemplate.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
    polyRulerTemplate.stroke.stroke.color = 'orange';
    polyRulerTemplate.stroke.set_strokeThickness(lt.LeadLengthD.create(2));
    const polyRulerLabels: any = polyRulerTemplate.labels;
    polyRulerLabels.RulerLength.set_background(polyRulerLabels.RulerLength.foreground.clone());
    polyRulerLabels.RulerLength.background.color = 'rgba(0, 0, 0, 0.25)';
    polyRulerLabels.RulerLength.foreground.color = 'orange';
    polyRulerLabels.RulerLength.font.fontWeight = 5;
    // polyRulerLabels.RulerLength.offset = lt.LeadPointD.create(20, 20);
    polyRulerLabels.RulerLength.offsetHeight = false;

    const crossRulerObj: lt.Annotations.Automation.AnnAutomationObject = annAutoManager.findObjectById(lt.Annotations.Engine.AnnObject.crossProductObjectId);
    const crossRulerTemplate: any = crossRulerObj.objectTemplate;
    crossRulerTemplate.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
    crossRulerTemplate.stroke.stroke.color = 'orange';
    crossRulerTemplate.stroke.set_strokeThickness(lt.LeadLengthD.create(2));
    const crossRulerLabels: any = crossRulerTemplate.labels;
    crossRulerLabels.RulerLength.set_background(crossRulerLabels.RulerLength.foreground.clone());
    crossRulerLabels.RulerLength.background.color = 'rgba(0, 0, 0, 0.25)';
    crossRulerLabels.RulerLength.foreground.color = 'orange';
    crossRulerLabels.RulerLength.font.fontWeight = 5;
    // crossRulerLabels.RulerLength.offset = lt.LeadPointD.create(20, 20);
    crossRulerLabels.RulerLength.offsetHeight = false;
    crossRulerLabels.SecondaryRulerLength.set_background(crossRulerLabels.SecondaryRulerLength.foreground.clone());
    crossRulerLabels.SecondaryRulerLength.background.color = 'rgba(0, 0, 0, 0.25)';
    crossRulerLabels.SecondaryRulerLength.foreground.color = 'orange';
    crossRulerLabels.SecondaryRulerLength.font.fontWeight = 5;
    // crossRulerLabels.SecondaryRulerLength.offset = lt.LeadPointD.create(20, 20);

    const annLineObj: lt.Annotations.Automation.AnnAutomationObject = annAutoManager.findObjectById(lt.Annotations.Engine.AnnObject.lineObjectId);
    const annLineTemplate: ltAnnotationsEngine.AnnObject = annLineObj.objectTemplate;
    (<any>annLineTemplate).stroke.stroke.color = 'orange';
    annLineTemplate.stroke.set_strokeThickness(lt.LeadLengthD.create(2));

    const jswAutomation = this.createJswAutomationObject(cell.automationManager);
    cell.automationManager.objects.add(jswAutomation);

    const kmaAutomation = this.createKmaAutomationObject(cell.automationManager);
    cell.automationManager.objects.add(kmaAutomation);

    try {
      this.seriesManagerService.setThumbPreferences(cell.get_automationManager().get_renderingEngine().get_renderers(), 0);
    } catch (error) {

    }
  }

  // tslint:disable-next-line:max-line-length
  createJswAutomationObject(annAutoManager: lt.Annotations.Automation.AnnAutomationManager): lt.Annotations.Automation.AnnAutomationObject {
    const renderingEngine = annAutoManager.renderingEngine;
    const annJswObject = new AnnJswObject();
    // Create the custom automation object and hook the designers
    const automationObj = new lt.Annotations.Automation.AnnAutomationObject();
    automationObj.id = AnnJswObject.jswObjectId;
    automationObj.name = 'AnnJswObject';
    automationObj.drawDesignerType = AnnJswDrawDesigner; // hook the custom draw designer
    automationObj.editDesignerType = AnnJswEditDesigner; // hook the custom edit designer
    automationObj.runDesignerType = lt.Annotations.Designers.AnnRunDesigner;

    const annJswRenderer = new AnnJswRenderer(annAutoManager);
    const annPolylineRenderer = renderingEngine.renderers[lt.Annotations.Engine.AnnObject.polylineObjectId];
    annJswRenderer.locationsThumbStyle = annPolylineRenderer.locationsThumbStyle;

    renderingEngine.renderers[AnnJswObject.jswObjectId] = annJswRenderer; // hook the custom renderer
    automationObj.objectTemplate = annJswObject;
    annJswRenderer.initialize(renderingEngine);

    // registerclass to annotation.Core for allowing it to get class Full Name
    (<any>AnnJswObject).registerClass('AnnJswObject', lt.Annotations.Engine.AnnPolylineObject);

    return automationObj;
  }

  // tslint:disable-next-line:max-line-length
  createKmaAutomationObject(annAutoManager: lt.Annotations.Automation.AnnAutomationManager): lt.Annotations.Automation.AnnAutomationObject {
    const renderingEngine = annAutoManager.renderingEngine;
    const annKmaObject = new AnnKmaObject();
    // Create the custom automation object and hook the designers
    const automationObj = new lt.Annotations.Automation.AnnAutomationObject();
    automationObj.id = AnnKmaObject.kmaObjectId;
    automationObj.name = 'AnnKmaObject';
    automationObj.drawDesignerType = AnnKmaDrawDesigner; // hook the custom draw designer
    automationObj.editDesignerType = AnnKmaEditDesigner; // hook the custom edit designer
    automationObj.runDesignerType = lt.Annotations.Designers.AnnRunDesigner;

    const annKmaRenderer = new AnnKmaRenderer(annAutoManager);
    const annPolylineRenderer = renderingEngine.renderers[lt.Annotations.Engine.AnnObject.polylineObjectId];
    annKmaRenderer.locationsThumbStyle = annPolylineRenderer.locationsThumbStyle;

    renderingEngine.renderers[AnnKmaObject.kmaObjectId] = annKmaRenderer; // hook the custom renderer
    automationObj.objectTemplate = annKmaObject;
    annKmaRenderer.initialize(renderingEngine);

    // registerclass to annotation.Core for allowing it to get class Full Name
    (<any>AnnKmaObject).registerClass('AnnKmaObject', lt.Annotations.Engine.AnnPolylineObject);

    return automationObj;
  }

  intializeActions(cell: lt.Controls.Medical.Cell): void {
    const spyGlass = new lt.Controls.Medical.SpyGlassAction();
    const scaleAction = new lt.Controls.Medical.ScaleAction();
    const probeTool = new lt.Controls.Medical.ProbeToolAction();
    const lineProfile = new lt.Controls.Medical.LineProfileAction();
    cell.lineProfile.add_histogramGenerated(this.histogramGenerated);
    spyGlass.add_imageRequested(this.spyGlassRequested);
    spyGlass.add_chunkLoaded(this.chunkLoaded);
    spyGlass.add_workCompleted(this.spyGlassDone);
    spyGlass.add_positionChanged(this.spyGlassPositionChanged);
    probeTool.add_probeToolUpdated(this.probeToolUpdated);
    cell.add_mouseDown((sender, e) => this.cellMousedown(sender, e, this));
    scaleAction.set_button(lt.Controls.MouseButtons.right);
    cell.setCommand(MedicalViewerAction.Offset, new lt.Controls.Medical.OffsetAction());
    cell.setCommand(MedicalViewerAction.ProbeTool, probeTool);
    probeTool.interactiveObject.backgroundColor = 'rgba(0, 0, 0, 0.0)';
    probeTool.interactiveObject.textColor = 'rgba(153, 200, 255, 1)';
    probeTool.interactiveObject.showBorder = false;

    cell.setCommand(MedicalViewerAction.ShutterRect, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.rectangleObjectId));
    cell.setCommand(MedicalViewerAction.ShutterEllipse, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.ellipseObjectId));
    cell.setCommand(MedicalViewerAction.ShutterFreeHand, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.freehandObjectId));
    cell.setCommand(MedicalViewerAction.ShutterPolygon, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.polygonObjectId));
    cell.setCommand(MedicalViewerAction.Cursor3D, new lt.Controls.Medical.Cursor3DAction());
    cell.setCommand(MedicalViewerAction.DragItem, new lt.Controls.Medical.TransformItemAction(cell));
    // cell.setCommand(MedicalViewerAction.CrossHair, new lt.Controls.Medical.CrossHairAction());
    cell.setCommand(MedicalViewerAction.LineProfile, lineProfile);
    cell.setCommand(MedicalViewerAction.Scale, new lt.Controls.Medical.ScaleAction());
    cell.setCommand(MedicalViewerAction.Magnify, new lt.Controls.Medical.MagnifyAction());
    cell.setCommand(MedicalViewerAction.WindowLevel, new lt.Controls.Medical.WindowLevelAction());
    cell.setCommand(MedicalViewerAction.Stack, new lt.Controls.Medical.StackAction());
    cell.setCommand(MedicalViewerAction.AnnRectangle, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.rectangleObjectId));
    cell.setCommand(MedicalViewerAction.AnnEllipse, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.ellipseObjectId));
    cell.setCommand(MedicalViewerAction.AnnPointer, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.pointerObjectId));
    cell.setCommand(MedicalViewerAction.AnnCurve, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.curveObjectId));
    cell.setCommand(MedicalViewerAction.AnnJswObject, new lt.Controls.Medical.AutomationInteractiveAction(AnnJswObject.jswObjectId));
    cell.setCommand(MedicalViewerAction.AnnKmaObject, new lt.Controls.Medical.AutomationInteractiveAction(AnnKmaObject.kmaObjectId));
    cell.setCommand(MedicalViewerAction.AnnLine, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.lineObjectId));
    cell.setCommand(MedicalViewerAction.AnnText, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.textObjectId));
    cell.setCommand(MedicalViewerAction.AnnHighlight, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.hiliteObjectId));
    cell.setCommand(MedicalViewerAction.AnnRuler, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.rulerObjectId));
    cell.setCommand(MedicalViewerAction.AnnPolyRuler, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.polyRulerObjectId));
    cell.setCommand(MedicalViewerAction.AnnProtractor, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.protractorObjectId));
    cell.setCommand(MedicalViewerAction.AnnCrossRuler, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.crossProductObjectId));
    cell.setCommand(MedicalViewerAction.AnnSelect, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.selectObjectId));
    cell.setCommand(MedicalViewerAction.AnnFreeHand, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.freehandObjectId));
    cell.setCommand(MedicalViewerAction.AnnPoint, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.pointObjectId));
    cell.setCommand(MedicalViewerAction.AnnPolyline, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.polylineObjectId));
    cell.setCommand(MedicalViewerAction.AnnPolygon, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.polygonObjectId));
    cell.setCommand(MedicalViewerAction.AnnNote, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.noteObjectId));
    cell.setCommand(MedicalViewerAction.AnnTextPointer, new lt.Controls.Medical.AutomationInteractiveAction(lt.Annotations.Engine.AnnObject.textPointerObjectId));
    cell.setCommand(MedicalViewerAction.SpyGlass, spyGlass);
    // cell.get_automation().get_manager().set_useFreehandSelection(true);
    // cell.get_automationManager().set_thumbsGap(3);

    const viewer = cell.viewer;

    const scaleInteractiveMode = cell.getCommandInteractiveMode(MedicalViewerAction.Scale);
    scaleInteractiveMode.add_workStarted(() => {
      // console.log('offsetInteractiveMode, add_workStarted...');
      if (this.viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled
        && this.viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
        viewer.layout.get_items().toArray().forEach(cell => {
          this.overlayManagerService.remove_mapped_canvas(cell);
        });
      } else {
        this.overlayManagerService.remove_mapped_canvas(cell);
      }
    });
    scaleInteractiveMode.add_workCompleted(sender => {
      // console.log('offsetInteractiveMode, add_workCompleted...');
      if (this.viewerComponent
        && this.viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled
        && this.viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
        viewer.layout.get_items().toArray().forEach(c => {
          this.overlayManagerService.redraw_mapped_canvas(c);
        });
        viewer.layout.get_items().toArray().forEach(c => {
          this.overlayManagerService.redraw_gradient_canvas(c);
        });
      } else {
        this.overlayManagerService.redraw_mapped_canvas(cell);
        this.overlayManagerService.redraw_gradient_canvas(cell);
      }
    });

    const offsetInteractiveMode = cell.getCommandInteractiveMode(MedicalViewerAction.Offset);
    offsetInteractiveMode.add_workStarted(() => {
      // console.log('offsetInteractiveMode, add_workStarted...');
      if (this.viewerComponent
        && this.viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled
        && this.viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
          viewer.layout.get_items().toArray().forEach(c => {
            this.overlayManagerService.remove_mapped_canvas(c);
          });
      } else {
        this.overlayManagerService.remove_mapped_canvas(cell);
      }
    });
    offsetInteractiveMode.add_workCompleted(sender => {
      // console.log('offsetInteractiveMode, add_workCompleted...');
      if (this.viewerComponent
        && this.viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled
        && this.viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
          viewer.layout.get_items().toArray().forEach(cell => {
            this.overlayManagerService.redraw_mapped_canvas(cell);
          });
          viewer.layout.get_items().toArray().forEach(cell => {
            this.overlayManagerService.redraw_gradient_canvas(cell);
          });
      } else {
        this.overlayManagerService.redraw_mapped_canvas(cell);
        this.overlayManagerService.redraw_gradient_canvas(cell);
      }
    });

    // this.setupPanoramicAction(cell);
    this.lineProfileInteractiveMode = cell.getCommandInteractiveMode(MedicalViewerAction.LineProfile);
    this.initializeAnnAutomationManager(cell);
    cell.add_disposing(this.onDisposeCell);
  }

  updateGridLayoutSize(viewer, cellCount) {
    if (viewer == null) {
      return;
    }
    if (viewer.gridLayout.rows * viewer.gridLayout.columns < cellCount) {
      const sq = Math.sqrt(cellCount);
      if (viewer.cellsArrangement === lt.Controls.Medical.CellsArrangement.grid) {
        viewer.layout.beginUpdate();
        viewer.gridLayout.rows = Math.floor(sq + 0.1);
        viewer.gridLayout.columns = Math.ceil(cellCount / viewer.gridLayout.rows);
        try {
          viewer.layout.endUpdate();
        } catch (e) {
          // @TODO add errorHandler
        }
        // reset cells position in the layout grid
        this.resetCellsPosition(viewer);
      }
    }
  }

  makeRoomFor(viewer: lt.Controls.Medical.MedicalViewer, roomFor, gridUpd = false) {
    try {
      // if (this.viewerComponent.viewerSettings.layout.auto) {
      const desiredNumber = viewer.layout.items.count + roomFor;

      if (viewer.cellsArrangement == lt.Controls.Medical.CellsArrangement.grid) {
        if (desiredNumber >= viewer.gridLayout.rows * viewer.gridLayout.columns) {
          const rows = Math.floor(Math.sqrt(desiredNumber));
          const cols = Math.ceil(desiredNumber / rows);

          viewer.layout.beginUpdate();
          viewer.gridLayout.rows = rows;
          viewer.gridLayout.columns = cols;
          viewer.layout.endUpdate();
        }
      }
      // }
    } catch (error) {
      console.log('Error >>> ', error);
    }
  }

  clearRoomFor(viewer: lt.Controls.Medical.MedicalViewer, roomFor) {
    try {
      if (this.viewerComponent.viewerSettings.layout.auto) {
        let desiredNumber = viewer.layout.items.count - roomFor;
        desiredNumber = (desiredNumber <= 0 || desiredNumber == null) ? 1 : desiredNumber;

        if (viewer.cellsArrangement == lt.Controls.Medical.CellsArrangement.grid) {
          if (viewer.gridLayout.rows * viewer.gridLayout.columns >= desiredNumber) {
            const rows = Math.floor(Math.sqrt(desiredNumber));
            const cols = Math.ceil(desiredNumber / rows);

            viewer.layout.beginUpdate();
            viewer.gridLayout.rows = rows;
            viewer.gridLayout.columns = cols;
            viewer.layout.endUpdate();
          }
        }
      }
    } catch (error) {
      console.log('Error >>> ', error);
    }
  }

  deleteCell(viewer, cell) {
    // console.log('deleteCell...');
    let is_deleted = false;

    if (viewer.layout.get_items().indexOf(cell) != -1) {
      try {
        viewer.layout.get_items().remove(cell);
        viewer.get_emptyDivs().get_items().remove(cell);
        this.disposeAutomation(cell.get_automation());
        cell.dispose();
        (<any>cell).is_deleted = true;
        is_deleted = true;
      } catch (e) {
        console.log(e);
      }
    }
    return is_deleted;
  }

  disposeAutomation(automation) {
    if (automation == null || automation.get_container() == null || automation.get_container().get_children()) {
      return;
    }

    automation.get_container().get_children().clear();
    automation.detach();
  }

  resetCellsPosition(viewer): void {
    // console.log('resetCellsPosition...');
    // NOTE: needs to be called PRECISELY after layout update and
    // after item has been added or removed from the layout items
    try {
      const cntRows = viewer.gridLayout.rows;
      const cntCols = viewer.gridLayout.columns;
      let cntItems = viewer.layout.get_items().count;

      if (cntItems > cntRows * cntCols) {
        cntItems = cntRows * cntCols;
      }

      let nextCol = 0;
      let nextRow = 0;
      for (let index = 0; index < cntItems; index++) {
        const cell: lt.Controls.Medical.Cell = viewer.layout.get_items().get_item(index);
        cell.columnsPosition = nextCol;
        cell.rowPosition = nextRow;
        cell.position = index;

        if (nextCol < cntCols - 1) {
          nextCol += 1;
        } else {
          nextCol = 0;
          nextRow = (nextRow < cntRows - 1) ? nextRow + 1 : 0;
        }

        if (nextCol >= cntCols || nextRow >= cntRows) {
          console.log('Next cell position out of range');
          break;
        }
      }
    } catch (error) {
      console.log('Error >>> ', error);
    }

  }

  onDisposeCell(sender, args) {
    const cell = sender;
    const probeToolInteractiveMode = cell.getCommandInteractiveMode(MedicalViewerAction.ProbeTool);
    if (probeToolInteractiveMode == null) {
      return;
    }

    if (this != null) {
      probeToolInteractiveMode.remove_probeToolUpdated(this.probeToolUpdated);
    }
    probeToolInteractiveMode.dispose();
    try {
      probeToolInteractiveMode.remove_probeToolUpdated(this.probeToolUpdated);
      probeToolInteractiveMode.dispose();
    } catch (e) {
      // for some reason there is an error about probetool == null
      // console.log(e)
    }
  }

  probeToolUpdated(sender, args) {
    const frame = args.get_target();
    // console.log(this.dicomHelperService)
    // let modality = this.dicomHelperService.getDicomTagValue(frame.JSON, DicomTag.Modality);
    const modality = 'MRI';
    // @TODO Remove, this condition doesn't work
    if (frame.get_isDataReady() && false) {
      const value = lt.Controls.Medical.ProbeToolInteractiveMode
        .getHuValue(frame, args.get_position().get_x(), args.get_position().get_y(), -1);
      args.set_pixelValue('Hu = ' + value);
    } else {
      const rgbValue = lt.Controls.Medical.ProbeToolInteractiveMode
        .getPixelValue(frame, args.get_position().get_x(), args.get_position().get_y());
      if (frame.get_isDataReady()) {
        args.set_pixelValue('Density = ' + rgbValue[0]);
      } else {
        args.set_pixelValue('RGB = ' + rgbValue[0] + ', ' + rgbValue[1] + ', ' + rgbValue[2]);
      }
    }
  }

  applyImageProcessingOnSpyGlassCanvas(cell, canvas, image) {
    const context = canvas.getContext('2d');
    if (image != null) {
      context.drawImage(image, 0, 0);
    }
    switch (this.SpyglassEffect) {
      case this.Spyglass.Equalization: {
        if (this.stretchIntensityHigh == 0) {
          return;
        }
        const context = canvas.getContext('2d');
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        lt.Controls.Medical.ImageProcessing.levelIntensity(imageData, this.stretchIntensityLow, this.stretchIntensityHigh);
        context.putImageData(imageData, 0, 0);
      }
        break;
      case this.Spyglass.Invert: {
        let context = canvas.getContext('2d');
        let imageData;
        let pixels;
        try {
          const imageCanvas = canvas;
          const context1 = imageCanvas.getContext('2d');
          imageData = context1.getImageData(0, 0, imageCanvas.width, imageCanvas.height);
          pixels = imageData.data;
          const length = pixels.length;
          for (let i = 0; i < length;) {
            pixels[i] = 255 - pixels[i];
            pixels[i + 1] = 255 - pixels[i + 1];
            pixels[i + 2] = 255 - pixels[i + 2];
            i += 4;
          }
          context.putImageData(imageData, 0, 0);
        } finally {
          pixels = null;
          imageData = null;
          context = null;
        }
      }
        break;
      case this.Spyglass.CLAHE: {
        const imageCanvas = canvas;
        const imageContext = imageCanvas.getContext('2d');
        const imageData = imageContext.getImageData(0, 0, imageCanvas.width, imageCanvas.height);
        /*let arguments = {
                alpha: 0.65,
                tilesize: 6,
                tilehistcliplimit: 0.08,
                binsnumber: 128,
                flags: 0x00001,
                imageData: imageData,
                useProgress: false
            };*/
        const ip = new lt.ImageProcessing();
        cell.getCommandInteractiveMode(MedicalViewerAction.SpyGlass).set_text('Filter: CLAHE (Loading)');
        ip.set_jsFilePath('/assets/libs/leadtools/Leadtools.ImageProcessing.Core.js');
        ip.set_command('CLAHE');
        ip.get_arguments()['alpha'] = 0.65;
        ip.get_arguments()['tilesize'] = 6;
        ip.get_arguments()['tilehistcliplimit'] = 0.08;
        ip.get_arguments()['binsnumber'] = 128;
        ip.get_arguments()['flags'] = 0x00001;
        ip.get_arguments()['useProgress'] = false;
        ip.set_imageData(imageData);
        ip.add_completed((sender, event) => {
          let spyCanvasContext = null;
          if (!canvas) {
            return;
          }
          if (canvas.width == 1) {
            return;
          }
          spyCanvasContext = canvas.getContext('2d');
          spyCanvasContext.putImageData(event.get_imageData(), 0, 0);
          sender.abort();
          if (cell.getCommandInteractiveMode(MedicalViewerAction.SpyGlass).get_isWorking()) {
            cell.getCommandInteractiveMode(MedicalViewerAction.SpyGlass).refresh();
            cell.getCommandInteractiveMode(MedicalViewerAction.SpyGlass).set_text('Filter: CLAHE');
          }
        });
        ip.run();
      }
        break;
      default:
        cell.getCommandInteractiveMode(MedicalViewerAction.SpyGlass).set_text('');
        break;
    }
  }

  // @TODO should be removed in case function body is empty
  histogramGenerated(sender, args) {
    // this.currentLineProfileFrame = args.frame;
    // this.lineProfileHistogram = args.histogram;
    // this.colorType = args.type;
    // this.RenderLineProfileHistogram(0, 0, false);
    // this.HistogramUpdated();
    // drawTest(args);
  }

  spyGlassRequested(sender, args) {
    const cell = sender.SubCellAttached;
    const subCell = args.get_userData();
    const frameIndex = this.seriesManagerService.getActiveSubCellIndex(cell);
    const frame = cell.get_frames().get_item(frameIndex);
    let spyCanvas = null;
    const canvas = args.get_inputCanvas();
    const width = canvas.width;
    const height = canvas.height;
    if (!subCell.spyCanvas) {
      subCell.spyCanvas = document.createElement('canvas');
      spyCanvas = subCell.spyCanvas;
      spyCanvas.width = width;
      spyCanvas.height = height;
    }
    switch (this.SpyglassEffect) {
      case this.Spyglass.Equalization:
        cell.getCommandInteractiveMode(MedicalViewerAction.SpyGlass).set_text('Filter: Revealer');
        break;
      case this.Spyglass.Invert:
        cell.getCommandInteractiveMode(MedicalViewerAction.SpyGlass).set_text('Filter: Invert');
        break;
      case this.Spyglass.CLAHE:
        break;
      default:
        cell.getCommandInteractiveMode(MedicalViewerAction.SpyGlass).set_text('');
        break;
    }
    this.applyImageProcessingOnSpyGlassCanvas(cell, subCell.spyCanvas, canvas);
    args.set_canvas(args.get_userData().spyCanvas);
  }

  chunkLoaded(sender, args) {
    const cell = sender.SubCellAttached;
    this.applyImageProcessingOnSpyGlassCanvas(cell, args.chunk.canvas, args.chunk.backCanvas);
  }

  spyGlassPositionChanged(sender, args) {
    if (this.SpyglassEffect != this.Spyglass.Equalization) {
      return;
    }
    const displayRect = args.get_displayRect();
    const subCell = args.get_subCell();
    const canvas = args.get_canvas();
    const inputCanvas = args.inputCanvas;
    const cell = sender.SubCellAttached;
    if (displayRect.get_isEmpty()) {
      return;
    }
    if (displayRect.get_width() == 0) {
      return;
    }
    if (displayRect.get_height() == 0) {
      return;
    }
    const imageCanvas = subCell.get_parentCell().get_imageViewer().get_foreCanvas();
    const imageContext = imageCanvas.getContext('2d');
    let imageData = imageContext.getImageData(displayRect.get_left(), displayRect.get_top(), displayRect.get_width(), displayRect.get_height());
    const point = lt.Controls.Medical.ImageProcessing.getHistogramPoint(imageData, 10);
    this.stretchIntensityLow = point.x;
    this.stretchIntensityHigh = point.y;
    let context;
    const frame = subCell.attachedFrame;
    this.applyImageProcessingOnSpyGlassCanvas(cell, canvas, inputCanvas);
    const chunkList = args.chunkList;
    let index = 0;
    let chunkData;
    for (index = 0; index < chunkList.length; index++) {
      chunkData = chunkList[index];
      context = chunkData.canvas.getContext('2d');
      context.drawImage(chunkData.backCanvas, 0, 0);
      imageData = context.getImageData(0, 0, chunkData.rect.width, chunkData.rect.height);
      lt.Controls.Medical.ImageProcessing.levelIntensity(imageData, this.stretchIntensityLow, this.stretchIntensityHigh);
      context.putImageData(imageData, 0, 0);
    }
  }

  spyGlassDone(sender, args) {
    if (args.get_userData().spyCanvas) {
      lt.Controls.Medical.Tools.resetCanvas(args.get_userData().spyCanvas);
      args.get_userData().spyCanvas = null;
    }
  }

  createPanZoomInteractiveMode() {
    const zoomToInteractiveMode = new lt.Controls.ImageViewerPanZoomInteractiveMode();
    zoomToInteractiveMode.set_idleCursor('default');
    zoomToInteractiveMode.set_workingCursor('crosshair');
    zoomToInteractiveMode.set_enablePan(false);
    zoomToInteractiveMode.set_enableZoom(true);
    zoomToInteractiveMode.set_enablePinchZoom(false);
    // zoomToInteractiveMode.set_workOnImageRectangle(false);
    // zoomToInteractiveMode.add_workStarted(Zoom_Started);
    // zoomToInteractiveMode.add_workCompleted(Zoom_Completed);
    zoomToInteractiveMode.set_zoomKeyModifier(lt.Controls.Keys.none);
    zoomToInteractiveMode.set_mouseButtons(lt.Controls.MouseButtons.right);
    return zoomToInteractiveMode;
  }

  RenderLineProfileHistogram(x, y, drawLine) {
    let innerLineCanvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('lineProfileCanvas');
    const outerLineCanvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('outerlineProfileCanvas');

    if (innerLineCanvas == null) {
      return;
    }
    if (outerLineCanvas == null) {
      return;
    }

    if (this.currentLineProfileFrame == null) {
      return;
    }

    const context = innerLineCanvas.getContext('2d');
    let height = innerLineCanvas.height;
    const minMaxDelta = this.currentLineProfileFrame.information.maxValue - this.currentLineProfileFrame.information.minValue;
    const normalizedValue = height;


    if (this.lineProfileHistogram.length == 0) {
      return;
    }

    // UpdateSliderPosition(lineProfileSliderSize - 1);

    this.UpdateSliderSize();


    let componant = 0;
    const componantCount = (this.colorType == lt.Controls.Medical.ColorType.gray) ? 1 : 3;
    // let colors = componantCount == 3 ? new number[] { 'red', 'green', 'blue'} : new number[] { 'black'};
    const colors: string[] = [];
    const colorEnabled: boolean[] = [];

    if (componantCount == 3) {
      colors[0] = 'red';
      colorEnabled[0] = $('#checkboxRed').is(':checked') ? true : false;
      colors[1] = 'green';
      colorEnabled[1] = $('#checkboxGreen').is(':checked') ? true : false;
      colors[2] = 'blue';
      colorEnabled[2] = $('#checkboxBlue').is(':checked') ? true : false;
    } else {
      colors[0] = 'black';
      colorEnabled[0] = true;
    }
    context.clearRect(0, 0, innerLineCanvas.width, innerLineCanvas.height);
    context.save();
    for (componant = 0; componant < componantCount; componant++) {

      if (!colorEnabled[componant]) {
        continue;
      }

      let index = 0;
      const length = Math.min(this.lineProfileHistogram.length / componantCount, innerLineCanvas.width);
      let yLine = (height) - ((this.lineProfileHistogram[this.lineProfileSliderPosition * componantCount + componant] - this.currentLineProfileFrame.information.minValue) * (height) / minMaxDelta);
      context.beginPath();
      context.strokeStyle = colors[componant];
      context.moveTo(0, yLine);
      let pixel;
      for (index = 1; index < length; index++) {
        pixel = this.lineProfileHistogram[(this.lineProfileSliderPosition + index) * componantCount + componant] - this.currentLineProfileFrame.information.minValue;
        yLine = height - (pixel * height / minMaxDelta);
        context.lineTo(index, yLine);
      }

      context.stroke();
    }

    innerLineCanvas = <HTMLCanvasElement>document.getElementById('lineProfileCanvas');
    height = innerLineCanvas.height;

    if (this.lineProfileHistogram.length == 0) {
      return;
    }

    if (drawLine) {
      const context = innerLineCanvas.getContext('2d');
      const left = context.measureText('65535').width;
      let xLine = (x + left);
      const componantCount = (this.colorType == lt.Controls.Medical.ColorType.gray) ? 1 : 3;
      let yLine = 0;
      let textPosition = xLine;

      let pixelValue = '';
      let pixelPosition;

      for (componant = 0; componant < componantCount; componant++) {

        pixelPosition = (this.lineProfileSliderPosition + xLine) * componantCount + componant;

        if (pixelPosition >= this.lineProfileHistogram.length) {
          continue;
        }

        xLine = (x + left);
        yLine = (height) - ((this.lineProfileHistogram[pixelPosition] - this.currentLineProfileFrame.information.minValue) * (height) / minMaxDelta);

        context.beginPath();

        context.strokeStyle = 'black';
        context.fillStyle = 'rgba(230, 230, 128, 0.5)';
        context.arc(xLine + 0.5, yLine, 4, 0, 2 * Math.PI);
        context.moveTo(xLine + 0.5, yLine);
        context.lineTo(xLine + 0.5, height);

        this.currentLineProfileFrame.parentCell.lineProfile.histogramMarker = this.lineProfileSliderPosition + xLine;

        pixelValue += this.lineProfileHistogram[pixelPosition].toString();
        if (componant < (componantCount - 1)) {
          pixelValue += ',';
        }
        context.fill();
        context.stroke();
      }

      if (xLine < context.measureText(pixelValue).width + 10) {
        context.textAlign = 'left';
        // fixed value to push the text away from the mouse/finger
        textPosition += 10;
      } else {
        context.textAlign = 'right';
        // fixed value to push the text away from the mouse/finger
        textPosition -= 4;
      }

      if (pixelValue) {
        context.beginPath();
        (<any>context).textBaseline = 'baseline';
        context.fillStyle = 'black';
        context.fillText(pixelValue, textPosition, y);
        context.fillStyle = 'gray';
        context.strokeText(pixelValue, textPosition, y);
        context.fill();
        context.stroke();
      }
    } else {
      this.currentLineProfileFrame.parentCell.lineProfile.histogramMarker = -1;
    }
    context.restore();
  }

  HistogramUpdated() {
    this.RenderLineProfileHistogram(0, 0, false);
    // let left = context.measureText("65535").width;
    const innerLineCanvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('lineProfileCanvas');
    if (innerLineCanvas == null) {
      return;
    }
    this.UpdateSliderPosition(innerLineCanvas.clientWidth);
  }

  UpdateSliderPosition(newPosition) {
    if (!this.lineProfileHistogram) {
      return;
    }
    if (this.lineProfileHistogram.length == 0) {
      return;
    }

    const mainDiv: any = document.getElementById('dialog');

    let width = mainDiv.clientWidth - 24;

    let height = 180;

    const outerlineCanvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('outerlineProfileCanvas');
    const innerLineCanvas: HTMLCanvasElement = <HTMLCanvasElement>document.getElementById('lineProfileCanvas');
    const context = outerlineCanvas.getContext('2d');
    const space: number = width / 15;

    const left = context.measureText('65535').width;
    const top = innerLineCanvas.offsetTop - outerlineCanvas.offsetTop;
    width = width - space - left;
    height = innerLineCanvas.clientHeight;

    context.clearRect(0, height + 2 + top, outerlineCanvas.width, outerlineCanvas.height - height);

    if (this.lineProfileSliderSize == 0) {
      return;
    }

    height = 180;

    context.lineWidth = 1;
    context.beginPath();
    context.strokeStyle = 'rgba(255, 0, 0, 0.5)';
    context.moveTo(left + 0.5, height + top + 15 + 0.5);
    context.lineTo(left + width + 0.5, height + top + 15 + 0.5);
    context.stroke();

    context.beginPath();
    this.currentPosition = newPosition;
    this.currentPosition = Math.min(left + width + 0.5, Math.max(left, left + 0.5 + this.currentPosition));
    const realPosition: number = this.currentPosition - left;
    this.lineProfileSliderPosition = Math.round((realPosition * this.lineProfileSliderSize / width));

    context.lineWidth = 2;
    context.fillStyle = 'rgba(255, 128, 128, 1)';
    context.arc(this.currentPosition, height + top + 15, 6, 0, 2 * Math.PI);
    context.fill();
    context.stroke();


    context.textAlign = 'left';
    context.textBaseline = 'top';
    const componantCount = (this.colorType == lt.Controls.Medical.ColorType.gray) ? 1 : 3;

    height = innerLineCanvas.clientHeight;
    const fromPercentage = Math.round(this.lineProfileSliderPosition * 100 / (this.lineProfileHistogram.length / componantCount));
    const toPercentage = Math.round(((this.lineProfileSliderPosition + width) * 100) / (this.lineProfileHistogram.length / componantCount));
    context.fillText(fromPercentage + '%', left, height + top + 1);
    context.textAlign = 'right';
    context.fillText(toPercentage + '%', left + width - 0.5, height + top + 1);
  }

  UpdateSliderSize() {
    this.lineProfileSliderSize = 0;
  }

  assignAction(cell, action: any, button: any, buttonid: any): boolean {
    if (cell && cell.getCommand) {
      if (action === MedicalViewerAction.Rotate3D) {
        return false;
      }

      const actionCommand: lt.Controls.Medical.ActionCommand = cell.getCommand(action);

      let index = 0;
      const length = cell.viewer.layout.items.count;

      actionCommand.linked.clear();

      if ((action !== MedicalViewerAction.WindowLevel && action !== MedicalViewerAction.Offset && action !== MedicalViewerAction.Scale) ||
        (action === MedicalViewerAction.WindowLevel && this.viewerComponent.dicomLoaderService.synchControls.WindowLevelSynch.enabled) ||
        ((action === MedicalViewerAction.Offset || action === MedicalViewerAction.Scale) && this.viewerComponent.dicomLoaderService.synchControls.PanZoomSynch.enabled)
        ) {
        for (index = 0; index < length; index++) {
          const currentCell: lt.Controls.Medical.Cell = cell.viewer.layout.items.get_item(index);
          // console.log(currentCell)
          if (currentCell.tickBoxes) {
            if (currentCell.tickBoxes[0].checked) {
              actionCommand.linked.add(currentCell);
            }
          }
        }
      }

      actionCommand.button = button;

      cell.runCommand(action);

      if (button === lt.Controls.MouseButtons.left) {
        this.LastCommand.ButtonID = buttonid;
        this.LastCommand.Action = action;
      }
    } else {
      const cell3D: lt.Controls.Medical.Cell3D = <lt.Controls.Medical.Cell3D>cell;
      let action3D: lt.Controls.Medical.Interactive3DAction;
      switch (action) {
        case MedicalViewerAction.Offset:
          action3D = lt.Controls.Medical.Interactive3DAction.offset;
          break;
        case MedicalViewerAction.WindowLevel:
          action3D = lt.Controls.Medical.Interactive3DAction.windowLevel;
          break;
        case MedicalViewerAction.Scale:
          action3D = lt.Controls.Medical.Interactive3DAction.scale;
          break;
        case MedicalViewerAction.Rotate3D:
          action3D = lt.Controls.Medical.Interactive3DAction.rotate3D;
          break;
        default:
          return false;
      }
      if (button === lt.Controls.MouseButtons.left) {
        this.Last3DCommand.ButtonID = buttonid;
        this.Last3DCommand.Action = action3D;
      }

      if (cell3D) {
        cell3D.actions[button] = action3D;
      }
    }

    return true;
  }

  runActionCommand(action: string = '', info: any = {}, ...params): void {
    const execAction = this.actions[action];
    if (isFunction(execAction)) {
      // @TODO Should be removed form here, this function should be simple enough
      if (this.utils.isDemriqProject() && info !== null && info.viewerComponent !== null && this.activeJoint.regionToScore !== '') {
        this.LastCommand.Action = 16;
        this.LastCommand.ButtonID = 'Freehand';
        try {
          info.viewerComponent.activeJoint = this.activeJoint;
          info.viewerComponent.actionManagerService.LastCommand = this.LastCommand;
          this.viewerComponent = info.viewerComponent;
          if (this.isRapidAction && (this.isNewJoint || (info.viewerComponent.lastAction === 'OnAnnotationFreehand' && info.isNewOpenedSeries && info.isNewOpenedSeries === true))) {
            if (this.isNewJoint) {
              this.isNewJoint = false;
            }
            setTimeout(() => {
              this.runActionCommand(info.viewerComponent.lastAction, info.viewerComponent.lastInfo);
            }, 10);
          }
        } catch (error) {
          console.log('runActionCommand, error:', error);
        }
      }
      execAction.apply(this, [info].concat(params));
    }
  }

  private OnAutoSynchronization(info) {
    // console.log("OnAutoSynchronization...");
    const viewerComponent = info.viewerComponent;
    viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled = false;
    this.OnSlicePositionSynch(info, true);
    this.OnPanZoomSynch(info, true);
    this.OnWindowLevelSynch(info, false);
    this.viewerToolbar.setItemProperty('AutoSynchronization', 'readonly', true);
    this.viewerToolbar.setItemProperty('ManualSynchronization', 'readonly', true);
    this.viewerToolbar.setItemProperty('DisableAllSynch', 'readonly', false);
    // build/update matching map
    const activeCell = this.seriesManagerService.getActiveCell();
    viewerComponent.dicomLoaderService.setCellSynchronization(activeCell);
  }

  private OnManualSynchronization(info) {
    // console.log("OnManualSynchronization...");
    const viewerComponent = info.viewerComponent;
    viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled = true;
    this.OnSlicePositionSynch(info, true);
    this.OnPanZoomSynch(info, true);
    this.OnWindowLevelSynch(info, false);
    this.viewerToolbar.setItemProperty('ManualSynchronization', 'readonly', true);
    this.viewerToolbar.setItemProperty('AutoSynchronization', 'readonly', true);
    this.viewerToolbar.setItemProperty('DisableAllSynch', 'readonly', false);
    // build/update matching map
    const activeCell = this.seriesManagerService.getActiveCell();
    viewerComponent.dicomLoaderService.setCellSynchronization(activeCell);
  }

  private OnSlicePositionSynch(info, status?) {
    const viewerComponent = info.viewerComponent;
    const isEnabled = viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled;
    viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled = status !== undefined ? status : !(isEnabled);
    // update toolbar tooltip
    const newTooltip = !(viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled) ? 'Enable Slice Position Synch' : 'Disable Slice Position Synch';
    this.viewerToolbar.setItemProperty('SlicePositionSynch', 'tooltip', newTooltip);
    this.viewerToolbar.setItemProperty('SlicePositionSynch', 'readonly', false);

    if (viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
      this.viewerToolbar.setItemProperty('SynchronizeSeries', 'caption', 'Manual Synch.');
    } else {
      this.viewerToolbar.setItemProperty('SynchronizeSeries', 'caption', 'Auto Synch.');
    }

    if (!isEnabled) {
      this.viewerToolbar.setItemProperty('DisableAllSynch', 'readonly', isEnabled);
    }
  }

  public OnPanZoomSynch(info, status?) {
    const viewerComponent = info.viewerComponent;
    const isEnabled = viewerComponent.dicomLoaderService.synchControls.PanZoomSynch.enabled;
    viewerComponent.dicomLoaderService.synchControls.PanZoomSynch.enabled = status !== undefined ? status : !(isEnabled);
    // update toolbar tooltip
    const newTooltip = !(viewerComponent.dicomLoaderService.synchControls.PanZoomSynch.enabled) ? 'Enable Pan & Zoom Synch' : 'Disable Pan & Zoom Synch';
    this.viewerToolbar.setItemProperty('PanZoomSynch', 'tooltip', newTooltip);
    this.viewerToolbar.setItemProperty('PanZoomSynch', 'readonly', false);

    if (viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
      this.viewerToolbar.setItemProperty('SynchronizeSeries', 'caption', 'Manual Synch.');
    } else {
      this.viewerToolbar.setItemProperty('SynchronizeSeries', 'caption', 'Auto Synch.');
    }

    if (!isEnabled) {
      this.viewerToolbar.setItemProperty('DisableAllSynch', 'readonly', isEnabled);
    }
    const targetCmds = [MedicalViewerAction.Offset, MedicalViewerAction.Scale];

    // Link intra/inter visit series
    if (viewerComponent.dicomLoaderService.synchControls.PanZoomSynch.enabled) {
      // console.log("Link intra/inter visit series...");
      const seriesByFOR = this.seriesManagerService.getSeriesByFOR();
      if (viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
        // link all cells
        // console.log("link all cells...");
        const viewer = this.getViewerInstance();
        if (viewer) {
          const linkableCells = viewer.layout.get_items().toArray();
          this.linkCells(linkableCells, targetCmds);
        }
      } else {
        // link intra visit series
        // console.log("link intra visit series...");
        for (const frameOfReferenceUID in seriesByFOR) {
          const cellIds: [] = seriesByFOR[frameOfReferenceUID];
          // console.log("cellIds:", cellIds);
          if (cellIds.length > 1) {
            const synchCells = cellIds.map(cellId => this.seriesManagerService.getSeriesCellById(cellId));
            this.linkCells(synchCells, targetCmds);
          }
        }
      }
    } else {
      // unlink intra/inter visit series
      // console.log("unlink intra/inter visit series...");
      const viewer = this.getViewerInstance();
      if (viewer) {
        const linkableCells = viewer.layout.get_items().toArray();
        this.unlinkCells(linkableCells, targetCmds);
      }
    }
  }

  private OnWindowLevelSynch(info, status?) {
    // console.log("OnWindowLevelSynch...");
    const viewerComponent = info.viewerComponent;
    const isEnabled = viewerComponent.dicomLoaderService.synchControls.WindowLevelSynch.enabled;
    viewerComponent.dicomLoaderService.synchControls.WindowLevelSynch.enabled = status !== undefined ? status : !(isEnabled);
    // update toolbar tooltip
    const newTooltip = !(viewerComponent.dicomLoaderService.synchControls.WindowLevelSynch.enabled) ? 'Enable Window-Level Synch' : 'Disable Window-Level Synch';
    this.viewerToolbar.setItemProperty('WindowLevelSynch', 'tooltip', newTooltip);
    this.viewerToolbar.setItemProperty('WindowLevelSynch', 'readonly', false);

    if (viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
      this.viewerToolbar.setItemProperty('SynchronizeSeries', 'caption', 'Manual Synch.');
    } else {
      this.viewerToolbar.setItemProperty('SynchronizeSeries', 'caption', 'Auto Synch.');
    }

    if (!isEnabled) {
      this.viewerToolbar.setItemProperty('DisableAllSynch', 'readonly', isEnabled);
    }
    const targetCmds = [MedicalViewerAction.WindowLevel];

    // Link intra/inter visit series
    if (viewerComponent.dicomLoaderService.synchControls.WindowLevelSynch.enabled) {
      // console.log("OnWindowLevelSynch, Link intra/inter visit series...");
      const seriesByFOR = this.seriesManagerService.getSeriesByFOR();
      if (viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
        // link all cells
        // console.log("link all cells...");
        const viewer = this.getViewerInstance();
        if (viewer) {
          const linkableCells = viewer.layout.get_items().toArray();
          this.linkCells(linkableCells, targetCmds);
        }
      } else {
        // link intra visit series
        // console.log("link intra visit series...");
        for (const frameOfReferenceUID in seriesByFOR) {
          const cellIds: [] = seriesByFOR[frameOfReferenceUID];
          // console.log("cellIds:", cellIds);
          if (cellIds.length > 1) {
            const synchCells = cellIds.map(cellId => this.seriesManagerService.getSeriesCellById(cellId));
            this.linkCells(synchCells, targetCmds);
          }
        }
      }
    } else {
      // unlink intra/inter visit series
      // console.log("OnWindowLevelSynch, unlink intra/inter visit series...");
      const viewer = this.getViewerInstance();
      if (viewer) {
        const linkableCells = viewer.layout.get_items().toArray();
        this.unlinkCells(linkableCells, targetCmds);
      }
    }
  }

  private OnIntraVisitSynch(info) {
    const viewerComponent = info.viewerComponent;
    const isEnabled = viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled;
    viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled = !(isEnabled);
    // update toolbar tooltip
    const curTooltip = this.viewerToolbar.getItem('IntraVisitSynch').tooltip;
    const newTooltip = curTooltip == 'Enable Intra-Visit Synch' ? 'Disable Intra-Visit Synch' : 'Enable Intra-Visit Synch';
    this.viewerToolbar.setItemProperty('IntraVisitSynch', 'caption', newTooltip);

    if (!isEnabled) {
      this.viewerToolbar.setItemProperty('DisableAllSynch', 'readonly', isEnabled);
    }
  }

  private OnDisableAllSynch(info) {
    const viewerComponent = info.viewerComponent;
    const isEnabled = viewerComponent.dicomLoaderService.synchControls.DisableAllSynch.enabled;

    viewerComponent.dicomLoaderService.synchControls.DisableAllSynch.enabled = !(isEnabled);

    // update toolbar visibility
    this.viewerToolbar.setReadOnly(true, 'DisableAllSynch');
    this.viewerToolbar.setReadOnly(false, 'AutoSynchronization');
    this.viewerToolbar.setReadOnly(false, 'ManualSynchronization');

    // disable SlicePositionSynch
    if (viewerComponent.dicomLoaderService.synchControls.SlicePositionSynch.enabled) {
      this.OnSlicePositionSynch(info, false);
    }
    // disable PanZoomSynch
    if (viewerComponent.dicomLoaderService.synchControls.PanZoomSynch.enabled) {
      this.OnPanZoomSynch(info, false);
    }
    // disable WindowLevelSynch
    if (viewerComponent.dicomLoaderService.synchControls.WindowLevelSynch.enabled) {
      this.OnWindowLevelSynch(info, false);
    }
    // disable intra-visit synchronization
    viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled = false;

    this.viewerToolbar.setItemProperty('SynchronizeSeries', 'caption', 'Synch. Disabled');
    // update controls visibility
    this.viewerToolbar.setReadOnly(true, 'SlicePositionSynch');
    this.viewerToolbar.setReadOnly(true, 'PanZoomSynch');
    this.viewerToolbar.setReadOnly(true, 'WindowLevelSynch');
    this.viewerToolbar.setItemProperty('SlicePositionSynch', 'caption', 'SlicePositionSynch new.');
  }

  private linkCells(cells, targetCmds: number[]) {
    // console.log("linkCells...");
    const length = cells.length;
    for (let index = 0; index < length; index++) {
      const cell: lt.Controls.Medical.Cell = cells[index];

      if (cell) {
        // link cells on the target command
        targetCmds.forEach(commandID => {
          const command = cell.getCommand(commandID);
          for (let itemIndex = 0; itemIndex < length; itemIndex++) {
            const itemCell = cells[itemIndex];
            command.linked.add(itemCell);
            // check the cell tickbox
            if (itemCell.tickBoxes && itemCell.tickBoxes.length > 0) {
              itemCell.tickBoxes[0].checked = true;
            }
          }
        });
      }
    }
  }

  public unlinkCells(cells, targetCmds?) {
    // console.log('unlinkCells...');
    const length = cells.length;
    for (let index = 0; index < length; index++) {
      const cell: lt.Controls.Medical.Cell = cells[index];
      const allCmds = Object.values(cell.get_commands());
      const _targetCmds = targetCmds ? targetCmds : allCmds;

      _targetCmds.forEach(commandID => {
        const command = cell.getCommand(commandID);
        // unlink cells on the target command
        command.linked.clear();
      });

      if (_targetCmds.length === allCmds.length && cell.tickBoxes && cell.tickBoxes.length > 0) {
        cell.tickBoxes[0].checked = false;
      }
    }
  }

  private runCommand(commandID: number, buttonID: any = '', info?): void {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const assigned: boolean = this.assignAction(cell, commandID, lt.Controls.MouseButtons.left, buttonID);

    const buttonIds = [
      'Ruler', 'PolyRuler', 'Angle', 'CrossRuler'
    ];
    if (buttonIds.some(item => buttonID === item)) {
      (<any>cell).annType = buttonID;
    }

    if (!assigned) {
      return;
    }

    // set predefined actions based on the current action selected
    switch (commandID) {
      case MedicalViewerAction.WindowLevel:
        this.assignAction(cell, MedicalViewerAction.Offset, lt.Controls.MouseButtons.right, buttonID);
        this.assignAction(cell, MedicalViewerAction.Scale, lt.Controls.MouseButtons.middle, buttonID);
        break;

      case MedicalViewerAction.Scale:
        this.assignAction(cell, MedicalViewerAction.WindowLevel, lt.Controls.MouseButtons.right, buttonID);
        this.assignAction(cell, MedicalViewerAction.Offset, lt.Controls.MouseButtons.middle, buttonID);
        break;

      default:
        this.assignAction(cell, MedicalViewerAction.WindowLevel, lt.Controls.MouseButtons.right, buttonID);
        this.assignAction(cell, MedicalViewerAction.Scale, lt.Controls.MouseButtons.middle, buttonID);
        break;
    }

    if (cell) {
      cell.invalidate();
    }
  }

  createIconRegistry(): void {
    const sanitize = this.sanitizer.bypassSecurityTrustResourceUrl;
    this.iconRegistry.addSvgIcon('Configure', sanitize('/assets/images/viewer/main/Configure.svg'));
    this.iconRegistry.addSvgIcon('Magnify', sanitize('/assets/images/viewer/main/Magnify.svg'));
    this.iconRegistry.addSvgIcon('ComposeLayout', sanitize('/assets/images/viewer/main/ComposeLayout.svg'));
    this.iconRegistry.addSvgIcon('MergeCells', sanitize('/assets/images/viewer/main/MergeCells.svg'));
    this.iconRegistry.addSvgIcon('SaveHangingProtocol', sanitize('/assets/images/viewer/main/SaveHangingProtocol.svg'));
    this.iconRegistry.addSvgIcon('SaveStructuredDisplay', sanitize('/assets/images/viewer/main/SaveStructuredDisplay.svg'));
    this.iconRegistry.addSvgIcon('DeleteStudyLayout', sanitize('/assets/images/viewer/main/DeleteStudyLayout.svg'));
    this.iconRegistry.addSvgIcon('WindowLevel', sanitize('/assets/images/viewer/main/WindowLevel.svg'));
    this.iconRegistry.addSvgIcon('WindowLevelCustom', sanitize('/assets/images/viewer/main/WindowLevelCustom.svg'));
    this.iconRegistry.addSvgIcon('Pan', sanitize('/assets/images/viewer/main/pan.svg'));
    this.iconRegistry.addSvgIcon('Zoom', sanitize('/assets/images/viewer/main/Zoom.svg'));
    this.iconRegistry.addSvgIcon('SpyGlass', sanitize('/assets/images/viewer/main/SpyGlass.svg'));
    this.iconRegistry.addSvgIcon('SpyglassInvert', sanitize('/assets/images/viewer/main/SpyglassInvert.svg'));
    this.iconRegistry.addSvgIcon('SpyglassCLAHE', sanitize('/assets/images/viewer/main/SpyglassCLAHE.svg'));
    this.iconRegistry.addSvgIcon('SpyGlassEqualization', sanitize('/assets/images/viewer/main/SpyGlassEqualization.svg'));
    this.iconRegistry.addSvgIcon('Stack', sanitize('/assets/images/viewer/main/Stack.svg'));
    this.iconRegistry.addSvgIcon('ProbeTool', sanitize('/assets/images/viewer/main/ProbeTool.svg'));
    this.iconRegistry.addSvgIcon('FitImage', sanitize('/assets/images/viewer/main/FitImage.svg'));
    this.iconRegistry.addSvgIcon('OneToOne', sanitize('/assets/images/viewer/main/OneToOne.svg'));
    this.iconRegistry.addSvgIcon('TrueSizeDisplay', sanitize('/assets/images/viewer/main/TrueSizeDisplay.svg'));
    this.iconRegistry.addSvgIcon('ZoomIn', sanitize('/assets/images/viewer/main/ZoomIn.svg'));
    this.iconRegistry.addSvgIcon('ZoomOut', sanitize('/assets/images/viewer/main/ZoomOut.svg'));
    this.iconRegistry.addSvgIcon('RotateClock', sanitize('/assets/images/viewer/main/RotateClock.svg'));
    this.iconRegistry.addSvgIcon('RotateCounterClock', sanitize('/assets/images/viewer/main/RotateCounterClock.svg'));
    this.iconRegistry.addSvgIcon('Flip', sanitize('/assets/images/viewer/main/Flip.svg'));
    this.iconRegistry.addSvgIcon('Reverse', sanitize('/assets/images/viewer/main/Reverse.svg'));
    this.iconRegistry.addSvgIcon('AlignLeft', sanitize('/assets/images/viewer/main/AlignLeft.svg'));
    this.iconRegistry.addSvgIcon('AlignRight', sanitize('/assets/images/viewer/main/AlignRight.svg'));
    this.iconRegistry.addSvgIcon('AlignCenter', sanitize('/assets/images/viewer/main/AlignCenter.svg'));
    this.iconRegistry.addSvgIcon('AlignTop', sanitize('/assets/images/viewer/main/AlignTop.svg'));
    this.iconRegistry.addSvgIcon('AlignBottom', sanitize('/assets/images/viewer/main/AlignBottom.svg'));
    this.iconRegistry.addSvgIcon('AlignMiddle', sanitize('/assets/images/viewer/main/AlignMiddle.svg'));
    this.iconRegistry.addSvgIcon('SortSeries', sanitize('/assets/images/viewer/main/SortSeries.svg'));
    this.iconRegistry.addSvgIcon('InvertColor', sanitize('/assets/images/viewer/main/InvertColor.svg'));
    this.iconRegistry.addSvgIcon('BrightnessContrast', sanitize('/assets/images/viewer/main/BrightnessContrast.svg'));
    this.iconRegistry.addSvgIcon('HSL', sanitize('/assets/images/viewer/main/HSL.svg'));
    this.iconRegistry.addSvgIcon('StretchHistogram', sanitize('/assets/images/viewer/main/StretchHistogram.svg'));
    this.iconRegistry.addSvgIcon('EdgeEnhancment', sanitize('/assets/images/viewer/main/EdgeEnhancment.svg'));
    this.iconRegistry.addSvgIcon('Endo', sanitize('/assets/images/viewer/main/Endo.svg'));
    this.iconRegistry.addSvgIcon('Perio', sanitize('/assets/images/viewer/main/Perio.svg'));
    this.iconRegistry.addSvgIcon('Dentin', sanitize('/assets/images/viewer/main/Dentin.svg'));
    this.iconRegistry.addSvgIcon('Select', sanitize('/assets/images/viewer/main/Select.svg'));
    this.iconRegistry.addSvgIcon('Arrow', sanitize('/assets/images/viewer/main/Arrow.svg'));
    this.iconRegistry.addSvgIcon('Point', sanitize('/assets/images/viewer/main/point.svg'));
    this.iconRegistry.addSvgIcon('Rectangle', sanitize('/assets/images/viewer/main/rectangle.svg'));
    this.iconRegistry.addSvgIcon('Ellipse', sanitize('/assets/images/viewer/main/Ellipse.svg'));
    this.iconRegistry.addSvgIcon('Curve', sanitize('/assets/images/viewer/main/Curve.svg'));
    this.iconRegistry.addSvgIcon('Line', sanitize('/assets/images/viewer/main/Line.svg'));
    this.iconRegistry.addSvgIcon('Freehand', sanitize('/assets/images/viewer/main/Freehand.svg'));
    this.iconRegistry.addSvgIcon('AutoFreehand', sanitize('/assets/images/viewer/main/AutoFreehand.svg'));
    this.iconRegistry.addSvgIcon('Polyline', sanitize('/assets/images/viewer/main/polyline.svg'));
    this.iconRegistry.addSvgIcon('Polygon', sanitize('/assets/images/viewer/main/Polygon.svg'));
    this.iconRegistry.addSvgIcon('Text', sanitize('/assets/images/viewer/main/Text.svg'));
    this.iconRegistry.addSvgIcon('Note', sanitize('/assets/images/viewer/main/Note.svg'));
    this.iconRegistry.addSvgIcon('Highlight', sanitize('/assets/images/viewer/main/Highlight.svg'));
    this.iconRegistry.addSvgIcon('TextPointer', sanitize('/assets/images/viewer/main/TextPointer.svg'));
    this.iconRegistry.addSvgIcon('Ruler', sanitize('/assets/images/viewer/main/ruler.svg'));
    this.iconRegistry.addSvgIcon('PolyRuler', sanitize('/assets/images/viewer/main/PolyRuler.svg'));
    this.iconRegistry.addSvgIcon('Protractor', sanitize('/assets/images/viewer/main/Protractor.svg'));
    this.iconRegistry.addSvgIcon('CalibrateRulerAnn', sanitize('/assets/images/viewer/main/CalibrateRulerAnn.svg'));
    this.iconRegistry.addSvgIcon('ShowHide', sanitize('/assets/images/viewer/main/ShowHide.svg'));
    this.iconRegistry.addSvgIcon('DeleteAnn', sanitize('/assets/images/viewer/main/DeleteAnn.svg'));
    this.iconRegistry.addSvgIcon('Clear', sanitize('/assets/images/viewer/main/Clear.svg'));
    this.iconRegistry.addSvgIcon('ClearAll', sanitize('/assets/images/viewer/main/ClearAll.svg'));
    this.iconRegistry.addSvgIcon('SaveAnn', sanitize('/assets/images/viewer/main/SaveAnn.svg'));
    this.iconRegistry.addSvgIcon('LoadAnn', sanitize('/assets/images/viewer/main/LoadAnn.svg'));
    this.iconRegistry.addSvgIcon('Reload', sanitize('/assets/images/viewer/main/reload.svg'));
    this.iconRegistry.addSvgIcon('ToggleTags', sanitize('/assets/images/viewer/main/ToggleTags.svg'));
    this.iconRegistry.addSvgIcon('ShowDicom', sanitize('/assets/images/viewer/main/ShowDicom.svg'));
    this.iconRegistry.addSvgIcon('FullScreen', sanitize('/assets/images/viewer/main/FullScreen.svg'));
    this.iconRegistry.addSvgIcon('CloseFullScreen', sanitize('/assets/images/viewer/main/CloseFullScreen.svg'));
    this.iconRegistry.addSvgIcon('Rotate3D', sanitize('/assets/images/viewer/main/Rotate3D.svg'));
    this.iconRegistry.addSvgIcon('VRT', sanitize('/assets/images/viewer/main/VRT.svg'));
    this.iconRegistry.addSvgIcon('MIP', sanitize('/assets/images/viewer/main/MIP.svg'));
    this.iconRegistry.addSvgIcon('MPR', sanitize('/assets/images/viewer/main/MPR.svg'));
    this.iconRegistry.addSvgIcon('MPRVolume', sanitize('/assets/images/viewer/main/MPRVolume.svg'));
    this.iconRegistry.addSvgIcon('Settings3D', sanitize('/assets/images/viewer/main/Settings3D.svg'));
    this.iconRegistry.addSvgIcon('CrossHair', sanitize('/assets/images/viewer/main/CrossHair.svg'));
    this.iconRegistry.addSvgIcon('HeadOrientation', sanitize('/assets/images/viewer/main/HeadOrientation.svg'));
    this.iconRegistry.addSvgIcon('FeetOrientation', sanitize('/assets/images/viewer/main/FeetOrientation.svg'));
    this.iconRegistry.addSvgIcon('LeftOrientation', sanitize('/assets/images/viewer/main/LeftOrientation.svg'));
    this.iconRegistry.addSvgIcon('RightOrientation', sanitize('/assets/images/viewer/main/RightOrientation.svg'));
    this.iconRegistry.addSvgIcon('AnteriorOrientation', sanitize('/assets/images/viewer/main/AnteriorOrientation.svg'));
    this.iconRegistry.addSvgIcon('PosteriorOrientation', sanitize('/assets/images/viewer/main/PosteriorOrientation.svg'));
    this.iconRegistry.addSvgIcon('DeleteCell', sanitize('/assets/images/viewer/main/DeleteCell.svg'));
    this.iconRegistry.addSvgIcon('StudyLayout', sanitize('/assets/images/viewer/main/StudyLayout.svg'));
    this.iconRegistry.addSvgIcon('SeriesLayout', sanitize('/assets/images/viewer/main/SeriesLayout.svg'));
    this.iconRegistry.addSvgIcon('ToggleCine', sanitize('/assets/images/viewer/main/ToggleCine.svg'));
    this.iconRegistry.addSvgIcon('CinePlayer', sanitize('/assets/images/viewer/main/CinePlayer.svg'));
    this.iconRegistry.addSvgIcon('PopupCapture', sanitize('/assets/images/viewer/main/PopupCapture.svg'));
    this.iconRegistry.addSvgIcon('SecondaryCapture', sanitize('/assets/images/viewer/main/SecondaryCapture.svg'));
    this.iconRegistry.addSvgIcon('Export', sanitize('/assets/images/viewer/main/Export.svg'));
    this.iconRegistry.addSvgIcon('StudyTimeLine', sanitize('/assets/images/viewer/main/StudyTimeLine.svg'));
    this.iconRegistry.addSvgIcon('WaveformBasicAudio', sanitize('/assets/images/viewer/main/WaveformBasicAudio.svg'));
    this.iconRegistry.addSvgIcon('ShutterObject', sanitize('/assets/images/viewer/main/ShutterObject.svg'));
    this.iconRegistry.addSvgIcon('Cursor3D', sanitize('/assets/images/viewer/main/Cursor3D.svg'));
    this.iconRegistry.addSvgIcon('ReferenceLine', sanitize('/assets/images/viewer/main/ReferenceLine.svg'));
    this.iconRegistry.addSvgIcon('ShowFirstLast', sanitize('/assets/images/viewer/main/ShowFirstLast.svg'));
    this.iconRegistry.addSvgIcon('LineProfile', sanitize('/assets/images/viewer/main/LineProfile.svg'));
    this.iconRegistry.addSvgIcon('Synchronization', sanitize('/assets/images/viewer/main/Synchronization.svg'));
    this.iconRegistry.addSvgIcon('LinkStudies', sanitize('/assets/images/viewer/main/LinkStudies.svg'));
    this.iconRegistry.addSvgIcon('Linked', sanitize('/assets/images/viewer/main/Linked.svg'));
    this.iconRegistry.addSvgIcon('CrossRuler', sanitize('/assets/images/viewer/main/CrossRuler.svg'));
    this.iconRegistry.addSvgIcon('ImportTemplates', sanitize('/assets/images/viewer/main/ImportTemplates.svg'));
    this.iconRegistry.addSvgIcon('GBMSegmentation', sanitize('/assets/images/viewer/main/GBMSegmentation.svg'));
    this.iconRegistry.addSvgIcon('2D3DDiam', sanitize('/assets/images/viewer/main/2D3DDiam.svg'));
    this.iconRegistry.addSvgIcon('GBMStation', sanitize('/assets/images/viewer/main/GBMStation.svg'));
    this.iconRegistry.addSvgIcon('RunSeg', sanitize('/assets/images/viewer/main/RunSeg.svg'));
    this.iconRegistry.addSvgIcon('RunReg', sanitize('/assets/images/viewer/main/RunReg.svg'));
    this.iconRegistry.addSvgIcon('TumorCorrection', sanitize('/assets/images/viewer/main/TumorCorrection.svg'));
    this.iconRegistry.addSvgIcon('ShowHideOverlays', sanitize('/assets/images/viewer/main/ShowHideOverlays.svg'));
    this.iconRegistry.addSvgIcon('SliceSync', sanitize('/assets/images/viewer/main/SliceSync.svg'));
    this.iconRegistry.addSvgIcon('VisualComparison', sanitize('/assets/images/viewer/main/VisualComparison.svg'));
    this.iconRegistry.addSvgIcon('AddMarker', sanitize('/assets/images/viewer/main/AddMarker.svg'));
  }

  getViewerInstance(cell = this.seriesManagerService.getActiveCell()): lt.Controls.Medical.MedicalViewer {
    return cell ? cell.get_viewer() : null;
  }

  onViewerLayout(rows, cols, info) {
    const viewerComponent = info.viewerComponent;
    const viewer = viewerComponent.viewer;
    if (viewer) {
      const cntRows = viewer.gridLayout.rows;
      const cntCols = viewer.gridLayout.columns;

      viewerComponent.viewerSettings.layout.rows = rows;
      viewerComponent.viewerSettings.layout.cols = cols;
      this.OnViewerLayoutAuto(info, false);

      viewer.gridLayout.set_rows(rows);
      viewer.gridLayout.set_columns(cols);

      viewer.set_cellsArrangement(0);

      // reset cells position if layout reduce size
      this.resetCellsPosition(viewer);
    }
  }

  onCustomViewerLayout(info) {
    const viewer: lt.Controls.Medical.MedicalViewer = info ?
      info.viewerComponent.viewer : this.getViewerInstance();

    let numberRows = 1;
    let numberCols = 2;

    if (viewer.cellsArrangement == lt.Controls.Medical.CellsArrangement.grid) {
      numberRows = viewer.gridLayout.rows;
      numberCols = viewer.gridLayout.columns;
    }

    const dialogRef = this.dialog.open(CustomLayoutDialogComponent, {
      height: 'auto',
      width: '300px',
      disableClose: true,
      data: {
        numberRows: numberRows,
        numberCols: numberCols,
        viewer: viewer,
        actionManagerService: this,
        viewerComponent: info.viewerComponent
      }
    });

    this.viewerToolbar.setItemProperty('ViewerLayoutAuto', 'readonly', false);
  }

  OnViewerLayoutAuto(info, auto?) {
    try {
      const viewerComponent = info.viewerComponent;
      viewerComponent.viewerSettings.layout.auto = auto !== undefined && auto !== null ? auto : !(viewerComponent.viewerSettings.layout.auto);
      if (auto != false) {
        viewerComponent.viewerSettings.layout.rows = 1;
        viewerComponent.viewerSettings.layout.cols = 1;
        viewerComponent.viewer.gridLayout.rows = 1;
        viewerComponent.viewer.gridLayout.columns = 1;
        this.makeRoomFor(viewerComponent.viewer, 0);
        // reset cells position if layout reduce size
        this.resetCellsPosition(viewerComponent.viewer);
      }
      console.log('viewerComponent.viewerSettings.layout.auto:', viewerComponent.viewerSettings.layout.auto);
      this.viewerToolbar.setItemProperty('ViewerLayoutAuto', 'readonly', viewerComponent.viewerSettings.layout.auto);
      this.asyncResizeTrigger();
    } catch (error) {
      console.log('Error >>> ', error);
    }
  }

  setAnnTool(tool, info?) {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();

    if (cell) {
      this.assignAction(cell, tool, lt.Controls.MouseButtons.left, '');
      if (info && info.annType) {
        (<any>cell).annType = info.annType;
        this.LastCommand.ButtonID = info.annType;
      }
    }
  }

  addObjectAdded(new_child, cell, frameIndex, info) {
    // event fire only on the current offset
    if ((new_child.guid != null || (new_child.bounds.height === 0 && new_child.bounds.isEmpty == true)) && (new_child.get_metadata()['CurrentFrame'] == undefined || new_child.get_metadata()['CurrentFrame'] == null)) {
      return;
    }

    // event breaks when object is reference line
    if (new_child.metadata && new_child.metadata.Label === 'ReferenceLine') {
      return;
    }

    // remove very samll ROIs in Demriq project
    try {
      if (this.utils.isDemriqProject()) {
        const childMetadata = new_child.get_metadata();
        const activeContainer = cell.get_automation().get_activeContainer();
        if (new_child.get_points().count < 7 && childMetadata.Subject === 'primaryLocationArea') {
          new_child.isSelected = false;
          activeContainer.children.remove(new_child);
          console.log('very small ROI was removed...');
          return;
        }
      }
    } catch (error) {
      console.log('Error... ', error);
    }

    const viewerComponent = info.viewerComponent;
    const childMetadata = new_child.get_metadata();

    (<any>cell).annType = null; // TODO: move annType from cell to child
    info.annType = childMetadata.AnnoType ? childMetadata.AnnoType : this.LastCommand.ButtonID;

    info.seriesManagerService = this.seriesManagerService;
    info.actionManagerService = this;
    const isNewChild: boolean = this.utils.onObjectAdded(cell, new_child, frameIndex, info);

    if (new_child.get_metadata()['Subject'] === 'primaryLocationArea' && !this.isRapidAction) {
      this.isRapidAction = true;
    }
    // store the new annotation when its been drawn and repeat last freehand action
    if (this.utils.isDemriqProject() && isNewChild) {
      if (viewerComponent.lastAction === 'OnAnnotationFreehand') {
        setTimeout(() => {
            this.runActionCommand(info.viewerComponent.lastAction, info.viewerComponent.lastInfo);
          }, 10);
      }
      this.seriesManagerService.autoVolumeCalculations(info, cell, childMetadata.Label).then((rowData2) => {
        viewerComponent.rowData2 = rowData2;
      });
    }
  }

  addContainerEvents(cell: lt.Controls.Medical.Cell, info) {
    // Add events listeners to track annotation changes
    const frameArray: Array<lt.Controls.Medical.Frame> = cell.get_frames().toArray();

    frameArray.forEach((frame, frameIndex) => {
      const container: ltAnnotationsEngine.AnnContainer = frame.get_container();

      const objectAdded = (sender, e) => {
        // console.log("objectAdded, data:", {"sender": sender, "e": e, "frameIndex": frameIndex});
        if (this.annoTracker['mousedown']) {
          const objAdded = { 'child': e.object, 'cell': cell, 'frameIndex': frameIndex, 'info': info };
          this.annoTracker['objectAdded'].push(objAdded);
        } else {
          this.addObjectAdded(e.object, cell, frameIndex, info);
          e.object.get_metadata()['ShadowROI'] = true;
        }
      };

      const objectRemoved = (sender, e) => {
        this.utils.onObjectRemoved(cell, frameIndex, info, e);
        // enabling drawing last selected primaryLocation area for Demriq project after any ROI gets removed
        if (this.utils.isDemriqProject() && this.activeJoint && this.viewerComponent.lastAction === 'OnAnnotationFreehand' && this.activeJoint.color !== '') {
            cell.get_automation().cancel();
            this.drawCustomFreehand(this.activeJoint.color, this.activeJoint.regionToScore, this.activeJoint.primaryLocation, info);
        }
      };

      // add events and ensure their atomicity
      if (!(<any>container).is_add_objectAdded) {
        container.add_objectAdded(objectAdded);
        (<any>container).is_add_objectAdded = true;
      }

      if (!(<any>container).is_add_objectRemoved) {
        container.add_objectRemoved(objectRemoved);
        (<any>container).is_add_objectRemoved = true;
      }

      // event on children collection changed
      frame.get_container().get_children().add_collectionChanged((sender, e) => {
        // console.log("collectionChanged, data:", {"sender": sender, "e": e, "frameIndex": frameIndex});
        this.utils.onCollectionChanged(e, cell, frameIndex, info);
      });
    });

    cell.get_div().addEventListener('mousedown', () => {
      // console.log("mousedown...");
      this.annoTracker['mouseup'] = false;
      this.annoTracker['mousedown'] = true;

      // catch mousedown event to reset ROI selection
      if ((<any>cell).lastSelChild) {
        // console.log("add_mouseDown, lastSelChild:", (<any>cell).lastSelChild);
        (<any>cell).lastSelChild.isSelected = false;
        (<any>cell).lastSelChild = null;

        const viewerComponent = info.viewerComponent;
        // reset selection on ROI panel
        viewerComponent.dataSource1['data'].forEach(item => {
          if (item['isSelected']) {
            item['isSelected'] = false;

            if (item.child) {
              item.child.isSelected = false;
            }
          }
        });
      }
    });

    cell.get_div().addEventListener('mouseup', (event: any) => {
      try {
        if (this.isAddingMarker && this.markerType !== '') {
          if (this.lesionName != undefined && this.lesionName != null && this.lesionName != '') {
            info.viewerComponent.addMarker(this.markerType, event.offsetX, event.offsetY, this.lesionName);
          } else {
            info.viewerComponent.addMarker(this.markerType, event.offsetX, event.offsetY);
          }
          this.isAddingMarker = false;
        }
      } catch (error) {

      }

      this.annoTracker['mousedown'] = false;
      this.annoTracker['mouseup'] = true;

      if (this.annoTracker['objectAdded'].length > 0) {
        const objAdded = this.annoTracker['objectAdded'].pop();
        this.addObjectAdded(objAdded.child, objAdded.cell, objAdded.frameIndex, objAdded.info);
      }
    });
  }

  reset_selection_tool(info) {
    const anno_buttonIDs = [
      'Rectangle',
      'Ellipse', 'Curve',
      'Freehand', 'Polygon',
    ];

    const ruler_buttonIDs = [
      'Ruler', 'CrossRuler',
      // 'PolyRuler', 'Protractor',
      // 'OnAnnotationCobbAngle',
    ];

    console.log('Action:', this.LastCommand.Action);
    console.log('ButtonID:', this.LastCommand.ButtonID);
    if (anno_buttonIDs.some(item => this.LastCommand.ButtonID === item)) {
      console.log('1.reset the selection tool...');
      setTimeout(() => {
        this.setAnnTool(this.LastCommand.Action, info);
      }, 30);
    } else if (ruler_buttonIDs.some(item => this.LastCommand.ButtonID === item)) {
      console.log('2.reset the selection tool...');
      setTimeout(() => {
        this.runCommand(this.LastCommand.Action, this.LastCommand.ButtonID, info);
      }, 30);
    }
  }

  horImageAlign(action) {
    const side =
      action === 'ImageAlignCenter' ? 'center' :
        action === 'ImageAlignLeft' ? 'left' :
          action === 'ImageAlignRight' ? 'right' : 'center';
    const cell = this.seriesManagerService.getActiveCell();
    if (cell) {
      const linked = cell.get_linked();
      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, function (frame, index) {
          frame.horizontalAlignment = lt.Controls.Medical.HorizontalAlignmentType[side];
        });
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame();
        cellFrame.horizontalAlignment = lt.Controls.Medical.HorizontalAlignmentType[side];
      }
    }
  }

  vertImageAlign(action) {
    const side =
      action === 'ImageAlignBottom' ? 'bottom' :
        action === 'ImageAlignTop' ? 'top' :
          action === 'ImageAlignMiddle' ? 'middle' : 'center';
    const cell = this.seriesManagerService.getActiveCell();
    if (cell) {
      const linked = cell.get_linked();
      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, function (frame, index) {
          frame.verticalAlignment = lt.Controls.Medical.VerticalAlignmentType[side];
        });
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame();
        cellFrame.verticalAlignment = lt.Controls.Medical.VerticalAlignmentType[side];
      }
    }
  }

  popupCapture() {
    // console.log("popupCapture...");
    const cell = this.seriesManagerService.getActiveCell();
    const sliceNumb = cell.currentOffset;
    const canvasData = this.seriesManagerService.captureCurrentFrame(true);
    const cellInfo = this.seriesManagerService.getSeriesInfo(cell);
    const snapShotData = {
      cell,
      cellInfo,
      sliceNum: sliceNumb,
      image: canvasData
    };
    // console.log("snapShotData:", snapShotData);

    this.snapeShotSubj.next(snapShotData);
    return snapShotData;
  }

  onReload() {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    if (cell != null) {
      this.seriesManagerService.enumerateFrames(cell, (frame: lt.Controls.Medical.Frame, index) => {
        frame.reset();
        this.utils.clearAllShutter(frame);

        frame.set_offsetX(0);
        frame.set_offsetY(0);
        if (frame.subCell != null) {
          this.utils.subCell_setPresentationMode(<lt.Controls.Medical.MRTISubCell>frame.subCell);
        }
        frame.horizontalAlignment = lt.Controls.Medical.HorizontalAlignmentType.middle;
        frame.verticalAlignment = lt.Controls.Medical.VerticalAlignmentType.middle;

      }, (cell3D: lt.Controls.Medical.Cell3D) => {
        cell3D.reset();
      });
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  onRotateClockwise(info: any, cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell(), useTransaction = true) {
    if (cell != null) {
      const linked = cell.get_linked();

      if (useTransaction) {
        cell.get_imageViewer().beginUpdate();
      }
      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame, index) => {
          const angle = this.getRealRotationAngle(90, frame);

          frame.set_rotateAngle(frame.get_rotateAngle() + angle);
          frame.IPFunctionParams.Rotate.Angle = frame.get_rotateAngle();
        });
        // store user rotation angle
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);
        (<any>cell).user_angle = cellFrame.get_rotateAngle();
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);
        const angle = this.getRealRotationAngle(90, cellFrame);

        cellFrame.set_rotateAngle(cellFrame.get_rotateAngle() + angle);
        // store user rotation angle
        (<any>cell).user_angle = cellFrame.get_rotateAngle();
      }

      if (useTransaction) {
        cell.get_imageViewer().endUpdate();
      }
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
      // console.log('(<any>cell).user_angle:', (<any>cell).user_angle);
    }
  }

  getRealRotationAngle(angle, frame) {
    let direction = 1;
    if (frame.get_flipped() ^ frame.get_reversed()) {
      direction = -1;
    }

    return direction * angle;
  }

  onRotateCounterclockwise(info: any, cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell(), useTransaction = true) {
    if (cell != null) {
      const linked = cell.get_linked();

      if (useTransaction) {
        cell.get_imageViewer().beginUpdate();
      }

      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame, index) => {
          const angle = this.getRealRotationAngle(-90, frame);

          frame.set_rotateAngle(frame.get_rotateAngle() + angle);
        });
        // store user rotation angle
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);
        (<any>cell).user_angle = cellFrame.get_rotateAngle();
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);
        const angle = this.getRealRotationAngle(-90, cellFrame);

        cellFrame.set_rotateAngle(cellFrame.get_rotateAngle() + angle);
        // store user rotation angle
        (<any>cell).user_angle = cellFrame.get_rotateAngle();
      }

      if (useTransaction) {
        cell.get_imageViewer().endUpdate();
      }
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
      // console.log('(<any>cell).user_angle:', (<any>cell).user_angle);
    }
  }

  onFlip(info: any, cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell(), useTransaction = true) {
    if (cell != null) {
      const linked = cell.get_linked();

      if (useTransaction) {
        cell.get_imageViewer().beginUpdate();
      }

      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame, index) => {
          frame.set_flipped(!frame.get_flipped());
        });
        // store user flip status
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);
        (<any>cell).user_flip = cellFrame.get_flipped();
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);

        cellFrame.set_flipped(!cellFrame.get_flipped());
        // store user flip status
        (<any>cell).user_flip = cellFrame.get_flipped();
      }

      if (useTransaction) {
        cell.get_imageViewer().endUpdate();
      }
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  onReverse(info: any, cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell(), useTransaction = false) {
    if (cell) {
      const linked = cell.get_linked();

      if (useTransaction) {
        cell.get_imageViewer().beginUpdate();
      }

      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame, index) => {
          frame.set_reversed(!frame.get_reversed());

          // try to hide labels of ROIs when image is been reversed and show it when its not reversed
          try {
            frame.container.children.W.forEach(child => {
              if (child.labels.AnnObjectName) {
                child.labels.AnnObjectName.set_isVisible(!frame.get_reversed());
              }
            });
            this.asyncResizeTrigger();
          } catch (error) {
            console.log('Error: ', error);
          }
        });
        // store user flip status
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);
        (<any>cell).user_reverse = cellFrame.get_reversed();
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);
        cellFrame.set_reversed(!cellFrame.get_reversed());
        // store user flip status
        (<any>cell).user_reverse = cellFrame.get_reversed();
      }

      if (useTransaction) {
        cell.get_imageViewer().endUpdate();
      }
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  onFitImage() {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    if (cell != null) {
      const linked = cell.get_linked();

      cell.get_imageViewer().beginUpdate();
      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame, index) => {
          frame.set_offsetX(0);
          frame.set_offsetY(0);
          frame.zoom(lt.Controls.Medical.MedicalViewerSizeMode.fit, 1);
        });
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame(cell);

        cellFrame.set_offsetX(0);
        cellFrame.set_offsetY(0);
        cellFrame.zoom(lt.Controls.Medical.MedicalViewerSizeMode.fit, 1);
      }
      cell.get_imageViewer().endUpdate();
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  onOneToOne() {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    if (cell != null) {
      const linked = cell.get_linked();

      cell.get_imageViewer().beginUpdate();
      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame: lt.Controls.Medical.Frame, index) => {
          frame.zoom(lt.Controls.Medical.MedicalViewerSizeMode.actualSize, 1);
        });
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame();

        cellFrame.zoom(lt.Controls.Medical.MedicalViewerSizeMode.actualSize, 1);
      }
      cell.get_imageViewer().endUpdate();
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  onTrueSizeDisplay() {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    if (cell != null) {
      const linked = cell.get_linked();

      cell.get_imageViewer().beginUpdate();
      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame: lt.Controls.Medical.Frame, index) => {
          frame.set_offsetX(0);
          frame.set_offsetY(0);
          frame.zoom(lt.Controls.Medical.MedicalViewerSizeMode.trueSize, 1);
        });
      } else {
        const cellFrame = this.seriesManagerService.getActiveCellFrame();

        cellFrame.set_offsetX(0);
        cellFrame.set_offsetY(0);
        cellFrame.zoom(lt.Controls.Medical.MedicalViewerSizeMode.trueSize, 1);
      }
      cell.get_imageViewer().endUpdate();
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  onZoomIn() {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    if (cell != null) {
      const linked = cell.get_linked();

      cell.get_imageViewer().beginUpdate();
      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame: lt.Controls.Medical.Frame, index) => {
          frame.zoom(frame.get_scaleMode(), frame.get_scale() * 2);
        });
      } else {
        const cellFrame: lt.Controls.Medical.Frame = this.seriesManagerService.getActiveCellFrame();

        cellFrame.zoom(cellFrame.get_scaleMode(), cellFrame.get_scale() * 2);
      }
      cell.get_imageViewer().endUpdate();
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  onZoomOut() {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    if (cell != null) {
      const linked = cell.get_linked();

      cell.get_imageViewer().beginUpdate();
      if (linked) {
        this.seriesManagerService.enumerateFrames(cell, (frame: lt.Controls.Medical.Frame, index) => {
          frame.zoom(frame.get_scaleMode(), frame.get_scale() / 2);
        });
      } else {
        const cellFrame: lt.Controls.Medical.Frame = this.seriesManagerService.getActiveCellFrame();

        cellFrame.zoom(cellFrame.get_scaleMode(), cellFrame.get_scale() / 2);
      }
      cell.get_imageViewer().endUpdate();
      // redraw map & gradient if displayed
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  onToggleFullScreen(info: any) {
    info.viewerComponent.preventClose = true;

    // changing button icon and caption
    const fullScreentoolbarItem = info.viewerComponent.viewerToolbar._menuItems.items.find(x => x.id == 'FullScreen');
    fullScreentoolbarItem.cssIconClass = 'CloseFullScreen';
    fullScreentoolbarItem.caption = 'Close Full Screen';

    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    if (!this.isFullscreen()) {
      if (document.fullscreenEnabled ||
        (<any>document).webkitFullscreenEnabled ||
        (<any>document).mozFullScreenEnabled ||
        (<any>document).msFullscreenEnabled) {

        const overlay = document.querySelector('.cdk-overlay-container');
        const element = document.querySelector('.viewer-parent');
        console.log(element);
        element.appendChild(overlay);

        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if ((<any>element).webkitRequestFullscreen) {
          (<any>element).webkitRequestFullscreen();
        } else if ((<any>document).mozRequestFullScreen) {
          (<any>document).mozRequestFullScreen();
        } else if ((<any>document).msRequestFullscreen) {
          (<any>document).msRequestFullscreen();
        }
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
        // changing button icon and caption to default
        fullScreentoolbarItem.cssIconClass = 'FullScreen';
        fullScreentoolbarItem.caption = 'Full Screen';
      } else if ((<any>document).webkitExitFullscreen) {
        (<any>document).webkitExitFullscreen();
      } else if ((<any>document).mozCancelFullScreen) {
        (<any>document).mozCancelFullScreen();
      } else if ((<any>document).msExitFullscreen) {
        (<any>document).msExitFullscreen();
      }
    }
    // redraw map & gradient if displayed
    if (cell !== null) {
      this.overlayManagerService.redraw_mapped_canvas(cell);
      this.overlayManagerService.redraw_gradient_canvas(cell);
    }
  }

  isFullscreen() {
    return (<any>document).fullscreenElement
      || (<any>document).webkitFullscreenElement
      || (<any>document).mozFullScreenElement
      || (<any>document).webkitCurrentFullScreenElement;
  }

  initializeWindowLevelCustomSubMenu(cell: lt.Controls.Medical.Cell): any[] {
    try {
      let cellFrame: lt.Controls.Medical.Frame;
      let defaultWW;
      let defaultWC;

      let metadata;
      if (cell instanceof lt.Controls.Medical.Cell3D) {
        const cell3D: lt.Controls.Medical.Cell3D = <lt.Controls.Medical.Cell3D>cell;
        metadata = cell3D.JSON;
        defaultWW = cell3D.defaultWindowLevelWidth;
        defaultWC = cell3D.defaultWindowLevelCenter;
      } else {
        cellFrame = this.seriesManagerService.getActiveCellFrame();
        metadata = (<any>cellFrame).metadata;
        defaultWW = cellFrame.defaultWindowLevelWidth;
        defaultWC = cellFrame.defaultWindowLevelCenter;
      }

      const items: any = [];
      let presetValueInDataSet;
      if (cell instanceof lt.Controls.Medical.Cell3D) {
        const widthCenter3D = { W: (<any>cell).bone.width, C: (<any>cell).bone.center };
        items.add({ Text: 'Bone', Info: widthCenter3D });
      } else {
        const explantationTag = metadata[DicomTag.WindowCenterWidthExplanation];
        const modality = metadata[DicomTag.Modality].Value[0];

        // get the menu item text
        presetValueInDataSet = ((explantationTag != null) && explantationTag.Value && (explantationTag.Value.length > 0));
        if (presetValueInDataSet) {
          const length = explantationTag.Value.length;
          let index = 0;

          for (index = 0; index < length; index++) {
            const widthCenter = this.getWidthAndCenter((<any>cellFrame).metadata, index);
            items.add({ Text: this.camelize(explantationTag.Value[index]), Info: widthCenter });
          }
        }

        // adding the modality specific preset window level value.
        this.AddOtherPresetWindowLevelValues(modality, items);
      }

      // adding default one, to revert to the original window level value.
      if (!presetValueInDataSet) {
        items.insert(0, { Text: 'Auto', Info: { W: defaultWW, C: defaultWC } });
      }

      const customItem = { VoiType: VoiType.Undefined, Text: 'Custom', Info: null };
      // no preset window level value, so just show the custom window level dialog.
      if (items.length == 0) {
        (<any>cellFrame).currentCustomWindowlevel = customItem;
        return [];
      }


      // custom to show the custom window level dialog.
      items.add(customItem);

      const subMenuItems: any[] = [];
      items.forEach(item => {
        subMenuItems.push({
          'id': item.Text,
          'action': 'WindowLevelCustom',
          'caption': item.Text,
          'tooltip': item.Text,
          'type': 'button',
          'info': item.Info,
          'items': []
        });
      });

      return subMenuItems;
    } catch (error) {
    }
  }

  getWidthAndCenter(metadata, index) {
    let width;
    let center;
    let windowWidthTag = metadata[DicomTag.WindowWidth];
    let windowCenterTag = metadata[DicomTag.WindowCenter];

    if (windowWidthTag == null) {
      windowWidthTag = this.dicomHelperService.findTag(metadata, DicomTag.WindowWidth);
    }

    if (null != windowWidthTag && windowWidthTag.Value && windowWidthTag.Value.length > index) {
      width = windowWidthTag.Value[index] >> 0;
    } else {
      return null;
    }

    if (windowCenterTag == null) {
      windowCenterTag = this.dicomHelperService.findTag(metadata, DicomTag.WindowCenter);
    }

    if (null != windowCenterTag && windowCenterTag.Value && windowCenterTag.Value.length > 0) {
      center = windowCenterTag.Value[0] >> 0;
    } else {
      return null;
    }

    return { W: width, C: center };
  }

  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
      return index !== 0 ? letter.toLowerCase() : letter.toUpperCase();
    });
  }

  AddOtherPresetWindowLevelValues(modality, items) {
    const wlArray: Array<Object> = this.GetPresetWindowLevelValuesArray();

    for (let i = 0; i < wlArray.length; i++) {
      const item = wlArray[i];
      if (item['VR'] == modality) {
        items.add(item);
      }
    }
  }

  GetPresetWindowLevelValuesArray(): Array<Object> {
    const wlArray: Array<Object> = new Array<Object>();

    wlArray.push({ VR: 'CT', VoiType: VoiType.Lung, Text: this.camelize('LUNG'), Info: { W: 1500, C: -600 } });
    wlArray.push({
      VR: 'CT',
      VoiType: VoiType.Mediastinum,
      Text: this.camelize('MEDIASTINUM'),
      Info: { W: 640, C: 95 }
    });
    wlArray.push({
      VR: 'CT',
      VoiType: VoiType.AbdoPelvis,
      Text: this.camelize('ABDO PELVIS'),
      Info: { W: 400, C: 40 }
    });
    wlArray.push({ VR: 'CT', VoiType: VoiType.Liver, Text: this.camelize('LIVER'), Info: { W: 160, C: 70 } });
    wlArray.push({
      VR: 'CT',
      VoiType: VoiType.SoftTissue,
      Text: this.camelize('SOFT TISSUE'),
      Info: { W: 400, C: 40 }
    });
    wlArray.push({ VR: 'CT', VoiType: VoiType.Bone, Text: this.camelize('BONE'), Info: { W: 1500, C: 150 } });
    wlArray.push({ VR: 'CT', VoiType: VoiType.Brain, Text: this.camelize('BRAIN'), Info: { W: 150, C: 40 } });
    wlArray.push({ VR: 'CT', VoiType: VoiType.PostFossa, Text: this.camelize('POST FOSSA'), Info: { W: 250, C: 45 } });

    wlArray.push({ VR: 'MR', VoiType: VoiType.BrainT1, Text: this.camelize('Brain T1'), Info: { W: 500, C: 250 } });
    wlArray.push({ VR: 'MR', VoiType: VoiType.BrainT2, Text: this.camelize('Brain T2'), Info: { W: 350, C: 150 } });
    wlArray.push({ VR: 'MR', VoiType: VoiType.SagT2, Text: this.camelize('Sag T2'), Info: { W: 300, C: 150 } });
    wlArray.push({ VR: 'MR', VoiType: VoiType.HeadNeck, Text: this.camelize('Head / Neck'), Info: { W: 500, C: 250 } });
    wlArray.push({ VR: 'MR', VoiType: VoiType.Spine, Text: this.camelize('Spine'), Info: { W: 500, C: 250 } });
    wlArray.push({
      VR: 'MR',
      VoiType: VoiType.AbdomenPelvisT1,
      Text: this.camelize('Abdomen / Pelvis T1'),
      Info: { W: 600, C: 180 }
    });
    wlArray.push({
      VR: 'MR',
      VoiType: VoiType.AbdomenPelvisT2,
      Text: this.camelize('Abdomen/ Pelvis T2'),
      Info: { W: 850, C: 150 }
    });

    wlArray.push({
      VR: 'US',
      VoiType: VoiType.LowContrast,
      Text: this.camelize('Low Contrast'),
      Info: { W: 190, C: 80 }
    });
    wlArray.push({
      VR: 'US',
      VoiType: VoiType.MediumContrast,
      Text: this.camelize('Medium Contrast'),
      Info: { W: 160, C: 70 }
    });
    wlArray.push({
      VR: 'US',
      VoiType: VoiType.HighContrast,
      Text: this.camelize('High Contrast'),
      Info: { W: 120, C: 60 }
    });

    return wlArray;
  }

  onWindowLevelCustom(info) {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const cellFrame: lt.Controls.Medical.Frame = this.seriesManagerService.getActiveCellFrame();
    if (info.W === undefined && info.C === undefined) {
      this.showCustomWindowlevelDialog(cell, cellFrame);
    } else {
      if (cell instanceof lt.Controls.Medical.Cell3D) {
        const cell3D: lt.Controls.Medical.Cell3D = <lt.Controls.Medical.Cell3D>cell;
        cell3D.information.windowWidth = info.W;
        cell3D.information.windowCenter = info.C;
        cell3D.updateWindowLevelValues();
      } else {
        if (cell.linked) {
          this.seriesManagerService.enumerateFrames(cell, (frame) => {
            frame.setWindowLevel(info.W, info.C);
          });
        } else {
          cellFrame.setWindowLevel(info.W, info.C);
        }
      }
    }
  }

  showCustomWindowlevelDialog(cell, cellFrame) {
    const settings: any = {};

    if (cell instanceof lt.Controls.Medical.Cell3D) {
      const cell3D: lt.Controls.Medical.Cell3D = <lt.Controls.Medical.Cell3D>cell;
      settings.width = cell3D.information.windowWidth;
      settings.center = cell3D.information.windowCenter;
      settings.originalWindowLevelCenter = cell3D.defaultWindowLevelCenter;
      settings.originalWindowLevelWidth = cell3D.defaultWindowLevelWidth;
    } else {
      const frame: lt.Controls.Medical.Frame = this.seriesManagerService.getActiveCellFrame();
      const renderer = frame.get_wlRenderer();
      if (renderer) {
        settings.width = renderer.get_windowLevelWidth();
        settings.center = renderer.get_windowLevelCenter();
        settings.originalWindowLevelCenter = renderer.originalWindowLevelCenter;
        settings.originalWindowLevelWidth = renderer.originalWindowLevelWidth;
      } else {
        return;
      }
    }

    const dialogRef = this.dialog.open(WindowLevelDialogComponent, {
      height: '230px',
      width: '245px',
      disableClose: true,
      data: { W: settings.width, C: settings.center }
    });

    dialogRef.afterClosed().subscribe((info) => {
      if (info) {
        if (cell instanceof lt.Controls.Medical.Cell3D) {
          const cell3D: lt.Controls.Medical.Cell3D = <lt.Controls.Medical.Cell3D>cell;
          cell3D.information.windowWidth = info.W;
          cell3D.information.windowCenter = info.C;
          cell3D.updateWindowLevelValues();
        } else {
          if (cell.linked) {
            this.seriesManagerService.enumerateFrames(cell, (frame) => {
              frame.setWindowLevel(info.W, info.C);
            });
          } else {
            cellFrame.setWindowLevel(info.W, info.C);
          }
        }
      }
    });
  }

  onShowDicom() {
    this.dataSourceAr = [];
    let cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const viewer = cell.viewer;
    let cellFrame = null;
    let cellIndex = 0;
    let oneChecked = false;
    let labelList: string[] = [];
    const cellCount = viewer.layout.items.count;
    const dataDicomTags = {
      dataSources: [],
      dicomLabels: []
    };
    for (cellIndex = 0; cellIndex < cellCount; cellIndex++) {
      cell = viewer.layout.items.get_item(cellIndex);
      if (cell.tickBoxes[0].checked == true) {
        oneChecked = true;
        cellFrame = null;
        this.rowData = [];
        if (cell instanceof lt.Controls.Medical.Cell3D) {
          const cell3D: lt.Controls.Medical.Cell3D = <lt.Controls.Medical.Cell3D>cell;
          cell = cell3D.referenceCell;
        }
        cellFrame = this.seriesManagerService.getActiveItemForCell(cell).attachedFrame;
        this.rowData = this.buildDicomData(cellFrame.metadata, this.rowData);
        this.rowData.forEach((tagRow) => {
          if (tagRow['data']['name'] === 'Series Description') {
            labelList[labelList.length++] = tagRow['data']['value'];
          }
        });
        this.dataSourceAr[this.dataSourceAr.length++] = <any>new MatTableDataSource(this.rowData);
      }
    }
    if (!oneChecked) {
      this.dataSourceAr = [];
      this.rowData = [];
      labelList = [];
      cell = this.seriesManagerService.getActiveCell();
      if (cell instanceof lt.Controls.Medical.Cell3D) {
        const cell3D: lt.Controls.Medical.Cell3D = <lt.Controls.Medical.Cell3D>cell;
        cell = cell3D.referenceCell;
      }
      cellFrame = this.seriesManagerService.getActiveItemForCell(cell).attachedFrame;
      this.rowData = this.buildDicomData(cellFrame.metadata, this.rowData);
      this.rowData.forEach((tagRow) => {
        if (tagRow['data']['name'] === 'Series Description') {
          labelList[0] = tagRow['data']['value'];
        }
      });
      this.dataSourceAr[0] = <any>new MatTableDataSource(this.rowData);
    }
    dataDicomTags.dataSources = this.dataSourceAr;
    dataDicomTags.dicomLabels = labelList;
    let dialogRef: any;
    // console.log(dataDicomTags);
    switch (this.dataSourceAr.length) {
      case 1:
        dialogRef = this.dialog.open(DicomInformationDialogComponent, {
          height: '570px',
          width: '530px',
          disableClose: true,
          data: dataDicomTags
        });
        break;
      case 2:
        dialogRef = this.dialog.open(DicomInformationDialogComponent, {
          height: '630px',
          width: '660px',
          disableClose: true,
          data: dataDicomTags
        });
        break;
      case 3:
        dialogRef = this.dialog.open(DicomInformationDialogComponent, {
          height: '680px',
          width: '780px',
          disableClose: true,
          data: dataDicomTags
        });
        break;
      default:
        dialogRef = this.dialog.open(DicomInformationDialogComponent, {
          height: '570px',
          width: '630px',
          disableClose: true,
          data: dataDicomTags
        });
        break;
    }
  }

  private splitCamelCaseToString(s) {
    if (!s) {
      return '';
    }

    return s.replace
    (/(^[a-z]+)|[0-9]+|[A-Z][a-z]+|[A-Z]+(?=[A-Z][a-z]|[0-9])/g
      , function (match, first) {
        if (first) {
          match = match[0].toUpperCase() + match.substr(1);
        }
        return match + ' ';
      }
    ).trim();
  }

  private buildDicomData(dicom, data: DicomTagRow[]): DicomTagRow[] {
    // tslint:disable-next-line: forin
    for (const key in dicom) {
      const dcmData = new DicomData(this.splitCamelCaseToString(dicom[key].keyword), key);
      const row = new DicomTagRow(dcmData);

      const cell = this.seriesManagerService.getActiveCell();
      const { shouldShowDICOM } = this.seriesManagerService.getSeriesInfo(cell);

      const blindedTags = ['acquisition date', 'study date'];
      const isHiding = !shouldShowDICOM;
      let isBlinded = false;

      if (!dcmData.name) {
        dcmData.name = 'Unknown';
      }

      if (isHiding && blindedTags.some(item => item === dcmData.name.toLowerCase())) {
        isBlinded = true;
      }

      dcmData.vr = dicom[key].vr;
      if (dicom[key].vr == 'PN') {
        dcmData.value = this.dicomHelperService.getPatientNameFromTag(dicom[key]);
      } else if (dicom[key].vr != 'SQ') {
        let value: string = this.dicomHelperService.getConvertValue(dicom[key]);

        if (dicom[key].vr == 'DA') {
          if (value) {
            value = this.dicomHelperService.parseDicomDate(value);
          }
        }

        if (dicom[key].vr == 'TM') {
          if (value) {
            value = this.dicomHelperService.parseDicomTime(value);
          }
        }

        dcmData.value = isBlinded ? 'Blinded' : value;
      } else {
        if (dicom[key].Value && dicom[key].Value.length > 0) {
          const length: number = dicom[key].Value.length;

          row.group = true;
          row.expanded = false;
          for (let index = 0; index < length; index++) {
            const dcmData = new DicomData('Item', 'FFFEE000');
            const itemRow = new DicomTagRow(dcmData);

            itemRow.group = true;
            itemRow.expanded = false;
            row.children.push(itemRow);
            this.buildDicomData(dicom[key].Value[index], itemRow.children);
          }
        }
      }

      data.push(row);
    }

    return data;
  }

  onCalibrateRuler() {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const dialogRef = this.dialog.open(CalibrateRulerDialogComponent, {
      height: '270px',
      width: '250px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((calibration) => {
      if (calibration) {
        const automation: lt.Annotations.Automation.AnnAutomation = cell.get_automation();
        if (isNaN(calibration.length)) {
          alert('Invalid Length');
        } else {
          const annObject: lt.Annotations.Engine.AnnPolyRulerObject = <lt.Annotations.Engine.AnnPolyRulerObject>automation.get_currentEditObject();
          if (annObject) {
            const unit: number = parseInt(calibration.unit, 10);

            if (!calibration.applyToAll) {
              const container: lt.Annotations.Engine.AnnContainer = automation.get_containers().item(this.seriesManagerService.getActiveSubCellIndex(cell));

              container.get_mapper().calibrate(annObject.getRulerLength(1), 0, lt.LeadLengthD.create(calibration.length), unit);
              annObject.measurementUnit = unit;
            } else {
              let length = cell.get_frames().count;

              while (length--) {
                const container: lt.Annotations.Engine.AnnContainer = automation.get_containers().item(length);

                if (container) {
                  container.get_mapper().calibrate(annObject.getRulerLength(1), 0, lt.LeadLengthD.create(calibration.length), unit);
                  container.children.toArray().forEach((item: lt.Annotations.Engine.AnnObject, index: number) => {
                    if (item instanceof lt.Annotations.Engine.AnnPolyRulerObject) {
                      (<lt.Annotations.Engine.AnnPolyRulerObject>item).measurementUnit = unit;
                    }
                  });
                }
              }
            }
          } else {
            this.toastOptions.msg = 'Please select a ruler annotation to calibrate';
            this.toastyService.info(this.toastOptions);
          }
        }

        automation.get_automationControl().automationInvalidate(lt.LeadRectD.empty);
      }
    });
  }

  generateMPRCells(viewer, cell: lt.Controls.Medical.Cell, cellMPRTypes, loadingDiv: any, serverSideRendering) {
    if (cell instanceof lt.Controls.Medical.Cell3D) {
      cell = (cell).referenceCell;
    }
    const mprCell1 = this.createMPRCell(viewer, cell, cellMPRTypes[0], serverSideRendering);
    this.copyOverlays(mprCell1, cell);
    const mprCell2 = this.createMPRCell(viewer, cell, cellMPRTypes[1], serverSideRendering);
    this.copyOverlays(mprCell2, cell);

    // set tickbox checked to ensure synch action with the main cell
    cell.tickBoxes[0].checked = true;

    this.enumerateCell(viewer, (cell) => {
      cell.drawCrossHairLines = true;
    });
    loadingDiv = cell.div.getElementsByClassName('loader' + cell.div.id)[0];
    if (loadingDiv != null) {
      cell.div.removeChild(loadingDiv);
      loadingDiv.id = '';
    }
    cell.viewer.layout.selectedItem = cell;

    // set the main cell as active
    this.seriesManagerService.setActiveCell(cell.divID);
    this.runCommand(this.LastCommand.Action, this.LastCommand.ButtonID);
  }

  enumerateCell(medicalViewer, cellFunction) {
    let index = 0;
    const length = medicalViewer.layout.get_items().get_count();
    let cell;
    for (index = 0; index < length; index++) {
      cell = medicalViewer.layout.get_items().get_item(index);
      if (cell != null) {
        cellFunction(cell);
      }
    }
  }

  addCloseBtn(viewer, mprCell, cell, setActive = true, shouldHide?) {
    const cell_div = mprCell.get_div();
    const closeBtn = document.createElement('div');
    closeBtn.className = 'viewerCloseBtn';
    closeBtn.addEventListener('click', () => {
      this.removeSeriesCellSubj.next(mprCell);
    });
    cell_div.appendChild(closeBtn);
  }

  copyOverlays(mprCell, cell) {
    const length = cell.get_overlays().get_count();
    let index = 0;
    let overlay;
    let newOverlay;
    for (index = 0; index < length; index++) {
      overlay = cell.get_overlays().get_item(index);

      if (overlay.alignment == lt.Controls.Medical.OverlayAlignment.topRight) {
        continue;
      }

      newOverlay = new lt.Controls.Medical.OverlayText();
      if (overlay.type == lt.Controls.Medical.OverlayTextType.userData) {
        newOverlay.text = overlay.text;
      }
      newOverlay.type = overlay.type;
      newOverlay.positionIndex = overlay.positionIndex;
      newOverlay.weight = overlay.weight;
      newOverlay.color = overlay.color;
      newOverlay.alignment = overlay.alignment;

      if (this.overlayManagerService.overlayId2names[cell.divID]
        && this.overlayManagerService.overlayId2names[cell.divID][index]) {
        const name = this.overlayManagerService.overlayId2names[cell.divID][index];
        const newIndex = mprCell.get_overlays().get_count();
        if (this.overlayManagerService.name2OverlayIds[mprCell.divID]) {
          this.overlayManagerService.name2OverlayIds[mprCell.divID][name] = newIndex;
        } else {
          this.overlayManagerService.name2OverlayIds[mprCell.divID] = { [name]: newIndex };
        }

        const currentFrame = mprCell.get_frames().get_item(mprCell.get_currentOffset());
        if (name == 'image-size') {
          newOverlay.text = 'Image Size,px: (' + String(currentFrame.get_height()) + ',' + String(currentFrame.get_width()) + ')';
        }
        if (name == 'image-spacing') {
          newOverlay.text = 'Spacing: (' + currentFrame.get_columnSpacing().toFixed(3) + ',' + currentFrame.get_rowSpacing().toFixed(3) + ')';
        }
      }
      mprCell.get_overlays().add(newOverlay);
    }

    // enable probe tool action on mprcells
    this.overlayManagerService.actionProbeTool(mprCell);

    // add additional overlays for mprCells
    newOverlay = new lt.Controls.Medical.OverlayText();
    newOverlay.type = lt.Controls.Medical.OverlayTextType.frameNumber;
    newOverlay.positionIndex = 0;
    newOverlay.weight = overlay.weight;
    newOverlay.color = overlay.color;
    newOverlay.alignment = lt.Controls.Medical.OverlayAlignment.topLeft;
    mprCell.get_overlays().add(newOverlay);
  }

  reArrangeCellBasedOnMPRType(mprCell, mprType) {
    switch (mprType) {
      case lt.Controls.Medical.CellMPRType.sagittal:
        mprCell.rowPosition = 1;
        mprCell.columnsPosition = -1;
        break;
      case lt.Controls.Medical.CellMPRType.coronal:
        mprCell.rowPosition = 1;
        mprCell.columnsPosition = -1;
        break;
      case lt.Controls.Medical.CellMPRType.axial:
        mprCell.rowPosition = 0;
        mprCell.columnsPosition = -1;
        break;
    }
  }

  find3DObject(medicalViewer, cell) {
    let index = 0;
    const length = medicalViewer.layout.get_items().get_count();
    // let cell;
    for (index = 0; index < length; index++) {
      const currentCell = medicalViewer.layout.get_items().get_item(index);
      if (currentCell instanceof lt.Controls.Medical.Cell3D) {
        if ((this.getCell3DProperty(currentCell, 'stackInstanceUID') == cell.stackInstanceUID)
          && (currentCell.seriesInstanceUID == cell.seriesInstanceUID)) {
          // if ((currentCell.seriesInstanceUID == cell.seriesInstanceUID))
          return currentCell;
        }
      }
    }
    return null;
  }

  getMPRFramePosition(cell, mprType) {
    if (cell.mprType == mprType) {
      return cell.currentOffset;
    }
    if (cell.derivatives != null) {
      if (cell.derivatives.get_item(0) != null) {
        if (cell.derivatives.get_item(0).mprType == mprType) {
          return cell.derivatives.get_item(0).currentOffset;
        }
      }
      if (cell.derivatives.get_item(1) != null) {
        if (cell.derivatives.get_item(1).mprType == mprType) {
          return cell.derivatives.get_item(1).currentOffset;
        }
      }
    }
    return -1;
  }

  connectMPRWith3DObject(cell, parentCell, viewer) {
    cell.add_currentOffsetChanged(() => {
      const cell3D = this.find3DObject(viewer, parentCell);
      if (cell3D == null) {
        return;
      }
      cell3D.beginUpdate();
      cell3D.MPR.axialPosition = this.getMPRFramePosition(parentCell, lt.Controls.Medical.CellMPRType.axial);
      cell3D.MPR.sagittalPosition = this.getMPRFramePosition(parentCell, lt.Controls.Medical.CellMPRType.sagittal);
      cell3D.MPR.coronalPosition = this.getMPRFramePosition(parentCell, lt.Controls.Medical.CellMPRType.coronal);
      cell3D.endUpdate();
    });
  }

  cellMousedown(sender, e, _this?) {
    const seriesInstanceUID = sender.get_seriesInstanceUID();
    let click = false;
    if (e != null && e != 'undefined') {
      click = true;
    }
    if (sender != _this.seriesManagerService.getActiveCell()) {
      _this.seriesManagerService.setActiveCell(sender.divID);
    }
    // if (click) {
    //     this.cellClicked({ seriesInstanceUID: seriesInstanceUID });
    // }
    if (sender.getCommandInteractiveMode) {
      const lineProfile = sender.getCommandInteractiveMode(MedicalViewerAction.LineProfile);
      lineProfile.refresh(sender);
    }
  }

  stackChanged(sender, e, _this?) {
    const index = sender.get_currentOffset();
    let frame = null;
    if (index != -1) {
      frame = sender.get_frames().item(index);
    }
    // scope.stackChanged({ sender: sender, e: e });
    const cell = _this.seriesManagerService.getActiveCell();
    if (cell) {
      const current = cell.currentOffset;
      _this.currentPosition = current + 1;
    }
    // eventService.publish(EventNames.StackChanged, { viewer: viewer, cell: sender, frame: frame });
  }

  cinePlayerActive(viewer) {
    let index;
    const length = viewer.layout.get_items().get_count();
    for (index = 0; index < length; index++) {
      const currentCell = viewer.layout.get_items().get_item(index);
      if (currentCell.cinePlayer != null && currentCell.cinePlayer != 'undefined' && currentCell.cinePlayer.isPlaying) {
        return true;
      }
    }
    return false;
  }

  tickBoxClicked(sender, e, _this?) {
    const parentCell = sender.parent;
    const viewer = parentCell.viewer;
    let index;
    const length = parentCell.viewer.layout.get_items().get_count();
    for (index = 0; index < length; index++) {
      const cell = viewer.layout.get_items().get_item(index);
      if (_this.cinePlayerActive(viewer)) {
        if (cell.tickBoxes[0].checked) {
          if (!cell.cinePlayer.isPlaying) {
            cell.cinePlayer.play();
          }
        } else {
          if (cell.cinePlayer.isPlaying) {
            cell.cinePlayer.stop();
          }
        }
      }
      for (const commandItem in cell.commands) {
        const command = cell.commands[commandItem];
        command.linked.clear();
        let itemIndex;
        const itemLength = parentCell.viewer.layout.get_items().get_count();
        for (itemIndex = 0; itemIndex < itemLength; itemIndex++) {
          const itemCell = parentCell.viewer.layout.get_items().get_item(itemIndex);
          if (itemCell.tickBoxes && itemCell.tickBoxes.length > 0 && itemCell.tickBoxes[0].checked) {
            command.linked.add(itemCell);
          }
        }
      }
    }
  }

  initializeCell(viewer, cell, series, position?, rowPosition?, columnPosition?) {
    const id = cell.get_divID();
    cell.beginUpdate();
    cell.set_unselectedBorderColor('#000000');
    cell.set_selectedSubCellBorderColor('#000000');
    cell.set_selectedBorderColor('rgb(255, 152, 0)');
    cell.set_highlightedSubCellBorderColor('rgb(255, 152, 0)');
    cell.set_showFrameBorder(true);
    cell.set_seriesInstanceUID(series.seriesInstanceUID);
    cell.get_progress().setColor(255, 152, 0);
    cell.add_mouseDown((sender, e) => this.cellMousedown(sender, e, this));
    // cell.add_cellClicked(cell_clicked);
    cell.endUpdate();
    // onLoadObjectscell.add_currentOffsetChanged((sender, e) => this.stackChanged(sender, e, this));
    cell.set_linked(series.link);
    cell.tickBoxes[0].add_tickBoxClicked((sender, e) => this.tickBoxClicked(sender, e, this));
    cell.selectedItems.add_collectionChanged((sender, e) => {
      if (e.action == lt.NotifyLeadCollectionChangedAction.add) {
        if (e.newItems.length > 0) {
          const subCell = e.newItems[0];
          if (subCell != null && subCell != 'undefined') {
            const frame = subCell.get_attachedFrame();
            // eventService.publish(EventNames.NewSubCellSelected, { subCell: subCell, frame: frame });
          }
        }
      }
    });
    // cell.automation.add_draw(function (sender, e) {
    //   if (cell.selectedItem != null) {
    //       switch (this.LastCommand.Action) {
    //           case MedicalViewerAction.ShutterRect:
    //           case MedicalViewerAction.ShutterEllipse:
    //           case MedicalViewerAction.ShutterFreeHand:
    //           case MedicalViewerAction.ShutterPolygon:
    //               if (e.object.id == lt.Annotations.Engine.AnnObject.selectObjectId)
    //                   return;
    //               var frame = cell.selectedItem.attachedFrame;
    //               if (e.operationStatus == lt.Annotations.Engine.AnnDesignerOperationStatus.end) {
    //                   this.Utils.clearAllShutter(frame);
    //                   frame.get_shutter().get_objects().add(e.object);
    //                   frame.get_shutter().fillStyle = "rgba(0, 0, 0, 1)";
    //               }
    //               break;
    //       }
    //   }
    // });

    this.setupDraggable(cell);
    if (position) {
      cell.set_position(position);
    } else if (series.dislaySetNumber) {
      cell.set_position(series.dislaySetNumber);
    }
    if (rowPosition) {
      cell.set_rowPosition(rowPosition);
    }
    if (columnPosition) {
      cell.set_columnsPosition(columnPosition);
    }
    viewer.layout.get_items().add(cell);
    // cell.set_selected(true);
    try {
      this.intializeActions(cell);
    } catch (error) {

    }
    this.seriesManagerService.addSeriesCell(cell);
    this.seriesManagerService.setActiveCell(cell.divID);
    $('#' + id).attr('seriesInstanceUID', cell.get_seriesInstanceUID());
    $('#' + id).attr('seriesID', series.id);
  }

  setupDraggable(cell) {
    const dragMode = new lt.Controls.ImageViewerDragInteractiveMode;
    cell.imageViewer.interactiveModes.beginUpdate();
    cell.imageViewer.interactiveModes.add(dragMode);
    cell.imageViewer.interactiveModes.endUpdate();
  }

  createMPRCell(viewer: lt.Controls.Medical.MedicalViewer, cell, mprType, serverSideRendering) {
    let cellName = 'MedicalCell' + Date.now();
    const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
    let seriesInstanceUID = cell.get_seriesInstanceUID();
    switch (mprType) {
      case lt.Controls.Medical.CellMPRType.sagittal:
        seriesInstanceUID += '_Sagittal';
        cellName = cell.divID + '_Sagittal';
        break;
      case lt.Controls.Medical.CellMPRType.coronal:
        seriesInstanceUID += '_Coronal';
        cellName = cell.divID + '_Coronal';
        break;
      case lt.Controls.Medical.CellMPRType.axial:
        seriesInstanceUID += '_Axial';
        cellName = cell.divID + '_Axial';
        break;
    }
    const newSeriesInfo = jQuery.extend(true, {}, seriesInfo);
    newSeriesInfo.seriesUID = seriesInstanceUID;
    this.seriesManagerService.setSeriesInfo(cell, newSeriesInfo);
    this.seriesManagerService.setActiveCell(cell.divID);

    const mprCell = new lt.Controls.Medical.MPRCell(cell, mprType, cellName, serverSideRendering);
    mprCell.add_frameRequested((sender, args) => {
      // console.log("sender: ", sender);
      // console.log("args: ", args);
      const cell3D = cell.cell3D;
      if (cell3D.object3D.volumeReady) {
        args.image.src = this.objectRetrieveService.getMPRFrame(cell3D.object3D.id, args.mprType, args.index);
      }
      // Allow cross-origin since the webclient and LT server have different domains
      args.image.crossOrigin = 'Anonymous';
    });
    mprCell.tickBoxes[0].visible = true;
    mprCell.tickBoxes[0].checked = true;
    this.reArrangeCellBasedOnMPRType(mprCell, mprType);
    mprCell.frameOfReferenceUID = cell.frameOfReferenceUID;
    this.connectMPRWith3DObject(mprCell, cell, viewer);
    mprCell.overlayTextVisible = cell.overlayTextVisible;

    const castViewer = viewer;
    const series = new MedicalViewerSeries(newSeriesInfo.InstanceUID, '', 1, 1);
    series.seriesInstanceUID = newSeriesInfo.seriesUID;
    series.link = cell.get_linked();
    this.initializeCell(castViewer, mprCell, series);
    this.addCloseBtn(viewer, mprCell, cell);

    this.seriesManagerService.addSeriesCell(mprCell);
    this.seriesManagerService.setSeriesInfo(mprCell, newSeriesInfo);

    // add container event handler to mprCell
    const info = { viewerComponent: this.viewerComponent };
    this.addContainerEvents(mprCell, info);

    // add custom instances for mprCell
    this.utils.buildMPRCellInstance(mprCell);

    // CommandManager.RunCommand(mprCell, CommandManager.LastCommand.Action, CommandManager.LastCommand.ButtonID);
    const assigned: boolean = this.assignAction(mprCell, this.LastCommand.Action, lt.Controls.MouseButtons.left, this.LastCommand.ButtonID);
    return mprCell;
  }

  progressCompleted(viewer, cell, cellMPRTypes, serverSideRendering) {
    const loadingDiv = document.createElement('div') as HTMLDivElement;
    loadingDiv.id = 'loader';
    loadingDiv.className = 'loader' + cell.div.id;
    cell.div.appendChild(loadingDiv);
    if (lt.LTHelper.device == lt.LTDevice.desktop && lt.LTHelper.browser != lt.LTBrowser.internetExplorer && !serverSideRendering) {
      if (cell.frames.count > 200) {
        cell.create3D = true;
      } else {
        this.generateMPRCells(viewer, cell, cellMPRTypes, loadingDiv, serverSideRendering);
      }
    } else {
      this.generateMPRCells(viewer, cell, cellMPRTypes, loadingDiv, serverSideRendering);
    }

    cell.add_data3DGenerated((sender, e) => {
      this.generateMPRCells(viewer, cell, cellMPRTypes, loadingDiv, serverSideRendering);
    });
  }

  alreadyHaveA3Dobject(cell, volumeType) {
    const viewer = cell.viewer;
    let index = 0;
    const length = viewer.layout.items.count;
    let cell3D;
    for (index = 0; index < length; index++) {
      if (viewer.layout.items.get_item(index) instanceof lt.Controls.Medical.Cell3D) {
        cell3D = viewer.layout.items.get_item(index);
        if (cell3D.object3D.progress === 100) {
          cell3D.volumeType = volumeType;
        }
        if (cell3D.referenceCell === cell) {
          return true;
        }
      }
    }
    return false;
  }

  getRealId(fullID: string) {
    const index = fullID.lastIndexOf('_');
    const stripEngineID = (index == -1 ? fullID : fullID.substr(0, index));
    // let stripEngineID = (index == -1 ? fullID : fullID.substr(index+1));
    return stripEngineID;
  }

  handleEngine3DData(sender, args, query, id, sopInstanceUID) {
    const engine = sender;
    const renderingMethod = 0; // 0 or 1;
    let result = null;
    switch (args.type) {
      case lt.Controls.Medical.Requested3DDataType.create3DObject:
        result = this.queryArchiveService.start3DObject(query, id, renderingMethod, sopInstanceUID).toPromise().then((data) => {
          // console.log("start3DObject> id: ", id);
          // console.log("start3DObject> data: ", data);
          if (data !== 'Success') {
            engine.status = lt.Controls.Medical.Object3DStatus.error;
            engine.errorMessage = data.Message ? data.Message : data;
          }
        });
        break;
      case lt.Controls.Medical.Requested3DDataType.creationProgress:
        result = this.queryArchiveService.checkProgress(id).toPromise().then(data => {
          if (isNaN(data)) {
            engine.status = lt.Controls.Medical.Object3DStatus.error;
            engine.errorMessage = data.Message ? data.Message : data;
          } else if (+data === 100) {
            engine.progress = +data;
            this.MPRObjectSubj$.next(id);
          }
        });
        break;
      case lt.Controls.Medical.Requested3DDataType.keepServerObjectAlive:
        result = this.queryArchiveService.keepAlive(id);
        break;
      case lt.Controls.Medical.Requested3DDataType.delete3DObject:
        result = this.queryArchiveService.close3DImage(id);
        break;
    }

    return result;
  }

  handleRequest3DData(cell3D, args) {
    switch (args.type) {
      case lt.Controls.Medical.Requested3DDataType.render:
        cell3D.image.src = args.JSON;
        break;
      case lt.Controls.Medical.Requested3DDataType.delete3DObject: {
        const cell = cell3D.get_referenceCell();
        if (cell) {
          cell.divID = UUID.genV4().toString();
        }
      }
        break;
      case lt.Controls.Medical.Requested3DDataType.update3DSettings:
        this.queryArchiveService.update3DSettings(args.JSON, cell3D.object3D.id).toPromise().then((data) => {
          cell3D.refresh();
        });
        break;
      case lt.Controls.Medical.Requested3DDataType.get3DInfo:
        this.queryArchiveService.get3DSettings(args.JSON, cell3D.object3D.id).toPromise().then((data) => {
          const info = new lt.Controls.Medical.WindowLevelInfomation();
          try {
            const json = JSON.parse(data);
            info.windowWidth = json['WindowWidth'];
            info.windowCenter = json['WindowCenter'];
            info.minValue = json['MinimumValue'];
            info.maxValue = json['MaximumValue'];
            info.autoScaleSlope = cell3D.information.autoScaleSlope;
            info.autoScaleIntercept = cell3D.information.autoScaleIntercept;
            cell3D.bone = {};
            cell3D.bone.width = json['BoneWindowWidth'];
            cell3D.bone.center = json['BoneWindowCenter'];
            cell3D.information = info;
            if (this.dicomHelperService.getTagText(cell3D.JSON[DicomTag.Modality]) == 'CT') {
              info.windowWidth = json['BoneWindowWidth'];
              info.windowCenter = json['BoneWindowCenter'];
            }
            cell3D.information = info;
          } catch (e) {
          }
        });
        break;
    }
  }

  addnewEngine(id, query, sopInstanceUID) {
    const engineID = this.getRealId(id);
    if (!this._engineDictionary[engineID]) {
      const engine = new lt.Controls.Medical.Object3DEngine(engineID);
      this._engineDictionary[engineID] = engine;
      engine.add_request3DData((sender, args) => {
        return this.handleEngine3DData(sender, args, query, engineID, sopInstanceUID);
      });
    }
    return this._engineDictionary[engineID];
  }

  setCell3DProperty(cell3D, property, value) {
    cell3D[property] = value;
    return cell3D;
  }

  getCell3DProperty(cell3D, property) {
    return cell3D[property];
  }

  create3DObject(volumeType, is3DView: boolean, isLayoutUpdate = true, cell = this.seriesManagerService.getActiveCell()) {
    const query = new QueryOptions();
    query.SeriesOptions.SeriesInstanceUID = cell.seriesInstanceUID;

    // change the volume type if the volume exist.
    if (cell instanceof lt.Controls.Medical.Cell3D) {
      const cell3D = cell;
      if (cell3D.object3D.progress == 100) {
        cell3D.volumeType = volumeType;
      }
      return true;
    }
    // if the cell has a 3d volume, then just return.
    if (this.alreadyHaveA3Dobject(cell, volumeType)) {
      return;
    }

    const viewer = cell.viewer;
    // increase the viewer cell count to show the 3d volume.
    if ((viewer.layout.items.count + viewer.emptyDivs.items.count) < 3 && is3DView && isLayoutUpdate) {
      viewer.cellsArrangement = lt.Controls.Medical.CellsArrangement.grid;
      viewer.layout.beginUpdate();
      viewer.gridLayout.rows = 2;
      viewer.gridLayout.columns = 2;
      viewer.layout.endUpdate();
    }

    if (viewer.exploded) {
      viewer.exploded = false;
    }

    // TODO: remove this
    cell.Id3D = cell.divID + '_3D';
    let frame = cell.frames.get_item(0);
    const sopInstanceUID = frame.Instance.SOPInstanceUID;
    let cell3D = new lt.Controls.Medical.Cell3D(viewer, cell.Id3D);
    cell3D.object3D = this.addnewEngine(cell.divID, query, sopInstanceUID);
    // cell3D.add_volumeTypeChanged((sender, args) => {
    //     //eventService.publish(EventNames.ActiveSeriesChanged, { seriesInstanceUID: cell.seriesInstanceUID, id: cell.divID });
    // });
    cell3D.referenceCell = cell;
    cell.cell3D = cell3D;

    // let renderingMethod = 0; // 0: Hardware Software, 1: others
    cell.object3D = cell3D.object3D;
    cell3D.object3D.add_request3DData((sender, args) => {
      this.handleRequest3DData(cell3D, args);
    });
    cell3D.object3D.add_statusChanged((sender, args) => {
      switch (args.status) {
        case lt.Controls.Medical.Object3DStatus.error:
          const medicalViewer = cell3D.viewer;
          medicalViewer.layout.get_items().remove(cell3D);
          this.seriesManagerService.removeCell(cell3D);
          cell3D.dispose();
          alert(args.message);
          break;
        case lt.Controls.Medical.Object3DStatus.ready:
          if (cell3D.object3D.progress == 100) {
            cell3D.URL = this.queryArchiveService.get3DImage();
            let inputJson = {};
            inputJson['BoneWindowWidth'] = '';
            inputJson['BoneWindowCenter'] = '';
            if (this.dicomHelperService.getTagText(frame.JSON[DicomTag.Modality]) === 'CT') {
              this.queryArchiveService.get3DSettings(inputJson, cell3D.object3D.id).toPromise().then((data) => {
                const info = new lt.Controls.Medical.WindowLevelInfomation();
                inputJson = JSON.parse(data);
                const json = {};
                json['WindowWidth'] = inputJson['BoneWindowWidth'];
                json['WindowCenter'] = inputJson['BoneWindowCenter'];
                this.queryArchiveService.update3DSettings(json, cell3D.object3D.id).toPromise().then((data) => {
                  cell3D.beginUpdate();
                  cell3D.volumeType = volumeType;
                  cell3D.endUpdate();
                });
              });
            } else {
              cell3D.beginUpdate();
              cell3D.volumeType = volumeType;
              cell3D.endUpdate();
            }
          }
      }
    });
    frame = cell.frames.get_item(0);
    const metadata = frame.metadata;
    cell3D.JSON = metadata;

    cell3D = this.setCell3DProperty(cell3D, 'stackInstanceUID', cell.stackInstanceUID);
    cell3D.seriesInstanceUID = cell.seriesInstanceUID;
    cell3D.studyInstanceUID = '3D';
    cell3D.set_unselectedBorderColor('#000000');
    cell3D.set_selectedSubCellBorderColor('#000000');
    cell3D.set_selectedBorderColor('rgb(255, 152, 0)');
    cell3D.set_highlightedSubCellBorderColor('rgb(255, 152, 0)');
    cell3D.set_showFrameBorder(true);

    const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
    this.seriesManagerService.setSeriesInfo(cell3D, seriesInfo);
    this.overlayManagerService.setCellOverlays(cell3D, metadata, false);

    // cell3D.get_progress().setColor(255, 152, 0);
    if (!is3DView) {
      cell3D.set_visibility(false);
    }

    if (is3DView && isLayoutUpdate) {
      viewer.layout.get_items().add(cell3D);
    }


    const id = cell3D.get_divID();
    this.seriesManagerService.addSeriesCell(cell3D);
    $('#' + id).attr('seriesInstanceUID', cell3D.get_seriesInstanceUID());
    cell3D.start('');
    this.addCloseBtn(viewer, cell3D, cell);
  }

  onNoVolume() {
    console.log('onNoVolume...');
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const cellId = this.getRealId(cell.divID);
    // console.log("cellId: ", cellId);

    if (cell !== null) {
      const viewer = this.getViewerInstance();
      const volumeType = lt.Controls.Medical.VolumeType.MPR;
      if (!this.alreadyHaveA3Dobject(cell, volumeType)) {
        return;
      }

      // remove mpr cells
      const mprCellIds = [cellId + '_Sagittal', cellId + '_Coronal', cellId + '_Axial'];
      let roomFor = 0;
      for (let i = 0; i < mprCellIds.length; i++) {
        const mprCell = this.seriesManagerService.getSeriesCellById(mprCellIds[i]);

        if (mprCell != null) {
          viewer.layout.get_items().remove(mprCell);
          this.seriesManagerService.removeCell(mprCell);
          mprCell.dispose();
          roomFor += 1;
        }
      }
      if (roomFor >= 3) {
        roomFor = 3;
      }

      // remove cell3D
      const cell3D = this.seriesManagerService.getSeriesCellById(cellId + '_3D');
      if (cell3D != null) {
        const engineID = this.getRealId(cellId);
        this.queryArchiveService.close3DImage(engineID).toPromise().then((result) => {
          viewer.layout.get_items().remove(cell3D);
          this.seriesManagerService.removeCell(cell3D);
          cell3D.dispose();
        });
        delete this._engineDictionary[engineID];
      }

      // update the grid layout
      this.clearRoomFor(viewer, roomFor);

      const mainCell = this.seriesManagerService.getSeriesCellById(cellId);
      if (mainCell != null) {
        this.enumerateCell(viewer, (mainCell) => {
          mainCell.drawCrossHairLines = false;
        });
      }
    }
  }

  onVolumeRendering(volumeType, info, is3DView: boolean = false) {
    console.log('onVolumeRendering...');
    let cell = this.seriesManagerService.getActiveCell();
    const serverSideRendering = true;
    this.isVolumeRendering = true;
    this.viewerComponent = info.viewerComponent;

    if (cell !== null) {
      const viewer: lt.Controls.Medical.MedicalViewer = this.getViewerInstance();

      if (this.alreadyHaveA3Dobject(cell, volumeType)) {
        const cell3D = this.seriesManagerService.getSeriesCellById(cell.Id3D) as lt.Controls.Medical.Cell3D;
        cell3D.set_visibility(is3DView);
        return;
      }

      this.makeRoomFor(viewer, Math.max(0, 3 - cell.derivatives.count));

      if (cell instanceof lt.Controls.Medical.Cell3D) {
        cell = cell.referenceCell;
        if (viewer.layout.items.indexOf(cell) === -1) {
          return;
        }
      }
      if (cell.derivatives.count !== 0) {
        return;
      }
      const status = lt.Controls.Medical.MPRCell.canDoMPR(cell);
      switch (status) {
        case lt.Controls.Medical.MPRStatus.imageOrientationNotTheSame:
          alert('An MPR cannot be created for this cell, because each frame in the cell has a different orientation.');
          return;
        case lt.Controls.Medical.MPRStatus.ok:
          break;
        case lt.Controls.Medical.MPRStatus.cellNotValid:
          alert('You can\'t create an MPR image out of this cell');
          return;
        case lt.Controls.Medical.MPRStatus.imagePositionNotReady:
          alert('The first and second position of the cell is not present');
          return;
        case lt.Controls.Medical.MPRStatus.notEnoughFrames:
          alert('the cell must have at least 3 frames to generate MPR cell out of it');
          return;
        case lt.Controls.Medical.MPRStatus.allFramesNotReady:
          alert('cell data is not downloaded yet');
          return;
      }

      cell.currentOffset = cell.frames.count >> 1;
      let firstCellMPRType;
      let secondCellMPRType;
      switch (cell.mprType) {
        case lt.Controls.Medical.CellMPRType.axial:
          firstCellMPRType = lt.Controls.Medical.CellMPRType.coronal;
          secondCellMPRType = lt.Controls.Medical.CellMPRType.sagittal;
          break;
        case lt.Controls.Medical.CellMPRType.coronal:
          firstCellMPRType = lt.Controls.Medical.CellMPRType.axial;
          secondCellMPRType = lt.Controls.Medical.CellMPRType.sagittal;
          break;
        case lt.Controls.Medical.CellMPRType.sagittal:
          firstCellMPRType = lt.Controls.Medical.CellMPRType.axial;
          secondCellMPRType = lt.Controls.Medical.CellMPRType.coronal;
          break;
      }

      this.create3DObject(volumeType, is3DView, true);

      const cellMPRTypes = [firstCellMPRType, secondCellMPRType];

      if (!cell.fullDownload && (!serverSideRendering)) {
        cell.fullDownload = true;
        cell.add_progressCompleted((sender, e) => {
          this.progressCompleted(viewer, cell, cellMPRTypes, serverSideRendering);
        });
      } else {
        this.progressCompleted(viewer, cell, cellMPRTypes, serverSideRendering);
      }
      this.reArrangeCellBasedOnMPRType(cell, cell.mprType);

      // let selCells = this.viewer.layout.selectedItems.toArray();
      // if(selCells != null && selCells.length > 0){
      //     this.dicomLoaderService.setActiveCell(selCells[0].divID);
      // }
      // this.actionManagerService.runActionCommand(this.lastAction, this.lastInfo);
    } else {
      console.log('onVolumeRendering > Null cell found');
    }
  }

  toggleReferenceLine(info?) {
    try {
      console.log('toggleReferenceLine...');
      // if(!this.isReferenceLine || !(info.viewerComponent.actionManagerService.isReferenceLine)){
      if (!this.isReferenceLine) {
        this.isReferenceLine = !this.isReferenceLine;
        const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
        const matchedCells: any = cell.get_viewer().get_matchedCells();
        for (const key in matchedCells) {
          if (matchedCells.hasOwnProperty(key)) {
            // frameofReferenceUID = String(key);
            this.matchedPack = <any>Array.from(matchedCells[key]);
            let activeOrientation;
            let currentOffset;
            this.matchedPack.forEach((cellItem: any) => {
              const cellDivID: string = cellItem.get_divID();
              if (cellItem.XY && cellItem.XY == true) {
                activeOrientation = cellItem.mprType;
                this.refrenceCellSliceCount = cellItem.automation.containers.count;
                if (cellDivID.includes('_Axial') || cellDivID.includes('_Sagital') || cellDivID.includes('_Coronal')) {
                  currentOffset = cellItem.GC;
                } else {
                  currentOffset = (cellItem as lt.Controls.Medical.Cell).get_currentOffset();
                }
              }
              if (cellItem.mprType == 0) {
                this.axialImageHeight = cellItem.frames.W[0].UI;
                this.axialImageWidth = cellItem.frames.W[0].oJ;
              } else if (cellItem.mprType == 1) {
                this.sagitalImageHeight = cellItem.frames.W[0].UI;
                this.sagitalImageWidth = cellItem.frames.W[0].oJ;
              } else if (cellItem.mprType == 2) {
                this.coronalImageHeight = cellItem.frames.W[0].UI;
                this.coronalImageWidth = cellItem.frames.W[0].oJ;
              }
            });
            if (activeOrientation == 0) {
              this.referenceLineStatus = 'Axial';
              this.addingReferenceLines(currentOffset);
            } else if (activeOrientation == 1) {
              this.referenceLineStatus = 'Sagital';
              this.addingReferenceLines(currentOffset);
            } else if (activeOrientation == 2) {
              this.referenceLineStatus = 'Coronal';
              this.addingReferenceLines(currentOffset);
            }
          }
        }
        this.overlayManagerService.cellsliceChanged.subscribe(e => {
          if (this.isReferenceLine) {
            this.addingReferenceLines(e.get_currentOffset());
          }
        });
      } else {
        const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
        this.isReferenceLine = !this.isReferenceLine;
        this.referenceLineStatus = '';
        cell.get_viewer().layout.get_items().toArray().forEach(cellItem => {
          if (cellItem.mprType != undefined) {
            const automation = cellItem.get_automation();
            const containers: lt.Annotations.Engine.AnnContainerCollection = automation.get_containers();
            containers.toArray().forEach((container: lt.Annotations.Engine.AnnContainer) => {
              container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
                if (item.get_metadata()['Label'] == 'ReferenceLine') {
                  container.get_children().remove(item);
                }
              });
            });
            automation.set_active(true);
          }
        });
        this.asyncResizeTrigger();
      }
    } catch (error) {
      console.log('error => toggleReferenceLine... ', error);
    }
  }

  addingReferenceLines(currentOffset) {
    try {
      const activeViewer: any = this.seriesManagerService.getActiveCell().get_viewer();
      const activeCell: any = activeViewer.layout.selectedItem;

      const matchedCells: any = activeCell.get_viewer().get_matchedCells();
      for (const key in matchedCells) {
        if (matchedCells.hasOwnProperty(key)) {
          this.matchedPack = <any>Array.from(matchedCells[key]);
        }
      }
      let activeOrientation;
      if (activeCell.get_mprType() >= 0) {
        activeOrientation = activeCell.get_mprType();
        currentOffset++;
        this.matchedPack.forEach((cellItem: lt.Controls.Medical.Cell) => {
          const automation = cellItem.get_automation();
          const frame: lt.Controls.Medical.Frame = cellItem.get_frames().get_item(cellItem.get_currentOffset());
          const container: lt.Annotations.Engine.AnnContainer = frame.get_container();
          container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
            if (item.get_metadata()['Label'] == 'ReferenceLine') {
              container.get_children().remove(item);
            }
          });
          if (frame != undefined && frame != null && frame.width != undefined) {
            const srcImage_w = frame.width;
            const srcImage_h = frame.height;
            this.conImage_w = frame.get_container().get_size().get_width();
            this.conImage_h = frame.get_container().get_size().get_height();
            this.scaleX = this.conImage_w / srcImage_w;
            this.scaleY = this.conImage_h / srcImage_h;
          }

          // Add a line object
          const lineObj: lt.Annotations.Engine.AnnPolylineObject = new lt.Annotations.Engine.AnnPolylineObject();
          // Set the stroke
          const sel_strokeColor = 'blue';
          const sel_strokeThickness = 3;
          const annStroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create(sel_strokeColor),
            lt.LeadLengthD.create(sel_strokeThickness));
          lineObj.opacity = 0.8;
          lineObj.set_stroke(annStroke);
          lineObj.get_metadata()['AnnoType'] = 'Ruler';
          lineObj.get_metadata()['Label'] = 'ReferenceLine';

          if (activeOrientation == 0) {
            if (cellItem.mprType == 1) {
              lineObj.points.add(lt.LeadPointD.create(0, ((currentOffset * this.sagitalImageHeight) / this.refrenceCellSliceCount) * this.scaleY));
              lineObj.points.add(lt.LeadPointD.create(this.sagitalImageWidth * this.scaleX, ((currentOffset * this.sagitalImageHeight) / this.refrenceCellSliceCount) * this.scaleY));
              lineObj.get_metadata()['Name'] = 'ReferenceRulerAS';
            } else if (cellItem.mprType == 2) {
              lineObj.points.add(lt.LeadPointD.create(0, ((currentOffset * this.coronalImageHeight) / this.refrenceCellSliceCount) * this.scaleY));
              lineObj.points.add(lt.LeadPointD.create(this.coronalImageWidth * this.scaleX, ((currentOffset * this.coronalImageHeight) / this.refrenceCellSliceCount) * this.scaleY));
              lineObj.get_metadata()['Name'] = 'ReferenceRulerAC';
            }
          } else if (activeOrientation == 1) {
            if (cellItem.mprType == 0) {
              lineObj.points.add(lt.LeadPointD.create(((currentOffset * this.axialImageWidth) / this.refrenceCellSliceCount) * this.scaleX, 0));
              lineObj.points.add(lt.LeadPointD.create(((currentOffset * this.axialImageWidth) / this.refrenceCellSliceCount) * this.scaleX, this.axialImageHeight * this.scaleY));
              lineObj.get_metadata()['Name'] = 'ReferenceRulerSA';
            } else if (cellItem.mprType == 2) {
              lineObj.points.add(lt.LeadPointD.create(((currentOffset * this.coronalImageWidth) / this.refrenceCellSliceCount) * this.scaleX, 0));
              lineObj.points.add(lt.LeadPointD.create(((currentOffset * this.coronalImageWidth) / this.refrenceCellSliceCount) * this.scaleX, this.coronalImageHeight * this.scaleY));
              lineObj.get_metadata()['Name'] = 'ReferenceRulerSC';
            }
          } else if (activeOrientation == 2) {
            if (cellItem.mprType == 0) {
              lineObj.points.add(lt.LeadPointD.create(0, ((currentOffset * this.axialImageHeight) / this.refrenceCellSliceCount) * this.scaleX));
              lineObj.points.add(lt.LeadPointD.create(this.axialImageWidth * this.scaleY, ((currentOffset * this.axialImageHeight) / this.refrenceCellSliceCount) * this.scaleX));
              lineObj.get_metadata()['Name'] = 'ReferenceRulerCA';
            } else if (cellItem.mprType == 1) {
              lineObj.points.add(lt.LeadPointD.create(((currentOffset * this.sagitalImageWidth) / this.refrenceCellSliceCount) * this.scaleX, 0));
              lineObj.points.add(lt.LeadPointD.create(((currentOffset * this.sagitalImageWidth) / this.refrenceCellSliceCount) * this.scaleX, this.sagitalImageHeight * this.scaleY));
              lineObj.get_metadata()['Name'] = 'ReferenceRulerCS';
            }
          }

          // Add the object to the automation container
          container.get_children().add(lineObj);
          automation.set_active(true);
          automation.invalidate(lt.LeadRectD.empty);
          automation.get_automationControl().automationInvalidate(lt.LeadRectD.empty);
        });
      }
    } catch (error) {
      console.log('==> error... ', error);
    }
  }

  changeMPROrientation(mprType, info, cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell()): void {
    console.log('changeMPROrientation...');

    if (cell == null) {
      console.log('cell null found');
      return;
    }
    if (mprType === cell.mprType) {
      console.log('same mprType found: ', mprType);
      return;
    }

    // if (!cell.fullDownload) {
    //   cell.fullDownload = true;
    //   cell.add_progressCompleted((sender, e) => {
    //       this.changeMPROrientation(mprType);
    //   });
    //   return;
    // }

    const viewer = this.getViewerInstance(cell);
    const serverSideRendering = true;
    const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
    let seriesInstanceUID = cell.get_seriesInstanceUID();
    let cellName = 'MedicalCell' + Date.now();

    const splits = cell.divID.split('_');
    // console.log("splits: ", splits);

    if (['Sagittal', 'Coronal', 'Axial'].includes(splits[splits.length - 1])) {
      if (viewer.layout.get_items().indexOf(cell) !== -1) {
        // remove this mpr cell
        // console.log("remove this mpr cell:", cell.divID);
        viewer.layout.get_items().remove(cell);
        this.disposeAutomation(cell.get_automation());
        this.seriesManagerService.removeCell(cell);
        cell.dispose();
      }

      // retrieve the initial cell
      const cellId = this.getRealId(cell.divID);
      cell = this.seriesManagerService.getSeriesCellById(cellId);
      seriesInstanceUID = cell.get_seriesInstanceUID();
    }
    seriesInstanceUID = this.getRealId(seriesInstanceUID);
    // console.log("cell:", cell);

    switch (mprType) {
      case lt.Controls.Medical.CellMPRType.sagittal:
        seriesInstanceUID += '_Sagittal';
        cellName = cell.divID + '_Sagittal';
        break;
      case lt.Controls.Medical.CellMPRType.coronal:
        seriesInstanceUID += '_Coronal';
        cellName = cell.divID + '_Coronal';
        break;
      case lt.Controls.Medical.CellMPRType.axial:
        seriesInstanceUID += '_Axial';
        cellName = cell.divID + '_Axial';
        break;
    }

    const volumeType = lt.Controls.Medical.VolumeType.MPR;
    this.create3DObject(volumeType, false, false, cell);

    let mprCell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(cellName);
    // console.log("seriesInstanceUID:", seriesInstanceUID);
    // console.log("cellName:", cellName);
    // console.log("mprCell:", mprCell);

    if (mprCell == null && mprType !== cell.mprType) {
      const status = lt.Controls.Medical.MPRCell.canDoMPR(cell);
      switch (status) {
        case lt.Controls.Medical.MPRStatus.imageOrientationNotTheSame:
          alert('An MPR cannot be created for this cell, because each frame in the cell has a different orientation.');
          return;
        case lt.Controls.Medical.MPRStatus.ok:
          break;
        case lt.Controls.Medical.MPRStatus.cellNotValid:
          alert('You can\'t create an MPR image out of this cell');
          return;
        case lt.Controls.Medical.MPRStatus.imagePositionNotReady:
          alert('The first and second position of the cell is not present');
          return;
        case lt.Controls.Medical.MPRStatus.notEnoughFrames:
          alert('the cell must have at least 3 frames to generate MPR cell out of it');
          return;
        case lt.Controls.Medical.MPRStatus.allFramesNotReady:
          alert('cell data is not downloaded yet');
          return;
      }
      console.log('create new mprCell...');
      mprCell = new lt.Controls.Medical.MPRCell(cell, mprType, cellName, serverSideRendering);
      (<any>mprCell).add_frameRequested((sender, args) => {
        const automation = mprCell.get_automation();
        const containers: lt.Annotations.Engine.AnnContainerCollection = automation.get_containers();
        containers.toArray().forEach((container: lt.Annotations.Engine.AnnContainer) => {
          container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
            if (item.get_metadata()['Label'] === 'ReferenceLine') {
              container.get_children().remove(item);
            }
          });
        });

        if (this.isReferenceLine && mprCell.get_selected()) {
          this.addingReferenceLines(args.index);
        }
        const cell3D = (<any>cell).cell3D;
        if (cell3D.object3D.volumeReady) {
          args.image.src = this.objectRetrieveService.getMPRFrame(cell3D.object3D.id, args.mprType, args.index);
        }
        // Allow cross-origin since the webclient and LT server have different domains
        args.image.crossOrigin = 'Anonymous';
      });

      this.addContainerEvents(mprCell, info);
    } else if (mprCell == null && mprType === cell.mprType) {
      console.log('use initial cell...');
      mprCell = cell;
      (<any>mprCell).isInitCell = true;
    } else {
      console.log('use existing mprCell...');
    }

    mprCell.frameOfReferenceUID = cell.frameOfReferenceUID;
    mprCell.overlayTextVisible = cell.overlayTextVisible;
    mprCell.fullDownload = true;

    mprCell.beginUpdate();
    mprCell.set_unselectedBorderColor('#000000');
    mprCell.set_selectedSubCellBorderColor('#000000');
    mprCell.set_selectedBorderColor('rgb(255, 152, 0)');
    mprCell.set_highlightedSubCellBorderColor('rgb(255, 152, 0)');
    mprCell.set_showFrameBorder(true);
    mprCell.set_seriesInstanceUID(seriesInstanceUID);
    mprCell.get_progress().setColor(255, 152, 0);
    mprCell.add_mouseDown((sender, e) => this.cellMousedown(sender, e, this));
    mprCell.endUpdate();
    // add custom instances for mprCell
    this.utils.buildMPRCellInstance(mprCell);

    // load annotations for initial/mpr cell
    if (info.viewerComponent.splitConfig && !info.viewerComponent.splitConfig.disabled) {
      this.loadCellAnnotations(mprCell, info);
    }

    // arrange cell to the original position
    mprCell.rowPosition = cell.rowPosition;
    mprCell.columnsPosition = cell.columnsPosition;

    // add the MPR cell to the viewer
    if (viewer.layout.get_items().indexOf(mprCell) === -1 && !(<any>mprCell).isInitCell) {
      console.log('mprCell added...');
      mprCell.set_visibility(true);
      viewer.layout.get_items().add(mprCell);

      this.intializeActions(mprCell);
      this.copyOverlays(mprCell, cell);
      this.addCloseBtn(viewer, mprCell, cell, false, true);

      const newSeriesInfo = jQuery.extend(true, {}, seriesInfo);
      newSeriesInfo.seriesInstanceUID = seriesInstanceUID;

      this.seriesManagerService.addSeriesCell(mprCell);
      this.seriesManagerService.setSeriesInfo(mprCell, newSeriesInfo);

      // Add drop listener to the series loaded
      this.seriesManagerService.enableDropTarget(
        mprCell.get_div(), { 'viewer': mprCell.get_viewer(), 'viewerComponent': info.viewerComponent });

    } else {
      console.log('mprCell found...');
      mprCell.set_visibility(true);
      viewer.layout.get_items().add(mprCell);
    }

    // remove cross hair lines
    this.enumerateCell(viewer, (mprCellItem) => {
      mprCellItem.drawCrossHairLines = false;
    });
    mprCell.overlayTextVisible = true;

    this.seriesManagerService.setActiveCell(mprCell.divID);

    // remove the initial cell
    if (!['Sagittal', 'Coronal', 'Axial'].includes(splits[splits.length - 1])) {
      viewer.layout.get_items().remove(cell);
      // this.disposeAutomation(cell.get_automation()); // Create issue when add anno on reloaded cell
      // this.seriesManagerService.removeCell(cell);
      // cell.dispose();
      cell.set_visibility(false);
    }
  }

  setEnableSeriesSynchronization() {
    console.log('setEnableSeriesSynchronization...');
    const cell = this.seriesManagerService.getActiveCell();
    const medicalViewer = cell.get_viewer();

    const synchronization = !medicalViewer.get_enableSynchronization();
    medicalViewer.set_enableSynchronization(synchronization);
  }

  onSaveAnnotations() {
    console.log('onSaveAnnotations...');
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const length = cell.get_frames().get_count();
    let annFound = false;
    let _container;

    for (let index = 0; index < length; index++) {
      const frame = cell.get_frames().item(index);
      const children = frame.get_container().get_children();
      const count = children.count;
      _container = frame.get_container();
      if (count != 0) {
        annFound = true;
      }
    }
    if (!annFound) {
      alert('No annotations found to save');
      return;
    }

    const dialogRef = this.dialog.open(AnnotationSaveDialogComponent, {
      height: 'auto',
      width: '250px',
      disableClose: true,
      data: {
        description: '',
        container: _container,
        seriesManagerService: this.seriesManagerService,
        objectStoreService: this.objectStoreService
      }
    });
  }

  onLoadAnnotations(info) {
    console.log('onLoadAnnotations...');
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const annotations = this.seriesManagerService.getAnnotationIDs(cell.seriesInstanceUID, cell.divID);
    const frame: lt.Controls.Medical.Frame = this.seriesManagerService.getActiveCellFrame();
    const children = frame.get_container().get_children();
    const count = children.count;
    const _container = frame.get_container();
    // console.log("children: ", children);

    const dialogRef = this.dialog.open(AnnotationLoadDialogComponent, {
      height: 'auto',
      width: '600px',
      disableClose: true,
      data: {
        cell: cell,
        viewerComponent: info.viewerComponent,
        annotations: annotations,
        container: _container,
        objectRetrieveService: this.objectRetrieveService,
        objectStoreService: this.objectStoreService,
        seriesManagerService: this.seriesManagerService
      }
    });
  }

  onLoadObjects(info, shouldOpen = true) {
    console.log('onLoadObjects...');
    const viewerComponent = info.viewerComponent;
    if (this.autoSplitted && info.viewerComponent.splitConfig && !info.viewerComponent.splitConfig.disabled) {
      this.shouldSplitViewer = false;
    }

    // enable ROIs & VOIs panels

    if (shouldOpen) {
      if (viewerComponent.splitConfig && (viewerComponent.splitConfig.disabled || this._gbmAction == 'OnTumorCorrection' || this._gbmAction == 'On2D3DDiam')) {
        viewerComponent.ROIPanelEnabled = true;
        viewerComponent.splitScreen({
          size1: 70,
          size2: 30,
          disabled: false,
          gutterSize: 11
        });
        // update split areas in left-panel
        viewerComponent.splitLeftConfig = {
          size1: 20,
          size2: 20,
          size3: 20,
          size4: 20,
          size5: 20,
          disabled: false,
          gutterSize: 8
        };

        this.asyncResizeTrigger();

        // load Diameter Panel
        if (viewerComponent.dataSourceDiam === undefined) {
          this.diameters = [];
          this.diameters.push(this.createNewDiameter('Enhancing Tumour'));
          this.diameters.push(this.createNewDiameter('Necrosis'));
          this.diameters.push(this.createNewDiameter('Edema'));
          this.dataSourceDiam = new MatTableDataSource(this.diameters);
          const diamData = {
            ID: 0,
            Data: this.dataSourceDiam
          };
          // this.newCellSubjDiam.next(diamData);
        }
      } else {
        viewerComponent.ROIPanelEnabled = false;
        viewerComponent.selectedRows = [];
        viewerComponent.disable_statistics_panel();
        viewerComponent.splitScreen({
          size1: 100,
          size2: 0,
          disabled: true,
          gutterSize: 0
        });
        return;
      }
    }

    if (viewerComponent.viewerSettings.toolbar.isIntChartPanel) {
      // enable spinner while getting pixel data
      viewerComponent.isIntensityChartActionInProgress = true;
    }

    if (!this.isVolumeRendering) {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      this.loadCellAnnotations(cell, info);
      if (viewerComponent.viewerSettings.toolbar.isIntChartPanel) {
        this.seriesManagerService.loadPixelData(cell).then(() => {
          viewerComponent.isIntensityChartActionInProgress = false;
          viewerComponent.cd.detectChanges();
        });
      }
    } else {
      // load annotations for all cells on the viewer layout
      const cells: lt.Controls.Medical.Cell[] = viewerComponent.viewer.layout.get_items().toArray();
      cells.forEach(cell => {
        this.loadCellAnnotations(cell, info);
        if (viewerComponent.viewerSettings.toolbar.isIntChartPanel) {
          this.seriesManagerService.loadPixelData(cell).then(() => {
            viewerComponent.isIntensityChartActionInProgress = false;
            viewerComponent.cd.detectChanges();
          });
        }
      });
    }

    // update let-panel areas
    if (shouldOpen) {
      this.updateLeftPanel(viewerComponent);
    }
  }

  updateLeftPanel(viewerComponent) {
    // console.log('updateLeftPanel...');
    let isRoiArea = true;
    const isVoiArea = true;
    let isPMConfigArea = true;
    let isStatDataArea = true;
    const isIntChartArea = viewerComponent.viewerSettings.toolbar.isIntChartPanel;
    let numAreas = 4;

    // hide ROI table if this is DEMRIQ project
    if (this.utils.isDemriqProject()) {
      // console.log('DEMRIQ project, hide ROI panel...');
      isRoiArea = false;
      numAreas -= 1;
    }
    if (!isIntChartArea) {
      numAreas -= 1;
    }
    // hide MC & PM in reading page
    const selectedPage = get(viewerComponent, 'viewerData.selectedPage', '');
    if (selectedPage !== 'QC') {
      isPMConfigArea = false;
      numAreas -= 1;
    }
    if (selectedPage === 'Reading' && this.utils.isDemriqProject()) {
      isPMConfigArea = true;
      numAreas = 1;
    }

    if (!this.utils.isMranoProject() || !this.utils.isAdvancedAnalysis) {
      isStatDataArea = false;
      numAreas -= 1;
    }

    // update the viewer left-panel regardless enabled areas
    const splitAreaSize = Math.floor(100 / numAreas);
    const splitAreaMarg = 100 - splitAreaSize * numAreas;
    viewerComponent.splitLeftConfig = {
      size1: splitAreaSize * Number(isRoiArea),
      size2: (splitAreaSize + splitAreaMarg) * Number(isVoiArea),
      size3: splitAreaSize * Number(isIntChartArea),
      size4: splitAreaSize * Number(isPMConfigArea),
      size5: splitAreaSize * Number(isStatDataArea),
      disabled: false,
      gutterSize: 8
    };

    this.asyncResizeTrigger();
  }

  loadCellAnnotations(cell: lt.Controls.Medical.Cell, info: any): void {
    try {
      const viewerComponent = info.viewerComponent;
      if (!get(cell, 'seriesInstanceUID', null)) {
        console.log('Warning: Objects are not ready yet.');
        return;
      }
      if ((this._gbmAction === 'OnTumorCorrection' || this._gbmAction === 'On2D3DDiam') && !info.isDiameterOffset) {
        info.isDiameterOffset = true;
      }

      const mprType = this.utils.getCellMPRType(cell);
      let resp = '';
      const xmlAnnotations = [];
      const cellId = this.getRealId(cell.divID);
      const cellSeriesUID = this.getRealId(cell.seriesInstanceUID);
      const annotations = this.seriesManagerService.getAnnotationIDs(cellSeriesUID, cellId);

      if (!annotations || annotations.length <= 0) {
        console.log('loadCellAnnotations: No annotations, found:', annotations);
        return;
      }
      if (!(<any>cell).IsAnnoLoaded) {
        // clear annotations before new load
        this.seriesManagerService.clearAllAnnotations();
        annotations.forEach((annotation) => {
          if (mprType === annotation.ContentDescription || (mprType === null && annotation.ContentDescription === '')) {
            xmlAnnotations.push(annotation.XMLData);
            resp += this.seriesManagerService.addAnnotations(cell, annotation.XMLData, info);
          }
        });

        if (resp == '') {
          viewerComponent.rowData1 = [];
          this.utils.buildROIRowData(cell, info);
        } else {
          console.log('Error:', resp);
        }
        (<any>cell).IsAnnoLoaded = true;
      } else {
        console.log('annotations already loaded !');
        annotations.forEach((annotation) => {
          if (mprType === annotation.ContentDescription) {
            xmlAnnotations.push(annotation.XMLData);
          }
        });
        // reset ROIs table ONLY if volume rendering is disable
        if (!this.isVolumeRendering) {
          viewerComponent.rowData1 = [];
          this.utils.buildROIRowData(cell, info);
        }
      }
    } catch (error) {
      console.log('>>> Error: ', error);
    }
  }

  onAnnotationCobbAngle(info): void {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();

    if (this.LastCommand.ButtonID !== 'CobbAngle') {
      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      seriesInfo['cobbAngle'] = { 'firstLine': null, 'secondLine': null };
      this.seriesManagerService.setSeriesInfo(cell, seriesInfo);
    }
    this.setAnnTool(MedicalViewerAction.AnnLine, info);
  }

  onCrossHairLine(info): void {
    const viewerComponent = info.viewerComponent ? info.viewerComponent : this.viewerComponent;
    if (viewerComponent == null) {
      return;
    }

    // show/hide cross hair on all cells
    this.enumerateCell(viewerComponent.viewer, (mprCell) => {
      mprCell.drawCrossHairLines = mprCell.drawCrossHairLines ? false : true;
    });
  }

  onAddToBrainLesion() {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const series: any = this.seriesManagerService.getSeriesInfo(cell);
    const sliceId = cell.currentOffset + 1;
    const seriesId = series.seriesId;
    this.objectRetrieveService.getSeriesStacks(cell.seriesInstanceUID).subscribe((result: any) => {
      const sopInstanceUIDs = result[0].SopInstanceUIDs;
      localStorage.setItem('brainLesionSopInstanceUID', sopInstanceUIDs[cell.currentOffset]);
      localStorage.setItem('brainSeriesId', seriesId.toString());
      localStorage.setItem('brainSliceId', sliceId.toString());
    });
  }

  onFullDownload() {
    console.log('onFullDownload...');
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    cell.fullDownload = true;
  }

  OnRunRegistration() {
    try {
      this.visitConfigID = 0;
      const activeCell = this.seriesManagerService.getActiveCell();
      const seriesInfo = this.seriesManagerService.getSeriesInfo(activeCell);

      this.locStorage = JSON.parse(localStorage.getItem('project'));
      const locStorageQC = JSON.parse(localStorage.getItem('qc.selectTask'));
      this.patientStudyID = this.locStorage['id'];
      let patientId = 0;

      if (seriesInfo?.patient?.id) {
        patientId = seriesInfo.patient.id;
      }

      if (this.seriesConfigStat.length > 0) {
        this.seriesConfigStat.forEach(item => {
          if (activeCell.seriesId && item.seriesId == activeCell.seriesId) {
            this.visitConfigID = item.visitConfigId;
          }
        });
      }

      if (locStorageQC != null && locStorageQC != '') {
        if (this.visitConfigID == 0) {
          this.visitConfigID = (locStorageQC['visitConfigId'] as any);
        }
        if (patientId === 0) {
          patientId = locStorageQC.patientId;
        }
      }

      let seriesIds = [];
      let studyData = [];

      let T1 = 0;
      let T1post = 0;
      let T2 = 0;
      let FLAIR = 0;

      this.qualityControlService.getSeriesByVisitConfigId(this.patientStudyID, this.visitConfigID).subscribe((HttpResponseData: any) => {
        seriesIds = Array.from(new Set(HttpResponseData['data']));

        this.studySequenceLabelservice.getStudySeriesBySeriesIds(seriesIds).subscribe((StudyData: any) => {
          studyData = Array.from(new Set(StudyData['data']));
          let seriesDesc: string;
          let foundPost = false;
          let foundFlair = false;
          let foundT1 = false;
          let foundT2 = false;

          studyData.forEach(studySeries => {
            seriesDesc = studySeries.label;
            if (seriesDesc == 'T1ce' && !foundPost) {
              foundPost = true;
              T1post = studySeries.id;
            } else if (seriesDesc == 'Flair' && !foundFlair) {
              foundFlair = true;
              FLAIR = studySeries.id;
            } else if (seriesDesc == 'T1' && !foundT1) {
              foundT1 = true;
              T1 = studySeries.id;
            } else if (seriesDesc == 'T2' && !foundT2) {
              foundT2 = true;
              T2 = studySeries.id;
            }
          });

          if (foundPost && foundPost && foundT1 && foundT2) {
            const postData = {
              'studyId': parseInt(this.patientStudyID, 10),
              'patientId': patientId,
              'seriesIds': {
                'T1': T1,
                'T1post': T1post,
                'FLAIR': FLAIR,
                'T2': T2
              },
              'visitConfigId': parseInt(this.visitConfigID, 10),
              'flexibleConfigId': 0
            };
            const newheaders = new HttpHeaders().set(
              'Content-Type', 'application/json',
            );
            this.readingConfigFlexibleService.getActiveConfigsByStudyId(parseInt(this.patientStudyID, 10)).subscribe((resp: any) => {
              if (resp['data'].length > 0) {
                const readings = Array.from(resp['data']);
                readings.forEach(reading => {
                  if (reading['config']['endpoint']['name'] == 'RANO') {
                    console.log('>>> flexibleConfigId === ', reading['id']);
                    postData.flexibleConfigId = reading['id'];
                  }
                });
                this.toastOptions.title = 'ID 77: The registration process has begun successfully';
                this.toastOptions.msg = 'registration is on going, after that you can draw ROIs manually';
                this.toastyService.success(this.toastOptions);

                this.gbmService.registration(JSON.stringify(postData), newheaders).subscribe((response: any) => {
                  this.toastOptions.title = 'ID 78: The registration process has been completed';
                  this.toastOptions.msg = 'registration is done, now you can draw ROIs manually';
                  this.toastyService.success(this.toastOptions);

                  this.setToolbarsReadonly('RunSeg', false);
                  this.setToolbarsReadonly('TumorCorrection', true);
                  this.setToolbarsReadonly('2D3DDiam', true);
                });
              }
              this.toastOptions.title = 'ID 77: The registration process has begun successfully';
              this.toastOptions.msg = 'registration is on going, after that you can draw ROIs manually';
              this.toastyService.success(this.toastOptions);

              this.gbmService.registration(JSON.stringify(postData), newheaders).subscribe((response: any) => {
                this.toastOptions.title = 'ID 78: The registration process has been completed';
                this.toastOptions.msg = 'registration is done, now you can draw ROIs manually';
                this.toastyService.success(this.toastOptions);

                this.setToolbarsReadonly('RunSeg', false);
                this.setToolbarsReadonly('TumorCorrection', true);
                this.setToolbarsReadonly('2D3DDiam', true);
              });
            });
          }
        });
      });
    } catch (error) {

    }
  }

  OnTumorDetection() {
    try {
      this.visitConfigID = 0;
      const activeCell = this.seriesManagerService.getActiveCell();
      const seriesInfo = this.seriesManagerService.getSeriesInfo(activeCell);
      this.locStorage = JSON.parse(localStorage.getItem('project'));
      const locStorageQC = JSON.parse(localStorage.getItem('qc.selectTask'));
      this.patientStudyID = this.locStorage['id'];
      let patientId = 0;

      if (seriesInfo?.patient?.id) {
        patientId = seriesInfo.patient.id;
      }

      if (this.seriesConfigStat.length > 0) {
        this.seriesConfigStat.forEach(item => {
          if (activeCell.seriesId && item.seriesId == activeCell.seriesId) {
            this.visitConfigID = item.visitConfigId;
          }
        });
      }

      if (locStorageQC != null && locStorageQC != '') {
        if (this.visitConfigID == 0) {
          this.visitConfigID = (locStorageQC['visitConfigId'] as any);
        }
        if (patientId === 0) {
          patientId = locStorageQC.patientId;
        }
      }

      let seriesIds = [];
      let studyData = [];
      let T1 = 0;
      let T1post = 0;
      let T2 = 0;
      let FLAIR = 0;

      this.qualityControlService.getSeriesByVisitConfigId(this.patientStudyID, this.visitConfigID).subscribe((HttpResponseData: any) => {
        seriesIds = Array.from(new Set(HttpResponseData['data']));
        console.log(seriesIds);

        this.studySequenceLabelservice.getStudySeriesBySeriesIds(seriesIds).subscribe((StudyData: any) => {
          studyData = Array.from(new Set(StudyData['data']));
          let seriesDesc: string;
          let foundPost = false;
          let foundFlair = false;
          let foundT1 = false;
          let foundT2 = false;

          studyData.forEach(studySeries => {
            seriesDesc = studySeries.label;
            if (seriesDesc == 'T1ce' && !foundPost) {
              foundPost = true;
              T1post = studySeries.id;
            } else if (seriesDesc == 'Flair' && !foundFlair) {
              foundFlair = true;
              FLAIR = studySeries.id;
            } else if (seriesDesc == 'T1' && !foundT1) {
              foundT1 = true;
              T1 = studySeries.id;
            } else if (seriesDesc == 'T2' && !foundT2) {
              foundT2 = true;
              T2 = studySeries.id;
            }
          });

          if (foundPost && foundPost && foundT1 && foundT2) {
            const postData = {
              'studyId': parseInt(this.patientStudyID, 10),
              'patientId': patientId,
              'seriesIds': {
                'T1': T1,
                'T1post': T1post,
                'FLAIR': FLAIR,
                'T2': T2
              },
              'visitConfigId': parseInt(this.visitConfigID, 10),
              'flexibleConfigId': 0
            };
            const newheaders = new HttpHeaders().set(
              'Content-Type', 'application/json',
            );
            this.readingConfigFlexibleService.getActiveConfigsByStudyId(parseInt(this.patientStudyID, 10)).subscribe((resp: any) => {
              if (resp['data'].length > 0) {
                const readings = Array.from(resp['data']);
                readings.forEach(reading => {
                  if (reading['config']['endpoint']['name'] == 'RANO') {
                    console.log('>>> flexibleConfigId === ', reading['id']);
                    postData.flexibleConfigId = reading['id'];
                  }
                });
                this.toastOptions.title = 'ID 66: The segmentation process has begun successfully';
                this.toastOptions.msg = 'In a moment you see the list of tumors (ROI) created automatically';
                this.toastyService.success(this.toastOptions);

                this.gbmService.segmentationforQC(JSON.stringify(postData), newheaders).subscribe((response: any) => {
                  this.toastOptions.title = 'ID 68: The segmentation process has been completed';
                  this.toastOptions.msg = 'The tumors has been segmented. The list of ROI is available for further use';
                  this.toastyService.success(this.toastOptions);

                  this.setToolbarsReadonly('RunSeg', true);
                  this.setToolbarsReadonly('TumorCorrection', false);
                  this.setToolbarsReadonly('2D3DDiam', false);
                });
              }
            });
          }
        });
      });
    } catch (error) {

    }
  }

  async OnTumorCorrection(info?) {
    const viewerComponent = info.viewerComponent;
    try {
      const activeCell = this.seriesManagerService.getActiveCell();
      const cells: lt.Controls.Medical.Cell[] = activeCell.get_viewer().layout.get_items().toArray();
      await cells.forEach(async (_cell: any) => {
        this.seriesManagerService.setActiveCell(_cell.divID);
        const isSaved: boolean = this.isGBMSegemntationSaved(_cell);
        let feature_path;
        const seg_path = JSON.stringify(this.gbmStatus.seg_result);
        if (this.gbmStatus.feature_ext_ellipse_L14 === 'NONE') {
          if (this.gbmStatus.feature_ext_ellipse_L4 === 'NONE') {
            this.toastOptions.title = 'ID 312: Auto segmentation did not detect any enhancing tumor';
            this.toastOptions.msg = 'Auto segmentation did not detect any enhancing tumor, please use ROI and Measurement tools to draw it manually.';
            this.toastyService.warning(this.toastOptions);
          } else {
            feature_path = JSON.stringify(this.gbmStatus.feature_ext_ellipse_L4);
          }
        } else {
          feature_path = JSON.stringify(this.gbmStatus.feature_ext_ellipse_L14);
        }

        if (!isSaved) {
          await this.showGBMSegmentation(seg_path, feature_path, info, _cell);
        } else {
          await this.loadCellAnnotations(_cell, info);
          // load Diameter Panel
          viewerComponent.showDiameterFlag = true;
          this.diameters = [];
          this.diameters.push(this.createNewDiameter('Enhancing Tumour'));
          this.diameters.push(this.createNewDiameter('Necrosis'));
          this.diameters.push(this.createNewDiameter('Edema'));
          this.dataSourceDiam = new MatTableDataSource(this.diameters);
          const diamData = {
            ID: 0,
            Data: this.dataSourceDiam
          };
          this.newCellSubjDiam.next(diamData);
          info['isDiameterOffset'] = true;
          const automation = _cell.get_automation();
          const containers: any = automation.get_containers().toArray();
          for (let i = 0; i < containers.length; i++) {
            const container: lt.Annotations.Engine.AnnContainer = containers[i];
            container.get_children().toArray().forEach((annotation: lt.Annotations.Engine.AnnObject) => {
              if (annotation.get_metadata()['Subject'] === 'DiamRulerL14') {
                _cell.currentOffset = i;
              }
            });
          }
        }
      });
      // show ROIs panel
      this.onLoadObjects(info);
      this._gbmAction = '';
      this.runCommand(MedicalViewerAction.AnnSelect, '', info);

    } catch (error) {
      this.showTumorCorrectionError(viewerComponent, error);
    }
  }

  OnGBMStation(info) {
    const viewerComponent = info.viewerComponent;
    try {
      this.visitConfigID = 0;
      const activeCell = this.seriesManagerService.getActiveCell();
      this.locStorage = JSON.parse(localStorage.getItem('project'));
      const locStorageQC = JSON.parse(localStorage.getItem('qc.selectTask'));
      this.patientStudyID = this.locStorage['id'];

      if (this.seriesConfigStat.length > 0) {
        this.seriesConfigStat.forEach(item => {
          if (activeCell.seriesId && item.seriesId == activeCell.seriesId) {
            this.visitConfigID = item.visitConfigId;
          }
        });
      }

      if (locStorageQC != null && locStorageQC != '' && this.visitConfigID == 0) {
        this.visitConfigID = (locStorageQC['visitConfigId'] as any);
      }

      let interval;
      let seriesIds: any[];
      let studyData: any[];
      const cellData: any[] = [0, 1, 2, 3, 4];
      const activeViewer: lt.Controls.Medical.MedicalViewer = activeCell.get_viewer();
      const frame: lt.Controls.Medical.Frame = activeCell.get_frames().get_item(0);

      if (frame != undefined && frame != null && frame.width != undefined) {
        const srcImage_w = frame.width;
        const srcImage_h = frame.height;
        this.conImage_w = frame.get_container().get_size().get_width();
        this.conImage_h = frame.get_container().get_size().get_height();
        this.scaleX = this.conImage_w / srcImage_w;
        this.scaleY = this.conImage_h / srcImage_h;
      }
      let seriesFound = [];
      const SendData = {
        cell: '',
        isGBMComing: true
      };
      activeViewer.layout.get_items().toArray().forEach(cellItem => {
        SendData.cell = cellItem;
        this.removeSeriesCellSubj.next(SendData);
      });

      this.qualityControlService.getSeriesByVisitConfigId(this.patientStudyID, this.visitConfigID).subscribe((HttpResponseData: any) => {
        seriesIds = Array.from(new Set(HttpResponseData['data']));
        this.studySequenceLabelservice.getStudySeriesBySeriesIds(seriesIds).subscribe((StudyData: any) => {
          studyData = Array.from(new Set(StudyData['data']));
          let seriesDesc: string;
          let foundPost = false;
          let foundFlair = false;
          let foundT1 = false;
          let foundT2 = false;
          seriesFound = [];
          if (this.gbmStatus.error != undefined) {
            console.log('There is some thing wrong with calling Status API please reload the page');
          } else {
            const _status: string = this.gbmStatus['state'];
            console.log('gbmStatus:', _status);
            switch (_status.toLowerCase()) {
              case 'none':
                studyData.forEach(studySeries => {
                  seriesDesc = studySeries.label;
                  if (seriesDesc == 'T1ce' && !foundPost) {
                    foundPost = true;
                    cellData[2] = studySeries.id;
                    seriesFound[seriesFound.length] = studySeries.id;
                  } else if (seriesDesc == 'Flair' && !foundFlair) {
                    foundFlair = true;
                    cellData[3] = studySeries.id;
                    seriesFound[seriesFound.length] = studySeries.id;
                  } else if (seriesDesc == 'T1' && !foundT1) {
                    foundT1 = true;
                    cellData[1] = studySeries.id;
                    seriesFound[seriesFound.length] = studySeries.id;
                  } else if (seriesDesc == 'T2' && !foundT2) {
                    foundT2 = true;
                    cellData[4] = studySeries.id;
                    seriesFound[seriesFound.length] = studySeries.id;
                  }
                });
                this.setToolbarsReadonly('RunSeg', false);
                this.setToolbarsReadonly('TumorCorrection', true);
                this.setToolbarsReadonly('2D3DDiam', true);
                break;
              case 'pending':
                this.toastOptions.title = 'ID 79: Auto segmentation is on Going';
                this.toastOptions.msg = 'Auto segmentation is on Going and it will be done in a few minutes, try again later';
                this.toastyService.info(this.toastOptions);
                break;
              case 'done':
                studyData.forEach(studySeries => {
                  seriesDesc = studySeries.seriesDescription;
                  if (seriesDesc === 'T1post Reg' && !foundPost) {
                    foundPost = true;
                    cellData[2] = studySeries.id;
                    seriesFound[seriesFound.length] = studySeries.id;
                  } else if (seriesDesc == 'FLAIR Reg' && !foundFlair) {
                    foundFlair = true;
                    cellData[3] = studySeries.id;
                    seriesFound[seriesFound.length] = studySeries.id;
                  } else if (studySeries.label == 'T1' && !foundT1) {
                    foundT1 = true;
                    cellData[1] = studySeries.id;
                    seriesFound[seriesFound.length] = studySeries.id;
                  } else if (seriesDesc == 'T2 Reg' && !foundT2) {
                    foundT2 = true;
                    cellData[4] = studySeries.id;
                    seriesFound[seriesFound.length] = studySeries.id;
                  }
                });
                this.setToolbarsReadonly('RunSeg', true);
                this.setToolbarsReadonly('TumorCorrection', false);
                this.setToolbarsReadonly('2D3DDiam', false);
                break;
              default:
                this.toastOptions.title = 'ID 67: The segmentation process failure';
                this.toastOptions.msg = 'The auto segmentation process failed due to some reason. Try again or contact support team';
                this.toastyService.error(this.toastOptions);
                break;
            }
            if (seriesFound.length > 0) {
              this.newCellSubj.next(seriesFound);
              const maxTries = 50;
              let curTries = 0;
              interval = setInterval(() => {
                curTries += 1;
                if (this.gbmSeries.indexOf(seriesFound[0]) > -1 && this.gbmSeries.indexOf(seriesFound[1]) > -1
                  && this.gbmSeries.indexOf(seriesFound[2]) > -1 && this.gbmSeries.indexOf(seriesFound[3]) > -1) {
                  activeViewer.layout.get_items().toArray().forEach((cellItem: lt.Controls.Medical.Cell) => {
                    if (cellItem['seriesId'] == cellData[1]) {
                      cellItem.rowPosition = 0;
                      cellItem.columnsPosition = 0;
                    } else if (cellItem['seriesId'] == cellData[2]) {
                      cellItem.rowPosition = 0;
                      cellItem.columnsPosition = 1;
                    } else if (cellItem['seriesId'] == cellData[3]) {
                      cellItem.rowPosition = 1;
                      cellItem.columnsPosition = 0;
                    } else if (cellItem['seriesId'] == cellData[4]) {
                      cellItem.rowPosition = 1;
                      cellItem.columnsPosition = 1;
                    }
                  });

                  this.onViewerLayout(2, 2, info);

                  setTimeout(() => {
                    clearInterval(interval);
                    interval = null;
                  }, 100);
                } else {
                  console.log('series missing in this.gbmSeries:', this.gbmSeries);
                }

                if (curTries >= maxTries) {
                  setTimeout(() => {
                    clearInterval(interval);
                    interval = null;
                  }, 30);
                } else {
                  console.log('waiting for gbmSeries be loaded...');
                }

              }, 1500);
            }
          }
        });
      });

    } catch (error) {
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();
    }
  }

  async On2D3DMeasurments(info) {
    const viewerComponent = info.viewerComponent;
    try {
      viewerComponent.isViewerActionInProgress = true;
      viewerComponent.viewerProgressActionText = `Preparing 2D/3D Measurements...`;
      const activeCell = this.seriesManagerService.getActiveCell();
      const cells: lt.Controls.Medical.Cell[] = activeCell.get_viewer().layout.get_items().toArray();
      await cells.forEach(async (_cell: any) => {
        this.seriesManagerService.setActiveCell(_cell.divID);
        // load Diameter Panel
        viewerComponent.showDiameterFlag = true;
        this.diameters = [];
        this.diameters.push(this.createNewDiameter('Enhancing Tumour'));
        this.diameters.push(this.createNewDiameter('Necrosis'));
        this.diameters.push(this.createNewDiameter('Edema'));
        this.dataSourceDiam = new MatTableDataSource(this.diameters);
        const diamData = {
          ID: 0,
          Data: this.dataSourceDiam
        };
        this.newCellSubjDiam.next(diamData);
        info['isDiameterOffset'] = true;
        const automation = _cell.get_automation();
        const containers: any = automation.get_containers().toArray();
        for (let i = 0; i < containers.length; i++) {
          const container: lt.Annotations.Engine.AnnContainer = containers[i];
          container.get_children().toArray().forEach((annotation: lt.Annotations.Engine.AnnObject) => {
            if (annotation.get_metadata()['Subject'] === 'DiamRulerL14') {
              _cell.currentOffset = i;
            }
          });
        }
      });
      // show ROIs panel
      this.onLoadObjects(info);
      this._gbmAction = '';
      this.runCommand(MedicalViewerAction.AnnSelect, '', info);
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();

    } catch (error) {
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();
    }
  }

  isGBMSegemntationSaved(cell) {
    try {
      console.log('isGBMSegemntationSaved...');
      // Get existing annotations
      let is_gbm_segm = false;
      const mprType = this.utils.getCellMPRType(cell);
      const celldivID = this.utils.getRealId(cell.divID);
      const cellSeriesUID = this.utils.getRealId(cell.seriesInstanceUID);
      const annotations = this.seriesManagerService.getAnnotationIDs(cellSeriesUID, celldivID);
      console.log('_mprType:', mprType);
      console.log('_annotations:', annotations);

      const codecs: lt.Annotations.Engine.AnnCodecs = new lt.Annotations.Engine.AnnCodecs();

      if (annotations?.length > 0) {
        const local_ann_idx = annotations.findIndex(
          anno => anno.ContentDescription == mprType);

        if (local_ann_idx > -1) {
          const local_ann = annotations[local_ann_idx];
          const local_containers = codecs.loadAllFromXML(local_ann.XMLData);

          for (let i = 0; i < local_containers.length; i++) {
            const container = local_containers[i];
            const children = container.get_children().toArray();

            const gbm_index = children.findIndex(
              child => (
                child.get_metadata()['CreateMethod'] == 'GBM AI' ||
                child.get_metadata()['Subject'] == 'GBMROI' ||
                child.get_metadata()['Subject'] == 'DiamRulerL14'));

            is_gbm_segm = (gbm_index != -1);
            if (is_gbm_segm) {
              break;
            }
          }
        }
      } else {
        return is_gbm_segm;
      }
      // console.log("is_gbm_segm:", is_gbm_segm);
      return is_gbm_segm;
    } catch (error) {

    }
  }

  showGBMSegmentation(seg_path: string, feature_path: string, info, cell) {
    const viewerComponent = info.viewerComponent;
    try {
      viewerComponent.isViewerActionInProgress = true;
      viewerComponent.viewerProgressActionText = `Preparing Tumor Correction...`;
      viewerComponent.cd.detectChanges();
      let _urlX = '';
      const header = new HttpHeaders().set(
        'Content-Type', 'application/json',
      );
      let dataDiameter: any = null;
      let sliceNumber = 0;
      if (feature_path != null) {
        this.diameters = [];
        this.diameters.push(this.createNewDiameter('Enhancing Tumour'));
        this.diameters.push(this.createNewDiameter('Necrosis'));
        this.diameters.push(this.createNewDiameter('Edema'));
        this.dataSourceDiam = new MatTableDataSource(this.diameters);
        const diamData = {
          ID: 0,
          Data: this.dataSourceDiam
        };
        this.newCellSubjDiam.next(diamData);
        _urlX = feature_path.replace('"', '');
      }
      const frame: lt.Controls.Medical.Frame = (cell.get_frames()).W[0];
      if (frame != undefined && frame != null && frame.width != undefined) {
        const srcImage_w = frame.width;
        const srcImage_h = frame.height;
        this.conImage_w = frame.get_container().get_size().get_width();
        this.conImage_h = frame.get_container().get_size().get_height();
        this.scaleX = this.conImage_w / srcImage_w;
        this.scaleY = this.conImage_h / srcImage_h;
      }

      let pageNumber: number;
      const _url = seg_path.replace('"', '');
      this.gbmService.getGBMJsonContent(_url, header).subscribe((response: any) => {
        this.gbmService.getGBMJsonContent(_urlX, header).subscribe((responseX: any) => {
          dataDiameter = responseX;
          sliceNumber = dataDiameter['MaxBurdenSlice'];
          const gbmData = response;
          const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
          const SOPInstanceUIDs = (<any>cell).SOPInstanceUIDs;
          console.log('seriesInstanceUID: ', seriesInfo.seriesInstanceUID);
          console.log('divID: ', cell.divID);
          console.log('SOPInstanceUIDs: ', SOPInstanceUIDs);
          let SliceLabels = [];
          for (const key in gbmData) {
            if (gbmData.hasOwnProperty(key)) {
              pageNumber = Number(key);
              SliceLabels = <any>Array.from(gbmData[key]);

              const frameX: lt.Controls.Medical.Frame = cell.get_frames().get_item(pageNumber - 1);
              const container: lt.Annotations.Engine.AnnContainer = frameX.get_container();

              SliceLabels.forEach(labelItem => {
                const labelName = labelItem.label;
                const reg_id = labelItem.reg_id;
                const reg_size = labelItem.reg_size;
                if (reg_size > 15) {
                  const sliceRoI = <any>Array.from(labelItem.xys);
                  const annPolylineObjectObj: lt.Annotations.Engine.AnnPolylineObject = new lt.Annotations.Engine.AnnPolylineObject();
                  const annPolylineMetadata = annPolylineObjectObj.get_metadata();
                  const roiXYs = <any>Array.from(sliceRoI);
                  let counter = 0;
                  let pointSkiped = 0;
                  annPolylineObjectObj.points.add(lt.LeadPointD.create(roiXYs[0][0] * this.scaleX, roiXYs[0][1] * this.scaleY));
                  for (let i = 1; i < (roiXYs.length) - 1; i++) {
                    const X1 = roiXYs[i - 1][0];
                    const X2 = roiXYs[i][0];
                    const Y1 = roiXYs[i - 1][1];
                    const Y2 = roiXYs[i][1];
                    if (pointSkiped >= 3) {
                      if ((Math.abs((X2 - X1)) >= 1 && Math.abs((Y2 - Y1)) >= 1)) {
                        annPolylineObjectObj.points.add(lt.LeadPointD.create(X2 * this.scaleX, Y2 * this.scaleY));
                        pointSkiped = 0;
                        counter = 0;
                      } else if (counter >= 1) {
                        annPolylineObjectObj.points.add(lt.LeadPointD.create(X2 * this.scaleX, Y2 * this.scaleY));
                        pointSkiped = 0;
                        counter = 0;
                      } else {
                        counter++;
                      }
                    } else {
                      pointSkiped++;
                    }
                  }
                  annPolylineObjectObj.points.add(lt.LeadPointD.create(roiXYs[roiXYs.length - 1][0] * this.scaleX, roiXYs[roiXYs.length - 1][1] * this.scaleY));
                  switch (labelName) {
                    case 1:
                      annPolylineObjectObj.fill = lt.Annotations.Engine.AnnSolidColorBrush.create('blue');
                      annPolylineObjectObj.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush
                        .create('blue'), lt.LeadLengthD.create(1));
                        annPolylineMetadata['Label'] = 'Necrosis';
                      break;
                    case 2:
                      annPolylineObjectObj.fill = lt.Annotations.Engine.AnnSolidColorBrush.create('green');
                      annPolylineObjectObj.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush
                        .create('green'), lt.LeadLengthD.create(1));
                        annPolylineMetadata['Label'] = 'Edema';
                      break;
                    case 4:
                      annPolylineObjectObj.fill = lt.Annotations.Engine.AnnSolidColorBrush.create('red');
                      annPolylineObjectObj.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush
                        .create('red'), lt.LeadLengthD.create(1));
                        annPolylineMetadata['Label'] = 'Enhancing';
                      break;
                    default:
                      break;
                  }
                  annPolylineObjectObj.set_opacity(0.15);
                  annPolylineObjectObj.isClosed = true;
                  annPolylineMetadata['Name'] = 'GBM_AI_' + pageNumber + '_' + reg_id;
                  annPolylineMetadata['AnnoType'] = 'Freehand';
                  annPolylineMetadata['Area'] = '0';
                  annPolylineMetadata['SliceNumber'] = pageNumber + '';
                  annPolylineMetadata['CreateMethod'] = 'GBM AI';
                  annPolylineMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
                  annPolylineMetadata['Subject'] = 'GBMROI';
                  try {
                    if (viewerComponent.viewerData && viewerComponent.viewerData.studyUserRoles) {
                        const JWTData = this.utils.JWTData ? this.utils.JWTData : this.utils.getJWTData();
                        let userRoles = <Array<any>>viewerComponent.studyUserRoles;
                        if (userRoles == undefined) {
                          userRoles = <Array<any>>viewerComponent.viewerData.studyUserRoles;
                        }
                        const userRoleNames = userRoles.map(item => item.roleName);
                        annPolylineMetadata['LastEditedBy'] = JWTData.subject;
                        annPolylineMetadata['CreatedBy'] = JWTData.subject;
                        annPolylineMetadata['Institution'] = JWTData.institution;
                        annPolylineMetadata['UserRoleNames'] = userRoleNames.toString();
                        if (viewerComponent.viewerData.selectedPage === 'Reading' && viewerComponent.viewerData.readingID !== undefined && viewerComponent.viewerData.readingID !== '') {
                          annPolylineMetadata['ReadingID'] = viewerComponent.viewerData.readingID;
                        }
                    }
                  } catch (error) {
                    this.showTumorCorrectionError(viewerComponent, error);
                  }
                  container.get_children().add(annPolylineObjectObj);
                }
              });
              if (sliceNumber === pageNumber) {
                // Add a crossproduct object
                const crossProductObj: lt.Annotations.Engine.AnnCrossProductObject = new lt.Annotations.Engine.AnnCrossProductObject();
                const crossProductMetadata = crossProductObj.get_metadata();
                // Set the points for the first ruler
                crossProductObj.firstStartPoint = lt.LeadPointD.create((dataDiameter['MajorAxisPointsVoxel2d'][0][0] as number) * this.scaleX, (dataDiameter['MajorAxisPointsVoxel2d'][0][1] as number) * this.scaleY);
                crossProductObj.firstEndPoint = lt.LeadPointD.create((dataDiameter['MajorAxisPointsVoxel2d'][1][0] as number) * this.scaleX, (dataDiameter['MajorAxisPointsVoxel2d'][1][1] as number) * this.scaleY);
                // Set the points for the second ruler
                crossProductObj.secondStartPoint = lt.LeadPointD.create((dataDiameter['MinorAxisPointsVoxel2d'][0][0] as number) * this.scaleX, (dataDiameter['MinorAxisPointsVoxel2d'][0][1] as number) * this.scaleY);
                crossProductObj.secondEndPoint = lt.LeadPointD.create((dataDiameter['MinorAxisPointsVoxel2d'][1][0] as number) * this.scaleX, (dataDiameter['MinorAxisPointsVoxel2d'][1][1] as number) * this.scaleY);
                crossProductObj.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
                // Calculate the intersection point
                crossProductObj.updateIntersectionPoint();
                // Set the stroke
                const sel_strokeColor = 'red';
                const sel_strokeThickness = 2;
                const annStroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create(sel_strokeColor),
                  lt.LeadLengthD.create(sel_strokeThickness));
                crossProductObj.set_stroke(annStroke);
                // crossProductObj.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create("orange"), lt.LeadLengthD.create(7));

                crossProductMetadata['Name'] = 'DiamRulerL14';
                crossProductMetadata['AnnoType'] = 'CrossRuler';
                crossProductMetadata['Area'] = '0';
                crossProductMetadata['SliceNumber'] = sliceNumber + '';
                crossProductMetadata['CreateMethod'] = 'GBM AI';
                crossProductMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
                crossProductMetadata['Label'] = 'Enhancing';
                crossProductMetadata['Subject'] = 'DiamRulerL14';

                try {
                  if (viewerComponent.viewerData && viewerComponent.viewerData.studyUserRoles) {
                      const JWTData = this.utils.JWTData ? this.utils.JWTData : this.utils.getJWTData();
                      let userRoles = <Array<any>>viewerComponent.studyUserRoles;
                      if (userRoles == undefined) {
                        userRoles = <Array<any>>viewerComponent.viewerData.studyUserRoles;
                      }
                      const userRoleNames = userRoles.map(item => item.roleName);
                      crossProductMetadata['LastEditedBy'] = JWTData.subject;
                      crossProductMetadata['CreatedBy'] = JWTData.subject;
                      crossProductMetadata['Institution'] = JWTData.institution;
                      crossProductMetadata['UserRoleNames'] = userRoleNames.toString();
                      if (viewerComponent.viewerData.selectedPage === 'Reading' && viewerComponent.viewerData.readingID !== undefined && viewerComponent.viewerData.readingID !== '') {
                        crossProductMetadata['ReadingID'] = viewerComponent.viewerData.readingID;
                      }
                  }
                } catch (error) {
                  this.showTumorCorrectionError(viewerComponent, error);
                }

                // Add the object to the automation container
                container.get_children().add(crossProductObj);
                cell.currentOffset = sliceNumber - 1;
              }
            }
          }

          if (feature_path == null && SOPInstanceUIDs.length > 0) {
            if (SOPInstanceUIDs.length % 2 == 0) {
              cell.currentOffset = SOPInstanceUIDs.length / 2;
            } else {
              cell.currentOffset = (SOPInstanceUIDs.length + 1) / 2;
            }
          }

          // save annotations to LT server and reload from local set
          forkJoin([this.seriesManagerService.saveCellROIs(cell, info)]).subscribe(() => {
            // enable selection tool with select
            this.runCommand(MedicalViewerAction.AnnSelect, '', info);
            this._gbmAction = '';
            // disable loading animation
            viewerComponent.isViewerActionInProgress = false;
            viewerComponent.cd.detectChanges();
          }, err => {
            viewerComponent.toastOptions.title = 'ERROR';
            viewerComponent.toastOptions.msg = 'Something went wrong...';
            viewerComponent.toastyService.error(this.toastOptions);
          }, () => {
            viewerComponent.isViewerActionInProgress = false;
          });
        }, error => {
            this.showTumorCorrectionError(viewerComponent, error);
        });
      }, error => {
        this.showTumorCorrectionError(viewerComponent, error);
      });
    } catch (error) {
      console.log('error found:', error);
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();
    }
  }

  showTumorCorrectionError(viewerComponent, error) {
    viewerComponent.isViewerActionInProgress = false;
    viewerComponent.cd.detectChanges();
    console.log('Error... ', error);
  }

  showJSWContours(info, reset: boolean = false) {
    const viewerComponent = info.viewerComponent;
    viewerComponent.isJSWProject = true;
    viewerComponent.isViewerActionInProgress = true;
    viewerComponent.viewerProgressActionText = `Getting Knee Contours ...`;
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(0);
      const container: lt.Annotations.Engine.AnnContainer = frame.get_container();
      container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
        if (item.get_metadata()['Subject'] == 'RadioBoticROI') {
          container.get_children().remove(item);
        }
      });
      let srcImage_w = 0;
      let srcImage_h = 0;

      if (frame != undefined && frame != null && frame.width != undefined) {
        srcImage_w = frame.width;
        srcImage_h = frame.height;
        this.conImage_w = frame.get_container().get_size().get_width();
        this.conImage_h = frame.get_container().get_size().get_height();
        this.scaleX = this.conImage_w / srcImage_w;
        this.scaleY = this.conImage_h / srcImage_h;
      }
      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      const visitConfigId = seriesInfo.visitConfigId;
      const locStorage = JSON.parse(localStorage.getItem('project'));
      const studyID = locStorage['id'];

      try {
        this.readingJSWService.getJSWContours(studyID, visitConfigId, JSON.parse(localStorage.getItem('currentUser'))).subscribe((response: any) => {
          const kneeData = response.elements[0].rawResponse;
          let amendedResult = null;
          try {
            if (!reset) {
              amendedResult = kneeData.amendedResult;
            }
          } catch (error) {
            amendedResult = null;
          }
          this.radioboticsActionID = response.elements[0].id;
          const container: lt.Annotations.Engine.AnnContainer = frame.get_container();
          const results = kneeData.results;
          const knees: any[] = results.knees;
          const error = results.error;
          if (error !== '-1') {
            this.toastOptions.title = 'ID 600: There is no Contours to add';
            this.toastOptions.msg = 'Please try to run the Radiobotic AI in QC page or wait for it to be finished';
            this.toastyService.error(this.toastOptions);
            return;
          }

          knees.forEach(knee => {
            const kneeData = knee.data;
            const kneeSide = knee.side;
            const jsw_lateral = kneeData.jsw_lateral;
            const jsw_medial = kneeData.jsw_medial;
            const lateral_femur_Ys: any[] = jsw_lateral.jsw_femur_lines_y;
            const lateral_femur_Xs: any[] = jsw_lateral.jsw_femur_lines_x;
            const lateral_tibia_Ys: any[] = jsw_lateral.jsw_tibia_lines_y;
            const lateral_tibia_Xs: any[] = jsw_lateral.jsw_tibia_lines_x;
            const medial_femur_Ys: any[] = jsw_medial.jsw_femur_lines_y;
            const medial_femur_Xs: any[] = jsw_medial.jsw_femur_lines_x;
            const medial_tibia_Ys: any[] = jsw_medial.jsw_tibia_lines_y;
            const medial_tibia_Xs: any[] = jsw_medial.jsw_tibia_lines_x;

            let KneeName = '';
            if (kneeSide === 'al') {
              KneeName = 'Left Knee';
            } else {
              KneeName = 'Right Knee';
            }
            let lateral_femurXYs: any[][];
            let medial_femurXYs: any[][];
            let shouldReducePoint = true;
            if (amendedResult !== null && amendedResult !== undefined) {
              shouldReducePoint = false;
              if (KneeName === 'Left Knee' && amendedResult.lateral_leftKneeFemur && amendedResult.medial_leftKneeFemur) {
                lateral_femurXYs = amendedResult.lateral_leftKneeFemur;
                medial_femurXYs = amendedResult.medial_leftKneeFemur;
              } else if (KneeName === 'Right Knee' && amendedResult.lateral_rightKneeFemur && amendedResult.medial_rightKneeFemur) {
                lateral_femurXYs = amendedResult.lateral_rightKneeFemur;
                medial_femurXYs = amendedResult.medial_rightKneeFemur;
              }
            } else {
              lateral_femurXYs = this.reOrderXYsforDrawing(lateral_femur_Xs, lateral_femur_Ys);
              medial_femurXYs = this.reOrderXYsforDrawing(medial_femur_Xs, medial_femur_Ys);
            }
            const lateral_annFemur_Contour: lt.Annotations.Engine.AnnPolylineObject = new lt.Annotations.Engine.AnnPolylineObject();
            const lateral_annFemur_ContourMetadata = lateral_annFemur_Contour.get_metadata();
            lateral_annFemur_Contour.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('tomato'), lt.LeadLengthD.create(2));
            lateral_annFemur_Contour.set_opacity(0.8);
            lateral_annFemur_ContourMetadata['Name'] = KneeName + '_Lateral_Femur';
            lateral_annFemur_ContourMetadata['Label'] = 'None';
            lateral_annFemur_ContourMetadata['AnnoType'] = 'Freehand';
            lateral_annFemur_ContourMetadata['ObjectType'] = KneeName + '_Lateral_Femur';
            lateral_annFemur_ContourMetadata['ContourIndex'] = KneeName + '_1';
            lateral_annFemur_ContourMetadata['Area'] = '0';
            lateral_annFemur_ContourMetadata['SliceNumber'] = '1';
            lateral_annFemur_ContourMetadata['FrameNumber'] = '1';
            lateral_annFemur_ContourMetadata['FrameCount'] = '1';
            lateral_annFemur_ContourMetadata['CreateMethod'] = 'RadioBotic AI';
            lateral_annFemur_ContourMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
            lateral_annFemur_ContourMetadata['Subject'] = 'RadioBoticROI';
            lateral_annFemur_ContourMetadata['Timepoint'] = '';
            lateral_annFemur_ContourMetadata['SeriesId'] = (cell as any).seriesId;
            lateral_annFemur_ContourMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

            const medial_annFemur_Contour: lt.Annotations.Engine.AnnPolylineObject = new lt.Annotations.Engine.AnnPolylineObject();
            const medial_annFemur_ContourMetadata = medial_annFemur_Contour.get_metadata();
            medial_annFemur_Contour.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('tomato'), lt.LeadLengthD.create(2));
            medial_annFemur_Contour.set_opacity(0.8);
            medial_annFemur_ContourMetadata['Name'] = KneeName + '_Medial_Femur';
            medial_annFemur_ContourMetadata['Label'] = 'None';
            medial_annFemur_ContourMetadata['AnnoType'] = 'Freehand';
            medial_annFemur_ContourMetadata['ObjectType'] = KneeName + '_Medial_Femur';
            medial_annFemur_ContourMetadata['ContourIndex'] = KneeName + '_2';
            medial_annFemur_ContourMetadata['Area'] = '0';
            medial_annFemur_ContourMetadata['SliceNumber'] = '1';
            medial_annFemur_ContourMetadata['FrameNumber'] = '1';
            medial_annFemur_ContourMetadata['FrameCount'] = '1';
            medial_annFemur_ContourMetadata['CreateMethod'] = 'RadioBotic AI';
            medial_annFemur_ContourMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
            medial_annFemur_ContourMetadata['Subject'] = 'RadioBoticROI';
            medial_annFemur_ContourMetadata['Timepoint'] = '';
            medial_annFemur_ContourMetadata['SeriesId'] = (cell as any).seriesId;
            medial_annFemur_ContourMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

            let lateral_tibiaXYs: any[][];
            let medial_tibiaXYs: any[][];
            if (amendedResult !== null && amendedResult !== undefined) {
              if (KneeName === 'Left Knee' && amendedResult.lateral_leftKneeTibia && amendedResult.medial_leftKneeTibia) {
                lateral_tibiaXYs = amendedResult.lateral_leftKneeTibia;
                medial_tibiaXYs = amendedResult.medial_leftKneeTibia;
              } else if (KneeName === 'Right Knee' && amendedResult.lateral_rightKneeTibia && amendedResult.medial_rightKneeTibia) {
                lateral_tibiaXYs = amendedResult.lateral_rightKneeTibia;
                medial_tibiaXYs = amendedResult.medial_rightKneeTibia;
              }
            } else {
              lateral_tibiaXYs = this.reOrderXYsforDrawing(lateral_tibia_Xs, lateral_tibia_Ys);
              medial_tibiaXYs = this.reOrderXYsforDrawing(medial_tibia_Xs, medial_tibia_Ys);
            }
            const lateral_annTibia_Contour: lt.Annotations.Engine.AnnPolylineObject = new lt.Annotations.Engine.AnnPolylineObject();
            const lateral_annTibia_ContourMetadata = lateral_annTibia_Contour.get_metadata();
            lateral_annTibia_Contour.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('orange'), lt.LeadLengthD.create(2));
            lateral_annTibia_Contour.set_opacity(0.8);
            lateral_annTibia_ContourMetadata['Name'] = KneeName + '_Lateral_Tibia';
            lateral_annTibia_ContourMetadata['Label'] = 'None';
            lateral_annTibia_ContourMetadata['AnnoType'] = 'Freehand';
            lateral_annTibia_ContourMetadata['ObjectType'] = KneeName + '_Lateral_Tibia';
            lateral_annTibia_ContourMetadata['ContourIndex'] = KneeName + '_1';
            lateral_annTibia_ContourMetadata['Area'] = '0';
            lateral_annTibia_ContourMetadata['SliceNumber'] = '1';
            lateral_annTibia_ContourMetadata['FrameNumber'] = '1';
            lateral_annTibia_ContourMetadata['FrameCount'] = '1';
            lateral_annTibia_ContourMetadata['CreateMethod'] = 'RadioBotic AI';
            lateral_annTibia_ContourMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
            lateral_annTibia_ContourMetadata['Subject'] = 'RadioBoticROI';
            lateral_annTibia_ContourMetadata['Timepoint'] = '';
            lateral_annTibia_ContourMetadata['SeriesId'] = (cell as any).seriesId;
            lateral_annTibia_ContourMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

            const medial_annTibia_Contour: lt.Annotations.Engine.AnnPolylineObject = new lt.Annotations.Engine.AnnPolylineObject();
            const medial_annTibia_ContourMetadata = medial_annTibia_Contour.get_metadata();
            medial_annTibia_Contour.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('orange'), lt.LeadLengthD.create(2));
            medial_annTibia_Contour.set_opacity(0.8);
            medial_annTibia_ContourMetadata['Name'] = KneeName + '_Medial_Tibia';
            medial_annTibia_ContourMetadata['Label'] = 'None';
            medial_annTibia_ContourMetadata['AnnoType'] = 'Freehand';
            medial_annTibia_ContourMetadata['ObjectType'] = KneeName + '_Medial_Tibia';
            medial_annTibia_ContourMetadata['ContourIndex'] = KneeName + '_2';
            medial_annTibia_ContourMetadata['Area'] = '0';
            medial_annTibia_ContourMetadata['SliceNumber'] = '1';
            medial_annTibia_ContourMetadata['FrameNumber'] = '1';
            medial_annTibia_ContourMetadata['FrameCount'] = '1';
            medial_annTibia_ContourMetadata['CreateMethod'] = 'RadioBotic AI';
            medial_annTibia_ContourMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
            medial_annTibia_ContourMetadata['Subject'] = 'RadioBoticROI';
            medial_annTibia_ContourMetadata['Timepoint'] = '';
            medial_annTibia_ContourMetadata['SeriesId'] = (cell as any).seriesId;
            medial_annTibia_ContourMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

            let lateral_shortestDistance: any[][];
            let medial_shortestDistance: any[][];
            if (amendedResult !== null && amendedResult !== undefined) {
              if (KneeName === 'Left Knee' && amendedResult.lateral_leftKneeShortestLine && amendedResult.medial_leftKneeShortestLine) {
                lateral_shortestDistance = amendedResult.lateral_leftKneeShortestLine;
                medial_shortestDistance = amendedResult.medial_leftKneeShortestLine;
              } else if (KneeName === 'Right Knee' && amendedResult.lateral_rightKneeShortestLine && amendedResult.medial_rightKneeShortestLine) {
                lateral_shortestDistance = amendedResult.lateral_rightKneeShortestLine;
                medial_shortestDistance = amendedResult.medial_rightKneeShortestLine;
              }
            } else {
              lateral_shortestDistance = this.calculateShortestDistance(lateral_femurXYs, lateral_tibiaXYs);
              medial_shortestDistance = this.calculateShortestDistance(medial_femurXYs, medial_tibiaXYs);
            }

            const lateral_annShortestDistance: lt.Annotations.Engine.AnnPolyRulerObject = new lt.Annotations.Engine.AnnPolyRulerObject();
            const lateral_annShortestDistanceMetadata = lateral_annShortestDistance.get_metadata();
            if (shouldReducePoint) {
              lateral_annShortestDistance.points.add(lt.LeadPointD.create(lateral_shortestDistance[0][0] * this.scaleX, lateral_shortestDistance[0][1] * this.scaleY));
              lateral_annShortestDistance.points.add(lt.LeadPointD.create(lateral_shortestDistance[1][0] * this.scaleX, lateral_shortestDistance[1][1] * this.scaleY));
            } else {
              lateral_annShortestDistance.points.add(lt.LeadPointD.create(Math.round(lateral_shortestDistance[0]['x']), Math.round(lateral_shortestDistance[0]['y'])));
              lateral_annShortestDistance.points.add(lt.LeadPointD.create(Math.round(lateral_shortestDistance[1]['x']), Math.round(lateral_shortestDistance[1]['y'])));
            }
            lateral_annShortestDistance.stroke.set_strokeThickness(lt.LeadLengthD.create(0.25));
            lateral_annShortestDistance.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
            lateral_annShortestDistance.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('mediumspringgreen'), lt.LeadLengthD.create(0.25));
            lateral_annShortestDistance.set_opacity(0.9);
            lateral_annShortestDistanceMetadata['Name'] = 'KneeShortestDistance_' + KneeName + '_1';
            lateral_annShortestDistanceMetadata['Label'] = 'None';
            lateral_annShortestDistanceMetadata['AnnoType'] = 'Ruler';
            lateral_annShortestDistanceMetadata['ObjectType'] = 'KneeShortestDistance_' + KneeName + '_1';
            lateral_annShortestDistanceMetadata['ContourIndex'] = KneeName + '_1';
            lateral_annShortestDistanceMetadata['SliceNumber'] = '1';
            lateral_annShortestDistanceMetadata['FrameNumber'] = '1';
            lateral_annShortestDistanceMetadata['FrameCount'] = '1';
            lateral_annShortestDistanceMetadata['CreateMethod'] = 'IAG';
            lateral_annShortestDistanceMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
            lateral_annShortestDistanceMetadata['Subject'] = 'RadioBoticROI';
            lateral_annShortestDistanceMetadata['Timepoint'] = '';
            lateral_annShortestDistanceMetadata['SeriesId'] = (cell as any).seriesId;
            lateral_annShortestDistanceMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

            const medial_annShortestDistance: lt.Annotations.Engine.AnnPolyRulerObject = new lt.Annotations.Engine.AnnPolyRulerObject();
            const medial_annShortestDistanceMetadata = medial_annShortestDistance.get_metadata();
            if (shouldReducePoint) {
              medial_annShortestDistance.points.add(lt.LeadPointD.create(medial_shortestDistance[0][0] * this.scaleX, medial_shortestDistance[0][1] * this.scaleY));
              medial_annShortestDistance.points.add(lt.LeadPointD.create(medial_shortestDistance[1][0] * this.scaleX, medial_shortestDistance[1][1] * this.scaleY));
            } else {
              medial_annShortestDistance.points.add(lt.LeadPointD.create(Math.round(medial_shortestDistance[0]['x']), Math.round(medial_shortestDistance[0]['y'])));
              medial_annShortestDistance.points.add(lt.LeadPointD.create(Math.round(medial_shortestDistance[1]['x']), Math.round(medial_shortestDistance[1]['y'])));
            }
            medial_annShortestDistance.stroke.set_strokeThickness(lt.LeadLengthD.create(0.25));
            medial_annShortestDistance.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
            medial_annShortestDistance.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('mediumspringgreen'), lt.LeadLengthD.create(0.25));
            medial_annShortestDistance.set_opacity(0.9);
            medial_annShortestDistanceMetadata['Name'] = 'KneeShortestDistance_' + KneeName + '_2';
            medial_annShortestDistanceMetadata['Label'] = 'None';
            medial_annShortestDistanceMetadata['AnnoType'] = 'Ruler';
            medial_annShortestDistanceMetadata['ObjectType'] = 'KneeShortestDistance_' + KneeName + '_2';
            medial_annShortestDistanceMetadata['ContourIndex'] = KneeName + '_2';
            medial_annShortestDistanceMetadata['SliceNumber'] = '1';
            medial_annShortestDistanceMetadata['FrameNumber'] = '1';
            medial_annShortestDistanceMetadata['FrameCount'] = '1';
            medial_annShortestDistanceMetadata['CreateMethod'] = 'IAG';
            medial_annShortestDistanceMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
            medial_annShortestDistanceMetadata['Subject'] = 'RadioBoticROI';
            medial_annShortestDistanceMetadata['Timepoint'] = '';
            medial_annShortestDistanceMetadata['SeriesId'] = (cell as any).seriesId;
            medial_annShortestDistanceMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

            if (amendedResult === null) {
              for (let i = 0; i < lateral_femurXYs.length; i++) {
                lateral_annFemur_Contour.points.add(lt.LeadPointD.create(Math.round(lateral_femurXYs[i]['0'] * this.scaleX), Math.round(lateral_femurXYs[i]['1'] * this.scaleY)));
              }
              for (let i = 0; i < lateral_tibiaXYs.length; i++) {
                lateral_annTibia_Contour.points.add(lt.LeadPointD.create(Math.round(lateral_tibiaXYs[i]['0'] * this.scaleX), Math.round(lateral_tibiaXYs[i]['1'] * this.scaleY)));
              }
              for (let i = 0; i < medial_femurXYs.length; i++) {
                medial_annFemur_Contour.points.add(lt.LeadPointD.create(Math.round(medial_femurXYs[i]['0'] * this.scaleX), Math.round(medial_femurXYs[i]['1'] * this.scaleY)));
              }
              for (let i = 0; i < medial_tibiaXYs.length; i++) {
                medial_annTibia_Contour.points.add(lt.LeadPointD.create(Math.round(medial_tibiaXYs[i]['0'] * this.scaleX), Math.round(medial_tibiaXYs[i]['1'] * this.scaleY)));
              }
            } else {
              for (let i = 0; i < lateral_femurXYs.length; i++) {
                lateral_annFemur_Contour.points.add(lt.LeadPointD.create(Math.round(lateral_femurXYs[i]['x']), Math.round(lateral_femurXYs[i]['y'])));
              }
              for (let i = 0; i < lateral_tibiaXYs.length; i++) {
                lateral_annTibia_Contour.points.add(lt.LeadPointD.create(Math.round(lateral_tibiaXYs[i]['x']), Math.round(lateral_tibiaXYs[i]['y'])));
              }
              for (let i = 0; i < medial_femurXYs.length; i++) {
                medial_annFemur_Contour.points.add(lt.LeadPointD.create(Math.round(medial_femurXYs[i]['x']), Math.round(medial_femurXYs[i]['y'])));
              }
              for (let i = 0; i < medial_tibiaXYs.length; i++) {
                medial_annTibia_Contour.points.add(lt.LeadPointD.create(Math.round(medial_tibiaXYs[i]['x']), Math.round(medial_tibiaXYs[i]['y'])));
              }
            }

            container.get_children().add(lateral_annFemur_Contour);
            container.get_children().add(lateral_annTibia_Contour);
            container.get_children().add(lateral_annShortestDistance);
            container.get_children().add(medial_annFemur_Contour);
            container.get_children().add(medial_annTibia_Contour);
            container.get_children().add(medial_annShortestDistance);
          });
        });
        this.runCommand(MedicalViewerAction.AnnSelect, '', info);
        viewerComponent.isViewerActionInProgress = false;
        viewerComponent.cd.detectChanges();
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 1500);

      } catch (error) {
        console.log(error);
      }
      // enable selection tool with select
      this.runCommand(MedicalViewerAction.AnnSelect, '', info);
      // disable loading animation
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();
    } catch (error) {
      console.log('error found:', error);
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();
    }
  }
  showJSWContours2(info, reset: boolean = false) {
    console.log('showJSWContours2');
    const viewerComponent = info.viewerComponent;
    viewerComponent.isJSWProject = true;
    viewerComponent.isViewerActionInProgress = true;
    viewerComponent.viewerProgressActionText = `Getting Knee Contours ...`;
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(0);
      const container: lt.Annotations.Engine.AnnContainer = frame.get_container();
      container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
        if (item.get_metadata()['Subject'] == 'RadioBoticROI') {
          container.get_children().remove(item);
        }
      });
      let srcImage_w = 0;
      let srcImage_h = 0;

      if (frame !== undefined && frame !== null && frame.width != undefined) {
        srcImage_w = frame.width;
        srcImage_h = frame.height;
        this.conImage_w = frame.get_container().get_size().get_width();
        this.conImage_h = frame.get_container().get_size().get_height();
        this.scaleX = this.conImage_w / srcImage_w;
        this.scaleY = this.conImage_h / srcImage_h;
      }
      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      const visitConfigId = seriesInfo.visitConfigId;
      const locStorage = JSON.parse(localStorage.getItem('project'));
      const studyID = locStorage['id'];

      try {
        this.readingJSWService.getJSWContours(studyID, visitConfigId, JSON.parse(localStorage.getItem('currentUser'))).subscribe((response: any) => {
          const kneeData = response.elements[0].rawResponse;
          let amendedResult = null;
          try {
            if (!reset) {
              amendedResult = kneeData.amendedResult;
            }
          } catch (error) {
            amendedResult = null;
          }
          this.radioboticsActionID = response.elements[0].id;
          const container: lt.Annotations.Engine.AnnContainer = frame.get_container();
          const results = kneeData.results;
          const knees: any[] = results.knees;
          const error = results.error;
          if (error !== '-1') {
            this.toastOptions.title = 'ID 600: There is no Contours to add';
            this.toastOptions.msg = 'Please try to run the Radiobotic AI in QC page or wait for it to be finished';
            this.toastyService.error(this.toastOptions);
            return;
          }

          knees.forEach(knee => {
            const kneeData = knee.data;
            const kneeSide = knee.side;

            const jsw_lateral = kneeData.jsw_lateral;
            const jsw_lateral_scale = 1;
            const lateral_femur_Ys: any[] = jsw_lateral.jsw_femur_lines_y;
            const lateral_femur_Xs: any[] = jsw_lateral.jsw_femur_lines_x;
            const lateral_tibia_Ys: any[] = jsw_lateral.jsw_tibia_lines_y;
            const lateral_tibia_Xs: any[] = jsw_lateral.jsw_tibia_lines_x;
            const jsw_lateral_mm = kneeData.jsw_lateral_mm;
            const jsw_lateral_valid: any[] = kneeData.jsw_lateral_valid;
            const literal_jsw_coordinates_x: any[] = jsw_lateral.jsw_coordinates_x;
            const literal_jsw_coordinates_y: any[] = jsw_lateral.jsw_coordinates_y;

            const jsw_medial = kneeData.jsw_medial;
            const jsw_medial_scale = 1;
            const medial_femur_Ys: any[] = jsw_medial.jsw_femur_lines_y;
            const medial_femur_Xs: any[] = jsw_medial.jsw_femur_lines_x;
            const medial_tibia_Ys: any[] = jsw_medial.jsw_tibia_lines_y;
            const medial_tibia_Xs: any[] = jsw_medial.jsw_tibia_lines_x;
            const jsw_medial_mm = kneeData.jsw_medial_mm;
            const jsw_medial_valid: any[] = kneeData.jsw_medial_valid;
            const medial_jsw_coordinates_x: any[] = jsw_medial.jsw_coordinates_x;
            const medial_jsw_coordinates_y: any[] = jsw_medial.jsw_coordinates_y;

            let KneeName = '';
            if (kneeSide === 'al') {
              KneeName = 'Left Knee';
            } else {
              KneeName = 'Right Knee';
            }
            let lateral_femurXYs: any[][];
            let medial_femurXYs: any[][];
            if (amendedResult !== null && amendedResult !== undefined) {
              if (KneeName === 'Left Knee' && amendedResult.lateral_leftKneeFemur && amendedResult.medial_leftKneeFemur) {
                lateral_femurXYs = amendedResult.lateral_leftKneeFemur;
                medial_femurXYs = amendedResult.medial_leftKneeFemur;
              } else if (KneeName === 'Right Knee' && amendedResult.lateral_rightKneeFemur && amendedResult.medial_rightKneeFemur) {
                lateral_femurXYs = amendedResult.lateral_rightKneeFemur;
                medial_femurXYs = amendedResult.medial_rightKneeFemur;
              }
            } else {
              lateral_femurXYs = this.reOrderXYsforDrawing(lateral_femur_Xs, lateral_femur_Ys);
              medial_femurXYs = this.reOrderXYsforDrawing(medial_femur_Xs, medial_femur_Ys);
            }
            const lateral_Contour: AnnJswObject = new AnnJswObject();

            if (jsw_lateral_scale && Number.isFinite(jsw_lateral_scale)) {
              lateral_Contour.distanceScale = jsw_lateral_scale;
            }

            const lateral_ContourMetadata = lateral_Contour.get_metadata();
            // tslint:disable-next-line:max-line-length
            lateral_Contour.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('tomato'), lt.LeadLengthD.create(2));
            lateral_Contour.set_opacity(0.8);
            lateral_ContourMetadata['Name'] = KneeName + '_Lateral';
            lateral_ContourMetadata['Label'] = 'None';
            lateral_ContourMetadata['AnnoType'] = 'AnnJswObject';
            lateral_ContourMetadata['ObjectType'] = KneeName + '_Lateral';
            lateral_ContourMetadata['ContourIndex'] = KneeName + '_1';
            lateral_ContourMetadata['Area'] = '0';
            lateral_ContourMetadata['SliceNumber'] = '1';
            lateral_ContourMetadata['FrameNumber'] = '1';
            lateral_ContourMetadata['FrameCount'] = '1';
            lateral_ContourMetadata['CreateMethod'] = 'RadioBotic AI';
            lateral_ContourMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
            lateral_ContourMetadata['Subject'] = 'RadioBoticROI';
            lateral_ContourMetadata['Timepoint'] = '';
            lateral_ContourMetadata['SeriesId'] = (cell as any).seriesId;
            lateral_ContourMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

            const medial_Contour: AnnJswObject = new AnnJswObject();
            if (jsw_medial_scale && Number.isFinite(jsw_medial_scale)) {
              medial_Contour.distanceScale = jsw_medial_scale;
            }

            const medial_ContourMetadata = medial_Contour.get_metadata();
            medial_Contour.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('tomato'), lt.LeadLengthD.create(2));
            medial_Contour.set_opacity(0.8);
            medial_ContourMetadata['Name'] = KneeName + '_Medial';
            medial_ContourMetadata['Label'] = 'None';
            medial_ContourMetadata['AnnoType'] = 'AnnJswObject';
            medial_ContourMetadata['ObjectType'] = KneeName + '_Medial';
            medial_ContourMetadata['ContourIndex'] = KneeName + '_2';
            medial_ContourMetadata['Area'] = '0';
            medial_ContourMetadata['SliceNumber'] = '1';
            medial_ContourMetadata['FrameNumber'] = '1';
            medial_ContourMetadata['FrameCount'] = '1';
            medial_ContourMetadata['CreateMethod'] = 'RadioBotic AI';
            medial_ContourMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
            medial_ContourMetadata['Subject'] = 'RadioBoticROI';
            medial_ContourMetadata['Timepoint'] = '';
            medial_ContourMetadata['SeriesId'] = (cell as any).seriesId;
            medial_ContourMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

            let lateral_tibiaXYs: any[][];
            let medial_tibiaXYs: any[][];
            if (amendedResult !== null && amendedResult !== undefined) {
              if (KneeName === 'Left Knee' && amendedResult.lateral_leftKneeTibia && amendedResult.medial_leftKneeTibia) {
                lateral_tibiaXYs = amendedResult.lateral_leftKneeTibia;
                medial_tibiaXYs = amendedResult.medial_leftKneeTibia;
              } else if (KneeName === 'Right Knee' && amendedResult.lateral_rightKneeTibia && amendedResult.medial_rightKneeTibia) {
                lateral_tibiaXYs = amendedResult.lateral_rightKneeTibia;
                medial_tibiaXYs = amendedResult.medial_rightKneeTibia;
              }
            } else {
              lateral_tibiaXYs = this.reOrderXYsforDrawing(lateral_tibia_Xs, lateral_tibia_Ys);
              medial_tibiaXYs = this.reOrderXYsforDrawing(medial_tibia_Xs, medial_tibia_Ys);
            }

            if (jsw_lateral_valid && jsw_lateral_mm && Number.isFinite(jsw_lateral_mm)) {
              const lateral_ContourLabel = new lt.Annotations.Engine.AnnLabel();
              lateral_ContourLabel.text = `${jsw_lateral_mm.toFixed(2)}mm`;
              lateral_Contour.labels['InitialDistance'] = lateral_ContourLabel;
              if ((literal_jsw_coordinates_x || []).length >= 2 && (literal_jsw_coordinates_y || []).length >= 2) {
                const literalFemurPointExists = lateral_femurXYs.some(
                  (l: any[]): boolean => l[0] === literal_jsw_coordinates_x[0] && l[1] === literal_jsw_coordinates_y[0]
                );

                const literalTibiaPointExists = lateral_tibiaXYs.some(
                  (l: any[]): boolean => l[0] === literal_jsw_coordinates_x[1] && l[1] === literal_jsw_coordinates_y[1]
                );

                if (literalFemurPointExists && literalTibiaPointExists) {
                  // tslint:disable-next-line:max-line-length
                  lateral_Contour.distanceFirstPoint = lt.LeadPointD.create(
                    Math.round(literal_jsw_coordinates_x[0]) * this.scaleX,
                    Math.round(literal_jsw_coordinates_y[0]) * this.scaleY
                  );

                  // tslint:disable-next-line:max-line-length
                  lateral_Contour.distanceSecondPoint = lt.LeadPointD.create(
                    Math.round(literal_jsw_coordinates_x[1]) * this.scaleX,
                    Math.round(literal_jsw_coordinates_y[1]) * this.scaleY
                  );
                }
              }
            }

            if (jsw_medial_valid && jsw_medial_mm && Number.isFinite(jsw_medial_mm)) {
              const medial_ContourLabel =  new lt.Annotations.Engine.AnnLabel();
              medial_ContourLabel.text = `${jsw_medial_mm.toFixed(2)}mm`;
              medial_Contour.labels['InitialDistance'] = medial_ContourLabel;

              if ((medial_jsw_coordinates_x || []).length >= 2 && (medial_jsw_coordinates_y || []).length >= 2) {
                const medialFemurPointExists = medial_femurXYs.some(
                  (l: any[]): boolean => l[0] === medial_jsw_coordinates_x[0] && l[1] === medial_jsw_coordinates_y[0]
                );

                const medialPointExists = medial_tibiaXYs.some(
                  (l: any[]): boolean => l[0] === medial_jsw_coordinates_x[1] && l[1] === medial_jsw_coordinates_y[1]
                );

                if (medialFemurPointExists && medialPointExists) {
                  // tslint:disable-next-line:max-line-length
                  medial_Contour.distanceFirstPoint = lt.LeadPointD.create(
                    Math.round(medial_jsw_coordinates_x[0]) * this.scaleX,
                    Math.round(medial_jsw_coordinates_y[0]) * this.scaleY
                  );

                  // tslint:disable-next-line:max-line-length
                  medial_Contour.distanceSecondPoint = lt.LeadPointD.create(
                    Math.round(medial_jsw_coordinates_x[1]) * this.scaleX,
                    Math.round(medial_jsw_coordinates_y[1]) * this.scaleY
                  );
                }
              }
            }

            if (amendedResult === null) {
              for (let i = 0; i < lateral_femurXYs.length; i++) {
                lateral_Contour.addFirstLinePoint(lt.LeadPointD.create(Math.round(lateral_femurXYs[i][0] * this.scaleX), Math.round(lateral_femurXYs[i][1] * this.scaleY)));
              }
              for (let i = 0; i < lateral_tibiaXYs.length; i++) {
                lateral_Contour.addSecondLinePoint(lt.LeadPointD.create(Math.round(lateral_tibiaXYs[i][0] * this.scaleX), Math.round(lateral_tibiaXYs[i][1] * this.scaleY)));
              }
              for (let i = 0; i < medial_femurXYs.length; i++) {
                medial_Contour.addFirstLinePoint(lt.LeadPointD.create(Math.round(medial_femurXYs[i][0] * this.scaleX), Math.round(medial_femurXYs[i][1] * this.scaleY)));
              }
              for (let i = 0; i < medial_tibiaXYs.length; i++) {
                medial_Contour.addSecondLinePoint(lt.LeadPointD.create(Math.round(medial_tibiaXYs[i][0] * this.scaleX), Math.round(medial_tibiaXYs[i][1] * this.scaleY)));
              }
            } else {
              for (let i = 0; i < lateral_femurXYs.length; i++) {
                lateral_Contour.addFirstLinePoint(lt.LeadPointD.create(Math.round(lateral_femurXYs[i]['x']), Math.round(lateral_femurXYs[i]['y'])));
              }
              for (let i = 0; i < lateral_tibiaXYs.length; i++) {
                lateral_Contour.addSecondLinePoint(lt.LeadPointD.create(Math.round(lateral_tibiaXYs[i]['x']), Math.round(lateral_tibiaXYs[i]['y'])));
              }
              for (let i = 0; i < medial_femurXYs.length; i++) {
                medial_Contour.addFirstLinePoint(lt.LeadPointD.create(Math.round(medial_femurXYs[i]['x']), Math.round(medial_femurXYs[i]['y'])));
              }
              for (let i = 0; i < medial_tibiaXYs.length; i++) {
                medial_Contour.addSecondLinePoint(lt.LeadPointD.create(Math.round(medial_tibiaXYs[i]['x']), Math.round(medial_tibiaXYs[i]['y'])));
              }
            }

            container.children.add(lateral_Contour);
            container.children.add(medial_Contour);
          });
        });
        this.runCommand(MedicalViewerAction.AnnSelect, '', info);
        viewerComponent.isViewerActionInProgress = false;
        viewerComponent.cd.detectChanges();
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 1500);

      } catch (error) {
        console.log(error);
      }
      // enable selection tool with select
      this.runCommand(MedicalViewerAction.AnnSelect, '', info);
      // disable loading animation
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();
    } catch (error) {
      console.log('error found:', error);
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();
    }
  }
  reOrderXYsforDrawing(originXs: any[], originYs: any[]) {
    const originXYs: any[][] = [];
    for (let i = 0; i < originXs.length; i++) {
      originXYs[i] = [originXs[i], originYs[i]];
    }
    return originXYs;
  }

  calculateShortestDistance(object1Points: any[][], object2Points: any[][]) {
    const distanceObject: any[][] = [];
    let shortestDistance = 0;
    distanceObject.push([0, 0]);
    distanceObject.push([0, 0]);

    for (let i = 0; i < object1Points.length; i++) {
      const point1 = object1Points[i];
      const X1 = point1[0];
      const Y1 = point1[1];
      let point2Index = 0;
      let closestPointDistance = 0;

      for (let j = 0; j < object2Points.length; j++) {
        const point2 = object2Points[j];
        let XxX = X1 - point2[0];
        let YyY = Y1 - point2[1];
        if (XxX < -1) {
          XxX = XxX * -1;
        }
        if (YyY < -1) {
          YyY = YyY * -1;
        }
        const closeDistance = XxX + YyY;
        if (closeDistance < closestPointDistance || closestPointDistance === 0) {
          closestPointDistance = closeDistance;
          point2Index = j;
        }
      }
      if (closestPointDistance < shortestDistance || shortestDistance === 0) {
        shortestDistance = closestPointDistance;
        distanceObject[0] = point1;
        distanceObject[1] = object2Points[point2Index];
      }
    }
    return distanceObject;
  }

  ShowShortestDistance(info, isItHip, contourIndex) {
    this.JSWCounter++;
    const viewerComponent = info.viewerComponent;
    viewerComponent.isViewerActionInProgress = true;
    viewerComponent.viewerProgressActionText = `Calculating Shortest Distance ...`;
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const frame = this.seriesManagerService.getActiveCellFrame();
      const container = cell.get_automation().get_activeContainer();
      let srcImage_w = 0;
      let srcImage_h = 0;
      const tempRowData1: any[] = [];
      const objectPoints: any[] = [];
      const firstPoints: any[][] = [];
      const secondPoints: any[][] = [];
      objectPoints.push();
      objectPoints.push();
      let rowIndex = 0;
      let isItLeft = false;
      viewerComponent.selection1.selected.forEach((row) => {
        if (row.type == 'Freehand') {
          objectPoints[rowIndex] = row.child.points.W;
          rowIndex++;
        }
      });

      if (frame != undefined && frame != null && frame.width != undefined) {
        srcImage_w = frame.width;
        srcImage_h = frame.height;
        this.conImage_w = frame.get_container().get_size().get_width();
        this.conImage_h = frame.get_container().get_size().get_height();
        this.scaleX = this.conImage_w / srcImage_w;
        this.scaleY = this.conImage_h / srcImage_h;
      }

      for (let i = 0; i < rowIndex; i++) {
        const points = objectPoints[i];
        for (let j = 0; j < points.length; j++) {
          const x = Math.round(points[j].x / this.scaleX);
          const y = Math.round(points[j].y / this.scaleY);
          if (i === 0) {
            firstPoints.push([x, y]);
          } else if (i === 1) {
            secondPoints.push([x, y]);
          }
        }
      }

      container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
        if (item.get_metadata()['ContourIndex'] === contourIndex && item.get_metadata()['AnnoType'] === 'Ruler') {
          container.get_children().remove(item);
        }
        const rowIndex = viewerComponent._rowData1.findIndex(rowData => rowData.name === item.get_metadata().Name);
        if (rowIndex != -1) {
          tempRowData1.push(viewerComponent._rowData1[rowIndex]);
        }
        if (item.get_metadata()['BodySide'] === 'Left') {
          isItLeft = true;
        }
      });

      viewerComponent._rowData1 = tempRowData1;
      viewerComponent._dataSource1.data = tempRowData1;

      const shortestDistance = this.calculateShortestDistance(firstPoints, secondPoints);
      const annShortestDistance: lt.Annotations.Engine.AnnPolyRulerObject = new lt.Annotations.Engine.AnnPolyRulerObject();
      const annShortestDistanceMetadata = annShortestDistance.get_metadata();
      annShortestDistance.points.add(lt.LeadPointD.create(shortestDistance[0][0] * this.scaleX, shortestDistance[0][1] * this.scaleY));
      annShortestDistance.points.add(lt.LeadPointD.create(shortestDistance[1][0] * this.scaleX, shortestDistance[1][1] * this.scaleY));
      annShortestDistance.stroke.set_strokeThickness(lt.LeadLengthD.create(0.25));
      annShortestDistance.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
      annShortestDistance.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('mediumspringgreen'), lt.LeadLengthD.create(0.25));
      annShortestDistance.set_opacity(0.9);
      if (isItHip === true) {
        annShortestDistanceMetadata['Name'] = 'HipShortestDistance_' + contourIndex;
        annShortestDistanceMetadata['ObjectType'] = 'HipShortestDistance_' + contourIndex;
        annShortestDistanceMetadata['Subject'] = 'JSWROI';
        annShortestDistanceMetadata['ContourIndex'] = contourIndex;
        if (isItLeft) {
          annShortestDistanceMetadata['BodySide'] = 'Left';
        } else {
          annShortestDistanceMetadata['BodySide'] = 'Right';
        }
      } else {
        annShortestDistanceMetadata['Name'] = 'KneeShortestDistance_' + contourIndex;
        annShortestDistanceMetadata['ObjectType'] = 'KneeShortestDistance_' + contourIndex;
        annShortestDistanceMetadata['Subject'] = 'RadioBoticROI';
        annShortestDistanceMetadata['ContourIndex'] = contourIndex;
      }
      annShortestDistanceMetadata['Label'] = 'None';
      annShortestDistanceMetadata['AnnoType'] = 'Ruler';
      annShortestDistanceMetadata['SliceNumber'] = '1';
      annShortestDistanceMetadata['FrameNumber'] = '1';
      annShortestDistanceMetadata['FrameCount'] = '1';
      annShortestDistanceMetadata['CreateMethod'] = 'IAG';
      annShortestDistanceMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
      annShortestDistanceMetadata['Timepoint'] = '';
      annShortestDistanceMetadata['SeriesId'] = (cell as any).seriesId;
      annShortestDistanceMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

      container.get_children().add(annShortestDistance);

      viewerComponent.cd.detectChanges();
      viewerComponent.isViewerActionInProgress = false;
      this.asyncResizeTrigger();

    } catch (error) {
      console.log('error found:', error);
      viewerComponent.isViewerActionInProgress = false;
      viewerComponent.cd.detectChanges();
    }
  }

  confirmContours(info) {
    try {
      this.viewerComponent = info.viewerComponent;
      this.viewerComponent.isJSWProject = true;
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const isItLeft = false;
      const series: any = this.seriesManagerService.getSeriesInfo(cell);
      const seriesId = series.seriesId;
      const container: lt.Annotations.Engine.AnnContainer = cell.get_automation().get_activeContainer();
      const updatedData = {
        'seriesId': seriesId,
        'lateral_leftKneeFemur': null,
        'medial_leftKneeFemur': null,
        'lateral_leftKneeTibia': null,
        'medial_leftKneeTibia': null,
        'lateral_leftKneeShortestLine': null,
        'medial_leftKneeShortestLine': null,
        'lateral_leftKneeShortestDistance': null,
        'medial_leftKneeShortestDistance': null,
        'lateral_rightKneeFemur': null,
        'medial_rightKneeFemur': null,
        'lateral_rightKneeTibia': null,
        'medial_rightKneeTibia': null,
        'lateral_rightKneeShortestLine': null,
        'medial_rightKneeShortestLine': null,
        'lateral_rightKneeShortestDistance': null,
        'medial_rightKneeShortestDistance': null
      };
      const updatedHipData = {
        'seriesId': seriesId,
        'Right_Hip_ShortestDistance': null,
        'Left_Hip_ShortestDistance': null,
        'Hip_ShortestDistance': null
      };

      let isItHip = false;
      container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
        if (item.get_metadata()['Subject'] === 'JSWROI') {
          isItHip = true;
        }
        if (item.get_metadata()['Name'].includes('HipShortestDistance')) {
          if (item.get_metadata()['BodySide'] === 'Right') {
            updatedHipData.Right_Hip_ShortestDistance = item.labels.RulerLength.text;
          } else if (item.get_metadata()['BodySide'] === 'Left') {
            updatedHipData.Left_Hip_ShortestDistance = item.labels.RulerLength.text;
          } else {
            updatedHipData.Hip_ShortestDistance = item.labels.RulerLength.text;
          }
        }
      });

      if (!isItHip) {
        container.get_children().toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
          if (item.get_metadata()['Name'] === 'Left Knee_Lateral_Femur') {
            updatedData.lateral_leftKneeFemur = item.points.toArray();
          } else if (item.get_metadata()['Name'] === 'Left Knee_Lateral_Tibia') {
            updatedData.lateral_leftKneeTibia = item.points.toArray();
          } else if (item.get_metadata()['Name'] === 'Left Knee_Medial_Femur') {
            updatedData.medial_leftKneeFemur = item.points.toArray();
          } else if (item.get_metadata()['Name'] === 'Left Knee_Medial_Tibia') {
            updatedData.medial_leftKneeTibia = item.points.toArray();
          } else if (item.get_metadata()['Name'] === 'Right Knee_Lateral_Femur') {
            updatedData.lateral_rightKneeFemur = item.points.toArray();
          } else if (item.get_metadata()['Name'] === 'Right Knee_Lateral_Tibia') {
            updatedData.lateral_rightKneeTibia = item.points.toArray();
          } else if (item.get_metadata()['Name'] === 'Right Knee_Medial_Femur') {
            updatedData.medial_rightKneeFemur = item.points.toArray();
          } else if (item.get_metadata()['Name'] === 'Right Knee_Medial_Tibia') {
            updatedData.medial_rightKneeTibia = item.points.toArray();
          } else if (item.get_metadata()['Name'] === 'KneeShortestDistance_Left Knee_1') {
            updatedData.lateral_leftKneeShortestLine = item.points.toArray();
            updatedData.lateral_leftKneeShortestDistance = item.labels.RulerLength.text;
          } else if (item.get_metadata()['Name'] === 'KneeShortestDistance_Left Knee_2') {
            updatedData.medial_leftKneeShortestLine = item.points.toArray();
            updatedData.medial_leftKneeShortestDistance = item.labels.RulerLength.text;
          } else if (item.get_metadata()['Name'] === 'KneeShortestDistance_Right Knee_1') {
            updatedData.lateral_rightKneeShortestLine = item.points.toArray();
            updatedData.lateral_rightKneeShortestDistance = item.labels.RulerLength.text;
          } else if (item.get_metadata()['Name'] === 'KneeShortestDistance_Right Knee_2') {
            updatedData.medial_rightKneeShortestLine = item.points.toArray();
            updatedData.medial_rightKneeShortestDistance = item.labels.RulerLength.text;
          }
        });

        const locStorage = JSON.parse(localStorage.getItem('project'));
        const studyID = locStorage['id'];

        try {
          this.readingJSWService.updateJSWContours(studyID, this.radioboticsActionID, JSON.parse(localStorage.getItem('currentUser')), updatedData).subscribe((response: any) => {
            console.log(response);
          });
          if (this.viewerComponent != null && this.viewerComponent != undefined) {
            this.viewerComponent.confirmContours(updatedData);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        if (this.viewerComponent != null && this.viewerComponent != undefined) {
          const info = { 'viewerComponent': this.viewerComponent, 'tableIndex': 1 };
          this.viewerComponent.isViewerActionInProgress = true;
          this.viewerComponent.viewerProgressActionText = `Saving All ROIs...`;
          this.seriesManagerService.saveAllROIs(info).subscribe(() => {
            this.viewerComponent.confirmContours(updatedHipData);
            this.viewerComponent.isViewerActionInProgress = false;
            this.viewerComponent.cd.detectChanges();
          });
        }
      }
      console.log(updatedData);
      console.log(updatedHipData);
      this.asyncResizeTrigger();
    } catch (error) {
      console.log('error found:', error);
    }
  }

  toggleJswDistanceCalculation(info) {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
    const activeContainer: lt.Annotations.Engine.AnnContainer = cell.automation.activeContainer;

    let firstSelected: AnnJswObject;
    for (const item of activeContainer.children.toArray()) {
      if (item.metadata.AnnoType.includes('AnnJswObject') && item.isSelected) {
        firstSelected = (item as AnnJswObject);
        firstSelected.CalcVerticalDistance = !firstSelected.CalcVerticalDistance;
        break;
      }
    }

    cell.automation.invalidate(lt.LeadRectD.empty);
  }

  confirmContours2(info, isHip, target) {
    try {
      this.viewerComponent = info.viewerComponent;
      this.viewerComponent.isJSWProject = true;
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();

      const series: any = this.seriesManagerService.getSeriesInfo(cell);
      const seriesId = series.seriesId;
      const container: lt.Annotations.Engine.AnnContainer = cell.get_automation().get_activeContainer();
      const updatedData = {
        seriesId,
        lateral_leftKneeFemur: null,
        medial_leftKneeFemur: null,
        lateral_leftKneeTibia: null,
        medial_leftKneeTibia: null,
        lateral_leftKneeShortestLine: null,
        medial_leftKneeShortestLine: null,
        lateral_leftKneeShortestDistance: null,
        medial_leftKneeShortestDistance: null,
        lateral_rightKneeFemur: null,
        medial_rightKneeFemur: null,
        lateral_rightKneeTibia: null,
        medial_rightKneeTibia: null,
        lateral_rightKneeShortestLine: null,
        medial_rightKneeShortestLine: null,
        lateral_rightKneeShortestDistance: null,
        medial_rightKneeShortestDistance: null
      };
      const updatedHipData = {
        seriesId,
        Right_Hip_ShortestDistance: null,
        Left_Hip_ShortestDistance: null,
        Hip_ShortestDistance: null
      };

      container.children.toArray().forEach((item: lt.Annotations.Engine.AnnObject) => {
        if (item.metadata.AnnoType.includes('AnnJswObject') && item.isSelected) {
          if (isHip) {
            updatedHipData[target] = item.labels.JswDistance.text;
          } else {
            updatedData[target] = item.labels.JswDistance.text;
          }

          item.metadata['JswTarget'] = target;
        }
      });

      if (isHip) {
        this.viewerComponent?.confirmContours(updatedHipData);
      } else {

        const locStorage = JSON.parse(localStorage.getItem('project'));
        const studyID = locStorage['id'];

        try {
          this.readingJSWService.updateJSWContours(
              studyID,
              this.radioboticsActionID,
              JSON.parse(localStorage.getItem('currentUser')),
              updatedData
            )
            .subscribe((response: any) => {
              console.log(response);
            });

          this.viewerComponent?.confirmContours(updatedData);
        } catch (error) {
          console.log(error);
        }
      }
      this.asyncResizeTrigger();
    } catch (error) {
      console.log('error found:', error);
    }
  }

  saveROI() {
    const info = { 'viewerComponent': this.viewerComponent, 'tableIndex': 1 };
    this.viewerComponent.isViewerActionInProgress = true;
    this.viewerComponent.viewerProgressActionText = `Saving All ROIs...`;
    this.seriesManagerService.saveAllROIs(info).subscribe(() => {
      this.viewerComponent.isViewerActionInProgress = false;
      this.viewerComponent.cd.detectChanges();
    });
  }
  fillGBMSeriesList(StatID: number, SeriesID?: number, visitConfigID?: number) {
    try {
      this.visitConfigID = 0;
      if (StatID == 1) {
        this.setToolbarsReadonly('RunSeg', true);
        this.setToolbarsReadonly('TumorCorrection', true);
        this.setToolbarsReadonly('2D3DDiam', true);

      } else if (StatID == 0) {
        this.gbmSeries = [];

      } else if (StatID === 3 && Boolean(SeriesID)) {
        this.gbmSeries[this.gbmSeries.length] = SeriesID;
        this.locStorage = JSON.parse(localStorage.getItem('project'));
        const locStorageQC = JSON.parse(localStorage.getItem('qc.selectTask'));
        this.patientStudyID = this.locStorage['id'];
        const seriesVisitConfigs: any = JSON.parse(localStorage.getItem('visitConfigIds'));
        let isGBMStatCalled = false;
        const seriesStatData = {
          visitConfigId: 0,
          seriesId: 0,
          gbmStat: '',
          gbmResponse: ''
        };

        if (this.seriesConfigStat.length > 0) {
          this.seriesConfigStat.forEach(item => {
            if (item.visitConfigId == visitConfigID && item.gbmResponse) {
              this.gbmStatus = item.gbmResponse;
              const _status: string = this.gbmStatus['state'];
              switch (_status.toLowerCase()) {
                case 'done':
                  this.setToolbarsReadonly('RunSeg', true);
                  this.setToolbarsReadonly('TumorCorrection', false);
                  this.setToolbarsReadonly('2D3DDiam', false);
                  break;
                default:
                  break;
              }
              isGBMStatCalled = true;
            }
          });
        }

        if (!isGBMStatCalled) {
          if (seriesVisitConfigs != undefined && seriesVisitConfigs.length > 0) {
            Array.from(seriesVisitConfigs).forEach((item: any) => {
              if (SeriesID && item.seriesId == SeriesID) {
                this.visitConfigID = item.visitConfigId;
                seriesStatData.visitConfigId = item.visitConfigId;
                seriesStatData.seriesId = item.seriesId;
                this.seriesConfigStat.push(seriesStatData);
              }
            });
          }

          if (locStorageQC != null && locStorageQC != '' && this.visitConfigID == 0) {
            this.visitConfigID = (locStorageQC['visitConfigId'] as any);

            seriesStatData.visitConfigId = this.visitConfigID;
            seriesStatData.seriesId = SeriesID;
            this.seriesConfigStat.push(seriesStatData);
          }
          const postDataX = {
            visitConfigId: parseInt(this.visitConfigID),
            flexibleConfigId: 0,
            studyId: parseInt(this.patientStudyID),
            bucketName: this.viewerComponent.viewerData.bucket
          };
          const newheaders = new HttpHeaders().set(
            'Content-Type', 'application/json',
          );
          if (this.visitConfigID != 0) {
            let GBMState = false;
            this.imagingAnalysisService.getContainerResultsWithChildsByVisitConfigId([this.visitConfigID], [])
              .subscribe((resp: any) => {
                (resp.executions as any).forEach((execution: any) => {
                  if (execution.workload === 'GBMSegmentation') {
                    GBMState = true;
                    if (execution.state === 'DONE' && execution.workloadInput.flexibleConfigId) {
                      postDataX.flexibleConfigId = (execution.workloadInput.flexibleConfigId as number);
                    }
                  }
                });
                if (postDataX.flexibleConfigId !== 0) {
                  this.gbmService.status(JSON.stringify(postDataX), newheaders).subscribe((response: any) => {
                    this.gbmStatus = response;
                      if (response.error != undefined) {
                        console.log('There is some thing wrong with calling Status API please reload the page');
                      } else {
                        const _status: string = this.gbmStatus['state'];
                        switch (_status.toLowerCase()) {
                          case 'done':
                            this.setToolbarsReadonly('RunSeg', true);
                            this.setToolbarsReadonly('TumorCorrection', false);
                            this.setToolbarsReadonly('2D3DDiam', false);
                            break;
                          default:
                            break;
                        }
                        Array.from(this.seriesConfigStat).forEach((item: any) => {
                          if (item.visitConfigId == postDataX.visitConfigId) {
                            item.gbmStat = _status;
                            item.gbmResponse = response;
                          }
                        });
                      }
                  },
                  error => {
                    console.log('>>> Status API Error ===', error);
                  });
                } else if (!this.isGBMStatus) {
                  this.isGBMStatus = true;
                  if (GBMState) {
                    this.toastOptions.title = 'ID 500: GBM segmentation is not being Finish yet';
                    this.toastOptions.msg = 'Please wait for it to be finished then try to open the image again';
                    this.toastyService.warning(this.toastOptions);
                  } else {
                    this.toastOptions.title = 'ID 501: GBM segmentation is not being Done yet';
                    this.toastOptions.msg = 'Please go to QC page and select right reading then click on Start GBM';
                    this.toastyService.warning(this.toastOptions);
                  }
                }
            });
          }
        }
      }
      return this.gbmSeries.length;
    } catch (error) {
      console.log('error', error);
    }
  }

  createNewDiameter(name: string): DiameterRow {
    try {
      switch (name) {
        case 'Enhancing Tumour':
          return {
            name: name,
            color: '#DA2412',
            value: 4,
            major: 0,
            minor: 0,
            spdp: 0,
            volume: 0,
          };
          break;
        case 'Necrosis':
          return {
            name: name,
            color: '#122DDA',
            value: 1,
            major: 0,
            minor: 0,
            spdp: 0,
            volume: 0,
          };
          break;
        case 'Edema':
          return {
            name: name,
            color: '#33DA12',
            value: 2,
            major: 0,
            minor: 0,
            spdp: 0,
            volume: 0,
          };
          break;
        default:
          break;
      }
    } catch (error) {

    }
  }

  addLabelOverlays(viewer: lt.Controls.Medical.MedicalViewer) {
    try {
      const t1_Overlay = new lt.Controls.Medical.OverlayText();
      t1_Overlay.text = 'T1';
      t1_Overlay.type = lt.Controls.Medical.OverlayTextType.userData;
      t1_Overlay.positionIndex = 0;
      t1_Overlay.color = 'orange';
      t1_Overlay.alignment = lt.Controls.Medical.OverlayAlignment.centerTop;

      const post_Overlay = new lt.Controls.Medical.OverlayText();
      post_Overlay.text = 'T1ce Reg';
      post_Overlay.type = lt.Controls.Medical.OverlayTextType.userData;
      post_Overlay.positionIndex = 0;
      post_Overlay.color = 'orange';
      post_Overlay.alignment = lt.Controls.Medical.OverlayAlignment.centerTop;

      const flair_Overlay = new lt.Controls.Medical.OverlayText();
      flair_Overlay.text = 'FLAIR Reg';
      flair_Overlay.type = lt.Controls.Medical.OverlayTextType.userData;
      flair_Overlay.positionIndex = 0;
      flair_Overlay.color = 'orange';
      flair_Overlay.alignment = lt.Controls.Medical.OverlayAlignment.centerTop;

      const t2_Overlay = new lt.Controls.Medical.OverlayText();
      t2_Overlay.text = 'T2 Reg';
      t2_Overlay.type = lt.Controls.Medical.OverlayTextType.userData;
      t2_Overlay.positionIndex = 0;
      t2_Overlay.color = 'orange';
      t2_Overlay.alignment = lt.Controls.Medical.OverlayAlignment.centerTop;

      viewer.get_emptyDivs().get_items().toArray().forEach((emptyCell) => {
        if (emptyCell.rowPosition == 0 && emptyCell.columnsPosition == 0) {
          emptyCell.labels.add(t1_Overlay);
        } else if (emptyCell.rowPosition == 0 && emptyCell.columnsPosition == 1) {
          emptyCell.labels.add(post_Overlay);
        } else if (emptyCell.rowPosition == 1 && emptyCell.columnsPosition == 0) {
          emptyCell.labels.add(flair_Overlay);
        } else if (emptyCell.rowPosition === 1 && emptyCell.columnsPosition === 1) {
          emptyCell.labels.add(t2_Overlay);
        }
      });
    } catch (error) {

    }
  }

  setToolbarsReadonly(id: string, readonly: boolean) {
    this.viewerToolbar.setItemProperty(id, 'readonly', readonly);
  }

  enableSelectionForRois(info: any, loadROIs: boolean) {
    // console.log('enableSelectionForRois...');
    try {
      // enable Select as default anno tool to enable the selection after loding cell annotations
      if (loadROIs) {
        this.onLoadObjects(info, false);
      }
      // NOTE: disable this as it create conflict with requirement to set Pan as default action
      // and because it prevent action progration across cells

      // if (!this.isRapidAction && !this.utils.isDemriqProject()) {
      //   info.viewerComponent.lastAction = 'Select';
      //   info['annType'] = 'Select';
      //   this.setAnnTool(MedicalViewerAction.AnnSelect, info);
      // }
    } catch (error) {

    }
  }

  OnShowHideOverlays() {
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const viewer: lt.Controls.Medical.MedicalViewer = cell.get_viewer();
      viewer.layout.get_items().toArray().forEach((cellItem: lt.Controls.Medical.Cell) => {
        cellItem.set_overlayTextVisible(!this.showOverlays);
      });
      this.showOverlays = !this.showOverlays;
      if (this.showOverlays) {
        this.viewerToolbar.setItemProperty('ShowHideOverlays', 'tooltip', 'Hide Metadata');
      } else {
        this.viewerToolbar.setItemProperty('ShowHideOverlays', 'tooltip', 'Show Metadata');
      }
      // setTimeout(() => {
      //   window.dispatchEvent(new Event('resize'));
      // }, 30);
    } catch (error) {

    }
  }

  drawCustomFreehand(color, regionToScore, primaryLocation, info?) {
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      if ((this as any).annType !== null) {
        (cell as any).annType = (this as any).annType;
      }
      const annAutomation: lt.Annotations.Automation.AnnAutomation = cell.get_automation();
      const annAutoManager: lt.Annotations.Automation.AnnAutomationManager = cell.get_automationManager();
      if (this.activeJoint.color !== '' && this.activeJoint.color !== color) {
        annAutomation.cancel();
        this.isNewJoint = true;
      }

      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      const seriesId = seriesInfo.seriesId;
      const visitConfigId = seriesInfo.visitConfigId;
      const timepoint = seriesInfo.timepoint;
      const currentOffset = cell.get_currentOffset();
      const frameCount = seriesInfo.numbOfFrames;
      const sliceCount = seriesInfo.numbOfSlices;
      const currentFrame = parseInt(((currentOffset / sliceCount) + 1) + '');
      const currentSlice = parseInt((document.getElementById(cell.divID + '_slice-tracker_current') as HTMLInputElement).value, 10);
      const viewerComponent = info.viewerComponent;

      this.activeJoint.visitConfigId = visitConfigId;
      this.activeJoint.seriesId = seriesId;
      this.activeJoint.color = color;
      this.activeJoint.regionToScore = regionToScore;
      this.activeJoint.primaryLocation = primaryLocation;
      this.activeJoint.timepoint = timepoint;

      try {
        viewerComponent.actionManagerService.activeJoint = this.activeJoint;
      } catch (error) {

      }

      if (visitConfigId !== 0 && visitConfigId != null) {
        const freehandObj: lt.Annotations.Automation.AnnAutomationObject = annAutoManager.findObjectById(lt.Annotations.Engine.AnnObject.freehandObjectId);
        const freehandTemplate: any = freehandObj.objectTemplate;
        const freehandMetadata: any = freehandTemplate.get_metadata();
        const label: lt.Annotations.Engine.AnnLabel = freehandTemplate.labels['AnnObjectName'];
        label.isVisible = true;
        label.foreground = lt.Annotations.Engine.AnnSolidColorBrush.create(color);
        label.background = lt.Annotations.Engine.AnnSolidColorBrush.create('rgba(0, 0, 0, 0)');

        freehandTemplate.stroke.stroke.color = color;
        freehandTemplate.stroke.set_strokeThickness(lt.LeadLengthD.create(2));
        freehandMetadata['AnnoType'] = 'Freehand';
        freehandMetadata['Area'] = '0';
        freehandMetadata['SliceNumber'] = currentSlice;
        freehandMetadata['_sliceNumber'] = currentSlice;
        freehandMetadata['CurrentFrame'] = currentFrame;
        freehandMetadata['CreateMethod'] = 'ّJoint Area';
        freehandMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
        freehandMetadata['Label'] = regionToScore + '_' + primaryLocation;
        freehandMetadata['primaryLocation'] = primaryLocation;
        freehandMetadata['Subject'] = 'primaryLocationArea';
        freehandMetadata['regionToScore'] = regionToScore;
        freehandMetadata['VisitConfigId'] = visitConfigId;
        freehandMetadata['SeriesId'] = seriesId;
        freehandMetadata['CurrentOffset'] = currentOffset + '';
        freehandMetadata['SliceCount'] = sliceCount;
        freehandMetadata['FrameCount'] = frameCount;
        freehandMetadata['Timepoint'] = timepoint;

        try {
          if (viewerComponent.viewerData && viewerComponent.viewerData.studyUserRoles) {
              const JWTData = this.utils.JWTData ? this.utils.JWTData : this.utils.getJWTData();
              let userRoles = <Array<any>>viewerComponent.studyUserRoles;
              if (userRoles == undefined) {
                userRoles = <Array<any>>viewerComponent.viewerData.studyUserRoles;
              }
              const userRoleNames = userRoles.map(item => item.roleName);
              freehandMetadata['LastEditedBy'] = JWTData.subject;
              freehandMetadata['CreatedBy'] = JWTData.subject;
              freehandMetadata['Institution'] = JWTData.institution;
              freehandMetadata['UserRoleNames'] = userRoleNames.toString();
              if (viewerComponent.viewerData.selectedPage === 'Reading' && viewerComponent.viewerData.readingID !== undefined && viewerComponent.viewerData.readingID !== '') {
                freehandMetadata['ReadingID'] = viewerComponent.viewerData.readingID;
              }
          }
        } catch (error) {

        }

        label.text = freehandMetadata['Label'];

        if (!this.isRapidAction || info.viewerComponent.lastAction !== 'OnAnnotationFreehand') {
          this.isRapidAction = true;
          this.onLoadObjects(info, false);
          this.viewerToolbar.setItemProperty('RapidAction', 'tooltip', 'Disable Rapid Drawing');
          info.viewerComponent.lastAction = 'OnAnnotationFreehand';
          info.viewerComponent.lastInfo = info;
        }
        setTimeout(() => {
          this.runActionCommand('OnAnnotationFreehand', info);
        }, 0);
      }
    } catch (error) {
      console.log('>>> error ... ', error);
    }
  }

  DrawFirstContour(info, isItLeft: boolean) {
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const viewerComponent = info.viewerComponent;
      viewerComponent.isJSWProject = true;
      const annAutoManager: lt.Annotations.Automation.AnnAutomationManager = cell.get_automationManager();
      const freehandObj: lt.Annotations.Automation.AnnAutomationObject = annAutoManager.findObjectById(lt.Annotations.Engine.AnnObject.freehandObjectId);
      const freehandTemplate: any = freehandObj.objectTemplate;
      const freehandMetadata: any = freehandTemplate.get_metadata();

      freehandTemplate.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('orange'), lt.LeadLengthD.create(2));
      freehandTemplate.set_opacity(0.8);

      freehandMetadata['Name'] = 'First_Contour_' + this.JSWCounter;
      freehandMetadata['Label'] = 'None';
      freehandMetadata['ObjectType'] = 'First_Contour_' + this.JSWCounter;
      freehandMetadata['AnnoType'] = 'Freehand';
      freehandMetadata['ContourIndex'] = this.JSWCounter;
      if (isItLeft) {
        freehandMetadata['BodySide'] = 'Left';
      } else {
        freehandMetadata['BodySide'] = 'Right';
      }
      freehandMetadata['Area'] = '0';
      freehandMetadata['SliceNumber'] = '1';
      freehandMetadata['FrameNumber'] = '1';
      freehandMetadata['FrameCount'] = '1';
      freehandMetadata['CreateMethod'] = 'Manual';
      freehandMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
      freehandMetadata['Subject'] = 'JSWROI';
      freehandMetadata['Timepoint'] = '';
      freehandMetadata['SeriesId'] = (cell as any).seriesId;
      freehandMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

      setTimeout(() => {
        this.runActionCommand('OnAnnotationFreehand', info);
      }, 0);
    } catch (error) {
      console.log('>>> error ... ', error);
    }
  }

  DrawSecondContour(info, isItLeft: boolean) {
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const viewerComponent = info.viewerComponent;
      const annAutoManager: lt.Annotations.Automation.AnnAutomationManager = cell.get_automationManager();
      const freehandObj: lt.Annotations.Automation.AnnAutomationObject = annAutoManager.findObjectById(lt.Annotations.Engine.AnnObject.freehandObjectId);
      const freehandTemplate: any = freehandObj.objectTemplate;
      const freehandMetadata: any = freehandTemplate.get_metadata();

      freehandTemplate.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('tomato'), lt.LeadLengthD.create(2));
      freehandTemplate.set_opacity(0.8);

      freehandMetadata['Name'] = 'Second_Contour_' + this.JSWCounter;
      freehandMetadata['Label'] = 'None';
      freehandMetadata['AnnoType'] = 'Freehand';
      freehandMetadata['ObjectType'] = 'Second_Contour_' + this.JSWCounter;
      freehandMetadata['ContourIndex'] = this.JSWCounter;
      if (isItLeft) {
        freehandMetadata['BodySide'] = 'Left';
      } else {
        freehandMetadata['BodySide'] = 'Right';
      }
      freehandMetadata['Area'] = '0';
      freehandMetadata['SliceNumber'] = '1';
      freehandMetadata['FrameNumber'] = '1';
      freehandMetadata['FrameCount'] = '1';
      freehandMetadata['CreateMethod'] = 'Manual';
      freehandMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
      freehandMetadata['Subject'] = 'JSWROI';
      freehandMetadata['Timepoint'] = '';
      freehandMetadata['SeriesId'] = (cell as any).seriesId;
      freehandMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

      setTimeout(() => {
        this.runActionCommand('OnAnnotationFreehand', info);
      }, 0);
    } catch (error) {
      console.log('>>> error ... ', error);
    }
  }

  DrawContours(info, isKnee) {
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const annAutoManager: lt.Annotations.Automation.AnnAutomationManager = cell.get_automationManager();
      const jswObject: lt.Annotations.Automation.AnnAutomationObject = annAutoManager.findObjectById(AnnJswObject.jswObjectId);
      const freehandTemplate: any = jswObject.objectTemplate;
      const jswMetadata: any = freehandTemplate.metadata;

      jswMetadata['AnnoType'] = 'AnnJswObject';
      jswMetadata['Area'] = '0';
      jswMetadata['SliceNumber'] = '1';
      jswMetadata['FrameNumber'] = '1';
      jswMetadata['FrameCount'] = '1';
      jswMetadata['CreateMethod'] = 'Manual';
      jswMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
      jswMetadata['Subject'] = 'JSWROI';
      jswMetadata['Timepoint'] = '';
      jswMetadata['SeriesId'] = (cell as any).seriesId;
      jswMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];
      jswMetadata['CalcVerticalDistance'] = isKnee.toString();

      setTimeout(() => {
        this.runActionCommand('OnDrawJsw', info);
      }, 0);
    } catch (error) {
      console.log('>>> error ... ', error);
    }
  }

  DrawKma(info) {
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const annAutoManager: lt.Annotations.Automation.AnnAutomationManager = cell.get_automationManager();
      const kmaObject: lt.Annotations.Automation.AnnAutomationObject = annAutoManager.findObjectById(AnnKmaObject.kmaObjectId);
      const freehandTemplate: any = kmaObject.objectTemplate;
      const kmaMetadata: any = freehandTemplate.metadata;

      kmaMetadata['AnnoType'] = 'AnnKmaObject';
      kmaMetadata['Area'] = '0';
      kmaMetadata['SliceNumber'] = '1';
      kmaMetadata['FrameNumber'] = '1';
      kmaMetadata['FrameCount'] = '1';
      kmaMetadata['CreateMethod'] = 'Manual';
      kmaMetadata['LastEditedOn'] = this.utils.getCurrentDatetime();
      kmaMetadata['Subject'] = 'KMAROI';
      kmaMetadata['Timepoint'] = '';
      kmaMetadata['SeriesId'] = (cell as any).seriesId;
      kmaMetadata['SopInstanceUid'] = (cell as any).SOPInstanceUIDs[0];

      setTimeout(() => {
        this.runActionCommand('OnDrawKma', info);
      }, 0);
    } catch (error) {
      console.log('>>> error ... ', error);
    }
  }

  setSelectedRegions(selectedRegions) {
    try {
      try {
        this.viewerComponent.selectedRegions = selectedRegions;
      } catch (error) {
        console.log('Error... ', error);
      }
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      const visitConfigId = seriesInfo.visitConfigId;
      const regions: string[] = selectedRegions;
      const containers = cell.get_automation().get_containers().toArray();

      this.seriesManagerService.allowSavingActiveContainer = false;
      for (let i = 0; i < containers.length; i++) {
        const container: lt.Annotations.Engine.AnnContainer = containers[i];
        container.get_children().toArray().forEach((annObject: lt.Annotations.Engine.AnnObject) => {
          const regionToScore = annObject.get_metadata()['regionToScore'];
          if (regionToScore != null && regionToScore != undefined) {
            if (regions.includes(regionToScore)) {
              annObject.set_isVisible(true);
            } else {
              annObject.set_isVisible(false);
            }
          }
        });
      }
      this.seriesManagerService.allowSavingActiveContainer = true;

      this.asyncResizeTrigger();

    } catch (error) {
      console.log('>>> error ... ', error);
      this.seriesManagerService.allowSavingActiveContainer = true;
    }
  }

  OnSliceSync() {
    try {
      this.sliceSync = !this.sliceSync;
      if (this.sliceSync) {
        this.viewerToolbar.setItemProperty('SliceSync', 'tooltip', 'Disable Slice Sync');
      } else {
        this.viewerToolbar.setItemProperty('SliceSync', 'tooltip', 'Enable Slice Sync');
      }
      this.overlayManagerService.sliceSyncStatus = this.sliceSync;
    } catch (error) {

    }
  }

  OnVisualComparison(sliceRates?) {
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      console.log(cell);
      // if(cell.get_tickBoxes())
      console.log(cell.get_tickBoxes());
      cell.set_borderThickness(4);
      // cell.get_progress().setColor(0, 255, 0);
      cell.set_selectedBorderColor('rgb(0, 255, 0)');

      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 30);
    } catch (error) {
      console.log('>>> error ...', error);
    }
  }

  asyncResizeTrigger() {
    // console.log('asyncResizeTrigger...');
    this.utils.syncResizeOn = Date.now();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  OnRapidAction(info) {
    try {
      if (!this.isRapidAction) {
        this.isRapidAction = true;
        if (this.utils.isDemriqProject()) {
          if (this.activeJoint && this.activeJoint.color !== '') {
              this.drawCustomFreehand(this.activeJoint.color, this.activeJoint.regionToScore, this.activeJoint.primaryLocation, info);
          }
        }
        this.viewerToolbar.setItemProperty('RapidAction', 'tooltip', 'Disable Rapid Drawing');
      } else {
        this.isRapidAction = false;
        this.viewerToolbar.setItemProperty('RapidAction', 'tooltip', 'Enable Rapid Drawing');
        info.annType = 'Select';
        this.setAnnTool(MedicalViewerAction.AnnSelect, info);
      }
    } catch (error) {
      console.log('>>> error ...', error);
    }
  }

  OnConfirmMarker(info) {
    try {
      console.log('>>> OnConfirmMarker... ');
      this.viewerComponent = info.viewerComponent;
      if (this.viewerComponent != null && this.viewerComponent != undefined) {
        this.viewerComponent.confirmMarker();
      }
    } catch (error) {
      console.log('>>> error ...', error);
    }
  }

  OnRemoveMarker(info) {
    try {
      console.log('>>> OnRemoveMarker... ');
      const activeCell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const cellAutomation = activeCell.get_automation();
      const currentEditObject: lt.Annotations.Engine.AnnObject = cellAutomation.get_currentEditObject();
      if (currentEditObject != null) {
        cellAutomation.deleteSelectedObjects();
      }
      this.asyncResizeTrigger();
    } catch (error) {
      console.log('>>> error ...', error);
    }
  }

  OnAddMarker(info, markerType) {
    try {
      console.log('>>> OnAddMarker... ');
      this.isAddingMarker = true;
      this.markerType = markerType;
    } catch (error) {
      console.log('>>> error ...', error);
    }
  }

  async OnCopyROI(info) {
    try {
      console.log('>>> OnCopyROI... ');
      const viewerComponent = info.viewerComponent;
      viewerComponent.isViewerActionInProgress = true;
      viewerComponent.viewerProgressActionText = `Copying ROIs is in process...`;
      const activeCell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const cellAutomation = activeCell.get_automation();
      const currentEditObject: lt.Annotations.Engine.AnnObject = cellAutomation.get_currentEditObject();
      const currentOffset = activeCell.get_currentOffset();
      const currentSlice = parseInt((document.getElementById(activeCell.divID + '_slice-tracker_current') as HTMLInputElement).value, 10);
      const sliceCount = this.overlayManagerService.cellOverlays[activeCell.divID].sliceCount;
      const currentFrame = parseInt(((currentOffset / sliceCount) + 1) + '');

      // stop if any ROI is not selected
      if (!currentEditObject) {
        this.toastOptions.title = 'Not selected';
        this.toastOptions.msg = 'Select a ROI';
        this.toastyService.warning(this.toastOptions);
        viewerComponent.isViewerActionInProgress = false;
        return;
      }

      // stop if entered range is invalid
      const containers = cellAutomation.get_containers().toArray();
      if (info.to > sliceCount
        || info.from < 1
        || info.from > info.to) {
          this.toastOptions.title = 'Invalid range';
          this.toastOptions.msg = 'Selected range is invalid';
          this.toastyService.warning(this.toastOptions);
          viewerComponent.isViewerActionInProgress = false;
          return;
      }

      // set GroupId: GroupId get from source ROI guid
      if (!currentEditObject.metadata.GroupId) {
        currentEditObject.metadata.GroupId = currentEditObject.guid;
      }

      // copy ROIs in the entered range
      const rangeStart = ((currentFrame - 1) * sliceCount) + info.from;
      const rangeEnd = ((currentFrame - 1) * sliceCount) + info.to;
      for (let j =  rangeStart - 1; j <  rangeEnd; j++) {

        const container: lt.Annotations.Engine.AnnContainer = containers[j];
        if (container.children.toArray().findIndex(x => x.metadata.GroupId === currentEditObject.metadata.GroupId) === -1) {
            const new_child: lt.Annotations.Engine.AnnObject = currentEditObject.clone();
            const newSliceNumber = j + 1;

            new_child.get_metadata().Copied = 'true';
            new_child.get_metadata()['NewSliceNumber'] = newSliceNumber + '';
            new_child.get_metadata()['SliceNumber'] = newSliceNumber + '';
            new_child.set_guid(null);
            activeCell.set_currentOffset(j);

            container.children.add(new_child);

            await this.seriesManagerService.saveActiveContainerROIs(info).then((result) => {
              console.log(result);
            });
        }
      }

      viewerComponent.rowData2 = this.seriesManagerService.autoVolumeCalculations(info, activeCell, currentEditObject.metadata.Label);

      this.asyncResizeTrigger();
      viewerComponent.isViewerActionInProgress = false;

    } catch (error) {
      info.viewerComponent.isViewerActionInProgress = false;
    }
  }

  async OnDeleteROI(info) {
    try {
      console.log('>>> OnDeleteROI... ');
      const viewerComponent = info.viewerComponent;
      viewerComponent.isViewerActionInProgress = true;
      viewerComponent.viewerProgressActionText = `Deleting ROIs is in process...`;
      const activeCell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const cellAutomation = activeCell.get_automation();
      const currentEditObject: lt.Annotations.Engine.AnnObject = cellAutomation.get_currentEditObject();
      const objectMetadata = currentEditObject.get_metadata();
      let objectGroupID = '';
      if (objectMetadata.GroupId && objectMetadata.GroupId !== undefined && objectMetadata.GroupId !== '') {
        objectGroupID = objectMetadata.GroupId;
      } else {
        objectGroupID = currentEditObject.guid;
      }
      const currentOffset = activeCell.get_currentOffset();
      const currentSlice = parseInt((document.getElementById(activeCell.divID + '_slice-tracker_current') as HTMLInputElement).value, 10);
      const sliceCount = this.overlayManagerService.cellOverlays[activeCell.divID].sliceCount;
      const currentFrame = parseInt(((currentOffset / sliceCount) + 1) + '');
      let roiGUID = '';

      // stop if any ROI is not selected
      if (!currentEditObject) {
        this.toastOptions.title = 'Not selected';
        this.toastOptions.msg = 'Select a ROI';
        this.toastyService.warning(this.toastOptions);
        viewerComponent.isViewerActionInProgress = false;
        return;
      }

      // stop if entered range is invalid
      const containers =  cellAutomation.get_containers().toArray();
      if (info.to > containers.length
        || info.from < 1
        || info.from > info.to) {
          this.toastOptions.title = 'Invalid range';
          this.toastOptions.msg = 'Selected range is invalid';
          this.toastyService.warning(this.toastOptions);
          viewerComponent.isViewerActionInProgress = false;
          return;
      }

      // delete ROIs in the entered range
      const rangeStart = ((currentFrame - 1) * sliceCount) + info.from;
      const rangeEnd = ((currentFrame - 1) * sliceCount) + info.to;
      for (let i = rangeStart - 1; i < rangeEnd; i++) {
        const container: lt.Annotations.Engine.AnnContainer = containers[i];

        activeCell.set_currentOffset(i);

        container.children.toArray().filter(x => (x.metadata.GroupId && x.metadata.GroupId === objectGroupID) || (x.metadata.GroupId === undefined && x.guid === objectGroupID))
          .forEach(x => {
            roiGUID = x.guid;
            container.children.remove(x);
        });

        await this.seriesManagerService.saveActiveContainerROIs(info, roiGUID).then((result) => {
          console.log(result);
        });
      }
      viewerComponent.rowData2 = this.seriesManagerService.autoVolumeCalculations(info, activeCell, currentEditObject.metadata.Label);

      this.asyncResizeTrigger();
      viewerComponent.isViewerActionInProgress = false;

    } catch (error) {
      info.viewerComponent.isViewerActionInProgress = false;
    }
  }

  CustomUpload(info) {
    try {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getActiveCell();
      const viewerComponent = info.viewerComponent;
      const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
      const seriesId = seriesInfo.seriesId;
      const seriesinstanceUID = seriesInfo.seriesInstanceUID;
      const bucket = seriesInfo.bucket;
      viewerComponent.isJSWProject = true;
      var auditData = info.viewerComponent.getSeriesAuditData(seriesId);
      this.objectRetrieveService.customUpload(bucket, seriesId, seriesinstanceUID, auditData).subscribe((resultT: any) => {
        if (resultT ===  'DONE') {
          console.log('ReUpload is DONE...');
          try {
            viewerComponent.removeCell(cell);
          } catch (error) {

          }
        }
      });

    } catch (error) {
      console.log('>>> error ... ', error);
    }
  }
}
