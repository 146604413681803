import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DragDropFilesComponent} from '../../DataUpload/drag-drop-files/drag-drop-files.component';
import {
  AdjudicationDataImportInfo,
  AdjudicationExternalConfigDialogComponent, UploadAdjFileDataItem
} from '../adjudication-external-config-dialog/adjudication-external-config-dialog.component';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {MatTableDataSource} from "@angular/material/table";
import {PatientService} from "../../../_services/patient.service";

export interface UploadAdjFileDialogData {
  studyId: number;
  service: AdjudicationExternalServiceName;
}

@Component({
  selector: 'app-upload-adj-file-dialog',
  templateUrl: './upload-adj-file-dialog.component.html',
  styleUrls: ['./upload-adj-file-dialog.component.css']
})
export class UploadAdjFileDialogComponent implements OnInit {

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  @ViewChild('dragDropFile') selectedFile: DragDropFilesComponent;

  emptyFileContainer = true;
  dropOver = false;
  files = [];
  _csvOnly = true;
  showCsvResults = false;
  importingStatus: string;
  IMPORTING_STATUS_SUCCESS = 'getting adjudication results success';
  IMPORTING_STATUS_FAILURE = 'getting adjudication failure';

  constructor(public dialogRef: MatDialogRef<UploadAdjFileDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UploadAdjFileDialogData,
              private dialog: MatDialog,
              private toastyService: ToastyService,
              private patientService: PatientService
  ) {
    this._csvOnly = true;
    this.showCsvOnlyWarning = false;
    this.showCsvResults = false;
    this.showOneFileOnlyWarning = false;
  }

  studyId: number;
  showCsvOnlyWarning = false;
  showOneFileOnlyWarning = false;
  fileRead: any;
  fileData: UploadAdjFileDataItem[] = [];

  ngOnInit(): void {
    this.studyId = this.data.studyId;
  }

  save(): void {
    this.fileRead = this.files[0];
    this.showToast('ID 122: Import process started successfully',
      'Files are uploading. When uploading is done and measurements remain retrieved then you see the measurement table updated',
      true);
    this.processExternalFile(this.fileRead);
  }

  processExternalFile(unprocessedFileData: any) {
    const reader: FileReader = new FileReader();
    reader.readAsText(unprocessedFileData);

    reader.onload = () => {
      const csv = reader.result.toString();
      const allTextLines = csv.split(/[\r\n\r]/).filter(line => line !== '');
      const headers = allTextLines[0].split(',');
      if (!allTextLines || allTextLines.length < 2) {
        this.importingStatus = this.IMPORTING_STATUS_FAILURE;
        this.showToast(
          'ID 124: Import process failed',
          'Parsing process failed due to some reason. Try again or contact support team',
          false);
        return;
      }
      if (allTextLines.length > 1000) {
        this.importingStatus = this.IMPORTING_STATUS_FAILURE;
        this.showToast(
          'ID 124: Import process failed',
          'Parsing process failed due to some reason. Try again or contact support team',
          false);
        return;
      }
      if (headers.length !== 2) {
        this.importingStatus = this.IMPORTING_STATUS_FAILURE;
        this.showToast('ID 124: Import process failed',
          'Parsing process failed due to some reason. Try again or contact support team',
          false);
        return;
      }

      for (let i = 1; i < allTextLines.length; i++) {
        const data = allTextLines[i].split(',');
        if (data.length === headers.length) {
          const element: UploadAdjFileDataItem = {
            patientCode: data[0],
            adjudicationReadingStatus: data[1] === 'true'
          };
          this.fileData.push(element);
        } else {
          this.importingStatus = this.IMPORTING_STATUS_FAILURE;
          this.showToast(
            'ID 124: Import process failed',
            'Parsing process failed due to some reason. Try again or contact support team',
            false);
          return;
        }
      }
      this.patientService.validatePatientsByStudyIdAndPatientCodes(this.studyId, this.fileData.map(entry => entry.patientCode))
        .subscribe(resp => {
          if (resp.responseCode === 200 && resp.data) {
            this.showToast(
              'ID 123: Import process finished successfully',
              'Files are successfully uploaded and measurements are retrieved. Find the results in the measurement table',
              true
            );
            this.importingStatus = this.IMPORTING_STATUS_SUCCESS;
            this.showApplyResultsDialog();
          } else {
            this.showToast(
              'ID 124: Import process failed',
              'Parsing process failed due to some reason. Try again or contact support team',
              false
            );
            this.importingStatus = this.IMPORTING_STATUS_FAILURE;
          }
      });
    };
  }

  readFiles(files) {
    this.showCsvOnlyWarning = false;
    this.showOneFileOnlyWarning = false;
    if (this.files.length === 1) {
      this.showOneFileOnlyWarning = true;
      return;
    }
    for (let i = 0; i < files.length; i++) {
      if (this._csvOnly) {
        const fileName = files[i].name;
        if (fileName.toLowerCase().endsWith('.csv')) {
          this.files.push(files[i]);
        } else {
          this.showCsvOnlyWarning = true;
        }
      } else {
        this.files.push(files[i]);
      }
    }
  }

  showToast(title: string, description: string, isSuccess: boolean) {
    this.toastOptions.title = title;
    this.toastOptions.msg = description;
    if (isSuccess) {
      this.toastyService.success(this.toastOptions);
    } else {
      this.toastyService.error(this.toastOptions);
    }
  }

  dragOver(e) {
    this.dropOver = true;
    return false;
  }

  dragEnded() {
    this.dropOver = false;
  }

  dragLeave() {
    this.dropOver = false;
  }

  dropFile(e) {
    e.preventDefault();
    e.stopPropagation();
    this.dropOver = false;
    this.readFiles(e.dataTransfer.files);
    return false;
  }

  clearFiles(event) {
    this.files = [];
    this.showOneFileOnlyWarning = false;
  }

  selectFiles(event) {
    this.showCsvOnlyWarning = false;
    this.showOneFileOnlyWarning = false;
    if (this.files.length === 1) {
      this.showOneFileOnlyWarning = true;
      return;
    }
    if (event.target.files) {
      for (let i = 0; i < event.target.files.length; i++) {
        if (this._csvOnly === true) {
          const fileName = event.target.files[i].name;
          if (fileName.toLowerCase().endsWith('.csv') === true) {
            this.files.push(event.target.files[i]);
          } else {
            this.showCsvOnlyWarning = true;
          }
        } else {
          this.files.push(event.target.files[i]);
        }
      }
    }
    event.preventDefault();
    event.target.value = '';
  }

  close(resp: any) {
    this.dialogRef.close(resp);
  }

  downloadExample() {
    const data = [
        {
          'Patient' : '001-998',
          'Adjudication reading status': 'true'
        },
        {
          'Patient' : '001-999',
          'Adjudication reading status': 'false'
        }
    ];
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    let csvArray: string = csv.join('\r\n');
    csvArray = csvArray.replace(/"/g, '');
    const a = document.createElement('a');
    const blob = new Blob([csvArray], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    a.href = url;
    a.download = AdjudicationExternalServiceName[this.data.service] + ' adjudication external analysis file.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  private showApplyResultsDialog() {
    const data: AdjudicationDataImportInfo = {
      fileData: this.fileData,
      status: this.importingStatus,
      studyId: this.studyId,
      service: this.data.service
    };

    const dialogRef = this.dialog.open(AdjudicationExternalConfigDialogComponent, {
      width: '700px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.close(result);
    });
  }
}

export enum AdjudicationExternalServiceName {
  WBMRI, PSAMRIS
}
