import { MranoEfficacyOncologyResponseAssessmentDialogComponent } from './../mrano-efficacy-oncology-response-assessment-dialog/mrano-efficacy-oncology-response-assessment-dialog.component';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { GlobalLesionModel, OncologyLesionType, OncologyResponseEnum, OncologyResponseModel, OncologyResponseType, ResponseOptions, MranoEfficacyLesionSetting } from 'src/app/_models/Oncology/global-lesion-model';
import { OncologyAssessmentVisitModel } from 'src/app/_models/Oncology/oncology-assessment-visit-model';
import { OncologyAssessmentService } from 'src/app/_services/oncology-assessment.service';
import { forkJoin, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-mrano-efficacy-dynamic-scoring-form',
  templateUrl: './mrano-efficacy-dynamic-scoring-form.component.html',
  styleUrls: ['./mrano-efficacy-dynamic-scoring-form.component.css']
})

export class MranoEfficacyDynamicScoringFormComponent implements OnInit ,OnDestroy {
  constructor(
    private toastyService: ToastyService,
    private oncologyAssessmentService: OncologyAssessmentService,
    private dialog: MatDialog
  ) { }

  listOfLesions: GlobalLesionModel[];
  readerId: number;
  responseType = OncologyResponseType;
  responseEnum = OncologyResponseEnum;
  reaponseOptions = ResponseOptions;
  allResponses: any = {};
  lesionsReviewed: any = {};
  MranoEfficacyLesionSetting = MranoEfficacyLesionSetting;
  primaryLocations = [];


  @Input() studyId: number;
  @Input() readingId: number;
  @Input() patientId: number;
  @Input() eCRFOnly: boolean;
  @Input() visit: OncologyAssessmentVisitModel;
  @Input() visits: OncologyAssessmentVisitModel[];
  @Input() sequentialLock: boolean;
  @Input() endpointName: string;
  @Input() lock: boolean;
  @Output('visitLock') visitLock: EventEmitter<any> = new EventEmitter<any>();
  lesionListSubscription: Subscription;
  lesionReviewedSubscription: Subscription;
  locationSubscription: Subscription;
  canComputeResponse: boolean = false;
  baselineNadirSubscription: Subscription;
  nadirSPPD;
  baselineSPPD;
  historicalNadir: any[];
  nadirVisitConfigId: number;
  allLesionSubscription: Subscription;
  readingAllLesions: any[];
  public get OncologyLesionType(): typeof OncologyLesionType {
    return OncologyLesionType;
  }

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  ngOnInit(): void {
    this.readerId = +JSON.parse(localStorage.getItem('userId'));
    this.oncologyAssessmentService.modalSpinnerChangeState(true);
    this.getLesionLists();

    this.lesionListSubscription = this.oncologyAssessmentService.refereshLesionList.subscribe(visitConfigId => {
      if (visitConfigId == this.visit.visitConfigId) {
        this.getLesionLists();
      }
    })

    this.lesionReviewedSubscription = this.oncologyAssessmentService.lesionReviewed.subscribe(result => {
      if (result) {
        if (result.visitConfigId === this.visit.visitConfigId)
        this.lesionsReviewed[result.status.type] = result.status.reviewed;
      }
    })

        //get all lesions
    this.allLesionSubscription = this.oncologyAssessmentService.allLesions.subscribe(response => {
      if (response) {
        this.readingAllLesions = response;
        }
      })

    this.baselineNadirSubscription = this.oncologyAssessmentService.baselineNadirSPPD.subscribe(data => {
      if (data) {
        this.nadirSPPD = data.nadirSPPD;
        this.baselineSPPD = data.baselineSPPD;
        this.historicalNadir = data.historicalNadir;
        this.nadirVisitConfigId = data.nadirVisitConfigId
      }
    })

    this.locationSubscription = this.oncologyAssessmentService.location.subscribe(
      (response)=>{
        if(response){
          this.primaryLocations = response;
        }
      }
    )
  }
  ngOnDestroy(): void{
    if (this.lesionListSubscription)
      this.lesionListSubscription.unsubscribe();
    if (this.lesionReviewedSubscription)
      this.lesionReviewedSubscription.unsubscribe();
    if (this.lesionListSubscription)
      this.lesionListSubscription.unsubscribe();
    if (this.allLesionSubscription)
      this.allLesionSubscription.unsubscribe();
  }
  showResponseOptions() {
    return this.reaponseOptions[this.responseType.OVERALL];
  }
  getLesionLists() {
    this.oncologyAssessmentService.modalSpinnerChangeState(true);
    this.oncologyAssessmentService.getLesionListByVisitConfigId(
      this.studyId,
      this.readingId,
      this.endpointName,
      this.visit.visitConfigId
    ).subscribe(resp => {
      this.listOfLesions = resp['data'] as GlobalLesionModel[];
      this.oncologyAssessmentService.addToAllLesions(this.listOfLesions);
      this.oncologyAssessmentService.modalSpinnerChangeState(false);
    })
  }
  getAllResponses(generate: boolean) {
    this.oncologyAssessmentService.modalSpinnerChangeState(true);
    this.oncologyAssessmentService.getResponsesByVisitConfigId(this.studyId, this.endpointName, this.readingId,this.readerId, this.visit.visitConfigId,generate).subscribe(resp => {
      this.allResponses = resp['data'];
      this.oncologyAssessmentService.modalSpinnerChangeState(false);
    })
  }
  onChangeResponse(event, type?: OncologyResponseType) {
    if (!this.allResponses.id) {
      this.toastOptions.title = 'INFO: Responses are not generated'
      this.toastOptions.msg = 'Responses must be generated before change'
      this.toastyService.info(this.toastOptions);
      return;
    }
    this.oncologyAssessmentService.modalSpinnerChangeState(true);
    const data: OncologyResponseModel ={
      id: this.allResponses.id,
      endpointName: this.endpointName,
      readingId: this.readingId,
      response: event.value,
      type: type? type : this.responseType.OVERALL,
      userId: this.readerId,
      visitConfigId: this.visit.visitConfigId
    }
    this.oncologyAssessmentService.updateResponsesByVisitConfigId(this.studyId, data).subscribe(response => {
      this.oncologyAssessmentService.modalSpinnerChangeState(false);
    })
  }
  onChangeTableResponse(event) {
    this.onChangeResponse(event.value,event.type)
  }
  checkAllLesionsReviewed() {
    let count = 0
    this.MranoEfficacyLesionSetting.allLesionTypes.forEach(type => {
      if (this.lesionsReviewed[type] === false)
        count++;
    });
    return count === 0;
  }
  onGenerateAllResponses() {
    this.getAllResponses(true);
  }
  onScoreLock() {
      this.oncologyAssessmentService.modalSpinnerChangeState(true);
    if (this.visit.baseline)
      this.openResponseAssessmentDialog()
    else
      this.oncologyAssessmentService.getEfficacyResponsesByVisitConfigId(this.studyId, this.readingId, this.readerId, this.visit.visitConfigId).subscribe(resp => {
        this.openResponseAssessmentDialog()
     });
  }
  openResponseAssessmentDialog() {
    const serviceSources = [
      this.oncologyAssessmentService.getEfficacyOverallResponse(this.studyId, this.readingId),
      this.oncologyAssessmentService.getAllLesions(this.studyId, this.readingId, this.endpointName),
    ];
    forkJoin(serviceSources).subscribe(
      values => {
        const [allResponses, allLesions] = values as any[];
        if (allResponses['responseCode'] === 200 && allLesions['responseCode'] === 200) {
          this.oncologyAssessmentService.modalSpinnerChangeState(false);
          const dialogRef = this.dialog.open(MranoEfficacyOncologyResponseAssessmentDialogComponent, {
            width: '900px',
            height: '800px',
            panelClass: 'response-dialog-container',
            data: {
              allLesionTypes: this.MranoEfficacyLesionSetting.allLesionTypes,
              studyId: this.studyId,
              readingId: this.readingId,
              readerId: this.readerId,
              endpointName: this.endpointName,
              visit: this.visit,
              visits: this.visits,
              baselineSPPD: this.baselineSPPD,
              nadirSPPD: this.nadirSPPD,
              historicalNadir: this.historicalNadir,
              allResponses: allResponses['data'],
              allLesions: allLesions['data'],
              primaryLocations: this.primaryLocations,
              nadirVisitConfigId: this.nadirVisitConfigId
            },
            disableClose: true,
            hasBackdrop: false
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result.submit) {
              this.lockVisit(result.multiTP);
            }
          });
        }
      });
  }
  lockVisit(multiTP:boolean = false) {
    const data = {
      visitConfigId: this.visit.visitConfigId,
      measurableBrain: this.listOfLesions,
      lock: true,
      multiTP: multiTP
    };
    this.visitLock.emit(data);
  }
  checkLocked() {
    return  this.sequentialLock || this.lock
  }
  comparisonSPPD(sppd:any){
    let sppdComparison = 0;
    if (sppd == 'NA' || sppd === null || sppd === undefined)
      return 'NA';
    if (sppd == 'NE' || sppd === 0)
      return 'NE';
    sppd = +sppd.toFixed(2);
    if (this.readingAllLesions) {
      const measurableLesions = this.readingAllLesions.filter(l => { return l.isMeasurable && l.visitConfigId === this.visit.visitConfigId})
      const currentSPPD = this.sppdCalculation(measurableLesions);
      if (currentSPPD >= 0)
        sppdComparison = (currentSPPD - sppd) * 100 / sppd;
    }
    return  +sppdComparison.toFixed(2);
  }
  comparisonNadirSPPD() {
    if (this.nadirVisitConfigId === this.visit.visitConfigId)
      return 0;
    if (!this.historicalNadir)
      return 'NA';

    const nadir = this.historicalNadir.find(hn => hn.visitConfigId === this.visit.visitConfigId);
    if (!nadir)
      return 'NA';
    
    if (nadir.nadirVisitConfigId === this.visit.visitConfigId)
      return 0;
      
    var sppd = nadir.nadirVisitTotalPpd;
    if (sppd == 'NA' || sppd === null || sppd === undefined)
      return 'NA';
    if (sppd == 'NE' || sppd === 0)
      return 'NE';

    sppd = +sppd.toFixed(2);
    
    let sppdComparison = 0;    
    if (this.readingAllLesions) {
      const measurableLesions = this.readingAllLesions.filter(l => { return l.isMeasurable && l.visitConfigId === this.visit.visitConfigId })
      const currentSPPD = this.sppdCalculation(measurableLesions);
      if (currentSPPD >= 0) {
        sppdComparison = (currentSPPD - sppd) * 100 / sppd;
      }
    }
    return +sppdComparison.toFixed(2);
  }

  sppdCalculation(lesionSource:GlobalLesionModel[]){
    let sppd = 0;
    if(lesionSource)
      lesionSource.forEach(l => {
        let ppd = l.ppd;
        if (ppd && ppd !== 'NA' && ppd !== 'NE' && l.isMeasurable &&
        (l.status === 'PRESENT' || l.type === this.OncologyLesionType.TARGET_LESION))
          sppd += +ppd;
      });
    return +sppd;
  }

}
