import { Observable } from 'rxjs';
import { QueryDEMRIQ, QueryGradient, QueryOptions, QueryROI, QueryVOI } from 'src/app/_models/viewer/QueryOptions';
/**
 * @description
 * Local query service interface for Dynamika image visualization component.
 *
 */

export abstract class QueryArchiveService {

    /**
     * Find instances of a series as a JSON string. Can be used to build instances stacks when loading series
     * @param queryParams
     * @param stackInstanceUID
     * @param maxQueryResults
     */
    abstract findInstances(queryParams: QueryOptions, stackInstanceUID?, maxQueryResults?: number): Observable<any>;
    /**
     * Find instances of a DCE-MRI series as a JSON string.
     * @param queryParams
     * @param seriesInstanceUIDs
     * @param maxQueryResults
     * @param useCache
     * @param seriesID
     */
    abstract findDCEInstances(queryParams: QueryOptions, seriesInstanceUIDs?, maxQueryResults?: number, useCache?, seriesID?): Observable<any>;
    /**
     * Find ROI statistics
     * @param queryROIs
     */
    abstract findROIStatistics(queryROIs: QueryROI[]): Observable<any>;
    /**
     * Find VOI statistics
     * @param queryVOIs
     */
    abstract findVOIStatistics(queryVOIs: QueryVOI[]): Observable<any>;
    /**
     * Find DEMRIQ data
     * @param queryROIs
     * @param queryVOIs
     */
    abstract findDEMRIQData(queryROIs: QueryROI[], queryVOIs: QueryVOI[]): Observable<any>;
    /**
     * Fetch DEMRIQ data
     * @param queryDEMRIQs
     */
    abstract fetchDEMRIQData(queryDEMRIQs: QueryDEMRIQ[], isAdvancedAnalysis: boolean): Observable<any>;
    /**
     * Find gradient or colour bar data
     * @param queryGradient
     */
    abstract findGradientData(queryGradients: QueryGradient[]): Observable<any>;
    /**
     * Find VOI volume
     * @param queryVOIs
     */
     abstract findVOIVolume(queryVOIs: QueryVOI[]): Observable<any>;
    /**
     * Find VOI volume
     * @param queryVOIs
     */
     abstract findVOIVolume(queryVOIs: QueryVOI[]): Observable<any>;
    /**
     * Get URI for a panoramic 3D image
     */
    abstract getPanoramic3DImage(): string;
    /**
     * Start 3D Object reconstruction
     * @param query
     * @param id
     * @param renderingMethod
     * @param sopInstanceUID
     */
    abstract start3DObject(query, id, renderingMethod, sopInstanceUID): Observable<any>;
    /**
     * Check progress of the 3D Object reconstruction process
     * @param id
     */
    abstract checkProgress(id): Observable<any>;
    /**
     * Keep alive the 3D Object reconstruction process
     * @param id
     */
    abstract keepAlive(id): Observable<any>;
    /**
     * Close the 3D Object reconstruction process
     * @param id
     */
    abstract close3DImage(id): Observable<any>;
    /**
     * Update 3D settings
     * @param JSON
     * @param id
     */
    abstract update3DSettings(JSON, id): Observable<any>;
    /**
     * Get 3D settings
     * @param JSON
     * @param id
     */
    abstract get3DSettings(JSON, id): Observable<any>;
    /**
     * Get URI of the 3D image
     */
    abstract get3DImage(): string;
    /**
     * Find presentation state for a referenced series selected.
     * It gives the cell annotations
     * @param referencedSeries
     */
    abstract findPresentationState(referencedSeries:string);
}

