import { MranoEligibilityScoringModel } from "./mrano-eligibility-scoring-model";
import { MranoHistoricalScoringModel } from "./mrano-historical-scoring-model";

export class MranoScoringModel {
    historical:MranoHistoricalScoringModel;
    eligibility:MranoEligibilityScoringModel;
}
export enum MRanoScoringType{
    HISTORICAL = 'HISTORICAL',
    ELIGIBILITY = 'ELIGIBILITY',
    VISIT = 'VISIT'
}
export enum MRanoLesionType{
    MEASURABLE = 'Measurable',
    NON_TARGET = 'Non_Target',
    NON_TARGET_ENHANCING = 'Non_Target',
    NON_TARGET_NON_ENHANCING = 'Non_Enhancing'
}
export enum MRanoLesionObject{
    TARGET_LESION = 'TARGET',
    NON_TARGET_LESION = 'NON_TARGET',
    MEASURABLE = 'MEASURABLE',
    NON_MEASURABLE = 'NON_MEASURABLE',
    NEW_LESION = 'NEW_LESION',
    MIDLINESHIFT = 'MIDLINESHIFT',
}
export enum MRanoLesionEditType{
    COMMENT = 'COMMENT',
    LOCATION = 'LOCATION',
    MEASUREMENT = 'MEASUREMENT',
    SCREENSHOT = 'SCREENSHOT'
}