import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MranoHistoricalScoringModel } from 'src/app/_models/MRANO/mrano-historical-scoring-model';
import { MranoLesionModel } from 'src/app/_models/MRANO/mrano-lesion-model';
import { MRanoLesionObject, MRanoLesionType, MRanoScoringType } from 'src/app/_models/MRANO/mrano-scoring-model';
import { BrainLesionsTableComponent } from '../brain-lesions-table/brain-lesions-table.component';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { Subscription } from 'rxjs';
import { DataUploadService } from 'src/app/_services';

@Component({
  selector: 'app-mrano-historical-form',
  templateUrl: './mrano-historical-form.component.html',
  styleUrls: ['./mrano-historical-form.component.css']
})

export class MranoHistoricalFormComponent implements OnInit , OnDestroy{

  constructor(private toastyService: ToastyService,
    private dataUploadService: DataUploadService) {

  }

  mranoLesionType = MRanoLesionType;
  mranoScoringType = MRanoScoringType;
  mranoLesionObject = MRanoLesionObject;
  historicalForm: FormGroup;
  listOfLesions: MranoLesionModel[] = [];
  totalVolume;
  sppd;
  getUploadedDocuments: Subscription;
  uploadedFiles: any[];
  @Input('studyId') studyId: number;
  @Input('readingId') readingId: number;
  @Input('readerId') readerId: number;
  @Output('scorLocked') scorelocked: EventEmitter<any> = new EventEmitter<any>();
  @Input('locked') formLocked: boolean;
  @Input('screenShotData') screenShotData: any;
  @Input('eCRFOnly') eCRFOnly: boolean;
  @Input('historicalScan') historicalScan: MranoHistoricalScoringModel;
  @Input('enableVolume') enableVolume: boolean;
  @Input('timepoint') timepoint: string;
  @Input('visitConfigId') visitConfigId: number;
  @Input('protocolVersion') protocolVersion: string;
  @Output('openLTViewer') openLTViewer: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('measurableLesionTable') measurableLesionTable: BrainLesionsTableComponent;
  @ViewChild('nonMeasurableLesionTable') nonMeasurableLesionTable: BrainLesionsTableComponent;
  scoringLockData: MranoHistoricalScoringModel;
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };


  ngOnInit(): void {
    this.historicalForm = new FormGroup({
      imageQuality: new FormControl('', [Validators.required]),
      imageQualityComment: new FormControl({ value: '', disabled: this.historicalScan.lock }),
      totalVolume: new FormControl({ value: null, disabled: this.historicalScan.lock })
    });

    if (this.historicalScan) {
      this.historicalForm.get('imageQuality').setValue(this.historicalScan.imageQualityAssessment);
      this.historicalForm.get('imageQualityComment').setValue(this.historicalScan.imageQualityAssessmentComment);
      this.historicalForm.get('totalVolume').setValue(this.historicalScan.totalVolume);
    }

    if (this.historicalScan.uploadResourceType === 'DOCUMENT'){
      //must be changed with new api
      this.getUploadedDocuments = this.dataUploadService.getDucumentDownloadLinks(this.studyId, this.visitConfigId).subscribe(response =>{
        if(response['responseCode'] === 200){
          this.uploadedFiles = (response['data'] as any[]).map(link => {
            return {
              link: link,
              name: link.toString().split('?')[0].split('/').reverse()[0]
          }});
        }
      })
    }
  }

  ngOnDestroy(): void {
    if(this.getUploadedDocuments)
    this.getUploadedDocuments.unsubscribe();
  }

  validateNumber(event, maxNumber) {
    const keyCode = event.keyCode;
    if (keyCode == 16)
      event.preventDefault()

    if (event.target.value.length == maxNumber && keyCode != 8)
      event.preventDefault()

    const excludedKeys = [8, 37, 39, 46, 190];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode > 64 && keyCode < 91) ||
      (keyCode == 32) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  onUpdateLesions(data) {
    data.forEach((l, i) => {
      const lesionindex = this.listOfLesions.findIndex(lesion => lesion.id === l.id);
      if (lesionindex >= 0) {
        this.listOfLesions[lesionindex] = l;
      }
      else
        this.listOfLesions.push(l)
    })
    this.calculateSummary();
  }
  onDeletedLesion(lesion: MranoLesionModel) {
    this.listOfLesions = this.listOfLesions.filter(l => l.id !== lesion.id);
    this.calculateSummary();
  }
  calculateSummary() {
    this.sppd = this.sppdCalculation(this.listOfLesions).toFixed(2);
    //this.totalVolumeCalculation();
  }
  sppdCalculation(lesionSource: MranoLesionModel[]) {
    if (lesionSource) {
      let sppd = 0;
      let activeLesions = lesionSource.filter((object) => {
        return object.lesionObject === MRanoLesionObject.MEASURABLE && object.status === 'AAA111'
      });
      activeLesions.forEach(l => {
        let ppd = (l.maxBiDimension?.Long * l.maxBiDimension?.Short);
        if (ppd)
          sppd += ppd;
      })
      return (isNaN(sppd) ? 0 : sppd);
    }
  }
  totalVolumeCalculation() {
    let totalVolume = 0;
    let activeLesions = this.listOfLesions.filter((object) => { return object.lesionObject !== MRanoLesionObject.MIDLINESHIFT && object.status === 'AAA111' });
    activeLesions.forEach(l => {
      let volume = (l.voiValue?.value);
      if (volume)
        totalVolume += volume;
    })
    this.totalVolume = totalVolume.toFixed(2);
  }

  onScoreLock() {

    this.scoringLockData = {
      id: this.historicalScan.id,
      imageQualityAssessment: this.historicalForm.get('imageQuality').value,
      imageQualityAssessmentComment: this.historicalForm.get('imageQualityComment').value,
      measurableBrain: this.listOfLesions,
      lock: true,
      sumPPD: this.sppd / 100,
      totalVolume: this.historicalForm.get('totalVolume').value,
      useBevacizumab: this.historicalScan.useBevacizumab,
      uploadResourceType: this.historicalScan.uploadResourceType
    };

    this.scorelocked.emit({
      type: this.mranoScoringType.HISTORICAL,
      data: this.scoringLockData
    });
  }

  addObjectFromLeadtools(data: any, edit: boolean, editType: string, editData, objectType: MRanoLesionObject) {
    if (data.objectType === MRanoLesionObject.MIDLINESHIFT) {
      this.toastOptions.title = 'Cannot add midline shift in historical form';
      this.toastOptions.msg = 'Midline shift assessment could be added in eligibility form';
      this.toastyService.error(this.toastOptions);
      return;
    }
    if (this.listOfLesions.filter(lesion => { return lesion.status === 'AAA111'}).length >= 5 && !edit) {
      this.toastOptions.title = 'ERROR Cannot add lesion';
      this.toastOptions.msg = 'Cannot add more than 5 lesions';
      this.toastyService.error(this.toastOptions);
      return;
    }
    switch (objectType) {
      case this.mranoLesionObject.MEASURABLE:
        this.measurableLesionTable.onOpenBrainLesion(edit, editData, editType, data.objectType, data);
        break;
      case this.mranoLesionObject.NON_MEASURABLE:
        this.nonMeasurableLesionTable.onOpenBrainLesion(edit, editData, editType, data.objectType, data);
        break;
      default:
        break;
    }
  }
}
