import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingPSMAVersion;
const SERVICE = 'msreadingpsmapet-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/reading/';
const LOCAL_API_URL = 'http://localhost:8080/api/reading/';

@Injectable({
  providedIn: 'root'
})
export class ReadingPsmaPetEligibilityService {
  private seriesLockedStatusSource = new BehaviorSubject([]);
  seriesLockedStatus = this.seriesLockedStatusSource.asObservable();

  private deletedAnnObjectsSource = new BehaviorSubject([]);
  deletedAnnObjects = this.deletedAnnObjectsSource.asObservable();

  private modalSpinnerSource  = new BehaviorSubject<boolean>(true);
   modalSpinner =this.modalSpinnerSource.asObservable();

   private openLTViewerSource = new BehaviorSubject<any>(null);
   openLTViewerData = this.openLTViewerSource.asObservable();

   private editLesionMeasurementSource = new BehaviorSubject<any>(null);
   editLesionMeasurement = this.editLesionMeasurementSource.asObservable();
  
  constructor(private http: HttpClient) {
  }
  modalSpinnerChangeState(state:boolean){
    this.modalSpinnerSource.next(state);
  }
  openLTViewer(data:any){
    this.openLTViewerSource.next(data)
  }
  onEditLesionMeasurement(data?:any){
    this.editLesionMeasurementSource.next(data);
  }
  addSeriestoSeriesLockedStatus(list:any){
    this.seriesLockedStatusSource.next(list)
  }
  deleteAnnObjectsSource(AnnObjects:any){
    this.deletedAnnObjectsSource.next(AnnObjects)
  }

  getNumberOfAvailableReadings(studyId, readerId) {
      return this.http.get(`${SERVICE_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getReadingByStudyIdAndReaderId(studyId, readerId) {
      return this.http.get(`${SERVICE_API_URL}get-available-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId) {
      return this.http.put(`${SERVICE_API_URL}start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds) {
      return this.http.put(`${SERVICE_API_URL}${studyId}/${readingId}`, {spentSeconds});
  }
  readingScoreLock(studyId,ScoringData){
      return this.http.post(`${SERVICE_API_URL}${ScoringData.type.toLowerCase()}/${studyId}`,ScoringData.data)
  }

  updateVisitData(studyId,visitId,data){
    return this.http.patch(`${SERVICE_API_URL}visits/${studyId}/${visitId}`, data);
  }
  completeReading(studyId, data) {
      return this.http.put(`${SERVICE_API_URL}complete/${studyId}`, data);
  }
  updateLesionData(studyId:number,data:any){
     return this.http.post(`${SERVICE_API_URL}lesion/${studyId}`, data);
  }
  deleteLesion(studyId:number,lesionId:number){
    return this.http.delete(`${SERVICE_API_URL}lesion/${studyId}/${lesionId}`);
 }
 getAllLesions(studyId:number,visitId:number){
    return this.http.get(`${SERVICE_API_URL}lesion/visit/${studyId}/${visitId}`);
 }

 getLesionImageUploadLink(studyId, readingId, seriesId, sliceNumber,screenshot:boolean){

    let url = `${SERVICE_API_URL}lesionscreenshot/upload-link/${studyId}/${readingId}`;
    if(screenshot)
     url = `${SERVICE_API_URL}lesionscreenshot/upload-link/${studyId}/${readingId}/${seriesId}/${sliceNumber}`;
    return this.http.get(url);
  }
  
  uplodFileToCloud(uploadUrl,formData){
    return this.http.put(uploadUrl,formData);
  }
}
