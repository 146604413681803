import {Action, Selector, State, StateContext} from '@ngxs/store';
import {DownloadingView} from '../../interfaces/downloading-view';
import {ClearDownloadProcess, PushDownloadProcess, SetDownloading, UpdateDownloadProcess} from '../actions/app.action';
import {DownloadMonitoringItem} from '../../../components/download-monitoring/download-monitoring.component';


export class AppStateModel {
  loading: DownloadingView;
  downloadMonitoring: DownloadMonitoringItem[];
}

const defaultAppState: AppStateModel = {
  loading: {isDownloading: false},
  downloadMonitoring: []
};

@State<AppStateModel>({
  name: 'appState',
  defaults: defaultAppState
})
export class AppState {

  @Selector()
  static isLoading(state: AppStateModel): DownloadingView {
    return state.loading;
  }

  @Selector()
  static getDownloadState(state: AppStateModel): DownloadMonitoringItem[] {
    return state.downloadMonitoring;
  }

  @Action(SetDownloading)
  setLoading({getState, patchState}: StateContext<AppStateModel>, {payload}: SetDownloading) {
    patchState({
      loading: {
        isDownloading: payload.isDownloading,
        downloadingTitle: payload.downloadingTitle
      },
    });
  }

  @Action(PushDownloadProcess)
  pushDownloadProcess({getState, patchState}: StateContext<AppStateModel>, {payload}: PushDownloadProcess) {
    const state = getState();
    patchState({
      downloadMonitoring: [...state.downloadMonitoring, payload]
    });
  }

  @Action(UpdateDownloadProcess)
  updateDownloadProcess({getState, patchState}: StateContext<AppStateModel>, {payload}: UpdateDownloadProcess) {
    const state = getState();
    patchState({
      downloadMonitoring: state.downloadMonitoring.map(u => {
        if (payload.uuid === u.uuid) {
          return payload;
        }
        return u;
      })
    });
  }

  @Action(ClearDownloadProcess)
  clearDownloadProcess({getState, patchState}: StateContext<AppStateModel>) {
    const state = getState();
    patchState({
      downloadMonitoring: state.downloadMonitoring.filter(u => u.status === 'READY_FOR_DOWNLOAD')
    });
  }

}
