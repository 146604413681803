import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {forkJoin, Observable} from 'rxjs';
import {ReadingConfigFlexibleService, StudySequenceLabelService, StudyService, StudyUserService} from '../../../../../_services';
import {MatTableDataSource} from '@angular/material/table';
import {FormMode} from '../../../../../core/constants/form-mode';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {BaseFlexibleConfig} from '../../../../../_models/ImagingProject/base-flexible-config';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {
  defaultAdjAutoBatchConfig,
  defaultLvl1AutoBatchConfig,
  ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { ReadingLevelOptionsDialogComponent } from 'src/app/components/controls/reading-level-options-dialog/reading-level-options-dialog.component';
import { VisitModalityConfiguratorDialogComponent } from '../../visit-modality-configurator-dialog/visit-modality-configurator-dialog.component';
import * as moment from 'moment';
import { VisitType } from 'src/app/_models/ImagingProject/patient-model';
import { GuerbetAdjConfigModel, GUERBET_VisitModel } from 'src/app/_models/ImagingProject/GUERBET/guerbet-adj-config-model';

export enum GuerbetReadingConfigFlexibleStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}

@Component({
  selector: 'app-imaging-project-reading-guerbet',
  templateUrl: './imaging-project-reading-guerbet.component.html',
  styleUrls: ['./imaging-project-reading-guerbet.component.css']
})
export class ImagingProjectReadingGuerbetComponent extends ImagingProjectReadingBasicComponent implements OnInit {
  readonly SHOW_VISITS_CHRONOLOGY = 'show_visits_chronology';
  readonly HIDE_VISITS_CHRONOLOGY = 'hide_visits_chronology';


  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();

  @Input()
  public mode: FormMode;

  readonly formModes = FormMode;

  @Input() selectedConfig: FlexibleConfig<GuerbetAdjConfigModel>;

  public get ReadingVersion(): typeof ReadingVersion {
    return ReadingVersion;
  }

  set readingVersion(value: any) {
    if (value != null) {
      this._configModel.readingVersion = value;
    }
  }
  get readingVersion(): any {
    return this._configModel.readingVersion;
  }

  @Input('configModel')
  set configModel(value: any) {
    if (value != null ) {
      this._configModel = value;
      if (this.mode === FormMode.NEW) {
        this._configModel.readingLevel = 'visit';
        this._configModel.readingType = 'parallel';
        this._configModel.hideVisitChronology = this.SHOW_VISITS_CHRONOLOGY;
      }
    }
  }
  get configModel(): any {
    return this._configModel;
  }


  get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true &&
      ((this.batchManagementForLevelOne === true && !this.lvl1BatchConfig?.numberOfReadings) || !this.lvl1BatchConfig)
        ? {norError: 'Number of readings is required'} : null;
    };
  }

  get adjBatchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true &&
      ((this.batchManagementForAdjudicationReaders === true && !this.adjudicationBatchConfig?.numberOfReadings) || !this.adjudicationBatchConfig)
        ? {norError: 'Number of readings is required'} : null;
    };
  }

  get readersValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!this.dataSourceReaders && !!this.dataSourceReaders.data && this.dataSourceReaders.data.length > 0
        ? null : {required: 'Please select readers'};
    };
  }

  readonly readingTypes = ReadingType;
  readonly EARLY_TERMINATION_VISIT = 'Early Termination';
  readonly readingVersions = ReadingVersion;

  readingLevelField = new FormControl('', [Validators.required]);
  readingVersionField = new FormControl('', [Validators.required]);
  modalityField = new FormControl([], [Validators.required ]);
  readingTypeField = new FormControl('', [Validators.required]);
  readersField = new FormControl('', [this.readersValidator]);
  adjReader = new FormControl('', [Validators.required]);
  consReader = new FormControl('', [Validators.required]);
  adjRuleField = new FormControl('', [Validators.required]);
  adjBlindReadersField = new FormControl('', [Validators.required]);
  finalReportField = new FormControl('', [Validators.required]);
  adjReportGenField = new FormControl('', [Validators.required]);
  anatomySelectorField = new FormControl(null, [Validators.required]);
  batchManagementForLevelOneControl = new FormControl(null, [Validators.required, this.batchManagementValidator]);
  batchManagementForAdjReadersControl = new FormControl(null, [Validators.required, this.adjBatchManagementValidator]);
  hideVisitChronologyControl = new FormControl(null, [Validators.required]);

  dataSourceReaders: any;
  dataSourceAdjReader: any;
  dataSourceConsReader: any;
  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete' ];

  readers: any[] = [];
  adjudicationReaders: any[] = [];
  consensusReaders: any[] = [];
  filteredReaders: Observable<any[]>;

  studySequenceLabels: SequenceLabelModel[] = [];
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  maxReaders = 20;
  selectedReaders = [];

  studyId: number;

  _configModel = {
    endpointReadingVersion: 'adjudication',
    readingVersion: null,
    readingLevel: null,
    readingType: null,
    modalities: [],
    readers: [],
    adjudicationRule: null,
    adjudicationBlind: null,
    adjudicationFinalReportWithoutDisagreementRule: null,
    adjudicationFinalReportWithDisagreementRule: null,
    adjudicationReader: null,
    consensusReader: null,
    anatomySelector: null,
    batchManagement: false,
    adjudicationBatchManagement: false,
    hideVisitChronology: this.SHOW_VISITS_CHRONOLOGY,
    visit: [],

  };
  selectedReadersIds = [];
  selectedAdjudicationReaderId = null;
  selectedConsensusReaderId = null;

  batchManagement: boolean;
  batchConfig: BatchConfigModel;
  batchManagementForLevelOne = false;
  batchManagementForAdjudicationReaders = false;

  adjudicationBatchConfig: BatchConfigModel;
  lvl1BatchConfig: BatchConfigModel;

  modalities = [];

  formValid = false;
  studyVisits: any[] = [];
  unscheduledAllowed: boolean;

  //for child configs
  status = GuerbetReadingConfigFlexibleStatus;

  constructor(private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private studySequenceLabelservice: StudySequenceLabelService,
    private studyUserService: StudyUserService,
    private toastyService: ToastyService,
    private studySequenceLabelService: StudySequenceLabelService,
    private batchLogicService: BatchLogicService,
    private studyService: StudyService) {
      super();
     }

  ngOnInit(): void {
    this.formValid = false;
    this.selectedReaders = [];
    this.studyId = parseInt(this.route.snapshot.params.id, 10);


    this.studySequenceLabelservice.getGroupedStudyModalitiesByStudyId(this.studyId).subscribe(respgroupedModalitiesResp => {

      if (respgroupedModalitiesResp['data'] != null) {
        this.modalities = respgroupedModalitiesResp['data'];
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        if (this.mode == FormMode.EDIT)
          this._configModel.modalities = respgroupedModalitiesResp.data.filter(x => this._configModel.modalities.find(m => m.name == x.name))
        else
          this._configModel.modalities = this.modalities

         // modality config
          const studyServices = [
          this.studyService.getVisitsByStudyId(this.studyId),
          this.studyService.getById(this.studyId),
        ]
        forkJoin(studyServices).subscribe(([studyVisitResponse, studyResponse]) => {
          this.studyVisits = [...studyVisitResponse['data']];
          this.initStudyVisits(studyResponse['data']);
        })
      }


      this.studyUserService.getReadersByStudyId(this.studyId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            const readers = [...response.data];
            this.readers = [...response.data];
            this.adjudicationReaders = response.data;
            this.consensusReaders = JSON.parse(JSON.stringify(response.data));
            this.selectedReaders = this.readers;
            if (this.mode === FormMode.EDIT) {
              // prepare modalities
              // prepare selected modalities
              const selectedModalities = [];
              this._configModel.modalities.forEach(m => {
                const modality = this.modalities.find(mod => mod.id === m.id);
                if (modality != null) {
                  selectedModalities.push(modality);
                }
              });
              this._configModel.modalities = selectedModalities;
              // if form in edit mode to draw table need to array of users ids add other reader information ( first name, last name etc.)
              const rIds = this._configModel.readers;
              const selectedReaders = [];
              rIds.forEach(rId => {
                const reader = this.readers.find(r => r.id === rId.id);
                if (reader != null) {
                  selectedReaders.push(reader);
                  this.readers.splice(this.readers.indexOf(reader), 1);
                }
              });
              this._configModel.readers = selectedReaders;
              this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
              // set adjudication reader info
              if (this._configModel.adjudicationReader != null) {
                const adjudicationReader = readers.find(reader => reader.id === this._configModel.adjudicationReader);
                this.adjudicationReaders.splice(this.adjudicationReaders.indexOf(adjudicationReader), 1);
                const adjReader = readers.find(reader => reader.id === this._configModel.adjudicationReader);
                this._configModel.adjudicationReader = adjReader;
                this.readers.splice(this.readers.indexOf(adjReader), 1);
                this.dataSourceAdjReader = new MatTableDataSource<any>([this._configModel.adjudicationReader]);
              }
              if (this._configModel.consensusReader != null) {
                const consensusReader = readers.find(reader => reader.id === this._configModel.consensusReader);
                this.adjudicationReaders.splice(this.consensusReaders.indexOf(consensusReader), 1);
                const consReader = readers.find(reader => reader.id === this._configModel.consensusReader);
                this._configModel.consensusReader = consReader;
                this.readers.splice(this.readers.indexOf(consReader), 1);
                this.dataSourceConsReader = new MatTableDataSource<any>([this._configModel.consensusReader]);
              }
              this._configModel.batchManagement = this.selectedConfig.config.batchManagement;
              this._configModel.adjudicationBatchManagement = this.selectedConfig.config.adjudicationBatchManagement;
              this.batchManagementForLevelOne = this.selectedConfig.config.batchManagement;
              this.batchManagementForAdjudicationReaders = this.selectedConfig.config.adjudicationBatchManagement;
              this.batchManagementForLevelOneControl.setValue(this.selectedConfig.config.batchManagement);
              this.batchManagementForAdjReadersControl.setValue(this.selectedConfig.config.adjudicationBatchManagement);
              this.batchManagementForLevelOneControl.updateValueAndValidity();
              this.batchManagementForAdjReadersControl.updateValueAndValidity();
              if (!this._configModel.hideVisitChronology) {
                this._configModel.hideVisitChronology = this.SHOW_VISITS_CHRONOLOGY;
              }
              this.validateForm();
            }

            this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId).subscribe(studySequenceResp => {
              this.prepareSequenceLabels(studySequenceResp)
            });
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );

    });
    this.loadBatchConfigs();
  }

    //modality config
    initStudyVisits(study: any) {
      //sort study visits by id
      this.studyVisits = this.sortVisits(this.studyVisits);
      this.unscheduledAllowed = study.unscheduledAllowed;
      //add unschaduled visits and early termination
      const unscheduled = new GUERBET_VisitModel();
      unscheduled.name = 'Unscheduled';
      unscheduled.type = VisitType.UNSCHEDULED_REGULAR;
      this.studyVisits.push(unscheduled);

      const earlyTermination = new GUERBET_VisitModel();
      earlyTermination.name = this.EARLY_TERMINATION_VISIT;
      earlyTermination.type = VisitType.UNSCHEDULED_EARLY_TERMINATION;
      this.studyVisits.forEach(visit => visit.isAvailable = true);
      this.studyVisits.push(earlyTermination);
      if (this.mode != FormMode.EDIT) {
        this._configModel.visit = this.studyVisits;
        this._configModel.visit.forEach(v => v['modalities'] = !this.unscheduledAllowed && v.type === VisitType.UNSCHEDULED_REGULAR ? [] : this.modalities)
      }
    }

    sortVisits(visits) {
      visits.forEach(visit => {
        let visitDate = moment(visit.create);
        if (visit.durationTimeUnit == 'D') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'd');
        } else if (visit.durationTimeUnit == 'W') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'w');
        } else if (visit.durationTimeUnit == 'M') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'M');
        }
        visit.visitDate = visitDate;
      });
      visits.sort((a, b) => {
        if (a.visitDate < b.visitDate) {
          return -1;
        }
        if (a.visitDate > b.visitDate) {
          return 1;
        }
        return 0;
      });
      let baselineIndex = visits.indexOf(visits.find(this.isBaseline));
      visits.unshift(visits.find(this.isBaseline));
      visits.splice(baselineIndex + 1, 1);
      return visits;
    }
    isBaseline(visit) {
      return visit.baseline === true;
    }

  loadBatchConfigs(): void {
    if (this.mode === FormMode.EDIT) {
      this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id).subscribe(response => {
        const batchConfigs = response.data;
        batchConfigs.forEach(c => {
          if (c.readingType === ReadingType.LEVEL1_READING) {
            this.lvl1BatchConfig = c;
          } else if (c.readingType === ReadingType.ADJUDICATION_READING) {
            this.adjudicationBatchConfig = c;
          }
        });
      });
    }
  }

  prepareSequenceLabels(studySequenceResp) {
    this.studySequenceLabels = studySequenceResp.data;
    if (this.mode === FormMode.EDIT) {
      if (!!this.configModel?.anatomySelector) {
        this.initSequenceLabels(this.configModel.anatomySelector);
      } else {
        this.initDefaultSequenceLabels(this.modalities);
      }
    } else
      this.initDefaultSequenceLabels(this.modalities);
  }

  initDefaultSequenceLabels(modalities) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.availableSequenceLabels;
    this._configModel.anatomySelector = this.selectedSequenceLabels;
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    const modalities = this.modalities.filter(m => this.selectedConfig.config.modalities.find(cm => cm.name === m.name));
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  getAvailableSequenceLabels(modalities): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const modality of modalities) {
      for (const label of modality.sequenceLabels) {
        configLabels.add(label);
      }
    }

    return this.studySequenceLabels
      .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
  }

  onBatchManagementSelected(isAdjBatchManagement: boolean): void {
    if (isAdjBatchManagement) {
      if (this.adjudicationBatchConfig) {
        this.adjudicationBatchConfig.numberOfReadings = null;
      }
      this.batchManagementForAdjReadersControl.updateValueAndValidity();
    } else {
      if (this.lvl1BatchConfig) {
        this.lvl1BatchConfig.numberOfReadings = null;
      }
      this.batchManagementForLevelOneControl.updateValueAndValidity();
    }
    this.validateForm();
  }

  openAutoCreationBatchConfigDialog(readingType: ReadingType, config: BatchConfigModel): void {
    let readonlyDialog: boolean;
    if (readingType === ReadingType.ADJUDICATION_READING) {
      readonlyDialog = !this.batchManagementForAdjudicationReaders;
    } else if (readingType === ReadingType.LEVEL1_READING) {
      readonlyDialog = !this.batchManagementForLevelOne;
    }

    let inputConfig = config as AutoBatchConfigPayload;
    if (readingType === ReadingType.LEVEL1_READING) {
      if (!this.batchManagementForLevelOne)
        inputConfig = { ...defaultLvl1AutoBatchConfig };
      else
        inputConfig = this.lvl1BatchConfig ? { ...this.lvl1BatchConfig } : null;
    }
    if (readingType === ReadingType.ADJUDICATION_READING) {
      if (!this.batchManagementForAdjudicationReaders)
        inputConfig = { ...defaultAdjAutoBatchConfig };
      else
        inputConfig = this.adjudicationBatchConfig ? { ...this.adjudicationBatchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        readingType: readingType,
        configInput: inputConfig,
        readonlyDialog: readonlyDialog
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        const resultConfig = result;
        if (readingType === ReadingType.ADJUDICATION_READING) {
          this.adjudicationBatchConfig = resultConfig;
          this.batchManagementForAdjReadersControl.updateValueAndValidity();
        } else {
          this.lvl1BatchConfig = resultConfig;
          this.batchManagementForLevelOneControl.updateValueAndValidity();
        }
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
    this.validateForm();
  }

  onModalityTypeChange(event) {
    // one element of the array
    this._configModel.modalities = event.value;
    this.initDefaultSequenceLabels(event.value);
    this.validateForm();
  }

  // modality config
  openModalitiesDialog() {
    ImagingProjectReadingBasicComponent.clearFocus();
    const dialogRef = this.dialog.open(VisitModalityConfiguratorDialogComponent, {
      data: {
        visits: this._configModel.visit,
        modalities: this.modalities,
        unscheduledAllowed: this.unscheduledAllowed
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.filterVisits(result.visits);
        const uniqueModality = this.getAllVisitsUniqueModalities(result.visits);
        this._configModel.modalities = [...uniqueModality];
        this.initDefaultSequenceLabels(this._configModel.modalities);
        this.validateForm();
      }
    });
  }

  getAllVisitsUniqueModalities(visits: any[]) {
    let modalities = [];
    visits.forEach((visit, visitIndex) => {
      this._configModel.visit[visitIndex]
        .modalities = [...visit.modalities];
      modalities = [...modalities, ...visit.modalities];
    });
     const uniqueModality = [];
     modalities.forEach((modality) => {
       let index = uniqueModality.findIndex((m) => m.id === modality.id) === -1;
       if (index) uniqueModality.push(modality);
     });
     return uniqueModality;
  }

  private filterVisits(visits) {
    visits.forEach(visit => {
      if (!visit.modalities || visit.modalities.length < 1) {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = false;
      } else {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = true;
      }
    });
  }

  onReadingVersionChange(event) {
    this._configModel.readingVersion = event.value;
    this.validateForm();
  }

  onReadingLevelChange(event) {
    this._configModel.readingLevel = event.value;
    this.validateForm();
  }

  onReadingTypeChange(event) {
    this._configModel.readingType = event.value;
    this.validateForm();
  }

  onAdjudicationRuleChange(event) {
    this._configModel.adjudicationRule = event.value;
    this.validateForm();
  }

  onAdjudicationBlindChange(event) {
    this._configModel.adjudicationBlind = event.value;
    this.validateForm();
  }

  onAdjudicationFinalReportWithoutDisagreementRuleChange(event) {
    this._configModel.adjudicationFinalReportWithoutDisagreementRule = event.value;
    this.validateForm();
  }

  onAdjudicationFinalReportWithDisagreementRuleChange(event) {
    this._configModel.adjudicationFinalReportWithDisagreementRule = event.value;
    this.validateForm();
  }

  onAdjudicationHideVisitChronologyChange(event) {
    this._configModel.hideVisitChronology = event.value;
    this.validateForm();
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this._configModel.anatomySelector = e;
    this.validateForm();
  }

  onAddSelectedReaders() {
    if (this.selectedReadersIds != null) {
      let counter = this._configModel.readers.length;
      this.selectedReadersIds.forEach(readerId => {
        if (counter < this.maxReaders) {
          const reader = this.readers.find(reader => reader.id === readerId);
          if ((reader != null) && (!this._configModel.readers.includes(reader))) {
            this._configModel.readers.push(reader);
            const index = this.readers.indexOf(reader);
            this.readers.splice(index, 1);
            this.validateForm();
          }
          counter += 1;
        }
      });

      this.selectedReadersIds = [];
    }
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
    this.selectedReaders = this._configModel.readers;
    if (this._configModel.readers.length > 0) {
      this.readersField.clearValidators();
    }
    if (this.dataSourceReaders.data.length > 0) {
      this.readersField.clearValidators();
    }
    this.validateForm();
  }

  deleteReader(reader) {

    const index = this._configModel.readers.indexOf(reader);
    this._configModel.readers.splice(index, 1);
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
    this.selectedReadersIds = this._configModel.readers;
    this.readers.unshift(reader);
    this.selectedReadersIds = [];

    if (this._configModel.readers.length === 0) {
      this.readersField.validator = this.readersValidator;
    } else {
      this.readersField.clearValidators();
    }
    if (this._configModel.readers.length > 0) {
      this.validateForm();
    } else {
      this.formValid = false;
    }
  }

  onAddSelectedAdjudicationReaders() {
    if(this.selectedAdjudicationReaderId != null) {
      const reader = this.adjudicationReaders.find(reader => reader.id === this.selectedAdjudicationReaderId );
      if (this._configModel.adjudicationReader != null) {
        this.adjudicationReaders.unshift(this._configModel.adjudicationReader);
      }
      if(reader != null)
        this._configModel.adjudicationReader = reader;
        this.adjudicationReaders.splice(this.adjudicationReaders.indexOf(reader), 1);
        this.selectedAdjudicationReaderId = null;
    }
    this.dataSourceAdjReader = new MatTableDataSource<any>([this._configModel.adjudicationReader]);
    this.adjReader.clearValidators();
    this.validateForm();
  }

  onAddSelectedConsensusReaders() {
    if(this.selectedConsensusReaderId != null) {
      const reader = this.consensusReaders.find(reader => reader.id === this.selectedConsensusReaderId );
      if (this._configModel.consensusReader != null) {
        this.consensusReaders.unshift(this._configModel.consensusReader);
      }
      if(reader != null)
        this._configModel.consensusReader = reader;
        this.consensusReaders.splice(this.consensusReaders.indexOf(reader), 1);
        this.selectedConsensusReaderId = null;
    }
    this.dataSourceConsReader = new MatTableDataSource<any>([this._configModel.consensusReader]);
    this.consReader.clearValidators();
    this.validateForm();
  }

  deleteAdjudicationReader(reader) {
    this._configModel.adjudicationReader = null;
    this.adjudicationReaders.unshift(reader);
    this.dataSourceAdjReader = new MatTableDataSource<any>([]);
    this.adjReader.validator = Validators.required;

    this.validateForm();
  }

  deleteConsensusReader(reader) {
    this._configModel.consensusReader = null;
    this.consensusReaders.unshift(reader);
    this.dataSourceConsReader = new MatTableDataSource<any>([]);
    this.consReader.validator = Validators.required;

    this.validateForm();
  }


  clickSubmitBtn() {
    const readers = [];
    this._configModel['endpointReadingVersion'] = 'adjudication';
    this._configModel.readers.forEach(r => {
      readers.push({id: r.id});
    });
    this._configModel.readers = readers;
    this._configModel.batchManagement = this.batchManagementForLevelOne;
    this._configModel.adjudicationBatchManagement = this.batchManagementForAdjudicationReaders;
    if(this._configModel.adjudicationReader != null) {
      this._configModel.adjudicationReader = this._configModel.adjudicationReader.id;
    }
    if(this._configModel.consensusReader != null) {
      this._configModel.consensusReader = this._configModel.consensusReader.id;
    }
    // let modalityIds = this._configModel.modalities.map(m => m.id);
    // this._configModel.modalities = this.modalities.filter(m => modalityIds.lastIndexOf(m.id) >= 0);
    const submitData: ImagingProjectSubmitEvent = {
      data: this._configModel,
      basicBatchConfig: null,
      adjudicationBatchConfig: this.adjudicationBatchConfig,
      level1BatchConfig: this.lvl1BatchConfig,
      result: 'submit'
    };
    this.clickSubmit.emit(submitData);
  }

  clickCancelBtn() {
    this.clickCancel.emit({result: 'cancel'});
  }

  validateForm() {
    let ans =  true;
    if(this._configModel.readingVersion == null)
      ans = false;
    if(this._configModel.readingLevel == null)
      ans = false;
    if(this._configModel.readingType == null)
      ans = false;
    if(this._configModel.readers.length < 2)
      ans = false;
    if (this.anatomySelectorField.invalid) {
      ans = false;
    }
    if(this._configModel.adjudicationRule == null)
      ans = false;
    if(this._configModel.adjudicationBlind == null)
      ans = false;
    if(this._configModel.adjudicationFinalReportWithoutDisagreementRule == null)
      ans = false;
    if(this._configModel.adjudicationFinalReportWithDisagreementRule == null)
      ans = false;
    if(this._configModel.adjudicationReader == null)
      ans = false;
    if(this._configModel.consensusReader == null)
      ans = false;
    if(!this._configModel.anatomySelector || this._configModel.anatomySelector.length < 1)
      ans = false;
    if(this.batchManagementForLevelOneControl.invalid)
      ans = false;
    if(this.batchManagementForAdjReadersControl.invalid)
      ans = false;
    if (this.hideVisitChronologyControl.invalid) {
      ans = false;
    }
    this.formValid = ans;
  }

  compareModality(m1, m2): boolean {
    return m1.name === m2.name;
  }

  compareReaders(r1: any, r2: any): boolean {
    return r1 && r2 ? r1.id === r2.id : r1 === r2;
  }

}
