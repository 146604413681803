import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {JswReadingKneeFormComponent} from '../jsw-reading-knee-form/jsw-reading-knee-form.component';
import {MatDialog} from '@angular/material/dialog';
import {JswReadingUploadFilesDialogComponent} from '../jsw-reading-upload-files-dialog/jsw-reading-upload-files-dialog.component';
import {JSW_OARSI_ReadingModel} from '../../../../_models/JSW/jsw-reading-model';
import {ReadingJSWService} from '../../../../_services/reading-jsw.service';
import {ToastService} from '../../../../_services/internal/toast.service';
import {JSWSeriesModel} from '../../../../_models/JSW/jsw-series-model';
import {map, switchMap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {DownloadService} from '../../../../_services/download.service';
import {DownloadPayload} from '../../../../core/payload/download-payload';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {DownloadModel} from '../../../../_models/Download/download.model';
import {PatientService} from '../../../../_services/patient.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {ResponseCode} from '../../../../core/constants/response-code';
import {ReadingConfigFlexibleService, SeriesService, VisitService} from '../../../../_services';
import {ImageViewerConfigurations, JSW_OARSI_BasicConfigModel} from '../../../../_models/ImagingProject/JSW/jsw-basic-config-model';
import {JswReadingForm} from '../jsw-reading-form/jsw-reading-form.component';
import * as moment from 'moment';
import {JSWRadioboticsFileType} from '../../../../_models/JSW/jsw-radiobotics-file-type';
import {ConfirmSigningDialogComponent} from "../../../ReportSign/confirm-signing-dialog/confirm-signing-dialog.component";

enum ImportFileStatus {
  new_import = 'jsw_import_100',
  uploading_in_progress = 'jsw_import_200',
  uploading_success = 'jsw_import_300',
  uploading_failure = 'jsw_import_400',
  uploading_cancelled = 'jsw_import_500',
  getting_measurements_in_progress = 'jsw_import_600',
  getting_measurements_success = 'jsw_import_700',
  getting_measurements_failure = 'jsw_import_800'
}

namespace ImportFileStatus {
  export function getLabel(status: ImportFileStatus): string {
    switch (status) {
      case ImportFileStatus.new_import:
        return 'New Import';
      case ImportFileStatus.uploading_in_progress:
        return 'Uploading In Progress';
      case ImportFileStatus.uploading_success:
        return 'Upload Success';
      case ImportFileStatus.uploading_failure:
        return 'Uploading Failure';
      case ImportFileStatus.uploading_cancelled:
        return 'Uploading Cancelled';
      case ImportFileStatus.getting_measurements_in_progress:
        return 'Getting Measurements In Progress';
      case ImportFileStatus.getting_measurements_success:
        return 'Getting Measurements Success';
      case ImportFileStatus.getting_measurements_failure:
        return 'Getting Measurements Failure';
    }
  }
}

interface FilesTableRow {
  timepoint: string;
  seriesId: number;
  sequenceName: string;
  status: string;
  isLoading: boolean;
}

@Component({
  selector: 'app-jsw-reading-form-moderation',
  templateUrl: './jsw-reading-form-moderation.component.html',
  styleUrls: ['./jsw-reading-form-moderation.component.css']
})
export class JswReadingFormModerationComponent implements OnInit, JswReadingForm {

  @Input()
  switchSubmitBtnDisabledSubject;

  @Input()
  viewerEnabledSubject;

  @Input()
  currentReadingSubject;

  reading: JSW_OARSI_ReadingModel;
  readingSeries;
  readingConfig: JSW_OARSI_BasicConfigModel;
  visitsConfigs;
  currentReadingSubjectSubscription;

  eligibilityControl: FormControl;
  filesControl: FormControl;
  isFormLoading = false;

  kneeFormArray;
  patientCode = '';

  importTableRows: FilesTableRow[];
  importTableDisplayedColumns = ['timepoint', 'sequence_name', 'export_series', 'import_result', 'status'];

  constructor(private readingService: ReadingJSWService,
              private readingConfigService: ReadingConfigFlexibleService,
              private downloadService: DownloadService,
              private patientService: PatientService,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private toastService: ToastService,
              private seriesService: SeriesService,
              private visitService: VisitService) {
  }

  ngOnInit(): void {
    this.subscribeReading();
  }

  clearForm(): void {
    this.checkCurrentReadingSubscription();
  }

  submitForm(studyId: number, spentSeconds: number): Observable<BasicResponse<JSW_OARSI_ReadingModel>> {
    this.applyFormValuesToCurrentReading();
    this.reading.timeSpent = spentSeconds;

    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.reading.studyId,
        patientId: this.reading.patientId,
        visitConfigId: this.reading.flexibleConfigId
      }
    });

    return dialogRef.afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          if (!dialogResponse?.jwt) {
            if (!dialogResponse?.canceled) {
              this.toastService.error('ERROR', 'Sign Report Failed.<br/>Please enter valid credentials');
            }

            return of(null);
          }

          return this.readingService.completeReading(studyId, this.reading, dialogResponse.jwt)
            .pipe(map(resp => this.handleCompleteReadingResponse(resp)));
        })
      );
  }

  skipForm(studyId: number, spentSeconds: number): Observable<BasicResponse<any>> {
    this.applyFormValuesToCurrentReading();
    this.reading.timeSpent = spentSeconds;
    return this.readingService.updateReadingWithSaveAllData(studyId, this.reading)
      .pipe(map(resp => this.handleUpdateSkipReadingResponse(resp)));
  }


  async initForm() {
    await this.initFilesTable();
    this.tryInitKneeFormArray();
    this.initPatientCode();
    this.initControls();
    this.initSubmitButtonStatusListener();
  }

  onDownloadSeries(seriesId: number, timepoint: string): void {
    this.makeImportResultsTableRowLoading(seriesId, true);
    const onFinish = () => this.makeImportResultsTableRowLoading(seriesId, false);

    const downloadPayload = this.buildDownloadSeriesPayload(seriesId);
    this.downloadService.sendDownloadRequest(downloadPayload).subscribe(resp => {
      if (resp.responseCode !== 200) {
        this.onDownloadingFailed(resp.data, onFinish);
      } else {
        this.checkStatusAndDownloadSeries(resp.data, timepoint, onFinish);
      }
    });
  }

  openUploadFilesDialog(studyId: number, seriesId: number): void {
    const dialogRef = this.buildUploadFilesDialog(studyId, seriesId);
    dialogRef.afterClosed().subscribe(result => result ? this.updateForm() : null);
  }

  isKneesFormCanBeShown(): boolean {
    return this.isAllVisitsHaveResults();
  }

  private buildDownloadSeriesPayload(seriesId: number): DownloadPayload {
    const currentSeries = this.reading.visits
      .flatMap(currentVisit => currentVisit.series)
      .find(series => series.id === seriesId);
    return {
      studyId: this.reading.studyId,
      seriesIds: [currentSeries.seriesId],
      patientId: this.reading.patientId
    } as DownloadPayload;
  }

  private buildUploadFilesDialog(studyId: number, seriesId: number): MatDialogRef<any> {
    return this.dialog.open(JswReadingUploadFilesDialogComponent, {
      width: '800px',
      restoreFocus: false,
      data: {
        studyId: studyId,
        seriesId: seriesId
      }
    });
  }

  private applyFormValuesToCurrentReading(): void {
    this.reading.eligible = this.eligibilityControl.value;
  }

  private subscribeReading(): void {
    this.currentReadingSubjectSubscription = this.currentReadingSubject.subscribe(async reading => {
      this.reading = reading;
      this.readingConfig = await this.getReadingConfig(reading);
      this.setupViewerMode(this.readingConfig);
      await this.initForm();
    });
  }

  private updateForm(): void {
    this.updateCurrentReading().toPromise().then(() => {
      this.initFilesTable();
      this.tryInitKneeFormArray();
      this.filesControl.updateValueAndValidity();
    });
  }

  private updateCurrentReading(): Observable<void> {
    return this.readingService.getModerationReadings(this.reading.studyId, this.reading.readerId).pipe(
      map(response => {
        this.reading = response.data.find(reading => reading.id === this.reading.id);
      })
    );
  }

  private initControls(): void {
    this.filesControl = new FormControl(null, [this.filesValidator]);
    this.eligibilityControl = new FormControl(null, [Validators.required]);
    this.eligibilityControl.valueChanges.subscribe(() => {
      this.filesControl.updateValueAndValidity();
    });
  }

  private tryInitKneeFormArray(): void {
    if (this.isAllVisitsHaveResults()) {
      this.initKneeFormArray();
    }
  }

  private checkCurrentReadingSubscription(): void {
    if (!this.isCurrentReadingSubjectSubscribed()) {
      this.subscribeReading();
    }
  }

  private isAllVisitsHaveResults(): boolean {
    if (!this.reading) {
      return false;
    }
    for (const visit of this.reading.visits) {
      if (!visit.noUploads && !visit.scoring) {
        return false;
      }
    }
    return true;
  }

  private initKneeFormArray(): void {
    this.kneeFormArray = JswReadingKneeFormComponent.generateKneeFormArray(this.reading, this.fb);
  }

  private initSubmitButtonStatusListener(): void {
    const isReadingFormValid = () => this.eligibilityControl.valid && this.filesControl.valid;
    const switchSubmitButton = () => this.switchSubmitBtnDisabledSubject.next(!isReadingFormValid());
    this.eligibilityControl.statusChanges.subscribe(switchSubmitButton);
    this.filesControl.statusChanges.subscribe(switchSubmitButton);
    this.eligibilityControl.updateValueAndValidity();
    this.filesControl.updateValueAndValidity();
    switchSubmitButton();
  }

  private async initFilesTable() {
    await this.initSeries();
    await this.initVisitsConfigs();
    const tableRows = [] as FilesTableRow[];
    this.reading.visits.forEach(visit => {
      visit.series.forEach(seriesItem => {
        const tableRow = this.createFilesTableRowForSeries(seriesItem);
        tableRows.push(tableRow);
      });
    });
    this.importTableRows = tableRows;
  }

  private createFilesTableRowForSeries(series: JSWSeriesModel): FilesTableRow {
    const currentSeries = this.getSeriesById(series.seriesId);
    const fileStatus = this.computeFileStatusForSeries(series.id);
    const combinedTimepoint = this.combineOriginalAndBlindTimepoints(series);
    return {
      timepoint: combinedTimepoint,
      sequenceName: currentSeries.label,
      status: ImportFileStatus.getLabel(fileStatus),
      seriesId: series.id,
      isLoading: false
    } as FilesTableRow;
  }

  private combineOriginalAndBlindTimepoints(series: JSWSeriesModel) {
    const visitConfigId = series.visit.visitConfigId;
    const visitConfig = this.visitsConfigs.find(config => config.id === visitConfigId);
    if (!visitConfig) {
      throw new Error(`Cannot find visitConfig with id: [${visitConfigId}]`);
    }
    return `${visitConfig.visitBlindName} (${visitConfig.visitName})`;
  }

  private getSeriesById(seriesId: number): JSWSeriesModel {
    const currentSeries = this.readingSeries.find(rSeries => rSeries.id === seriesId);
    if (!currentSeries) {
      throw new Error(`Cannot find series with id [${seriesId}]`);
    }
    return currentSeries;
  }

  private computeFileStatusForSeries(seriesId: number): ImportFileStatus {
    for (const visit of this.reading.visits) {
      const filesTypes = new Set<JSWRadioboticsFileType>();
      for (const file of visit.radioboticsFiles) {
        if (file.series.id === seriesId) {
          filesTypes.add(file.fileType);
        }
      }
      if (this.areThereAllRequiredFilesTypes(filesTypes)) {
        return ImportFileStatus.uploading_success;
      }
    }
    return ImportFileStatus.new_import;
  }

  private areThereAllRequiredFilesTypes(filesTypes: Set<JSWRadioboticsFileType>): boolean {
    const requiredFilesTypes = Object.keys(JSWRadioboticsFileType).filter(k => typeof JSWRadioboticsFileType[k] !== 'number')
      .map(key => JSWRadioboticsFileType[key]);
    return requiredFilesTypes.filter(requiredType => !filesTypes.has(requiredType)).length === 0;
  }

  private initPatientCode(): void {
    this.patientService.getById(this.reading.patientId).subscribe(resp => {
      this.patientCode = resp.data.patientCode;
    });
  }

  private isCurrentReadingSubjectSubscribed(): boolean {
    return this.currentReadingSubject.observers.length !== 0;
  }

  private get filesValidator(): ValidatorFn {
    return (): ValidationErrors | null => {
      if (this.eligibilityControl?.value === 'true' && !this.isAllVisitsHaveResults()) {
        return {required: 'All visits must have scorings. Please import results for each visit.'};
      }
      return null;
    };
  }

  private onDownloadingFailed(downloadModel: DownloadModel, onFinish?: () => any) {
    this.downloadService.updateDownloadStatus(downloadModel.id, 'ARCHIVE_DOWNLOAD_FAILURE').subscribe();
    if (onFinish) {
      onFinish();
    }
  }

  private onDownloadingStart(downloadModel: DownloadModel) {
    this.downloadService.updateDownloadStatus(downloadModel.id, 'ARCHIVE_DOWNLOADING').subscribe();
  }

  private onFileDownloadSuccess(downloadModel: DownloadModel, onFinish?: () => any): void {
    this.downloadService.updateDownloadStatus(downloadModel.id, 'DOWNLOADING_SUCCESS').subscribe();
    if (onFinish) {
      onFinish();
    }
  }

  private checkStatusAndDownloadSeries(downloadModel: DownloadModel, timepoint: string, onFinish?: () => any) {
    if (downloadModel.status === 'ASSEMBLING_ARCHIVE') {
      const intervalId = setInterval(() => {
        this.downloadService.getDownloadRequest(downloadModel.id)
          .subscribe(resp => {
            if (resp.data.status !== 'ASSEMBLING_ARCHIVE') {
              clearInterval(intervalId);
              this.checkStatusAndSendDownloadFileRequest(resp, downloadModel, timepoint, onFinish);
            }
          });
      }, 3000);
    }
  }

  private checkStatusAndSendDownloadFileRequest(resp: BasicResponse<DownloadModel>, downloadModel: DownloadModel,
                                                timepoint: string, onFinish?: () => any) {
    if (resp.data.status === 'READY_FOR_DOWNLOAD') {
      this.downloadService.downloadFile(resp.data.storageLink).subscribe(event => {
        if (event.type === HttpEventType.DownloadProgress) {
          this.onDownloadingStart(downloadModel);
        }
        if (event.type === HttpEventType.Response) {
          this.isFormLoading = false;
          if (event.status !== ResponseCode.OK) {
            this.onDownloadingFailed(downloadModel, onFinish);
          } else {
            this.onFileDownloadSuccess(downloadModel, onFinish);
            this.downloadFile(event, timepoint);
          }
        }
      });
    }
  }

  private downloadFile(event: HttpResponse<Blob>, timepoint: string) {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(event.body);
    a.href = objectUrl;
    a.download = this.patientCode + '_' + timepoint + '_' + moment().format('YYYY-MM-DD_HH-mm');
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  private handleCompleteReadingResponse(resp: BasicResponse<JSW_OARSI_ReadingModel>) {
    if (resp.responseCode !== 200) {
      this.toastService.error('Reading form is not submitted', 'There are some errors on the form must be resolved');
    } else {
      this.toastService.success('Reading form has been submitted successfully', 'The reading is completed. Report is available.');
    }
    return resp;
  }

  private handleUpdateSkipReadingResponse(resp: BasicResponse<JSW_OARSI_ReadingModel>): BasicResponse<JSW_OARSI_ReadingModel> {
    if (resp.responseCode !== 200) {
      this.toastService.error('Reading form is not updated', 'There are some errors on the form must be resolved');
    } else {
      this.toastService.success('Reading form has been updated successfully');
    }
    return resp;
  }

  private async getReadingConfig(reading: JSW_OARSI_ReadingModel): Promise<JSW_OARSI_BasicConfigModel> {
    const configResp = await this.readingConfigService.getById(reading['flexibleConfigId']).toPromise();
    return configResp.data.config;
  }

  private setupViewerMode(readingConfig: JSW_OARSI_BasicConfigModel) {
    if (readingConfig.imageViewerConfiguration === ImageViewerConfigurations.eCRF_MODE_ONLY) {
      this.trySetViewerEnabled(false);
    } else if (readingConfig.imageViewerConfiguration === ImageViewerConfigurations.DEFAULT) {
      this.trySetViewerEnabled(true);
    }
  }

  private trySetViewerEnabled(mode: boolean): void {
    if (!!this.viewerEnabledSubject) {
      this.viewerEnabledSubject.next(mode);
    }
  }

  private async initSeries() {
    const seriesIds = this.getSeriesIdsFromReading(this.reading);
    if (!seriesIds) {
      throw new Error('seriesIds cannot be null');
    }
    this.readingSeries = await this.getSeriesByIds(seriesIds);
  }

  private async getSeriesByIds(seriesIds: number[]) {
    const resp = await this.seriesService.getSeriesByIds(seriesIds).toPromise();
    if (!resp || !(resp.responseCode === 200 || resp.responseCode === 2001)) {
      throw new Error('seriesService call is unsuccessfully');
    }
    return resp.data;
  }

  private getSeriesIdsFromReading(reading: JSW_OARSI_ReadingModel) {
    return reading.visits.flatMap(currentVisit => currentVisit.series).flatMap(currentSeries => currentSeries.seriesId);
  }

  private async initVisitsConfigs() {
    const visitsConfigsIds = this.reading.visits.map(cVisit => cVisit.visitConfigId);
    if (!visitsConfigsIds) {
      throw new Error('visitsConfigsIds cannot be null');
    }
    this.visitsConfigs = await this.getVisitsConfigs(visitsConfigsIds);
  }

  private async getVisitsConfigs(visitsConfigsIds: number[]) {
    const resp = await this.visitService.getConfigs(visitsConfigsIds).toPromise();
    if (!resp || resp.responseCode !== 200) {
      throw new Error('visitService call is unsuccessfully');
    }
    return resp.data;
  }

  private makeImportResultsTableRowLoading(seriesId: number, isLoading: boolean) {
    const tableRow = this.getImportResultsTableRowBySeriesId(seriesId);
    tableRow.isLoading = isLoading;
  }

  private getImportResultsTableRowBySeriesId(seriesId: number) {
    const tableRow = this.importTableRows.find(row => row.seriesId === seriesId);
    if (!tableRow) {
      throw new Error(`Cannot find row with 'seriesID': [${seriesId}] in 'importTableRows'`);
    }
    return tableRow;
  }
}
