import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSUserVersion;
const SERVICE = 'msuser-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class UserRoleMetaDataService {

  constructor(private http: HttpClient) { }

  create(userRoleMetaData: any) {
    return this.http.post(requestURL + `/study-user-role`, userRoleMetaData);
  }
  
  update(userRoleMetaData: any) {
    return this.http.put(requestURL + `/study-user-role`, userRoleMetaData);
  }
  
  delete(userRoleMetaData: any) {
    return this.http.delete(requestURL + `/study-user-role/`, userRoleMetaData);
  }

  deleteAllByStudyUserId(studyUserId: number) {
    return this.http.delete(requestURL + `/study-user-role/delete-all-by-study-user-id/` + studyUserId);
  }

  deleteAllByRoleId(roleId: number) {
    return this.http.delete(requestURL + `/study-user-role/delete-all-by-role-id/` + roleId);
  }

  deleteByStudyUserIdAndRoleId(studyUserId: number, roleId: number) {
    return this.http.delete(requestURL + '/study-user-role/delete-by-study-user-id-and-role-id/' + studyUserId + '/' + roleId);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/study-user-role/` + id);
  }
  
  getAll() {
    return this.http.get(requestURL + `/study-user-role/retrieve-all`);
  }

  getStudyUserRole(studyUserId: number, roleId: number) {
    return this.http.get(requestURL + `/study-user-role/` + studyUserId + '/' + roleId);
  }

  getMetaDataWithUserName(studyId: number, userName: number) {
    return this.http.get(requestURL + `/study-user-role/meta-data-with-user-name/` + studyId + '/' + userName);
  }

  getMetaDataWithEmail(studyId: number, email: string) {
    return this.http.get(requestURL + `/study-user-role/meta-data-with-email/` + studyId + '/' + email);
  }

  getRoles(studyUserId: number) {
    return this.http.get(requestURL + `/study-user-role/roles/` + studyUserId);
  }

  getStudyUsers(roleId: number) {
    return this.http.get(requestURL + `/study-user-role/study-users/{roleId}` + roleId);
  }
}
