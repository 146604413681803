// export const Demriq_Regions = {
//   allRegions: [
//       {
//         region: 'MCP',
//         selected: true,
//         fingers: [
//           {
//             name: 'Thumb',
//             stat: true,
//             color: '#A9DFBF'
//           },
//           {
//             name: 'Index',
//             stat: true,
//             color: '#7DCEA0'
//           },
//           {
//             name: 'Middle',
//             stat: true,
//             color: '#52BE80'
//           },
//           {
//             name: 'Ring',
//             stat: true,
//             color: '#27AE60'
//           },
//           {
//             name: 'Little',
//             stat: true,
//             color: '#229954'
//           },
//         ]
//       },
//       {
//         region: 'PIP/IP',
//         selected: true,
//         fingers: [
//           {
//             name: 'Thumb',
//             stat: true,
//             color: '#2471A3'
//           },
//           {
//             name: 'Index',
//             stat: true,
//             color: '#2980B9'
//           },
//           {
//             name: 'Middle',
//             stat: true,
//             color: '#5499C7'
//           },
//           {
//             name: 'Ring',
//             stat: true,
//             color: '#7FB3D5'
//           },
//           {
//             name: 'Little',
//             stat: true,
//             color: '#A9CCE3'
//           },
//         ]
//       },
//       {
//         region: 'DIP',
//         selected: true,
//         fingers: [
//           {
//             name: 'Thumb',
//             stat: false,
//             color: ''
//           },
//           {
//             name: 'Index',
//             stat: true,
//             color: '#D4AC0D'
//           },
//           {
//             name: 'Middle',
//             stat: true,
//             color: '#F1C40F'
//           },
//           {
//             name: 'Ring',
//             stat: true,
//             color: '#F4D03F'
//           },
//           {
//             name: 'Little',
//             stat: true,
//             color: '#F7DC6F'
//           },
//         ]
//       },
//       {
//         region: 'Finger',
//         selected: true,
//         fingers: [
//           {
//             name: 'Thumb',
//             stat: true,
//             color: '#7D3C98'
//           },
//           {
//             name: 'Index',
//             stat: true,
//             color: '#8E44AD'
//           },
//           {
//             name: 'Middle',
//             stat: true,
//             color: '#A569BD'
//           },
//           {
//             name: 'Ring',
//             stat: true,
//             color: '#BB8FCE'
//           },
//           {
//             name: 'Little',
//             stat: true,
//             color: '#D2B4DE'
//           },
//         ]
//       },

//     ]
// };
class DemriqRegionLocation {
  private static instance: DemriqRegionLocation;

  private colors: any[] = [];

  public static getInstance(): DemriqRegionLocation {
    if (!this.instance) {
      this.instance = new DemriqRegionLocation();
    }
    return this.instance;
  }

  private constructor() {
    this.init();
  }

  private init() {
    this.colors = [
      ['#A9DFBF', '#7DCEA0', '#52BE80', '#27AE60', '#229954', '#FF8333'],
      ['#2471A3', '#2980B9', '#5499C7', '#7FB3D5', '#A9CCE3', '#FFD733'],
      ['#B7981B', '#D4AC0D', '#F1C40F', '#F4D03F', '#F7DC6F', '#FFB833'],
      ['#7D3C98', '#8E44AD', '#A569BD', '#BB8FCE', '#D2B4DE', '#FFE333'],
      ['#FFA07A', '#E9967A', '#FA8072', '#F08080', '#CD5C5C', '#FCFF33'],
    ]
  }

  public getAllRegions(primaryLocations: any[], regionsToScore: any[]): any[] {
    if (!primaryLocations || !regionsToScore)
      return []
    const allRegion: any[] = [];
    regionsToScore.forEach((region, i) => {
      allRegion.push({
        region: region,
        selected: true,
        primaryLocations:[]
      });
      primaryLocations.forEach((location, j) => {
        allRegion[i].primaryLocations.push({
          name: location,
          stat: true,
          color: this.colors[i][j]
        })
      })
    })
    return allRegion;
  }
}

export const Demriq_Regions = DemriqRegionLocation.getInstance();

