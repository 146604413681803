import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {WbmriReadingModel} from '../_models/WBMRI/wbmri-reading-model';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {ZitelabImageModel} from '../_models';
import {UploadAdjFileDataItem} from "../modules/OperationsDashboard/adjudication-external-config-dialog/adjudication-external-config-dialog.component";

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingWBMRIVersion;
const SERVICE = 'msreadingwbmri-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL;

// const SERVICE_API_URL = 'http://localhost:8080/api/';

@Injectable({
  providedIn: 'root'
})
export class ReadingWbmriService {

  constructor(private http: HttpClient) {
  }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/reading/get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getNumberOfAvailableAdjudicationReadings(studyId, readerId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/reading/get-number-of-available-adjudication-readings/${studyId}/${readerId}`);
  }

  getReadingByStudyIdAndReaderId(studyId, readerId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/reading/get-available-readings/${studyId}/${readerId}`);
  }

  getAdjudicationReadingsByStudyIdAndReaderId(studyId, readerId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/reading/get-available-adjudication-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/reading/start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds, comment): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/reading/${studyId}/${readingId}`, {spentSeconds, comment});
  }

  // hole reading obj
  completeReading(studyId, reading: WbmriReadingModel): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/reading/complete/${studyId}`, reading);
  }

  getDisagreement(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/reading/get-disagreement/${studyId}/${readingId}`);
  }

  ziteLabReadingInitiation(studyId, reading: WbmriReadingModel): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${SERVICE_API_URL}/zitelab/reading/initiate/${studyId}`, reading);
  }

  laodOnSamri(studyId, readingId): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${SERVICE_API_URL}/zitelab/reading/control-samri-view/${studyId}/${readingId}`, null);
  }

  getReadingCsv(studyId: number, readingId: number) {
    return this.http.get(`${SERVICE_API_URL}/resources/reading-csv?studyId=${studyId}&readingId=${readingId}`, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  getZitelabImages(studyId: number, readingId: number) {
    return this.http.get<BasicResponse<ZitelabImageModel>>(`${SERVICE_API_URL}/reading/get-zitelab-images/${studyId}/${readingId}`);
  }

  importAdjudicationFile(studyId, data: UploadAdjFileDataItem[]): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${SERVICE_API_URL}/reading/import-adjudicator-file/${studyId}`, data);
  }
}
