export interface FormEvent {

  /**
   * Provided by incoming events. Set by websocket-server.
   */
  publisherId?: string,

  /**
   * Provided by outgoing events. Set by web client and always has value.
   */
  destination?: string,

  key: EventKey,
  value: any
}
export enum EventKey {
    ADD_LESION_REQUEST = "EVENT_ADD_LESION_REQUEST",
    ADD_LESION = "EVENT_ADD_LESION",
    GO_TO_LESION = "EVENT_GO_TO_LESION",
    EDIT_LESION_DATA = "EVENT_EDIT_LESION_DATA",
    REMOVE_LESION = "EVENT_REMOVE_LESION",
    SCREENSHOT_REQUEST = "EVENT_SCREENSHOT_REQUEST",
    SCREENSHOT_DATA = "EVENT_SCREENSHOT_DATA",
    MEASURMENT_TOOL = "EVENT_MEASUREMENT_TOOL",
    LOCK_VISIT = "EVENT_LOCK_VISIT", //TODO No one published this event.
    SUBMIT_FORM = "EVENT_SUBMIT_FORM",
    SKIP_FORM = "EVENT_SKIP_FORM",
    EXIT_FORM = "EVENT_EXIT_FORM",
    END_READING = "END_READING",
    //eligibility
    OPEN_VIEWER = "OPEN_VIEWER",
    ELIGIBILITY_ADD_LESION = "ELIGIBILITY_ADD_LESION",
    ELIGIBILITY_LESION_DATA = "ELIGIBILITY_LESION_DATA",
}
export const SNAPSHOT_RAW_DATA = 'SNAPSHOT_RAW_DATA'
