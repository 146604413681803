import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormMode} from '../../../../../core/constants/form-mode';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {StudySequenceLabelService, StudyUserService} from '../../../../../_services';
import {ActivatedRoute} from '@angular/router';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {MatTableDataSource} from '@angular/material/table';
import {forkJoin} from 'rxjs';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {
  defaultBasicAutoBatchConfig,
  ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {WbmriConfigModel} from '../../../../../_models/ImagingProject/wbmri-config-model';
import {FactorsToScoreWbmri, Reader} from '../imaging-project-reading-wbmri-wrapper/imaging-project-reading-wbmri-wrapper.component';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {MatSelectChange} from '@angular/material/select';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {ToastService} from '../../../../../_services/internal/toast.service';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
    selector: 'app-imaging-project-reading-wbmri-basic',
    templateUrl: './imaging-project-reading-wbmri-basic.component.html',
    styleUrls: ['./imaging-project-reading-wbmri-basic.component.css']
})
export class ImagingProjectReadingWbmriBasicComponent extends ImagingProjectReadingBasicComponent implements OnInit {

    @Input() selectedConfig: FlexibleConfig<WbmriConfigModel>;
    @Input() mode: FormMode;
    @Output() public clickCancel = new EventEmitter<any>();
    @Output() public clickSubmit = new EventEmitter<any>();

    readonly formModes = FormMode;
    modalities: ModalityModel[];
    studyId: number;
    basicBatchConfig: BatchConfigModel = null;
    readersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    MIN_READERS = 1;
    MAX_READERS = 20;
    readers: Reader[];
    readerSelectOptions: Reader[];
    readersTableColumns: string[] = ['username', 'email', 'action'];
    factorstoScoreWbmri = FactorsToScoreWbmri;

    wbmriForm = this.fb.group({
        readingLevel: ['PATIENT', [Validators.required]],
        modalities: [[], [Validators.required]],
        readingType: ['PARALLEL', [Validators.required]],
        hideVisitHistory: ['hide_visits_chronology', [Validators.required]],
        anatomySelector: ['', [Validators.required]],
        imageViewerConfiguration: ['default', [Validators.required]],
        factorstoScoreWbmri: [this.factorstoScoreWbmri.map(f => f.id), [Validators.required]],
        readers: ['', [this.readersValidator]],
        batchManagement: [false, [Validators.required, this.batchManagementValidator]]
    });

    selectedSequenceLabels: SequenceLabelModel[] = [];
    availableSequenceLabels: SequenceLabelModel[] = [];

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private studySequenceLabelService: StudySequenceLabelService,
        private studyUserService: StudyUserService,
        private batchLogicService: BatchLogicService,
        private toastService: ToastService,
        private dialog: MatDialog
    ) {
      super();
    }

    ngOnInit(): void {
        this.studyId = +this.route.snapshot.params.id;

        const serviceSources = [
            this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
            this.studyUserService.getReadersByStudyId(this.studyId)
        ];
        if (this.mode === this.formModes.EDIT) {
            serviceSources.push(this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id));
        }
        forkJoin(serviceSources).subscribe(
            ([groupedModalitiesResponse, readersResponse, batchConfigsResponse]) => {
                this.initModalities(groupedModalitiesResponse);
                this.initReaders(readersResponse);
                if (this.mode === this.formModes.EDIT) {
                    this.initBatchConfigs(batchConfigsResponse);
                    this.initFormValues(this.selectedConfig.config);
                }
            },
            (error) => this.showErrorMessage(error.error));
    }

    showErrorMessage(response: any): void {
        this.toastService.respError(response);
    }

    initFormValues(config: WbmriConfigModel): void {
        if (config) {
            this.wbmriForm.patchValue(config);
            this.wbmriForm.patchValue({
                modalities: config.modalities,
                readers: config.readers.map(reader => reader.id)
            });
            this.pushReaders();
        }
    }

    initBatchConfigs(batchConfigsResponse): void {
        if (batchConfigsResponse.responseCode === 200 && batchConfigsResponse.data) {
            this.basicBatchConfig = (batchConfigsResponse.data as BatchConfigModel[])
                .find(batchConfig => batchConfig.readingType === ReadingType.BASIC_READING);
        }
    }

    initReaders(readersResponse): void {
        if (readersResponse.responseCode === 200 && readersResponse.data) {
            this.readers = readersResponse.data;
            if (this.mode === this.formModes.NEW) {
                this.readerSelectOptions =
                    (readersResponse.data as Reader[]).sort(this.compareReadersByName);
            }
        }
    }

    initModalities(groupedModalitiesResponse): void {
        if (groupedModalitiesResponse.responseCode === 200 && groupedModalitiesResponse.data) {
            // this.modalities = groupedModalitiesResponse.data;//.find(m => m.name.toLowerCase() === 'mri')];
            this.modalities = (groupedModalitiesResponse.data as ModalityModel[]).sort((a, b) => a.name.localeCompare(b.name));
            if (this.mode === this.formModes.NEW) {
                this.wbmriForm.patchValue({modalities: []});
            }
            this.availableSequenceLabels = this.getAvailableSequenceLabels(this.modalities);
            this.prepareSequenceLabels(this.modalities);
        }
    }

    getAvailableSequenceLabels(modalities): SequenceLabelModel[] {
      const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
      for (const modality of modalities) {
        for (const label of modality.sequenceLabels) {
          configLabels.add(label);
        }
      }
      return <SequenceLabelModel[]> Array.from(configLabels.values());
    }

    getFormControl(path: string): FormControl {
        return this.wbmriForm.get(path) as FormControl;
    }

    compareModalities(m1: any, m2: any): boolean {
        return m1 && m2 ? m1.name === m2.name : m1 === m2;
    }

    prepareSequenceLabels(modalities) {
        if (this.mode === FormMode.EDIT) {
            if (this.selectedConfig.config.anatomySelector) {
                this.initSequenceLabels(this.selectedConfig.config.anatomySelector);
            } else {
                this.initDefaultSequenceLabels(modalities);
            }
        } else {
            this.initDefaultSequenceLabels(modalities);
        }
    }

    initDefaultSequenceLabels(modalities) {
        this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
        this.selectedSequenceLabels = [...this.availableSequenceLabels];
    }

    initSequenceLabels(sequenceLabels: { id: number }[]) {
        this.selectedSequenceLabels = this.availableSequenceLabels
            .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
    }

    get batchManagementValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value && !this.basicBatchConfig?.numberOfReadings ? {norError: 'Number of readings is required'} : null;
        };
    }

    onModalityTypeChange(event: MatSelectChange) {
      this.wbmriForm.patchValue({modalities: event.value});
      this.initDefaultSequenceLabels(this.wbmriForm.controls.modalities.value);
    }

    onSequenceLabelSelect(e: SequenceLabelModel[]) {
        this.selectedSequenceLabels = e;
    }

    onBatchManagementSelected(event: MatSelectChange): void {
        if (this.basicBatchConfig) {
            this.basicBatchConfig.numberOfReadings = null;
            this.wbmriForm.get('batchManagement').updateValueAndValidity();
        }
    }

    openAutoCreationBatchConfigDialog(): void {
        let inputConfig: AutoBatchConfigPayload;
        const val = this.wbmriForm.get('batchManagement').value;
        if (!val) {
            inputConfig = {...defaultBasicAutoBatchConfig};
        } else {
            inputConfig = this.basicBatchConfig;
        }

        const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
            width: '500px',
            data: {
                readingType: ReadingType.BASIC_READING,
                configInput: inputConfig,
                readonlyDialog: !val
            }
        });

        dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
            if (result) {
                this.basicBatchConfig = {...result};
                this.wbmriForm.get('batchManagement').updateValueAndValidity();
            }
          ImagingProjectReadingBasicComponent.clearFocus();
        });
    }

    compareReaders(r1: any, r2: any): boolean {
        return r1 && r2 ? r1.id === r2.id : r1 === r2;
    }

    isReadersValid(): boolean {
        return this.readersDataSource.data.length >= this.MIN_READERS && this.readersDataSource.data.length <= this.MAX_READERS;
    }

    get readersValidator() {   // computed property suppress property is used before its initialization error
        return (): ValidationErrors | null => {
            return this.isReadersValid() ? null : {
                tableError: `${this.MIN_READERS}..${this.MAX_READERS} users must be in the list`,
                selectError: `please ${this.readersDataSource.data.length < this.MAX_READERS ? 'select' : 'remove'} option`
            };
        };
    }

    compareReadersByName(r1: Reader, r2: Reader): number {
        return (r1.firstName + r1.lastName).localeCompare(r2.firstName + r2.lastName);
    }

    pushReaders(): void {
        const selectedReaderIds = [...this.wbmriForm.get('readers').value, ...this.readersDataSource.data.map(reader => reader.id)];
        this.readersDataSource.data =
            this.readers
                .filter(reader => selectedReaderIds.includes(reader.id))
                .sort(this.compareReadersByName);
        this.readerSelectOptions = this.readers
            .filter(reader => !selectedReaderIds.includes(reader.id))
            .sort(this.compareReadersByName);
        this.wbmriForm.patchValue({readers: []});
    }

    removeReader(id: number): void {
        this.readersDataSource.data = this.readersDataSource.data.filter(reader => reader.id !== id);
        this.readerSelectOptions = [...this.readerSelectOptions, this.readers.find(r => r.id === id)].sort(this.compareReadersByName);
        this.wbmriForm.get('readers').updateValueAndValidity();
    }

    cancel(): void {
        this.clickCancel.emit({result: 'cancel'});
    }

    prepareBasicBatchConfigPayload(): AutoBatchConfigPayload {
        let configPayload: AutoBatchConfigPayload = null;
        const configId: number = this.basicBatchConfig?.id ? this.basicBatchConfig.id : null;
        if (this.wbmriForm.get('batchManagement').value) {
            configPayload = {
                numberOfReadings: this.basicBatchConfig.numberOfReadings,
                readingType: ReadingType.BASIC_READING,
                initiationMethod: this.basicBatchConfig.initiationMethod,
                initiationOption: this.basicBatchConfig.initiationOption,
                batchCount: this.basicBatchConfig.batchCount
            };
        } else {
            configPayload = {...defaultBasicAutoBatchConfig};
            configPayload.readingType = ReadingType.BASIC_READING;
        }
        configPayload.id = configId;
        return configPayload;
    }

    //noinspection Duplicates
    submit(): void {
        const payload: WbmriConfigModel = this.wbmriForm.value;
        payload.modalities = this.wbmriForm.value.modalities;
        payload.readers = this.readersDataSource.data.map(reader => {
            return {id: reader.id};
        });
        payload.readingVersion = ReadingVersion.WBMRI_BASIC;
        payload.endpointReadingVersion = 'basic';
        payload.anatomySelector = this.selectedSequenceLabels.map(l => {
            return {id: l.id};
        });

        const imagingProjectSubmitEvent: ImagingProjectSubmitEvent = {
            result: 'submit',
            data: payload,
            basicBatchConfig: this.prepareBasicBatchConfigPayload()
        };
        this.clickSubmit.emit(imagingProjectSubmitEvent);
    }
}
