import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class ReadingConfigService {

  constructor(private http: HttpClient) { }

  create(readingConfig: any) {
    return this.http.post(requestURL + `/reading-config`, readingConfig);
  }

  update(readingConfig: any) {
    return this.http.put(requestURL + `/reading-config`, readingConfig);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/reading-config/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/reading-config/` + id);
  }

  getAll() {
    return this.http.get(requestURL + `/reading-config/retrieve-all`);
  }

  addReaders(readingConfigId: any, readerIds: any) {
    return this.http.put(requestURL + `/reading-config/add-readers/` + readingConfigId, readerIds);
  }

  getReadingConfigByStudyIdAndEndpointName(studyId: number, endpointName: string) {
    return this.http.get(requestURL + `/reading-config/get-reading-config-by-study-id-and-endpoint-name/` + studyId + '/' + endpointName);
  }

  getAllReadingConfigsByStudyId(studyId: number) {
    return this.http.get(requestURL + `/reading-config/get-all-reading-configs-by-study-id/` + studyId);
  }

}