import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {UploadAdjFileDataItem} from '../modules/OperationsDashboard/adjudication-external-config-dialog/adjudication-external-config-dialog.component';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingPSAMRISVersion;
const SERVICE = 'msreadingpsamris-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/reading/';
// const SERVICE_API_URL = 'http://localhost:8080/api/reading/';

const NO_METHOD = undefined;
const METHOD_GET_NUMBER_OF_AVAILABLE_READINGS = 'get-number-of-available-readings';
const METHOD_GET_AVAILABLE_READINGS = 'get-available-readings';
const METHOD_GET_NUMBER_OF_AVAILABLE_ADJUDICATION_READINGS = 'get-number-of-available-adjudication-readings';
const METHOD_GET_AVAILABLE_ADJUDICATION_READINGS = 'get-available-adjudication-readings';
const METHOD_GET_DISAGREEMENT = 'get-disagreement';
const METHOD_GET_ZITELAB_IMAGES = 'get-zitelab-images';

// const METHOD_UPDATE_READING = 'reading';
const BASE_URL = `https://${API_VERSION}${SERVICE}${API_URL}`;

@Injectable({
  providedIn: 'root'
})
export class ReadingPsamrisService {

  constructor(private http: HttpClient) {
  }

  ziteLabReadingInitiation(studyId, readingId, handSide): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/zitelab/reading/initiate/${studyId}`;
    const data = {
      id: readingId,
      handSide: handSide
    };
    return this.http.post<BasicResponse<any>>(url, data);
  }

  laodOnSamri(studyId, readingId) {
    const url = `${BASE_URL}/zitelab/reading/control-samri-view/${studyId}/${readingId}`;
    return this.http.post(url, null);
  }

  getNumberOfAvailableReadings(studyId, readerId) {
    const url = this.buildApiUrl(METHOD_GET_NUMBER_OF_AVAILABLE_READINGS, studyId, readerId);
    return this.http.get(url);
  }

  getReadingByStudyIdAndReaderId(studyId, readerId): Observable<BasicResponse<any>> {
    const url = this.buildApiUrl(METHOD_GET_AVAILABLE_READINGS, studyId, readerId);
    return this.http.get<BasicResponse<any>>(url);
  }

  getNumberOfAvailableAdjudicationReadings(studyId, readerId) {
    const url = this.buildApiUrl(METHOD_GET_NUMBER_OF_AVAILABLE_ADJUDICATION_READINGS, studyId, readerId);
    return this.http.get(url);
  }

  getAdjudicationReadingsByStudyIdAndReaderId(studyId, readerId): Observable<BasicResponse<any>> {
    const url = this.buildApiUrl(METHOD_GET_AVAILABLE_ADJUDICATION_READINGS, studyId, readerId);
    return this.http.get<BasicResponse<any>>(url);
  }

  updateReading(studyId, readingId, comment, spentSeconds): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/${studyId}/${readingId}`;
    const data = {
      comment: comment,
      spentSeconds: spentSeconds,
    };
    return this.http.put<BasicResponse<any>>(url, data);
  }

  completeReading(studyId, readingId, comment, spentSeconds): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/complete/${studyId}`;
    const data = {
      id: readingId,
      studyId: studyId,
      comment: comment,
      spentSeconds: spentSeconds,
    };
    return this.http.put<BasicResponse<any>>(url, data);
  }

  startReading(studyId, readingId): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/start/${studyId}/${readingId}`;
    const data = {
      studyId: studyId,
      readingId: readingId,
    };
    return this.http.put<BasicResponse<any>>(url, data);
  }

  buildApiUrlNoMethod(...params: any[]): string {
    return this.buildApiUrl(NO_METHOD, params);
  }

  getDisagreement(studyId: number, readingId: number) {
    const url = this.buildApiUrl(METHOD_GET_DISAGREEMENT, studyId, readingId);
    return this.http.get(url);
  }

  buildApiUrl(methodName: String, ...params: any[]): string {
    let url = SERVICE_API_URL;

    let hasMethod = NO_METHOD !== methodName;
    if (hasMethod) {
      url += methodName;
    }

    for (const param of params) {
      if (hasMethod) {
        url += (`/${param}`);
      } else {
        url += param;
        hasMethod = true;
      }
    }
    return url;
  }

  importAdjudicationFile(studyId, data: UploadAdjFileDataItem[]): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${SERVICE_API_URL}/import-adjudicator-file/${studyId}`, data);
  }
}
