import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSQueriesVersion;
const SERVICE = 'msqueries-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class QueriesService {

  constructor(private http: HttpClient) { }

  create(queries: any, studyId: number) {
    return this.http.post(requestURL + '/queries/' + studyId, queries);
  }

  update(queries: any, studyId: number) {
    return this.http.put(requestURL + '/queries/' + studyId, queries);
  }

  delete(queriesId: number, studyId: number) {
    return this.http.delete(requestURL + '/queries/' + queriesId + '/' + studyId);
  }

  getQueries(queriesId: number, studyId: number) {
    return this.http.get(requestURL + '/queries/' + queriesId + '/' + studyId);
  }

  getQueriesByStudyId(studyId: number) {
    return this.http.get(requestURL + '/queries/get-queries-by-study-id/' + studyId);
  }

  getQueriesByQcTaskId(qcTaskId: number, studyId: number) {
    return this.http.get(requestURL + '/queries/get-queries-by-qc-task-id/' + qcTaskId +'/' + studyId);
  }

  getQueriesByVisitConfigId(visitConfigId: number, studyId: number) {
    return this.http.get(requestURL + '/queries/get-queries-by-visit-config-id/' + visitConfigId +'/' + studyId);
  }

  getQueriesByIds(queriesIds: number[], studyId: number) {
    return this.http.put(requestURL + '/queries/get-queries-by-ids/' + studyId, queriesIds);
  }

  getAll(studyId: number) {
    return this.http.get(requestURL + '/queries/retrieve-all/' + studyId);
  }

  getQueriesByGUID(guid: string){
    return this.http.get(requestURL + '/queries/get-queries-by-guid/' + guid);
  }

  updateStatusToUpdated(queryId:number, studyId: number){
    return this.http.put(requestURL + '/queries/update-status-to-updated/' + queryId + '/' + studyId,{});
  }

  updateStatusToResolved(queryId:number, resolvedUserId:number, studyId: number){
    return this.http.put(requestURL + '/queries/update-status-to-resolved/' + queryId + '/'+ resolvedUserId + '/' + studyId,{});
  }
}
