import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { StudyService } from '../../../_services/study.service';

export interface DialogModalityData {
  isAdd: boolean;
}

@Component({
  selector: 'app-qcpatientedit-dialog',
  templateUrl: './qcpatientedit-dialog.component.html',
  styleUrls: ['./qcpatientedit-dialog.component.css']
})
export class QCPatienteditDialogComponent implements OnInit {

  patientCode = '';
  reasonComment = '';
  allPatientsCodes = new Array();
  patientCodePatternRegEx = '';
  patientCodePatternMessage = '';

  patientForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<QCPatienteditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogModalityData,
              private studyService: StudyService,
              private fb: FormBuilder) {
    this.allPatientsCodes = data['allPatientsCodes'];
    this.patientForm = this.fb.group({
      patientCode: [this.patientCode, [Validators.required, Validators.pattern(this.patientCodePatternRegEx)]],
      reasonComment: [this.reasonComment, Validators.required],
    });
    this.patientCodePatternRegEx = data['patientCodePatternRegEx'];
    this.patientCodePatternMessage = data['patientCodePatternMessage'];
    this.patientForm.controls['patientCode'].clearValidators();
    this.patientForm.controls['patientCode'].setValidators([Validators.required, Validators.pattern(this.patientCodePatternRegEx)]);
  }

  ngOnInit() {

  }

  onClickCancel(): void {
    this.dialogRef.close({status: 'cancel', data: null} );
  }

  onClickOk(): void {
    let data = this.patientForm.getRawValue();
    let code = data.patientCode;
    let reason = data.reasonComment
    this.dialogRef.close({status: 'ok', data: {patientCode: code, reasonComment: reason} });
  }

  isPatientCodeExist() {
    let data = this.patientForm.getRawValue();
    let code = data.patientCode;
    return this.allPatientsCodes.includes(code);
  }
}
