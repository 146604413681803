import { MarkerType, MranoEfficacyLesionSetting, MranoEfficacyStatusOptions } from './../../../../../../_models/Oncology/global-lesion-model';
import { MranoEfficacyOncologyLesionDialogComponent } from './../../mrano-efficacy-oncology-lesion-dialog/mrano-efficacy-oncology-lesion-dialog.component';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MessageDialogComponent} from 'src/app/components/controls/message-dialog/message-dialog.component';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {Subscription} from 'rxjs';
import {EfficacyLestionTableColumns, HeaderNames, LestionTableColumns} from 'src/app/_models/Oncology/lesion-display-columns-model';
import {EventKey, FormEvent} from 'src/app/_models/Oncology/local-storage-keys.model';
import {
  AddLesionRequest,
  GlobalLesionModel,
  LesionTypeMarkers,
  OncologyAddLesionData,
  OncologyLesionEditType,
  OncologyLesionType,
  OncologyResponseEnum,
  OncologyResponseType,
  ResponseOptions,
  StatusOptions
} from 'src/app/_models/Oncology/global-lesion-model';
import {OncologyAssessmentVisitModel} from 'src/app/_models/Oncology/oncology-assessment-visit-model';
import {OncologyAssessmentService} from 'src/app/_services/oncology-assessment.service';
import {FormPage, MessagingService} from 'src/app/_services/messaging.service';

export enum APIType {
  POST,
  PUT,
  DELETE
}

@Component({
  selector: 'app-mrano-efficacy-dynamic-brain-lesion-table',
  templateUrl: './mrano-efficacy-dynamic-brain-lesion-table.component.html',
  styleUrls: ['./mrano-efficacy-dynamic-brain-lesion-table.component.css']
})
export class MranoEfficacyDynamicBrainLesionTableComponent implements OnInit, OnDestroy, OnChanges {
//parameters for localstorage
eventSubscription: Subscription;
readerId: number;
displayedColumns: string[] = [];
dataSource = new MatTableDataSource<any>();
lesionsDataSource: GlobalLesionModel[] = [];
RoiSeriesStatus = [];
primaryLocations = [];
subLocations = [];
locationSubscription;
responseType = OncologyResponseType;
responseEnum = OncologyResponseEnum;
reaponseOptions = ResponseOptions;
MranoEfficacyLesionSetting = MranoEfficacyLesionSetting;

public get editType(): typeof OncologyLesionEditType {
  return OncologyLesionEditType;
}

public get OncologyLesionType(): typeof OncologyLesionType {
  return OncologyLesionType;
}
@ViewChild(MatSort) sort: MatSort;
@Input() endpointName:string;
@Input() studyId:number;
@Input() readingId: number;
@Input() patientId: number;
@Input() lesionType: string;
@Input() visit: OncologyAssessmentVisitModel;
@Input() sequentialLock: boolean;
@Input() eCRFOnly:boolean;
@Input() lesionList: GlobalLesionModel[] = [];
@Input() defaultPrimaryLocation: string;
@Input() lock: boolean;
@Output('changeResponse') changeResponse: EventEmitter<{type:OncologyResponseType,value:string}>= new EventEmitter<{type:OncologyResponseType,value:string}>();
toastOptions: ToastOptions = {
  title: '',
  showClose: true,
  timeout: 10000,
  theme: 'material',
};
baselineNadirSubscription: Subscription;
baselineSPPD: any;
nadirSPPD: any;
historicalNadir: any[];
nadirVisitConfigId: number;
readingAllLesions: any[];
allLesionSubscription: Subscription;
  constructor(
    private dialog: MatDialog,
    private oncologyAssessmentService: OncologyAssessmentService,
    private toastyService: ToastyService,
    private dialogMessagePreview: MatDialog,
    private messagingService: MessagingService
  ) { }

  ngOnInit(): void {
    this.readerId = +JSON.parse(localStorage.getItem('userId'));

    this.displayedColumns = EfficacyLestionTableColumns[this.lesionType];
    this.shapeDisplayColumns();

    //get all locations
    this.locationSubscription = this.oncologyAssessmentService.location.subscribe(
      (response) => {
        if (response) {
          this.primaryLocations = response;
        }
      }
    )

    //get all lesions
    this.allLesionSubscription = this.oncologyAssessmentService.allLesions.subscribe(response => {
      if (response) {
        this.readingAllLesions = response;
      }
    })



    //baseline/nadir sppdChanges
    this.baselineNadirSubscription = this.oncologyAssessmentService.baselineNadirSPPD.subscribe(data => {
      if (data) {
        this.nadirSPPD = data.nadirSPPD;
        this.baselineSPPD = data.baselineSPPD;
        this.historicalNadir = data.historicalNadir
        this.nadirVisitConfigId = data.nadirVisitConfigId
      }
    })

    //TODO This may need fix. This calls multiple times in viewer mode of MRANO form.
    const roomIdPostfix = `${this.randomInt()}`
    this.eventSubscription = this.messagingService.init(this.readerId, this.readingId, FormPage.DYNAMIC_BRAIN_LESION)
      .subscribe(this.handleEvents.bind(this))
  }

  randomInt(max: number = 999_999_999) {
    return Math.floor(Math.random() * max);
  }

  //get new lesion list if changed from dynamic scoring form
  ngOnChanges(changes: SimpleChanges) {
    if (changes.lesionList) {
      this.lesionList = changes.lesionList.currentValue;
      this.refreshLesionSource();
    }
  }

  //shape display columns dynamically
  shapeDisplayColumns() {
    if (this.visit.baseline) {
      this.displayedColumns = this.displayedColumns
        .filter(c => {
          return c !== 'meppdChgNadir' &&
                 c !== 'notMeasurabilityReason'
        });
    } else if (this.lesionType !== OncologyLesionType.NEW_LESION) {
      this.displayedColumns = this.displayedColumns.filter(c => {return c !== 'operations'})
    }
  }

  ngOnDestroy(): void {
    if (this.eventSubscription)
      this.eventSubscription.unsubscribe();

    if (this.baselineNadirSubscription)
      this.baselineNadirSubscription.unsubscribe();

    if (this.allLesionSubscription)
      this.allLesionSubscription.unsubscribe();
  }

  //get lesion data from viewer
  handleEvents(event: FormEvent) {
    if (!event.value)
      return;
    switch (event.key) {
      case EventKey.ADD_LESION:
        this.onOpenBrainLesion(event.value)
        break;
    }
  }

  //get proper table header names
  getHeaderName(key: string) {
    return HeaderNames?.find(c => c[0] === key)?.[1];
  }

  //set lesions to table
  refreshLesionSource() {
    if (this.lesionList) {
      this.lesionsDataSource =  this.filterLesions(this.lesionList);
      this.dataSource = new MatTableDataSource(this.lesionsDataSource);
      this.dataSource.sort= this.sort;

      this.oncologyAssessmentService.modalSpinnerChangeState(false);
      this.oncologyAssessmentService.onEditLesionMeasurement(null);
      this.checkAllLesionsReviewed();
    }

  }

  //check if all lesions are reviewed
  checkAllLesionsReviewed() {
    let reviewedLesion = false ;
    reviewedLesion = this.lesionsDataSource.filter(lesion => {
      return lesion.snapshot == null || lesion.shortAxis == null || lesion.axialLongDiameter == null || lesion.ppd == null || lesion.subLocation === 'location'
        || ( (lesion.shortAxis < 10 || lesion.axialLongDiameter < 10) && (this.lesionType === OncologyLesionType.TARGET_LESION && this.visit.baseline))
    }).length === 0;
    this.oncologyAssessmentService.sendLesionReviewed({ visitConfigId: this.visit.visitConfigId, status: { type: this.lesionType, reviewed: reviewedLesion } });
  }

  //filter lesions by unique constraints
  filterLesions(lesions:GlobalLesionModel[]):GlobalLesionModel[]{
    const filtered = lesions.filter(
      (item) => {
        return item.type == this.lesionType  &&
        item.visitConfigId == this.visit.visitConfigId &&
        item.readingId == this.readingId &&
        item.endpointName == this.endpointName
      }
    )
    return filtered;
  }

  onChangeSublocation(event,lesion:GlobalLesionModel) {
    lesion.subLocation = event.value;
    this.addOrUpdateLesionService(lesion, true);
  }

  requestToAddOrEditLesion() {
    const maxLesionNumber = this.MranoEfficacyLesionSetting.maxLesionNo[this.lesionType]
    if (this.lesionsDataSource.length >= maxLesionNumber) {
      this.toastOptions.title = 'ERROR Cannot add lesion';
      this.toastOptions.msg = `Cannot add more than ${maxLesionNumber} ${this.lesionType.split('_').join(' ').split("NON ").join("NON-").toLowerCase()}s `;
      this.toastyService.error(this.toastOptions);
      return;
    }
    const lesion = new GlobalLesionModel(this.lesionType, this.patientId, this.readerId,
      this.readingId, this.visit.visitConfigId, this.endpointName,
      this.visit.baseline, MarkerType.BI_RULER);
    lesion.createLesionTemplate();
     this.addOrUpdateLesion(lesion, false)
  }

  //edit measurement of lesion
  onEditMeasurement(editType?:string,currentLesionData?:any){
    let data;
    let tempSnapshot;
    const tempCurrentLesionData = { ...currentLesionData };
    if (currentLesionData) {
      if (currentLesionData.snapshot) {
        tempSnapshot = {
          seriesId: currentLesionData.snapshot.seriesId,
          sliceNumber: currentLesionData.snapshot.sliceNumber
        }
        tempCurrentLesionData.snapshot = tempSnapshot;
      }
       data= {
        editMode:true,
        editType:editType,
        currentLesionData: tempCurrentLesionData,
         requestData: {}
       }
    }

    if (!this.eCRFOnly) {
      const markerType = this.lesionType === OncologyLesionType.TARGET_LESION ? LesionTypeMarkers[this.lesionType] :
        (currentLesionData.isMeasurable ? MarkerType.BI_RULER : MarkerType.MARKER)
      const requestData: AddLesionRequest = {
      readingId: this.readingId,
      visitId: this.visit.id,
      visitConfigId: this.visit.visitConfigId,
      lesionType: this.lesionType,
      markerType: markerType,
      lesionName: currentLesionData.lesionName
      }
      data.requestData = requestData;
      const message: FormEvent = {key: EventKey.EDIT_LESION_DATA, value: data};
      this.messagingService.sendRequest(message, FormPage.MRANO)
      return;
    } else
      this.onOpenBrainLesion(
        {
          edit: true,
          data: currentLesionData,
          editType: this.editType.MEASUREMENT,
          viewerData: null,
          requestedData: null
        })
  }

  //request (to viewer) to go to lesion
  goToLesion(lesion?: GlobalLesionModel) {
    const message: FormEvent = {key: EventKey.GO_TO_LESION, value: {currentLesionData: lesion}};
    this.messagingService.sendRequest(message, FormPage.MRANO)
    return;
  }

  //open brain lesion dialog
  onOpenBrainLesion(sentData: OncologyAddLesionData) {
    //check match with table:
    if (sentData.editType == this.editType.MEASUREMENT || !sentData.editType)
      if (sentData.requestedData?.visitId != this.visit.id ||
        sentData.requestedData?.readingId != this.readingId ||
        sentData.requestedData?.lesionType != this.lesionType)
        return;

    if ((this.lesionType === OncologyLesionType.TARGET_LESION || ((this.lesionType === OncologyLesionType.NON_TARGET_LESION || this.lesionType === OncologyLesionType.NEW_LESION) && sentData.data.isMeasurable === true)) && sentData.editType == this.editType.MEASUREMENT && (sentData.viewerData.axialLongDiameter < 10 || sentData.viewerData.shortAxis < 10)) {
      const message = 'Measurable lesion must be above 10x10 mm';

      const dialogRef = this.dialog.open(MessageDialogComponent, {
        height: '200px',
        width: '600px',
        disableClose: true,
        data: {
          title: 'Warning',
          message: message,
          showOk: true,
          showCancel: false,
          html: false
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        this.openMranoEfficacyOncologyLesionDialog(sentData);
      });
    } else {
      this.openMranoEfficacyOncologyLesionDialog(sentData);
    }
  }

  //open MranoEfficacyOncologyLesionDialog
  openMranoEfficacyOncologyLesionDialog(sentData: OncologyAddLesionData) {
    const dialogRef = this.dialog.open(MranoEfficacyOncologyLesionDialogComponent, {
      width: '500px',
      data: {
        lesionName: sentData.edit ? sentData.data.lesionName : this.generateLesionName(),
        studyId: this.studyId,
        readingId: this.readingId,
        readerId: this.readerId,
        patientId: this.patientId,
        endpointName: this.endpointName,
        visitConfigId: this.visit.visitConfigId,
        isBaseline: this.visit.baseline,
        markerType: LesionTypeMarkers[this.lesionType],
        editDialog: sentData.edit,
        lesionData: (sentData.data ? sentData.data : {}),
        screenShotData: sentData?.data?.snapshot,
        editType: sentData.editType,
        lesionType: this.lesionType,
        viewerData: sentData.viewerData,
        eCRFOnly: this.eCRFOnly,
        hasComment: true,
        lesionList: this.lesionsDataSource,
        disableLocationEdit: this.lesionType === OncologyLesionType.NEW_LESION && this.disableEditNewLesion(sentData.data)
      },
      hasBackdrop:false,
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result)
        this.addOrUpdateLesion(result, sentData.edit)
      else {
        if (sentData.edit) {
          this.toastOptions.title = 'INFO Edit mode is disabled';
          this.toastOptions.msg = 'To edit object try to click edit button';
          this.toastyService.info(this.toastOptions);
        }
      }
      //clear local storage causes DOM exception
      // this.clearAllLocalStorageKeys();
    });
  }

  //generate proper lesion name
  generateLesionName() {
    const objectLength =
      this.lesionsDataSource.length + 1;
    return objectLength < 10 ?
           (`${this.MranoEfficacyLesionSetting.prefixLesionID[this.lesionType]}0${objectLength}`).toString() :
           (`${this.MranoEfficacyLesionSetting.prefixLesionID[this.lesionType]}${objectLength}`).toString();
  }

  //prepare to update/add lesion
  addOrUpdateLesion(data: any, edit: boolean) {
    let lesion: GlobalLesionModel = data;
    if (!edit)
      lesion.lesionName = this.generateLesionName();

    this.addOrUpdateLesionService(lesion, edit)
  }

  sortedScreenshots() {
    const screenshots = this.lesionsDataSource.sort((l1, l2) => l1.id - l2.id);
    return screenshots;
  }

  onShowImage(lesion: GlobalLesionModel) {
    let title = "There is no image to preview";
    let message = "";
    if (lesion.snapshot) {
      title = 'Lesion: ' + lesion.lesionName + ',  Modality: ' + lesion.snapshot.modalityName  + ', Label: ' + lesion.snapshot.sequenceLabelName + ', Slice: '
              + lesion.snapshot.sliceNumber;
      message = '<img width="100%" src="' + lesion.snapshot.fileUrl + '"/>';
    }
    const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
      disableClose: true,
      hasBackdrop: false,
      data: {title: title, message: message, html: true, isDraggable: true}
    });

    dialogMessaegRef.afterClosed().subscribe(result => {
    });
  }

  getStatusText(status: string) {
    return MranoEfficacyStatusOptions[this.lesionType].find(i => i.value === status)?.text
  }

  onDelete(row) {
    const message ='Do you want to delete the current lesion?';

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '200px',
      width: '600px',
      disableClose: true,
      data: {
          title: 'Confirmation',
          message: message,
          showOk: true,
          showCancel: true,
          html : false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
        if (result === 'ok') {
          this.deleteLesionService(row);
        }
    });
  }

  //add/update lesion service
  addOrUpdateLesionService(data:any,edit:boolean){
    this.oncologyAssessmentService.modalSpinnerChangeState(true)
      this.oncologyAssessmentService
      .updateLesionData(this.studyId,data,edit).subscribe(
        response=>{
          if (response['responseCode'] == 200) {
            response['data'].snapshot = data.snapshot;
            if(edit)
            {
              this.updateLesionList(APIType.PUT,response['data']);
              this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
              this.toastOptions.msg = 'Lesion is updated';
            }
            else
            {
              this.updateLesionList(APIType.POST,response['data']);
              this.toastOptions.title = 'ID 42: Lesion has been created successfully';
              this.toastOptions.msg = 'Lesion is now available in the list of lesions';
            }
            this.oncologyAssessmentService.baselineNadirSPPDRequest(true);
            this.toastyService.success(this.toastOptions);
          }
          else{
            if(edit){
              this.toastOptions.title = 'ID 45: Lesion updating failure';
              this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
            }
            else {
              this.toastOptions.title = 'ID 43: Lesion creation failure';
              this.toastOptions.msg = 'Lesion is not created due to some reason';
            }
            this.toastyService.error(this.toastOptions);
          }
        }
      )
  }

  //delete lesion service
  deleteLesionService(lesion:any){
   this.oncologyAssessmentService.modalSpinnerChangeState(true)
    this.oncologyAssessmentService
    .deleteLesion(this.studyId,lesion.id,this.MranoEfficacyLesionSetting.prefixLesionID[this.lesionType]).subscribe(
      response=>{
        if(response['responseCode']==200){
          this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
          this.toastOptions.msg = 'Lesion is updated';
          this.toastyService.success(this.toastOptions);
          this.lesionList = this.lesionList.filter(l => { return l.id !== lesion.id });
          this.oncologyAssessmentService.baselineNadirSPPDRequest(true);
          this.updateLesionList(APIType.DELETE,response['data'], lesion);
        }
       this.oncologyAssessmentService.modalSpinnerChangeState(false)
      },
      error =>{
          this.toastOptions.title = 'ID 45: Lesion updating failure';
          this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
         this.toastyService.error(this.toastOptions);
       this.oncologyAssessmentService.modalSpinnerChangeState(false)
      }
    )
  }

  //update lesion Data source after api call
  updateLesionList(type: APIType, data, deletedLesion? : GlobalLesionModel) {
    switch (type) {
      case APIType.POST:
        this.oncologyAssessmentService.addToAllLesions([data])
        this.lesionList.push(data);
        this.refreshLesionSource();
        break;
      case APIType.PUT:
        for (let i = 0; i < this.lesionList.length; i++) {
          if (this.lesionList[i].id === data.id) {
            this.lesionList[i] = data;
            break;
          }
        }
        this.oncologyAssessmentService.updateAllLesions(data)
        this.refreshLesionSource();
        break;
      case APIType.DELETE:
        (data as GlobalLesionModel[]).forEach(d => {
          d.snapshot = this.lesionList.find(l => l.id === d.id).snapshot
        })
        this.oncologyAssessmentService.deleteFromAllLesions(deletedLesion, data)
        this.lesionList = data;
        this.refreshLesionSource();
        break;

      default:
        break;
    }
  }

  // get list of lesion to merge
  getOtherOptionsForMeasurabilityStatus(lesion:GlobalLesionModel){
    return this.lesionsDataSource.filter(l => {
      return l.lesionName !== lesion.lesionName
    }).map(l => {return l.lesionName})
  }

  onChangeMeasurabilityStatus(event, lesion: GlobalLesionModel) {
    lesion.notMeasurabilityReason = event.value;
    lesion.mergedWith = null;
    lesion.isMerged = null;
    lesion.snapshot = null;
    lesion.isMeasurable = event.value == 'PRESENT';
    switch (event.value) {
      case 'RESOLVED':
        lesion.axialLongDiameter = 0;
        lesion.shortAxis = 0;
        lesion.ppd = 0;
        break;
      case 'TOO_SMALL_TO_MEASURE':
        lesion.axialLongDiameter = 5;
        lesion.shortAxis = 5;
        lesion.ppd = 0.25;
        break;
      case 'MERGED_WITH_ANOTHER_LESION':
        lesion.mergedWith = event.source.triggerValue.split(' ')[2];
        lesion.isMerged = true;
        lesion.axialLongDiameter = 0;
        lesion.shortAxis = 0;
        lesion.ppd = 'NA';
        break;
      case 'NOT_EVALUABLE':
        lesion.axialLongDiameter = 'NE';
        lesion.shortAxis = 'NE';
        lesion.ppd = 'NE';
        break;
      default:
        break;
    }
    this.addOrUpdateLesionService(lesion, true);
  }

  onChangeStatus(event,lesion:GlobalLesionModel){
    lesion.status = event.value;
    lesion.snapshot = null;
    if(this.visit.baseline)
      lesion.status = 'NA';
    this.addOrUpdateLesionService(lesion, true);
  }
  showResponseOptions() {
    return this.reaponseOptions[this.responseType[this.lesionType]];
  }

  onChangeChangeStatus(event, lesion: GlobalLesionModel) {
    lesion.changeStatus = event.value;
    this.addOrUpdateLesionService(lesion, true);
  }
  correctPPD(nadir:boolean, sppd?: string | number) {
    let result: any = +sppd;
     if (nadir)
       result = +sppd;

    switch (sppd) {
      case 'NE':
        result = null;
        break;
      case "NA":
      case null:
        result = sppd
      break;
    }
    return result
  }
  calculateSummary(){
    return this.sppdCalculation(this.lesionsDataSource);
  }

  sppdCalculation(lesionSource:GlobalLesionModel[]){
    let sppd = 0;
    if(lesionSource)
      lesionSource.forEach(l => {
        let ppd = l.ppd;
        if (ppd && ppd !== 'NA' && ppd !== 'NE' && l.isMeasurable &&
          (l.status === 'PRESENT' || l.type === this.OncologyLesionType.TARGET_LESION))
          sppd += +ppd;
      });
    return +sppd;
  }
  onChangeResponse(event){
    this.changeResponse.emit({type:this.responseType[this.lesionType],value:event.value})
  }
  checkLocked() {
   return this.sequentialLock || this.lock
  }

  disableEditMeasurability(lesion: GlobalLesionModel) {
    return !lesion.isEnhanced || (this.disableEditNewLesion(lesion) && lesion.isMeasurable)
  }

  disableEditNewLesion(lesion: GlobalLesionModel) {
      return this.readingAllLesions.filter(l => l.lesionName === lesion.lesionName).length > 1
  }
}
