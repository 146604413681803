import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import toInteger from 'lodash/toInteger';
import { AnnoSubjectEnum } from './AnnoSubjectEnum';
import { AnnoTypeEnum } from './AnnoTypeEnum';

export class ROIRow {
  private _name = '';
  private _type = '';
  private _area = '';
  volume = -1;
  private _slice = '';
  private _label = '';
  color = '';
  private _mprType = '';
  private _createMethod = '';
  private _lastEditedBy = '';
  private _lastEditedOn = '';
  isChanged = false;
  annoSopIUID = '';
  srcChildren: ltAnnotationsEngine.AnnObject[] = [];
  child: ltAnnotationsEngine.AnnObject = null;
  isSelected = false;
  parentCell = null;

  constructor(child: ltAnnotationsEngine.AnnObject = null) {
    if (!isEmpty(child)) {
      const meta = child.get_metadata();

      this.name = get(meta, 'Name', '');
      this.type = get(meta, 'AnnoType', '');
      this.area = get(meta, 'Area', '');
      this.slice = get(meta, 'SliceNumber', '');
      this.mprType = get(meta, 'MPRType', '');
      this.createMethod = get(meta, 'CreateMethod', '');
      this.lastEditedBy = get(meta, 'LastEditedBy', '');
      this.lastEditedOn = get(meta, 'LastEditedOn', '');
      this.label = get(meta, 'Label', '');
      this.isChanged = get(meta, 'IsChanged', '') === 'true';

      if (meta.Subject === AnnoSubjectEnum.DIAM_RULER_L14) {
        this.type = AnnoTypeEnum.CROSS_RULER;
        this.mprType = 'Axial';
      }
      if (meta.Subject === AnnoSubjectEnum.GBMROI) {
        this.type = AnnoTypeEnum.FREEHAND;
        this.mprType = 'Axial';
      }
      this.child = child;
    }
  }

  set name(name) {
    this._name = name !== '' ? name : '--';
  }

  get name() {
    return this._name;
  }

  set type(type) {
    this._type = type !== '' ? type : '--';
  }

  get type() {
    return this._type;
  }

  set mprType(mprType) {
    this._mprType = mprType !== '' ? mprType : '--';
  }

  get mprType() {
    return this._mprType;
  }

  set area(area) {
    this._area = area !== '' && area !== '0' ? toInteger(area) : '--';
  }

  get area() {
    return this._area;
  }

  set createMethod(createMethod) {
    this._createMethod = createMethod !== '' ? createMethod : '--';
  }

  get createMethod() {
    return this._createMethod;
  }

  set slice(slice) {
    this._slice = slice !== '' ? slice : '-1';
  }

  get slice() {
    return this._slice;
  }

  set lastEditedBy(lastEditedBy) {
    this._lastEditedBy = lastEditedBy !== '' ? lastEditedBy : '--';
  }

  get lastEditedBy() {
    return this._lastEditedBy;
  }

  set lastEditedOn(lastEditedOn) {
    this._lastEditedOn = lastEditedOn !== '' ? lastEditedOn : '--';
  }

  get lastEditedOn() {
    return this._lastEditedOn;
  }

  set label(label) {
    this._label = label !== '' ? label : 'None';
  }

  get label() {
    return this._label;
  }
}
