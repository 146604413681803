import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface RanoImageConfigurationDialogData {
  reading : string,
  readonlyDialog:boolean,
  imageConfig:any
}
export interface RanoImageViewerConfigurationModel{
  autoSegmentation:boolean
}
@Component({
  selector: 'app-rano-image-config-dialog',
  templateUrl: './rano-image-config-dialog.component.html',
  styleUrls: ['./rano-image-config-dialog.component.css']
})
export class RanoImageViewerConfigDialogComponent implements OnInit {
  imageViewerConfig:RanoImageViewerConfigurationModel;
  constructor(public dialogRef: MatDialogRef<RanoImageViewerConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: RanoImageConfigurationDialogData) {
}
imgConfigForm:FormGroup= new FormGroup({})


  ngOnInit(): void {
    this.imgConfigForm= new FormGroup({
      autoSegmentationControl:  new FormControl(null,Validators.required)
    })
   this.imgConfigForm.get('autoSegmentationControl').setValue(this.data.imageConfig.autoSegmentation);
  }
  okClick(){
    this.imageViewerConfig = {
      autoSegmentation:this.imgConfigForm.get('autoSegmentationControl').value,
    }
    this.dialogRef.close(this.imageViewerConfig)
  }
}
