import {SeriesModel} from '..';
import {VisitModel} from './visit-model';

export interface PatientModel {
  id: number;
  created: Date;
  discontinued: boolean;
  discontinuedUpdated: Date;
  gender: string;
  modified: Date;
  modifyComment: string;
  modifyUser: number;
  patientBirthDate: Date;
  patientCode: string;
  phantomData: boolean;
  visitConfigs: VisitConfig[];
}

export interface VisitConfig {
  id: number;
  created: Date;
  expectedVisitDate: any;
  series: SeriesModel[];
  updateReasonComment: string | null;
  updateUserId: number | null;
  uploadDate: Date | null;
  visit: VisitModel;
  visitType: VisitType;
}

export enum VisitType {
  BASELINE = 'BASELINE',
  REGULAR = 'REGUALAR',
  UNSCHEDULED_REGULAR = 'UNSCHEDULED_REGULAR',
  UNSCHEDULED_EARLY_TERMINATION = 'UNSCHEDULED_EARLY_TERMINATION'
}
