import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { AnonymizationService } from '../../../_services';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {AnonymizationMapModel} from '../../../_models/ImagingProject/anonymization-map-model';

@Component({
  selector: 'app-imaging-project-anonymization',
  templateUrl: './imaging-project-anonymization.component.html',
  styleUrls: ['./imaging-project-anonymization.component.css']
})
export class ImagingProjectAnonymizationComponent implements OnInit {
  entry: any = {};
  anonymizationMap: AnonymizationMapModel[] = [];
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  displayedColumns: string[] = ['row-num', 'name', 'tag', 'action', 'status'];
  dataSource: MatTableDataSource<AnonymizationMapModel>;

  showTable = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private anonymizationService: AnonymizationService,
              private toastyService: ToastyService, private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('DICOM Anonymization map'));
    this.anonymizationService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          response.data.forEach(item => {
            const entry: AnonymizationMapModel = {
              name: item.name,
              tag: '(' + item.tagGroupNumber + ';' + item.tagElementNumber + ')',
              action: item.action,
              status: item.status
            };
            this.anonymizationMap.push(entry);
          });
          this.dataSource = new MatTableDataSource(this.anonymizationMap);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  sortData(sort: Sort) {
    const data = this.anonymizationMap.slice();
    if (!sort.active || sort.direction === '') {
      sort.direction = 'asc';
      sort.active = 'name';
    }

    this.anonymizationMap = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name': return compare(a.name, b.name, isAsc);
        case 'tag': return compare(a.tag, b.tag, isAsc);
        case 'action': return compare(a.action, b.action, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource<AnonymizationMapModel>(this.anonymizationMap);
  }

}

function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
  return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
}
