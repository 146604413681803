import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../reading-form-scoring.component';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {BasicResponse} from 'src/app/core/interfaces/basic-response';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {map} from 'rxjs/operators';
import {ReadingAtyrService} from 'src/app/_services/reading-atyr.service';

@Component({
  selector: 'app-atyr',
  templateUrl: './atyr.component.html',
  styleUrls: ['./atyr.component.css']
})
export class AtyrComponent extends ReadingFormScoringComponent implements OnInit, OnDestroy, ReadingFormScoring {

  readingForm: FormGroup;
  currentReading: any = {
    studyId: 'NA',
    patientId: 'NA',
    visit: {id: 'NA'},
    aquisitionDate: 'NA'
  };
  flexibleConfig: any;
  visitConfigs: any[];

  consensusReadingSubscription: Subscription;

  constructor(
    private store: Store,
    private atyrService: ReadingAtyrService,
    ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('aTyr'));

    this.initReadingForm();
    this.initOnCurrentReading();
  }

  ngOnDestroy(): void {
    if (this.consensusReadingSubscription) {
      this.consensusReadingSubscription.unsubscribe();
    }
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.pipe(
      map(currentReading => this.currentReading = currentReading))
      .subscribe();
  }

  initReadingForm(): void {
    this.readingForm = new FormGroup({
      INCLUSIONARY_FINDINGS: new FormGroup({
        groundGlassAppearance: new FormControl(false),
        honeyCombing: new FormControl(false),
        tractionBronchiolectasis: new FormControl(false),
        reticulation: new FormControl(false),
        other: new FormControl(false),
        otherText: new FormControl(''),
        findingsConsistentWithSScILD: new FormControl(false),
        findingsConsistentWithUipNsip: new FormControl(false,  [
          Validators.required
        ]),
        sscIldPulmonaryInvolvement: new FormControl(undefined,  [
          Validators.required
        ]),
      }),
      EXCLUSIONARY_FINDINGS: new FormGroup({
        moderateToSevereEmphysema: new FormControl(false),
        chronicHypersensitivityPneumonitis: new FormControl(false),
        sarcoidosis: new FormControl(false),
        otherILDsOther: new FormControl(false),
        otherILDsOtherText: new FormControl(''),
        evidenceForPulmonaryVenoOcclusiveDisease: new FormControl(false),
        nonTuberculousMycobacteria: new FormControl(false),
        tuberculosis: new FormControl(false),
        invasiveFungalInfections: new FormControl(false),
        findingsConsistentWithChronicInfectionsOther: new FormControl(false),
        findingsConsistentWithChronicInfectionsOtherText: new FormControl(''),
        tumour: new FormControl(false),
        tumourText: new FormControl(''),
      }),
      OTHER_FINDINGS: new FormGroup({
        adenopathy: new FormControl(false),
        pleuralDisease: new FormControl(false),
        pleuralDiseaseText: new FormControl(''),
        evidenceOfPulmonaryArterialHypertension: new FormControl(false),
        evidenceOfPulmonaryArterialHypertensionText: new FormControl(''),
        esophagealDilatation: new FormControl(false),
        other: new FormControl(false),
        otherText: new FormControl(''),
      }),
      patientEligiblePerHRCT: new FormControl(false),
      comment: new FormControl(''),
    });

    this.readingForm.valueChanges.subscribe((change) => {
      const inclusionaryFindings = this.readingForm.get('INCLUSIONARY_FINDINGS');

      const findingsConsistentWithSScILD = [
        'groundGlassAppearance',
        'honeyCombing',
        'tractionBronchiolectasis',
        'reticulation',
        'other'
      ].some(f => inclusionaryFindings.get(f).value);

      this.readingForm.get('INCLUSIONARY_FINDINGS.findingsConsistentWithSScILD').setValue(findingsConsistentWithSScILD, {emitEvent: false});

      const exclusionaryFindings = this.readingForm.get('EXCLUSIONARY_FINDINGS');

      const sscIldPulmonaryInvolvement = [
        'moderateToSevereEmphysema',
        'chronicHypersensitivityPneumonitis',
        'sarcoidosis',
        'otherILDsOther',
        'evidenceForPulmonaryVenoOcclusiveDisease',
        'nonTuberculousMycobacteria',
        'tuberculosis',
        'invasiveFungalInfections',
        'findingsConsistentWithChronicInfectionsOther',
        'tumour',
      ].some(f => exclusionaryFindings.get(f).value);

      const patientEligiblePerHRCT =  findingsConsistentWithSScILD
        && !sscIldPulmonaryInvolvement
        && inclusionaryFindings.get('sscIldPulmonaryInvolvement').value === false;

      this.readingForm.get('patientEligiblePerHRCT').setValue(patientEligiblePerHRCT, {emitEvent: false});

      this.switchSubmitBtnDisabledSubject.next(this.readingForm.invalid);
    });

    this.readingForm.updateValueAndValidity();
  }

  clearForm() {
    const inclusionaryFindings = this.readingForm.get('INCLUSIONARY_FINDINGS') as FormGroup;

    inclusionaryFindings.patchValue({
      groundGlassAppearance: false,
      honeyCombing: false,
      tractionBronchiolectasis: false,
      reticulation: false,
      other: false,
      otherText: '',
      findingsConsistentWithSScILD: false,
      findingsConsistentWithUipNsip: false,
      sscIldPulmonaryInvolvement: undefined
    });

    const exclusionaryFindings = this.readingForm.get('EXCLUSIONARY_FINDINGS') as FormGroup;
    exclusionaryFindings.patchValue({
      moderateToSevereEmphysema: false,
      chronicHypersensitivityPneumonitis: false,
      sarcoidosis: false,
      otherILDsOther: false,
      otherILDsOtherText: '',
      evidenceForPulmonaryVenoOcclusiveDisease: false,
      nonTuberculousMycobacteria: false,
      tuberculosis: false,
      invasiveFungalInfections: false,
      findingsConsistentWithChronicInfectionsOther: false,
      findingsConsistentWithChronicInfectionsOtherText: '',
      tumour: false,
      tumourText: ''
    });

    const otherFindings = this.readingForm.get('OTHER_FINDINGS') as FormGroup;
    otherFindings.patchValue({
      adenopathy: false,
      pleuralDisease: false,
      pleuralDiseaseText: '',
      evidenceOfPulmonaryArterialHypertension: false,
      evidenceOfPulmonaryArterialHypertensionText: '',
      esophagealDilatation: false,
      other: false,
      otherText: '',
    });

    this.readingForm.patchValue({
      patientEligiblePerHRCT: false,
      comment: '',
    });

    this.readingForm.updateValueAndValidity();
  }

  getEndpointName(): string {
    return 'aTyr';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.atyrService.getAvailableReadings(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.atyrService.startReading(readingId);
  }

  // tslint:disable-next-line:max-line-length
  updateReading(studyId: number, readingId: number, data: { spentSeconds: number; }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    const readingData = { id: this.currentReading.id, spentSeconds: data.spentSeconds };
    const serviceList = [
      this.atyrService.updateReadingOld(studyId, this.currentReading.id, readingData)
    ];
    return forkJoin(serviceList) as any;
  }

  boolToYesNo(value: boolean, yes?: string, no?: string) {
    if (value === null) {
      return value;
    }

    return value ? (yes ?? 'YES') : (no ?? 'NO');
  }

  convertFieldsToQuestions(questionType: string | undefined, group: AbstractControl, fields) {
    return fields.map(f => ({
      ...{
          questionType,
          questionGroup: f.group,
          answer: this.boolToYesNo(group.get(f.field).value, f.yes, f.no ),
        },
        ...(f.label ? {question: f.label} : {}),
        ...(group.get(f.field).value && f.commentField ? {comment: group.get(f.commentField).value} : {}),
      }
    ));
  }
  get inclusionaryFindingsQuestions() {
    const inclusionaryFindings = this.readingForm.get('INCLUSIONARY_FINDINGS');
    const fields = [
      { field: 'groundGlassAppearance', label: 'Ground Glass Appearance', group: 'SSc-ILD HRCT Findings' },
      { field: 'honeyCombing', label: 'Honey Combing', group: 'SSc-ILD HRCT Findings' },
      { field: 'tractionBronchiolectasis', label: 'Traction Bronchiolectasis', group: 'SSc-ILD HRCT Findings' },
      { field: 'reticulation', label: 'Reticulation', group: 'SSc-ILD HRCT Findings' },
      { field: 'other', label: 'Other', group: 'SSc-ILD HRCT Findings', commentField: 'otherText' },
      { field: 'findingsConsistentWithSScILD', group: 'Findings consistent with SSc-ILD' },
      { field: 'findingsConsistentWithUipNsip', group: 'Findings consistent with UIP/NSIP', yes: 'NSIP', no: 'UIP' },
      { field: 'sscIldPulmonaryInvolvement', group: 'SSc–ILD pulmonary involvement', yes: 'LTE_10', no: 'GT_10'},
    ];

    return this.convertFieldsToQuestions('INCLUSIONARY_FINDINGS', inclusionaryFindings, fields);
  }

  get exclusionaryFindingsQuestions() {
    const exclusionaryFindings = this.readingForm.get('EXCLUSIONARY_FINDINGS');
    const fields = [
      { field: 'moderateToSevereEmphysema', group: 'Moderate to severe Emphysema' },
      { field: 'chronicHypersensitivityPneumonitis', label: 'Chronic Hypersensitivity Pneumonitis', group: 'Other ILDs' },
      { field: 'sarcoidosis', label: 'Sarcoidosis', group: 'Other ILDs' },
      { field: 'otherILDsOther', label: 'Other', group: 'Other ILDs', commentField: 'otherILDsOtherText' },
      { field: 'evidenceForPulmonaryVenoOcclusiveDisease', group: 'Evidence for pulmonary veno-occlusive disease' },
      { field: 'nonTuberculousMycobacteria', label: 'Non-tuberculous mycobacteria', group: 'Findings consistent with chronic infections' },
      { field: 'tuberculosis', label: 'Tuberculosis', group: 'Findings consistent with chronic infections' },
      { field: 'invasiveFungalInfections', label: 'Invasive fungal infections', group: 'Findings consistent with chronic infections' },
      {
        field: 'findingsConsistentWithChronicInfectionsOther',
        label: 'Other',
        group: 'Findings consistent with chronic infections',
        commentField: 'findingsConsistentWithChronicInfectionsOtherText'
      },
      { field: 'tumour', group: 'Tumour', commentField: 'tumourText' },
    ];

    return this.convertFieldsToQuestions('EXCLUSIONARY_FINDINGS', exclusionaryFindings, fields);
  }

  get otherFindingsQuestions() {
    const otherFindings = this.readingForm.get('OTHER_FINDINGS');

    const fields = [
      { field: 'adenopathy', group: 'Adenopathy' },
      { field: 'pleuralDisease', group: 'Pleural disease', commentField: 'pleuralDiseaseText'},
      {
        field: 'evidenceOfPulmonaryArterialHypertension',
        group: 'Evidence of Pulmonary Arterial Hypertension',
        commentField: 'evidenceOfPulmonaryArterialHypertensionText'
      },
      { field: 'esophagealDilatation', group: 'Esophageal dilatation' },
      { field: 'other', group: 'Other', commentField: 'otherText' },
    ];

    return this.convertFieldsToQuestions('OTHER_FINDINGS', otherFindings, fields);
  }

  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    const questions = [
      ... this.inclusionaryFindingsQuestions,
      ...this.exclusionaryFindingsQuestions,
      ...this.otherFindingsQuestions,
    ];

    const scoringData = {
      scoring: {
        questions: questions.map((q, sequenceNumber) => ({...q, sequenceNumber}))
      },
      readerId: this.currentReading.readerId,
      timepoint: this.currentReading.visit.timepoint,
      modality: this.currentReading.visit?.series?.[0].modalityName,
      studyId: this.currentReading.studyId,
      patientId: this.currentReading.patientId,
      visitConfigId: this.currentReading.visitConfigId,
      comment: this.readingForm.get('comment').value,
      readingId: this.currentReading.id,
      reportPath: 'StudyProtocol_Eligibility_Report_PatientCode',
      isEligible: this.readingForm.get('patientEligiblePerHRCT').value,
      timeSpent: data.spentSeconds,
    };

    return this.atyrService.completeReading(this.currentReading.id, scoringData);
  }
}
