import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ModalityService, SequenceLabelService} from '../../../_services';
import {FormControl, Validators} from '@angular/forms';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SequenceLabelModel} from '../../../_models/ImagingProject/sequence-label-model';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';

export interface ImagingData {
  modalityId: number;
  modalityName: string;
  squenceLabelName: string;
  squenceLabelDescription: string;
}

@Component({
  selector: 'app-imaging-project-imaging',
  templateUrl: './imaging-project-imaging.component.html',
  styleUrls: ['./imaging-project-imaging.component.css']
})
export class ImagingProjectImagingComponent implements OnInit {
  requiredFormControl = new FormControl('', [
    Validators.required
  ]);
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  displayedColumns: string[] = ['row-num', 'modalityName', 'squenceLabelName', 'squenceLabelDescription', 'operations'];
  dataSource: MatTableDataSource<ImagingData>;
  imagingList: ImagingData[] = [];
  modalities: any[] = [];
  sequenceLabels: any[] = [];
  modality = {
    id: null,
    name: '',
    sequenceLabels: []
  };
  showTable = true;
  showImaging = false;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  seqLabelCtrl = new FormControl();
  filteredSequenceLabels: Observable<any[]>;

  selectedSequenceLabels: any[];
  displayedSeqLabelsColumns = ['id', 'name', 'actionDelete'];
  dataSourceLabels: MatTableDataSource<SequenceLabelModel>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('seqLabelInput') seqLabelInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private modalityService: ModalityService,
    private sequenceLabelService: SequenceLabelService,
    private toastyService: ToastyService, private store: Store) {
    this.filteredSequenceLabels = this.seqLabelCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) => value ? this._filter(value) : this.sequenceLabels.slice()));
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Imaging'));
    this.selectedSequenceLabels = [];
    this.modality.sequenceLabels = [];
    this.modalityService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.modalities = response.data;
          this.dataSourceLabels = new MatTableDataSource(this.modality.sequenceLabels);

          for (const modality of this.modalities) {
            for (const sequenceLabel of modality.sequenceLabels) {
              const x = {
                modalityId: modality.id,
                modalityName: modality.name,
                squenceLabelName: sequenceLabel.name,
                squenceLabelDescription: sequenceLabel.description
              };
              this.imagingList.push(x);
            }
          }
          this.dataSource = new MatTableDataSource(this.imagingList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );

    this.sequenceLabelService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.sequenceLabels = response.data;
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );

  }

  showImagings(element) {
    this.modality = {
      id: null,
      name: '',
      sequenceLabels: []
    };
    this.selectedSequenceLabels = [];

    this.showTable = false;
    this.showImaging = true;
    this.requiredFormControl.clearValidators();
    if (element) {
      this.modalityService.getById(element.modalityId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.modality = response.data;
            this.store.dispatch(new SetPageHeaderTitle('Edit Imaging'));
            this.dataSourceLabels = new MatTableDataSource(this.modality.sequenceLabels);
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.store.dispatch(new SetPageHeaderTitle('Create Imaging'));
    }
  }

  deleteImaging(element) {
    this.modalityService.delete(element.modalityId).subscribe((response: any) => {
        if (response.responseCode === 200) {
          this.modalityService.getAll().subscribe((response: any) => {
              if (response.responseCode === 200) {
                this.imagingList = [];
                this.modalities = response.data;
                for (const modality of this.modalities) {
                  for (const sequenceLabel of modality.sequenceLabels) {
                    const x = {
                      modalityId: modality.id,
                      modalityName: modality.name,
                      squenceLabelName: sequenceLabel.name,
                      squenceLabelDescription: sequenceLabel.description
                    };
                    this.imagingList.push(x);
                  }
                }
                this.dataSource.data = this.imagingList;
                this.toastOptions.title = '';
                this.toastOptions.msg = 'Imaging successfully deleted.';
                this.toastyService.success(this.toastOptions);
              } else {
                this.toastOptions.title = 'ERROR ' + response.responseCode;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  hideImaging() {
    this.showTable = true;
    this.showImaging = false;
  }

  saveImaging() {
    if (this.modality.id) {
      this.modalityService.update(this.modality).subscribe((response: any) => {
          if (response.responseCode === 200) {
            this.modalityService.getAll().subscribe((response: any) => {
                if (response.responseCode === 200) {
                  this.modalities = response.data;
                  this.imagingList = [];
                  for (const modality of this.modalities) {
                    for (const sequenceLabel of modality.sequenceLabels) {
                      const x = {
                        modalityId: modality.id,
                        modalityName: modality.name,
                        squenceLabelName: sequenceLabel.name,
                        squenceLabelDescription: sequenceLabel.description
                      };
                      this.imagingList.push(x);
                    }
                  }
                  this.dataSource.data = this.imagingList;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'Imaging successfully updated';
                  this.toastyService.success(this.toastOptions);
                  this.hideImaging();
                } else {
                  this.toastOptions.title = 'ERROR ' + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {

      this.modalityService.create(this.modality).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.modalityService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.modalities = response.data;
                  this.imagingList = [];
                  for (const modality of this.modalities) {
                    for (const sequenceLabel of modality.sequenceLabels) {
                      const x = {
                        modalityId: modality.id,
                        modalityName: modality.name,
                        squenceLabelName: sequenceLabel.name,
                        squenceLabelDescription: sequenceLabel.description
                      };
                      this.imagingList.push(x);
                    }
                  }
                  this.dataSource.data = this.imagingList;
                  this.toastOptions.title = '';
                  this.toastOptions.msg = 'Imaging successfully created';
                  this.toastyService.success(this.toastOptions);
                  this.hideImaging();
                } else {
                  this.toastOptions.title = 'ERROR ' + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  add(event: MatChipInputEvent): void {
    if (!this.matAutocomplete.isOpen) {
      const input = event.input;
      const value = event.value;
      if ((value || '').trim()) {
        this.modality.sequenceLabels.push(value.trim());
      }
      if (input) {
        input.value = '';
      }
      this.seqLabelCtrl.setValue(null);
    }
  }

  remove(sequenceLabel: string): void {
    const index = this.modality.sequenceLabels.indexOf(sequenceLabel);

    if (index >= 0) {
      this.modality.sequenceLabels.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.modality.sequenceLabels.push(event.option.value);
    this.seqLabelInput.nativeElement.value = '';
    this.seqLabelCtrl.setValue(null);
  }

  private _filter(value: any): any[] {
    if (value instanceof Object || value === null) {
      return;
    }
    const filterValue = value.toLowerCase();
    return this.sequenceLabels.filter(sequenceLabel => sequenceLabel.name.toLowerCase().indexOf(filterValue) === 0);
  }

  onSequenceLabelsSelectionChange(event: { value: any[]; }) {
    if ( this.modality.sequenceLabels == null ) {
      this.modality.sequenceLabels = [];
    }
    this.selectedSequenceLabels = event.value;
  }

  addSelectedLabels() {
    /*
      we have no sequence labels selected
    */
    if (this.modality.sequenceLabels.length === 0) {
      this.modality.sequenceLabels = this.selectedSequenceLabels;
    } else {
      /*
        we only add sequence labels which have not been selected before
      */
      this.selectedSequenceLabels.forEach(ns => {
        if (this.modality.sequenceLabels.indexOf(ns) === -1) {
          this.modality.sequenceLabels.push(ns);
        }
      });
    }
    this.dataSourceLabels = new MatTableDataSource(this.modality.sequenceLabels);
    /*
      clear selection
    */
    this.selectedSequenceLabels = [];
  }

  deleteSequenceLabel(element) {

    this.modality.sequenceLabels.forEach(sl => {
      if (sl.id === element.id) {
        const i = this.modality.sequenceLabels.indexOf(sl);
        this.modality.sequenceLabels.splice(i, 1);
      }
    });

    this.dataSourceLabels = new MatTableDataSource(this.modality.sequenceLabels);
  }
}
