import {Component, OnInit, ViewChild} from '@angular/core';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatDialog} from '@angular/material/dialog';
import {
  PrimaryBodyLocationDialogComponent,
  PrimaryBodyLocationDialogMode,
  PrimaryBodyLocationDialogModel
} from '../primary-body-location-dialog/primary-body-location-dialog.component';
import {PrimaryBodyLocationWithSublocationListDialogComponent} from '../primary-body-location-with-sublocation-list-dialog/primary-body-location-with-sublocation-list-dialog.component';
import {PrimaryBodyLocationStatusDialogComponent} from '../primary-body-location-status-dialog/primary-body-location-status-dialog.component';
import {BodyLocationAreaService, PrimaryBodyLocationService} from '../../../../_services';
import {BodyLocationAreaModel} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/body-location-area-model';
import {FormControl} from '@angular/forms';
import {
  PrimaryBodyLocationModel,
  PrimaryBodyLocationStatus
} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-model';
import {ToastService} from '../../../../_services/internal/toast.service';

@Component({
  selector: 'app-primary-body-location-list',
  templateUrl: './primary-body-location-list.component.html',
  styleUrls: ['./primary-body-location-list.component.css']
})
export class PrimaryBodyLocationListComponent implements OnInit {

  displayedColumns: string[] = ['primaryLocationName', 'subLocation', 'nodal', 'projectAssigned', 'status'];
  dataSource: MatTableDataSource<PrimaryBodyLocationModel>;
  primaryLocations: PrimaryBodyLocationModel[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  showModalSpinner = false;
  activeFilter: string;

  formControlArea = new FormControl();
  bodyLocationAreas: BodyLocationAreaModel[] = [];

  activeLocations: number;
  disableLocations: number;

  constructor(
    private bodyLocationAreaService: BodyLocationAreaService,
    private primaryBodyLocationService: PrimaryBodyLocationService,
    public dialog: MatDialog,
    private store: Store,
    private toastService: ToastService
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Body location'));
    this.showModalSpinner = true;
    this.bodyLocationAreaService.findAll().subscribe(areaResponce => {
      this.bodyLocationAreas = areaResponce.data;
    });

    this.dataSource = new MatTableDataSource<PrimaryBodyLocationModel>([]);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

  }

  selectedAreaChanged() {
    this.getPrimaryLocations(this.formControlArea.value);
  }

  getPrimaryLocations(area: BodyLocationAreaModel) {
    this.primaryBodyLocationService.getAllByAreaId(area.id).subscribe(locations => {
      if (locations.data != null) {
        this.primaryLocations = locations.data;
        this.dataSource = new MatTableDataSource<PrimaryBodyLocationModel>(locations.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (this.activeFilter) {
          this.applyFilter(this.activeFilter);
        }

        const active = locations.data.filter(item => item.status.toUpperCase()
          === PrimaryBodyLocationStatus.ACTIVE.toUpperCase());
        const diabled = locations.data.filter(item => item.status.toUpperCase()
          === PrimaryBodyLocationStatus.DISABLED.toUpperCase());
        this.activeLocations = active.length;
        this.disableLocations = diabled.length;
      }
    });
  }

  sortData(sort: Sort) {
    const data = this.primaryLocations.slice();
    if (!sort.active || sort.direction === '') {
      sort.direction = 'asc';
      sort.active = 'name';
    }

    this.primaryLocations = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'primaryLocationName': return compare(a.name, b.name, isAsc);
        case 'nodal': return compare(a.nodal, b.nodal, isAsc);
        case 'status': return compare(a.status, b.status, isAsc);
        default: return 0;
      }
    });
    this.dataSource = new MatTableDataSource<PrimaryBodyLocationModel>(this.primaryLocations);
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.activeFilter = filterValue;
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    } else {
      this.activeFilter = '';
    }
  }

  showErrorMessage(response: any): void {
    this.toastService.error('ERROR ' + response['responseCode'], response['responseMessage']);
  }

  openCreatePrimaryBodyLocationDialog(): void {
    if (this.formControlArea.value != null) {
      const data: PrimaryBodyLocationDialogModel = {
        mode: PrimaryBodyLocationDialogMode.NEW,
        bodyLocation: <PrimaryBodyLocationModel>{
          bodyLocationArea: <BodyLocationAreaModel>{
            id: this.formControlArea.value.id,
          },
          name: '',
          status: PrimaryBodyLocationStatus.ACTIVE
        },
        bodyLocationsList: this.primaryLocations
      };

      const dialogRef = this.dialog.open(PrimaryBodyLocationDialogComponent, {
        width: '600px',
        data: data
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        this.getPrimaryLocations(this.formControlArea.value);
      });
    }
  }

  editPrimaryLocationName(element) {
    if (this.formControlArea.value != null) {
      const data: PrimaryBodyLocationDialogModel = {
        mode: PrimaryBodyLocationDialogMode.EDIT,
        bodyLocation: element,
        bodyLocationsList: this.primaryLocations
      };

      const dialogRef = this.dialog.open(PrimaryBodyLocationDialogComponent, {
        width: '600px',
        data: data
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        this.getPrimaryLocations(this.formControlArea.value);
      });
    }
  }

  editPrimaryLocationSublocations(element) {
    const dialogRef = this.dialog.open(PrimaryBodyLocationWithSublocationListDialogComponent, {
      width: '600px',
      data: element
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.getPrimaryLocations(this.formControlArea.value);
    });
  }


  activateDisableLocation(element) {
    const data: PrimaryBodyLocationDialogModel = {
      mode: PrimaryBodyLocationDialogMode.EDIT,
      bodyLocation: element,
      bodyLocationsList: this.primaryLocations
    };
    console.log('status primary:', data);

    const dialogRef = this.dialog.open(PrimaryBodyLocationStatusDialogComponent, {
      width: '600px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      this.getPrimaryLocations(this.formControlArea.value);
    });
  }
}

function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
  return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
}
