export enum ReaderType {
  BASIC_READER = 'basic_reader',
  BASIC_READER_NAME = 'Basic Reader',

  LEVEL1_READER = 'level1_reader',
  LEVEL1_READER_NAME = 'Level1 Reader',

  CONSENSUS_READER = 'consensus_reader',
  CONSENSUS_READER_NAME = 'Consensus Reader',

  ADJUDICATION_READER = 'adjudication_reader',
  ADJUDICATION_READER_NAME = 'Adjudication Reader',

  MODERATION_READER = 'moderation_reader',
  MODERATION_READER_NAME = 'Moderation Reader'
}
