import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, shareReplay } from 'rxjs/operators';
import * as convert from 'xml-js';
import * as viewerModels from '../_models/viewer/hangingProtocol';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HangingProtocolService {
  protocols: { [key: string]: viewerModels.HangingProtocolListModel } = {};

  constructor(private http: HttpClient) {
  }

  public load(type: string = ''): Observable<viewerModels.HangingProtocolListModel> {
    if (type && type.length) {
      const requestUrl = `assets/xml/${type}_HangingProtocols.xml`;
      if (!this.protocols[type]) {
        return this.http.get(requestUrl, { responseType: 'text' })
          .pipe(
            map(xml => {
                const json = this.convert(xml);
                this.protocols[type] = new viewerModels.HangingProtocolListModel(json);
                return this.protocols[type];
              }
            ),
            shareReplay({ bufferSize: 1, refCount: true })
          );
      } else {
        return of(this.protocols[type]);
      }
    } else {
      return of(null);
    }
  }

  private convert(xml: string): any {
    try {
      return JSON.parse(convert.xml2json(xml, { compact: true, ignoreComment: true }));
    } catch (e) {
      return null;
    }
  }
}
