import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ViewerConfigModel } from '../_models/viewer/ViewerConfigModel';

@Injectable({
  providedIn: 'root'
})
export class ViewerConfigService {
  configs: { [key: string]: ViewerConfigModel } = {};

  constructor(private http: HttpClient) {
  }

  public load(type: string = ''): Observable<ViewerConfigModel | null> {
    let result;
    if (type && type.length) {
      const requestUrl = `/assets/json/viewerConfig_${type}.json`;
      if (!this.configs[type]) {
        result = this.http.get(requestUrl, { responseType: 'json' })
          .pipe(
            catchError(() => of(null)),
            map(config => {
                if (config) {
                  this.configs[type] = new ViewerConfigModel(config);
                  return this.configs[type];
                }
                return null;
              }
            ),
            shareReplay({ bufferSize: 1, refCount: true })
          );
      } else {
        result = of(this.configs[type]);
      }
    } else {
      result = of(null);
    }

    return result;
  }

}
