import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationBodyLocationDialogComponent} from '../confirmation-body-location-dialog/confirmation-body-location-dialog.component';
import {PrimaryBodyLocationService} from '../../../../_services';
import {
  PrimaryBodyLocationModel,
  PrimaryBodyLocationStatus
} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-model';
import {AbstractControl, FormControl, ValidationErrors, Validators} from '@angular/forms';

@Component({
  selector: 'app-primary-body-location-dialog',
  templateUrl: './primary-body-location-dialog.component.html',
  styleUrls: ['./primary-body-location-dialog.component.css']
})
export class PrimaryBodyLocationDialogComponent implements OnInit {


  nameFormControl =  new FormControl('', [Validators.required,
    Validators.maxLength(200),
    Validators.minLength(1),
    this.validateUniqueName]);

  nodalFormControl = new FormControl(false);

  constructor(
    private primaryBodyLocationService: PrimaryBodyLocationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PrimaryBodyLocationDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: PrimaryBodyLocationDialogModel
  ) { }

  ngOnInit(): void {
    this.nameFormControl.setValue(this.data.bodyLocation.name);
    this.nodalFormControl.setValue(this.data.bodyLocation.nodal);
    if (this.data.mode === PrimaryBodyLocationDialogMode.NEW) {
      this.data.bodyLocation.status = PrimaryBodyLocationStatus.ACTIVE;
    }
  }

  get validateUniqueName() {
    return (control: AbstractControl): ValidationErrors | null => {
      let names = this.data.bodyLocationsList.filter(p => p.name === control.value
        && 'ACTIVE' === p.status.toUpperCase());
      if (names.length === 1) {
        if (names[0].id === this.data.bodyLocation.id) {
          names = [];
        }
      }
      return names.length > 0 ? {duplicateValueError: 'Duplicate. value already exists'} : null;
    };
  }

  onNameChanged(event): void {
    this.nameFormControl.updateValueAndValidity();
  }

  save(): void {
    if (this.data.mode === PrimaryBodyLocationDialogMode.NEW) {
      const location = {
        name: this.nameFormControl.value,
        nodal: this.nodalFormControl.value
      };
      if (!location.nodal) {
        location.nodal = false;
      }
      this.primaryBodyLocationService.create(this.data.bodyLocation.bodyLocationArea.id,
        <PrimaryBodyLocationModel> location).subscribe(presp => {
        this.dialogRef.close(presp.data);
      });
    } else {
      const dialogRef = this.dialog.open(ConfirmationBodyLocationDialogComponent, {
        width: '600px',
        data: {}
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result === 'ok') {
          const location = {
            id: this.data.bodyLocation.id,
            name: this.nameFormControl.value,
            nodal: this.nodalFormControl.value
          };
          this.primaryBodyLocationService.updateName(<PrimaryBodyLocationModel> location).subscribe(presp => {
            this.dialogRef.close(presp.data);
          });
        } else {
          this.dialogRef.close();
        }
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}

export enum PrimaryBodyLocationDialogMode {
  NEW = 'new',
  EDIT = 'edit'
}

export interface PrimaryBodyLocationDialogModel {
  mode: PrimaryBodyLocationDialogMode;
  bodyLocation: PrimaryBodyLocationModel;
  bodyLocationsList: PrimaryBodyLocationModel[];
}
