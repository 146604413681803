import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef, ComponentFactoryResolver, ElementRef, ChangeDetectorRef } from '@angular/core';
import {  Router, ActivatedRoute, ParamMap  } from '@angular/router';

import {MatTableDataSource} from '@angular/material/table';


import { QueriesService } from '../../../../_services/queries.service';
import { QueriesChatlogService } from '../../../../_services/queries-chatlog.service';
import { SiteConfigService } from '../../../../_services/site-config.service';
import { PatientService } from '../../../../_services/patient.service';
import { ImagingProjectService } from '../../../../_services/imaging-project.service';
import { UserService } from '../../../../_services/user.service';
import { QualityControlService } from '../../../../_services/quality-control.service';
import { ModalityService } from '../../../../_services/modality.service';
import { StudySequenceLabelService } from '../../../../_services/study-sequence-label.service';
import { ViewerCoreLtComponent } from '../../../Viewer/viewer-core-lt/viewer-core-lt.component';

import * as _moment from 'moment';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';

import * as lodash from 'lodash';
import { ViewerAuthenticationService } from 'src/app/_services/interfaces/viewer/viewer-authentication-service';
import { forkJoin, Observable } from 'rxjs';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
import { StudyUserService } from 'src/app/_services';

const moment = _moment;


@Component({
  selector: 'app-edcf-detail',
  templateUrl: './edcf-detail.component.html',
  styleUrls: ['./edcf-detail.component.css']
})
export class EdcfDetailComponent implements OnInit {
  @ViewChild('viewer', {read: ViewContainerRef}) viewer: ViewContainerRef;
  @ViewChild('viewerLT', {read: ViewContainerRef}) viewerLT: ViewContainerRef;
  @ViewChild('tablecontiner', { read: ElementRef })  tablecontiner: ElementRef;
  @ViewChild('viewercontiner', { read: ElementRef }) viewercontiner: ElementRef;
  newViewer: any = null;
  newLTViewer: any = null;
  isActiveLTViewer = false;

  project: any;
  externalAccessKey: string;

  fromKey = false;

  query: any;

  edcf = {
    id: null,
    studyId: null,
    studyName: null,
    siteName: null,
    siteCode: null,
    patientCode: null,
    visitConfigId: null,
    visitName: null,

    modalities:[],
    resolved: null,

    freeComment: ''
  }

  message_typed = '';

  userId:number;

  chatLogs = [];

  displayedColumnsSeries: string[] = ['id', 'image', 'label', 'comment', 'repeat'];

  queries_resolve_eDCF = false;

  previousUrl = '';

  viewerData = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;

  debOpenViewer = lodash.debounce(seriesId => {
    if (this.viewerDataIsReady) {
      this.openViewer(seriesId);
    }
  }, 500);

   constructor(
     private queriesService: QueriesService,
     private queriesChatlogService: QueriesChatlogService,
     private route: ActivatedRoute,
     private router: Router,
     private siteConfigService: SiteConfigService,
     private patientService: PatientService,
     private imagingProjectService: ImagingProjectService,
     private userService: UserService,
     private qualityControlService: QualityControlService,
     private modalityService: ModalityService,
     private studySequenceLabelService: StudySequenceLabelService,
     private cfr: ComponentFactoryResolver,
     private store: Store,
     private authenticationService: ViewerAuthenticationService,
     private studyUserService: StudyUserService,
     private utils: Utils,
     private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.preOpenLTViewer();
    }, 100);

    this.store.dispatch(new SetPageHeaderTitle('eDCF details'));
    this.project = JSON.parse(localStorage.getItem('project'));
    this.userId = JSON.parse(localStorage.getItem('userId'));
    let activities = JSON.parse(localStorage.getItem('activities'));

    if(activities.includes('queries.resolve.eDCF')){
      this.queries_resolve_eDCF = true;
    };

    this.route.paramMap.subscribe(params => {
      this.externalAccessKey = params.get('key');
    });

    if(this.externalAccessKey != null) {
      this.queriesService.getQueriesByGUID(this.externalAccessKey).subscribe(queryResp => {
        this.fromKey = true;
        this.initData(queryResp['data']);
      });
    }
    else{
      let edcfIdstr = localStorage.getItem('query.edcf');
      if(edcfIdstr != null){
        let edcfId = JSON.parse(edcfIdstr);
        let project = JSON.parse(localStorage.getItem('project'));
        this.queriesService.getQueries(edcfId['id'], project['id']).subscribe(queryResp => {
          this.fromKey = false;
          this.initData(queryResp['data']);
        });
      }
    }
  }

  initData(queryEdcf){
    this.query = queryEdcf;
    this.edcf.id = queryEdcf.id;
    this.edcf.studyId = queryEdcf.studyId;
    this.edcf.visitConfigId = queryEdcf.visitConfigId;
    this.edcf.resolved = queryEdcf.resolved;
    this.edcf.freeComment = queryEdcf.freeComment;
    this.externalAccessKey = queryEdcf.guid;

    let failedSeriesIds = [];
    let missingSequenceLabelsIds = [];
    const viewerDataResults: Array<Observable<any>> = [];
    
    queryEdcf.modalities.forEach(modality => {
      modality.failedSeries.forEach(series => {
        failedSeriesIds.push(series.seriesId);
        // Get general viewer data
        const info = {"selectedPage": "Reading"};
        viewerDataResults.push(this.utils.initViewerData(series, this, info));
      });
    });
    if (viewerDataResults.length) {
      this.viewerDataIsLoading = true;
      forkJoin(viewerDataResults).subscribe(() => {
        this.viewerDataIsReady = true;
        this.viewerDataIsLoading = false;
        this.cdr.detectChanges();
      });
    }
    // console.log("viewerData:", this.viewerData);

    failedSeriesIds = Array.from(new Set(failedSeriesIds));

    let studySeriesData;
    this.studySequenceLabelService.getStudySeriesBySeriesIds(failedSeriesIds).subscribe( studySeriesResp => {
      studySeriesData = studySeriesResp['data'];
      queryEdcf.modalities.forEach(modality => {
        modality.failedSeries.forEach(series => {
          series.studySeries = studySeriesData.filter(s => s.id == series.seriesId)[0];
        });
        modality.dataSourceFailedSeries = new MatTableDataSource(modality.failedSeries);
      });
    });

    this.studySequenceLabelService.getStudyModalitiesByStudyId(this.edcf.studyId).subscribe(modalityResponse => {
      let modalityData = modalityResponse['data'];
      queryEdcf.modalities.forEach(modality => {
        let mod = modalityData.filter(m => m.id == modality.modalityId)[0];
        if(mod != null){
          modality.modalityName = mod.name;
          modality.missingSequenceLabels.forEach(sequenceLabel => {
            sequenceLabel.sequenceLabel = mod.sequenceLabels.filter(sl => sl.id == sequenceLabel.sequenceLabelId)[0];
          });
        };
      });
    });

    this.siteConfigService.getById(queryEdcf.siteConfigId).subscribe( siteConfigResp => {
      let siteConfigData = siteConfigResp['data'];
      this.edcf.siteName = siteConfigData.site.name;
      this.edcf.siteCode = siteConfigData.siteCode;
    });

    this.patientService.getById(queryEdcf.patientId).subscribe( patientResp => {
      let patientData = patientResp['data'];
      this.edcf.patientCode = patientData.patientCode;
    });

    this.imagingProjectService.getStudy(this.edcf.studyId).subscribe( studyIdResp => {
      let studyIdData = studyIdResp['data'];
      this.edcf.studyName = studyIdData.name;
    });

    this.imagingProjectService.getVisitConfig(this.edcf.visitConfigId).subscribe( visitConfigResp => {
      let visitConfigData = visitConfigResp['data'];
      this.edcf.visitName = visitConfigData.visit.name;
    });

    this.edcf.modalities = queryEdcf.modalities;
    this.initChatlogMessages(queryEdcf);
  }

  initChatlogMessages(queryEdcf){
    this.chatLogs = [];
    let usersIds = [];
    queryEdcf.chatLogs.forEach(message => {
      usersIds.push(message.userId);
    });
    usersIds = Array.from(new Set(usersIds))

    this.userService.getByListIds(usersIds).subscribe(usersInfoRes => {
      let userslist = usersInfoRes['data'];
      queryEdcf.chatLogs.forEach(message => {
        let userInfo = userslist.filter(user => user.id === message.userId)[0];
        let m = {
          id: message.id,
          created:message.created,
          timestamp: moment(message.created).format('DD/MM/YYYY HH:mm'),
          userId: message.userId,
          userFirstName: userInfo.firstName,
          userLastName: userInfo.lastName,
          comment: message.comment,
        };
        this.chatLogs.push(m);
      });
      this.chatLogs.sort((a, b) => (a.created > b.created) ? 1 : -1);
    });
  }

  clickCancel(){
    let isFromQcTaskList = JSON.parse(localStorage.getItem('query.edcf.fromQcTaskList'))
    if(this.fromKey == true) {
      this.router.navigate(['/imagingproject/projects']);
    }
    else{
      //return to qc task list if user press edcf from qc task list
      if(isFromQcTaskList == true) {
        this.router.navigate(['/imagingproject/qc']);
      }
      else {
        this.router.navigate(['/queries/edcfs']);
      }
    }
  }

  clickResolve(){
    this.queriesService.updateStatusToResolved(this.edcf.id, this.userId, this.edcf.studyId).subscribe( resolveResp => {
        let resolvData = resolveResp['data'];
        this.edcf.resolved = resolvData.resolved;
      });
   }

  clickSend(){
    this.queriesService.updateStatusToUpdated(this.edcf.id, this.edcf.studyId).subscribe( updateStatusResp => {
    });

    this.queriesChatlogService.createMessage(this.edcf.studyId, this.query.id, this.userId, this.message_typed).subscribe( createMessageResp =>{
      this.message_typed = '';
      this.queriesService.getQueriesByGUID(this.externalAccessKey).subscribe(queryResp => {
        this.query = queryResp['data'];
        this.initChatlogMessages(queryResp['data']);
      });
    });
  }

  /* --------------------------- viewer methods --------------------------- */

  openNewLTViewer(seriesInstanceUIDs) {
    const compFactory = this.cfr.resolveComponentFactory(
      ViewerCoreLtComponent
    );

    this.newLTViewer = this.viewerLT.createComponent(compFactory);
    this.newLTViewer.instance.seriesIds = seriesInstanceUIDs;
    this.newLTViewer.instance.shouldShowDICOM = false;
    this.newLTViewer.instance.onClose.subscribe(({ serID, shouldCloseViewer }) => {
      this.closeLTViewer(serID, shouldCloseViewer);
    })
  }

  preOpenLTViewer() {
    this.openNewLTViewer(null);
  }

  openLTViewer(seriesInstanceUIDs: string[] = []) {
    if (this.newLTViewer) {
      this.newLTViewer.instance.openNewSer(seriesInstanceUIDs, this.viewerData);
      return;
    }

    this.openNewLTViewer(seriesInstanceUIDs);
  }

  closeLTViewer(serID, shouldCloseViewer) {
    this.removeHighl(serID);

    if (shouldCloseViewer) {
      this.viewercontiner.nativeElement.style.zIndex = '-10';
      this.viewercontiner.nativeElement.style.display = 'none';
    }
  }

  removeHighl(serID) {
    const $serThumbn = document.querySelector('[data-seriesId="' + serID + '"] div');

    $serThumbn?.classList.remove('viewer-highlighted');
  }

  highlightOnClick(serID) {
    const $serThumbn = document.querySelector('[data-seriesId="' + serID + '"] div');

    $serThumbn?.classList.add('viewer-highlighted');
  }

  openViewer(serID) {
    setTimeout(function() {
      window.dispatchEvent(new Event('resize'));
    }, 500);

    this.highlightOnClick(serID)
    this.openLTViewer([ serID ]);

    this.viewercontiner.nativeElement.style.display = '';
    this.viewercontiner.nativeElement.style.zIndex = '2';
  }

  dragstart(ev, seriesId, seriesInstanceUID?){
    // console.log("dragstart...");
    // console.log("seriesId:", seriesId);
    // console.log("seriesInstanceUID:", seriesInstanceUID);
    ev.dataTransfer.setData("seriesId", seriesId);
    ev.dataTransfer.setData("seriesInstanceUID", seriesInstanceUID);
  }
}
