import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VisitStatus } from 'src/app/_models/ImagingProject/IF/incidental-findings-config-model';

export class ConfigurationObject {
  visits: VisitModel[]
}

export interface VisitModel {
  id?: number;
  created?: number;
  name: string;
  durationTimeUnit?: string;
  durationTimeValue?: number;
  baseline?: boolean;
  repeatAllowed?: boolean;
  modalities: any[];
  questions?: any[];
  status: string;
}


@Component({
  selector: 'app-visits-status-dialog',
  templateUrl: './visits-status-dialog.component.html',
  styleUrls: ['./visits-status-dialog.component.css']
})
export class VisitsStatusDialogComponent implements OnInit {
  

  constructor(public dialogRef: MatDialogRef<VisitsStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any[] ) { }

  ngOnInit(): void {
    
  }

  onSubmit() {
    this.dialogRef.close(true)
  }

  closeDialog() {
    this.dialogRef.close();
  }
  notAllDone(){
    return this.data.filter(visit => visit.status !== VisitStatus.DONE).length > 0
  }
}

