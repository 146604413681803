import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
export interface VOI {
  VOIName: string;
  value: number;
}

const ELEMENT_DATA: VOI[] = [
  { VOIName: 'VOI1', value : 1.0079},
  { VOIName: 'VOI2', value : 4.0026},
  { VOIName: 'VOI3', value : 6.941},
  { VOIName: 'VOI4', value : 9.0122,},
  { VOIName: 'VOI5', value : 10.811},
  { VOIName: 'VOI6', value : 12.0107},
  { VOIName: 'VOI7', value : 14.0067},
  { VOIName: 'VOI8', value : 15.9994},
  { VOIName: 'VOI9', value : 18.9984},
  { VOIName: 'VOI10', value : 20.1797},
];
@Component({
  selector: 'app-voi-list',
  templateUrl: './voi-list.component.html',
  styleUrls: ['./voi-list.component.css']
})

export class VoiListComponent implements OnInit {
  @Input('seriesId') seriesId?:number;
  @Input('VOISelected') VOISelected?:VOI;
  @Output('VOISelect') VOISelect:EventEmitter<VOI> = new EventEmitter<VOI>();
  displayedColumns: string[] = ['select', 'name', 'volume'];
  dataSource = new MatTableDataSource<VOI>(ELEMENT_DATA);
  selection = new SelectionModel<VOI>(false, []);
  ngOnInit(){
    if(this.VOISelected){
      let list = this.dataSource.data.map(element => element.VOIName);
      this.selection.select(this.VOISelected);
      this.selection = new SelectionModel<VOI>(false,[this.dataSource.data[list.indexOf(this.VOISelected.VOIName)]]);
    }
  }
  checkboxLabel(row?: VOI): string {
    let list = this.dataSource.data.map(element => element.VOIName);
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${list.indexOf(row.VOIName) + 1}`;
  }
  changeSelection(row?:VOI){
    this.selection.toggle(row)
    if(this.selection.isSelected(row))
      this.VOISelect.emit(row);
  }
}