import { ViewerAuditableContext } from "src/app/_models/viewer/ViewerConfigModel";

/**
 * @description
 * Dicom loader service interface for Dynamika image visualization component.
 *
 */
export abstract class DicomLoaderService {
    public cellReady: any;
    public annotationIsLoaded: any;
    public synchControls: any;
    public viewerComponent: any;
    /**
     * Loads DICOM image information and set this information to the frame attributes.
     * @param cellFrame
     * @param frameIndex
     * @param json
     */
    abstract loadFrameDicomJSON(cellFrame, frameIndex?: number, json?);
    /**
     * Set MRTIImage image information to the cell frames.
     * @param presentationInfo
     * @param seriesInstanceUID
     * @param id
     */
    abstract setImageInformation(presentationInfo, seriesInstanceUID, id: string): void;
    /**
     * Load series and trigger actions like: image staks, dicom headers, overlays, etc.
     * @param cell
     * @param stackInstanceUIDs
     * @param info
     */
    abstract loadSeries(cell, stackInstanceUIDs: string[], info: any, shouldShowDICOM: boolean, audit?: ViewerAuditableContext);
    /**
     * Set the active cell of the viewer
     * @param id
     */
    abstract setActiveCell(id: string)
    /**
     * Enable a spinner when loading a series
     * @param id
     */
    abstract enableSpinner(cell)
    /**
     * Disable a spinner when loading a series
     * @param id
     */
    abstract disableSpinner(id: string)
}

