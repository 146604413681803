import { forkJoin } from 'rxjs';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastOptions } from 'ng2-toasty';
import { AutoCreationBatchConfigDialogComponent } from 'src/app/components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import { FormMode } from 'src/app/core/constants/form-mode';
import { ReadingType } from 'src/app/core/constants/reading-type';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import { AutoBatchConfigPayload } from 'src/app/core/payload/auto-batch-config-payload';
import { BatchConfigModel } from 'src/app/_models/BatchLogic/batch-config-model';
import { ModalityModel } from 'src/app/_models/ImagingProject/modality-model';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import { ReadingConfigFlexibleService, StudySequenceLabelService, StudyService, StudyUserService } from 'src/app/_services';
import { BatchLogicService } from 'src/app/_services/batch-logic.service';
import { ReadingLevelOptionsDialogComponent } from 'src/app/components/controls/reading-level-options-dialog/reading-level-options-dialog.component';
import { ReadingTypeSequentialOptionsDialogComponent } from 'src/app/components/controls/reading-type-sequential-options-dialog/reading-type-sequential-options-dialog.component';
import {PrimaryBodyLocationService} from 'src/app/_services';
import * as moment from 'moment';
import { VisitType } from 'src/app/_models/ImagingProject/patient-model';
import { defaultBasicAutoBatchConfig } from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';
import { VisitModalityConfiguratorDialogComponent } from '../../visit-modality-configurator-dialog/visit-modality-configurator-dialog.component';
import { AdvancedAnalysisConfigModel, ADVANCED_ANALYSIS_VisitModel } from 'src/app/_models/ImagingProject/Advanced-Analysis/advanced-analysis-config-model';
import { FtbEditDialogComponent } from '../FTB-edit-dialog/ftb-edit-dialog/ftb-edit-dialog.component';

export enum ReadingConfigFlexibleStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}
export interface ImagingProjectSubmitEvent {
  result: 'submit' | 'cancel';
  data: any;
  level1BatchConfig?: AutoBatchConfigPayload;
  adjudicationBatchConfig?: AutoBatchConfigPayload;
  basicBatchConfig?: AutoBatchConfigPayload;
}

@Component({
  selector: 'app-imaging-project-reading-advanced-analysis',
  templateUrl: './imaging-project-reading-advanced-analysis.component.html',
  styleUrls: ['./imaging-project-reading-advanced-analysis.component.css']
})
export class ImagingProjectReadingAdvancedAnalysisComponent extends ImagingProjectReadingBasicComponent implements OnInit {
  
  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();
  @Input() public mode: FormMode;
  readonly formModes = FormMode;
  @Input('configModel')
  set configModel(value: any) {
    if (value != null) {
      this._configModel = value;
        this.selectedModality = this._configModel.modalities;
    }
  }
  @Input() selectedConfig: FlexibleConfig<AdvancedAnalysisConfigModel>;
  get configModel(): any {
    return this._configModel;
  }
  public get ReadingVersion(): typeof ReadingVersion {
    return ReadingVersion;
  }

  readonly EARLY_TERMINATION_VISIT = 'Early Termination';

  demriqtypes: any[];
  readingVersionField = new FormControl('', [
    Validators.required
  ]);
  readingLevelField = new FormControl('', [
    Validators.required
  ]);
  readingTypeField = new FormControl('', [
    Validators.required
  ]);
  hideVisitChronologyField = new FormControl('', [
    Validators.required
  ]);
  batchManagementField = new FormControl('', [
    Validators.required
  ]);
  imageViewerConfigurationField = new FormControl('', [
    Validators.required
  ])
  locationsField = new FormControl('');
  readersField = new FormControl('', [
    Validators.required
  ]);
  gbmSegmentationField = new FormControl(true, [Validators.required])
  isGBMSegmentation: boolean = true;
  gbmSegmentationAllTimepoints: boolean;
  studySequenceLabels: SequenceLabelModel[] = [];
  anatomySelectorControl: FormControl = new FormControl('', [Validators.required]);
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];
  basicBatchConfig: any;
  readingVersion: any;
  readingLevel: string;
  selectedModality: ModalityModel[];
  readingType: string;
  hideVisitChronologyType: string;
  batchManagementType = false;
  imageViewerConfigurationType: string;
  fingersToScore: any;
  regionsToScore: any;
  dataSourceReaders: any;
  dataSourceLocations: MatTableDataSource<any> = new MatTableDataSource<any>();
  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];
  displayedLocationsColumns: string[] = ['id', 'name', 'subName', 'actionRemove'];
  displayedScreenshotOptions: string[] = ['id', 'optionText', 'actionDelete'];
  minReaders = 1;
  maxReaders = 20;
  readers: any[] = [];
  minLocations = 1;
  locations: any[] = [];
  ftbLow: number;
  ftbMedium1: number;
  ftbMedium2: number;
  ftbHigh:number;

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  studyId: number;
  _configModel = {
    endpointReadingVersion: 'External AI',
    modalities: [] as ModalityModel[],
    readingLevel: 'longitudinal',
    readingLevelConfig: ["SMART", "SEQUENTIAL"],
    readingTypeConfig: ["AUTO"],
    readingType: 'sequential',
    hideVisitHistory: null,
    readers: [],
    locations: [],
    readingVersion: ReadingVersion.Advanced_Analysis,
    batchManagement: false,
    imageViewerConfigurationType: 'default',
    endpoint: null,
    anatomySelector: [],
    visit: [],
    isGBMSegmentation: false,
    gbmSegmentationAllTimepoints: false,
    ftbLow: 1.0,
    ftbMedium1: 1.0,
    ftbMedium2: 1.75,
    ftbHigh: 1.75
  };


  formValid = false;
  selectedReadersids = [];
  selectedLocationsids = [];
  modalities: ModalityModel[];
  selectedReaders = [];
  selectedLocations = [];
  selectedQuestionTemplate: string;
  unscheduledAllowed: boolean;

  //for child configs
  status = ReadingConfigFlexibleStatus;
  studyVisits: any[] = [];

  constructor(private router: Router,
    private route: ActivatedRoute,
    private studySequenceLabelService: StudySequenceLabelService,
    private studyUserService: StudyUserService,
    private dialog: MatDialog,
    private batchLogicService: BatchLogicService,
    private primaryBodyLocationService: PrimaryBodyLocationService,
    private studyService: StudyService) {
    super();
  }

  ngOnInit(): void {
    this.formValid = false;
    this.selectedReaders = [];
    this.selectedLocations = [];

    this._configModel.readers.forEach(ri => {
      this.selectedReadersids.push(ri['id']);
    });

    this.readingVersion = 'ADV_ANALYSIS_BASIC';
    this.readingLevel = 'longitudinal';
    this.readingType = 'sequential';
    this.hideVisitChronologyType = 'show_scan_dates';
    this.imageViewerConfigurationType = 'default';
    this.ftbLow = 1.0;
    this.ftbMedium1 = 1.0;
    this.ftbMedium2 = 1.75;
    this.ftbHigh = 1.75;
    if (this.mode == FormMode.EDIT) {
      this.readingVersion = this._configModel.readingVersion
      this.readingLevel = this._configModel.readingLevel;
      this.readingType = this._configModel.readingType;
      this.imageViewerConfigurationType = this._configModel.imageViewerConfigurationType;
      this.hideVisitChronologyType = this._configModel.hideVisitHistory;
      this.isGBMSegmentation = this._configModel.isGBMSegmentation;
      this.gbmSegmentationAllTimepoints = this._configModel.gbmSegmentationAllTimepoints;
      this.ftbLow = this._configModel.ftbLow;
      this.ftbMedium1 = this._configModel.ftbMedium1;
      this.ftbMedium2 = this._configModel.ftbMedium2;
      this.ftbHigh = this._configModel.ftbHigh
    }

    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId).subscribe(respgroupedModalitiesResp => {
      if (respgroupedModalitiesResp.data != null) {
        this.modalities = respgroupedModalitiesResp.data;
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        if (this.mode == FormMode.EDIT)
          this.selectedModality = respgroupedModalitiesResp.data.filter(x => this._configModel.modalities.find(m => m.name == x.name))
        else
          this._configModel.modalities = this.modalities
          // modality config
          const studyServices = [
            this.studyService.getVisitsByStudyId(this.studyId),
            this.studyService.getById(this.studyId),
          ]
          forkJoin(studyServices).subscribe(([studyVisitResponse, studyResponse]) => {
            this.studyVisits = [...studyVisitResponse['data']];
            this.initStudyVisits(studyResponse['data']);
          })
      }
      this.prepareSequenceLabels();
      this.studyUserService.getReadersByStudyId(this.studyId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.readers = response.data;
            const activeReader: any[] = [];
            this.readers.forEach(r => {

              if (this.selectedReadersids.includes(r.id)) {
                activeReader.push(r);
              }
            });

            this._configModel.readers = activeReader;
            this.dataSourceReaders = this._configModel.readers.sort((a, b) => a?.firstName - b?.firstName);
            this.selectedReaders = this._configModel.readers;
            this.selectedReadersids = [];
            this._configModel.readers.forEach(r => {
              const cur = this.readers.indexOf(r);
              this.readers.splice(cur, 1);
            });
          }
        });
    });
    this.primaryBodyLocationService.findAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.locations = response.data.filter(data => data.bodySubLocations.length > 0).sort((a, b) => a.name - b.name);
          if (this.mode == FormMode.EDIT) {
            this._configModel.locations.forEach(l => {
             this.selectedLocationsids.push(l.id)
            });
            this.onAddSelectedLocations();
          }
        }
      });
    if (this.selectedConfig) {
      this.batchManagementType = this.selectedConfig.config.batchManagement;
      this.loadBatchConfigs();
    }
  }

   //modality config
   initStudyVisits(study: any) {
    //sort study visits by id
    this.studyVisits = this.sortVisits(this.studyVisits);
    this.unscheduledAllowed = study.unscheduledAllowed;
    //add unschaduled visits and early termination
    const unscheduled = new ADVANCED_ANALYSIS_VisitModel();
    unscheduled.name = 'Unscheduled';
    unscheduled.type = VisitType.UNSCHEDULED_REGULAR;
    this.studyVisits.push(unscheduled);

    const earlyTermination = new ADVANCED_ANALYSIS_VisitModel();
    earlyTermination.name = this.EARLY_TERMINATION_VISIT;
    earlyTermination.type = VisitType.UNSCHEDULED_EARLY_TERMINATION;
    this.studyVisits.forEach(visit => visit.isAvailable = true);
    this.studyVisits.push(earlyTermination);
    if (this.mode != FormMode.EDIT) {
      this._configModel.visit = this.studyVisits;
      this._configModel.visit.forEach(v => v['modalities'] = !this.unscheduledAllowed && v.type === VisitType.UNSCHEDULED_REGULAR ? [] : this.modalities)
    }
  }

  sortVisits(visits) {
    visits.forEach(visit => {
      let visitDate = moment(visit.create);
      if (visit.durationTimeUnit == 'D') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'd');
      } else if (visit.durationTimeUnit == 'W') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'w');
      } else if (visit.durationTimeUnit == 'M') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'M');
      }
      visit.visitDate = visitDate;
    });
    visits.sort((a, b) => {
      if (a.visitDate < b.visitDate) {
        return -1;
      }
      if (a.visitDate > b.visitDate) {
        return 1;
      }
      return 0;
    });
    let baselineIndex = visits.indexOf(visits.find(this.isBaseline));
    visits.unshift(visits.find(this.isBaseline));
    visits.splice(baselineIndex + 1, 1);
    return visits;
  }
  isBaseline(visit) {
    return visit.baseline === true;
  }

  loadBatchConfigs(): void {
    if (this.mode === FormMode.EDIT) {
      this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id).subscribe(response => {
        const batchConfigs = response.data;
        batchConfigs.forEach(c => {
          this.basicBatchConfig = c;
        });
      });
    }
  }
  prepareSequenceLabels() {
    this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId).subscribe(studySequenceResp => {
      this.studySequenceLabels = studySequenceResp.data;
      if (this.mode === FormMode.EDIT) {
        if (!!this._configModel.anatomySelector) {
          this.initSequenceLabels(this._configModel.anatomySelector);
        } else {
          this.initDefaultSequenceLabels(this.modalities);
        }
      } else
        this.initDefaultSequenceLabels(this.modalities);
    });
  }
  
  initDefaultSequenceLabels(modalities?) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.availableSequenceLabels;
    this._configModel.anatomySelector = this.selectedSequenceLabels;
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    const modalities = this.modalities.filter(m => this.selectedConfig.config.modalities.find(cm => cm.name === m.name));
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

//modality config
getAvailableSequenceLabels(modalities?): SequenceLabelModel[] {
  const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
  for (const modality of modalities) {
    for (const label of modality.sequenceLabels) {
      configLabels.add(label);
    }
  }
  return this.studySequenceLabels
    .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
}

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this.validateForm();
  }

  onChangeReadingVersion(event) {
    this._configModel.readingVersion = event.value;
    this.validateForm();
  }

  // modality config
  openModalitiesDialog() {
    ImagingProjectReadingBasicComponent.clearFocus();
    const dialogRef = this.dialog.open(VisitModalityConfiguratorDialogComponent, {
      data: {
        visits: this._configModel.visit,
        modalities: this.modalities,
        unscheduledAllowed: this.unscheduledAllowed
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.filterVisits(result.visits);
        const uniqueModality = this.getAllVisitsUniqueModalities(result.visits);
        this._configModel.modalities = [...uniqueModality];
        this.initDefaultSequenceLabels(this._configModel.modalities);
        this.validateForm();
      }
    });
  }

  getAllVisitsUniqueModalities(visits: any[]) {
    let modalities = [];
    visits.forEach((visit, visitIndex) => {
      this._configModel.visit[visitIndex]
        .modalities = [...visit.modalities];
      modalities = [...modalities, ...visit.modalities];
    });
     const uniqueModality = [];
     modalities.forEach((modality) => {
       let index = uniqueModality.findIndex((m) => m.id === modality.id) === -1;
       if (index) uniqueModality.push(modality);
     });
     return uniqueModality;
  }

  private filterVisits(visits) {
    visits.forEach(visit => {
      if (!visit.modalities || visit.modalities.length < 1) {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = false;
      } else {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = true;
      }
    });
  }

  openReadingLevelConfiguration() {
    let inputOptions: string[];
    const val = this._configModel.readingLevelConfig;
    if (!val) {
      inputOptions = [];
    } else {
      inputOptions = [...val];
    }
    const dialogRef = this.dialog.open(ReadingLevelOptionsDialogComponent, {
      width: '500px',
      data: inputOptions
    });

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result) {
        if (!result) {
          result = [];
        }
        this._configModel.readingLevelConfig = result;
      }
      ImagingProjectReadingBasicComponent.clearFocus();
      this.validateForm();
    });
  }

  openReadingTypeConfiguration() {
    let inputOptions: string[];
    const val = this._configModel.readingTypeConfig;
    if (!val) {
      inputOptions = [];
    } else {
      inputOptions = [...val];
    }
    const dialogRef = this.dialog.open(ReadingTypeSequentialOptionsDialogComponent, {
      width: '500px',
      data: inputOptions
    });

    dialogRef.afterClosed().subscribe((result: string[]) => {
      if (result) {
        this._configModel.readingTypeConfig = result;
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  onBatchManagementSelected(event: MatSelectChange): void {
    if (this.basicBatchConfig) {
      this.basicBatchConfig.numberOfReadings = null;
      this.batchManagementField.updateValueAndValidity();
    }
    this.validateForm();
  }

  openAutoCreationBatchConfigDialog(): void {
    let inputConfig: AutoBatchConfigPayload;
    const val = this.batchManagementField.value;
    if (!val) {
      inputConfig = { ...defaultBasicAutoBatchConfig };
    } else {
      inputConfig = this.basicBatchConfig ? { ...this.basicBatchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
        width: '500px',
        data: {
            readingType: ReadingType.BASIC_READING,
            configInput: inputConfig,
            readonlyDialog: !val
        }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        this.basicBatchConfig = { ...result };
        this.batchManagementField.updateValueAndValidity();
        this.validateForm();
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  onReadingLevelChange(event: { value: any; }) {
    this._configModel.readingLevel = event.value;
    this.validateForm();
  }

  onReadingTypeChange(event: { value: any; }) {
    this.formValid = this._configModel.readingType !== event.value;
    this._configModel.readingType = event.value;
    this.validateForm();
  }

  onChangeGbmSegmentation(event) {
    if(event.value == false) {
      this._configModel.gbmSegmentationAllTimepoints = false;
      this.gbmSegmentationAllTimepoints = false;
    }
  }

  onReaderSelectionChange(event: { value: any[]; }) {
    this.selectedReadersids = event.value;
  }

  onLocationSelectionChange(event: { value: any[]; }) {
    this.selectedLocationsids = event.value;
  }
  onChangeImageConfiguration() {
    this.validateForm();
  }

  openEditFTBDialog(ftbName, value) {
    const dialogRef = this.dialog.open(FtbEditDialogComponent, {
      width: '250px',
      data: value
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(ftbName === 'ftbLow') {
          this.ftbLow = result
        } else if(ftbName === 'ftbMedium1') {
          this.ftbMedium1 = result
        } else if (ftbName === 'ftbMedium2') {
          this.ftbMedium2 = result
        } else if(ftbName === 'ftbHigh') {
          this.ftbHigh = result
        }
      }
      console.log(result);
      
    });
  }

  // this code must check and make sure the Reader is only added to the list if it is not already in it!
  onAddSelectedReaders() {

    if (this.selectedReadersids != null) {
      let counter = this._configModel.readers.length;
      this.selectedReadersids.forEach(readerId => {
        if (counter < this.maxReaders) {
          const reader = this.readers.find(r => r.id === readerId);
          if ((reader != null) && (!this._configModel.readers.includes(reader))) {
            this._configModel.readers.push(reader);
            const index = this.readers.indexOf(reader);
            this.readers.splice(index, 1);
            this.validateForm();
          }
          counter += 1;
        }
      });

      this.selectedReadersids = [];
    }
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers.sort((a, b) => a?.firstName - b?.firstName));
    this.selectedReaders = this._configModel.readers;
    if (this._configModel.readers.length > 0) {
      this.readersField.clearValidators();
    }
  }

  onAddSelectedLocations(addButtons: boolean = false) {
    const selectedLocations = this.locations.filter(loc => this.selectedLocationsids.includes(loc.id))
    // add selected locations to dataSource
    selectedLocations.forEach(location => {
      location.bodySubLocations.forEach(sublocation => {

        const loc = Object()
        loc.id = location.id;
        loc.name = location.name;
        loc.subId = sublocation.id;
        loc.subName = sublocation.name;
        if (this.mode === FormMode.EDIT && !addButtons ) {
          if (this._configModel.locations.find(lc => lc.id === location.id).subLocations.findIndex(sb => sb.id === sublocation.id) !== -1)
          this.dataSourceLocations.data.push(loc);
        } else
          this.dataSourceLocations.data.push(loc);
      });
    })

    this.dataSourceLocations = new MatTableDataSource<any>(this.dataSourceLocations.data.sort((a, b) => a.name - b.name));
    this.selectedLocations.push(...selectedLocations);
    // add selected locations to config model
    const addedLocations = this.selectedLocations.filter(l => this._configModel.locations.findIndex(cl => cl.id === l.id) === -1).map(sl => {
        return{
          id: sl.id,
          name: sl.name,
          subLocations: sl.bodySubLocations.map(sb => {
            return {
              id: sb.id,
              name: sb.name
            }
          })
        }
    })
    this._configModel.locations.push(...addedLocations)


    this.locations = this.locations.filter(lc => !this.selectedLocationsids.includes(lc.id))
    this.selectedLocationsids = [];
    if (this._configModel.locations.length > 0) {
      this.locationsField.clearValidators();
    }
    this.validateForm();
  }

  deleteReader(reader: any) {
    const index = this._configModel.readers.indexOf(reader);
    this._configModel.readers.splice(index, 1);
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers.sort((a, b) => a?.firstName - b?.firstName));
    this.selectedReadersids = this._configModel.readers;
    this.readers.unshift(reader);
    this.selectedReadersids = [];

    if (this._configModel.readers.length === 0) {
      this.readersField.validator = Validators.required;
    }
    if (this._configModel.readers.length > 0) {
      this.validateForm();
    } else {
      this.formValid = false;
    }

  }

  deleteLocation(location: any) {
    this.dataSourceLocations = new MatTableDataSource<any>(this.dataSourceLocations.data.filter(lc => lc.subId !== location.subId).sort((a, b) => a.name - b.name));
    const selectedBodyLocation = this._configModel.locations.find(lc => lc.id === location.id).subLocations;
    this._configModel.locations.find(lc => lc.id === location.id).subLocations= selectedBodyLocation.filter(sb => sb.id !== location.subId)

    if (this._configModel.locations.find(lc => lc.id === location.id).subLocations.length === 0) {
      this._configModel.locations = this._configModel.locations.filter(lc => lc.id !== location.id)
      const recoverLocation = this.selectedLocations.find(l => l.id === location.id)
      this.locations.unshift(recoverLocation);
      this.selectedLocations = this.selectedLocations.filter(l => l.id !== location.id);
    }
    this.selectedLocationsids = [];

    if (this._configModel.locations.length === 0) {
      this.locationsField.validator = Validators.required;
    }
    if (this._configModel.locations.length > 0) {
      this.validateForm();
    } else {
      this.formValid = false;
    }

  }

  clickSubmitBtn() {
    const readers = [];
    this._configModel.readers.forEach(r => {
      readers.push({id: r.id});
    });

    this._configModel.anatomySelector = this.selectedSequenceLabels.map(l => {
      return {id: l.id};
    });

    this._configModel.readingVersion = this.readingVersion;
    this._configModel.batchManagement =this.batchManagementType;
    this._configModel.hideVisitHistory=this.hideVisitChronologyType;
    this._configModel['endpointReadingVersion'] = 'External AI';
    this._configModel.readers = readers;
    this._configModel.anatomySelector = this.selectedSequenceLabels;
    this._configModel.readingType = this.readingType;
    this._configModel.readingLevel = this.readingLevel;
    this._configModel.imageViewerConfigurationType = this.imageViewerConfigurationType;
    this._configModel.visit = this._configModel.visit;
    this._configModel.isGBMSegmentation = this.isGBMSegmentation;
    this._configModel.gbmSegmentationAllTimepoints = this.gbmSegmentationAllTimepoints;
    this._configModel.ftbLow = this.ftbLow;
    this._configModel.ftbMedium1 = this.ftbMedium1;
    this._configModel.ftbMedium2 = this.ftbMedium2;
    this._configModel.ftbHigh = this.ftbHigh; 
    if(!this.basicBatchConfig)
      this.basicBatchConfig = {
        batchCount: "1",
        endpoint: 'Advanced Analysis',
        flexibleConfigId: null,
        id: null,
        initiationMethod: "auto",
        initiationOption: "by_quantity",
        numberOfReadings: 1,
        readingType: "basic_reading",
        studyId: this.studyId
      };
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: this._configModel,
      basicBatchConfig: this.basicBatchConfig
    };
    this.clickSubmit.emit(submitEvent);
  }

  clickCancelBtn() {
    this.clickCancel.emit({result: 'cancel'});
  }

  validateForm() {
    let ans = this.formValid = true;

    if (this._configModel.readingLevel == null) {
      ans = false;
    }

    if (this._configModel.readingType == null) {
      ans = false;
    }

    if (this._configModel.readers.length < this.minReaders || this._configModel.readers.length >this.maxReaders) {
      ans = false;
    }

    if (this._configModel.locations.length < this.minLocations) {
      ans = false;
    }

    if(this.hideVisitChronologyField.invalid)
      ans = false;

    if(this.imageViewerConfigurationField.invalid)
      ans = false;

    if(this.batchManagementField.invalid)
      ans = false;

    if (this.selectedSequenceLabels.length === 0)
      ans = false;

      if(this.ftbLow)

    this.formValid = ans;
  }

  public objectComparator = function (option, value): boolean {
    return option.id === value.id;
  };

}
