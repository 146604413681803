import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { BasicResponse } from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingMOAKSVersion;
const SERVICE = 'msreadingmoaks-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/reading/';
// const SERVICE_API_URL = 'http://localhost:8080/api/reading/';

@Injectable({
    providedIn: 'root'
})
export class ReadingMoaksService {

    constructor(private http: HttpClient) {
    }

    getNumberOfAvailableReadings(studyId, readerId) {
        return this.http.get(`${SERVICE_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
    }

    getReadingByStudyIdAndReaderId(studyId, readerId): Observable<BasicResponse<any>>{
        return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-available-readings/${studyId}/${readerId}`);
    }

    startReading(studyId, readingId): Observable<BasicResponse<any>>{
        return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}start/${studyId}/${readingId}`, null);
    }

    updateReading(studyId, readingId, spentSeconds): Observable<BasicResponse<any>>{
        return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}${studyId}/${readingId}`, {spentSeconds});
    }

    completeReading(studyId, data, jwtToken): Observable<BasicResponse<any>> {
      const customHeader = new HttpHeaders({
        'Authorization': `Bearer ${jwtToken}`
      });

      return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}complete/${studyId}`, data, {
        headers: customHeader
      });
    }
}
