
import { Observable } from 'rxjs';
import { ViewerAuditableContext } from 'src/app/_models/viewer/ViewerConfigModel';
/**
 * @description
 * Image object store service interface for Dynamika image visualization component.
 *
 */

export abstract class ObjectStoreService {
    /**
     * Store cell annotations
     * @param seriesInstanceUID
     * @param psSOPInstanceUID
     * @param annotationData
     * @param description
     * @param userData
     */
    abstract storeAnnotations(seriesInstanceUID: string, psSOPInstanceUID: string, annotationData: string, description: string, userData?, person?, audit?: ViewerAuditableContext): Observable<any>;
    /**
     * Store cell current slice annotations
     * @param seriesInstanceUID
     * @param psSOPInstanceUID
     * @param pageNumber
     * @param annotationData
     * @param description
     * @param sopInstanceUID
     * @param sliceCount
     * @param sliceNumber
     * @param frameCount
     * @param userData
     */
    abstract storeCurrentSliceAnnotations(seriesInstanceUID: string, psSOPInstanceUID: string, pageNumber: string, annotationData: string, description: string, sopInstanceUID: string, sliceCount: string, sliceNumber: string, frameCount: string, userData?, person?, audit?: ViewerAuditableContext): Observable<any>;
    /**
     * Delete cell annotations
     * @param sopInstanceUID
     * @param userData
     */
    abstract deleteAnnotations(sopInstanceUID: string, userData?, person?, audit?: ViewerAuditableContext): Observable<any>;

}

