import { Action, Selector, State, StateContext } from '@ngxs/store';
import { ClearCurrentUserInfo, GetCurrentUserInfo, GetUserRolesForStudy } from '../actions/user-info.action';
import { Injectable } from '@angular/core';
import { StudyUserService, UserService } from '../../../_services';
import { tap } from 'rxjs/operators';


export class CurrentUserInfoStateModel {
  userId?: number;
  firstName: string;
  lastName: string;
  previousLogin: string;
  roles?: Array<any>;

  toString() {
    return `${this.firstName} ${this.lastName}`;
  }
}

@State<CurrentUserInfoStateModel>({
  name: 'currentUserInfo',
  defaults: {
    userId: null,
    firstName: null,
    lastName: null,
    previousLogin: null,
    roles: []
  }
})

@Injectable()
export class CurrentUserInfoState {

  @Selector()
  static getCurrentUserInfo(state: CurrentUserInfoStateModel): CurrentUserInfoStateModel {
    return state;
  }

  @Selector()
  static getUserRolesForStudy(state: CurrentUserInfoStateModel): Array<any> {
    return state.roles;
  }

  constructor(private userService: UserService, private studyUserService: StudyUserService) {
  }

  @Action(GetUserRolesForStudy)
  getUserRolesForStudy(ctx: StateContext<CurrentUserInfoStateModel>, action: GetUserRolesForStudy) {
    const userInfo = ctx.getState();
    const role = userInfo.roles.find(r => r.studyId === action.studyId);
    if (role) {
      return;
    }

    return this.studyUserService.getStudyUserRoles(action.studyId, action.userId).pipe(
      tap(({ data }) => {
        const roles = userInfo.roles.slice();
        roles.push({
          studyId: action.studyId,
          roles: data
        });

        ctx.patchState({
          roles
        });
      })
    );
  }

  @Action(GetCurrentUserInfo)
  getCurrentUserInfo(ctx: StateContext<CurrentUserInfoStateModel>, action: GetCurrentUserInfo) {
    const userInfo = ctx.getState();
    const id = +action.userId;
    if (userInfo.userId === id) {
      return;
    }

    return this.userService.getById(id).pipe(
      tap(({ data }) => {
        ctx.patchState({
          userId: id,
          firstName: data.firstName,
          lastName: data.lastName,
          previousLogin: data.previousLogin,
          toString() {
            return `${this.firstName} ${this.lastName}`;
          }
        });
      })
    );
  }

  @Action(ClearCurrentUserInfo)
  clearUserInfo({ getState, patchState }: StateContext<CurrentUserInfoStateModel>) {
    patchState({
      firstName: null,
      lastName: null,
      previousLogin: null
    });
  }

}
