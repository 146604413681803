import {environment} from '../../environments/environment';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {EventModel} from '../_models/ImagingProject/event-model';
import {AuditTrailsRequest} from '../modules/AuditTrails/audit-trails-patient-view/audit-trails-patient-view.component';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSAuditTrailsVersion;
const SERVICE = 'msaudittrails-dot-';

const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/event';

@Injectable({
  providedIn: 'root'
})
export class AuditTrailsService {

  constructor(private http: HttpClient) {
  }

  getUniqueObjectNames(studyId: number, category: string): Observable<BasicResponse<string[]>> {
    return this.http.get<BasicResponse<string[]>>(`${BASE_URL}/get-unique-object-names`, {
      params: {
        studyId: studyId.toString(),
        category: category
      }
    });
  }

  getDistinctObjectNames(studyId: number): Observable<BasicResponse<string[]>> {
    return this.http.get<BasicResponse<string[]>>(`${BASE_URL}/get-object-names`, {
      params: {
        studyId: studyId.toString()
      }
    });
  }

  getEvent(id: number): Observable<BasicResponse<EventModel>> {
    return this.http.get<BasicResponse<EventModel>>(`${BASE_URL}/get-event`, {params: {eventId: id.toString()}});
  }

  getEvents(request: AuditTrailsRequest): Observable<BasicResponse<EventModel[]>> {
    const params: any = {
      page: request.page.toString(),
      size: request.size.toString()
    };
    if (request.category) {
      params.category = request.category;
    }
    if (request.studyId) {
      params.studyId = request.studyId.toString();
    }
    if (request.patientId) {
      params.patientId = request.patientId.toString();
    }
    if (request.objectName) {
      params.objectName = request.objectName;
    }
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/get-events`, {
      params: params
    });
  }

}
