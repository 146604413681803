import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatTableDataSource} from '@angular/material/table';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {ReadingConfigFlexibleService, StudySequenceLabelService, StudyUserService, UserService} from '../../../../../_services';
import {ActivatedRoute} from '@angular/router';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {
  defaultAdjAutoBatchConfig,
  defaultLvl1AutoBatchConfig,
  ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {FormMode} from '../../../../../core/constants/form-mode';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';

import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {PsamrisAdjudicationConfigModel} from '../../../../../_models/ImagingProject/PSAMRIS/psamris-adjudication-config-model';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {MatSelectChange} from '@angular/material/select';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
  selector: 'app-imaging-project-reading-psamris-adjudication',
  templateUrl: './imaging-project-reading-psamris-adjudication.component.html',
  styleUrls: ['./imaging-project-reading-psamris-adjudication.component.css']
})
export class ImagingProjectReadingPsamrisAdjudicationComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();

  // @Input() configModel: PsamrisConfigModel;
  @Input() selectedConfig: FlexibleConfig<PsamrisAdjudicationConfigModel>;
  @Input() mode: FormMode;

  @Input()
  public studySequenceLabels: SequenceLabelModel[];

  readonly formModes = FormMode;
  readonly readingTypes = ReadingType;

  endpointReadingVersion: 'basic' | 'adjudication' = 'adjudication';
  readingLevelControl: FormControl = new FormControl('', [Validators.required]);
  modalityControl: FormControl = new FormControl([], [Validators.required]);
  readingTypeControl: FormControl = new FormControl('', [Validators.required]);
  hideChronologyControl: FormControl = new FormControl('', [Validators.required]);
  batchManagementForLevelOneControl: FormControl = new FormControl('', [Validators.required]);
  imageViewerConfigControl: FormControl = new FormControl('', [Validators.required]);
  adjRuleControl: FormControl = new FormControl('', [Validators.required]);
  blindAdjReadingControl: FormControl = new FormControl('', [Validators.required]);
  reportLogicNoDisagreementControl: FormControl = new FormControl('', [Validators.required]);
  reportLogicAdjControl: FormControl = new FormControl('', [Validators.required]);
  featuresToScoreControl: FormControl = new FormControl('', [Validators.required]);
  fingersToScoreControl: FormControl = new FormControl('', [Validators.required]);
  regionsToScoreControl: FormControl = new FormControl('', [Validators.required]);
  batchManagementForAdjReadersControl: FormControl = new FormControl('', [Validators.required]);
  readersControl: FormControl = new FormControl('', [Validators.required]);
  adjudicationReaderControl: FormControl = new FormControl('', [Validators.required]);
  anatomySelectorControl: FormControl = new FormControl([], [Validators.required]);

  readingLevel = 'Patient';
  modalityType: ModalityModel[];
  readingType = 'Parallel';
  hideChronology = 'hide_visits_chronology';
  batchManagementForLevelOne = false;
  imageViewerConfig = 'default';
  adjudicationRule = 'Adjudication-PsamrisCelgene';
  blindAdjudicationReading = false;
  finalReportNoDisagreementLogic = 'Attach-AllLevel1';
  adjudicationReportLogic = 'Attach-AdjudicationOnly';
  featuresToScore: ('BP' | 'BE' | 'PI' | 'O' | 'S' | 'FT')[];
  fingersToScore: 'all' | '1to5';
  regionsToScore: 'Dh_Df' | 'Ph_Pf' | 'Mh_Mf' | 'Dh_Ph_Df_Pf' | 'Ph_Mh_Pf_Mf' | 'ALL';
  readers: { id: number, firstName: string, lastName: string }[] = [];
  adjudicationReader: { id: number, firstName: string, lastName: string };
  batchManagementForAdjudicationReaders = false;
  multipleVisits: { modalityName: string, baseline: boolean, week24: boolean, week48: boolean, earlyTermination: boolean }[] = [];

  adjudicationReadersOptions: { id: number, firstName: string, lastName: string }[] = [];
  readersOptions: { id: number, firstName: string, lastName: string }[] = [];
  readersSelectionModel: { id: number, firstName: string, lastName: string }[] = [];

  studyId: number;
  modalities: ModalityModel[];
  readersList: { id: number, firstName: string, lastName: string }[] = [];

  readersTableColumns: string[] = ['username', 'email', 'action'];
  multipleVisitsTableColumns: string[] = ['modalityType', 'baseline', 'w24', 'w48', 'earlyTermination'];
  multipleVisitsDataSource: MatTableDataSource<any> = new MatTableDataSource<any>();

  readersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  adjudicationReadersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);

  modalitySelectorValue: ModalityModel[];

  lvl1BatchConfig: BatchConfigModel;
  adjudicationBatchConfig: BatchConfigModel;

  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];

  constructor(private dialog: MatDialog,
              private userService: UserService,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private route: ActivatedRoute,
              private studySequenceLabelService: StudySequenceLabelService,
              private studyUserService: StudyUserService,
              private batchLogicService: BatchLogicService) {
    super();
  }

  ngOnInit() {
    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId).subscribe((groupedModalitiesRes: any) => {
      if (groupedModalitiesRes.responseCode === 200 && groupedModalitiesRes['data'] != null) {
        this.modalities = groupedModalitiesRes['data'];
        this.modalities.sort((a, b) => a.name.localeCompare(b.name));
        this.modalitySelectorValue = this.modalities;
        this.prepareSequenceLabels();
      }

      this.studyUserService.getReadersByStudyId(this.studyId).subscribe((readersResponse: any) => {
        if (readersResponse.responseCode === 200) {
          this.readersList = readersResponse.data;
          this.adjudicationReadersOptions = readersResponse.data;
          this.readersOptions = readersResponse.data;

          if (this.selectedConfig) {
            this.applyConfigValues(this.selectedConfig.config);
          }
        }
      });
    });

    this.multipleVisits = [{modalityName: 'MRI', baseline: true, week24: true, week48: true, earlyTermination: true}];
    this.multipleVisitsDataSource.data = this.multipleVisits;

    this.loadBatchConfigs();
  }

  prepareSequenceLabels() {
    if (this.mode === FormMode.EDIT) {
      this.modalityType = this.selectedConfig.config.modalities;
      if (this.selectedConfig.config.anatomySelector) {
        const selectedModalities = this.modalities
          .filter(m => this.selectedConfig.config.modalities.some(sm => sm.name === m.name));
        this.availableSequenceLabels = selectedModalities.flatMap(m => m.sequenceLabels);
        this.initSequenceLabels(this.selectedConfig.config.anatomySelector);
      } else {
        this.initDefaultSequenceLabels();
      }
    } else {
      this.initDefaultSequenceLabels();
    }
  }

  onModalitySelect(event: MatSelectChange): void {
    this.availableSequenceLabels = (<ModalityModel[]>event.value).flatMap(m => m.sequenceLabels);
    this.selectedSequenceLabels = [...this.availableSequenceLabels];
    this.validate();
  }

  initDefaultSequenceLabels() {
    this.selectedSequenceLabels = [...this.availableSequenceLabels];
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    this.selectedSequenceLabels = this.availableSequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  loadBatchConfigs(): void {
    if (this.mode === FormMode.EDIT) {
      this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id).subscribe(response => {
        const batchConfigs = response.data;
        batchConfigs.forEach(c => {
          if (c.readingType === ReadingType.LEVEL1_READING) {
            this.lvl1BatchConfig = c;
          } else if (c.readingType === ReadingType.ADJUDICATION_READING) {
            this.adjudicationBatchConfig = c;
          }
        });
      });
    }
  }

  applyConfigValues(config: PsamrisAdjudicationConfigModel): void {
    const cm = config;
    this.readingLevel = cm.readingLevel;
    this.modalityType = cm.modalities;
    this.readingType = cm.readingType;
    this.hideChronology = cm.hideVisitHistory;
    this.batchManagementForLevelOne = cm.batchManagement;
    this.imageViewerConfig = cm.imageViewerConfiguration;
    this.adjudicationRule = cm.adjudicationRule;
    this.blindAdjudicationReading = cm.adjudicationBlind;
    this.finalReportNoDisagreementLogic = cm.adjudicationFinalReportWithoutDisagreementRule;
    this.adjudicationReportLogic = cm.adjudicationFinalReportWithDisagreementRule;
    this.featuresToScore = cm.featuresToScore;
    this.fingersToScore = cm.fingersToScore;
    this.regionsToScore = cm.regionsToScore;
    this.batchManagementForAdjudicationReaders = cm.adjudicationBatchManagement;
    this.readers = cm.readers.map(r => {
      return this.readersList.find(t => t.id === r.id);
    });
    this.pushReaders();
    this.adjudicationReader = this.readersList.find(r => r.id === cm.adjudicationReader);
    this.adjudicationReadersOptions = this.readersList.filter(r => r.id !== cm.adjudicationReader);
    this.readersOptions = this.readersList.filter(r => cm.readers.filter(reader => reader.id === r.id).length === 0);
    this.adjudicationReadersDataSource.data = [this.readersList.find(r => r.id === cm.adjudicationReader)];
  }

  openAutoCreationBatchConfigDialog(readingType: ReadingType, config: BatchConfigModel): void {
    let readonlyDialog: boolean;
    if (readingType === ReadingType.ADJUDICATION_READING) {
      readonlyDialog = !this.batchManagementForAdjudicationReaders;
    } else if (readingType === ReadingType.LEVEL1_READING) {
      readonlyDialog = !this.batchManagementForLevelOne;
    }

    let inputConfig = config as AutoBatchConfigPayload;
    if (readingType === ReadingType.LEVEL1_READING) {
      if (!this.batchManagementForLevelOne)
        inputConfig = { ...defaultLvl1AutoBatchConfig };
      else
        inputConfig = this.lvl1BatchConfig ? { ...this.lvl1BatchConfig } : null;
    }
    if (readingType === ReadingType.ADJUDICATION_READING) {
      if (!this.batchManagementForAdjudicationReaders)
        inputConfig = { ...defaultAdjAutoBatchConfig };
      else
        inputConfig = this.adjudicationBatchConfig ? { ...this.adjudicationBatchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        readingType: readingType,
        configInput: inputConfig,
        readonlyDialog: readonlyDialog
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        const resultConfig = result;
        if (readingType === ReadingType.ADJUDICATION_READING) {
          this.adjudicationBatchConfig = resultConfig;
        } else {
          this.lvl1BatchConfig = resultConfig;
        }
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  pushReaders(): void {
    this.readers = [...this.readers, ...this.readersSelectionModel];
    this.readersOptions = this.readersList.filter(r => this.readers.filter(reader => reader.id === r.id).length === 0);
    this.readersSelectionModel = [];
    this.readersDataSource.data = this.readers;
    if (this.readersDataSource.data.length > 0) {
      this.readersControl.clearValidators();
    }
  }

  removeReader(id: number): void {
    this.readersOptions = [...this.readersOptions, this.readersList.find(r => r.id === id)];
    this.readers = this.readers.filter(r => r.id !== id);
    this.readersDataSource.data = this.readers;
    if (this.readersDataSource.data.length === 0) {
      this.readersControl.setValidators(Validators.required);
      this.readersControl.updateValueAndValidity();
    } else {
      this.readersControl.clearValidators();
    }
  }

  pushAdjudicator(): void {
    this.adjudicationReadersDataSource.data = [this.adjudicationReader];
    this.adjudicationReadersOptions = this.readersList.filter(r => r.id !== this.adjudicationReader.id);
  }

  removeAdjudicator(id: number): void {
    this.adjudicationReader = null;
    this.adjudicationReadersOptions = this.readersList;
    this.adjudicationReadersDataSource.data = [];
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this.validate();
  }

  cancel(): void {
    this.clickCancel.emit({result: 'cancel'});
  }

  submit(): void {
    if (!this.validate()) {
      return;
    }

    const config: PsamrisAdjudicationConfigModel = this.createFlexibleConfig();
    const imagingProjectSubmitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: config,
      level1BatchConfig: this.prepareLevelOneBatchConfigPayload(),
      adjudicationBatchConfig: this.prepareAdjudicationBatchConfigPayload(),
    };
    this.clickSubmit.emit(imagingProjectSubmitEvent);
  }

  prepareAdjudicationBatchConfigPayload(): AutoBatchConfigPayload {
    let configPayload: AutoBatchConfigPayload;
    const adjConf = this.adjudicationBatchConfig;
    if (adjConf) {
      const configId: number = adjConf.id ? adjConf.id : null;
      configPayload = {
        id: configId,
        numberOfReadings: adjConf.numberOfReadings || undefined,
        readingType: ReadingType.ADJUDICATION_READING,
        initiationMethod: adjConf.initiationMethod,
        initiationOption: adjConf.initiationOption,
        batchCount: adjConf.batchCount
      };
    } else {
      configPayload = null;
    }
    return configPayload;
  }

  prepareLevelOneBatchConfigPayload(): AutoBatchConfigPayload {
    let configPayload: AutoBatchConfigPayload;
    const lvl1Conf = this.lvl1BatchConfig;
    if (lvl1Conf) {
      const configId: number = lvl1Conf.id ? lvl1Conf.id : null;
      configPayload = {
        id: configId,
        numberOfReadings: lvl1Conf.numberOfReadings || undefined,
        readingType: ReadingType.LEVEL1_READING,
        initiationMethod: lvl1Conf.initiationMethod,
        initiationOption: lvl1Conf.initiationOption,
        batchCount: lvl1Conf.batchCount,
      };
    } else {
      configPayload = null;
    }
    return configPayload;
  }

  createFlexibleConfig(): PsamrisAdjudicationConfigModel {
    return {
      endpointReadingVersion: this.endpointReadingVersion,
      readingVersion: ReadingVersion.PSAMRIS_ADJUDICATION,
      readingLevel: this.readingLevel,
      readingType: this.readingType,
      modalities: this.modalityType,
      hideVisitHistory: this.hideChronology,
      batchManagement: this.batchManagementForLevelOne,
      adjudicationBatchManagement: this.batchManagementForAdjudicationReaders,
      adjudicationFinalReportWithoutDisagreementRule: this.finalReportNoDisagreementLogic,
      adjudicationFinalReportWithDisagreementRule: this.adjudicationReportLogic,
      adjudicationBlind: this.blindAdjudicationReading,
      adjudicationRule: this.adjudicationRule,
      imageViewerConfiguration: this.imageViewerConfig,
      featuresToScore: this.featuresToScore,
      fingersToScore: this.fingersToScore,
      regionsToScore: this.regionsToScore,
      readers: this.readers.map(r => {
        return {id: r.id};
      }),
      adjudicationReader: this.adjudicationReader.id,
      anatomySelector: this.selectedSequenceLabels.map(l => {
        return {id: l.id};
      })
    };
  }

  onBatchManagementSelected(isAdjBatchManagement: boolean): void {
    if (isAdjBatchManagement) {
      if (this.adjudicationBatchConfig) {
        this.adjudicationBatchConfig.numberOfReadings = null;
      }
    } else {
      if (this.lvl1BatchConfig) {
        this.lvl1BatchConfig.numberOfReadings = null;
      }
    }
  }

  compareReaders(r1: any, r2: any): boolean {
    return r1 && r2 ? r1.id === r2.id : r1 === r2;
  }

  compareModalities(m1: any, m2: any): boolean {
    return m1 && m2 ? m1.name === m2.name : m1 === m2;
  }

  isLevel1ReaderModelValid(): boolean {
    return this.readers.length === 2;
  }

  validate(): boolean {
    return !(this.readingLevelControl.invalid ||
      this.modalityControl.invalid ||
      this.readingTypeControl.invalid ||
      this.hideChronologyControl.invalid ||
      this.anatomySelectorControl.invalid ||
      this.batchManagementForLevelOneControl.invalid ||
      this.imageViewerConfigControl.invalid ||
      this.adjRuleControl.invalid ||
      this.blindAdjReadingControl.invalid ||
      this.reportLogicNoDisagreementControl.invalid ||
      this.reportLogicAdjControl.invalid ||
      this.featuresToScoreControl.invalid ||
      this.fingersToScoreControl.invalid ||
      this.regionsToScoreControl.invalid ||
      this.batchManagementForAdjReadersControl.invalid ||
      !this.isLevel1ReaderModelValid() ||
      !this.adjudicationReader ||
      this.adjudicationReadersDataSource.data.length == 0 ||
      this.batchManagementForAdjudicationReaders && (!this.adjudicationBatchConfig || !this.adjudicationBatchConfig.numberOfReadings) ||
      this.batchManagementForLevelOne && (!this.lvl1BatchConfig || !this.lvl1BatchConfig.numberOfReadings));
  }

}
