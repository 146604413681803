import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as _moment from 'moment';

const moment = _moment;

declare global {
  interface Window {
    grecaptcha: any;
    toastalert: any;
    reCaptchaLoad: () => void
  }
}

@Component({
  selector: 'app-maintenance-page',
  templateUrl: './maintenance-page.component.html',
  styleUrls: ['./maintenance-page.component.css']
})
export class MaintenancePageComponent implements OnInit {
  time: any;

  constructor(private router: Router) { }

  ngOnInit() {

    if( localStorage.getItem('maintenance') ){
      if(true == JSON.parse(localStorage.getItem('maintenance')) ){
        let finish = new Date(localStorage.getItem('maintenanceFinishTime').replace(/['"]+/g, ''));
        this.time = moment(finish, "x").zone(0).format('HH:mm');
        if(window['grecaptcha'] !=null){
          window.grecaptcha.execute(0);
        }
      }
    }
    else{
      //this.router.navigate(["/"]);
      localStorage.clear();
      window.location.href = "/login";
    }
  }

}
