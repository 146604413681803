import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {StudyDashboardModel, StudyModel} from '../_models/ImagingProject/study-model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';

const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL;
// const BASE_URL = 'http://localhost:8080/api';

@Injectable({
  providedIn: 'root'
})
export class ImagingProjectService {

  constructor(private http: HttpClient) {
  }

  getStudy(studyId): Observable<BasicResponse<StudyModel>> {
    return this.http.get<BasicResponse<StudyModel>>(`${BASE_URL}/study/${studyId}/without-rel`);
  }
  
  getStudyForConfig(studyId): Observable<BasicResponse<StudyModel>> {
    return this.http.get<BasicResponse<StudyModel>>(`${BASE_URL}/study/${studyId}`);
  }

  getStudyForVisits(studyId): Observable<BasicResponse<StudyModel>> {
    return this.http.get<BasicResponse<StudyModel>>(`${BASE_URL}/study/${studyId}/with-visits`);
  }


  getModalities() {
    const requestURL = `${BASE_URL}/modality/retrieve-all`;
    return this.http.get(requestURL);
  }

  createPatient(projectId, patient) {
    return this.http.post(`${BASE_URL}/patient/${projectId}`, patient);
  }

  getSiteConfig(siteId) {
    return this.http.get(`${BASE_URL}/site-config/${siteId}`);
  }

  getVisitConfig(visitConfigId = 'retrieve-all'): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/visit-config/${visitConfigId}`);
  }

  getVisitConfigsByIds(visitConfigsIds): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/visit-config/get-visitconfigs-by-ids`, visitConfigsIds);
  }

  getVisitConfigsByIdsBrief(visitConfigsIds): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/visit-config/get-visitconfigs-by-ids/brief`, visitConfigsIds);
  }

  getScanDatesByVisitConfigIds(visitConfigsIds) {
    return this.http.put(`${BASE_URL}/visit-config/get-scan-dates-by-visit-config-ids`, visitConfigsIds);
  }

  getSeriesPreviewUrl(seriesId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/series/preview/${seriesId}`);
  }

  getSeriesPreviewBySeriesIds(seriesIdsAndStudyId: any[]) {
    return this.http.put(`${BASE_URL}/series/preview/get-series-by-ids/optimized`, seriesIdsAndStudyId);
  }

  getSeriesDICOMDIRUrl(seriesId) {
    return this.http.get(`${BASE_URL}/series/dicomdir/${seriesId}`);
  }

  getSeriesDICOMFrameDIRUrl(seriesId, frame) {
    return this.http.get(`${BASE_URL}/series/dicomdir/${seriesId}/${frame}`);
  }

  getSeriesInfo(seriesId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/series/${seriesId}/without-rel`);
  }

  getReadingConfigByStudyIdAndEndpointName(studyId, endPointName) {
    return this.http.get(`${BASE_URL}/reading-config/get-reading-config-by-study-id-and-endpoint-name/${studyId}/${endPointName}`);
  }

  createUnscheduledVisit(patientId, studyId) {
    return this.http.post(`${BASE_URL}/visit-config/create-unscheduled/${patientId}/${studyId}`, null);
  }

  createEarlyTermination(studyId: number, patientId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/visit-config/create-early-termination/${patientId}/${studyId}`;
    return this.http.post<BasicResponse<any>>(url, {});
  }

  getChildSeries(seriesId) {
    return this.http.get(`${BASE_URL}/series/relation/children/${seriesId}/without-rel`);
  }

  getSeriesInstanceUID(seriesIds: number[]) {
    return this.http.put(`${BASE_URL}/series/get-series-by-ids/without-rel`, seriesIds);
  }

  getStudyFullInfoByIds(ids: number[]): Observable<BasicResponse<StudyModel[]>> {
    return this.http.get<BasicResponse<StudyModel[]>>(`${BASE_URL}/study`, {params: {ids: ids.map(id => id.toString())}});
  }

  getStudyiesForDashboardByIds(ids: number[]): Observable<BasicResponse<StudyDashboardModel[]>> {
    return this.http.get<BasicResponse<StudyDashboardModel[]>>(`${BASE_URL}/study/for-dashboard`, {params: {ids: ids.map(id => id.toString())}});
  }

  getLeadtoolsLicence() {
    return this.http.get('https://' + API_VERSION + SERVICE + API_URL + '/licence/leadtools-link');
  }

  getVisitFromSeriesID(seriesId) {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/visit-config/get-by-series-id/${parseInt(seriesId, 10)}/without-rel`);
  }

  getPatientFromSeriesID(seriesId) {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/patient/get-by-series-id/${parseInt(seriesId, 10)}/without-rel`);
  }

  updateNoUploads(studyId: number, visitConfigId: number, noUpload: boolean,
                  comment: string, updateUserId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/visit-config/no-upload/${studyId}`;
    return this.http.put<BasicResponse<any>>(url,
      {id: visitConfigId, noUploads: noUpload, noUploadsComment: comment, updateUserId: updateUserId});
  }

}
