import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActionManagerService } from '../../../../_services/interfaces/viewer/action-manager-service';
export interface Data {
  isCopy: boolean,
  viewerComponent: any
}
var State =
  Object.freeze(
    {
      Copy: { value: 'Copy', text: 'Copy ROI' },
      Delete: { value: 'Delete', text: 'Delete ROIs' }
    }
  );
@Component({
  selector: 'app-roi-copy-delete-dialog',
  templateUrl: './roi-copy-delete-dialog.component.html',
  styleUrls: ['./roi-copy-delete-dialog.component.css']
})
export class ROICopyDeleteDialogComponent implements OnInit {
  public from = '';
  public to = '';
  public state = State.Copy;
  public viewerComponent;

  constructor(
    private actionManagerService: ActionManagerService,
    public dialogRef: MatDialogRef<ROICopyDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: Data) {
    this.state = data.isCopy ? State.Copy : State.Delete;
    this.viewerComponent = data.viewerComponent;
  }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickOk(): void {
    const info = { from: this.from,to:this.to, viewerComponent: this.viewerComponent };
    if (this.state === State.Copy) {
      this.actionManagerService.OnCopyROI(info); 
      this.dialogRef.close();     
    } else if (this.state === State.Delete) {
      this.actionManagerService.OnDeleteROI(info); 
      this.dialogRef.close();
    }
  }

}
