import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {User} from '../_models/user';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSUserVersion;
const SERVICE = 'msuser-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable()
export class UserService {

    constructor(private http: HttpClient) { }

    create(user: any) {
        return this.http.post(requestURL + `/user`, user);
    }

    update(user: any) {
        return this.http.put(requestURL + `/user`, user);
    }

    delete(id: number) {
        return this.http.delete(requestURL + `/user/` + id);
    }

    activate(id: number): Observable<any>  {
        return this.http.put(`${requestURL}/user/activate/${id}`, null);
    }

    deactivate(id: number) {
        return this.http.put(requestURL + `/user/deactivate/` + id, null);
    }

    resetPassword(id: number) {
        return this.http.put(requestURL + `/user/reset-password/` + id, null);
    }

    resetPasswordWithToken(token: string) {
        return this.http.post(requestURL + `/user/reset-user-account-password`, { token: token });
    }

    resetPasswordWithEmail(email: string) {
        return this.http.put(requestURL + `/user/reset-password-with-email`, { emailAddress: email });
    }

    changePassword(id: number, password: string, oldPassword: string) {
        return this.http.put(requestURL + `/user/change-password`, { id: id, password, oldPassword });
    }

    validatePassword(id: number, password: string) {
      return this.http.put(requestURL + `/user/validate-password`, { id: id, password: password });
    }

    authenticateWithUserName(user: any) {
        return this.http.post(requestURL + `/user/authenticate-with-user-name`, user);
    }

    authenticateWithUserNameNoCaptcha(user: any) {
        return this.http.post(requestURL + `/user/authenticate-with-user-name-no-captcha`, user);
    }

    authenticateWithEmail(user: any) {
        return this.http.post(requestURL + `/user/authenticate-with-email-address`, user);
    }

    getEmailUser(email: string) {
        return this.http.get(requestURL + `/user/retrieve-with-email/` + email);
    }

    getById(id: number): Observable<BasicResponse<any>> {
        return this.http.get<BasicResponse<any>>(requestURL + `/user/` + id);
    }

    getByListIds(listIds): Observable<BasicResponse<User[]>> {
      return this.http.put<BasicResponse<User[]>>(requestURL + `/user/get-users-by-ids`, listIds);
    }

    getAll() {
        return this.http.get(requestURL + `/user/retrieve-all`);
    }

    getUsersBySiteIdAndStudyId(siteId: number, studyId: number){
      return this.http.get(requestURL + '/user/get-site-users-by-site-id-and-study-id/' + siteId + '/' + studyId);
    }
}
