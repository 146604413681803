import { Injectable, OnDestroy } from '@angular/core';
import { BroadcastChannel, createLeaderElection, LeaderElector } from 'broadcast-channel';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { skipWhile } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

export interface BroadcastChannelMessage<T> {
    token?: string;
    syncMe?: string;
    syncTabs?: string[];
    data?: T;
}
export interface BroadcastChannelData<T> {
    namespace: string;
    action: string;
    value: T;
}

@Injectable()
export class BroadcastChannelService<T> implements OnDestroy {
    protected myConfigs: any = { webWorkerSupport: false };
    protected myToken = uuidv4();
    protected myDataSubject = new BehaviorSubject<T>(undefined);
    protected mySendDataSubject = new Subject<T>();
    protected myReceiveDataSubject = new Subject<T>();
    protected myIsLeader = new Subject<boolean>();
    protected myBroadcastChannel: BroadcastChannel<BroadcastChannelMessage<T>>;
    protected myElector: LeaderElector;
    protected myChannelName: string;
    protected mySendDataSub: Subscription;
    protected myReceiveDataSub: Subscription;

    constructor() { }

    ngOnDestroy(): void {
        this.shutdown();
    }

    get token(): string {
        return this.myToken;
    }

    get broadcastChannel(): BroadcastChannel {
        return this.myBroadcastChannel;
    }

    get elector(): LeaderElector {
        return this.myElector;
    }

    get isLeader(): Observable<boolean> {
        return this.myIsLeader.asObservable();
    }

    get configs(): Readonly<any> {
        return this.myConfigs;
    }

    get channelName(): string {
        return this.myChannelName;
    }

    start(channelName?: string, configs?: any): void {
        this.myChannelName = channelName || uuidv4();
        this.myConfigs = { ...this.myConfigs, ...(configs || {}) };
        this.myBroadcastChannel = new BroadcastChannel(this.channelName, this.configs);
        this.myElector = createLeaderElection(this.myBroadcastChannel);
        this.myElector.awaitLeadership().then(() => this.myIsLeader.next(true));

        this.mySendDataSub?.unsubscribe();
        this.mySendDataSub = this.mySendDataSubject.subscribe(data => {
            this.postMessage({ data });
            this.updateData(data);
        });

        this.myReceiveDataSub?.unsubscribe();
        this.myReceiveDataSub = this.myReceiveDataSubject.subscribe(data => this.updateData(data));

        this.myElector.broadcastChannel.onmessage = (message) => {
            if (message.syncMe) {
                this.syncTabs([message.syncMe]);
            } else if (!Array.isArray(message.syncTabs) || message.syncTabs.includes(this.token)) {
                this.myReceiveDataSubject.next(message.data);
            }
        };

        setTimeout(() => this.syncMe(), 500);
    }

    shutdown(): void {
        this.mySendDataSub?.unsubscribe();
        this.myReceiveDataSub?.unsubscribe();
        this.myElector?.die().then(() => {
            this.myBroadcastChannel?.close();
        });
    }

    postMessage(message: any): void {
        this.myElector.broadcastChannel.postMessage({ token: this.token, ...message });
    }

    syncMe(): void {
        if (!this.myElector.isLeader) {
            this.postMessage({ syncMe: this.token });
        }
    }

    syncTabs(tabs: string[]): void {
        if (this.myElector.isLeader) {
            this.postMessage({ data: this.myDataSubject.getValue(), syncTabs: tabs });
        }
    }

    updateData(data: T): void {
        if (this.myElector.isLeader) {
            this.myDataSubject.next(data);
        }
    }

    sendData(data: T): void {
        this.mySendDataSubject.next(data);
    }

    receiveData(): Observable<T> {
        return this.myReceiveDataSubject.asObservable().pipe(skipWhile(v => v === undefined));
    }
}
