import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingHEELVersion;
const SERVICE = 'msreadingheel-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/reading/';
// const SERVICE_API_URL = 'http://localhost:8080/api/reading/';

const NO_METHOD = undefined;
const METHOD_INITIATE = 'initiate';
const METHOD_CONTROL_SAMRI_VIEW = 'control-samri-view';
const METHOD_GET_NUMBER_OF_AVAILABLE_READINGS = 'get-number-of-available-readings';
const METHOD_GET_AVAILABLE_READINGS = 'get-available-readings';
const METHOD_COMPLETE = 'complete';
const METHOD_GET_NUMBER_OF_AVAILABLE_ADJUDICATION_READINGS = 'get-number-of-available-adjudication-readings';
const METHOD_GET_AVAILABLE_ADJUDICATION_READINGS = 'get-available-adjudication-readings';
const METHOD_GET_DISAGREEMENT = 'get-disagreement';
const METHOD_GET_ZITELAB_IMAGES = 'get-zitelab-images';
const METHOD_START_READING = 'start';

// const METHOD_UPDATE_READING = 'reading';

@Injectable({
  providedIn: 'root'
})
export class ReadingHeelService {

  constructor(private http: HttpClient) {
  }

  ziteLabReadingInitiation(studyId, readingId) {
    const url = this.buildApiUrl(METHOD_INITIATE, studyId);
    const data = {
      readingId: readingId
    };
    return this.http.post(url, data);
  }

  laodOnSamri(studyId, readingId) {
    const url = this.buildApiUrl(METHOD_CONTROL_SAMRI_VIEW, studyId, readingId);
    return this.http.post(url, null);
  }

  getNumberOfAvailableReadings(studyId, readerId) {
    const url = this.buildApiUrl(METHOD_GET_NUMBER_OF_AVAILABLE_READINGS, studyId, readerId);
    return this.http.get(url);
  }

  getReadingByStudyIdAndReaderId(studyId, readerId) {
    const url = this.buildApiUrl(METHOD_GET_AVAILABLE_READINGS, studyId, readerId);
    return this.http.get(url);
  }

  getNumberOfAvailableAdjudicationReadings(studyId, readerId) {
    const url = this.buildApiUrl(METHOD_GET_NUMBER_OF_AVAILABLE_ADJUDICATION_READINGS, studyId, readerId);
    return this.http.get(url);
  }

  getAdjudicationReadingsByStudyIdAndReaderId(studyId, readerId) {
    const url = this.buildApiUrl(METHOD_GET_AVAILABLE_ADJUDICATION_READINGS, studyId, readerId);
    return this.http.get(url);
  }

  updateReading(studyId, readingId, comment, spentSeconds) {
    const url = this.buildApiUrl(NO_METHOD, studyId);
    const data = {
      readingId: readingId,
      comment: comment,
      spentSeconds: spentSeconds,
    };
    return this.http.put(url, data);
  }

  completeReading(studyId, readingId, comment, spentSeconds) {
    const url = this.buildApiUrl(METHOD_COMPLETE, studyId);
    const data = {
      studyId: studyId,
      readingId: readingId,
      comment: comment,
      spentSeconds: spentSeconds,
    };
    return this.http.put(url, data);
  }

  startReading(studyId, readingId) {
    const url = this.buildApiUrl(METHOD_START_READING, studyId);
    const data = {
      studyId: studyId,
      readingId: readingId,
    };
    return this.http.put(url, data);
  }

  buildApiUrlNoMethod(...params: any[]): string {
    return this.buildApiUrl(NO_METHOD, params);
  }

  getDisagreement(studyId: number, readingId: number) {
    const url = this.buildApiUrl(METHOD_GET_DISAGREEMENT, studyId, readingId);
    return this.http.get(url);
  }

  getZitelabImagesInfo(studyId: number, readingId: number) {
    const url = this.buildApiUrl(METHOD_GET_ZITELAB_IMAGES, studyId, readingId);
    return this.http.get(url);
  }

  buildApiUrl(methodName: String, ...params: any[]): string {
    let url = SERVICE_API_URL;

    let hasMethod = NO_METHOD !== methodName;
    if (hasMethod) {
      url += methodName;
    }

    for (const param of params) {
      if (hasMethod) {
        url += (`/${param}`);
      } else {
        url += param;
        hasMethod = true;
      }
    }
    return url;
  }
}
