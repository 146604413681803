import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingDEMRIQVersion;
const SERVICE = 'msreadingdemriq-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/reading/';
const LOCAL_API_URL = 'http://localhost:8080/api/reading/';

@Injectable({
  providedIn: 'root'
})
export class ReadingDemriqService {

  private deletedAnnObjectsSource = new BehaviorSubject([]);
  deletedAnnObjects = this.deletedAnnObjectsSource.asObservable();

  private deleteLastROISource = new BehaviorSubject<boolean>(false);
  deleteLastROI = this.deleteLastROISource.asObservable();

  private modalSpinnerSource  = new BehaviorSubject<boolean>(true);
   modalSpinner =this.modalSpinnerSource.asObservable();

   private openLTViewerSource = new BehaviorSubject<any>(null);
   openLTViewerData = this.openLTViewerSource.asObservable();

   private fingerJointDetailsSource = new BehaviorSubject<boolean>(null);
   fingerJointDetails = this.fingerJointDetailsSource.asObservable();

   private selectedJointSource = new BehaviorSubject<any>(null);
   selectedJoint = this.selectedJointSource.asObservable();

   private selectedRegionsSource = new BehaviorSubject<any>(null);
   selectedRegions = this.selectedRegionsSource.asObservable();

   private selectedViewerCellSource = new BehaviorSubject<boolean>(null);
   selectedViewerCell = this.selectedViewerCellSource.asObservable();

  constructor(private http: HttpClient) {
  }
  openLTViewer(data:any) {
    this.openLTViewerSource.next(data);
  }
  onOpenFingerJointDetails(state: boolean) {
    this.fingerJointDetailsSource.next(state);
  }
  onSelectedJoint(data: any) {
    this.selectedJointSource.next(data);
  }
  onSelectedRegions(data: any) {
    this.selectedRegionsSource.next(data);
  }
  onChangeViewerSelectedCell(changed: boolean) {
    this.selectedViewerCellSource.next(changed);
  }
  deleteAnnObjectsSource(AnnObjects:any){
    this.deletedAnnObjectsSource.next(AnnObjects)
  }
  deleteLastDrawnROI(state: boolean){
    this.deleteLastROISource.next(state);
  }

  getNumberOfAvailableReadings(studyId, readerId) {
      return this.http.get(`${SERVICE_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
      // return this.http.get(`${LOCAL_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getReadingByStudyIdAndReaderId(studyId, readerId) {
      return this.http.get(`${SERVICE_API_URL}get-available-readings/${studyId}/${readerId}`);
      // return this.http.get(`${LOCAL_API_URL}get-available-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId) {
      return this.http.put(`${SERVICE_API_URL}start/${studyId}/${readingId}`, null);
      // return this.http.put(`${LOCAL_API_URL}start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds) {
      // return this.http.put(`${LOCAL_API_URL}${studyId}/${readingId}`, {spentSeconds});
      return this.http.put(`${SERVICE_API_URL}${studyId}/${readingId}`, {spentSeconds});
  }
  completeReading(studyId, data) {
      // return this.http.put(`${LOCAL_API_URL}complete/${studyId}`, data);
      return this.http.put(`${SERVICE_API_URL}complete/${studyId}`, data);
  }
  saveVisitSingleROI(data:any,studyId:number){
     return this.http.post(`${SERVICE_API_URL}roi/${studyId}`, data);
  }
  saveVisitROIList(data:any[],studyId:number,readingId:number){
    return this.http.post(`${SERVICE_API_URL}roi/list/${studyId}/${readingId}`, data);
  }

  saveVisitSingleVOI(data:any,studyId:number){
    return this.http.post(`${SERVICE_API_URL}VOI/${studyId}`, data);
  }

  saveVisitVOIList(data:any[],studyId:number,readingId:number){
    return this.http.post(`${SERVICE_API_URL}VOI/list/${studyId}/${readingId}`, data);
  }

  saveVisitComment(data:any[],studyId:number){
    return this.http.patch(`${SERVICE_API_URL}visits?studyId=${studyId}`, data);
  }

}
