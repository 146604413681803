import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingAdvancedAnalysisVersion;
const SERVICE = 'msreadingadvanalysis-dot-';
const SERVICE_API_URL = `https://${API_VERSION}${SERVICE}${API_URL}`;

// const SERVICE_API_URL = 'http://localhost:8080/api/reading/';

@Injectable({
  providedIn: 'root'
})
export class ReadingAdvancedAnalysisService {

  constructor(private http: HttpClient) { }

getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<number>> {
  return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/readings/available-count/studyId-${studyId}/readerId-${readerId}`);
}

getReading(studyId, readerId): Observable<BasicResponse<any>> {
  return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/readings/available/studyId-${studyId}/readerId-${readerId}`);
}

startReading(readingId): Observable<BasicResponse<any>> {
  return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/readings/${readingId}/start`, null);
}

updateReading(readingId, spentSeconds): Observable<BasicResponse<any>> {
  return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/readings/${readingId}/log-time`, spentSeconds);
}

completeReading(studyId, reading: any): Observable<BasicResponse<any>> {
  return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/reading/complete/${studyId}`, reading);
}

lockTimepoint(studyId, visitId, model) {
  return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/visits/${visitId}`, model)
}

}
