import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class AnonymizationService {

  constructor(private http: HttpClient) { }

  create(anonymizationMap: any) {
    return this.http.post(requestURL + `/anonymization-map`, anonymizationMap);
  }

  update(anonymizationMap: any) {
    return this.http.put(requestURL + `/anonymization-map`, anonymizationMap);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/anonymization-map/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/anonymization-map/` + id);
  }

  getAll() {
    return this.http.get(requestURL + `/anonymization-map/retrieve-all`);
  }

  getAllEnabled() {
    return this.http.get(requestURL + `/anonymization-map/get-enabled`);
  }
}
