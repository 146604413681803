import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {Store} from '@ngxs/store';
import {ToastService} from '../../../_services/internal/toast.service';
import {ActivatedRoute, Router} from '@angular/router';
import {SetPageHeaderTitle, SetSelectedProject} from '../../../core/data-management/actions/projects.action';
import {VisitType} from '../../../_models/ImagingProject/patient-model';
import {FormMode} from '../../../core/constants/form-mode';
import {StudyModel} from '../../../_models/ImagingProject/study-model';
import {SiteConfigService} from '../../../_services';
import {ResponseCode} from '../../../core/constants/response-code';
import {timeUnitToDaysCount} from '../../../util/time-unit/time-unit-util';
import {PatientShortModel, SiteConfigShortModel, VisitsShortModel} from '../../../_models/ImagingProject/site-config-model';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {map} from 'rxjs/operators';
import {JwtUtilService} from "../../../_helpers/jwt-util.service";
import {of} from "rxjs";


const VISIT_SUB_COL_SUFFIX = '-sub';

@Component({
  selector: 'app-images-repository',
  templateUrl: './images-repository.component.html',
  styleUrls: ['./images-repository.component.css']/*,
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*', minHeight: '*'})),
      transition('expanded <=> collapsed', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]*/
})
export class ImagesRepositoryComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {read: MatSort, static: true}) sort: MatSort;

  constructor(private dialog: MatDialog,
              private store: Store,
              private route: ActivatedRoute,
              private toastService: ToastService,
              private siteConfigService: SiteConfigService,
              private jwtUtilService: JwtUtilService,
              private router: Router,
              private fb: FormBuilder) {
  }

  study: StudyModel;
  studyId: number;

  readonly formModes = FormMode;

  dataSource: MatTableDataSource<PatientListItem>;
  expandedElement: PatientListItem | null;

  patientsData: PatientListItem[];

  visitColumns: string[] = [];
  visitSubColumns: string[] = [];
  visitConfigs = [];
  skipVisitIds = [];

  filterInputTimeout;
  filterForm: FormGroup | { controls: FilterFormModel };

  siteConfigs: SiteConfigShortModel[] = [];

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Uploads'));
    this.store.dispatch(new SetSelectedProject(this.study as StudyModel));
    this.studyId = parseInt(this.route.snapshot.params.projectId, 10);
    this.initFilterForm();
    this.initSiteConfigs().subscribe(() => {
      this.initSkipVisits().subscribe(() => {
        this.initPatientsTable();
      });
    });
  }

  applyPatientFilter(): void {
    clearTimeout(this.filterInputTimeout);
    this.filterInputTimeout = setTimeout(() => {
      this.applyFilter();
    }, 1000);
  }

  applyFilter() {
    this.dataSource.filter = JSON.stringify(this.filterForm['value']);
  }

  compareSelectedSites(siteConfig1: number, siteConfig2: number) {
      return siteConfig1 === siteConfig2;
  }

  openImageRepository(patient: PatientListItem, visit: PatientListVisitItem) {
    this.router.navigate([`/operationdashboard/sponsor/${this.studyId}/${patient.id}/${visit.visitConfigId}`]).then(r => {
    });
  }

  private initSiteConfigs() {
    const projectId = this.getCurrentStudyId();
    return this.siteConfigService.getSiteConfigsByStudyIdForDashboard(projectId).pipe(map(resp => {
      if (resp.responseCode === ResponseCode.OK) {
        this.siteConfigs = resp.data;
      } else {
        this.toastService.respError(resp);
      }
    }, () => this.toastService.unexpectedError()));
  }

  private initSkipVisits() {
    const projectId = this.getCurrentStudyId();
    const isSponsorDashboard = this.jwtUtilService.isUserHasActivity('dashboard.access.sponsor', projectId);
    if (isSponsorDashboard) {
      return this.siteConfigService.getVisitSettingsByStudyId(this.studyId).pipe(map(resp => {
        if (resp.responseCode === ResponseCode.OK) {
          this.skipVisitIds = resp.data.filter(v => v.visibleForSponsor === false).map(config => config.visit.id);
        } else {
          this.toastService.respError(resp);
        }
      }));
    } else {
      return of(undefined);
    }
  }

  private initPatientsTable() {
    this.initTableRowsData();
    this.initVisitColumnNames();

    this.dataSource = new MatTableDataSource<PatientListItem>();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = (data, filter) => this.dataSourceFilterPredicate(data, filter);
    this.dataSource.data = this.patientsData;
  }

  private initVisitColumnNames() {
    const numCols = this.getMaxCountOfVisits();
    this.visitColumns = [];
    this.visitSubColumns = [];
    for (let i = 0; i < numCols; i++) {
      const uuid = UUID.generate();
      this.visitColumns.push(uuid);
      this.visitSubColumns.push(uuid + VISIT_SUB_COL_SUFFIX);
    }
  }

  private initFilterForm() {
    this.filterForm = this.fb.group({
      patient: new FormControl(null),
      siteConfigId: new FormControl(null)
    });
    this.filterForm.controls.siteConfigId.valueChanges.subscribe(() => {
      this.filterForm.controls.patient.setValue(null);
    });
  }

  private initTableRowsData() {
    const patientsRowData = [];
    this.siteConfigs.forEach(siteConfig => {
      const patientsRowData_ = siteConfig.patients
        .filter(this.isPatientSuitable)
        .map(patient => this.mapPatientToRowData(patient, siteConfig));
      patientsRowData.push(...patientsRowData_);
    });
    this.patientsData = patientsRowData;
  }

  private mapPatientToRowData(patient: PatientShortModel, siteConfig: SiteConfigShortModel): PatientListItem {
    const treatments = this.getTreatmentsForPatient(patient);
    return {
      id: patient.id,
      patient: patient.patientCode,
      siteConfigId: siteConfig.id,
      visits: this.mapPatientVisitsToRowData(patient, treatments),
      treatments: treatments
    };
  }

  private mapPatientVisitsToRowData(patient: PatientShortModel, treatments: TreatmentModel[]): PatientListVisitItem[] {
    const visits = this.getSortedVisitConfigs(patient);
    return visits.map(visit => {
      return {
        visitId: visit.id,
        timepoint: visit.visitName,
        visitConfigId: visit.visitConfigId,
        studiesUploadedCount: visit.generatedSeriesVersionCount,
        studyDate: visit.scanDate, // value is taken min from DB
        treatmentsParticipation: treatments.map(treatment => treatment.visitConfigIds.includes(visit.visitConfigId))
      } as PatientListVisitItem;
    });
  }

  private getSortedVisitConfigs(patient: PatientShortModel): VisitsShortModel[] {
    const baseline = this.getPatientsVisitConfigsOfType(patient, VisitType.BASELINE).sort(this.compareVisitConfigs);
    const regular = this.getPatientsVisitConfigsOfType(patient, VisitType.REGULAR).sort(this.compareVisitConfigs);
    const unscheduledRegular = this.getPatientsVisitConfigsOfType(patient, VisitType.UNSCHEDULED_REGULAR).sort(this.compareVisitConfigs);
    const unscheduledEarlyTermination = this.getPatientsVisitConfigsOfType(patient, VisitType.UNSCHEDULED_EARLY_TERMINATION).sort(this.compareVisitConfigs);
    return baseline.concat(regular).concat(unscheduledRegular).concat(unscheduledEarlyTermination);
  }

  private compareVisitConfigs(vc1: VisitsShortModel, vc2: VisitsShortModel): number {
    const baseDaysCount1 = timeUnitToDaysCount(vc1.durationTimeUnit);
    const baseDaysCount2 = timeUnitToDaysCount(vc2.durationTimeUnit);
    if (baseDaysCount1 * vc1.durationTimeValue > baseDaysCount2 * vc2.durationTimeValue) {
      return 1;
    } else if (baseDaysCount1 * vc1.durationTimeValue < baseDaysCount2 * vc2.durationTimeValue) {
      return -1;
    } else {
      return 0;
    }
  }

  private getPatientsVisitConfigsOfType(patient: PatientShortModel, visitType: VisitType): VisitsShortModel[] {
    return this.filterSkipVisits(patient.visits.filter(visit => visit.visitType === visitType));
  }

  private dataSourceFilterPredicate(data: PatientListItem, filterStr: string) {
    const filter: FilterFormModel = JSON.parse(filterStr);
    let valid = true;
    if (this.isPatientFilterValid(filter.patient)) {
      if (!data.patient.toLowerCase().includes(filter.patient.trim().toLowerCase())) {
        valid = false;
      }
    }
    if (!!filter.siteConfigId) {
      if (data.siteConfigId !== parseInt(filter.siteConfigId, 10)) {
        valid = false;
      }
    }
    return valid;
  }

  private filterSkipVisits(visits: Array<any>): VisitsShortModel[] {
    return visits.filter(visit => !this.skipVisitIds.includes(visit.id));
  }

  private getMaxCountOfVisits() {
    const visitConfigsCounts = this.siteConfigs.flatMap(siteConfig => siteConfig.patients)
      .filter(this.isPatientSuitable)
      .map(patient => this.filterSkipVisits(patient.visits).length);
    return Math.max.apply(Math, visitConfigsCounts);
  }

  private isPatientFilterValid(filter: string) {
    return !!filter && filter.length >= 2;
  }

  private getCurrentStudyId() {
    return parseInt(this.route.snapshot.params.projectId, 10);
  }

  private getTreatmentsForPatient(patient: PatientShortModel): TreatmentModel[] {
    const treatments = [];
    const treatment = {
      id: 1,
      name: 'Treatment (status)',
      visitConfigIds: patient.visits.map(m => m.visitConfigId)
    } as TreatmentModel;
    treatments.push(treatment);
    return treatments;
  }

  private isPatientSuitable(patient: PatientShortModel): boolean {
    return !patient.phantomData && !!patient.visits?.find(visitConfig => !!visitConfig.uploadDate);
  }
}

interface PatientListItem {
  id: number;
  siteConfigId: number;
  patient: string;
  visits: PatientListVisitItem[];
  treatments: TreatmentModel[];
}

interface PatientListVisitItem {
  visitId: number;
  visitConfigId: number;
  timepoint: string;
  studiesUploadedCount: number;
  studyDate: Date;
  treatmentsParticipation: boolean[];
}

interface FilterFormModel {
  patient;
  siteConfigId;
}

interface TreatmentModel {
  id: number;
  name: string;
  visitConfigIds: number[];
}
