import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const SERVICE_API_URL = 'https://' + environment.MSReadingNovadip5Version + 'msreadingnovadip5-dot-' + environment.apiUrl + '/reading';
// const SERVICE_API_URL = 'http://localhost:8080/api/reading';

@Injectable({
  providedIn: 'root'
})
export class ReadingNovadip5Service {

  constructor(private http: HttpClient) {
  }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getReadingByStudyIdAndReaderId(studyId, readerId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}/get-available-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/${studyId}/${readingId}`, {spentSeconds});
  }

  completeReading(studyId, data): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/complete/${studyId}`, data);
  }

    saveReading(studyId, data): Observable<BasicResponse<any>> {
        return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}/save/${studyId}`, data);
    }

}
