import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MoaksVisitModel} from '../../../../../_models/MOAKS/moaks-visit-model';
import {MoaksMeniscalExtrusionScoringModel} from '../../../../../_models/MOAKS/moaks-meniscal-extrusion-scoring-model';
import {MoaksMeniscalMorphologyScoringModel} from '../../../../../_models/MOAKS/moaks-meniscal-morphology-scoring-model';
import { SharedService } from '../../moaks-basic-form/shared.service';

@Component({
    selector: 'app-moaks-meniscal-scoring-form',
    templateUrl: './moaks-meniscal-scoring-form.component.html',
    styleUrls: ['./moaks-meniscal-scoring-form.component.css']
})
export class MoaksMeniscalScoringFormComponent implements OnInit, OnDestroy {

    menExtrusions = ['0', '1', '2', '3', 'U'];
    menSignalTears = ['0', '1', '2', '3', '4', '5', '6', '7', 'U'];
    menRootTears = ['0', '1', 'U'];
    menCysts = ['0', '1', 'U'];
    menHyps = ['0', '1', 'U'];
    formExtrusion: FormGroup;
    formMorphology: FormGroup;
    tableDataSourceExtrusion: MatTableDataSource<MoaksMeniscalExtrusionScoringModel>;
    tableDataSourceMorphology: MatTableDataSource<MoaksMeniscalMorphologyScoringModel>;
    tableColumnsExtrusion = ['Name', 'Extrusion'];
    tableColumnsMorphology = ['Name', 'SignalTear', 'RootTear', 'Cyst', 'Hyp'];
    @Input()
    get visit(): MoaksVisitModel { return this._visit; }
    set visit(visit: MoaksVisitModel) {
        this._visit = visit;
        if (this.formExtrusion && this.formMorphology) { // ngOnInit() will init first time
            this.init();
        }
    }
    private _visit: MoaksVisitModel;

    @Input() uid: string;

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
    }

    ngOnInit(): void {
        this.tableDataSourceExtrusion = new MatTableDataSource<MoaksMeniscalExtrusionScoringModel>();
        const scoringExtrusionFormControl = this.fb.array([]);
        this._visit.meniscalExtrusionScorings.forEach((scoring) => {
            scoringExtrusionFormControl.push(this.fb.group({
                id: ['', [Validators.required]],
                code: ['', [Validators.required]],
                region: ['', [Validators.required]],
                extrusion: ['', [Validators.required]]
            }));
        });
        this.formExtrusion = this.fb.group({
            scorings: scoringExtrusionFormControl
        });

        this.tableDataSourceMorphology = new MatTableDataSource<MoaksMeniscalMorphologyScoringModel>();
        const scoringMorphologyFormControl = this.fb.array([]);
        this._visit.meniscalMorphologyScorings.forEach((scoring) => {
            scoringMorphologyFormControl.push(this.fb.group({
                id: ['', [Validators.required]],
                code: ['', [Validators.required]],
                region: ['', [Validators.required]],
                signalTear: ['', [Validators.required]],
                rootTear: ['', [Validators.required]],
                cyst: ['', [Validators.required]],
                hyp: ['', [Validators.required]]
            }));
        });
        this.formMorphology = this.fb.group({
            scorings: scoringMorphologyFormControl
        });

        this.init();
        this.fillExtrusionEmptyCellsWithZero();
        this.fillMorphologyEmptyCellsWithZero();

        this.sharedService.sync(this.uid, (rowIndex, valueName, value) => {
          if (valueName === 'extrusion') {
            this._visit.meniscalExtrusionScorings[rowIndex][valueName] = value;
            if (this.formExtrusion) {
              (this.formExtrusion.get('scorings') as FormArray).controls[rowIndex].get(valueName).setValue(value);
            }
          } else {
            this._visit.meniscalMorphologyScorings[rowIndex][valueName] = value;
            if (this.formMorphology) {
              (this.formMorphology.get('scorings') as FormArray).controls[rowIndex].get(valueName).setValue(value);
            }
          }
        });
    }

    ngOnDestroy(): void {
        this.setSubmitData();
        this.sharedService.messageSource.next(null);
    }

    shouldDisable(){
      return (this._visit.status ||'') .toLowerCase() === 'done';
    }

    init(): void {
        this.tableDataSourceExtrusion.data = this._visit.meniscalExtrusionScorings;

        let index = 0;
        ((this.formExtrusion.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.tableDataSourceExtrusion.data[index];
            formGroup.get('id').setValue(scoring.id);
            formGroup.get('code').setValue(scoring.code);
            formGroup.get('region').setValue(scoring.region);
            formGroup.get('extrusion').setValue(scoring.extrusion);
            if (this.shouldDisable()) {
              formGroup.disable();
            }
            index++;
        });
        this.tableDataSourceMorphology.data = this._visit.meniscalMorphologyScorings;

        index = 0;
        ((this.formMorphology.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.tableDataSourceMorphology.data[index];
            formGroup.get('id').setValue(scoring.id);
            formGroup.get('code').setValue(scoring.code);
            formGroup.get('region').setValue(scoring.region);
            formGroup.get('signalTear').setValue(scoring.signalTear);
            formGroup.get('rootTear').setValue(scoring.rootTear);
            formGroup.get('cyst').setValue(scoring.cyst);
            formGroup.get('hyp').setValue(scoring.hyp);
            if (this.shouldDisable()) {
              formGroup.disable();
            }
            index++;
        });
    }

    fillExtrusionEmptyCellsWithZero(): void {
        ((this.formExtrusion.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            if (!formGroup.get('extrusion').value) {
                formGroup.get('extrusion').setValue('0');
            }
        });
    }

    fillMorphologyEmptyCellsWithZero(): void {
        ((this.formMorphology.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            if (!formGroup.get('signalTear').value) {
                formGroup.get('signalTear').setValue('0');
            }
            if (!formGroup.get('rootTear').value) {
                formGroup.get('rootTear').setValue('0');
            }
            if (!formGroup.get('cyst').value) {
                formGroup.get('cyst').setValue('0');
            }
            if (!formGroup.get('hyp').value) {
                formGroup.get('hyp').setValue('0');
            }
        });
    }

    reset(): void {
        ((this.formExtrusion.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            formGroup.get('extrusion').reset();
            formGroup.get('id').reset();
            formGroup.get('code').reset();
            formGroup.get('region').reset();
        });
        ((this.formMorphology.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            formGroup.get('signalTear').reset();
            formGroup.get('rootTear').reset();
            formGroup.get('cyst').reset();
            formGroup.get('hyp').reset();
            formGroup.get('id').reset();
            formGroup.get('code').reset();
            formGroup.get('region').reset();
        });
    }

    setSubmitData() {
        ((this.formExtrusion.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.visit.meniscalExtrusionScorings.find(value => value.id === formGroup.get('id').value);
            scoring.extrusion = formGroup.get('extrusion').value;
        });
        ((this.formMorphology.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.visit.meniscalMorphologyScorings.find(value => value.id === formGroup.get('id').value);
            scoring.signalTear = formGroup.get('signalTear').value;
            scoring.rootTear = formGroup.get('rootTear').value;
            scoring.cyst = formGroup.get('cyst').value;
            scoring.hyp = formGroup.get('hyp').value;
        });
    }

    duplicateValue(rowIndex, item) {
      this.setSubmitData();
      const scoring = item === 'extrusion' ? this._visit.meniscalExtrusionScorings : this._visit.meniscalMorphologyScorings;
      const val = scoring[rowIndex][item];
      const uidIndex = this.uid.replace(/\D+/g, '');
      this.sharedService.messageSource.next([rowIndex, item, val, uidIndex]);
    }
}
