import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class SeriesService {

  constructor(private http: HttpClient) { }

  updateLabel(projectId, seriesId, labelName) {
    let data = {
      id: seriesId,
      label: labelName,
    };
    return this.http.put(requestURL + `/series/`+projectId, data);
  }

  getSeriesByPatientIdAndVisitId(patientId: number, visitId: number) {
    return this.http.get(requestURL + '/series/' + patientId + '/' + visitId);
  }

  getSeriesByIds(ids: number[]): Observable<any> {
    return this.http.put(`${requestURL}/series/get-series-by-ids/without-rel`, ids);
  }

  getSeriesByVisitConfigId(visitConfigId: number): Observable<any> {
    return this.http.get(`${requestURL}/series/by-visit-config/${visitConfigId}`);
  }

}
