import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {BatchInitiationMethod} from '../../../core/constants/BatchLogic/batch-initiation-method';
import {BatchInitiationOption} from '../../../core/constants/BatchLogic/batch-initiation-option';
import {BatchReaderOption} from '../../../core/constants/BatchLogic/batch-reader-option';
import {BatchConfigModel} from '../../../_models/BatchLogic/batch-config-model';
import {ReadingType} from '../../../core/constants/reading-type';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';

export interface AutoCreationBatchDialogData {
 // reading : string;
  readingType: ReadingType;
  configInput: BatchConfigModel;
  readonlyDialog?: boolean;
}

@Component({
  selector: 'app-auto-creation-batch-config',
  templateUrl: './auto-creation-batch-config-dialog.component.html',
  styleUrls: ['./auto-creation-batch-config-dialog.component.css']
})
export class AutoCreationBatchConfigDialogComponent implements OnInit {

  readonly initiationMethods = BatchInitiationMethod;
  readonly initiationOptions = BatchInitiationOption;
  readonly readerOptions = BatchReaderOption;
  readonly readingTypes = ReadingType;

  numberOfReadingsFormControl: FormControl = new FormControl('', [Validators.required, Validators.min(1), Validators.max(99999)]);
  patientFormControl: FormControl = new FormControl('', [Validators.required]);
  visitFormControl: FormControl = new FormControl('', [Validators.required]);
  readerFormControl: FormControl = new FormControl('', [Validators.required]);
  initMethodFormControl: FormControl = new FormControl('', [Validators.required]);
  initOptionFormControl: FormControl = new FormControl('', [Validators.required]);
  batchCountFormControl: FormControl = new FormControl('', [Validators.required]);

  patient = 0;
  visitConfigId = 0;

  readersOption: BatchReaderOption;

  batchConfig: BatchConfigModel;
  initialBatchConfig: BatchConfigModel;

  readonlyDialog: boolean;

  constructor(public dialogRef: MatDialogRef<AutoCreationBatchConfigDialogComponent>, public dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: AutoCreationBatchDialogData) {
  }

  ngOnInit() {
    if (this.data.configInput) {
      this.batchConfig = this.data.configInput;
    } else {
      this.batchConfig = {
        id: null,
        numberOfReadings: null,
        initiationMethod: BatchInitiationMethod.MANUALLY,
        initiationOption: BatchInitiationOption.BY_USER_ONLY,
        batchCount: '',
        studyId: null,
        endpoint: null,
        flexibleConfigId: null,
        readingType: this.data.readingType
      };
    }
    this.initialBatchConfig = {...this.batchConfig};
    this.readonlyDialog = !!this.data.readonlyDialog && this.data.readonlyDialog;
  }

  onInitiationMethodSelect(event: MatSelectChange): void {
    if (event.value === BatchInitiationMethod.MANUALLY) {
      this.batchConfig.initiationOption = BatchInitiationOption.BY_USER_ONLY;
      this.batchConfig.batchCount = null;
    } else {
      this.batchConfig.initiationOption = BatchInitiationOption.BY_QUANTITY;
      this.batchConfig.batchCount = null;
    }
  }

  onInitiationOptionSelect(event: MatSelectChange): void {
    if (event.value === BatchInitiationOption.BY_QUANTITY && this.batchConfig.numberOfReadings) {
      this.batchConfig.batchCount = this.batchConfig.numberOfReadings.toString();
    } else {
      this.batchConfig.batchCount = null;
    }
  }

  closeClick(): void {
    this.dialogRef.close();
  }

  saveClick(): void {
    if (!this.validate()) {
      return;
    }
    if (this.batchConfig.id === null) {
      this.dialogRef.close(this.batchConfig);
      return;
    }
    let message = "Automation will be applied for newly created batches. Are you sure?";
    const confirmDialogRef = this.dialog.open(MessageDialogComponent, {
      height: '180px',
      width: '500px',
      disableClose: true,
      data: {title:"Confirm changes", message: message, showOk:true, showCancel: true, textOk: "Yes", textCancel: "No", html :false}
    });

    confirmDialogRef.afterClosed().subscribe(result => {
      if(result == 'ok'){
        this.dialogRef.close(this.batchConfig);
      }
    });
  }

  onNumberOfReadingsChange(value) {
    if (value) {
      this.batchConfig.batchCount = value.toString();
    }
  }

  validate(): boolean {
    return !(this.numberOfReadingsFormControl.invalid ||
      this.initMethodFormControl.invalid ||
      (this.batchConfig.initiationMethod === BatchInitiationMethod.AUTO && this.initOptionFormControl.invalid) ||
      (this.batchConfig.initiationMethod === BatchInitiationMethod.AUTO &&
        this.batchConfig.initiationOption === BatchInitiationOption.BY_DATE_AND_TIME &&
        this.batchCountFormControl.invalid));
  }

}
