import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SequenceLabelModel} from '../../../../_models/ImagingProject/sequence-label-model';
import {MatSelectChange} from '@angular/material/select';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-anatomy-selector',
  templateUrl: './anatomy-selector.component.html',
  styleUrls: ['./anatomy-selector.component.css']
})
export class AnatomySelectorComponent implements OnInit, OnChanges {

  @Input() availableSequenceLabels: SequenceLabelModel[];
  @Input() selectedSequenceLabels: SequenceLabelModel[];
  @Input() disabled: boolean;
  @Input() formControl: FormControl;
  @Output() onSequenceLabelsSelect = new EventEmitter<SequenceLabelModel[]>();

  selectedLabels: SequenceLabelModel[] = [];

  constructor() {
  }

  resolveSelectedSequenceLabels(e: MatSelectChange): SequenceLabelModel[] {
    return e.value;
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedSequenceLabels) {
      this.selectedLabels = changes.selectedSequenceLabels.currentValue;
    }
  }

  compareSequences(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }
}
