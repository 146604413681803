import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {JSW_OARSI_ReadingModel} from '../../../../_models/JSW/jsw-reading-model';
import {ImageViewerConfigurations, JSW_OARSI_BasicConfigModel} from '../../../../_models/ImagingProject/JSW/jsw-basic-config-model';
import {ReadingConfigFlexibleService} from '../../../../_services';
import {Observable} from 'rxjs';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {map} from 'rxjs/operators';
import {ToastService} from '../../../../_services/internal/toast.service';
import {JSW_OARSI_VisitModel} from '../../../../_models/JSW/jsw-visit-model';
import * as lodash from 'lodash';
import { OasriReadingKneeFormComponent } from '../oarsi-reading-knee-form/oasri-reading-knee-form/oasri-reading-knee-form.component';
import { ReadingOarsiService } from 'src/app/_services/reading-oarsi.service';
import { ReadingFormScoring, ReadingFormScoringComponent } from '../../reading-form-scoring.component';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { SetPageHeaderTitle } from 'src/app/core/data-management/actions/projects.action';

interface DynamikaAIResultsTableRow {
  timepoint: string;
  visitId: number;
}

@Component({
  selector: 'app-oarsi-reading-form-basic',
  templateUrl: './oarsi-reading-form-basic.component.html',
  styleUrls: ['./oarsi-reading-form-basic.component.css']
})
export class OarsiReadingFormBasicComponent extends ReadingFormScoringComponent  implements OnInit, ReadingFormScoring<JSW_OARSI_ReadingModel>  {

  @Input()
  switchSubmitBtnDisabledSubject;

  @Input()
  viewerEnabledSubject;

  @Input()
  currentReadingSubject;

  reading: JSW_OARSI_ReadingModel;
  readingConfig: JSW_OARSI_BasicConfigModel;
  currentReadingSubjectSubscription;

  questionsForm;
  kneeFormArray;
  clonedReading;
  commentControl: FormControl = new FormControl('');
  imageQualityControl : FormGroup = new FormGroup({});
  wholeReadingRejection : FormControl = new FormControl(false);
  dynamikaAIResultsTableRows: DynamikaAIResultsTableRow[];
  dynamikaAIResultsTableDisplayedColumns = ['visit_name', 'report'];
  changeCompleted: boolean = true;
  dataloaded: boolean = false;
  
  constructor(private fb: FormBuilder,
    private readingService: ReadingOarsiService,
    private readingConfigService: ReadingConfigFlexibleService,
    private toastService: ToastService,
    private store: Store,
    private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.setupFormTitle()
    this.subscribeReading();
  }

  clearForm() {
    this.checkCurrentReadingSubscription();
  }

  getEndpointName(): string {
    return 'OARSI';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
      return this.readingService.getReading(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    if (justSaveTime)
      return this.readingService.updateReading(studyId, readingId, data.spentSeconds);
    return this.skipForm(studyId, data.spentSeconds);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.submitForm(studyId, data.spentSeconds);
  }

  submitForm(studyId: number, spentSeconds: number): Observable<BasicResponse<any>> {
    if (this.reading.visits.find(v => v.scoring === null && !v.noUploads) === undefined) {
      this.applyFormValuesToCurrentReading(true);
    }
    this.reading.timeSpent = spentSeconds;
    this.reading.comment = this.commentControl.value;
    this.reading.isRejected = this.wholeReadingRejection.value;
    return this.readingService.completeReading(studyId, this.reading)
      .pipe(map(resp => this.handleCompleteReadingResponse(resp)));
  }

  skipForm(studyId: number, spentSeconds: number): Observable<BasicResponse<any>> {
    if (this.reading.visits.find(v => v.scoring === null && !v.noUploads) === undefined) {
      this.applyFormValuesToCurrentReading(false);
    }
    this.reading.timeSpent = spentSeconds;
    this.reading.comment = this.commentControl.value;
    this.reading.isRejected = this.wholeReadingRejection.value;
    this.updateReadingNavigator(this.reading);
    return this.readingService.updateReadingWithSaveAllData(studyId, this.reading)
      .pipe(map(resp => this.handleUpdateSkipReadingResponse(resp)));
  }

  updateReadingNavigator(reading: JSW_OARSI_ReadingModel) {
    this.readingListUpdatedSubject.next(reading);
  }

  initForm(): void {
    this.initImageQualityControl();
    this.initQuestionsForm();
    this.initDynamikaAIResultsTable();
    this.initKneeFormArray();
    this.initCommentControl();
    this.initSubmitButtonStatusListener();
    this.dataloaded = true;
  }

  onDownloadMedicalReport(visitId: number): void {
    const currentVisit = this.reading.visits.find(cVisit => cVisit.id === visitId);
    window.open(currentVisit.medicalReportLink);
  }

  private subscribeReading(): void {
    this.currentReadingSubjectSubscription = this.currentReadingSubject.subscribe(async reading => {
      this.reading = reading;
      this.readingConfig = await this.getReadingConfig(reading);
      this.setupViewerMode(this.readingConfig);
      this.initForm();
    });
  }

  private async getReadingConfig(reading: JSW_OARSI_ReadingModel): Promise<JSW_OARSI_BasicConfigModel> {
    const configResp = await this.readingConfigService.getById(reading['flexibleConfigId']).toPromise();
    return configResp.data.config;
  }

  private initKneeFormArray(): void {
    this.kneeFormArray = OasriReadingKneeFormComponent.generateKneeFormArray(this.reading, this.fb);
    this.clonedReading = lodash.cloneDeep(this.reading);
  }

  private initQuestionsForm(): void {
    const questionsForm = this.fb.array([]);
    this.reading.visits.filter(currentVisit => !currentVisit.noUploads).forEach(currentVisit => {
      const questionForm = this.generateQuestionFromForVisit(currentVisit);
      questionsForm.push(questionForm);
    });
    this.questionsForm = questionsForm;
  }

  private generateQuestionFromForVisit(currentVisit: JSW_OARSI_VisitModel): FormGroup {
    return this.fb.group({
      visitId: new FormControl(currentVisit.id, [Validators.required]),
      visitName: new FormControl(currentVisit.timepoint, [Validators.required]),
      question1: new FormControl(currentVisit.jsmCorrectKnee !== null ? currentVisit.jsmCorrectKnee : true, [Validators.required]),
      question2: new FormControl(currentVisit.jsmAppropriate !== null ? currentVisit.jsmAppropriate : true, [Validators.required]),
    });
  }

  private initCommentControl(): void {
    this.commentControl = new FormControl(this.reading.comment, this.reading.isRejected ? [Validators.required] : []);
    this.wholeReadingRejection = new FormControl(this.reading.isRejected, []);
    this.readingRejection({ value: this.reading.isRejected })
  }

  private initImageQualityControl(): void {
    this.imageQualityControl = new FormGroup({
      imageQualityAssessment: new FormControl(this.reading.imageQualityAssessment, [Validators.required]),
      imageQualityAssessmentComment: new FormControl(this.reading.imageQualityAssessmentComment, [])
    });
    this.imageQualityControl.valueChanges.subscribe(value => {
      this.reading.imageQualityAssessment = value.imageQualityAssessment;
      this.reading.imageQualityAssessmentComment = value.imageQualityAssessmentComment
    })
 
  }

  private initSubmitButtonStatusListener() {
    const isReadingFormValid = () => this.questionsForm.valid && this.kneeFormArray.valid && this.commentControl.valid && this.imageQualityControl.valid;
    const switchSubmitButton = () => this.switchSubmitBtnDisabledSubject.next(!isReadingFormValid());

    this.questionsForm.statusChanges.subscribe(switchSubmitButton);
    this.kneeFormArray.statusChanges.subscribe(switchSubmitButton);
    this.commentControl.statusChanges.subscribe(switchSubmitButton);
    this.imageQualityControl.statusChanges.subscribe(switchSubmitButton);
    switchSubmitButton();
  }

  private checkCurrentReadingSubscription(): void {
    if (!this.isCurrentReadingSubjectSubscribed()) {
      this.subscribeReading();
    }
  }

  private isCurrentReadingSubjectSubscribed(): boolean {
    return this.currentReadingSubject.observers.length !== 0;
  }

  private setupViewerMode(readingConfig: JSW_OARSI_BasicConfigModel) {
    if (readingConfig.imageViewerConfiguration === ImageViewerConfigurations.eCRF_MODE_ONLY) {
      this.trySetViewerEnabled(false);
    } else if (readingConfig.imageViewerConfiguration === ImageViewerConfigurations.DEFAULT) {
      this.trySetViewerEnabled(true);
    }
  }

  private trySetViewerEnabled(mode: boolean): void {
    if (!!this.viewerEnabledSubject) {
      this.viewerEnabledSubject.next(mode);
    }
  }

  private handleCompleteReadingResponse(resp: BasicResponse<JSW_OARSI_ReadingModel>): BasicResponse<JSW_OARSI_ReadingModel> {
    if (resp.responseCode !== 200) {
      this.toastService.error('Reading form is not submitted', 'There are some errors on the form must be resolved');
    } else {
      this.toastService.success('Reading form has been submitted successfully', 'The reading is completed. Report is available.');
    }
    return resp;
  }

  private handleUpdateSkipReadingResponse(resp: BasicResponse<JSW_OARSI_ReadingModel>): BasicResponse<JSW_OARSI_ReadingModel> {
    if (resp.responseCode !== 200) {
      this.toastService.error('Reading form is not updated', 'There are some errors on the form must be resolved');
    } else {
      this.toastService.success('Reading form has been updated successfully');
    }
    return resp;
  }

  private applyFormValuesToCurrentReading(isSubmitted: boolean): void {
    this.reading.visits.filter(currentVisit => !currentVisit.noUploads).forEach(currentVisit => {
      this.applyQuestionFormForCurrentVisit(currentVisit);
      this.applyKneeFormForCurrentVisit(currentVisit, isSubmitted);
    });
  }

  private applyKneeFormForCurrentVisit(currentVisit: JSW_OARSI_VisitModel, isSubmitted: boolean) {
    const rejecttionAndSkip = this.wholeReadingRejection.value && !isSubmitted;
    const currentKneeForm = rejecttionAndSkip ? this.clonedReading.visits.find(v => v.id === currentVisit.id).scoring : this.kneeFormArray.value.find(kneeForm => kneeForm.visitId === currentVisit.id);
    if (!currentVisit.scoring)
    return
    currentVisit.scoring.jsnAgreed = currentKneeForm.JsnRKL || false;
    currentVisit.scoring.sclerosisAgreed = currentKneeForm.ScRKL || false;
    currentVisit.scoring.attritionAgreed = currentKneeForm.AtRK || false;
    currentVisit.scoring.osteophytosisFemurAgreed = currentKneeForm.OfRKL || false;
    currentVisit.scoring.osteophytosisTibiaAgreed = currentKneeForm.OtRKL || false;
    currentVisit.scoring.jsnRightKneeLateralMm = currentKneeForm.jsnRightKneeLateralMm;
    currentVisit.scoring.jsnRightKneeMedialMm = currentKneeForm.jsnRightKneeMedialMm;
    currentVisit.scoring.jsnLeftKneeLateralMm = currentKneeForm.jsnLeftKneeLateralMm;
    currentVisit.scoring.jsnLeftKneeMedialMm = currentKneeForm.jsnLeftKneeMedialMm;
    currentVisit.scoring.sclerosisRightKneeLateral = currentKneeForm.sclerosisRightKneeLateral;
    currentVisit.scoring.sclerosisRightKneeMedial = currentKneeForm.sclerosisRightKneeMedial;
    currentVisit.scoring.sclerosisLeftKneeLateral = currentKneeForm.sclerosisLeftKneeLateral;
    currentVisit.scoring.sclerosisLeftKneeMedial = currentKneeForm.sclerosisLeftKneeMedial;
    currentVisit.scoring.attritionRightKnee = currentKneeForm.attritionRightKnee;
    currentVisit.scoring.attritionLeftKnee = currentKneeForm.attritionLeftKnee;
    currentVisit.scoring.osteophytosisFemurRightKneeLateral = currentKneeForm.osteophytosisFemurRightKneeLateral;
    currentVisit.scoring.osteophytosisFemurRightKneeMedial = currentKneeForm.osteophytosisFemurRightKneeMedial;
    currentVisit.scoring.osteophytosisFemurLeftKneeLateral = currentKneeForm.osteophytosisFemurLeftKneeLateral;
    currentVisit.scoring.osteophytosisFemurLeftKneeMedial = currentKneeForm.osteophytosisFemurLeftKneeMedial;
    currentVisit.scoring.osteophytosisTibiaRightKneeLateral = currentKneeForm.osteophytosisTibiaRightKneeLateral;
    currentVisit.scoring.osteophytosisTibiaRightKneeMedial = currentKneeForm.osteophytosisTibiaRightKneeMedial;
    currentVisit.scoring.osteophytosisTibiaLeftKneeLateral = currentKneeForm.osteophytosisTibiaLeftKneeLateral;
    currentVisit.scoring.osteophytosisTibiaLeftKneeMedial = currentKneeForm.osteophytosisTibiaLeftKneeMedial;
    currentVisit.scoring.jsnComment = rejecttionAndSkip ? currentKneeForm.jsnComment : currentKneeForm.JSNComment;
    currentVisit.scoring.sclerosisComment = rejecttionAndSkip ? currentKneeForm.sclerosisComment : currentKneeForm.SCComment;
    currentVisit.scoring.attritionComment = rejecttionAndSkip ? currentKneeForm.attritionComment : currentKneeForm.ATComment;
    currentVisit.scoring.osteophytosisFemurComment = rejecttionAndSkip ? currentKneeForm.osteophytosisFemurComment : currentKneeForm.OFComment;
    currentVisit.scoring.osteophytosisTibiaComment = rejecttionAndSkip ? currentKneeForm.osteophytosisTibiaComment : currentKneeForm.OTComment;
  }

  private applyQuestionFormForCurrentVisit(currentVisit: JSW_OARSI_VisitModel): void {
    const currentQuestionForm = this.questionsForm.value.find(questionForm => questionForm.visitId === currentVisit.id);
    currentVisit.jsmCorrectKnee = currentQuestionForm.question1;
    currentVisit.jsmAppropriate = currentQuestionForm.question2;
  }

  private initDynamikaAIResultsTable() {
    const tableRows = [] as DynamikaAIResultsTableRow[];
    this.reading.visits.filter(currentVisit => !currentVisit.noUploads).forEach(currentVisit => {
      const tableRow = this.visitToDynamikaAIResultTableRow(currentVisit);
      tableRows.push(tableRow);
    });
    this.dynamikaAIResultsTableRows = tableRows;
  }

  private visitToDynamikaAIResultTableRow(currentVisit: JSW_OARSI_VisitModel) {
    return {
      visitId: currentVisit.id,
      timepoint: currentVisit.timepoint
    } as DynamikaAIResultsTableRow;
  }

  readingRejection(event) {
    this.changeCompleted = false;
    if (event.value) {
      this.applyFormValuesToCurrentReading(true);
      this.clonedReading = lodash.cloneDeep(this.reading)
    }
    setTimeout(() => {
      this.reading.visits.filter(currentVisit => !currentVisit.noUploads).forEach(v => {
        const clonedVisit = this.clonedReading.visits.find(cv => cv.id === v.id);
        const kneeFormIndex = this.kneeFormArray.value.findIndex(kneeForm => kneeForm.visitId === v.id);
        const currentKneeForm = this.kneeFormArray.controls[kneeFormIndex] as FormGroup
        currentKneeForm.patchValue({
          JsnRKL: !event.value,
          JsnRKM: !event.value,
          JsnLKL: !event.value,
          JsnLKM: !event.value,
          ScRKL: !event.value,
          ScRKM: !event.value,
          ScLKL: !event.value,
          ScLKM: !event.value,
          AtRK: !event.value,
          AtLK: !event.value,
          OfRKL: !event.value,
          OfRKM: !event.value,
          OfLKL: !event.value,
          OfLKM: !event.value,
          OtRKL: !event.value,
          OtRKM: !event.value,
          OtLKL: !event.value,
          OtLKM: !event.value,
          jsnRightKneeLateralMm: event.value ? "NE" : clonedVisit.scoring.jsnRightKneeLateralMm,
          jsnRightKneeMedialMm: event.value ? "NE" : clonedVisit.scoring.jsnRightKneeMedialMm,
          jsnLeftKneeLateralMm: event.value ? "NE" : clonedVisit.scoring.jsnLeftKneeLateralMm,
          jsnLeftKneeMedialMm: event.value ? "NE" : clonedVisit.scoring.jsnLeftKneeMedialMm,
          sclerosisRightKneeLateral: event.value ? "NE" : clonedVisit.scoring.sclerosisRightKneeLateral,
          sclerosisRightKneeMedial: event.value ? "NE" : clonedVisit.scoring.sclerosisRightKneeMedial,
          sclerosisLeftKneeLateral: event.value ? "NE" : clonedVisit.scoring.sclerosisLeftKneeLateral,
          sclerosisLeftKneeMedial: event.value ? "NE" : clonedVisit.scoring.sclerosisLeftKneeMedial,
          attritionRightKnee: event.value ? "NE" : clonedVisit.scoring.attritionRightKnee,
          attritionLeftKnee: event.value ? "NE" : clonedVisit.scoring.attritionLeftKnee,
          osteophytosisFemurRightKneeLateral: event.value ? "NE" : clonedVisit.scoring.osteophytosisFemurRightKneeLateral,
          osteophytosisFemurRightKneeMedial: event.value ? "NE" : clonedVisit.scoring.osteophytosisFemurRightKneeMedial,
          osteophytosisFemurLeftKneeLateral: event.value ? "NE" : clonedVisit.scoring.osteophytosisFemurLeftKneeLateral,
          osteophytosisFemurLeftKneeMedial: event.value ? "NE" : clonedVisit.scoring.osteophytosisFemurLeftKneeMedial,
          osteophytosisTibiaRightKneeLateral: event.value ? "NE" : clonedVisit.scoring.osteophytosisTibiaRightKneeLateral,
          osteophytosisTibiaRightKneeMedial: event.value ? "NE" : clonedVisit.scoring.osteophytosisTibiaRightKneeMedial,
          osteophytosisTibiaLeftKneeLateral: event.value ? "NE" : clonedVisit.scoring.osteophytosisTibiaLeftKneeLateral,
          osteophytosisTibiaLeftKneeMedial: event.value ? "NE" : clonedVisit.scoring.osteophytosisTibiaLeftKneeMedial
        });
        v.scoring.jsnAgreed = !event.value;
        v.scoring.sclerosisAgreed = !event.value;
        v.scoring.attritionAgreed = !event.value;
        v.scoring.osteophytosisFemurAgreed = !event.value;
        v.scoring.osteophytosisTibiaAgreed = !event.value;
        v.scoring.jsnRightKneeLateralMm = event.value ? "NE" : clonedVisit.scoring.jsnRightKneeLateralMm;
        v.scoring.jsnRightKneeMedialMm = event.value ? "NE" : clonedVisit.scoring.jsnRightKneeMedialMm;
        v.scoring.jsnLeftKneeLateralMm = event.value ? "NE" : clonedVisit.scoring.jsnLeftKneeLateralMm;
        v.scoring.jsnLeftKneeMedialMm = event.value ? "NE" : clonedVisit.scoring.jsnLeftKneeMedialMm;
        v.scoring.sclerosisRightKneeLateral = event.value ? "NE" : clonedVisit.scoring.sclerosisRightKneeLateral;
        v.scoring.sclerosisRightKneeMedial = event.value ? "NE" : clonedVisit.scoring.sclerosisRightKneeMedial;
        v.scoring.sclerosisLeftKneeLateral = event.value ? "NE" : clonedVisit.scoring.sclerosisLeftKneeLateral;
        v.scoring.sclerosisLeftKneeMedial = event.value ? "NE" : clonedVisit.scoring.sclerosisLeftKneeMedial;
        v.scoring.attritionRightKnee = event.value ? "NE" : clonedVisit.scoring.attritionRightKnee;
        v.scoring.attritionLeftKnee = event.value ? "NE" : clonedVisit.scoring.attritionLeftKnee;
        v.scoring.osteophytosisFemurRightKneeLateral = event.value ? "NE" : clonedVisit.scoring.osteophytosisFemurRightKneeLateral;
        v.scoring.osteophytosisFemurRightKneeMedial = event.value ? "NE" : clonedVisit.scoring.osteophytosisFemurRightKneeMedial;
        v.scoring.osteophytosisFemurLeftKneeLateral = event.value ? "NE" : clonedVisit.scoring.osteophytosisFemurLeftKneeLateral;
        v.scoring.osteophytosisFemurLeftKneeMedial = event.value ? "NE" : clonedVisit.scoring.osteophytosisFemurLeftKneeMedial;
        v.scoring.osteophytosisTibiaRightKneeLateral = event.value ? "NE" : clonedVisit.scoring.osteophytosisTibiaRightKneeLateral;
        v.scoring.osteophytosisTibiaRightKneeMedial = event.value ? "NE" : clonedVisit.scoring.osteophytosisTibiaRightKneeMedial;
        v.scoring.osteophytosisTibiaLeftKneeLateral = event.value ? "NE" : clonedVisit.scoring.osteophytosisTibiaLeftKneeLateral;
        v.scoring.osteophytosisTibiaLeftKneeMedial = event.value ? "NE" : clonedVisit.scoring.osteophytosisTibiaLeftKneeMedial;
      })
      if (!event.value) {
        this.reading = lodash.cloneDeep(this.clonedReading);
        this.initKneeFormArray();
      }
      this.commentControl.validator = event.value ? Validators.required : null;
      this.commentControl.updateValueAndValidity();
      this.changeCompleted = true;
    }, 50);
  }

  private setupFormTitle() {
    this.store.dispatch(new SetPageHeaderTitle('OARSI Scoring Form'));
  }
}
