import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {EndpointService, ReadingConfigFlexibleService} from '../../../../_services';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {FormMode} from '../../../../core/constants/form-mode';
import {AutoBatchConfigPayload} from '../../../../core/payload/auto-batch-config-payload';
import {BatchInitiationOption} from '../../../../core/constants/BatchLogic/batch-initiation-option';
import {BatchInitiationMethod} from '../../../../core/constants/BatchLogic/batch-initiation-method';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {FlexibleConfig} from '../../../../core/interfaces/flexible-config';
import {ResponseCode} from '../../../../core/constants/response-code';
import {Observable} from 'rxjs';
import {ReadingType} from '../../../../core/constants/reading-type';
import {EndpointModel} from '../../../../_models/ImagingProject/endpoint-model';

export interface FlexibleConfigWithBatch extends FlexibleConfig {
  batchConfigs: AutoBatchConfigPayload[];
}

export interface ImagingProjectSubmitEvent {
  result: 'submit' | 'cancel';
  data: any;
  level1BatchConfig?: AutoBatchConfigPayload;
  adjudicationBatchConfig?: AutoBatchConfigPayload;
  moderationBatchConfig?: AutoBatchConfigPayload;
  basicBatchConfig?: AutoBatchConfigPayload;
}

export const defaultBasicAutoBatchConfig: AutoBatchConfigPayload = {
  numberOfReadings: 1,
  readingType: ReadingType.BASIC_READING,
  initiationMethod: BatchInitiationMethod.AUTO,
  initiationOption: BatchInitiationOption.BY_QUANTITY,
  batchCount: '1',
};


export const defaultLvl1AutoBatchConfig: AutoBatchConfigPayload = {
  numberOfReadings: 1,
  readingType: ReadingType.LEVEL1_READING,
  initiationMethod: BatchInitiationMethod.AUTO,
  initiationOption: BatchInitiationOption.BY_QUANTITY,
  batchCount: '1',
};

export const defaultAdjAutoBatchConfig: AutoBatchConfigPayload = {
  numberOfReadings: 1,
  readingType: ReadingType.ADJUDICATION_READING,
  initiationMethod: BatchInitiationMethod.AUTO,
  initiationOption: BatchInitiationOption.BY_QUANTITY,
  batchCount: '1',
};

export const defaultModerationAutoBatchConfig: AutoBatchConfigPayload = {
    numberOfReadings: 1,
    readingType: ReadingType.MODERATION_READING,
    initiationMethod: BatchInitiationMethod.AUTO,
    initiationOption: BatchInitiationOption.BY_QUANTITY,
    batchCount: '1',
};

@Component({
  selector: 'app-imaging-project-reading-selector',
  templateUrl: './imaging-project-reading-selector.component.html',
  styleUrls: ['./imaging-project-reading-selector.component.css']
})
export class ImagingProjectReadingSelectorComponent implements OnInit {

  @Output()
  public clickCancelEvent = new EventEmitter();
  @Output()
  public clickSubmitEvent = new EventEmitter();

  @Input()
  public mode: FormMode;
  @Input() visitsQuantity: number;
  @Input() unscheduledAllowed: boolean;

  readonly configFormMode = FormMode;

  _selectedConfig: FlexibleConfig;
  configModel: any;

  @Input('selectedConfig')
  set selectedConfig(value: any) {
    this._selectedConfig = value;
    if (this._selectedConfig != null) {
      this.configModel = this._selectedConfig.config;
      if (this._selectedConfig.config.endpoint != null) {
        this.selectedEndpoint = this._selectedConfig.config.endpoint;
      } else {
        this.selectedEndpoint = null;
      }
    } else {
      this.configModel = null;
    }
  }

  get selectedConfig(): any {
    return this.selectedEndpoint;
  }

  studyId: number;

  selectedEndpoint: EndpointModel = null;
  endpoints: EndpointModel[];
  endpointOptions: EndpointModel[];

  showModalSpinnerLoadingData = false;

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(private router: Router,
              private route: ActivatedRoute,
              private endpointService: EndpointService,
              private toastyService: ToastyService,
              private readingConfigFlexibleService: ReadingConfigFlexibleService
  ) {
  }

  ngOnInit() {
    this.studyId = parseInt(this.route.snapshot.params.id, 10);
    this.endpointService.getAll().subscribe((endpointResp: any) => {
      this.endpoints = [...endpointResp.data];
      if (this.mode === FormMode.NEW) {
        this.endpointOptions = [...endpointResp.data.filter(e => e.name !== 'KOALA K&L')];
      } else {
        this.endpointOptions = [...endpointResp.data];
      }
      // select endpoint with same id as was set in template
      if (this.selectedEndpoint != null) {
        this.selectedEndpoint = {...this.endpoints.find(e => e.id === this.selectedEndpoint.id)};
        if (this.selectedEndpoint.name === 'KOALA K&L') {
          this.selectedEndpoint.name = 'K&L';
        }
      }
    });
  }

  endpointNameChange(endpointName: string) {
    return ['JSW','OARSI'].includes(endpointName) ? endpointName + ' knee' : endpointName
  }

  onEndpointChange(event) {
    if (event.value == null) {
      this.selectedEndpoint = null;
    } else {
      this.selectedEndpoint = this.endpoints.filter(endpoint => endpoint.id === event.value.id)[0];
    }
    if (!!this.selectedEndpoint && this.selectedEndpoint.name === 'KOALA K&L') {
      this.selectedEndpoint.name = 'K&L';
    }
  }

  clickSubmit(event: ImagingProjectSubmitEvent) {
    if (event.data != null && this.selectedEndpoint != null) {
      this.showModalSpinnerLoadingData = true;
      if (!event.data.endpoint) {
        event.data.endpoint = this.selectedEndpoint;
      }
      const flexibleConfigId = !!this._selectedConfig ? this._selectedConfig.id : null;
      const config: FlexibleConfigWithBatch = {
        id: flexibleConfigId,
        studyId: this.studyId,
        endpointId: !event.data.endpoint ? this.selectedEndpoint.id : event.data.endpoint.id,
        config: event.data,
        batchConfigs: this.prepareConfigFlexiblePayload(
          event.data.readingVersion,
          event,
          event.data.readers,
          event.data?.adjudicationReader,
          event.data?.moderationReader
        )
      };
      let observable: Observable<BasicResponse<FlexibleConfig>>;
      let result: 'new' | 'update';
      if (this.mode === FormMode.NEW) {
        observable = this.readingConfigFlexibleService.create(config);
        result = 'new';
      } else if (this.mode === FormMode.EDIT) {
        observable = this.readingConfigFlexibleService.update(config);
        result = 'update';
      }
      observable.subscribe((newConfigResult: BasicResponse<FlexibleConfig>) => {
        if (newConfigResult.responseCode !== ResponseCode.OK) {
          this.selectedEndpoint = null;
          this.toastOptions.title = 'ERROR ' + newConfigResult.responseCode;
          this.toastOptions.msg = newConfigResult.responseMessage;
          this.toastyService.error(this.toastOptions);
        } else {
          if (result === 'new') {
            this.toastOptions.title = 'Reading config is created successfully';
            this.toastOptions.msg = 'System will create new readings in according with submitted configuration';
          } else {
            this.toastOptions.title = 'Reading config has been updated successfully';
            this.toastOptions.msg = 'New configuration is applied to newly created readings. Existing readings with previous config version stay unchanged and continue their workflow';
          }
          this.toastyService.success(this.toastOptions);
        }
        this.clickSubmitEvent.emit({result: 'submit', data: result});
        this.showModalSpinnerLoadingData = false;
      }, error => {
        this.selectedEndpoint = null;
        this.toastOptions.title = 'ERROR ' + error.error.responseCode;
        this.toastOptions.msg = error.error.responseMessage;
        this.toastyService.error(this.toastOptions);
        this.clickSubmitEvent.emit({ result: 'submit', data: result });
        this.showModalSpinnerLoadingData = false;
      });
    }
  }

  prepareConfigFlexiblePayload(readingVersion: string, event: ImagingProjectSubmitEvent,
                               readers: any[], adjudicationReaderId: number, moderationReaderId: number): AutoBatchConfigPayload[] {
    const result: AutoBatchConfigPayload[] = [];
    this.selectedEndpoint = null;
    const isAdjudication: boolean = readingVersion && readingVersion.toLowerCase().includes('adjudication');
    if (isAdjudication) {
      result.push(this.prepareBatchConfig(event.level1BatchConfig, readers.map(r => r.id), defaultLvl1AutoBatchConfig));
      result
        .push(this.prepareBatchConfig(event.adjudicationBatchConfig, [adjudicationReaderId], defaultAdjAutoBatchConfig));
    } else {
        const isModeration: boolean = readingVersion && readingVersion.toLowerCase().includes('moderation');
        if (isModeration) {
            result.push(this.prepareBatchConfig(event.level1BatchConfig, readers.map(r => r.id), defaultLvl1AutoBatchConfig));
            result.push(this.prepareBatchConfig(event.moderationBatchConfig, [moderationReaderId], defaultModerationAutoBatchConfig));
        } else {
            result.push(this.prepareBatchConfig(event.basicBatchConfig, readers.map(r => r.id), defaultBasicAutoBatchConfig));
        }
    }
    return result;
  }

  prepareBatchConfig(config: AutoBatchConfigPayload, readers: any[], defaultValue: AutoBatchConfigPayload): AutoBatchConfigPayload {
    let batchConfig: AutoBatchConfigPayload;
    if (config && config.numberOfReadings) {
      batchConfig = config;
    } else {
      batchConfig = Object.assign(defaultValue);
      if (config && config.id) {
        batchConfig.id = config.id;
      }
    }
    batchConfig.batchCount = batchConfig.numberOfReadings.toString();
    batchConfig.readerIds = readers;
    return batchConfig;
  }

  clickCancel() {
    this.selectedEndpoint = null;
    this.clickCancelEvent.emit({result: 'cancel', data: null});
  }

  compareEndpoints(e1, e2) {
      return e1 && e2 ? e1.id === e2.id : e1 === e2;
  }

}
