import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ConfirmationBodyLocationDialogComponent} from '../confirmation-body-location-dialog/confirmation-body-location-dialog.component';
import {PrimaryBodyLocationModel} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-model';
import {BodySubLocationModel} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/body-sub-location-model';
import {BodySubLocationService} from '../../../../_services';
import {AbstractControl, FormControl, ValidationErrors, Validators} from '@angular/forms';

@Component({
  selector: 'app-primary-body-sublocation-dialog',
  templateUrl: './primary-body-sublocation-dialog.component.html',
  styleUrls: ['./primary-body-sublocation-dialog.component.css']
})
export class PrimaryBodySublocationDialogComponent implements OnInit {

  currentLocation: PrimaryBodyLocationModel;
  nameFormControl =  new FormControl('', [Validators.required,
    Validators.maxLength(200),
    Validators.minLength(1),
    this.validateUniqueName]);

  constructor(
    private bodySubLocationService: BodySubLocationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BodySublocationDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: BodySublocationDialogModel
  ) {
    this.currentLocation = JSON.parse(JSON.stringify(this.data.subLocation));
    console.log('--- sub: ', this.data);
  }

  ngOnInit(): void {
    this.nameFormControl.setValue(this.currentLocation.name);
  }

  get validateUniqueName() {
    return (control: AbstractControl): ValidationErrors | null => {
      let names = this.data.subLocationList.filter(p => p.name === control.value
        && 'ACTIVE' === p.status.toUpperCase());
      if (names.length === 1) {
        if (names[0].id === this.data.subLocation.id) {
          names = [];
        }
      }
      return names.length > 0 ? {duplicateValueError: 'Duplicate. value already exists'} : null;
    };
  }

  onNameChanged(event): void {
    this.nameFormControl.updateValueAndValidity();
  }

  save(): void {
    if (this.data.mode === BodySublocationDialogMode.NEW) {
      const location = {
        name: this.nameFormControl.value
      };
      this.bodySubLocationService.create(this.data.primaryLocation.id,
        <BodySubLocationModel> location).subscribe(presp => {
        this.dialogRef.close(presp.data);
      });
    } else if (this.data.mode === BodySublocationDialogMode.EDIT) {
      const dialogRef = this.dialog.open(ConfirmationBodyLocationDialogComponent, {
        width: '600px',
        data: {}
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        if (result === 'ok') {
          const location = {
            id: this.currentLocation.id,
            name: this.nameFormControl.value
          };
          this.bodySubLocationService.updateName(<BodySubLocationModel> location).subscribe(presp => {
            this.dialogRef.close(presp.data);
          });
        } else {
          this.dialogRef.close();
        }
      });
    }
  }

  close() {
    this.dialogRef.close();
  }
}


export enum BodySublocationDialogMode {
  NEW = 'new',
  EDIT = 'edit'
}

export interface BodySublocationDialogModel {
  mode: BodySublocationDialogMode;
  primaryLocation: PrimaryBodyLocationModel;
  subLocation: BodySubLocationModel;
  subLocationList: BodySubLocationModel[];
}
