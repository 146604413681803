import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../../reading-form-scoring.component';
import {Observable} from 'rxjs';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {Store} from '@ngxs/store';
import {DynamikaWebSocket, ReadingWbmriService, UserService} from '../../../../_services';
import {BasicReading} from '../../../../_models/basic-reading';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
import {EndpointReadingVersion} from '../../../../core/types/endpoint-reading-version';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../environments/environment';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {WbmriReadingModel} from '../../../../_models/WBMRI/wbmri-reading-model';
import {WbmriFormControls, WbmriFormGroup} from './wbmri-form-model';
import {ReadingVersion} from '../../../../core/constants/reading-version';

const ZITELAB_URL = environment.ziteLabUrl;

@Component({
  selector: 'app-wbmri',
  templateUrl: './wbmri-form.component.html',
  styleUrls: ['./wbmri-form.component.css']
})
export class WbmriFormComponent extends ReadingFormScoringComponent implements OnInit, AfterViewInit, ReadingFormScoring {

  readonly readingVersion = ReadingVersion;

  studyId: number;
  endpointReadingVersion: EndpointReadingVersion;
  currentReading: BasicReading & WbmriReadingModel;
  isReadingComplete = false;

  initiatingStatus: boolean | 'loading' | 'error' = false;

  form: WbmriFormGroup;

  constructor(private store: Store,
              private readingService: ReadingWbmriService,
              private userService: UserService,
              private webSocketService: DynamikaWebSocket,
              private route: ActivatedRoute,
              private fb: FormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.stepSaveEnabled = false;
    this.studyId = +JSON.parse(localStorage.getItem('project')).id;
    this.endpointReadingVersion = this.route.snapshot.params.type as EndpointReadingVersion;
    this.store.dispatch(new SetPageHeaderTitle(`WBMRI ${this.endpointReadingVersion.toString()} Scoring Form`));
    this.initForm();
    this.initOnCurrentReading();
    this.subscribeSiteLabReadingMessage();
  }

  subscribeSiteLabReadingMessage() {
    this.webSocketService.listen('reading_wbmri_message', null, "wbmri-form").subscribe((data) => {
      const msg = JSON.parse(data);
      this.isReadingComplete = this.currentReading.readerId === msg.readerId && this.currentReading.id === msg.patientId;
      this.switchSubmitBtnDisabledSubject.next(!this.isReadingComplete);
    });
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      this.currentReading = currentReading as BasicReading & WbmriReadingModel;
      this.initiatingStatus = this.currentReading.ziteLabInitiated;
      this.isReadingComplete = this.currentReading.ziteLabDataReceived;
      this.switchSubmitBtnDisabledSubject.next(!this.isReadingComplete);
    });
  }

  initForm(): void {
    this.form = this.fb.group({
      comment: new FormControl(null, [Validators.maxLength(200)]),
      initiated: new FormControl()
    } as WbmriFormControls) as WbmriFormGroup;
  }

  clearForm(): void {
    this.form.reset();
  }

  getEndpointName(): string {
    return 'WBMRI';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    if (this.route.snapshot.params.type as EndpointReadingVersion === 'basic') {
      return this.readingService.getReadingByStudyIdAndReaderId(studyId, readerId);
    } else {
      return this.readingService.getAdjudicationReadingsByStudyIdAndReaderId(studyId, readerId);
    }
  }

  ngAfterViewInit(): void {
  }

  saveReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.updateReading(studyId, this.currentReading.id, this.form.controls.comment.value, data.spentSeconds);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    const reading: WbmriReadingModel = JSON.parse(JSON.stringify(this.currentReading));
    reading.comment = this.form.controls.comment.value;
    reading.timeSpent = data.spentSeconds;
    return this.readingService.completeReading(studyId, reading);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.updateReading(studyId, readingId, data.spentSeconds, this.form.controls.comment.value);
  }

  ziteLabInitiateReading() {
    this.initiatingStatus = 'loading';
    this.readingService
      .ziteLabReadingInitiation(this.studyId, this.currentReading).subscribe(response => {
      if (response.responseCode === 200) {
        this.currentReading.ziteLabInitiated = true;
        this.initiatingStatus = true;
        this.controlSamriView();
      } else {
        this.initiatingStatus = 'error';
      }
    }, error => {
      this.initiatingStatus = 'error';
    });
  }

  controlSamriView() {
    this.readingService.laodOnSamri(this.studyId, this.currentReading.id).subscribe();
  }

  openZiteLabTab() {
    let userName: string;
    const dbName = environment.zitelab_wbmri_db_prefix;
    this.userService.getById(this.currentReading.readerId).subscribe(response => {
      if (response) {
        if (response['responseCode'] === 200) {
          userName = response['data'].userName as string;
          this.readingService.laodOnSamri(this.studyId, this.currentReading.id)
            .subscribe(resp => {
              window.open(`${ZITELAB_URL}/samrisystem/${userName}/${dbName}${this.currentReading.flexibleConfigId}/${this.currentReading.id}`, '_blank');
            });
        }
      }
    });
  }

}
