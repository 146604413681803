import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, Router} from '@angular/router';
import {BatchLogicService} from '../../../../_services/batch-logic.service';
import {SelectionModel} from '@angular/cdk/collections';

import * as _moment from 'moment';
import {PatientService} from '../../../../_services/patient.service';
import {ImagingProjectService, UserService} from '../../../../_services';
import {FormControl, FormGroup} from '@angular/forms';
import {BatchReadingModel} from '../../../../_models/BatchLogic/batch-reading-model';
import {FormMode} from '../../../../core/constants/form-mode';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BatchRequestPayload} from '../batch/batch.component';
import {forkJoin} from 'rxjs';
import {ReadingType} from '../../../../core/constants/reading-type';

const moment = _moment;

export interface BatchReadingsSelectorDialogData {
  mode: FormMode;
  studyId: number;
  flexibleConfig: any;
  reader: any;
  readingType: ReadingType;
  batch: BatchRequestPayload;
  readings: BatchReadingModel[];
}

interface Filter {
  patientCode: number;
  visit: string;
  modality: string;
  config: number;
}

const emptyFilter: Filter = {
  patientCode: null,
  visit: null,
  modality: null,
  config: null,
};

type FilterControls = { [key in keyof Filter]: FormControl };
type FilterFormGroup = FormGroup & { value: Filter, controls: FilterControls };

@Component({
  selector: 'app-batch-readings-selector',
  templateUrl: './batch-readings-selector.component.html',
  styleUrls: ['./batch-readings-selector.component.css']
})
export class BatchReadingsSelectorComponent implements OnInit {

  displayedColumns: string[] = ['select', 'readingId', 'patient', 'visit', 'modality',
    'readingStatus', 'turnAround', 'remaining', 'created'];
  dataSource: MatTableDataSource<any>;

  readingListData = [];
  selection = new SelectionModel<BatchReadingModel>(true, []);

  mode: FormMode;

  // filters
  patients = [];
  readers = [];
  endpoints = [];
  readingStatusName = [];
  modalitiesNames = [];
  visitNames = [];

  studyId: number;

  config: any;
  selectedReader: any;
  batch: any;
  selectedBeforeReadings: BatchReadingModel[] = [];
  readingTypeName: string;

  defaultReadingFilter: { endpoint: string, reader: any, config: number, readingType: ReadingType };

  filterForm = new FormGroup({
    patientCode: new FormControl(null),
    visit: new FormControl(null),
    modality: new FormControl(null),
    config: new FormControl(null),
  } as FilterControls) as FilterFormGroup;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  showModalSpinner = false;

  constructor(
    public dialogRef: MatDialogRef<BatchReadingsSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BatchReadingsSelectorDialogData,
    private router: Router,
    private route: ActivatedRoute,
    private batchLogicService: BatchLogicService,
    private userService: UserService,
    private patientService: PatientService,
    private imagingProjectService: ImagingProjectService,
  ) {
  }

  ngOnInit() {
    this.studyId = this.data.studyId;
    this.dataSource = new MatTableDataSource<BatchReadingModel>();
    this.mode = this.data.mode;
    this.batch = this.data.batch;
    this.selectedReader = this.data.reader;
    this.config = this.data.flexibleConfig;
    this.selectedBeforeReadings = this.data.readings;
    this.readingTypeName = this.getReadingTypeName(this.data.readingType);
    this.createDefaultFilter();
    this.getReadings(this.defaultReadingFilter, true);
  }

  getReadingTypeName(readingType: ReadingType): string {
    switch (readingType) {
      case ReadingType.BASIC_READING:
        return ReadingType.BASIC;
      case ReadingType.LEVEL1_READING:
        return ReadingType.LEVEL1;
      case ReadingType.ADJUDICATION_READING:
        return ReadingType.ADJUDICATION;
      case ReadingType.MODERATION_READING:
        return ReadingType.MODERATION;
    }
  }

  createDefaultFilter(): void {
    this.defaultReadingFilter = {
      endpoint: this.data.flexibleConfig.config.endpoint.name,
      reader: this.data.reader.id,
      config: this.data.flexibleConfig.id,
      readingType: this.data.readingType,
    };
  }

  getReadings(filter, setFilter = false): void {


    this.showModalSpinner = true;
    this.batchLogicService.getReadings(this.studyId, filter).subscribe(readingResp => {
      let usersIds = [];
      let patientIds = [];
      let visitConfigIds = [];
      const endp = [];
      const statusName = [];
      const modalitiesNames = [];

      const readingList = readingResp.data
        .filter(r => !this.selectedBeforeReadings.find(readingExists => readingExists.id === r.id));

      readingList.forEach(reading => {
        usersIds.push(reading.readerId);
        patientIds.push(reading.patientId);
        endp.push(reading.endpoint);
        statusName.push(reading.status.name);

        reading.visits.forEach(visit => {
          visitConfigIds.push(visit.visitConfigId);
          visit.modalities.forEach(modality => {
            modalitiesNames.push(modality.name);
          });
        });
        reading['createdFormatted'] = moment(reading.created.toString()).format('DD.MM.YYYY HH:mm');
      });

      usersIds = Array.from(new Set(usersIds));
      patientIds = Array.from(new Set(patientIds));
      visitConfigIds = Array.from(new Set(visitConfigIds));
      this.endpoints = Array.from(new Set(endp));
      this.endpoints.sort();
      this.readingStatusName = Array.from(new Set(statusName));
      this.readingStatusName.sort();
      this.modalitiesNames = Array.from(new Set(modalitiesNames));
      this.modalitiesNames.sort();


      this.userService.getByListIds(usersIds).subscribe(usersResp => {
        this.readers = usersResp['data'];

        this.loadPatients(readingList, patientIds, visitConfigIds, setFilter);
      });
    });
  }

  loadPatients(readingsList, patientIds: number[], visitsConfigIds: number[], setFilter: boolean) {
    forkJoin([this.patientService.getByListIds(patientIds), this.imagingProjectService.getVisitConfigsByIds(visitsConfigIds)])
      .subscribe(([patientsResp, visitConfigsResp]) => {
        if (setFilter) {
          this.patients = patientsResp.data;
        }
        const vNames = [];
        readingsList.forEach(reading => {
          const reader = this.readers.find(u => u.id === reading.readerId);
          const patient = patientsResp.data.find(p => p.id === reading.patientId);
          reading['reader'] = reader;
          reading['patientCode'] = patient['patientCode'];
          reading.visits.forEach(visit => {
            const visitConfig = visitConfigsResp.data.find(v => v.id === visit.visitConfigId);
            visit['visitName'] = visitConfig.visit.name;
            vNames.push(visitConfig.visit.name);
          });
        });

        this.visitNames = Array.from(new Set(vNames));
        this.visitNames.sort();

        this.setTableData(readingsList, patientsResp.data, visitConfigsResp.data);
        this.showModalSpinner = false;
      });
  }

  setTableData(readingsList, patients, visitConfigs) {
    const vNames = [];
    readingsList.forEach(reading => {
      const reader = this.readers.find(u => u.id === reading.readerId);
      const patient = patients.find(p => p.id === reading.patientId);
      reading['reader'] = reader;
      reading['patientCode'] = patient['patientCode'];
      reading.visits.forEach(visit => {
        const visitConfig = visitConfigs.find(v => v.id === visit.visitConfigId);
        visit['visitName'] = visitConfig.visit.name;
        vNames.push(visitConfig.visit.name);
      });
      const selectedReading = this.selection.selected.find(r => r.id === reading.id);
      if (selectedReading) {
        reading.checked = true;
      }
    });
    this.visitNames = Array.from(new Set(vNames));
    this.visitNames.sort();

    this.readingListData = readingsList;
    this.dataSource.data = readingsList;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  onApplyFilter() {
    const filter: any = Object.assign({}, this.defaultReadingFilter);

    if (this.filterForm.controls.modality.value != null) {
      filter.modality = this.filterForm.controls.modality.value;
    }
    if (this.filterForm.controls.patientCode.value != null) {
      filter.patient = this.filterForm.controls.patientCode.value;
    }
    if (this.filterForm.controls.visit.value != null) {
      let visitConfigIdFound = false;
      for (const reading of this.readingListData) {
        for (const visit of reading.visits) {
          if (visit.visitName === this.filterForm.controls.visit.value) {
            visitConfigIdFound = true;
            filter.visit = visit.visitConfigId;
            break;
          }
        }
        if (visitConfigIdFound) {
          break;
        }
      }
    }

    this.getReadings(filter);
  }

  onCheckReading(element: BatchReadingModel) {
    if (!!this.selection.selected.find(item => item.id === element.id)) {
      const selected = this.selection.selected.filter(r => r.id !== element.id);
      this.selection.clear();
      selected.forEach(r => this.selection.select(r));
    } else {
      this.selection.select(element);
    }
  }

  readingChecked(element: BatchReadingModel) {
    return !!this.selection.selected.find(item => item.id === element.id);
  }

  onClearFilter() {
    this.filterForm.setValue(emptyFilter);
    this.getReadings(this.defaultReadingFilter);
  }

  onResetSelection() {
    this.filterForm.setValue(emptyFilter);
    this.selection.clear();
    this.getReadings(this.defaultReadingFilter);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onAdd() {
    this.dialogRef.close(this.selection.selected);
  }

  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
}
