import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root',
})

export class ViewerData {
  private viewerSeriesSource = new BehaviorSubject<string>('');
  viewerSeries = this.viewerSeriesSource.asObservable();

  private storeDataSource = new BehaviorSubject<Array<any>>([]);
  private openedViewersSource = new BehaviorSubject<Array<string>>([]);
  
  constructor() {}
  
  changeViewerSeries(seriesID: string) {
    this.viewerSeriesSource.next(seriesID);
  }

  updStoreAdd(newData) {
  	this.updStoreRemove(newData.seriesID)
  	const curValue = this.storeDataSource.value;
	  const updatedValue = [...curValue, Object.assign({}, newData)];
    this.storeDataSource.next(updatedValue);
  }

  updStoreRemove(seriesID: string) {
  	const curValue = this.storeDataSource.value;
  	const updatedValue = curValue.filter(item => item.seriesID !== seriesID);
    this.storeDataSource.next(updatedValue);
  }

  getData(seriesID: string) {
  	const [data] = this.storeDataSource.value.filter(item => item.seriesID === seriesID);
  	return data;
  }

  // methods for work with array of opeend viewers
  checkViewers(seriesID: string) {
  	return this.openedViewersSource.value.includes(seriesID);
  }

  removeViewer(seriesID: string, shouldRemHigh: boolean = true) {
  	const curValue = this.openedViewersSource.value;
  	// if current seriesID are not repeatting in array, we are closing last one with this ID
  	const dupl = this.findDuplicates(curValue);
  	if (!dupl.includes(seriesID)) {
  		this.updStoreRemove(seriesID)
      if (shouldRemHigh && document.querySelector('.tid' + seriesID)) 
        document.querySelector('.tid' + seriesID).classList.remove('viewer-highlighted');
  	}
  	const updatedValue = this.removeItemFromArr(seriesID, curValue);
    this.openedViewersSource.next(updatedValue);
  }

  addViewer(seriesID: string) {
  	const curValue = this.openedViewersSource.value;
    const updatedValue = [...curValue, seriesID];
    this.openedViewersSource.next(updatedValue);
  }

  findDuplicates(arr) {
  	return arr.filter((item, index) => arr.indexOf(item) != index);
  }

  removeItemFromArr(item, array) {
  	const index = array.indexOf(item);
	  return [...array.slice(0, index), ...array.slice(index + 1)];
	}
}