import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {PatientModel} from '../_models/ImagingProject/patient-model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;
// const requestURL = 'http://localhost:8080/api';


@Injectable({
  providedIn: 'root'
})
export class PatientService {

  constructor(private http: HttpClient) {
  }

  getById(patientId: number): Observable<BasicResponse<any>> {
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/' + patientId + '/without-rel';
    return this.http.get<BasicResponse<PatientModel>>(requestUrl);
  }

  getByIdWithVisitConfig(patientId: number): Observable<BasicResponse<any>> {
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/' + patientId + '/with-visit-configs';
    return this.http.get<BasicResponse<any>>(requestUrl);
  }

  getPatientsFromStudyBySiteId(studyId, siteId) {
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/get-patients-by-site-id-and-study-id/' + siteId + '/' + studyId;
    return this.http.get(requestUrl);
  }

  getPatientsByStudyIdAndSiteIds(studyId: number, siteIds: number[]) {
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/get-patients-by-study-id-and-site-ids/' + studyId;
    return this.http.put(requestUrl, siteIds);
  }

  getPatientsByStudyId(studyId): Observable<BasicResponse<any>> {
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/get-patients-by-study-id/' + studyId;
    return this.http.get<BasicResponse<any>>(requestUrl);
  }

  getPatientsByStudyIdBrief(studyId): Observable<BasicResponse<PatientModel[]>> {
    return this.http.get<BasicResponse<PatientModel[]>>(requestURL + '/patient/get-patients-by-study-id/brief/' + studyId);
  }


  updatePatientDiscontinued(studyId: number, patientId: number, discontinued: boolean) {
    const patient = {
      id: patientId,
      discontinued: discontinued
    };
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/update-patient-discontinued/' + studyId;
    return this.http.put(requestUrl, patient);
  }

  getPhantomPatientCode(studyConfigId: number) {
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/get-phantom-patient-code/' + studyConfigId;
    return this.http.get(requestUrl);
  }

  updatePatientCode(studyId: number, patientId: number, patientCode: string, modifyUser: number,
                    modifyComment: string, patientBirthDate: string) {
    const patient = {
      id: patientId,
      patientBirthDate: patientBirthDate,
      patientCode: patientCode,
      modifyUser: modifyUser,
      modifyComment: modifyComment
    };
    const url = `${requestURL}/patient/${studyId}`;
    return this.http.put(url, patient);
  }

  updatePatientBirthDate(studyId: number, patientId: number, patientBirthDate: string, modifyUser: number,
                         modifyComment: string, patientCode: string) {
    const patient = {
      id: patientId,
      patientBirthDate: patientBirthDate,
      modifyUser: modifyUser,
      modifyComment: modifyComment,
      patientCode: patientCode
    };
    const url = `${requestURL}/patient/${studyId}`;
    return this.http.put(url, patient);
  }

  getByListIds(listIds): Observable<BasicResponse<PatientModel[]>> {
    return this.http.put<BasicResponse<PatientModel[]>>(requestURL + `/patient/get-patients-by-ids`, listIds);
  }

  getPatientsSitesByStudyId(studyId: number): Observable<BasicResponse<any>> {
    const url = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/get-by-study-id/' + studyId + '/without-rel';
    return this.http.get<BasicResponse<any>>(url);
  }

  validatePatientsByStudyIdAndPatientCodes(studyId: number, patientCodes: string[]): Observable<BasicResponse<boolean>> {
    const url = 'https://' + API_VERSION + SERVICE + API_URL + '/patient/validate-all-by-patient-codes/' + studyId;
    return this.http.post<BasicResponse<any>>(url, patientCodes);
  }
}
