import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LeadToolsActionManagerService, PrimaryBodyLocationService, ReadingPsmaPetEligibilityService } from 'src/app/_services';
import { Subscription } from 'rxjs';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { PSMAPETLesionEditType, PSMAPETLesionObject } from 'src/app/_models/PSMA-PET/psma-pet-scoring-model';
import { PSMAPETLesionModel } from 'src/app/_models/PSMA-PET/psma-pet-lesion-model';

export interface ModifiedViewerData{
  lesionObject:string
  rois?:any,
  linearMeasurement?:number,
  sliceNumber?:number,
  seriesId?:number
}
export interface LesionDialogFormData{
  studyId:number,
  readingId:number,
  visit:any,
  editDialog:boolean,
  lesionObject:string,
  editType:string,
  lesionData:any,
  screenShotData:any,
  viewerData?:any,
  eCRFOnly:boolean,
}

@Component({
  selector: 'app-psma-lesion-dialog',
  templateUrl: './psma-lesion-dialog.component.html',
  styleUrls: ['./psma-lesion-dialog.component.css']
})
export class PSMALesionDialogComponent implements OnInit , OnDestroy{
  @ViewChild('primaryLocation') primaryLocation:MatSelect;
  public get lesionObject(): typeof PSMAPETLesionObject {
    return PSMAPETLesionObject;
  }
  public get lesionEditType(): typeof PSMAPETLesionEditType {
    return PSMAPETLesionEditType;
  }
  showModalSpinnerLoadingData=false;
  lesionData : PSMAPETLesionModel;
  imageUrl:string='';
  linkData:any;
  imageAttached:boolean=false;
  attachMessage:string;
  modifiedViewerData:ModifiedViewerData;
  maxRoi;
  linear;
  primaryLocations=[];
  subLocations=[];
  locationSubscription:Subscription;
  fileAttached;
  constructor(public dialogRef: MatDialogRef<PSMALesionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LesionDialogFormData,
   private readingPSMAPETService:ReadingPsmaPetEligibilityService,
   private primaryBodyLocation:PrimaryBodyLocationService,
   private leadtoolsActionManagerService:LeadToolsActionManagerService) { }
    LesionForm:FormGroup = new FormGroup({});

  ngOnInit(): void {
    if(this.data.viewerData)
      this.mapToModifiedViewerData(this.data.viewerData);
      this.initLesionForm()
      this.getLocations();
    //console.log(this.data)
  }
  mapToModifiedViewerData(viewerData){
      this.modifiedViewerData ={
        lesionObject :viewerData.objectType
      }
        this.modifiedViewerData = {
          lesionObject :viewerData.objectType,
          seriesId:viewerData.seriesID,
          rois:viewerData.metadatas.map(data=>{
            return{
              id:null,
              name: data.Name,
              area: data.Area,
              sliceNumber: data.SliceNumber,
              seriesId: viewerData.seriesID,
              biDimension: {
                Long: data.FirstLineLength.slice(0, -3)>data.SecondLineLength.slice(0, -3)?data.FirstLineLength.slice(0, -3):data.SecondLineLength.slice(0, -3),
                Short: data.FirstLineLength.slice(0, -3)<data.SecondLineLength.slice(0, -3)?data.FirstLineLength.slice(0, -3):data.SecondLineLength.slice(0, -3),
               }
            }
          }),
        }
        //add cross ruler to rois
        if(viewerData.metadatas.CrossRulerName){
          this.modifiedViewerData.rois.push({
              id:null,
              name: viewerData.metadatas?.CrossRulerName,
              area: 0,
              sliceNumber: viewerData.SliceNumber,
              seriesId: viewerData.seriesID,
              biDimension: {
                Long: 0,
                Short: 0
              }
          })
        }
      //console.log(this.modifiedViewerData)
  }
  getLocations(){
    this.showModalSpinnerLoadingData=true;
    this.locationSubscription = this.primaryBodyLocation.findAll().subscribe(
      (response)=>{
        if(response.responseCode ==200){
          this.primaryLocations = response.data;
          if(this.data.editDialog)
            this.onEditForm();
          this.showModalSpinnerLoadingData=false;
        }
      }
    )
  }
  initLesionForm(){
    if(this.modifiedViewerData){
      this.maxRoi= this.calculateMaxBidimention();
    }
    this.LesionForm = new FormGroup({
      id:new FormControl(null),
      lesionObjectTypeField : new FormControl({value:this.modifiedViewerData?this.modifiedViewerData.lesionObject:this.data.lesionObject,disabled:true},[Validators.required]),
      recordNumberField : new FormControl({value:'',disabled:true},[Validators.required]),
      shortAxisField: new FormControl({value:this.maxRoi?.biDimension['Short'],disabled:!this.data.eCRFOnly},[Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)'​)]),
      longAxisField: new FormControl({value:this.maxRoi?.biDimension['Long'],disabled:!this.data.eCRFOnly},[Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')]),
      primaryLocationField: new FormControl('',[Validators.required]),
      locationCommentField: new FormControl(''),
      lesionSliceIdField: new FormControl(0),
      lesionSeriesIdField: new FormControl(''),
      screenshotId: new FormControl(null),
      storageFileName: new FormControl(''),
      storageFileNamePreview: new FormControl(''),
      storagePath: new FormControl(''),
      fileUrl: new FormControl(''),
      fileUrlPreview: new FormControl(''),
      bucketName: new FormControl(''),
      modalityName: new FormControl(''),
      sequenceLabelName: new FormControl(''),
      sliceNumber: new FormControl(''),
      PSMAExpressionGrade: new FormControl('',[Validators.required,Validators.pattern('[0-3]')]),
      PSMA_POSITIVE: new FormControl(false),
      SIGNAL_UPTAKE_VALUE: new FormControl('',[Validators.pattern('([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)')]),
    })
    this.checkMeasurementValidators();
  }
  onEditForm(){
      this.LesionForm.patchValue({
        'id':this.data.lesionData.id,
        'lesionObjectTypeField':this.data.lesionData.lesionType,
        'recordNumberField':this.data.lesionData.recordNumber,
        'shortAxisField':this.maxRoi? this.maxRoi.biDimension['Short']: this.data.lesionData.maxBiDimension?.Short,
        'longAxisField':this.maxRoi? this.maxRoi.biDimension['Long']:this.data.lesionData.maxBiDimension?.Long,
        'primaryLocationField':+this.data.lesionData.primaryLocation,
        'locationCommentField':this.data.lesionData.locationComment,
        'lesionSliceIdField':this.modifiedViewerData?this.modifiedViewerData.rois[0].sliceNumber:this.data.lesionData.sliceNumber,
        'lesionSeriesIdField':this.modifiedViewerData?this.modifiedViewerData.seriesId :this.data.lesionData.rois[0]?.seriesId,
        'PSMAExpressionGrade':this.data.lesionData.PSMAExpressionGrade,
        'PSMA_POSITIVE':this.data.lesionData.PSMA_POSITIVE,
        'SIGNAL_UPTAKE_VALUE':this.data.lesionData.SIGNAL_UPTAKE_VALUE,
      })
    //  this.subLocations = this.primaryLocations.filter(item=>{return item.id == this.data.lesionData.primaryLocation }).map(item =>{return item.bodySubLocations})[0];
    // this.LesionForm.patchValue({
    //   'subLocationField':+this.data.lesionData.subLocation,
    // })
      if(this.data.lesionData.screenShot){
        this.LesionForm.patchValue({
          'screenshotId':this.data.lesionData.screenShot.id,
        'storageFileName':this.data.lesionData.screenShot.storageFileName,
        'storageFileNamePreview':this.data.lesionData.screenShot.storageFileNamePreview,
        'storagePath':this.data.lesionData.screenShot.storagePath,
        'fileUrl':this.data.lesionData.screenShot.fileUrl,
        'fileUrlPreview':this.data.lesionData.screenShot.fileUrlPreview,
        'bucketName':this.data.lesionData.screenShot.bucketName,
        'modalityName':this.data.lesionData.screenShot.modalityName,
        'sequenceLabelName':this.data.lesionData.screenShot.sequenceLabelName,
        'sliceNumber':this.data.lesionData.screenShot.sliceNumber,
      })
      this.imageUrl = this.data.lesionData.screenShot.fileUrl
      }
  }


  validateNumber(event,maxNumber) {
    const keyCode = event.keyCode;
    if(keyCode == 16)
    event.preventDefault()

    if(event.target.value.length == maxNumber && keyCode != 8) 
     event.preventDefault()

    const excludedKeys = [8, 37, 39, 46, 190];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode > 64 && keyCode < 91) ||
      (keyCode == 32) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }


  calculateMaxBidimention(){
    if(this.modifiedViewerData){
      let maxArea =Math.max.apply(Math, this.modifiedViewerData.rois?.map(function(r) { return r['area']; }))
      return this.modifiedViewerData.rois?.filter(roi => roi['area']==maxArea)[0]
    }
    return;
  }
  checkMeasurementValidators(){
    if(this.LesionForm.get('lesionObjectTypeField').value ==PSMAPETLesionObject.NON_TARGET_LESION){
      this.LesionForm.get('shortAxisField').clearValidators();
      this.LesionForm.get('shortAxisField').updateValueAndValidity();
      this.LesionForm.get('longAxisField').clearValidators();
      this.LesionForm.get('longAxisField').updateValueAndValidity();
    }
  }
  onLocationChange(event: MatSelectChange){
    if(event.source.triggerValue ==='Other'){
      this.LesionForm.get('locationCommentField').setValidators(Validators.required);
      this.LesionForm.get('locationCommentField').updateValueAndValidity();
    }
    else{
      this.LesionForm.get('locationCommentField').clearValidators();
      this.LesionForm.get('locationCommentField').updateValueAndValidity();
    }
  }
  onChangePSMAExpressionGrade(event){
    this.LesionForm.get('PSMA_POSITIVE').setValue(event.target.value >=2);
  }
  selectFiles(event) {
    if (event.target.files) {
      this.fileAttached = event.target.files[0];
      this.imageAttached=true;
      let reader = new FileReader();
      reader.onload = (e)=> {
      this.imageUrl = e.target.result.toString();
      };
      reader.readAsDataURL(event.target.files[0]);
    }
    event.preventDefault();
  }
  attachImage(){
    if(this.data.screenShotData){
      this.imageUrl = this.data.screenShotData.image;
      this.imageAttached= true;
      this.attachMessage="";
    }
    else{
      let snapshot = this.leadtoolsActionManagerService.popupCapture();
      if(snapshot){
        this.data.screenShotData ={
          seriesId : snapshot.cellInfo.seriesId,
          image :snapshot.image,
          preview:snapshot.image,
          seriesUID : snapshot.cellInfo.seriesUID,
          sliceNumber:snapshot.sliceNum,
          modalityName:snapshot.cellInfo.modality,
          sequenceLabelName:snapshot.cellInfo.label,
        }
        this.imageUrl =this.data.screenShotData.image;
        this.imageAttached= true;
        this.attachMessage="";
      }
      else{
        this.attachMessage = "take a screenshot on Leadtools first";
        this.imageAttached= false;
      }
    }
  }
  saveImageToCloud() {
    this.showModalSpinnerLoadingData = true;
    if(this.data.eCRFOnly)
    {
      this.readingPSMAPETService.getLesionImageUploadLink(this.data.studyId, this.data.readingId,0,0,!this.data.eCRFOnly).subscribe(
        linkResp => {
          this.linkData = linkResp['data'];
          this.readingPSMAPETService.uplodFileToCloud(this.linkData.fileUrl,this.fileAttached).subscribe(resp=>{
            this.lesionData.screenShot.seriesId=0;
            this.lesionData.screenShot.sliceNumber=0;
            this.lesionData.screenShot.storageFileName =  this.linkData['storageFileName'];
            this.lesionData.screenShot.storageFileNamePreview=this.linkData['storageFileNamePreview'];
            this.lesionData.screenShot.bucketName = this.linkData['bucketName'];
            this.lesionData.screenShot.storagePath = this.linkData['storagePath'];
            this.lesionData.screenShot.fileUrl =this.linkData['fileUrl'];
            this.lesionData.screenShot.fileUrlPreview=this.linkData['fileUrlPreview'];
            this.lesionData.screenShot.modalityName = '';
            this.lesionData.screenShot.sequenceLabelName = '';
            this.showModalSpinnerLoadingData = false;
          this.dialogRef.close(this.lesionData)
          })
        }
      )
    }
    else{
      // convert base64 string to Blob object ( array of 8 bit integers)
      const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
        const byteCharacters = window.atob(b64Data);
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
          const slice = byteCharacters.slice(offset, offset + sliceSize);
          const byteNumbers = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
      };
      const blob = b64toBlob(this.data.screenShotData.image.split(',')[1], 'image/jpeg');
      const blobPreview = b64toBlob(this.data.screenShotData.preview.split(',')[1], 'image/jpeg');
      const ifSliceNum = this.data.screenShotData.sliceNumber;
      this.readingPSMAPETService
        .getLesionImageUploadLink(this.data.studyId, this.data.readingId,this.data.screenShotData.seriesId, ifSliceNum,!this.data.eCRFOnly).subscribe(linkResp => {
        this.linkData = linkResp['data'];
        // fake input form with data
        const fdImage = new FormData();
        fdImage.append('file', blob, 'filename.jpg');
        const fdImagePreview = new FormData();
        fdImage.append('file', blobPreview, 'filename_preview.jpg');
        this.readingPSMAPETService.uplodFileToCloud(this.linkData.fileUrl, blob).subscribe(imgaeUploadRes => {
          this.readingPSMAPETService.uplodFileToCloud(this.linkData.fileUrlPreview, blobPreview).subscribe(imgaePreviewUploadRes => {
            this.lesionData.screenShot.seriesId=this.data.screenShotData.seriesId;
            this.lesionData.screenShot.sliceNumber=this.data.screenShotData.sliceNumber;
            this.lesionData.screenShot.storageFileName =  this.linkData['storageFileName'];
            this.lesionData.screenShot.storageFileNamePreview=this.linkData['storageFileNamePreview'];
            this.lesionData.screenShot.bucketName = this.linkData['bucketName'];
            this.lesionData.screenShot.storagePath = this.linkData['storagePath'];
            this.lesionData.screenShot.fileUrl =this.linkData['fileUrl'];
            this.lesionData.screenShot.fileUrlPreview=this.linkData['fileUrlPreview'];
            this.lesionData.screenShot.modalityName = this.data.screenShotData.modalityName;
            this.lesionData.screenShot.sequenceLabelName = this.data.screenShotData.sequenceLabelName;
            this.showModalSpinnerLoadingData = false;
          this.dialogRef.close(this.lesionData)
          });
        });
      });
    }
  }
  OnSave(){
    this.lesionData = {
      id : this.LesionForm.get('id').value,
      visit:this.data.visit,
      recordNumber : this.LesionForm.get('recordNumberField').value,
      lesionType:this.LesionForm.get('lesionObjectTypeField').value,
      maxBiDimension:{
        Short:this.LesionForm.get('shortAxisField').value,
        Long:this.LesionForm.get('longAxisField').value
      },
      primaryLocation: this.LesionForm.get('primaryLocationField').value,
      locationComment:this.LesionForm.get('locationCommentField').value,
      status:"AAA111",
      screenShot:{
        id:this.LesionForm.get('screenshotId').value,
        storageFileName:this.LesionForm.get('storageFileName').value,
        storageFileNamePreview: this.LesionForm.get('storageFileNamePreview').value,
        storagePath: this.LesionForm.get('storagePath').value,
        fileUrl:this.LesionForm.get('fileUrl').value,
        fileUrlPreview:this.LesionForm.get('fileUrlPreview').value,
        bucketName: this.LesionForm.get('bucketName').value,
        modalityName:this.LesionForm.get('modalityName').value,
        sequenceLabelName:this.LesionForm.get('sequenceLabelName').value,
        sliceNumber:+this.LesionForm.get('sliceNumber').value,
        seriesId:+this.LesionForm.get('lesionSeriesIdField').value
       },
       rois:this.modifiedViewerData?this.modifiedViewerData?.rois:this.data.lesionData?.rois,
       PSMAExpressionGrade :this.LesionForm.get('PSMAExpressionGrade').value,
       PSMA_POSITIVE :this.LesionForm.get('PSMA_POSITIVE').value,
       SIGNAL_UPTAKE_VALUE :this.LesionForm.get('SIGNAL_UPTAKE_VALUE').value,
      }
      if(this.data.eCRFOnly){
        let longAxis = +this.LesionForm.get('longAxisField').value;
        let shortAxis =+this.LesionForm.get('shortAxisField').value;
        this.lesionData.rois=[{
          id:null,
          name: 'eCRF',
          sliceNumber: 0,
          seriesId: 0,
          biDimension: {
            Long: longAxis,
            Short: shortAxis
          }
        }]
      }
     if(this.imageUrl == '' && !this.fileAttached)
        this.lesionData.screenShot=null;
      if(this.imageAttached)
         this.saveImageToCloud();
      else
        this.dialogRef.close(this.lesionData)
}
  closeClick(){
    this.dialogRef.close();
  }

  ngOnDestroy(){
    if(this.locationSubscription)
      this.locationSubscription.unsubscribe()
  }
}
