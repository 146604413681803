import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {JSW_OARSI_ReadingModel} from '../_models/JSW/jsw-reading-model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingJSWVersion;
const SERVICE = 'msreadingjsw-dot-';
const SERVICEQC = 'msqualitycontrol-dot-';
const SERVICE_API_URL = `https://${API_VERSION}${SERVICE}${API_URL}/reading/`;
const SERVICE_API_URL_JSW = `https://${API_VERSION}${SERVICEQC}${API_URL}/radiobotics/`;

@Injectable({
  providedIn: 'root'
})
export class ReadingJSWService {

  constructor(private http: HttpClient) {
  }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getReading(studyId, readerId): Observable<BasicResponse<JSW_OARSI_ReadingModel[]>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-available-readings/${studyId}/${readerId}`);
  }

  getNumberOfAvailableModerationReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-number-of-available-moderator-readings/${studyId}/${readerId}`);
  }

  getModerationReadings(studyId, readerId): Observable<BasicResponse<JSW_OARSI_ReadingModel[]>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-available-adjudication-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds): Observable<BasicResponse<JSW_OARSI_ReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}${studyId}/${readingId}`, {spentSeconds});
  }

  updateReadingWithSaveAllData(studyId, reading): Observable<BasicResponse<JSW_OARSI_ReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}update/${studyId}`, reading);
  }

  completeReading(studyId, reading: JSW_OARSI_ReadingModel, jwtToken): Observable<BasicResponse<JSW_OARSI_ReadingModel>> {
    const customHeader = new HttpHeaders({
      'Authorization': `Bearer ${jwtToken}`
    });

    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}complete/${studyId}`, reading, {
      headers: customHeader
    });
  }

  uploadFiles(studyId, seriesId, filesForm): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${SERVICE_API_URL}upload-files/${studyId}/${seriesId}`, filesForm);
  }

  getJSWContours(studyId, visitConfigId, auth): Observable<BasicResponse<number>> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${auth}`
      })
    };
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL_JSW}studyId-` + studyId + `/visitConfigId-` + visitConfigId + `/list`, httpOptions);
  }

  updateJSWContours(studyId, radioboticsAction, auth, data){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': `Bearer ${auth}`
      }),
    };
    return this.http.patch<BasicResponse<any>>(`${SERVICE_API_URL_JSW}studyId-` + studyId + `/radioboticsAction-` + radioboticsAction + `/amend-result`, data, httpOptions);
  }
}
