import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MoaksVisitModel} from '../../../../../_models/MOAKS/moaks-visit-model';
import {MoaksPeriarticularFeaturesScoringModel} from '../../../../../_models/MOAKS/moaks-periarticular-features-scoring-model';
import {MoaksPeriarticularLigamentTendonScoringModel} from '../../../../../_models/MOAKS/moaks-periarticular-ligament-tendon-scoring-model';
import { SharedService } from '../../moaks-basic-form/shared.service';

@Component({
    selector: 'app-moaks-periarticular-scoring-form',
    templateUrl: './moaks-periarticular-scoring-form.component.html',
    styleUrls: ['./moaks-periarticular-scoring-form.component.css']
})
export class MoaksPeriarticularScoringFormComponent implements OnInit, OnDestroy {

    hoffaSynovitisScores = ['0', '1', '2', '3', 'U'];
    effusionSynovitisScores = ['0', '1', '2', '3', 'U'];
    perFeatures = ['0', '1', 'U'];
    ligTendScores = ['0', '1', '2', 'U'];
    formPerFeatures: FormGroup;
    tableDataSourcePerFeatures: MatTableDataSource<MoaksPeriarticularFeaturesScoringModel>;
    tableColumnsPerFeatures = ['Name', 'Feature'];
    formLigTendScoring: FormGroup;
    tableDataSourceLigTendScoring: MatTableDataSource<MoaksPeriarticularLigamentTendonScoringModel>;
    tableColumnsLigTendScoring = ['Name', 'LigTendScoring'];

    form: FormGroup;
    @Input()
    get visit(): MoaksVisitModel { return this._visit; }
    set visit(visit: MoaksVisitModel) {
        this._visit = visit;
        if (this.form && this.formPerFeatures && this.formLigTendScoring) { // ngOnInit() will init first time
            this.init();
        }
    }
    private _visit: MoaksVisitModel;

  @Input() uid: string;
  @Output('onValidForm') onValidForm: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private fb: FormBuilder,
        private sharedService: SharedService
    ) {
    }
    
    ngOnInit(): void {
        const scoringFormControl = this.fb.array([]);
        this._visit.periarticularScorings.forEach((scoring) => {
            scoringFormControl.push(this.fb.group({
                id: ['', [Validators.required]],
                code: ['', [Validators.required]],
                region: [''],
                scoring: ['']
            }));
        });
        this.form = this.fb.group({
            scorings: scoringFormControl
        });

        this.tableDataSourcePerFeatures = new MatTableDataSource<MoaksPeriarticularFeaturesScoringModel>();
        const scoringFormControlPerFeatures = this.fb.array([]);
        this._visit.periarticularFeaturesScorings.forEach((scoring) => {
            scoringFormControlPerFeatures.push(this.fb.group({
                id: ['', [Validators.required]],
                code: ['', [Validators.required]],
                region: ['', [Validators.required]],
                feature: ['', [Validators.required]]
            }));
        });
        this.formPerFeatures = this.fb.group({
            scorings: scoringFormControlPerFeatures
        });

        this.tableDataSourceLigTendScoring = new MatTableDataSource<MoaksPeriarticularLigamentTendonScoringModel>();
        const scoringFormControlLigTendScoring = this.fb.array([]);
        this._visit.periarticularLigamentTendonScorings.forEach((scoring) => {
            scoringFormControlLigTendScoring.push(this.fb.group({
                id: ['', [Validators.required]],
                code: ['', [Validators.required]],
                region: ['', [Validators.required]],
                ligamentTendon: ['', [Validators.required]]
            }));
        });
        this.formLigTendScoring = this.fb.group({
            scorings: scoringFormControlLigTendScoring
        });

        this.init();
        this.fillPerFeatureEmptyCellsWithZero();
        this.fillLigTendScoringEmptyCellsWithZero();

        this.sharedService.sync(this.uid, (rowIndex, valueName, value) => {
          if(valueName === 'feature') {
            this._visit.periarticularFeaturesScorings[rowIndex][valueName] = value;
            if (this.formPerFeatures) {
              (this.formPerFeatures.get('scorings') as FormArray).controls[rowIndex].get(valueName).setValue(value);
            }
          } else if(valueName === 'ligamentTendon') {
            this._visit.periarticularLigamentTendonScorings[rowIndex][valueName] = value;
            if (this.formLigTendScoring) {
              (this.formLigTendScoring.get('scorings') as FormArray).controls[rowIndex].get(valueName).setValue(value);
            }
          } else if(valueName === 'Hoffa-synovitis' || valueName === 'Effusion-synovitis') {
            this._visit.periarticularScorings[rowIndex][valueName] = value;
            if (this.form) {
              (this.form.get('scorings') as FormArray).controls[rowIndex].get('scoring').setValue(value);
            }
          }
        });
    }

    ngOnDestroy(): void {
        this.setSubmitData();
        this.sharedService.messageSource.next(null);
    }

    init(): void {
        this.tableDataSourcePerFeatures.data = this._visit.periarticularFeaturesScorings;
        let index = 0;
        ((this.formPerFeatures.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.tableDataSourcePerFeatures.data[index];
            formGroup.get('id').setValue(scoring.id);
            formGroup.get('code').setValue(scoring.code);
            formGroup.get('region').setValue(scoring.region);
            formGroup.get('feature').setValue(scoring.feature);
            if (this.shouldDisable()) {
              formGroup.disable();
            }
            index++;
        });

        this.tableDataSourceLigTendScoring.data = this._visit.periarticularLigamentTendonScorings;
        index = 0;
        ((this.formLigTendScoring.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.tableDataSourceLigTendScoring.data[index];
            formGroup.get('id').setValue(scoring.id);
            formGroup.get('code').setValue(scoring.code);
            formGroup.get('region').setValue(scoring.region);
            formGroup.get('ligamentTendon').setValue(scoring.ligamentTendon);
            if (this.shouldDisable()) {
              formGroup.disable();
            }
            index++;
        });
        index = 0;
        const data = this._visit.periarticularScorings;
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = data[index];
            formGroup.get('id').setValue(scoring.id);
            formGroup.get('code').setValue(scoring.code);
            formGroup.get('region').setValue(scoring.region);
            formGroup.get('scoring').setValue(scoring.scoring);
            if (this.shouldDisable()) {
              formGroup.disable();
            }
            index++;
        });

        this.form.valueChanges.subscribe(() => {
          this.onValidForm.emit(this.form.valid);
        })
    }

    getRegionFormControlValue(index: number): boolean {
        return ((this.form.get('scorings') as FormArray).controls as FormGroup[])[index].get('region').value;
    }

    validateScoringFormControl(index: number): boolean {
        return ((this.form.get('scorings') as FormArray).controls as FormGroup[])[index].get('scoring').hasError('required');
    }

    fillPerFeatureEmptyCellsWithZero(): void {
        ((this.formPerFeatures.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            if (!formGroup.get('feature').value) {
                formGroup.get('feature').setValue('0');
            }
        });
    }

    fillLigTendScoringEmptyCellsWithZero(): void {
        ((this.formLigTendScoring.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            if (!formGroup.get('ligamentTendon').value) {
                formGroup.get('ligamentTendon').setValue('0');
            }
        });
    }

    reset(): void {
        ((this.formPerFeatures.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            formGroup.get('feature').reset();
            formGroup.get('id').reset();
            formGroup.get('code').reset();
            formGroup.get('region').reset();
        });
        ((this.formLigTendScoring.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            formGroup.get('ligamentTendon').reset();
            formGroup.get('id').reset();
            formGroup.get('code').reset();
            formGroup.get('region').reset();
        });
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            formGroup.get('scoring').reset();
            formGroup.get('id').reset();
            formGroup.get('code').reset();
            formGroup.get('region').reset();
        });
    }

    setSubmitData() {
        ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.visit.periarticularScorings.find(value => value.id === formGroup.get('id').value);
            scoring.scoring = formGroup.get('scoring').value;
        });
        ((this.formPerFeatures.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.visit.periarticularFeaturesScorings.find(value => value.id === formGroup.get('id').value);
            scoring.feature = formGroup.get('feature').value;
        });
        ((this.formLigTendScoring.get('scorings') as FormArray).controls as FormGroup[]).forEach(formGroup => {
            const scoring = this.visit.periarticularLigamentTendonScorings.find(value => value.id === formGroup.get('id').value);
            scoring.ligamentTendon = formGroup.get('ligamentTendon').value;
        });
    }

    shouldDisable(){
      return (this._visit.status ||'') .toLowerCase() === 'done';
    }

    duplicateValue(rowIndex, item, value?) {
      this.setSubmitData();
      const uidIndex = this.uid.replace(/\D+/g, '');
      if(item === 'feature') {
        this.sharedService.messageSource.next([rowIndex, item, this._visit.periarticularFeaturesScorings[rowIndex][item], uidIndex]);
      } else if(item === 'ligamentTendon') {
        this.sharedService.messageSource.next([rowIndex, item, this._visit.periarticularLigamentTendonScorings[rowIndex][item], uidIndex]);
      } else if(item === 'Hoffa-synovitis' || item === 'Effusion-synovitis') {
        this.sharedService.messageSource.next([rowIndex, item, ((this.form.get('scorings') as FormArray).controls[rowIndex] as FormGroup).get('scoring').value, uidIndex]);
      }
    }
}
