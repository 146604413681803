export enum ReadingType {
  BASIC_READING = 'basic_reading',
  BASIC = 'Basic',

  LEVEL1_READING = 'level1_reading',
  LEVEL1 = 'Level 1',

  ADJUDICATION_READING = 'adjudication_reading',
  ADJUDICATION = 'Adjudication',

  CONSENSUS = 'Consensus',

  MODERATION_READING = 'moderation_reading',
  MODERATION = 'Moderation',

  SEQUENTIAL= 'SEQUENTIAL'
}
