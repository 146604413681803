import { Injectable } from '@angular/core';
import { Select } from '@ngxs/store';
import { AuthInfoState, AuthInfoStateModel } from '../core/data-management/states/auth-info.state';
import { Observable, Subject } from 'rxjs';
import { Path, PathAccessPermission } from '../core/constants/path';

@Injectable({
  providedIn: 'root'
})
export class AccessPermissionsService {

  @Select(AuthInfoState.getAuthInfo) authInfo: Observable<AuthInfoStateModel>;

  readonly permissionsSubject = new Subject();
  readonly permissions = {};

  constructor() {
    this.init();
  }

  private init() {
    this.authInfo.subscribe(authInfo => {
      for (const pathKey of Object.keys(Path)) {
        const path = Path[pathKey];
        this.permissions[path] = PathAccessPermission[path](authInfo);
      }
      this.permissionsSubject.next(this.permissions);
    });
  }

  public redirect(path: Path): string {
      let redirectPath = null;
      switch (path) {
          case Path.IMAGING_PROJECT:
              if (this.permissions[Path.OPERATIONAL_DASHBOARD]) {
                  redirectPath = Path.OPERATIONAL_DASHBOARD;
              } else if (this.permissions[Path.AUDIT_TRAILS]) {
                  redirectPath = Path.AUDIT_TRAILS;
              }
              break;
          case Path.OPERATIONAL_DASHBOARD:
              break;
          case Path.AUDIT_TRAILS:
              redirectPath = Path.IMAGING_PROJECT;
              break;
          case Path.APP_VERSION:
              redirectPath = Path.IMAGING_PROJECT;
              break;
      }
      return redirectPath;
  }
}
