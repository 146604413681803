// Generated by https://quicktype.io

export class OncologyCriteria {
    private readonly criteriaResponse: Keys[];
    private readonly targetLesionTemplate: LesionSettings;
    private readonly targetLesionBaselineTemplate: LesionSettings;
    private readonly formSummaryMetricList: LesionPpdMetric[];
    private readonly imageQualityQuestionTemplate: ImageQualityQuestionTemplate[];

    constructor(criteria: Keys[], baseline: boolean) {
        this.criteriaResponse = criteria;

        this.imageQualityQuestionTemplate = this.criteriaResponse.find(object => {
            return object.key === 'ImageQualityQuestionTemplate'
        }).value as ImageQualityQuestionTemplate[];

        this.targetLesionTemplate = this.criteriaResponse.find(object => {
            return (object.key === 'TargetLesionBaselineTemplate' && baseline) ||
                (object.key === 'TargetLesionFollowUpTemplate' && !baseline)
        }).value as LesionSettings;

        this.targetLesionBaselineTemplate = this.criteriaResponse.find(object => {
            return (object.key === 'TargetLesionBaselineTemplate')
        }).value as LesionSettings;

        this.formSummaryMetricList = this.targetLesionTemplate.FormSummaryMetricList;

    }
    QuestionTitle() {
        return this.imageQualityQuestionTemplate[0]?.QuestionTitle;
    }
    QuestionOptions() {
        return this.imageQualityQuestionTemplate[0]?.QuestionOptions;
    }
    CommentTitle() {
        return this.imageQualityQuestionTemplate[1]?.QuestionTitle;
    }
    QuestionFreeTextMaxCharacters() {
        return this.imageQualityQuestionTemplate[1]?.QuestionFreeTextMaxCharacters;
    }
    mainLocation() {
        return this.targetLesionTemplate?.LesionLocation.mainLocation;
    }
    subLocation() {
        return this.targetLesionTemplate.LesionLocation.subLocation;
    }
    LesionLocationAllowModify() {
        return this.targetLesionTemplate.LesionLocation.LesionLocationAllowModify === 'TRUE';
    }
    LesionAllowFreeTextEntry() {
        return this.targetLesionTemplate.LesionComments.LesionAllowFreeTextEntry === 'TRUE';
    }
    LesionFreeTextMaxCharacters() {
        return this.targetLesionTemplate.LesionComments.LesionFreeTextMaxCharacters;
    }
    ALD() {
       return this.targetLesionTemplate.LesionMeasurementList[0].MeasurementName[0]
    }
    ALDUnit() {
        return this.targetLesionTemplate.LesionMeasurementList[0].MeasurementUnit[0];
    }
    ALDdescription() {
        return this.targetLesionTemplate?.LesionMeasurementList[0].MeasurementDescription[0];
    }
    SAX() {
        return this.targetLesionTemplate.LesionMeasurementList[0].MeasurementName[1];
    }
    SAXUnit() {
        return this.targetLesionTemplate.LesionMeasurementList[0].MeasurementUnit[1];
    }
    SAXdescription() {
        return this.targetLesionTemplate?.LesionMeasurementList[0].MeasurementDescription[1];
    }
    SPPDUnit() {
        return this.formSummaryMetricList[0].Unit;
    }
    SPPDName() {
        return this.formSummaryMetricList[0].Name;
    }
    SPPDBaselineUnit() {
        return this.formSummaryMetricList[1].Unit;
    }
    SPPDBaselineName() {
        return this.formSummaryMetricList[1].Name;
    }
    SPPDNadirUnit() {
        return this.formSummaryMetricList[2].Unit;
    }
    SPPDNadirName() {
        return this.formSummaryMetricList[2].Name;
    }
    PPDUnit() {
        return this.targetLesionTemplate.LesionPpdMetric.Unit;
    }
    PPDName() {
        return this.targetLesionTemplate.LesionPpdMetric.Name;
    }
    PPDNadirUnit() {
        return this.targetLesionTemplate.LesionPpdChangeMetric.Unit;
    }
    PPDNadirName() {
        return this.targetLesionTemplate.LesionPpdChangeMetric.Name;
    }
    AddLesions() {
        return this.targetLesionTemplate.AddLesions === 'TRUE';
    }
    LesionIDMaxNumber() {
        return this.targetLesionTemplate.LesionID ? this.targetLesionTemplate.LesionID.LesionIDMaxNumber : -1;
    }
    LesionIDPrefix() {
        return this.targetLesionTemplate.LesionID?.LesionIDPrefix;
    }
    LesionIDCopyFromBaseline() {
        return this.targetLesionTemplate.LesionID?.LesionIDCopyFromBaseline === 'TRUE';
    }
    BaselineLesionIDPrefix() {
        return this.targetLesionBaselineTemplate.LesionID?.LesionIDPrefix;
    }
    ALDDecimal() {
        return this.targetLesionTemplate.LesionMeasurementList[0].MeasurementNumberOfDecimalPlaces[0];
    }
    SAXDecimal() {
        return this.targetLesionTemplate.LesionMeasurementList[0].MeasurementNumberOfDecimalPlaces[1];
    }
    PPDDecimal() {
        return this.targetLesionTemplate.LesionPpdMetric.NumberOfDecimalPlaces;
    }
}

export interface Keys {
    id:      number;
    project: string;
    key:     string;
    value:   ImageQualityQuestionTemplate[] | LesionSettings;
}

export interface ImageQualityQuestionTemplate {
    Name?:                          string;
    Description?:                   string;
    Unit?:                          string;
    NumberOfDecimalPlaces?:         string;
    QuestionOptionType?:            string;
    QuestionTitle?:                 string;
    QuestionOptions?:               string[];
    QuestionFreeTextMaxCharacters?: string;
}

export interface LesionSettings {
    AddLesions?:                 string;
    LesionID?:                   LesionID;
    LesionLocation?:             LesionLocationClass;
    LesionComments?:             LesionComments;
    LesionMeasurementList?:      LesionMeasurementList[];
    LesionPpdMetric?:            LesionPpdMetric;
    LesionPpdChangeMetric?:      LesionPpdMetric;
    FormSummaryMetricList?:      LesionPpdMetric[];
    LesionStatusSummary?:        LesionStatusSummary;
    ScoringTemplateName?:        string;
    ScoringTemplateDescription?: string;
    ProtocolNumber?:             string;
    ImagingCharterLocation?:     string;
    HangingProtocolList?:        string;
}

export interface LesionPpdMetric {
    Name:                  string;
    Description:           string;
    Unit:                  string;
    NumberOfDecimalPlaces: string;
}

export interface LesionComments {
    LesionAllowFreeTextEntry:    string;
    LesionFreeTextMaxCharacters: string;
}

export interface LesionID {
    LesionIDPrefix?:           string;
    LesionIDMaxNumber?:        string;
    LesionIDCopyFromBaseline?: string;
}

export interface LesionLocationClass {
    LesionLocationAllowModify: string;
    mainLocation:              string[];
    subLocation:               string[];
}

export interface LesionMeasurementList {
    LesionMeasurementType:            string;
    MeasurementName:                  string[];
    MeasurementDescription:           string[];
    MeasurementUnit:                  string[];
    MeasurementNumberOfDecimalPlaces: string[];
}

export interface LesionStatusSummary {
    Name:        string;
    Description: string;
    OptionType:  string;
    Options:     string[];
}
