import { Injectable } from '@angular/core';
import { forkJoin, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ObjectRetrieveService } from '../interfaces/viewer/object-retrieve-service';
import { DicomLoaderService } from '../interfaces/viewer/dicom-loader-service';
import { ViewerAuthenticationService } from '../interfaces/viewer/viewer-authentication-service';
import { DicomHelperService } from '../interfaces/viewer/dicom-helper-service';
import { ActionManagerService } from '../interfaces/viewer/action-manager-service';
import { QueryArchiveService } from '../interfaces/viewer/query-archive.service';
import { SeriesManagerService } from '../interfaces/viewer/series-manager.service';
import { LeadToolsUtils, Utils } from './lead-tools-utils';
import { OverlayManagerService } from '../interfaces/viewer/overlay-manager-service';
import { QueryOptions } from 'src/app/_models/viewer/QueryOptions';
import { DicomTag } from '../interfaces/viewer/dicom-tag';
import { ImagingProjectService } from '../imaging-project.service';
import get from 'lodash/get';
import { ViewerAuditableContext } from 'src/app/_models/viewer/ViewerConfigModel';

const ROOT_URL = environment.Leadtools.rootURL;
const SERVICES = environment.Leadtools.services;

@Injectable({
  providedIn: 'root'
})
export class LeadToolsDicomLoaderService implements DicomLoaderService {

  private cellReadySubj = new Subject<any>();
  private _retrieveLocalUrl;
  public cellReady = this.cellReadySubj.asObservable();

  private scrollData = {};
  private frameOffsetsSubj = new Subject<any>();
  private frameOffsets = this.frameOffsetsSubj.asObservable();

  public annotationIsLoadedSubj = new Subject<any>();
  public annotationIsLoaded = this.annotationIsLoadedSubj.asObservable();

  public seriesSynchronization = { 'ALL': { method: 'Auto', enabled: false, matchingMap: null } };
  public synchControls = {
    SlicePositionSynch: { 'enabled': true },
    PanZoomSynch: { 'enabled': true },
    WindowLevelSynch: { 'enabled': false },
    IntraVisitSynch: { 'enabled': false },
    DisableAllSynch: { 'enabled': false }
  };
  public viewerComponent;
  private mouseoverCell;
  public backupSeriesInfo;
  public backupSeriesSliceNumber;

  constructor(
    private utils: Utils,
    private ltUtils: LeadToolsUtils,
    private overlayManagerService: OverlayManagerService,
    private seriesManagerService: SeriesManagerService,
    private queryArchiveService: QueryArchiveService,
    private objectRetrieveService: ObjectRetrieveService,
    private dicomHelperService: DicomHelperService,
    private actionManagerService: ActionManagerService,
    private authenticationService: ViewerAuthenticationService,
    private imagingProjectService: ImagingProjectService
  ) {
    this._retrieveLocalUrl = ROOT_URL + '/' + SERVICES.objectRetrieveLocalServiceName;
  }

  flattenObjOfArr(obj): string[] {
    return Object.values(obj).flat() as string[];
  }

  loadSeries(cell, stackInstanceUIDs: string[], seriesInfo, shouldShowDICOM, audit?: ViewerAuditableContext) {
    let bucket;
    const project = JSON.parse(localStorage.getItem('project'));
    if (project != null) {
      bucket = seriesInfo['bucket'];
    } else {
      console.warn('No project data from localStorage');
      throw new Error(`Can't get bucket info`);
    }
    cell.SortOrderAcsending = true;

    this.seriesManagerService.addSeriesCell(cell);
    this.seriesManagerService.setSeriesInfo(cell, seriesInfo);
    this.actionManagerService.intializeActions(cell);

    this.scrollData[cell.divID] = { 'cur_offset': cell.currentOffset, 'new_offset': -1 };

    // set sort data
    this.actionManagerService.sortData[cell.divID] = {
      'AcquisitionTime': { enabled: false, order: 'ASC' },
      'Axis': { enabled: true, order: 'ASC' },
      'InstanceNumber': { enabled: false, order: 'ASC' },
    };

    return this.objectRetrieveService.getDCEStacks(bucket, seriesInfo.id).toPromise().then((data: any) => {
      if (data != null) {
        try {
          if (data.seriesInstanceUIDs.length !== data.AqTimes.length) {
            const seriesId = seriesInfo.seriesId;
            const seriesinstanceUID = seriesInfo.seriesInstanceUID;
            const bucket = seriesInfo.bucket;
            this.objectRetrieveService.customUpload(bucket, seriesId, seriesinstanceUID, audit).subscribe((resultT: any) => {
              if (resultT ===  'DONE') {
                return this.load_as_dce(cell, data, seriesInfo, shouldShowDICOM);
              }
            });
          } else {
            return this.load_as_dce(cell, data, seriesInfo, shouldShowDICOM);
          }
        } catch (error) {
        }
      } else {
        console.log(`Warn > can't find DCE data, so open series as 3D volume`);
        return this.load_as_3d(cell, stackInstanceUIDs, seriesInfo, shouldShowDICOM);
      }
    });
  }

  load_as_dce(cell, data, seriesInfo, shouldShowDICOM) {
    const seriesInstanceUID: string = cell.get_seriesInstanceUID();
    const id: string = cell.divID;
    const isDCE = true;

    const numbOfFrames = data.NumberOfFrame;
    const numbOfSlices = data.sopInstanceUIDs[1].length;
    const stackInstanceUIDs = this.flattenObjOfArr(data.sopInstanceUIDs);
    const seriesInstanceUIDs = [...Object.values(data.seriesInstanceUIDs)];

    seriesInfo.numbOfFrames = numbOfFrames;
    seriesInfo.numbOfSlices = numbOfSlices;
    seriesInfo.aqTimes = data.AqTimes;
    seriesInfo.triggerTimes = data.TriggerTimes;
    seriesInfo.isDCE = isDCE;
    seriesInfo.shouldShowDICOM = shouldShowDICOM;

    this.overlayManagerService.initializeCellOverlay(cell);
    this.seriesManagerService.setSeriesInfo(cell, seriesInfo);
    return this.getInfo(cell, seriesInstanceUID, id, stackInstanceUIDs, isDCE, seriesInstanceUIDs, numbOfSlices);
  }

  load_as_3d(cell, stackInstanceUIDs, seriesInfo, shouldShowDICOM) {
    const seriesInstanceUID: string = cell.get_seriesInstanceUID();
    const id: string = cell.divID;
    const isDCE = false;

    seriesInfo.numbOfSlices = stackInstanceUIDs.length;
    seriesInfo.shouldShowDICOM = shouldShowDICOM;
    seriesInfo.isDCE = isDCE;

    this.overlayManagerService.initializeCellOverlay(cell);

    this.seriesManagerService.setSeriesInfo(cell, seriesInfo);
    return this.getInfo(cell, seriesInstanceUID, id, stackInstanceUIDs, isDCE, null, null);
  }

  setActiveCell(id: string) {
    this.seriesManagerService.setActiveCell(id);
  }

  getInfo(cell: any, seriesInstanceUID: string, id: string, stackInstanceUIDs: string[], isDCE: boolean, seriesInstanceUIDs: any, numbOfSlices: number) {
    if (isDCE) {
      return this.findDCEInstances(seriesInstanceUIDs, id, numbOfSlices, stackInstanceUIDs).then((result) => {
        return this.getDicomJSON(result, id).then((data) => {
          this.setImageInformation(seriesInstanceUIDs[0], id);
          // disable the cell spinner loader
          this.disableSpinner(id);
          return {'isDCE': isDCE};
        });
      });
    } else {
      return this.findSeriesInstances(seriesInstanceUID, id, stackInstanceUIDs).then((result) => {
        try {
          // handle multiframe series
          const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
          const numInstances = result.instances.length;
          const numFrames = result.instances[0].NumberOfFrames;
          const numSlices = numInstances * numFrames;

          if (numFrames > 1) {
            seriesInfo.isDCE = true;
            seriesInfo.numbOfFrames = numFrames;
            seriesInfo.numbOfSlices = numInstances;

            seriesInfo.aqTimes = {};
            for (let index = 0; index < numFrames; index++) {
              seriesInfo.aqTimes[index] = 0;
            }
          } else {
            seriesInfo.numbOfSlices = numSlices;
          }

          this.backupSeriesSliceNumber = numSlices;
          this.backupSeriesInfo = seriesInfo;

          this.seriesManagerService.setSeriesInfo(cell, seriesInfo);

          return this.getDicomJSON(result, id).then((data) => {
            this.setImageInformation(seriesInstanceUID, id);
            // disable the cell spinner loader
            this.disableSpinner(id);
            return {'isDCE': isDCE};
          });
        } catch (error) {
          console.log('Error... ', error);
          if (this.backupSeriesInfo !== undefined && cell.seriesId === this.backupSeriesInfo.seriesId) {
            this.backupSeriesInfo.numbOfSlices = this.backupSeriesSliceNumber;
            this.seriesManagerService.setSeriesInfo(cell, this.backupSeriesInfo);
          }
        }
      });
    }
  }

  loadPresentationState(result, id, stackInstanceUIDs) {
    const __this = this;
    return this.queryArchiveService.findPresentationState(result.seriesInstanceUID)
      .toPromise().then(function (data) {
        let annotations = null;
        let annotationsOriginal = data;
        if(annotationsOriginal.length > 1) {
          let axialID = '';
          let axialDate = '';
          let coronalID = '';
          let coronalDate = '';
          let sagittalID = '';
          let sagittalDate = '';
          let noneID = '';
          let noneDate = '';
          for (let i = 0; i < annotationsOriginal.length; i++) {
            const annotation = annotationsOriginal[i];
            if(annotation.ContentDescription === 'Axial'){
              if(axialID === '') {
                axialID = annotation.SeriesInstanceUID;
                axialDate = annotation.CreationDate;
              } else if(Date.parse(annotation.CreationDate) > Date.parse(axialDate)) {
                axialID = annotation.SeriesInstanceUID;
                axialDate = annotation.CreationDate;
              }
            } else if(annotation.ContentDescription === 'Coronal'){
              if(coronalID === '') {
                coronalID = annotation.SeriesInstanceUID;
                coronalDate = annotation.CreationDate;
              } else if(Date.parse(annotation.CreationDate) > Date.parse(coronalDate)) {
                coronalID = annotation.SeriesInstanceUID;
                coronalDate = annotation.CreationDate;
              }
            } else if(annotation.ContentDescription === 'Sagittal'){
              if(sagittalID === '') {
                sagittalID = annotation.SeriesInstanceUID;
                sagittalDate = annotation.CreationDate;
              } else if(Date.parse(annotation.CreationDate) > Date.parse(sagittalDate)) {
                sagittalID = annotation.SeriesInstanceUID;
                sagittalDate = annotation.CreationDate;
              }
            } else if(annotation.ContentDescription === ''){
              if(noneID === '') {
                noneID = annotation.SeriesInstanceUID;
                noneDate = annotation.CreationDate;
              } else if(Date.parse(annotation.CreationDate) > Date.parse(noneDate)) {
                noneID = annotation.SeriesInstanceUID;
                noneDate = annotation.CreationDate;
              }
            }
          }
          let annotationsTemp: any[] = [];
          for (let i = 0; i < annotationsOriginal.length; i++) {
            const annotation = annotationsOriginal[i];
            if(annotation.SeriesInstanceUID === axialID && annotation.ContentDescription === 'Axial' && annotation.CreationDate === axialDate) {
              annotationsTemp.push(annotation);
            } else if(annotation.SeriesInstanceUID === coronalID && annotation.ContentDescription === 'Coronal' && annotation.CreationDate === coronalDate) {
              annotationsTemp.push(annotation);
            } else if(annotation.SeriesInstanceUID === sagittalID && annotation.ContentDescription === 'Sagittal' && annotation.CreationDate === sagittalDate) {
              annotationsTemp.push(annotation);
            } else if(annotation.SeriesInstanceUID === noneID && annotation.ContentDescription === '' && annotation.CreationDate === noneDate) {
              annotationsTemp.push(annotation);
            }
          }
          annotations = annotationsTemp;
        } else {
          annotations = annotationsOriginal;
        }
        __this.onPresentationStateLoaded({
          seriesInstanceUID: result.seriesInstanceUID,
          annotations: annotations, id: id,
          sopInstanceUIDs: stackInstanceUIDs,
        });
      });
  }

  enableSpinner(cell) {
    const loadingDiv = this.createSpinner(cell) as HTMLDivElement;
    if (loadingDiv != null) {
      loadingDiv.classList.remove('overlay-hidden');
      cell.div.appendChild(loadingDiv);
    } else {
      console.log('loadingDiv, null found');
    }
  }

  disableSpinner(id) {
    try {
      let cell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(id);
      let loadingDiv = document.getElementById(cell.divID + "_cell-spinner") as HTMLDivElement;
      if (loadingDiv != null) {
        loadingDiv.classList.add('overlay-hidden');
        try {
          cell.div.removeChild(loadingDiv);
        } catch (e) {

        }
        // loadingDiv.id = '';
      }
    } catch (error) {

    }
  }

  createSpinner(cell) {
    const loadingDiv = document.createElement('div');
    const new_spinner_id = cell.divID + '_cell-spinner';

    const spinnerStr = '<div _ngcontent-sbq-c361=\'\' id=\'' + new_spinner_id + '\' class=\'modal-spinner overlay-hidden\'><div _ngcontent-sbq-c361=\'\' class=\'container-spinner overlay-hidden\'><mat-spinner _ngcontent-sbq-c361=\'\' role=\'progressbar\' mode=\'indeterminate\' diameter=\'50\' color=\'Warn\' class=\'mat-spinner mat-progress-spinner mat-Warn mat-progress-spinner-indeterminate-animation\' ng-reflect-diameter=\'50\' ng-reflect-color=\'Warn\' style=\'width: 50px; height: 50px;\'><svg preserveAspectRatio=\'xMidYMid meet\' focusable=\'false\' ng-reflect-ng-switch=\'true\' viewBox=\'0 0 45 45\' style=\'width: 50px; height: 50px;\'><circle cx=\'50%\' cy=\'50%\' r=\'20\' class=\'ng-star-inserted\' style=\'animation-name: mat-progress-spinner-stroke-rotate-50; stroke-dasharray: 125.664px; stroke-width: 10%;\'></circle><!--bindings={  \'ng-reflect-ng-switch-case\': \'true\'}--><!--bindings={  \'ng-reflect-ng-switch-case\': \'false\'}--></svg></mat-spinner></div></div>';
    loadingDiv.innerHTML = spinnerStr.trim();
    return loadingDiv.firstChild;
  }

  findDCEInstancesWithPromise(seriesInstanceUIDs: string[], id: string,
                              numbOfSlices: number, stackInstanceUIDs?: string[]) {
    const __this = this;
    const query: QueryOptions = new QueryOptions();

    let instances: Array<any>;
    const output: any = [];

    const functionWithPromise = (seriesInstanceUID, mapIndex) => {
      const stackInstanceUID = typeof stackInstanceUIDs !== 'undefined' && (stackInstanceUIDs != null) && stackInstanceUIDs.length > 0 ? stackInstanceUIDs[mapIndex * numbOfSlices] : '';
      query.SeriesOptions.SeriesInstanceUID = seriesInstanceUID;

      return this.queryArchiveService.findInstances(query, stackInstanceUID).toPromise().then(function (data) {

        if (stackInstanceUIDs && stackInstanceUIDs.length > 0) {
          let index = 0;
          const length = stackInstanceUIDs.length;
          let j;

          for (index = 0; index < length; index++) {
            for (j = 0; j < data.length; j++) {
              if (data[j] != null) {
                if (stackInstanceUIDs[index] == data[j].SOPInstanceUID) {
                  output.add(data[j]);
                  break;
                }
              }
            }
          }
          instances = output;
        }

        __this.onInstancesFound({
          seriesInstanceUID: seriesInstanceUID,
          instances: instances, id: id,
          stackInstanceUID: stackInstanceUID
        });
        return { seriesInstanceUID: seriesInstanceUID, instances: instances, id: id };
      });
    };

    const anAsyncFunction = async (seriesInstanceUID, mapIndex) => {
      return functionWithPromise(seriesInstanceUID, mapIndex);
    };

    return Promise.all(seriesInstanceUIDs.map(
      (seriesInstanceUID, mapIndex) => anAsyncFunction(seriesInstanceUID, mapIndex)));
  }

  filterInstances(data, stackInstanceUIDs) {
    let instances: Array<any> = data;
    const output: any = [];

    let index = 0;
    const length = stackInstanceUIDs.length;
    let j;

    for (index = 0; index < length; index++) {
      for (j = 0; j < instances.length; j++) {
        if (instances[j] != null) {
          if (stackInstanceUIDs[index] == instances[j].SOPInstanceUID) {
            output.add(instances[j]);
            break;
          }
        }
      }
    }
    return output;
  }

  findDCEInstances(seriesInstanceUIDs: string[], id: string,
                   numbOfSlices: number, stackInstanceUIDs?: string[]) {
    const query: QueryOptions = new QueryOptions();
    const stackInstanceUID = typeof stackInstanceUIDs !== 'undefined' && (stackInstanceUIDs != null) && stackInstanceUIDs.length > 0 ? stackInstanceUIDs[0] : '';

    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(id);

    return this.queryArchiveService.findDCEInstances(query, seriesInstanceUIDs, (cell as any).seriesInstanceUID, null, (cell as any).seriesId)
      .toPromise().then((data) => {
        let instances: Array<any> = data;
        this.onInstancesFound({
          seriesInstanceUID: cell.seriesInstanceUID,
          instances: instances, id: id,
          stackInstanceUID: stackInstanceUID
        });
        return { seriesInstanceUID: cell.seriesInstanceUID, instances: instances, id: id };
      });
  }

  findSeriesInstances(seriesInstanceUID: string, id: string, stackInstanceUIDs?: string[]) {
    const query: QueryOptions = new QueryOptions();
    const stackInstanceUID = typeof stackInstanceUIDs !== 'undefined' && (stackInstanceUIDs != null) && stackInstanceUIDs.length > 0 ? stackInstanceUIDs[0] : '';

    query.SeriesOptions.SeriesInstanceUID = seriesInstanceUID;
    return this.queryArchiveService.findInstances(query, stackInstanceUID).toPromise().then((data) => {
      let instances: Array<any> = data;
      try {
        const instancesTemp = instances;
        let instancesSorted = [];
        let instancesSortedFinal = [];
        instancesSorted.length = instancesTemp.length;
        for (let index = 0; index < instancesTemp.length; index++) {
          let instanceNumber = Number(instancesTemp[index].InstanceNumber as string);
          instancesSorted[instanceNumber - 1] = instancesTemp[index];
        }
        instancesSorted.forEach(instanceX => {
          instancesSortedFinal.push(instanceX);
        });
        instances = instancesSortedFinal;

      } catch (error) {
        console.log('error... ', error);
      }
      this.onInstancesFound({
        seriesInstanceUID: seriesInstanceUID,
        instances: instances,
        id: id,
        stackInstanceUID: stackInstanceUID
      });
      return { seriesInstanceUID: seriesInstanceUID, instances: instances, id: id };
    });
  }

  getDicomJSON(result, id: string) {
    const instance = result.instances[0];

    return this.objectRetrieveService.getDicomJSON(instance.StudyInstanceUID, instance.SeriesInstanceUID, instance.SOPInstanceUID).toPromise()
      .then((data) => {
        let metadata;
        try {
          metadata = JSON.parse(data);
        } catch (error) {
          let regExp = /-\.(\d*)/g;
          let matchAll = data.matchAll(regExp);
          matchAll = Array.from(matchAll);
          matchAll.forEach(match => {
            data = data.replace(match[0], '-0.' + match[1]);
          });
          metadata = JSON.parse(data);
        }
        this.onDicomJSONRetrieved({
          seriesInstanceUID: result.seriesInstanceUID,
          instances: result.instances,
          metadata: metadata,
          id: id,
          sopInstanceUID: instance.SOPInstanceUID
        });
        // __this._eventService.publish(EventNames.LoadedDicomJSON, { seriesInstanceUID: result.seriesInstanceUID, cellFrame: null, id: id });
        return { seriesInstanceUID: result.seriesInstanceUID, instances: result.instances, metadata: metadata };
      });
  }

  loadFrameDicomJSON(cellFrame, frameIndex?: number, json?) {
    if (json) {
      return new Promise<any>(resolve => {
        return this.updateJSON(cellFrame, json, frameIndex);
      });
    } else {
      return this.objectRetrieveService.getDicomJSON(cellFrame.Instance.StudyInstanceUID, cellFrame.Instance.SeriesInstanceUID, cellFrame.Instance.SOPInstanceUID).toPromise()
        .then((data) => {
          let metadata;
          try {
            metadata = JSON.parse(data);
          } catch (error) {
            let regExp = /[^0-9](\.\d*)/g;
            let matchAll = data.matchAll(regExp);
            matchAll = Array.from(matchAll);
            matchAll.forEach(match => {
              data = data.replace(match[0], '0' + match[1]);
            });
          metadata = JSON.parse(data);
          }
          return this.updateJSON(cellFrame, metadata);
        });
    }
  }

  updateJSON(cellFrame, metadata, frameIndex?: number) {
    if (metadata != null) {
      const cell: lt.Controls.Medical.Cell = cellFrame.parentCell;
      const information = this.dicomHelperService.getDicomImageInformation(metadata, frameIndex);

      this.addLabels(cell, metadata);
      // __this.UpdateCineSettings(cell, metadata); // TO-DO

      cellFrame.set_imagePosition(information.position);
      if (information.orientation) {
        cellFrame.imageOrientation = information.orientation;
      }
      try {
        cellFrame.viewPosition = information.viewPosition;
      } catch (error) {

      }
      cellFrame.set_imageType(information.imageType);
      cellFrame.set_lossyCompression(information.lossyImageCompression);
      cellFrame.metadata = metadata;
      cellFrame.JSON = metadata;
      cellFrame.isWaveForm = information.isWaveForm;
      cellFrame.set_information(information);
      const laterality: string = this.getLateralityValue(metadata);
      if (laterality != '') {
        cellFrame.laterality = laterality;
      }
      cellFrame.set_rowSpacing(information.rowSpacing);
      cellFrame.set_columnSpacing(information.columnSpacing);
      cellFrame.originalSize = { width: information.get_width(), height: information.get_height() };

      // __this._eventService.publish(EventNames.LoadedDicomJSON, { seriesInstanceUID: cellFrame.Instance.SeriesInstanceUID, cellFrame: cellFrame });
      return { xmlData: metadata, cellFrame: cellFrame };
    }
  }

  getLateralityValue(metadata) {
    let element;

    element = metadata[DicomTag.Laterality];
    if (element && element.Value && element.Value.length > 0) {
      return this.dicomHelperService.getTagText(element);
    }

    element = metadata[DicomTag.ImageLaterality];
    if (element && element.Value && element.Value.length > 0) {
      return this.dicomHelperService.getTagText(element);
    }

    element = metadata[DicomTag.FrameLaterality];
    if (element && element.Value && element.Value.length > 0) {
      return this.dicomHelperService.getTagText(element);
    }
    return '';
  }

  addLabels(item, metadata) {
    if (item.forCompare) {
      const patientId = this.dicomHelperService.getPatientName(metadata, DicomTag.PatientName);
      const StudyDescription = this.dicomHelperService.getTagTextValue(metadata, DicomTag.StudyDescription);
      const StudyDate = this.dicomHelperService.parseDicomDate(this.dicomHelperService.getTagTextValue(metadata, DicomTag.StudyDate));
      const SeriesDate = this.dicomHelperService.parseDicomDate(this.dicomHelperService.getTagTextValue(metadata, DicomTag.SeriesDate));
      const SeriesDescription = this.dicomHelperService.getTagTextValue(metadata, DicomTag.SeriesDescription);

      const cell: lt.Controls.Medical.Cell = item;

      if (cell.labels.count != 0) {
        return;
      }

      cell.labels.add(this.addTag(patientId, 0));
      cell.labels.add(this.addTag(StudyDescription, 2));
      cell.labels.add(this.addTag(StudyDate, 3));
      cell.labels.add(this.addTag(SeriesDescription, 5));
      cell.labels.add(this.addTag(SeriesDate, 6));
    }
  }

  addTag(text: string, index: number) {
    const newOverlay: lt.Controls.Medical.OverlayText = new lt.Controls.Medical.OverlayText();

    newOverlay.text = text;
    newOverlay.type = lt.Controls.Medical.OverlayTextType.userData;
    newOverlay.positionIndex = index;
    newOverlay.weight = 1;
    newOverlay.alignment = lt.Controls.Medical.OverlayAlignment.topLeft;

    return newOverlay;
  }

  setImageInformation(seriesInstanceUID, id: string) {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(id);

    if (cell != null && cell.frames.count > 0) {
      const instances: any[] = this.seriesManagerService.getInstances(cell.seriesInstanceUID, id);
      const frameArray: Array<lt.Controls.Medical.Frame> = cell.frames.toArray();

      if (instances) {
        const instanceLength: number = instances.length;
        let lastIndex = 0;
        const numFrames = instances[0].NumberOfFrames;

        for (let instanceCounter = 0; instanceCounter < instanceLength; instanceCounter++) {
          const instance = instances[instanceCounter];

          if (instance != null && instance.Pages != null && instance.Pages.length > 0) {
            const framesLength = instance.Pages.length;
            let currentPageInfo;
            let pageInfo = instance.Pages[0];

            for (let index = 0; index < framesLength; index++) {
              if ((index + lastIndex) < cell.frames.count) {
                let cellFrame: lt.Controls.Medical.Frame;

                if (numFrames == 1) {
                  cellFrame = this.ltUtils.findFirst(frameArray, (frame: lt.Controls.Medical.Frame) => {
                    const cellFrameInstance = (<any>frame).Instance;

                    if (cellFrameInstance) {
                      return cellFrameInstance.SOPInstanceUID == instance.SOPInstanceUID;
                    }
                    return false;
                  });
                } else {
                  cellFrame = cell.frames.item(index + lastIndex);
                }

                currentPageInfo = instance.Pages[index];
                if (currentPageInfo != null) {

                  if (pageInfo.TileSize.width != 0) {
                    pageInfo = currentPageInfo;
                  }
                }

                const supportWindowLevel = pageInfo.SupportWindowLevel;

                if (pageInfo.ImagePositionPatientArray != null) {
                  cellFrame.set_imagePosition(pageInfo.ImagePositionPatientArray);
                }

                if (pageInfo.ImageOrientationPatientArray != null) {
                  cellFrame.set_imageOrientation(pageInfo.ImageOrientationPatientArray);
                }

                if (pageInfo.PatientOrientation != null) {
                  try {
                    cellFrame.set_patientProjection(pageInfo.PatientOrientation);
                  } catch (error) {
                    if (pageInfo.PatientOrientation && pageInfo.PatientOrientation.length > 0) {
                      if(pageInfo.PatientOrientation[0].length == 2) {
                        cellFrame.set_patientProjection([pageInfo.PatientOrientation[0][0], pageInfo.PatientOrientation[0][1]]);
                      }
                    }
                  }
                }

                if (pageInfo.PixelSpacingPatientArray != null && pageInfo.PixelSpacingPatientArray.length == 2) {
                  cellFrame.set_rowSpacing(parseFloat(pageInfo.PixelSpacingPatientArray[1]));
                  cellFrame.set_columnSpacing(parseFloat(pageInfo.PixelSpacingPatientArray[0]));
                }

                {
                  const mrtiInfo: lt.Controls.Medical.MRTIImage = new lt.Controls.Medical.MRTIImage();

                  mrtiInfo.imageUri = this._retrieveLocalUrl + '/GetImageTile?auth=' + encodeURIComponent(this.authenticationService.authenticationCode) + '&instance=' + instance.SOPInstanceUID;

                  mrtiInfo.imageName = pageInfo.ImageName;
                  mrtiInfo.mimeType = pageInfo.MimeType;

                  mrtiInfo.fullDpi = lt.LeadSizeD.create(150, 150);
                  mrtiInfo.tileSize = lt.LeadSizeD.create(pageInfo.TileSize.width, pageInfo.TileSize.height);
                  mrtiInfo.frameIndex = index;
                  mrtiInfo.supportWindowLevel = supportWindowLevel && !cellFrame.isWaveForm;

                  mrtiInfo.resolutions = [];
                  const pageInfo_len = pageInfo.Resolutions.length;
                  for (let i = 0; i < pageInfo_len; i++) {
                    mrtiInfo.resolutions[i] = lt.LeadSizeD.create(pageInfo.Resolutions[i].width, pageInfo.Resolutions[i].height);
                  }
                  // mrtiInfo.resolutions[0] = lt.LeadSizeD.create(pageInfo.Resolutions[pageInfo_len - 1].width, pageInfo.Resolutions[pageInfo_len - 1].height);

                  cellFrame.set_width(mrtiInfo.resolutions[0].width);
                  cellFrame.set_height(mrtiInfo.resolutions[0].height);
                  mrtiInfo.fullSize = lt.LeadSizeD.create(cellFrame.get_width(), cellFrame.get_height());
                  cellFrame.mrtiInfo = mrtiInfo;
                }
              }
            }
            lastIndex += framesLength;
          }
        }
      } else {
        setTimeout($.proxy(function () {
          this.setImageInformation(seriesInstanceUID, id);
        }, this), 350);
      }
    }
  }

  loadImages(seriesInstanceUID: string, id: string, metadata, dataSopInstanceUID: string) {
    const instances: any[] = this.seriesManagerService.getInstances(seriesInstanceUID, id);
    let framesLength = 0;
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(id);
    let information;
    const LazyLoadingThreshold = environment.Leadtools.LazyLoadingThreshold;
    const LazyLoadingMobileThreshold = environment.Leadtools.LazyLoadingMobileThreshold;
    const LazyLoadingBuffer = environment.Leadtools.LazyLoadingBuffer;
    framesLength = instances[0].NumberOfFrames;
    const seriesInfo = this.seriesManagerService.getSeriesInfo(cell);
    const imageDownloaded = (e, args) => {
      const loadFrame = (frame, frameIndex = null, metadata = null, postAction = false) => {
        return this.loadFrameDicomJSON(frame, frameIndex, metadata).then((result) => {
          const jsonReady = this.onFrameDicomJsonReady(cell, framesLength);
          if (postAction) {
            if (!(<any>cell).isPresentationStateLoaded) {
              (<any>cell).isPresentationStateLoaded = true;
              const stackInstanceUIDs = this.seriesManagerService.getSopInstanceUIDs(seriesInstanceUID, cell.divID);
              try {
                if (stackInstanceUIDs.length === 0) {
                  const frame: lt.Controls.Medical.Frame = this.seriesManagerService.getActiveCellFrame();
                  stackInstanceUIDs.push((frame as any).Instance.SOPInstanceUID);
                }
              } catch (error) {

              }
              return this.loadPresentationState({ seriesInstanceUID }, cell.divID, stackInstanceUIDs).then(() => {
                (<any>cell).isPresentationStateLoaded = true;
              }).catch(e => {
                (<any>cell).isPresentationStateLoaded = false;
              });
            }
          } else {
            return jsonReady;
          }
        });
      };

      return loadFrame(args.frame, null, null, true);
    };

    const progressCompleted = (e, args) => {
      cell.remove_imageDownloaded(imageDownloaded);
      cell.remove_progressCompleted(progressCompleted);
      this.cellReadySubj.next([true, cell]);
    };

    const cellDispose = (e, args) => {
      const cell: lt.Controls.Medical.Cell = e;
      cell.remove_imageDownloaded(imageDownloaded);
      cell.remove_progressCompleted(progressCompleted);
      cell.remove_disposing(cellDispose);
    };

    cell.add_imageDownloaded(imageDownloaded);
    cell.add_progressCompleted(progressCompleted);
    cell.add_disposing(cellDispose);

    if (lt.LTHelper.device == lt.LTDevice.mobile) {
      if (framesLength >= LazyLoadingMobileThreshold) {
        cell.fullDownload = false;
      }
    } else {
      if (framesLength >= LazyLoadingThreshold) {
        cell.fullDownload = false;
      }
    }
    cell.marginFramesCount = LazyLoadingBuffer;
    cell.fullDownload = !!seriesInfo.fullDownload;

    const modality = this.dicomHelperService.getTagText(metadata[DicomTag.Modality]);

    (<any>cell).length = Math.min(framesLength, cell.get_imageViewer().get_items().count);
    cell.get_progress().set_totalFrames(instances.length);
    cell.studyInstanceUID = this.dicomHelperService.getTagText(metadata[DicomTag.StudyInstanceUID]);
    for (let instanceIndex = 0; instanceIndex < instances.length; instanceIndex++) {
      const instance = instances[instanceIndex];

      if (instance == null) {
        continue;
      }

      for (let frameIndex = 0; frameIndex < instance.NumberOfFrames; frameIndex++) {
        let cellFrame = this.seriesManagerService.getCellFrame(seriesInstanceUID, id, instance.SOPInstanceUID, modality != 'IO');

        if (cellFrame == undefined) {
          //
          // We have a layout but the specified image is not contained in the layout.
          // Need to add the image to overflow.
          //
          // this._eventService.publish(EventNames.InstanceOverflow, { instance: instance, xml: metadata, frame: frameIndex, parentCell: cell });

          cellFrame = new lt.Controls.Medical.Frame(cell);
          cell.get_frames().add(cellFrame);
        }

        const imageDataReady = () => {
          // console.log('imageDataReady...');
          cellFrame.remove_imageDataReady(imageDataReady);
          (<any>cellFrame).isImageReady = true;

          // enable synchronization features when the last frame is ready
          if (frameIndex == instance.NumberOfFrames - 1 && !(<any>cell).isSynchData) {
            // set cell synchronization
            this.setCellSynchronization(cell);

            const info = { 'viewerComponent': this.viewerComponent };
            const status = this.synchControls.SlicePositionSynch.enabled;
            this.actionManagerService.OnPanZoomSynch(info, status);

            // add event for synch
            this.addEventSynchronization(cell);
            (<any>cell).isSynchData = true;

            const seriesByFOR = this.seriesManagerService.addCellToSeriesByFOR(cell);
            const numVisits = Object.keys(seriesByFOR).length;
            if (numVisits > 1 && !this.viewerComponent.dicomLoaderService.synchControls.IntraVisitSynch.enabled) {
              const info = {'viewerComponent': this.viewerComponent};
              this.actionManagerService.runActionCommand('OnDisableAllSynch', info);
            }
          }

          // ensure the rotation angle is consistent through all slices
          const frame = cell.get_frames().toArray()[cell.currentOffset];
          if ((<any>cell).user_angle && (<any>cell).user_angle !== frame.get_rotateAngle()) {
            frame.set_rotateAngle((<any>cell).user_angle);
          }
          if ((<any>cell).user_flip && (<any>cell).user_flip !== frame.get_flipped()) {
            frame.set_flipped((<any>cell).user_flip);
          }
          if ((<any>cell).user_reverse && (<any>cell).user_reverse !== frame.get_reversed()) {
            frame.set_reversed((<any>cell).user_reverse);
          }
        };

        cellFrame.add_imageDataReady(imageDataReady);
        cellFrame.set_instanceNumber(instance.InstanceNumber);
        cellFrame.metadata = (instanceIndex == 0) ? metadata : null;
        cellFrame.Instance = instance;
        cellFrame.FrameNumber = frameIndex + 1;
        cellFrame.FrameIndex = frameIndex;
        cellFrame.SeriesInstanceUID = seriesInstanceUID;
        cellFrame.enableDraw = true;

        if (instance.SOPInstanceUID == dataSopInstanceUID) {
          if (frameIndex == 0) {
            information = this.dicomHelperService.getDicomImageInformation(metadata, frameIndex);

            cellFrame.set_information(information);
            cellFrame.set_imagePosition(information.position);
            cellFrame.set_imageType(information.imageType);
            cellFrame.set_lossyCompression(information.lossyImageCompression);
            cellFrame.isWaveForm = information.isWaveForm;

            cellFrame.set_width(information.width);
            cellFrame.set_height(information.height);

            cellFrame.set_rowSpacing(information.rowSpacing);
            cellFrame.set_columnSpacing(information.columnSpacing);
            cellFrame.originalSize = { width: information.width, height: information.height };

            cell.set_patientName(this.dicomHelperService.getPatientName(metadata, DicomTag.PatientName));
            cell.set_seriesNumber(this.dicomHelperService.getDicomTagValue(metadata, DicomTag.SeriesNumber));
            cell.set_frameOfReferenceUID(information.frameOfReferenceUID);

            this.overlayManagerService.setCellOverlays(cell, cellFrame.metadata, cellFrame.isWaveForm);
          } else {
            information = this.dicomHelperService.getDicomImageInformation(metadata);
            cellFrame.set_information(information);
            cellFrame.originalSize = { width: information.get_width(), height: information.get_height() };
            cellFrame.set_width(information.width);
            cellFrame.set_height(information.height);
            cellFrame.isWaveForm = information.isWaveForm;
          }
        } else {
        }
      }
    }
  }

  setCellSynchronization(cell) {
    if (cell === null || cell === undefined)
      return;
    // console.log('setCellSynchronization...');
    // disable the default cell synch
    cell.viewer.enableSynchronization = false;

    // add cell to seriesByFOR
    const seriesByFOR = this.seriesManagerService.addCellToSeriesByFOR(cell);
    // prepare matching map for each FOR
    for (const frameOfReferenceUID in seriesByFOR) {
      const cellIds: [] = seriesByFOR[frameOfReferenceUID];

      const synchCells = cellIds
        .map(cellId => this.seriesManagerService.getSeriesCellById(cellId))
        .filter(c => c instanceof lt.Controls.Medical.Cell);

      const fromSameFOR = this.fromSameFOR(synchCells);
      const isOrienEqui = this.isOrienEquivalent(synchCells);

      // set sychn mode for this FOR
      const synchMeth = fromSameFOR && isOrienEqui ? 'Auto' : 'Manual';
      if (!this.seriesSynchronization[frameOfReferenceUID]) {
        this.seriesSynchronization[frameOfReferenceUID] = {
          method: synchMeth, enabled: (synchMeth === 'Auto'), matchingMap: null
        };
      } else {
        this.seriesSynchronization[frameOfReferenceUID].method = synchMeth;
        this.seriesSynchronization[frameOfReferenceUID].enabled = (synchMeth === 'Auto');
      }

      if (!this.seriesSynchronization[frameOfReferenceUID].matchingMap) {
        this.seriesSynchronization[frameOfReferenceUID].matchingMap = {};
      }

      if (cellIds.length > 1) {
        // build/update the matching map for this FOR
        this.buildMatchingMap(synchCells, synchCells);
      }
    }

    // prepare matching map for inter FOR
    const frameOfReferenceUIDs = Object.keys(seriesByFOR);
    for (let i = 0; i < frameOfReferenceUIDs.length; i++) {
      const srcFOR = frameOfReferenceUIDs[i];
      const srcCellIds: [] = seriesByFOR[srcFOR];
      const srcSynchCells = srcCellIds.map(cellId => this.seriesManagerService.getSeriesCellById(cellId));

      for (let j = 0; j < frameOfReferenceUIDs.length; j++) {
        const dstFOR = frameOfReferenceUIDs[j];
        if (srcFOR === dstFOR) {
          continue;
        }
        const dstCellIds: [] = seriesByFOR[dstFOR];
        const dstSynchCells = dstCellIds.map(cellId => this.seriesManagerService.getSeriesCellById(cellId));
        this.buildMatchingMap(srcSynchCells, dstSynchCells);
      }
    }
  }

  addEventSynchronization(cell: lt.Controls.Medical.Cell) {
    // console.log("addEventSynchronization...");

    cell.get_div().addEventListener('mouseover', () => {
      // console.log("mouseover cell:", cell.divID);
      this.mouseoverCell = cell;
    });

    cell.add_scrollChanged((e, args) => {
      if (this.mouseoverCell && cell.divID === this.mouseoverCell.divID) {
        const frameOfReferenceUID = cell.get_frameOfReferenceUID();
        const seriesByFOR = this.seriesManagerService.getSeriesByFOR();

        // Apply series synchronization if enabled
        if (this.synchControls.SlicePositionSynch.enabled &&
          this.seriesSynchronization[frameOfReferenceUID] &&
          this.seriesSynchronization[frameOfReferenceUID].enabled) {
          const curOffset = cell.get_currentOffset();
          const curSeriesIUID = cell.get_seriesInstanceUID();
          // console.log("curSeriesIUID:", curSeriesIUID);
          // console.log("curOffset:", curOffset);


          const cellIds: [] = seriesByFOR[frameOfReferenceUID];
          const cellsToSynch = cellIds.map(cellId => this.seriesManagerService.getSeriesCellById(cellId));

          for (let index = 0; index < cellsToSynch.length; index++) {
            const cellToSynch = cellsToSynch[index];
            if (cellToSynch === null) {
              continue;
            }
            const dstSeriesIUID = cellToSynch.get_seriesInstanceUID();

            if (curSeriesIUID == dstSeriesIUID) {
              // TODO: use divID instead
              // console.log("WARN, same seriesIUID across cells:", curSeriesIUID);
              cellToSynch.currentOffset = curOffset;
              continue;
            }

            if (this.seriesSynchronization[frameOfReferenceUID].matchingMap &&
              this.seriesSynchronization[frameOfReferenceUID].matchingMap[curSeriesIUID] &&
              this.seriesSynchronization[frameOfReferenceUID].matchingMap[curSeriesIUID][dstSeriesIUID]) {
              const dstOffset = this.seriesSynchronization[frameOfReferenceUID].matchingMap[curSeriesIUID][dstSeriesIUID][curOffset];
              cellToSynch.currentOffset = dstOffset;
              // console.log("1.dstSeriesIUID:", dstSeriesIUID);
              // console.log("1.dstOffset:", dstOffset);
            }
          }

          // synchronize series from different FOR
          if (this.synchControls.IntraVisitSynch.enabled) {
            const frameOfReferenceUIDs = Object.keys(seriesByFOR);
            for (let i = 0; i < frameOfReferenceUIDs.length; i++) {
              const selFOR = frameOfReferenceUIDs[i];
              if (selFOR == frameOfReferenceUID) {
                continue;
              }

              const dstFOR = frameOfReferenceUIDs[i];
              const dstCellIds: [] = seriesByFOR[dstFOR];
              const dstSynchCells = dstCellIds.map(cellId => this.seriesManagerService.getSeriesCellById(cellId));

              for (let j = 0; j < dstSynchCells.length; j++) {
                const cellToSynch = dstSynchCells[j];
                if (cellToSynch === null) {
                  continue;
                }
                const dstSeriesIUID = cellToSynch.get_seriesInstanceUID();

                if (curSeriesIUID == dstSeriesIUID) {
                  continue;
                }

                if (this.seriesSynchronization[frameOfReferenceUID].matchingMap &&
                  this.seriesSynchronization[frameOfReferenceUID].matchingMap[curSeriesIUID] &&
                  this.seriesSynchronization[frameOfReferenceUID].matchingMap[curSeriesIUID][dstSeriesIUID]) {
                  const dstOffset = this.seriesSynchronization[frameOfReferenceUID].matchingMap[curSeriesIUID][dstSeriesIUID][curOffset];
                  cellToSynch.currentOffset = dstOffset;
                  // console.log("2.dstSeriesIUID:", dstSeriesIUID);
                  // console.log("2.dstOffset:", dstOffset);
                } else { console.log('Missing matching map or dstSeriesIUID'); }
              }
            }
          }
        }
      }
    });
  }

  fromSameFOR(synchCells: lt.Controls.Medical.Cell[]) {
    let fromSameFOR = true;
    let sample_FOR = null;

    synchCells.forEach(cell => {
      if (sample_FOR === null) {
        sample_FOR = cell?.frameOfReferenceUID;
      } else { fromSameFOR = (sample_FOR === cell?.frameOfReferenceUID); }
    });
    return fromSameFOR;
  }

  isOrienEquivalent(synchCells: lt.Controls.Medical.Cell[] = [], system_epsilon = 0.03): boolean {
    if (synchCells.length === 0) {
      return false;
    }

    let sameLength = true;
    let minLength = null;
    synchCells.forEach(cell => {
      const framesCount = cell?.get_frames()?.count;
      minLength = minLength === null ? framesCount : minLength;
      minLength = framesCount < minLength ? framesCount : minLength;
      sameLength = (minLength === framesCount);
    });
    // console.log("minLength:", minLength);

    let isOrienEquivalent = true;
    for (let i = 0; i < synchCells.length; i++) {
      const cell_A = synchCells[i];

      for (let j = i + 1; j < synchCells.length; j++) {
        const cell_B = synchCells[j];

        if (i == j) { continue; }

        const frames_A = cell_A.get_frames().toArray();
        const frames_B = cell_B.get_frames().toArray();

        for (let k = 0; k < minLength; k++) {
          const frame_A: lt.Controls.Medical.Frame = frames_A[k];
          const frame_B: lt.Controls.Medical.Frame = frames_B[k];

          frame_A.imageOrientation.forEach((value, index) => {
            isOrienEquivalent = Math.abs(value - frame_B.imageOrientation[index]) <= system_epsilon;
          });
        }
      }
    }
    return isOrienEquivalent;
  }

  buildMatchingMap(srcCells: lt.Controls.Medical.Cell[], dstCells: lt.Controls.Medical.Cell[]) {
    // build matching indexes for srcCells & dstCells
    // console.log("buildMatchingMap...");
    const activeCellFrame: lt.Controls.Medical.Frame = this.seriesManagerService.getActiveCellFrame();
    const ippOffset = [0, 0, 0];

    for (let i = 0; i < srcCells.length; i++) {
      const cell_A = srcCells[i];
      const FOR_A = cell_A.get_frameOfReferenceUID();

      for (let j = 0; j < dstCells.length; j++) {
        const cell_B = dstCells[j];
        const FOR_B = cell_B.get_frameOfReferenceUID();

        // skip computation on the same series
        if (cell_A.get_seriesInstanceUID() == cell_B.get_seriesInstanceUID()) {
          continue;
        }

        const frames_A: lt.Controls.Medical.Frame[] = cell_A.get_frames().toArray();
        const frames_B: lt.Controls.Medical.Frame[] = cell_B.get_frames().toArray();
        const minidx_A_B = {};

        // set ipp offset for intra visit cells
        if (FOR_A != FOR_B) {
          const currFrame_A = frames_A[cell_A.get_currentOffset()];
          const currFrame_B = frames_B[cell_B.get_currentOffset()];
          ippOffset[0] = currFrame_B.imagePosition[0] - currFrame_A.imagePosition[0];
          ippOffset[1] = currFrame_B.imagePosition[1] - currFrame_A.imagePosition[1];
          ippOffset[2] = currFrame_B.imagePosition[2] - currFrame_A.imagePosition[2];
          // console.log("ippOffset:", ippOffset);
        }

        frames_A.forEach((frame_A, index_A) => {
          const dist_to_B = [];
          const ipp_A = frame_A.imagePosition;
          frames_B.forEach(frame_B => {
            const ipp_B = frame_B.imagePosition;

            let dist = Math.pow(ipp_B[0] - ipp_A[0] - ippOffset[0], 2);
            dist += Math.pow(ipp_B[1] - ipp_A[1] - ippOffset[1], 2);
            dist += Math.pow(ipp_B[2] - ipp_A[2] - ippOffset[2], 2);
            dist = Math.sqrt(dist);
            dist_to_B.push(dist);
          });

          const minidx_to_B = dist_to_B.lastIndexOf(Math.min.apply(Math, dist_to_B));
          minidx_A_B[index_A] = minidx_to_B;
          // console.log("dist_to_B:", dist_to_B);
        });

        const seriesIUD_A = cell_A.get_seriesInstanceUID();
        const seriesIUD_B = cell_B.get_seriesInstanceUID();

        if (this.seriesSynchronization[FOR_A].matchingMap[seriesIUD_A]) {
          this.seriesSynchronization[FOR_A].matchingMap[seriesIUD_A][seriesIUD_B] = minidx_A_B;
        } else {
          this.seriesSynchronization[FOR_A].matchingMap[seriesIUD_A] = { [seriesIUD_B]: minidx_A_B };
        }
      }
    }
  }

  onFrameDicomJsonReady(cell, framesLength) {
    const LazyLoadingThreshold = environment.Leadtools.LazyLoadingThreshold;
    const LazyLoadingBuffer = environment.Leadtools.LazyLoadingBuffer;

    (<any>cell).imageLoaded = !(<any>cell).imageLoaded ? 1 : (<any>cell).imageLoaded + 1;
    if (framesLength >= LazyLoadingThreshold && (<any>cell).imageLoaded == LazyLoadingBuffer + 1) { this.cellReadySubj.next([true, cell]); }
    return this.cellReadySubj;
  }

  onInstancesFound(data) {
    this.seriesManagerService.setInstances(data.seriesInstanceUID, data.id, data.instances, data.stackInstanceUID);
  }

  onDicomJSONRetrieved(data) {
    const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(data.id);
    return this.loadImages(data.seriesInstanceUID, data.id, data.metadata, data.sopInstanceUID);
  }

  onPresentationStateLoaded(data) {
    const annotations = data.annotations;
    const frame: lt.Controls.Medical.Frame = this.seriesManagerService.getActiveCellFrame();
    const _container = frame.get_container();

    const userData = JSON.stringify({
      SourceDpiX: _container.mapper.sourceDpiX,
      SourceDpiY: _container.mapper.sourceDpiY,
      TargetDpiX: _container.mapper.targetDpiX,
      TargetDpiY: _container.mapper.targetDpiY
    });
    let counter = 0;

    const person = this.utils.getRanoAdudicationPerson();

    annotations.forEach(item => {
      this.objectRetrieveService.getPresentationAnnotations(item.SOPInstanceUID, userData, person)
        .toPromise().then(result => {
        counter++;
        const parser = new DOMParser();
        if (result) {
          item['XMLData'] = parser.parseFromString(result, 'text/xml');
        } else {
          console.log('getPresentationAnnotations, Error occured. Response: ', result);
        }

        if (counter === annotations.length) {
          this.seriesManagerService.setAnnotationIDs(data.seriesInstanceUID, data.id, annotations);
          try {
            if (data.sopInstanceUIDs.length === 0) {
              data.sopInstanceUIDs.push((frame as any).Instance.SOPInstanceUID);
            }
          } catch (error) {

          }
          this.seriesManagerService.setSopInstanceUIDs(data.seriesInstanceUID, data.id, data.sopInstanceUIDs);
          const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(data.id);
          this.annotationIsLoadedSubj.next(cell);
        }
      });
    });

    if (!annotations.length) {
      const cell: lt.Controls.Medical.Cell = this.seriesManagerService.getSeriesCellById(data.id);
      this.annotationIsLoadedSubj.next(cell);
    }
    // this.seriesManagerService.setAnnotationIDs(data.seriesInstanceUID, data.id, data.annotations);
    // this._eventService.publish(EventNames.AnnotationsLoaded, { seriesInstanceUID: data.args.seriesInstanceUID, id: data.args.id });
  }
}
