import {Observable} from 'rxjs';

/**
 * @description
 * Dynamika web socket messaging interface to emit and listen web socket
 * event messages via NodeJs WebSocket Server deployed as
 * websockets-server microservice on Google Cloud Platform
 * AppEngine Flexible Environment.
 *
 */

export abstract class DynamikaWebSocket {
  /**
   * Emit a message event for sending data on the connection.
   *
   * @param eventName Event name which will be used as a key to listen.
   * @param data Data to be send. Can be a JSON string.
   */
  abstract emit(eventName: string, data: any): void;

  /**
   * Listen a message event for receiving data on the connection.
   *
   * @param eventName Event name which was sent by the emiter as a key to listen.
   */
  abstract listen(eventName: string, roomId: string, componentName: string): Observable<any>;
}
