import { FormGroup } from '@angular/forms';
import { AsyncSubject, BehaviorSubject, ReplaySubject, Subject, Subscription } from 'rxjs';
import { filter, skipWhile } from 'rxjs/operators';
import { BroadcastChannelData, BroadcastChannelService } from 'src/app/_services/broadcast-channel.service';

export class BroadcastChannelMixin {
    broadcastChannelNamespace = this.constructor.name;
    broadcastChannelName: string;
    broadcastChannelReceiveSub: Subscription;

    constructor(public broadcastChannelService: BroadcastChannelService<BroadcastChannelData<any>>) {

    }

    startBroadcastChannel(channelName?: string, configs?: any): void {
        this.broadcastChannelNamespace = this.broadcastChannelNamespace || this.constructor.name;

        this.broadcastChannelService = new BroadcastChannelService<BroadcastChannelData<any>>();
        this.broadcastChannelService.start(channelName, configs);

        this.broadcastChannelReceiveSub?.unsubscribe();
        this.broadcastChannelReceiveSub = this.broadcastChannelService.receiveData()
            .pipe(
                skipWhile(v => !v),
                filter(v => v.namespace === this.broadcastChannelNamespace)
            )
            .subscribe(data => {
                if (!(data.action in this)) {
                    return;
                }

                const action = this[data.action];

                if (action instanceof Subject
                    || action instanceof BehaviorSubject
                    || action instanceof ReplaySubject
                    || action instanceof AsyncSubject
                ) {
                    action.next(data.value);

                    if (action instanceof AsyncSubject) {
                        action.complete();
                    }
                } else if (action instanceof FormGroup) {
                    action.patchValue(data.value);
                } else if (typeof action === 'function') {
                    action.apply(this, data.value);
                } else {
                    this[data.action] = data.value;
                }
            });
    }

    shutdownBroadcastChannel(): void {
        this.broadcastChannelReceiveSub?.unsubscribe();
        this.broadcastChannelService?.shutdown();
        this.broadcastChannelService = null;
    }

    broadcastData(action: string, value?: any): void {
        if (!this.broadcastChannelService?.broadcastChannel) {
            return;
        }

        this.broadcastChannelService.sendData({
            namespace: this.broadcastChannelNamespace, action, value
        });
    }
}
