import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {BodySublocationDialogModel} from '../primary-body-sublocation-dialog/primary-body-sublocation-dialog.component';
import {BodySubLocationService} from '../../../../_services';
import {BodySubLocationModel} from '../../../../_models/ImagingProject/ImagingProjectBodypartDataset/body-sub-location-model';
import {AbstractControl, FormControl, ValidationErrors} from "@angular/forms";

@Component({
  selector: 'app-primary-body-sublocation-status-dialog',
  templateUrl: './primary-body-sublocation-status-dialog.component.html',
  styleUrls: ['./primary-body-sublocation-status-dialog.component.css']
})
export class PrimaryBodySublocationStatusDialogComponent implements OnInit {

  currentSublocation: BodySubLocationModel;
  statusFormControl =  new FormControl('ACTIVE', [this.validateUniqueName]);

  constructor(
    private bodySubLocationService: BodySubLocationService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<BodySublocationDialogModel>,
    @Inject(MAT_DIALOG_DATA) public data: BodySublocationDialogModel
  ) {
    this.currentSublocation = JSON.parse(JSON.stringify(this.data.subLocation));
  }

  ngOnInit(): void {
    this.statusFormControl.setValue(this.data.subLocation.status.toUpperCase());
  }

  get validateUniqueName() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.currentSublocation != null) {
        let names = this.data.subLocationList.filter(p => p.name === this.data.subLocation.name
          && 'ACTIVE' === p.status.toUpperCase());
        if (names.length === 1) {
          if (names[0].id === this.data.subLocation.id) {
            names = [];
          }
        }
        return names.length > 0 ? {duplicateValueError: 'Duplicate.'} : null;
      }
      return null;
    };
  }

  onStatusChanged(event) {
    this.statusFormControl.updateValueAndValidity();
  }

  save(): void {
    const location = {
      id: this.currentSublocation.id,
      status: this.statusFormControl.value
    };
    this.bodySubLocationService.updateStatus(<BodySubLocationModel> location).subscribe(presp => {
      this.dialogRef.close(presp.data);
    });
  }

  close() {
    this.dialogRef.close();
  }
}
