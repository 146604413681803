import { Component, OnInit, Inject, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { QualityControlService } from '../../../_services/quality-control.service';

interface QCDicomChecksElement{
  name: string;
  actual_value: any;
  qc_value: any;
  match: boolean;
}

@Component({
  selector: 'app-qcdicomchecks-dialog',
  templateUrl: './qcdicomchecks-dialog.component.html',
  styleUrls: ['./qcdicomchecks-dialog.component.css']
})
export class QCDicomchecksDialogComponent implements OnInit {

  dicomChecks: object;

  dataSource: any;
  detailedData = [];
  displayedColumns: string[] = ['name', 'realValue', 'rightValue', 'status' ];

  studyId: any;
  seriesId: any;

  constructor(
    private qualityControlService: QualityControlService,
    public dialogRef: MatDialogRef<QCDicomchecksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: object){

  }

  ngOnInit() {
      this.studyId = this.data['studyId'];
      this.seriesId = this.data['seriesId'];
      let dicomChecks = this.data['dicomChecks'];
      let qc_results = dicomChecks['qc_results'];
      let keys = Object.keys(qc_results);
      this.detailedData = [];
      if(keys != null){
        keys.forEach(key => {
          let qc_prperty = qc_results[key];
          let qc ={
            name: key,
            actual_value: qc_prperty['actual_value'],
            qc_value: qc_prperty['qc_value'],
            match: qc_prperty['match'],
          };
          this.detailedData.push(qc);
        });
      }

      this.dataSource = new MatTableDataSource<QCDicomChecksElement>(this.detailedData);
  }

  onCloseClick(): void {
    this.qualityControlService.updateLastByTypeSeriesTimeTrackEvent(this.studyId, this.seriesId, "view_dicom_checks").subscribe(success=>{

    });
    this.dialogRef.close();
  }
}
