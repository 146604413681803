import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class TherapeuticAreaService {

  constructor(private http: HttpClient) { }

  create(therapeuticArea: any) {
    return this.http.post(requestURL + `/therapeutic-area`, therapeuticArea);
  }
  
  update(therapeuticArea: any) {
    return this.http.put(requestURL + `/therapeutic-area`, therapeuticArea);
  }
  
  delete(id: number) {
    return this.http.delete(requestURL + `/therapeutic-area/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/therapeutic-area/` + id);
  }
  
  getAll() {
    return this.http.get(requestURL + `/therapeutic-area/retrieve-all`);
  }
}
