import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSUserVersion;
const SERVICE = 'msuser-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private http: HttpClient) { }

  create(activity: any) {
    return this.http.post(requestURL + `/activity`, activity);
  }

  update(activity: any) {
    return this.http.put(requestURL + `/activity`, activity);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/activity/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/activity/` + id);
  }

  getAll() {
    return this.http.get(requestURL + `/activity/retrieve-all`);
  }
}