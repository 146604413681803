export interface BodySubLocationModel {
  id: number;
  name: string;
  status: BodySubLocationStatus;
}

export enum BodySubLocationStatus {
  ACTIVE ='A',
  DISABLED = 'D'
}
