export class ViewerImageGridModel {
  private _columns: number;
  private _rows: number;

  get columns() {
    return this._columns;
  }

  get rows() {
    return this._rows;
  }

  set columns(columns) {
    this._columns = +columns;
  }

  set rows(rows) {
    this._rows = +rows;
  }
}
