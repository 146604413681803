import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from 'src/environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const helper = new JwtHelperService();
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingDXAVersion;
const SERVICE = 'msreadingdxa-dot-';
const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL;
// const BASE_URL = 'http://localhost:8080/api';
@Injectable({
  providedIn: 'root'
})
export class ReadingDXAService {

  constructor(private http: HttpClient) {
  }

  updateReading(studyId, reading) {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/reading/update/${studyId}`, reading);
  }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/get-number-of-available-readings/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getAvailableReadings(studyId, userId): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/get-available-readings/${studyId}/${userId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/start/${studyId}/${readingId}`;
    return this.http.put<BasicResponse<any>>(url, null);
  }

  updateReadingTime(studyId, readingId, data): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/reading/${studyId}/${readingId}`, data);
  }

  completeReading(studyId, reading): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/reading/complete/${studyId}`, reading);
  }

  getAIPending(studyId: number, visitConfigId: number) {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/reading/ai-pending/studyId-${studyId}/visitConfigId-${visitConfigId}`)
  }

}
