import {ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef, ViewChildren} from '@angular/core';
import {ImagingProjectService} from 'src/app/_services/imaging-project.service';

@Component({
  selector: 'app-viewer-thumbnail',
  templateUrl: './viewer-thumbnail.component.html',
  styleUrls: ['./viewer-thumbnail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewerThumbnailComponent implements OnInit {

  @ViewChildren('thumbnail') _thumbnail: TemplateRef<any>;

  @Input() seriesId: number;
  @Input() seriesUrl: string;
  @Input() seriesInstanceUid;

  constructor(
    private imagingProjectService: ImagingProjectService
  ) {
  }

  private slice = 0;
  private sliceinpx = 0;
  private backgroundHeight = 0;
  private componentHeight = 96;
  private thumbnailHeight = 96;
  private backgroundImageUrl = '';
  private sliceMax = 1;

  public backgroundImgPromise: Promise<string>;

  ngOnInit() {
    this.backgroundImgPromise = new Promise<string>(resolve => {
      if (this.seriesUrl == null) {
        this.imagingProjectService.getSeriesPreviewUrl(this.seriesId).subscribe(result => {
          if (result.hasOwnProperty('data')) {
            this.backgroundImageUrl = result.data;
            const img = new Image();
            const self = this;
            img.addEventListener('load', function () {
              self.backgroundHeight = this.naturalHeight;
              self.sliceMax = self.backgroundHeight / self.thumbnailHeight - 1;
            });
            img.src = this.backgroundImageUrl;
            resolve(this.backgroundImageUrl);
          }
        });
      } else {
        this.backgroundImageUrl = this.seriesUrl;
        const img = new Image();
        const self = this;
        img.addEventListener('load', function () {
          self.backgroundHeight = this.naturalHeight;
          self.sliceMax = self.backgroundHeight / self.thumbnailHeight - 1;
        });
        img.src = this.backgroundImageUrl;
        resolve(this.backgroundImageUrl);
      }
    });
  }

  onMouseMove(e) {
    // console.log("e:", e);
    // console.log("e.target.draggable:", e.target.draggable);
    // e.target.draggable = true;
    this.slice = Math.round((e.offsetY / this.componentHeight) * this.sliceMax);

    if (this.slice < 0) {
      this.slice = 0;
    } else if (this.slice > this.sliceMax) {
      this.slice = this.sliceMax;
    }

    this.sliceinpx = this.slice * -96;
  }

  get gradientStyle() {
    const sliceinpx = this.sliceinpx;
    const componentHeight = this.componentHeight;

    return {
      'background-position-y.px': sliceinpx,
      'height': componentHeight
    };
  }

  onDragStart(e){
    console.log("onDragStart...");
    e.dataTransfer.setData('seriesId', this.seriesId);
  }

  // onDragOver(e?){
  //   console.log("onDragOver...");
  // }
  

}
