import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ImagingProjectService} from '../../../_services';
import {Select, Store} from '@ngxs/store';
import {forkJoin, Observable, of, Subject, Subscription} from 'rxjs';
import {StudyDashboardModel} from '../../../_models/ImagingProject/study-model';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ProjectsState} from '../../../core/data-management/states/projects.state';
import {ToastService} from '../../../_services/internal/toast.service';
import {ReportGeneratorService} from '../../../_services/report-generator.service';
import {ShortSignStudyDashboardModel, SignStudyDashboardModel, StudyType} from '../../../_models/ImagingProject/sign-model';
import {RefreshAPIService} from "../../../_services/refresh-api.service";
import {catchError, takeUntil} from "rxjs/operators";
import {ReadingGuerbetService} from "../../../_services/reading-guerbet.service";

@Component({
    selector: 'app-sign-studies-list',
    templateUrl: './sign-studies-list.component.html',
    styleUrls: ['./sign-studies-list.component.css']
})
export class SignStudiesListComponent implements OnInit, OnDestroy {
  readonly COLUMNS = ['row-num', 'studyName', 'sponsorName', 'reportsCompleted', 'reportsToBeSigned', 'totalReports', 'actions'];

  @Select(ProjectsState.getDashboardProjectIds) projectIdsObservable: Observable<number[]>;
  @Select(ProjectsState.getDashboardProjects) projectsObservable: Observable<StudyDashboardModel[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  reportsList: SignStudyDashboardModel[] = [];
  reportsDataSource: MatTableDataSource<SignStudyDashboardModel>;
  loading = true;
  protected unsubscribe = new Subject<void>();
  refreshAPISubscription: Subscription;

  constructor(
    private router: Router,
    private imagingProjectService: ImagingProjectService,
    private reportGeneratorService: ReportGeneratorService,
    private guerbetService: ReadingGuerbetService,
    private toastService: ToastService,
    private store: Store,
    private refreshAPIService: RefreshAPIService
  ) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Signing Reports'));
    this.reportsDataSource = new MatTableDataSource<SignStudyDashboardModel>();
    this.loadStudies();
    this.initRefresh();
  }

  ngOnDestroy(): void {
    if (this.refreshAPISubscription) {
      this.refreshAPIService.clearRefreshAPIData();
      this.refreshAPISubscription.unsubscribe();
    }
  }

  loadGuerbetStudies() {
    return this.guerbetService.getSignSummary()
      .pipe(
        catchError(error => {
          console.error('Error geting guerbet sign summary', error);
          return of(null);
        })
      );
  }

  loadReportGeneratorStudies() {
    return this.reportGeneratorService.getSummary()
      .pipe(
        catchError(error => {
          console.error('Error getting report sign summary', error);
          return of(null);
        })
      );
  }

  loadStudies() {
    forkJoin([this.loadReportGeneratorStudies(), this.loadGuerbetStudies()])
      .subscribe(
        results => {
          const [reportGeneratorResult, guerbetResult] = results;

          const reportGeneratorReportsList = (reportGeneratorResult || []).map(r => {
            return {
              ...r,
              studyType: 'REPORT'
            } as ShortSignStudyDashboardModel;
          });

          const guerbetReportsList = (guerbetResult || []).map(r => {
            return {
              ...r,
              studyType: 'GUERBET'
            } as ShortSignStudyDashboardModel;
          });

          this.loadStudiesInfo([...reportGeneratorReportsList, ...guerbetReportsList]);
        },
        error => {
          this.loading = false;
          this.toastService.respError(error);
        }
      );
  }

  loadStudiesInfo(shortReportsList: ShortSignStudyDashboardModel[]) {

    const studyIds = shortReportsList.filter(report => !!report.studyId).map(report => report.studyId);
    if (!studyIds.length) {
      this.loading = false;
      return;
    }

    this.imagingProjectService.getStudyiesForDashboardByIds(studyIds)
      .subscribe(
        response => {
          if (response.responseCode === 200) {
            this.reportsList = response.data.map(study => {
              const report = shortReportsList.find(r => r.studyId === study.id);
              return {
                studyId: study.id,
                studyName: study.name,
                sponsorName: study.sponsorName,
                signedReportsCount: report.signedReportsCount,
                reportsToBeSigned: report.totalReportsCount - report.signedReportsCount,
                totalReportsCount: report.totalReportsCount,
                studyType: report.studyType
              };
            });

            this.updateReportsList();
          } else if (response.responseCode === 500) {
            this.loading = false;
            this.toastService.respError(response);
          }
        },
        error => {
          this.loading = false;
          this.toastService.respError(error);
        }
      );
  }

  navigateToDetails(id: number, studyType: StudyType): void {
    console.log('navigateToDetails', id, studyType);
    const url = studyType === 'GUERBET' ? `/sign-guerbet/${id}` : `/sign/${id}`;
    this.router.navigate([url]).then(r => {});
  }

  applyFilter(filterValue: string): void {
    if (this.reportsDataSource !== undefined) {
      this.reportsDataSource.filter = filterValue.trim().toLowerCase();
      if (this.reportsDataSource.paginator) {
        this.reportsDataSource.paginator.firstPage();
      }
    }
  }

  updateReportsList(): void {
    this.reportsDataSource.data = this.reportsList;
    this.reportsDataSource.paginator = this.paginator;
    this.reportsDataSource.sort = this.sort;
    this.loading = false;
  }

  initRefresh() {
    // refresh APIs
    this.refreshAPISubscription = this.refreshAPIService.refreshAPIData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
        if (response) {
          if (response.componentUrl === this.router.url) {
            window.location.reload();
          }
        }
      });
  }
}
