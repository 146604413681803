import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-body-location-dialog',
  templateUrl: './confirmation-body-location-dialog.component.html',
  styleUrls: ['./confirmation-body-location-dialog.component.css']
})
export class ConfirmationBodyLocationDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }

  save(): void {
    this.dialogRef.close('ok');
  }

  close() {
    this.dialogRef.close('cancel');
  }
}
