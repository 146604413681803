import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {CandenSparccReadingModel} from '../_models/CandenSparcc/canden-sparcc-reading-model';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingCandenSparccVersion;
const SERVICE = 'msreadingcandensparcc-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/reading/';

// const SERVICE_API_URL = 'http://localhost:8080/api/reading/';

@Injectable({
  providedIn: 'root'
})
export class ReadingCandenSparccService {

  constructor(private http: HttpClient) {
  }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-number-of-available-readings/${studyId}/${readerId}`);
  }

  getReading(studyId, readerId): Observable<BasicResponse<CandenSparccReadingModel[]>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-available-readings/${studyId}/${readerId}`);
  }

  getNumberOfAvailableModerationReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-number-of-available-moderator-readings/${studyId}/${readerId}`);
  }

  getModerationReadings(studyId, readerId): Observable<BasicResponse<CandenSparccReadingModel[]>> {
    return this.http.get<BasicResponse<any>>(`${SERVICE_API_URL}get-available-adjudication-readings/${studyId}/${readerId}`);
  }

  startReading(studyId, readingId): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}start/${studyId}/${readingId}`, null);
  }

  updateReading(studyId, readingId, spentSeconds, comment): Observable<BasicResponse<CandenSparccReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}${studyId}/${readingId}`, {spentSeconds, comment});
  }

  completeReading(studyId, reading: CandenSparccReadingModel): Observable<BasicResponse<CandenSparccReadingModel>> {
    return this.http.put<BasicResponse<any>>(`${SERVICE_API_URL}complete/${studyId}`, reading);
  }

  ziteLabReadingInitiation(studyId, reading: CandenSparccReadingModel): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${SERVICE_API_URL}zitelab/initiate/${studyId}`, reading);
  }

  loadOnSamri(studyId, readingId): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${SERVICE_API_URL}zitelab/control-samri-view/${studyId}/${readingId}`, null);
  }
}
