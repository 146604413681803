import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MRanoLesionObject } from 'src/app/_models/MRANO/mrano-scoring-model';

export interface objectData{
  serieId:number,
  objectType: MRanoLesionObject
  objectData:any
}
@Component({
  selector: 'app-object-convert-dialog',
  templateUrl: './object-convert-dialog.component.html',
  styleUrls: ['./object-convert-dialog.component.css']
})
export class ObjectConvertDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ObjectConvertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: objectData) { }
  mranoLesionObject =MRanoLesionObject;
  objectType = MRanoLesionObject.TARGET_LESION;
  ngOnInit(): void {
  }
  closeClick(){
    this.dialogRef.close();
  }
  nextClick(){
    this.data ={
      serieId:this.data.serieId,
      objectData:this.data.objectData,
      objectType : this.objectType
    }
    this.dialogRef.close(this.data)
  }
}
