import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {interval, Subscription} from 'rxjs';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {EventKey, FormEvent, SNAPSHOT_RAW_DATA} from 'src/app/_models/Oncology/local-storage-keys.model';
import {
  GlobalLesionModel,
  OncologyLesionEditType,
  OncologyLesionType,
  Snapshot
} from 'src/app/_models/Oncology/global-lesion-model';
import {OncologyAssessmentService} from 'src/app/_services/oncology-assessment.service';
import {FormPage, MessagingService} from "../../../../_services/messaging.service";
import {finalize, map, take, takeWhile} from "rxjs/operators";
import {Observable} from "rxjs/src/Observable";
import { LesionSettings, OncologyCriteria } from 'src/app/_models/Oncology/criteria-model';
import { PrimaryBodyLocationModel } from 'src/app/_models/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-model';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { BodySubLocationModel } from 'src/app/_models/ImagingProject/ImagingProjectBodypartDataset/body-sub-location-model';
import { PrimaryBodyLocationService } from 'src/app/_services/primary-body-location.service';

export interface LesionDialogFormData {
  lesionName: string,
  studyId: number,
  readingId: number,
  readerId: number,
  patientId: number,
  endpointName: string,
  visitConfigId: number,
  isBaseline: boolean,
  markerType:string,
  editDialog:boolean,
  lesionType: OncologyLesionType,
  editType:string,
  lesionData:any,
  screenShotData:any,
  viewerData?:any,
  eCRFOnly:boolean,
  viewerLess: boolean,
  hasComment: boolean
}
@Component({
  selector: 'app-oncology-lesion-dialog',
  templateUrl: './oncology-lesion-dialog.component.html',
  styleUrls: ['./oncology-lesion-dialog.component.css'],
  providers: [MessagingService]
})
export class OncologyLesionDialogComponent implements OnInit, OnDestroy {
  lesionType = OncologyLesionType;
  configPrimaryLocations: any[];
  allPrimaryLocations: any[];

  public get editType(): typeof OncologyLesionEditType {
    return OncologyLesionEditType;
  }

  minimized: boolean = false;
  showModalSpinnerLoadingData = false;
  imageUrl: string = '';
  linkData: any;
  imageAttached: boolean = false;
  attachMessage: string;
  locationSubscription: Subscription;
  eventSubscription: Subscription;
  lesionForm: FormGroup = new FormGroup({});
  loadingScreenshot = false
  timer$: Observable<string>;
  criteriaSubscription: Subscription;
  criteria: OncologyCriteria;
  
  

  constructor(public dialogRef: MatDialogRef<OncologyLesionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: LesionDialogFormData,
              private oncologyAssessmentService: OncologyAssessmentService,
              private primaryBodyLocationService: PrimaryBodyLocationService,
              private fb: FormBuilder,
              private messagingService: MessagingService
  ) { }

  attachImage() {
    let message: FormEvent = {key: EventKey.SCREENSHOT_REQUEST, value: ' '};
    this.messagingService.sendRequest(message, FormPage.MRANO);
    this.enableCountDown();
  }

  ngOnInit(): void {
    this.criteriaSubscription = this.oncologyAssessmentService.criteriaSetting.subscribe(criteria => {
      if (criteria) {
        this.criteria = new OncologyCriteria(criteria, this.data.isBaseline)
      }
   });

    this.initlesionForm();
    if (this.data.editDialog)
      this.onEditForm();

    const userId = +JSON.parse(localStorage.getItem('userId'));
    this.oncologyAssessmentService.location.subscribe(
      (response) => {
        if (response) {
          this.configPrimaryLocations = response;
        }
      }
    )

    this.locationSubscription = this.primaryBodyLocationService.findAll().subscribe(
      (response) => {
        if (response.responseCode == 200) {
          this.allPrimaryLocations = response.data;
        }
      }
    )
    this.eventSubscription = this.messagingService.init(userId, this.data.readingId, FormPage.ONCOLOGY_LESION_DIALOG)
      .subscribe(this.handleEvents.bind(this))
    
    if (!this.data.editDialog || (this.data.editType === this.editType.MEASUREMENT || this.data.editType === this.editType.SCREENSHOT)) 
        this.attachImage();
  }
  isRecistEligibility(){
    return this.data.endpointName === ReadingVersion.RECIST_ELIGIBILITY;
  }

  get bodyLocations(){

    if (this.isRecistEligibility()) {
      return this.configPrimaryLocations?.map((location) => location.name) || [];
    }

    return this.criteria.mainLocation();
  }


  get bodySubLocations() {
    if (this.isRecistEligibility()) {
      const currentPrimaryLocation = this.lesionForm.get('primaryLocation')?.value;
      return this.configPrimaryLocations?.find((location) => location.name === currentPrimaryLocation)?.subLocations?.map((subLocation: BodySubLocationModel) => subLocation.name) || [];
    }
    return this.criteria.subLocation();
  }

  get isNodalLocation() {
    if (this.isRecistEligibility()) {
      const currentPrimaryLocation = this.lesionForm.get('primaryLocation')?.value;
      return this.allPrimaryLocations?.find((location) => location.name === currentPrimaryLocation)?.nodal;
    }
    return null;
  }

  handleEvents(event: FormEvent) {
    if (!event.value)
      return;
    switch (event.key) {
      case EventKey.SCREENSHOT_DATA:
        if (event.value) {
          this.setScreenShotToImgFromWebsocket(event.value);
        }
        break;
      default:
        break;
    }
  }
  minimizeDialog(){
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.add('minimized')
    let top = ((dialog[0] as HTMLElement).offsetTop + 60) + 'px';
    document.documentElement.style.setProperty('--dialog-display', "translate3d(0 , calc(100vh - " + top + ") , 0)");
    document.documentElement.style.setProperty('--dialog-background', "#e99425");
    document.documentElement.style.setProperty('--dialog-color', "#fff");
    this.minimized = true;
  }
  maximizeDialog(){
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.remove('minimized')
    document.documentElement.style.setProperty('--dialog-display', "");
    document.documentElement.style.setProperty('--dialog-background', "#fff");
    document.documentElement.style.setProperty('--dialog-color', "");
    this.minimized = false;
  }

  initlesionForm(){
    this.lesionForm = this.fb.group(new GlobalLesionModel(
      this.data.lesionType,
      this.data.patientId,
      this.data.readerId,
      this.data.readingId,
      this.data.visitConfigId,
      this.data.endpointName,
      this.data.isBaseline ? true : false,
      this.data.markerType
    ));
    if(this.data.viewerData)
      this.setViewerDataToForm(this.data.viewerData);
  }

  onEditForm() {
    this.lesionForm.setValue(this.data.lesionData);
    
      if(this.data.lesionData.snapshot){
        this.imageUrl = this.data.lesionData.snapshot.fileUrl;
      }
      if(this.data.viewerData)
        this.setViewerDataToForm(this.data.viewerData);
  }

  ppdUnitCalculate() {
    const ALDUnit = this.criteria.ALDUnit(); 
    const SAXUnit = this.criteria.SAXUnit();
    const PPDUnit = this.criteria.PPDUnit();
    if(((ALDUnit && SAXUnit) === "mm" && PPDUnit === "mm2") || ((ALDUnit && SAXUnit) === "cm" && PPDUnit === "cm2")) {
      return 1;
    } if((ALDUnit && SAXUnit) === "mm" && PPDUnit === "cm2") {
      return 100
    } if((ALDUnit && SAXUnit) === "cm" && PPDUnit === "mm2") {
      return 0.01
    }
  }
  setViewerDataToForm(viewerData) {
    const ALDUnit = this.criteria.ALDUnit(); 
    const SAXUnit = this.criteria.SAXUnit();
    const ALDDecimal = this.isRecistEligibility() ? 1 : this.criteria.ALDDecimal();
    const SAXDecimal = this.isRecistEligibility() ? 1 : this.criteria.SAXDecimal();
    const PPDDecimal = this.isRecistEligibility() ? 1 : this.criteria.PPDDecimal();
    const axialLongDiameter = viewerData.axialLongDiameter == "" ? null : ((+viewerData.axialLongDiameter)/(ALDUnit == "mm" ? 1 : 10)).toFixed(+ALDDecimal);
    const shortAxis = viewerData.shortAxis == "" ? null : ((+viewerData.shortAxis)/(SAXUnit == "mm" ? 1 : 10)).toFixed(+SAXDecimal);
    const ppd = !viewerData.axialLongDiameter ? null : (+((+axialLongDiameter) * (+shortAxis) )/(this.ppdUnitCalculate())).toFixed(+PPDDecimal);
    this.lesionForm.patchValue({
        type: this.data.lesionType,
        frameNumber: viewerData.frameNumber,
        objectLocationX: viewerData.objectLocationX,
        objectLocationY: viewerData.objectLocationY,
        sopInstanceUid: viewerData.sopInstanceUid,
        sliceNumber: viewerData.sliceNumber,
        seriesId: viewerData.seriesId,
        axialLongDiameter: axialLongDiameter,
        shortAxis: shortAxis,
        ppd: ppd
    });
    if (this.data.lesionType == OncologyLesionType.TARGET_LESION) {
      this.lesionForm.patchValue({
        notMeasurabilityReason: 'PRESENT',
        isMeasurable: true,
      })
    }
  }

  private enableCountDown() {
    this.loadingScreenshot = true;
    const stepsInOneSec = 10;
    const time = 10 * stepsInOneSec // 10 seconds
    let period = 1000 / stepsInOneSec;
    this.timer$ = interval(period) // 1000 = 1 second
      .pipe(
        take(time),
        takeWhile(_ => this.loadingScreenshot),
        map((v) => ((time - 1) - v) / stepsInOneSec), // to reach zero
        map(v => v.toFixed(2)),
        finalize(() => this.loadingScreenshot = false)
      )
    // .subscribe((v) => console.log('Countdown: ', v))

  }

  setScreenShotToImgFromWebsocket(snapshot: any) {
    this.data.screenShotData = {
      seriesId: snapshot.cellInfo.seriesId,
      image: snapshot.image,
      preview: snapshot.image,
      sopInstanceUid: snapshot.cellInfo.seriesUID,
      sliceNumber: snapshot.sliceNum + 1,
      modalityName: snapshot.cellInfo.modality,
      sequenceLabelName: snapshot.cellInfo.label
    }
    this.imageUrl = this.data.screenShotData.image;
    this.imageAttached = true;
    this.attachMessage = "";
    this.loadingScreenshot = false;
  }

  setScreenShotToImg(snapshot: any) {
    // @ts-ignore ldb code is set in index.html file. Read ldb document on https://github.com/DVLP/localStorageDB.
    ldb.get(SNAPSHOT_RAW_DATA, (image) => {
      this.data.screenShotData = {
        seriesId: snapshot.cellInfo.seriesId,
        image: image,
        preview: image,
        sopInstanceUid: snapshot.cellInfo.seriesUID,
        sliceNumber: snapshot.sliceNum + 1,
        modalityName: snapshot.cellInfo.modality,
        sequenceLabelName: snapshot.cellInfo.label
      }
      this.imageUrl = this.data.screenShotData.image;
      this.imageAttached = true;
      this.attachMessage = "";

      // @ts-ignore ldb code is set in index.html file. Read ldb document on https://github.com/DVLP/localStorageDB.
      ldb.set(SNAPSHOT_RAW_DATA, '', () => {
      });
    });
  }

  saveImageToCloud(screenShotData) {
    this.lesionForm.patchValue({
      isNode: this.isNodalLocation
    });
    this.showModalSpinnerLoadingData = true;
    // convert base64 string to Blob object ( array of 8 bit integers)
    const b64toBlob = (b64Data, contentType, sliceSize = 512) => {
      const byteCharacters = window.atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    };
    const blob = b64toBlob(screenShotData.image.split(',')[1], 'image/jpeg');
    const blobPreview = b64toBlob(screenShotData.preview.split(',')[1], 'image/jpeg');
    const ifSliceNum = screenShotData.sliceNumber;
    this.oncologyAssessmentService
      .getLesionImageUploadLink(this.data.studyId, this.data.readingId,screenShotData.seriesId, ifSliceNum).subscribe(linkResp => {
      this.linkData = linkResp['data'];
      // fake input form with data
      const fdImage = new FormData();
      fdImage.append('file', blob, 'filename.jpg');
      const fdImagePreview = new FormData();
      fdImage.append('file', blobPreview, 'filename_preview.jpg');
      this.oncologyAssessmentService.uplodFileToCloud(this.linkData.fileUrl, blob).subscribe(imgaeUploadRes => {
        this.oncologyAssessmentService.uplodFileToCloud(this.linkData.fileUrlPreview, blobPreview).subscribe(imgaePreviewUploadRes => {
          const snapshot = new Snapshot();
          snapshot.seriesId=this.data.screenShotData.seriesId;
          snapshot.sliceNumber=this.data.screenShotData.sliceNumber;
          snapshot.storageFileName =  this.linkData['storageFileName'];
          snapshot.storageFileNamePreview=this.linkData['storageFileNamePreview'];
          snapshot.bucketName = this.linkData['bucketName'];
          snapshot.storagePath = this.linkData['storagePath'];
          snapshot.fileUrl =this.linkData['fileUrl'];
          snapshot.fileUrlPreview=this.linkData['fileUrlPreview'];
          snapshot.modalityName = this.data.screenShotData.modalityName;
          snapshot.sequenceLabelName = this.data.screenShotData.sequenceLabelName;
          snapshot.sopInstanceUid = this.data.screenShotData.sopInstanceUid;
          this.lesionForm.patchValue({
            snapshot : snapshot
          })
          this.showModalSpinnerLoadingData = false;

         this.dialogRef.close(this.lesionForm.value)
        });
      });
    });
  }

  OnSave(){
      if(this.imageUrl=='')
        this.lesionForm.patchValue({
          snapshot : null
        })
      if (this.imageAttached) {
        this.saveImageToCloud(this.data.screenShotData);
      } else {
        this.lesionForm.patchValue({
          isNode: this.isNodalLocation
        });
        this.dialogRef.close(this.lesionForm.value)
      }
  }

  closeClick(){
    this.dialogRef.close();
  }

  validForm() {
    let valid = true;
    if (this.data.editDialog && !this.lesionForm.dirty)
      valid = false;

    if (
      this.data.editDialog &&
      this.data.editType == this.editType.SCREENSHOT &&
      this.imageUrl
    )
      valid = true;
    else if(
        this.data.editDialog &&
          this.data.editType == this.editType.MEASUREMENT &&
          this.imageUrl &&
          this.lesionForm.valid
      )
      valid = true;
    else
      valid = false;

    if (this.data.editDialog && this.lesionForm.dirty &&
      (this.data.editType == this.editType.LOCATION || this.data.editType == this.editType.COMMENT))
      valid = true;
    
    if (!this.data.editDialog && (!this.lesionForm.valid || !this.imageAttached))
      valid = false;
    
    if (!this.data.editDialog && this.lesionForm.valid && this.imageAttached)
      valid = true;
    
    return valid;
  }

  ngOnDestroy() {
    if (this.locationSubscription)
      this.locationSubscription.unsubscribe();

    if (this.eventSubscription)
      this.eventSubscription.unsubscribe();

    if (this.criteriaSubscription)
      this.criteriaSubscription.unsubscribe();
  }
}
