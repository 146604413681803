import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../reading-form-scoring.component';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {BasicResponse} from 'src/app/core/interfaces/basic-response';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {catchError, concatMap, map, mergeMap, switchMap, toArray} from 'rxjs/operators';
import {ImagingProjectService, ReadingConfigFlexibleService, StudySequenceLabelService} from 'src/app/_services';
import {ReadingGuerbetService} from 'src/app/_services/reading-guerbet.service';
import {ActivatedRoute} from '@angular/router';
import {PatientService} from 'src/app/_services/patient.service';
import {ConfirmSigningDialogComponent} from "../../ReportSign/confirm-signing-dialog/confirm-signing-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {ToastService} from "../../../_services/internal/toast.service";
import {ResponseCode} from "../../../core/constants/response-code";

export enum ReaderType {
  LEVEL1 = 'LEVEL1_READER',
  ADJUDICATION = 'ADJUDICATION_READER',
  CONSENSUS = 'CONSENSUS_READER'
}
@Component({
  selector: 'app-guerbet',
  templateUrl: './guerbet.component.html',
  styleUrls: ['./guerbet.component.css']
})
export class GuerbetComponent extends ReadingFormScoringComponent implements OnInit, OnDestroy, ReadingFormScoring {

  public get ReaderType(): typeof ReaderType {
    return ReaderType;
  }

  // tricky way to find decimal character
  decimalSeparator = (1.1).toLocaleString().substring(1, 2);
  lesionCount = 0;
  readingForm: FormGroup;
  currentReading: any = {
    studyId: 'NA',
    patientId: 'NA',
    visit: {id: 'NA'},
    aquisitionDate: 'NA',
    patientCode: 'NA'
  };
  flexibleConfig: any;
  visitConfigs: any[];
  isConsensusBlind = true;
  readerType: ReaderType;
  hasLesionMatching = true;
  relevantConsensusReading: any;
  readingInitialized = false;

  consensusReadingSubscription: Subscription;
  readingId: number;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private readingConfigService: ReadingConfigFlexibleService,
    private imagingProjectService: ImagingProjectService,
    private guerbetService: ReadingGuerbetService,
    private studySequenceLabelService: StudySequenceLabelService,
    private patientService: PatientService,
    private dialog: MatDialog,
    private toastService: ToastService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.viewerEnabledSubject.next(false);
    this.store.dispatch(new SetPageHeaderTitle('Guerbet'));

    this.stepSaveEnabled = false;

    this.initReadingForm();
    this.initOnCurrentReading();
    this.addLesionMatchingQuestionToReadingForm();
  }

  ngOnDestroy(): void {
    if (this.consensusReadingSubscription) {
      this.consensusReadingSubscription.unsubscribe();
    }
  }

  getSubmitButtonLabel() {
    if (this.isCompleted) {
      return this.isSigned ? '' : 'Sign';
    }
  }

  getHideSubmitButton(): boolean {
    return this.isCompleted && this.isSigned;
  }

  get isCompleted() {
    return this.currentReading?.status?.toLowerCase() === 'complete';
  }

  get isSigned() {
    return !!this.currentReading?.signDate;
  }

  initOnCurrentReading(): void {
    this.readingInitialized = false;
    this.currentReadingSubject.pipe(
      map(currentReading => this.currentReading = currentReading),
      map(() => {
        this.initReading();
        this.findAquisitionDate();
        this.findPatientCode();
      })).subscribe();
  }

  initReading(): void {
    this.relevantConsensusReading = null;
    this.readingConfigService.getById(this.currentReading.flexibleConfigId).pipe(
      map(response => {
        this.flexibleConfig = response.data.config;
        this.currentReading.visits = this.currentReading.visits.sort((a, b) => a.patientVisitOrder - b.patientVisitOrder);
        this.isConsensusBlind = this.flexibleConfig.adjudicationBlind;

        const currentVisit = this.currentReading.visits[0];
        this.readingForm.get('comment').setValue(currentVisit.comment);

        if (currentVisit.scoring) {

          // tslint:disable-next-line:max-line-length
          if (currentVisit.scoring.lesionAssessment && currentVisit.scoring.lesionAssessment.length === 0) {
            delete currentVisit.scoring.lesionAssessment;
          } else {
            for (let i = 0 ; i < currentVisit.scoring.lesionAssessment?.length ; i++) {
              if (this.readerType === ReaderType.LEVEL1) {
                delete currentVisit.scoring.lesionAssessment[i].readerId;
              }
              this.onAddLesion();
            }
          }

          if (currentVisit.scoring.technicalAdequacyOfTheCBVMap) {
            if (currentVisit.scoring.technicalAdequacyOfTheCBVMap.value === 'Non-diagnostic') {
              // tslint:disable-next-line:max-line-length
              (this.readingForm.get('technicalAdequacyOfTheCBVMap') as FormGroup).addControl('typeOfArtifactPresentInTheImages', new FormControl(null, Validators.required));
              const diagnosticQualityOfTheCBVMapValueControl = this.readingForm.get('diagnosticQualityOfTheCBVMap.value');
              if (diagnosticQualityOfTheCBVMapValueControl) {
                diagnosticQualityOfTheCBVMapValueControl.validator = null;
                diagnosticQualityOfTheCBVMapValueControl.updateValueAndValidity();
                diagnosticQualityOfTheCBVMapValueControl.reset();
              }
            } else {
              delete currentVisit.scoring.technicalAdequacyOfTheCBVMap.typeOfArtifactPresentInTheImages;
            }

            // tslint:disable-next-line:max-line-length
            const typeOfArtifactPresentInTheImages = (currentVisit.scoring.technicalAdequacyOfTheCBVMap.typeOfArtifactPresentInTheImages || '').split(';');
            if (typeOfArtifactPresentInTheImages.includes('Other, specify')) {
              (this.readingForm.get('technicalAdequacyOfTheCBVMap') as FormGroup).addControl('specifyValue', new FormControl(null));
            } else {
              delete currentVisit.scoring.technicalAdequacyOfTheCBVMap.specifyValue;
            }
          }

          if (this.readerType === ReaderType.CONSENSUS) {
            delete currentVisit.scoring.lesionMatching?.criteriaOfNotMatching;
            this.readingForm.removeControl('technicalAdequacyOfTheCBVMap');
            delete currentVisit.scoring.technicalAdequacyOfTheCBVMap;
          }

          if (this.readerType === ReaderType.LEVEL1) {
            delete currentVisit.scoring.lesionMatching;
          }

          if (this.readerType === ReaderType.ADJUDICATION) {
            delete currentVisit.scoring.diagnosticQualityOfTheCBVMap;
            delete currentVisit.scoring.technicalAdequacyOfTheCBVMap;
          }

          const scoring = currentVisit.scoring;

          this.setPrefilledDiagnosticQualityOfTheCBVMap();
          this.updateHasLesionMatching();

          scoring.lesionAssessment?.forEach((lesion, i) => {
            const fieldGroup = (<FormArray>(
              this.readingForm.get('lesionAssessment')
            )).controls[i];
            const isProvidingSufficientInformationNo =
              fieldGroup.get('providingSufficientInformation').value === 'No';
            if (isProvidingSufficientInformationNo) {
              fieldGroup.get('curveFWHM').disable();
              fieldGroup.get('curveFWHM').setValue('');
              fieldGroup.get('maximumSignalDrop').disable();
              fieldGroup.get('maximumSignalDrop').setValue('');
            }
          });

          this.checkValidity();
        }

      }),
      concatMap(() => {
        // get visit configs
        const serviceList = [];
        // tslint:disable-next-line:max-line-length
        (this.currentReading.visits as any[]).forEach((visit) => { serviceList.push(this.imagingProjectService.getVisitConfig(visit.visitConfigId)); });
        return this.getVisitConfigs(serviceList);
      })
    ).subscribe(() => {
      this.readingInitialized = true;
    });

    if (this.readerType === ReaderType.ADJUDICATION) {
      // tslint:disable-next-line:max-line-length
      this.consensusReadingSubscription = this.guerbetService.getConsensusReadingByReadingId(this.currentReading.levelOneReadings[0].id).subscribe(response => {
        if (response.responseCode === 200) {
          this.relevantConsensusReading = response.data;
        }
      });
    }
  }

  get formTitle() {
    if (this.readerType === ReaderType.LEVEL1) {
      return 'Primary Reading Form';
    }

    if (this.readerType === ReaderType.CONSENSUS) {
      return 'Consensus Reading Form';
    }

    if (this.readerType === ReaderType.ADJUDICATION) {
      return 'Lesion Matching Form';
    }

    return 'GUERBET Form';
  }
  setPrefilledDiagnosticQualityOfTheCBVMap() {
    const scoring = this.currentReading.visits[0].scoring;

    if (!scoring?.diagnosticQualityOfTheCBVMap?.value) {
      const answers = [];
      const sortedReadings = this.currentReading?.primaryReadings?.sort((a, b) => a.readerId - b.readerId);
      sortedReadings?.forEach(reading => {
        const val = reading?.visit?.scoring?.diagnosticQualityOfTheCBVMap?.value;
        if (val && !answers.includes(val)) {
          answers.push(val);
        }
      });

      if (answers.length === 1) {
        scoring.diagnosticQualityOfTheCBVMap = { value: answers[0] };
      }
    }

    if (this.readerType !== ReaderType.LEVEL1) {
      if (!scoring.lesionMatching) {
        scoring.lesionMatching = { value: null, comment: null };
      } else {
        this.updateLesionMatchingComment(scoring.lesionMatching.value, false);
      }
    }

    this.readingForm.patchValue(scoring);
  }

  updateHasLesionMatching() {
    if (this.readerType === ReaderType.ADJUDICATION) {
      // tslint:disable-next-line:max-line-length
      this.hasLesionMatching =  this.currentReading?.levelOneReadings?.some(reading => reading?.visit?.scoring?.technicalAdequacyOfTheCBVMap?.value !== 'Non-diagnostic');

      if (!this.hasLesionMatching) {
        this.readingForm.removeControl('lesionMatching');
      }
    }
  }
  getVisitConfigs(servicesList): Observable<any> {
    this.visitConfigs = [];
    return forkJoin(servicesList).pipe(
      map((responseList: any[]) => {

        (this.currentReading.visits as any[]).forEach(visit => {
          const visitConfig = responseList.find(resp => resp.data.id === visit.visitConfigId).data;

          visit.name = visitConfig.visitName;
          visit.durationTimeUnit = visitConfig.visit.durationTimeUnit;
          visit.durationTimeValue = visitConfig.visit.durationTimeValue;
          visit.type = visitConfig.visitType;
          visit.durationTimeUnit = visitConfig.visit.durationTimeUnit;
          visit.noUploads = visitConfig.noUploads;
          if (this.flexibleConfig.hideVisitChronology === 'hide_visits_chronology') {
            visit.name = visitConfig.visitBlindName; // for blind name
          }
          this.visitConfigs.push(visitConfig);
        });

      })
    );
  }

  initReadingForm(): void {
    this.readingForm = this.fb.group({
      technicalAdequacyOfTheCBVMap: new FormGroup({
        value: new FormControl(null, Validators.required)
      }),
      diagnosticQualityOfTheCBVMap: new FormGroup({
        value: new FormControl(null, Validators.required)
      }),
      comment: new FormControl(null, null)
    });
  }

  lesionAssessmentForm(): FormGroup {
    if (this.readerType === ReaderType.CONSENSUS) {
      return this.fb.group({
        readerId: new FormControl(null, Validators.required),
        name: new FormControl(null, Validators.required),
        anatomicalLocation: new FormControl(null, Validators.required),
        longestDiameter: new FormControl(null, [Validators.required, this.decimalValidator(2)]),
        rcbvmeanValueAtTheLesionROI: new FormControl(null, Validators.required),
        gilomaGrade: new FormControl(null, Validators.required),
        providingSufficientInformation: new FormControl(null, Validators.required),
        levelOfDiagnosisConfidence: new FormControl(null, Validators.required),
        curveFWHM: new FormControl(null, [Validators.required, this.decimalValidator(2)]),
        maximumSignalDrop: new FormControl(null, [Validators.required, this.decimalValidator(2, true)]),
      });
    } else {
      return this.fb.group({
        name: 'Lesion ' + this.lesionCount.toString(),
        anatomicalLocation: new FormControl(null, Validators.required),
        longestDiameter: new FormControl(null, [Validators.required, this.decimalValidator(2)]),
        rcbvmeanValueAtTheLesionROI: new FormControl(null, [Validators.required, this.decimalValidator(3)]),
        gilomaGrade: new FormControl(null, Validators.required),
        providingSufficientInformation: new FormControl(null, Validators.required),
        levelOfDiagnosisConfidence: new FormControl(null, Validators.required),
        curveFWHM: new FormControl(null, [Validators.required, this.decimalValidator(2)]),
        maximumSignalDrop: new FormControl(null, [Validators.required, this.decimalValidator(2, true)]),
      });
    }
  }

  decimalValidator(decimalPlaces: number, negative: boolean = false) {
    return (control) => {
      const regexPattern = negative
        ? `^(0|-\\d+(\\.\\d{1,${decimalPlaces}})?)$`
        : `^\\d+(\\.\\d{1,${decimalPlaces}})?$`;
      const pattern = new RegExp(regexPattern);

      return pattern.test(control.value) ? null : {invalidNumber: true};
    };
  }

  getLesionAssessmentControls() {
    return (<FormArray>this.readingForm.get('lesionAssessment')).controls;
  }

  onStopAssessment() {
    if (this.readerType === ReaderType.LEVEL1) {
      return this.readingForm.get('technicalAdequacyOfTheCBVMap.value')?.value === 'Non-diagnostic';
    }
  }

  getCBVMapPrimaryAnswers(index: number) {
    if (this.currentReading?.primaryReadings) {
      const sortedReadings = this.currentReading.primaryReadings.sort((a, b) => a.readerId - b.readerId);
      return sortedReadings[index]?.visit?.scoring.diagnosticQualityOfTheCBVMap.value;
    }
  }

  get disabledDiagnosticQuality () {
    if (this.onStopAssessment()) {
      return true;
    }

    const firstPrimaryAnswer = this.getCBVMapPrimaryAnswers(0);
    if (firstPrimaryAnswer === undefined) {
      return false;
    }

    const secondPrimaryAnswer = this.getCBVMapPrimaryAnswers(1);
    if (secondPrimaryAnswer === undefined) {
      return false;
    }

    return firstPrimaryAnswer === secondPrimaryAnswer;
  }

  getAllRelevantReadingLesions(): any[] {
    const level1reading = this.readerType === ReaderType.CONSENSUS ? 'primaryReadings' : 'levelOneReadings';
    const lesionAssessments = [];
    if (this.currentReading[level1reading]) {
      const sortedReadings = this.currentReading[level1reading].sort((a, b) => a.readerId - b.readerId);
      sortedReadings.forEach((reading, index) => {
        if (reading.visit.scoring?.lesionAssessment?.length) {
          reading.visit.scoring.lesionAssessment.forEach((lesion) => {
            lesion.readerId = 'Reader ' + (index + 1);
            lesionAssessments.push(lesion);
          });
        }
      });
    }

    if (this.readerType === ReaderType.ADJUDICATION) {
      if (this.relevantConsensusReading) {
        if (this.relevantConsensusReading.visit.scoring?.lesionAssessment?.length) {
            this.relevantConsensusReading.visit.scoring?.lesionAssessment.forEach((lesion) => {
            lesion.readerId = 'Consensus Reader';
            lesionAssessments.push(lesion);
          });
        }
      }
    }

    return lesionAssessments;
  }

  getReaders(): any[] {
    return this.getAllRelevantReadingLesions().map(l => l.readerId).filter((r, index, self) => index === self.indexOf(r));
  }

  getLesionOfReaderByReaderId(readerId: number) {
    return this.getAllRelevantReadingLesions().filter((lesion, index, array) => lesion.readerId === readerId);
  }

  onSelectReader(index: number) {
    const lesionForm: FormGroup = this.getLesionAssessmentControls()[index] as FormGroup;
    lesionForm.get('name').reset();
    this.checkValidity();
  }

  onLesionSelect(consensusLesionIndex: number, readerId: number, lesionName: string) {
    const lesionForm: FormGroup = this.getLesionAssessmentControls()[consensusLesionIndex] as FormGroup;
    const lesion = this.getLesionOfReaderByReaderId(readerId).find(l => l.name === lesionName);
    lesionForm.patchValue(lesion);
    lesionForm.get('rcbvmeanValueAtTheLesionROI').setValue(+lesion.rcbvmeanValueAtTheLesionROI);

    this.onChangeprovidingSufficientInformation({value: lesion.providingSufficientInformation }, consensusLesionIndex);
  }

  onAddLesion(): void {
    this.lesionCount += 1;
    if (!this.readingForm.get('lesionAssessment')) {
      this.readingForm.addControl('lesionAssessment', new FormArray([this.lesionAssessmentForm()]));
    } else {
      (this.readingForm.get('lesionAssessment') as FormArray).push(this.lesionAssessmentForm());
    }

    this.checkValidity();
  }

  onRemoveLesion() {
    if (this.lesionCount === 1) {
      this.readingForm.removeControl('lesionAssessment');
    } else {
      (this.readingForm.get('lesionAssessment') as FormArray).removeAt(this.lesionCount - 1);
    }
    this.lesionCount -= 1;
    this.checkValidity();
  }

  technicalAdequacyOfTheCBVMapChange(event) {
    if (event.value === 'Non-diagnostic') {
      (this.readingForm.get('technicalAdequacyOfTheCBVMap') as FormGroup)
        .addControl('typeOfArtifactPresentInTheImages', new FormControl(null, Validators.required));
    } else if (event.value !== 'Non-diagnostic' &&
              (this.readingForm.get('technicalAdequacyOfTheCBVMap.typeOfArtifactPresentInTheImages') !== undefined ||
              this.readingForm.get('technicalAdequacyOfTheCBVMap.typeOfArtifactPresentInTheImages').value === null)) {
      (this.readingForm.get('technicalAdequacyOfTheCBVMap') as FormGroup).removeControl('typeOfArtifactPresentInTheImages');
      (this.readingForm.get('technicalAdequacyOfTheCBVMap') as FormGroup).removeControl('specifyValue');
    }

    if (this.onStopAssessment()) {
      this.readingForm.get('diagnosticQualityOfTheCBVMap').reset();
      this.readingForm.get('diagnosticQualityOfTheCBVMap.value').validator = null;
      this.readingForm.get('diagnosticQualityOfTheCBVMap.value').updateValueAndValidity();
      this.readingForm.get('diagnosticQualityOfTheCBVMap.value').reset();
      this.readingForm.removeControl('lesionAssessment');
      this.lesionCount = 0;
    } else {
      this.readingForm.get('diagnosticQualityOfTheCBVMap.value').validator = Validators.required;
      this.readingForm.get('diagnosticQualityOfTheCBVMap.value').updateValueAndValidity();
    }
    this.checkValidity();
  }

  typeOfArtifactPresentInTheImagesChange(value, isChecked) {
    // tslint:disable-next-line:max-line-length
    let valuesList = (this.readingForm.get('technicalAdequacyOfTheCBVMap.typeOfArtifactPresentInTheImages')?.value || '').split(';').filter(f => f !== '');
    if (isChecked) {
      if (!valuesList.includes(value)) {
        valuesList.push(value);
      }
    } else {
      valuesList = valuesList.filter(v => v !== value);
    }

    if (valuesList.includes('Other, specify')) {
      if (!this.readingForm.get('technicalAdequacyOfTheCBVMap.specifyValue')) {
        // tslint:disable-next-line:max-line-length
        (this.readingForm.get('technicalAdequacyOfTheCBVMap') as FormGroup).addControl('specifyValue', new FormControl(null, Validators.required));
      }
    } else if (
      this.readingForm.get('technicalAdequacyOfTheCBVMap.specifyValue') !== undefined ||
      this.readingForm.get('technicalAdequacyOfTheCBVMap.specifyValue').value === null
    ) {
      (this.readingForm.get('technicalAdequacyOfTheCBVMap') as FormGroup).removeControl('specifyValue');
    }

    this.readingForm.get('technicalAdequacyOfTheCBVMap.typeOfArtifactPresentInTheImages').setValue(valuesList.join(';'));

    this.checkValidity();
  }

  getTypeOfArtifactPresentInTheImages(value) {
    const controlValue = this.readingForm.get('technicalAdequacyOfTheCBVMap.typeOfArtifactPresentInTheImages')?.value || '';
    const valuesList = controlValue.split(';');

    return valuesList.includes(value);
  }

  onChangeprovidingSufficientInformation(event, i) {
    const isYes = event.value === 'Yes';
    const fieldGroup = (<FormArray>this.readingForm.get('lesionAssessment'))
      .controls[i];

    const curveFWHM = fieldGroup.get('curveFWHM');
    const maximumSignalDrop = fieldGroup.get('maximumSignalDrop');

    curveFWHM.setValidators(isYes ? [Validators.required, this.decimalValidator(2)] : null);
    curveFWHM.updateValueAndValidity();

    maximumSignalDrop.setValidators(isYes ? [Validators.required, this.decimalValidator(2, true)] : null);
    maximumSignalDrop.updateValueAndValidity();
    if (!isYes) {
      curveFWHM.disable();
      curveFWHM.setValue('');

      maximumSignalDrop.disable();
      maximumSignalDrop.setValue('');
    } else {
      curveFWHM.enable();
      maximumSignalDrop.enable();
    }

    this.checkValidity();
  }

  updateLesionMatchingComment(value, callCheckValidity = true) {
    const commentControl = this.readingForm.get('lesionMatching.comment');

    if (commentControl) {
      if (value === 'No' && this.readerType !== ReaderType.ADJUDICATION) {
        commentControl.setValidators([Validators.required]);
      } else {
        commentControl.clearValidators();
      }

      commentControl.updateValueAndValidity();
    }

    if (callCheckValidity) {
      this.checkValidity();
    }
  }
  checkValidity() {
    if (this.isCompleted) {
      this.switchSubmitBtnDisabledSubject.next(false);
      return;
    }

    if (this.lesionCount < 1 && (this.readerType === ReaderType.CONSENSUS || this.onStopAssessment() === false)) {
      this.switchSubmitBtnDisabledSubject.next(true);
    } else {
      this.readingForm.updateValueAndValidity();
      this.switchSubmitBtnDisabledSubject.next(this.readingForm.invalid);
    }
  }

  findAquisitionDate() {
    const seriesIds = (this.currentReading.visits[0].series || [] as Array<any>).map(s => s.seriesId);
    if (seriesIds.length > 0) {
      this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).pipe(
        map(response => {
          // if (response?.data && response?.data.length > 0) {
          // tslint:disable-next-line:max-line-length
          const scanTimes = (response?.data ?? []).filter(s => s.scanDate).map(s => new Date(s.scanDate).getTime());
          const minTime = Math.min(...scanTimes);
          const minDate = (new Date(minTime));
          const minIsoString = minDate.toISOString();
          this.currentReading.aquisitionDate = minIsoString.substring(0, 10);
          // }
        }),
      ).subscribe();
    }
  }

  findPatientCode() {
    this.patientService.getById(this.currentReading.patientId).subscribe(resp => {
      this.currentReading.patientCode = resp.data.patientCode;
    });
  }

  addLesionMatchingQuestionToReadingForm() {
    if (this.readerType === ReaderType.CONSENSUS) {
      this.readingForm.addControl('lesionMatching', new FormGroup({ value: new FormControl(null, Validators.required),
                                                                    comment: new FormControl(null)}));
      this.checkValidity();
    }

    if (this.readerType === ReaderType.ADJUDICATION) {
      this.readingForm.removeControl('technicalAdequacyOfTheCBVMap');
      this.readingForm.removeControl('diagnosticQualityOfTheCBVMap');
      this.readingForm.addControl('lesionMatching', new FormGroup({ value: new FormControl(null, Validators.required),
        criteriaOfNotMatching: new FormControl(null),

        comment: new FormControl(null)}));

      this.checkValidity();
    }
  }

  // convert value to fixed
  ensureFormat(value: number, decimalDigits: number) {
    const pow = Math.pow(10, decimalDigits);
    const floorValue = Math.floor(value * pow) / pow;
    return value * pow - floorValue ? floorValue.toFixed(decimalDigits) : value;
  }

  // try to find how hany decimal digits we have and cut extra digits
  ensureStringFormat(value: string, decimalDigits: number) {
    const index = value.indexOf(this.decimalSeparator);
    const decimals = index >= 0 ? value.substring(index+1) : '';
    return decimals.length > decimalDigits ? value.substring(0, index+1) + decimals.substring(0, decimalDigits) : value;
  }

  setGliomaGrade(stringValue: string, lesionIndex) {
    // tslint:disable-next-line:max-line-length
    const control = ((this.readingForm.get('lesionAssessment') as FormArray).controls[lesionIndex] as FormGroup).get('rcbvmeanValueAtTheLesionROI');
    if (stringValue != this.ensureStringFormat(stringValue, 3)) {
      control.setValue(this.ensureFormat(control.value, 3));
    }

    if (control.value < 1.75) {
      ((this.readingForm.get('lesionAssessment') as FormArray).controls[lesionIndex] as FormGroup).get('gilomaGrade').setValue('Low grade');
    } else {
      // tslint:disable-next-line:max-line-length
      ((this.readingForm.get('lesionAssessment') as FormArray).controls[lesionIndex] as FormGroup).get('gilomaGrade').setValue('High grade');
    }
    this.checkValidity();
  }

  onLesionControlChange(stringValue: string, lesionIndex, controlName, digits: number) {
    const control = ((this.readingForm.get('lesionAssessment') as FormArray).controls[lesionIndex] as FormGroup).get(controlName);
    if (stringValue != this.ensureStringFormat(stringValue, digits)) {
      control.setValue(this.ensureFormat(control.value, digits));
    }

    this.checkValidity();
  }

  getEndpointName(): string {
    return 'GUERBET';
  }

  mapReaderType(urlReaderType: string) {
    switch (urlReaderType) {
      case ReaderType.CONSENSUS: return 'consensus_reader';
      case ReaderType.ADJUDICATION: return 'adjudication_reader';
      case ReaderType.LEVEL1: return 'level1_reader';
    }
  }

  loadSingleReading(readingId: number) {
    return this.guerbetService.getReadingByReadingId(readingId).pipe(
      switchMap(readingResponse => {
        if (readingResponse.responseCode === 200) {
          this.currentReading = readingResponse.data;

          return this.guerbetService.getSignReportsByStudyId(this.currentReading.studyId).pipe(
            map(readingsList => {
              const mappedReaderType = this.mapReaderType(this.readerType);
              const data = readingsList.filter(r => r.readerType === mappedReaderType).sort((a, b) => a.id - b.id).map(reading =>
                reading.id === this.currentReading.id ? this.currentReading : reading
              );

              return {
                responseCode: 200,
                responseMessage: 'Success',
                studyId: this.currentReading.studyId,
                data,
              };
            })
          );
        } else {
          throw new Error('Failed to load reading');
        }
      })
    );
  }

  getReadingId() {
    return this.readingId;
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    switch (this.activatedRoute.snapshot.params.readerType) {
      case 'adjudication':
        this.readerType = ReaderType.ADJUDICATION;
        break;
      case 'consensus':
        this.readerType = ReaderType.CONSENSUS;
        break;
      default:
        this.readerType = ReaderType.LEVEL1;
        break;
    }

    const readingId = this.activatedRoute.snapshot.params.readingId;
    if (readingId) {
      this.readingId = readingId;
      return this.loadSingleReading(readingId);
    }

    return this.guerbetService.getAvailableReadings(studyId, readerId, this.readerType);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    if (this.isCompleted) {
      return of(null);
    }

    return this.guerbetService.startReading(readingId);
  }

  saveReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.updateReading(studyId, this.currentReading.id, data);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number; }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    if (this.isCompleted) {
      return of(null);
    }

    const readingData = { id: this.currentReading.id, spentSeconds: data.spentSeconds };

    if (this.readingInitialized) {
      return forkJoin([
        this.guerbetService.updateReadingOld(studyId, this.currentReading.id, readingData),
        this.guerbetService.storeScoring(this.currentReading.visits[0].id, this.readingForm.value)
      ]) as any;
    } else {
      return this.guerbetService.updateReadingOld(studyId, this.currentReading.id, readingData) as any;
    }
  }

  completeReading(studyId: number, data: { spentSeconds: number; }, jwtToken) {
    this.currentReading.visits[0].scoring = this.readingForm.value;
    const comment = this.readingForm.get('comment')?.value;
    const submitData = {
      scoring: this.readingForm.value,
      imageQualityAssessment: 'Optimal',
      imageQualityAssessmentComment: 'test',
      disagreement: true,
      timeSpent: data.spentSeconds,
      comment: comment
    };

    submitData.scoring.lesionAssessment = (submitData.scoring?.lesionAssessment ?? []).map(l => {
      return {
        ...l,
        curveFWHM: (+l.curveFWHM).toFixed(2),
        longestDiameter: (+l.longestDiameter).toFixed(2),
        maximumSignalDrop: (+l.maximumSignalDrop).toFixed(2),
        rcbvmeanValueAtTheLesionROI: (+l.rcbvmeanValueAtTheLesionROI).toFixed(3),
      };
    });

    return this.guerbetService.completeReading(this.currentReading.id, submitData, this.readerType, jwtToken);
  }

  signReading(jwtToken) {
    return this.guerbetService.signReading(this.currentReading.studyId, this.currentReading.id, jwtToken);
  }

  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {


    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.currentReading.studyId,
        patientId: this.currentReading.patientId,
        visitConfigId: this.currentReading.visitConfigId
      }
    });

    return dialogRef.afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          if (!dialogResponse?.jwt) {
            if (!dialogResponse?.canceled) {
              this.toastService.error('ERROR', 'Sign Report Failed.<br/>Please enter valid credentials');
            }

            return of({
              responseCode: ResponseCode.OK,
              responseMessage: 'canceled',
              data: {
                canceled: true
              }
            });
          }

          if (this.isCompleted) {
            if (this.isSigned) {
              return of(null);
            } else {
              return this.signReading(dialogResponse.jwt);
            }
          } else {
            return this.completeReading(studyId, data, dialogResponse.jwt);
          }
        })
      );
  }

  get showLesionMatching() {
    return [ReaderType.ADJUDICATION, ReaderType.CONSENSUS].includes(this.readerType) && this.hasLesionMatching;
  }
  clearForm(): void {
    this.readingForm.removeControl('lesionAssessment');
    this.lesionCount = 0;
    this.readingForm.reset();
  }

  getUrlForReaderType(readerType: string) {
    switch (readerType) {
      case 'consensus_reader': return 'consensus';
      case 'adjudication_reader': return 'adjudication';
      case 'level1_reader': return 'level-one';
    }
  }

  getNextReadingUrl(reading) {
    if (!this.isCompleted) {
      return;
    }

    const urlPath = this.getUrlForReaderType(reading.readerType);

    return `/reading/guerbet/${urlPath}/${reading.id}`;
  }

  getSignList() {
    return this.isCompleted;
  }
}
