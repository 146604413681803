import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class TrialPhaseService {

  constructor(private http: HttpClient) { }

  create(trialPhase: any) {
    return this.http.post(requestURL + `/trial-phase`, trialPhase);
  }
  
  update(trialPhase: any) {
    return this.http.put(requestURL + `/trial-phase`, trialPhase);
  }
  
  delete(id: number) {
    return this.http.delete(requestURL + `/trial-phase/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/trial-phase/` + id);
  }
  
  getAll() {
    return this.http.get(requestURL + `/trial-phase/retrieve-all`);
  }
}
