import {BodySubLocationModel} from './body-sub-location-model';
import {BodyLocationAreaModel} from './body-location-area-model';

export interface PrimaryBodyLocationModel {
  id: number;
  name: string;
  nodal: boolean;
  status: PrimaryBodyLocationStatus;
  bodySubLocations: BodySubLocationModel[];
  bodyLocationArea: BodyLocationAreaModel;
}

export enum PrimaryBodyLocationStatus {
  ACTIVE = 'Active',
  DISABLED = 'DISABLED'
}
