import get from 'lodash/get';
import * as moment from 'moment';
import {formatDate} from '@angular/common';

export class QCTask {
  id: number;
  studyId: number;
  generalInfoId: number;
  patientId: number;
  siteId: number;
  visitConfigId: number;
  siteConfigId: number;
  qcUserId: number;
  queriesId: number;
  numberInStudy: number;
  imageAnalysisExecutionId: number;
  status: string; // @TODO enum
  studyName: string;
  siteCode: string;
  patientCode: string;
  visitName: string;
  qcUserFullName: string;
  modalities: Array<any>; // @TODO add modality type
  uploadDate: string; // @TODO replace with Date type
  scanDate: string; // @TODO replace with Date type
  qcDate: string; // @TODO replace with Date type

  constructor(data) {
    this.id = get(data, 'id', null);
    this.studyId = get(data, 'studyId', null);
    this.generalInfoId = get(data, 'generalInfoId', null);
    this.patientId = get(data, 'patientId', null);
    this.siteId = get(data, 'siteId', null);
    this.visitConfigId = get(data, 'visitConfigId', null);
    this.siteConfigId = get(data, 'siteConfigId', null);
    this.qcUserId = get(data, 'qcUserId', null);
    this.queriesId = get(data, 'queriesId', null);
    this.numberInStudy = get(data, 'numberInStudy', null);
    this.imageAnalysisExecutionId = get(data, 'imageAnalysisExecutionId', null);
    this.status = get(data, 'status');
    this.studyName = get(data, 'studyName');
    this.siteCode = get(data, 'siteCode');
    this.patientCode = get(data, 'patientCode');
    this.visitName = get(data, 'visitName');
    this.qcUserFullName = get(data, 'qcUserFullName');
    this.modalities = get(data, 'modalities', []);

    const uploadDate = get(data, 'uploadDate', null);
    const scanDate = get(data, 'scanDate', null);
    const qcDate = get(data, 'qcDate', null);
    this.uploadDate = uploadDate ? moment(uploadDate).format('DD/MM/YYYY') : null;
    this.scanDate = scanDate ? moment(scanDate, 'DD/MM/YYYY').format('DD/MM/YYYY  HH:mm:ss') : null;
    this.qcDate = qcDate ? moment(qcDate).format('DD/MM/YYYY') : null;
  }
}
