import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface dialogData {
  taskList: any[],
  patientCode: string
}

@Component({
  selector: 'app-qc-patient-tasks-dialog',
  templateUrl: './qc-patient-tasks-dialog.component.html',
  styleUrls: ['./qc-patient-tasks-dialog.component.css']
})
export class QcPatientTasksDialogComponent implements OnInit {
  displayedColumns: string[] = ['id', 'status', 'siteCode', 'visitName', 'scanDate', 'uploadDate', 'qcDate'];

  constructor(public dialogRef: MatDialogRef<QcPatientTasksDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: dialogData) { }

  ngOnInit(): void {
  }

  clickTableCell(task: any) {
    this.dialogRef.close(task)
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

}
