import {ReadingL3smiService} from './../../../_services/reading-l3smi.service';
import {Component, OnInit} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../reading-form-scoring.component';
import {Observable, Subscription} from 'rxjs';
import {BasicResponse} from 'src/app/core/interfaces/basic-response';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from 'src/app/core/data-management/actions/projects.action';
import {DataUploadService} from 'src/app/_services';
import {VisitStatus} from 'src/app/_models/ImagingProject/IF/incidental-findings-config-model';
import {ReadingLevel} from 'src/app/core/constants/reading-level';
import {ResponseCode} from 'src/app/core/constants/response-code';
import {ToastOptions, ToastyService} from 'ng2-toasty';

@Component({
  selector: 'app-l3-smi',
  templateUrl: './l3-smi.component.html',
  styleUrls: ['./l3-smi.component.css']
})
export class L3SMIComponent extends ReadingFormScoringComponent implements OnInit, ReadingFormScoring {

  currentReading: any;
  readingForm: FormGroup = this.fb.group({});
  selectedVisit = 0;
  activeVisit: any;
  dataUploadSubscription: Subscription;
  baselineSmaScore: number;
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private l3smiService: ReadingL3smiService,
    private dataUploadService: DataUploadService,
    private toastService: ToastyService
  ) {
    super();
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('L3 SMI'));
    this.initOnCurrentReading();
    this.readingSeriesInitiated.subscribe(resp => {
      if (resp) {
        this.chooseActiveVisitByStatus();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.dataUploadSubscription)
      this.dataUploadSubscription.unsubscribe();
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      if (currentReading.readingLevel.toLowerCase() === ReadingLevel.LONGITUDINAL.toLowerCase()) {
        this.switchSubmitBtnDisabledSubject.next(false);
      }
      this.readingForm = new FormGroup({
        visits: new FormArray([])
      });
      this.currentReading = currentReading;
      this.initReadingForm();
      this.activeVisit = this.currentReading.visits.find(visit => [VisitStatus.IN_PROGRESS, VisitStatus.NEW_EDITABLE].includes(visit.status));
      this.getPatientHeightFromEdtf();
    });
  }

  initReadingForm(): void {
    this.currentReading.visits = this.currentReading.visits.sort((a, b) => a.visitOrder - b.visitOrder);
    this.currentReading.visits.forEach((v, visitIndex) => {
      //create visit form
      const visitFormControl = new FormGroup({
        id: new FormControl(v.id),
        visitConfigId: new FormControl(v.visitConfigId),
        status: new FormControl(v.status),
        visitOrder: new FormControl(v.visitOrder),
        timepoint: new FormControl(v.timepoint),
        patientsHeight: new FormControl(null),
        sma: new FormControl(v.scoring.sma, [Validators.required, this.validateOrdinalNumber]),
        sliceNumber: new FormControl(v.scoring.sliceNumber, Validators.required),
        l3SkeletalMusceleIndex: new FormControl(v.scoring.l3SkeletalMusceleIndex, Validators.required),
        percentageChangeFromBaseline: new FormControl(v.scoring.percentageChangeFromBaseline, Validators.required),
        comment: new FormControl(v.comment),
      });
      (<FormArray>this.readingForm.get('visits')).push(visitFormControl)
    });
  }

  getVisitArrayForm(index: number) {
    return (<FormGroup>(<FormArray>this.readingForm.get('visits')).controls[index]);
  }

  chooseActiveVisitByStatus() {
    const activeVisitIndex = this.currentReading.visits.findIndex(visit => [VisitStatus.IN_PROGRESS, VisitStatus.NEW_EDITABLE].includes(visit.status));
    this.activeVisit = this.currentReading.visits[activeVisitIndex];
    if (activeVisitIndex !== -1)
      this.onChangeActivatedVisit(activeVisitIndex);
  }

  onChangeActivatedVisit(index) {
    this.selectedVisit = index;
    // this.activedVisitSubject.next(this.currentReading.visits[index]);
  }

  getPreviousVisits() {
    const visits = (<FormArray>this.readingForm.get('visits')).value;
    return visits.filter(visit => visit.visitOrder < this.activeVisit?.visitOrder);
  }

  isPatientHeight(fieldName?: string) {
    if (!fieldName) {
      return false;
    }

    const checkFieldName = fieldName.toString().trimEnd().trimStart().toLowerCase();

    return checkFieldName.includes('patient') && checkFieldName.includes('height');
  }
  getPatientHeightFromEdtf() {
    // tslint:disable-next-line:max-line-length
    this.dataUploadSubscription = this.dataUploadService.getUploadGeneralInfoByVisitConfigId(this.currentReading.studyId, this.activeVisit?.visitConfigId).subscribe((response: any) => {
      if (response.responseCode === ResponseCode.OK) {
        const generalInfo: any[] = response.data;
        generalInfo.forEach(gInfo => {
          if (gInfo.edtf) {
            gInfo.edtf.blocks.forEach(block => {
              if (block.hasOwnProperty('group')) {
                const groups = block.group;
                groups.forEach(group => {
                  if (this.isPatientHeight(group?.question) || this.isPatientHeight(block?.description)) {
                    if (group.hasOwnProperty('inputText')) {
                      if (group.inputText.value) {
                        this.currentReading.visits.forEach((visit, index) => {
                          // tslint:disable-next-line:max-line-length
                          (<FormGroup>(<FormArray>this.readingForm.get('visits')).controls[index]).get('patientsHeight').setValue(group.inputText.value);
                        });
                      }
                    }
                  } else {
                    this.currentReading.visits.forEach((visit, index) => {
                      (<FormGroup>(<FormArray>this.readingForm.get('visits')).controls[index]).get('patientsHeight').setValue(null);
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }

  get validateSMA() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value
        && (control.value.toString().includes('.'))
        && ((control.value.toString().substring(0 , control.value.toString().indexOf('.')).length !== 2)
        || (
          control
            .value
            .toString()
            .substring(control.value.toString().indexOf('.') + 1, control.value.toString().length + 1).length !== 2))) {
        return {
          errorMessage: 'Please enter exact 2 decimal before and after decimal point.'
        };
      }

      if (isNaN(control.value)) {
        return {
          errorMessage: 'Please enter a number.'
        };
      }

      return null;
    };
  }

  get validateOrdinalNumber() {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value && isNaN(control.value)) {
        return {
          errorMessage: 'Please enter a number.'
        };
      }

      return null;
    };
  }

  validateNumber(event,maxNumber) {
    const keyCode = event.keyCode;
    if(keyCode == 16)
    event.preventDefault()

    if(event.target.value.length == maxNumber && keyCode != 8)
     event.preventDefault()

    const excludedKeys = [8, 37, 39, 46, 190];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode > 64 && keyCode < 91) ||
      (keyCode == 32) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }

  calculateL3SkeletalMusceleIndex(index: number) {
    if(!this.getVisitArrayForm(index).get('sma').value) {
      this.getVisitArrayForm(index).get('l3SkeletalMusceleIndex').setValue(null);
    } else {
      const l3SkeletalMusceleIndex = (this.getVisitArrayForm(index).get('sma').value / ((this.getVisitArrayForm(index).get('patientsHeight').value) * (this.getVisitArrayForm(index).get('patientsHeight').value))).toFixed(2);
      this.getVisitArrayForm(index).get('l3SkeletalMusceleIndex').setValue(l3SkeletalMusceleIndex);
      this.getVisitArrayForm(index).get('l3SkeletalMusceleIndex').updateValueAndValidity();
      this.calculatepercentageChangeFromBaseline(index);
    }
  }

  calculatepercentageChangeFromBaseline(index: number) {
    if (!this.getVisitArrayForm(index).get('sma').value) {
      this.getVisitArrayForm(index).get('percentageChangeFromBaseline').setValue(null);
    } else {
      const baselineSmaScore = (<FormGroup>(<FormArray>this.readingForm.get('visits')).controls[0]).get('sma').value;

      // for baseline visit percentage must be N/A
      const percentageChangeFromBaseline =
          (index === 0)
          ? 'N/A'
          : (((this.getVisitArrayForm(index).get('sma').value - baselineSmaScore) / baselineSmaScore) * 100).toFixed(2) + '%';
      this.getVisitArrayForm(index).get('percentageChangeFromBaseline').setValue(percentageChangeFromBaseline);
    }
  }

   checkValidity(index: number) {
    return (<FormGroup>(<FormArray>this.readingForm.get('visits')).controls[index]).invalid;
  }

  getEndpointName(): string {
    return 'L3SMI';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.l3smiService.getReading(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.l3smiService.startReading(readingId);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number; }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    this.updateReadingNavigator(this.currentReading);
    const readingData = {
      spentSeconds: data.spentSeconds,
      comment: ""
    }
    return this.l3smiService.updateReading(readingId, readingData);
  }

  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    return this.l3smiService.completeReading(this.currentReading.id, this.currentReading);
  }

  submitVisit(visitIndex: number) {
    const visit = this.currentReading.visits[this.selectedVisit];
    const data = {
      doneVisit: true,
      comment: this.getVisitArrayForm(visitIndex).get('comment').value,
      scoring: {
        patientsHeight: this.getVisitArrayForm(visitIndex).get('patientsHeight').value,
        sma: this.getVisitArrayForm(visitIndex).get('sma').value,
        sliceNumber: this.getVisitArrayForm(visitIndex).get('sliceNumber').value,
        l3SkeletalMusceleIndex: this.getVisitArrayForm(visitIndex).get('l3SkeletalMusceleIndex').value,
        percentageChangeFromBaseline: this.getVisitArrayForm(visitIndex).get('percentageChangeFromBaseline').value,
      }
    }

    this.l3smiService.lockTimepoint(this.currentReading.studyId, visit.id, data).subscribe(() => {
      this.toastOptions.title = 'SUCCESS: Timepoint is successfully saved and locked';
      this.toastService.success(this.toastOptions);
      visit.status = VisitStatus.DONE;
      const nextVisit = this.currentReading.visits.find(v => v.visitOrder > visit.visitOrder && !v.noUpload && v.status !== VisitStatus.NOT_AVAILABLE);
      if (nextVisit) {
        nextVisit.status = VisitStatus.IN_PROGRESS;
        this.chooseActiveVisitByStatus();
      }
      else {
        this.updateReadingListAfterAllVisitDone(this.currentReading);
      }
    }, (error => {
      this.toastOptions.title = 'ERROR: ' + error.message;
      this.toastService.error(this.toastOptions);
    }))
  }

  updateReadingListAfterAllVisitDone(reading) {
    if (!this.readingListUpdatedAfterVisitsDoneSubject.closed)
      this.readingListUpdatedAfterVisitsDoneSubject.next(reading);
  }

  updateReadingNavigator(reading: any) {
    if (!this.readingListUpdatedSubject.closed)
      this.readingListUpdatedSubject.next(reading);
  }

  clearForm(): void {
  }

  filterReading(reading: any): boolean {
    return (reading.visits || []).some(v => v.status !== 'DONE');
  }
}
