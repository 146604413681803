import get from 'lodash/get';
import { SliceSynchronizationModel } from './slice-synchronization.model';

export class ViewerConfigOptionsModel {
  referenceLinesOn: boolean;
  defaultSliceDisplay: string;
  defaultFrameDisplay: string;
  sliceSynchronization: SliceSynchronizationModel;

  constructor(data: any) {
    if (data) {
      this._parse(data);
    }
  }

  private _parse(data: any) {
    const sliceSynchronization = get(data, 'SliceSynchronization', null);

    this.referenceLinesOn = !!get(data, 'ReferenceLinesOn._text', 'false').toLowerCase();
    this.defaultSliceDisplay = get(data, 'DefaultSliceDisplay._text', '').toLowerCase();
    this.defaultFrameDisplay = get(data, 'DefaultFrameDisplay._text', '').toLowerCase();

    if (sliceSynchronization) {
      this.sliceSynchronization = new SliceSynchronizationModel();
      this.sliceSynchronization.automaticSliceMatchingOn =
        !!get(sliceSynchronization, 'AutomaticSliceMatchingOn._text', 'false').toLowerCase();
      this.sliceSynchronization.panZoomSyncronizationOn =
        !!get(sliceSynchronization, 'PanZoomSyncronizationOn._text', 'false').toLowerCase();
    }

  }
}
