import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReportGeneratorVersion;
const SERVICE = 'msreportgenerator-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class ReportGeneratorService {

  constructor(private http: HttpClient) { }

  getSummary() {
    console.log('requestURL: ', requestURL + '/report/summary');
    return this.http.get(requestURL + '/report/summary');
  }

  getReportsByStudyId(studyId: number) {
    return this.http.get(requestURL + `/report/${studyId}`);
  }

  getReportByStudyIdAndId(studyId: number, reportId: number) {
    return this.http.get(requestURL + `/report/${studyId}/${reportId}`);
  }

  signReport(studyId: number, reportId: number, jwtToken: string) {
    const customHeader = new HttpHeaders({
      'Authorization': `Bearer ${jwtToken}`
    });
    return this.http.post(
      requestURL + `/report/sign/${studyId}/${reportId}`,
      {},
      {
        headers: customHeader
      }
    );
  }
}
