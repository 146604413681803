
/**
 * @description
 * Dicom helper service interface for Dynamika image visualization component.
 *
 */

export abstract class DicomHelperService {
    /**
     * Check if support Window Level
     * @param cell
     * @param index
     */
    abstract supportWindowLevel(cell, index): boolean;
    /**
     * Get bits per pixel
     * @param dicomDataSet
     */
    abstract getBitsPerPixel(dicomDataSet): number;
    /**
     *
     * @param tag
     */
    abstract getTagText(tag): any;
    /**
     * Check if tag exists
     * @param metadata
     * @param tagNumber
     */
    abstract tagExists(metadata, tagNumber: string): boolean;
    /**
     * Check if it is a DICOM Image
     * @param metadata
     */
    abstract isDicomImage(metadata): boolean;
    /**
     * Get tag text value
     * @param metadata
     * @param tag
     */
    abstract getTagTextValue(metadata, tag): any;
    /**
     * Get DICOM image information
     * @param metadata
     * @param frameIndex
     */
    abstract getDicomImageInformation(metadata, frameIndex?: number): any;
    /**
     * Get DICOM Tag
     * @param metadata
     * @param tag
     */
    abstract getDicomTag(metadata, tag: string): any;
    /**
     * Find tag
     * @param dicom
     * @param tag
     */
    abstract findTag(dicom, tag: string): any;
    /**
     * Get DICOM tag value
     * @param metadata
     * @param tag
     * @param index
     */
    abstract getDicomTagValue(metadata, tag: string, index?: number): any;
    /**
     * Clone DICOM tag value
     * @param metadata
     * @param tag
     */
    abstract cloneDicomTagValue(metadata, tag: string): string;
    /**
     * Get study date time
     * @param metadata
     * @param ignoreStudyTime
     */
    abstract getStudyDateTime(metadata, ignoreStudyTime: boolean): IDateJS;
    /**
     * Get study date time as string
     * @param metadata
     * @param ignoreStudyTime
     * @param includeParenthesis
     */
    abstract getStudyDateTimeString(metadata, ignoreStudyTime: boolean, includeParenthesis: boolean): string;
    /**
     * Get tag value
     * @param metadata
     * @param tag
     * @param index
     */
    abstract getTagValue(metadata, tag: string, index?: number): any;
    /**
     * Get all values
     * @param metadata
     * @param tag
     */
    abstract getAllValues(metadata, tag: string): any[];
    /**
     * Get convert value
     * @param dcmTag
     */
    abstract getConvertValue(dcmTag): any;
    /**
     * Get string if available
     * @param tag
     * @param para
     */
    abstract getStringIfAvailable(tag: string, para: boolean): string;
    /**
     * Get patient name
     * @param metadata
     * @param tag
     * @param index
     */
    abstract getPatientName(metadata, tag: string, index?: number): string;
    /**
     * Get patient name from tag
     * @param dcmTag
     * @param index
     */
    abstract getPatientNameFromTag(dcmTag, index?: number): string;
    /**
     * Parse DICOM date
     * @param dateString
     */
    abstract parseDicomDate(dateString: string, settings?: object): string;
    /**
     * Parse DICOM time
     * @param timeString
     */
    abstract parseDicomTime(timeString: string): string;
    /**
     * Get Code Sequence
     * @param sequenceItem
     */
    abstract getCodeSequence(sequenceItem): any;
    /**
     * Get Code Sequence List
     * @param metadata
     * @param tagList
     * @param itemNumberList
     */
    abstract getCodeSequenceList(metadata, tagList: string, itemNumberList: string): Array<any>;
}

