import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class SiteService {

  constructor(private http: HttpClient) { }

  create(site: any) {
    return this.http.post(requestURL + `/site`, site);
  }

  update(site: any) {
    return this.http.put(requestURL + `/site`, site);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/site/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/site/` + id);
  }

  getAll(): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(requestURL + `/site/retrieve-all`);
  }

    findAllForDashboard(studyId: number) {
        return this.http.get(requestURL + `/site/find-all-for-dashboard-optimized?studyId=${studyId}`);
    }
}
