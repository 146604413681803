import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as io from 'socket.io-client';
import {environment} from 'src/environments/environment';
import {DynamikaWebSocket} from './dynamika-web-socket';

const URL = environment.url;
const API_VERSION = environment.WebSocketsServerVersion;
const SERVICE = 'websockets-server-dot-';
const webSocketServerURL = 'wss://' + API_VERSION + SERVICE + URL;
// const webSocketServerURL = 'http://localhost:8081/';
// const webSocketServerURL = 'wss://websockets-server-dot-dynamikax-dev.ew.r.appspot.com/';

@Injectable({providedIn: 'root'})
export class WebSocketService implements DynamikaWebSocket {
  initialized = false
  activeListeners = 0
  private socket;

  constructor() { }

  listen(eventName: string, roomId: string = null, componentName: string): Observable<any> {
    return new Observable(observer => {
      this.socket = io(webSocketServerURL, {
        autoConnect: false,
        // transports: ["websocket", "polling"],
        query: {roomId},
        withCredentials: true,
        forceNew: true
      });

      if (roomId != null) this.socket.query = {roomId};
      this.socket.connect();

      this.initialized = true
      this.activeListeners++
      this.socket.on(eventName, (data) => {
        console.log(`Event ${eventName} received for component ${componentName}`, data)
        observer.next(data);
      });
      return () => {
        this.activeListeners--
        if (this.activeListeners <= 0) {
          this.socket.disconnect();
          // console.log(`Socket of component ${componentName} disconnected `, this.socket);
        }
      };
    });
  }

  emit(eventName: string, data: any) {
    console.log(`Emitting event ${eventName}`, data)
    return this.socket.emit(eventName, data);
  }

  emitAndForget(eventName: string, data: any) {
    const socket = io(webSocketServerURL, {
      autoConnect: false,
      // transports: ["websocket", "polling"],
      withCredentials: true
    });

    try {
      socket.connect();
      console.log(`Emitting event ${eventName}`, data)
      socket.emit(eventName, data);
    } finally {
      socket.disconnect();
      console.log(`Temporary socket disconnected `, this.socket)
    }
  }
}
