import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';

export interface CustomLayoutData {
  numberCols: number;
  numberRows: number;
  actionManagerService: any;
  viewer: any;
  viewerComponent: any;
}

@Component({
  selector: 'app-custom-layout-dialog',
  templateUrl: './custom-layout-dialog.component.html',
  styleUrls: ['./custom-layout-dialog.component.css']
})
export class CustomLayoutDialogComponent implements OnInit {
  private maxColumns = 8;
  private maxRows = 8;
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<CustomLayoutDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CustomLayoutData) {
  }

  ngOnInit(): void {
    const viewerLayout = this.data?.viewer?.get_gridLayout();
    this.form = new FormGroup({
      rows: new FormControl(viewerLayout ? viewerLayout.get_rows() : 1, [
        Validators.required,
        Validators.max(this.maxRows)
      ]),
      columns: new FormControl(viewerLayout ? viewerLayout.get_columns() : 1, [
        Validators.required,
        Validators.max(this.maxColumns)
      ])
    });
  }

  isValid() {
    return this.data.numberRows !== 0 && this.data.numberRows <= this.maxRows
      && this.data.numberCols !== 0 && this.data.numberCols <= this.maxColumns;
  }

  cancel(): void {
    this.dialogRef.close();
  }

  onClickOk(): void 
  {
    this.data.viewer.get_gridLayout().set_rows(this.data.numberRows);
    this.data.viewer.get_gridLayout().set_columns(this.data.numberCols);
    this.data.viewerComponent.viewerSettings.layout.rows = this.data.numberRows;
    this.data.viewerComponent.viewerSettings.layout.cols = this.data.numberCols;
    this.data.viewerComponent.viewerSettings.layout.auto = false;
    this.data.viewer.set_cellsArrangement(0);
    delete (this.data.viewer.customLayout);
  }

  get rows(): FormControl {
    return <FormControl>this.form.get('rows');
  }

  get columns(): FormControl {
    return <FormControl>this.form.get('columns');
  }

  submit(): void {
    if (this.form.valid) {
      this.data.viewer.get_gridLayout().set_rows(this.rows.value);
      this.data.viewer.get_gridLayout().set_columns(this.columns.value);
      this.data.viewer.set_cellsArrangement(0);
      delete (this.data.viewer.customLayout);

      this.dialogRef.close();
    }
  }
}
