import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { OncologyCriteria } from 'src/app/_models/Oncology/criteria-model';
import {
  GlobalLesionModel,
  OncologyLesionType,
  OncologyResponseEnum,
  OncologyResponseModel,
  OncologyResponseType,
  ResponseOptions
} from 'src/app/_models/Oncology/global-lesion-model';
import { OncologyAssessmentVisitModel } from 'src/app/_models/Oncology/oncology-assessment-visit-model';
import { OncologyAssessmentService } from 'src/app/_services/oncology-assessment.service';

export interface ResponseDialogFormData {
  allLesionTypes: string[],
  studyId: number,
  readingId: number,
  readerId: number,
  endpointName: string,
  visit: OncologyAssessmentVisitModel,
  visits: OncologyAssessmentVisitModel[],
  baselineSPPD: any,
  nadirSPPD: any,
  historicalNadir: any[],
  allResponses: any[],
  allLesions: GlobalLesionModel[],
  primaryLocations: any[],
  nadirVisitConfigId: number,
  criteria: OncologyCriteria
}

@Component({
  selector: 'app-oncology-response-assessment-dialog',
  templateUrl: './oncology-response-assessment-dialog.component.html',
  styleUrls: ['./oncology-response-assessment-dialog.component.css']
})
export class OncologyResponseAssessmentDialogComponent implements OnInit {
  responseEnum = OncologyResponseEnum;
  responseType = OncologyResponseType;
  lesionType = OncologyLesionType;
  reaponseOptions = ResponseOptions;
  minimized = false;
  selectedTab = 0;
  showModalSpinnerLoadingData = false;
  commentForm: FormGroup;
  multiTP = false;
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(public dialogRef: MatDialogRef<OncologyResponseAssessmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ResponseDialogFormData,
              private oncologyAssessmentService: OncologyAssessmentService,
              private toastyService: ToastyService) {
    this.data.visits = this.data.visits.filter(visit => {
      return visit.id <= this.data.visit.id;
    });
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.commentForm = new FormGroup({
      id: new FormControl(this.data.visit.id),
      comment: new FormControl(this.data.visit.comment ? this.data.visit.comment: '')
    });
  }

  changeCommentMandatory(required: boolean) {
    if (required) {
      this.commentForm.get('comment').setValidators([Validators.required]);
    } else {
      this.commentForm.get('comment').clearValidators();
    }

    this.commentForm.get('comment').updateValueAndValidity();
  }

  minimizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.add('minimized');
    let top = ((dialog[0] as HTMLElement).offsetTop + 60) + 'px';
    document.documentElement.style.setProperty('--dialog-display', 'translate3d(0 , calc(100vh - ' + top + ') , 0)');
    document.documentElement.style.setProperty('--dialog-background', '#e99425');
    document.documentElement.style.setProperty('--dialog-color', '#fff');
    this.minimized = true;
  }

  maximizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.remove('minimized');
    document.documentElement.style.setProperty('--dialog-display', '');
    document.documentElement.style.setProperty('--dialog-background', '#fff');
    document.documentElement.style.setProperty('--dialog-color', '');
    this.minimized = false;
  }

  showResponseOptions(responseType: ResponseType) {
    return this.reaponseOptions[responseType];
  }

  getJustFollowups() {
    return this.data.visits.filter(visit => {
      return !visit.baseline;
    });
  }

  onChangeResponse(event, responseEnum: OncologyResponseEnum, visitConfigId: number) {
    const responseObject = this.data.allResponses.find(response => {
      return response.visitConfigId === visitConfigId;
    });
    this.showModalSpinnerLoadingData = true;
    const data: OncologyResponseModel = {
      id: responseObject[responseEnum].id,
      endpointName: this.data.endpointName,
      readingId: this.data.readingId,
      response: event.value,
      type: responseObject[responseEnum].type,
      userId: this.data.readerId,
      visitConfigId: visitConfigId
    };
    this.oncologyAssessmentService.updateResponsesByVisitConfigId(this.data.studyId, data).subscribe(() => {
      this.showModalSpinnerLoadingData = false;
      this.changeCommentMandatory(true);
    });
  }

  // getCurrentFollowUpName() {
  //   const index = this.data.visits.indexOf(this.data.visit);
  //   return 'Follow Up Visit ' + index;
  // }

  getLesions(lesionType: OncologyLesionType) {
    const lesions = this.data.allLesions.filter(lesion => {
      return lesion.type === lesionType;
    }).sort((a, b) => a.id - b.id);
    let flags = [];
    let output = [];
    for (let index = 0; index < lesions.length; index++) {
      if (flags[lesions[index].lesionName]) {
        continue;
      }
      flags[lesions[index].lesionName] = true;
      output.push(lesions[index]);
    }
    return output;
  }

  getLesion(lesionName: string, visitConfigId: number) {
    return this.data.allLesions.find(lesion => lesion.lesionName === lesionName && lesion.visitConfigId === visitConfigId);
  }

  subLocationName(lesion: GlobalLesionModel) {
    const primaryId = lesion?.primaryLocation;
    const subId = lesion?.subLocation;
    let location;
    let subLocation;
    location = this.data.primaryLocations.find(el => {
      return el.id == primaryId;
    }) as any;
    if (location) {
      subLocation = location.bodySubLocations.find(el => {
        return el.id == subId;
      });
    }
    if (subLocation) {
      return subLocation.name;
    }
    return;
  }

  getResponse(responseEnum: OncologyResponseEnum, visitConfigId: number) {
    const visit = this.data.visits.find(v => v.visitConfigId === visitConfigId);
    if (visit.noUpload)
      return 'NA';
    const response = this.data.allResponses.find(response => response.visitConfigId === visitConfigId);
    return response && response[responseEnum] ? response[responseEnum].response : undefined;
  }

  disableResponse(visit: any) {
    return visit.visitConfigId !== this.data.visit.visitConfigId;
  }

  getSPPD(visitConfigId) {
    const lesions = this.data.allLesions.filter(lesion => {
      return lesion.type === OncologyLesionType.TARGET_LESION && lesion.visitConfigId === visitConfigId;
    });
    let sppd = 0;
    lesions.forEach(l => {
      if (l.ppd !== 'NA' && l.ppd !== 'NE') {
        sppd += +l.ppd;
      }
    });
    return sppd;
  }

  compareSPPD(sppd: any, visitConfigId: number) {
    let sppdComparison = 0;
    if (sppd == 'NA' || !sppd) {
      return 'NA';
    }
    if (sppd == 'NE') {
      return 'NE';
    }
    sppd = +sppd;

    const currentSPPD = this.getSPPD(visitConfigId);
    if (currentSPPD >= 0) {
      sppdComparison = (currentSPPD - sppd) * 100 / sppd;
    }
    return +sppdComparison.toFixed(2);
  }

  compareNadirSPPD(sppd: any, visitConfigId: number) {
    if (this.data.nadirVisitConfigId === visitConfigId) {
      return 0;
    }
    if (!this.data.historicalNadir) {
      return;
    }
    let sppdComparison = 0;
    const nadir = this.data.historicalNadir.find(hn => hn.visitConfigId === visitConfigId);

    if (sppd == 'NA' || sppd === null) {
      return 'NA';
    }
    if (sppd == 'NE') {
      return 'NE';
    }
    sppd = +sppd.toFixed(2);

    const currentSPPD = this.getSPPD(visitConfigId);
    if (currentSPPD >= 0) {
      if (nadir) {
        sppd = nadir.nadirVisitTotalPpd;
        if (nadir.nadirVisitConfigId === visitConfigId) {
          return 0;
        }
      }
      if (sppd === 0)
        return 0;
      sppdComparison = (currentSPPD - sppd) * 100 / sppd;
    }

    return sppdComparison <= 0 ? 0 : +sppdComparison.toFixed(2);
  }

  onSaveVisitComment() {
    this.showModalSpinnerLoadingData = true;
    this.oncologyAssessmentService.updateVisitComment(this.data.studyId, this.commentForm.value).subscribe(response => {
      this.showModalSpinnerLoadingData = false;
      this.toastOptions.title = 'SUCCESS: The comment is saved';
      this.toastOptions.msg = 'Comment is successfully saved';
      this.toastyService.success(this.toastOptions);
    });
  }

  closeClick() {
    this.dialogRef.close({ submit: false, responses: this.data.allResponses });
  }

  saveClick() {
    this.dialogRef.close({ submit: true, responses: this.data.allResponses, multiTP: false });
  }

}
