import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {FlexibleConfig} from '../core/interfaces/flexible-config';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;
const LOCAL_API_URL = 'http://localhost:8080/api';

@Injectable({
  providedIn: 'root'
})
export class ReadingConfigFlexibleService {

  constructor(private http: HttpClient) {
  }

  create(readingConfig: any): Observable<BasicResponse<FlexibleConfig>> {
    const name = ['PSAMRIS', 'WBMRI', 'CANDEN&SPARCC','HEEL'].includes(readingConfig.config.endpoint.name.toUpperCase()) ?
        readingConfig.config.endpoint.name : null;
    const readType = name != null ? '/create-with-integration/' + readingConfig.config.endpoint.name : '';
    return this.http.post<BasicResponse<FlexibleConfig>>(requestURL + '/reading-config-flexible' + readType, readingConfig);
    // return this.http.post<BasicResponse<FlexibleConfig>>(LOCAL_API_URL + '/reading-config-flexible' + readType, readingConfig);
  }

  update(readingConfig: any): Observable<BasicResponse<FlexibleConfig>> {
    /*const name = ['PSAMRIS', 'WBMRI', 'CANDEN&SPARCC'].includes(readingConfig.config.endpoint.name.toUpperCase()) ?
        readingConfig.config.endpoint.name : null;
    const readType = name != null ? '/update-with-integration/' + readingConfig.config.endpoint.name : '';
    return this.http.put<BasicResponse<FlexibleConfig>>(requestURL + '/reading-config-flexible' + readType, readingConfig);*/
      return this.http.put<BasicResponse<FlexibleConfig>>(requestURL + '/reading-config-flexible', readingConfig);
      // return this.http.put<BasicResponse<FlexibleConfig>>(LOCAL_API_URL + '/reading-config-flexible', readingConfig);
  }

  delete(id: number) {
    return this.http.delete(requestURL + '/reading-config-flexible/' + id);
    // return this.http.delete(LOCAL_API_URL + '/reading-config-flexible/' + id);
  }

  getByStudyId(studyId: number): Observable<BasicResponse<FlexibleConfig[]>> {
    return this.http.get<BasicResponse<FlexibleConfig[]>>(requestURL + '/reading-config-flexible/get-by-study-id/' + studyId);
    // return this.http.get<BasicResponse<FlexibleConfig[]>>(LOCAL_API_URL + '/reading-config-flexible/get-by-study-id/' + studyId);
  }

  getActiveConfigsByStudyId(studyId: number): Observable<BasicResponse<FlexibleConfig[]>> {
    return this.http.get<BasicResponse<FlexibleConfig[]>>(requestURL + '/reading-config-flexible/get-active-by-study-id/' + studyId);
  }

  getByEndpointId(endpointId: number) {
    return this.http.get(requestURL + '/reading-config-flexible/get-by-endpoint-id/' + endpointId);
  }

  getByStudyIdAndEndpointId(studyId: number, endpointId: number) {
    return this.http.get(requestURL + '/reading-config-flexible/get-by-study-id-and-endpoint-id/' + studyId + '/' + endpointId);
  }

  getReadingVersions(studyId: number, readerId: number) {
    return this.http.get(requestURL + '/reading-config-flexible/get-reading-versions/' + studyId + '/' + readerId);
  }

  getById(readingConfigFlexibleId: number): Observable<BasicResponse<FlexibleConfig>> {
    return this.http.get<BasicResponse<FlexibleConfig>>(requestURL + '/reading-config-flexible/' + readingConfigFlexibleId);
  }

  getAll() {
    return this.http.get(requestURL + '/reading-config-flexible/retrieve-all/without-rel');
  }

  getAllForImagingProjectList(): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(requestURL + '/reading-config-flexible/retrieve-all/for-imaging-project-list');
  }

}
