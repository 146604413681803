import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { SeriesNavigatorComponent } from 'src/app/components/controls/series-navigator/series-navigator.component';
import { forkJoin, interval, Observable, Subscription } from 'rxjs';
import {
  ImagingProjectService,
  LeadToolsActionManagerService,
  ReadingConfigFlexibleService,
  StudySequenceLabelService,
  StudyService,
  EventService, ViewerAuthenticationService, StudyUserService
} from 'src/app/_services';
import { ReadingMranoService } from 'src/app/_services/reading-mrano.service';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from 'src/app/core/data-management/actions/projects.action';
import { ToastOptions, ToastyService } from 'ng2-toasty';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import { BaseFlexibleConfig } from 'src/app/_models/ImagingProject/base-flexible-config';
import { MRanoLesionObject, MRanoScoringType } from 'src/app/_models/MRANO/mrano-scoring-model';
import { MranoHistoricalFormComponent } from '../mrano-historical-form/mrano-historical-form.component';
import { MranoEligibilityFormComponent } from '../mrano-eligibility-form/mrano-eligibility-form.component';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
import * as _ from 'lodash';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EventKey, FormEvent } from 'src/app/_models/Oncology/local-storage-keys.model';
import { AddLesionRequest, MarkerType, OncologyAddLesionData } from 'src/app/_models/Oncology/global-lesion-model';
import { OncologyAssessmentService } from 'src/app/_services/oncology-assessment.service';
import { FormPage, MessagingService } from 'src/app/_services/messaging.service';
import { VisitStatus } from 'src/app/_models/ImagingProject/IF/incidental-findings-config-model';
import { ResponseCode } from 'src/app/core/constants/response-code';
import { MatDialog } from '@angular/material/dialog';
import { ReadingFormBaseComponent } from '../../reading-form-base.component';
import { takeUntil } from 'rxjs/operators';
import { ReadingNavigatorComponent } from '../../reading-navigator/reading-navigator.component';
import { PatientService } from './../../../../_services/patient.service';
import { PatientModel } from 'src/app/_models/ImagingProject/patient-model';
import { BasicResponse } from 'src/app/core/interfaces/basic-response';
import { ReadingVersion } from '../../../../core/constants/reading-version';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MranoAdjudicationReadingModel} from '../../../../_models/MRANO/mrano-adjudication-reading-model';
import {GBMService} from "../../../../_services/gbm.service";

interface ViewsLayoutConfiguration {
  col: number;
  row: number;
  sizex: number;
  sizey: number;
}

const AI_LABELS = ['DSC', 'DCE', 'DWI', 'ADC', 'rCBV', 'Ktrans', 'Ve', 'Vp', 'DSC reg',
  'DCE reg', 'DWI reg', 'ADC reg', 'rCBV reg', 'Ktrans reg', 'Ve reg', 'Vp reg', 'T1', 'T2', 'T1ce', 'Flair'];

@Component({
  selector: 'app-mrano',
  templateUrl: './mrano-adjudication-form.component.html',
  styleUrls: ['./mrano-adjudication-form.component.css'],
  providers: [MessagingService]
})
export class MranoAdjudicationFormComponent extends ReadingFormBaseComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  @ViewChildren('viewerLT', { read: ViewContainerRef }) viewerLTs: QueryList<ViewContainerRef>;
  viewerLT: ViewContainerRef;
  @ViewChild('viewerContainer', { read: ElementRef }) viewerContainer: ElementRef;
  @ViewChild('seriesNavigator') seriesNavigator: SeriesNavigatorComponent;
  @ViewChild('historicalComponent') historicalComponent: MranoHistoricalFormComponent;
  @ViewChild('eligibilityComponent') eligibilityComponent: MranoEligibilityFormComponent;
  selectedMainTab = 0;
  adjudicationForm: FormGroup;

  openedNumberOfImages = 0;
  viewsLayoutConfigurations: ViewsLayoutConfiguration[] = [];
  readingList: Array<MranoAdjudicationReadingModel> = [];
  currentReading: MranoAdjudicationReadingModel;
  userId: number;
  study: any;
  spentSecondsSubsctiption: Subscription;
  ltViewersSubsctiption: Subscription;
  ltViewerSubsctiption: Subscription;
  spentSeconds = 0;
  showModalSpinnerLoadingData = true;
  visitedSeriesCounter = 0;
  visitConfig: any;
  currentVisit: any;
  levelOneReadings: any = [];
  readingSeries = [];
  allseries = [];
  readingSeriesDetails = [];
  isExpanded = true;
  currentScoringFormSelector = MRanoScoringType.HISTORICAL;
  readingLevel: string;
  smartRead = false;
  sequentialLock = false;
  eCRFOnly = false;
  detailsGot = false;
  validationMessages: string[];
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  availableSequenceLabels: SequenceLabelModel[] = [];
  defaultSequenceLabelIds: number[] = [];
  screenShotData: any;
  editObjectData: any;
  readingConfigResponse: any;
  seriesStatusSubscription: Subscription;
  seriesStatus = [];
  deletedAnnotationSubscription: Subscription;
  modalSpinnerSubscription: Subscription;
  modalSpinnerAssessmentSubscription: Subscription;
  openLTViewerSubscription: Subscription;
  editMeasurementSubscription: Subscription;
  registeredImages = false;
  openedSeriesVisitConfigIds = [];
  viewerInitialized = false;

  viewerData = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;
  // parameters for viewer less mode
  viewerLess: boolean;
  readingId: number;
  addLesionRequestData: AddLesionRequest;
  eventSubscription: Subscription;
  lastRequest: FormEvent;
  baselineNadirSPPDRequestSubscription: Subscription;
  selectedVisit = 0;
  totalVolumeCalculation = false;
  enableWidespreadOrFocal = false;
  patientSubscription: Subscription;
  patientCode: string = null;

  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    private studySequenceLabelService: StudySequenceLabelService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private readingMranoService: ReadingMranoService,
    private toastyService: ToastyService,
    private store: Store,
    private readingConfigService: ReadingConfigFlexibleService,
    private imagingProjectService: ImagingProjectService,
    public utils: Utils,
    public route: ActivatedRoute,
    public cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private leadtoolsActionManagerService: LeadToolsActionManagerService,
    private oncologyAssessmentService: OncologyAssessmentService,
    private messagingService: MessagingService,
    private studyService: StudyService,
    private dialog: MatDialog,
    public eventService: EventService,
    private patientService: PatientService,
    // don't remove next 3 services they are pass and used inside initViewerData in leadtool Utils
    private authenticationService: ViewerAuthenticationService,
    private gbmService: GBMService,
    private studyUserService: StudyUserService,
  ) {
    super(route, cdr, componentFactoryResolver, eventService, utils);
  }

  ngOnInit(): void {
    this.viewerLess = this.activatedRoute.snapshot.queryParams['viewerLess'] === 'true';
    this.readingId = this.activatedRoute.snapshot.queryParams['rid'];
    this.utils.isAdvancedAnalysis = true;
    const sideNav = document.getElementsByTagName('mat-sidenav');
    (sideNav[0] as HTMLElement).style.display = 'inherit';
    if (this.viewerLess) {
      (sideNav[0] as HTMLElement).style.display = 'none';
    }

    // modal spinner
    this.modalSpinnerSubscription = this.readingMranoService.modalSpinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
      this.ModalSpinnerChangeState(state);
    });
    this.modalSpinnerAssessmentSubscription = this.oncologyAssessmentService.modalSpinner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(state => {
      this.ModalSpinnerChangeState(state);
    });

    this.userId = +JSON.parse(localStorage.getItem('userId'));
    this.study = JSON.parse(localStorage.getItem('project'));
    this.configureLayouts();
    const serviceSources = [
      this.readingMranoService.getAvailableAdjudicationReadings(this.study.id, this.userId),
      this.readingConfigFlexibleService.getByStudyId(this.study['id'])
    ];
    forkJoin(serviceSources).subscribe(
      values => {
        const [readingResponse, readingConfigResponse] = values as any;
        if (readingResponse['responseCode'] === 200 && readingConfigResponse['responseCode'] === 200) {
          this.readingConfigResponse = readingConfigResponse;
          this.refreshReadingsList(readingResponse);
        } else if (readingResponse['responseCode'] !== 200) {
          this.showErrorMessage(readingResponse);
        } else if (readingConfigResponse['responseCode'] !== 200) {
          this.showErrorMessage(readingConfigResponse);
        }
      },
      (error) => this.showErrorMessage(error.error)
    );
    this.spentSecondsSubsctiption = interval(1000)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
      this.spentSeconds++;
    });
    // get all series status
    this.seriesStatusSubscription = this.readingMranoService.seriesLockedStatus
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {
      if (response) {
        this.seriesStatus.push(...response);
      }
    });

    // get all annotation deleted when lesion deleted
    this.deletedAnnotationSubscription = this.readingMranoService.deletedAnnObjects
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(response => {});

    // open lt viewer from children
    this.openLTViewerSubscription = this.readingMranoService.openLTViewerData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          this.onEditMeasurement(data);
          const message: FormEvent = { key: EventKey.OPEN_VIEWER, value: data };
          this.messagingService.sendRequest(message, FormPage.MRANO);
        }
    });

    this.editObjectData = null;
    // edit lesion measurement
    this.editMeasurementSubscription = this.readingMranoService.editLesionMeasurement
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        if (data) {
          this.onEditMeasurement(data);
          const message: FormEvent = { key: EventKey.ELIGIBILITY_ADD_LESION, value: data };
          this.messagingService.sendRequest(message, FormPage.MRANO);
        }
    });

    // request to calculate baseline and nadir sppd
    this.baselineNadirSPPDRequestSubscription = this.oncologyAssessmentService.baselineNadirRequest
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
      if (data) {
        this.getBaseLineAndHistoricalNadir();
      }
    });

    this.updateEventSubscriptionForCurrentReading();

    // get criteria setting for assessment
    this.oncologyAssessmentService.getUploadedCriteriaJason();
  }
  openReadingsIdListDialog() {
    const dialogRef = this.dialog.open(ReadingNavigatorComponent, {
      width: '650px',
      height: '556px',
      data: this.readingList,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((readingId: any) => {
      if (readingId && readingId !== this.currentReading.id) {
        this.skipCurrentReadingAndUpdate(readingId);
      }
    });
  }
  // handeling local storage events
  handleEvents(event: FormEvent) {
    if (!event.value) {
      return;
    }
    // viewerLess
    if (this.viewerLess) {
      switch (event.key) {
        case EventKey.EXIT_FORM:
          this.router.navigate(['/']);
          break;
        case EventKey.ELIGIBILITY_LESION_DATA:
          if (this.currentReading?.historicalScan?.lock && !this.currentReading?.eligibilityScan?.lock) {
            // tslint:disable-next-line:max-line-length
            this.eligibilityComponent.addObjectFromLeadtools(event.value, this.editObjectData?.editMode, this.editObjectData?.editType, this.editObjectData?.currentLesionData, this.editObjectData.type);
          } else if (!this.currentReading?.historicalScan?.lock) {
            // tslint:disable-next-line:max-line-length
            this.historicalComponent.addObjectFromLeadtools(event.value, this.editObjectData?.editMode, this.editObjectData?.editType, this.editObjectData?.currentLesionData, this.editObjectData.type);
          }
          break;
        default:
          break;
      }
    }
    if (!this.viewerLess) {
      this.lastRequest = event;
      switch (event.key) {
        case EventKey.SCREENSHOT_REQUEST:
          this.makeSnapshotFromViewerOverWebsocket(event);
          break;

        case EventKey.GO_TO_LESION:
          this.goToLesion(event.value?.currentLesionData, true, false);
          break;

        case EventKey.SKIP_FORM:
          this.skipCurrentReading(event.value.index, event.value.removeCurrent);
          break;

        case EventKey.SUBMIT_FORM:
          this.checkReadings(event.value.index);
          break;

        case EventKey.END_READING:
          this.readingList.splice(0, 1);
          this.currentReading = undefined;
          break;

        case EventKey.OPEN_VIEWER:
          this.openLTViewerFromChildren(event.value);
          break;

        default:
          break;
      }
    }
  }

  // make snapshot from viewer after request
  makeSnapshotFromViewerOverWebsocket(event: FormEvent) {
    try {
      const snapshot = this.leadtoolsActionManagerService.popupCapture();
      const data = {
        cellInfo: {
          seriesId: snapshot.cellInfo.seriesId,
          seriesUID: snapshot.cellInfo.seriesUID,
          modality: snapshot.cellInfo.modality,
          label: snapshot.cellInfo.label
        },
        sliceNum: snapshot.sliceNum,
        image: snapshot.image
      };
      const message: FormEvent = { key: EventKey.SCREENSHOT_DATA, value: data, destination: event.publisherId };
      this.messagingService.sendRequest(message);
    } catch {
      this.toastOptions.title = 'ERROR: Image not found';
      this.toastOptions.msg = 'The image must be open before capture snapshot';
      this.toastyService.error(this.toastOptions);
      this.addLesionRequestData = null;
    }
  }

  // open scoring form in new window
  open(): void {
    this.updateEventSubscriptionForCurrentReading();
    const a = document.createElement('a');
    const objectUrl = window.location.href.replace(/\?.*$/, '') + `?viewerLess=true&rid=${this.currentReading.id}`;
    a.href = objectUrl;
    a.target = '_blank';
    a.click();
    URL.revokeObjectURL(objectUrl);
  }

  updateEventSubscriptionForCurrentReading() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }

    if (this.currentReading) { this.readingId = this.currentReading.id; }
    // else this.readingId = null;
    this.updateRouteQueryParams();
    this.userId = +JSON.parse(localStorage.getItem('userId'));
    if (this.readingId) {
      const postfix = ''; // this.eligibility ? '' : (this.viewerLess ? 'viewerLess' : '');
      this.eventSubscription = this.messagingService.init(this.userId, this.readingId, FormPage.MRANO, postfix)
        .subscribe(this.handleEvents.bind(this));
    }
  }

  updateRouteQueryParams() {
    const queryParams: Params = {};
    queryParams['rid'] = this.readingId;
    queryParams['viewerLess'] = this.viewerLess ? this.viewerLess : false;

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        // queryParamsHandling: 'merge', // remove to replace all query params by provided,
        // replaceUrl: true
      });
  }

  // go to lesion from localstorage event
  goToLesion(lesion?: any, toast?: boolean, edit?: boolean) {
    if (lesion) {
      let seriesIdAndSliceNumber;

      seriesIdAndSliceNumber = {
        seriesId: lesion.snapshot ? lesion.snapshot.seriesId : lesion.seriesId,
        sliceNumber: lesion.snapshot ? lesion.snapshot.sliceNumber : lesion.sliceNumber
      };

      this.openLTViewerFromChildren(seriesIdAndSliceNumber);

      if (!edit) {
        this.readingMranoService.onEditLesionMeasurement(null);
      }
      if (toast) {
        this.toastOptions.title = 'ID 60: ' + lesion.lesionName + ' is loaded successfully and available in the viewer';
        this.toastOptions.msg = 'Reader can see the associated ROI in the new slot in the image viewer';
        this.toastOptions.timeout = 15000;
        this.toastyService.success(this.toastOptions);
      }
    } else {
      this.readingMranoService.onEditLesionMeasurement(null);
      if (toast) {
        this.toastOptions.title = 'ID 61: ' + lesion.lesionName + ' loading failure';
        this.toastOptions.msg = 'Lesion is not loaded due to some reasons. Try again or contact support team';
        this.toastyService.error(this.toastOptions);
      }
    }
  }

  showReadingForm(): void {
    this.isExpanded = true;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  hideReadingForm(): void {
    this.isExpanded = false;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  ngOnDestroy(): void {
    this.destroy();

    if (this.spentSecondsSubsctiption) {
      this.spentSecondsSubsctiption.unsubscribe();
    }

    if (this.ltViewerSubsctiption) {
      this.ltViewerSubsctiption.unsubscribe();
    }

    if (this.ltViewersSubsctiption) {
      this.ltViewersSubsctiption.unsubscribe();
    }

    if (this.currentReading) {
      this.readingMranoService.updateReading(
        this.study.id, this.currentReading.id, this.spentSeconds
      ).subscribe(response => {
      });
    }

    if (this.seriesStatusSubscription) {
      this.seriesStatusSubscription.unsubscribe();
    }

    if (this.deletedAnnotationSubscription) {
      this.deletedAnnotationSubscription.unsubscribe();
    }

    if (this.modalSpinnerSubscription) {
      this.modalSpinnerSubscription.unsubscribe();
    }

    if (this.openLTViewerSubscription) {
      this.openLTViewerSubscription.unsubscribe();
    }

    this.readingMranoService.onEditLesionMeasurement(null);
    this.editObjectData = null;
    if (this.editMeasurementSubscription) {
      this.editMeasurementSubscription.unsubscribe();
    }

    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }

    if (this.baselineNadirSPPDRequestSubscription) {
      this.baselineNadirSPPDRequestSubscription.unsubscribe();
    }

    if (this.patientSubscription) {
      this.patientSubscription.unsubscribe();
    }

    const message: FormEvent = { key: EventKey.EXIT_FORM, value: { exit: true } };
    this.messagingService.sendRequest(message, FormPage.MRANO);

    this.utils.readingId = null;
    this.utils.isAdvancedAnalysis = false;

    if (this.newLTViewer) {
      this.newLTViewer.instance.statDatas = null;
    }
  }

  ngAfterViewInit(): void {
    this.ltViewersSubsctiption = this.viewerLTs.changes
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((comps: QueryList<ViewContainerRef>) => {
      if (!this.viewerLT && comps.first) {
        this.viewerLT = comps.first;
        // this.preOpenLTViewer();
      }
    });
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges(); // fix ExpressionChangedAfterItHasBeenCheckedError
  }

  configureFromConfigFlexible(response) {
    let existed = false;
    this.enableWidespreadOrFocal = false;
    const data = response['data'];
    if (data) {
      for (let i1 = 0; i1 < data.length; i1++) {
        const i = data[i1];
        if (i.config['endpoint'].name.toLowerCase() === 'rano' && this.currentReading.configId === i['id']) {
          if (i.config.readingVersion === 'ReadingVersion-EfficacyMRANO') {
            this.oncologyAssessmentService.locationRequest(i.config.locations);
          }
          if (i.config['imageViewerConfigurationType'] === 'eCRF only (RANO)') {
            this.eCRFOnly = true;
          }
          this.readingLevel = i.config.readingLevel;
          if (i.config.readingLevelConfig.includes('SMART')) {
            this.smartRead = true;
          }
          if (i.config.readingLevelConfig.includes('SEQUENTIAL')) {
            this.sequentialLock = true;
          }
          if (i.config.totalVolumeCuttOffEnable) {
            this.totalVolumeCalculation = true;
          }
          if (i.config.enableWidespreadOrFocalLeptomeningeal) {
            this.enableWidespreadOrFocal = true;
          }
          existed = true;
          break;
        }
      }
    }
    if (!existed) {
      this.toastOptions.title = 'ERROR Reading configuration not existed';
      this.toastOptions.msg = 'There is no active reading configuration for this reading';
      this.toastyService.error(this.toastOptions);
    }
  }

  refreshReadingsList(response) {
    let index = 0;
    this.readingList = [];
    const read = response['data'];
    if (read != null) {
      this.readingList = read;
      if (this.readingList.length) {
        if (this.readingId) {
         index = this.readingList.findIndex(r => +r.id === +this.readingId);
        }
        this.initReading(this.readingList[index]);
      } else {
        this.detailsGot = true;
        this.readingMranoService.modalSpinnerChangeState(false);
      }
    }
  }

  initReading(reading: MranoAdjudicationReadingModel) {
    if (this.newLTViewer) {
      this.newLTViewer.instance.removeMarkerName();
    }
    this.openedSeriesVisitConfigIds = [];
    this.seriesStatus = [];
    this.detailsGot = false;
    this.eCRFOnly = false;
    this.showModalSpinnerLoadingData = true;
    this.currentReading = reading;
    this.currentScoringFormSelector = MRanoScoringType.HISTORICAL;
    this.currentReading.visits = reading.visits.sort((a, b) => a.order > b.order ? 1 : -1);
    this.oncologyAssessmentService.clearAllLesions();
    this.utils.readingId = this.currentReading.id;

    this.patientSubscription = this.patientService.getById(reading.patientId).subscribe((response: BasicResponse<PatientModel>) => {
      this.patientCode = response.data.patientCode;
    });

    this.visitedSeriesCounter = 0;

    this.currentReading.readingLevel = this.readingLevel;

    this.spentSeconds = this.currentReading.timeSpent || 0;
    if (this.currentReading.status.code < 700) {       // in_progress
      this.readingMranoService.startReading(this.currentReading.studyId, this.currentReading.id).subscribe(response => {
        if (response['responseCode'] === 200) {
          this.currentReading.status = response['data'].status;
        } else {
          this.showErrorMessage(response);
        }
      });
    }

    this.initVisits();
    this.visitConfig = this.levelOneReadings[0].visits;
    this.resetScoringForms();

    this.configureFromConfigFlexible(this.readingConfigResponse);
    if (this.viewerLess) {
      setTimeout(() => { this.detailsGot = true; }, 0);
      this.showModalSpinnerLoadingData = false;
    }
    if (!this.viewerLess) {
      this.showModalSpinnerLoadingData = false;
      this.initRegisteredImages();
    }

    this.updateEventSubscriptionForCurrentReading();

    this.adjudicationForm = new FormGroup({
      adjudicatorAgreesWithReader: new FormControl('', [Validators.required]),
      adjudicatorComment: new FormControl('')
    });
  }

  getNextWorkVisit() {
    return this.currentReading.visits.find(v => v.status === VisitStatus.NEW_EDITABLE || v.status === VisitStatus.IN_PROGRESS);
  }

  initVisits() {
    this.currentReading.visits = this.currentReading.visits.sort((a, b) => a.order > b.order ? 1 : -1);

    this.currentVisit = this.getNextWorkVisit();

    if (this.currentVisit && this.currentReading.levelOneReadings) {
      this.levelOneReadings = this.currentReading.levelOneReadings.map(reading => {
        const filteredVisits = reading.visits.filter(v => v.order <= this.currentVisit.order);

        return {
        ...reading,
          visits: filteredVisits,
          selectedTab: 0,
        };
      });

      if (this.currentVisit.status !== VisitStatus.IN_PROGRESS) {
        this.readingMranoService.updateAdjudicationTimepointStatus(
          this.currentReading.studyId,
          this.currentReading.id,
          this.currentVisit.visitConfigId,
          VisitStatus.IN_PROGRESS
        ).subscribe(response => {
          this.currentVisit.status = VisitStatus.IN_PROGRESS;
        });
      }
    }
  }

  initRegisteredImages() {
    try {
      this.readingSeries = [];
      this.studyService.getByIdWithoutRelations(this.currentReading.studyId).subscribe((resp: any) => {
        (this.currentReading as any).bucketName = resp.data.bucketLocation;
        this.loadRanoAssessmentData();
      });
    } catch (error) {
      console.log('==> Error... ', error);
    }
  }


  loadRanoAssessmentData() {
    const services = [Promise.resolve({})];
    forkJoin(services).subscribe(values => {
      const serviceResponses = values as any[];
      const viewerDataResults: Array<Observable<any>> = [];

      if (serviceResponses.filter(r => r.state !== 'DONE').length === 0) {
        this.registeredImages = true;
        this.readingSeriesDetails = [];
        (this.levelOneReadings?.[0].visits ?? []).forEach(v => {
          this.imagingProjectService.getVisitConfig(v.visitConfigId.toString()).subscribe(
            resul => {
              if (resul['responseCode'] === 200) {
                let seriesDetails = resul['data'].series;
                // const visitName = resul['data'].visitBlindName ? resul['data'].visitBlindName : resul['data'].visitName;
                const visitName = resul['data'].visitName;
                seriesDetails.forEach(s => {
                  this.readingSeries.push(s);
                });
                seriesDetails = seriesDetails.filter(rs => rs.label === 'T1' ||
                  rs.seriesDescription === 'FLAIR Reg' ||
                  rs.seriesDescription === 'T2 Reg' ||
                  rs.seriesDescription === 'T1post Reg' ||
                  AI_LABELS.includes(rs.seriesDescription) ||
                  AI_LABELS.includes(rs.label));
                seriesDetails.forEach(sd => {
                  sd['seriesVisitName'] = visitName;
                  switch (sd.seriesDescription) {
                    case 'FLAIR Reg':
                      sd['label'] = 'Flair';
                      break;
                    case 'T2 Reg':
                      sd['label'] = 'T2';
                      break;
                    case 'T1post Reg':
                      sd['label'] = 'T1ce';
                      break;
                  }
                  sd['modality'] = 'MR';
                  sd['projectModality'] = 'MRI';
                  sd['visitConfigId'] = v.visitConfigId;
                  sd['order'] = v.order;
                  this.readingSeriesDetails.push(sd);
                  this.readingSeriesDetails = this.readingSeriesDetails.sort((a, b) => a.order > b.order ? 1 : -1);
                  const series = sd;
                  if (series != null) {
                    series.seriesId = sd.id;
                    series.seriesInstanceUID = sd.seriesUID;
                    series.timepoint = visitName;
                    this.allseries.push(series);
                    const info = { 'selectedPage': 'Reading' };
                    this.viewerDataIsLoading = true;
                    viewerDataResults.push(this.utils.initViewerData(series, this, info));
                  }
                  this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
                    this.detailsGot = true;
                    this.initDefaultSequenceLabels(configResp.data);
                  });
                });
                if (viewerDataResults.length) {
                  forkJoin(viewerDataResults).subscribe(() => {
                    this.viewerData['shouldHidePatient'] = true;
                    this.viewerDataIsReady = true;
                    this.viewerDataIsLoading = false;

                    if (!this.viewerInitialized) {
                      this.openLTViewer(['-1']);
                      this._updateViewerToolbar();
                      this.viewerInitialized = true;
                    }

                    if (this.viewerData.studyUserRoles && this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
                      this.viewerData.toolbarExcludeItems = ['ShowDicom'];
                    }

                    this.cdr.detectChanges();
                  });
                }
              }
            }
          );
        });
      } else {
        this.registeredImages = false;
        this.visitConfig.forEach(visits => {
          this.imagingProjectService.getVisitConfig(visits.visitConfigId).subscribe(imagProjResp => {
            const visitConfig = imagProjResp['data'];
            if (visitConfig != null) {
              visitConfig.series.forEach(visitSeries => {
                const series = visitSeries;
                if (series != null) {
                  this.allseries.push(series);
                  series.seriesId = visitSeries.id;
                  series.seriesInstanceUID = visitSeries.seriesUID;
                  // series.timepoint = visitConfig.visitBlindName ? visitConfig.visitBlindName : visitConfig.visitName;
                  series.timepoint = visitConfig.visitName;

                  const info = { 'selectedPage': 'Reading' };
                  this.viewerDataIsLoading = true;
                  viewerDataResults.push(this.utils.initViewerData(series, this, info));
                }
              });
            }
            if (viewerDataResults.length) {
              forkJoin(viewerDataResults).subscribe(() => {
                this.viewerData['shouldHidePatient'] = true;
                this.viewerDataIsReady = true;
                this.viewerDataIsLoading = false;
                this.openLTViewer(['-1']);
                this._updateViewerToolbar();
              });
            } else {
              this.viewerDataIsReady = true;
              this.openLTViewer(['-1']);
              this._updateViewerToolbar();
            }
          });
        });
        if (this.levelOneReadings && this.levelOneReadings.length > 0 && this.levelOneReadings[0].visits) {
          this.readingSeries = [];
          this.readingSeriesDetails = [];
          const seriesIds = [];
          this.levelOneReadings[0].visits.forEach(v => {
            v.series.forEach(s => {
              this.readingSeries.push(s);
              seriesIds.push(s.seriesId);
            });
          });
          this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
            this.detailsGot = true;
            this.initDefaultSequenceLabels(configResp.data);
          });
          this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).subscribe(response => {
            if (response['responseCode'] === 200) {
              if (response['data']) {
                this.readingSeriesDetails = (response['data'] as any[]); // .sort(this.GetSortOrder('visitConfig'));
                this.readingSeriesDetails.forEach(sd => {
                  const visit = this.getVisitInfo(sd.id, false);
                  if (visit != null) {
                    sd['seriesVisitName'] = visit.timepoint;
                    sd['visitConfigId'] = visit.visitConfigId;
                    sd['order'] = visit.order;
                  }
                });
                this.readingSeriesDetails = this.readingSeriesDetails.sort((a, b) => a.order > b.order ? 1 : -1);
              }
            } else {
              this.showErrorMessage(response);
            }
          });
        }
      }
    }, (error) => {
      console.error(error);
    });
  }

  initDefaultSequenceLabels(readingConfig: FlexibleConfig<BaseFlexibleConfig>): void {
    const configSequenceLabels: SequenceLabelModel[] = [];
    readingConfig.config.modalities.forEach(m => {
      m.sequenceLabels.forEach(s => configSequenceLabels.push(s));
    });
    this.availableSequenceLabels = configSequenceLabels;
    if (!!readingConfig.config.anatomySelector) {
      this.defaultSequenceLabelIds = (readingConfig.config.anatomySelector as { id: number }[])
        .filter(label => this.availableSequenceLabels.find(studyLabel => studyLabel.id === label.id)).map(l => l.id);
    } else {
      this.defaultSequenceLabelIds = this.availableSequenceLabels.map(l => l.id);
    }
  }

  getVisitInfo(seriesId, registered: boolean) {
    if (registered) {
      return this.readingSeriesDetails.find(s => s.id === seriesId);
    }
    let series;
    this.currentReading.visits.forEach(v => {
      const serie = v.series.find(s => s.seriesId === seriesId);
      if (serie) {
        series = v;
      }
    });

    if (!series) {
      this.levelOneReadings.forEach(r => {
        r.visits.forEach(v => {
            const serie = v.series.find(s => s.seriesId === seriesId);
            if (serie) {
              series = v;
            }
          }
        );
      });
    }

    return series;
  }


  configureLayouts() {
    /* layout configuration for multiple viewers */
    this.viewsLayoutConfigurations.push({ 'col': 0, 'row': 0, 'sizex': 12, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 0, 'sizex': 6, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
  }

  /* --------------------------- navigator methods --------------------------- */

  onVisitedSeriesCounter(event) {
    this.visitedSeriesCounter = event;
  }

  onCloseAllViewers() {
    this.newLTViewer.instance.removeAllCells();
  }

  onOpenViewer(event) {
    if (event != null) {
      const seriesArr = [];
      event.forEach((seriesId) => {
        if (this.openedSeriesVisitConfigIds.filter(s => s.sereisId === seriesId).length === 0) {
          this.openedSeriesVisitConfigIds.push({
            seriesId: seriesId,
            visitConfigId: this.getVisitInfo(seriesId, this.registeredImages).visitConfigId
          });
        }
        if (this.registeredImages) {
          const series = this.readingSeriesDetails.find(s => s.id === seriesId);
          seriesArr.push(series.id);
        } else {
          const series = this.readingSeries.find(s => s.seriesId === seriesId);
          seriesArr.push(series.seriesId);
        }
      });
      localStorage.setItem('visitConfigIds', JSON.stringify(this.openedSeriesVisitConfigIds));
      this.openLTViewer(seriesArr);
    }
  }

  /* --------------------------- viewer methods --------------------------- */

  openNewLTViewer(seriesInstanceUIDs) {
    this.createViewerCmpInstance(this.viewerLT);
    this.newLTViewer.instance.seriesIds = seriesInstanceUIDs;
    this.newLTViewer.instance.shouldShowDICOM = true; // show scan date
    this.newLTViewer.instance.useHangingProtocol = false;
    this.newLTViewer.instance.shouldApplyProtocol = false;

    this.newLTViewer.instance.onClose
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(({ serID, shouldCloseViewer }) => {
      this.closeLTViewerSeries(serID, shouldCloseViewer);
    });

    this.newLTViewer.instance.onMakeSnapShotListner
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
      const series = this.allseries.find(s => s.seriesUID === data.seriesId);
      this.screenShotData = {
        seriesId: series.seriesId,
        image: data.image,
        preview: data.preview,
        seriesUID: data.seriesId,
        sliceNumber: data.sliceNum,
        modalityName: series.projectModality,
        sequenceLabelName: series.label,
      };

      if (this.screenShotData) {
        this.toastOptions.title = 'ID 46: The screenshot of the lesion is acquired successfully';
        this.toastOptions.msg = 'The screenshot includes measurement info';
        this.toastyService.success(this.toastOptions);
      } else {
        this.toastOptions.title = 'ID 47: The screenshot failure';
        this.toastOptions.msg = 'Screenshot is not made due to some reason. Try again or contact support team';
        this.toastyService.error(this.toastOptions);
      }
    });
    this.newLTViewer.instance.onConfirmLesion
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (objectData) => {
          this.onViewerDataToAddLesion(objectData);
      }
    );
    this.newLTViewer.instance.onConfirmMarker
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(
        (objectData) => {
          if (objectData) {
            this.onViewerDataToAddLesion(objectData);
          }
      }
    );

    this.newLTViewer.instance.onDemriqStatData
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((data) => {
        if (data !== null) {
          const visitId = this.currentReading.visits.find(visit => visit.series.find(s => s.seriesId === data.vois[0].seriesId)).id;
          this.readingMranoService.storeStatisticDataByVisit(data, visitId).subscribe(response => {
            if (response) {
              this.toastOptions.title = 'SUCCESS Statistic data saved!';
              this.toastOptions.msg = 'Adavanced analysis statistic data successfully stored';
              this.toastyService.success(this.toastOptions);
              this.newLTViewer.instance.statDatas.find(visit => visit.id === visitId).scoring = data;
            }
          });
        } else {
          this.showModalSpinnerLoadingData = false;
          this.toastOptions.title = 'Something went wrong.';
          this.toastOptions.msg = 'Please try again later.';
          this.toastyService.error(this.toastOptions);
        }
      });
  }

  onViewerDataToAddLesion(objectData: any) {
    const neededObject = this.removeUselessfields(objectData);

    this.sendViewerDataByLocalStorage(neededObject);

    this.editObjectData = null;
  }

  removeUselessfields(objectData: any) {
    return {
      axialLongDiameter: objectData.axialLongDiameter,
      diam: objectData.diam,
      objectLocationX: objectData.objectLocationX,
      objectLocationY: objectData.objectLocationY,
      frameNumber: objectData.frameNumber,
      metadatas: objectData.metadatas,
      objectType: objectData.objectType,
      seriesId: objectData.seriesId,
      shortAxis: objectData.shortAxis,
      sliceNumber: objectData.sliceNumber,
      sopInstanceUid: objectData.sopInstanceUid,
      voi: { name: objectData?.voi?.name, volume: objectData?.voi?.volume }
    };
  }

  sendViewerDataByLocalStorage(objectData?: any) {
    if (!this.addLesionRequestData) {
      this.toastOptions.title = 'ERROR: Lesion type is undefined';
      this.toastOptions.msg = 'Lesion type must be chosen in scoring form';
      this.toastyService.error(this.toastOptions);
      return;
    }
    const sentData: OncologyAddLesionData = {
      viewerData: objectData,
      edit: this.editObjectData?.editMode,
      editType: this.editObjectData?.editType,
      data: this.editObjectData?.currentLesionData,
      requestedData: this.addLesionRequestData
    };
    const message: FormEvent = {
      key: EventKey.ADD_LESION,
      value: sentData,
      destination: this.lastRequest.publisherId
    };
    this.messagingService.sendRequest(message);
    // this.makeSnapshotFromViewerOverWebsocket(this.lastRequest)
    this.addLesionRequestData = null;
    this.editObjectData = null;
  }

  onEditMeasurement(data) {
    // this.isExpanded = false;
    this.editObjectData = data;
  }

  onCancelEditMode() {
    try {
      if (this.newLTViewer.instance.actionManagerService['isAddingMarker']) {
        this.newLTViewer.instance.actionManagerService['isAddingMarker'] = false;
      }
    } catch (error) {

    }
    this.readingMranoService.onEditLesionMeasurement(null);
    this.editObjectData = null;
    this.addLesionRequestData = null;
  }

  openLTViewer(seriesInstanceUIDs: string[] = [], sliceNumber?: number, locked?: boolean) {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1500);

    this.viewerContainer.nativeElement.style.display = '';

    if (this.newLTViewer && this.viewerDataIsReady && this.viewerData) {
      this.viewerData['sliceNumber'] = sliceNumber;
      this.viewerData['readingID'] = this.currentReading.id;
      this.viewerData['isMRano'] = true;
      this.viewerData['locked'] = locked;
      this.newLTViewer.instance.statDatas = this.visitConfig;
      this.newLTViewer.instance.openNewSer(seriesInstanceUIDs, this.viewerData);
      this.openedNumberOfImages = this.newLTViewer.instance.getNumberOfImages();
      return;
    } else {
      this.openNewLTViewer(seriesInstanceUIDs);
    }

    this._updateViewerToolbar();

    const toolbarExcludeItems = _.get(this.viewerData, 'toolbarExcludeItems', []);
    if (toolbarExcludeItems.length > 0) {
      _.each(toolbarExcludeItems, i => {
        this.newLTViewer.instance.viewerToolbar.setItemProperty(i, 'disabled', true);
      });
    }
  }

  closeLTViewerSeries(serID, shouldCloseViewer) {
    const index = this.openedSeriesVisitConfigIds.find((s, i) => {
      if (s.seriesId === serID) {
        return i;
      }
    });
    this.openedSeriesVisitConfigIds.splice(index, 1);
    localStorage.setItem('visitConfigIds', JSON.stringify(this.openedSeriesVisitConfigIds));
    this.seriesNavigator.unmarkSeriesAsOpenedBySeriesId(serID);

    if (shouldCloseViewer) {
      this.viewerContainer.nativeElement.style.display = 'none';
    }
  }

  secondsToHHMMSS() {
    const currentSeconds = this.spentSeconds;
    let hours: number | string = Math.floor(currentSeconds / 3600);
    let minutes: number | string = Math.floor((currentSeconds - (hours * 3600)) / 60);
    let seconds: number | string = currentSeconds - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  skipCurrentReadingAndUpdate(readingId?: number) {
    this.showModalSpinnerLoadingData = true;
    this.currentReading.timeSpent = this.spentSeconds;
    this.readingMranoService.updateReading(
      this.study.id, this.currentReading.id, this.spentSeconds
    ).subscribe(response => {
      if (response['responseCode'] === 200) {
        this.skipCurrentReading(readingId ? this.readingList.findIndex(reading => reading.id === readingId) : this.getIndexOfCurrentReading() + 1);
      } else {
        this.showErrorMessage(response);
      }
    });
  }

  getIndexOfCurrentReading() {
    return this.readingList.indexOf(this.currentReading);
  }

  skipCurrentReading(nextIndex: number, removeCurrent: boolean = false) {
    // hande scoring less
    if (!this.viewerLess) {
      if (this.newLTViewer) {
        this.newLTViewer.instance.removeAllCells();
      }
      this.newLTViewer.instance.removeAllCells();
      this.visitedSeriesCounter = 0;
      if (removeCurrent) {
        const index = this.getIndexOfCurrentReading();
        this.readingList.splice(index, 1);
      }
    }

    if (nextIndex >= this.readingList.length) {  // circle
      nextIndex = 0;
      this.detailsGot = true;
      this.readingMranoService.modalSpinnerChangeState(false);
    }

    // hande viewer less
    if (this.viewerLess) {
      const message: FormEvent = { key: EventKey.SKIP_FORM, value: { index: nextIndex, removeCurrent: removeCurrent } };
      this.messagingService.sendRequest(message, FormPage.MRANO);
      if (this.readingList.length) {
        this.router.navigate([],
          {
            relativeTo: this.activatedRoute,
            queryParams: { viewerLess: 'true', rid: this.readingList[nextIndex].id }
          });
      }
    }
    if (this.readingList.length) {
      this.initReading(this.readingList[nextIndex]);
    }

  }

  resetScoringForms() {
    this.store.dispatch(new SetPageHeaderTitle('Rano Assessment – Scoring Form'));

    // get nadir and baseline sppd by reading id
    if (this.viewerLess) {
      this.getBaseLineAndHistoricalNadir();
    }
   }

  getBaseLineAndHistoricalNadir() {
    this.levelOneReadings?.forEach((reading) => {
      this.oncologyAssessmentService.getNadirBaselineSPPD(this.study.id, this.currentReading.readingVersion, reading.id).subscribe(nadirResponse => {
        if (nadirResponse['responseCode'] === 200) {
          this.oncologyAssessmentService.getHistoricalNadir(this.study.id, this.currentReading.readingVersion, reading.id).subscribe(baselineResponse => {
            if (baselineResponse['responseCode'] === 200) {
              this.oncologyAssessmentService.baselineNadirSPPDChange({
                readingId: reading.id,
                baselineSPPD: nadirResponse['data'].baselineSppd,
                nadirSPPD: nadirResponse['data'].nadirSppd,
                historicalNadir: baselineResponse['data'],
                nadirVisitConfigId: nadirResponse['data'].nadirVisitConfigId
              });
            }
          });
        }
      });
    });
  }

  checkReadings(index: number) {
    this.readingList.splice(index, 1);
    this.skipCurrentReading(index, true);    // ind == nextIndex after splice
    if (!this.readingList.length) {
      this.currentReading = undefined;
      this.updateEventSubscriptionForCurrentReading();
      this.showModalSpinnerLoadingData = false;
    }
  }

  showErrorMessage(response: any): void {
    this.showModalSpinnerLoadingData = false;
    this.toastOptions.title = 'ERROR ' + response['responseCode'];
    this.toastOptions.msg = response['responseMessage'];
    this.toastyService.error(this.toastOptions);
  }

  ModalSpinnerChangeState(state: boolean) {
    this.showModalSpinnerLoadingData = state;
  }

  openLTViewerFromChildren(data) {
    if (data['seriesId'] && this.newLTViewer) {
      if (this.newLTViewer.instance.seriesOpened && this.newLTViewer.instance.seriesOpened[data['seriesId']] != null) {
        if (data['sliceNumber'] !== undefined) {
          this.newLTViewer.instance.viewer.layout.get_items().toArray().forEach(cell => {
            if (cell.seriesId === data['seriesId'] || cell.seriesId === data['seriesId'] + '') {
              cell.currentOffset = parseInt(data['sliceNumber'], 10) - 1;
            }
          });
        }
      } else {
        const sliceNumber = data['sliceNumber'] ? data['sliceNumber'] : data['sliceNumber'];
        const seriesId = data['seriesId'];
        this.openLTViewer([seriesId], sliceNumber);
      }
    }
  }

  updateTimepointStatus(status: VisitStatus, visit) {
    if (visit.status === VisitStatus.IN_PROGRESS ||
      visit.status === VisitStatus.DONE ||
      visit.status === VisitStatus.NOT_AVAILABLE) { return; }

    this.oncologyAssessmentService.updateTimepointStatus(this.currentReading.studyId, this.currentReading.id, visit.visitConfigId, status).subscribe(response => {
      if (response.responseCode === ResponseCode.OK) {
        visit.status = status;
      }
    });
  }

  submitReading(): void  {
    const adjudicatorAgreesWithReaderValue = +this.adjudicationForm.get('adjudicatorAgreesWithReader').value;
    const adjudicatorComment = this.adjudicationForm.get('adjudicatorComment').value;

    this.adjudicationForm.markAllAsTouched();
    this.currentReading.timeSpent = this.spentSeconds;
    this.showModalSpinnerLoadingData = true;
    const lastVisitIndex = this.levelOneReadings[0].visits.length - 1;
    const data = {
      comment: adjudicatorComment,
      score: {
        reader1Response: this.levelOneReadings[0].visits[lastVisitIndex].overallResponse,
        reader2Response: this.levelOneReadings[1].visits[lastVisitIndex].overallResponse,
        adjudicationResponse: this.levelOneReadings[adjudicatorAgreesWithReaderValue].visits[lastVisitIndex].overallResponse,
      }
    };

    this.readingMranoService.lockAdjudicationVisit(
      this.study.id, this.currentReading.id, this.currentVisit.visitConfigId, data
    ).subscribe(response => {
      if (response['responseCode'] === 200) {
        this.currentVisit.status = VisitStatus.DONE;
        const nextWorkVisitInCurrenReading = this.getNextWorkVisit();
        const currentReadingIndex = this.getIndexOfCurrentReading();
        if (nextWorkVisitInCurrenReading) {
          this.initReading(this.readingList[currentReadingIndex]);
        } else {
          this.checkReadings(currentReadingIndex);
        }

      } else {
        this.showErrorMessage(response);
      }
    });
  }

  validateSubmit() {
    return this.adjudicationForm.valid;
  }
}
