import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { ScannerService, ModalityService } from '../../../_services';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';

@Component({
  selector: 'app-imaging-project-scanner',
  templateUrl: './imaging-project-scanner.component.html',
  styleUrls: ['./imaging-project-scanner.component.css']
})
export class ImagingProjectScannerComponent implements OnInit {
  modalityCtrl = new FormControl('', [
    Validators.required
  ]);
  requiredManufacturerCtrl = new FormControl('', [
    Validators.required
  ]);
  requiredModelCtrl = new FormControl('', [
    Validators.required
  ]);
  scanner: any = {};
  scanners: any[] = [];
  toastOptions: ToastOptions = {
    title: "",
    showClose: true,
    timeout: 10000,
    theme: "material",
  };
  modalities: any[] = [];
  filteredModalities: Observable<any[]>;
  displayedColumns: string[] = ['row-num', 'manufacturer', 'model', 'version', 'modality', 'comment', 'operations'];
  dataSource: MatTableDataSource<any>;

  showTable: boolean = true;
  showScanner: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private scannerService: ScannerService,
    private modalityService: ModalityService,
    private toastyService: ToastyService, private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Scanners'));
    this.scannerService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.scanners = response.data;
          this.dataSource = new MatTableDataSource(this.scanners);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
    this.modalityService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.modalities = response.data;
          this.filteredModalities = this.modalityCtrl.valueChanges
            .pipe(
              startWith(''),
              map(value => this._filter(value))
            );
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  saveScanner() {
    if (this.scanner.id) {
      this.scannerService.update(this.scanner).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.scannerService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.scanners = response.data;
                  this.dataSource.data = response.data;
                  this.toastOptions.title = "";
                  this.toastOptions.msg = "Scanner successfully updated";
                  this.toastyService.success(this.toastOptions);
                  this.hideScanners();
                } else {
                  this.toastOptions.title = "ERROR " + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = 'ERROR ' + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      if (!this.scanner.modality || !this.modalities.includes(this.scanner.modality)) {
        this.toastOptions.title = 'ERROR Bad Request';
        this.toastOptions.msg = 'Please select modality from the list of modalities';
        this.toastyService.error(this.toastOptions);
      } else {
        this.scannerService.create(this.scanner).subscribe(
          (response: any) => {
            if (response.responseCode === 200) {
              this.scannerService.getAll().subscribe(
                (response: any) => {
                  if (response.responseCode === 200) {
                    this.scanners = response.data;
                    this.dataSource.data = response.data;
                    this.toastOptions.title = '';
                    this.toastOptions.msg = 'Scanner successfully created';
                    this.toastyService.success(this.toastOptions);
                    this.hideScanners();
                  } else {
                    this.toastOptions.title = 'ERROR ' + response.responseCode;
                    this.toastOptions.msg = response.responseMessage;
                    this.toastyService.error(this.toastOptions);
                  }
                }
              );
            } else {
              this.toastOptions.title = 'ERROR ' + response.responseCode;
              this.toastOptions.msg = response.responseMessage;
              this.toastyService.error(this.toastOptions);
            }
          }
        );
      }
    }
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  showScanners(element) {
    this.scanner = {};
    this.showTable = false;
    this.showScanner = true;
    this.modalityCtrl.clearValidators();
    this.modalityCtrl.setValue(null);
    this.requiredManufacturerCtrl.clearValidators();
    this.requiredModelCtrl.clearValidators();
    if (element) {
      this.scannerService.getById(element.id).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.scanner = response.data;
            this.modalityCtrl.setValue(this.scanner.modality.name);
            this.store.dispatch(new SetPageHeaderTitle('Edit Scanner'));
          }
        }
      );
    } else {
      this.store.dispatch(new SetPageHeaderTitle('Create Scanner'));
    }
  }

  deleteScanner(element) {
    this.scannerService.delete(element.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.scannerService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                this.scanners = response.data;
                this.dataSource.data = response.data;
              } else {
                this.toastOptions.title = 'ERROR ' + response.responseCode;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
          this.toastOptions.title = '';
          this.toastOptions.msg = 'Scanner successfully deleted.';
          this.toastyService.success(this.toastOptions);
        } else {
          this.toastOptions.title = 'ERROR ' + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  hideScanners() {
    this.showTable = true;
    this.showScanner = false;
  }

  selectedModality(event: MatAutocompleteSelectedEvent): void {
    this.scanner.modality = event.option.value;
    this.modalityCtrl.setValue(this.scanner.modality.name);
  }

  private _filter(value: any): any[] {
    if (value instanceof Object || value === null) {
      return;
    }
    const filterValue = value.toLowerCase();
    return this.modalities.filter(modality => modality.name.toLowerCase().includes(filterValue));
  }

  sortData(sort: Sort) {
    const data = this.scanners.slice();
    if (!sort.active || sort.direction === '') {
      sort.direction = 'asc';
      sort.active = 'name';
    }

    this.scanners = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'manufacturer':
          return compare(a.manufacturer, b.manufacturer, isAsc);
        case 'model':
          return compare(a.model, b.model, isAsc);
        case 'version':
          return compare(a.version, b.version, isAsc);
        case 'modality':
          return compare(a.modality.name, b.modality.name, isAsc);
        case 'comment':
          return compare(a.comment, b.comment, isAsc);
        default:
          return 0;
      }
    });
    this.dataSource = new MatTableDataSource<any>(this.scanners);
  }

}

function compare(a: number | string | Date | boolean, b: number | string | Date | boolean, isAsc: boolean) {
  return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
}
