import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ScoringType} from '../scoring.type';
import {JSW_OARSI_ReadingModel} from '../../../../_models/JSW/jsw-reading-model';
import {JSW_OARSI_VisitModel} from '../../../../_models/JSW/jsw-visit-model';

export interface TableRow {
  visitName: string;
  id: number;
  LKM: number | "NE";
  LKL: number | "NE";
  RKM: number | "NE";
  RKL: number | "NE";
  Lcomment: string;
  Rcomment: string;
  LKMControl: FormControl;
  LKLControl: FormControl;
  RKMControl: FormControl;
  RKLControl: FormControl;
  LcommentControl: FormControl;
  RcommentControl: FormControl;
  leftKneeMedialMmControl:  FormControl;
  leftKneeLateralMmControl: FormControl;
  rightKneeMedialMmControl: FormControl;
  rightKneeLateralMmControl: FormControl;
}

@Component({
  selector: 'app-jsw-reading-knee-form',
  templateUrl: './jsw-reading-knee-form.component.html',
  styleUrls: ['./jsw-reading-knee-form.component.css']
})
export class JswReadingKneeFormComponent implements OnInit {
  private visits: JSW_OARSI_VisitModel[];
  public kneeFormArray: FormArray;
  private static FRACTION_DIGITS = 2;

  @Input() set setVisits(visits: JSW_OARSI_VisitModel[]) {
    this.visits = (visits || []).sort((a, b) => a.id - b.id);
  }

  @Input() set setKneeFormArray(kneeFormArray: FormArray) {
    this.kneeFormArray = kneeFormArray;
    this.tryInitForm();
  }
  @Input() reject: boolean;

  @Input()
  isApproveNeeded = true;

  rows: TableRow[];
  absLKM;
  absLKL;
  absRKM;
  absRKL;
  absLKMApproval;
  absLKLApproval;
  absRKMApproval;
  absRKLApproval;

  constructor() {
  }

  static generateKneeFormArray(reading: JSW_OARSI_ReadingModel, fb: FormBuilder): FormArray {
    const kneeFormArray = fb.array([]);
    reading.visits.filter(visit => !visit.noUploads).forEach(visit => {
      const kneeForm = JswReadingKneeFormComponent.generateKneeFormForVisit(visit, fb);
      // if ((visit.status || '').toLowerCase() === 'done') {
      //   kneeForm.get('Lcomment').disable();
      //   kneeForm.get('Rcomment').disable();
      //   kneeForm.get('leftKneeMedialMm').disable();
      //   kneeForm.get('leftKneeLateralMm').disable();
      //   kneeForm.get('rightKneeMedialMm').disable();
      //   kneeForm.get('rightKneeLateralMm').disable();
      // }
      kneeFormArray.push(kneeForm);
    });
    return kneeFormArray;
  }

  private static generateKneeFormForVisit(visit: JSW_OARSI_VisitModel, fb: FormBuilder): FormGroup {
    return fb.group({
      visitId: new FormControl(visit.id, [Validators.required]),
      LKM: new FormControl(visit.scoring?.leftKneeMedialValid, []),
      LKL: new FormControl(visit.scoring?.leftKneeLateralValid, []),
      RKM: new FormControl(visit.scoring?.rightKneeMedialValid, []),
      RKL: new FormControl(visit.scoring?.rightKneeLateralValid, []),
      Lcomment: new FormControl(visit.scoring?.leftKneeComment, []),
      Rcomment: new FormControl(visit.scoring?.rightKneeComment, []),
      leftKneeMedialMm: new FormControl(this.parseToFixed(visit.scoring?.leftKneeMedialMm), [Validators.required, this.customValidator]),
      leftKneeLateralMm: new FormControl(this.parseToFixed(visit.scoring?.leftKneeLateralMm), [Validators.required, this.customValidator]),
      rightKneeMedialMm: new FormControl(this.parseToFixed(visit.scoring?.rightKneeMedialMm), [Validators.required, this.customValidator]),
      rightKneeLateralMm: new FormControl(this.parseToFixed(visit.scoring?.rightKneeLateralMm), [Validators.required, this.customValidator]),
    });
  }

  static get customValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value !== 'NE' && control.value < 0
        ? { invalidError: 'invalid input' } : null;
    };
  }

  private static parseToFixed(mesurement: string): number | "NE" {
    const number = parseFloat(mesurement);
    return isNaN(number) ? "NE" : parseFloat(number.toFixed(this.FRACTION_DIGITS));
  }

  ngOnInit(): void {
    this.tryInitForm();
  }

  switchCheckbox(checkBoxControl: FormControl) {
    if (this.reject) return;
    checkBoxControl.setValue(!checkBoxControl.value);
  }

  private tryInitForm() {
    if (!!this.visits && !!this.kneeFormArray) {
      this.initForm();
    }
  }

  private initForm() {
    this.initTableRows();
    this.subscribeKneeFromValueChanges();
    this.onFormValueChange(this.kneeFormArray.value);
  }

  private subscribeKneeFromValueChanges() {
    this.kneeFormArray.valueChanges.subscribe(formRows => this.onFormValueChange(formRows));
  }

  private initTableRows() {
    const rows: TableRow[] = [];
    this.visits.filter(visit => !visit.noUploads).forEach(visit => {
      rows.push(this.visitToTableRow(visit));
    });
    this.rows = rows;
  }

  private onFormValueChange(formRows) {
    this.absRKLApproval = this.isValuesApprovedForScoringTypeInForm(formRows, ScoringType.RKL);
    this.absRKL = this.absRKLApproval ? this.calculateAbsForScoringType(ScoringType.RKL) : 0;

    this.absRKMApproval = this.isValuesApprovedForScoringTypeInForm(formRows, ScoringType.RKM);
    this.absRKM = this.absRKMApproval ? this.calculateAbsForScoringType(ScoringType.RKM) : 0;

    this.absLKLApproval = this.isValuesApprovedForScoringTypeInForm(formRows, ScoringType.LKL);
    this.absLKL = this.absLKLApproval ? this.calculateAbsForScoringType(ScoringType.LKL) : 0;

    this.absLKMApproval = this.isValuesApprovedForScoringTypeInForm(formRows, ScoringType.LKM);
    this.absLKM = this.absLKMApproval ? this.calculateAbsForScoringType(ScoringType.LKM) : 0;
  }

  private calculateAbsForScoringType(scoringType: ScoringType): any {
    const valuesOfType: any[] = this.rows.map(row => row[scoringType]);
    const minValue = Math.min(...valuesOfType);
    const absMinValue = Math.abs(minValue);
    const maxValue = Math.max(...valuesOfType);
    const absMaxValue = Math.abs(maxValue);
    const result =  Math.abs(absMaxValue - absMinValue)
    return isNaN(result) ? "NE" : result.toFixed(JswReadingKneeFormComponent.FRACTION_DIGITS);
  }

  private visitToTableRow(visit: JSW_OARSI_VisitModel): TableRow {
    const currentVisitForm = this.kneeFormArray.controls.find(vF => vF.value.visitId === visit.id);

    if ((visit.status || '').toLowerCase() === 'done') {
      currentVisitForm.disable();
    }
    return {
      id: visit.id,
      visitName: visit.timepoint,
      LKM: JswReadingKneeFormComponent.parseToFixed(visit.scoring?.leftKneeMedialMm),
      LKL: JswReadingKneeFormComponent.parseToFixed(visit.scoring?.leftKneeLateralMm),
      RKM: JswReadingKneeFormComponent.parseToFixed(visit.scoring?.rightKneeMedialMm),
      RKL: JswReadingKneeFormComponent.parseToFixed(visit.scoring?.rightKneeLateralMm),
      Lcomment: String(visit.scoring?.leftKneeComment),
      Rcomment: String(visit.scoring?.rightKneeComment),
      LKMControl: currentVisitForm['controls'].LKM,
      LKLControl: currentVisitForm['controls'].LKL,
      RKMControl: currentVisitForm['controls'].RKM,
      RKLControl: currentVisitForm['controls'].RKL,
      LcommentControl: currentVisitForm['controls'].Lcomment,
      RcommentControl: currentVisitForm['controls'].Rcomment,
      leftKneeMedialMmControl: currentVisitForm['controls'].leftKneeMedialMm,
      leftKneeLateralMmControl: currentVisitForm['controls'].leftKneeLateralMm,
      rightKneeMedialMmControl: currentVisitForm['controls'].rightKneeMedialMm,
      rightKneeLateralMmControl: currentVisitForm['controls'].rightKneeLateralMm
    } as TableRow;
  }

  private isValuesApprovedForScoringTypeInForm(formRows, scoringType: ScoringType) {
    return this.rows.length > 1 && !formRows.some(row => !row[scoringType]);
  }
}
