export enum PSMAPETScoringType{
    ELIGIBILITY = 'ELIGIBILITY'
}
export enum PSMAPETLesionObject{
    TARGET_LESION = 'TARGET_LESION',
    NON_TARGET_LESION = 'NON_TARGET_LESION',
}
export enum PSMAPETLesionEditType{
    COMMENT = 'COMMENT',
    LOCATION = 'LOCATION',
    MEASUREMENT = 'MEASUREMENT'
}