import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Novadip5ReadingFormControls, Novadip5ReadingFormGroup, Novadip5ReadingFormModel} from './novadip5-form-model';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../reading-form-scoring.component';
import {ReadingNovadip5Service} from '../../../_services/reading-novadip5.service';
import {Novadip5ScoringModel} from '../../../_models/NOVADIP5/novadip5-scoring-model';
import {Observable} from 'rxjs';
import {BasicResponse} from '../../../core/interfaces/basic-response';
import {BasicReading} from '../../../_models/basic-reading';
import {Novadip5SubmitModel} from './novadip5-submit-model';
import {modifyTabHeader, updateTabHeaderWidth} from '../helper/mat-tab-helper';
import {isNumeric} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-novadip5',
  templateUrl: './novadip5.component.html',
  styleUrls: ['./novadip5.component.css']
})
export class Novadip5Component extends ReadingFormScoringComponent implements OnInit, AfterViewInit, ReadingFormScoring {

  @ViewChild('readingTabGroup') readingTabGroup: ElementRef;

  readonly discFusionColumns: string [] = ['parameter', 'coronalReformat', 'sagittalReformat', 'total'];
  readonly facetFusionColumns: string [] = ['parameter', 'transverseImages'];
  readonly paramAnswerColumns: string [] = ['parameter', 'answer'];

  currentReading: BasicReading;

  formItems: {
    form: Novadip5ReadingFormGroup,
    discHeightDataSource: MatTableDataSource<any>,
    diskFusionDataSource: MatTableDataSource<any>,
    facetFusionDataSource: MatTableDataSource<any>,
    integrityDataSource: MatTableDataSource<any>,
    visitId: number,
    timepoint: string
  }[];

  constructor(private store: Store,
              private fb: FormBuilder,
              private readingService: ReadingNovadip5Service) {
    super();
  }

  static calculateDiscFusionTotal(v1: number, v2: number, totalFormControl: FormControl): void {
    if (v1 != null && v2 != null) {
      totalFormControl.setValue(Math.max(v1, v2));
    }
  }

  static calculateDiscHeightMean(msah: number, msch: number, msph: number, mcrh: number, mclh: number, meanFormControl: FormControl): void {
    meanFormControl.setValue(([msah, msch, msph, mcrh, mclh].reduce((sum, current) => sum + current, 0) / 5).toFixed(2));
  }

  static buildScoringModel(formValue: Novadip5ReadingFormModel): Novadip5ScoringModel {
    return {
      lumbarL1L2: formValue.lumbarLevel === 1 ? 1 : 0,
      lumbarL2L3: formValue.lumbarLevel === 2 ? 1 : 0,
      lumbarL3L4: formValue.lumbarLevel === 3 ? 1 : 0,
      lumbarL4L5: formValue.lumbarLevel === 4 ? 1 : 0,
      lumbarL5S1: formValue.lumbarLevel === 5 ? 1 : 0,

      ctScanImage: formValue.ctScanImage,
      imageQualityComnt: formValue.imageQualityComnt,

      discFusionCsCr: formValue.discFusionCsCr?.toString(),
      discFusionCsSr: formValue.discFusionCsSr?.toString(),
      discFusionCsTotal: formValue.discFusionCsTotal?.toString(),

      discFusionPsCr: formValue.discFusionPsCr?.toString(),
      discFusionPsSr: formValue.discFusionPsSr?.toString(),
      discFusionPsTotal: formValue.discFusionPsTotal?.toString(),

      discFusionMaCr: formValue.discFusionMaCr?.toString(),
      discFusionMaSr: formValue.discFusionMaSr?.toString(),
      discFusionMaTotal: formValue.discFusionMaTotal?.toString(),

      facetFusionRfj: formValue.facetFusionRfj?.toString(),
      facetFusionLfj: formValue.facetFusionLfj?.toString(),

      instrBonesIcfm: formValue.instrBonesIcfm?.toString(),
      instrBonesSfm: formValue.instrBonesSfm?.toString(),
      instrBonesBraic: formValue.instrBonesBraic?.toString(),
      instrBonesBras: formValue.instrBonesBras?.toString(),
      instrBonesPvbr: formValue.instrBonesPvbr?.toString(),
      instrBonesNbfod: formValue.instrBonesNbfod?.toString(),

      discHeightMsAh: formValue.discHeightMsAh?.toString(),
      discHeightMsCh: formValue.discHeightMsCh?.toString(),
      discHeightMsPh: formValue.discHeightMsPh?.toString(),
      discHeightMcRh: formValue.discHeightMcRh?.toString(),
      discHeightMcLh: formValue.discHeightMcLh?.toString(),
      discHeightMean: formValue.discHeightMean?.toString(),

      comment: formValue.comment
    };
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Novadip5 Scoring Form'));
    this.initOnCurrentReading();
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      this.currentReading = currentReading;
      this.formItems = [];
      currentReading.visits.filter(visit => !visit['noUploads']).forEach(visit => {
        const form = this.initForm(visit.scoring);
        this.formItems.push({
          form: form,
          discHeightDataSource: this.initDiscHeightDataSource(form),
          diskFusionDataSource: this.initDiskFusionDataSource(form),
          facetFusionDataSource: this.initFacetFusionDataSource(form),
          integrityDataSource: this.initIntegrityDataSource(form),
          visitId: visit.id,
          timepoint: visit.timepoint
        });
      });

    });
  }

  ngAfterViewInit(): void {
    modifyTabHeader('Visit selector');
  }

  changeTabHeaderWidth(): void {
    updateTabHeaderWidth();
  }

  calcLumbarLevelValue(scoring: any): number {
      let value: number = null;
      const keyPath = 'lumbarL';
      for (const key in scoring) {
          if (scoring.hasOwnProperty(key)) {
             if (key.startsWith(keyPath) && scoring[key] === 1) {
                 value = Number(key.substr(keyPath.length, 1));
                 break;
             }
          }
      }
      return value;
  }

  initForm(scoring: any): FormGroup & Novadip5ReadingFormGroup {
    const fg = this.fb.group({
      lumbarLevel: new FormControl(this.calcLumbarLevelValue(scoring), [Validators.required]),

      ctScanImage: new FormControl(scoring?.ctScanImage, [Validators.required]),
      imageQualityComnt: new FormControl(scoring?.imageQualityComnt, [Validators.maxLength(200)]),

      discFusionCsCr: new FormControl(Number(scoring?.discFusionCsCr), [Validators.required]),
      discFusionCsSr: new FormControl(Number(scoring?.discFusionCsSr), [Validators.required]),
      discFusionCsTotal: new FormControl(
          isNumeric(scoring?.discFusionCsTotal) ? Number(scoring.discFusionCsTotal) : '-', [Validators.required]
      ),

      discFusionPsCr: new FormControl(Number(scoring?.discFusionPsCr), [Validators.required]),
      discFusionPsSr: new FormControl(Number(scoring?.discFusionPsSr), [Validators.required]),
      discFusionPsTotal: new FormControl(
          isNumeric(scoring?.discFusionPsTotal) ? Number(scoring.discFusionPsTotal) : '-', [Validators.required]
      ),

      discFusionMaCr: new FormControl(Number(scoring?.discFusionMaCr), [Validators.required]),
      discFusionMaSr: new FormControl(Number(scoring?.discFusionMaSr), [Validators.required]),
      discFusionMaTotal: new FormControl(
          isNumeric(scoring?.discFusionMaTotal) ? Number(scoring.discFusionMaTotal) : '-', [Validators.required]
      ),

      facetFusionRfj: new FormControl(
          isNumeric(scoring?.facetFusionRfj) ? Number(scoring.facetFusionRfj) : null, [Validators.required]
      ),
      facetFusionLfj: new FormControl(
          isNumeric(scoring?.facetFusionLfj) ? Number(scoring.facetFusionLfj) : null, [Validators.required]
      ),

      instrBonesIcfm: new FormControl(scoring?.instrBonesIcfm, [Validators.required]),
      instrBonesSfm: new FormControl(scoring?.instrBonesSfm, [Validators.required]),
      instrBonesBraic: new FormControl(scoring?.instrBonesBraic, [Validators.required]),
      instrBonesBras: new FormControl(scoring?.instrBonesBras, [Validators.required]),
      instrBonesPvbr: new FormControl(scoring?.instrBonesPvbr, [Validators.required]),
      instrBonesNbfod: new FormControl(scoring?.instrBonesNbfod, [Validators.required]),

      discHeightMsAh: new FormControl(Number(scoring?.discHeightMsAh), [Validators.required, Validators.min(0), Validators.max(99.99)]),
      discHeightMsCh: new FormControl(Number(scoring?.discHeightMsCh), [Validators.required, Validators.min(0), Validators.max(99.99)]),
      discHeightMsPh: new FormControl(Number(scoring?.discHeightMsPh), [Validators.required, Validators.min(0), Validators.max(99.99)]),
      discHeightMcRh: new FormControl(Number(scoring?.discHeightMcRh), [Validators.required, Validators.min(0), Validators.max(99.99)]),
      discHeightMcLh: new FormControl(Number(scoring?.discHeightMcLh), [Validators.required, Validators.min(0), Validators.max(99.99)]),
      discHeightMean: new FormControl(Number(scoring?.discHeightMean) ? scoring.discHeightMean : 0, [Validators.required]),

      comment: new FormControl(scoring?.comment, [
        Validators.minLength(1),
        Validators.maxLength(200)])

    } as Novadip5ReadingFormControls) as Novadip5ReadingFormGroup;

    this.initFormChangeValueSubscription(fg);

    return fg;
  }

  private initFormChangeValueSubscription(form: Novadip5ReadingFormGroup): void {

    form.valueChanges.subscribe(() => {
      let isFormValid = true;
      this.formItems.forEach(item => isFormValid = isFormValid && !item.form.invalid);
      this.switchSubmitBtnDisabledSubject.next(!isFormValid);
    });

    form.controls.discFusionCsCr.valueChanges.subscribe(() => {
      Novadip5Component.calculateDiscFusionTotal(form.controls.discFusionCsCr.value, form.controls.discFusionCsSr.value,
        form.controls.discFusionCsTotal);
    });
    form.controls.discFusionCsSr.valueChanges.subscribe(() => {
      Novadip5Component.calculateDiscFusionTotal(form.controls.discFusionCsCr.value, form.controls.discFusionCsSr.value,
        form.controls.discFusionCsTotal);
    });

    form.controls.discFusionPsCr.valueChanges.subscribe(() => {
      Novadip5Component.calculateDiscFusionTotal(form.controls.discFusionPsCr.value, form.controls.discFusionPsSr.value,
        form.controls.discFusionPsTotal);
    });
    form.controls.discFusionPsSr.valueChanges.subscribe(() => {
      Novadip5Component.calculateDiscFusionTotal(form.controls.discFusionPsCr.value, form.controls.discFusionPsSr.value,
        form.controls.discFusionPsTotal);
    });

    form.controls.discFusionMaCr.valueChanges.subscribe(() => {
      Novadip5Component.calculateDiscFusionTotal(form.controls.discFusionMaCr.value, form.controls.discFusionMaSr.value,
        form.controls.discFusionMaTotal);
    });
    form.controls.discFusionMaSr.valueChanges.subscribe(() => {
      Novadip5Component.calculateDiscFusionTotal(form.controls.discFusionMaCr.value, form.controls.discFusionMaSr.value,
        form.controls.discFusionMaTotal);
    });

    const calculateDiscHeightMean = () => {
      Novadip5Component.calculateDiscHeightMean(
        form.controls.discHeightMsAh.value, form.controls.discHeightMsCh.value,
        form.controls.discHeightMsPh.value, form.controls.discHeightMcRh.value,
        form.controls.discHeightMcLh.value, form.controls.discHeightMean
      );
    };

    form.controls.discHeightMsAh.valueChanges.subscribe(() => {
      calculateDiscHeightMean();
    });
    form.controls.discHeightMsCh.valueChanges.subscribe(() => {
      calculateDiscHeightMean();
    });
    form.controls.discHeightMsPh.valueChanges.subscribe(() => {
      calculateDiscHeightMean();
    });
    form.controls.discHeightMcLh.valueChanges.subscribe(() => {
      calculateDiscHeightMean();
    });
    form.controls.discHeightMcRh.valueChanges.subscribe(() => {
      calculateDiscHeightMean();
    });
  }

  initDiskFusionDataSource(form: Novadip5ReadingFormGroup): MatTableDataSource<any> {
    const data = [
      {
        param: 'Central segment',
        crFormControl: form.controls.discFusionCsCr,
        srFormControl: form.controls.discFusionCsSr,
        totalFormControl: form.controls.discFusionCsTotal
      },
      {
        param: 'Peripheral segment',
        crFormControl: form.controls.discFusionPsCr,
        srFormControl: form.controls.discFusionPsSr,
        totalFormControl: form.controls.discFusionPsTotal
      },
      {
        param: 'Marginal area',
        crFormControl: form.controls.discFusionMaCr,
        srFormControl: form.controls.discFusionMaSr,
        totalFormControl: form.controls.discFusionMaTotal
      },
    ];
    return new MatTableDataSource<any>(data);
  }

  initFacetFusionDataSource(form: Novadip5ReadingFormGroup): MatTableDataSource<any> {
    const data = [
      {param: 'Right facet join', formControl: form.controls.facetFusionRfj},
      {param: 'Left facet join', formControl: form.controls.facetFusionLfj}
    ];
    return new MatTableDataSource<any>(data);
  }

  initIntegrityDataSource(form: Novadip5ReadingFormGroup): MatTableDataSource<any> {
    const data = [
      {param: 'Interbody cage fragmentation or migration', formControl: form.controls.instrBonesIcfm},
      {param: 'Screw fragmentation or migration', formControl: form.controls.instrBonesSfm},
      {param: 'Bone resorption around the interbody cage', formControl: form.controls.instrBonesBraic},
      {param: 'Bone resorption around the screw', formControl: form.controls.instrBonesBras},
      {param: 'Progressive vertebral bone resorption', formControl: form.controls.instrBonesPvbr},
      {param: 'New bone formation out of disc', formControl: form.controls.instrBonesNbfod}
    ];
    return new MatTableDataSource<any>(data);
  }

  initDiscHeightDataSource(form: Novadip5ReadingFormGroup): MatTableDataSource<any> {
    const data = [
      {param: 'Mid-Sagittal: Anterior height (mm)', formControl: form.controls.discHeightMsAh},
      {param: 'Mid-Sagittal: Central height (mm)', formControl: form.controls.discHeightMsCh},
      {param: 'Mid-Sagittal: Posterior height (mm)', formControl: form.controls.discHeightMsPh},
      {param: 'Mid-Coronal: Right height (mm)', formControl: form.controls.discHeightMcRh},
      {param: 'Mid-Coronal: Left height (mm)', formControl: form.controls.discHeightMcLh}
    ];
    return new MatTableDataSource<any>(data);
  }

  getEndpointName(): string {
    return 'Novadip5';
  }

  clearForm(): void {
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingService.getReadingByStudyIdAndReaderId(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.updateReading(studyId, readingId, data.spentSeconds);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
      return this.readingService.completeReading(studyId, this.calcSubmitData(data));
  }

    saveReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
      return this.readingService.saveReading(studyId, this.calcSubmitData(data));
    }

    private calcSubmitData(data: { spentSeconds: number }) {
        const resultVisits = this.formItems.map(s => {
            return {
                id: s.visitId,
                timepoint: s.timepoint,
                scoring: Novadip5Component.buildScoringModel(s.form.value),
            };
        });
        const submitData: Novadip5SubmitModel = {
            id: this.currentReading.id,
            visits: resultVisits,
            configId: this.currentReading.configId,
            studyId: this.currentReading.studyId,
            timeSpent: data.spentSeconds
        };
        return submitData;
    }

}
