import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { SequenceLabelService } from '../../../_services';
import { FormControl, Validators } from '@angular/forms';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-imaging-project-sequence',
  templateUrl: './imaging-project-sequence.component.html',
  styleUrls: ['./imaging-project-sequence.component.css']
})
export class ImagingProjectSequenceComponent implements OnInit {
  requiredNameCtrl = new FormControl('', [
    Validators.required
  ]);
  requiredDescriptionCtrl = new FormControl('', [
    Validators.required
  ]);
  toastOptions: ToastOptions = {
    title: "",
    showClose: true,
    timeout: 10000,
    theme: "material",
  };
  displayedColumns: string[] = ['row-num', 'name', 'description', 'operations'];
  dataSource: MatTableDataSource<any>;
  sequences: any[] = [];
  sequence: any = {};
  showTable: boolean = true;
  showSequence: boolean = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private sequenceLabelService: SequenceLabelService,
    private toastyService: ToastyService, private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Sequences'));
    this.sequenceLabelService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.sequences = response.data;
          this.dataSource = new MatTableDataSource(this.sequences);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  showSequences(element) {
    this.sequence = {};
    this.showTable = false;
    this.showSequence = true;
    this.requiredNameCtrl.clearValidators();
    this.requiredDescriptionCtrl.clearValidators();
    if (element) {
      this.sequence = {...element};
      this.store.dispatch(new SetPageHeaderTitle('Edit Sequence'));
    } else {
      this.store.dispatch(new SetPageHeaderTitle('Create Sequence'));
    }
  }

  deleteSequence(element) {
    this.sequenceLabelService.delete(element.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.sequenceLabelService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                this.sequences = response.data;
                this.dataSource.data = response.data;
              } else {
                this.toastOptions.title = "ERROR " + response.responseCode;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
          this.toastOptions.title = "";
          this.toastOptions.msg = "Sequence successfully deleted.";
          this.toastyService.success(this.toastOptions);
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  hideSequences() {
    this.showTable = true;
    this.showSequence = false;
  }

  saveSequence() {
    if (this.sequence.id) {
      this.sequenceLabelService.update(this.sequence).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.sequenceLabelService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.sequences = response.data;
                  this.dataSource.data = response.data;
                  this.toastOptions.title = "";
                  this.toastOptions.msg = "Sequence successfully updated";
                  this.toastyService.success(this.toastOptions);
                  this.hideSequences();
                } else {
                  this.toastOptions.title = "ERROR " + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = "ERROR " + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        },
        (error: HttpErrorResponse) => {
          if(error.error.responseCode === 409) {
            this.toastOptions.title = "ERROR " + error.error.responseCode;
            this.toastOptions.msg = error.error.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.sequenceLabelService.create(this.sequence).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.sequenceLabelService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.sequences = response.data;
                  this.dataSource.data = response.data;
                  this.toastOptions.title = "";
                  this.toastOptions.msg = "Sequence successfully created";
                  this.toastyService.success(this.toastOptions);
                  this.hideSequences();
                } else {
                  this.toastOptions.title = "ERROR " + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = "ERROR " + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        },
        (error: HttpErrorResponse) => {
          if(error.error.responseCode === 409) {
            this.toastOptions.title = "ERROR " + error.error.responseCode;
            this.toastOptions.msg = error.error.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

}
