import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EndpointService, ReadingConfigFlexibleService, UserService} from '../../../../_services';
import {FlexibleConfig} from '../../../../core/interfaces/flexible-config';
import {User} from '../../../../_models/user';
import {MatSelectChange} from '@angular/material/select';
import {ReadingType} from '../../../../core/constants/reading-type';

export interface BatchReaderDialogData {
  studyId: number;
  selectedConfig: FlexibleConfig;
  readingType: ReadingType;
  selectedReader: User;
}

export interface BatchReaderDialogResult {
  result: 'ok' | 'cancel';
  config: FlexibleConfig;
  readingType: ReadingType;
  reader: any;
}

@Component({
  selector: 'app-batch-reader-dialog',
  templateUrl: './batch-reader-dialog.component.html',
  styleUrls: ['./batch-reader-dialog.component.css']
})
export class BatchReaderDialogComponent implements OnInit {

  canSave = false;
  studyId: number;

  studyFlexibleConfigs = [];
  selectedConfig: any;
  readingType: ReadingType;
  selectedReader: any;

  readonly readingTypes = ReadingType;

  constructor(
    private userService: UserService,
    private endpointService: EndpointService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    public dialogRef: MatDialogRef<BatchReaderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BatchReaderDialogData) {

    if (data['studyId'] != null) {
      this.studyId = data['studyId'];
    }
  }

  ngOnInit() {
    this.canSave = false;
    this.selectedReader = this.data.selectedReader;
    this.selectedConfig = this.data.selectedConfig;
    this.readingType = this.data.readingType;

    this.readingConfigFlexibleService.getActiveConfigsByStudyId(this.studyId).subscribe(flexConfigsResp => {
      const flexConfigs = flexConfigsResp.data;
      let usersIds = [];

      flexConfigs.forEach(flexConfig => {
        const config = flexConfig.config;
        // add basic readers
        config['readers'].forEach(reader => {
          usersIds.push(reader.id);
        });
        // add adjudication readers
        if (config.adjudicationReader != null) {
          usersIds.push(config.adjudicationReader);
        }
          // add moderation readers
        if (config.moderationReader != null) {
          usersIds.push(config.moderationReader);
        }
      });
      // only uniques ids
      usersIds = Array.from(new Set(usersIds));


      this.userService.getByListIds(usersIds).subscribe(usersResp => {
        const allReaders = usersResp.data;
        this.endpointService.getAll().subscribe(endpointRes => {
          const endpoints = endpointRes.data;
          this.studyFlexibleConfigs = flexConfigs.map(config => {
            const endpoint = endpoints.filter(e => e.id === config.endpointId)[0];
            config['name'] = endpoint['name'];

            // add basic readers names
            config.config['readers'].forEach((reader: User) => {
              const aReader: User = allReaders.find(r => r.id === reader.id);
              if (aReader != null) {
                reader['name'] = aReader.firstName + ' ' + aReader.lastName;
              }
            });
            // add adjudication readers names
            if (config.config['adjudicationReader'] != null) {
              const aReader: User = allReaders.find(r => r.id === config['config']['adjudicationReader']);
              config.config['adjudicationReader'] = {id: aReader.id, name: aReader.firstName + ' ' + aReader.lastName};
            }
              // add moderation readers names
              if (config.config['moderationReader'] != null) {
                  const aReader: User = allReaders.find(r => r.id === config['config']['moderationReader']);
                  config.config['moderationReader'] = {id: aReader.id, name: aReader.firstName + ' ' + aReader.lastName};
              }
            config['endpointReadingVersion'] = config['config']['endpointReadingVersion'];
            return config;
          });
          this.canSave = true;
        });

      });
    });
  }


  onEndpointSelectionChange(event: MatSelectChange) {
    this.selectedConfig = event.value;
    this.readingType = null;
    this.selectedReader = null;
    this.validate();
  }

  onReaderTypeSelectionChange(event: MatSelectChange) {
    this.readingType = event.value;
    this.selectedReader = null;
    this.validate();
  }

  onReaderSelectionChange(event: MatSelectChange) {
    this.selectedReader = event.value;
    this.validate();
  }

  validate() {
    this.canSave = this.selectedConfig != null && this.readingType != null && this.selectedReader != null;
  }

  onClickCancel() {
    this.dialogRef.close({result: 'cancel'});
  }

  onClickOk() {
    const result: BatchReaderDialogResult = {
        result: 'ok',
        config: this.selectedConfig,
        readingType: this.readingType,
        reader: this.selectedReader
      }
    ;
    this.dialogRef.close(result);
  }

  compareReaders(ra, rb): boolean {
      if (!ra || !rb) {
          return false;
      }
    return ra.id === rb.id;
  }

  compareConfigs(ca, cb): boolean {
      if (!ca || !cb) {
          return false;
      }
    return ca.id === cb.id;
  }
}
