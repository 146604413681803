import {Component, OnInit, ViewChild} from '@angular/core';
import {SetPageHeaderTitle} from '../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';
import {environment} from '../../../environments/environment';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

const SERVICE_NAMES = [
  'MSUser',
  'MSCommands',
  'MSImagingProject',
  'MSUpload',
  'MSAuditTrails',
  'MSQualityControl',
  'MSImageAnalysis',
  'MSQueries',
  'MSBatchLogic',
  'MSReadingIF',
  'MSReadingKandL',
  'MSReadingKandLAuto',
  'WebSocketsServer',
  'MSReadingPSAMRIS',
  'MSReadingWBMRI',
  'MSReadingMOAKS',
  'MSReadingNovadip5',
  'MSReadingCandenSparcc',
  'MSReadingJSW',
  'MSReadingLugano',
  'MSReadingRecist',
  'MSReadingMRANO',
  'MSReadingHEEL',
  'MSReadingDEMRIQ',
  'MSReadingPSMA',
  'MSDashboardStat',
];

@Component({
  selector: 'app-app-versions',
  templateUrl: './app-versions.component.html',
  styleUrls: ['./app-versions.component.css']
})
export class AppVersionsComponent implements OnInit {

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  services: ServicesTableRow[];
  servicesDataSource: MatTableDataSource<ServicesTableRow>;

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Dynamika app versions'));
    this.initServices();
    this.initServicesDataSource();
  }

  private initServices() {
    this.services = SERVICE_NAMES.map(serviceName => this.buildServicesTableRow(serviceName));
  }

  private initServicesDataSource() {
    this.servicesDataSource =  new MatTableDataSource(this.services);
    this.servicesDataSource.sort = this.sort;
  }

  private buildServicesTableRow(serviceName: string): ServicesTableRow {
    return {
      name: serviceName,
      version: this.getServiceVersion(serviceName)
    } as ServicesTableRow;
  }

  private getServiceVersion(serviceName: string): string {
    if (!serviceName) {
      throw new Error('serviceName cannot be blank');
    }
    const dirtyVersion = environment[`${serviceName}Version`];
    return dirtyVersion.replace('-dot-', '').replaceAll('-', '.');
  }
}

interface ServicesTableRow {
  name: string;
  version: string;
}
