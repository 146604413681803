import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class SiteConfigService {

  constructor(private http: HttpClient) {
  }

  create(siteConfig: any): Observable<BasicResponse<any>> {
    return this.http.post<BasicResponse<any>>(`${BASE_URL}/site-config`, siteConfig);
  }

  update(siteConfig: any): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/site-config`, siteConfig);
  }

  delete(id: number): Observable<BasicResponse<any>> {
    return this.http.delete<BasicResponse<any>>(`${BASE_URL}/site-config/${id}`);
  }

  getById(id: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/site-config/${id}`);
  }

  getSiteConfig(studyId: number, siteId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/site-config/${studyId}/${siteId}`);
  }

  getSiteConfigsBySiteIds(siteIds: number[]): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/site-config/get-by-site-ids`, siteIds);
  }

  getSiteConfigsByStudyIdAndSiteIds(studyId: number, siteIds: number[]): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/site-config/get-by-study-id-and-site-ids/${studyId}`, siteIds);
  }

  getSiteConfigsByStudyIdAndSiteIdsForUpload(studyId: number, siteIds: number[]): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/site-config/get-by-study-id-and-site-ids/for-upload/${studyId}`, siteIds);
  }

  getSiteConfigsByStudyId(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/site-config/get-site-configs-by-study-id/${studyId}`);
  }

  getSiteConfigsByStudyIdForDashboard(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/site-config/get-site-configs-by-study-id/for-dashboard/${studyId}`);
  }

  getSiteConfigsByStudyIdForUpload(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/site-config/get-site-configs-by-study-id/for-upload/${studyId}`);
  }

  getSiteConfigsByStudyIdBrief(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/site-config/get-site-configs-by-study-id/brief/${studyId}`);
  }


  getSiteUploadsByStudyId(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/site-config/get-upload-details-by-study-id/${studyId}`);
  }


  getAll(): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/site-config/retrieve-all`);
  }

  getVisitSettingsByStudyId(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/visit-setting/by-study/${studyId}`);
  }
}
