import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastOptions } from 'ng2-toasty';
import { AutoCreationBatchConfigDialogComponent } from 'src/app/components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import { ImageViewerConfigDialogComponent } from 'src/app/components/controls/image-viewer-config-dialog/image-viewer-config-dialog.component';
import { FormMode } from 'src/app/core/constants/form-mode';
import { ReadingType } from 'src/app/core/constants/reading-type';
import { ReadingVersion } from 'src/app/core/constants/reading-version';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import { AutoBatchConfigPayload } from 'src/app/core/payload/auto-batch-config-payload';
import { BatchConfigModel } from 'src/app/_models/BatchLogic/batch-config-model';
import { DemriqBasicConfigModel } from 'src/app/_models/ImagingProject/DEMRIQ/demriq-basic-config-model';
import { ModalityModel } from 'src/app/_models/ImagingProject/modality-model';
import { SequenceLabelModel } from 'src/app/_models/ImagingProject/sequence-label-model';
import { ReadingConfigFlexibleService, StudySequenceLabelService, StudyUserService } from 'src/app/_services';
import { BatchLogicService } from 'src/app/_services/batch-logic.service';
import {
  defaultBasicAutoBatchConfig,
  ImagingProjectSubmitEvent
} from '../imaging-project-reading-selector/imaging-project-reading-selector.component';
import { ImagingProjectReadingBasicComponent } from '../../imaging-project-reading-basic.component';
import { RegionLocationConfigDialogComponent } from './region-location-config-dialog/region-location-config-dialog.component';

@Component({
  selector: 'app-imaging-project-reading-demriq',
  templateUrl: './imaging-project-reading-demriq.component.html',
  styleUrls: ['./imaging-project-reading-demriq.component.css']
})
export class ImagingProjectReadingDemriqComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();
  @Input() public mode: FormMode;
  readonly formModes = FormMode;

  @Input('configModel')
  set configModel(value: any) {
    if (value != null) {
      this._configModel = value;
      if (this._configModel.readingLevel === 'visit') {
        this.modalities = this._configModel.modalities;
        this.setVisitModalityText(this.modalities);
        this.modalities.unshift(this.visitReadingLevelModality);
        this.selectedModality = this.visitReadingLevelModality;
      } else {
        this.selectedModalities = this._configModel.modalities;
      }
      this.hideVisitChronologyType = this._configModel.hideVisitHistory;
      this.imageViewerConfigurationType = this._configModel.imageViewerConfigurationType;
      this.bodyLocationToScore = this._configModel.bodyLocationToScore;
      this.primaryLocations = this._configModel.primaryLocations;
      this.regionsToScore = this._configModel.regionsToScore;
      this.readingVersion = this._configModel.readingVersion;
      this.imageViewerConfiguration = this._configModel.imageViewerConfiguration;
    }
  }

  @Input() selectedConfig: FlexibleConfig<DemriqBasicConfigModel>;

  get configModel(): any {
    return this._configModel;
  }

  get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true && ((!!this.basicBatchConfig && !this.basicBatchConfig?.numberOfReadings) || !this.basicBatchConfig)
        ? { norError: 'Number of readings is required' } : null;
    };
  }

  demriqtypes: any[];
  readingVersionField = new FormControl('', [
    Validators.required
  ]);
  readingLevelField = new FormControl('', [
    Validators.required
  ]);
  modalityField = new FormControl('', [
    Validators.required
  ]);
  readingTypeField = new FormControl('', [
    Validators.required
  ]);
  hideVisitChronologyField = new FormControl('', [
    Validators.required
  ]);
  batchManagementField = new FormControl('', [
    Validators.required, this.batchManagementValidator
  ]);
  imageViewerConfigurationField = new FormControl('', [
    Validators.required
  ]);
  bodyLocationToScoreField = new FormControl('', [
    Validators.required
  ]);
  readersField = new FormControl('', [
    Validators.required
  ]);
  studySequenceLabels: SequenceLabelModel[] = [];
  anatomySelectorControl: FormControl = new FormControl('', [Validators.required]);
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];

  basicBatchConfig: any = null;

  readingVersion: any;
  readingLevel: string;
  selectedModality: ModalityModel;
  selectedModalities: ModalityModel[];
  readingType: string;
  hideVisitChronologyType: string;
  batchManagementType = false;
  imageViewerConfiguration: any;
  imageViewerConfigurationType: any;
  bodyLocationToScore: string;
  primaryLocations: any[] = [];
  regionsToScore: any[] = [];
  dataSourceReaders: any;
  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];

  displayedScreenshotOptions: string [] = ['id', 'optionText', 'actionDelete'];
  // screenshotComments: ScreenshotComment [] = [];
  screenshotDataSource: any = [];
  minReaders = 1;
  maxReaders = 20;

  displayQuestionDetails: string [] = ['id', 'questionText', 'answerType', 'answeringOption1', 'answeringOption2', 'commentText', 'actionDelete'];

  dataSourceQuestion: any = [];
  readers: any[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  studyId: number;
  _configModel = {
    endpointReadingVersion: 'basic',
    modalities: [] as ModalityModel[],
    readingLevel: 'patient',
    readingType: 'parallel',
    hideVisitHistory: 'hide_scan_dates',
    bodyLocationToScore: 'hand',
    primaryLocations: [],
    regionsToScore: [],
    readers: [],
    readingVersion: ReadingVersion.DEMRIQ_BASIC,
    batchManagement: false,
    imageViewerConfigurationType: 'default',
    imageViewerConfiguration: {
      parametricMaps: [],
      motionCorrection: '',
      statisticalInformation: []
    },
    endpoint: null,
    anatomySelector: []
  };


  formValid = false;
  selectedReadersids = [];
  modalities: ModalityModel[];
  selectedReaders = [];
  selectedQuestionTemplate: string;
  visitReadingLevelModality: any = {};


  constructor(private router: Router,
              private route: ActivatedRoute,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private studySequenceLabelService: StudySequenceLabelService,
              private studyUserService: StudyUserService,
              private flowViewer: MatDialog,
              private dialog: MatDialog,
              private batchLogicService: BatchLogicService) {
    super();
  }

  ngOnInit() {
    this.formValid = false;
    this.selectedReaders = [];

    this.readingVersion = this._configModel.readingVersion;
    this.readingLevel = this._configModel.readingLevel;
    this.readingType = this._configModel.readingType;
    this.hideVisitChronologyType = this._configModel.hideVisitHistory;
    this.imageViewerConfigurationType = this._configModel.imageViewerConfigurationType;

    this._configModel.readers.forEach(ri => {
      this.selectedReadersids.push(ri['id']);
    });

    this.studyId = parseInt(this.route.snapshot.params.id, 10);

    this.prepareSequenceLabels();

    this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId).subscribe(respgroupedModalitiesResp => {
      if (respgroupedModalitiesResp.data != null) {
        this.modalities = respgroupedModalitiesResp.data;
        /*
          reading level visit modality string
        */
        if (this._configModel.readingLevel === 'visit') {
          if (this.visitReadingLevelModality.name === '') {
            this.modalities = this._configModel.modalities;
            this.setVisitModalityText(this.modalities);
          }
          this.modalities.unshift(this.visitReadingLevelModality);
          this.selectedModality = this.visitReadingLevelModality;
        } else if (this._configModel.readingLevel === 'modality') {
          this.selectedModalities = this.modalities.filter(m => this.configModel.modalities.find(cm => cm.name === m.name));
        }
        if (this.mode === FormMode.EDIT) {
          this.selectedModalities = respgroupedModalitiesResp.data.filter(x => this._configModel.modalities.find(m => m.name === x.name));
        }
      }

      this.studyUserService.getReadersByStudyId(this.studyId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.readers = response.data;
            const activeReader: any[] = [];
            this.readers.forEach(r => {

              if (this.selectedReadersids.includes(r.id)) {
                activeReader.push(r);
              }
            });

            this._configModel.readers = activeReader;
            this.dataSourceReaders = this._configModel.readers;
            this.selectedReaders = this._configModel.readers;
            this.selectedReadersids = [];
            this._configModel.readers.forEach(r => {
              const cur = this.readers.indexOf(r);
              this.readers.splice(cur, 1);
            });
          }
        });
    });
    if (this.selectedConfig) {
      this.batchManagementType = this.selectedConfig.config.batchManagement;
      this.loadBatchConfigs();
    }
  }

  loadBatchConfigs(): void {
    if (this.mode === FormMode.EDIT) {
      this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id).subscribe(response => {
        const batchConfigs = response.data;
        batchConfigs.forEach(c => {
          this.basicBatchConfig = c;
        });
      });
    }
  }

  prepareSequenceLabels() {
    this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId).subscribe(studySequenceResp => {
      this.studySequenceLabels = studySequenceResp.data;
      if (this.mode === FormMode.EDIT) {
        if (this._configModel.anatomySelector) {
          this.initSequenceLabels(this._configModel.anatomySelector);
        } else {
          this.initDefaultSequenceLabels();
        }
      }
    });
  }

  initDefaultSequenceLabels() {
    this.availableSequenceLabels = this.getAvailableSequenceLabels();
    this.selectedSequenceLabels = this.availableSequenceLabels;
    this.anatomySelectorControl.setValue(this.selectedSequenceLabels)
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels();
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  getAvailableSequenceLabels(): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const modality of this._configModel.modalities) {
      for (const label of modality.sequenceLabels) {
        configLabels.add(label);
      }
    }

    return this.studySequenceLabels
      .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this.validateForm();
  }

  onModalityTypeChange(event) {
    this._configModel.modalities = [];
    event.value.forEach(element => {

      this.selectedModality = element;
      this._configModel.modalities.push(this.selectedModality);
    });
    this.initDefaultSequenceLabels();
    this.validateForm();
  }

  openImageViewerConfiguration() {
    const value = this._configModel.imageViewerConfiguration;
    const dialogRef = this.dialog.open(ImageViewerConfigDialogComponent, {
      width: '500px',
      data: {
        reading: 'DEMRIQ',
        readonlyDialog: false,
        imageConfig: {
          parametricMaps: value.parametricMaps ? value.parametricMaps : this.imageViewerConfiguration?.parametricMaps,
          motionCorrection: value.motionCorrection ? value.motionCorrection : this.imageViewerConfiguration?.motionCorrection,
          statisticalInformation: value.statisticalInformation ? value.statisticalInformation : this.imageViewerConfiguration?.statisticalInformation
        }
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this._configModel.imageViewerConfiguration = result;
      }
      this.validateForm();
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  onBatchManagementSelected(event: MatSelectChange): void {
    if (this.basicBatchConfig) {
      this.basicBatchConfig.numberOfReadings = null;
      this.batchManagementField.updateValueAndValidity();
    }
    this.validateForm();
  }

  openAutoCreationBatchConfigDialog(): void {
    let inputConfig: AutoBatchConfigPayload;
    const val = this.batchManagementField.value;
    if (!val) {
      inputConfig = { ...defaultBasicAutoBatchConfig };
    } else {
      inputConfig = this.basicBatchConfig ? { ...this.basicBatchConfig } : null;
    }
    console.log(inputConfig);

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        reading: 'DEMRIQ',
        readingType: ReadingType.BASIC_READING,
        configInput: inputConfig,
        readonlyDialog: !val
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        this.basicBatchConfig = { ...result };
        this.batchManagementField.updateValueAndValidity();
        this.validateForm();
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  bodyLocationChange(): void {
    this.primaryLocations = [];
    this.regionsToScore = [];
    this._configModel.primaryLocations = [];
    this._configModel.regionsToScore = [];
    this.validateForm();
  }

  openLocationRegionConfigDialog(): void {
    const dialogRef = this.dialog.open(RegionLocationConfigDialogComponent, {
      data: {
        regionsToScore: this.regionsToScore,
        primaryLocations: this.primaryLocations
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        switch (result.type) {
          case 'primary_location':
            this.primaryLocations.push(result.title)
            break;
          case 'region':
            this.regionsToScore.push(result.title)
            break;
          default:
            break;
        }
      }
      this.validateForm();
    })
  }

  removeRegionLocation(type: string, index: number): void {
    switch (type) {
      case 'primary_location':
        this.primaryLocations.splice(index, 1);
        break;
      case 'region':
        this.regionsToScore.splice(index, 1);
        break;
      default:
        break;
    }
    this.validateForm();
  }

  maximumItems(): any[] {
    return this.regionsToScore.length > this.primaryLocations.length ? this.regionsToScore : this.primaryLocations;
  }

  onReadingLevelChange(event: { value: any; }) {
    this._configModel.readingLevel = event.value;

    if (this._configModel.readingLevel === 'visit') {
      this.setVisitModalityText(this.modalities);
      this.modalities.unshift(this.visitReadingLevelModality);
      this.selectedModality = this.visitReadingLevelModality;
    } else {
      if (this.modalities[0].id === -1) {
        this.modalities.shift();
      }
      this.selectedModality = null;
    }
    this.modalities = this.modalities.filter((modality) => modality.name === 'DCE-MRI' || modality.name === 'MRI');
    this.validateForm();
  }

  onImageConfigurationTypeChange(event: { value: any; }) {
    this._configModel.imageViewerConfigurationType = event.value;
    this.validateForm();
  }

  onReadingTypeChange(event: { value: any; }) {
    this.formValid = this._configModel.readingType !== event.value;
    this._configModel.readingType = event.value;
    this.validateForm();
  }

  onReaderSelectionChange(event: { value: any[]; }): void {
    this.selectedReadersids = event.value;
  }

  // this code must check and make sure the Reader is only added to the list if it is not already in it!
  onAddSelectedReaders(): void {

    if (this.selectedReadersids != null) {
      let counter = this._configModel.readers.length;
      this.selectedReadersids.forEach(readerId => {
        if (counter < this.maxReaders) {
          const reader = this.readers.find(r => r.id === readerId);
          if ((reader != null) && (!this._configModel.readers.includes(reader))) {
            this._configModel.readers.push(reader);
            const index = this.readers.indexOf(reader);
            this.readers.splice(index, 1);
            this.validateForm();
          }
          counter += 1;
        }
      });

      this.selectedReadersids = [];
    }
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
    this.selectedReaders = this._configModel.readers;
    if (this._configModel.readers.length > 0) {
      this.readersField.clearValidators();
    }
  }


  deleteReader(reader: any): void {
    const index = this._configModel.readers.indexOf(reader);
    this._configModel.readers.splice(index, 1);
    this.dataSourceReaders = new MatTableDataSource<any>(this._configModel.readers);
    this.selectedReadersids = this._configModel.readers;
    this.readers.unshift(reader);
    this.selectedReadersids = [];

    if (this._configModel.readers.length === 0) {
      this.readersField.validator = Validators.required;
    }
    if (this._configModel.readers.length > 0) {
      this.validateForm();
    } else {
      this.formValid = false;
    }

  }

  clickSubmitBtn(): void {
    const readers = [];
    this._configModel.readers.forEach(r => {
      readers.push({ id: r.id });
    });

    this._configModel.anatomySelector = this.selectedSequenceLabels.map(l => {
      return { id: l.id };
    });

    this._configModel.modalities = [];
    if (this.selectedModalities == null) {
      this._configModel.modalities = this.modalities;
    } else {
      this.modalities.forEach(m => {
        if (this.selectedModalities.find(x => x.id === m.id)) {
          this._configModel.modalities.push(m);
        }
      });
    }
    this._configModel.batchManagement = this.batchManagementType;
    this._configModel.hideVisitHistory = this.hideVisitChronologyType;
    this._configModel.bodyLocationToScore = this.bodyLocationToScore;
    this._configModel.primaryLocations = this.primaryLocations;
    this._configModel.regionsToScore = this.regionsToScore;
    this._configModel['endpointReadingVersion'] = 'basic';
    this._configModel.readers = readers;
    this._configModel.anatomySelector = this.selectedSequenceLabels;
    if (!this.basicBatchConfig) {
      this.basicBatchConfig = {
        batchCount: '1',
        endpoint: 'DEMRIQ',
        flexibleConfigId: null,
        id: null,
        initiationMethod: 'auto',
        initiationOption: 'by_quantity',
        numberOfReadings: 1,
        readingType: ReadingType.BASIC_READING,
        studyId: this.studyId
      };
    }
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: this._configModel,
      basicBatchConfig: this.basicBatchConfig
    };
    this.clickSubmit.emit(submitEvent);
  }

  clickCancelBtn(): void {
    this.clickCancel.emit({ result: 'cancel' });
  }

  validateForm(): void {
    let ans = this.formValid = true;

    if (this.regionsToScore.length === 0 || this.primaryLocations.length === 0)
      ans = false;

    if (this._configModel.readingLevel == null) {
      ans = false;
    }

    if (this._configModel.readingType == null) {
      ans = false;
    }

    if (this._configModel.readers.length < this.minReaders || this._configModel.readers.length > this.maxReaders) {
      ans = false;
    }

    if (this.anatomySelectorControl.invalid) {
      ans = false;
    }
    if (this.hideVisitChronologyField.invalid)
      ans = false;

    if (this.imageViewerConfigurationField.invalid)
      ans = false;

    if (this.batchManagementField.invalid)
      ans = false;

    this.formValid = ans;
  }

  setVisitModalityText(modalities): void {
    let mText = '';
    modalities.forEach(m => {
      mText += m.name + ', ';
    });

    this.visitReadingLevelModality.id = -1;
    this.visitReadingLevelModality.created = null;
    this.visitReadingLevelModality.name = mText.substr(0, mText.length - 2);
    this.visitReadingLevelModality.sequenceLabels = [];
  }

  public objectComparator = function (option, value): boolean {
    return option.id === value.id;
  };

}
