import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {ModalityModel} from '../_models/ImagingProject/modality-model';


const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class ModalityService {

  constructor(private http: HttpClient) { }

  create(modality: any) {
    return this.http.post(requestURL + `/modality`, modality);
  }

  update(modality: any) {
    return this.http.put(requestURL + `/modality`, modality);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/modality/` + id);
  }

  getById(id: number): Observable<BasicResponse<ModalityModel>> {
    return this.http.get<BasicResponse<ModalityModel>>(requestURL + `/modality/` + id);
  }

  getAll() {
    return this.http.get(requestURL + `/modality/retrieve-all`);
  }

  getByVisitConfig(visitConfigId: number): Observable<BasicResponse<any>> {
    const url = `${requestURL}/modality/visit-config-id/${visitConfigId}`;
    return this.http.get<BasicResponse<any>>(url);
  }
}
