import {Component, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-view-mode-selector',
  templateUrl: './audit-trails-view-mode-selector.component.html',
  styleUrls: ['./audit-trails-view-mode-selector.component.css']
})
export class AuditTrailsViewModeSelectorComponent implements OnInit {

  readonly ViewMode = ViewMode;
  viewModeControl = new FormControl(ViewMode.PATIENT);
  studyId: number;

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.studyId = parseInt(this.route.snapshot.params.projectId, 10);
  }
}

enum ViewMode {
  PATIENT = 'Patient',
  PROJECT = 'Project',
  SYSADMIN = 'Sysadmin'
}
