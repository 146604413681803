import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingGENANTVersion;
const SERVICE = 'msreadinggenant-dot-';
const BASE_URL = `https://${API_VERSION}${SERVICE}${API_URL}`;
// const BASE_URL = `http://localhost:8080/api`;


@Injectable({
  providedIn: 'root'
})
export class ReadingGenantService {

  constructor(private http: HttpClient) {
   }

   getNumberOfAvailableLevelOneReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/count-available-level-one/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getNumberOfAvailableAdjudicationReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/count-available-adjudication/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getAvailableLevelOneReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/level-one/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getAvailableAdjudicationReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/adjudication/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getReading(studyId, readingId) {
    const url = `${BASE_URL}/reading/${studyId}/${readingId}`;
    return this.http.get(url);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/start/${studyId}/${readingId}`;
    return this.http.put<BasicResponse<any>>(url, null);
  }

  updateReading(studyId, readingId, data: any): Observable<BasicResponse<any>>{
    const url = `${BASE_URL}/reading/${studyId}/${readingId}`;
    return this.http.put<BasicResponse<any>>(url, data);
  }

  lockTimepoint(studyId, visitId, model) {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/visit/${studyId}/${visitId}`, model)
  }

  updateTimepointStatus(studyId, readingId, visitConfigId, status) {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/visit/status/${studyId}/${readingId}/${visitConfigId}`, {
      status: status
    })
  }

}
