import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MessageDialogComponent} from '../message-dialog/message-dialog.component';
import {NgModel} from "@angular/forms";


@Component({
  selector: 'app-study-sequence-label',
  templateUrl: './study-sequence-label.component.html',
  styleUrls: ['./study-sequence-label.component.css']
})
export class StudySequenceLabelComponent implements OnInit {

  private _parameters: any;
  private _parametersTypes: any;
  private _parametersNames = [];

  @Input('parameters')
  set parameters(value: any) {
    if(value ==  null)
      value = {};
    this._parameters = value;
    this._parametersTypes = JSON.parse(JSON.stringify(this._parameters));

    this._parametersNames = Object.keys(this._parameters);
  }
  get parameters(): any {
    return this._parameters;
  }

  @Output('parametersChange')
  parametersEmitter: EventEmitter<object> = new EventEmitter<object>();


  @Input()
  public modeEdit;

  types = [
    {
      value: typeof 10,
      name: 'Number',
    },
    {
      value: typeof 'string',
      name: 'String',
    },
    {
      value: typeof [],
      name: 'Array'
    }
  ];

  propertName: string;
  propertyType: string;
  propertyValue: string;

  constructor(public dialog: MatDialog) { }

  ngOnInit() {
  }

  onFocusOut(value): void{
    try{
      value = JSON.parse(value);
    }
    catch(e){
    }
  }

  onValueChanged(event, parameters,prop) {

    if (typeof this._parametersTypes[prop] == 'number'){
      if(typeof this._parameters[prop] == 'string'){
        this._parameters[prop] = +this._parameters[prop];
      }
      if(typeof this._parameters[prop] == 'object'){

      }
    }
    if(typeof this._parametersTypes[prop] == 'string'){
      if (typeof this._parameters[prop] == 'number'){
        this._parameters[prop] = JSON.stringify(this._parameters[prop]);
      }
      if (typeof this._parameters[prop] == 'string'){
        this._parameters[prop] = this._parameters[prop].trim();
      }
      if(typeof this._parameters[prop] == 'object'){

      }
    }
    if(typeof this._parametersTypes[prop] == 'object'){
      if (typeof this._parameters[prop] == 'number'){
        this._parameters[prop] = JSON.parse("[" + this._parameters[prop] + "]");
      }
      if(typeof this._parameters[prop] == 'string'){
        this._parameters[prop] = JSON.parse("[" + this._parameters[prop] + "]");
      }
    }
  }


  getTypeName(prop){
    if(typeof this._parametersTypes[prop] == 'number')
      return 'Number';

    if(typeof this._parametersTypes[prop] == 'string')
      return 'String';

    if(typeof this._parametersTypes[prop] == 'object')
      return 'Array';

    return 'None';
  }

  isValueValid(prop){
    if(typeof this._parametersTypes[prop] == typeof this._parameters[prop])
      return true;
    return false;
  }

  onPropertyNameValueChanged(){
    if(this.propertName?.length > 0) {
      this.propertName = this.propertName.replace(/\s/g, "");
    }
  }

    createPropertyField(propName: NgModel, propType: NgModel, propValue: NgModel) {
        //clean all whitespaces
        propName.control.markAsDirty();
        propType.control.markAsDirty();
        propValue.control.markAsDirty();

        if (this.isFormValid()) {
            this.propertName = this.propertName.replace(/\s/g, "");

            try {
                if (this.propertyType == 'number') {
                    let n = +this.propertyValue;
                    if (typeof n == 'number') {
                        this._parametersTypes[this.propertName] = n;
                        this._parameters[this.propertName] = n;
                    }
                } else if (this.propertyType == 'string') {
                    this._parametersTypes[this.propertName] = this.propertyValue;
                    this._parameters[this.propertName] = this.propertyValue;
                } else if (this.propertyType == 'object') {
                    this._parametersTypes[this.propertName] = JSON.parse("[" + this.propertyValue + "]");
                    this._parameters[this.propertName] = JSON.parse("[" + this.propertyValue + "]");
                }
            } catch (e) {
                console.warn("Can't convert value: ", this.propertyValue);
            }

            this._parametersNames = Object.keys(this._parameters);
            this.parametersEmitter.emit(this._parameters);

            propName.control.reset();
            propType.control.reset();
            propValue.control.reset();
        }
    }

  deletePropertyField(prop){
    delete this._parameters[prop];
    delete this._parametersTypes[prop];
    this._parametersNames = Object.keys(this._parameters);
  }

  openDeleteDialog(prop): void {
    let message = "Do you want to delete property: " + prop ;

    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '200px',
      width: '600px',
      disableClose: true,
      data: {title:"Delete property", message: message, showOk:true, showCancel: true, html :false}
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result == 'ok'){
        this.deletePropertyField(prop);
      }
    });
  }

  isFormValid(): boolean {
      const nameValid = this.propertName != null && this.propertName.length > 0;
      const typeValid = this.propertyType != null;
      const valueValid = this.propertyValue != null && this.propertyValue.length > 0;
      return nameValid && typeValid && valueValid;
  }
}
