import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
export interface AnnotationSaveData {
  label: string;
  fillColor: string;
  outlineColor: string;
  outlineSize: number;
  opacity: number;
  selectionColor: string;
  selectionBoxSize: number;
  selectionOutlineColor: string;
  selectionOutilineSize: number;
  viewerComponent: any;
  child: any;
}

@Component({
  selector: 'app-roi-settings-dialog',
  templateUrl: './roi-settings-dialog.component.html',
  styleUrls: ['./roi-settings-dialog.component.css']
})
export class ROISettingsDialogComponent implements OnInit {
  public showAlert = false;
  public typeAlert = "success";
  public contAlert = "";

  constructor(private utils: Utils,
    public dialogRef: MatDialogRef<ROISettingsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AnnotationSaveData) { }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickOk(): void 
  {
    if(this.data.child != null)
    {
      // Apply settings to the selected ROI/VOI object
      let child: ltAnnotationsEngine.AnnObject = this.data.child;
      let viewerComponent = this.data.viewerComponent;
      (<any>child).isSettingsChanged = true;
      
      // Add fill color
      if (this.data.fillColor != null){
        let annBrush = lt.Annotations.Engine.AnnSolidColorBrush.create(this.data.fillColor);
        child.set_fill(annBrush);
      }
      // Add outline color and size
      let annStroke = lt.Annotations.Engine.AnnStroke.create(
        lt.Annotations.Engine.AnnSolidColorBrush.create(this.data.outlineColor),
        lt.LeadLengthD.create(this.data.outlineSize));
      child.set_stroke(annStroke);
      // Add opacity
      let opacity = this.data.opacity / 100;
      child.set_opacity(opacity);
      // Add selection outline color and size
      let annSelStroke = lt.Annotations.Engine.AnnStroke.create(
        lt.Annotations.Engine.AnnSolidColorBrush.create(this.data.selectionOutlineColor),
        lt.LeadLengthD.create(this.data.selectionOutilineSize));
      child.set_selectionStroke(annSelStroke);

      // Add TickMarkStroke and label color
      let info = {"annType": child.get_metadata()["AnnoType"]};
      if(this.utils.isAnnoRuler(info)){
        let tickMarkStroke = lt.Annotations.Engine.AnnStroke.create(
          lt.Annotations.Engine.AnnSolidColorBrush.create(this.data.outlineColor),
          lt.LeadLengthD.create(1));
        (<any>child).set_tickMarksStroke(tickMarkStroke);

        let label_solidColor = lt.Annotations.Engine.AnnSolidColorBrush.create(this.data.outlineColor);

        for(let key in child.get_labels()){
          let label = child.get_labels()[key];
          label.set_foreground(label_solidColor);
        }
      }

      viewerComponent.selection1.clear();
      // viewerComponent.selection1.select(this.data.row);
      // this.data.row.child = child;

      (<any>child).isSettingsChanged = null;

      // refresh viewer
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 30);
    }

    this.dialogRef.close();
  }

  triggerAlert(type, msg, timeout, close, data?){
    this.typeAlert = type;
    this.showAlert = true;
    this.contAlert = msg;
    if (type == "danger")
      console.log("Error found, data: ", data);
    setTimeout(() => {
      this.showAlert = false;
      if (close)
        this.dialogRef.close();
    }, timeout);
  }
}
