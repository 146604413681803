import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthenticationService, UserService} from '../../../_services';
import {ToastService} from '../../../_services/internal/toast.service';

import * as _moment from 'moment';

const moment = _moment;

@Component({
  selector: 'app-reset-password-page',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  token: string;
  loadingResetPassword = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private toastService: ToastService) { }

  ngOnInit() {
    this.token = this.route.snapshot.params.token;
    console.log('Token:', this.token);
  }

  resetPassword(): void {
    this.loadingResetPassword = true;
    this.userService.resetPasswordWithToken(this.token).subscribe(
      (response: any) => {
        this.loadingResetPassword = false;
        if (response.responseCode === 200) {
          this.toastService.success('Success', 'Your password reset successfully. Please check your mailbox for new password');
          
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 3000);
        } else {
          this.toastService.respError(response);
        }
      }, err => {
        this.loadingResetPassword = false;
        this.toastService.error("Failed", "Password reset failed");
      }
    );
  }

}
