import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ImagingProjectService} from '../../../_services';
import {Store} from '@ngxs/store';
import {StudyModel} from '../../../_models/ImagingProject/study-model';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {AuthInfoState} from '../../../core/data-management/states/auth-info.state';
import {DashboardMode, RoleType} from '../operation-dashboard-component/operation-dashboard.component';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.css']
})
export class ProjectDetailsComponent implements OnInit {

  readonly DashboardMode = DashboardMode;
  projectId: number;
  study: StudyModel;
  dashboardMode: DashboardMode;

  constructor(private route: ActivatedRoute,
              private imagingProjectService: ImagingProjectService,
              private store: Store) {
  }

  ngOnInit() {
    this.projectId = this.route.snapshot.params.projectId;
    this.initDashboardMode();
    this.imagingProjectService.getStudy(this.projectId).subscribe(studyResult => {
      this.study = studyResult.data;
      this.store.dispatch(new SetPageHeaderTitle(`Details: ${this.study.name}`));
    });
  }

  private initDashboardMode(): void {
    this.store.select(AuthInfoState.getAuthInfo).subscribe(info => {
      const roleTypes = info.roles.map(role => role.roleType);
      if (!!roleTypes.find(roleType => roleType === RoleType.DASHBOARD)) {
        this.dashboardMode = DashboardMode.PM;
      } else if (!!roleTypes.find(roleType => roleType === RoleType.SPONSOR)) {
        this.dashboardMode = DashboardMode.SPONSOR;
      }
    });
  }

}
