import {AlphabetEncoder} from "./AlphabetEncoder";
import {PERMISSIONS} from "./permissions";

type JwtActivity = { projectId: number, activities: string[] }
type JwtActivities = { globals: string[], activities: JwtActivity };
export type DecodedJwt = { permissions: JwtActivities, jti, sub, iss };

export class CompactJwt {

  static MUST_USE_SEMI_COLON = Object.keys(PERMISSIONS).length > AlphabetEncoder.ALPHABET.length;

  public static decodeActivities(jwt: any): DecodedJwt {
    if (!jwt || jwt.hasOwnProperty('permissions') || !jwt.hasOwnProperty('prm'))
      return jwt

    type CompactJwt = { prm: { glb: string, perms: string } };

    const globals = this.decodeGlobal(jwt.prm.glb)
    const activities = this.decodeProjectActivities(jwt.prm.perms)
    jwt.permissions = {globals, activities}

    return jwt
  }

  private static decodeGlobal(globals: string): string[] {
    if (!globals)
      return []

    return globals.split(';')
      .flatMap(it => this.MUST_USE_SEMI_COLON || it.indexOf('.') >= 0 ? [it] : it.split(""))
      .map(it => this.decodeActivity(it))
  }

  private static decodeActivity(encoded: string): string {
    const codes = encoded.indexOf('.') >= 0 ? encoded.split(".") : encoded.split('')

    return codes.map(it => PERMISSIONS[it] || it).join('.')
  }

  private static decodeProjectActivities(activities: string): JwtActivity[] {
    if (!activities)
      return []

    return activities.split('|')
      .map(it => this.decodePIdAndActivity(it))
  }

  private static decodePIdAndActivity(encoded: string): JwtActivity {
    const split = encoded.split("~")
    const projectId = AlphabetEncoder.decode(split[0])
    if (split.length == 1)
      return {projectId, activities: []}

    const activities = split[1].split(';')
      .flatMap(it => this.MUST_USE_SEMI_COLON || it.indexOf('.') >= 0 ? [it] : it.split(""))
      .map(it => this.decodeActivity(it))
    return {projectId, activities}
  }
}
