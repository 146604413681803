import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

export interface MessageDialogData {
  title: string;
  message: string;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent implements OnInit {

  title: string;
  message: string;
  showOk = true;
  showCancel = false;

  textOk = 'OK';
  textCancel = 'Cancel';

  html = false;
  htmlMessage = null;
  minimized: boolean = false;
  isDraggable: boolean = false;

  constructor(public dialogRef: MatDialogRef<MessageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MessageDialogData,
              private dom: DomSanitizer) {
    this.title = data['title'];
    this.message = data['message'];

    if(data['showOk'] != null)
      this.showOk = data['showOk'];
    if(data['showCancel'] != null)
      this.showCancel = data['showCancel'];
    if(data['textOk'] != null)
      this.textOk = data['textOk'];
    if(data['textCancel'] != null)
      this.textCancel = data['textCancel'];

    if(data['html'] == true){
      this.html = true;
      this.htmlMessage = this.dom.sanitize(SecurityContext.HTML, this.message);
    }
    if (data['isDraggable'] == true)
      this.isDraggable = true;
  }

  ngOnInit() {
   
  }
  minimizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.add('dialog-minimized')
    let top = ((dialog[0] as HTMLElement).offsetTop + 60) + 'px';
    document.documentElement.style.setProperty('--message-dialog-display', "translate3d(0 , calc(100vh - " + top + ") , 0)");
    this.minimized = true;
  }
  maximizeDialog() {
    let dialog = document.getElementsByClassName('cdk-overlay-pane');
    dialog[0].classList.remove('dialog-minimized')
    document.documentElement.style.setProperty('--message-dialog-display', "");
    this.minimized = false;
  }
  onClickCancel(): void{
    this.dialogRef.close('cancel');
  }

  onClickOk(): void {
    this.dialogRef.close('ok');
  }
}
