import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-calibrate-ruler-dialog',
  templateUrl: './calibrate-ruler-dialog.component.html',
  styleUrls: ['./calibrate-ruler-dialog.component.css']
})
export class CalibrateRulerDialogComponent implements OnInit {
  calibration: any;

  constructor(public dialogRef: MatDialogRef<CalibrateRulerDialogComponent>) {
    this.calibration = {};
    this.calibration.length = null;
    this.calibration.unit = 6;
    this.calibration.applyToAll = false;
  }

  ngOnInit(): void {
  }

  isValid() {
    return this.calibration.length != null && this.calibration.length.length > 0;
  }

  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickOk(): void {
    this.dialogRef.close(this.calibration);
  }

}
