import {Component, OnInit, ViewChild} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import {ImagingProjectService} from '../../../../_services/imaging-project.service';
import {QualityControlService} from '../../../../_services/quality-control.service';
import {DataUploadService} from '../../../../_services/data-upload.service';
import {UserService} from '../../../../_services/user.service';
import { SiteConfigService } from '../../../../_services/site-config.service';
import { PatientService } from '../../../../_services/patient.service';
import { QueriesService } from '../../../../_services/queries.service';

import * as _moment from 'moment';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
import {formatDate} from "@angular/common";

const moment = _moment;

export interface  PeriodicElement {
  id: number;
  uploadDate: number;
  expectedVisitDate: number;
  series: any;
  visit: any;
}

@Component({
  selector: 'app-data-upload-list',
  templateUrl: './qctask-list.component.html',
  styleUrls: ['./qctask-list.component.css']
})
export class WebinarQCTaskListComponent implements OnInit {
  currentImageProjectId: number;
  dataSource: any;
  detailedData = [];
  displayedColumns: string[] = ['id', 'status', 'siteCode', 'patientCode', 'visitName', 'scanDate', 'uploadDate', 'qcDate' ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  showModalSpinnerLoadingData = false;

  constructor(
    private imagingProjectSerivce: ImagingProjectService,
    private qualityControlService: QualityControlService,
    private dataUploadService: DataUploadService,
    private userService: UserService,
    private siteConfigService: SiteConfigService,
    private patientService: PatientService,
    private queriesService: QueriesService,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store
  ) { }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Pending QCs'));
    const project = JSON.parse(localStorage.getItem('project'));
    this.currentImageProjectId = project.id;

    this.detailedData = [];

    this.showModalSpinnerLoadingData = true;
    this.qualityControlService.getQCUploadTaskList(this.currentImageProjectId).subscribe( success => {
      // get QC tasks
      if (success['data'] != null) {
        const listQC = success['data'];
        let usersIds = [];
        let visitConfigs = [];
        this.dataUploadService.getUploadGeneralInfo(this.currentImageProjectId).subscribe(uploads => {
          // insert msupload data in qc and create table data
          if (uploads['data'] != null) {

            this.queriesService.getQueriesByStudyId(this.currentImageProjectId).subscribe( queries => {
              const queriesData = queries['data'];
              listQC.forEach(qcItem => {
                if (qcItem.qcUserId != null) {
                  usersIds.push(qcItem.qcUserId);
                }
                visitConfigs.push(qcItem.visitConfigId);
              });

              usersIds = Array.from(new Set(usersIds));
              visitConfigs = Array.from(new Set(visitConfigs));

              this.imagingProjectSerivce.getScanDatesByVisitConfigIds(visitConfigs).subscribe(scanDatesRes => {
                const scanDates = scanDatesRes['data'];
                this.userService.getByListIds(usersIds).subscribe(usersInfoRes => {
                  let userslist = null;
                  userslist = usersInfoRes['data'];
                  if (userslist == null) {
                    userslist = [];
                  }

                  listQC.forEach(qcItem => {
                    const generalInfo = uploads['data'].filter(uploadItem => uploadItem.id === qcItem.generalInfoId);
                    const userInfo = userslist.filter(user => user.id === qcItem.qcUserId)[0];
                    const queriesEdcf = queriesData.filter(q => q.qcTaskId == qcItem.id)[0];

                    generalInfo.forEach(ginfo => {

                      const qct = {
                        id: qcItem.id,
                        numberInStudy: qcItem.numberInStudy,
                        generalInfoId: ginfo.id,
                        imageAnalysisExecutionId: qcItem.imageAnalysisExecutionId,
                        status: qcItem.status,
                        studyId: ginfo.imageProjectId,
                        studyName: null,
                        siteConfigId: ginfo.siteConfigId,
                        siteId: null,
                        siteCode: null,
                        patientId: ginfo.patientId,
                        patientCode: null,
                        visitConfigId: ginfo.visitConfigId,
                        visitName: null,
                        modalities: qcItem.modality,
                        uploadDate: moment(ginfo.created).format('DD/MM/YYYY HH:mm:ss'),
                        scanDate: null,
                        qcUserId: qcItem.qcUserId,
                        qcUserFullName: '',
                        qcDate: '',
                        queriesId: null,
                      };

                      if (qct.status == 'done') {
                        if (qcItem.qcDate != null) {
                          qct.qcDate = moment(qcItem.qcDate).format('DD/MM/YYYY');
                        }
                      }
                      if (userInfo != null) {
                        qct.qcUserFullName = userInfo.firstName + ' ' + userInfo.lastName;
                      }
                      if (queriesEdcf != null) {
                        qct.queriesId = queriesEdcf.id;
                      }
                      if (scanDates[qct.visitConfigId] != null) {
                        qct.scanDate = formatDate(scanDates[qct.visitConfigId], 'dd/MM/yyyy HH:mm:ss', 'en-GB', 'UTC');
                      }

                      this.detailedData.push(qct);
                    });
                  });

                  // insert siteCode and patientCode in every row of the table data
                  this.imagingProjectSerivce.getStudy(this.currentImageProjectId).subscribe(imageProjectResponse => {
                    this.siteConfigService.getSiteConfigsByStudyId(this.currentImageProjectId).subscribe(siteConfigResponse => {
                      this.patientService.getPatientsByStudyId(this.currentImageProjectId).subscribe(patientsResponse => {
                        if (imageProjectResponse['data'] != null && siteConfigResponse['data'] != null && patientsResponse['data'] != null) {
                          const patients = patientsResponse['data'];
                          this.detailedData.forEach(dataRow => {
                            const site = siteConfigResponse['data'].filter(csite => csite.id == dataRow.siteConfigId)[0];
                            if (site != null) {
                              dataRow.studyName = imageProjectResponse['data']['name'];
                              dataRow.siteCode = site.siteCode;
                              dataRow.siteId = site.site.id;
                              const patient = patients.filter(pat => pat.id == dataRow.patientId)[0];
                              const cvisit = patient.visitConfigs.filter(spvisitConfig => spvisitConfig.id == dataRow.visitConfigId)[0];

                              dataRow.patientCode = patient.patientCode;
                              dataRow.visitName = cvisit.visit.name;
                            }

                          });
                        }
                        this.setTableData();
                      });
                    });
                  });
                });
              });
            });
          }
        });
      }
    });
  }

  setTableData() {
    this.dataSource = new MatTableDataSource<PeriodicElement>(this.detailedData);

    if (this.dataSource != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    this.showModalSpinnerLoadingData = false;
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  clickTableCell(element) {
    localStorage.setItem('qc.selectTask', JSON.stringify(element));
    this.router.navigate(['/webinar/qc/details']);
  }

  clickTableCellEdcf(element) {
    if (element.queriesId != null) {
      const edcf = {
        id: element.queriesId,
      };
      localStorage.setItem('query.edcf', JSON.stringify(edcf));
      localStorage.setItem('query.edcf.fromQcTaskList', JSON.stringify(true));
      this.router.navigate(['/queries/edcf/detail']);
    }
  }
}
