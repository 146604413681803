import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {WbmriImgCompareDialogComponent, WbmriImgCompareItemData} from './wbmri-img-compare-dialog/wbmri-img-compare-dialog.component';
import {MatTableDataSource} from '@angular/material/table';
import {ResourceService} from '../../../../_services/resource.service';
import {WbmriReadingModel} from '../../../../_models/WBMRI/wbmri-reading-model';
import {WbmriScoringTotalModel} from '../../../../_models/WBMRI/wbmri-scoring-total-model';
import {ReadingConfigFlexibleService, ReadingWbmriService} from '../../../../_services';
import {ResponseCode} from '../../../../core/constants/response-code';
import {forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WbmriAdjudicationConfigModel} from '../../../../_models/ImagingProject/WBMRI/wbmri-adjudication-config-model';
import {PsamrisRuleDelta, PsamrisScoringTotalModel} from "../../../../_models";
import {Router} from "@angular/router";
import {EXTERNAL_ADJ_RULE} from '../../../ImagingProject';

const COLOR_RED = 'red';
const COLOR_BLACK = 'black';

@Component({
  selector: 'app-wbmri-level1-result',
  templateUrl: './wbmri-level1-result.component.html',
  styleUrls: ['./wbmri-level1-result.component.css']
})
export class WbmriLevel1ResultComponent implements OnInit, OnChanges {

  @Input() adjReading: WbmriReadingModel;
    readonly EXTERNAL_ADJ_RULE = EXTERNAL_ADJ_RULE;

  studyId: number;
  flexibleConfig: WbmriAdjudicationConfigModel;

  scoringColumns: string[] = [
    'visit',
    'pii',
    'pai',
    'pei'
  ];

  adjudicationScoringColumns: string[] = [
    'visit',
    'pii',
    'pai',
    'pei'
  ];

  firstReaderDataSource = new MatTableDataSource<WbmriScoringTotalModel>();
  secondReaderDataSource = new MatTableDataSource<WbmriScoringTotalModel>();
  disagreementDataSource = new MatTableDataSource<WbmriScoringTotalModel>();

  constructor(private dialog: MatDialog,
              private resourceService: ResourceService,
              private readingService: ReadingWbmriService,
              private readingConfigService: ReadingConfigFlexibleService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.studyId = +JSON.parse(localStorage.getItem('project')).id;
    this.initAdjudicationReadingData(this.adjReading);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initAdjudicationReadingData(this.adjReading);
  }

    initAdjudicationReadingData(reading: WbmriReadingModel) {
        this.readingConfigService.getById(reading.flexibleConfigId).subscribe(value => {
            this.flexibleConfig = value.data.config;
            if (this.adjReading.level1Readings.length === 2) {
                if (!this.flexibleConfig.adjudicationBlind) {
                    // scorings 1
                    this.populateReadingScoringsTable(this.adjReading.level1Readings[0], this.firstReaderDataSource);
                    // scorings 2
                    this.populateReadingScoringsTable(this.adjReading.level1Readings[1], this.secondReaderDataSource);
                }
                // disagreement
                if (this.adjReading?.disagreement && this.flexibleConfig?.adjudicationRule !== this.EXTERNAL_ADJ_RULE) {
                    this.readingService.getDisagreement(this.studyId, reading.id).subscribe(resp => {
                        if (resp.responseCode === ResponseCode.OK && resp.data) {
                            this.populateDifferenceTable(resp.data.difference, resp.data.rule, this.disagreementDataSource);
                        }
                    });
                }
            }
        });
    }

    private populateReadingScoringsTable(reading: WbmriReadingModel, dataSource: MatTableDataSource<WbmriScoringTotalModel>) {
        const scorings = new Array<WbmriScoringTotalModel>();
        for (const visit of reading.visits) {
            const scoring: WbmriScoringTotalModel = {
                timepoint: visit.timepoint,
                all_ebme_sti: 'n/a',
                all_jbme_syn: 'n/a',
                all_total_sum: 'n/a'
            };

            if (!visit.noUploads) {
                scoring.timepoint = visit.timepoint;
                scoring.all_ebme_sti = visit.scoring.all_ebme_sti;
                scoring.all_jbme_syn = visit.scoring.all_jbme_syn;
                scoring.all_total_sum = visit.scoring.all_total_sum;
            }

            scorings.push(scoring);
        }
        dataSource.data = scorings;
    }

  private populateDifferenceTable(difference: [], rule, table: MatTableDataSource<WbmriScoringTotalModel>) {
    const differenceMap = new Map(Object.entries(difference));
    table.data = Array.from(differenceMap.entries()).map(entry => {
      return {
        timepoint: entry[0],
        all_ebme_sti: this.buildDifferenceTableNode(entry[1]['all_ebme_sti'], rule.all_ebme_sti),
        all_jbme_syn: this.buildDifferenceTableNode(entry[1]['all_jbme_syn'], rule.all_jbme_syn),
        all_total_sum: this.buildDifferenceTableNode(entry[1]['all_total_sum'], rule.all_total_sum),
      };
    }) as any[];
  }

  private buildDifferenceTableNode(data, rule) {
    return {
      value: data['delta'] ?? 'NA',
      disagreement:
        this.hasDisagreement(rule, data['sameDirection'], data['delta']),
      sameDirection: data['sameDirection']
    };
  }

  private populateRangeTable(range: any[], table: MatTableDataSource<WbmriScoringTotalModel>) {
    const rangeMap = new Map(Object.entries(range));
    table.data = Array.from(rangeMap.entries()).map(entry => {
      return {
        timepoint: entry[0],
        all_ebme_sti: entry[1]['all_ebme_sti'],
        all_jbme_syn: entry[1]['all_jbme_syn'],
        all_total_sum: entry[1]['all_total_sum']
      } as WbmriScoringTotalModel;
    });
  }

  hasDisagreement(rule: number, sameDirection: boolean, delta: number): boolean {
    if (sameDirection) {
      rule *= 2;
    }
    return rule <= delta;
  }

  onCsvReport(readingNumber: number) {
    const reading = this.adjReading.level1Readings[readingNumber - 1];
    this.readingService.getReadingCsv(this.studyId, reading.id).subscribe(resp => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(resp.body);
      a.href = objectUrl;
      a.download = resp.headers.get('Content-Disposition').split('; ')[1].replace('filename=', '');
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  onImageComparisonClick() {
    const lvl1 = this.adjReading.level1Readings;
    this.getZiteLabImages(lvl1).subscribe(readingsImages => {
      const factors = new Set([].concat(...readingsImages).map(i => i.factor));
      const timepoints = new Set(this.adjReading.visits.map(v => v.timepoint));

      const data: WbmriImgCompareItemData[] = [];

      for (const timepoint of timepoints) {
        for (const factor of factors) {
          const dataItem: WbmriImgCompareItemData = {
            timepoint,
            factor,
            image1: readingsImages[0].find(i => i.factor === factor && i.timepoint === timepoint).storageLink,
            image2: readingsImages[1].find(i => i.factor === factor && i.timepoint === timepoint).storageLink
          };
          data.push(dataItem);
        }
      }

      const url = this.router.url.replace('reading/wbmri/adjudication', 'wbmri/reading-image-comparison');
      localStorage.setItem('reading_wbmri_data', JSON.stringify(data));
      window.open(url, '_blank');

      // const dialogRef = this.dialog.open(WbmriImgCompareDialogComponent, {
      //   width: '1300px',
      //   data
      // });
    });
  }

  private getZiteLabImages(readings: WbmriReadingModel[]): Observable<any[]> {
    const imagesObservables = readings.map(reading => this.readingService.getZitelabImages(reading.studyId, reading.id));
    return forkJoin(imagesObservables).pipe(map((resps => {
      if (!resps.find(resp => resp.responseCode !== ResponseCode.OK)) {
        return resps.map(resp => resp.data);
      }
      const errorMsg = resps.map(resp => `${resp.responseCode}: ${resp.responseMessage}`).join(';');
      throw new Error(errorMsg);
    })));
  }
}
