import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import * as _ from 'lodash';
import {SequenceLabelModel} from '../../../_models/ImagingProject/sequence-label-model';

@Component({
  selector: 'app-series-navigator',
  templateUrl: './series-navigator.component.html',
  styleUrls: ['./series-navigator.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SeriesNavigatorComponent implements OnInit, OnChanges {

  @Input() series: any[];
  @Input() currentReading: any;
  @Input() visitConfig: number;
  @Input() availableSequenceLabels: SequenceLabelModel[];
  @Input() defaultSequenceLabelIds: number[];
  @Input() selectorDisabled = false;
  @Input() vertical: boolean = false;
  visitedSeriesCounter: number;
  @Input() openedViewers: number;
  @Output() public onOpenViewer: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onCloseAllViewers: EventEmitter<any> = new EventEmitter<any>();
  selectedSequenceLabels: SequenceLabelModel[] = [];
  filteredSeries: any[] = [];
  blockIndex = 0;
  blockSize = 1;
  @Output() public isOpenImages: EventEmitter<any> = new EventEmitter<any>();
  @Output('onVisitedSeriesCounter')
  public onVisitedSeriesCounter: EventEmitter<any> = new EventEmitter<any>();

  onClickOpenViwer = _.debounce((series) => {
    this.openViewer([series.id]);
    this.markSeriesAsOpened(series);

  }, 300);

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.availableSequenceLabels?.currentValue && !!changes.defaultSequenceLabelIds?.currentValue) {
      if (!!this.availableSequenceLabels && !!this.defaultSequenceLabelIds && !!this.series && !this.selectorDisabled) {
        this.applyDefaultSequenceLabels();
      }
      if (!!this.availableSequenceLabels && this.selectorDisabled) {
        this.defaultSequenceLabelIds = this.availableSequenceLabels.map(s => s.id);
        this.applyDefaultSequenceLabels();
      }
    }
    this.filterSeries();
    this.setSeriesColors();
  }

  applyDefaultSequenceLabels(): void {
    this.selectedSequenceLabels = this.availableSequenceLabels
      .filter(studyLabel => !!this.defaultSequenceLabelIds.find(id => id === studyLabel.id));
  }

  filterSeries(): void {
    this.filteredSeries = this.series
      .filter(s => this.selectedSequenceLabels.find(l => l.name.toLowerCase().trim() === s.label.toLowerCase().trim()));
  }

  getSeriesSelectionColor(series) {
    if (series['openInViewerNow'] === true) {
      return '#f9bf91';
    } else if (series['openedInViewer'] === true) {
      return '#c1c1c1';
    }
    // default color
    return 'efefef';
  }

  getSeriesColor(series): string {
    try {
      return series.color ? series.color : '#000000';
    } catch (error) {
      console.log("==> error... ", error);
    }
  }

  setSeriesColors() {
    if (this.filteredSeries.length > 0 && !this.filteredSeries[0].color) {
      let colors = ['MediumVioletRed', 'OrangeRed', 'ForestGreen',
        'DarkTurquoise', 'Brown', 'DodgerBlue','OliveDrab', 'Navy', 'LimeGreen', 'SlateBlue'];
      const sort = Math.random() - 0.5;
      colors = colors.sort(() => sort);
      const visits = [];
      let visitColor = {};
      let counter = 0;
      for (let i = 0; i < this.filteredSeries.length; i++) {
        let t = this.filteredSeries[i].timepoint;
        if(t == undefined){
          t = this.filteredSeries[i].seriesVisitName;
        }
        if(t !== undefined){
          if (!visits.includes(t)) {
            visits.push(t);
            visitColor[t] = colors[counter];
            this.filteredSeries[i].color = visitColor[t]
            counter++;
            if (counter > colors.length) {
              counter = 0;
            }
          } else {
            this.filteredSeries[i].color = visitColor[t];
          }
        }
      }
    }
  }

  isSeriesFirst(seriesIndex) {
    return seriesIndex === this.blockIndex;
  }

  isSeriesMiddle(seriesIndex) {
    return ((seriesIndex > this.blockIndex) && (seriesIndex < this.blockIndex + this.blockSize - 1));
  }

  isSeriesLast(seriesIndex) {
    return (seriesIndex === this.blockIndex + this.blockSize - 1) ||
      (this.blockIndex + this.blockSize - 1 >= this.series.length && seriesIndex === this.series.length - 1);
  }

  isSeriesFirstAndLast(seriesIndex) {
    return (seriesIndex === this.series.length - 1 && seriesIndex === this.blockIndex);
  }

  onPreviousFrame() {
    this.blockIndex = this.blockIndex - this.blockSize;
    if (this.blockIndex < 0) {
      this.blockIndex = 0;
    }
    this.onCloseAllViewers.emit();
    this.openBlockSeries();
  }

  onNextFrame() {
    this.blockIndex = this.blockIndex + this.blockSize;
    if (this.blockIndex >= this.series.length) {
      this.blockIndex = this.series.length - this.blockSize;
    }
    this.openBlockSeries();
  }

  onApplyFrame() {
    this.resetIndex();
    this.closeAllViwers();
    this.openBlockSeries();
  }

  onFrameLengthChange(): void {
    if (this.blockSize > 9 || this.blockSize > this.series.length) {
      if (this.series.length >= 9) {
        this.blockSize = 9;
      } else {
        this.blockSize = this.series.length;
      }
    }
    if (this.blockSize < 1) {
      this.blockSize = 1;
    }
  }

  closeAllViwers() {
    if (this.onCloseAllViewers != null) {
      this.onCloseAllViewers.emit();
    }
  }

  openViewer(seriesIds) {
    if (this.onOpenViewer != null) {
      this.onOpenViewer.emit(seriesIds);
    }
  }

  openBlockSeries() {
    const seriesIds = [];
    if (this.filteredSeries != null) {
      this.onCloseAllViewers.emit();
      this.filteredSeries.forEach((element, index) => {
        this.unmarkSeriesAsOpened(element);

        if ((index >= this.blockIndex) && (index < (this.blockIndex + this.blockSize))) {
          this.markSeriesAsOpened(element);
          seriesIds.push(element.id);
        }
      });
    }
    this.openViewer(seriesIds)
  }

  markSeriesAsOpened(series) {
    if (this.openedViewers < 9) {
      series['openInViewerNow'] = true;
      series['openedInViewer'] = true;
    }
    this.validateIsOpenImages();
  }

  unmarkSeriesAsOpened(series) {
    if (series != null) {
      series['openInViewerNow'] = false;
    }
  }

  unmarkSeriesAsOpenedBySeriesId(seriesId) {
    const selectedSeries = this.series.find(s => s.id === seriesId);
    if (selectedSeries != null) {
      selectedSeries['openInViewerNow'] = false;
    }
    this.validateIsOpenImages();
  }

  validateIsOpenImages() {
    let ans = false;
    this.visitedSeriesCounter = 0;
    if (this.series != null) {
      this.series.forEach((element, index) => {
        if (element['openInViewerNow'] === true || element['openedInViewer'] === true) {
          ans = true;
          this.visitedSeriesCounter = this.visitedSeriesCounter + 1;
        }

      });
    }
    this.onVisitedSeriesCounter.emit(this.visitedSeriesCounter);
    this.isOpenImages.emit(ans);
  }

  resetIndex() {
    this.blockIndex = 0;
  }

  dragstart(ev, seriesId, seriesInstanceUID?){
    // console.log("dragstart...");
    // console.log("seriesId:", seriesId);
    // console.log("seriesInstanceUID:", seriesInstanceUID);
    ev.dataTransfer.setData("seriesId", seriesId);
    ev.dataTransfer.setData("seriesInstanceUID", seriesInstanceUID);
  }

  trackByFn(index: number): number {
    return index;
  }

}
