import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-region-location-config-dialog',
  templateUrl: './region-location-config-dialog.component.html',
  styleUrls: ['./region-location-config-dialog.component.css']
})
export class RegionLocationConfigDialogComponent implements OnInit {

  regionLocationForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<RegionLocationConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { regionsToScore: any[], primaryLocations: any[]}) { }

  ngOnInit(): void {
    this.regionLocationForm = new FormGroup({
      type: new FormControl('primary_location', [Validators.required]),
      title: new FormControl(null, [Validators.required]),
    })
  }

  maximumLimitation() {
    if (this.regionLocationForm.get('type').value === 'primary_location'
      && this.data.primaryLocations?.length === 6)
      return '6 primary locations'
    if (this.regionLocationForm.get('type').value === 'region'
      && this.data.regionsToScore?.length === 5)
      return '5 regions'
    return;
  }

  OnSave() {
    this.dialogRef.close(this.regionLocationForm.value);
  }

  closeClick() {
    this.dialogRef.close();
  }

}
