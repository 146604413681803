import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormMode} from '../../../../../core/constants/form-mode';
import {FormControl, Validators} from '@angular/forms';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {MatDialog} from '@angular/material/dialog';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
  selector: 'app-imaging-project-reading-recist-wrapper',
  templateUrl: './imaging-project-reading-recist-wrapper.component.html',
  styleUrls: ['./imaging-project-reading-recist-wrapper.component.css']
})
export class ImagingProjectReadingRecistWrapperComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  @Input() public mode: FormMode;
  @Input() selectedConfig: any;
  @Input() visitsQuantity: number;
  @Input() unscheduledAllowed: boolean;
  @Output() public clickCancel = new EventEmitter<any>();
  @Output() public clickSubmit = new EventEmitter<any>();
  versionFormControl = new FormControl(ReadingVersion.LUGANO_BASIC, [Validators.required]);
  readonly readingVersions = ReadingVersion;
  readonly formModes = FormMode;
  validationMessages: string[];

  constructor(private flowViewer: MatDialog) {
    super();
  }

  ngOnInit(): void {
  }

  handleCancelClick(event) {
    this.clickCancel.emit(event);
  }

  handleSubmitClick(event) {
    this.clickSubmit.emit(event);
  }

  isPreconditionsValid(readingVersion: ReadingVersion) {
    let isValid = true;
    this.validationMessages = [];

    console.log('--- isPreconditionsValid');
    console.log(readingVersion);

    if (this.versionFormControl.value === readingVersion) {
      // if (this.visitsQuantity !== 2) {
      //  this.validationMessages.push(`RECIST 1.1 for two visits can be performed only on two visits, current quantity of visits are - ${this.visitsQuantity || 0}`);
      // }
      // if (this.unscheduledAllowed) {
      //  this.validationMessages.push('Disable unscheduled visit option, it makes site users adding more than two visits. This version requires two visits for a Patient');
      // }
      if (this.validationMessages.length) {
        isValid = false;
      }
    }
    return isValid;
  }

}

export interface Reader {
  id: number;
  firstName: string;
  lastName: string;
}
