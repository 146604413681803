import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {FormMode} from '../../../../../core/constants/form-mode';
import {EndpointModel} from '../../../../../_models/ImagingProject/endpoint-model';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {MatDialog} from '@angular/material/dialog';
import {ImagingProjectKLFlowDialogComponent} from '../../KandL/imaging-project-klflow-dialog/imaging-project-klflow-dialog.component';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
  selector: 'app-imaging-project-reading-jsw-versrion-selector',
  templateUrl: './imaging-project-reading-jsw-versrion-selector.component.html',
  styleUrls: ['./imaging-project-reading-jsw-versrion-selector.component.css']
})
export class ImagingProjectReadingJswVersrionSelectorComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  @Output()
  public clickCancel = new EventEmitter<any>();
  @Output()
  public clickSubmit = new EventEmitter<any>();

  @Input() public mode: FormMode;
  @Input() public endpoints: EndpointModel[];
  @Input() public selectedConfig;

  jswVersionField = new FormControl('', Validators.required);

  readingVersion;
  _configModel;
  readonly readingVersions = ReadingVersion;

  readonly formModes = FormMode;
  _configModelBasic = {
    readingVersion: null,
    readingLevel: null,
    readingType: null,
    modalities: [],
    readers: [],
  };

  @Input('configModel')
  set configModel(value: any) {
    if (!!value) {
      this.readingVersion = value.readingVersion;
    }
    this._configModel = value;
  }

  get configModel(): any {
    return this._configModel;
  }

  constructor(public dialogFlow: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.initReadingVersionField();
  }

  initReadingVersionField() {
    if (this.mode === FormMode.NEW) {
      this.jswVersionField.setValue(this.readingVersions.JSW_BASIC);
    } else if (this.mode === FormMode.EDIT) {
      this.jswVersionField.setValue(this.readingVersion);
    }
    this.jswVersionField.updateValueAndValidity();
  }

  onVersionChange(event) {
    this.readingVersion = event.value;
    if (event.value === this.readingVersions.JSW_BASIC || event.value === this.readingVersions.JSW_3Visits) {
      this._configModel = this._configModelBasic;
      this._configModel['readingVersion'] = event.value;
    }
  }

  onSubmitEvent(event) {
    this.clickSubmit.emit(event);
  }

  onCancelEvent(event) {
    this.clickCancel.emit(event);
  }
}
