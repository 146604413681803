import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { QueryArchiveService } from '../interfaces/viewer/query-archive.service';
import { ViewerAuthenticationService } from '../interfaces/viewer/viewer-authentication-service';
import { QueryDEMRIQ, QueryGradient, QueryOptions, QueryROI, QueryVOI } from 'src/app/_models/viewer/QueryOptions';

const ROOT_URL = environment.Leadtools.rootURL;
const SERVICES = environment.Leadtools.services;

@Injectable()
export class LeadToolsQueryArchiveService implements QueryArchiveService {

    private _queryLocalUrl;
    private _threedLocalUrl;

    constructor(
        private http: HttpClient,
        private authenticationService: ViewerAuthenticationService
    ) {
        this._queryLocalUrl = ROOT_URL + "/" + SERVICES.queryLocalServiceName;
        this._threedLocalUrl = ROOT_URL+"/"+SERVICES.threeDServiceName;
    }

    findInstances(queryParams: QueryOptions, stackInstanceUID?, maxQueryResults?) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            options: queryParams,
            extraOptions: { UserData2: stackInstanceUID, UserData: maxQueryResults}
        };
        return this.http.post(this._queryLocalUrl + "/FindInstances", JSON.stringify(data), { headers });
    }

    findDCEInstances(queryParams: QueryOptions, seriesInstanceUIDs, maxQueryResults?, useCache?, seriesID?) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        useCache = useCache === null || useCache === undefined ? true : useCache;
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            options: queryParams,
            extraOptions: { UserData: seriesID, UserData2: maxQueryResults},
            seriesInstanceUIDs: seriesInstanceUIDs,
            useCache: useCache
        };
        return this.http.post(this._queryLocalUrl + "/FindDCEInstances", JSON.stringify(data), { headers });
    }

    findROIStatistics(queryROIs: QueryROI[]) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            queryROIs: queryROIs
        };
        return this.http.post(this._queryLocalUrl + "/FindROIStatistics", JSON.stringify(data), { headers });
    }

    findVOIStatistics(queryVOIs: QueryVOI[]) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            queryVOIs: queryVOIs
        };
        return this.http.post(this._queryLocalUrl + "/FindVOIStatistics", JSON.stringify(data), { headers });
    }

    findDEMRIQData(queryROIs: QueryROI[], queryVOIs: QueryVOI[]){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            queryROIs: queryROIs,
            queryVOIs: queryVOIs
        };
        return this.http.post(this._queryLocalUrl + "/FindDEMRIQData", JSON.stringify(data), { headers });
    }

    fetchDEMRIQData(queryDEMRIQs: QueryDEMRIQ[], isAdvancedAnalysis: boolean){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            isAdvancedAnalysis: isAdvancedAnalysis,
            queryDEMRIQs: queryDEMRIQs
        };
        return this.http.post(this._queryLocalUrl + "/FetchDEMRIQData", JSON.stringify(data), { headers });
    }

    findGradientData(queryGradients: QueryGradient[]){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            queryGradients: queryGradients
        };
        return this.http.post(this._queryLocalUrl + "/FindGradientData", JSON.stringify(data), { headers });
    }

    findVOIVolume(queryVOIs: QueryVOI[]) {
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            queryVOIs: queryVOIs
        };
        return this.http.post(this._queryLocalUrl + "/FindVOIVolume", JSON.stringify(data), { headers });
    }

    getPanoramic3DImage(): string {
        return this._queryLocalUrl + "/GetPanoramicImage?auth=" + this.authenticationService.authenticationCode;
    }

    start3DObject(queryParams: QueryOptions, id, renderingMethod, stackInstanceUID){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            options: queryParams,
            id: id,
            renderingType: renderingMethod,
            extraOptions: { UserData2: stackInstanceUID }
        };
        return this.http.post(this._threedLocalUrl + "/Create3DObject", JSON.stringify(data), { headers });
    }

    checkProgress(id){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            id: id
        };
        return this.http.post(this._threedLocalUrl + "/CheckProgress", JSON.stringify(data), {headers});
    }

    keepAlive(id){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            id: id
        };
        return this.http.post(this._threedLocalUrl + "/KeepAlive", JSON.stringify(data), {headers});
    }

    close3DImage(id){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            id: id,
        };
        return this.http.post(this._threedLocalUrl + "/End3DObject", JSON.stringify(data), {headers});
    }

    update3DSettings(settings, id){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            id: id,
            options: settings,
        };
        return this.http.post(this._threedLocalUrl + "/Update3DSettings", JSON.stringify(data), {headers});
    }

    get3DSettings(settings, id){
        let headers = new HttpHeaders().set('Content-Type', 'application/json; charset=UTF-8');
        let data = {
            authenticationCookie: this.authenticationService.authenticationCode,
            id: id,
            options: settings,
        };
        return this.http.post(this._threedLocalUrl + "/Get3DSettings", JSON.stringify(data), {headers});
    }

    get3DImage() {
        return this._threedLocalUrl + "/Get3DImage?auth=" + encodeURIComponent(this.authenticationService.authenticationCode);
    }

    findPresentationState(referencedSeries:string){
        let parameter = "auth=" + encodeURIComponent(this.authenticationService.authenticationCode) + "&series=" + referencedSeries;

        return this.http.get(this._queryLocalUrl + "/FindPresentationState?" + parameter);
    }

}
