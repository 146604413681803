import {environment} from 'src/environments/environment';
import {RefreshComponentsUrl} from '../../_models/refresh-components-url';

export const CachableRoutePatterns: Object = {};
// QC List URLs
CachableRoutePatterns['https://'
                        + environment.MSQualityControlVersion
                        + 'msqualitycontrol-dot-' + environment.apiUrl + '/task/list/:id']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSUploadVersion
                        + 'msupload-dot-' + environment.apiUrl + '/upload-general-info/by-imageproject/:id']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSQueriesVersion
                        + 'msqueries-dot-' + environment.apiUrl + '/queries/get-queries-by-study-id/:id']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/patient/get-by-study-id/:id/without-rel']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/visit-config/get-scan-dates-by-visit-config-ids']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSUserVersion
                        + 'msuser-dot-' + environment.apiUrl + '/user/get-users-by-ids']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/study/:id/without-rel']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/patient/get-patients-by-study-id/:id']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE];

// QC List and Upload Details URLs
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/site-config/get-site-configs-by-study-id/:id']
                        = [RefreshComponentsUrl.QC_TASK_LIST_PAGE, RefreshComponentsUrl.UPLOAD_LIST_PAGE];

// Upload List UrRLs
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/study/:id/with-visits']
                        = [RefreshComponentsUrl.UPLOAD_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/study-sequence-label/get-study-modalities-by-study-id/:id']
                        = [RefreshComponentsUrl.UPLOAD_LIST_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSUploadVersion
                        + 'msupload-dot-' + environment.apiUrl + '/information/get-list-by-study-id-and-site-config-ids/:id']
                        = [RefreshComponentsUrl.UPLOAD_LIST_PAGE];


// QC Details URLs
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/study/get-study-patient-code-pattern-as-regex/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/reading-config-flexible/get-active-by-study-id/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSUploadVersion
                        + 'msupload-dot-' + environment.apiUrl + '/upload-general-info/by-general-infoid/:id/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/study-sequence-label/get-sequence-labels-by-study-id/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/comment/retrieve-all']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSUploadVersion
                        + 'msupload-dot-' + environment.apiUrl + '/upload-general-info/by-visit-config-id/with-download-links/:id/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/patient/:id/with-visit-configs']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSUserVersion
                        + 'msuser-dot-' + environment.apiUrl + '/user/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/study-sequence-label/get-grouped-study-modalities-by-study-id/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImageAnalysisVersion
                        + 'msimageanalysis-dot-' + environment.url +'/executions/0/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSQualityControlVersion
                        + 'msqualitycontrol-dot-' + environment.apiUrl + '/series/get-seriesids-by-visitconfig/:id/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/series/preview/get-series-by-ids/optimized']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/series/get-series-parents-by-ids/without-rel']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSQualityControlVersion
                        + 'msqualitycontrol-dot-' + environment.apiUrl + '/series/get-series-by-seriesids/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/series/get-series-by-ids/without-rel']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSQualityControlVersion
                        + 'msqualitycontrol-dot-' + environment.apiUrl + '/visit/with-modalities-by-visit-config/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImageAnalysisVersion
                        + 'msimageanalysis-dot-' + environment.apiUrl + '/executions/4/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSUserVersion
                        + 'msuser-dot-' + environment.apiUrl + '/user/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/study/get-imaging-manual-download-url/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/patient/get-patients-by-study-id/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSImagingProjectVersion
                        + 'msimagingproject-dot-' + environment.apiUrl + '/patient/get-by-series-id/:id/without-rel']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
CachableRoutePatterns['https://'
                        + environment.MSUserVersion
                        + 'msuser-dot-' + environment.apiUrl + '/study-user/retrieve-roles/:id/:id']
                        = [RefreshComponentsUrl.QC_DETAILS_PAGE];
