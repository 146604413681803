import {BaseFlexibleConfig} from '../base-flexible-config';
import { ModalityModel } from '../modality-model';
import { VisitType } from '../patient-model';
import {SequenceLabelModel} from '../sequence-label-model';

export interface AdvancedAnalysisConfigModel extends BaseFlexibleConfig {
    hideVisitHistory: boolean;
    imageViewerConfiguration: string;
    readinTypeConfiguration:[];
    readingLevelConfiguration:[];
    anatomySelector: SequenceLabelModel[] | {id: number}[];
    visit?: any[];
    isGBMSegmentation?: boolean;
    gbmSegmentationAllTimepoints?: boolean;
}

export class ADVANCED_ANALYSIS_VisitModel{
    id?: number;
    created?: number;
    name: string;
    type?: VisitType;
    durationTimeUnit?: string;
    durationTimeValue?: number;
    baseline?: boolean;
    repeatAllowed?: boolean;
    modalities?: ModalityModel[];
    isAvailable = true;
  }
