import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSUserVersion;
const SERVICE = 'msuser-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class StudyUserService {

  constructor(private http: HttpClient) { }

  create(studyUser: any) {
    return this.http.post(requestURL + `/study-user`, studyUser);
  }

  update(studyUser: any) {
    return this.http.put(requestURL + `/study-user`, studyUser);
  }

  delete(studyUser: any) {
    return this.http.delete(requestURL + `/study-user`, studyUser);
  }

  deleteAllByStudyId(id: number) {
    return this.http.delete(requestURL + `/study-user/delete-all-by-study-id/` + id);
  }

  deleteAllByUserId(id: number) {
    return this.http.delete(requestURL + `/study-user/delete-all-by-user-id/` + id);
  }

  getStudyUser(studyId: number, userId: number) {
    return this.http.get(requestURL + `/study-user/` + studyId + '/' + userId);
  }

  getStudiesWithUserName(userName: string) {
    return this.http.get(requestURL + `/study-user/retrieve-with-user-name/` + userName);
  }

  getStudiesWithEmail(email: string) {
    return this.http.get(requestURL + `/study-user/retrieve-with-email/` + email);
  }

  getUsers(studyId: number) {
    return this.http.get(requestURL + `/study-user/retrieve-users/` + studyId);
  }

  getStudies(userId: number) {
    return this.http.get(requestURL + `/study-user/retrieve-studies/` + userId);
  }

  getStudyUserRoles(studyId: number, userId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(requestURL + `/study-user/retrieve-roles/` + studyId + '/' + userId);
  }

  getStudyUserActivities(userId: string)  {
    return this.http.get(requestURL + `/study-user/retrieve-activities/` + userId);
  }

  getStudyUserByUserId(userId: number) {
    return this.http.get(requestURL + `/study-user/retrieve-with-user-id/` + userId);
  }

  getReadersByStudyId(studyId: number): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(requestURL + `/study-user/get-readers-by-study-id/` + studyId);
  }

  getAll() {
    return this.http.get(requestURL + `/study-user/retrieve-all`);
  }
}
