import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../../reading-form-scoring.component';
import {Observable} from 'rxjs';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {JSW_OARSI_ReadingModel} from '../../../../_models/JSW/jsw-reading-model';
import {ReadingJSWService} from '../../../../_services/reading-jsw.service';
import {ActivatedRoute} from '@angular/router';
import {JswReadingFormModerationComponent} from '../jsw-reading-form-moderation/jsw-reading-form-moderation.component';
import {JswReadingFormBasicComponent} from '../jsw-reading-form-basic/jsw-reading-form-basic.component';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';

export interface JswReadingForm {
  clearForm(): void;

  submitForm(studyId: number, spentSeconds: number): Observable<BasicResponse<any>>;
  skipForm(studyId: number, spentSeconds: number): Observable<BasicResponse<any>>;
}

@Component({
  selector: 'app-jsw-reading-form',
  templateUrl: './jsw-reading-form.component.html',
  styleUrls: ['./jsw-reading-form.component.css']
})
export class JswReadingFormComponent extends ReadingFormScoringComponent
  implements OnInit, AfterViewInit, ReadingFormScoring<JSW_OARSI_ReadingModel> {

  @ViewChild(JswReadingFormBasicComponent) basicReadingForm: JswReadingForm;
  @ViewChild(JswReadingFormModerationComponent) moderationReadingForm: JswReadingForm;

  readingForm: JswReadingForm;

  constructor(private store: Store,
              private readingService: ReadingJSWService,
              private route: ActivatedRoute) {
    super();
  }

  ngOnInit(): void {
    this.setupFormTitle();
  }

  ngAfterViewInit(): void {
    this.initReadingFormRef();
  }

  clearForm(): void {
    this.readingForm.clearForm();
  }

  getEndpointName(): string {
    return 'JSW';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    if (this.isModerationMode()) {
      return this.readingService.getModerationReadings(studyId, readerId);
    } else {
      return this.readingService.getReading(studyId, readerId);
    }
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingForm.submitForm(studyId, data.spentSeconds);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    if (justSaveTime)
      return this.readingService.updateReading(studyId, readingId, data.spentSeconds);
    return this.readingForm.skipForm(studyId, data.spentSeconds);
  }

  private initReadingFormRef() {
    if (this.isModerationMode()) {
      this.readingForm = this.moderationReadingForm;
    } else {
      this.readingForm = this.basicReadingForm;
    }
  }

  private isModerationMode(): boolean {
    return Boolean(this.route.snapshot.params['moderation']);
  }

  private setupFormTitle() {
    this.store.dispatch(new SetPageHeaderTitle('JSW Scoring Form'));
  }
}
