import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {FormMode} from '../../../../../core/constants/form-mode';
import {ActivatedRoute} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {WbmriConfigModel} from '../../../../../_models/ImagingProject/wbmri-config-model';
import {StudySequenceLabelService, StudyUserService} from '../../../../../_services';
import {forkJoin} from 'rxjs';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {MatDialog} from '@angular/material/dialog';
import {
    defaultAdjAutoBatchConfig, defaultLvl1AutoBatchConfig,
    ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {
    FactorsToScoreWbmri,
    Reader
} from '../imaging-project-reading-wbmri-wrapper/imaging-project-reading-wbmri-wrapper.component';
import {MatTableDataSource} from '@angular/material/table';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {MatSelectChange} from '@angular/material/select';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
    selector: 'app-imaging-project-reading-wbmri-adjudication',
    templateUrl: './imaging-project-reading-wbmri-adjudication.component.html',
    styleUrls: ['./imaging-project-reading-wbmri-adjudication.component.css']
})
export class ImagingProjectReadingWbmriAdjudicationComponent extends ImagingProjectReadingBasicComponent implements OnInit {

    @Input() selectedConfig: FlexibleConfig<WbmriConfigModel>;
    @Input() mode: FormMode;
    @Input() visitsQuantity: number;
    @Output() public clickCancel = new EventEmitter<any>();
    @Output() public clickSubmit = new EventEmitter<any>();

    readonly formModes = FormMode;
    readonly readingTypes = ReadingType;
    modalities: ModalityModel[];
    studyId: number;
    lvl1BatchConfig: BatchConfigModel = null;
    adjudicationBatchConfig: BatchConfigModel = null;
    readersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    adjReadersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    MIN_READERS = 1;
    MAX_READERS = 20;
    ADJ_WMRI_CELGENE_READERS = 2;
    ADJ_WMRI_CELGENE_ADJ_READER = 1;
    readers: Reader[];
    adjReaderSelectOptions: Reader[];
    readerSelectOptions: Reader[];
    readersTableColumns: string[] = ['username', 'email', 'action'];
    factorstoScoreWbmri = FactorsToScoreWbmri;
    ADJUDICATION_RULE_CELGENE = 'Adjudication-WbmriCelgene';
    ADJUDICATION_RULE_EXTERNAL = 'Adjudication-ExternalRule';

    wbmriForm = this.fb.group({
        readingLevel: ['PATIENT', [Validators.required]],
        modalities: [[], [Validators.required]],
        readingType: ['PARALLEL', [Validators.required]],
        hideVisitHistory: ['hide_visits_chronology', [Validators.required]],
        anatomySelector: ['', [Validators.required]],
        batchManagement: [false, [Validators.required, this.batchManagementValidator]],
        imageViewerConfiguration: ['default', [Validators.required]],
        adjudicationRule: [this.ADJUDICATION_RULE_CELGENE, [Validators.required]],
        adjudicationBlind: [false, [Validators.required]],
        adjudicationFinalReportWithoutDisagreementRule: ['Attach-AllLevel1', [Validators.required]],
        adjudicationFinalReportWithDisagreementRule: ['Attach-AdjudicationOnly', [Validators.required]],
        factorstoScoreWbmri: [this.factorstoScoreWbmri.map(f => f.id), [Validators.required]],
        readers: ['', [this.readersValidator]],
        adjudicationBatchManagement: [false, [Validators.required, this.adjudicationBatchManagementValidator]],
        // adjudicationReaders: ['', [this.adjReadersValidator]]
        adjudicationReader: ['', [this.adjReaderValidator]]
    });

    selectedSequenceLabels: SequenceLabelModel[] = [];
    availableSequenceLabels: SequenceLabelModel[] = [];
    toastOptions: ToastOptions = {
        title: '',
        showClose: true,
        timeout: 10000,
        theme: 'material',
    };

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private studyUserService: StudyUserService,
        private studySequenceLabelService: StudySequenceLabelService,
        private dialog: MatDialog,
        private toastyService: ToastyService,
        private batchLogicService: BatchLogicService
    ) {
      super();
    }

    ngOnInit(): void {
        this.studyId = +this.route.snapshot.params.id;

        const serviceSources = [
            this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
            this.studyUserService.getReadersByStudyId(this.studyId)
        ];
        if (this.mode === this.formModes.EDIT) {
            serviceSources.push(this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id));
        }
        forkJoin(serviceSources).subscribe(
            ([groupedModalitiesResponse, readersResponse, batchConfigsResponse]) => {
                this.initModalities(groupedModalitiesResponse);
                this.initReaders(readersResponse);
                if (this.mode === this.formModes.EDIT) {
                    this.initBatchConfigs(batchConfigsResponse);
                    this.initFormValues(this.selectedConfig.config);
                }
            },
            (error) => this.showErrorMessage(error.error));
    }

    showErrorMessage(response: any): void {
        this.toastOptions.title = 'ERROR ' + response['responseCode'];
        this.toastOptions.msg = response['responseMessage'];
        this.toastyService.error(this.toastOptions);
    }

    initFormValues(config: WbmriConfigModel): void {
        if (config) {
            this.wbmriForm.patchValue(config);
            this.wbmriForm.patchValue({
                modalities: config.modalities,
                readers: config.readers.map(reader => reader.id),
                // adjudicationReaders: config.adjudicationReaders.map(reader => reader.id)
                adjudicationReader: config.adjudicationReader
            });
            this.pushReaders();
            this.pushAdjudicator();
        }
    }

    initBatchConfigs(batchConfigsResponse): void {
        if (batchConfigsResponse.responseCode === 200 && batchConfigsResponse.data) {
            batchConfigsResponse.data.forEach(batchConfig => {
                if (batchConfig.readingType === ReadingType.LEVEL1_READING) {
                    this.lvl1BatchConfig = batchConfig;
                } else if (batchConfig.readingType === ReadingType.ADJUDICATION_READING) {
                    this.adjudicationBatchConfig = batchConfig;
                }
            });
        }
    }

    initReaders(readersResponse): void {
        if (readersResponse.responseCode === 200 && readersResponse.data) {
            this.readers = readersResponse.data;
            if (this.mode === this.formModes.NEW) {
                this.readerSelectOptions =
                    (readersResponse.data as Reader[]).sort(this.compareReadersByName);
                this.adjReaderSelectOptions =
                    (readersResponse.data as Reader[]).sort(this.compareReadersByName);
            }
        }
    }

    initModalities(groupedModalitiesResponse): void {
        if (groupedModalitiesResponse.responseCode === 200 && groupedModalitiesResponse.data) {
            // this.modalities = groupedModalitiesResponse.data;
            this.modalities = (groupedModalitiesResponse.data as ModalityModel[]).sort((a, b) => a.name.localeCompare(b.name));
            if (this.mode === this.formModes.NEW) {
                this.wbmriForm.patchValue({modalities: []});
            }
            this.availableSequenceLabels = this.getAvailableSequenceLabels(this.modalities);
            this.prepareSequenceLabels(this.modalities);
        }
    }

    getAvailableSequenceLabels(modalities): SequenceLabelModel[] {
        const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
        for (const modality of modalities) {
            for (const label of modality.sequenceLabels) {
                configLabels.add(label);
            }
        }
        return <SequenceLabelModel[]> Array.from(configLabels.values());
    }

    getFormControl(path: string): FormControl {
        return this.wbmriForm.get(path) as FormControl;
    }

    compareModalities(m1: any, m2: any): boolean {
        return m1 && m2 ? m1.name === m2.name : m1 === m2;
    }

    onModalityTypeChange(event: MatSelectChange) {
        this.wbmriForm.patchValue({modalities: event.value});
        this.initDefaultSequenceLabels(this.wbmriForm.controls.modalities.value);
      }

    prepareSequenceLabels(modalities) {
        if (this.mode === FormMode.EDIT) {
            if (this.selectedConfig.config.anatomySelector) {
                this.initSequenceLabels(this.selectedConfig.config.anatomySelector);
            } else {
                this.initDefaultSequenceLabels(modalities);
            }
        } else {
            this.initDefaultSequenceLabels(modalities);
        }
    }

    initDefaultSequenceLabels(modalities) {
        this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
        this.selectedSequenceLabels = [...this.availableSequenceLabels];
    }

    initSequenceLabels(sequenceLabels: { id: number }[]) {
        this.selectedSequenceLabels = this.availableSequenceLabels
            .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
    }

    get batchManagementValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value && !this.lvl1BatchConfig?.numberOfReadings ? {norError: 'Number of readings is required'} : null;
        };
    }

    get adjudicationBatchManagementValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value && !this.adjudicationBatchConfig?.numberOfReadings ?
                {norError: 'Number of readings is required'} : null;
        };
    }

    onSequenceLabelSelect(e: SequenceLabelModel[]) {
        this.selectedSequenceLabels = e;
    }

    onBatchManagementSelected(event: MatSelectChange, isAdjBatchManagement: boolean): void {
        if (isAdjBatchManagement) {
            if (this.adjudicationBatchConfig) {
                this.adjudicationBatchConfig.numberOfReadings = null;
                this.wbmriForm.get('adjudicationBatchManagement').updateValueAndValidity();
            }
        } else {
            if (this.lvl1BatchConfig) {
                this.lvl1BatchConfig.numberOfReadings = null;
                this.wbmriForm.get('batchManagement').updateValueAndValidity();
            }
        }
    }

    openAutoCreationBatchConfigDialog(readingType: ReadingType, config: BatchConfigModel): void {
        let inputConfig: AutoBatchConfigPayload;
        let val: boolean;
        if (readingType === ReadingType.LEVEL1_READING) {
            val = this.wbmriForm.get('batchManagement').value;
            if (!val) {
                inputConfig = {...defaultLvl1AutoBatchConfig};
            }
        } else {
            val = this.wbmriForm.get('adjudicationBatchManagement').value;
            if (!val) {
                inputConfig = {...defaultAdjAutoBatchConfig};
            }
        }

        if (!inputConfig) {
            inputConfig = config;
        }

        const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
            width: '500px',
            data: {
                readingType: readingType,
                configInput: inputConfig,
                readonlyDialog: !val
            }
        });

        dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
            if (result) {
                if (readingType === ReadingType.ADJUDICATION_READING) {
                    this.adjudicationBatchConfig = {...result};
                    this.wbmriForm.get('adjudicationBatchManagement').updateValueAndValidity();
                } else {
                    this.lvl1BatchConfig = {...result};
                    this.wbmriForm.get('batchManagement').updateValueAndValidity();
                }
            }
          ImagingProjectReadingBasicComponent.clearFocus();
        });
    }

    compareReaders(r1: any, r2: any): boolean {
        return r1 && r2 ? r1.id === r2.id : r1 === r2;
    }

    isReadersValid(): boolean {
        if (this.wbmriForm) {
            if (this.wbmriForm.get('adjudicationRule').value === this.ADJUDICATION_RULE_CELGENE) {
                return this.readersDataSource.data.length === this.ADJ_WMRI_CELGENE_READERS;
            } else {
                return this.readersDataSource.data.length >= this.MIN_READERS && this.readersDataSource.data.length <= this.MAX_READERS;
            }
        } else {
            return true;
        }
    }

    isAdjReadersValid(): boolean {
        if (this.wbmriForm) {
            if (this.wbmriForm.get('adjudicationRule').value === this.ADJUDICATION_RULE_CELGENE) {
                return this.adjReadersDataSource.data.length === this.ADJ_WMRI_CELGENE_ADJ_READER;
            } else {
                return this.adjReadersDataSource.data.length >= this.MIN_READERS &&
                    this.adjReadersDataSource.data.length <= this.MAX_READERS;
            }
        } else {
            return true;
        }
    }

    get readersValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.isReadersValid()) {
                return null;
            } else {
                const adjRuleVal = control.parent.get('adjudicationRule').value;
                if (adjRuleVal === this.ADJUDICATION_RULE_CELGENE) {
                    return {
                        tableError: `${this.ADJ_WMRI_CELGENE_READERS} users must be in the list`,
                        selectError: `please ${this.readersDataSource.data.length < this.ADJ_WMRI_CELGENE_READERS ? 'select' : 'remove'} option`
                    };
                } else {
                    return {
                        tableError: `${this.MIN_READERS}..${this.MAX_READERS} users must be in the list`,
                        selectError: `please ${this.readersDataSource.data.length < this.MAX_READERS ? 'select' : 'remove'} option`
                    };
                }
            }
        };
    }

    get adjReaderValidator() {   // computed property suppress property is used before its initialization error
        return (control: AbstractControl): ValidationErrors | null => {
            if (this.isAdjReadersValid()) {
                return null;
            } else {
                const adjRuleVal = control.parent.get('adjudicationRule').value;
                if (adjRuleVal === this.ADJUDICATION_RULE_CELGENE) {
                    return {
                        tableError: `${this.ADJ_WMRI_CELGENE_ADJ_READER} users must be in the list`,
                        selectError: `please ${this.adjReadersDataSource.data.length < this.ADJ_WMRI_CELGENE_ADJ_READER ? 'select' : 'remove'} option`
                    };
                } else {
                    return {
                        tableError: `${this.MIN_READERS}..${this.MAX_READERS} users must be in the list`,
                        selectError: `please ${this.adjReadersDataSource.data.length < this.MAX_READERS ? 'select' : 'remove'} option`
                    };
                }
            }
        };
    }

    compareReadersByName(r1: Reader, r2: Reader): number {
        return (r1.firstName + r1.lastName).localeCompare(r2.firstName + r2.lastName);
    }

    //noinspection Duplicates
    pushReaders(): void {
        const selectedReaderIds = [...this.wbmriForm.get('readers').value, ...this.readersDataSource.data.map(reader => reader.id)];
        this.readersDataSource.data =
            this.readers
                .filter(reader => selectedReaderIds.includes(reader.id))
                .sort(this.compareReadersByName);
        this.readerSelectOptions = this.readers
            .filter(reader => !selectedReaderIds.includes(reader.id))
            .sort(this.compareReadersByName);
        this.wbmriForm.patchValue({readers: []});
    }

    //noinspection Duplicates
    removeReader(id: number): void {
        this.readersDataSource.data = this.readersDataSource.data.filter(reader => reader.id !== id);
        this.readerSelectOptions = [...this.readerSelectOptions, this.readers.find(r => r.id === id)].sort(this.compareReadersByName);
        this.wbmriForm.get('readers').updateValueAndValidity();
    }

    /*pushAdjudicator(): void {
        const selectedAdjudicatorId =
            [...this.wbmriForm.get('adjudicationReaders').value, ...this.adjReadersDataSource.data.map(reader => reader.id)];
        this.adjReadersDataSource.data =
            this.readers
                .filter(reader => selectedAdjudicatorId.includes(reader.id))
                .sort(this.compareReadersByName);
        this.adjReaderSelectOptions = this.readers
            .filter(reader => !selectedAdjudicatorId.includes(reader.id))
            .sort(this.compareReadersByName);
        this.wbmriForm.patchValue({adjudicationReader: ''});
    }*/

    pushAdjudicator(): void {
        const selectedAdjudicatorId = this.wbmriForm.get('adjudicationReader').value;
        this.adjReadersDataSource.data = [this.readers.find(reader => selectedAdjudicatorId === reader.id)];
        this.adjReaderSelectOptions = this.readers
            .filter(reader => selectedAdjudicatorId !== reader.id)
            .sort(this.compareReadersByName);
        this.wbmriForm.patchValue({adjudicationReader: ''});
    }

    /*removeAdjudicator(id: number): void {
        this.adjReadersDataSource.data = this.adjReadersDataSource.data.filter(reader => reader.id !== id);
        this.adjReaderSelectOptions = [...this.adjReaderSelectOptions, this.readers.find(r => r.id === id)].sort(this.compareReadersByName);
        this.wbmriForm.get('adjudicationReader').updateValueAndValidity();
    }*/

    removeAdjudicator(id: number): void {
        this.adjReadersDataSource.data = [];
        this.adjReaderSelectOptions = this.readers;
        this.wbmriForm.get('adjudicationReader').updateValueAndValidity();
    }

    cancel(): void {
        this.clickCancel.emit({result: 'cancel'});
    }

    prepareLevelOneBatchConfigPayload(): AutoBatchConfigPayload {
        let configPayload: AutoBatchConfigPayload = null;
        if (this.lvl1BatchConfig) {
            const configId: number = this.lvl1BatchConfig.id ? this.lvl1BatchConfig.id : null;
            if (this.wbmriForm.get('batchManagement').value) {
                configPayload = {
                    id: configId,
                    numberOfReadings: this.lvl1BatchConfig.numberOfReadings,
                    readingType: ReadingType.LEVEL1_READING,
                    initiationMethod: this.lvl1BatchConfig.initiationMethod,
                    initiationOption: this.lvl1BatchConfig.initiationOption,
                    batchCount: this.lvl1BatchConfig.batchCount
                };
            } else {
                configPayload = {...defaultLvl1AutoBatchConfig};
                configPayload.readingType = ReadingType.LEVEL1_READING;
            }
            configPayload.id = configId;
        }
        return configPayload;
    }

    prepareAdjudicationBatchConfigPayload(): AutoBatchConfigPayload {
        let configPayload: AutoBatchConfigPayload = null;
        if (this.adjudicationBatchConfig) {
            const configId: number = this.adjudicationBatchConfig.id ? this.adjudicationBatchConfig.id : null;
            if (this.wbmriForm.get('adjudicationBatchManagement').value) {
                configPayload = {
                    id: configId,
                    numberOfReadings: this.adjudicationBatchConfig.numberOfReadings,
                    readingType: ReadingType.ADJUDICATION_READING,
                    initiationMethod: this.adjudicationBatchConfig.initiationMethod,
                    initiationOption: this.adjudicationBatchConfig.initiationOption,
                    batchCount: this.adjudicationBatchConfig.batchCount
                };
            } else {
                configPayload = {...defaultAdjAutoBatchConfig};
                configPayload.readingType = ReadingType.ADJUDICATION_READING;
            }
            configPayload.id = configId;
        }
        return configPayload;
    }

    //noinspection Duplicates
    submit(): void {
        const payload: WbmriConfigModel = this.wbmriForm.value;
        payload.modalities =  this.wbmriForm.value.modalities;
        payload.readers = this.readersDataSource.data.map(reader => {
            return {id: reader.id};
        });
        /*payload.adjudicationReader = this.adjReadersDataSource.data.map(reader => {
            return {id: reader.id};
        });*/
        payload.adjudicationReader = this.adjReadersDataSource.data[0]['id'];
        payload.readingVersion = ReadingVersion.WBMRI_ADJUDICATION;
        payload.endpointReadingVersion = 'adjudication';
        payload.anatomySelector = this.selectedSequenceLabels.map(l => {
            return {id: l.id};
        });

        const imagingProjectSubmitEvent: ImagingProjectSubmitEvent = {
            result: 'submit',
            data: payload,
            level1BatchConfig: this.prepareLevelOneBatchConfigPayload(),
            adjudicationBatchConfig: this.prepareAdjudicationBatchConfigPayload()
        };

        // console.warn(JSON.stringify(imagingProjectSubmitEvent));
        this.clickSubmit.emit(imagingProjectSubmitEvent);
    }

    isFormValid(): boolean {
        let isValid = this.wbmriForm.valid;
        if (isValid) {
            if (this.wbmriForm.get('adjudicationRule').value === this.ADJUDICATION_RULE_CELGENE) {
                isValid = this.visitsQuantity === 3; /// !!!
            }
        }
        return isValid;
    }

}
