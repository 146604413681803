import {AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {first, map, startWith} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {DataUploadService} from '../../../../_services/data-upload.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DragDropFilesComponent} from '../../../DataUpload/drag-drop-files/drag-drop-files.component';
import {MatDialog} from '@angular/material/dialog';
import {UploadDialogComponent} from '../../../DataUpload/upload-dialog/upload-dialog.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {MatAutocomplete, MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ImagingProjectService} from '../../../../_services/imaging-project.service';
import {UserService} from '../../../../_services/user.service';
import {StudySequenceLabelService} from '../../../../_services/study-sequence-label.service';
import {QualityControlService} from '../../../../_services/quality-control.service';
import {JwtUtilService} from '../../../../_helpers/jwt-util.service';
import {PatientService} from '../../../../_services/patient.service';


import * as _moment from 'moment';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';

const moment = _moment;


export interface ModalityElement {
  id: number;
  name: string;
}

class UploadFileInfo {
  id: number;
  name: string;
  size: number;
  status: string;
  resourceType: string;
}

@Component({
  selector: 'app-upload-visit-form',
  templateUrl: './upload-visit-form.component.html',
  styleUrls: ['./upload-visit-form.component.css']
})
export class WebinarUploadVisitFormComponent implements OnInit, OnDestroy, AfterViewInit  {
  isNewUpload = true;
  state = {
    siteConfigId: 0,
    siteCode: 0,
    patientId: 0,
    patientCode: 0,
    patientBirthDate: '',
    visitDataId: 0,
    visitConfId: 0,
    imageProject: 0,
    mode: 'upload',
    visit: {},
    bucketLocation: '',
    isUseEDTFUpload: false,
    qcVisitLock: false,
    unscheduled: false,
  };

  patient: {};

  allModalities = [];
  qcFilteredModalities = [];
  qcDisableUI = false;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredModality: Observable<ModalityElement[]>;
  modalitiesSelected: any[] = [];


  @ViewChild('modalityInput') modalityInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  @ViewChild('dragDropImages') selectedFiles: DragDropFilesComponent;
  @ViewChild('dragDropEdtf') selectedEdtfFile: DragDropFilesComponent;
  // @ViewChild('edtfDataForm') edtfData: EdtfEditComponent;
  edtfTemplate = null;

  uploadForm: FormGroup;

  errorGetUploadInformation = false;
  uploadFilesInfo = new Array();

  visitDetails: any[];

  activities: any[];
  profile = {
    rights: {
      canCreateOrUploadEDTF: false,
      canViewEDTF: false,
      iagProjectManager: false,
    }
  };

  _patient: any;
  _visitConfig: any;
  _siteConfig: any;
  _edtfVisits: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private imagingProjectSerivce: ImagingProjectService,
    private patientService: PatientService,
    private serviceDataUpload: DataUploadService,
    private userService: UserService,
    private studySequenceLabelService: StudySequenceLabelService,
    private qualityControlService: QualityControlService,
    private jwtUtilService: JwtUtilService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private store: Store) {
    this.createForm();
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Upload visit files'));
    // intercept visitId from url
    this.route.params.subscribe(params => {
      this.state.mode = 'upload';
      this.state.imageProject = +params['ipid'];
      this.state.siteConfigId = +params['sid'];
      this.state.patientId = +params['pid'];
      this.state.visitConfId = +params['vcid'];
      this.state.visitDataId = +params['vdid'];
      this.state.mode = params['mode'];
      this.state.unscheduled = params['unscheduled'];
      this.state.qcVisitLock = false;
      this.qcDisableUI = false;

      this.imagingProjectSerivce.getSiteConfig(this.state.siteConfigId).subscribe( siteConfResp => {
        this._siteConfig = siteConfResp['data'];
        this.patientService.getById(this.state.patientId).subscribe( patientResp => {
            this._patient = patientResp['data'];
            this.state.patientBirthDate = moment(this._patient['patientBirthDate']).format('MM/YYYY');
            this.imagingProjectSerivce.getVisitConfig(this.state.visitConfId.toString()).subscribe( visitConfigResp => {
              this._visitConfig = visitConfigResp['data'];

              this.state.patientCode = this._patient['patientCode'];
              // this.edtfData.patientCode = this._patient['patientCode'];
              // this.edtfData.patientDaeOfBirth = this._patient['patientBirthDate'];
              this.updateValidationRules();


              this.patient = JSON.parse(localStorage.getItem('tmp.upload.patient.visit'));
              this._edtfVisits = this.patient['visits'];
              if (this.patient['visitsUnscheduled'] != null) {
                const v = this.patient['visitsUnscheduled'].find(vu => vu.config.visitConfigsId == this._visitConfig.id);
                if (v != null) {
                  this._edtfVisits.push(v);
                }
              }
              this.state.visit = this.patient['visits'].filter(item => (item.config != null && item.config.visitId === this.state.visitDataId))[0];

              this.qualityControlService.getQCVisitByVisitWithoutRelations(this.state.imageProject, this.state.visitConfId).subscribe( qcVisitResp => {
                const qcVisit = qcVisitResp['data'];
                if (qcVisit != null) {
                  // the patient discontinued flag was removed
                  if (qcVisit['patientDiscontinuedFlag'] == true && this._patient['discontinued'] == false) {
                    this.state.qcVisitLock = false;
                  } else {
                    if (qcVisit['lockFlag'] == true) {
                      this.state.qcVisitLock = true;
                    }
                  }
                }
              });

              this.imagingProjectSerivce.getStudy(this.state.imageProject).subscribe(result => {
                if (result.hasOwnProperty('data')) {
                  const data = result['data'];
                  this.state.bucketLocation = data['bucketLocation'];
                  if (data.eDTF != null) {
                    const edtfObj = data.eDTF;
                    this.setEdtfData(edtfObj);
                  }
                }
              });
            });

            this.studySequenceLabelService.getStudyModalitiesByStudyId(this.state.imageProject).subscribe( result => {
              const modalities = result['data'];
              const mAll: ModalityElement = {
                name: 'All',
                id: -1,
              };
              this.allModalities.push(mAll);
              modalities.forEach(item => {
                const m: ModalityElement = {
                  name: item.name,
                  id: item.id,
                };
                this.allModalities.push(m);
              });

              this.qualityControlService.getModalitiesByVisitConfigId(this.state.imageProject, this.state.visitConfId).subscribe( qcModalitiesResp => {
                let qcModalitiesData = qcModalitiesResp['data'];
                qcModalitiesData = qcModalitiesData.filter(m => m.lockFlag == true);

                this.allModalities.forEach(m => {
                  const qcModals = qcModalitiesData.filter(qcm => m.id == qcm.modalityId);
                  if (qcModals.length == 0) {
                    this.qcFilteredModalities.push(m);
                  }
                });
                if (this.qcFilteredModalities.length == 0) {
                  this.qcDisableUI = true;
                  this.uploadForm.controls['comment'].disable();
                }
                if (this.profile.rights.iagProjectManager == true) {
                  this.uploadForm.controls['comment'].disable();
                }
                if (this._patient != null) {
                  if (this._patient['discontinued'] == true) {
                    this.uploadForm.controls['comment'].disable();
                  }
                }

                this.filteredModality = this.uploadForm.controls.modality.valueChanges.pipe(
                  startWith(null),
                  map((modality: ModalityElement | null) => modality ? this._filter(modality) : this.qcFilteredModalities.slice()));

              });
            });

            const aStr = localStorage.getItem('activities');
            this.activities = JSON.parse(aStr);
            this.setActivityRights(this.state.imageProject);

            if (this.state.mode == 'edit') {
              this.initData();
            } else {
              this.visitDetails = [];
            }
          });
        });
      });

  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
    // if (this.edtfData != null ) {
    //   this.edtfData.patientCode = this._patient['patientCode'];
    //   this.edtfData.patientDaeOfBirth = this._patient['patientBirthDate'];
    // }
    if (this.selectedFiles != null) {
      this.selectedFiles._zipOnly = true;
    }
    if (this.selectedEdtfFile != null) {
      this.selectedEdtfFile._zipOnly = false;
    }
    this.updateValidationRules();
    this.updateValidationRules();
  }

  setEdtfData(edtf) {
    this.edtfTemplate = (JSON.parse(JSON.stringify(edtf)));
    // if (this.edtfData != null) {
    //   this.edtfData.setEdtf(JSON.parse(JSON.stringify(edtf)));
    // }
  }


  updateValidationRules() {
    // if (this.edtfData != null) {
    //   if (this._patient != null && this._visitConfig != null) {
    //     this.edtfData.initValidation(this._patient['patientCode'], this._visitConfig.id, this._patient['patientBirthDate']);
    //   }
    // }
  }

  setActivityRights(id) {
    const str  = localStorage.getItem('activities');
    if (str != null) {
      this.activities = JSON.parse(str);
      if (this.activities.indexOf('upload.create.or.upload.eDTF') > -1) {
        this.profile.rights.canCreateOrUploadEDTF = true;
      }
      if (this.activities.indexOf('upload.view.eDTF') > -1) {
        this.profile.rights.canViewEDTF = true;
      }
    }
    this.profile.rights.iagProjectManager = this.jwtUtilService.isUserHasActivity('configuration.upload.view.all.site.data', id);
  }

  initData() {
    this.serviceDataUpload.getUploadGeneralInfoByVisitConfigIdWithDownloadLinks(this.state.imageProject, this.state.visitConfId).subscribe( genInfoResp => {
      this.addUserInformation(genInfoResp['data']);
    });
  }

  addUserInformation(data) {
    const users = [];
    if (data != null) {
      data.forEach(upload => {
        upload.created = moment(upload.created).format('DD/MM/YYYY');
        users.push(upload.uploadUserId);
      });
    }

    this.userService.getByListIds(users).subscribe(usersInfo => {
      const userInfoData = usersInfo['data'];
      if (userInfoData != null) {
        data.forEach(upload => {
          const user = userInfoData.filter(user => user.id == upload.uploadUserId)[0];
          if (user != null) {
            upload.uploadUserFirstName = user.firstName;
            upload.uploadUserLastName = user.lastName;
          }
        });
      }
      this.setVisitInformation(data);
    });
  }

  setVisitInformation(visitInfo) {
    this.visitDetails = visitInfo;
    this.injectModalitiesToEDTF();
    const lastUpload = visitInfo[0]; // back end return general info  sorted by DESC
    const lastUploadStatus = this.getLastUploadStatus(lastUpload);
    // if (this.state.visitConfId == visitInfo.visitConfigId && lastUploadStatus === 'success' &&  this.edtfData != null) {
    //   if (lastUpload.edtf != null) {
    //     this.edtfData.setEdtf(lastUpload.edtf);
    //   } else {
    //     this.edtfData.setEdtf(JSON.parse(JSON.stringify(this.edtfTemplate)));
    //   }
    // }
    // if (this.edtfData != null) {
    //   if (this.edtfData.edtf == null) {
    //     this.state.isUseEDTFUpload = false;
    //   }
    // }
    this.uploadForm.patchValue({comment: lastUpload.comment});
    for (let i = 0 ; i < lastUpload.modalities.modality.length ; i++) {
      if (lastUpload.modalities.modality[i] != null) {
        this.modalitiesSelected.push(lastUpload.modalities.modality[i]);
      }
    }
    // if (this.edtfData != null) {
    //   this.edtfData.rights.canCreateOrUploadEDTF = this.profile.rights.canCreateOrUploadEDTF;
    //   this.edtfData.rights.canViewEDTF = this.profile.rights.canViewEDTF;
    // }
  }

  injectModalitiesToEDTF() {
    const modalitySection = {
      question: 'Modalities',
      modalitySelector: {
      selectedModalities: null,
      availableMOdalities: this.filteredModality,
      },
      actionId: 'modalitySelector',
    };

  }

  getLastUploadStatus(lastUpload) {
    if (lastUpload['files'] != null) {
      const files = lastUpload.files.filter(file => file.status.name != 'success');
      if (files.length > 0) {
        return 'failed';
      }
    }
    return 'success';
  }

  createForm() {
    this.uploadForm = this.fb.group({
      modality: ['', Validators.required],
      comment: ['', Validators.required],
      files: ['', Validators.required],
      edtf: {},
    });
  }

  removeModality(item): void {
    const index = this.modalitiesSelected.indexOf(item);
    if (index >= 0) {
      this.modalitiesSelected.splice(index, 1);
    }
  }

  selectedModality(event: MatAutocompleteSelectedEvent): void {
    const m = event.option.value;
    if (m.name == 'All' && m.id == -1) {
      let modalities = [];
      this.filteredModality.forEach( nextModalities => {
        modalities = nextModalities.filter(modality => modality.id != -1 && modality.name != 'All');
      });
      if (modalities != null) {
        this.modalitiesSelected = modalities;
      }
    } else {
      this.modalitiesSelected.push(m);
    }

    this.modalityInput.nativeElement. value = '';
    this.uploadForm.patchValue({modality: null});
  }

  private _filter(value: ModalityElement): ModalityElement[] {
    return this.allModalities.filter(modality => modality.id === value.id);
  }

  clickCancel() {
    this.router.navigate(['/imagingproject/upload']);
  }


  clickUpload() {
    const dataUploadForm = this.uploadForm.value;
    const dataFiles = this.selectedFiles['files'];
    let edtfFiles;
    if (this.selectedEdtfFile != null) {
      edtfFiles = this.selectedEdtfFile['files'];
    }
    this.uploadFilesInfo = new Array();
    const registerFilesInfo = new Array();
    const selModalities = new Array();

    const formData: FormData = new FormData();


    for (let i = 0; i < this.modalitiesSelected.length ; i++) {
      const item = this.allModalities.find(modality => this.modalitiesSelected[i].name === modality.name);
      selModalities.push(item);
    }

    formData.append('imageProjectId', JSON.stringify(this.state.imageProject));
    formData.append('siteConfigId', JSON.stringify(this.state.siteConfigId));
    formData.append('modalities', JSON.stringify({modality: selModalities}));
    formData.append('patientId', JSON.stringify(this.state.patientId));
    formData.append('visitId', JSON.stringify(this.state.visitDataId));
    formData.append('visitConfigId', JSON.stringify(this.state.visitConfId));
    formData.append('comment', dataUploadForm.comment);
    formData.append('bucketLocation', this.state.bucketLocation);
    formData.append('unscheduled', JSON.stringify(this.state.unscheduled));

    if (this.state.isUseEDTFUpload == false) {
      formData.append('edtf', JSON.stringify(null));
      formData.append('edtfFile', null);
    } else {
      if (edtfFiles.length > 0) {
        const file = {
          name: edtfFiles[0].name,
          size: edtfFiles[0].size,
        };
        const edtfInfo = new UploadFileInfo();
        edtfInfo.name = edtfFiles[0].name;
        edtfInfo.size = edtfFiles[0].size;
        edtfInfo.status = 'pending';
        edtfInfo.resourceType = 'idtf';
        this.uploadFilesInfo.push(edtfInfo);
        formData.append('edtfFile', JSON.stringify(file));
        formData.append('edtf', null);
      }
    }

    for (let i = 0; i < dataFiles.length; i++) {
      const file = {
        name: dataFiles[i].name,
        size: dataFiles[i].size,
      };
      const fInfo = new UploadFileInfo();
      fInfo.name = dataFiles[i].name;
      fInfo.size = dataFiles[i].size;
      fInfo.status = 'pending';
      fInfo.resourceType = 'image';
      this.uploadFilesInfo.push(fInfo);
      registerFilesInfo.push(file);
    }
    formData.append('files', JSON.stringify(registerFilesInfo));

    this.serviceDataUpload.getUploaddFileInformation(formData)
      .pipe(first())
      .subscribe(
        data => {
          for (let i = 0; i < this.uploadFilesInfo.length; i++) {
            const fi = this.getElementWithFileNameInfo(data, this.uploadFilesInfo[i]);
            if (fi != null) {
              this.uploadFilesInfo[i].id = fi.id;
              this.uploadFilesInfo[i].status = fi.status;
            }
          }
          this.uploadFiles(formData, data, dataFiles);
          if (edtfFiles != null) {
            this.uploadFiles(formData, data, edtfFiles);
          }
        });
  }

  updateEDTF() {
    const uploadDataInfo = {
      bucketLocation: this.state.bucketLocation,
      imageProjectId: this.state.imageProject,
      visitConfigId: this.state.visitConfId,
      edtf: null,
      edtfFile: [],
    };

    let edtfFiles;
    if (this.selectedEdtfFile != null) {
      edtfFiles = this.selectedEdtfFile['files'];
    }

    if (this.state.isUseEDTFUpload == false) {
      uploadDataInfo.edtf = null;
      uploadDataInfo.edtfFile = [];
    } else {
      if (edtfFiles.length > 0) {
        this.uploadFilesInfo = [];
        edtfFiles.forEach(edtfFile => {
          const file = {
            name: edtfFile.name,
            size: edtfFile.size,
          };
          const edtfInfo = new UploadFileInfo();
          edtfInfo.name = edtfFile.name;
          edtfInfo.size = edtfFile.size;
          edtfInfo.status = 'pending';
          edtfInfo.resourceType = 'idtf';
          this.uploadFilesInfo.push(edtfInfo);

          uploadDataInfo.edtfFile.push(file);
          uploadDataInfo.edtf = null;
        });
      }
    }

    this.serviceDataUpload.getUploadEdtfFileInformation(uploadDataInfo)
      .pipe(first())
      .subscribe(
        edtfResp => {
          if (Array.isArray(edtfResp['data']) == true && edtfResp['data']['length'] > 0) {
            // upload eDTF files
            // response contained URLs for google cloud
            this.uploadFilesInfo.forEach(file => {
              const fi = edtfResp['data'].find(item => item.name === file.name && item.size === file.size);
              if (fi != null) {
                file.id = fi.id;
                file.status = fi.status;
              }
            });
            this.uploadEdtfFiles(edtfResp['data'], edtfFiles);
          } else {
            // response when update inly eDTF json.
            // The response contained general_upload_info
            this.router.navigate(['/imagingproject/upload']);
          }
        }
      );
  }

  uploadFiles(formData: FormData, dbFilesInfo, files) {
    this.openDialog();
    for (let i = 0;  i < files.length; i++) {
      const fileInfo = this.getElementWithFileNameInfo(dbFilesInfo, files[i]);
      if (fileInfo != null) {
        this.serviceDataUpload.uplodFileToCloud(fileInfo.url, files[i])
          .pipe(first())
          .subscribe( data => {
            this.setUploadFileInfosStatus(fileInfo.id, 'success');
          },
            error => {
            this.setUploadFileInfosStatus(fileInfo.id, 'failed');
          });
      }
    }
  }

  uploadEdtfFiles(dbFilesInfo, files) {
    this.openDialog();
    files.forEach(file => {
      const fileInfo = dbFilesInfo.find(item => item.name === file.name && item.size === file.size);
      if (fileInfo != null) {
        this.serviceDataUpload.uplodFileToCloud(fileInfo.url, file)
          .pipe(first())
          .subscribe( data => {
              this.setUploadEDTFFileInfosStatus(fileInfo.id, 'success');
            },
            error => {
              this.setUploadEDTFFileInfosStatus(fileInfo.id, 'failed');
            });
      }
    });
  }

  setUploadFileInfosStatus(fileId, status) {
    let all = true;
    for (let j = 0; j < this.uploadFilesInfo.length; j++) {
      if (fileId == this.uploadFilesInfo[j].id) {
        this.uploadFilesInfo[j].status = status;
      }
      if (this.uploadFilesInfo[j].status == 'pending') {
        all = false;
      }
    }
    if (all === true) {
      this.serviceDataUpload.setUploadFilesStatusInformation(this._patient['id'], this._patient['discontinued'], this.uploadFilesInfo)
        .pipe(first())
        .subscribe( data => {
          this.dialog.closeAll();
        },
          error => {
            this.dialog.closeAll();
        });
    }
  }

  setUploadEDTFFileInfosStatus(fileId, status) {
    let all = true;
    this.uploadFilesInfo.forEach(ufi => {
      if (fileId == ufi.id) {
        ufi.status = status;
      }
      if (ufi.status == 'pending') {
        all = false;
      }
    });
    if (all === true) {
      this.serviceDataUpload.setUploadEDTFFilesStatusInformation(
        this.state.imageProject, this._patient['id'], this._patient['discontinued'], this.uploadFilesInfo)

        .pipe(first())
        .subscribe( data => {
            this.dialog.closeAll();
          },
          error => {
            this.dialog.closeAll();
          });
    }
  }

  getElementWithFileNameInfo(array, element) {
    const ans = array.find(item => item.name === element.name && item.size === element.size);
    return ans;
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      height: '400px',
      width: '600px',
      disableClose: true,
      data: {files: this.uploadFilesInfo}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.router.navigate(['/imagingproject/upload']);
    });
  }

  toggleEdtfLogic() {
    this.state.isUseEDTFUpload = !this.state.isUseEDTFUpload;
  }

  downloadFile(file) {
    window.location.assign(file.url);
  }
}
