import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MessageDialogComponent } from 'src/app/components/controls/message-dialog/message-dialog.component';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import { MRanoLesionObject } from 'src/app/_models/MRANO/mrano-scoring-model';
import { PrimaryBodyLocationService, ReadingPsmaPetEligibilityService } from 'src/app/_services';
import { PSMAPETLesionModel } from 'src/app/_models/PSMA-PET/psma-pet-lesion-model';
import { PSMAPETLesionEditType, PSMAPETLesionObject } from 'src/app/_models/PSMA-PET/psma-pet-scoring-model';
import { PSMALesionDialogComponent } from '../lesion-dialog/psma-lesion-dialog.component';

@Component({
  selector: 'app-psma-lesion-table',
  templateUrl: './psma-lesion-table.component.html',
  styleUrls: ['./psma-lesion-table.component.css']
})
export class PSMALesionTableComponent implements OnInit {

  constructor(private dialog: MatDialog,
    private readingPSMAPETService:ReadingPsmaPetEligibilityService,
    private toastyService:ToastyService,
    private dialogMessagePreview:MatDialog,
    private primaryBodyLocationService:PrimaryBodyLocationService) { }
  displayedColumns: string[] = [ 'recordNumber','location','locationComment','editLocation','PSMAExpressionGrade','PSMA_POSITIVE','SIGNAL_UPTAKE_VALUE','ald', 'sax', 'ppd','attachment','operations'];
  dataSource = new MatTableDataSource<any>();
  lesionsDataSource:PSMAPETLesionModel[]=[];
  primaryLocations=[];
  subLocations=[];
  locationSubscription;
  public get psmaEditType(): typeof PSMAPETLesionEditType {
    return PSMAPETLesionEditType;
  }
  @ViewChild(MatSort) sort: MatSort;
  @Input('studyId') studyId:number;
  @Input('readingId') readingId:number;
  @Input('visit') visit:any;
  @Input('type') lesionType:string;
  @Input('lesionsData') lesionsData:any[];
  @Input('screenShotData') screenShotData:any;
  @Input('eCRFOnly') eCRFOnly:boolean;
  @Output('lesionExist') lesionExist:EventEmitter<any> = new  EventEmitter<any>();
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
};
  ngOnInit(): void {
    if(this.lesionType == PSMAPETLesionObject.NON_TARGET_LESION){
      this.displayedColumns.splice(7,3)
    }
    this.locationSubscription = this.primaryBodyLocationService.findAll().subscribe(
      (response)=>{
        if(response.responseCode==200){
          this.primaryLocations = response.data;
          this.getAllLesion();
        }
      }
    )
  }
  getAllLesion(){
    this.readingPSMAPETService.modalSpinnerChangeState(true)
    this.readingPSMAPETService.getAllLesions(this.studyId,this.visit.id).subscribe(
      (response)=>{
        if(response['responseCode']==200){
          this.lesionsDataSource =response['data'];
          this.refreshLesionSource(response['data']);
        }
      }
    )
  }

  filterLesions(lesions:PSMAPETLesionModel[]):PSMAPETLesionModel[]{
    let activeLesions = lesions.filter(item=>item.status==='AAA111');
    if (activeLesions.length)
      this.lesionExist.emit({visitId:this.visit.id,present:true})
    else
      this.lesionExist.emit({visitId:this.visit.id,present:false})
    
    let filtered = lesions.filter(
      item => item.lesionType == this.lesionType
    )
    return filtered;
  }
  onEditMeasurement(editType?:string,currentLesionData?:any){
    let data;
    if(currentLesionData){
       data= {
        editMode:true,
        editType:editType,
        currentLesionData:currentLesionData
      }
    }
    if(!this.eCRFOnly){
      this.readingPSMAPETService.onEditLesionMeasurement(data);
      this.goToLesion(currentLesionData,true,true)
    }
    else
      this.onOpenBrainLesion(true,currentLesionData,this.psmaEditType.MEASUREMENT,currentLesionData.lesionType,null)
  }
  goToLesion(lesion?:PSMAPETLesionModel,toast?:boolean,edit?:boolean){
    if(lesion){
      let seriesIdAndSliceNumber ={
        seriesId:lesion?.rois[0].seriesId,
        sliceNumber:lesion?.rois[0].sliceNumber
      }
      this.readingPSMAPETService.openLTViewer(seriesIdAndSliceNumber);
      if(!edit)
          this.readingPSMAPETService.onEditLesionMeasurement();
      if(toast){
        this.toastOptions.title ='ID 60: '+lesion.lesionType+' is loaded successfully and available in the viewer'
        this.toastOptions.msg ='Reader can see the associated ROI in the new slot in the image viewer'
        this.toastOptions.timeout =15000;
        this.toastyService.success(this.toastOptions)
      }
    }
    else{
      this.readingPSMAPETService.onEditLesionMeasurement();
      if(toast){
        this.toastOptions.title ='ID 61: '+lesion.lesionType+' loading failure'
        this.toastOptions.msg ='Lesion is not loaded due to some reasons. Try again or contact support team'
        this.toastyService.error(this.toastOptions)
      }
    }
  }
  onOpenBrainLesion(edit:boolean,data:any,editType:string,lesionObject:string,viewerData?:any){
    if(this.lesionsDataSource.filter(lesion =>{return lesion.status ==='AAA111' && lesion.lesionType ==lesionObject}).length >= 10 && !edit)
      {
        this.toastOptions.title = 'ERROR Cannot add lesion';
        this.toastOptions.msg =`Cannot add more than 10 ${lesionObject.toLowerCase().split('_').join(' ')}s`;
        this.toastyService.error(this.toastOptions);
        return;
      }
    const dialogRef = this.dialog.open(PSMALesionDialogComponent, {
      width: '500px',
      data: {
        studyId :this.studyId,
        readingId:this.readingId,
        visit:this.visit,
        editDialog:edit,
        lesionData: (data? data: {}),
        screenShotData:this.screenShotData,
        editType:editType,
        lesionObject:lesionObject,
        viewerData:viewerData,
        eCRFOnly:this.eCRFOnly
      },
      disableClose:true
    });
    dialogRef.afterClosed().subscribe((result) => {
      if(result)
       this.updateLesion(result,edit)
     });
  }
  generateLesionName(objectType:string){
    if(objectType==PSMAPETLesionObject.NON_TARGET_LESION){
    let objectLength = this.lesionsDataSource.filter((object) =>{return object.lesionType ===PSMAPETLesionObject.NON_TARGET_LESION}).length+1;
     return objectLength < 10 ? ('NT0'+objectLength).toString():('NT'+objectLength).toString()
    }
    else{
      let objectLength = this.lesionsDataSource.filter((object) =>{return object.lesionType ===PSMAPETLesionObject.TARGET_LESION}).length+1;
      return objectLength < 10 ? ('TL0'+objectLength).toString():('TL'+objectLength).toString()
    }
  }
  updateLesion(data:any,edit:boolean){
    const lesion :PSMAPETLesionModel = data;
    lesion.lesionType=this.lesionType;
    if(!edit)
      lesion.recordNumber = this.generateLesionName(lesion.lesionType);
  this.updateLesionService(lesion,edit)      
  }

   onShowImage(screenshot) {
     let title="There is no image to preview";
     let message="";
    if(screenshot){
     // title = 'Modality: ' + screenshot.modalityName + ' Label: ' + screenshot.sequenceLabelName + ' Slice: '
     // + screenshot.sliceNumber;
	  title = '';
      message = '<img width="100%" src="'+ screenshot.fileUrl + '"/>';
    }
    const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
      width: '800px',
      disableClose: true,
      data: {title: title, message: message, html: true}
    });

    dialogMessaegRef.afterClosed().subscribe(result => {
    });
  }
  showGradeTable(){
    this.sort.disabled = true;
     let title = 'PSMA grade expression table';
     let message = `      <table class='table table-bordered table-striped font-small'>
     <thead>
       <tr>
         <th>PSMA expression score</th>
         <th>Grade of PSMA expression</th>
       </tr>
     </thead>
     <tbody>
       <tr>
         <td>Score = 0</td>
         <td>Below blood pool</td>
       </tr>
       <tr>
         <td>Score = 1</td>
         <td>Equal to or above blood pool and lower than liver</td>
       </tr>
       <tr>
         <td>Score = 2</td>
         <td>Equal to or above liver and lower than parotid gland</td>
       </tr>
       <tr>
         <td>Score = 3</td>
         <td>Equal to or above parotid gland</td>
       </tr>
     </tbody>
   </table>`;
    const dialogMessaegRef = this.dialogMessagePreview.open(MessageDialogComponent, {
      width: '800px',
      disableClose: false,
      data: {title: title, message: message, html: true,showOk: false,}
    });

    dialogMessaegRef.afterClosed().subscribe(result => {
      this.sort.disabled = false;
    });
  }
   onDelete(row){
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '200px',
      width: '600px',
      disableClose: true,
      data: {
          title: 'Confirmation',
          message: 'Do you want to delete current lesion?',
          showOk: true,
          showCancel: true,
          html : false
      }
  });

  dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {          
        this.deleteLesionService(row);
      }
  });
   }

   refreshLesionSource(data) {
      let Lesions=  this.filterLesions(data)
    this.dataSource = new MatTableDataSource(Lesions);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch(property) {
        case 'recordNumber': return item.recordNumber;
        case 'location': return this.primaryLocationName(item.primaryLocation);
        case 'locationComment': return item.locationComment;
        case 'editLocation': return item.editLocation;
        case 'PSMAExpressionGrade': return item.PSMAExpressionGrade;
        case 'PSMA_POSITIVE': return item.PSMA_POSITIVE;
        case 'SIGNAL_UPTAKE_VALUE': return item.SIGNAL_UPTAKE_VALUE;
        case 'ald': return item.maxBiDimension.Long;
        case 'sax': return item.maxBiDimension.Short;
        case 'ppd': return item.maxBiDimension.Short*item.maxBiDimension.Long;
        case 'attachment': return item.attachment;
        case 'operations': return item.operations;
        default: return item[property];
      }
    };
    this.dataSource.sort = this.sort;
   this.readingPSMAPETService.modalSpinnerChangeState(false)
  }
  primaryLocationName(id:number){
    let location = 
       this.primaryLocations.find(el=>{return el.id == id});
       if(location)
           return location.name
  }
  updateLesionService(data:any,edit:boolean){
    this.readingPSMAPETService.modalSpinnerChangeState(true)
      this.readingPSMAPETService
      .updateLesionData(this.studyId,data).subscribe(
        response=>{
          if(response['responseCode']==200){
            if(edit)
            {
             this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
              this.toastOptions.msg = 'Lesion is updated';
            }
            else
              {
                this.toastOptions.title = 'ID 42: Lesion has been created successfully';
                this.toastOptions.msg = 'Lesion is now available in the list of lesions';
              }
                this.getAllLesion();
                this.toastyService.success(this.toastOptions);
          }
          else{
            if(edit){
              this.toastOptions.title = 'ID 45: Lesion updating failure';
              this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
            }
            else {
              this.toastOptions.title = 'ID 43: Lesion creation failure';
              this.toastOptions.msg = 'Lesion is not created due to some reason';
            }
            this.toastyService.error(this.toastOptions);
          }
        }
      )
  }
  deleteLesionService(lesion:any){
   this.readingPSMAPETService.modalSpinnerChangeState(true)
    this.readingPSMAPETService
    .deleteLesion(this.studyId,lesion.id).subscribe(
      response=>{
        if(response['responseCode']==200){
          this.toastOptions.title = 'ID 44: Lesion has been updated successfully';
          this.toastOptions.msg = 'Lesion is updated';
          this.toastyService.success(this.toastOptions);
          this.readingPSMAPETService.deleteAnnObjectsSource(lesion.rois);
          this.getAllLesion();
        }
       this.readingPSMAPETService.modalSpinnerChangeState(false)
      },
      error =>{
          this.toastOptions.title = 'ID 45: Lesion updating failure';
          this.toastOptions.msg = 'Lesion is not updated due to some reasons. Resolve issues on the form or contact support team';
          if(lesion.lesionObject==='MIDLINESHIFT'){
            this.toastOptions.title = 'ID 73: Midline shift measurement updating failure';
            this.toastOptions.msg = 'Midline shift is not updated due to some reasons. Resolve issues on the form or contact support team';
          }
         this.toastyService.error(this.toastOptions);
       this.readingPSMAPETService.modalSpinnerChangeState(false)
      }
    )
  }
}

