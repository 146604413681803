import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-edtf-view',
  templateUrl: './edtf-view.component.html',
  styleUrls: ['./edtf-view.component.css']
})
export class EdtfViewComponent implements OnInit {

  @Input()
  public edtf :any;
  @Input() isEnableToldMRI: boolean;

  @Input('modalitiesSelected')
  public modalitiesSelected: any;


  constructor() { }

  ngOnInit() {
  }

  showRadioButtonQuestions(edtfGroup) {
    if (!edtfGroup?.radioButton) return false;

    const radioButtonTitle = edtfGroup.radioButtonTitle.trim().toLowerCase();
    const question1 = 'Is this upload for TOLD MRI visit?'.trim().toLowerCase();
    const question2 = 'Is this Told MRI pre or post dose?'.trim().toLowerCase();
    const questionExists = [question1, question2].includes(radioButtonTitle);

    return !questionExists || this.isEnableToldMRI;
  }
}
