import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { StudyUserService } from '../../../_services';
import { Utils } from '../../../_services/leadtools/lead-tools-utils';
import { Select, Store } from '@ngxs/store';
import { AuthInfoState, AuthInfoStateModel } from '../../../core/data-management/states/auth-info.state';
import { CurrentUserInfoState } from 'src/app/core/data-management/states/user-info.state';
import { GetUserRolesForStudy } from '../../../core/data-management/actions/user-info.action';

@Injectable()
export class StudyUserRolesResolver implements Resolve<any> {
  @Select(AuthInfoState.getAuthInfo) authInfo: Observable<AuthInfoStateModel>;
  @Select(CurrentUserInfoState.getUserRolesForStudy) userRoles: Observable<any>;

  constructor(private studyUserService: StudyUserService, private utils: Utils, private store: Store) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return new Observable<any>(subscriber => {
      this.authInfo.subscribe((authInfo: AuthInfoStateModel) => {
        const project = this.utils.getCurrentProject();
        this.store.dispatch(new GetUserRolesForStudy(project.id, authInfo.userId))
          .subscribe((roles = []) => {
          subscriber.next(roles);
          subscriber.complete();
        }, err => {
          subscriber.error(err);
        });

      }, err => {
        subscriber.error(err);
      });
    });
  }
}
