import {StudyModel} from '../study-model';

export interface CohortsModel {
  id: number;
  name: string;
  study: StudyModel;
  status: CohortsStatus;
  defaults: boolean;
}

export enum CohortsStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED'
}
