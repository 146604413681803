import { Component, OnInit, Input } from '@angular/core';

import * as moment from 'moment';

@Component({
  selector: 'app-visit-status',
  templateUrl: './visit-status.component.html',
  styleUrls: ['./visit-status.component.css']
})
export class VisitStatusComponent implements OnInit {

  lastUploadTime: string;

  @Input()
  public _status: any;

  @Input('status')
  set status(value: any) {
    if(value ==  null)
      value = {};
    this._status = value;

    if(value['upload'] != null){
      this.lastUploadTime = moment(value['upload']['latestUploadTime']).format('DD/MM/YYYY');
    }
  }
  get status(): any {
    return this._status;
  }


  @Input()
  public clickReadMode: Function;

  @Input()
  public clickEditMode: Function;

  @Input()
  public discontinued: any;


  constructor() { }

  ngOnInit() {
  }

  clickVisit(){
  }
}
