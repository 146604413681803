import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {
  DynamikaWebSocket,
  ImagingProjectService,
  ReadingConfigFlexibleService,
  StudySequenceLabelService,
  StudyUserService,
  UserService,
  ViewerAuthenticationService
} from 'src/app/_services';
import {HeelReadingModel} from '../../../_models/HEEL/heel-reading-model';
import {SeriesNavigatorComponent} from '../../../components/controls/series-navigator/series-navigator.component';

import {environment} from '../../../../environments/environment';
import {forkJoin, interval, Observable, Subscription} from 'rxjs';
import {ViewerCoreLtComponent} from '../../Viewer/viewer-core-lt/viewer-core-lt.component';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ReadingStatus} from '../../../core/constants/reading-status';
import {ReadingHeelService} from 'src/app/_services/reading-heel.service';
import {SequenceLabelModel} from 'src/app/_models/ImagingProject/sequence-label-model';
import {FlexibleConfig} from 'src/app/core/interfaces/flexible-config';
import {BaseFlexibleConfig} from 'src/app/_models/ImagingProject/base-flexible-config';
import {Utils} from 'src/app/_services/leadtools/lead-tools-utils';
import * as _ from 'lodash';
import {ActivatedRoute, Data} from '@angular/router';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material/dialog';
import { ReadingNavigatorComponent } from '../reading-navigator/reading-navigator.component';

const ZITELAB_URL = environment.ziteLabUrl;

interface ViewsLayoutConfiguration {
  col: number;
  row: number;
  sizex: number;
  sizey: number;
}

@Component({
  selector: 'app-heel-enthesitis-basic-form',
  templateUrl: './heel-enthesitis-basic-form.component.html',
  styleUrls: ['./heel-enthesitis-basic-form.component.css']
})
export class HeelEnthesitisBasicFormComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('viewerLT', { read: ViewContainerRef }) viewerLT: ViewContainerRef;
  ltViewersSubsctiption: Subscription;
  @ViewChild('viewerContainer', { read: ElementRef }) viewerContainer: ElementRef;
  newLTViewer: any = null;

  openedNumberOfImages = 0;
  form: FormGroup;

  viewsLayoutConfigurations: ViewsLayoutConfiguration[];
  isExpanded = true;
  showModalSpinner = false;
  showModalSpinnerLoadingData = true;

  @ViewChild('seriesNavigator') seriesNavigator: SeriesNavigatorComponent;

  isInitialised: any = {
    'done': false,
    'status': null,
    'attempted': false,
    'zitelabUrl': null,
    'reset': function () {
      this.done = false;
      this.status = false;
      this.attempted = false;
      this.zitelabUrl = null;
    }
  };

  readingLevel: string;
  zitelabInitConfiguration: any = {
    'scoring': null,
    'featuresToScore': null,
    'patientId': null,
    'visitId': null,
    'timepoint': null,
    'reset': function (init: any) {
      init.reset();
    }
  };

  validationMsg: any = {
    'nextPatient': false,
    'clearMsgs': function () {
      this.nextPatient = false;
    }
  };

  visitedSeriesCounter = 0;
  currentVisitId = 0;
  // -----------------------------------------------------------------------------

  userId: number;
  readingList: Array<HeelReadingModel> = [];
  currentReading: HeelReadingModel;
  currentReadingIndex = 0;
  study: any;
  visitConfig: any;
  readingSeries = [];
  readingSeriesDetails = [];
  availableReadingsCount = 0;

  allSeries = [];
  spentSeconds: number;
  subsctiption: Subscription;
  comment: string;
  initialisingZiteLab = false;
  isReadingComplete = false;
  processedPatientIds: number[];
  resizeInterval: any = null;
  availableSequenceLabels: SequenceLabelModel[] = [];
  defaultSequenceLabelIds: number[] = [];

  viewerData: any = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;
  protocolType: string = null;
  submitInvalid = false;
  footSide = 'Both';
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(private heelreadingService: ReadingHeelService,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private studySequenceLabelService: StudySequenceLabelService,
              private dynamikaWebSocket: DynamikaWebSocket,
              private componentFactoryResolver: ComponentFactoryResolver,
              private store: Store,
              private userService: UserService,
              private readingConfigService: ReadingConfigFlexibleService,
              private authenticationService: ViewerAuthenticationService,
              private imagingProjectService: ImagingProjectService,
              private studyUserService: StudyUserService,
              private utils: Utils,
              private route: ActivatedRoute,
              private cdr: ChangeDetectorRef,
              private toastyService: ToastyService,
              private dialog: MatDialog,
              private fb: FormBuilder,
  ) {
    this.route.data.subscribe((data: Data): void => {
      this.protocolType = data.protocolType;
    });
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('HEEL Enthesitis Scoring Form'));
    this.userId = +JSON.parse(localStorage.getItem('userId'));
    this.dynamikaWebSocket.listen('reading_heel_message', null, 'heel-enthesitis-basic-form')
      .subscribe((data) => {

        const receivedMsg = JSON.parse(data);
        if (this.userId === receivedMsg.readerId) {
          // enable the transition to the next reading
          // zitelab patient id equals dynamika reading id, implementationf for andjudication readings
          this.isReadingComplete = (this.readingList.find(r => r.id === receivedMsg.patientId) !== undefined);
        }
      });

    this.viewsLayoutConfigurations = [];
    this.configureLayouts();
    this.isInitialised.status = false;
    this.isInitialised.done = false;

    this.study = JSON.parse(localStorage.getItem('project'));
    this.refreshReadingsList();

    this.readingConfigFlexibleService.getByStudyId(this.study['id']).subscribe(response => {
      const data = response['data'];
      if (data != null) {
        data.forEach(i => {
          if (i.config['endpoint'].name.toLowerCase() === 'heel') {
            this.zitelabInitConfiguration.featuresToScore = i.config.featuresToScore;
            this.zitelabInitConfiguration.fingersToScore = i.config.fingersToScore;
            this.zitelabInitConfiguration.regionsToScore = i.config.regionsToScore;
            this.readingLevel = i.config.readingLevel;
          }
        });
      }
    });

    this.spentSeconds = 0;

    this.subsctiption = interval(1000).subscribe(() => {
      this.spentSeconds++;
    });
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      comment: ['', Validators.maxLength(200)]
    });
  }

  ngAfterViewInit(): void {
    /*this.ltViewersSubsctiption = this.viewerLT.changes.subscribe((comps: QueryList<ViewContainerRef>) => {
      if (!this.viewerLT && comps.first) {
        this.viewerLT = comps.first;
        this.preOpenLTViewer();
      }
    });*/
  }

  openReadingsIdListDialog() {
    const dialogRef = this.dialog.open(ReadingNavigatorComponent, {
      width: '650px',
      height: '556px',
      data: this.readingList,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((readingId: any) => {
      if (readingId && readingId !== this.currentReading.id)
        this.skipCurrentReadingAndUpdateTime(readingId);
    });
  }

  ngOnDestroy(): void {
    if (this.currentReading != null) {
      this.heelreadingService.updateReading(this.study.id, this.currentReading.id, this.comment, this.spentSeconds)
        .subscribe(updateResp => {
          // TODO
        });
    }
    if (this.ltViewersSubsctiption) {
      this.ltViewersSubsctiption.unsubscribe();
    }
  }

  initDefaultSequenceLabels(readingConfig: FlexibleConfig<BaseFlexibleConfig>): void {
    const configSequenceLabels: SequenceLabelModel[] = [];
    readingConfig.config.modalities.forEach(m => {
      m.sequenceLabels.forEach(s => configSequenceLabels.push(s));
    });
    this.availableSequenceLabels = configSequenceLabels;
    if (!!readingConfig.config.anatomySelector) {
      this.defaultSequenceLabelIds = (readingConfig.config.anatomySelector as { id: number }[])
        .filter(label => this.availableSequenceLabels.find(studyLabel => studyLabel.id === label.id)).map(l => l.id);
    } else {
      this.defaultSequenceLabelIds = this.availableSequenceLabels.map(l => l.id);
    }
  }

  refreshReadingsList() {
    this.currentReadingIndex = 0;
    this.readingList = [];

    this.heelreadingService.getReadingByStudyIdAndReaderId(this.study.id, this.userId).subscribe(readingResp => {
      if (readingResp) {
        const read = readingResp['data'];
        if (read != null) {
          this.readingList = read;
          if (this.readingList.length > 0) {
            this.availableReadingsCount = this.readingList.length;
            this.initReading(this.readingList[this.currentReadingIndex]);
          }
        }
      }
      this.showModalSpinnerLoadingData = false;
      setTimeout(() => {
        this.preOpenLTViewer();
      }, 500);
    });
  }

  initReading(reading) {
    this.isReadingComplete = false;
    this.isInitialised.zitelabUrl = reading.initiated ? ZITELAB_URL : null;
    this.currentReading = reading;
    this.readingConfigService.getById(this.currentReading.configId).subscribe(configResp => {
      this.initDefaultSequenceLabels(configResp.data);
    });
    this.currentReading.visits = reading['visits'];
    this.visitedSeriesCounter = 0;
    this.visitConfig = this.currentReading.visits.filter(visit => {
      return visit['noUpload'] === false
    });
    this.currentReading.readingLevel = this.readingLevel;
    this.comment = this.currentReading.comment;
    if (this.currentReading.visits) {
      this.readingSeries = [];
      this.readingSeriesDetails = [];
      const seriesIds = [];
      this.currentReading.visits.forEach(v => {
        v.series.forEach(s => {
          this.readingSeries.push(s);
          seriesIds.push(s.seriesId);
        });
        this.currentVisitId = v.visitConfigId;
        this.zitelabInitConfiguration.scoring = v.scoring;
        this.zitelabInitConfiguration.timepoint = v.timepoint;
      });
      this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).subscribe(seriesResp => {
        if (seriesResp['data'] != null) {
          this.readingSeriesDetails = seriesResp['data'];
          this.readingSeriesDetails.forEach(sd => {
            const visit = this.getVisitInfo(sd.id);
            if (visit != null) {
              sd['seriesVisitName'] = visit.timepoint;
              sd['visitConfigId'] = visit.visitConfigId;
            }
          });
        }
      });
    }

    const viewerDataResults: Array<Observable<any>> = [];

    this.visitConfig.forEach(visits => {
      this.imagingProjectService.getVisitConfig(visits.visitConfigId).subscribe(imagProjResp => {
        const visitConfig = imagProjResp['data'];
        if (visitConfig != null) {
          visitConfig.series.forEach(visitSeries => {
            const series = visitSeries;
            if (series != null) {
              this.allSeries.push(series);
              series.seriesId = visitSeries.id;
              series.seriesInstanceUID = visitSeries.seriesUID;
              const visit = this.getVisitInfo(series.seriesId);
              series.timepoint = visit && visit.timepoint ? visit.timepoint : 'Missing';

              const info = { "selectedPage": "Reading" };
              viewerDataResults.push(this.utils.initViewerData(series, this, info));
            }
          });

          if (viewerDataResults.length) {
            this.viewerDataIsLoading = true;
            forkJoin(viewerDataResults).subscribe(() => {
              this.viewerDataIsReady = true;
              this.viewerDataIsLoading = false;
              this.openLTViewer(['-1']);
              this._updateViewerToolbar();
              // this.newLTViewer.instance.createCell(null);
              if (this.viewerData.studyUserRoles && this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
                this.viewerData.toolbarExcludeItems = ['ShowDicom'];
              }

              this.cdr.detectChanges();

              this.allSeries.forEach(series => {
                const visit = this.getVisitInfo(series.seriesId);
                series.timepoint = visit && visit.timepoint ? visit.timepoint : 'Missing';
                this.viewerData[series.seriesId] = series;
              });

              // if (this.viewerData.studyUserRoles.find(i => i.roleType === 'READER')) {
              //   this.viewerData.toolbarExcludeItems = ['ShowDicom'];
              // }
            });
          } else {
            this.viewerDataIsReady = true;
          }
        }}
      );
    });

    this.zitelabInitConfiguration.visitId = this.currentVisitId;
    // zitelab patient id equals dynamika reading id, implementationf for andjudication readings
    this.zitelabInitConfiguration.patientId = this.currentReading.id;
    // set start  reading time
    const timeNow = new Date();
    // get the time zone offset
    const offSetted = timeNow.getTime() + timeNow.getTimezoneOffset();

    this.isReadingComplete = reading.patientJsonNode !== undefined && reading.patientJsonNode !== null;

    this.submitInvalid = false;

    if (this.currentReading.status.code === ReadingStatus.READING_PENDING_STATUS_CODE) {
      this.heelreadingService.startReading(this.currentReading.studyId, this.currentReading.id).subscribe(response => {
      });
    }
  }

  // getSeriesColor(series) {
  //   const color = series.color.name ? series.color.name : "#000000";
  //   return color;
  // }

  // setSeriesColors() {
  //   var colors = ['rgba(255,0,0,1)', 'rgba(255,200,0,1)', 'rgb(0,150,0,1)',
  //                 'rgb(0,0,255,1)', 'rgb(240,0,240,1)'];
  //   var colorsUnselected = ['rgba(255,0,0,0.6)', 'rgba(255,255,0,0.6)', 'rgb(0,255,0,0.3)',
  //                           'rgb(0,0,255,0.6)', 'rgb(255, 0, 255, 0.5)']
  //   const sort = Math.random() - 0.5;
  //   colors = colors.sort(() => sort);
  //   colorsUnselected = colorsUnselected.sort(() => sort);
  //   const visits = [];
  //   var visitColor = {};
  //   var counter = 0;
  //   for (var i = 0; i < this.allSeries.length; i++) {
  //     var t = this.allSeries[i].timepoint
  //     if (! visits.includes(t)) {
  //       visits.push(t);
  //       visitColor[t] = {
  //         name: colors[counter],
  //         unselected: colorsUnselected[counter]
  //       }
  //       this.allSeries[i].color = {
  //         name: visitColor[t].name,
  //         unselected: visitColor[t].unselected
  //       }
  //       counter++;
  //       if (counter > colors.length) {
  //         counter = 0;
  //       }
  //     } else {
  //       this.allSeries[i].color = visitColor[t];
  //     }
  //   }
  // }

  controlSamriView(toaster: boolean) {
    this.heelreadingService.laodOnSamri(this.study.id, this.currentReading.id)
      .subscribe(response => {
        if (response) {
          if (response['responseCode'] === 200) {
            if(toaster) {
              this.toastOptions.title ='ID 2: Sync Patient Request'
              this.toastOptions.msg ='Sync request has been sent to other devices. Make sure that you are authorized on ZiteLab app'
              this.toastOptions.timeout =5000;
              this.toastyService.info(this.toastOptions)
            }
            console.log('Reading loaded onto SAMRI Portal');
          }
        }
      });
  }

  onReadingCommentChange() {
    this.currentReading.comment = this.comment;
  }

  onNextPatient() {
    const timeNow = new Date();
    // get the time zone offset
    const offSetted = timeNow.getTime() + timeNow.getTimezoneOffset();
    // apply time zone offset
    this.currentReading.finishReadingTime = new Date(offSetted).toLocaleString();

    this.heelreadingService.updateReading(
      this.study.id, this.currentReading.id, this.comment, this.spentSeconds).subscribe(updateResp => {
      if (updateResp['responseCode'] === 200) {
        this.processedPatientIds.push(this.currentReading.patientId);
        this.dynamikaWebSocket.emit('submit', this.currentReading);
        this.skipCurrentReading(this.currentReadingIndex);
        this.isReadingComplete = false;
      }
    });

    this.spentSeconds = 0;
  }

  submitResult() {
    if (this.isReadingComplete) {
      this.heelreadingService.completeReading(
        this.study.id, this.currentReading.id, this.comment, this.spentSeconds).subscribe(updateResp => {
        if (updateResp['responseCode'] === 200) {
          this.dynamikaWebSocket.emit('submit', this.currentReading);
          this.readingList.splice(this.currentReadingIndex, 1);
          this.availableReadingsCount = this.readingList.length;
          this.skipCurrentReading(this.currentReadingIndex);
        }
      });
      this.spentSeconds = 0;
    } else {
      this.submitInvalid = true;
    }
  }

  skipCurrentReadingAndUpdateTime(readingId: number) {
    this.heelreadingService.updateReading(
      this.study.id, this.currentReading.id, this.comment, this.spentSeconds).subscribe(updateResp => {
      this.skipCurrentReading(readingId ? this.readingList.findIndex(reading => reading.id === readingId) : this.currentReadingIndex + 1);
    });
  }

  skipCurrentReading(index: number) {
    // clear viewer
    if (this.newLTViewer) {
      this.newLTViewer.instance.removeAllCells();
    }
    // reset the visited series index.
    this.visitedSeriesCounter = 0;
    // proceed to the next reading without amending the record for the current
    this.currentReadingIndex = index;
    // check if there were readings skipped
    if (this.readingList.length <= this.currentReadingIndex) {
      // cater for the skipped readings
      this.currentReadingIndex = (this.readingList.length > 0) ? 0 : -1;
    }

    if (this.currentReadingIndex >= 0) {
      this.zitelabInitConfiguration.reset(this.isInitialised);
      this.initReading(this.readingList[index]);
    }

    this.spentSeconds = 0;
    this.submitInvalid = false;
  }

  ziteLabReadingInitialisation() {
    this.validationMsg.clearMsgs();
    this.initialisingZiteLab = true;
    this.isInitialised.attempted = true;

    this.heelreadingService.ziteLabReadingInitiation(this.study.id, this.currentReading.id).subscribe(
      response => {
        if (response['responseCode'] === 200) {
          this.logConfiguration('ZiteLab Initiation Response:', response);
          this.isInitialised.done = true;
          this.currentReading.initiated = true;
          // if (this.currentReading.initiated) {
          this.isInitialised.zitelabUrl = ZITELAB_URL;
          // this.currentReading.initiated = true;
          this.isInitialised.attempted = true;
          // make reading available on SAMRI portal
          this.controlSamriView(false);
          // }
        } else {
          this.isInitialised.done = false;
          this.isInitialised.attempted = true;
        }
        this.initialisingZiteLab = false;
      });
  }

  configureLayouts() {
    /* layout configuration for multiple viewers */
    this.viewsLayoutConfigurations.push({ 'col': 0, 'row': 0, 'sizex': 12, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 0, 'sizex': 6, 'sizey': 12 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 6, 'row': 6, 'sizex': 6, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 8, 'row': 6, 'sizex': 4, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
    this.viewsLayoutConfigurations.push({ 'col': 9, 'row': 6, 'sizex': 3, 'sizey': 6 });
  }

  logConfiguration(tag: string, what: any) {
    console.log(tag + '\n', JSON.stringify(what));
  }

  secondsToHHMMSS() {
    const currentSeconds = this.spentSeconds + this.currentReading?.timeSpent;
    let hours: number | string = Math.floor(currentSeconds / 3600);
    let minutes: number | string = Math.floor((currentSeconds - (hours * 3600)) / 60);
    let seconds: number | string = currentSeconds - (hours * 3600) - (minutes * 60);

    if (hours < 10) {
      hours = '0' + hours;
    }
    if (minutes < 10) {
      minutes = '0' + minutes;
    }
    if (seconds < 10) {
      seconds = '0' + seconds;
    }
    return hours + ':' + minutes + ':' + seconds;
  }

  showReadingForm(): void {
    this.isExpanded = true;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  hideReadingForm(): void {
    this.isExpanded = false;
    setTimeout(() => {
      this.onResize();
    }, 100);
  }

  onResize(): void {
    window.dispatchEvent(new Event('resize'));
  }

  openZitelabTab() {
    let userName: string;
    const dbName = environment.zitelab_heel_db_prefix;
    this.userService.getById(this.currentReading.readerId).subscribe(response => {
      if (response) {
        if (response['responseCode'] === 200) {
          userName = response['data'].userName as string;
        }
      }
    });
    this.heelreadingService.laodOnSamri(this.study.id, this.currentReading.id)
      .subscribe(response => {
        if (response) {
          if (response['responseCode'] === 200) {
            window.open(
              `${ZITELAB_URL}/samrisystem/${userName}/${dbName}${this.currentReading.configId}/${this.currentReading.id}`,
              '_blank');
          }
        }
      });
  }

  getVisitInfo(seriesId) {
    let visit = null;
    this.currentReading.visits.forEach(v => {
      const series = v.series.find(s => s.seriesId === seriesId);
      if (series != null) {
        visit = v;
      }
    });
    return visit;
  }

  /* --------------------------- navigator methods --------------------------- */

  onVisitedSeriesCounter(event) {
    this.visitedSeriesCounter = event;
  }

  onCloseAllViewers(event) {
    console.log('--- onCloseAllViewers');

    this.newLTViewer.instance.removeAllCells();
  }

  onOpenViewer(event) {
    if (event != null) {
      const seriesArr = [];
      event.forEach((seriesId) => {
        const series = this.readingSeries.find(s => s.seriesId === seriesId);
        seriesArr.push(series.seriesId);
      });
      this.openLTViewer(seriesArr);

      const toolbarExcludeItems = _.get(this.viewerData, 'toolbarExcludeItems', []);
      if (toolbarExcludeItems.length > 0) {
        _.each(toolbarExcludeItems, i => {
          this.newLTViewer.instance.viewerToolbar.setItemProperty(i, 'disabled', true);
        });
      }
    }
  }

  /* --------------------------- viewer methods --------------------------- */

  openNewLTViewer(seriesInstanceUIDs) {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(
      ViewerCoreLtComponent
    );

    this.newLTViewer = this.viewerLT.createComponent(compFactory);
    this.newLTViewer.instance.seriesIds = seriesInstanceUIDs;
    this.newLTViewer.instance.shouldShowDICOM = false;
    this.newLTViewer.instance.preventClose = true;
    this.newLTViewer.instance.protocolType = this.protocolType;
    this.newLTViewer.instance.useHangingProtocol = true;
    this.newLTViewer.instance.shouldApplyProtocol = true;

    this.newLTViewer.instance.onCellReady.subscribe(() => {
      this._updateViewerToolbar();
    });

    this.newLTViewer.instance.onClose.subscribe(({ serID, shouldCloseViewer }) => {
      this._updateViewerToolbar();
      this.closeLTViewerSeries(serID, shouldCloseViewer);
    });

    this.cdr.detectChanges();
  }

  preOpenLTViewer() {
    // console.log("preOpenLTViewer...");
    this.openNewLTViewer(null);
  }

  openLTViewer(seriesInstanceUIDs: string[] = []) {
    this.viewerContainer.nativeElement.style.display = 'block';

    // console.log("openLTViewer...");
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 1500);


    // console.log('this.newLTViewer:', this.newLTViewer);
    // console.log('this.viewerDataIsReady:', this.viewerDataIsReady);
    if (this.newLTViewer && this.viewerDataIsReady) {
      this.viewerData['readingID'] = this.currentReading.id;
      this.newLTViewer.instance.openNewSer(seriesInstanceUIDs, this.viewerData);
      this.openedNumberOfImages = this.newLTViewer.instance.getNumberOfImages();
      return;
    } else {
      this.openNewLTViewer(seriesInstanceUIDs);
    }
  }

  closeLTViewerSeries(serID, shouldCloseViewer) {
    this.seriesNavigator.unmarkSeriesAsOpenedBySeriesId(serID);

    if (shouldCloseViewer) {
      this.viewerContainer.nativeElement.style.display = 'none';
    }
  }

  private _updateViewerToolbar() {
    const viewerActionsAllowed =
      this.newLTViewer && this.newLTViewer.instance.viewer && this.newLTViewer.instance.viewer.layout.get_items().get_count() > 0;
    if (viewerActionsAllowed) {
      this.newLTViewer.instance.viewerToolbar.setReadOnly(false);
    } else {
      this.newLTViewer.instance.viewerToolbar.setReadOnly(true);
      this.newLTViewer.instance.viewerToolbar.setItemProperty('ViewerLayout', 'readonly', false, true);
    }
    this.cdr.detectChanges();
  }

  getValidity() {
    let isValid = true;
    if (this.comment && this.comment.length > 200) {
      isValid = false;
    }
    return isValid;
  }
}
