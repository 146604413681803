import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {VisitInformation} from '../_models/VisitInformation';

import {environment} from 'src/environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {DataUploadModel} from '../_models/ImagingProject/data-upload-model';


const helper = new JwtHelperService();
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSUploadVersion;
const SERVICE = 'msupload-dot-';



@Injectable({
  providedIn: 'root'
})
export class DataUploadService {

  constructor(private http: HttpClient) {
  }

  getUploaddFileInformation(formData: FormData) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/files/information';
    return this.http.post(requestURL, formData);
  }

  getUploadLinks(formData: FormData) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/files/generate-upload-links';
    return this.http.post(requestURL, formData);
  }

  getUploadEdtfFileInformation(edtfInfo) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/files/information/update-edtf-by-visit-config-id';
    return this.http.post(requestURL, edtfInfo);
  }

  uplodFileToCloud(uploadUrl, formData) {
    return this.http.put(uploadUrl, formData);
  }

  setUploadFilesStatusInformation(patientId, patientDiscontinuedFlag, fileStatuses: object) {
    const data = {
      patientId: patientId,
      patientDiscontinued: patientDiscontinuedFlag,
      files: fileStatuses
    };
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/files/status';
    return this.http.post(requestURL , data);
  }

  setUploadEDTFFilesStatusInformation(imageProjectId, patientId, patientDiscontinuedFlag, fileStatuses: object) {
    const data = {
      patientId: patientId,
      patientDiscontinued: patientDiscontinuedFlag,
      files: fileStatuses
    };
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/files/update-edtf-file-status/' + imageProjectId ;
    return this.http.post(requestURL, data);
  }

  getUploadInformationByStudyId(imagingProjectId: number) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/information/get-list-by-study-id/' + imagingProjectId;
    return this.http.get<DataUploadModel[]>(requestURL);
  }

  getUploadInformationByStudyIdAndSiteConfigsIds(imagingProjectId: number, siteConfigIds: number[]) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/information/get-list-by-study-id-and-site-config-ids/' + imagingProjectId;
    return this.http.put<VisitInformation[]>(requestURL, siteConfigIds);
  }

  getUploadInformationByStudyIdSiteConfigId(siteConfigId: number, imagingProjectId: number) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/information/get-list-by-site-config-id-and-study-id/';
    return this.http.get<VisitInformation[]>(requestURL + siteConfigId + '/' + imagingProjectId);
  }

  getVisitDetails(visitId: number) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/information/by-visit-config/';
    return this.http.get(requestURL + visitId);
  }

  getUploadGeneralInfo(imageProjectId) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/upload-general-info/by-imageproject/' + imageProjectId;
    return this.http.get(requestURL);
  }

  getUploadGeneralInfoById(imageProjectId, generalInfoId) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/upload-general-info/by-general-infoid/' + imageProjectId + '/' + generalInfoId;
    return this.http.get(requestURL);
  }

  updateModalities(imageProjectId, generalInfoId, modalities, reason) {
    const data = {
      id: generalInfoId,
      reasonChangeModalityComment: reason,
      modalities: {
        modality: modalities
      },
      visitId: -1,
      imageProjectId: -1,
      siteConfigId: -1,
      patientId: -1,
      visitConfigId: -1,
    };
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/upload-general-info/modalities-only/' + imageProjectId;
    return this.http.post(requestURL, data);
  }

  getUploadGeneralInfoByVisitConfigId(imageProjectId, visitConfigId) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/upload-general-info/by-visit-config-id/' + imageProjectId + '/' + visitConfigId;
    return this.http.get(requestURL);
  }

  getUploadGeneralInfoByPatientId(imageProjectId, patientId) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/upload-general-info/by-patient-id/' + imageProjectId + '/' + patientId;
    return this.http.get(requestURL);
  }

  getUploadGeneralInfoByVisitConfigIdWithDownloadLinks(imageProjectId, visitConfigId) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/upload-general-info/by-visit-config-id/with-download-links/' + imageProjectId + '/' + visitConfigId;
    return this.http.get(requestURL);
  }

  getUploadGeneralInfoByPatientIdWithDownloadLinks(imageProjectId, patientId) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/upload-general-info/by-patient-id/with-download-links/' + imageProjectId + '/' + patientId;
    return this.http.get(requestURL);
  }

  moveFilesToAnotherVisit(visitId: number, data: {
    srcVisitConfigId: number,
    destVisitConfigId: number,
    srcUploadGeneralInfoId: number,
    reasonForVisitChangeComment: string}) {
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/upload-general-info/move-files-to-another-visit/' + visitId;
    return this.http.post(requestUrl, data);
  }

  moveFileToAnotherVisit(visitId: number, data: {
    srcUploadFileId: number,
    srcVisitConfigId: number,
    destVisitConfigId: number,
    reasonForVisitChangeComment: string}) {
    const requestUrl = 'https://' + API_VERSION + SERVICE + API_URL + '/files/move-files-to-another-visit/' + visitId;
    return this.http.post(requestUrl, data);
  }

  getDucumentDownloadLinks(imageProjectId:number , visitConfigId: number) {
    const requestURL = 'https://' + API_VERSION + SERVICE + API_URL + '/compressed-files/extract/' + imageProjectId + '/' + visitConfigId;
    return this.http.get(requestURL);
  }
}
