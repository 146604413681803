// tslint:disable-next-line:max-line-length
import {AnnJswObject} from "./ann-jsw-object";

export class AnnJswRenderer extends lt.Annotations.Rendering.AnnPolylineObjectRenderer implements lt.Annotations.Engine.IAnnObjectRenderer {
  private annAutoManager: lt.Annotations.Automation.AnnAutomationManager;

  constructor(annAutoManager) {
    super();
    this.annAutoManager = annAutoManager;
  }

  // Override the Render method in order to draw the 3 points as the user creates them.
  render(mapper: lt.Annotations.Engine.AnnContainerMapper, annObject: lt.Annotations.Engine.AnnObject) {
    // tslint:disable-next-line:max-line-length
    const engine: lt.Annotations.Rendering.AnnHtml5RenderingEngine = <lt.Annotations.Rendering.AnnHtml5RenderingEngine>super.get_renderingEngine();
    if (!engine) {
      return;
    }

    const context = engine.context;
    if (!context) {
      return;
    }

    const jswObject = annObject as AnnJswObject;
    context.save();

    // tslint:disable-next-line:max-line-length
    lt.Annotations.Rendering.AnnHtml5RenderingEngine.setStroke(context, lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('orange'), lt.LeadLengthD.create(2)));

    const firstLinePointsOrig = jswObject.firstLinePoints;

    let drawRawPoints = true;
    if (firstLinePointsOrig && firstLinePointsOrig.length > 0) {
      const firstLinePoints = mapper.pointsFromContainerCoordinates(firstLinePointsOrig, annObject.fixedStateOperations);
      this.drawPoints(context, firstLinePoints);
      drawRawPoints = false;
    }

    const secondLinePointsOrig = jswObject.secondLinePoints;
    if (secondLinePointsOrig && secondLinePointsOrig.length > 0) {
      const secondLinePoints = mapper.pointsFromContainerCoordinates(secondLinePointsOrig, annObject.fixedStateOperations);
      this.drawPoints(context, secondLinePoints);
      drawRawPoints = false;
    }

    if (drawRawPoints && annObject.points && annObject.points.toArray().length > 0) {
      const rawPoints = mapper.pointsFromContainerCoordinates(annObject.points.toArray(), annObject.fixedStateOperations);
      this.drawPoints(context, rawPoints);
    }

    const distance = jswObject.distance;

    let rulerObject: lt.Annotations.Engine.AnnPolyRulerObject;
    if (distance) {
      const rulerRenderer = this.renderingEngine.renderers[lt.Annotations.Engine.AnnObject.rulerObjectId];
      rulerRenderer.initialize(this.renderingEngine);
      rulerObject = new lt.Annotations.Engine.AnnPolyRulerObject();
      rulerObject.points.add(distance.first);
      rulerObject.points.add(distance.second);
      rulerObject.stroke.strokeThickness = lt.LeadLengthD.create(0.25);
      rulerObject.measurementUnit = lt.Annotations.Engine.AnnUnit.millimeter;
      const rulerLength = rulerObject.labels.RulerLength;
      rulerLength.isVisible = true;
      rulerLength.background = lt.Annotations.Engine.AnnSolidColorBrush.create('white');
      rulerLength.font.fontWeight = 5;
      rulerLength.foreground = lt.Annotations.Engine.AnnSolidColorBrush.create('orange');

      rulerRenderer.render(mapper, rulerObject);

      const first = mapper.pointFromContainerCoordinates(distance.first, annObject.fixedStateOperations);
      const second = mapper.pointFromContainerCoordinates(distance.second, annObject.fixedStateOperations);

      engine.context.beginPath();
      context.moveTo(first.x, first.y);
      context.lineTo(second.x, second.y);
    }

    engine.context.closePath();

    let label = jswObject.labels['InitialDistance'];
    if (!label && rulerObject) {
      label = jswObject.labels['JswDistance'];
      label.text = rulerObject.getRulerLengthAsString(mapper.get_calibrationScale() * jswObject.distanceScale);
    }

    if (label) {
      const hasJswTarget = !!jswObject.metadata['JswTarget'];
      label.foreground = lt.Annotations.Engine.AnnSolidColorBrush.create('White');
      label.background = lt.Annotations.Engine.AnnSolidColorBrush.create(hasJswTarget ? 'Green' : 'Blue' );
      const allPointsOrig = [...firstLinePointsOrig, ...secondLinePointsOrig];

      if (allPointsOrig.length > 0) {
        let labelPoint;
        if (distance) {
          labelPoint = distance.first.y > distance.second.y ? distance.first : distance.second;
        } else {
          let maxXpoint = allPointsOrig[0];
          allPointsOrig.forEach(p => {
            if (p.x > maxXpoint.x) {
              maxXpoint = p;
            }
          });

          labelPoint = maxXpoint;
        }

        let maxYpoint = allPointsOrig[0];
        allPointsOrig.forEach(p => {
          if (p.y > maxYpoint.y) {
            maxYpoint = p;
          }
        });

        labelPoint = lt.LeadPointD.create(labelPoint.x, maxYpoint.y);

        // tslint:disable-next-line:max-line-length
        label.originalPosition = lt.LeadPointD.create(labelPoint.x * 1.01, labelPoint.y * 1.01);
      }
    }

    context.restore();
  }

  drawPoints(context: CanvasRenderingContext2D, points) {
    context.beginPath();
    context.moveTo(points[0].x, points[0].y);
    for (let x = 0; x < points.length; x++) {
      const point = points[x];
      if (!point.isEmpty) {
        context.lineTo(point.x, point.y);
      }
    }
    context.stroke();
    context.closePath();
  }
}

