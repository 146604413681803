import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AbstractControl, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {CohortsModel} from '../../../../_models/ImagingProject/ImagimgProjectCohorts/cohorts-model';
import {CohortsService} from '../../../../_services';

@Component({
  selector: 'app-imaging-project-cohorts-dialog',
  templateUrl: './imaging-project-cohorts-dialog.component.html',
  styleUrls: ['./imaging-project-cohorts-dialog.component.css']
})
export class ImagingProjectCohortsDialogComponent implements OnInit {

  nameFormControl =  new FormControl('', [Validators.required,
    Validators.maxLength(200),
    Validators.minLength(1),
    this.validateUniqueName]);

  defaultFormControl = new FormControl(false);

  constructor(private cohortsService: CohortsService,
              public dialog: MatDialog,
              public dialogRef: MatDialogRef<CohortDialogModel>,
              @Inject(MAT_DIALOG_DATA) public data: CohortDialogModel) {

  }

  ngOnInit(): void {
    this.nameFormControl.setValue(this.data.cohort.name);
    this.defaultFormControl.setValue(this.data.cohort.defaults);
  }

  get validateUniqueName() {
    return (control: AbstractControl): ValidationErrors | null => {
      let names = this.data.studyCohortsList.filter(p => p.name === control.value
        && 'ACTIVE' === p.status.toUpperCase());
      if (names.length === 1) {
        if (names[0].id === this.data.cohort.id) {
          names = [];
        }
      }
      return names.length > 0 ? {duplicateValueError: 'Duplicate. value already exists'} : null;
    };
  }

  onNameChanged(event): void {
    this.nameFormControl.updateValueAndValidity();
  }

  save(): void {
    if ( this.data.mode === CohortDialogMode.NEW ) {
      const cohort = {
        name: this.nameFormControl.value,
        defaults: this.defaultFormControl.value
      };

      this.cohortsService.create(this.data.cohort.study.id, <CohortsModel>cohort).subscribe(cResp => {
        this.dialogRef.close(cResp);
      });
    }
    else {
      const cohort = {
        id: this.data.cohort.id,
        name: this.nameFormControl.value,
        defaults: this.defaultFormControl.value
      };
      this.cohortsService.updateCohort(<CohortsModel>cohort).subscribe(cResp => {
        this.dialogRef.close(cResp);
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

}

export enum CohortDialogMode {
  NEW = 'new',
  EDIT = 'edit',
  ACTIVATE = 'activate',
  DISABLE = 'disable'
}

export interface CohortDialogModel {
  mode: CohortDialogMode;
  cohort: CohortsModel;
  studyCohortsList: CohortsModel[];
}
