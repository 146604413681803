
import { Observable } from 'rxjs';
import { ViewerAuditableContext } from 'src/app/_models/viewer/ViewerConfigModel';
/**
 * @description
 * Image object retrieve service interface for Dynamika image visualization component.
 *
 */

export abstract class ObjectRetrieveService {
    /**
     * Get series stacks as array of SopInstanceUIDs. Each SopInstanceUID can be used to retrieve a frame/slice of the serieas.
     * @param seriesInstanceUID
     * @param userData
     */
    abstract getSeriesStacks(seriesInstanceUID: string, userData?): Observable<any>;
    /**
         * upload the series if it failed at loading wuth custom upload API
         * @param bucket
         * @param seriesId
         * @param seriesInstanceUID
         */
    abstract customUpload(bucket: string, seriesId: string, seriesInstanceUID: string, audit?: ViewerAuditableContext): Observable<any>;
    /**
     * Get series stacks as array of SopInstanceUIDs. Each SopInstanceUID can be used to retrieve a frame/slice of the serieas.
     * @param seriesInstanceUID
     * @param orderBy
     * @param orderType
     * @param userData
     */
    abstract getInstanceStacks(seriesInstanceUID: string, orderBy?: string, orderType?: string, userData?): Observable<any>;

    /**
     * Get series frame/slice DICOM headers and values as a JSON string. Can be used to search DICOM header values.
     * @param studyInstanceUID
     * @param seriesInstanceUID
     * @param sopInstanceUID
     */
    abstract getDicomJSON(studyInstanceUID: string, seriesInstanceUID: string, sopInstanceUID: string): Observable<any>;

    /**
     * Get DCE series stacks as a dictionnary providing acquisition times and SopInstanceUIDs grouped by frame.
     * @param bucket
     * @param seriesId
     * @param userData
     */
    abstract getDCEStacks(bucket: string, seriesId: string, userData?): Observable<any>;

    /**
     * Get image of an instance as a RAW data. Can be used to get PNG or JPG image of an instance (slice)
     * @param sopInstanceUID
     * @param frame
     * @param mime
     * @param width
     * @param height
     * @param bitsPerPixel
     * @param qualityFactor
     * @param userData
     */
    abstract getImage(sopInstanceUID: string, frame: number, mime: string, width: number, height?: number, bitsPerPixel?: number, qualityFactor?: number, userData?)

    /**
     * Get image pixel data of an instance as a JSON array of intensities.
     * @param sopInstanceUID
     * @param width
     * @param height
     * @param wlData
     */
    abstract getImagePixelData(sopInstanceUID: string, width: number, height: number): Observable<any>;

    /**
     * Get pixel data of an instance as a JSON object.
     * @param sopInstanceUID
     * @param useScale
     */
    abstract getPixelData(sopInstanceUID: string, useScale: boolean): Observable<any>;

    /**
     * Get frame based on a MPR type
     * @param id
     * @param mprType
     * @param index
     */
    abstract getMPRFrame(id: string, mprType: string, index: string);

    /**
     * Get presentation annotations
     * @param sopInstanceUID
     * @param userData
     */
    abstract getPresentationAnnotations(sopInstanceUID: string, userData, person?: string);
}

