import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import 'rxjs/add/operator/debounceTime';

@Component({
  selector: 'app-status-icon',
  templateUrl: './status-icon.component.html',
  styleUrls: ['./status-icon.component.css']
})
export class StatusIconComponent implements OnInit {

  availableStatuses = ['none', 'not_set', 'success', 'failed', 'holdon', 'generate_event'];

  @Input()
  public statusList: string[]; // ['success', 'failed', 'holdon'];

  @Output()
  public updateStatus = new EventEmitter();

  @Input()
  public block: boolean;

  @Input()
  public lock: boolean;

  status = 'none';

  @Input()
  set initialStatus(newStatus) {
    this.status = newStatus;
  }

  constructor() {
  }

  ngOnInit() {
  }

  switchNextStatus() {
    if (this.block === false && this.lock === false) {
      let index = this.statusList.indexOf(this.status);
      if (index === this.statusList.length - 1) {
        index = 0;
      } else if (index < this.statusList.length - 1) {
        index++;
      }
      this.status = this.statusList[index];
      this.updateStatus.emit(this.status);
    }
  }
}
