import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-mrano-efficacy-response-comment-dialog',
  templateUrl: './mrano-efficacy-response-comment-dialog.component.html',
  styleUrls: ['./mrano-efficacy-response-comment-dialog.component.css']
})
export class MranoEfficacyResponseCommentDialogComponent implements OnInit {
  finalComment: string;

  constructor(public dialogRef: MatDialogRef<MranoEfficacyResponseCommentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {comment:string}) { }

  ngOnInit(): void {
    this.finalComment = this.data.comment;
  }

  onSubmitComment() {
    this.dialogRef.close({save: true, comment: this.finalComment})
  }

  onCloseComment() {
    this.dialogRef.close({save: false})
  }
}
