import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../../_services";
import {ToastService} from "../../../_services/internal/toast.service";

export interface ConfirmSigningDialogData {
  title: string;
  studyId: number;
  patientId: number;
  visitConfigId: number;
}

@Component({
  selector: 'app-confirm-signing-dialog',
  templateUrl: './confirm-signing-dialog.component.html',
  styleUrls: ['./confirm-signing-dialog.component.css']
})
export class ConfirmSigningDialogComponent implements OnInit {
  signForm: FormGroup;
  loading = false;

  constructor(
    public dialogRef: MatDialogRef<ConfirmSigningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmSigningDialogData,
    private authenticationService: AuthenticationService,
  ) {
  }

  ngOnInit(): void {
    this.signForm = new FormGroup({
      username: new FormControl(null, [Validators.required]),
      password: new FormControl(null, [Validators.required]),
      confirmSign: new FormControl(null, [Validators.pattern('true'), Validators.required]),
    });

    this.loading = false;
  }

  onSign() {
    const context = {
      studyId: this.data.studyId,
      patientId: this.data.patientId,
      visitConfigId: this.data.visitConfigId
    };

    this.loading = true;
    this.authenticationService.createToken(this.signForm.value.username.toLowerCase(), this.signForm.value.password, 'SIGN', context)
      .subscribe(
        response => {
          if (response?.data?.jwt) {
            this.dialogRef.close({ jwt: response.data.jwt });
          } else {
            this.dialogRef.close();
          }
        },
        error => {
          this.dialogRef.close();
        }
      );
  }

  onClose() {
    this.dialogRef.close({ canceled: true });
  }
}
