import {BaseFlexibleConfig} from '../base-flexible-config';
import { ModalityModel } from '../modality-model';
import { VisitType } from '../patient-model';
import {SequenceLabelModel} from '../sequence-label-model';

export interface MranoBasicConfigModel extends BaseFlexibleConfig {
  hideVisitHistory: boolean;
  imageViewerConfiguration: string;
  readinTypeConfiguration:[];
  readingLevelConfiguration:[];
  anatomySelector: SequenceLabelModel[] | {id: number}[];
  assessmentConfigs?: any[];
  visit?: any[];
  mranoEfficacyDependency?: string;
  mranoAssessmentDependency?: string;
  moderationReadingConfiguratorType?: string;
  midlineShiftCuttOff?: number;
  sppdCuttOff?: number;
  totalVolumeCuttOff?: number;
  totalVolumeCuttOffEnable?: boolean;
  gbmSegmentationEnable?: boolean;
  enableToldMRI?: boolean;
}

export class MRANOEfficacyVisitModel{
  id?: number;
  created?: number;
  name: string;
  type?: VisitType;
  durationTimeUnit?: string;
  durationTimeValue?: number;
  baseline?: boolean;
  repeatAllowed?: boolean;
  modalities?: ModalityModel[];
  isAvailable = true;
}
