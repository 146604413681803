import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MranoEfficacyResponseOptions } from 'src/app/_models/Oncology/global-lesion-model';


@Component({
  selector: 'app-mrano-efficacy-response-options',
  templateUrl: './mrano-efficacy-response-options.component.html',
  styleUrls: ['./mrano-efficacy-response-options.component.css']
})
export class MranoefficacyresponseoptionsComponent implements OnInit {
  reaponseOptions = MranoEfficacyResponseOptions.OVERALL;
  finalResponse: string;
  disableSubmitButton: boolean = true;

  constructor(public dialogRef: MatDialogRef<MranoefficacyresponseoptionsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {response:string}) { }

  ngOnInit(): void {
    this.finalResponse = this.data.response;
  }

  onSubmitResponse(save: boolean) {
    console.log(this.finalResponse);
    if(save)
    this.dialogRef.close(this.finalResponse)
    else 
    this.dialogRef.close()
  }

  onChangeResponse() {
    this.disableSubmitButton = this.finalResponse === this.data.response;
  }
}
