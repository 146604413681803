import {AnnKmaObject} from './ann-kma-object';

export class AnnKmaDrawDesigner extends lt.Annotations.Designers.AnnDrawDesigner {

  // tslint:disable-next-line:max-line-length
  constructor(automationControl: lt.Annotations.Engine.IAnnAutomationControl, container: lt.Annotations.Engine.AnnContainer, annKmaObject: lt.Annotations.Engine.AnnObject) {
    super(automationControl, container, annKmaObject);
  }

  // override the onPointerDown method and add 3 points for our triangle
  onPointerDown(sender: lt.Annotations.Engine.AnnContainer, e: lt.Annotations.Engine.AnnPointerEventArgs): boolean {
    console.log('AnnKmaDrawDesigner onPointerDown');
    let handled = super.onPointerDown(sender, e);

    if (this.targetObject.points.count < 3) {
      this.targetObject.tag = 'drawing';
      if (e.button === lt.Annotations.Engine.AnnMouseButton.left) {
        const t = this.targetObject as AnnKmaObject;

        const firstPointStart = e.location.clone();
        firstPointStart.y -= 800;
        t.points.add(this.snapPointToGrid(firstPointStart, false));

        const firstPointEnd = e.location.clone();
        t.points.add(this.snapPointToGrid(firstPointEnd, false));

        const secondPointStart = e.location.clone();
        t.points.add(this.snapPointToGrid(secondPointStart, false));

        const secondPointEnd = e.location.clone();
        secondPointEnd.y += 800;
        t.points.add(this.snapPointToGrid(secondPointEnd, false));

        this.startWorking();
        handled = true;
      }
    }
    this.invalidate(lt.LeadRectD.empty);
    return handled;
  }

  // override the onPointerUp method and end the drawing when we have our 3 points
  onPointerUp(sender: lt.Annotations.Engine.AnnContainer, e: lt.Annotations.Engine.AnnPointerEventArgs): boolean {
    let handled = super.onPointerUp(sender, e);
    handled = true;
    if (this.targetObject.points.count >= 3) {
      this.targetObject.tag = null;
      this.endWorking();
    }
    return handled;
  }
}
