import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;
const LOCAL_API_URL = 'http://localhost:8080/api';

@Injectable({
  providedIn: 'root'
})
export class VisitService {

  constructor(private http: HttpClient) {
  }

  create(visit: any, studyId: number) {
    return this.http.post(requestURL + `/visit/${studyId}`, visit);
    // return this.http.post(LOCAL_API_URL + `/visit/${studyId}`, visit);
  }

  update(visit: any) {
    return this.http.put(requestURL + `/visit`, visit);
    // return this.http.put(LOCAL_API_URL + `/visit`, visit);
  }

  delete(id: number) {
    return this.http.delete(requestURL + `/visit/` + id);
    // return this.http.delete(LOCAL_API_URL + `/visit/` + id);
  }

  getById(id: number) {
    return this.http.get(requestURL + `/visit/` + id);
    // return this.http.get(LOCAL_API_URL + `/visit/` + id);
  }

  getAll() {
    return this.http.get(requestURL + `/visit/retrieve-all`);
    // return this.http.get(LOCAL_API_URL + `/visit/retrieve-all`);
  }

  getConfigs(visitsConfigsIds: number[]): Observable<any> {
    return this.http.put(`${requestURL}/visit-config/get-visitconfigs-by-ids`, visitsConfigsIds);
  }
}
