import _ from 'lodash';

export class ViewerToolbar {
  private static instance?: ViewerToolbar;
  private _menuItems = {
    name: 'main',
    items: [
      {
        id: 'ViewerLayout',
        action: 'OnViewerLayout',
        caption: 'Viewer Layout',
        tooltip: 'Change Viewer Layout',
        type: 'button',
        cssIconClass: 'SeriesLayout',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'ViewerLayout1',
            action: 'OnViewerLayout1x1',
            caption: '1x1 Viewer Layout',
            tooltip: '1x1 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayout2',
            action: 'OnViewerLayout1x2',
            caption: '1x2 Viewer Layout',
            tooltip: '1x2 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayout3',
            action: 'OnViewerLayout1x3',
            caption: '1x3 Viewer Layout',
            tooltip: '1x3 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayout4',
            action: 'OnViewerLayout1x4',
            caption: '1x4 Viewer Layout',
            tooltip: '1x4 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayout5',
            action: 'OnViewerLayout2x1',
            caption: '2x1 Viewer Layout',
            tooltip: '2x1 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayout6',
            action: 'OnViewerLayout2x2',
            caption: '2x2 Viewer Layout',
            tooltip: '2x2 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayout7',
            action: 'OnViewerLayout2x3',
            caption: '2x3 Viewer Layout',
            tooltip: '2x3 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayout8',
            action: 'OnViewerLayout2x4',
            caption: '2x4 Viewer Layout',
            tooltip: '2x4 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayout9',
            action: 'OnViewerLayout3x3',
            caption: '3x3 Viewer Layout',
            tooltip: '3x3 Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'CustomViewerLayout',
            action: 'OnCustomViewerLayout',
            caption: 'Viewer Layout', tooltip: 'Custom Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SaveAnnSeperator',
            action: '',
            caption: 'Sep', tooltip: '',
            type: 'seperator',
            cssIconClass: '',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ViewerLayoutAuto',
            action: 'OnViewerLayoutAuto',
            caption: 'Auto Viewer Layout', tooltip: 'Auto Viewer Layout',
            type: 'button',
            cssIconClass: 'SeriesLayout',
            disabled: false, readonly: true,
            items: []
          },
        ]
      },
      {
        id: 'SynchronizeSeries',
        action: 'OnSynchronizeSeries',
        caption: 'Auto Synch.',
        tooltip: 'Synchronize Series',
        type: 'button',
        cssIconClass: 'Synchronization',
        disabled: false,
        readonly: false,
        items: [
          // {
          //   id: 'IntraVisitSynch',
          //   action: 'OnIntraVisitSynch',
          //   caption: 'Synchronization',
          //   tooltip: 'Enable Intra-Visit Synch',
          //   type: 'button',
          //   cssIconClass: 'Synchronization',
          //   disabled: true,
          //   readonly: false,
          //   items: []
          // },
          {
            id: 'AutoSynchronization',
            action: 'OnAutoSynchronization',
            caption: 'Auto Synch.',
            tooltip: 'Auto Synchronization',
            type: 'button',
            cssIconClass: 'Synchronization',
            disabled: false,
            readonly: true,
            items: []
          },
          {
            id: 'ManualSynchronization',
            action: 'OnManualSynchronization',
            caption: 'Manual Synch.',
            tooltip: 'Manual Synchronization',
            type: 'button',
            cssIconClass: 'Synchronization',
            disabled: false,
            readonly: true,
            items: []
          },
          {
            id: 'DisableAllSynch',
            action: 'OnDisableAllSynch',
            caption: 'Synch. Disabled',
            tooltip: 'Disable Synchronization',
            type: 'button',
            cssIconClass: 'Synchronization',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SaveAnnSeperator',
            action: '',
            caption: 'Sep', tooltip: '',
            type: 'seperator',
            cssIconClass: '',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SlicePositionSynch',
            action: 'OnSlicePositionSynch',
            caption: 'Synchronization',
            tooltip: 'Disable Slice Position Synch',
            type: 'button',
            cssIconClass: 'Synchronization',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'PanZoomSynch',
            action: 'OnPanZoomSynch',
            caption: 'Synchronization',
            tooltip: 'Disable Pan & Zoom Synch',
            type: 'button',
            cssIconClass: 'Synchronization',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'WindowLevelSynch',
            action: 'OnWindowLevelSynch',
            caption: 'Synchronization',
            tooltip: 'Enable Window-Level Synch',
            type: 'button',
            cssIconClass: 'Synchronization',
            disabled: false,
            readonly: false,
            items: []
          },
        ]
      },
      // {
      //   id: 'Magnify',
      //   action: 'InteractiveMagnifyGlass',
      //   caption: 'Magnify', tooltip: 'Magnify',
      //   type: 'button',
      //   cssIconClass: 'Magnify',
      //   disabled: true,
      //   readonly: false,
      //   items: []
      // },
      {
        id: 'Select',
        action: 'OnAnnotationSelect',
        caption: 'Select', tooltip: 'Select',
        type: 'button',
        cssIconClass: 'Select',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'Pan',
        action: 'Pan',
        caption: 'Pan', tooltip: 'Pan',
        type: 'button',
        cssIconClass: 'Pan',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'Zoom',
        action: 'ZoomToInteractiveMode',
        caption: 'Zoom', tooltip: 'Zoom',
        type: 'button',
        cssIconClass: 'Zoom',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'WindowLevel',
        action: 'WindowLevelInteractiveMode',
        caption: 'W/L', tooltip: 'Window Level',
        type: 'button',
        cssIconClass: 'WindowLevel',
        disabled: false,
        readonly: false,
        items: []
      },
      // {
      //     id: 'Stack',
      //     action: 'InteractiveStack',
      //     caption: 'Stack', tooltip: 'Stack',
      //     type: 'button',
      //     cssIconClass: 'Stack',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      {
        id: 'Reload',
        action: 'OnReload',
        caption: 'Reload', tooltip: 'Reload',
        type: 'button',
        cssIconClass: 'Reload',
        disabled: false,
        readonly: false,
        items: []
      },
      // {
      //   id: 'JSW',
      //   action: 'OnJSW',
      //   caption: 'JSW tools',
      //   tooltip: 'JSW tools',
      //   type: 'button',
      //   cssIconClass: 'SliceSync',
      //   disabled: false,
      //   readonly: false,
      //   items: [
      //     {
      //       id: 'ShowAIContours',
      //       action: 'OnShowAIContours',
      //       caption: 'Show AI Contours',
      //       tooltip: 'Show AI Contours',
      //       type: 'button',
      //       cssIconClass: 'Clear',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     },
      //     {
      //       id: 'ResetAIResult',
      //       action: 'OnResetAIResult',
      //       caption: 'Reset AI Result',
      //       tooltip: 'Reset AI Result',
      //       type: 'button',
      //       cssIconClass: 'Clear',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     },
      //     {
      //       id: 'SaveAnnSeperator',
      //       action: '',
      //       caption: 'Sep', tooltip: '',
      //       type: 'seperator',
      //       cssIconClass: '',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     },
      //     {
      //       id: 'DrawFirstContour',
      //       action: 'OnDrawFirstContour',
      //       caption: 'Draw First Contour (Right)',
      //       tooltip: 'Draw First Contour (Right)',
      //       type: 'button',
      //       cssIconClass: 'SliceSync',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     },
      //     {
      //       id: 'DrawSecondContour',
      //       action: 'OnDrawSecondContour',
      //       caption: 'Draw Second Contour (Right)',
      //       tooltip: 'Draw Second Contour (Right)',
      //       type: 'button',
      //       cssIconClass: 'RotateClock',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     },
      //     {
      //       id: 'DrawFirstContourLeft',
      //       action: 'OnDrawFirstContourLeft',
      //       caption: 'Draw First Contour (Left)',
      //       tooltip: 'Draw First Contour (Left)',
      //       type: 'button',
      //       cssIconClass: 'SliceSync',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     },
      //     {
      //       id: 'DrawSecondContourLeft',
      //       action: 'OnDrawSecondContourLeft',
      //       caption: 'Draw Second Contour (Left)',
      //       tooltip: 'Draw Second Contour (Left)',
      //       type: 'button',
      //       cssIconClass: 'RotateClock',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     },
      //     {
      //       id: 'SaveAnnSeperator',
      //       action: '',
      //       caption: 'Sep', tooltip: '',
      //       type: 'seperator',
      //       cssIconClass: '',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     },
      //     {
      //       id: 'ConfirmContours',
      //       action: 'OnConfirmContours',
      //       caption: 'Confirm Contours',
      //       tooltip: 'Confirm Contours',
      //       type: 'button',
      //       cssIconClass: 'Clear',
      //       disabled: false,
      //       readonly: false,
      //       items: []
      //     }
      //   ]
      // },
      {
        id: 'KNEE_JSW',
        caption: 'KNEE JSW',
        tooltip: 'KNEE JSW',
        type: 'button',
        cssIconClass: 'SliceSync',
        disabled: true,
        readonly: false,
        items: [
          {
            id: 'DrawKneeContours',
            action: 'OnDrawKneeContours',
            caption: 'Draw Knee Contours',
            tooltip: 'Draw Knee Contours',
            type: 'button',
            cssIconClass: 'CalibrateRulerAnn',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'FillRightKneeLateral',
            action: 'OnFillRightKneeLateral',
            caption: 'Fill Right Knee Lateral',
            tooltip: 'Fill Right Knee Lateral',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: true,
            items: []
          },
          {
            id: 'FillRightKneeMedial',
            action: 'OnFillRightKneeMedial',
            caption: 'Fill Right Knee Medial',
            tooltip: 'Fill Right Knee Medial',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: true,
            items: []
          },
          {
            id: 'FillLeftKneeMedial',
            action: 'OnFillLeftKneeMedial',
            caption: 'Fill Left Knee Medial',
            tooltip: 'Fill Left Knee Medial',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: true,
            items: []
          },
          {
            id: 'FillLeftKneeLateral',
            action: 'OnFillLeftKneeLateral',
            caption: 'Fill Left Knee Lateral',
            tooltip: 'Fill Left Knee Lateral',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: true,
            items: []
          },
          // {
          //   id: 'ShowAIContours2',
          //   action: 'OnShowAIContours2',
          //   caption: 'Show AI Contours',
          //   tooltip: 'Show AI Contours',
          //   type: 'button',
          //   cssIconClass: 'Clear',
          //   disabled: false,
          //   readonly: false,
          //   items: []
          // },
          // {
          //   id: 'ResetAIResult2',
          //   action: 'OnResetAIResult2',
          //   caption: 'Reset AI Result',
          //   tooltip: 'Reset AI Result',
          //   type: 'button',
          //   cssIconClass: 'Clear',
          //   disabled: false,
          //   readonly: false,
          //   items: []
          // },
          {
            id: 'SaveROI',
            action: 'OnSaveROI',
            caption: 'Save ROI',
            tooltip: 'Save ROI',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: true,
            items: []
          },
        ]
      },
      {
        id: 'HIPS_JSW',
        caption: 'HIPS JSW',
        tooltip: 'HIPS JSW',
        type: 'button',
        cssIconClass: 'SliceSync',
        disabled: true,
        readonly: false,
        items: [
          {
            id: 'DrawHipContours',
            action: 'OnDrawHipContours',
            caption: 'Draw Hip Contours',
            tooltip: 'Draw Hip Contours',
            type: 'button',
            cssIconClass: 'CalibrateRulerAnn',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'FillRightHip',
            action: 'OnFillRightHip',
            caption: 'Fill Right Hip',
            tooltip: 'Fill Right Hip',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: true,
            items: []
          },
          {
            id: 'FillLeftHip',
            action: 'OnFillLeftHip',
            caption: 'Fill Left Hip',
            tooltip: 'Fill Left Hip',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: true,
            items: []
          },
          {
            id: 'ToggleJswHipDistanceCalculation',
            action: 'OnToggleJswDistanceCalculation',
            caption: 'Toggle Calculation',
            tooltip: 'Toggle Calculation',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: true,
            items: []
          },
          {
            id: 'SaveROI',
            action: 'OnSaveROI',
            caption: 'Save ROI',
            tooltip: 'Save ROI',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: false,
            items: []
          },
        ]
      },
      {
        id: 'KMA',
        caption: 'KMA',
        tooltip: 'KMA',
        type: 'button',
        cssIconClass: 'SliceSync',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'DrawKma',
            action: 'OnDrawKmaContours',
            caption: 'Draw KMA',
            tooltip: 'Draw KMA',
            type: 'button',
            cssIconClass: 'CalibrateRulerAnn',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SaveKMA',
            action: 'OnSaveROI',
            caption: 'Save KMA',
            tooltip: 'Save KMA',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: false,
            items: []
          },
        ]
      },
      {
        id: 'Orientation',
        action: 'RotateClockwise',
        caption: 'Rotate C', tooltip: 'Rotate Clockwise',
        type: 'button',
        cssIconClass: 'RotateClock',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'RotateClockwise',
            action: 'RotateClockwise',
            caption: 'Rotate C', tooltip: 'Rotate Clockwise',
            type: 'button',
            cssIconClass: 'RotateClock',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'RotateCounterClock',
            action: 'RotateCounterclockwise',
            caption: 'Rotate CC', tooltip: 'Rotate Counterclockwise',
            type: 'button',
            cssIconClass: 'RotateCounterClock',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Flip',
            action: 'Flip',
            caption: 'Flip', tooltip: 'Flip',
            type: 'button',
            cssIconClass: 'Flip',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Reverse',
            action: 'Reverse',
            caption: 'Reverse', tooltip: 'Reverse',
            type: 'button',
            cssIconClass: 'Reverse',
            disabled: false,
            readonly: false,
            items: []
          }
        ]
      },
      {
        id: 'FitImageParent',
        action: 'FitImage',
        caption: 'Fit', tooltip: 'Fit',
        type: 'button',
        cssIconClass: 'FitImage',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'FitImage',
            action: 'FitImage',
            caption: 'Fit', tooltip: 'Fit',
            type: 'button',
            cssIconClass: 'FitImage',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'OneToOne',
            action: 'OneToOne',
            caption: '1-1', tooltip: 'One To One',
            type: 'button',
            cssIconClass: 'OneToOne',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'TrueSizeDisplay',
            action: 'TrueSizeDisplay',
            caption: 'True Size', tooltip: 'True Size Display',
            type: 'button',
            cssIconClass: 'TrueSizeDisplay',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ZoomIn',
            action: 'ZoomIn',
            caption: 'Zoom In', tooltip: 'Zoom In',
            type: 'button',
            cssIconClass: 'ZoomIn',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ZoomOut',
            action: 'ZoomOut',
            caption: 'Zoom Out', tooltip: 'Zoom Out',
            type: 'button',
            cssIconClass: 'ZoomOut',
            disabled: false,
            readonly: false,
            items: []
          }
        ]
      },
      {
        id: 'SortSeriesParent',
        action: 'SortSeries',
        caption: 'By Axis \u21E7',
        tooltip: 'Sort Series',
        type: 'button',
        cssIconClass: 'SortSeries',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'SortByAcquisitionTime',
            action: 'OnSortByAcquisitionTime',
            caption: 'By Acq. Time',
            tooltip: 'Acquisition Time',
            type: 'button',
            cssIconClass: 'SortSeries',
            disabled: true,
            readonly: false,
            items: []
          },
          {
            id: 'SortByAxis',
            action: 'OnSortByAxis',
            caption: 'By Axis \u21E7',
            tooltip: '\u2713 Axis \u21E7',
            type: 'button',
            cssIconClass: 'SortSeries',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SortByInstanceNumber',
            action: 'OnSortByInstanceNumber',
            caption: 'By Inst. Number', tooltip: 'Instance Number',
            type: 'button',
            cssIconClass: 'SortSeries',
            disabled: false,
            readonly: false,
            items: []
          }
        ]
      },
      {
        id: 'FullScreen',
        action: 'ToggleFullScreen',
        caption: 'Full Screen', tooltip: 'Toggle Full Screen',
        type: 'button',
        cssIconClass: 'FullScreen',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'WindowLevelCustom',
        action: 'WindowLevelCustom',
        caption: 'Custom WL', tooltip: 'Custom Window Level',
        type: 'button',
        cssIconClass: 'WindowLevelCustom',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'Measurements',
        action: 'OnAnnotationRuler',
        caption: 'Measurements', tooltip: 'Ruler',
        type: 'button',
        cssIconClass: 'Ruler',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'Ruler',
            action: 'OnAnnotationRuler',
            caption: 'Ruler', tooltip: 'Ruler',
            type: 'button',
            cssIconClass: 'Ruler',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'PolyRuler',
            action: 'OnAnnotationPolyRuler',
            caption: 'Poly ruler', tooltip: 'Poly Ruler',
            type: 'button',
            cssIconClass: 'PolyRuler',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Protractor',
            action: 'OnAnnotationProtractor',
            caption: 'Angle', tooltip: 'Angle',
            type: 'button',
            cssIconClass: 'Protractor',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'CobbAngle',
            action: 'OnAnnotationCobbAngle',
            caption: 'CobbAngle',
            tooltip: 'CobbAngle',
            type: 'button',
            cssIconClass: 'Protractor',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'CrossRuler',
            action: 'OnAnnotationCrossRuler',
            caption: 'CrossRuler', tooltip: 'CrossRuler',
            type: 'button',
            cssIconClass: 'CrossRuler',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'CalibrateRuler',
            action: 'OnCalibrateRuler',
            caption: 'Calibrate', tooltip: 'Calibrate Ruler',
            type: 'button',
            cssIconClass: 'CalibrateRulerAnn',
            disabled: false,
            readonly: false,
            items: []
          }
        ]
      },
      {
        id: 'ShowDicom',
        action: 'ShowDicom',
        caption: 'Headers', tooltip: 'Show Dicom Info',
        type: 'button',
        cssIconClass: 'ShowDicom',
        disabled: false,
        readonly: false,
        items: []
      },
      // {
      //     id: 'LayoutCompose',
      //     action: 'Compose',
      //     caption: 'Compose Layout', tooltip: 'Compose Layout',
      //     type: 'button',
      //     cssIconClass: 'ComposeLayout',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      // {
      //     id: 'MergeCells',
      //     action: 'MergeCells',
      //     caption: 'Merge', tooltip: 'Merge Cells',
      //     type: 'button',
      //     'visible': 'false',
      //     cssIconClass: 'MergeCells',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      // {
      //     id: 'HangingProtocol',
      //     action: 'HangingProtocol',
      //     caption: 'HP', tooltip: 'Save Hanging Protocol',
      //     type: 'button',
      //     'visible': 'false',
      //     cssIconClass: 'SaveHangingProtocol',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      // {
      //     id: 'SaveStructuredDisplay',
      //     action: 'SaveStructuredDisplay',
      //     caption: 'Save SSD', tooltip: 'Save Study Structured Display',
      //     type: 'button',
      //     'visible': 'false',
      //     cssIconClass: 'SaveStructuredDisplay',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      // {
      //     id: 'DeleteStudyStructuredDisplay',
      //     action: 'DeleteStudyStructuredDisplay',
      //     caption: 'Delete SSD', tooltip: 'Delete Study Structured Display',
      //     type: 'button',
      //     'visible': 'false',
      //     cssIconClass: 'DeleteStudyLayout',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      {
        id: 'SpyglassGroup',
        action: 'OnSpyGlass',
        caption: 'SpyGlass', tooltip: 'Spyglass',
        type: 'button',
        cssIconClass: 'SpyGlass',
        disabled: true,
        readonly: true,
        items: [
          {
            id: 'SpyGlass',
            action: 'OnSpyGlass',
            caption: 'SpyGlass',
            tooltip: 'Spyglass',
            type: 'button',
            cssIconClass: 'SpyGlass',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'SpyGlassInvert',
            action: 'OnSpyGlassInvert',
            caption: 'Invert',
            tooltip: 'Spyglass Invert',
            type: 'button',
            cssIconClass: 'SpyglassInvert',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'SpyGlassCLAHE',
            action: 'OnSpyGlassCLAHE',
            caption: 'Clahe',
            tooltip: 'Spyglass CLAHE',
            type: 'button',
            cssIconClass: 'SpyglassCLAHE',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'SpyGlassEqualization',
            action: 'OnSpyGlassEqualization',
            caption: 'Revealer',
            tooltip: 'Revealer',
            type: 'button',
            cssIconClass: 'SpyGlassEqualization',
            disabled: true,
            readonly: true,
            items: []
          }
        ]
      },
      {
        id: 'ProbeTool',
        action: 'OnProbeTool',
        caption: 'Probe',
        tooltip: 'Probe Tool',
        type: 'button',
        cssIconClass: 'ProbeTool',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'ParametricMaps',
        action: 'OnParametricMaps',
        caption: 'Parametric Maps',
        tooltip: 'Parametric Maps',
        type: 'button',
        cssIconClass: 'Stack',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'MotionCorrection',
        action: 'OnMotionCorrection',
        caption: 'Motion Correction',
        tooltip: 'Motion Correction',
        type: 'button',
        cssIconClass: 'Stack',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'HorizontalAlignment',
        action: 'ImageAlignLeft',
        caption: 'Left', tooltip: 'Align Left',
        type: 'button',
        cssIconClass: 'AlignLeft',
        disabled: true,
        readonly: false,
        items: [
          {
            id: 'ImageAlignLeft',
            action: 'ImageAlignLeft',
            caption: 'Left', tooltip: 'Align Left',
            type: 'button',
            cssIconClass: 'AlignLeft',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ImageAlignRight',
            action: 'ImageAlignRight',
            caption: 'Right', tooltip: 'Align Right',
            type: 'button',
            cssIconClass: 'AlignRight',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ImageAlignCenter',
            action: 'ImageAlignCenter',
            caption: 'Center', tooltip: 'Align Center',
            type: 'button',
            cssIconClass: 'AlignCenter',
            disabled: false,
            readonly: false,
            items: []
          }
        ]
      },
      {
        id: 'VerticalAlignment',
        action: 'ImageAlignTop',
        caption: 'Top', tooltip: 'Align Top',
        type: 'button',
        cssIconClass: 'AlignTop',
        disabled: true,
        readonly: false,
        items: [
          {
            id: 'ImageAlignTop',
            action: 'ImageAlignTop',
            caption: 'Top', tooltip: 'Align Top',
            type: 'button',
            cssIconClass: 'AlignTop',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ImageAlignBottom',
            action: 'ImageAlignBottom',
            caption: 'Bottom', tooltip: 'Align Bottom',
            type: 'button',
            cssIconClass: 'AlignBottom',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ImageAlignMiddle',
            action: 'ImageAlignMiddle',
            caption: 'Middle', tooltip: 'Align Middle',
            type: 'button',
            cssIconClass: 'AlignMiddle',
            disabled: false,
            readonly: false,
            items: []
          }
        ]
      }, {
        id: 'SortSeries',
        action: 'OnSort',
        caption: 'Sort', tooltip: 'Sort Series',
        type: 'button',
        cssIconClass: 'SortSeries',
        disabled: true,
        readonly: true,
        items: []
      },
      {
        id: 'Effects',
        action: 'Invert',
        caption: 'Invert', tooltip: 'Invert',
        type: 'button',
        cssIconClass: 'InvertColor',
        disabled: true,
        readonly: true,
        items: [
          {
            id: 'InvertColor',
            action: 'Invert',
            caption: 'Invert', tooltip: 'Invert',
            type: 'button',
            cssIconClass: 'InvertColor',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'BrightnessContrast',
            action: 'BrightnessContrast',
            caption: 'Lightness', tooltip: 'Brightness Contrast',
            type: 'button',
            cssIconClass: 'BrightnessContrast',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'HSL',
            action: 'HSL',
            caption: 'HSL', tooltip: 'HSL',
            type: 'button',
            cssIconClass: 'HSL',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'StretchHistogram',
            action: 'StretchHistogram',
            caption: 'Equalize', tooltip: 'Stretch Histogram',
            type: 'button',
            cssIconClass: 'StretchHistogram',
            disabled: true,
            readonly: true,
            items: []
          }
        ]
      },
      {
        id: 'EdgeEnhancment',
        action: 'OnEdgeEnhancment',
        caption: 'Enhance Edges', tooltip: 'Edge Enhancment',
        type: 'button',
        cssIconClass: 'EdgeEnhancment',
        disabled: true,
        readonly: true,
        items: []
      },
      // {
      //     id: 'Endo',
      //     action: 'OnToggleEndo',
      //     caption: 'Endo', tooltip: 'Toggle Endo',
      //     type: 'button',
      //     cssIconClass: 'Endo',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      // {
      //     id: 'Perio',
      //     action: 'OnTogglePerio',
      //     caption: 'Perio', tooltip: 'Toggle Perio',
      //     type: 'button',
      //     cssIconClass: 'Perio',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      // {
      //     id: 'Dentin',
      //     action: 'OnToggleDentin',
      //     caption: 'Dentin', tooltip: 'Toggle Dentin',
      //     type: 'button',
      //     cssIconClass: 'Dentin',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      {
        id: 'Annotations',
        action: 'OnAnnotationSelect',
        caption: 'ROI', tooltip: 'ROI',
        type: 'button',
        cssIconClass: 'Select',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'Arrow',
            action: 'OnAnnotationArrow',
            caption: 'Arrow', tooltip: 'Arrow',
            type: 'button',
            cssIconClass: 'Arrow',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'Point',
            action: 'OnAnnotationPoint',
            caption: 'Point', tooltip: 'Point',
            type: 'button',
            cssIconClass: 'Point',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'Rectangle',
            action: 'OnAnnotationRectangle',
            caption: 'Rectangle', tooltip: 'Rectangle',
            type: 'button',
            cssIconClass: 'Rectangle',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Ellipse',
            action: 'OnAnnotationEllipse',
            caption: 'Ellipse', tooltip: 'Ellipse',
            type: 'button',
            cssIconClass: 'Ellipse',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Curve',
            action: 'OnAnnotationCurve',
            caption: 'Curve', tooltip: 'Curve',
            type: 'button',
            cssIconClass: 'Curve',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Line',
            action: 'OnAnnotationLine',
            caption: 'Line', tooltip: 'Line',
            type: 'button',
            cssIconClass: 'Line',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Freehand',
            action: 'OnAnnotationFreehand',
            caption: 'Free Hand', tooltip: 'Freehand',
            type: 'button',
            cssIconClass: 'Freehand',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'AutoFreehand',
            action: 'OnAnnotationAutoFreehand',
            caption: 'Auto Free Hand', tooltip: 'Auto Freehand',
            type: 'button',
            cssIconClass: 'AutoFreehand',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Polyline',
            action: 'OnAnnotationPolyline',
            caption: 'Poly line', tooltip: 'Polyline',
            type: 'button',
            cssIconClass: 'Polyline',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'Polygon',
            action: 'OnAnnotationPolygon',
            caption: 'Polygon', tooltip: 'Polygon',
            type: 'button',
            cssIconClass: 'Polygon',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'Text',
            action: 'OnAnnotationText',
            caption: 'Text', tooltip: 'Text',
            type: 'button',
            cssIconClass: 'Text',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'Note',
            action: 'OnAnnotationNote',
            caption: 'Note', tooltip: 'Note',
            type: 'button',
            cssIconClass: 'Note',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'Highlight',
            action: 'OnAnnotationHighlight',
            caption: 'Highlight', tooltip: 'Highlight',
            type: 'button',
            cssIconClass: 'Highlight',
            disabled: true,
            readonly: false,
            items: []
          },
          {
            id: 'TextPointer',
            action: 'OnAnnotationTextPointer',
            caption: 'Text pointer', tooltip: 'TextPointer',
            type: 'button',
            cssIconClass: 'TextPointer',
            disabled: true,
            readonly: true,
            items: []
          }
        ]
      },
      {
        id: 'AnnotationOptions',
        action: 'OnAnnotationShowHide',
        caption: 'Show/Hide', tooltip: 'Show/Hide Annotations',
        type: 'button',
        cssIconClass: 'ShowHide',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'AnnotationShowHide',
            action: 'OnAnnotationShowHide',
            caption: 'Show/Hide', tooltip: 'Show/Hide Annotations',
            type: 'button',
            cssIconClass: 'ShowHide',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'DeleteAnnotations',
            action: 'OnDeleteAnnotation',
            caption: 'Delete', tooltip: 'Delete Annotations',
            type: 'button',
            cssIconClass: 'DeleteAnn',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ClearAnnotations',
            action: 'OnClearAnnotation',
            caption: 'Clear', tooltip: 'Clear Annotations',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ClearAllAnnotations',
            action: 'OnClearAllAnnotation',
            caption: 'Clear all', tooltip: 'Clear All Annotations',
            type: 'button',
            cssIconClass: 'ClearAll',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SaveAnnSeperator',
            action: '',
            caption: 'Sep', tooltip: '',
            type: 'seperator',
            cssIconClass: '',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SaveAnn',
            action: 'OnSaveAnnotations',
            caption: 'Save', tooltip: 'Save Annotations',
            type: 'button',
            cssIconClass: 'SaveAnn',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'LoadAnn',
            action: 'OnLoadAnnotations',
            caption: 'Load', tooltip: 'Load Annotations',
            type: 'button',
            cssIconClass: 'LoadAnn',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'LoadObj',
            action: 'OnLoadObjects',
            caption: 'Load', tooltip: 'Show/Hide ROIs & VOIs',
            type: 'button',
            cssIconClass: 'ShowHide',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'LoadStatistics',
            action: 'OnLoadStatistics',
            caption: 'Statistics', tooltip: 'Disable Statistics',
            type: 'button',
            cssIconClass: 'ShowHide',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'LoadIntChart',
            action: 'OnLoadIntChart',
            caption: 'IntChart', tooltip: 'Enable Intensity Chart',
            type: 'button',
            cssIconClass: 'ShowHide',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ShowHideOverlays',
            action: 'OnShowHideOverlays',
            caption: 'Image Metadata', tooltip: 'Hide Metadata',
            type: 'button',
            cssIconClass: 'ShowHideOverlays',
            disabled: false,
            readonly: false,
            items: []
          }
        ]
      },
      {
        id: 'ToggleTags',
        action: 'OnToggleTags',
        caption: 'Overlays', tooltip: 'Overlays',
        type: 'button',
        cssIconClass: 'ToggleTags',
        disabled: true,
        readonly: true,
        items: []
      },
      {
        id: 'Rotate3D',
        action: 'OnRotate3D',
        caption: 'Rotate 3D', tooltip: 'Rotate 3D Object',
        type: 'button',
        cssIconClass: 'Rotate3D',
        disabled: true,
        readonly: true,
        items: []
      },
      {
        id: 'MPROrientation',
        action: 'OnMPROrientation',
        caption: 'Orientations', tooltip: 'MPR Orientation',
        type: 'button',
        cssIconClass: 'MPR',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'AxialOrientation',
            action: 'OnAxialOrientation',
            caption: 'OnAxialOrientation', tooltip: 'Axial Orientation',
            type: 'button',
            cssIconClass: 'MPR',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'CoronalOrientation',
            action: 'OnCoronalOrientation',
            caption: 'OnCoronalOrientation', tooltip: 'Coronal Orientation',
            type: 'button',
            cssIconClass: 'MPR',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SagitalOrientation',
            action: 'OnSagitalOrientation',
            caption: 'OnSagitalOrientation', tooltip: 'Sagital Orientation',
            type: 'button',
            cssIconClass: 'MPR',
            disabled: false,
            readonly: false,
            items: []
          },
        ]
      },
      {
        id: 'VolumeType',
        action: 'OnVRT',
        caption: '3D Volume', tooltip: '3D Volume Type',
        type: 'button',
        cssIconClass: 'VRT',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'NoVolume',
            action: 'OnNoVolume',
            caption: 'NoVolume', tooltip: 'Default',
            type: 'button',
            cssIconClass: 'VRT',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'VRT',
            action: 'OnVRT',
            caption: 'VRT', tooltip: 'VRT',
            type: 'button',
            cssIconClass: 'VRT',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'MIP',
            action: 'OnMIP',
            caption: 'MIP', tooltip: 'MIP',
            type: 'button',
            cssIconClass: 'MIP',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'MPRNo3D',
            action: 'OnMPRVolumeWithout3D',
            caption: 'MPRNo3D', tooltip: 'MPR without 3D View',
            type: 'button',
            cssIconClass: 'MPRVolume',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'MPR3D',
            action: 'OnMPRVolumeWith3D',
            caption: 'MPR3D', tooltip: 'MPR', // MPR with 3D View
            type: 'button',
            cssIconClass: 'MPRVolume',
            disabled: false,
            readonly: false,
            items: []
          },
        ]
      },
      {
        id: 'Settings3DParent',
        action: 'OnSettings3D',
        caption: 'Settings', tooltip: '3D Settings',
        type: 'button',
        cssIconClass: 'Settings3D',
        disabled: true,
        readonly: true,
        items: [
          {
            id: 'Settings3D',
            action: 'OnSettings3D',
            caption: 'Settings', tooltip: '3D Settings',
            type: 'button',
            cssIconClass: 'Settings3D',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'CrossHair',
            action: 'OnCrossHair',
            caption: 'Cross Hair', tooltip: 'Cross hair',
            type: 'button',
            cssIconClass: 'CrossHair',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'HeadOrientation',
            action: 'OnHeadOrientation',
            caption: 'Head', tooltip: 'Head',
            type: 'button',
            cssIconClass: 'HeadOrientation',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'FeetOrientation',
            action: 'OnFeetOrientation',
            caption: 'Feet', tooltip: 'Feet',
            type: 'button',
            cssIconClass: 'FeetOrientation',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'LeftOrientation',
            action: 'OnLeftOrientation',
            caption: 'Left', tooltip: 'Left Orientation',
            type: 'button',
            cssIconClass: 'LeftOrientation',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'RightOrientation',
            action: 'OnRightOrientation',
            caption: 'Right',
            tooltip: 'Right Orientation',
            type: 'button',
            cssIconClass: 'RightOrientation',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'AnteriorOrientation',
            action: 'OnAnteriorOrientation',
            caption: 'Anterior',
            tooltip: 'Anterior',
            type: 'button',
            cssIconClass: 'AnteriorOrientation',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'PosteriorOrientation',
            action: 'OnPosteriorOrientation',
            caption: 'Posterior',
            tooltip: 'Posterior',
            type: 'button',
            cssIconClass: 'PosteriorOrientation',
            disabled: true,
            readonly: true,
            items: []
          }]
      },
      {
        id: 'DeleteCell',
        action: 'DeleteCell',
        caption: 'Delete',
        tooltip: 'Delete Cell',
        type: 'button',
        cssIconClass: 'DeleteCell',
        disabled: true,
        readonly: true,
        items: []
      },
      // {
      //     id: 'StudyLayout',
      //     action: 'OnStudyLayout',
      //     caption: 'Study Layout', tooltip: 'Change Study Layout',
      //     type: 'button',
      //     cssIconClass: 'StudyLayout',
      //     disabled: true, readonly: true,
      //     items: [
      //         {
      //             id: 'StudyLayout',
      //             action: 'OnStudyLayout',
      //             caption: 'Study Layout', tooltip: 'Change Study Layout',
      //             type: 'button',
      //             cssIconClass: 'StudyLayout',
      //             disabled: true, readonly: true,
      //             items: []
      //         },
      //         {
      //             id: 'SeriesLayouts',
      //             action: 'OnSeriesLayout',
      //             caption: 'Series Layout', tooltip: 'Change Series Layout',
      //             type: 'button',
      //             cssIconClass: 'SeriesLayout',
      //             disabled: true, readonly: true,
      //             items: []
      //         }
      //     ]
      // },
      {
        id: 'ToggleCine',
        action: 'OnToggleCine',
        caption: 'Cine',
        tooltip: 'Toggle Cine',
        type: 'button',
        cssIconClass: 'ToggleCine',
        disabled: true,
        readonly: true,
        items: [
          {
            id: 'ToggleCine',
            action: 'OnToggleCine',
            caption: 'Play/Stop',
            tooltip: 'Toggle Cine',
            type: 'button',
            cssIconClass: 'ToggleCine',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'CinePlayer',
            action: 'CinePlayer',
            caption: 'Cine',
            tooltip: 'Cine Player',
            type: 'button',
            cssIconClass: 'CinePlayer',
            disabled: true,
            readonly: true,
            items: []
          }
        ]
      },
      {
        id: 'PopupCaptureParent',
        action: 'OnSecondaryCapturePopup',
        caption: 'ScreenShot',
        tooltip: 'ScreenShot',
        type: 'button',
        cssIconClass: 'PopupCapture',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'SecondaryCapture',
            action: 'OnSecondaryCapture',
            caption: 'Save Derived',
            tooltip: 'Save As Derived',
            type: 'button',
            cssIconClass: 'SecondaryCapture',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'Export',
            action: 'OnExport',
            caption: 'Export',
            tooltip: 'Export',
            type: 'button',
            cssIconClass: 'Export',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'PopupCapture',
            action: 'OnSecondaryCapturePopup',
            caption: 'ScreenShot',
            tooltip: 'ScreenShot',
            type: 'button',
            cssIconClass: 'PopupCapture',
            disabled: false,
            readonly: false,
            items: []
          }
        ]
      },
      {
        id: 'StudyTimeLine',
        action: 'StudyTimeLine',
        caption: 'Time Line',
        tooltip: 'Toggle Study Time Line',
        type: 'button',
        cssIconClass: 'StudyTimeLine',
        disabled: true,
        readonly: true,
        items: []
      },
      // {
      //     id: 'WaveformBasicAudio',
      //     action: 'OnWaveformBasicAudio',
      //     caption: 'Audio', tooltip: 'Waveform Basic Audio',
      //     type: 'button',
      //     cssIconClass: 'WaveformBasicAudio',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      // {
      //     id: 'ShutterObject',
      //     action: 'OnShutterObject',
      //     caption: 'Shutter', tooltip: 'Shutter Object',
      //     type: 'button',
      //     cssIconClass: 'ShutterObject',
      //     disabled: true, readonly: true,
      //     items: []
      // },
      {
        id: 'AddToBrainLesion',
        action: 'onAddToBrainLesion',
        caption: 'Add Brain Lesion',
        tooltip: 'Add slice to Brain Lesions',
        type: 'button',
        cssIconClass: 'ImportTemplates',
        disabled: false,
        readonly: false,
        items: []
      },
      {
        id: 'Cursor3DParent',
        action: 'OnCursor3D',
        caption: '3D Cursor',
        tooltip: '3D Cursor',
        type: 'button',
        cssIconClass: 'Cursor3D',
        disabled: true,
        readonly: true,
        items: [
          {
            id: 'Cursor3D',
            action: 'OnCursor3D',
            caption: '3D Cursor',
            tooltip: '3D Cursor',
            type: 'button',
            cssIconClass: 'Cursor3D',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'ReferenceLine',
            action: 'ToggleReferenceLine',
            caption: 'Reference Line',
            tooltip: 'Show/Hide Reference Lines',
            type: 'button',
            cssIconClass: 'ReferenceLine',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'CrossHairLine',
            action: 'OnCrossHairLine',
            caption: 'Cross Hair Lines',
            tooltip: 'Show/Hide Cross Hair Lines',
            type: 'button',
            cssIconClass: 'Cursor3D',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'ShowFirstLast',
            action: 'ShowFirstLastReferenceLine',
            caption: 'First/Last',
            tooltip: 'Show First And Last Reference Line',
            type: 'button',
            cssIconClass: 'ShowFirstLast',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'LineProfile',
            action: 'OnLineProfile',
            caption: 'Line Profile',
            tooltip: 'Line Profile',
            type: 'button',
            cssIconClass: 'LineProfile',
            disabled: true,
            readonly: true,
            items: []
          },
          // {
          //   id: 'SynchronizeSeries',
          //   action: 'SetEnableSeriesSynchronization',
          //   caption: 'Synch',
          //   tooltip: 'Synchronize Series',
          //   type: 'button',
          //   cssIconClass: 'Synchronization',
          //   disabled: false,
          //   readonly: false,
          //   items: []
          // },
          {
            id: 'LinkCells',
            action: 'LinkCells',
            caption: 'Link Cells',
            tooltip: 'Link Cells',
            type: 'button',
            cssIconClass: 'LinkStudies',
            disabled: true,
            readonly: true,
            items: []
          },
          {
            id: 'LinkImages',
            action: 'SetLinked',
            caption: 'Link Images',
            tooltip: 'Link Images',
            type: 'button',
            cssIconClass: 'Linked',
            disabled: true,
            readonly: true,
            items: []
          }
        ]
      },
      {
        id: 'GBMSegmentation',
        action: 'OnGBMSegmentation',
        caption: 'GBM Segmentation',
        tooltip: 'GBM Segmentation',
        type: 'button',
        cssIconClass: 'GBMSegmentation',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'RunReg',
            action: 'OnRunReg',
            caption: 'Registration Only',
            tooltip: 'Registration Only',
            type: 'button',
            cssIconClass: 'RunReg',
            disabled: true,
            readonly: true,
            items: [],
          },
          {
            id: 'RunSeg',
            action: 'OnRunSeg',
            caption: 'Tumor Detection',
            tooltip: 'Tumor Detection',
            type: 'button',
            cssIconClass: 'RunSeg',
            disabled: false,
            readonly: true,
            items: [],
          },
          {
            id: 'GBMAnnSeperator',
            action: '',
            caption: 'Sep',
            tooltip: '',
            type: 'seperator',
            cssIconClass: '',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'GBMStation',
            action: 'OnGBMStation',
            caption: 'GBM Station',
            tooltip: 'Show GBM Station',
            type: 'button',
            cssIconClass: 'GBMStation',
            disabled: false,
            readonly: false,
            items: [],
          },
          {
            id: 'TumorCorrection',
            action: 'OnTumorCorrection',
            caption: 'Tumor Correction',
            tooltip: 'Show Tumor Correction',
            type: 'button',
            cssIconClass: 'TumorCorrection',
            disabled: false,
            readonly: true,
            items: [],
          },
          {
            id: '2D3DDiam',
            action: 'On2D3DDiam',
            caption: '2D/3D Measurements',
            tooltip: 'Show 2D/3D Measurements',
            type: 'button',
            cssIconClass: '2D3DDiam',
            disabled: false,
            readonly: true,
            items: [],
          },
          {
            id: 'GBMAnnSeperator',
            action: '',
            caption: 'Sep',
            tooltip: '',
            type: 'seperator',
            cssIconClass: '',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'SliceSync',
            action: 'OnSliceSync',
            caption: 'Enable Slice Sync',
            tooltip: 'Enable Slice Sync',
            type: 'button',
            cssIconClass: 'SliceSync',
            disabled: false,
            readonly: false,
            items: [],
          },
          {
            id: 'VisualComparison',
            action: 'OnVisualComparison',
            caption: 'Enable Visual Comparison',
            tooltip: 'Enable Visual Comparison',
            type: 'button',
            cssIconClass: 'VisualComparison',
            disabled: true,
            readonly: false,
            items: [],
          }
        ]
      },
      {
        id: 'Lesion',
        action: 'OnLesion',
        caption: 'Lesion & Contours',
        tooltip: 'Lesion & Contours',
        type: 'button',
        cssIconClass: 'SliceSync',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'AddCrossRuler',
            action: 'OnAddCrossRuler',
            caption: 'Add Cross Ruler',
            tooltip: 'Add Cross Ruler',
            type: 'button',
            cssIconClass: 'CrossRuler',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'AddRuler',
            action: 'OnAddRuler',
            caption: 'Add Ruler',
            tooltip: 'Add Ruler',
            type: 'button',
            cssIconClass: 'Ruler',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'AddMarker',
            action: 'OnAddMarker',
            caption: 'Add Marker',
            tooltip: 'Add Marker',
            type: 'button',
            cssIconClass: 'AddMarker',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ConfirmMarker',
            action: 'OnConfirmMarker',
            caption: 'Confirm Lesion',
            tooltip: 'Confirm Lesion',
            type: 'button',
            cssIconClass: 'ImportTemplates',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'RemoveMarker',
            action: 'OnRemoveMarker',
            caption: 'Remove Marker',
            tooltip: 'Remove Marker',
            type: 'button',
            cssIconClass: 'Clear',
            disabled: false,
            readonly: false,
            items: []
          },
        ]
      },
      {
        id: 'ViewerSettings',
        action: 'OnViewerSettings',
        caption: 'Viewer Settings',
        tooltip: 'Viewer Settings',
        type: 'button',
        cssIconClass: 'Settings3D',
        disabled: false,
        readonly: false,
        items: [
          {
            id: 'FullDownload',
            action: 'OnFullDownload',
            caption: 'Enable Full Download',
            tooltip: 'Enable Full Download',
            type: 'button',
            cssIconClass: 'Settings3D',
            disabled: false,
            readonly: false,
            items: []
          },
          // {
          //   id: 'CustomUpload',
          //   action: 'OnCustomUpload',
          //   caption: 'Do Custom ReUpload',
          //   tooltip: 'Do Custom ReUpload',
          //   type: 'button',
          //   cssIconClass: 'Reload',
          //   disabled: false,
          //   readonly: false,
          //   items: []
          // },
          // {
          //   id: 'MPRLocalRendering',
          //   action: 'OnMPRLocalRendering',
          //   caption: 'Render MPR Locally',
          //   tooltip: 'Render MPR Locally',
          //   type: 'button',
          //   cssIconClass: 'Settings3D',
          //   disabled: true,
          //   readonly: true,
          //   items: []
          // },
          {
            id: 'SaveAnnSeperator',
            action: '',
            caption: 'Sep',
            tooltip: '',
            type: 'seperator',
            cssIconClass: '',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'RapidAction',
            action: 'OnRapidAction',
            caption: 'Rapid Action',
            tooltip: 'Enable Rapid Drawing',
            type: 'button',
            cssIconClass: 'Settings3D',
            disabled: false,
            readonly: false,
            items: []
          },
          {
            id: 'ExitViewer',
            action: 'OnExitViewer',
            caption: 'Exit Viewer',
            tooltip: 'Exit Viewer',
            type: 'button',
            cssIconClass: 'Settings3D',
            disabled: true,
            readonly: true,
            items: []
          },
        ]
      },
    ]
  };
  private _state: Array<any> = null;
  private _allItems = [];

  constructor() {
    this._allItems = [];
    this._parse(this.items.slice());
  }

  public static getInstance() {
    if (!ViewerToolbar.instance) {
      ViewerToolbar.instance = new ViewerToolbar();
    }
    return ViewerToolbar.instance;
  }

  private _parse(items, storeTo = this._allItems) {
    _.each(items, i => {
      storeTo[i.id] = i;

      if (i.items && i.items.length) {
        i.items.map(it => {
          it.parent = i.id;
        });

        this._parse(i.items);
      } else {
        return;
      }
    });

    return storeTo;
  }

  public applyConfig(config: any, userRoles = null): void {
    let roleTypes = [];
    if (userRoles && userRoles.length) {
      roleTypes = userRoles.map(r => r.roles).flat().map(r => _.get(r, 'roleType', null));
      roleTypes = [...new Set(roleTypes)];

      // Find configurations for roles
      const configItem = config.find(c => _.difference(c.roles, _.intersection(roleTypes, c.roles)).length === 0);

      if (configItem) {
        // switch all visibility off
        this.setVisibility(false);

        configItem.items.forEach(item => {
          if (_.isObjectLike(item)) {
            let menuItem = this.getItem(item.id);
            const itemChildren = item.items ? item.items.map(i => _.isObjectLike(i) ? i : this.getItem(i)) : [];
            const itemToMerge = itemChildren.length ? { ...item, ...{ items: itemChildren } } : item;
            menuItem = Object.assign(menuItem, itemToMerge);
            this.setVisibility(true, menuItem.id, true, true);
          } else if (_.isString(item)) {
            this.setVisibility(true, item, true, true);
          }
        });
      }
    }
  }

  public getItem(id: string): any {
    return this._getMenuItem(this._menuItems, id);
  }

  public setReadOnly(flag: boolean = true, id?: string, excludedItems = []): any {
    if (id) {
      this.setItemProperty(id, 'readonly', flag);
    } else {
      _.each(_.values(this._allItems), i => {
        if (excludedItems.length && excludedItems.indexOf(i.id) === -1) {
          i.readonly = flag;
        }
      });
    }
    return this.items;
  }

  public setVisibility(flag: boolean = true, id?: string, includeChildren = false, includeRoot = false): any {
    if (id) {
      this.setItemProperty(id, 'disabled', !flag, includeChildren, includeRoot);
    } else {
      _.each(_.values(this._allItems), i => {
        i.disabled = !flag;
      });
    }

    return this.items;
  }

  public addItem(item, menuItemID = null) {
    if (menuItemID) {
      const menuIndex = _.findIndex(this.items, { id: menuItemID });
      if (menuIndex !== -1) {
        const targetItemIndex = _.findIndex(this.items, { id: item.id });
        if (targetItemIndex !== -1) {
          this.items[targetItemIndex] = item;
        } else {
          this.items.splice(menuIndex + 1, 0, item);
        }
        this._allItems = [];
        this._parse(this.items);
      }
    }
  }

  get items() {
    return this._menuItems.items;
  }

  set items(items: any) {
    this._menuItems.items = items;
  }

  private _getMenuItem(items: any = this._menuItems, menuItemID: string = '') {
    let item: any = null;
    if (menuItemID.length) {
      item = _.find(items.items, { id: menuItemID });
      if (item) {
        return item;
      } else {
        _.each(items.items, i => {
          item = this._getMenuItem(i, menuItemID);
          if (item) {
            return false;
          }
        });
      }
    }

    return item;
  }

  public setItemProperty(menuItemID: string, propertyName: string, propertyValue: any, includeChildren = false, includeRoot = false) {
    const menuItem = this._getMenuItem(this._menuItems, menuItemID);
    if (menuItem) {
      menuItem[propertyName] = propertyValue;
    }

    if (includeChildren && menuItem.items && menuItem.items.length) {
      menuItem.items.map(i => {
        i[propertyName] = propertyValue;
      });
    }

    if (includeRoot && menuItem.parent) {
      this.setItemProperty(menuItem.parent, propertyName, propertyValue, false, true);
    }
    // console.log('menuItem:', JSON.parse(JSON.stringify(menuItem)));

    return menuItem;
  }

  public hasState() {
    return this._state !== null;
  }

  public saveState(state: Array<any> = this.items): ViewerToolbar {
    this._state = _.cloneDeep(state.slice());
    return this;
  }

  public restoreState(): void {
    this.items = _.cloneDeep(this._state);
    // this._state = null;
    this._allItems = [];
    this._parse(this.items.slice());
  }
}
