import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {FormMode} from '../../../../../core/constants/form-mode';
import {CandenSparccConfigModel} from '../../../../../_models/ImagingProject/canden-sparcc-config-model';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {StudySequenceLabelService, StudyUserService} from '../../../../../_services';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {MatDialog} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import {Reader} from '../imaging-project-reading-canden-sparcc-wrapper/imaging-project-reading-canden-sparcc-wrapper.component';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {MatSelectChange} from '@angular/material/select';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {
    defaultAdjAutoBatchConfig,
    defaultBasicAutoBatchConfig,
    defaultLvl1AutoBatchConfig, defaultModerationAutoBatchConfig,
    ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {MatTableDataSource} from '@angular/material/table';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {MatCheckboxChange} from '@angular/material/checkbox';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
    selector: 'app-imaging-project-reading-canden-sparcc-basic',
    templateUrl: './imaging-project-reading-canden-sparcc-basic.component.html',
    styleUrls: ['./imaging-project-reading-canden-sparcc-basic.component.css']
})
export class ImagingProjectReadingCandenSparccBasicComponent extends ImagingProjectReadingBasicComponent implements OnInit {

    @Input() selectedConfig: FlexibleConfig<CandenSparccConfigModel>;
    @Input() mode: FormMode;
    @Output() public clickCancel = new EventEmitter<any>();
    @Output() public clickSubmit = new EventEmitter<any>();

    readonly formModes = FormMode;
    readonly readingTypes = ReadingType;
    modalities: ModalityModel[];
    studyId: number;
    MIN_READERS = 1;
    MAX_READERS = 20;
    MODERATOR_READERS = 1;
    readers: Reader[];
    readerSelectOptions: Reader[];
    moderatorReaderSelectOptions: Reader[];
    readersTableColumns: string[] = ['username', 'email', 'action'];
    basicBatchConfig: BatchConfigModel = null;
    moderationBatchConfig: BatchConfigModel = null;
    readersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    moderatorReadersDataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
    candenSparccForm = this.fb.group({
        readingLevel: ['Patient', [Validators.required]],
        modalities: [[], [Validators.required]],
        readingType: ['Parallel', [Validators.required]],
        hideVisitHistory: ['hide_visits_chronology', [Validators.required]],
        anatomySelector: [[], [Validators.required]],
        batchManagement: [false, [Validators.required, this.batchManagementValidator]],
        imageViewerConfiguration: ['default', [Validators.required]],
        factorstoScoreSpine: [
            ['BME', 'SPARCC', 'FAT', 'NBF', 'EROSION'],
            [Validators.required]
        ],
        factorstoScoreSIJ: [
            ['BME', 'FAT', 'EROSION', 'BACKFILL', 'ANK'],
            [this.factorsToScoreSIJValidator]
        ],
        readers: ['', [this.readersValidator]],
        moderationEnabled: [false, [Validators.required]],
        moderatorReaders: ['', [this.moderatorReadersValidator]],
        moderationBatchManagement: [false, [Validators.required, /*this.moderationBatchManagementValidator*/]]
    });

    selectedSequenceLabels: SequenceLabelModel[] = [];
    availableSequenceLabels: SequenceLabelModel[] = [];
    toastOptions: ToastOptions = {
        title: '',
        showClose: true,
        timeout: 10000,
        theme: 'material',
    };

    constructor(
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private studySequenceLabelService: StudySequenceLabelService,
        private studyUserService: StudyUserService,
        private batchLogicService: BatchLogicService,
        private toastyService: ToastyService,
        private dialog: MatDialog
    ) {
      super();
    }

    ngOnInit(): void {
        this.studyId = +this.route.snapshot.params.id;

        const serviceSources = [
            this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
            this.studyUserService.getReadersByStudyId(this.studyId)
        ];
        if (this.mode === this.formModes.EDIT) {
            serviceSources.push(this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id));
        }

        forkJoin(serviceSources).subscribe(
            values => {
                const [groupedModalitiesResponse, readersResponse, batchConfigsResponse] = values as any;
                this.initModalities(groupedModalitiesResponse);
                this.initReaders(readersResponse);
                if (this.mode === this.formModes.EDIT) {
                    this.initBatchConfigs(batchConfigsResponse);
                    this.initFormValues(this.selectedConfig.config);
                }
            },
            (error) => this.showErrorMessage(error.error)
        );
    }

    showErrorMessage(response: any): void {
        this.toastOptions.title = 'ERROR ' + response['responseCode'];
        this.toastOptions.msg = response['responseMessage'];
        this.toastyService.error(this.toastOptions);
    }

    initFormValues(config: CandenSparccConfigModel): void {
        if (config) {
            this.candenSparccForm.patchValue(config);
            this.candenSparccForm.patchValue({
                modalities: config.modalities,
                readers: config.readers.map(reader => reader.id),
                moderatorReaders: config.moderatorReaders ? config.moderatorReaders.map(reader => reader.id) : []
            });
            this.pushReaders();
            this.pushModeratorReaders();
        }
    }

    initBatchConfigs(batchConfigsResponse): void {
        if (batchConfigsResponse.responseCode === 200 && batchConfigsResponse.data) {
            batchConfigsResponse.data.forEach(batchConfig => {
                if (batchConfig.readingType === ReadingType.BASIC_READING) {
                    this.basicBatchConfig = batchConfig;
                } else if (batchConfig.readingType === ReadingType.LEVEL1_READING) {
                    this.basicBatchConfig = batchConfig;
                } else if (batchConfig.readingType === ReadingType.MODERATION_READING) {
                    this.moderationBatchConfig = batchConfig;
                }
            });
        }
    }

    initReaders(readersResponse): void {
        if (readersResponse.responseCode === 200 && readersResponse.data) {
            this.readers = readersResponse.data;
            if (this.mode === this.formModes.NEW) {
                this.readerSelectOptions =
                    (readersResponse.data as Reader[]).sort(this.compareReadersByName);
                this.moderatorReaderSelectOptions =
                    (readersResponse.data as Reader[]).sort(this.compareReadersByName);
            }
        }
    }

    initModalities(groupedModalitiesResponse): void {
        if (groupedModalitiesResponse.responseCode === 200 && groupedModalitiesResponse.data) {
            this.modalities = groupedModalitiesResponse.data;
            this.modalities.sort((a, b) => a.name.localeCompare(b.name));
            this.candenSparccForm.controls.modalities.setValue(this.modalities);
            this.prepareSequenceLabels();
        }
    }

    onModalityTypeChange(event: MatSelectChange) {
        this.candenSparccForm.controls.modalities.setValue(event.value);
        this.initDefaultSequenceLabels(event.value);
        this.candenSparccForm.updateValueAndValidity();
    }

    getFormControl(path: string): FormControl {
        return this.candenSparccForm.get(path) as FormControl;
    }

    compareModalities(m1: any, m2: any): boolean {
        return m1 && m2 ? m1.name === m2.name : m1 === m2;
    }

    prepareSequenceLabels() {
        if (this.mode === FormMode.EDIT && this.selectedConfig.config.anatomySelector) {
            const selectedModalities = this.modalities
                .filter(m => this.selectedConfig.config.modalities.some(sm => sm.name.toLowerCase() === m.name.toLowerCase()));
            this.availableSequenceLabels = selectedModalities.flatMap(m => m.sequenceLabels);
            this.initSequenceLabels(this.selectedConfig.config.anatomySelector);
        } else {
            this.initDefaultSequenceLabels(this.candenSparccForm.controls.modalities.value);
        }
    }

    initDefaultSequenceLabels(modalities: ModalityModel[]) {
        this.availableSequenceLabels = modalities.flatMap(m => m.sequenceLabels);
        this.availableSequenceLabels.sort((a, b) => a.name.localeCompare(b.name));
        this.selectedSequenceLabels = [...this.availableSequenceLabels];
    }

    initSequenceLabels(sequenceLabels: { id: number }[]) {
        this.selectedSequenceLabels = this.availableSequenceLabels
            .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
        this.candenSparccForm.controls.anatomySelector.setValue(this.selectedSequenceLabels);
        this.candenSparccForm.updateValueAndValidity();
    }

    get factorsToScoreSIJValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.candenSparccForm && this.candenSparccForm.get('moderationEnabled').value ? Validators.required(control) : null;
        };
    }

    get batchManagementValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            return control.value && !this.basicBatchConfig?.numberOfReadings ? {norError: 'Number of readings is required'} : null;
        };
    }

    get moderationBatchManagementValidator() {
        return (control: AbstractControl): ValidationErrors | null => {
            return this.candenSparccForm && this.candenSparccForm.get('moderationEnabled').value &&
            control.value && !this.moderationBatchConfig?.numberOfReadings ?
                {norError: 'Number of readings is required'} : null;
        };
    }

    onSequenceLabelSelect(e: SequenceLabelModel[]) {
        this.selectedSequenceLabels = e;
    }

    onBatchManagementSelected(event: MatSelectChange, isModerationBatchManagement: boolean): void {
        if (isModerationBatchManagement) {
            if (this.moderationBatchConfig) {
                this.moderationBatchConfig.numberOfReadings = null;
                this.candenSparccForm.get('moderationBatchManagement').updateValueAndValidity();
            }
        } else {
            if (this.basicBatchConfig) {
                this.basicBatchConfig.numberOfReadings = null;
                this.candenSparccForm.get('batchManagement').updateValueAndValidity();
            }
        }
    }

    openAutoCreationBatchConfigDialog(readingType: ReadingType, config: BatchConfigModel): void {
        let inputConfig: AutoBatchConfigPayload;
        let val: boolean;
        if (readingType === ReadingType.BASIC_READING) {
            val = this.candenSparccForm.get('batchManagement').value;
            if (!val) {
                inputConfig = {...defaultBasicAutoBatchConfig};
            }
        } else {
            val = this.candenSparccForm.get('moderationBatchManagement').value;
            if (!val) {
                inputConfig = {...defaultModerationAutoBatchConfig};
            }
        }

        if (!inputConfig) {
            inputConfig = config;
        }

        const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
            width: '500px',
            data: {
                readingType: readingType,
                configInput: inputConfig,
                readonlyDialog: !val
            }
        });

        dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
            if (result) {
                if (readingType === ReadingType.MODERATION_READING) {
                    this.moderationBatchConfig = {...result};
                    this.candenSparccForm.get('moderationBatchManagement').updateValueAndValidity();
                } else {
                    this.basicBatchConfig = {...result};
                    this.candenSparccForm.get('batchManagement').updateValueAndValidity();
                }
            }
          ImagingProjectReadingBasicComponent.clearFocus();
        });
    }

    compareReaders(r1: any, r2: any): boolean {
        return r1 && r2 ? r1.id === r2.id : r1 === r2;
    }

    isReadersValid(): boolean {
        return this.readersDataSource.data.length >= this.MIN_READERS && this.readersDataSource.data.length <= this.MAX_READERS;
    }

    isModeratorReadersValid(): boolean {
        return !this.candenSparccForm || !this.candenSparccForm.get('moderationEnabled').value ||
            this.moderatorReadersDataSource.data.length === this.MODERATOR_READERS;
    }

    get readersValidator() {   // computed property suppress property is used before its initialization error
        return (): ValidationErrors | null => {
            return this.isReadersValid() ? null : {
                tableError: `${this.MIN_READERS}..${this.MAX_READERS} users must be in the list`,
                selectError: `please ${this.readersDataSource.data.length < this.MAX_READERS ? 'select' : 'remove'} option`
            };
        };
    }

    get moderatorReadersValidator() {
        return (): ValidationErrors | null => {
            return this.isModeratorReadersValid() ? null : {
                tableError: `${this.MODERATOR_READERS} users must be in the list`,
                selectError: `please ${this.moderatorReadersDataSource.data.length < this.MODERATOR_READERS ? 'select' : 'remove'} option`
            };
        };
    }

    compareReadersByName(r1: Reader, r2: Reader): number {
        return (r1.firstName + r1.lastName).localeCompare(r2.firstName + r2.lastName);
    }

    pushReaders(): void {
        const selectedReaderIds = [...this.candenSparccForm.get('readers').value, ...this.readersDataSource.data.map(reader => reader.id)];
        this.readersDataSource.data =
            this.readers
                .filter(reader => selectedReaderIds.includes(reader.id))
                .sort(this.compareReadersByName);
        this.readerSelectOptions = this.readers
            .filter(reader => !selectedReaderIds.includes(reader.id))
            .sort(this.compareReadersByName);
        this.candenSparccForm.patchValue({readers: []});
    }

    pushModeratorReaders(): void {
        const selectedReaderIds =
            [...this.candenSparccForm.get('moderatorReaders').value, ...this.moderatorReadersDataSource.data.map(reader => reader.id)];
        this.moderatorReadersDataSource.data =
            this.readers
                .filter(reader => selectedReaderIds.includes(reader.id))
                .sort(this.compareReadersByName);
        this.moderatorReaderSelectOptions = this.readers
            .filter(reader => !selectedReaderIds.includes(reader.id))
            .sort(this.compareReadersByName);
        this.candenSparccForm.patchValue({moderatorReaders: []});
    }

    removeReader(id: number): void {
        this.readersDataSource.data = this.readersDataSource.data.filter(reader => reader.id !== id);
        this.readerSelectOptions = [...this.readerSelectOptions, this.readers.find(r => r.id === id)].sort(this.compareReadersByName);
        this.candenSparccForm.get('readers').updateValueAndValidity();
    }

    removeModeratorReader(id: number): void {
        this.moderatorReadersDataSource.data = this.moderatorReadersDataSource.data.filter(reader => reader.id !== id);
        this.moderatorReaderSelectOptions =
            [...this.moderatorReaderSelectOptions, this.readers.find(r => r.id === id)].sort(this.compareReadersByName);
        this.candenSparccForm.get('moderatorReaders').updateValueAndValidity();
    }

    cancel(): void {
        this.clickCancel.emit({result: 'cancel'});
    }

    prepareBasicBatchConfigPayload(): AutoBatchConfigPayload {
        let configPayload: AutoBatchConfigPayload = null;
        if (this.basicBatchConfig) {
            const configId: number = this.basicBatchConfig.id ? this.basicBatchConfig.id : null;
            if (this.candenSparccForm.get('batchManagement').value) {
                configPayload = {
                    numberOfReadings: this.basicBatchConfig.numberOfReadings,
                    readingType: ReadingType.BASIC_READING,
                    initiationMethod: this.basicBatchConfig.initiationMethod,
                    initiationOption: this.basicBatchConfig.initiationOption,
                    batchCount: this.basicBatchConfig.batchCount
                };
            } else {
                configPayload = {...defaultBasicAutoBatchConfig};
                configPayload.readingType = ReadingType.BASIC_READING;
            }
            configPayload.id = configId;
        }
        return configPayload;
    }

    prepareLevelOneBatchConfigPayload(): AutoBatchConfigPayload {
        let configPayload: AutoBatchConfigPayload = null;
        if (this.basicBatchConfig) {
            const configId: number = this.basicBatchConfig.id ? this.basicBatchConfig.id : null;
            if (this.candenSparccForm.get('batchManagement').value) {
                configPayload = {
                    numberOfReadings: this.basicBatchConfig.numberOfReadings,
                    readingType: ReadingType.LEVEL1_READING,
                    initiationMethod: this.basicBatchConfig.initiationMethod,
                    initiationOption: this.basicBatchConfig.initiationOption,
                    batchCount: this.basicBatchConfig.batchCount
                };
            } else {
                configPayload = {...defaultLvl1AutoBatchConfig};
                configPayload.readingType = ReadingType.LEVEL1_READING;
            }
            configPayload.id = configId;
        }
        return configPayload;
    }

    prepareModerationBatchConfigPayload(): AutoBatchConfigPayload {
        let configPayload: AutoBatchConfigPayload = null;
        /*if (this.moderationBatchConfig) {
            const configId: number = this.moderationBatchConfig.id ? this.moderationBatchConfig.id : null;
            if (this.candenSparccForm.get('moderationBatchManagement').value) {
                configPayload = {
                    id: configId,
                    numberOfReadings: this.moderationBatchConfig.numberOfReadings,
                    readingType: ReadingType.MODERATION_READING,
                    initiationMethod: this.moderationBatchConfig.initiationMethod,
                    initiationOption: this.moderationBatchConfig.initiationOption,
                    batchCount: this.moderationBatchConfig.batchCount
                };
            } else {
                configPayload = {...defaultAdjAutoBatchConfig};
                configPayload.readingType = ReadingType.MODERATION_READING;
            }
            configPayload.id = configId;
        }*/
        if (this.moderationBatchConfig?.id) {
            configPayload = {
                id: this.moderationBatchConfig.id,
                numberOfReadings: this.moderationBatchConfig.numberOfReadings,
                readingType: ReadingType.MODERATION_READING,
                initiationMethod: this.moderationBatchConfig.initiationMethod,
                initiationOption: this.moderationBatchConfig.initiationOption,
                batchCount: this.moderationBatchConfig.batchCount
            };
        }
        return configPayload;
    }

    submit(): void {
        const payload: CandenSparccConfigModel = this.candenSparccForm.value;
        payload.modalities = this.candenSparccForm.value.modalities;
        payload.readers = this.readersDataSource.data.map(reader => {
            return {id: reader.id};
        });
        payload.moderatorReaders = [];
        payload.anatomySelector = this.selectedSequenceLabels.map(l => {
            return {id: l.id};
        });

        let imagingProjectSubmitEvent: ImagingProjectSubmitEvent;
        if (!payload.moderationEnabled) {  // no moderation
            payload.readingVersion = ReadingVersion.CANDEN_SPARCC_BASIC;
            payload.endpointReadingVersion = 'basic';
            imagingProjectSubmitEvent = {
                result: 'submit',
                data: payload,
                basicBatchConfig: this.prepareBasicBatchConfigPayload()
            };
        } else {
            payload.moderatorReaders = this.moderatorReadersDataSource.data.map(reader => {
                return {id: reader.id};
            });
            payload.moderationReader = this.moderatorReadersDataSource.data[0]['id']; /// !
            payload.readingVersion = ReadingVersion.CANDEN_SPARCC_MODERATION;
            payload.endpointReadingVersion = 'moderation';
            imagingProjectSubmitEvent = {
                result: 'submit',
                data: payload,
                level1BatchConfig: this.prepareLevelOneBatchConfigPayload(),
                moderationBatchConfig: this.prepareModerationBatchConfigPayload()
            };
        }
        // console.warn(JSON.stringify(imagingProjectSubmitEvent));
        this.clickSubmit.emit(imagingProjectSubmitEvent);
    }

    moderationEnabledChange(change: MatCheckboxChange): void {
        this.candenSparccForm.get('moderatorReaders').updateValueAndValidity();
        this.candenSparccForm.get('moderationBatchManagement').updateValueAndValidity();
        this.candenSparccForm.get('factorstoScoreSIJ').updateValueAndValidity();
    }

}
