import {LoginGuard} from './_guards/login.guard';
import {RouterModule, Routes} from '@angular/router';
import {DEFAULT_ROUTE, LoginComponent} from './modules/login';
import {AuthGuard} from './_guards';
import {
  ImagingProjectListComponent
} from './modules/ImagingProject/imaging-project-list/imaging-project-list.component';
import {UploadListComponent} from './modules/DataUpload/upload-list/upload-list.component';
import {UploadVisitFormComponent} from './modules/DataUpload/upload-visit-form/upload-visit-form.component';
import {NewPatientComponent} from './modules/Patient/new-patient/new-patient.component';
import {
  ImagingProjectCreateComponent
} from './modules/ImagingProject/imaging-project-create/imaging-project-create.component';
import {
  ImagingProjectSponsorComponent
} from './modules/ImagingProject/imaging-project-sponsor/imaging-project-sponsor.component';
import {
  ImagingProjectSiteComponent
} from './modules/ImagingProject/imaging-project-site/imaging-project-site.component';
import {
  ImagingProjectImagingComponent
} from './modules/ImagingProject/imaging-project-imaging/imaging-project-imaging.component';
import {
  ImagingProjectSequenceComponent
} from './modules/ImagingProject/imaging-project-sequence/imaging-project-sequence.component';
import {
  ImagingProjectEndpointComponent
} from './modules/ImagingProject/imaging-project-endpoint/imaging-project-endpoint.component';
import {
  ImagingProjectUserComponent
} from './modules/ImagingProject/imaging-project-user/imaging-project-user.component';
import {
  ImagingProjectRoleComponent
} from './modules/ImagingProject/imaging-project-role/imaging-project-role.component';
import {
  ImagingProjectActivityComponent
} from './modules/ImagingProject/imaging-project-activity/imaging-project-activity.component';
import {
  ImagingProjectScannerComponent
} from './modules/ImagingProject/imaging-project-scanner/imaging-project-scanner.component';
import {QCTaskListComponent} from './modules/QualityControl/qctask-list/qctask-list.component';
import {QCDetailsComponent} from './modules/QualityControl/qcdetails/qcdetails.component';
import {ChangePasswordComponent} from './modules/UserManagement/change-password/change-password.component';
import {NewEdcfComponent} from './modules/Queries/edcf/new-edcf/new-edcf.component';
import {EdcfListComponent} from './modules/Queries/edcf/edcf-list/edcf-list.component';
import {EdcfDetailComponent} from './modules/Queries/edcf/edcf-detail/edcf-detail.component';
import {DashboardContainerComponent} from './modules/Dashboard/dashboard-container/dashboard-container.component';
import {
  DownloadReportPageComponent
} from './modules/Reading/Download/download-report-page/download-report-page.component';
import {
  OverallStudyFinderComponent
} from './modules/Reading/OverallStudyFinder/overall-study-finder/overall-study-finder.component';
import {MaintenancePageComponent} from './modules/Maintenance/maintenance-page/maintenance-page.component';
import {ViewerCoreLtComponent} from './modules/Viewer/viewer-core-lt/viewer-core-lt.component';
import {
  OperationDashboardComponent
} from './modules/OperationsDashboard/operation-dashboard-component/operation-dashboard.component';
import {ProjectDetailsComponent} from './modules/OperationsDashboard/project-details/project-details.component';
import {BatchComponent} from './modules/OperationsDashboard/BatchLogic/batch/batch.component';
import {MoaksBasicFormComponent} from './modules/Reading/MOAKS/moaks-basic-form/moaks-basic-form.component';
import {ReadingFormComponent} from './modules/Reading/ReadingForm/reading-form/reading-form.component';
import {Novadip5Component} from './modules/Reading/NOVADIP5/novadip5.component';
import {KlFormComponent} from './modules/Reading/KL/kl-form/kl-form.component';
import {LuganoComponent} from './modules/Reading/Lugano/lugano.component';
import {RecistComponent} from './modules/Reading/RECIST/recist.component';
import {MRANOFormComponent} from './modules/Reading/MRANO/mrano-form.component';
import {
  PrimaryBodyLocationListComponent
} from './modules/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-list/primary-body-location-list.component';
import {PsamrisFormComponent} from './modules/Reading/PsAMRIS/psamris-form/psamris-form.component';
import {HeelEnthesitisBasicFormComponent} from './modules/Reading/HEEL/heel-enthesitis-basic-form.component';
import {WbmriFormComponent} from './modules/Reading/WBMRI/wbmri-form/wbmri-form.component';
import {
  WebinarProjectListComponent
} from './modules/WebinarProject/webinar-project-list/webinar-project-list.component';
import {
  WebinarProjectCreateComponent
} from './modules/WebinarProject/webinar-project-create/webinar-project-create.component';
import {WebinarUploadListComponent} from './modules/WebinarProject/DataUpload/upload-list/upload-list.component';
import {
  WebinarUploadVisitFormComponent
} from './modules/WebinarProject/DataUpload/upload-visit-form/upload-visit-form.component';
import {WebinarQCTaskListComponent} from './modules/WebinarProject/QualityControl/qctask-list/qctask-list.component';
import {NewCaseComponent} from './modules/WebinarProject/Case/new-case/new-case.component';
import {CaseListComponent} from './modules/WebinarProject/Case/case-list/case-list.component';
import {QuestionFormComponent} from './modules/WebinarProject/Case/question-form/question-form.component';
import {
  CandenSparccReadingFormComponent
} from './modules/Reading/CANDEN-SPARCC/canden-sparcc-reading-form/canden-sparcc-reading-form.component';
import {DEMRIQFromComponent} from './modules/Reading/DEMRIQ/demriq-form.component';
import {PSMAPETFormComponent} from './modules/Reading/PSMA-PET/psma-pet-form.component';
import {
  ImagingProjectAnonymizationComponent
} from './modules/ImagingProject/imaging-project-anonymization/imaging-project-anonymization.component';
import {IncidentalFindingsComponent} from './modules/Reading/IF/incidental-findings.component';
import {JswReadingFormComponent} from './modules/Reading/JSW/jsw-reading-form/jsw-reading-form.component';
import {
  SponsorSeriesListComponent
} from './modules/OperationsDashboard/sponsor-series-list-component/sponsor-series-list.component';
import {AppVersionsComponent} from './modules/app-versions/app-versions.component';
import * as viewerResolvers from './modules/Viewer/resolvers';
import {
  ImageComparisonDialogComponent
} from './modules/Reading/PsAMRIS/psamris-level1-result/dialog/image-comparison-dialog.component';
import {
  WbmriImgCompareDialogComponent
} from './modules/Reading/WBMRI/wbmri-level1-result/wbmri-img-compare-dialog/wbmri-img-compare-dialog.component';
import {
  AuditTrailsProjectsListComponent
} from './modules/AuditTrails/projects-list/audit-trails-projects-list.component';
import {Path} from './core/constants/path';
import {
  AdvancedAnalysisReadingFormComponent
} from './modules/Reading/Advanced-Analysis/advanced-analysis-reading-form/advanced-analysis-reading-form.component';
import {AuditTrailsViewModeSelectorComponent} from './modules/AuditTrails/view-mode-selector/audit-trails-view-mode-selector.component';
import { OarsiReadingFormBasicComponent } from './modules/Reading/OARSI/oarsi-reading-form-basic/oarsi-reading-form-basic.component';
import { GenantLevel1ReadingFormComponent } from './modules/Reading/GENANT/genant-level1-form/genant-level1-reading-form/genant-level1-reading-form.component';
import { GenantAdjudicationReadingFormComponent } from './modules/Reading/GENANT/genant-adjudication-form/genant-adjudication-reading-form.component';
import { DXAComponent } from './modules/Reading/DXA/dxa.component';
import { GuerbetComponent } from './modules/Reading/GUERBET/guerbet.component';
import { BulkUploadComponent } from './modules/ImagingProject/bulk-upload/bulk-upload.component';
import { L3SMIComponent } from './modules/Reading/l3-smi/l3-smi.component';
import { AtyrComponent } from './modules/Reading/ATYR/atyr.component';
import { ResetPasswordComponent } from './modules/UserManagement/reset-password/reset-password.component';
import {MranoAdjudicationFormComponent} from './modules/Reading/MRANO/mrano-adjudication-form/mrano-adjudication-form.component';
import {
  RanoAdjudicationReadingFormComponent
} from './modules/Reading/MRANO/rano-adjudication-form/rano-adjudication-reading-form.component';
import {SignDetailsComponent} from './modules/ReportSign/sign-details/sign-details.component';
import {SignStudiesListComponent} from "./modules/ReportSign/sign-studies-list/sign-studies-list.component";
import {SignStudyReportsListComponent} from "./modules/ReportSign/sign-study-reports-list/sign-study-reports-list.component";
import { MEPOReadingFormComponent } from './modules/Reading/MEPO/mepo-reading-form.component';

const appRoutes: Routes = [
  {path: '', redirectTo: DEFAULT_ROUTE, pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: Path.APP_VERSION, component: AppVersionsComponent, canActivate: [AuthGuard]},
  {path: 'maintenance', component: MaintenancePageComponent},
  {path: 'reset-password/:token', component: ResetPasswordComponent},
  {path: DEFAULT_ROUTE, component: ImagingProjectListComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/bulk-upload', component: BulkUploadComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/upload/visit', component: UploadVisitFormComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/upload', component: UploadListComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/project/create/:id', component: ImagingProjectCreateComponent, canActivate: [AuthGuard]},
  {path: 'patient/new', component: NewPatientComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/sponsor', component: ImagingProjectSponsorComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/site', component: ImagingProjectSiteComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/user', component: ImagingProjectUserComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/role', component: ImagingProjectRoleComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/activity', component: ImagingProjectActivityComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/imaging', component: ImagingProjectImagingComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/sequence', component: ImagingProjectSequenceComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/endpoint', component: ImagingProjectEndpointComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/bodylocations', component: PrimaryBodyLocationListComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/qc', component: QCTaskListComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/qc/details', component: QCDetailsComponent, canActivate: [AuthGuard]},
  {path: 'leadtools/:seriesId', component: ViewerCoreLtComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/scanner', component: ImagingProjectScannerComponent, canActivate: [AuthGuard]},
  {path: 'usermanagement/changepassword', component: ChangePasswordComponent, canActivate: [AuthGuard]},
  {path: 'queries/edcf/new', component: NewEdcfComponent, canActivate: [AuthGuard]},
  {path: 'queries/edcfs', component: EdcfListComponent, canActivate: [AuthGuard]},
  {path: 'queries/edcf/detail', component: EdcfDetailComponent, canActivate: [AuthGuard]},
  {path: 'queries/edcf/detail/:key', component: EdcfDetailComponent, canActivate: [AuthGuard]},
  {path: Path.OPERATIONAL_DASHBOARD, component: OperationDashboardComponent, canActivate: [AuthGuard]},
  {path: 'operationdashboard/:projectId', component: ProjectDetailsComponent, canActivate: [AuthGuard]},
  {path: 'operationdashboard/batch/:projectId', component: BatchComponent, canActivate: [AuthGuard]},
  {path: 'operationdashboard/batch/:projectId/:batchId', component: BatchComponent, canActivate: [AuthGuard]},
  {path: 'operationdashboard/sponsor/:studyId/:patientId/:visitConfigId', component: SponsorSeriesListComponent, canActivate: [AuthGuard]},
  {path: Path.AUDIT_TRAILS, component: AuditTrailsProjectsListComponent, canActivate: [AuthGuard]},
  {path: `${Path.AUDIT_TRAILS}/:projectId`, component: AuditTrailsViewModeSelectorComponent, canActivate: [AuthGuard]},
  {path: 'reading/mrano/mrano-basic', component: MRANOFormComponent, canActivate: [AuthGuard]},
  { path: 'reading/mrano/adjudication', component: MranoAdjudicationFormComponent, canActivate: [AuthGuard] },
  { path: 'reading/heel-enthesitis', component: HeelEnthesitisBasicFormComponent, canActivate: [AuthGuard], data: {protocolType: 'HEMRIS'}},
  { path: 'download/reading/report/:studyId/:endpointName/:readingId', component: DownloadReportPageComponent, canActivate: [AuthGuard]},
  { path: 'dashboard/:studyId', component: DashboardContainerComponent, canActivate: [AuthGuard]},
  {path: 'reading/studyfinder', component: OverallStudyFinderComponent, canActivate: [AuthGuard]},
  {path: 'webinar/list', component: WebinarProjectListComponent, canActivate: [AuthGuard]},
  {path: 'webinar/create/:id', component: WebinarProjectCreateComponent, canActivate: [AuthGuard]},
  {path: 'webinar/upload', component: WebinarUploadListComponent, canActivate: [AuthGuard]},
  {path: 'webinar/upload/visit', component: WebinarUploadVisitFormComponent, canActivate: [AuthGuard]},
  {path: 'webinar/qc', component: WebinarQCTaskListComponent, canActivate: [AuthGuard]},
  {path: 'webinar/qc/details', component: QCDetailsComponent, canActivate: [AuthGuard]},
  {path: 'case/new', component: NewCaseComponent, canActivate: [AuthGuard]},
  {path: 'webinar/case-list', component: CaseListComponent, canActivate: [AuthGuard]},
  {path: 'webinar/question/visit', component: QuestionFormComponent, canActivate: [AuthGuard]},
  {
    path: 'reading/demriq',
    component: DEMRIQFromComponent,
    canActivate: [AuthGuard],
    data: { protocolType: 'Demriq' },
    resolve: {
      viewerConfig: viewerResolvers.ViewerConfigResolver,
      userRoles: viewerResolvers.StudyUserRolesResolver
    }
  },
  {path: 'reading/psma-pet-eligibility', component: PSMAPETFormComponent, canActivate: [AuthGuard]},
  {path: 'imagingproject/anonymization', component: ImagingProjectAnonymizationComponent, canActivate: [AuthGuard]},
  {path: 'psamris/reading-image-comparison', component: ImageComparisonDialogComponent, canActivate: [AuthGuard]},
  {path: 'wbmri/reading-image-comparison', component: WbmriImgCompareDialogComponent, canActivate: [AuthGuard]},
  {
    path: 'reading', component: ReadingFormComponent, canActivate: [AuthGuard],
    children: [
      { path: 'novadip5', component: Novadip5Component, canActivate: [AuthGuard] },
      { path: 'kl/:type', component: KlFormComponent, canActivate: [AuthGuard] },
      { path: 'genant/basic', component: GenantLevel1ReadingFormComponent, canActivate: [AuthGuard] },
      { path: 'genant/adjudication', component: GenantAdjudicationReadingFormComponent, canActivate: [AuthGuard] },
      { path: 'advanced-analysis', component: AdvancedAnalysisReadingFormComponent, canActivate: [AuthGuard] },
      { path: 'lugano', component: LuganoComponent, canActivate: [AuthGuard] },
      { path: 'recist', component: RecistComponent, canActivate: [AuthGuard] },
      { path: 'moaks/moaks-basic', component: MoaksBasicFormComponent, canActivate: [AuthGuard] },
      {
        path: 'psamris/:type',
        component: PsamrisFormComponent,
        canActivate: [AuthGuard],
        data: { protocolType: 'Psamris' }
      },
      {
        path: 'wbmri/:type',
        component: WbmriFormComponent,
        canActivate: [AuthGuard],
        data: { protocolType: 'WB-MRI' }
      },
      {
        path: 'canden-sparcc',
        component: CandenSparccReadingFormComponent,
        canActivate: [AuthGuard],
        data: { protocolType: 'CANDEN_SPARCC' }
      },
      { path: 'if', component: IncidentalFindingsComponent, canActivate: [AuthGuard] },
      { path: 'dxa', component: DXAComponent, canActivate: [AuthGuard] },
      { path: 'jsw', component: JswReadingFormComponent, canActivate: [AuthGuard] },
      { path: 'oarsi', component: OarsiReadingFormBasicComponent, canActivate: [AuthGuard] },
      { path: 'guerbet/:readerType', component: GuerbetComponent, canActivate: [AuthGuard] },
      { path: 'guerbet/:readerType/:readingId', component: GuerbetComponent, canActivate: [AuthGuard] },
      { path: 'l3smi', component: L3SMIComponent, canActivate: [AuthGuard] },
      { path: 'atyr', component: AtyrComponent, canActivate: [AuthGuard] },
      { path: 'mepo', component: MEPOReadingFormComponent, canActivate: [AuthGuard] },
      { path: 'rano/adjudication', component: RanoAdjudicationReadingFormComponent, canActivate: [AuthGuard] },
    ]
  },
  {path: 'sign', component: SignStudiesListComponent, canActivate: [AuthGuard]},
  {path: 'sign/:studyId', component: SignStudyReportsListComponent, canActivate: [AuthGuard], data: {studyType: 'REPORT'}},
  {path: 'sign-guerbet/:studyId', component: SignStudyReportsListComponent, canActivate: [AuthGuard], data: {studyType: 'GUERBET'}},
  {path: 'sign/:studyId/:reportId', component: SignDetailsComponent, canActivate: [AuthGuard]},

  // otherwise redirect to home
  {path: '**', redirectTo: ''}
];

export const routing = RouterModule.forRoot(appRoutes);
