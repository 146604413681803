
/**
 * @description
 * Action manager service interface for Dynamika image visualization component.
 *
 */
export abstract class ActionManagerService {
  public takeSnapShot: any;
  public removeSeriesCell: any;
  public newCell: any;
  public newCellDiam: any;
  public isReferenceLine: any;
  public referenceLineStatus: any;
  public refrenceCellSliceCount: any;
  public sortData: any;
  public viewerComponent;

  /**
   * Set command actions to cell for toolbar buttons.
   * @param cell
   */
  abstract intializeActions(cell: any): void;
  /**
   * Run Action Command
   * @param action
   */
  abstract runActionCommand(action: string, info: any): void;
  /**
   * Creates SVG icon registry for Dynamika image visualization component toolbar.
   */
  abstract createIconRegistry(): void;
  /**
   *  Initialize the window level custom menu
   */
  abstract initializeWindowLevelCustomSubMenu(cell): any[];
  /**
   *  Make room cells on the viewer
   */
  abstract makeRoomFor(viewer, roomFor)
  /**
   *  Clean room cells on the iezer
   */
  abstract clearRoomFor(viewer, roomFor)
  /**
   *  Add container events
   */
  abstract addContainerEvents(cell, info);
  /**
   *  Update the grid layout size
   * @param viewer
   * @param cellCount
   */
  abstract updateGridLayoutSize(viewer, cellCount);
  /**
   *  Delete cell from the viewer
   * @param viewer
   * @param cell
   */
  abstract deleteCell(viewer, cell)
  /**
   *  Reset cells position
   * @param viewer
   */
  abstract resetCellsPosition(viewer)
  /**
   *  Fill GBM Series List
   * @param StatID
   * @param SeriesID
   * @param visitConfigID
   */
  abstract fillGBMSeriesList(StatID: number, SeriesID?: number, visitConfigID?: number);
  /**
   *  Load cell annotations
   * @param cell
   * @param info
   */
  abstract loadCellAnnotations(cell, info);
  /**
   *  enable Selection For Rois
   * @param info
   * @param loadROIs
   */
  abstract enableSelectionForRois(info, loadROIs);
  /**
   *  set custom freehand info
   * @param color
   * @param regionToScore
   * @param primaryLocation
   * @param info
   */
  abstract drawCustomFreehand(color, regionToScore, primaryLocation, info);
  /**
   *  set selected regions
   * @param selectedRegions
   */
  abstract setSelectedRegions(selectedRegions);
  /**
   *  adding reference lines to the cells in viewer
   * @param currentOffset
   */
  abstract getViewerInstance(): lt.Controls.Medical.MedicalViewer;
  /**
   *  trigger pan and zoom synchronization
   * @param info
   * @param status
   */
  abstract OnPanZoomSynch(info, status?)
  /**
   *  add reference lines
   * @param currentOffset
   */
  abstract addingReferenceLines(currentOffset);
  /**
   *  activate/deactivate Rapid Drawing feature
   * @param info
   */
   abstract OnRapidAction(info);
    /**
   *  Add marker
   * @param info
   * @param markerType
   * @param lesionName
   */
   abstract OnAddMarker(info, markerType, lesionName?);
   /**
   *  Confirm selected marker as lesion
   * @param info
   */
   abstract OnConfirmMarker(info);
   /**
   *  Remove Marker
   * @param info
   */
   abstract OnRemoveMarker(info);
    /**
   *  Confirm selected marker as lesion
   * @param info
   * @param shouldOpen
   */
   abstract onLoadObjects(info, shouldOpen);
    /**
   *  copy ROI
   * @param info
   */
   abstract OnCopyROI(info);
    
   /**
    *  delete ROIs
    * @param info
    */
    abstract OnDeleteROI(info);

    /**
    *  unlink cells
    * @param cells
    * @param targetCmds
    */
    abstract unlinkCells(cells, targetCmds);

    /**
    * @param info
    */
    abstract confirmContours(info);
}

