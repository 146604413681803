import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MultiselectAnswers } from '../imaging-project-reading-if/model.questionformat';

@Component({
  selector: 'app-multiselect-answers-dialog',
  templateUrl: './multiselect-answers-dialog.component.html',
  styleUrls: ['./multiselect-answers-dialog.component.css']
})

export class MultiselectAnswersDialogComponent implements OnInit {

  multiSelectForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<MultiselectAnswersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { data:any[], hasCommentRequiredField: boolean, title: string}) { }
  ngOnInit(): void {
    this.multiSelectForm = new FormGroup({
      multiSelectItems: new FormArray([])
    });
    if (this.data) {
      this.initAnswers(this.data.data)
    }
  }

  initAnswers(copyData: any[]) {
    let item: FormGroup;
    copyData.forEach(a => {
      if(this.data.hasCommentRequiredField){
        item = new FormGroup({
          title: new FormControl(a.title, [Validators.required, Validators.maxLength(200)]),
          commentRequired: new FormControl(a.commentRequired, [Validators.required]),
        });
      } else {
        item = new FormGroup({
          title: new FormControl(a.title, [Validators.required, Validators.maxLength(200)]),
        });
      }
      (<FormArray>this.multiSelectForm.get('multiSelectItems')).push(item);
    })
  }

  getArrayControls() {
   return (this.multiSelectForm.get('multiSelectItems') as FormArray).controls
  }

  getControl(i) {
    return (<FormArray>this.multiSelectForm.get('multiSelectItems')).controls[i].get('title')
  }

  onAddItem() {
    let item: FormGroup;
    if(this.data.hasCommentRequiredField){
      item = new FormGroup({
        title: new FormControl('', [Validators.required, Validators.maxLength(200)]),
        commentRequired: new FormControl(false, [Validators.required]),
      });
    } else {
      item = new FormGroup({
        title: new FormControl('', [Validators.required, Validators.maxLength(200)]),
      });
    }
    (<FormArray>this.multiSelectForm.get('multiSelectItems')).push(item);
  }

  onRemoveItem(index) {
    (<FormArray>this.multiSelectForm.get('multiSelectItems')).removeAt(index);
  }

  onSubmitForm() {
    this.dialogRef.close(this.multiSelectForm.get('multiSelectItems').value)
  }

  closeClick() {
    this.dialogRef.close();
  }
  
}
