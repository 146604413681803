import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSUserVersion;
const SERVICE = 'msuser-dot-';
const requestURL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class SiteUserService {

  constructor(private http: HttpClient) { }

  create(siteUser: any) {
    return this.http.post(requestURL + `/site-user`, siteUser);
  }
  
  update(siteUser: any) {
    return this.http.put(requestURL + `/site-user`, siteUser);
  }
  
  delete(siteUser: any) {
    return this.http.delete(requestURL + `/site-user/`, siteUser);
  }

  deleteAllBySiteId(siteId: number) {
    return this.http.delete(requestURL + `/site-user/delete-all-by-site-id/` + siteId);
  }

  deleteAllByUserId(userId: number) {
    return this.http.delete(requestURL + `/site-user/delete-all-by-user-id/` + userId);
  }

  getSiteUser(siteId: number, userId: number) {
    return this.http.get(requestURL + '/site-user/' + siteId + '/' + userId);
  }

  getUsers(siteId: number) {
    return this.http.get(requestURL + '/site-user/users/' + siteId);
  }

  getSites(userId: number) {
    return this.http.get(requestURL + '/site-user/sites/' + userId);
  }
  
  getAll() {
    return this.http.get(requestURL + `/site-user/retrieve-all`);
  }

}
