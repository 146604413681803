import { Observable } from 'rxjs';
/**
 * @description
 * Authentication service interface for Dynamika image visualization component backend API services. 
 * 
 */

export abstract class ViewerAuthenticationService {
    public authenticationCode: string;
    public authenticationMessage: string;

    /**
     * Authenticate with Dynamika image visualization component backend API services.
     */
    abstract authenticate(): Observable<any>;
}

