import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {forkJoin} from 'rxjs';
import {ReadingConfigFlexibleService, StudySequenceLabelService, StudyUserService} from '../../../../../_services';
import {MatTableDataSource} from '@angular/material/table';
import {FormMode} from '../../../../../core/constants/form-mode';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {User} from '../../../../../_models/user';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {KLBasicConfigModel} from '../../../../../_models/ImagingProject/KANDL/kandl-basic-config-model';
import {defaultBasicAutoBatchConfig, ImagingProjectSubmitEvent} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {MatDialog} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

interface ConfigForm {
  endpointReadingVersion: 'basic';
  readingLevel: 'modality' | 'visit';
  readingType: 'parallel';
  readingVersion: ReadingVersion.KANDL_BASIC;
  modality: ModalityModel;
  anatomySelector: SequenceLabelModel[];
  imageViewerConfiguration: 'default';
  batchManagement: boolean;
  hideVisitChronology: string;
  enableOAPredominantlyMedial: boolean;
  enableOAPredominantlyLateral: boolean;
  kneeMalalaignment: boolean;
  modalities: [];
  readers: User[];
}

type ConfigControls = { [key in keyof ConfigForm]: FormControl };
type ConfigFormGroup = FormGroup & { value: ConfigForm, controls: ConfigControls };

@Component({
  selector: 'app-imaging-project-reading-klbasic',
  templateUrl: './imaging-project-reading-klbasic.component.html',
  styleUrls: ['./imaging-project-reading-klbasic.component.css']
})
export class ImagingProjectReadingKLBasicComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  readonly SHOW_VISITS_CHRONOLOGY = 'show_visits_chronology';
  readonly HIDE_VISITS_CHRONOLOGY = 'hide_visits_chronology';

  @Input() selectedConfig: FlexibleConfig<KLBasicConfigModel>;

  @Input() public mode: FormMode;

  @Input() set configModel(configModel: KLBasicConfigModel) {
    if (configModel) {
      this._configModel = configModel;
    }
  }

  get configModel(): KLBasicConfigModel {
    return this._configModel;
  }

  @Output() public clickCancel = new EventEmitter<any>();
  @Output() public clickSubmit = new EventEmitter<any>();

  get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true && ((!!this.batchConfig && !this.batchConfig?.numberOfReadings) || !this.batchConfig)
        ? {norError: 'Number of readings is required'} : null;
    };
  }

  get readersValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return !!this.readersDataSource.data && this.readersDataSource.data.length > 0
        ? null : {required: 'Please select readers'};
    };
  }

  readonly MAX_READERS = 20;
  readonly formModes = FormMode;

  klForm: ConfigFormGroup;
  batchConfig: BatchConfigModel;

  studySequenceLabels: SequenceLabelModel[] = [];
  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];

  modalities: ModalityModel[];

  availableReaders: User[] = [];
  readerSelectOptions: User[] = [];

  selectedModality: ModalityModel;

  readersDataSource: MatTableDataSource<User>;

  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];

  readers: any[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  studyId: number;

  _configModel: KLBasicConfigModel;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private studySequenceLabelService: StudySequenceLabelService,
    private batchLogicService: BatchLogicService,
    private studyUserService: StudyUserService,
    private toastyService: ToastyService,
    private fb: FormBuilder,
    private dialog: MatDialog) {
    super();
  }

  ngOnInit() {
    this.studyId = parseInt(this.route.snapshot.params.id, 10);
    this.readersDataSource = new MatTableDataSource<User>();
    this.initConfigForm();

    const serviceCalls = [
      this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
      this.studyUserService.getReadersByStudyId(this.studyId),
      this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId)
    ];

    if (this.mode === FormMode.EDIT) {
      serviceCalls.push(this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id));
    }

    forkJoin(serviceCalls)
      .subscribe(([respGroupedModalitiesResp, readersResponse, studySequenceResp, batchConfigResponse]) => {
        if (this.mode === FormMode.EDIT) {
          this.initBatchConfig(batchConfigResponse.data);
        }
        this.initConfigFormValues(readersResponse.data);
        this.initModalities(respGroupedModalitiesResp);
        this.initReaders(readersResponse);
        this.prepareSequenceLabels(studySequenceResp);
      });

  }

  initConfigForm(): void {
    this.klForm = this.fb.group({
      endpointReadingVersion: new FormControl('basic', [Validators.required]),
      readingLevel: new FormControl('modality', [Validators.required]),
      modality: new FormControl([], [Validators.required]),
      readingType: new FormControl('parallel', [Validators.required]),
      readers: new FormControl([], [this.readersValidator]),
      anatomySelector: new FormControl(null, [Validators.required]),
      imageViewerConfiguration: new FormControl('default', [Validators.required]),
      batchManagement: new FormControl(false, [Validators.required, this.batchManagementValidator]),
      hideVisitChronology: new FormControl(this.SHOW_VISITS_CHRONOLOGY, [Validators.required]),
      enableOAPredominantlyMedial: new FormControl(false, [Validators.required]),
      enableOAPredominantlyLateral: new FormControl(false, [Validators.required]),
      kneeMalalaignment: new FormControl(false, [Validators.required]),
    } as ConfigControls) as ConfigFormGroup;
  }

  initConfigFormValues(availableReaders: User[]): void {
    const inputConfig = this.configModel;
    if (this.mode === FormMode.EDIT) {
      const readers = availableReaders.filter(r => !!inputConfig.readers.find(rId => rId.id === r.id));
      this.readerSelectOptions = availableReaders.filter(r => !readers.find(rId => rId.id === r.id));
      this.klForm.patchValue({
        endpointReadingVersion: inputConfig.endpointReadingVersion,
        readingLevel: inputConfig.readingLevel,
        readingType: inputConfig.readingType,
        batchManagement: inputConfig.batchManagement,
        readers: readers,
        modality: inputConfig.modalities,
        hideVisitChronology: inputConfig.hideVisitChronology,
        enableOAPredominantlyMedial: inputConfig.enableOAPredominantlyMedial,
        enableOAPredominantlyLateral: inputConfig.enableOAPredominantlyLateral,
        kneeMalalaignment: inputConfig.kneeMalalaignment
      });
      if (!inputConfig.hideVisitChronology) {
        inputConfig.hideVisitChronology = this.SHOW_VISITS_CHRONOLOGY;
      }
      this.klForm.patchValue({hideVisitChronology: inputConfig.hideVisitChronology});

      this.readersDataSource.data = readers;
      this.klForm.markAllAsTouched();
      this.klForm.markAsDirty();
    }

    this.klForm.controls.readers.updateValueAndValidity();
    this.klForm.updateValueAndValidity();
  }

  initModalities(respGroupedModalitiesResp): void {
    if (respGroupedModalitiesResp.data != null) {
      this.modalities = respGroupedModalitiesResp.data;
      this.modalities.sort((a, b) => a.name.localeCompare(b.name));
      //const modalities = this.modalities.filter(m => m.id === val.modality.id);
      //this.modalities = [this.modalities.find(m => m.name === 'DX')];
     /* let modality = [];
      this.configModel.modalities.forEach(m => {
          modality.push(this.modalities.filter(m => m.id === val.modality.id));
      });
      this.configModel.modalities = modality;*/
      if (!!this.configModel) {
        //this.klForm.controls.modality.setValue(this.configModel.modalities);
      }
    }
  }

  initBatchConfig(batchConfigData): void {
    this.batchConfig = batchConfigData[0];
  }

  initReaders(readersResponse): void {
    if (readersResponse.responseCode === 200) {
      if (readersResponse.data) {
        this.availableReaders = readersResponse.data;
        if (this.mode === FormMode.NEW) {
          this.readerSelectOptions =
            (readersResponse.data as User[]).sort(this.compareReadersByName);
        }
      }
    }
  }

  prepareSequenceLabels(studySequenceResp) {
    this.studySequenceLabels = studySequenceResp.data;
    if (this.mode === FormMode.EDIT) {
      if (!!this.configModel?.anatomySelector) {
        this.initSequenceLabels(this.configModel.anatomySelector);
      } else {
        this.initDefaultSequenceLabels(this.modalities);
      }
    }
  }

  initDefaultSequenceLabels(modalities) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.availableSequenceLabels;
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    const modalities = this.modalities.filter(m => this.selectedConfig.config.modalities.find(cm => cm.name === m.name));
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  getAvailableSequenceLabels(modalities): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const modality of modalities) {
      for (const label of modality.sequenceLabels) {
        configLabels.add(label);
      }
    }

    return this.studySequenceLabels
      .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
  }

  onBatchManagementSelected(): void {
    if (this.batchConfig) {
      this.batchConfig.numberOfReadings = null;
    }
    this.klForm.controls.batchManagement.updateValueAndValidity();
  }

  openAutoCreationBatchConfigDialog(config: BatchConfigModel): void {
    let inputConfig = config as AutoBatchConfigPayload;
    const val: boolean = this.klForm.controls.batchManagement.value;

    if (!val) {
      inputConfig = { ...defaultBasicAutoBatchConfig };
    } else {
      inputConfig = this.batchConfig ? { ...this.batchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        readingType: ReadingType.BASIC_READING,
        configInput: inputConfig,
        readonlyDialog: !val
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
        this.batchConfig = {...result};
        this.klForm.get('batchManagement').updateValueAndValidity();
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  onModalityTypeChange(event: MatSelectChange) {
    this.klForm.patchValue({modalities: event.value});
    this.initDefaultSequenceLabels(this.klForm.controls.modality.value);
  }

  onSequenceLabelSelect(e: SequenceLabelModel[]) {
    this.selectedSequenceLabels = e;
    this.klForm.controls.anatomySelector.setValue(e);
  }

  onAddSelectedReaders() {
    const selectedReaderIds = [...this.klForm.controls.readers.value, ...this.readersDataSource.data.map(reader => reader.id)];
    this.readersDataSource.data = this.availableReaders
      .filter(reader => selectedReaderIds.includes(reader.id))
      .sort(this.compareReadersByName);
    this.readerSelectOptions = this.availableReaders
      .filter(reader => !selectedReaderIds.includes(reader.id))
      .sort(this.compareReadersByName);
    this.klForm.patchValue({readers: this.readersDataSource.data});
  }

  deleteSelectedReader(reader: User): void {
    this.readersDataSource.data = this.readersDataSource.data.filter(r => r.id !== reader.id);
    this.readerSelectOptions = [...this.readerSelectOptions, this.availableReaders
      .find(r => r.id === reader.id)].sort(this.compareReadersByName);
    this.klForm.controls.readers.setValue(this.readersDataSource.data);
    this.klForm.controls.readers.updateValueAndValidity();
  }

  clickSubmitBtn() {
    const readers = [];
    this._configModel['endpointReadingVersion'] = 'basic';
    this._configModel.readers.forEach(r => {
      readers.push({id: r.id});
    });
    this._configModel.readers = readers;

    const  modalities: ModalityModel[] =  this.klForm.value.modality;

    //const modalities = this.modalities.filter(m => m.id === val.modality.id);

    const readersData = this.klForm.controls.readers.value.map(r => {
        return {id: r.id};
      }
    );

    const configData: KLBasicConfigModel = {
      readers: readersData,
      readingVersion: ReadingVersion.KANDL_BASIC,
      readingType: 'parallel',
      readingLevel: 'modality',
      endpoint: null,
      modalities: modalities,
      endpointReadingVersion: 'basic',
      batchManagement: this.klForm.controls.batchManagement.value,
      hideVisitChronology: this.klForm.controls.hideVisitChronology.value,
      // karimcheck: 364 value?
      enableOAPredominantlyMedial: this.klForm.controls.enableOAPredominantlyMedial.value,
      enableOAPredominantlyLateral: this.klForm.controls.enableOAPredominantlyLateral.value,
      kneeMalalaignment: this.klForm.controls.kneeMalalaignment.value,
      anatomySelector: this.klForm.controls.anatomySelector.value
    };
    const submitData: ImagingProjectSubmitEvent = {
      data: configData,
      basicBatchConfig: this.batchConfig,
      adjudicationBatchConfig: null,
      level1BatchConfig: null,
      result: 'submit'
    };
    this.clickSubmit.emit(submitData);
  }

  clickCancelBtn() {
    this.clickCancel.emit({result: 'cancel'});
  }

  compareReadersByName(r1: User, r2: User): number {
    return (r1.firstName + r1.lastName).localeCompare(r2.firstName + r2.lastName);
  }

  compareModality(m1, m2): boolean {
    return m1.name === m2.name;
  }

}
