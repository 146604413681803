import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReadingLevelOptions} from '../../../core/constants/reading-level-options';

@Component({
  selector: 'app-reading-level-options-dialog',
  templateUrl: './reading-level-options-dialog.component.html',
  styleUrls: ['./reading-level-options-dialog.component.css']
})
export class ReadingLevelOptionsDialogComponent implements OnInit {

  optionSmartRead: FormControl = new FormControl(false);
  optionSquentialLock: FormControl = new FormControl(false);

  options: string[];

  constructor(public dialogRef: MatDialogRef<ReadingLevelOptionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string[]) { }

  ngOnInit(): void {

    if (this.data) {
      this.options = this.data;
    } else {
      this.options = [];
    }
    if (this.options.includes(ReadingLevelOptions.SMART) === true) {
      this.optionSmartRead.setValue( true);
    } else {
      this.optionSmartRead.setValue( false);
    }
    if (this.options.includes(ReadingLevelOptions.SEQUENTIAL) === true) {
      this.optionSquentialLock.setValue(true);
    } else {
      this.optionSquentialLock.setValue(false);
    }
  }


  okClick() {
    const options = [];
    if (this.optionSmartRead.value === true) {
      options.push(ReadingLevelOptions.SMART);
    }
    if (this.optionSquentialLock.value === true) {
      options.push(ReadingLevelOptions.SEQUENTIAL);
    }
    console.log('--- dialog options');
    console.log(options);

    this.dialogRef.close(options);
  }

  closeClick() {
    this.dialogRef.close();
  }
}
