import { Injectable } from '@angular/core';

import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {HttpClient, HttpParams} from '@angular/common/http';
import {PrimaryBodyLocationModel} from '../_models/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-model';
import {BodySubLocationModel} from '../_models/ImagingProject/ImagingProjectBodypartDataset/body-sub-location-model';
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/body-sub-location';

@Injectable({
  providedIn: 'root'
})
export class BodySubLocationService {

  constructor(private http: HttpClient) { }

  public findAll(): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public getAllByPrimaryLocationId(primaryBodyLocationId: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all/${primaryBodyLocationId}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public getAllActiveByPrimaryLocationId(primaryBodyLocationId: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all-active/${primaryBodyLocationId}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public getById(id: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find/${id}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public create(primaryBodyLocationId: number, bodySubLocation: BodySubLocationModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/${primaryBodyLocationId}`;
    return this.http.post<BasicResponse<any>>(requestURL, bodySubLocation);
  }

  public updateStatus(location: BodySubLocationModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/update-status/${location.id}?status=${location.status}`;
    return this.http.put<BasicResponse<any>>(requestURL,  { status: location.status});
  }

  public updateName(location: BodySubLocationModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/update-name/${location.id}?name=${location.name}`;
    return this.http.put<BasicResponse<any>>(requestURL, { name: location.name});
  }
}
