import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-qc-lateralitycorrection-dialog',
  templateUrl: './qc-lateralitycorrection-dialog.component.html',
  styleUrls: ['./qc-lateralitycorrection-dialog.component.css']
})
export class QcLateralitycorrectionDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<QcLateralitycorrectionDialogComponent>) { }

  LateralityQuestion1Control= new FormControl();
  LateralityQuestion2Control= new FormControl();
  LateralityQuestion3Control= new FormControl();

  ngOnInit(): void {
  }

  onSubmit() {
    this.dialogRef.close(true)
  }

  closeLateralityDialog() {
    this.dialogRef.close(false);
  }
}
