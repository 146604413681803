import {BaseFlexibleConfig} from '../base-flexible-config';
import { ModalityModel } from '../modality-model';
import { VisitType } from '../patient-model';
import {SequenceLabelModel} from '../sequence-label-model';

export enum ImageViewerConfigurations {
  DEFAULT = 'default',
  eCRF_MODE_ONLY = 'eCRF mode only'
}

export interface MEPOBasicConfigModel extends BaseFlexibleConfig {
    anatomySelector?: SequenceLabelModel[] | { id: number }[];
    hideVisitHistory: 'hide_visits_chronology' | 'hide_scan_dates';
    imageViewerConfiguration: ImageViewerConfigurations;
    moderationReader: number;
    moderatorReaders: { id: number }[];
    visit?: any[];
}

export class MEPOVisitModel{
    id?: number;
    created?: number;
    name: string;
    type?: VisitType;
    durationTimeUnit?: string;
    durationTimeValue?: number;
    baseline?: boolean;
    repeatAllowed?: boolean;
    modalities?: ModalityModel[];
    isAvailable = true;
  }