import { HttpCacheService } from 'src/app/_services/http-cache/http-cache.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDatepicker} from '@angular/material/datepicker';
import {ImagingProjectService} from '../../../_services/imaging-project.service';
import {first} from 'rxjs/operators';
import {Location} from '@angular/common';
import {DataUploadService} from '../../../_services/data-upload.service';
import {SiteConfigService} from '../../../_services/site-config.service';
import {StudyService} from '../../../_services/study.service';
import {PatientService} from '../../../_services/patient.service';

import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import * as _moment from 'moment';
import {Moment} from 'moment';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ToastOptions, ToastyService} from 'ng2-toasty';

const moment = _moment;


const DATE_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-new-patient',
  templateUrl: './new-patient.component.html',
  styleUrls: ['./new-patient.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS},
  ],
})
export class NewPatientComponent implements OnInit {
  startDate = new Date();

  state = {
    projectId: 0,
    siteId: 0,
    siteCode: '',
    patientCode: '',
    phantom: false,
    date: Date(),
    patientCodePatternRegEx: '',
    patientCodePatternMessage: '',
    phantomPatientCode: false,
    patientCode_reserve: ''
  };

  wrongBirthDate = false;

  visitsConfig = new Array();
  all_ptinets_codes = new Array();

  siteConfig: {};

  showErrorCreatePatient = false;

  @ViewChild('picker') picker;

  patientForm: FormGroup;

  showModalSpinner = false;

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataUploadService: DataUploadService,
    private imagingProjectSerivce: ImagingProjectService,
    private siteConfigService: SiteConfigService,
    private studyService: StudyService,
    private patientService: PatientService,
    private location: Location,
    private toastyService: ToastyService,
    private fb: FormBuilder,
    private store: Store,
    private _cache: HttpCacheService) {
    this.patientForm = this.fb.group({
      siteCode: [{value: this.state.siteCode, disabled: true}, Validators.required],
      patientCode: [this.state.patientCode, [Validators.required, Validators.pattern(this.state.patientCodePatternRegEx)]],
      phantom: [this.state.phantom, Validators.required],
      date: [this.state.date, Validators.required],
    });
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Create new patient'));
    this.route.params.subscribe(params => {
      this.state.projectId = +params['ipid'];
      this.state.siteId = +params['sid'];
    });
    this.showModalSpinner = false;

    const vConfig = localStorage.getItem('tmp.upload.visitsConfig');
    this.visitsConfig = JSON.parse(vConfig);
    this.all_ptinets_codes = JSON.parse(localStorage.getItem('tmp.upload.all_patinets_codes'));
    this.siteConfigService.getSiteConfig(this.state.projectId, this.state.siteId)
      .subscribe(success => {
        this.siteConfig = success['data'];
        this.setSiteCode(this.siteConfig['siteCode']);

        this.studyService.getStudyPatientCodePatternAsRegEx(this.state.projectId).subscribe(patternResp => {
          let pattern = patternResp['data'].replace('<sitecode>', this.state.siteCode);
          this.state.patientCodePatternRegEx = pattern;
          this.state.patientCodePatternMessage = pattern.replace(new RegExp(/\\d/, 'g'), '%').replace(/\[A-Z\]/g, '*');
          this.patientForm.controls['patientCode'].clearValidators();
          this.patientForm.controls['patientCode'].setValidators([Validators.required, Validators.pattern(this.state.patientCodePatternRegEx)]);
        });
    });
  }

  setSiteCode(code) {
    this.state.siteCode = code;
    this.patientForm.setValue({
      siteCode: code,
      patientCode: '',
      phantom: this.state.phantom,
      date: this.state.date
    });
  }

  setPatientCode(code) {
    this.patientForm.setValue({
      siteCode: this.state.siteCode,
      patientCode: code,
      phantom: this.patientForm.value.phantom,
      date: this.patientForm.value.date
    });
  }


  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    let ctrlValue;
    if (this.patientForm.get('date').value) {
      ctrlValue = moment(this.patientForm.get('date').value);
    } else {
      ctrlValue = moment(new Date());
    }
    if (normalizedYear.year() > new Date().getFullYear()) {
      this.wrongBirthDate = true;
      this.patientForm.get('date').setValue(null);
      datepicker.close();
      return;
    }
    ctrlValue.year(normalizedYear.year()).month(ctrlValue.month()).date(1);
    const val = this.patientForm.getRawValue();
    val.date = ctrlValue.toDate();
    this.patientForm.setValue(val);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.patientForm.get('date').value);
    ctrlValue.year(ctrlValue.year()).month(normalizedMonth.month()).date(1);

    if (ctrlValue.year() > new Date().getFullYear() || (ctrlValue.month() > new Date().getMonth() && ctrlValue.year() == new Date().getFullYear())) {
      this.patientForm.get('date').setValue(null);
      this.wrongBirthDate = true;
      datepicker.close();
    } else {
      this.wrongBirthDate = false;
      const val = this.patientForm.getRawValue();
      val.date = ctrlValue.toDate();
      this.patientForm.setValue(val);
      datepicker.close();
    }
  }

  endDateChangeHandler(event) {
    this.patientForm.get('date').setValue(null);
  }

  onPhantomChange() {
    const patientCreateForm = this.patientForm.value;
    if (this.patientForm.value.phantom == true) {
      this.state.phantomPatientCode = true;
      this.patientService.getPhantomPatientCode(this.siteConfig['id']).subscribe(codeResp => {
        if (codeResp['data'] != null) {
          this.state.patientCode_reserve = this.patientForm.value['patientCode'];
          this.setPatientCode(codeResp['data']);
          this.patientForm.controls['patientCode'].disable();
        }
      });
    } else {
      this.state.phantomPatientCode = false;
      this.setPatientCode(this.state.patientCode_reserve);
      this.patientForm.controls['patientCode'].enable();
    }
  }

  clickCancel() {
    this.location.back();
  }

  clickCreate() {
    const patientCreateForm = this.patientForm.value;
    const patient = {
      siteConfig: {
        id: this.siteConfig['id'],
      },
      patientCode: this.patientForm.controls['patientCode'].value,
      patientBirthDate: patientCreateForm.date.getFullYear() + "-" + ('0' + (patientCreateForm.date.getMonth() + 1)).slice(-2) + "-01",
      gender: patientCreateForm.gender,
      phantomData: patientCreateForm.phantom,
    };
    this.showModalSpinner = true;

    this.imagingProjectSerivce.createPatient(this.state.projectId, patient)
      .pipe(first())
      .subscribe(data => {
          this._cache.clearCache();
          this.showErrorCreatePatient = false;
          this.showModalSpinner = false;
          this.toastOptions.title = 'Patient has been created successfully';
          this.toastOptions.msg = 'Patient is now available for uploads';
          this.toastyService.success(this.toastOptions);
          this.router.navigate(['/imagingproject/upload']);
        },
        error => {
          this.showErrorCreatePatient = true;
          this.showModalSpinner = false;
          this.toastOptions.title = 'Patient creation failure';
          this.toastOptions.msg = 'Patient is not created due to some reason. Resolve issues on the form or contact support team';
          this.toastyService.error(this.toastOptions);
        });
  }

  isPatientCodeExist() {
    let data = this.patientForm.getRawValue();
    let code = data.patientCode;
    return this.all_ptinets_codes.includes(code);
  }
}
