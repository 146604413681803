import {Injectable, OnDestroy} from '@angular/core';
import {Observable} from "rxjs";
import {WebSocketService} from "./web-socket.service";
import {FormEvent} from "../_models/Oncology/local-storage-keys.model";

@Injectable()
export class MessagingService implements OnDestroy {
  public changes: Observable<any>;
  private userId: number;
  private readingId: number;

  private webSocketService: WebSocketService;

  constructor() {
    this.webSocketService = new WebSocketService()
  }

  init(userId: number, readingId: number, formPage: FormPage, roomIdPostfix: string = ''): Observable<any> {
    this.userId = userId;
    this.readingId = readingId;
    roomIdPostfix = this.isEmpty(roomIdPostfix) ? '' : `~${roomIdPostfix}`;
    let roomId = this.getRoomId(formPage) + roomIdPostfix;
    // if (!this.isEmpty(this.roomIdPostfix))
    //   roomId = roomId.replace(this.roomIdPostfix, '')
    // console.log(`Using Websocket to handle form events for component ${formPage}. Listening on event (roomId): ${roomId}`)
    return this.webSocketService.listen(roomId, roomId, formPage);
  }

  isEmpty(str: string) {
    return (!str || str.length === 0);
  }

  sendRequest(message: FormEvent, destinationFormPage: FormPage = null) {
    if (message != null && destinationFormPage != null)
      message.destination = this.getRoomId(destinationFormPage);
    this.webSocketService.emit('events:publish', message)
  }

  private getRoomId(formPage: FormPage) {
    return `user-${this.userId}~reading-${this.readingId}~${formPage}`;
  }

  ngOnDestroy(): void {
  }
}

export enum FormPage {
  MRANO = "mrano",
  ONCOLOGY_LESION_DIALOG = "oncology-lesion-dialog",
  DYNAMIC_BRAIN_LESION = "dynamic-brain-lesion",
  LESION_DIALOG = "lesion-dialog"
}
