import {AuthInfoStateModel} from '../states/auth-info.state';

export class SetAuthInfo {
  static readonly type = '[AuthInfo] SetAuthInfo';

  constructor(public payload: AuthInfoStateModel) {
  }
}

export class GetAuthInfo {
  static readonly type = '[AuthInfo] GetAuthInfo';
}

export class ClearAuthInfo {
  static readonly type = '[AuthInfo] ClearAuthInfo';
}
