import {AnnJswObject} from "./ann-jsw-object";
import AnnDesignerOperationStatus = lt.Annotations.Engine.AnnDesignerOperationStatus;
import AnnEditDesignerOperation = lt.Annotations.Engine.AnnEditDesignerOperation;

export class AnnJswEditDesigner extends lt.Annotations.Designers.AnnPolylineEditDesigner {
  // tslint:disable-next-line:max-line-length
  constructor(automationControl: lt.Annotations.Engine.IAnnAutomationControl, container: lt.Annotations.Engine.AnnContainer, annJswObject: AnnJswObject) {
    super(automationControl, container, annJswObject);
  }

  onEdit(e: lt.Annotations.Engine.AnnEditDesignerEventArgs): void {
    if (e.operationStatus === AnnDesignerOperationStatus.start && e.operation === AnnEditDesignerOperation.moveThumb) {
      delete this.targetObject.get_labels()['InitialDistance'];
      (this.targetObject as AnnJswObject).distanceFirstPoint = undefined;
      (this.targetObject as AnnJswObject).distanceSecondPoint = undefined;
    }

    super.onEdit(e);
  }
}

