import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {BasicReading} from '../_models/basic-reading';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingATYRVersion;
const SERVICE = 'msreadingatyr-dot-';
const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class ReadingAtyrService {

  constructor(private http: HttpClient) { }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/readings/available-count/studyId-${studyId}/readerId-${readerId}`);
  }

  getNumberOfAvailableAdjudicationReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/readings/available-count/studyId-${studyId}/readerId-${readerId}?readerType=ADJUDICATION_READER`);
  }

  getNumberOfAvailableConsensusReadings(studyId, readerId): Observable<BasicResponse<number>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/readings/available-count/studyId-${studyId}/readerId-${readerId}?readerType=CONSENSUS_READER`);
  }

  getAvailableReadings(studyId, readerId): Observable<BasicResponse<any>> {
    return this.http.get<BasicResponse<any>>(`${BASE_URL}/readings/available/studyId-${studyId}/readerId-${readerId}`);
  }

  startReading(readingId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/readings/${readingId}/start`;
    return this.http.put<BasicResponse<any>>(url, null);
  }

  updateReadingOld(studyId, readingId, data) {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/readings/${readingId}/log-time`, data);
  }

  completeReading(studyId, data): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/readings/${studyId}/complete`, data);
  }
}
