import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EndpointService } from '../../../../_services/endpoint.service';
import { ReadingIncidentalFindingsService } from '../../../../_services/reading-incidental-findings.service';
import { ReadingKandlService } from '../../../../_services/reading-kandl.service';
import { ReadingKandlAutoServiceService } from '../../../../_services/reading-kandl-auto-service.service';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';


@Component({
  selector: 'app-download-report-page',
  templateUrl: './download-report-page.component.html',
  styleUrls: ['./download-report-page.component.css']
})
export class DownloadReportPageComponent implements OnInit {

  studyId: number;
  endpointName: string;
  readingId: number;

  constructor(private route: ActivatedRoute,
              private endpointService: EndpointService,
              private readingIncidentialFindingsService: ReadingIncidentalFindingsService,
              private readingKandlService: ReadingKandlService,
              private readingKandlAutoServiceService: ReadingKandlAutoServiceService,
              private store: Store) {
  }

  ngOnInit() {
    this.studyId = parseInt(this.route.snapshot.params.studyId, 10);
    this.endpointName = this.route.snapshot.params.endpointName;
    this.store.dispatch(new SetPageHeaderTitle(`Download ${this.endpointName} report.`));
    this.readingId = parseInt(this.route.snapshot.params.readingId, 10);

    this.getReportInformation();
  }

  getReportInformation(){
    // support andpointname as db value and bucket storage path
    if('Incidental Findings'.toLowerCase() == this.endpointName.toLowerCase()  //db name
      ||
      'incidentalfindings'.toLowerCase() == this.endpointName.toLowerCase()){ // bucket storage name
      this.readingIncidentialFindingsService.getReading(this.studyId, this.readingId).subscribe(readingResp => {
        let readingIfData = readingResp['data'];
        if(readingIfData['reportFileUrl'] != null){
          window.open(readingIfData['reportFileUrl'], "_blank");
        }
      });
    }
    // support andpointname as db value and bucket storage path
    else if('K&L'.toLowerCase() == this.endpointName.toLowerCase() //db name
            ||
            'k&l'.toLowerCase() == this.endpointName.toLowerCase()
            ||
            'kl'.toLowerCase() == this.endpointName.toLowerCase()){ // bucket storage name
      this.readingKandlService.getReading(this.studyId, this.readingId).subscribe(readingResp => {
        let readingKLData = readingResp['data'];
        if(readingKLData['reportFileUrl'] != null){
          window.open(readingKLData['reportFileUrl'], "_blank");
        }
      });
    }
    // support andpointname as db value and bucket storage path
    else if('KOALA K&L'.toLowerCase() == this.endpointName.toLowerCase() //db name
            ||
            'koalak&l'.toLowerCase() == this.endpointName.toLowerCase()
            ||
            'koalakl'.toLowerCase() == this.endpointName.toLowerCase()
            ||
            'KOALA KL'.toLowerCase() == this.endpointName.toLowerCase()){ // bucket storage name
      this.readingKandlAutoServiceService.getReading(this.studyId, this.readingId).subscribe(readingResp => {
        let readingKLAutoData = readingResp['data'];
        if(readingKLAutoData['reports'] != null){
          readingKLAutoData.reports.map(report => {
            if(report['pdfUrl'] != null) {
              window.open(report['pdfUrl'], "_blank");
            }
          });
        };
      });
    }
  }


}
