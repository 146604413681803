import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

import { EndpointService } from '../../../_services';
import { FormControl, Validators } from '@angular/forms';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';

@Component({
  selector: 'app-imaging-project-endpoint',
  templateUrl: './imaging-project-endpoint.component.html',
  styleUrls: ['./imaging-project-endpoint.component.css']
})
export class ImagingProjectEndpointComponent implements OnInit {
  requiredFormControl = new FormControl('', [
    Validators.required
  ]);
  toastOptions: ToastOptions = {
    title: "",
    showClose: true,
    timeout: 10000,
    theme: "material",
  };
  displayedColumns: string[] = ['row-num', 'name', 'operations'];
  dataSource: MatTableDataSource<any>;
  endpoints: any[] = [];
  endpoint: any = {};
  showTable: boolean = true;
  showEndpoint: boolean = false;
  endpointName: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private endpointService: EndpointService,
    private toastyService: ToastyService, private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Endpoints'));
    this.endpointService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.endpoints = response.data;
          this.dataSource = new MatTableDataSource(this.endpoints);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  showEndpoints(element) {
    this.endpoint = {};
    this.showTable = false;
    this.showEndpoint = true;
    this.requiredFormControl.clearValidators();
    if (element) {
      this.endpointService.getById(element.id).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.endpoint = response.data;
            this.store.dispatch(new SetPageHeaderTitle('Edit Endpoint'));
          }
        }
      );
    } else {
      this.store.dispatch(new SetPageHeaderTitle('Create Endpoint'));
    }
  }

  deleteEndpoint(element) {
    this.endpointService.delete(element.id).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.endpointService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                this.endpoints = response.data;
                this.dataSource.data = response.data;
              } else {
                this.toastOptions.title = "ERROR " + response.responseCode;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
          this.toastOptions.title = "";
          this.toastOptions.msg = "Endpoint successfully deleted.";
          this.toastyService.success(this.toastOptions);
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  hideEndpoints() {
    this.showTable = true;
    this.showEndpoint = false;
  }

  saveEndpoints() {
    if (this.endpoint.id) {
      this.endpointService.update(this.endpoint).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.endpointService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.endpoints = response.data;
                  this.dataSource.data = response.data;
                  this.showTable = true;
                  this.showEndpoint = false;
                  this.toastOptions.title = "";
                  this.toastOptions.msg = "Endpoint successfully updated";
                  this.toastyService.success(this.toastOptions);
                } else {
                  this.toastOptions.title = "ERROR " + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = "ERROR " + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.endpointService.create(this.endpoint).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.endpointService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.endpoints = response.data;
                  this.dataSource.data = response.data;
                  this.showTable = true;
                  this.showEndpoint = false;
                  this.toastOptions.title = "";
                  this.toastOptions.msg = "Endpoint successfully created";
                  this.toastyService.success(this.toastOptions);
                } else {
                  this.toastOptions.title = "ERROR " + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = "ERROR " + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

}
