import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MoaksVisitModel} from '../../../../../_models/MOAKS/moaks-visit-model';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {SharedService} from '../../moaks-basic-form/shared.service';
import {isNotNullOrUndefined} from "codelyzer/util/isNotNullOrUndefined";
import {MoaksMocartScoringModel} from "../../../../../_models/MOAKS/moaks-mocart-scoring-model";


@Component({
  selector: 'app-moaks-mocart-scoring-form',
  templateUrl: './moaks-mocart-scoring-form.component.html',
  styleUrls: ['./moaks-mocart-scoring-form.component.css']
})

export class MoaksMocartScoringFormComponent implements OnInit, OnDestroy {

  form: FormGroup;
  mocartFormControl: FormGroup;
  // tableDataSource: MatTableDataSource<MoaksMocartScoringModel>;

  @Input()
  get visit(): MoaksVisitModel {
    return this._visit;
  }

  set visit(visit: MoaksVisitModel) {
    this._visit = visit;
    if (this.form) { // ngOnInit() will init first time
      this.init();
    }
  }

  private _visit: MoaksVisitModel;

  @Input() uid: string;

  @Input() clinicalData: string;
  @Output('onValidForm') onValidForm: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private fb: FormBuilder,
    private sharedService: SharedService
  ) {
     this.mocartFormControl = new FormGroup({
      // Define form controls and their initial values
       id: new FormControl('', []),
       code: new FormControl('', []),
       region: new FormControl('', []),
       loss: new FormControl('', []),
       lesionLocation: new FormControl('', [Validators.required]),
       volumeOfCartilageDefectFiling: new FormControl('', [Validators.required]),
       volumeOfCartilageDefectFilingScore: new FormControl(0, [Validators.required]),
       integrationAdjacentCartilage: new FormControl('', [Validators.required]),
       integrationAdjacentCartilageScore: new FormControl(0, [Validators.required]),
       surfaceRepairTissue: new FormControl('', [Validators.required]),
       surfaceRepairTissueScore: new FormControl(0, [Validators.required]),
       structureRepairTissue: new FormControl('', [Validators.required]),
       structureRepairTissueScore: new FormControl(0, [Validators.required]),
       signalIntensityRepairTissue: new FormControl('', [Validators.required]),
       signalIntensityRepairTissueScore: new FormControl(0, [Validators.required]),
       bonyDefect: new FormControl('', [Validators.required]),
       bonyDefectScore: new FormControl(0, [Validators.required]),
       subChondralChanges: new FormControl('', [Validators.required]),
       subChondralChangesScore: new FormControl(0, [Validators.required]),
       comment: new FormControl('', []),
       totalMocartScore: new FormControl(0, [Validators.required]),
    });
    this.form = this.fb.group({
      scorings: this.mocartFormControl
    });
  }

  ngOnDestroy(): void {
    this.setSubmitData();
  }

  ngOnInit(): void {

    if (this._visit.mocartScorings) {
      this.mocartFormControl.patchValue({
        id: this._visit.mocartScorings.id,
        code: this._visit.mocartScorings.code,
        region: this._visit.mocartScorings.region,
        loss: this._visit.mocartScorings.loss,
        lesionLocation: this._visit.mocartScorings.lesionLocation,
        volumeOfCartilageDefectFiling: this._visit.mocartScorings.volumeOfCartilageDefectFiling,
        volumeOfCartilageDefectFilingScore: this._visit.mocartScorings.volumeOfCartilageDefectFilingScore,
        integrationAdjacentCartilage: this._visit.mocartScorings.integrationAdjacentCartilage,
        integrationAdjacentCartilageScore: this._visit.mocartScorings.integrationAdjacentCartilageScore,
        surfaceRepairTissue: this._visit.mocartScorings.surfaceRepairTissue,
        surfaceRepairTissueScore: this._visit.mocartScorings.surfaceRepairTissueScore,
        structureRepairTissue: this._visit.mocartScorings.structureRepairTissue,
        structureRepairTissueScore: this._visit.mocartScorings.structureRepairTissueScore,
        signalIntensityRepairTissue: this._visit.mocartScorings.signalIntensityRepairTissue,
        signalIntensityRepairTissueScore: this._visit.mocartScorings.signalIntensityRepairTissueScore,
        bonyDefect: this._visit.mocartScorings.bonyDefect,
        bonyDefectScore: this._visit.mocartScorings.bonyDefectScore,
        subChondralChanges: this._visit.mocartScorings.subChondralChanges,
        subChondralChangesScore: this._visit.mocartScorings.subChondralChangesScore,
        comment: this._visit.mocartScorings.comment,
        totalMocartScore: this._visit.mocartScorings.totalMocartScore,
      });
    }
  }

  init(): void {
    this.form.valueChanges.subscribe(() => {
      this.onValidForm.emit(this.form.valid);
    })
  }

  calculateScore (name: string) {
    if (this.checkIfEmpty(name)) {
      return;
    }
    switch (name) {
      case 'volumeOfCartilageDefectFiling': this.calculateVolumeOfCartilageDefectFilingScore(name);
        break;
      case 'integrationAdjacentCartilage': this.calculateIntegrationAdjacentCartilageScore(name);
        break;
      case 'surfaceRepairTissue': this.calculateSurfaceRepairTissueScore(name);
        break;
      case 'structureRepairTissue': this.calculateStructureRepairTissueScore(name);
        break;
      case 'signalIntensityRepairTissue': this.calculateSignalIntensityRepairTissueScore(name);
        break;
      case 'bonyDefect': this.calculateBonyDefectScore(name);
        break;
      case 'subChondralChanges': this.calculateSubChondralChangesScore(name);
        break;
    }
  }

  calculateVolumeOfCartilageDefectFilingScore(name: string) {
    let score = 0;
    switch (this.form.get(`scorings.${name}`).value) {
      case 'minorHypertrophy':
        score = 20;
        break;
      case 'majorHypertrophy':
        score = 15;
        break;
      case 'under75Filling':
        score = 10;
        break;
      case 'under50Filling':
        score = 5;
        break;
      case 'under25Filling':
        score = 0;
        break;
      default:
        score = 0;
    }
    this.setScore(name, score);
  }

  calculateIntegrationAdjacentCartilageScore(name: string) {
    let score = 0;
    switch (this.form.get(`scorings.${name}`).value) {
      case 'completeIntegration':
        score = 15;
        break;
      case 'under2mm':
        score = 10;
        break;
      case 'over2mmUnder0Percent':
        score = 5;
        break;
      case 'under50Percent':
        score = 0;
        break;
      default:
        score = 0;
    }
    this.setScore(name, score);
  }

  calculateSurfaceRepairTissueScore(name: string) {
    let score = 0;
    switch (this.form.get(`scorings.${name}`).value) {
      case 'surfaceIntact':
        score = 10;
        break;
      case 'surfaceIrregularUnder50Percent':
        score = 5;
        break;
      case 'surfaceIrregularUnderEqualTo50Percent':
        score = 0;
        break;
      default:
        score = 0;
    }
    this.setScore(name, score);
  }

  calculateStructureRepairTissueScore(name: string) {
    let score = 0;
    switch (this.form.get(`scorings.${name}`).value) {
      case 'homogeneous':
        score = 10;
        break;
      case 'inhomogeneous':
        score = 0;
        break;
      default:
        score = 0;
    }
    this.setScore(name, score);
  }

  calculateSignalIntensityRepairTissueScore(name: string) {
    let score = 0;
    switch (this.form.get(`scorings.${name}`).value) {
      case 'normal':
        score = 15;
        break;
      case 'minorAbnormal':
        score = 10;
        break;
      case 'severelyAbnormal':
        score = 0;
        break;
      default:
        score = 0;
    }
    this.setScore(name, score);
  }

  calculateBonyDefectScore(name: string) {
    let score = 0;
    switch (this.form.get(`scorings.${name}`).value) {
      case 'noBonyDefect':
        score = 10;
        break;
      case 'bonyDefectUnder50Percent':
        score = 5;
        break;
      case 'bonyDefectOver50Percent':
        score = 0;
        break;
      default:
        score = 0;
    }
    this.setScore(name, score);
  }

  calculateSubChondralChangesScore(name: string) {
    let score = 0;
    switch (this.form.get(`scorings.${name}`).value) {
      case 'noMajor':
        score = 20;
        break;
      case 'minorEdema':
        score = 15;
        break;
      case 'severeEdema':
        score = 10;
        break;
      case 'subchondralCyst':
        score = 0;
        break;
      default:
        score = 0;
    }
    this.setScore(name, score);
  }

  setScore(name: string, score: number) {
    const scoreName = name + 'Score';
    this.form.get(`scorings.${scoreName}`).setValue(score);
  }

  getScore(name: string) {
    if (!name || !this.form.get(`scorings.${name}`)) {
      // console.warn('The form control name is empty.');
      return;
    }
    return this.form.get(`scorings.${name}`).value;
  }

  reset(): void {
    ((this.form.get('scorings') as FormArray).controls as FormGroup[]).forEach(control => {
        control.reset();
    });
  }

  calculateTotalMocartScore () {
    const volumeOfCartilageDefectFilingScore = !this.checkIfEmpty('volumeOfCartilageDefectFilingScore') ? this.form.get(`scorings.volumeOfCartilageDefectFilingScore`).value : 0;
    const integrationAdjacentCartilageScore = !this.checkIfEmpty('integrationAdjacentCartilageScore') ? this.form.get(`scorings.integrationAdjacentCartilageScore`).value : 0;
    const surfaceRepairTissueScore = !this.checkIfEmpty('surfaceRepairTissueScore') ? this.form.get(`scorings.surfaceRepairTissueScore`).value : 0;
    const structureRepairTissueScore = !this.checkIfEmpty('structureRepairTissueScore') ? this.form.get(`scorings.structureRepairTissueScore`).value : 0;
    const signalIntensityRepairTissueScore = !this.checkIfEmpty('signalIntensityRepairTissueScore') ? this.form.get(`scorings.signalIntensityRepairTissueScore`).value : 0;
    const bonyDefectScore = !this.checkIfEmpty('bonyDefectScore') ? this.form.get(`scorings.bonyDefectScore`).value : 0;
    const subChondralChangesScore = !this.checkIfEmpty('subChondralChangesScore') ? this.form.get(`scorings.subChondralChangesScore`).value : 0;

    const totalMocartScore = volumeOfCartilageDefectFilingScore + integrationAdjacentCartilageScore + surfaceRepairTissueScore
      + structureRepairTissueScore + signalIntensityRepairTissueScore + bonyDefectScore + subChondralChangesScore;

    this.form.get(`scorings.${`totalMocartScore`}`).setValue(totalMocartScore);
    return totalMocartScore;
  }

  checkIfEmpty(name: string) {
      return !name || !this.form.get(`scorings.${name}`);
  }

  setSubmitData() {

    if (!this.visit.mocartScorings) {
      // Initialize scorings if it's undefined
      this.visit.mocartScorings = {};
    }
    const scorings = this.visit.mocartScorings;
    scorings.lesionLocation = this.form.get('scorings.lesionLocation').value;
    scorings.volumeOfCartilageDefectFiling = this.form.get('scorings.volumeOfCartilageDefectFiling').value;
    scorings.volumeOfCartilageDefectFilingScore = this.form.get('scorings.volumeOfCartilageDefectFilingScore').value;
    scorings.integrationAdjacentCartilage = this.form.get('scorings.integrationAdjacentCartilage').value;
    scorings.integrationAdjacentCartilageScore = this.form.get('scorings.integrationAdjacentCartilageScore').value;
    scorings.surfaceRepairTissue = this.form.get('scorings.surfaceRepairTissue').value;
    scorings.surfaceRepairTissueScore = this.form.get('scorings.surfaceRepairTissueScore').value;
    scorings.structureRepairTissue = this.form.get('scorings.structureRepairTissue').value;
    scorings.structureRepairTissueScore = this.form.get('scorings.structureRepairTissueScore').value;
    scorings.signalIntensityRepairTissue = this.form.get('scorings.signalIntensityRepairTissue').value;
    scorings.signalIntensityRepairTissueScore = this.form.get('scorings.signalIntensityRepairTissueScore').value;
    scorings.bonyDefect = this.form.get('scorings.bonyDefect').value;
    scorings.bonyDefectScore = this.form.get('scorings.bonyDefectScore').value;
    scorings.subChondralChanges = this.form.get('scorings.subChondralChanges').value;
    scorings.subChondralChangesScore = this.form.get('scorings.subChondralChangesScore').value;
    scorings.comment = this.form.get('scorings.comment').value;
    scorings.totalMocartScore = this.form.get('scorings.totalMocartScore').value;
  }
}
