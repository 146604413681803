export const modifyTabHeader = (title: string): void => {
  try {
    const tabHeader = document.querySelector('.mat-tab-header');
    const textNode = document.createTextNode(title);
    const text = document.createElement('span');
    text.style.fontSize = '16px';
    text.style.fontWeight = 'bold';
    text.style.textAlign = 'center';
    text.appendChild(textNode);
    tabHeader.insertBefore(text, tabHeader.firstChild);

    const wrapper = document.createElement('div');
    wrapper.style.display = 'flex';
    const pagBefore = document.querySelector('.mat-tab-header-pagination-before');
    const pagAfter = document.querySelector('.mat-tab-header-pagination-after');
    const labelContainer = document.querySelector('.mat-tab-label-container');
    wrapper.appendChild(pagBefore);
    wrapper.appendChild(labelContainer);
    wrapper.appendChild(pagAfter);
    tabHeader.appendChild(wrapper);
  } catch (e) {
    console.log(e);
  }
};

export const updateTabHeaderWidth = (): void => {
  try {
    const tabHeader = document.querySelector('.mat-tab-header');
    if (tabHeader.getBoundingClientRect().top - 80 < 5) {
      tabHeader.classList.add('tab-header-full-width');
      window.dispatchEvent(new Event('resize'));
    } else if (tabHeader.classList.contains('tab-header-full-width')) {
      tabHeader.classList.remove('tab-header-full-width');
      window.dispatchEvent(new Event('resize'));
    }
  } catch (e) {
    console.log(e);
  }
};
