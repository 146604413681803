import {Component, OnInit} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../../reading-form-scoring.component';
import {Observable, of} from 'rxjs';
import {BasicResponse} from '../../../../core/interfaces/basic-response';
import {KLReadingActionRequest, ReadingConfigFlexibleService, ReadingKandlService} from '../../../../_services';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';
import {Store} from '@ngxs/store';
import {ActivatedRoute} from '@angular/router';
import {EndpointReadingVersion} from '../../../../core/types/endpoint-reading-version';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {KLReadingFormControls, KLReadingFormGroup} from '../kl-form-model';
import {BasicReading} from '../../../../_models/basic-reading';
import {ReadingVersion} from '../../../../core/constants/reading-version';
import { ResponseCode } from 'src/app/core/constants/response-code';
import {MatDialog} from "@angular/material/dialog";
import {ConfirmSigningDialogComponent} from "../../../ReportSign/confirm-signing-dialog/confirm-signing-dialog.component";
import {map, switchMap} from "rxjs/operators";
import {ToastService} from "../../../../_services/internal/toast.service";

@Component({
  selector: 'app-kl-form',
  templateUrl: './kl-form.component.html',
  styleUrls: ['./kl-form.component.css']
})
export class KlFormComponent extends ReadingFormScoringComponent implements OnInit, ReadingFormScoring {

  readonly readingVersions = ReadingVersion;

  endpointReadingVersion: EndpointReadingVersion;
  form: KLReadingFormGroup;
  enableOAPredominantly: boolean = null;
  enableOAPredominantlyLateral: boolean = null;
  enableKneeMalalaignment: boolean = null;

  currentReading: BasicReading & { adjudicationBlind: boolean, readings: { rightScore: number, leftScore: number, comment: string }[] };

  constructor(private store: Store,
              private route: ActivatedRoute,
              private fb: FormBuilder,
              private readingService: ReadingKandlService,
              private readingConfigService: ReadingConfigFlexibleService,
              private toastService: ToastService,
              private dialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.stepSaveEnabled = false;
    this.endpointReadingVersion = this.route.snapshot.params.type as EndpointReadingVersion;
    this.store.dispatch(new SetPageHeaderTitle(`KL ${this.endpointReadingVersion.toString()} Scoring Form`));
    this.openSeriesCountRestriction = 1;
    this.initForm();
    this.subscribeCurrentReading();
  }

  subscribeCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      this.enableOAPredominantly = null;
      this.enableOAPredominantlyLateral = null;
      this.enableKneeMalalaignment = null;
      this.currentReading = currentReading as BasicReading &
        { adjudicationBlind: boolean, readings: { rightScore: number, leftScore: number, rightKneePredominantlyMedial: boolean, leftKneePredominantlyMedial: boolean, rightKneePredominantlyLateral: boolean, leftKneePredominantlyLateral: boolean, comment: string }[] };
      this.readingConfigService.getById(this.currentReading.configId).subscribe(res => {
        if (res.responseCode === ResponseCode.OK) {
          this.enableOAPredominantly = res.data.config['enableOAPredominantlyMedial'];
          this.enableOAPredominantlyLateral = res.data.config['enableOAPredominantlyLateral'];
          this.enableKneeMalalaignment = res.data.config['kneeMalalaignment'];
          this.form.controls.rightKneePredominantlyMedial.validator = this.enableOAPredominantly === true ? Validators.required : null;
          this.form.controls.leftKneePredominantlyMedial.validator = this.enableOAPredominantly === true ? Validators.required : null;
          this.form.controls.rightKneePredominantlyLateral.validator = this.enableOAPredominantlyLateral ? Validators.required : null;
          this.form.controls.leftKneePredominantlyLateral.validator = this.enableOAPredominantlyLateral ? Validators.required : null;
          this.form.controls.rightKneeMalalginment.validator = this.enableKneeMalalaignment ? Validators.required : null;
          this.form.controls.leftKneeMalalignment.validator = this.enableKneeMalalaignment ? Validators.required : null;
          this.form.controls.rightKneePredominantlyMedial.updateValueAndValidity();
          this.form.controls.leftKneePredominantlyMedial.updateValueAndValidity();
          this.form.controls.rightKneePredominantlyLateral.updateValueAndValidity();
          this.form.controls.leftKneePredominantlyLateral.updateValueAndValidity();
          this.form.controls.rightKneeMalalginment.updateValueAndValidity();
          this.form.controls.leftKneeMalalignment.updateValueAndValidity();
        }
      });
    });
  }

  initForm(): void {
    const fg = this.fb.group({
      rightScore: new FormControl(null, [Validators.required]),
      leftScore: new FormControl(null, [Validators.required]),
      rightKneePredominantlyMedial:  new FormControl(null, [Validators.required]),
      leftKneePredominantlyMedial: new FormControl(null, [Validators.required]),
      rightKneePredominantlyLateral: new FormControl(null, [Validators.required]),
      leftKneePredominantlyLateral: new FormControl(null, [Validators.required]),
      rightKneeMalalginment: new FormControl(null, [Validators.required]),
      leftKneeMalalignment: new FormControl(null, [Validators.required]),
      rightKneeMalalignmentComment: new FormControl('', null),
      leftKneeMalalignmentComment: new FormControl('', null),
      comment: new FormControl(null, [Validators.maxLength(200)])
    } as KLReadingFormControls) as KLReadingFormGroup;
    this.initFormChangeValueSubscription(fg);
    this.form = fg;
  }

  initFormChangeValueSubscription(fg: KLReadingFormGroup): void {
    fg.valueChanges.subscribe(() => {
      this.switchSubmitBtnDisabledSubject.next(fg.invalid);
    });
  }

  onChangeRightKneeLateralQuestion(event) {
    this.form.controls.rightKneeMalalignmentComment.validator = event.value.toLowerCase() === 'yes' ? Validators.required : null;
    this.form.controls.rightKneeMalalignmentComment.updateValueAndValidity();
    this.form.controls.rightKneeMalalignmentComment.setValue('');

  }

  onChangeLeftKneeLateralQuestion(event) {
    this.form.controls.leftKneeMalalignmentComment.validator = event.value.toLowerCase() === 'yes' ? Validators.required : null;
    this.form.controls.leftKneeMalalignmentComment.updateValueAndValidity();
    this.form.controls.leftKneeMalalignmentComment.setValue('');
  }

  getEndpointName(): string {
    return 'K&L';
  }

  clearForm(): void {
    this.form.reset();
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    if (this.route.snapshot.params.type as EndpointReadingVersion === 'basic') {
      return this.readingService.getAvailableBasicReadings(studyId, readerId);
    } else {
      return this.readingService.getAvailableAdjudicationReadings(studyId, readerId);
    }
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    const submitData: KLReadingActionRequest = {
      rightScore: this.form.controls.rightScore.value,
      leftScore: this.form.controls.leftScore.value,
      rightKneePredominantlyMedial: this.form.controls.rightKneePredominantlyMedial.value,
      leftKneePredominantlyMedial: this.form.controls.leftKneePredominantlyMedial.value,
      rightKneePredominantlyLateral: this.form.controls.rightKneePredominantlyLateral.value,
      leftKneePredominantlyLateral: this.form.controls.leftKneePredominantlyLateral.value,
      rightKneeMalalginment: this.form.controls.rightKneeMalalginment.value,
      leftKneeMalalignment: this.form.controls.leftKneeMalalignment.value,
      rightKneeMalalginmentComment: this.form.controls.rightKneeMalalignmentComment.value == null ? '' : this.form.controls.rightKneeMalalignmentComment.value.toString(),
      leftKneeMalalignmentComment: this.form.controls.leftKneeMalalignmentComment.value == null ? '' : this.form.controls.leftKneeMalalignmentComment.value.toString(),
      comment: this.form.controls.comment.value,
      spentSeconds: data.spentSeconds
    };

    const dialogRef = this.dialog.open(ConfirmSigningDialogComponent, {
      width: '500px',
      data: {
        title: 'Image Analysis Group requires you to authenticate your signature on this document.',
        studyId: this.currentReading.studyId,
        patientId: this.currentReading.patientId,
        visitConfigId: this.currentReading.flexibleConfigId
      }
    });

    return dialogRef.afterClosed()
      .pipe(
        switchMap((dialogResponse) => {
          if (!dialogResponse?.jwt) {
            if (!dialogResponse?.canceled) {
              this.toastService.error('ERROR', 'Sign Report Failed.<br/>Please enter valid credentials');
            }

            return of({
              responseCode: ResponseCode.OK,
              responseMessage: 'canceled',
              data: {
                canceled: true
              }
            });
          }

          return this.readingService.submitReading(studyId, this.currentReading.id, submitData, dialogResponse.jwt);
        })
      );
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.updateReadingData(studyId, readingId, null, null, null, null, null, data.spentSeconds);
  }

  updateReadingData(studyId: number, readingId: number, rightScore: number,
                    leftScore: number, rightKneePredominantlyMedial: boolean, leftKneePredominantlyMedial: boolean,
                    comment: string, spentSeconds: number): Observable<BasicResponse<any>> {
    const updateData: KLReadingActionRequest = {
      rightScore,
      leftScore,
      rightKneePredominantlyMedial,
      leftKneePredominantlyMedial,
      comment,
      spentSeconds
    };
    return this.readingService.updateReadingData(studyId, readingId, updateData);
  }

}
