import {Injectable} from '@angular/core';
import {HttpClient, HttpEvent} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {DownloadPayload} from '../core/payload/download-payload';
import {DownloadModel} from '../_models/Download/download.model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSCommandsVersion;
const SERVICE = 'mscommands-dot-';

const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL;

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient) {
  }

  public sendDownloadRequest(payload: DownloadPayload): Observable<BasicResponse<DownloadModel>> {
    const url = `${BASE_URL}/download-series-command`;
    return this.http.post<BasicResponse<DownloadModel>>(url, payload);
  }

  public getDownloadRequest(downloadId: number): Observable<BasicResponse<DownloadModel>> {
    const url = `${BASE_URL}/download-series-command/${downloadId}`;
    return this.http.get<BasicResponse<DownloadModel>>(url);
  }

  public updateDownloadStatus(downloadId: number, status: string): Observable<BasicResponse<DownloadModel>> {
    const url = `${BASE_URL}/download-series-command/update-status/${downloadId}?status=${status}`;
    return this.http.put<BasicResponse<DownloadModel>>(url, {});
  }

  public downloadFile(url: string): Observable<HttpEvent<Blob>> {
    return this.http.get(url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    });
  }

}
