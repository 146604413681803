import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BasicResponse } from 'src/app/core/interfaces/basic-response';
import { ReadingFormScoringComponent, ReadingFormScoring } from '../../reading-form-scoring.component';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from 'src/app/core/data-management/actions/projects.action';
import { ReadingLevel } from 'src/app/core/constants/reading-level';
import { ReadingGenantService } from 'src/app/_services/reading-genant.service';
import { VisitStatus } from 'src/app/_models/ImagingProject/IF/incidental-findings-config-model';
import { MessageDialogComponent } from 'src/app/components/controls/message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';

interface GenantModel {
  name: string;
  adjudication: string;
  reader01: string;
  reader02: string;
}

@Component({
  selector: 'app-genant-adjudication-reading-form',
  templateUrl: './genant-adjudication-reading-form.component.html',
  styleUrls: ['./genant-adjudication-reading-form.component.css']
})
export class GenantAdjudicationReadingFormComponent extends ReadingFormScoringComponent implements OnInit, ReadingFormScoring {
  currentReading;
  readingForm: FormGroup = this.fb.group({});
  visitsArrayForm: FormArray = this.fb.array([]);
  genantForm: FormGroup = this.fb.group({});
  selectedVisit = 0;
  previousTimePoint;

  GENANT_Table_DATA: GenantModel[] = [
    { name: "T4", adjudication: null, reader01: null, reader02: null },
    { name: "T5", adjudication: null, reader01: null, reader02: null },
    { name: "T6", adjudication: null, reader01: null, reader02: null },
    { name: "T7", adjudication: null, reader01: null, reader02: null },
    { name: "T8", adjudication: null, reader01: null, reader02: null },
    { name: "T9", adjudication: null, reader01: null, reader02: null },
    { name: "T10", adjudication: null, reader01: null, reader02: null },
    { name: "T11", adjudication: null, reader01: null, reader02: null },
    { name: "T12", adjudication: null, reader01: null, reader02: null },
    { name: "L1", adjudication: null, reader01: null, reader02: null },
    { name: "L2", adjudication: null, reader01: null, reader02: null },
    { name: "L3", adjudication: null, reader01: null, reader02: null },
    { name: "L4", adjudication: null, reader01: null, reader02: null }
  ]

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private readingGenantService: ReadingGenantService,
    private dialog: MatDialog
  ) {
    super()
  }

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Genant'));
    this.initOnCurrentReading();
    this.readingSeriesInitiated.subscribe(resp => {
      if (resp)
        this.chooseActiveVisitByStatus();
    })
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      if (currentReading.readingLevel === ReadingLevel.LONGITUDINAL) {
        this.switchSubmitBtnDisabledSubject.next(false);
      }
      this.readingForm = new FormGroup({});
      this.visitsArrayForm = new FormArray([]);
      this.currentReading = currentReading;
      for (let i = 0; i < 13; i++) {
        switch (this.GENANT_Table_DATA[i].name) {
          case "T4":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t4
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t4
            break;
          case "T5":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t5
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t5
            break;
          case "T6":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t6
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t6
            break;
          case "T7":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t7
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t7
            break;
          case "T8":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t8
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t8
            break;
          case "T9":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t9
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t9
            break;
          case "T10":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t10
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t10
            break;
          case "T11":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t11
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t11
            break;
          case "T12":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.t12
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.t12
            break;
          case "L1":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.l1
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.l1
            break;
          case "L2":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.l2
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.l2
            break;
          case "L3":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.l3
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.l3
            break;
          case "L4":
            this.GENANT_Table_DATA[i].reader01 = this.currentReading.levelOneVisits[0].score.l4
            this.GENANT_Table_DATA[i].reader02 = this.currentReading.levelOneVisits[1].score.l4
            break;
        }
      }
      this.getPreviousTimePoint();
      this.initReadingForm();
    });
  }

  initReadingForm(): void {
    this.currentReading.visits = this.currentReading.visits.sort((a, b) => a.visitOrder - b.visitOrder);
    const level1Score = this.currentReading.levelOneVisits[0].score;
    this.currentReading.visits.forEach((v, visitIndex) => {
      //create visit form
      const visitFormControl = new FormGroup({
        id: new FormControl(v.id),
        visitConfigId: new FormControl(v.visitConfigId),
        status: new FormControl(v.status),
        visitOrder: new FormControl(v.visitOrder),
        disagreement: new FormControl(v.disagreement),
        score: new FormGroup({
          id: new FormControl(v.score.id),
          l1: new FormControl(v.score.l1 === null ? level1Score.l1 : v.score.l1),
          l2: new FormControl(v.score.l2 === null ? level1Score.l2 : v.score.l2),
          l3: new FormControl(v.score.l3 === null ? level1Score.l3 : v.score.l3),
          l4: new FormControl(v.score.l4 === null ? level1Score.l4 : v.score.l4),
          t4: new FormControl(v.score.t4 === null ? level1Score.t4 : v.score.t4),
          t5: new FormControl(v.score.t5 === null ? level1Score.t5 : v.score.t5),
          t6: new FormControl(v.score.t6 === null ? level1Score.t6 : v.score.t6),
          t7: new FormControl(v.score.t7 === null ? level1Score.t7 : v.score.t7),
          t8: new FormControl(v.score.t8 === null ? level1Score.t8 : v.score.t8),
          t9: new FormControl(v.score.t9 === null ? level1Score.t9 : v.score.t9),
          t10: new FormControl(v.score.t10 === null ? level1Score.t10 : v.score.t10),
          t11: new FormControl(v.score.t11 === null ? level1Score.t11 : v.score.t11),
          t12: new FormControl(v.score.t12 === null ? level1Score.t12 : v.score.t12),
          comment: new FormControl(v.score.comment)
        }),
      });

      (<FormArray>this.visitsArrayForm).push(visitFormControl);
      visitFormControl.valueChanges.subscribe(() => {
        this.checkValidity(visitFormControl);
      });

    });
    this.readingForm.addControl('visits', this.visitsArrayForm);
    this.checkValidity(this.readingForm);
  }

  // current visit score must be not less then prior visit, otherwice comment required
  isTrustedScore(current: string, previous: string) {
    const score = (t) => (t === 'GRADE_0' ? 0 : t === 'GRADE_1' ? 1 : t === 'GRADE_2' ? 2 : t === 'GRADE_3' ? 3 : -1);
    return score(current) >= score(previous);
  }

  isScoreValid(scoreName) {
    scoreName = scoreName.toLowerCase();
    const controls = this.getVisitControls(this.selectedVisit);
    const a = controls ? (<FormGroup>controls.score).controls[scoreName]?.value : null;

    return !this.previousTimePoint || this.isTrustedScore(a, this.previousTimePoint[scoreName]);
  }

  isCommentOptional(currentScore) {
    
    return !this.previousTimePoint ||
        (this.isTrustedScore(currentScore.score.l1, this.previousTimePoint.l1)
        && this.isTrustedScore(currentScore.score.l2, this.previousTimePoint.l2)
        && this.isTrustedScore(currentScore.score.l3, this.previousTimePoint.l3)
        && this.isTrustedScore(currentScore.score.l4, this.previousTimePoint.l4)
        && this.isTrustedScore(currentScore.score.t4, this.previousTimePoint.t4)
        && this.isTrustedScore(currentScore.score.t5, this.previousTimePoint.t5)
        && this.isTrustedScore(currentScore.score.t6, this.previousTimePoint.t6)
        && this.isTrustedScore(currentScore.score.t7, this.previousTimePoint.t7)
        && this.isTrustedScore(currentScore.score.t8, this.previousTimePoint.t8)
        && this.isTrustedScore(currentScore.score.t9, this.previousTimePoint.t9)
        && this.isTrustedScore(currentScore.score.t10, this.previousTimePoint.t10)
        && this.isTrustedScore(currentScore.score.t11, this.previousTimePoint.t11)
        && this.isTrustedScore(currentScore.score.t12, this.previousTimePoint.t12));
  }

  isCommentValid() {
    const currentScore = this.buildDatasObject(this.selectedVisit, VisitStatus.IN_PROGRESS) as any;
    return currentScore && (currentScore.score.comment || this.isCommentOptional(currentScore));
  }

  openGuidlineDialogBox(){
    const  content = '<img width="100%" src="assets/images/genant-scoring-info.png"/>';
    this.dialog.open(MessageDialogComponent, {
      data: {message: content, html: true}
    });
  }

  chooseActiveVisitByStatus() {
    const activeVisitIndex = this.currentReading.visits.findIndex(visit => visit.status === VisitStatus.IN_PROGRESS);
    if (activeVisitIndex !== -1) {
      this.onChangeActivatedVisit(activeVisitIndex);
    }
  }

  onChangeActivatedVisit(index) {
    this.selectedVisit = index;
    // this.activedVisitSubject.next(this.currentReading.visits[index]);
  }

  getEndpointName(): string {
    return 'GENANT';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingGenantService.getAvailableAdjudicationReadings(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingGenantService.startReading(studyId, readingId)
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number; }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    const model = this.buildDatasObject(this.selectedVisit, VisitStatus.IN_PROGRESS);
    const visit = this.currentReading.visits[this.selectedVisit];
    visit.score = (model as any).score;
    this.updateReadingNavigator(this.currentReading);
    const readingData = {
      spentSeconds: data.spentSeconds,
    }
    if (justSaveTime)
      return this.readingGenantService.updateReading(studyId, readingId, readingData);
    return this.skipReading(studyId, { spentSeconds: data.spentSeconds });
  }

  updateReadingNavigator(reading: any) {
    if (!this.readingListUpdatedSubject.closed)
      this.readingListUpdatedSubject.next(reading);
  }

  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    this.currentReading.timeSpent = data.spentSeconds;
    const model = this.buildDatasObject(this.selectedVisit, VisitStatus.DONE);
    const visit = this.currentReading.visits[this.selectedVisit];
    return this.readingGenantService.lockTimepoint(studyId, visit.id, model);
  }

  skipReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    const model = this.buildDatasObject(this.selectedVisit, VisitStatus.IN_PROGRESS);
    const visit = this.currentReading.visits[this.selectedVisit];
    return this.readingGenantService.lockTimepoint(studyId, visit.id, model);
  }

  buildDatasObject(visitIndex: number, status: string): Object {
    const controls = this.getVisitControls(visitIndex);
    if (!controls) return null;

    const data = Object();
    data.id = controls.id.value;
    data.visitConfigId = controls.visitConfigId.value;
    data.status = status;
    data.disagreement = controls.disagreement.value;
    data.visitOrder = controls.visitOrder.value;
    data.score = {
      id: (<FormGroup>controls.score).controls.id.value,
      l1: (<FormGroup>controls.score).controls.l1.value,
      l2: (<FormGroup>controls.score).controls.l2.value,
      l3: (<FormGroup>controls.score).controls.l3.value,
      l4: (<FormGroup>controls.score).controls.l4.value,
      t4: (<FormGroup>controls.score).controls.t4.value,
      t5: (<FormGroup>controls.score).controls.t5.value,
      t6: (<FormGroup>controls.score).controls.t6.value,
      t7: (<FormGroup>controls.score).controls.t7.value,
      t8: (<FormGroup>controls.score).controls.t8.value,
      t9: (<FormGroup>controls.score).controls.t9.value,
      t10: (<FormGroup>controls.score).controls.t10.value,
      t11: (<FormGroup>controls.score).controls.t11.value,
      t12: (<FormGroup>controls.score).controls.t12.value,
      comment: (<FormGroup>controls.score).controls.comment.value
    }
    return data;
  }

  getVisitControls(visitIndex: number) {
    return (<FormGroup>(<FormGroup>(<FormArray>this.readingForm.controls.visits).controls[visitIndex]))?.controls;
  }

  clearForm(): void {

  }

  // table
  defaultColumns: string[] = ['name', 'adjudication', 'reader01', 'reader02'];
  dataSource = this.GENANT_Table_DATA;
  // end table

  private checkValidity(formControl) {
    const commentValid = this.isCommentValid();
    this.switchSubmitBtnDisabledSubject.next(formControl.invalid || !commentValid);
  }

  getPreviousTimePoint() {
    console.log('this.currentReading: ', this.currentReading);
    this.previousTimePoint = null;
    if (this.currentReading.visits.length > 1) {
      const currentVisit = this.currentReading.visits.find(v => v.status === VisitStatus.IN_PROGRESS && v.visitOrder !== 1);
      if (currentVisit !== undefined) {
        const uploadedVisits = this.currentReading.visits.filter(v => v.noUploads === false).sort((a, b) => a.visitOrder - b.visitOrder);
        const previousVisitIndex = uploadedVisits.findIndex(visit => visit.id === currentVisit.id) - 1;
        this.previousTimePoint = uploadedVisits[previousVisitIndex].score;
      }
    }

    console.log('this.previousTimePoint: ', this.previousTimePoint);
  }

  toTitleCase(str) {
    return str?.toLowerCase().split('_').map(function (word) {
      return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ') ?? '';
  }

  getExactPreviousScore(visitStatus, fieldScore) {
    if (visitStatus !== VisitStatus.IN_PROGRESS) {
      return 'NA';
    }

    const fieldScoreLower = fieldScore.toLowerCase();

    return this.previousTimePoint === null || !this.previousTimePoint[fieldScoreLower] ? 'NA'
      : this.toTitleCase(this.previousTimePoint[fieldScore.toLowerCase()]);
  }

  getColumns(showPreviousTP: boolean) {
    if (showPreviousTP) {
      return [...this.defaultColumns, 'previousTP'];
    }

    return this.defaultColumns;
  }
}
