export class GetCurrentUserInfo {
  static readonly type = '[Current User Info] GetCurrentUserInfo';

  constructor(public userId?: number) {
    this.userId = this.userId ?? JSON.parse(localStorage.getItem('userId'));
  }
}

export class GetUserRolesForStudy {
  static readonly type = '[Current User Info] GetUserRolesForStudy';

  constructor(public studyId?: number, public userId?: number) {
    this.userId = (isNaN(this.userId) || !this.userId) ? JSON.parse(localStorage.getItem('userId')) : this.userId;
    this.studyId = this.studyId ?? JSON.parse(localStorage.getItem('project'))?.id;
  }
}

export class ClearCurrentUserInfo {
  static readonly type = '[Current User Info] ClearCurrentUserInfo';
}
