import { Injectable } from '@angular/core';

import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/body-location-area';

@Injectable({
  providedIn: 'root'
})
export class BodyLocationAreaService {

  constructor(private http: HttpClient) { }

  public findAll(): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public getById(id: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find/${id}` ;
    return this.http.get<BasicResponse<any>>(requestURL);
  }
}
