import {Injectable} from '@angular/core';

import {environment} from '../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import {PrimaryBodyLocationModel} from '../_models/ImagingProject/ImagingProjectBodypartDataset/primary-body-location-model';

const API_URL = environment.apiUrl;
const API_VERSION = environment.MSImagingProjectVersion;
const SERVICE = 'msimagingproject-dot-';
const SERVICE_API_URL = 'https://' + API_VERSION + SERVICE + API_URL + '/primary-body-location';

@Injectable({
  providedIn: 'root'
})
export class PrimaryBodyLocationService {

  constructor(private http: HttpClient) { }

  public findAll(): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public getAllByAreaId(bodyLocationAreaId: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all/${bodyLocationAreaId}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public getAllActiveByAreaId(bodyLocationAreaId: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find-all-active/${bodyLocationAreaId}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public getById(id: number): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/find/${id}`;
    return this.http.get<BasicResponse<any>>(requestURL);
  }

  public create(bodyLocationAreaId: number, primaryBodyLocation: PrimaryBodyLocationModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/${bodyLocationAreaId}`;
    return this.http.post<BasicResponse<any>>(requestURL, primaryBodyLocation);
  }

  public updateStatus(location: PrimaryBodyLocationModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/update-status/${location.id}?status=${location.status}`;
    const params = new HttpParams().set('status', location.status);
    return this.http.put<BasicResponse<any>>(requestURL,  { status: location.status});
  }

  public updateName(location: PrimaryBodyLocationModel): Observable<BasicResponse<any>> {
    const requestURL = `${SERVICE_API_URL}/update-name/${location.id}?name=${location.name}&nodal=${location.nodal}`;
    return this.http.put<BasicResponse<any>>(requestURL,{ name: location.name, nodal: location.nodal} );
  }
}
