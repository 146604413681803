import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ImagingProjectService, SiteConfigService, StudyUserService} from '../../../_services';
import {JwtUtilService} from '../../../_helpers/jwt-util.service';
import {Select, Store} from '@ngxs/store';
import {Observable} from 'rxjs';
import {StudyModel, StudyDashboardModel} from '../../../_models/ImagingProject/study-model';
import {PushDashboardProjects, SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';
import {ProjectsState} from '../../../core/data-management/states/projects.state';
import {ToastService} from '../../../_services/internal/toast.service';
import {AuthInfoState} from '../../../core/data-management/states/auth-info.state';
import {ResponseCode} from '../../../core/constants/response-code';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-operation-dashboard',
  templateUrl: './operations-dashboard.component.html',
  styleUrls: ['./operations-dashboard.component.css']
})
export class OperationDashboardComponent implements OnInit, AfterViewInit {

  readonly DashboardMode = DashboardMode;
  readonly PM_COLUMNS = ['row-num', 'protocolId', 'sponsorName', 'area', 'disease', 'startDate', 'endDate', 'sites', 'patients', 'timepoints', 'actions'];
  readonly SPONSOR_COLUMNS = ['protocolId', 'sponsorName', 'area', 'disease', 'sites', 'patients', 'timepoints', 'status', 'actions'];

  @Select(ProjectsState.getDashboardProjectIds) projectIdsObservable: Observable<number[]>;
  @Select(ProjectsState.getDashboardProjects) projectsObservable: Observable<StudyDashboardModel[]>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns: string[] = [
    'protocolId',
    'sponsorName',
    'area',
    'disease',
    'sites',
    'patients',
    'timepoints',
    'status',
    'actions'
  ];

  projectList: StudyDashboardModel[] = [];
  projectListCache: StudyDashboardModel[] = [];
  projectsDataSource: MatTableDataSource<StudyDashboardModel>;
  loading = true;
  dashboardMode: DashboardMode;

  constructor(private router: Router,
              private imagingProjectService: ImagingProjectService,
              private siteConfigService: SiteConfigService,
              private studyUserService: StudyUserService,
              private toastService: ToastService,
              private jwtUtilService: JwtUtilService,
              private store: Store) {
  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Operations Dashboard'));
    this.projectsDataSource = new MatTableDataSource<StudyDashboardModel>();

    this.initDashboardMode();

    this.projectsObservable.subscribe(p => {
      this.projectListCache = [...this.projectListCache, ...p];
    });

    this.store.select(ProjectsState.getDashboardProjectIds).subscribe(ids => {
      this.loading = true;
      const futureIds = this.checkProjectIds(ids);
      this.loadProjectsInfo(futureIds);
      this.projectList = [...this.projectList, ...this.projectListCache];
    });

  }

  ngAfterViewInit(): void {
    if (!!this.sort) {
      this.projectsDataSource.sort = this.sort;
    }
    if (!!this.paginator) {
      this.projectsDataSource.paginator = this.paginator;
    }
  }

  private initDashboardMode(): void {
    this.store.select(AuthInfoState.getAuthInfo).subscribe(info => {
      const roleTypes = info.roles.map(role => role.roleType);
      if (!!roleTypes.find(roleType => roleType === RoleType.DASHBOARD)) {
        this.dashboardMode = DashboardMode.PM;
      } else if (!!roleTypes.find(roleType => roleType === RoleType.SPONSOR)) {
        this.dashboardMode = DashboardMode.SPONSOR;
      }
    });
  }

  loadProjectsInfo(projectIds: number[]) {

    if (projectIds.length < 1) {
      this.updateProjectList();
      return;
    }

    this.imagingProjectService.getStudyiesForDashboardByIds(projectIds).subscribe(response => {
      if (response.responseCode === 200) {
        this.projectList = [...response.data];
        this.projectList.forEach(project => this.addMetricsToProject(project));

        this.updateProjectList();
      } else if (response.responseCode === 500) {
        this.loading = false;
        this.toastService.respError(response);
      }
    });
  }

  private addMetricsToProject(project) {
    this.siteConfigService.getSiteUploadsByStudyId(project.id).subscribe(siteConfigsResp => {
      if (siteConfigsResp.responseCode === ResponseCode.OK) {
        project.patientswithUploads = siteConfigsResp.data.patientsWithUploads; //this.getPatientsWithUploads(siteConfigsResp.data);
        project.timepointsWithUploads = siteConfigsResp.data.timepointsWithUploads; // this.getTimepointsWithUploads(siteConfigsResp.data);
        project.sitesWithUploads = siteConfigsResp.data.sitesWithUploads; //this.getSitesWithUploads(siteConfigsResp.data);
      } else if (siteConfigsResp.responseCode === ResponseCode.INTERNAL_SERVER_ERROR) {
        this.toastService.respError(siteConfigsResp);
      }
    });
  }

  private getSitesWithUploads(siteConfigs) {
    return siteConfigs
      .filter(siteConfig => siteConfig.patients
        .flatMap(patient => patient.visitConfigs)
        .filter(visitConfig => !!visitConfig.uploadDate)
        .length > 0)
      .length;
  }

  private getTimepointsWithUploads(siteConfigs) {
    return siteConfigs
      .flatMap(siteConfig => siteConfig.patients)
      .filter(patient => patient.phantomData === false)
      .flatMap(patient => patient.visitConfigs)
      .filter(visitConfig => !!visitConfig.uploadDate)
      .length;
  }

  private getPatientsWithUploads(siteConfigs) {
    return siteConfigs
      .flatMap(siteConfig => siteConfig.patients)
      .filter(patient => patient.phantomData === false
        && !!patient.visitConfigs.find(visitConfig => !!visitConfig.uploadDate))
      .length;
  }

  checkProjectIds(ids: number[]): number[] {
    let actualIds: number[];
    actualIds = ids.filter(id => !this.projectListCache.find(p => p.id === id));
    return actualIds;
  }

  navigateToDetails(id: number): void {
    this.router.navigate([`/operationdashboard/${id}`]).then(r => {
    });
  }

  applyFilter(filterValue: string): void {
    if (this.projectsDataSource !== undefined) {
      this.projectsDataSource.filter = filterValue.trim().toLowerCase();
      if (this.projectsDataSource.paginator) {
        this.projectsDataSource.paginator.firstPage();
      }
    }
  }

  updateProjectList(projectList?: StudyDashboardModel[]): void {
    let list = projectList ? projectList : this.projectList;
    list = [...list, ...this.projectListCache.filter(project => !list.find(target => target.id === project.id))];
    this.store.dispatch(new PushDashboardProjects(this.projectList));
    this.projectsDataSource.data = list.sort((a, b) => a.startDate < b.startDate ? 1 : -1);
    this.projectsDataSource.paginator = this.paginator;
    this.projectsDataSource.sort = this.sort;
    this.loading = false;
  }
}

export enum DashboardMode {
  SPONSOR, PM
}

export enum RoleType {
  SPONSOR = 'Sponsor',
  DASHBOARD = 'DASHBOARD'
}
