import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { Demriq_Regions } from 'src/app/_models/DEMRIQ/demriq-regions.model';
import { ReadingDemriqService } from 'src/app/_services';

@Component({
  selector: 'app-demriq-region-location-table',
  templateUrl: './demriq-region-location-table.component.html',
  styleUrls: ['./demriq-region-location-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemriqRegionLocationTableComponent implements OnInit, OnDestroy {
  @Input('regionsToScore') regionsToScore: any[];
  @Input('primaryLocations') primaryLocations: any[];
  @Input() title: string;

  constructor(private readingDemriqService: ReadingDemriqService) {
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes.regionsToScore.currentValue != changes.regionsToScore.previousValue
      || changes.primaryLocations.currentValue != changes.primaryLocations.currentValue) {
        this.initForm();
      }
  }

  selectedArea: any;
  allRegions = [];
  selectedRegions: any[] = [];
  isChecked = true;
  allComplete = true;
  viewerCellChangeSubscription: Subscription;

  ngOnInit(): void {
    this.initForm();
    this.viewerCellChangeSubscription = this.readingDemriqService.selectedViewerCell.subscribe(value => {
      if (value) {
        this.allRegions.forEach(r => r.selected = value);
      }
    });
  }

  initForm() {
    this.allRegions = Demriq_Regions.getAllRegions(this.primaryLocations, this.regionsToScore);
    this.selectedRegions = JSON.parse(JSON.stringify(this.regionsToScore));
    this.filterAvailableRegions();
  }

  ngOnDestroy(): void {
    if (this.viewerCellChangeSubscription) {
      this.viewerCellChangeSubscription.unsubscribe();
    }
  }

  filterAvailableRegions() {
    if (this.regionsToScore.length !== 0) {
      this.allRegions = this.allRegions.filter(r => this.regionsToScore.find(ar => ar.toLowerCase() === r.region.toLowerCase()));
    }
  }

  onSelectAreaChange({region, primaryLocation}, color) {
    this.selectedArea = { region, primaryLocation, color };
    this.readingDemriqService.onSelectedJoint(this.selectedArea);
  }

  onChangeAll(event) {
    this.allRegions.forEach(item => {
      item.selected = event;
    });
    this.selectedRegions = [];
    if (event) {
      this.selectedRegions = JSON.parse(JSON.stringify(this.regionsToScore));
    }

    this.regionChangeService();
  }

  onRegionChange(event, value) {
    if (event.checked) {
      this.selectedRegions.push(value);
    } else {
      this.selectedRegions.splice(this.selectedRegions.indexOf(value), 1);
    }
    this.regionChangeService();
  }

  someRegionSelected() {
    const trueItems = this.allRegions.filter(item => item.selected).length;
    this.allComplete = trueItems === this.allRegions.length;
    return (trueItems > 0 && trueItems !== this.allRegions.length);
  }

  regionChangeService() {
    this.readingDemriqService.onSelectedRegions({
      selectedRegions: this.selectedRegions
    });
    this.someRegionSelected();
    console.log(this.selectedRegions);
  }

  OnSeeDetails() {
    this.readingDemriqService.onOpenFingerJointDetails(true);
  }

  deleteLastROI() {
    this.readingDemriqService.deleteLastDrawnROI(true);
  }

  trackByRegion(index, item) {
    return item.region;
  }

  trackByFingerName(index, item) {
    return item.name;
  }
}
