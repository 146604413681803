import {DownloadingView} from '../../interfaces/downloading-view';
import {DownloadMonitoringItem} from '../../../components/download-monitoring/download-monitoring.component';

export class SetDownloading {
  static readonly type = '[App] SetDownloading';

  constructor(public payload: DownloadingView) {
  }
}

export class PushDownloadProcess {
  static readonly type = '[App] PushDownloadProcess';

  constructor(public payload: DownloadMonitoringItem) {
  }
}

export class UpdateDownloadProcess {
  static readonly type = '[App] UpdateDownloadProcess';

  constructor(public payload: DownloadMonitoringItem) {
  }
}

export class ClearDownloadProcess {
  static readonly type = '[App] ClearDownloadProcess';
}
