import {ActivatedRoute, Router} from '@angular/router';
import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MessageDialogComponent} from '../../../../components/controls/message-dialog/message-dialog.component';
import {DataUploadService} from '../../../../_services/data-upload.service';
import {ImagingProjectService} from '../../../../_services/imaging-project.service';
import {StudySequenceLabelService} from '../../../../_services/study-sequence-label.service';
import {JwtUtilService} from '../../../../_helpers/jwt-util.service';


import * as moment from 'moment';
import {PatientService} from '../../../../_services/patient.service';
import {SiteConfigService} from '../../../../_services/site-config.service';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../../core/data-management/actions/projects.action';

const DEFAULT_COLUMNS = ['patientId', 'siteCode', 'patientCode', 'discontinued'];

@Component({
  selector: 'app-webinar-upload-list',
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.css']
})
export class WebinarUploadListComponent implements OnInit {
  displayedDynamicColumns: string[];
  displayedColumns: string[];
  dataSource: any;

  numberOfVisits: number;
  maxNumberOfUnscheduledVisits: number;

  detailedData = {
    visitsConfig: [],
    patients: [],
    uploads: [],
    projectModalities: [],
  };

  // initial rights for user
  profile = {
    rights: {
      canUploadData: false,
      canCreatePatient: false,
      iagProjectManager: false,
      canMarkPatientDiscontinued: false,
    }
  };

  study: any;

  activitiy: any;
  currentImageProject: any;
  currentImageProjectId: number;
  selectedSiteConfig = null;
  currentSiteConfigs = [];
  showModalSpinner = false;
  showSiteSelector = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  siteSelectControl = new FormControl();

  constructor(
    private imagingProjectSerivce: ImagingProjectService,
    private dataUploadService: DataUploadService,
    private patientService: PatientService,
    private siteConfigService: SiteConfigService,
    private studySequenceLabelService: StudySequenceLabelService,
    private jwtUtilService: JwtUtilService,
    public dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private store: Store) {

    this.activitiy = localStorage.getItem('activities');

  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Site upload'));
    let project = JSON.parse(localStorage.getItem('project'));
    this.currentImageProjectId = project.id;
    this.setActivityRights(this.currentImageProjectId);
    this.imagingProjectSerivce.getStudyForVisits(this.currentImageProjectId).subscribe(studyResponse => {
      this.study = studyResponse['data'];
      this.resetDysplayedColumns();
      if (this.study['unscheduledAllowed'] == true) {
        this.displayedColumns.push('unscheduled');
      }
      let siteId = localStorage.getItem('siteId');
      let siteIds = [];
      if ('ALL' != siteId) {
        let ids = siteId.split(',');
        ids.forEach(id => {
          siteIds.push(parseInt(id));
        });
      }
      ;

      if ('ALL' == siteId) {
        this.siteConfigService.getSiteConfigsByStudyId(this.currentImageProjectId).subscribe(siteConfigResponse => {
          this.currentSiteConfigs = siteConfigResponse['data'];
          this.setSelectedSiteConfigIfPatientWasCreated(studyResponse['data']);
        });
      } else {
        this.siteConfigService.getSiteConfigsByStudyIdAndSiteIds(this.currentImageProjectId, siteIds).subscribe(siteConfigResponse => {
          this.currentSiteConfigs = siteConfigResponse['data'];
          this.setSelectedSiteConfigIfPatientWasCreated(studyResponse['data']);
        });
      }
    });

    this.dataSource = new MatTableDataSource(this.detailedData.patients);
    if (this.dataSource != null) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }

  setSelectedSiteConfigIfPatientWasCreated(studyData) {
    let strSConf = localStorage.getItem('tmp.upload.patient_create.selectedSiteVisitConfig');
    this.selectedSiteConfig = JSON.parse(strSConf);
    if (this.selectedSiteConfig != null) {
      localStorage.setItem('tmp.upload.patient_create.selectedSiteVisitConfig', null);
      this.selectedSiteConfig = this.currentSiteConfigs.filter(sconf => sconf.id == this.selectedSiteConfig.id)[0];
      this.siteSelectControl.setValue(this.selectedSiteConfig.id);
      const siteConfigsArray = [];
      siteConfigsArray.push(this.selectedSiteConfig);
      if (this.currentSiteConfigs.length == 1) {
        this.showSiteSelector = false;
        this.selectedSiteConfig = this.currentSiteConfigs[0];
        this.setCurrentImageProject(this.currentSiteConfigs, studyData);
      } else {
        this.showSiteSelector = true;
        this.setCurrentImageProject(this.currentSiteConfigs, studyData);
      }
    } else {
      if (this.currentSiteConfigs.length == 1) {
        this.showSiteSelector = false;
        this.selectedSiteConfig = this.currentSiteConfigs[0];
        this.setCurrentImageProject(this.currentSiteConfigs, studyData);
      } else {
        this.showSiteSelector = true;
        this.setCurrentImageProject(this.currentSiteConfigs, studyData);
      }
    }
  }

  setActivityRights(id) {
    const str = localStorage.getItem('activities');
    if (str != null) {
      this.activitiy = JSON.parse(str);
      if (this.activitiy.indexOf('upload.data') > -1) {
        this.profile.rights.canUploadData = true;
      }
      if (this.activitiy.indexOf('upload.create.patient') > -1) {
        this.profile.rights.canCreatePatient = true;
      }
    }
    this.profile.rights.iagProjectManager = this.jwtUtilService.isUserHasActivity('configuration.upload.view.all.site.data', id);
    this.profile.rights.canMarkPatientDiscontinued = this.jwtUtilService.isUserHasActivity('upload.patient.discontinued', id);
  }

  setCurrentImageProject(siteConfigData, studyData) {
    if (siteConfigData != null && studyData != null) {
      this.showModalSpinner = true;
      let siteConfigIds = new Array();
      siteConfigData.forEach(sconf => {
        siteConfigIds.push(sconf.id);
      });
      siteConfigIds = Array.from(new Set(siteConfigIds));

      const patients = new Array();
      this.currentImageProject = studyData;
      this.currentImageProject.visits = this.sortVisits(this.currentImageProject.visits);

      if (this.currentImageProject.hasOwnProperty('visits')) {
        this.detailedData.patients = this.createPatientArray(siteConfigData, this.currentImageProject['visits']);
        this.detailedData.visitsConfig = this.currentImageProject['visits'];
        this.numberOfVisits = this.detailedData.visitsConfig.length;

        this.detailedData.visitsConfig.forEach(visit => {
          this.displayedDynamicColumns.push(visit.name);
        });

        if (this.maxNumberOfUnscheduledVisits > 0) {
          for (let k = 0; k < this.maxNumberOfUnscheduledVisits; k++) {
            this.displayedDynamicColumns.push('unscheduledVisit' + k);
          }
        }

        this.refreshDisplayedColumns();

        this.studySequenceLabelService.getStudyModalitiesByStudyId(this.currentImageProjectId).subscribe(modalitiesResp => {
          this.detailedData.projectModalities = modalitiesResp['data'];
          this.dataUploadService.getUploadInformationByStudyIdAndSiteConfigsIds(this.currentImageProjectId, siteConfigIds).subscribe(uploads => {
            this.detailedData.uploads = uploads;
            this.preparePatientData();
          });
        });
      }
    }
  }

  sortVisits(visits) {
    visits.forEach(visit => {
      let visitDate = moment(visit.create);
      if (visit.durationTimeUnit == 'D') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'd');
      } else if (visit.durationTimeUnit == 'W') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'w');
      } else if (visit.durationTimeUnit == 'M') {
        visitDate = moment(visit.create).add(visit.durationTimeValue, 'M');
      }
      visit.visitDate = visitDate;
    });
    visits.sort((a, b) => {
      if (a.visitDate < b.visitDate) {
        return -1;
      }
      if (a.visitDate > b.visitDate) {
        return 1;
      }
      return 0;
    });
    let baselineIndex = visits.indexOf(visits.find(this.isBaseline));
    visits.unshift(visits.find(this.isBaseline));
    visits.splice(baselineIndex + 1, 1);
    return visits;
  }

  isBaseline(visit) {
    return visit.baseline === true;
  }
  createPatientArray(siteConfigData, visits) {
    this.maxNumberOfUnscheduledVisits = 0;
    const data = new Array();
    siteConfigData.forEach(siteConfig => {
      siteConfig.patients.forEach(patient => {
        const p = {
          siteConfigId: siteConfig.id,
          created: patient.created,
          patientId: patient.id,
          patientCode: patient.patientCode,
          patientBirthDate: patient.patientBirthDate,
          phantom: patient.phantomData,
          siteCode: siteConfig.siteCode,
          discontinued: patient.discontinued,
          visits: [],
          visitsUnscheduled: [],
          visitConfigs: patient.visitConfigs,
        };
        //add project visits
        visits.forEach(visit => {
          let vConf = patient.visitConfigs.filter(config => config.visit.id == visit.id)[0];
          const v = {
            upload: null,
            config: {
              visitConfigsId: vConf.id,
              visitId: vConf.visit.id,
              name: vConf.visit.name,
              durationTimeUnit: vConf.visit.durationTimeUnit,
              durationTimeValue: vConf.visit.durationTimeValue,
            }
          };
          p.visits.push(v);
        });
        // unscheduled visits
        let unshedulledVisits = patient.visitConfigs.filter(vc => vc.visit.name.toLowerCase() == 'Unscheduled'.toLowerCase());
        if (unshedulledVisits != null) {
          unshedulledVisits.sort((a, b) => {
            if (a.created > b.created) {
              return 1;
            }
            if (a.created < b.created) {
              return -1;
            }
            return 0;
          });

          unshedulledVisits.forEach(uvConf => {
            const v = {
              upload: null,
              config: {
                visitConfigsId: uvConf.id,
                visitId: uvConf.visit.id,
                name: uvConf.visit.name,
                durationTimeUnit: uvConf.visit.durationTimeUnit,
                durationTimeValue: uvConf.visit.durationTimeValue,
              }
            };
            p.visitsUnscheduled.push(v);
          });
        }

        if (this.maxNumberOfUnscheduledVisits < p.visitsUnscheduled.length) {
          this.maxNumberOfUnscheduledVisits = p.visitsUnscheduled.length;
        }
        data.push(p);
      });
    });

    data.sort((a, b) => {
      if (a.created < b.created) {
        return 1;
      }
      if (a.created > b.created) {
        return -1;
      }
      return 0;
    });
    return data;
  }

  filterUploadsByPationId(element, i) {
    return (element.patientId == this.detailedData.patients[i].patientId);
  }

  preparePatientData() {
    this.detailedData.patients.forEach(patient => {
      const patient_uploads = this.detailedData.uploads.filter(pu => pu.patientId == patient.patientId);
      patient_uploads.forEach(patientUpload => {
        let visit_uploads = this.detailedData.uploads.filter(vu => vu.visitConfigId == patientUpload.visitConfigId);
        let allModalities = [];
        let latestUploadTime = 0;
        visit_uploads.forEach(visitUpload => {
          if (visitUpload.modalities['modality'] != null) {
            visitUpload.modalities['modality'].forEach(m => {
              if (m != null) {
                allModalities.push(m['name']);
              }
            });
          }
          if (visitUpload.created > latestUploadTime) {
            latestUploadTime = visitUpload.created;
          }
        });
        allModalities = Array.from(new Set(allModalities));
        let missingModalities = [];
        this.detailedData.projectModalities.forEach(mod => {
          let tmpMod = allModalities.filter(m => m == mod.name);
          if (tmpMod.length == 0) {
            missingModalities.push(mod.name);
          }
        });

        // upload info data
        const pUpload = {
          visitStatus: undefined,
          filesPending: 0,
          filesSuccess: 0,
          filesFailed: 0,
          visitId: patientUpload.visitId,
          visitConfigId: patientUpload.visitConfigId,
          siteConfigId: patientUpload.siteConfigId,
          patientId: patientUpload.patientId,
          imageProjectId: patientUpload.imageProjectId,
          requiredModalities: missingModalities,
          requiredModalitiesMessage: '',
          latestUploadTime: latestUploadTime,
        };
        if (pUpload.requiredModalities.length > 0) {
          pUpload.requiredModalitiesMessage = 'Pending: ' + missingModalities.join();
        } else {
          pUpload.requiredModalitiesMessage = 'all modalities';
        }
        if (patientUpload.lastUploadResultStatus == null) {
          pUpload.visitStatus = 'none';
        } else if (patientUpload.lastUploadResultStatus == 'success') {
          pUpload.visitStatus = 'success';
        } else {
          pUpload.visitStatus = 'failed';
        }

        const patientVisitData = patient.visits.find(pv => pv.config.visitConfigsId == patientUpload.visitConfigId);
        if (patientVisitData != null) {
          patientVisitData.upload = pUpload;
        }

        const patientVisitUnshedulledData = patient.visitsUnscheduled.find(pv => pv.config.visitConfigsId == patientUpload.visitConfigId);
        if (patientVisitUnshedulledData != null) {
          patientVisitUnshedulledData.upload = pUpload;
        }

      });
    });


    this.showModalSpinner = false;
    if (this.detailedData != null && this.detailedData.patients != null) {
      this.dataSource = new MatTableDataSource(this.detailedData.patients);
      if (this.dataSource != null) {
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    }
  }

  createPatient() {
    let allPatientsCodes = new Array();
    if (this.selectedSiteConfig != null) {
      let siteId = this.selectedSiteConfig.site.id;
      this.patientService.getPatientsByStudyId(this.currentImageProjectId).subscribe(patientResp => {
        let patients = patientResp['data'];
        if (patients != null) {
          patients.forEach(patient => {
            allPatientsCodes.push(patient.patientCode);
          });
        }
        localStorage.setItem('tmp.upload.all_patinets_codes', JSON.stringify(allPatientsCodes));
        localStorage.setItem('tmp.upload.visitsConfig', JSON.stringify(this.currentImageProject['visits']));
        localStorage.setItem('tmp.upload.patient_create.selectedSiteVisitConfig', JSON.stringify(this.selectedSiteConfig));
        this.router.navigate(['/case/new/', {ipid: this.currentImageProjectId, sid: siteId}]);
      });
    }
  }

  clickVisitEdit(item, element, unscheduled) {
    if (element != null) {
      localStorage.setItem('tmp.upload.patient.visit', JSON.stringify(item));
      if (element.upload == null) {
        this.router.navigate(['/imagingproject/upload/visit',
          {
            ipid: this.currentImageProject.id, sid: item.siteConfigId, pid: item.patientId,
            vcid: element.config.visitConfigsId, vdid: element.config.visitId, mode: 'upload', unscheduled: unscheduled,
          }]);
      } else if (element.upload.visitStatus == undefined || element.upload.visitStatus == 'none') {
        this.router.navigate(['/imagingproject/upload/visit',
          {
            ipid: this.currentImageProject.id, sid: item.siteConfigId, pid: item.patientId,
            vcid: element.config.visitConfigsId, vdid: element.config.visitId, mode: 'upload', unscheduled: unscheduled,
          }]);
      } else if (element.upload.visitStatus == 'failed') {
        this.router.navigate(['/imagingproject/upload/visit',
          {
            ipid: this.currentImageProject.id, sid: item.siteConfigId, pid: item.patientId,
            vcid: element.config.visitConfigsId, vdid: element.config.visitId, mode: 'edit', unscheduled: unscheduled,
          }]);
      } else if (element.upload.visitStatus == 'success') {
        this.router.navigate(['/imagingproject/upload/visit',
          {
            ipid: this.currentImageProject.id, sid: item.siteConfigId, pid: item.patientId,
            vcid: element.config.visitConfigsId, vdid: element.config.visitId, mode: 'edit', unscheduled: unscheduled,
          }]);
      }
    }
  }

  clickVisitUnscheduled(element) {
    if (this.maxNumberOfUnscheduledVisits < this.currentImageProject.unscheduledLimit) {
      let unscheduledVisitsWithoutUploads = element.visitsUnscheduled.filter(vc => vc.upload == null).length;
      if (unscheduledVisitsWithoutUploads == 0) {
        this.imagingProjectSerivce.createUnscheduledVisit(element.patientId, this.currentImageProject.id).subscribe(unschedVisitRep => {
          let vConf = unschedVisitRep['data'];
          if (vConf != null) {
            this.router.navigate(['/imagingproject/upload/visit',
              {
                ipid: this.currentImageProject.id, sid: element.siteConfigId, pid: element.patientId,
                vcid: vConf.id, vdid: vConf.visit.id, mode: 'upload', unscheduled: true,
              }]);
          }
        });
      } else {
        // dialog to upload data in existing unscheduled visit without uploaded data
        this.dialog.open(MessageDialogComponent, {
          height: '200px',
          width: '600px',
          disableClose: true,
          data: {
            title: 'Visit without uploaded data already exists',
            message: 'Please upload data in existing unscheduled visit without uploaded data',
            showOk: true,
            showCancel: false,
            html: false
          }
        });
      }
    } else {
      // dialog that user can’t create more than limited number unscheduled visits
      this.dialog.open(MessageDialogComponent, {
        height: '200px',
        width: '600px',
        disableClose: true,
        data: {
          title: 'Visit without uploaded data already exists',
          message: 'You  can’t create more than limited number unscheduled visits',
          showOk: true,
          showCancel: false,
          html: false
        }
      });
    }
  }


  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  selectSite(e) {
    let sConfigs = [];
    if (e != null) {
      this.selectedSiteConfig = this.currentSiteConfigs.filter(s => s.id == e)[0];
      sConfigs.push(this.selectedSiteConfig);
    } else {
      this.selectedSiteConfig = null;
      sConfigs = this.currentSiteConfigs;
    }
    this.detailedData.patients = [];
    this.detailedData.visitsConfig = [];
    this.detailedData.uploads = [];
    this.resetDysplayedColumns();
    if (this.study['unscheduledAllowed'] == true) {
      this.displayedColumns.push('unscheduled');
    }
    this.setCurrentImageProject(sConfigs, this.currentImageProject);
  }


  compareSelectedSites(i1, i2) {
    return i1 == i2;
  }

  changeDiscontinued(patient) {
    if (this.profile.rights.canMarkPatientDiscontinued === true) {
      this.openDialog(patient);
    }
  }


  openDialog(patient): void {
    let message = '';
    //reversed message
    if (!patient['discontinued']) {
      message = 'You are about to change the status of a patient discontinued from the clinical trial.\n' +
        '<br/><br/>' +
        'Please confirm that this patient status change?\n' +
        '<br/><br/>' +
        '<b>Note: When a patient status is changed, new data could be uploaded for this patient</b>';

    } else {
      message = 'You are about to mark the patient as discontinued from the clinical trial.\n' +
        '<br/><br/>' +
        'Please confirm that this patient is discontinued from the clinical trial?\n' +
        '<br/><br/>' +
        '<b>Note: When a patient is marked as discontinued, no more data can be uploaded</b>';
    }
    ;
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '270px',
      width: '600px',
      disableClose: true,
      data: {title: 'Patient discontinued', message: message, showOk: true, showCancel: true, html: true}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == 'ok') {
        this.patientService.updatePatientDiscontinued(this.currentImageProjectId, patient['patientId'], patient['discontinued']).subscribe(patientResult => {
        });
      } else {
        patient['discontinued'] = !patient['discontinued'];
      }
    });
  }

  private resetDysplayedColumns() {
    this.displayedColumns = new Array<string>();
    for (const columnName of DEFAULT_COLUMNS) {
      this.displayedColumns.push(columnName);
    }
    this.displayedDynamicColumns = new Array<string>();
  }

  private refreshDisplayedColumns() {
    // dynamic
    for (const columnName of this.displayedDynamicColumns) {
      this.displayedColumns.push(columnName);
    }
  }
}
