export class AnnJswObject extends lt.Annotations.Engine.AnnPolylineObject {
  public static jswObjectId = -199;
  public static splineTension = 0.3;
  private _firstLine: Array<number> = [];
  private _secondLine: Array<number> = [];
  public distanceScale = 1;
  public distanceFirstPoint?: lt.LeadPointD;
  public distanceSecondPoint?: lt.LeadPointD;
  private _calcVerticalDistance = true;

  constructor() {
    super();
    this.setId(AnnJswObject.jswObjectId); // Set the object id

    this.tag = null;

    this.labels['JswDistance'] = new lt.Annotations.Engine.AnnLabel();
    this._firstLine = [];
    this._secondLine = [];
  }

  create(): AnnJswObject {
    return new AnnJswObject();
  }

  public get_friendlyName(): string {
    return 'Jsw';
  }

  addFirstLinePoint(point: lt.LeadPointD) {
    this._firstLine.push(this.points.count);
    this.points.add(point);
  }

  addSecondLinePoint(point: lt.LeadPointD) {
    this._secondLine.push(this.points.count);
    this.points.add(point);
  }

  set CalcVerticalDistance(value) {
    this._calcVerticalDistance = value;
  }

  get CalcVerticalDistance() {
    return this._calcVerticalDistance;
  }

  get firstLinePoints(): Array<lt.LeadPointD> {
    if (!this._firstLine) {
      return [];
    }

    const points = this._firstLine?.map(f => this.points.item(f)) || [];

    if(points.length <= 1) {
      return points;
    }

    return lt.Annotations.Engine.Utils.splineCurve(points, AnnJswObject.splineTension);
  }

  get firstLine(): Array<lt.LeadPointD> {
    return this._firstLine?.map(f => this.points.item(f)) || [];
  }

  get secondLinePoints(): Array<lt.LeadPointD> {
    if (!this._secondLine) {
      return [];
    }

    const points = this._secondLine?.map(f => this.points.item(f)) || [];

    if (points.length <= 1) {
      return points;
    }

    return lt.Annotations.Engine.Utils.splineCurve(points, AnnJswObject.splineTension);
  }

  get secondLine(): Array<lt.LeadPointD> {
    return this._secondLine?.map(f => this.points.item(f)) || [];
  }

  get distance() {
    const [first, second, minDistance] = this._calcVerticalDistance ? this.findMinimalDistanceVertical() : this.findMinimalDistanceFree();

    if (!Number.isFinite(minDistance)) {
      return null;
    }

    return {
      first: this.distanceFirstPoint ?? first,
      second: this.distanceSecondPoint ?? second,
      distance: minDistance
    };
  }

  public hitTest(point: lt.LeadPointD, hitTestBuffer: number): boolean {
    const hit = super.hitTest(point, hitTestBuffer);

    if (hit) {
      return true;
    }

    const first = this.hitPolyline(point, hitTestBuffer, this.firstLinePoints);
    const second = this.hitPolyline(point, hitTestBuffer, this.secondLinePoints);

    return first || second;
  }

  private hitPolyline(hitPoint: lt.LeadPointD, hitTestBuffer: number, linePoints: Array<lt.LeadPointD>): boolean {
    const polyline = new lt.Annotations.Engine.AnnPolylineObject();
    (linePoints || []).forEach(p => polyline.points.add(p));

    return polyline.hitTest(hitPoint, hitTestBuffer);
  }

  interpolateY(x, prevPoint, nextPoint) {
    return prevPoint.y + (x - prevPoint.x) * (nextPoint.y - prevPoint.y) / (nextPoint.x - prevPoint.x);
  }

  findMinimalDistanceVertical() {
    const firstLinePoints = this.firstLinePoints.sort((a, b) => a.x - b.x);
    const secondLinePoints = this.secondLinePoints.sort((a, b) => a.x - b.x);

    let firstLineIndex = 0, secondLineIndex = 0;
    let minDistance = Infinity;
    let firstMinDistanceDot;
    let secondMinDistanceDot;

    while (firstLineIndex < firstLinePoints.length && secondLineIndex < secondLinePoints.length) {
      if (firstLinePoints[firstLineIndex].x < secondLinePoints[secondLineIndex].x) {
        if (secondLineIndex > 0 && firstLinePoints[firstLineIndex].x > secondLinePoints[secondLineIndex - 1].x) {
          const interpolatedY = this.interpolateY(
            firstLinePoints[firstLineIndex].x,
            secondLinePoints[secondLineIndex - 1],
            secondLinePoints[secondLineIndex]
          );

          const distance = Math.abs(firstLinePoints[firstLineIndex].y - interpolatedY);
          if (distance < minDistance) {
            minDistance = distance;
            firstMinDistanceDot = firstLinePoints[firstLineIndex];
            secondMinDistanceDot = lt.LeadPointD.create(firstLinePoints[firstLineIndex].x, interpolatedY);
          }
        }

        firstLineIndex++;
      } else if (firstLinePoints[firstLineIndex].x > secondLinePoints[secondLineIndex].x) {
        if (firstLineIndex > 0 && firstLinePoints[firstLineIndex - 1].x < secondLinePoints[secondLineIndex].x) {
          const interpolatedY = this.interpolateY(
            secondLinePoints[secondLineIndex].x,
            firstLinePoints[firstLineIndex - 1],
            firstLinePoints[firstLineIndex]
          );

          const distance = Math.abs(interpolatedY - secondLinePoints[secondLineIndex].y);
          if (distance < minDistance) {
            minDistance = distance;
            firstMinDistanceDot = lt.LeadPointD.create(secondLinePoints[secondLineIndex].x, interpolatedY);
            secondMinDistanceDot = secondLinePoints[secondLineIndex];
          }
        }

        secondLineIndex++;
      } else {
        const distance = Math.abs(firstLinePoints[firstLineIndex].y - secondLinePoints[secondLineIndex].y);
        if (distance < minDistance) {
          minDistance = distance;
          firstMinDistanceDot = firstLinePoints[firstLineIndex];
          secondMinDistanceDot = secondLinePoints[secondLineIndex];
        }
        firstLineIndex++;
        secondLineIndex++;
      }
    }

    return [firstMinDistanceDot, secondMinDistanceDot, minDistance === Infinity ? Number.NaN : minDistance];
  }

  findMinimalDistanceKeyPoints() {
    const line1 = this.firstLinePoints;
    const line2 = this.secondLinePoints;
    const orderedLine1 = (line1 || []).sort((a, b) => a.x - b.x);
    const orderedLine2 = (line2 || []).sort((a, b) => a.x - b.x);
    let dot1;
    let dot2;
    let minDistance = Number.MAX_VALUE;

    orderedLine1.forEach((d1, index) => {
      if (index >= orderedLine2.length) {
        return;
      }

      const d2 = orderedLine2[index];
      const distance = this.calcDistance(d1, d2);

      if (distance < minDistance) {
        dot1 = d1;
        dot2 = d2;
        minDistance = distance;
      }
    });

    return [dot1, dot2, minDistance === Number.MAX_VALUE ? Number.NaN : minDistance];
  }

  findMinimalDistanceFree() {
    const line1 = this.firstLinePoints;
    const line2 = this.secondLinePoints;
    let dot1 = line1 && line1.length ? line1[0] : undefined;
    let dot2 = line2 && line2.length ? line2[0] : undefined;
    let minDistance = this.calcDistance(dot1, dot2);

    (line1 || []).forEach(d1 => {
      (line2 || []).forEach(d2 => {
        const distance = this.calcDistance(d1, d2);

        if (distance < minDistance) {
          dot1 = d1;
          dot2 = d2;
          minDistance = distance;
        }
      });
    });

    return [dot1, dot2, minDistance];
  }

  private calcDistance(p1: lt.LeadPointD, p2: lt.LeadPointD): number {
    if (!p1 || !p2) {
      return Number.NaN;
    }

    const dX = (p1.x - p2.x);
    const dY = (p1.y - p2.y);
    return Math.sqrt(dX * dX + dY * dY);
  }

  public serialize(options: lt.Annotations.Engine.AnnSerializeOptions, parentNode: Node, document: Document) {
    super.serialize(options, parentNode, document);
    this.serializeArray((this._firstLine || []), 'FirstLinePoints', document, parentNode);
    this.serializeArray((this._secondLine || []), 'SecondLinePoints', document, parentNode);
    this.serializeData(this.distanceScale.toString(), 'DistanceScale', document, parentNode);
    if (this.distanceFirstPoint && this.distanceSecondPoint) {
      this.serializeArray([this.distanceFirstPoint.x, this.distanceFirstPoint.y], 'DistanceFirstPoint', document, parentNode);
      this.serializeArray([this.distanceSecondPoint.x, this.distanceSecondPoint.y], 'DistanceSecondPoint', document, parentNode);
    }

    this.serializeData(this._calcVerticalDistance.toString(), 'CalcVerticalDistance', document, parentNode);
  }

  private serializeArray(array: Array<any>, elementName: string, document: Document, parentNode: Node) {
    const data = array.join(',');
    this.serializeData(data, elementName, document, parentNode);
  }

  private serializeData(data: string, elementName: string, document: Document, parentNode: Node) {
    const element = document.createElement(elementName);
    const node = document.createTextNode(data);
    element.appendChild(node);
    parentNode.appendChild(element);
  }

  public deserialize(options: lt.Annotations.Engine.AnnDeserializeOptions, element: Node, document: Document) {
    super.deserialize(options, element, document);
    const firstData = this.deserializeData(element, 'FirstLinePoints');
    const secondData = this.deserializeData(element, 'SecondLinePoints');

    this._firstLine = (firstData.split(',') || [])
      .map((n) => parseInt(n, 10))
      .filter( n => Number.isFinite(n));

    this._secondLine = (secondData.split(',') || [])
      .map((n) => parseInt(n, 10))
      .filter( n => Number.isFinite(n));

    const distanceScaleStr = this.deserializeData(element, 'DistanceScale');
    const distanceScale = parseFloat(distanceScaleStr);
    this.distanceScale = isFinite(distanceScale) ? distanceScale : 1;

    const calcVerticalDistanceStr = this.deserializeData(element, 'CalcVerticalDistance');
    this._calcVerticalDistance = !calcVerticalDistanceStr || calcVerticalDistanceStr?.toLowerCase() === 'true';
  }

  private deserializeData(element: Node, elementName: string) {
    let firstData = '';

    const xmlElement = <Element>element;
    const nodeList = xmlElement.getElementsByTagName(elementName);

    for (let i = 0; i < nodeList.length; i++) {
      const childNode = nodeList[i];
      if (childNode != null && childNode.firstChild != null && (childNode.parentNode === element)) {
        firstData = childNode.firstChild.nodeValue.trim();
        break;
      }
    }

    return firstData;
  }
}

(<any>window).AnnJswObject = AnnJswObject;

