import {Component, Inject, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReadingTypeSequentialOptions} from '../../../core/constants/reading-type-sequential-options';

@Component({
  selector: 'app-reading-type-sequential-options-dialog',
  templateUrl: './reading-type-sequential-options-dialog.component.html',
  styleUrls: ['./reading-type-sequential-options-dialog.component.css']
})
export class ReadingTypeSequentialOptionsDialogComponent implements OnInit {

  readerTypeSequentialOptionsForm: FormControl = new FormControl(ReadingTypeSequentialOptions.AUTO);

  options: string;

  constructor(public dialogRef: MatDialogRef<ReadingTypeSequentialOptionsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string) { }

  ngOnInit(): void {

    if (this.data) {
      this.options = this.data;
    } else {
      this.options = '';
    }
    if (this.options === ReadingTypeSequentialOptions.AUTO) {
      this.readerTypeSequentialOptionsForm.setValue(ReadingTypeSequentialOptions.AUTO);
    } else if (this.options === ReadingTypeSequentialOptions.MANUAL ) {
      this.readerTypeSequentialOptionsForm.setValue( ReadingTypeSequentialOptions.MANUAL);
    }
  }


  okClick() {
    const options = this.readerTypeSequentialOptionsForm.value;
    this.dialogRef.close(options);
  }

}
