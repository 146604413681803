import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class RefreshAPIService {

  private refreshAPISource = new BehaviorSubject<any>(null);
  refreshAPIData = this.refreshAPISource.asObservable();

  whichPage: string | null = null;

  constructor() {
  }

  onRefreshAPIs(refreshMode: boolean, url: string) {
    const data = {
      refreshMode: refreshMode,
      componentUrl: url
    }
    //delete cache
    this.refreshAPISource.next(data);
  }

  clearRefreshAPIData(): void {
    this.whichPage = null;
    this.refreshAPISource.next(null);
  }
}
