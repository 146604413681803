import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StudySequenceLabelService, EndpointService, ReadingConfigFlexibleService } from '../../../_services';

@Component({
  selector: 'app-email-distribution-list',
  templateUrl: './email-distribution-list.component.html',
  styleUrls: ['./email-distribution-list.component.css']
})
export class EmailDistributionListComponent implements OnInit {

  @Input()
  public emailList: any[] = [];

  @Input()
  public studyId: any;

  @Output('emailListChange')
  emailListEmitter: EventEmitter<object> = new EventEmitter<object>();

  modalities: any[] = [];
  endpoints: any[] = [];

  emailConfig: any = {
    email: '',
    qc: false,
    modalities: [],
    readings: []
  };

  isEmailValid = true;

  constructor(private studySequenceLabelService: StudySequenceLabelService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    private endpointService: EndpointService) { }

  ngOnInit() {
    this.studySequenceLabelService.getStudyModalitiesByStudyId(this.studyId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.modalities = response.data;
          this.endpointService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                let allEndpoints = response.data;
                this.readingConfigFlexibleService.getByStudyId(this.studyId).subscribe(
                  (response: any) => {
                    if (response.responseCode === 200) {
                      let readingConfigFlexibleList = response.data;
                      if (readingConfigFlexibleList) {
                        readingConfigFlexibleList.forEach(readingConfigFlexible => {
                          if (this.endpoints.filter(endpt => endpt.id === readingConfigFlexible.endpointId).length === 0) {
                            this.endpoints.push(allEndpoints.filter(endpoint => endpoint.id === readingConfigFlexible.endpointId)[0]);
                          }
                        });
                      }
                      this.syncEmailList();
                      this.initEmailConfig();
                    } else {
                      console.log("ERROR " + response.responseCode + " Message: " + response.responseMessage);
                    }
                  }
                );
              } else {
                console.log("ERROR " + response.responseCode + " Message: " + response.responseMessage);
              }
            }
          );
        } else {
          console.log("ERROR " + response.responseCode + " Message: " + response.responseMessage);
        }
      }
    );
  }

  createEmailDistribution() {
    this.validateEmail();
    if (this.isEmailValid) {
        if (!this.emailList) {
            this.emailList = [];
        }
        this.emailList.push(this.emailConfig);
        this.emailListEmitter.emit(this.emailList);
        this.initEmailConfig();
    }
  }

  deleteEmailDistribution(emailConfig) {
    this.emailList = this.emailList.filter(emailDistribution => emailDistribution.email !== emailConfig.email);
    this.emailListEmitter.emit(this.emailList);
    this.initEmailConfig();
  }

  initEmailConfig() {
    this.emailConfig = {
      email: '',
      qc: false,
      modalities: [],
      readings: []
    };
    this.modalities.forEach(modality => {
      this.emailConfig.modalities.push({ name: modality.name, value: false });
    });
    this.endpoints.forEach(endpoint => {
      this.emailConfig.readings.push({ name: endpoint.name, value: false });
    });
  }

  syncEmailList() {
    if (this.emailList) {
      this.emailList.forEach(emailConfig => {
        this.modalities.forEach(modality => {
          if (emailConfig.modalities.filter(ecModality => ecModality.name === modality.name).length === 0) {
            emailConfig.modalities.push({ name: modality.name, value: false });
          }
        });
        let updatedReadings = [];
        this.endpoints.forEach(endpoint => {
          if (emailConfig.readings.filter(ecEndpoint => ecEndpoint.name === endpoint.name).length === 0) {
            updatedReadings.push({ name: endpoint.name, value: false });
          } else if (emailConfig.readings.filter(ecEndpoint => ecEndpoint.name === endpoint.name).length === 1) {
            updatedReadings.push(emailConfig.readings.filter(ecEndpoint => ecEndpoint.name === endpoint.name)[0]);
          }
        });
        emailConfig.readings = updatedReadings;
      });
      this.emailListEmitter.emit(this.emailList);
    }
  }

  checkboxSelectionChange() {
    this.emailListEmitter.emit(this.emailList);
  }

  validateEmail() {
      this.isEmailValid = !!this.emailConfig.email;
  }

}
