import { SeriesService } from './../../../../_services/series.service';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ImagingProjectService } from 'src/app/_services/imaging-project.service';
import { PatientService } from '../../../../_services/patient.service';
import { SiteConfigService } from '../../../../_services/site-config.service';
import { DataUploadService } from '../../../../_services/data-upload.service';
import { QualityControlService } from '../../../../_services/quality-control.service';
import { MessageDialogComponent } from '../../../../components/controls/message-dialog/message-dialog.component';

import * as _moment from 'moment';
import * as lodash from 'lodash';
import { ViewerCoreLtComponent } from '../../../Viewer/viewer-core-lt/viewer-core-lt.component';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from '../../../../core/data-management/actions/projects.action';
import { ViewerAuthenticationService } from 'src/app/_services/interfaces/viewer/viewer-authentication-service';
import { StudyUserService, StudySequenceLabelService } from 'src/app/_services';
import { Utils } from 'src/app/_services/leadtools/lead-tools-utils';
import { forkJoin, Observable } from 'rxjs';
import {SequenceLabelModel} from 'src/app/_models/ImagingProject/sequence-label-model';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {formatDate} from "@angular/common";

const moment = _moment;

@Component({
  selector: 'app-overall-study-finder',
  templateUrl: './overall-study-finder.component.html',
  styleUrls: ['./overall-study-finder.component.css']
})
export class OverallStudyFinderComponent implements OnInit, AfterViewInit {
  @ViewChild('viewer', { read: ViewContainerRef }) viewer: ViewContainerRef;
  @ViewChild('viewerLT', { read: ViewContainerRef }) viewerLT: ViewContainerRef;
  @ViewChild('tablecontiner', { read: ElementRef }) tablecontiner: ElementRef;
  @ViewChild('viewercontiner', { read: ElementRef }) viewercontiner: ElementRef;

  private _ = lodash;
  newLTViewer: any = null;
  isActiveLTViewer = false;

  readingSeriesDetails = [];
  activeSeriesDetails = [];
  availableSequenceLabels: SequenceLabelModel[] = [];

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material'
  };

  openedNumberOfImages = 0;

  visitedSeriesCounter = 0;
  study: any;

  dataSource: MatTableDataSource<unknown>;

  selectedPatient = null;
  patients: any;
  patientsDataSource: MatTableDataSource<any>;
  patientTableDisplayedColumns: string[] = ['patientCode', 'siteCode', 'modalities'];
  patientFinderExpanded = false;

  showModalSpinnerLoadingData = 0;
  @ViewChild(MatSort) patientSort: MatSort;

  viewerData = null;
  viewerDataIsReady = false;
  viewerDataIsLoading = false;

  debOpenViewer = lodash.debounce(seriesId => {
    if (this.viewerDataIsReady) {
      this.openViewer(seriesId);
    }
  }, 500);

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private studySequenceLabelService: StudySequenceLabelService,
    private patientService: PatientService,
    private siteConfigService: SiteConfigService,
    private dataUploadService: DataUploadService,
    private qualityControlService: QualityControlService,
    private imagingProjectService: ImagingProjectService,
    private toastyService: ToastyService,
    private authenticationService: ViewerAuthenticationService,
    private studyUserService: StudyUserService,
    public dialog: MatDialog,
    private store: Store,
    private utils: Utils,
    private cdr: ChangeDetectorRef,
    private seriesService: SeriesService
  ) {

  }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Study Finder'));

    setTimeout(() => {
      this.preOpenLTViewer();
    }, 100);

    // ----------------------------------------------------------------------------
    const project = JSON.parse(localStorage.getItem('project'));
    this.imagingProjectService.getStudy(project.id).subscribe(({data}) => {
      this.study = data;
    });


    // show spinner when load data
    this.showModalSpinnerLoadingData++;

    const configs = this.siteConfigService.getSiteConfigsByStudyIdForUpload(project.id);
    const visits = this.qualityControlService.getQCVisitsByStudyId(project.id);

    forkJoin({ configs, visits }).subscribe(response => {
      this.patients = [];
      const siteConfigsData = response.configs.data;
      const qcVisits = response.visits.data;
      const qcVisitsFiltered = [];
      // filter locked qcVisits or locked qc modalities
      qcVisits.forEach(visit => {
        const lockedModalities = visit.modalities.filter(modality => modality.lockFlag === true);
        if (visit.lockFlag === true || lockedModalities.length > 0) {
          qcVisitsFiltered.push(visit);
        }
      });
      siteConfigsData.sort((a, b) => (a.id > b.id) ? 1 : -1);
      siteConfigsData.forEach(siteConfig => {
        if (siteConfig.patients != null) {
          // sort patients of the site
          siteConfig.patients = siteConfig.patients.sort((a, b) => (a.patientCreated < b.patientCreated) ? 1 : -1);
          siteConfig.patients.forEach(patient => {
            patient.siteCode = siteConfig.siteCode;
            patient.modalities = null;

            // add patients only if data present in msqc
            const patientQcVisits = qcVisitsFiltered.filter(visit => visit.patientId === patient.id);

            if (patientQcVisits.length > 0) {
              // add locked modalities as list
              const allModalities = [];
              patientQcVisits.forEach(pqv => {
                if (pqv.modalities != null) {
                  pqv.modalities.forEach(pqvModality => {
                    if (pqvModality.lockFlag === true) {
                      allModalities.push(pqvModality.modalityName);
                    }
                  });
                }
              });
              patient.modalities = Array.from(new Set(allModalities));
              this.patients.push(patient);
            }

          });
        }
      });
      this.patientsDataSource = new MatTableDataSource<any>(this.patients);
      this.patientsDataSource.filterPredicate = this.patientTableFilter();
      this.patientsDataSource.sortingDataAccessor = (item, property) => {
        return item[property];
      };
      this.patientsDataSource.sort = this.patientSort;
      this.showModalSpinnerLoadingData = 0;
    });
  }

  ngAfterViewInit() {

  }

  patientTableFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function (data, filter): boolean {
      let ans;
      filter = filter.toLowerCase();
      if (data.modalities != null) {
        ans = data.patientCode.toLowerCase().indexOf(filter) !== -1
          || data.siteCode.toString().toLowerCase().indexOf(filter) !== -1
          || data.modalities.toString().toLowerCase().indexOf(filter) !== -1;
      } else {
        ans = data.patientCode.toLowerCase().indexOf(filter) !== -1
          || data.siteCode.toString().toLowerCase().indexOf(filter) !== -1;
      }
      return ans;
    };
    return filterFunction;
  }

  onSelectPatient(patient) {
    if (!lodash.isEmpty(this.newLTViewer.instance.seriesOpened)) {
      this.openSelectPatientDialog();
    }
    this.selectPatient(patient);
  }

  openSelectPatientDialog() {
    const message = 'Do you want to close the current images?';
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '210px',
      width: '570px',
      disableClose: true,
      data: {
        title: 'You are changing a patient',
        message: message,
        showOk: true,
        showCancel: true,
        textOK: 'YES',
        textCancel: 'NO',
        html: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === 'ok') {
        this.newLTViewer.instance.removeAllCells();
      }
    });
  }

  selectPatient(patient) {
    this.selectedPatient = patient;
    this.updatePatientSeries(this.selectedPatient);
  }

  applyPatientFilter(filterValue: string) {
    if (this.patientsDataSource != null) {
      this.patientsDataSource.filter = filterValue.trim().toLowerCase();
      if (this.patientsDataSource.paginator) {
        this.patientsDataSource.paginator.firstPage();
      }
    }
  }


  updatePatientSeries(selectedPatient) {
    // show spinner
    this.showModalSpinnerLoadingData++;
    return this.patientService.getByIdWithVisitConfig(selectedPatient.id).subscribe(patientResp => {
      const seriesServices = [];
      this.selectedPatient = patientResp['data'];
      this.selectedPatient['siteCode'] = selectedPatient['siteCode'];
      this.selectedPatient.visitConfigs.forEach(vc => {
        seriesServices.push(this.seriesService.getSeriesByVisitConfigId(vc.id))
      });
      forkJoin(seriesServices).subscribe((seriesResponse: any[]) => {
        if (seriesResponse[0].responseCode === 200) {
        this.selectedPatient.visitConfigs.forEach((vc, index) => {
          vc['series'] = seriesResponse[index].data
        });
        this.setSeriesColors();
        this.qualityControlService.getQCVisitsByPatientId(this.study.id, this.selectedPatient.id).subscribe(qcPatientVisitsResp => {
          const qcPatientVisitsData = qcPatientVisitsResp['data'];
          // filter locked series and modalities
          // get preview links for locked series (series in locked modalities)
          let seriesIds = [];

          this.dataUploadService.getUploadGeneralInfoByPatientId(this.study.id, this.selectedPatient.id).subscribe(patientUploadsResp => {
            // add last upload time ( update time from msupload)
            const patientUploadsData = patientUploadsResp['data'];
            patientUploadsData.map(upload => {
              upload.createdLong = +moment(upload.created).format('x');
            });

            this.selectedPatient.visitConfigs.forEach(visitConfig => {
              const qcVisit = qcPatientVisitsData.find(qcv => qcv.visitConfigId === visitConfig.id);
              const visitConfigUploads = patientUploadsData.filter(pud => pud.visitConfigId === visitConfig.id);
              if (visitConfigUploads.length > 0) {
                // get latest
                visitConfigUploads.sort((a, b) => (a.createdLong < b.createdLong) ? 1 : -1);
                visitConfig.lastUploadTime = moment(visitConfigUploads[0].created).format('DD/MM/YYYY HH:mm:ss');
              }
              if (qcVisit != null) {
                visitConfig.lockFlag = qcVisit.lockFlag;
                const visitQcSeries = [];
                if (qcVisit.modalities != null) {
                  qcVisit.modalities.forEach(qcModality => {
                    if (qcModality.lockFlag === true) {
                      if (qcModality.series != null) {
                        qcModality.series.forEach(qcSeries => {
                          visitQcSeries.push(qcSeries);
                        });
                      }
                    }
                  });
                }

                visitConfig['collapseSeries'] = false;

                if (visitConfig.series != null) {
                  visitConfig.series.forEach(series => {
                    // get links for all series without filtration
                    seriesIds.push(series.id);
                    series.scanDate = series.scanDate ? series.scanDate : '';
                    series['scanDateFormatted'] = series.scanDate ? formatDate(series.scanDate, 'dd/MM/yyyy HH:mm:ss', 'en-GB', 'UTC') : '';
                    // set lock flag for series inside locked modalities
                    const qcSer = visitQcSeries.filter(qcs => qcs.seriesId === series.id);
                    if (qcSer.length > 0) {
                      series.qcLockFlag = true;
                    }
                  });
                  const lockedSeries = visitConfig.series.filter(se => se.qcLockFlag === true);
                  if (lockedSeries.length > 0) {
                    visitConfig.containLockedSeries = true;
                  }
                }
              }
            });

            seriesIds = Array.from(new Set(seriesIds));

            this.studySequenceLabelService.getStudySeriesBySeriesIds(seriesIds).subscribe(resp => {
              if (resp.responseCode === 200) {
                if (resp.data) {
                  this.readingSeriesDetails = resp.data;
                  this.readingSeriesDetails.forEach(sd => {
                    this.imagingProjectService.getVisitFromSeriesID(sd.id).subscribe((visit_rsp: any) => {
                      const visit = visit_rsp.data;
                      if (visit != null) {
                        sd.seriesVisitName = visit.visitName;
                        localStorage.setItem('readingSeriesDetails', JSON.stringify(this.readingSeriesDetails));
                      }
                    });
                  });
                  this.activeSeriesDetails = this.readingSeriesDetails;
                  this.availableSequenceLabels = this.availableSequenceLabels
                    .filter(a => this.activeSeriesDetails.find(r => r.label === a.name));
                }
              } else {
                this.showErrorMessage(resp);
                return;
              }
            });

            this.viewerData = null;
            const seriesIdsAndStudyId = seriesIds.map((s) => ({ seriesId: s, studyId: this.study.id }));
            this.imagingProjectService.getSeriesPreviewBySeriesIds(seriesIdsAndStudyId).subscribe(previewSeriesResp => {
              const previewSeriesData = previewSeriesResp['data'];
              const viewerDataResults: Array<Observable<any>> = [];
              this.selectedPatient.visitConfigs.forEach(visitConfig => {
                visitConfig.series.forEach(s => {
                  const preview = previewSeriesData.filter(p => p.serialId === s.id);
                  if (preview != null) {
                    s.seriesPreviewUrl = preview[0]['url'];
                  } else {
                    s.seriesPreviewUrl = null;
                  }
                  // Get general viewer data
                  s.seriesId = s.id;
                  s.seriesInstanceUID = s.seriesUID;
                  s.timepoint = visitConfig.visitName;

                  const info = { selectedPage: 'StudyFinder' };
                  viewerDataResults.push(this.utils.initViewerData(s, this, info));
                });
              });

              if (viewerDataResults.length) {
                this.viewerDataIsLoading = true;
                forkJoin(viewerDataResults).subscribe(() => {
                  this.viewerDataIsLoading = false;
                  this.viewerDataIsReady = true;
                  this.openViewer('-1');
                  this._updateViewerToolbar();
                  this.newLTViewer.instance.createCell(null);
                });
              }

              // hide spinner
              this.showModalSpinnerLoadingData = 0;
            });



          });
        });
      }
      });

    });
  }

  collapseSeries(visitConfig) {
    if (visitConfig['collapseSeries'] != null) {
      visitConfig['collapseSeries'] = !visitConfig['collapseSeries'];
    }
  }

  /* --------------------------- viewer methods --------------------------- */

  openNewLTViewer(seriesInstanceUIDs) {
    const compFactory = this.componentFactoryResolver.resolveComponentFactory(
      ViewerCoreLtComponent
    );

    this.newLTViewer = this.viewerLT.createComponent(compFactory);
    this.newLTViewer.instance.seriesIds = seriesInstanceUIDs;
    this.newLTViewer.instance.preventClose = true;
    this.newLTViewer.instance.shouldShowDICOM = false;
    this.newLTViewer.instance.onCellReady.subscribe(() => {
      this._updateViewerToolbar();
    });
    this.newLTViewer.instance.onClose.subscribe(({ serID, shouldCloseViewer }) => {
      this._updateViewerToolbar();
      this.closeLTViewer(serID, shouldCloseViewer);
    });
    this.newLTViewer.instance.onRemoveAllCells.subscribe(seriesIds => {
      this._updateViewerToolbar();
      seriesIds.forEach(id => this.removeHighl(id));
    });
  }

  private _updateViewerToolbar() {
    const viewerActionsAllowed =
      this.newLTViewer && this.newLTViewer.instance.viewer && this.newLTViewer.instance.viewer.layout.get_items().get_count() > 0;
    if (viewerActionsAllowed) {
      this.newLTViewer.instance.viewerToolbar.setReadOnly(false);
    } else {
      this.newLTViewer.instance.viewerToolbar.setReadOnly(true);
      this.newLTViewer.instance.viewerToolbar.setItemProperty('ViewerLayout', 'readonly', false, true);
    }
    try {
      const imageViewerDiv = document.getElementsByClassName('visitName-label') as HTMLCollectionOf<Element>;
      Array.from(imageViewerDiv).forEach((element: any) => {
        const visitColor = this.getVisitColor(element.innerText);
        element.style.color = visitColor;
      });
    } catch (error) {
      console.log('==> error... ', error);
    }
    this.cdr.detectChanges();
  }

  preOpenLTViewer() {
    this.openNewLTViewer(null);
  }

  openLTViewer(seriesInstanceUIDs: string[] = []) {
    if (this.newLTViewer) {
      this.newLTViewer.instance.openNewSer(seriesInstanceUIDs, this.viewerData);
      this.openedNumberOfImages = this.newLTViewer.instance.getNumberOfImages();
      this._updateViewerToolbar();
      return;
    }

    this.openNewLTViewer(seriesInstanceUIDs);
  }

  closeLTViewer(serID, shouldCloseViewer) {
    this.removeHighl(serID);

    if (shouldCloseViewer) {
      this.viewercontiner.nativeElement.style.display = 'none';
    }
  }

  removeHighl(serID) {
    const $serThumbn = document.querySelector(`.tid${serID}`);
    $serThumbn?.classList.remove('viewer-highlighted');
    setTimeout(() => {
      $serThumbn?.classList.remove('viewer-highlighted');
    }, 10);
  }

  highlightOnClick(serID) {
    const $serThumbnail = document.querySelector(`.tid${serID}`);
    $serThumbnail?.classList.add('viewer-highlighted');
  }

  openViewer(seriesId = null) {
    setTimeout(function () {
      window.dispatchEvent(new Event('resize'));
    }, 500);

    this.highlightOnClick(seriesId);
    this.openLTViewer([seriesId]);

    const toolbarExcludeItems = lodash.get(this.viewerData, 'toolbarExcludeItems', []);
    if (toolbarExcludeItems.length > 0) {
      lodash.each(toolbarExcludeItems, i => {
        this.newLTViewer.instance.viewerToolbar.setItemProperty(i, 'disabled', true);
      });
    }
    this.viewercontiner.nativeElement.style.display = '';
  }

  dragstart(ev, seriesId, seriesInstanceUID?) {
    // console.log("dragstart...");
    // console.log("seriesId:", seriesId);
    // console.log("seriesInstanceUID:", seriesInstanceUID);
    ev.dataTransfer.setData('seriesId', seriesId);
    ev.dataTransfer.setData('seriesInstanceUID', seriesInstanceUID);
  }

  getVisitColor(visitName) {
    try {
      let visitColor = 'black';
      const visitConfigs: any[] = this.selectedPatient.visitConfigs;
      if (visitConfigs.length > 0 && visitConfigs[0].color) {
        visitConfigs.forEach(visitItem => {
          if (visitItem.visitName === visitName) {
            visitColor = visitItem.color;
          }
        });
      }
      return visitColor;
    } catch (error) {
      console.log('==> error... ', error);
    }
  }

  setSeriesColors() {
    const visitConfigs: any[] = this.selectedPatient.visitConfigs;
    if (visitConfigs.length > 0 && !visitConfigs[0].color) {
      let colors = ['MediumVioletRed', 'OrangeRed', 'ForestGreen',
        'DarkTurquoise', 'Brown', 'DodgerBlue', 'OliveDrab', 'Navy', 'LimeGreen', 'SlateBlue'];
      const sort = Math.random() - 0.5;
      colors = colors.sort(() => sort);
      const visits = [];
      const visitColor = {};
      let counter = 0;
      for (let i = 0; i < visitConfigs.length; i++) {
        let t = visitConfigs[i].timepoint;
        if (t === undefined) {
          t = visitConfigs[i].visitName;
        }
        if (t !== undefined) {
          if (!visits.includes(t)) {
            visits.push(t);
            visitColor[t] = colors[counter];
            visitConfigs[i].color = visitColor[t];
            counter++;
            if (counter > colors.length) {
              counter = 0;
            }
          } else {
            visitConfigs[i].color = visitColor[t];
          }
        }
      }
      this.selectedPatient.visitConfigs = visitConfigs;
    }
  }

  showErrorMessage(response: any): void {
    this.showModalSpinnerLoadingData++;
    this.toastOptions.title = 'ERROR ' + response['responseCode'];
    this.toastOptions.msg = response['responseMessage'];
    this.toastyService.error(this.toastOptions);
  }
}
