import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ReadingFormScoring, ReadingFormScoringComponent} from '../reading-form-scoring.component';
import {BasicResponse} from '../../../core/interfaces/basic-response';
import {Observable} from 'rxjs';
import {Store} from '@ngxs/store';
import {FormBuilder} from '@angular/forms';
import {ReadingLuganoService} from '../../../_services/reading-lugano.service';
import {StudyService} from '../../../_services';
import {LuganoReadingModel} from '../../../_models/Lugano/lugano-reading-model';
import {BasicReadingVisit} from '../../../_models/basic-reading';
import {sortDateRanged} from '../../../util/time-unit/time-unit-util';
import {areAllVisitsLocked, hasReadingNotLockedVisits, setVisitsLockAvailability} from '../helper/visit-helper';
import {modifyTabHeader, updateTabHeaderWidth} from '../helper/mat-tab-helper';
import {PatientService} from '../../../_services/patient.service';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';

@Component({
  selector: 'app-lugano',
  templateUrl: './lugano.component.html',
  styleUrls: ['./lugano.component.css']
})
export class LuganoComponent extends ReadingFormScoringComponent implements OnInit, AfterViewInit, ReadingFormScoring {

  constructor(private store: Store,
              private fb: FormBuilder,
              private studyService: StudyService,
              private readingService: ReadingLuganoService,
              private patientService: PatientService) {
    super();
  }

  studyId: number;
  currentReading: LuganoReadingModel;
  visits: BasicReadingVisit[] = [];

  ngOnInit(): void {
    this.store.dispatch(new SetPageHeaderTitle('Lugano Scoring Form'));
    this.studyId = +JSON.parse(localStorage.getItem('project')).id;
    this.subscribeCurrentReading();
  }

  ngAfterViewInit(): void {
    modifyTabHeader('Visit selector');
  }

  subscribeCurrentReading(): void {
    this.currentReadingSubject.subscribe(reading => {
      this.currentReading = reading as LuganoReadingModel;
      this.visits = setVisitsLockAvailability(sortDateRanged<BasicReadingVisit>(this.currentReading.visits));
      this.loadPatientData();
      this.switchSubmitBtnDisabledSubject.next(!hasReadingNotLockedVisits(this.currentReading));
    });
  }

  loadPatientData(): void {
    this.patientService.getByIdWithVisitConfig(this.currentReading.patientId).subscribe((response: any) => {
      console.log(response);
      response.data.visitConfigs.forEach(vc => {
        const visit = this.visits.find(v => v.visitConfigId === vc.id);
        if (!!visit) {
          visit.timepoint = vc.visitName;
        }
      });
    });
  }

  getEndpointName(): string {
    return 'Lugano';
  }

  clearForm(): void {
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingService.getReadingByStudyIdAndReaderId(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingService.startReading(studyId, readingId);
  }

  submitReading(studyId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    const submitData = {
      id: this.currentReading.id,
      timeSpent: data.spentSeconds
    };
    return this.readingService.completeReading(studyId, submitData);
  }

  updateReading(studyId: number, readingId: number, data: { spentSeconds: number }): Observable<BasicResponse<any>> {
    return this.readingService.updateReading(studyId, readingId, data.spentSeconds);
  }

  lockVisit(visitId: number): void {
    this.readingService.lockTimepoint(this.studyId, visitId, true).subscribe(response => {
      this.visits.find(v => v.id === visitId).locked = response.data.locked;
      this.visits = setVisitsLockAvailability(this.visits);
      this.switchSubmitBtnDisabledSubject.next(!areAllVisitsLocked(this.visits));
    });
  }

  changeTabHeaderWidth() {
    updateTabHeaderWidth();
  }
}
