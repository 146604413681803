import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';

export interface WbmriImgCompareItemData {
  timepoint: string;
  factor: string;
  image1?: string;
  image2?: string;
}

const FACTOR_MAPPING = {
  FEET_SYN_BME_STI: 'Feet (SYN, BME, STI)',
  HANDS_SYN_BME: 'Hands (BME, STI)',
  KNEES_SYN_BME_STI: 'Knees (SYN, BME, STI)',
  PELVIS_SYN_BME_STI: 'Pelvis (SYN, BME, STI)',
  SHOULDERS_SYN_BME_STI: 'Shoulders (SYN, BME, STI)'
};

@Component({
  selector: 'app-image-comparison-dialog',
  templateUrl: './wbmri-img-compare-dialog.component.html',
  styleUrls: ['./wbmri-img-compare-dialog.component.css']
})
export class WbmriImgCompareDialogComponent implements OnInit {

  readonly factorMapping = FACTOR_MAPPING;

  readerColumns = ['visit', 'reader1', 'reader2'];

  dataSource = new MatTableDataSource<WbmriImgCompareItemData>();

  ngOnInit(): void {
    this.dataSource.data = JSON.parse(localStorage.getItem('reading_wbmri_data'));
  }
}
