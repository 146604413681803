import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {FormControl} from '@angular/forms';

import * as _moment from 'moment';
import {Moment} from 'moment';
import {MatDatepicker} from '@angular/material/datepicker';

const moment = _moment;


const DATE_FORMAT_MM = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-month-datapicker',
  templateUrl: './month-datapicker.component.html',
  styleUrls: ['./month-datapicker.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT_MM}],
})
export class MonthDatapickerComponent implements OnInit {

  @Input()
  public placeholder: Function;

  @Input()
  public canBeGreaterThanCurrent?: boolean = true;

  @Input()
  public wrongDateMessage?: string = '';

  @Input()
  public value: Date;

  @Output()
  public valueChange = new EventEmitter();

  @Input('disabled') disabled: boolean;

  date = new FormControl(moment(this.value));
  dateInFuture = false;

  constructor() {
  }

  ngOnInit() {
  }


  chosenYearHandler(normalizedYear: Moment, datepicker: MatDatepicker<Moment>) {
    let ctrlValue;
    if (this.date.value) {
      ctrlValue = moment(this.date.value);
    } else {
      ctrlValue = moment(new Date());
    }
    if ((normalizedYear.year() > new Date().getFullYear())) {
      this.dateInFuture = true;
      this.date.setValue(null);
      this.valueChange.emit(null);
      datepicker.close();
      return;
    }
    this.dateInFuture = false;
    ctrlValue.year(normalizedYear.year()).month(ctrlValue.month()).date(1);
    this.date.setValue(ctrlValue);
    this.valueChange.emit(ctrlValue.toDate());
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = moment(this.date.value);
    ctrlValue.year(ctrlValue.year()).month(normalizedMonth.month()).date(1);
    if ((ctrlValue.year() > new Date().getFullYear() || (ctrlValue.month() > new Date().getMonth() && ctrlValue.year() == new Date().getFullYear())) && !this.canBeGreaterThanCurrent) {
      this.dateInFuture = true;
      this.date.setValue(null);
      this.valueChange.emit(null);
      datepicker.close();
    } else {
      this.dateInFuture = false;
      this.date.setValue(ctrlValue);
      this.valueChange.emit(ctrlValue.toDate());
      datepicker.close();
    }

  }

  EndDateChangeHandler(event) {
    this.date.setValue(null);
    this.valueChange.emit(null);
  }
}
