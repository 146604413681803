import {ReadingDashboardService, ReadingModel} from '../../../_services/reading-dashboard.service';
import {ImagingProjectService, UserService, VisitService} from '../../../_services';
import {ActivatedRoute, Router} from '@angular/router';
import {PatientService} from '../../../_services/patient.service';
import {BATCH_STATUS_CODES} from '../../../_statuses/batch-status';
import {READING_STATUS_CODES} from '../../../_statuses/reading-status';
import {ResourceService} from '../../../_services/resource.service';
import {BatchLogicService} from '../../../_services/batch-logic.service';
import {BatchModel, ReadingBatchModel} from '../../../_models/BatchLogic/batch-model';
import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ReadingType} from '../../../core/constants/reading-type';
import {forkJoin} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {MimeType} from '../../../core/constants/mime-type';
import {MatDialog} from '@angular/material/dialog';
import {ReadingDetailsDialogComponent, ReadingDetailsDialogData} from '../reading-details-dialog/reading-details-dialog.component';
import {ReadingStatusModel} from '../../../_models';
import { DashboardMode } from '../operation-dashboard-component/operation-dashboard.component';
import * as moment from 'moment';
import { ReaderType } from 'src/app/core/constants/reader-type';
import {READING_VERSION_MAPPING} from "../../../_helpers/reading-version";

interface ReadingTableModel {
  id?: number;
  created?: Date;
  patient?: number;
  visit_orig?: string[];
  visit_blind?: string[];
  endpoint?: string;
  readingVersion?: string;
  reader?: string;
  readerId: number;
  readingType?: string;
  adjudication?: string;
  turnaround?: string;
  remaining?: string;
  timeSpent?: number;
  status?: ReadingStatusModel;
  batch?: string;
  reports?: Report[];
  batchId?: number;
  configId?: number;
  patientId?: number;
  finishTime?: Date;
}

interface Report {
  link: string;
  mimeType: MimeType;
  description: string;
}

@Component({
  selector: 'app-readings-list',
  templateUrl: './readings-list.component.html',
  styleUrls: ['./readings-list.component.css']
})
export class ReadingsListComponent implements OnInit {

  sponsorDashboardReadingList = ['MERGER', 'JSW', 'Incidental Findings', 'DXA', 'GENANT'];
  // from RANO endpoint we need to see reports for only sponsors which have following reading versions
  sponsorDashboardReadingVersionList = ['ReadingVersion-EfficacyMRANO'];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  readonly DashboardMode = DashboardMode;
  @Input() dashboardMode: DashboardMode;

  displayedColumns: string[] = [
    'row-num',
    'id',
    'created',
    "finishTime",
    'patient',
    'visit_orig', // visits - id visit -> baseline etc.
    'visit_blind', // visits - timepoints commas separated
    'endpoint',
    'reader',
    'adjudication',
    'timeSpent',
    'status', // status reading
    'batch', // status batch
    'details',
    'report' // resources + mimetype + link (resolve endpoint)
  ];

  projectId: number;
  readings: ReadingModel[] = [];
  origReadings: ReadingModel[];
  readingList: ReadingTableModel[] = [];
  readingsDataSource: MatTableDataSource<ReadingTableModel> = new MatTableDataSource<ReadingTableModel>();
  showModalSpinner = false;
  currentSort: Sort;

  private studyId: number;

  constructor(private readingDashboardService: ReadingDashboardService,
              private imagingProjectService: ImagingProjectService,
              private patientService: PatientService,
              private visitService: VisitService,
              private userService: UserService,
              private resourceService: ResourceService,
              private batchLogicService: BatchLogicService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit() {
    console.log('ReadingsListComponent ngOnInit ');
    this.studyId = parseInt(this.route.snapshot.params.projectId, 10);
    if (this.dashboardMode === DashboardMode.SPONSOR) {
      this.displayedColumns =  ['row-num', 'created', 'patient','visit_orig', 'endpoint','status', 'report']
    }
    this.loadReadings();
  }

  loadReadings() {
    this.loadReadingsWithSort(null);
  }

  loadReadingsWithSort(sort: Sort) {
    this.showModalSpinner = true;
    this.readingDashboardService.getReadingsForDashboard(this.studyId)
      .pipe(
        map(readingsResponse => {
          this.readingList = [];
          this.origReadings = this.dashboardMode === DashboardMode.SPONSOR 
            ? readingsResponse.data.filter(reading => this.sponsorDashboardReadingList.includes(reading.endpointName) 
              || this.sponsorDashboardReadingVersionList.includes(reading.readingVersion))
            : readingsResponse.data;
          return readingsResponse;
        }),
        mergeMap(readingsResponse => {
          return forkJoin([
            this.imagingProjectService.getVisitConfigsByIdsBrief(this.getVisitConfigIds(readingsResponse.data)),
            this.patientService.getPatientsByStudyIdBrief(this.studyId),
            this.userService.getByListIds(this.getReaderIds(readingsResponse.data)),
            this.batchLogicService.getReadingWithBatch(this.studyId)
          ]);
        })
      )
      .subscribe(([visitConfigs, patients, readers, batches]) => {
        for (const reading of this.origReadings) {
          this.readingList.push(this.createTableReadingItem(reading, readers.data, patients.data, visitConfigs.data, batches.data));
        }

        if (sort) {
          this.sortData(sort);
        } else {
          this.populateReadingsTable();
        }
        this.showModalSpinner = false;
      });
  }

  createTableReadingItem(reading: ReadingModel, readers, patients, visitConfigs, readingBatches: ReadingBatchModel[]): ReadingTableModel {
    const readingItem: ReadingTableModel = {
      id: reading.readingId,
      created: reading.created,
      visit_orig: [],
      visit_blind: [],
      endpoint: reading.endpointName,
      readingVersion: reading.readingVersion,
      adjudication: reading.isAdjudication ? 'Yes' : 'No',
      timeSpent: reading.timeSpent * 1000,
      status: {name: READING_STATUS_CODES.getDescription(reading.readingStatusCode), code: reading.readingStatusCode},
      batch: BATCH_STATUS_CODES.getDescription(reading.batchStatusCode),
      readerId: reading.readerId,
      configId: reading.configId,
      patientId: reading.patientId,
      finishTime: reading.finishTime,
    };

    this.resolveReadingType(reading.readingType, readingItem, reading.readerType);

    if (READING_STATUS_CODES.isComplete(reading.readingStatusCode) || READING_STATUS_CODES.isInProgress(reading.readingStatusCode) || READING_STATUS_CODES.isAdjudicationCheckPending(reading.readingStatusCode)) {
      this.applyReadingResources(reading.endpointName, reading.resources, readingItem);
    }

    readingItem.patient = patients.find(p => p.id === reading.patientId).patientCode;

    let reader;
    if (readers) {
      reader = readers.find(r => r.id === reading.readerId);
    }
    if (!reader || !reader.firstName && !reader.lastName) {
      readingItem.reader = 'SYSTEM';
    } else {
      readingItem.reader = reader.firstName + ' ' + reader.lastName;
    }

    readingItem.batchId = readingBatches.find(b => b.readingId === reading.readingId)?.batchId;

    this.loadReadingVisits(readingItem, reading.visits, visitConfigs);

    return readingItem;
  }

  loadReadingVisits(readingItem, visits, visitConfigs): void {
    for (const visit of visits) {
      const currentVisitConfig = visitConfigs.find(v => v.id === visit.visitConfigId);
      if (currentVisitConfig && currentVisitConfig.visitBlindName) {
        readingItem.visit_blind = [...readingItem.visit_blind, currentVisitConfig.visitBlindName];
      } else {
        readingItem.visit_blind = [...readingItem.visit_blind, ''];
      }
      if (currentVisitConfig) {
        if (currentVisitConfig.visitOriginalName) {
          readingItem.visit_orig = [...readingItem.visit_orig, currentVisitConfig.visitOriginalName];
        } else {
          readingItem.visit_orig = [...readingItem.visit_orig, ''];
        }
      } else {
        readingItem.visit_orig = [...readingItem.visit_orig, ''];
      }
    }
    if (readingItem.endpoint === 'MERGER') {
      const compositionReading =
        this.origReadings.find(reading => reading.patientId === readingItem.patientId
          && reading.readingVersion === 'GenantEligibilityDXA')
      readingItem.readingVersion = '';
      if (compositionReading) {
        const visitConfig = visitConfigs.find(v => v.id === compositionReading.visits[0]?.visitConfigId);
        readingItem.visit_orig = [visitConfig.visitName];
        readingItem.visit_blind = [visitConfig.visitBlindName];
      }
    }
  }

  sortVisits(visitConfigs) {
    visitConfigs.forEach(visit => {
      let visitDate = moment(visit.create);
      if (visit.durationTimeUnit == 'D') {
        visitDate = moment(visit.create).add(visit.visit.durationTimeValue, 'd');
      } else if (visit.durationTimeUnit == 'W') {
        visitDate = moment(visit.create).add(visit.visit.durationTimeValue, 'w');
      } else if (visit.durationTimeUnit == 'M') {
        visitDate = moment(visit.create).add(visit.visit.durationTimeValue, 'M');
      }
      visit.visitDate = visitDate;
    });
    visitConfigs.sort((a, b) => {
      if (a.visitDate < b.visitDate) {
        return -1;
      }
      if (a.visitDate > b.visitDate) {
        return 1;
      }
      return 0;
    });
    let baselineIndex = visitConfigs.indexOf(visitConfigs.find(this.isBaseline));
    visitConfigs.unshift(visitConfigs.find(this.isBaseline));
    visitConfigs.splice(baselineIndex + 1, 1);
    return visitConfigs;
  }
  isBaseline(visitConfigs) {
    return visitConfigs.visit.baseline === true;
  }

  resolveReadingType(readingType, readingItem, readerType): void {



    switch (readingType) {
      case ReadingType.BASIC_READING: {
        readingItem.readingType = ReadingType.BASIC;
        break;
      }
      case ReadingType.ADJUDICATION_READING: {
        // Type should be CONSENSUS if reader is CONSENSUS_READER
        // https://imageanalysis.atlassian.net/browse/DYN6-8706
         if (readerType === ReaderType.CONSENSUS_READER) {
           readingItem.readingType = ReadingType.CONSENSUS;
           return;
         }
        readingItem.readingType = ReadingType.ADJUDICATION;
        break;
      }
      case ReadingType.LEVEL1_READING: {
        readingItem.readingType = ReadingType.LEVEL1;
        break;
      }
        case ReadingType.MODERATION_READING: {
            readingItem.readingType = ReadingType.MODERATION;
            break;
        }
    }
  }

  applyReadingResources(endpointName, resources, readingItem: ReadingTableModel): void {
    if (resources) {
      const reports: Report[] = [];
      for (const resource of resources) {
        reports.push({
          link: this.resourceService.buildResourceLink(endpointName, resource.resourceLink),
          mimeType: resource.mimeType,
          description: resource?.description
        });
      }
      readingItem.reports = [...reports];
    }
  }

  getVisitConfigIds(readings: ReadingModel[]): Set<any> {
    const visitConfigIdsSet = new Set<number>();
    for (const reading of readings) {
      for (const visit of reading.visits) {
        visitConfigIdsSet.add(visit.visitConfigId);
      }
    }
    return visitConfigIdsSet;
  }

  getPatientIds(readings: ReadingModel[]): Set<any> {
    const patientIdsSet = new Set<number>();
    for (const reading of readings) {
      patientIdsSet.add(reading.patientId);
    }
    return patientIdsSet;
  }

  getReaderIds(readings: ReadingModel[]): Set<any> {
    const readerIdsSet = new Set<number>();
    for (const reading of readings) {
      readerIdsSet.add(reading.readerId);
    }
    return readerIdsSet;
  }

  populateReadingsTable() {
    this.readingsDataSource.data = this.readingList;
    this.readingsDataSource.paginator = this.paginator;
    this.readingsDataSource.sort = this.sort;
  }

  applyFilter(filterValue: string): void {
    if (this.readingsDataSource !== undefined) {
      this.readingsDataSource.filter = filterValue.trim().toLowerCase();
      if (this.readingsDataSource.paginator) {
        this.readingsDataSource.paginator.firstPage();
      }
    }
  }

  onReportClick(report: Report) {
    this.resourceService.getResource(report.link).subscribe(resp => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(resp.body);
      a.href = objectUrl;
      a.target = '_blank';
      a.download = resp.headers.get('content-disposition').split('; ')[1].replace('filename=', '');
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  onBatchClick(batchId: number): void {
    if (batchId) {
      this.router.navigate([`/operationdashboard/batch/${this.studyId}/${batchId}`]);
    }
  }

  openReadingDetailsDialog(reading: ReadingTableModel): void {

    const data: ReadingDetailsDialogData = {
      readingId: reading.id,
      readingStatus: {name: reading.status.name, code: reading.status.code},
      endpoint: reading.endpoint,
      studyId: this.studyId,
      readerId: reading.readerId
    };

    const dialogRef = this.dialog.open(ReadingDetailsDialogComponent, {
      width: '450px',
      data: data
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result === 200) {
        const that = this;
        this.showModalSpinner = true;
        setTimeout(function() { that.showModalSpinner = false; that.loadReadingsWithSort(that.currentSort); }, 5000);
      }
    });
  }

  sortData(sort: Sort) {
    const data = this.readingList.slice();
    if (!sort.active || sort.direction === '') {
      sort.direction = 'asc';
      sort.active = 'created';
    }
    this.currentSort = sort;

    this.readingList = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'created': return compare(a.created, b.created, isAsc);
        case 'adjudication': return compare(a.readingType, b.readingType, isAsc);
        case 'timeSpent': return compare(a.timeSpent, b.timeSpent, isAsc);
        case 'status': return compare(a.status.name, b.status.name, isAsc);
        default: return 0;
      }
    });
    this.populateReadingsTable();
  }

  getReadingVersionText(row) {
    const readingVersion =  (row?.readingVersion !== null && row?.readingVersion !== undefined) ? row.readingVersion : '';

    if (READING_VERSION_MAPPING[readingVersion]) {
      return READING_VERSION_MAPPING[readingVersion];
    }

    return readingVersion;
  }
}

function compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
  return (a < b ? -1 : a > b ? 1 : 0) * (isAsc ? 1 : -1);
}
