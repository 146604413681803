import get from 'lodash/get';

export class ViewerConfigModel {
  private readonly data = {};

  constructor(data: any) {
    this.data = data;
  }

  get toolbarConfig(): any {
    return get(this.data, 'toolbar', {});
  }

  get viewerConfig(): any {
    return get(this.data, 'viewer', {});
  }
};

// Model which is used to hold and sent auditable data to LT
export class ViewerAuditableContext {

    userId?: number;
    userName: string;
    studyId: number;
    patientId: number;
    visitConfigId: number;
};