import {Component, OnInit} from '@angular/core';
import {PsamrisReadingModel} from '../../../../../_models';
import {MatTableDataSource} from '@angular/material/table';

interface VisitModel {
  visit?: string;
  image1?: string;
  image2?: string;
}

@Component({
  selector: 'app-image-comparison-dialog',
  templateUrl: './image-comparison-dialog.component.html',
  styleUrls: ['./image-comparison-dialog.component.css']
})
export class ImageComparisonDialogComponent implements OnInit {

  reading1: PsamrisReadingModel;
  reading2: PsamrisReadingModel;

  readerColumns = ['visit', 'reader1', 'reader2'];
  synovitisDatasource = new MatTableDataSource<VisitModel>();
  periarticularDatasource = new MatTableDataSource<VisitModel>();
  bonesDatasource = new MatTableDataSource<VisitModel>();

  ngOnInit(): void {
    this.reading1 = JSON.parse(localStorage.getItem('reading1'));
    this.reading2 = JSON.parse(localStorage.getItem('reading2'));
    this.init();
  }

  init() {
    // BONE
    const boneVisitModels = new Array<VisitModel>();
    for (const visit of this.reading1.visits) {
      const visitModel: VisitModel = {
        visit: visit.timepoint,
        image1: this.getZitelabImagePath(this.reading1, 'BONE', visit.timepoint),
        image2: this.getZitelabImagePath(this.reading2, 'BONE', visit.timepoint),
      };
      boneVisitModels.push(visitModel);
    }
    this.bonesDatasource.data = boneVisitModels;

    // PERIARTICULAROSTEITIS
    const paVisitModels = new Array<VisitModel>();
    for (const visit of this.reading1.visits) {
      const visitModel: VisitModel = {
        visit: visit.timepoint,
        image1: this.getZitelabImagePath(this.reading1, 'PERIARTICULAROSTEITIS', visit.timepoint),
        image2: this.getZitelabImagePath(this.reading2, 'PERIARTICULAROSTEITIS', visit.timepoint),
      };
      paVisitModels.push(visitModel);
    }
    this.periarticularDatasource.data = paVisitModels;

    // SYNOVITIS
    const synovisitModels = new Array<VisitModel>();
    for (const visit of this.reading1.visits) {
      const visitModel: VisitModel = {
        visit: visit.timepoint,
        image1: this.getZitelabImagePath(this.reading1, 'SYNOVITIS', visit.timepoint),
        image2: this.getZitelabImagePath(this.reading2, 'SYNOVITIS', visit.timepoint),
      };
      synovisitModels.push(visitModel);
    }
    this.synovitisDatasource.data = synovisitModels;
  }

  getZitelabImagePath(reading: PsamrisReadingModel, feature: string, timepoint: string): string {
    let result = 'ops';

    let selectedVisit = null;
    for (const v of reading.visits) {
      if (v.timepoint === timepoint) {
        selectedVisit = v;
        break;
      }
    }

    if (selectedVisit) {
      const images = selectedVisit.ziteLabImages;
      if (images) {
        for (const image of images) {
          if (image.factor === feature) {
            result = image.storageLink;
            break;
          }
        }
      }
    }

    return result;
  }
}
