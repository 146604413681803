import {Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { JSW_OARSI_ReadingModel } from 'src/app/_models/JSW/jsw-reading-model';
import { JSW_OARSI_VisitModel } from 'src/app/_models/JSW/jsw-visit-model';


export interface TableRow {
  visitName: string;
  id: number;
  JsnRKL: number | "NE";
  JsnRKM: number | "NE";
  JsnLKL: number | "NE";
  JsnLKM: number | "NE";
  JSNControl: FormControl;
  jsnRightKneeLateralMmControl:  FormControl;
  jsnRightKneeMedialMmControl: FormControl;
  jsnLeftKneeLateralMmControl: FormControl;
  jsnLeftKneeMedialMmControl: FormControl;
  JSNComment: string;
  JSNCommentControl: FormControl;

  ScRKL: string;
  ScRKM: string;
  ScLKL: string;
  ScLKM: string;
  SCControl: FormControl;
  scRightKneeLateralControl: FormControl;
  scRightKneeMedialControl: FormControl;
  scLeftKneeLateralControl: FormControl;
  scLeftKneeMedialControl: FormControl;
  SCComment: string;
  SCCommentControl: FormControl;

  AtRK: string;
  AtLK: string;
  ATControl: FormControl;
  AtRightKneeControl: FormControl;
  AtLeftKneeControl: FormControl;
  ATComment: string;
  ATCommentControl: FormControl;

  OfRKL: number | "NE";
  OfRKM: number | "NE";
  OfLKL: number | "NE";
  OfLKM: number | "NE";
  OFControl: FormControl;
  ofRightKneeLateralControl:  FormControl;
  ofRightKneeMedialControl: FormControl;
  ofLeftKneeLateralControl: FormControl;
  ofLeftKneeMedialControl: FormControl;
  OFComment: string;
  OFCommentControl: FormControl; 

  OtRKL: number | "NE";
  OtRKM: number | "NE";
  OtLKL: number | "NE";
  OtLKM: number | "NE";
  OTControl: FormControl;
  otRightKneeLateralControl:  FormControl;
  otRightKneeMedialControl: FormControl;
  otLeftKneeLateralControl: FormControl;
  otLeftKneeMedialControl: FormControl;
  OTComment: string;
  OTCommentControl: FormControl; 
}

@Component({
  selector: 'app-oasri-reading-knee-form',
  templateUrl: './oasri-reading-knee-form.component.html',
  styleUrls: ['./oasri-reading-knee-form.component.css']
})
export class OasriReadingKneeFormComponent implements OnInit {
  private visits: JSW_OARSI_VisitModel[];
  public kneeFormArray: FormArray;

  @Input() set setVisits(visits: JSW_OARSI_VisitModel[]) {
    this.visits = visits;
  }

  @Input() set setKneeFormArray(kneeFormArray: FormArray) {
    this.kneeFormArray = kneeFormArray;
    this.tryInitForm();
  }
  @Input() reject: boolean;

  @Input()
  isApproveNeeded = true;

  rows: TableRow[];

  constructor() { }

  static generateKneeFormArray(reading: JSW_OARSI_ReadingModel, fb: FormBuilder): FormArray {
    const kneeFormArray = fb.array([]);
    reading.visits.filter(visit => !visit.noUploads).forEach(visit => {
      const kneeForm = OasriReadingKneeFormComponent.generateKneeFormForVisit(visit, fb);
      kneeFormArray.push(kneeForm);
    });
    return kneeFormArray;
  }

  private static generateKneeFormForVisit(visit: JSW_OARSI_VisitModel, fb: FormBuilder): FormGroup {
    return fb.group({
      visitId: new FormControl(visit.id, [Validators.required]),
      JsnRKL: new FormControl(visit.scoring?.jsnAgreed, []),
      JsnRKM: new FormControl(visit.scoring?.jsnAgreed, []),
      JsnLKL: new FormControl(visit.scoring?.jsnAgreed, []),
      JsnLKM: new FormControl(visit.scoring?.jsnAgreed, []),
      ScRKL: new FormControl(visit.scoring?.sclerosisAgreed, []),
      ScRKM: new FormControl(visit.scoring?.sclerosisAgreed, []),
      ScLKL: new FormControl(visit.scoring?.sclerosisAgreed, []),
      ScLKM: new FormControl(visit.scoring?.sclerosisAgreed, []),
      AtRK: new FormControl(visit.scoring?.attritionAgreed, []),
      AtLK: new FormControl(visit.scoring?.attritionAgreed, []),
      OfRKL: new FormControl(visit.scoring?.osteophytosisFemurAgreed, []),
      OfRKM: new FormControl(visit.scoring?.osteophytosisFemurAgreed, []),
      OfLKL: new FormControl(visit.scoring?.osteophytosisFemurAgreed, []),
      OfLKM: new FormControl(visit.scoring?.osteophytosisFemurAgreed, []),
      OtRKL: new FormControl(visit.scoring?.osteophytosisTibiaAgreed, []),
      OtRKM: new FormControl(visit.scoring?.osteophytosisTibiaAgreed, []),
      OtLKL: new FormControl(visit.scoring?.osteophytosisTibiaAgreed, []),
      OtLKM: new FormControl(visit.scoring?.osteophytosisTibiaAgreed, []),
      JSNComment: new FormControl(visit.scoring?.jsnComment, []),
      SCComment: new FormControl(visit.scoring?.sclerosisComment, []),
      ATComment: new FormControl(visit.scoring?.attritionComment, []),
      OFComment: new FormControl(visit.scoring?.osteophytosisFemurComment, []),
      OTComment: new FormControl(visit.scoring?.osteophytosisTibiaComment, []),
      jsnRightKneeLateralMm: new FormControl(this.parseStringToFloat(visit.scoring?.jsnRightKneeLateralMm), [Validators.required]),
      jsnRightKneeMedialMm: new FormControl(this.parseStringToFloat(visit.scoring?.jsnRightKneeMedialMm), [Validators.required]),
      jsnLeftKneeLateralMm: new FormControl(this.parseStringToFloat(visit.scoring?.jsnLeftKneeLateralMm), [Validators.required]),
      jsnLeftKneeMedialMm: new FormControl(this.parseStringToFloat(visit.scoring?.jsnLeftKneeMedialMm), [Validators.required]),
      sclerosisRightKneeLateral: new FormControl(visit.scoring?.sclerosisRightKneeLateral, [Validators.required]),
      sclerosisRightKneeMedial: new FormControl(visit.scoring?.sclerosisRightKneeMedial, [Validators.required]),
      sclerosisLeftKneeLateral: new FormControl(visit.scoring?.sclerosisLeftKneeLateral, [Validators.required]),
      sclerosisLeftKneeMedial: new FormControl(visit.scoring?.sclerosisLeftKneeMedial, [Validators.required]),
      attritionRightKnee: new FormControl(visit.scoring?.attritionRightKnee, [Validators.required]),
      attritionLeftKnee: new FormControl(visit.scoring?.attritionLeftKnee, [Validators.required]),
      osteophytosisFemurRightKneeLateral: new FormControl(this.parseStringToFloat(visit.scoring?.osteophytosisFemurRightKneeLateral), [Validators.required]),
      osteophytosisFemurRightKneeMedial: new FormControl(this.parseStringToFloat(visit.scoring?.osteophytosisFemurRightKneeMedial), [Validators.required]),
      osteophytosisFemurLeftKneeLateral: new FormControl(this.parseStringToFloat(visit.scoring?.osteophytosisFemurLeftKneeLateral), [Validators.required]),
      osteophytosisFemurLeftKneeMedial: new FormControl(this.parseStringToFloat(visit.scoring?.osteophytosisFemurLeftKneeMedial), [Validators.required]),
      osteophytosisTibiaRightKneeLateral: new FormControl(this.parseStringToFloat(visit.scoring?.osteophytosisTibiaRightKneeLateral), [Validators.required]),
      osteophytosisTibiaRightKneeMedial: new FormControl(this.parseStringToFloat(visit.scoring?.osteophytosisTibiaRightKneeMedial), [Validators.required]),
      osteophytosisTibiaLeftKneeLateral: new FormControl(this.parseStringToFloat(visit.scoring?.osteophytosisTibiaLeftKneeLateral), [Validators.required]),
      osteophytosisTibiaLeftKneeMedial: new FormControl(this.parseStringToFloat(visit.scoring?.osteophytosisTibiaLeftKneeMedial), [Validators.required]),
    });
  }

  private static parseStringToFloat(measurement: string): number | "NE" {
    const number = parseFloat(measurement);
    return isNaN(number) ? "NE" : number;
  }

  ngOnInit(): void {
    this.tryInitForm();
  }

  switchCheckbox(checkBoxControl: FormControl) {
    if (this.reject) return;
    checkBoxControl.setValue(!checkBoxControl.value);
  }

  private tryInitForm() {
    if (!!this.visits && !!this.kneeFormArray) {
      this.initForm();
    }
  }

  private initForm() {
    this.initTableRows();
  }

  private initTableRows() {
    const rows: TableRow[] = [];
    this.visits.filter(visit => !visit.noUploads).forEach(visit => {
      rows.push(this.visitToTableRow(visit));
    });
    this.rows = rows;
  }

  private visitToTableRow(visit: JSW_OARSI_VisitModel): TableRow {
    const currentVisitForm = this.kneeFormArray.controls.find(vF => vF.value.visitId === visit.id);
    return {
      id: visit.id,
      visitName: visit.timepoint,
      JsnRKL: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.jsnRightKneeLateralMm),
      JsnRKM: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.jsnRightKneeMedialMm),
      JsnLKL: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.jsnLeftKneeLateralMm),
      JsnLKM: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.jsnLeftKneeMedialMm),
      ScRKL: visit.scoring?.sclerosisRightKneeLateral,
      ScRKM: visit.scoring?.sclerosisRightKneeMedial,
      ScLKL: visit.scoring?.sclerosisLeftKneeLateral,
      ScLKM: visit.scoring?.sclerosisLeftKneeMedial,
      AtRK: visit.scoring?.attritionRightKnee,
      AtLK: visit.scoring?.attritionLeftKnee,
      OfRKL: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.osteophytosisFemurRightKneeLateral),
      OfRKM: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.osteophytosisFemurRightKneeMedial),
      OfLKL: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.osteophytosisFemurLeftKneeLateral),
      OfLKM: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.osteophytosisFemurLeftKneeMedial),
      OtRKL: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.osteophytosisTibiaRightKneeLateral),
      OtRKM: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.osteophytosisTibiaRightKneeMedial),
      OtLKL: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.osteophytosisTibiaLeftKneeLateral),
      OtLKM: OasriReadingKneeFormComponent.parseStringToFloat(visit.scoring?.osteophytosisTibiaLeftKneeMedial),
      JSNComment: String(visit.scoring?.jsnComment),
      SCComment: String(visit.scoring?.sclerosisComment),
      ATComment: String(visit.scoring?.attritionComment),
      OFComment: String(visit.scoring?.osteophytosisFemurComment),
      OTComment: String(visit.scoring?.osteophytosisTibiaComment),
      JSNControl: currentVisitForm['controls'].JsnRKL,
      SCControl: currentVisitForm['controls'].ScRKL,
      ATControl: currentVisitForm['controls'].AtRK,
      OFControl: currentVisitForm['controls'].OfRKL,
      OTControl: currentVisitForm['controls'].OtRKL,
      JSNCommentControl: currentVisitForm['controls'].JSNComment,
      SCCommentControl: currentVisitForm['controls'].SCComment,
      ATCommentControl: currentVisitForm['controls'].ATComment,
      OFCommentControl: currentVisitForm['controls'].OFComment,
      OTCommentControl: currentVisitForm['controls'].OTComment, 
      jsnRightKneeLateralMmControl: currentVisitForm['controls'].jsnRightKneeLateralMm,
      jsnRightKneeMedialMmControl: currentVisitForm['controls'].jsnRightKneeMedialMm,
      jsnLeftKneeLateralMmControl: currentVisitForm['controls'].jsnLeftKneeLateralMm,
      jsnLeftKneeMedialMmControl: currentVisitForm['controls'].jsnLeftKneeMedialMm,
      scRightKneeLateralControl: currentVisitForm['controls'].sclerosisRightKneeLateral,
      scRightKneeMedialControl: currentVisitForm['controls'].sclerosisRightKneeMedial,
      scLeftKneeLateralControl: currentVisitForm['controls'].sclerosisLeftKneeLateral,
      scLeftKneeMedialControl: currentVisitForm['controls'].sclerosisLeftKneeMedial,
      AtRightKneeControl: currentVisitForm['controls'].attritionRightKnee,
      AtLeftKneeControl: currentVisitForm['controls'].attritionLeftKnee,
      ofRightKneeLateralControl: currentVisitForm['controls'].osteophytosisFemurRightKneeLateral,
      ofRightKneeMedialControl: currentVisitForm['controls'].osteophytosisFemurRightKneeMedial,
      ofLeftKneeLateralControl: currentVisitForm['controls'].osteophytosisFemurLeftKneeLateral,
      ofLeftKneeMedialControl: currentVisitForm['controls'].osteophytosisFemurLeftKneeMedial,
      otRightKneeLateralControl: currentVisitForm['controls'].osteophytosisTibiaRightKneeLateral,
      otRightKneeMedialControl: currentVisitForm['controls'].osteophytosisTibiaRightKneeMedial,
      otLeftKneeLateralControl: currentVisitForm['controls'].osteophytosisTibiaLeftKneeLateral,
      otLeftKneeMedialControl: currentVisitForm['controls'].osteophytosisTibiaLeftKneeMedial,
    } as TableRow;
  }
}
