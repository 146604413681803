import { Injectable, OnDestroy } from '@angular/core';
import { applyMixins } from '../_helpers/apply-mixins';
import { BroadcastChannelMixin } from '../_mixins/broadcast-channel.mixin';
import { BroadcastChannelData, BroadcastChannelService } from './broadcast-channel.service';

@Injectable({
    providedIn: 'root'
})
export class ReadingFormService implements OnDestroy {
    
    get broadcastChannelName(): string {
        return this.broadcastChannelService?.channelName;
    }

    ngOnDestroy(): void {
        this.shutdownBroadcastChannel();
    }
}
export interface ReadingFormService extends BroadcastChannelMixin { }
applyMixins(ReadingFormService, [BroadcastChannelMixin]);