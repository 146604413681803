import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ReadingConfigFlexibleService, StudyService} from '../../../../_services';
import {MatTableDataSource} from '@angular/material/table';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {FormMode} from '../../../../core/constants/form-mode';
import {MessageDialogComponent} from '../../../../components/controls/message-dialog/message-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, Sort} from '@angular/material/sort';
import { isDefined } from '@angular/compiler/src/util';
import { ImagingProjectReadingBasicComponent } from '../../imaging-project-reading-basic.component';
import { FlexibleConfig } from 'src/app/core/interfaces/flexible-config';
import {READING_VERSION_MAPPING} from "../../../../_helpers/reading-version";


@Component({
  selector: 'app-imaging-project-reading-list',
  templateUrl: './imaging-project-reading-list.component.html',
  styleUrls: ['./imaging-project-reading-list.component.css']
})
export class ImagingProjectReadingListComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  dataSource: any;
  displayedColumns: string[] = ['id', 'projectCode', 'endpointName', 'modalityType', 'readingLevel', 'status', 'readingVersion', 'actionEdit', 'actionDelete'];
  // readingConfigs = [];

  study: any;

  showConfiguration = false;
  configMode: FormMode;
  selectedEditConfig = null;

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  @Input() visitsQuantity: number;
  @Input() unscheduledAllowed: boolean;
  @Input() readingConfigs: any[];
  @Output() readingConfigsChange = new EventEmitter<Array<FlexibleConfig>>();

  status = ReadingConfigFlexibleStatus;

  @ViewChild('readingPaginator') readingPaginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('deactivateButton') deactivateButton: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private studyService: StudyService,
    private toastyService: ToastyService,
    private readingConfigFlexibleService: ReadingConfigFlexibleService,
    public dialog: MatDialog
  ) {
    super();
  }

  ngOnInit() {
    this.showConfiguration = false;
    this.configMode = null;
    this.selectedEditConfig = null;

    const studyId = parseInt(this.route.snapshot.params.id, 10);
    this.studyService.getById(studyId).subscribe(studyResp => {
      this.study = studyResp['data'];
      this.refreshReadingConfigs();
    });
  }

  refreshReadingConfigs() {
    if (this.study != null) {
      this.readingConfigFlexibleService.getByStudyId(this.study.id).subscribe(response => {
        if (response['data'] != null) {
          this.readingConfigs = response['data'];
          this.initDataSource(this.readingConfigs.sort(this.compareConfigs));
          this.readingConfigsChange.emit(response['data']);
        }
      });
    }
  }

  compareConfigs(c1: any, c2: any): number {
    if (c1.status.name === 'Deleted' && c2.status.name === 'Active') {
      return 1;
    } else {
      if (c1.status.name === 'Active' && c2.status.name === 'Active') {
        if (c1.created < c2.created) {
          return 1;
        }
      }
      return -1;
    }
  }

  showReadingConfiguration() {
    this.showConfiguration = true;
    this.configMode = FormMode.NEW;
    this.selectedEditConfig = null;
  }

  editReading(readingConfig) {
    this.showConfiguration = true;
    this.configMode = FormMode.EDIT;
    this.selectedEditConfig = readingConfig;
  }

  deleteReading(readingConfig) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      height: '200px',
      width: '600px',
      disableClose: true,
      data: {
        title: 'Confirmation',
        message: 'Do you want to deactivate current config?',
        showOk: true,
        showCancel: true,
        html: false
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.unfocusDeactivateButton();
      if (result === 'ok') {
        this.readingConfigFlexibleService.delete(readingConfig.id).subscribe(deleteResp => {
          if (deleteResp['responseCode'] === 200) {
            this.toastOptions.title = 'Reading deactivation success';
            this.toastOptions.msg = 'Configuration is deactivated. Existing readings continue workflow. System will not create new reading paradigm iteration in terms of this config';
            this.toastyService.success(this.toastOptions);
            this.refreshReadingConfigs();
          } else {
            this.toastOptions.title = 'ERROR ' + deleteResp['responseCode'];
            this.toastOptions.msg = deleteResp['responseMessage'];
            this.toastyService.error(this.toastOptions);
          }
        });
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  onSubmitOrCancelConfigEvent() {
    this.showConfiguration = false;
    this.refreshReadingConfigs();
  }

  sortData(sort: Sort) {
    const data = this.readingConfigs.slice();
    if (!sort.active || sort.direction === '') {
      this.initDataSource(data.sort(this.compareConfigs));
      return;
    }

    this.readingConfigs = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'endpointName':
          return compare(a.config.endpoint.name, b.config.endpoint.name, isAsc);
        case 'modalityType':
          return compare(a.config.modalities[0].name, b.config.modalities[0].name, isAsc);
        case 'readingLevel':
          return compare(a.config.readingLevel.lowerCase, b.config.readingLevel.lowerCase, isAsc);
        case 'status':
          return compare(a.status.name, b.status.name, isAsc);
        case 'readingVersion':
          return compare(a.config.endpointReadingVersion, b.config.endpointReadingVersion, isAsc);
        case 'actionEdit':
          return compare(a.status.name, b.status.name, isAsc);
        case 'actionDelete':
          return compare(a.status.name, b.status.name, isAsc);
        default:
          return 0;
      }
    });
    this.initDataSource(this.readingConfigs);
  }

  private initDataSource(data: any[]) {
    this.dataSource = new MatTableDataSource<any>(data);
    this.dataSource.paginator = this.readingPaginator;
    this.dataSource.sort = this.sort;
  }

  private unfocusDeactivateButton() {
    this.deactivateButton['_elementRef'].nativeElement
      .classList.remove('cdk-program-focused');
  }

  getReadingVersionText(config) {
    const readingVersion =  (config?.readingVersion !== null && config?.readingVersion !== undefined) ? config.readingVersion : '';

    if (READING_VERSION_MAPPING[readingVersion]) {
      return READING_VERSION_MAPPING[readingVersion];
    }

    return readingVersion;
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

enum ReadingConfigFlexibleStatus {
  Active = 'Active',
  Deleted = 'Deleted'
}
