import { Component, Input } from '@angular/core';

@Component({
  selector: 'viewer-chart',
  templateUrl: './viewer-chart.component.html',
  styleUrls: ['./viewer-chart.component.css']
})

export class ViewerChart {
  @Input() graphData: any;
  @Input() graphTitle: any;
  @Input() graphType: any;
  @Input() graphOptions: any;
  @Input() graphWidth: any;

  title = this.graphTitle;
  data = this.graphData;
  type = this.graphType;
  options = this.graphOptions;
  width = this.graphWidth;
}