import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-unscheduled-visit-dialog',
  templateUrl: './unscheduled-visit-dialog.component.html',
  styleUrls: ['./unscheduled-visit-dialog.component.css']
})
export class UnscheduledVisitDialogComponent implements OnInit {

  visitTypeControl: FormControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<UnscheduledVisitDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: UnscheduledVisitDialogData) {
  }

  ngOnInit(): void {
    if (this.data.unscheduledAllowed || !this.data.hasET) {
      this.visitTypeControl.setValidators(Validators.required);
    } else {
      this.visitTypeControl.disable();
    }
  }

  submit(): void {
    this.dialogRef.close(this.visitTypeControl.value);
  }

  close(): void {
    this.dialogRef.close();
  }

}

export interface UnscheduledVisitDialogData {
  unscheduledAllowed: boolean;
  hasET: boolean;
}
