import {Component, Input, OnInit} from '@angular/core';
import {UpdateDownloadProcess} from '../../../core/data-management/actions/app.action';
import {DownloadMonitoringItem} from '../download-monitoring.component';
import {Store} from '@ngxs/store';

@Component({
  selector: 'download-monitoring-item',
  templateUrl: './download-monitoring-item.component.html',
  styleUrls: ['./download-monitoring-item.component.css']
})
export class DownloadMonitoringItemComponent implements OnInit {

  @Input() download: DownloadMonitoringItem;

  constructor(private store: Store) { }

  ngOnInit(): void {
  }

  cancelRequest(item: DownloadMonitoringItem): void {
    item.subscriptions.every(subscription => subscription.unsubscribe());
    item.status = 'ARCHIVE_DOWNLOAD_FAILURE';
    this.store.dispatch(new UpdateDownloadProcess(item));
  }

}
