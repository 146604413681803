import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';

const SERVICE_API_URL = 'https://' + environment.MSDashboardStatVersion + 'msdashboardstat-dot-' + environment.apiUrl + '/readings';
// const SERVICE_API_URL = 'http://localhost:8080/api/readings';

const NO_METHOD = undefined;

export interface ReadingModel {
  id?: number;
  created?: Date;
  readingId?: number;
  configId?: number;
  studyId?: number;
  endpointName?: string;
  readingVersion?: string;
  patientId?: number;
  readerId?: number;
  readingStatusCode?: number;
  batchStatusCode?: number;
  isAdjudication?: boolean;
  timeSpent?: number;
  finishTime?: Date;
  resources?: any;
  visits?: any;
  readerType?: string;
  readingType?: string;
}

@Injectable({
  providedIn: 'root'
})
export class ReadingDashboardService {
  constructor(private http: HttpClient
  ) {
  }

  getReadings(filter: Map<string, string>): Observable<BasicResponse<ReadingModel[]>> {
    const params = {
      'studyId': filter.get('studyId')
    };
    return this.http.get<BasicResponse<ReadingModel[]>>(SERVICE_API_URL, {params: params});
  }

  getReadingsForDashboard(studyId: number): Observable<BasicResponse<ReadingModel[]>> {
    const url = `${SERVICE_API_URL}/for-dashboard`;
    return this.http.get<BasicResponse<ReadingModel[]>>(url, {params: {studyId: studyId.toString()}});
  }

  terminateReading(readingId: number, studyId: number, endpoint: string): Observable<BasicResponse<any>> {
    const body = {
      endpoint: endpoint
    };
    const url = `${SERVICE_API_URL}/${readingId}/terminate?studyId=${studyId}`;
    return this.http.post<BasicResponse<any>>(url, body);
  }

  assignReader(readingId: number, readerId: number, studyId: number, endpoint: string): Observable<BasicResponse<any>> {
    const body = {
      endpoint: endpoint,
      readerId: readerId
    };
    const url = `${SERVICE_API_URL}/${readingId}/assign-reader?studyId=${studyId}`;
    return this.http.post<BasicResponse<any>>(url, body);
  }

}
