import { Injectable, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';

import { ObjectRetrieveService } from '../interfaces/viewer/object-retrieve-service';
import { SeriesManagerService } from '../interfaces/viewer/series-manager.service';
import { AnnUserData, SopInstanceReference } from 'src/app/_models/viewer/DataModels';
import { ObjectStoreService } from '../interfaces/viewer/object-store-service';
import { QueryArchiveService } from '../interfaces/viewer/query-archive.service';
import { Utils } from './lead-tools-utils';
import { toInteger } from 'lodash';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { ROISettingsDialogComponent } from 'src/app/modules/Viewer/viewer-core-lt/roi-settings-dialog/roi-settings-dialog.component';
import { ROICopyDeleteDialogComponent } from 'src/app/modules/Viewer/viewer-core-lt/roi-copy-delete-dialog/roi-copy-delete-dialog.component';
import { ROIRow } from '../../_models/viewer/RoiRowModel';
import { forkJoin, Subject } from 'rxjs';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import { MatchedROIs, VoiROI } from '../interfaces/viewer/voi-roi';
import { EventService, EventNames } from './event.service';
import { AnnJswObject } from "./ann-jsw-object/ann-jsw-object";
import { ViewerToolbar } from "../interfaces/viewer/toolbar";

const ROOT_URL = environment.Leadtools.rootURL;
const SERVICES = environment.Leadtools.services;

@Injectable()
export class LeadToolsSeriesManagerService implements SeriesManagerService {

  private _series: any;
  private _ROIData: { [key: string]: Array<ROIRow> } = {};
  private _VOIData: { [key: string]: Array<ROIRow> } = {};
  private _activeCell: lt.Controls.Medical.Cell;
  private _seriesByFOR = {};

  private activeCellChangingObj = new Subject<any>();
  public activeCellChanging = this.activeCellChangingObj.asObservable();
  public silentPropertyChange = false;
  allowSavingActiveContainer = true;
  viewerToolbar: ViewerToolbar = ViewerToolbar.getInstance();

  toastOptions: ToastOptions = {
    title: "",
    showClose: true,
    timeout: 10000,
    theme: "material",
  };

  constructor(
    private objectStoreService: ObjectStoreService,
    private objectRetrieveService: ObjectRetrieveService,
    private queryArchiveService: QueryArchiveService,
    public dialog: MatDialog,
    private utils: Utils,
    private ngZone: NgZone,
    private toastyService: ToastyService,
    private eventService: EventService
  ) {
    this._series = {};
  }

  getSeriesByFOR(){
    return this._seriesByFOR;
  }

  getSeriesFromFOR(frameOfReferenceUID){
    if (this._seriesByFOR[frameOfReferenceUID])
      return this._seriesByFOR[frameOfReferenceUID];
    else
      return null;
  }

  addCellToSeriesByFOR(cell: lt.Controls.Medical.Cell){
    const frameOfReferenceUID = cell.get_frameOfReferenceUID();
    const cellId = cell.get_divID();

    if(this._seriesByFOR[frameOfReferenceUID]){
      this._seriesByFOR[frameOfReferenceUID].push(cellId);
      this._seriesByFOR[frameOfReferenceUID] = Array.from(new Set(this._seriesByFOR[frameOfReferenceUID]));
    } else {
      this._seriesByFOR[frameOfReferenceUID] = [cellId];
    }
    return this._seriesByFOR;
  }

  removeCellToSeriesByFOR(cell) {
    const frameOfReferenceUID = cell.get_frameOfReferenceUID();
    const cellId = cell.get_divID();

    if (this._seriesByFOR[frameOfReferenceUID]) {
      const index = this._seriesByFOR[frameOfReferenceUID].indexOf(cellId);
      if (index > -1) {
        this._seriesByFOR[frameOfReferenceUID].splice(index, 1);
      }
    }
  }

  setSeriesByFOR(value){
    this._seriesByFOR = value;
  }

  getSeriesCellById(id: string): any {
    for (const key in this._series) {
      if (this._series.hasOwnProperty(key)) {
        if (this._series[key].cells) {
          const length: number = this._series[key].cells.length;

          for (let index = 0; index < length; index++) {
            const cell: lt.Controls.Medical.Cell = this._series[key].cells[index];

            if (cell.get_divID() == id) {
              return cell;
            }
          }
        }
      }
    }
    return null;
  }

  setROIData(cell: lt.Controls.Medical.Cell, data: Array<ROIRow>) {
    this._ROIData[cell.get_divID()] = data.slice();
  }

  getROIData(cell: lt.Controls.Medical.Cell) {
    return this._ROIData[cell.get_divID()];
  }

  resetROIData(): void {
    this._ROIData = {};
  }

  setVOIData(cell: lt.Controls.Medical.Cell, data: Array<ROIRow>) {
    this._VOIData[cell.get_divID()] = data.slice();
  }

  getVOIData(cell: lt.Controls.Medical.Cell) {
    return this._VOIData[cell.get_divID()];
  }

  resetVOIData(): void {
    this._ROIData = {};
  }

  getInstances(seriesInstanceUID: string, id: string): any[] {
    if (this._series[seriesInstanceUID]) {
      if (this._series[seriesInstanceUID].instances && this._series[seriesInstanceUID].instances[id]) {
        return this._series[seriesInstanceUID].instances[id];
      }
    }
    return new Array<any>();
  }

  setInstances(seriesInstanceUID: string, id: string, instances: Array<any>, stackInstanceUID) {
    if (!this._series[seriesInstanceUID]) {
      this._series[seriesInstanceUID] = {};
    }

    if (!this._series[seriesInstanceUID].instances) {
      this._series[seriesInstanceUID].instances = {};
    }
    this._series[seriesInstanceUID].instances[id] = instances;
  }

  getCellFrame(seriesInstanceUID: string, id: string, sopInstanceUID: string, getNew?: boolean) {
    if (this._series[seriesInstanceUID]) {
      const sopMappings = this.get_sopMappings(seriesInstanceUID, id);
      const cell: lt.Controls.Medical.Cell = this.getSeriesCellById(id);

      getNew = getNew || false;
      if (sopMappings == null) {
        const cellFrame = new lt.Controls.Medical.Frame(cell);

        cell.get_frames().add(cellFrame);
        return cellFrame;
      } else if (sopInstanceUID in sopMappings) {
        return cell.get_frames().get_item(sopMappings[sopInstanceUID]);
      } else if (getNew) {
        const cellFrame = new lt.Controls.Medical.Frame(cell);

        cell.get_frames().add(cellFrame);
        return cellFrame;
      }
    }
    return null;
  }

  get_sopMappings(seriesInstanceUID: string, id: string) {
    if (this._series[seriesInstanceUID]) {
      if (this._series[seriesInstanceUID].sopMappings && this._series[seriesInstanceUID].sopMappings[id]) {
        return this._series[seriesInstanceUID].sopMappings[id];
      }
    }
    return null;
  }

  addSeriesCell(cell: any): void {
    const seriesInstanceUID = cell.get_seriesInstanceUID();
    if (!this._series[seriesInstanceUID]) {
      this._series[seriesInstanceUID] = {};
    }
    if (!this._series[seriesInstanceUID].cells) {
      this._series[seriesInstanceUID].cells = new Array();
    }
    this._series[seriesInstanceUID].cells.push(cell);
  }

  removeSeriesCell(seriesInstanceUID) {
    if (this._series[seriesInstanceUID]) {
      this._series[seriesInstanceUID].cells.forEach(cell => cell.dispose());
      this._series[seriesInstanceUID].cells = null;
      this._series[seriesInstanceUID].instances = null;
      this._series[seriesInstanceUID].loaders = null;
      this._series[seriesInstanceUID].info = null;
      delete this._series[seriesInstanceUID];
    }
  }

  setSeriesInfo(cell: any, value: any) {
    try {
      const seriesInstanceUID = cell.get_seriesInstanceUID();
      if (!this._series[seriesInstanceUID]) {
        return;
      }

      if (this._series[seriesInstanceUID].info) {
        const serID = [this._series[seriesInstanceUID].info.id];
        const arrOfIDs = [...serID.flat(2), cell.seriesId];
        this._series[seriesInstanceUID].info.id = [...new Set(arrOfIDs)];
        this._series[seriesInstanceUID].info.visits =
          this._series[seriesInstanceUID].info.visits ?
          this._series[seriesInstanceUID].info.visits : {};
        if(value.visits && value.visits[cell.seriesId]){
          this._series[seriesInstanceUID].info.visits[cell.seriesId] = value.visits[cell.seriesId];
        }
      } else if (this._series[seriesInstanceUID]) {
        this._series[seriesInstanceUID].info = value;
      }
    } catch (error) {
      console.log("error...", error);
    }
  }

  getSeriesInfo(cell: lt.Controls.Medical.Cell) {
    const seriesInstanceUID = cell ? cell.get_seriesInstanceUID() : null;
    if (seriesInstanceUID && this._series[seriesInstanceUID] && this._series[seriesInstanceUID].info) {
      return this._series[seriesInstanceUID].info;
    }
    return null;
  }

  getActiveSubCellIndex(cell: any): number {
    if (this._series[cell.seriesInstanceUID]) {
      let index = -1;
      const items = cell.get_selectedItems();

      if (items.get_count() > 0) {
        index = cell.get_imageViewer().get_items().indexOf(items.get_item(0));
      }

      if (index < 0) {
        index = 0;
      }

      return index;
    }
    return -1;
  }

  setThumbPreferences(renderers, skipnumber) {
    const bigThumbSize = 36 * 2;
    const smallThumbSize = 36;
    const locationThumbStyle = new lt.Annotations.Rendering.AnnEllipseThumbStyle();
    const locationThumbStyleJsw = new lt.Annotations.Rendering.AnnEllipseThumbStyle();
    const rotateCenterThumbStyle = new lt.Annotations.Rendering.AnnEllipseThumbStyle();
    const rotateGripperThumbStyle = new lt.Annotations.Rendering.AnnEllipseThumbStyle();
    // if (lt.LTHelper.device == lt.LTDevice.desktop) {
    //     locationThumbStyle.set_size(lt.LeadSizeD.create(smallThumbSize, smallThumbSize));
    // }
    // else {
    //     locationThumbStyle.set_size(lt.LeadSizeD.create(bigThumbSize, bigThumbSize));
    // }
    locationThumbStyle.set_size(lt.LeadSizeD.create(smallThumbSize, smallThumbSize));
    locationThumbStyle.set_stroke(lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('orange'), lt.LeadLengthD.create(1)));
    locationThumbStyle.set_fill(lt.Annotations.Engine.AnnSolidColorBrush.create('rgba(243, 167, 68, .3)'));

    locationThumbStyleJsw.size = lt.LeadSizeD.create(smallThumbSize, smallThumbSize);
    locationThumbStyleJsw.stroke = lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('red'), lt.LeadLengthD.create(5));
    locationThumbStyleJsw.fill = lt.Annotations.Engine.AnnSolidColorBrush.create('rgba(243, 167, 68, .3)');

    rotateCenterThumbStyle.set_size(lt.LeadSizeD.create(smallThumbSize * 1.5, smallThumbSize * 1.5));
    rotateCenterThumbStyle.set_stroke(lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('#e40cae'), lt.LeadLengthD.create(1)));
    rotateCenterThumbStyle.set_fill(lt.Annotations.Engine.AnnSolidColorBrush.create('rgba(221, 14, 214, 1)'));
    rotateGripperThumbStyle.set_size(lt.LeadSizeD.create(bigThumbSize * 1.5, bigThumbSize * 1.5));
    rotateGripperThumbStyle.set_stroke(lt.Annotations.Engine.AnnStroke.create(lt.Annotations.Engine.AnnSolidColorBrush.create('green'), lt.LeadLengthD.create(1)));
    rotateGripperThumbStyle.set_fill(lt.Annotations.Engine.AnnSolidColorBrush.create('rgba(70, 228, 12, 0.7)'));
    for (const $key2 in renderers) {
      const item = { key: $key2, value: renderers[$key2] };
      const renderer = item.value;
      if ($key2 === AnnJswObject.jswObjectId.toString()) {
        renderer.locationsThumbStyle = locationThumbStyleJsw;
      } else {
        renderer.set_locationsThumbStyle(locationThumbStyle);
      }
      renderer.set_rotateCenterThumbStyle(rotateCenterThumbStyle);
      renderer.set_rotateGripperThumbStyle(rotateGripperThumbStyle);
    }
  }

  enumerateFrames(cell: any, frameFunction: Function, cell3DFunction?: Function) {
    if (this._series[cell.seriesInstanceUID] && frameFunction) {
      const viewer = cell.viewer;
      let frames;
      let length;

      let cellIndex = 0;
      const cellCount = viewer.layout.items.count;

      for (cellIndex = 0; cellIndex < cellCount; cellIndex++) {
        cell = viewer.layout.items.get_item(cellIndex);
        if (cell instanceof lt.Controls.Medical.Cell3D) {
          if (cell3DFunction) {
            cell3DFunction(cell);
          }
          continue;
        }
        frames = cell.get_frames();
        length = frames.count;
        if (!cell.tickBoxes[0].checked && !cell.selected) {
          continue;
        }
        for (let index = 0; index < length; index++) {
          try {
            frameFunction(frames.item(index), index);
          } catch (err) {
          }
        }
      }
    }
  }

  getActiveCellFrame(cell = this.getActiveCell()): any {
    if (cell instanceof lt.Controls.Medical.Cell3D) {
      cell = (<lt.Controls.Medical.Cell3D>cell).referenceCell;
    }
    if (cell) {
      const index = this.getActiveSubCellIndex(cell);
      const subCell: lt.Controls.Medical.SubCell = <lt.Controls.Medical.SubCell>(cell.imageViewer.items.get_item(index));
      return <any>(subCell.attachedFrame);
    }
    return null;
  }

  getActiveCell(): lt.Controls.Medical.Cell {
    return this._activeCell;
  }

  setActiveCell(id: string, addToLayout = false): void {
    if (id == null) {
      this._activeCell = null;
    } else {
      const cell: lt.Controls.Medical.Cell = this.getSeriesCellById(id);
      if (cell !== null) {
        const previousCellID = this._activeCell ? this._activeCell.divID : null;

        this._activeCell = cell;
        this.activeCellChangingObj.next({previousCellID: previousCellID, activeCellID: cell.divID});

        cell.viewer.layout.selectedItems.clear();

        if (addToLayout) {
          cell.viewer.layout.items.add(cell);
        }
        cell.selected = true;


        const seriesInfo = this.getSeriesInfo(cell);
        this.manageJswTools(seriesInfo?.label);
      }
    }
  }

  private manageJswTools(seriesLabel: string) {
    const isHipVisible = ['Pelvis Xray'].includes(seriesLabel);
    const isKneeVisible = ['JSW Knee', 'Bilateral Knees X-Ray', 'Left Knee X-Ray', 'Right Knee X-Ray'].includes(seriesLabel);

    this.viewerToolbar.setVisibility(isHipVisible,  'HIPS_JSW');
    this.viewerToolbar.setVisibility(isKneeVisible,  'KNEE_JSW');
  }

  getActiveItemForCell(cell: any): any {
    if (cell) {
      const items = cell.get_selectedItems();
      if (items.get_count() > 0) {
        return items.get_item(0);
      }
    }
    return null;
  }

  getActiveViewer(): any {
    const cell = this.getActiveCell();
    if (cell) {
      const items = cell.get_selectedItems();
      if (items.get_count() > 0) {
        return items.get_item(0);
      }
    }
    return null;
  }

  removeCell(cell) {
    const seriesInstanceUID = cell.seriesInstanceUID;
    if (this._series[seriesInstanceUID]) {
      const cells = this._series[seriesInstanceUID].cells;
      if (cells) {
        const index = cells.indexOf(cell);
        if (index !== -1) {
          cells.splice(index, 1);
          if (cells.length === 0) {
            delete this._series[seriesInstanceUID];
          }
        }
      }
    }

    // remove cell from SeriesByFOR
    if(cell){
      this.removeCellToSeriesByFOR(cell);
    }
  }

  deleteSelectedAnnotations(result: Subject<lt.Annotations.Engine.AnnObject> = new Subject<lt.Annotations.Engine.AnnObject>()): void {
    const cell = this.getActiveCell();
    if (cell) {
      const automation = cell.get_automation();
      if (automation !== null && automation.canDeleteObjects) {
        const currentEditObject = automation.get_currentEditObject();
        if (currentEditObject) {
          automation.deleteSelectedObjects();
          result.next(currentEditObject);
        } else {
          result.error('no annotation found to delete1');
        }
      } else {
        result.error('no annotation found to delete2');
      }
    } else {
      result.error('No active viewer cell found');
    }
  }

  undoAction() {
    const cell = this.getActiveCell();
    if (cell) {
      const automation = cell.get_automation();
      automation.undo();
    }
  }

  clearAnnotations() {
    const cell = this.getActiveCell();
    if (cell) {
      const automation = cell.get_automation();
      const frame = this.getActiveCellFrame();
      const children = frame.get_container().get_children();
      const count = children.count;
      if (children.count > 0) {
        children.clear();
        automation.invalidate(lt.LeadRectD.empty);
      }
    }
  }

  loadPixelData(cell: lt.Controls.Medical.Cell) {
    // console.log("loadPixelData...");
    const seriesInfo = this.getSeriesInfo(cell);
    const viewerComponent = seriesInfo.viewerComponent;
    const prevSliceNumb = seriesInfo.prevSliceNumb ? seriesInfo.prevSliceNumb : 1;
    const numbOfSlices = seriesInfo.numbOfSlices;
    const numbOfFrames = seriesInfo.numbOfFrames;
    const cellFrames = cell.get_frames().toArray();

    const functionWithPromise = (cellFrame, useScale) => {
      return this.objectRetrieveService.getPixelData(
        cellFrame.Instance.SOPInstanceUID, useScale).toPromise().then((data) => {
          cellFrame.pixelData = data.pixel_data ? data.pixel_data : null;
        });
    };

    const anAsyncFunction = async (cellFrame, useScale) => {
      return functionWithPromise(cellFrame, useScale);
    }

    // load pixel data for the corresponding instances on each frame
    const tgtFrames = [];
    for (let index = 0; index < numbOfFrames; index++) {
      const frame_idx = (prevSliceNumb - 1) + index * numbOfSlices;
      const cellFrame = cellFrames[frame_idx];
      if (cellFrame.pixelData === null || cellFrame.pixelData === undefined) {
        tgtFrames.push(cellFrame);
      }
    }
    // console.log('tgtFrames:', tgtFrames);

    // early return if pixel_data already exists
    if (tgtFrames.length === 0) {
      console.log('use pixel_data cached...');
      this.eventService.publish(EventNames.VIEWER_LOADING, false);
      return new Promise<any>(resolve => {
        resolve('');
      });
    }

    return Promise.all(tgtFrames.map(
      (cellFrame) => anAsyncFunction(cellFrame, true)));
  }

  clearAllAnnotations() {
    const cell = this.getActiveCell();
    if (cell) {
      const length = cell.get_frames().get_count();
      const automation = cell.get_automation();
      let annFound = false;
      for (let index = 0; index < length; index++) {
        const frame = cell.get_frames().item(index);
        const children = frame.get_container().get_children();
        if (children.count > 0) {
          annFound = true;
          children.clear();
        }
      }
      if (annFound) {
        automation.invalidate(lt.LeadRectD.empty);
      }
    }
  }

  getCellAnnotations(cell) {
    if (this._series[cell.seriesInstanceUID]) {
      const frames = cell.get_frames();
      const codecs = new lt.Annotations.Engine.AnnCodecs();
      let length = frames.count;
      let annotationsData = '';
      const automation = cell.get_automation();

      while (length--) {
        const frame = frames.item(length);
        const container = frame.get_container();
        if (automation != null) {
          const instance = frame.Instance;

          if (container.children.count > 0) {
            const userData: AnnUserData = new AnnUserData();
            const refSop: SopInstanceReference = new SopInstanceReference();

            refSop.ReferencedSopClassUid = instance.SOPClassUID;
            refSop.ReferencedSopInstanceUid = instance.SOPInstanceUID;
            refSop.ReferencedFrameNumber.push(frame.FrameNumber);

            userData.ImageSize = this.getImageSize(frame);
            userData.ReferencedImageSequence = refSop;
            container.userData = JSON.stringify(userData);
            annotationsData = codecs.save(container, lt.Annotations.Engine.AnnFormat.annotations, annotationsData, length + 1);
          }
        }
      }
      return annotationsData;
    }
    return '';
  }

  getImageSize(cellFrame) {
    const originalSize = cellFrame.mrtiInfo.fullSize;
    return { Width: originalSize.width, Height: originalSize.height };
  }

  cloneCanvas(overlayCanvas) {
    let rect;
    let canvas;
    const cell = this.getActiveCell();
    if (cell instanceof lt.Controls.Medical.Cell3D) {
      const cell3D = cell;
      canvas = cell3D.image;
      rect = lt.LeadRectD.create(0, 0, cell3D.image.naturalWidth, cell3D.image.naturalHeight);
    } else {
      const viewer = cell.get_imageViewer();
      const activeItem = viewer.get_activeItem();
      canvas = (activeItem as any).getForground();
      rect = viewer.getItemViewBounds(activeItem, lt.Controls.ImageViewerItemPart.view, true);
    }
    const clonedCanvas = document.createElement('canvas');
    clonedCanvas.width = rect.width;
    clonedCanvas.height = rect.height;
    clonedCanvas.style.left = '0px';
    clonedCanvas.style.top = '0px';
    clonedCanvas.style.width = rect.width + 'px';
    clonedCanvas.style.height = rect.height + 'px';
    // blit
    const context = clonedCanvas.getContext('2d');
    context.fillStyle = '#0';
    context.fillRect(0, 0, rect.width, rect.height);
    context.drawImage(canvas, rect.left, rect.top, rect.width, rect.height, 0, 0, rect.width, rect.height);
    if (overlayCanvas) {
      context.drawImage(overlayCanvas, 0, 0);
    }
    return clonedCanvas;
  }

  burnAnnotations(canvas) {
    const automation = this.getActiveCell().get_automation();
    if (!automation) {
      return;
    }

    const engine = new lt.Annotations.Rendering.AnnHtml5RenderingEngine();
    engine.attach(automation.get_activeContainer(), canvas.getContext('2d'));
    engine.burn();
  }

  captureCurrentFrame(burnAnnotations) {
    const burn = burnAnnotations || false;
    const canvas = this.cloneCanvas(false);
    if (burn) {
      this.burnAnnotations(canvas);
    }
    return canvas.toDataURL('image/png');

  }

  getAnnotationIDs(seriesInstanceUID: string, id: string): Array<any> {
    if (this._series[seriesInstanceUID] && this._series[seriesInstanceUID].annotations) {
      let annotationsOriginal = this._series[seriesInstanceUID].annotations[id];
      return annotationsOriginal;

    }
    return new Array<any>();
  }

  setAnnotationIDs(seriesInstanceUID: string, id: string, annotationIds: Array<any>) {
    if (this._series[seriesInstanceUID]) {
      if (!this._series[seriesInstanceUID].annotations) {
        this._series[seriesInstanceUID].annotations = {};
      }
      this._series[seriesInstanceUID].annotations[id] = annotationIds;
    }
  }

  getSopInstanceUIDs(seriesInstanceUID: string, id: string): Array<any> {
    if (this._series[seriesInstanceUID] && this._series[seriesInstanceUID].sopInstanceUIDs) {
      return this._series[seriesInstanceUID].sopInstanceUIDs[id];
    }
    return new Array<any>();
  }

  setSopInstanceUIDs(seriesInstanceUID: string, id: string, sopInstanceUIDs: Array<any>) {
    if (this._series[seriesInstanceUID]) {
      if (!this._series[seriesInstanceUID].sopInstanceUIDs) {
        this._series[seriesInstanceUID].sopInstanceUIDs = {};
      }
      this._series[seriesInstanceUID].sopInstanceUIDs[id] = sopInstanceUIDs;
    }
  }

  addAnnotations(cell: lt.Controls.Medical.Cell, annotations: Document, info?) {
    console.log('addAnnotations...');
    const viewerComponent = info.viewerComponent;
    const cellSeriesUID = this.utils.getRealId(cell.seriesInstanceUID);
    const viewerData = info.viewerComponent.viewerData;
    const studyUserRoles: Array<any> = viewerData?.studyUserRoles ?? [];
    const JWTData = this.utils.getJWTData();
    const seriesInfo = this.getSeriesInfo(cell);

    // const userRoles = studyUserRoles.filter(
    //   item => {return (item.roleType == 'RANO')});
    const userRoleNames = studyUserRoles.map(item => item.roleName);

    const isDiameterOffset = info.isDiameterOffset ? info.isDiameterOffset : false;
    // console.log('isDiameterOffset:', isDiameterOffset);

    if (this._series[cellSeriesUID]) {
      try {
        const length = cell.get_frames().get_count();
        const codecs: lt.Annotations.Engine.AnnCodecs = new lt.Annotations.Engine.AnnCodecs();
        const sopInstanceIndexMap = {};
        let baseIndex = 0;
        const frames = cell.get_frames();
        const annInfo = codecs.getInfoFromXmlDocument(annotations);
        const pages = annInfo.get_pages();
        if (pages == null) {
          return;
        }
        let pagesCount = pages.length;

        while (baseIndex < length) {
          const sopInstanceUID = frames.item(baseIndex).Instance.SOPInstanceUID;

          if (sopInstanceIndexMap[sopInstanceUID] == undefined) {
            sopInstanceIndexMap[sopInstanceUID] = baseIndex;
          }
          baseIndex++;
        }

        while (pagesCount--) {
          const loadContainer: lt.Annotations.Engine.AnnContainer = codecs.loadFromXmlDocument(annotations, pages[pagesCount]);
          const automation = cell.get_automation();

          if (loadContainer) {
            // We want the RotateCenter to be automatically recalculated.
            // It rotate center is empty (x = Nan, y = Nan), it is automatically recalculated.
            // But in the deserialization, the NaN is converted to 0 so the RotateCenter is set to (0,0)
            // The workaround is to just automatically set the  RoateCenter to be (x = Nan, y = Nan)
            const children = loadContainer.get_children();
            if (children) {
              const children_count = children.get_count();
              for (let i = 0; i < children_count; i++) {
                const child = children.get_item(i);
                if (child) {
                  child.set_rotateCenter(lt.LeadPointD.empty);
                }
              }
            }

            const annData = JSON.parse(loadContainer.get_userData());
            const sopInstance = annData.ReferencedImageSequence.ReferencedSopInstanceUid;
            const refFrames = annData.ReferencedImageSequence.ReferencedFrameNumber;
            const framesCount = refFrames.length;

            for (let index = 0; index < framesCount; index++) {
              let srcChildren: lt.Annotations.Engine.AnnObjectCollection = loadContainer.get_children();

              if (srcChildren.count == 0) {
                continue;
              }

              let frameIndex = sopInstanceIndexMap[sopInstance] + refFrames[index] - 1;
              if (Number.isNaN(frameIndex)) {
                frameIndex = 0;
              }
              const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(frameIndex);
              const container: lt.Annotations.Engine.AnnContainer = frame.container;
              const destChildren = container.get_children();

              let childrenCount = srcChildren.get_count();

              container.set_userData(annData);

              const scale_H = frame.get_height() / frame.get_container().get_size().get_height();
              const scale_W = frame.get_width() / frame.get_container().get_size().get_width();
              let scale = scale_H > scale_W ? scale_H : scale_W;
              scale = scale > 1 ? 1 : scale;
              info['scale'] = scale;

              // Filter ROIs for active user
              if(viewerData && childrenCount > 0){
                console.log("Filter ROIs/VOIs for current user...");
                srcChildren = this.filterChildren(srcChildren.toArray(), JWTData, userRoleNames, viewerData);
              }

              childrenCount = srcChildren.get_count();

              const jswFields: any = {
              };

              for (let i = 0; i < childrenCount; i++) {
                const child = srcChildren.get_item(i);
                const childMetadata = child.get_metadata();

                // restore the visibility of lable for ROI
                if (child.labels.AnnObjectName) {
                  child.labels.AnnObjectName.set_isVisible(true);
                }

                // lock ROIs for MRanoEfficancy project in deactive visits
                if (this.utils.isMranoEfficancy && this.utils.MRanoActiveVisits.length > 0) {
                  if (this.utils.MRanoActiveVisits.includes(seriesInfo.timepoint) || this.utils.MRanoActiveVisits.includes(childMetadata.Timepoint)) {
                    child.unlock('IAG');
                  } else {
                    child.lock('IAG');
                  }
                }

                try {
                  if(childMetadata["VOIStatus"] && childMetadata["VOIStatus"] == "Grouped") {
                    child.unlock("IAG");
                  }
                } catch (error) {

                }

                if (childMetadata.Subject === 'primaryLocationArea') {
                  try {
                      child.add_propertyChanged((sender, e) => {
                        if (!this.silentPropertyChange) {
                          this.utils.onPropertyChanged(sender, e, child, info);
                          if (!child.isSelected && !e.newValue && e.oldValue && this.allowSavingActiveContainer) {
                            // Saving annotation after any change for simple ROIs and right after unselect for complex ROIs
                            info['currentOffset'] = childMetadata.CurrentOffset;
                            this.saveActiveContainerROIs(info);
                            this.autoVolumeCalculations(info, cell, childMetadata.Label).then((rowData2) => {
                              viewerComponent.rowData2 = rowData2;
                            });
                          }
                        }
                      });
                      (<any>child).is_add_propertyChanged = true;
                  } catch (error) {
                    console.log(">>> error ... ", error);
                  }
                } else {
                  // Add an event for changes
                  if (!(<any>child).is_add_propertyChanged) {
                    child.add_propertyChanged((sender, e) => {
                      if (!this.silentPropertyChange) {
                        this.utils.onPropertyChanged(sender, e, child, info);
                      }
                    });
                    (<any>child).is_add_propertyChanged = true;
                  }
                }

                try {
                  if(childMetadata["VOIStatus"] && childMetadata["VOIStatus"] == "Grouped") {
                    child.unlock("IAG");
                  }
                } catch (error) {

                }

                // set viewer offset on diameter index
                if (childMetadata["Subject"] == "DiamRulerL14") {
                  try {
                    viewerComponent.showDiameterFlag = true;
                    const major = (child['labels']['RulerLength']['Of'] as string).replace(' mm', '');
                    const minor = (child['labels']['SecondaryRulerLength']['Of'] as string).replace(' mm', '');
                    const spdp = (<any>major as number) * (<any>minor as number);
                    const volume = 0;
                    viewerComponent.dataSourceDiam['data'][0]['major'] = major;
                    viewerComponent.dataSourceDiam['data'][0]['minor'] = minor;
                    viewerComponent.dataSourceDiam['data'][0]['spdp'] = spdp;
                    viewerComponent.dataSourceDiam['data'][0]['volume'] = volume;

                    if (isDiameterOffset) {
                      cell.set_currentOffset(frameIndex);
                    }
                  } catch (error) {

                  }
                }
                destChildren.add(child);

                if (childMetadata && childMetadata.hasOwnProperty('JswTarget')) {
                  jswFields[childMetadata['JswTarget']] = child.labels.JswDistance.text;
                }
              }

              if (Object.keys(jswFields).length > 0) {
                jswFields.seriesId = seriesInfo.seriesId;
                viewerComponent?.confirmContours(jswFields);
              }

              container.mapper.calibrate(
                lt.LeadLengthD.create(1), lt.Annotations.Engine.AnnUnit.unit,
                lt.LeadLengthD.create(loadContainer.mapper.calibrationScale),
                lt.Annotations.Engine.AnnUnit.unit);

              automation.set_active(true);
              automation.invalidate(lt.LeadRectD.empty);
            }
          }
          automation.get_automationControl().automationInvalidate(lt.LeadRectD.empty);
        }
        return '';
      } catch (er) {
        // this._dialogs.error(this._errorTitle, "Invalid annotations data: " + er);
        return 'Invalid annotations data: ' + er;
      }
    }

    return 'Missing cell.seriesInstanceUID: ' + cell.seriesInstanceUID;
  }

  filterChildren(children: Array<any>, JWTData, curr_userRoleNames: Array<any>, viewerData: any){
    let sel_children = new ltAnnotationsEngine.AnnObjectCollection();
    if(JWTData === undefined || JWTData === null || JWTData === '') {
      JWTData = this.utils.JWTData ? this.utils.JWTData : this.utils.getJWTData();
    }
    if (this.utils.isMranoProject()) {
      children.forEach(child => {
        const lastEditedBy = child.get_metadata()["LastEditedBy"] ? child.get_metadata()["LastEditedBy"] : null;
        const createdBy = child.get_metadata()["CreatedBy"] ? child.get_metadata()["CreatedBy"] : null;
        const createMethod = child.get_metadata()["CreateMethod"] ? child.get_metadata()["CreateMethod"] : null;

        if (createMethod === 'GBM AI') {
          sel_children.add(child);
        }

        else if (viewerData.selectedPage === 'Reading') {
          if ((lastEditedBy == JWTData.subject || createdBy == JWTData.subject) && child.get_metadata()['ReadingID'] == viewerData.readingID) {
            sel_children.add(child);
          }
        } else if (lastEditedBy == JWTData.subject || createdBy == JWTData.subject) {
            sel_children.add(child);
        }
      });
    } else if (this.utils.isDemriqProject()) {
      children.forEach(child => {
        const lastEditedBy = child.get_metadata()["LastEditedBy"] ? child.get_metadata()["LastEditedBy"] : null;
        const createdBy = child.get_metadata()["CreatedBy"] ? child.get_metadata()["CreatedBy"] : null;

        if ((lastEditedBy == JWTData.subject || createdBy == JWTData.subject) || ((lastEditedBy == 'janus.uhd.nybing@regionh.dk' || createdBy == 'janus.uhd.nybing@regionh.dk') && JWTData.subject == 'mikael.boesen@gmail.com')) {
            sel_children.add(child);
        }
      });
    } else {
      children.forEach(child => {
        const lastEditedBy = child.get_metadata()["LastEditedBy"] ? child.get_metadata()["LastEditedBy"] : null;
        const createdBy = child.get_metadata()["CreatedBy"] ? child.get_metadata()["CreatedBy"] : null;
        const institution = child.get_metadata()["Institution"] ? child.get_metadata()["Institution"] : null;
        const Subject = child.get_metadata()["Subject"] ? child.get_metadata()["Subject"] : null;
        if (Subject === 'RadioBoticROI' || Subject === 'JSWROI') {
          sel_children.add(child);
        } else if (institution == JWTData.institution) {
          if (lastEditedBy == JWTData.subject || createdBy == JWTData.subject) {
              sel_children.add(child);
          }
        }
      });
    }
    return sel_children;
  }

  saveAllROIs(info) {
    if (!this.utils.isDemriqProject()) {
      const savePromises = [];
      const viewerComponent = info.viewerComponent;
      const viewer: lt.Controls.Medical.MedicalViewer = viewerComponent.viewer;
      const cells: lt.Controls.Medical.Cell[] = viewer.layout.get_items().toArray();

      cells.forEach(cell => {
        if (cell instanceof lt.Controls.Medical.Cell3D) {
        } else {
          savePromises.push(this.saveCellROIs(cell, info));
        }
      });

      return forkJoin(savePromises);
    }
  }

  saveCellROIs(cell, info) {
    if (!this.isQcOrReader(info) || this.utils.isDemriqProject()) {
      return new Promise<any>(resolve => {
        resolve('');
      });
    }

    console.log('saveCellROIs, cell.seriesInstanceUID:', cell.seriesInstanceUID);
    const frames: Array<lt.Controls.Medical.Frame> = cell.get_frames().toArray();
    const viewerComponent = info.viewerComponent;
    const mprType = this.utils.getCellMPRType(cell);
    let annotationsData = '';
    let imageSize = null;

    const auditData = viewerComponent.getSeriesAuditData((<any>cell).seriesId);
    const codecs: lt.Annotations.Engine.AnnCodecs = new lt.Annotations.Engine.AnnCodecs();

    // Get existing annotations
    const celldivID = this.utils.getRealId(cell.divID);
    const cellSeriesUID = this.utils.getRealId(cell.seriesInstanceUID);
    const annotations = this.getAnnotationIDs(cellSeriesUID, celldivID);

    for (let index = 0; index < frames.length; index++) {
      const frame = frames[index];
      const instance = (<any>frame).Instance;

      const new_container = frame.get_container();
      let old_container: ltAnnotationsEngine.AnnContainer;

      // unselect children
      // => avoid saving child with selection settings
      new_container.children.toArray().forEach(child => {
        if (child) {
          child.isSelected = false;
          child.get_metadata().isChanged = 'false';
        }
      });

      const old_ann_idx = annotations.findIndex(
        a => (a.ReferencedSOPInstanceUIDs.some(
          item => instance.SOPInstanceUID === item)));
      const old_ann = annotations[old_ann_idx];

      const userData: AnnUserData = new AnnUserData();
      const refSop: SopInstanceReference = new SopInstanceReference();
      const frameNumber = (<any>frame).FrameNumber ? (<any>frame).FrameNumber : 1;

      refSop.ReferencedSopClassUid = instance.SOPClassUID;
      refSop.ReferencedSopInstanceUid = instance.SOPInstanceUID;
      refSop.ReferencedFrameNumber.push(frameNumber);

      imageSize = imageSize === null ? this.getImageSize(frame) : imageSize;
      userData.ImageSize = imageSize;
      userData.ReferencedImageSequence = refSop;
      userData.MPRType = mprType;

      if (old_ann !== undefined && old_ann !== null && old_ann.XMLData !== undefined) {
        old_container = codecs.loadFromXmlDocument(old_ann.XMLData, index + 1);
      }

      if (old_container != null && !viewerComponent.isJSWProject) {
        // check if ROI already exists, then overwrite
        new_container.children.toArray().forEach(new_child => {
          if (new_child) {
            const old_child_idx = old_container.children.toArray().findIndex(
              a => a.get_guid() === new_child.get_guid());

            if (old_child_idx > -1) {
              old_container.get_children().removeItem(old_child_idx);
              old_container.get_children().insertItem(old_child_idx, new_child);
            } else {
              old_container.get_children().add(new_child);
            }
          } else console.log('Warn > wrong child found:', new_child);
        });

        // Convert & store annotations from old_container
        if (old_container.get_children().count > 0) {

          old_container.userData = JSON.stringify(userData);
          annotationsData = codecs.save(
            old_container, lt.Annotations.Engine.AnnFormat.annotations, annotationsData, index + 1);
        }
      } else {
        // Convert & store annotations from new_container
        if (new_container.get_children().count > 0) {
          new_container.userData = JSON.stringify(userData);
          annotationsData = codecs.save(
            new_container, lt.Annotations.Engine.AnnFormat.annotations, annotationsData, index + 1);
        }
      }
    }

    if (annotationsData.length <= 0) {
      const err_msg = 'No annotations found to save';
      viewerComponent.selection1.clear();
      // remove presentation state if exists
      if (annotations.length > 0) {
        // select the annotation related to the MPR type
        const sel_annotation = annotations.find(a => a.ContentDescription === mprType);
        if (sel_annotation != null) {
          console.log('As no annotations remains, removing the presentation state:', sel_annotation.SOPInstanceUID);
          // psSOPInstanceUID = sel_annotation.SOPInstanceUID;
          const person = this.utils.getRanoAdudicationPerson();
          return this.objectStoreService.deleteAnnotations(
              sel_annotation.SOPInstanceUID, null, person, auditData).toPromise().then((data: any) => {
            return;
          });
        }
      } else {
        console.log(err_msg);
      }
      return new Promise<any>(resolve => {
        resolve('');
      });
    }

    const container = frames[0].get_container();
    const userData = JSON.stringify({
      SourceDpiX: container.mapper.sourceDpiX,
      SourceDpiY: container.mapper.sourceDpiY,
      TargetDpiX: container.mapper.targetDpiX,
      TargetDpiY: container.mapper.targetDpiY,
      MPRType: mprType
    });

    // const description = mprType+"_"+cell.seriesInstanceUID.substring(cell.seriesInstanceUID.length - 4);
    const description = mprType;

    let psSOPInstanceUID = null;
    if (annotations.length > 0) {
      // select the annotation related to the MPR type
      const sel_annotation = annotations.find(a => a.ContentDescription === mprType);
      if (sel_annotation != null) {
        psSOPInstanceUID = sel_annotation.SOPInstanceUID;
      }
    }

    const _info = {
      seriesInstanceUID: this.utils.getRealId(cell.seriesInstanceUID),
      psSOPInstanceUID,
      description,
      userData,
      divID: cell.divID,
      viewerComponent,
      audit: auditData
    };

    return this.saveAllAnnotations(annotationsData, _info);
  }

  isQcOrReader(info) {
    const viewerData = info.viewerComponent.viewerData;
    const studyUserRoles: Array<any> = viewerData?.studyUserRoles ?? [];

    return this.utils.isUserHasRole(studyUserRoles, 'IAG Technologist')
      || this.utils.isUserHasRole(studyUserRoles, 'Central Reade');
  }
  saveActiveContainerROIs(info, roiGUID?): Promise<any> {
    if (!this.isQcOrReader(info)) {
      return;
    }
    try {
      // console.log('saveActiveCellROIs...');
      const viewerComponent = info.viewerComponent;
      if (viewerComponent.isJSWProject === true) {
        return;
      }
      let activeCell: lt.Controls.Medical.Cell = this.getActiveCell();
      if(info.previousCell !== undefined && info.previousCell !== null) {
        activeCell = info.previousCell;
      }

      const auditData = viewerComponent.getSeriesAuditData(info.seriesId);

      const frames: Array<lt.Controls.Medical.Frame> = activeCell.get_frames().toArray();
      const mprType = this.utils.getCellMPRType(activeCell);
      let annotationsData = '';
      let imageSize = null;
      let currentOffset = activeCell.get_currentOffset();
      const currentSliceElement = document.getElementById(activeCell.divID + '_slice-tracker_current') as HTMLInputElement;
      let currentSlice = currentSliceElement && currentSliceElement.value ? parseInt( currentSliceElement.value) : 0;
      const sliceCountElement = document.getElementById(activeCell.divID + '_slice-tracker_count') as HTMLDivElement;
      const sliceCount = sliceCountElement && sliceCountElement.innerText ?  parseInt(sliceCountElement.innerText) : 0;

      if(info.currentOffset !== undefined && info.currentOffset !== null && info.currentOffset !== '') {
        try {
          currentOffset = parseInt(info.currentOffset);
          currentSlice = (currentOffset % sliceCount) + 1;

        } catch (error) {
          console.log('Error... ', error);
          return;
        }
      }

      if (currentOffset === currentSlice) {
        return;
      }

      const codecs: lt.Annotations.Engine.AnnCodecs = new lt.Annotations.Engine.AnnCodecs();
      // Get existing annotations
      const celldivID = this.utils.getRealId(activeCell.divID);
      const cellSeriesUID = this.utils.getRealId(activeCell.seriesInstanceUID);
      const annotations = this.getAnnotationIDs(cellSeriesUID, celldivID);

      const frame = frames[currentOffset];
      const instance = (<any>frame).Instance;
      const new_container = frame.get_container();

      // => avoid saving child with selection settings
      new_container.get_children().toArray().forEach(child => {
        if (child) {
          child.isSelected = false;
          child.get_metadata().isChanged = 'false';
          child.get_metadata()['SliceNumber'] = currentSlice + '';
          child.get_metadata()['_sliceNumber'] = currentSlice + '';
          child.get_metadata()['CurrentOffset'] = currentOffset + '';
        }
      });

      const userData: AnnUserData = new AnnUserData();
      const refSop: SopInstanceReference = new SopInstanceReference();
      const frameNumber = (<any>frame).FrameNumber ? (<any>frame).FrameNumber : 1;

      refSop.ReferencedSopClassUid = instance.SOPClassUID;
      refSop.ReferencedSopInstanceUid = instance.SOPInstanceUID;
      refSop.ReferencedFrameNumber.push(frameNumber);

      imageSize = imageSize === null ? this.getImageSize(frame) : imageSize;
      userData.ImageSize = imageSize;
      userData.ReferencedImageSequence = refSop;
      userData.MPRType = mprType;

      // Convert & store annotations from new_container
      if (new_container.get_children().count > 0) {
        new_container.userData = JSON.stringify(userData);
        annotationsData = codecs.save(
          new_container, lt.Annotations.Engine.AnnFormat.annotations, annotationsData, currentOffset + 1);
      }

      const container = frames[0].get_container();
      const userDataX = JSON.stringify({
        SourceDpiX: container.mapper.sourceDpiX,
        SourceDpiY: container.mapper.sourceDpiY,
        TargetDpiX: container.mapper.targetDpiX,
        TargetDpiY: container.mapper.targetDpiY,
        MPRType: mprType
      });
      const description = mprType;

      let psSOPInstanceUID = null;
      if (annotations && annotations.length > 0) {
        // select the annotation related to the MPR type
        const sel_annotation = annotations.find(a => a.ContentDescription === mprType);
        if (sel_annotation != null) {
          psSOPInstanceUID = sel_annotation.SOPInstanceUID;
        }
      }

      const _info = {
        'seriesInstanceUID': this.utils.getRealId(activeCell.seriesInstanceUID),
        'psSOPInstanceUID': psSOPInstanceUID,
        'description': description,
        'userData': userDataX,
        'pageNumber': currentOffset + 1,
        'sopInstanceUID': '',
        'sliceCount': sliceCount,
        'sliceNumber': currentSlice,
        'frameCount': (frames.length + 1) + '',
        'viewerComponent': viewerComponent,
        'actionManagerService': info.actionManagerService,
        'audit': auditData
      };

      if (_info.actionManagerService === undefined || _info.actionManagerService === null) {
        _info.actionManagerService = viewerComponent.actionManagerService;
      }

      if (roiGUID !== undefined) {
        _info.sopInstanceUID = roiGUID;
      }

      if (annotationsData === '' && roiGUID === undefined) {
        return;
      }

      return this.saveContainerAnnotations(activeCell, annotationsData, _info, 10).then((result) => {
        return result;
      });
    } catch (error) {
      console.log('>>> Error: ', error);
    }
  }
  saveAllAnnotations(annotationsData, info, retries?) {
    if (!this.isQcOrReader(info)) {
      return;
    }
    const viewerComponent = info.viewerComponent;
    const person = this.utils.getRanoAdudicationPerson();

    return this.objectStoreService.storeAnnotations(
      info.seriesInstanceUID, info.psSOPInstanceUID, annotationsData,
      info.description, info.userData, person, info.audit).toPromise().then((data: any) => {
        // reload local annotations
        const sopInstanceUIDs = this.getSopInstanceUIDs(info.seriesInstanceUID, info.divID);
        viewerComponent.dicomLoaderService.loadPresentationState(
          { 'seriesInstanceUID': info.seriesInstanceUID }, info.divID, sopInstanceUIDs);

        // update row status on the table
        viewerComponent.rowData1.forEach(row => {
          row.isChanged = false;
          if (data && data.SOPInstanceUID) {
            row.annoSopIUID = data.SOPInstanceUID;
          }
        });
        viewerComponent.selection1.clear();
      },
      (error: any) => {
        console.log('Error found, response: ', error);
        retries = retries !== undefined ? retries + 1 : 0;
        if (retries >= 3) {
          return new Promise<any>(resolve => {
            resolve('');
          });
        }
        setTimeout(() => {
          this.saveAllAnnotations(annotationsData, info, retries);
        }, 100);
      }
    );
  }

  saveContainerAnnotations(activeCell, annotationsData, info, retries?) {
    if (!this.isQcOrReader(info)) {
      return;
    }

    try {
      console.log('saveContainerAnnotations...');
      try {
        if(info.viewerComponent !== undefined && info.viewerComponent.selectedRegions && info.actionManagerService !== undefined) {
          info.actionManagerService.setSelectedRegions(info.viewerComponent.selectedRegions);
        }
      } catch (error) {

      }
      try {
        if (info.viewerComponent.isJSWProject === true) {
          return;
        }
      } catch (error) {

      }

      const person = this.utils.getRanoAdudicationPerson();
      return this.objectStoreService.storeCurrentSliceAnnotations(
        info.seriesInstanceUID, info.psSOPInstanceUID, info.pageNumber, annotationsData,
        info.description, info.sopInstanceUID, info.sliceCount, info.sliceNumber, info.frameCount, info.userData, person, info.audit).toPromise().then((data: any) => {
          console.log('>>> Container Annotation Data is Saved...');
        },
        (error: any) => {
          console.log('Error found, response: ', error);
          retries = retries !== undefined ? retries + 1 : 0;
          if (retries >= 3) {
            return new Promise<any>(resolve => {
              resolve('');
            });
          }
          setTimeout(() => {
            this.saveContainerAnnotations(activeCell, annotationsData, info, retries);
          }, 300);
        }
      );
    } catch (error) {

    }
  }

  deleteROIs(info) {
    console.log('deleteROIs...');
    const viewerComponent = info.viewerComponent;
    const viewer: lt.Controls.Medical.MedicalViewer = viewerComponent.viewer;

    const cells: lt.Controls.Medical.Cell[] = viewer.layout.get_items().toArray();
    cells.forEach(cell => {
      if (cell instanceof lt.Controls.Medical.Cell3D) {
      } else {
        this.deleteCellROIs(cell, viewerComponent.selection1.selected, info).then((result: boolean) => {
          viewerComponent.selection1.clear();

          if (result) {
            return this.saveCellROIs(cell, info);
          }

          // trigger an event to refresh the viewer
          setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 30);
        });
      }
    });
  }

  private _removeCellROIs(cell, rows: Array<ROIRow>): boolean {
    const frames: Array<lt.Controls.Medical.Frame> = cell.get_frames().toArray();
    const celldivID = this.utils.getRealId(cell.divID);
    const cellSeriesUID = this.utils.getRealId(cell.seriesInstanceUID);
    const codecs: lt.Annotations.Engine.AnnCodecs = new lt.Annotations.Engine.AnnCodecs();
    const parser = new DOMParser();
    let isDelete = false;

    rows.forEach((row: ROIRow) => {
      if (!row) {
        isDelete = true;
        return;
      }
      if (row.child.isLocked) {
        return;
      }

      let isRemoved = false;
      if (row.child.get_metadata()["isRemoved"] && row.child.get_metadata()["isRemoved"] === "true") {
        isRemoved = true;
        row.child.get_metadata()["isRemoved"] = "false";
      }
      let frameIndex = toInteger(row.slice) - 1
      const frame = frames[frameIndex];
      const container = frame.get_container();
      let childIndex = container
        .get_children()
        .toArray()
        .findIndex(child => child.get_metadata().Name === row.name);

      // remove rois from container and table
      if (childIndex !== -1 && !isRemoved) {
        container.get_children().removeItem(childIndex);
      }

      // remove rois from local annotations
      const annotations = this.getAnnotationIDs(cellSeriesUID, celldivID);
      const old_ann_idx = annotations.findIndex(
        a => (a.ReferencedSOPInstanceUIDs.some(
          item => frames.some(_frame => (<any>_frame).Instance.SOPInstanceUID === item))));
      const old_ann = annotations[old_ann_idx];

      let annotationsData = '';
      if (old_ann != null) {
        const old_containers: ltAnnotationsEngine.AnnContainer[] = codecs.loadAllFromXML(old_ann.XMLData);
        const old_contIndex = old_containers.findIndex(c => c.get_pageNumber() == (frameIndex + 1));
        const old_container = old_containers[old_contIndex];
        if(old_container != undefined) {
          const old_childIndex = old_container.get_children().toArray().findIndex(c => c.get_metadata()['Name'] == row.name);
          // remove rois from old container
          if (old_childIndex > -1) {
            old_container.get_children().removeItem(old_childIndex);
          }
        }
        old_containers[old_contIndex] = old_container;
        annotationsData = codecs.saveAll(old_containers, lt.Annotations.Engine.AnnFormat.annotations);
        old_ann.XMLData = parser.parseFromString(annotationsData, 'text/xml');
        // update local annotations before saving all ROIs
        annotations[old_ann_idx] = old_ann;
        this.setAnnotationIDs(cellSeriesUID, celldivID, annotations);
        isDelete = true;
      }
    });

    return isDelete;
  }

  deleteCellROIs(cell, rows: Array<ROIRow>, info) {
    console.log('deleteCellROIs...');

    const isDeleted: boolean = this._removeCellROIs(cell, rows);
    let result = null;

    if (isDeleted) {
      result = this.saveCellROIs(cell, info);
    }

    return new Promise<any>(resolve => {
      resolve(result);
    });
  }
  groupAsVolume(info) {
    console.log('groupAsVolume...');
    this.toastOptions.title = "ID 99: VOI grouping process started successfully";
    this.toastOptions.msg = "Process has started successfully. Please wait for the Volume created notification";
    this.toastyService.info(this.toastOptions);
    const cell: lt.Controls.Medical.Cell = this.getActiveCell();
    const frames: Array<lt.Controls.Medical.Frame> = cell.get_frames().toArray();
    const viewerComponent = info.viewerComponent;

    const codecs: lt.Annotations.Engine.AnnCodecs = new lt.Annotations.Engine.AnnCodecs();
    const parser = new DOMParser();
    let firstLineLength;
    let secondLineLength;
    // create a volume GUID
    const uuid = UUID.generate();
    console.log('new uuid: ', uuid);

    let randomColor = Math.floor(Math.random() * 16777215).toString(16);
    if (randomColor.length === 5) {
      randomColor = randomColor + '0';
    }
    const forbiddenColors: any[] = ['da2412', '122dda', '33da12', '000000'];
    while (forbiddenColors.includes(randomColor)) {
      randomColor = Math.floor(Math.random() * 16777215).toString(16);
    }

    // const r = this.hexToRgb('#' + randomColor).r;
    // const g = this.hexToRgb('#' + randomColor).g;
    // const b = this.hexToRgb('#' + randomColor).b;
    // const brushcolor = 'rgba(' + r + ',' + g + ',' + b + ',0.4)';
    // const strokecolor = 'rgba(' + r + ',' + g + ',' + b + ')';

    let isGBMROI = false;
    let isGrouped = false;
    const roiTypes = {};
    const roiAreas = [];
    const roiSlices = {};
    const srcChildren = [];
    let lastEditedBy = null;

    viewerComponent.selection1.selected.forEach((row) => {
      if (row.type == 'CrossRuler') {
        const frameIndex = toInteger(row.slice) - 1;
        const frame = frames[frameIndex];
        const container = frame.get_container();
        const childIndex = container.get_children().toArray().findIndex(child => child.get_metadata()['Name'] == row.name);
        if (childIndex > -1) {
          const child = container.get_children().get_item(childIndex);
          firstLineLength = child.get_metadata()['FirstLineLength'];
          secondLineLength = child.get_metadata()['SecondLineLength'];
        }
      }
    });

    const selRows = viewerComponent.selection1.selected;
    for (let index = 0; index < selRows.length; index++) {
      const row = selRows[index];
      const frameIndex = toInteger(row.slice) - 1;
      const rowIndex = viewerComponent.rowData1.findIndex(a => a.name === row.name);
      const frame = frames[frameIndex];
      const container = frame.get_container();
      const childIndex = container.get_children().toArray().findIndex(child => child.get_metadata()['Name'] == row.name);

      // Assign a volume GUID to ROIs selected on container annotations
      if (childIndex > -1) {
        const child = container.get_children().get_item(childIndex);
        child.get_metadata()['VolumeGUID'] = uuid;
        child.get_metadata()['StrokeColor'] = randomColor;
        child.get_metadata()['FirstLineLength'] = firstLineLength;
        child.lock("IAG");
        child.get_metadata()['SecondLineLength'] = secondLineLength;
        if (firstLineLength) {
          child.get_metadata()['HasCrossRuler'] = 'true';
        } else {
          child.get_metadata()['HasCrossRuler'] = 'false';
        }

        (<any>child).isChanged = row.isChanged;

        // remove selected rows from datasource1
        viewerComponent.rowData1.splice(rowIndex, 1);
        if (!(viewerComponent.dataSource1 instanceof MatTableDataSource)) {
          viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
        } else {
          viewerComponent.dataSource1.data = viewerComponent.rowData1;
        }

        viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
        viewerComponent.dataSource1.sort = viewerComponent.sort1;
        viewerComponent.selection1.clear();

        if (child.get_metadata()["CreateMethod"] !== "GBM AI") {
          // Set border color to the ROI
          const annStroke = lt.Annotations.Engine.AnnStroke.create(
            lt.Annotations.Engine.AnnSolidColorBrush.create('#' + randomColor),
            lt.LeadLengthD.create(1));
          child.set_stroke(annStroke);
          // Set fill color to the ROI
          const annBrush = lt.Annotations.Engine.AnnSolidColorBrush.create('#' + randomColor);
          child.set_fill(annBrush);
        } else {
          isGBMROI = true;
        }

        roiTypes[child.get_metadata()['AnnoType']] = child.get_metadata()['AnnoType'];
        if (parseInt(child.get_metadata()['Area']) > 0 && child.get_metadata()['AnnoType'] != 'CrossRuler') {
          roiAreas.push(parseInt(child.get_metadata()['Area']));
          roiSlices[child.get_metadata()['SliceNumber']] = child.get_metadata()['SliceNumber'];
        }
        if (lastEditedBy == null) {
          lastEditedBy = child.get_metadata()['LastEditedBy'];
        }
        child.get_metadata()["VOIStatus"] = "Grouped";
        child.isSelected = false;
        srcChildren.push(child);
        isGrouped = true;
      }

      // Assign a volume GUID to ROIs selected on local annotations
      const annotations = this.getAnnotationIDs(cell.seriesInstanceUID, cell.divID);
      const old_ann_idx = annotations.findIndex(
        a => (a.ReferencedSOPInstanceUIDs.some(item => (<any>frame).Instance.SOPInstanceUID === item)));
      const old_ann = annotations[old_ann_idx];

      let annotationsData = '';
      if (old_ann != null) {
        const old_containers: ltAnnotationsEngine.AnnContainer[] = codecs.loadAllFromXML(old_ann.XMLData);
        const old_contIndex = old_containers.findIndex(c => c.get_pageNumber() == (frameIndex + 1));
        const old_container = old_containers[old_contIndex];
        const old_childIndex = old_container.get_children().toArray().findIndex(c => c.get_metadata()['Name'] == row.name);

        // Assign volume GUID
        if (old_childIndex > -1) {
          const old_child = old_container.get_children().get_item(old_childIndex);
          old_child.get_metadata()['VolumeGUID'] = uuid;
          old_child.get_metadata()['FirstLineLength'] = firstLineLength;
          old_child.get_metadata()['SecondLineLength'] = secondLineLength;

          if (old_child.get_metadata()["CreateMethod"] !== "GBM AI") {
            // Set border color to the ROI
            const annStroke = lt.Annotations.Engine.AnnStroke.create(
              lt.Annotations.Engine.AnnSolidColorBrush.create('#' + randomColor),
              lt.LeadLengthD.create(1));
            old_child.set_stroke(annStroke);
            // Set fill color to the ROI
            const annBrush = lt.Annotations.Engine.AnnSolidColorBrush.create('#' + randomColor);
            old_child.set_fill(annBrush);
          } else {
            isGBMROI = true;
          }

          old_containers[old_contIndex] = old_container;
          annotationsData = codecs.saveAll(old_containers, lt.Annotations.Engine.AnnFormat.annotations);
          old_ann.XMLData = parser.parseFromString(annotationsData, 'text/xml');
          // update local annotations before saving all ROIs
          annotations[old_ann_idx] = old_ann;
          this.setAnnotationIDs(cell.seriesInstanceUID, cell.divID, annotations);
        }
      }
    }

    if (isGrouped) {
      // create a row data for datasource2
      const row = new ROIRow();
      const roiType_vals = Object.keys(roiTypes);
      const roiSlices_vals = Object.keys(roiSlices);
      if(roiSlices_vals.length < 2){
        this.toastOptions.title = "ID 201: There is less that 1 slice";
        this.toastOptions.msg = "Warning: there is just 1 slice in this VOI!";
        this.toastyService.warning(this.toastOptions);
      }
      row.name = 'VOL_' + uuid.substring(uuid.length - 4).toUpperCase();
      row.type = (roiType_vals.length == 1) ? roiType_vals[0] : 'Multiple';
      row.slice = '[' + roiSlices_vals.toString() + ']';
      row.createMethod = 'Manual';
      row.lastEditedBy = lastEditedBy;
      row.lastEditedOn = this.utils.getCurrentDatetime();
      row.isChanged = false;
      row.srcChildren = srcChildren;
      row.color = randomColor;
      row.parentCell = cell;

      this.computeVOIVolumeData(row, info).then((volData) => {
        // console.log('volData:', volData);
        row.volume = volData.volume_cm3 ? volData.volume_cm3 : null;
        row.area = volData.area_mm2 ? volData.area_mm2 : null;

        viewerComponent.rowData2.add(row);
        // update datasource2 and clear selection1
        viewerComponent.dataSource2 = new MatTableDataSource(viewerComponent.rowData2);
        viewerComponent.dataSource2.paginator = viewerComponent.paginator2;
        viewerComponent.dataSource2.sort = viewerComponent.sort2;

        if (!(viewerComponent.dataSource1 instanceof MatTableDataSource)) {
          viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
        } else {
          viewerComponent.dataSource1.data = viewerComponent.rowData1;
        }
        viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
        viewerComponent.dataSource1.sort = viewerComponent.sort1;

        // update VOI panel
        info.viewerComponent.dataSource2 = new MatTableDataSource(info.viewerComponent.rowData2);
        info.viewerComponent.dataSource2.paginator = info.viewerComponent.paginator2;
        // trigger an event to refresh the viewer
        setTimeout(() => {
          window.dispatchEvent(new Event('resize'));
        }, 30);
        this.toastOptions.title = "ID 101: VOI grouping process finished successfully";
        this.toastOptions.msg = "Process has finished successfully.";
        this.toastyService.success(this.toastOptions);
      });
    }
    else {
      this.toastOptions.title = "ID 100: VOI grouping process failure";
      this.toastOptions.msg = "Process has failed due to some reasons. Try again or contact support team";
      this.toastyService.error(this.toastOptions);
    }
  }

  computeVOIVolumeData(row: ROIRow, info) {
    const viewerComponent = info.viewerComponent;
    const queryVOI4Vol = viewerComponent.buildQueryVOI4Vol(row);
    return this.queryArchiveService.findVOIVolume([queryVOI4Vol]).toPromise().then(data => {
      return data && data.length > 0? data[0] : null;
    });
  }

  async renderingVolumes(info) {
    const cell: lt.Controls.Medical.Cell = this.getActiveCell();
    const frames: Array<lt.Controls.Medical.Frame> = cell.get_frames().toArray();
    const containers = cell.get_automation().get_containers().toArray();
    const viewerComponent = info.viewerComponent;
    const matchedVOIs: MatchedROIs[] = [];

    for (let i = 0; i < containers.length; i++) {
      const container: lt.Annotations.Engine.AnnContainer = containers[i];
      container.get_children().toArray().forEach((annObject: lt.Annotations.Engine.AnnObject) => {
        if (annObject.get_metadata()["VolumeGUID"] != undefined) {
          let volumeGUID = annObject.get_metadata()["VolumeGUID"];
          let voiExisted: boolean = false;
          matchedVOIs.forEach(matchedVOI => {
            if (matchedVOI.volumeGUID == volumeGUID) {
              voiExisted = true;
            }
          });
          if (!voiExisted) {
            const _matchedROIs: MatchedROIs = {
              volumeGUID: "",
              voiROIs: [],
              voiColor: ""
            };
            _matchedROIs.volumeGUID = volumeGUID;
            _matchedROIs.voiROIs = [];
            _matchedROIs.voiColor = annObject.get_metadata()['StrokeColor'];
            matchedVOIs.push(_matchedROIs);
          }
          matchedVOIs.forEach(matchedVOI => {
            if (matchedVOI.volumeGUID === volumeGUID) {
              const _voiROI: VoiROI = {
                name: '',
                guid: '',
                slice: ''
              };
              _voiROI.name = annObject.get_metadata()['Name'];
              _voiROI.guid = annObject.get_guid();
              _voiROI.slice = annObject.get_metadata()["SliceNumber"];
              matchedVOI.voiROIs.push(_voiROI);
            }
          });
        }
      });
    }

    viewerComponent.rowData2 = [];

    for (let i = 0; i < matchedVOIs.length; i++) {
      const matchedVOI = matchedVOIs[i];
      const voiROIs = matchedVOI.voiROIs;
      const roiTypes = {};
      const roiAreas = [];
      const roiSlices = {};
      const srcChildren = [];
      let lastEditedBy = null;
      const uuid = UUID.generate();

      for (let j = 0; j < voiROIs.length; j++) {
        const row = voiROIs[j];
        const frameIndex = toInteger(row.slice) - 1;
        const rowIndex = viewerComponent.rowData1.findIndex(a => a.name === row.name);
        const frame = frames[frameIndex];
        const container = frame.get_container();
        const childIndex = container.get_children().toArray().findIndex(child => child.get_metadata()['Name'] == row.name);

        // Assign a volume GUID to ROIs selected on container annotations
        if (childIndex > -1) {
          const child = container.get_children().get_item(childIndex);

          // remove selected rows from datasource1
          viewerComponent.rowData1.splice(rowIndex, 1);
          if (!(viewerComponent.dataSource1 instanceof MatTableDataSource)) {
            viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
          } else {
            viewerComponent.dataSource1.data = viewerComponent.rowData1;
          }

          viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
          viewerComponent.dataSource1.sort = viewerComponent.sort1;
          viewerComponent.selection1.clear();

          roiTypes[child.get_metadata()['AnnoType']] = child.get_metadata()['AnnoType'];
          if (parseInt(child.get_metadata()['Area']) > 0 && child.get_metadata()['AnnoType'] != 'CrossRuler') {
            roiAreas.push(parseInt(child.get_metadata()['Area']));
            roiSlices[child.get_metadata()['SliceNumber']] = child.get_metadata()['SliceNumber'];
          }
          if (lastEditedBy == null) {
            lastEditedBy = child.get_metadata()['LastEditedBy'];
          }
          child.get_metadata()["VOIStatus"] = "Grouped";
          child.isSelected = false;
          child.lock("IAG");
          srcChildren.push(child);
        }
      }

      // create a row data for datasource2
      let row = new ROIRow();
      const roiType_vals = Object.keys(roiTypes);
      const roiSlices_vals = Object.keys(roiSlices);

      row.name = 'VOL_' + uuid.substring(uuid.length - 4).toUpperCase();
      row.type = (roiType_vals.length == 1) ? roiType_vals[0] : 'Multiple';
      row.slice = '[' + roiSlices_vals.toString() + ']';
      row.createMethod = 'Manual';
      row.lastEditedBy = lastEditedBy;
      row.lastEditedOn = this.utils.getCurrentDatetime();
      row.isChanged = false;
      row.srcChildren = srcChildren;
      row.color = matchedVOI.voiColor;
      row.parentCell = cell;

      await this.computeVOIVolumeData(row, info).then((volData) => {
        row.volume = volData.volume_cm3 ? volData.volume_cm3 : null;
        row.area = volData.area_mm2 ? volData.area_mm2 : null;
        viewerComponent.rowData2.add(row);
      });
    }
    // update datasource2
    viewerComponent.dataSource2 = new MatTableDataSource(viewerComponent.rowData2);
    viewerComponent.dataSource2.paginator = viewerComponent.paginator2;
    viewerComponent.dataSource2.sort = viewerComponent.sort2;
    viewerComponent.selection2.clear();

    if (!(viewerComponent.dataSource1 instanceof MatTableDataSource)) {
      viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
    } else {
      viewerComponent.dataSource1.data = viewerComponent.rowData1;
    }
    viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
    viewerComponent.dataSource1.sort = viewerComponent.sort1;

    // update VOI panel
    info.viewerComponent.dataSource2 = viewerComponent.dataSource2;

    setTimeout(() => {
    window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  autoVolumes(info, indexedCell?, voiName?) {
    console.log('autoVolumes...');
    const rowData2 = [];
    try {
      const cell: lt.Controls.Medical.Cell = indexedCell ? indexedCell : this.getActiveCell();
      const frames: Array<lt.Controls.Medical.Frame> = cell.get_frames().toArray();
      const viewerComponent = info.viewerComponent;
      const matchedVOIs: MatchedROIs[] = [];
      const selRows = viewerComponent.selection1.selected;

      for (let i = 0; i < frames.length; i++) {
        const frame = frames[i];

        const container: lt.Annotations.Engine.AnnContainer = frame.get_container();
        container.get_children().toArray().forEach((annObject: lt.Annotations.Engine.AnnObject) => {
          //if (annObject.get_metadata()['Subject'] && annObject.get_metadata()['Subject'] === 'primaryLocationArea') {
          if  (annObject.get_metadata()['Label'] ) {
            const volumeGUID = annObject.get_metadata()['Label'];
            //console.log("volumeGUID",volumeGUID)

          let voiExisted: boolean = false;
          matchedVOIs.forEach(matchedVOI => {
              if (matchedVOI.volumeGUID === volumeGUID) {
                voiExisted = true;
              }
            });
            if (!voiExisted) {
              const _matchedROIs: MatchedROIs = {
                volumeGUID: "",
                voiROIs: [],
                voiColor: ""
              };
              //use random color for voiColor
              let randomColor = Math.floor(Math.random() * 16777215).toString(16);
              if (randomColor.length === 5) {
                   randomColor = randomColor + '0';
              }
              const forbiddenColors: any[] = ['da2412', '122dda', '33da12', '000000'];
              while (forbiddenColors.includes(randomColor)) {
                 randomColor = Math.floor(Math.random() * 16777215).toString(16);
              }
              _matchedROIs.volumeGUID = volumeGUID;
              _matchedROIs.voiROIs = [];
              _matchedROIs.voiColor =randomColor;

              //_matchedROIs.voiColor = this.rgbToHex((<any>annObject.get_stroke()).stroke.color);
              //if (_matchedROIs.voiColor === '') {
               // _matchedROIs.voiColor = ((<any>annObject.get_stroke()).stroke.color as string).replace('#', '');
              //}
              matchedVOIs.push(_matchedROIs);
            }
            matchedVOIs.forEach(matchedVOI => {
              if (matchedVOI.volumeGUID == volumeGUID) {


                const _voiROI: VoiROI = {
                  name: '',
                  guid: '',
                  slice: '',
                  lastEdited: '',
                  seriesId: '',
                  sopiuid: '',
                  seriesiuid: ''
                };
                _voiROI.name = annObject.get_metadata()["Name"];
                _voiROI.guid = annObject.get_guid();
                _voiROI.seriesId = (<any>cell).seriesId;
                _voiROI.seriesiuid = cell.seriesInstanceUID;
                _voiROI.sopiuid = (<any>frame).Instance.SOPInstanceUID;
                _voiROI.slice = i.toString();
                _voiROI.lastEdited = annObject.get_metadata()["LastEditedOn"];
                if (info.scale) {
                  annObject.get_metadata()["Area"] = toInteger(annObject.getArea() * (info.scale ** 2));
                }

                //console.log("matchedVOIs",matchedVOIs)

                const rowIndex = matchedVOI.voiROIs
                  .findIndex(rowData => rowData.guid === _voiROI.guid);
                if (rowIndex == -1) {
                  matchedVOI.voiROIs.push(_voiROI);
                  //console.log("matchedVOI",matchedVOI)


                }
              }
            });
          }
        });

      }

      for (let i = 0; i < matchedVOIs.length; i++) {


        console.log(" matchedVOIs[i]", matchedVOIs[i])
        const matchedVOI = matchedVOIs[i];
        const voiROIs = matchedVOI.voiROIs;
        const roiTypes = {};
        const roiSlices = {};
        const srcChildren = [];
        let lastEditedBy = null;
        let voiName = '';
        for (let j = 0; j < voiROIs.length; j++) {
          const row = voiROIs[j];
          console.log("row",row)
          const frameIndex = toInteger(row.slice);
          const frame = frames[frameIndex];
          const container = frame.get_container();
          const childIndex = container.get_children().toArray().findIndex(child => child.get_metadata()['Name'] == row.name);


          // Assign a volume GUID to ROIs selected on container annotations
          if (childIndex > -1) {
            const child = container.get_children().get_item(childIndex);
            roiTypes[child.get_metadata()['AnnoType']] = child.get_metadata()['AnnoType'];
            if (parseInt(child.get_metadata()['Area']) > 0 && child.get_metadata()['AnnoType'] != 'CrossRuler') {
              roiSlices[child.get_metadata()['SliceNumber']] = child.get_metadata()['SliceNumber'];
            }
            if (lastEditedBy == null) {
              lastEditedBy = child.get_metadata()['LastEditedBy'];
            }
            child.get_metadata()["VOIStatus"] = "Grouped";
            child.get_metadata()["SeriesId"] = row.seriesId;
            child.get_metadata()["SOPInstanceUID"] = row.sopiuid;
            child.get_metadata()["SeriesInstanceUID"] = row.seriesiuid;
            voiName = child.get_metadata()["Label"];
            child.get_metadata()['StrokeColor'] = Math.floor(Math.random() * 16777215).toString(16);
            srcChildren.push(child);
            //console.log("srcChildren",srcChildren)
            //Assign a color to ROIs selected on container annotations
            const annBrush = lt.Annotations.Engine.AnnSolidColorBrush.create('#' + matchedVOI.voiColor);
            child.set_fill(annBrush);

            // remove selected rows from datasource1
            const selRows = viewerComponent.selection1.selected;
            for (let index = 0; index < selRows.length; index++) {
              const row = selRows[index];
              const frameIndex = toInteger(row.slice) - 1;
              const rowIndex = viewerComponent.rowData1.findIndex(a => a.label === row.label);
              viewerComponent.rowData1.splice(rowIndex, 1);
              if (!(viewerComponent.dataSource1 instanceof MatTableDataSource)) {
              viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
              } else {
              viewerComponent.dataSource1.data = viewerComponent.rowData1;
              }
              viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
              viewerComponent.dataSource1.sort = viewerComponent.sort1;
              viewerComponent.selection1.clear();

            }

            //const rowIndex = viewerComponent.rowData1.findIndex(a => a.name === row.name);
            /*viewerComponent.rowData1.splice(rowIndex, 1);
            if (!(viewerComponent.dataSource1 instanceof MatTableDataSource)) {
            viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
            } else {
            viewerComponent.dataSource1.data = viewerComponent.rowData1;
            }
            viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
            viewerComponent.dataSource1.sort = viewerComponent.sort1;
            viewerComponent.selection1.clear();*/




          }
        }



        // create a row data for datasource2
        const row = new ROIRow();
        const roiType_vals = Object.keys(roiTypes);
        const roiSlices_vals = Object.keys(roiSlices);

        row.name = voiName;
        row.type = (roiType_vals.length == 1) ? roiType_vals[0] : 'Multiple';
        row.slice = '[' + roiSlices_vals.toString() + ']';
        row.createMethod = 'IAG-Demriq';
        row.lastEditedBy = lastEditedBy;
        row.lastEditedOn = this.utils.getCurrentDatetime();

        row.isChanged = false;
        row.srcChildren = srcChildren;
        row.color = matchedVOI.voiColor;
        row.parentCell = cell;
        //const child = container.get_children().get_item(childIndex);


        rowData2.push(row)
      }

      // compute volume with backend API
      if (!(<any>cell).volumes) {
        (<any>cell).volumes = {};
      }

      const getVOIVolume = async (row, info) => {
        // check if volume has already been computed
        if(!(<any>cell).volumes[row.name] || (voiName !== undefined && row.name === voiName)) {
          return this.computeVOIVolumeData(row, info).then((volData) => {
            row.volume = volData.volume_cm3 ? volData.volume_cm3 : null;
            (<any>cell).volumes[row.name] = row.volume
          });
        }
        else {
          row.volume = (<any>cell).volumes[row.name];
          return new Promise<any>(resolve => {
            resolve('');
          });
        }
      };

      return Promise.all(rowData2.map(
        (_row) => getVOIVolume(_row, info))).then(() => {
          // update datasource2
          viewerComponent.dataSource2 = new MatTableDataSource(rowData2);
          viewerComponent.dataSource2.paginator = viewerComponent.paginator2;
          viewerComponent.dataSource2.sort = viewerComponent.sort2;
          info.viewerComponent = viewerComponent;
          this.utils.asyncResizeTrigger();
          return rowData2;
        }
      );
    } catch (error) {
      return new Promise<any>(resolve => {
        resolve([]);
      });
    }
  }


  autoVolumeCalculations(info, indexedCell?, voiName?) {
    // console.log('autoVolumeCalculations...');
    const rowData2 = [];
    try {
      const cell: lt.Controls.Medical.Cell = indexedCell ? indexedCell : this.getActiveCell();
      const frames: Array<lt.Controls.Medical.Frame> = cell.get_frames().toArray();
      const viewerComponent = info.viewerComponent;
      const matchedVOIs: MatchedROIs[] = [];

      for (let i = 0; i < frames.length; i++) {
        const frame = frames[i];
        const container: lt.Annotations.Engine.AnnContainer = frame.get_container();
        container.get_children().toArray().forEach((annObject: lt.Annotations.Engine.AnnObject) => {
          if (annObject.get_metadata()['Subject'] && annObject.get_metadata()['Subject'] === 'primaryLocationArea') {
            const volumeGUID = annObject.get_metadata()['Label'];
          let voiExisted: boolean = false;
          matchedVOIs.forEach(matchedVOI => {
              if (matchedVOI.volumeGUID === volumeGUID) {
                voiExisted = true;
              }
            });
            if (!voiExisted) {
              const _matchedROIs: MatchedROIs = {
                volumeGUID: "",
                voiROIs: [],
                voiColor: ""
              };
              _matchedROIs.volumeGUID = volumeGUID;
              _matchedROIs.voiROIs = [];
              _matchedROIs.voiColor = this.rgbToHex((<any>annObject.get_stroke()).stroke.color);
              if (_matchedROIs.voiColor === '') {
                _matchedROIs.voiColor = ((<any>annObject.get_stroke()).stroke.color as string).replace('#', '');
              }
              matchedVOIs.push(_matchedROIs);
            }
            matchedVOIs.forEach(matchedVOI => {
              if (matchedVOI.volumeGUID == volumeGUID) {
                const _voiROI: VoiROI = {
                  name: '',
                  guid: '',
                  slice: '',
                  lastEdited: '',
                  seriesId: '',
                  sopiuid: '',
                  seriesiuid: ''
                };
                _voiROI.name = annObject.get_metadata()["Name"];
                _voiROI.guid = annObject.get_guid();
                _voiROI.seriesId = (<any>cell).seriesId;
                _voiROI.seriesiuid = cell.seriesInstanceUID;
                _voiROI.sopiuid = (<any>frame).Instance.SOPInstanceUID;
                _voiROI.slice = i.toString();
                _voiROI.lastEdited = annObject.get_metadata()["LastEditedOn"];

                if (info.scale) {
                  annObject.get_metadata()["Area"] = toInteger(annObject.getArea() * (info.scale ** 2));
                }

                const rowIndex = matchedVOI.voiROIs
                  .findIndex(rowData => rowData.guid === _voiROI.guid);
                if (rowIndex == -1) {
                  matchedVOI.voiROIs.push(_voiROI);
                }
              }
            });
          }
        });
      }

      for (let i = 0; i < matchedVOIs.length; i++) {
        const matchedVOI = matchedVOIs[i];
        const voiROIs = matchedVOI.voiROIs;
        const roiTypes = {};
        const roiSlices = {};
        const srcChildren = [];
        let lastEditedBy = null;
        let voiName = '';
        for (let j = 0; j < voiROIs.length; j++) {
          const row = voiROIs[j];
          const frameIndex = toInteger(row.slice);
          const frame = frames[frameIndex];
          const container = frame.get_container();
          const childIndex = container.get_children().toArray().findIndex(child => child.get_metadata()['Name'] == row.name);

          // Assign a volume GUID to ROIs selected on container annotations
          if (childIndex > -1) {
            const child = container.get_children().get_item(childIndex);
            roiTypes[child.get_metadata()['AnnoType']] = child.get_metadata()['AnnoType'];
            if (parseInt(child.get_metadata()['Area']) > 0 && child.get_metadata()['AnnoType'] != 'CrossRuler') {
              roiSlices[child.get_metadata()['SliceNumber']] = child.get_metadata()['SliceNumber'];
            }
            if (lastEditedBy == null) {
              lastEditedBy = child.get_metadata()['LastEditedBy'];
            }
            child.get_metadata()["VOIStatus"] = "Grouped";
            child.get_metadata()["SeriesId"] = row.seriesId;
            child.get_metadata()["SOPInstanceUID"] = row.sopiuid;
            child.get_metadata()["SeriesInstanceUID"] = row.seriesiuid;
            voiName = child.get_metadata()["Label"];
            srcChildren.push(child);
          }
        }

        // create a row data for datasource2
        const row = new ROIRow();
        const roiType_vals = Object.keys(roiTypes);
        const roiSlices_vals = Object.keys(roiSlices);

        row.name = voiName;
        row.type = (roiType_vals.length == 1) ? roiType_vals[0] : 'Multiple';
        row.slice = '[' + roiSlices_vals.toString() + ']';
        row.createMethod = 'IAG-Demriq';
        row.lastEditedBy = lastEditedBy;
        row.lastEditedOn = this.utils.getCurrentDatetime();

        row.isChanged = false;
        row.srcChildren = srcChildren;
        row.color = matchedVOI.voiColor;
        row.parentCell = cell;

        rowData2.push(row)
      }

      // compute volume with backend API
      if (!(<any>cell).volumes) {
        (<any>cell).volumes = {};
      }

      const getVOIVolume = async (row, info) => {
        // check if volume has already been computed
        if(!(<any>cell).volumes[row.name] || (voiName !== undefined && row.name === voiName)) {
          return this.computeVOIVolumeData(row, info).then((volData) => {
            row.volume = volData.volume_cm3 ? volData.volume_cm3 : null;
            (<any>cell).volumes[row.name] = row.volume
          });
        }
        else {
          row.volume = (<any>cell).volumes[row.name];
          return new Promise<any>(resolve => {
            resolve('');
          });
        }
      };

      return Promise.all(rowData2.map(
        (_row) => getVOIVolume(_row, info))).then(() => {
          // update datasource2
          viewerComponent.dataSource2 = new MatTableDataSource(rowData2);
          viewerComponent.dataSource2.paginator = viewerComponent.paginator2;
          viewerComponent.dataSource2.sort = viewerComponent.sort2;
          info.viewerComponent = viewerComponent;
          this.utils.asyncResizeTrigger();
          return rowData2;
        }
      );
    } catch (error) {
      return new Promise<any>(resolve => {
        resolve([]);
      });
    }
  }

  ungroupToSlice(info) {
    console.log('ungroupToSlice...');
    const cell: lt.Controls.Medical.Cell = this.getActiveCell();
    const viewerComponent = info.viewerComponent;

    viewerComponent.selection2.selected.forEach((row) => {
      const children = row.srcChildren;
      const rowIndex = viewerComponent.rowData2.findIndex(a => a.name === row.name);
      children.forEach((child: ltAnnotationsEngine.AnnObject) => {
        const isChanged = (<any>child).isChanged;
        // remove VolumeGUID from metadata
        delete child.get_metadata()['VolumeGUID'];
        child.unlock("IAG");
        if (child.get_metadata()["CreateMethod"] !== "GBM AI") {
            // remove outline and fill color
            const annStroke = lt.Annotations.Engine.AnnStroke.create(
              lt.Annotations.Engine.AnnSolidColorBrush.create('red'),
              lt.LeadLengthD.create(1));
            child.set_stroke(annStroke);
            // Set fill color to the ROI
            child.set_fill(null);
        } else {
          let annStroke_gbm;
          switch (child.get_metadata()['Label']) {
            case 'Necrosis':
                annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
                  lt.Annotations.Engine.AnnSolidColorBrush.create('blue'),
                  lt.LeadLengthD.create(1));
              break;
            case 'Edema':
                annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
                  lt.Annotations.Engine.AnnSolidColorBrush.create('green'),
                  lt.LeadLengthD.create(1));
              break;
            case 'Enhancing':
                annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
                  lt.Annotations.Engine.AnnSolidColorBrush.create('red'),
                  lt.LeadLengthD.create(1));
              break;
            default:
                annStroke_gbm = lt.Annotations.Engine.AnnStroke.create(
                  lt.Annotations.Engine.AnnSolidColorBrush.create('red'),
                  lt.LeadLengthD.create(1));
              break;
          }
          child.set_stroke(annStroke_gbm);
        }
        child.get_metadata()["VOIStatus"] = "UnGrouped";
        child.isSelected = false;
        const row = this.utils.createROIRowData(child, cell, isChanged);

        // update table dataSource & set roi as 1st item
        viewerComponent.rowData1.insert(0, row);
      });

      // remove row fron VOIs list
      viewerComponent.rowData2.splice(rowIndex, 1);
      viewerComponent.dataSource2 = new MatTableDataSource(viewerComponent.rowData2);
      viewerComponent.dataSource2.paginator = viewerComponent.paginator2;
      viewerComponent.dataSource2.sort = viewerComponent.sort2;
      viewerComponent.selection2.clear();

      if (!(viewerComponent.dataSource1 instanceof MatTableDataSource)) {
        viewerComponent.dataSource1 = new MatTableDataSource(viewerComponent.rowData1);
      } else {
        viewerComponent.dataSource1.data = viewerComponent.rowData1;
      }
      viewerComponent.dataSource1.paginator = viewerComponent.paginator1;
      viewerComponent.dataSource1.sort = viewerComponent.sort1;
      viewerComponent.selection1.clear();

      // trigger an event to refresh the viewer
      setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, 30);
    });
  }

  hideOrShowROIs(info) {
    console.log('hideOrShowROIs...');
    const cell: lt.Controls.Medical.Cell = this.getActiveCell();
    const viewerComponent = info.viewerComponent;

    viewerComponent.selection1.selected.forEach((row) => {
      // let child: ltAnnotationsEngine.AnnObject = row.child;
      const isChanged = row.isChanged;
      const frame: lt.Controls.Medical.Frame = cell.get_frames().get_item(parseInt(row.slice, 10) - 1);
      const childIndex = frame.get_container().get_children().toArray().findIndex(item => item.get_metadata()['Name'] === row.name);
      const child = frame.get_container().get_children().get_item(childIndex);

      const isVisible = (child.get_isVisible() == null) ? true : child.get_isVisible();
      child.set_isVisible(!isVisible);
      row.child = child;
      row.isChanged = isChanged; // reset row initial change state
    });
    viewerComponent.selection1.clear();

    // trigger an event to refresh the viewer
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);

  }

  hideOrShowVOIs(info) {
    console.log('hideOrShowVOIs...');
    const cell: lt.Controls.Medical.Cell = this.getActiveCell();
    const viewerComponent = info.viewerComponent;

    viewerComponent.selection2.selected.forEach((row) => {
      const children = row.srcChildren;

      children.forEach((child) => {
        const isVisible = (child.get_isVisible() == null) ? true : child.get_isVisible();
        child.set_isVisible(!isVisible);
      });
    });
    viewerComponent.selection2.clear();

    // trigger an event to refresh the viewer
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 30);
  }

  showROIsSettings(info) {
    const cell: lt.Controls.Medical.Cell = this.getActiveCell();
    const viewerComponent = info.viewerComponent;
    const data = {
      viewerComponent: viewerComponent,
    };

    if (viewerComponent.selection1.selected.length <= 0) {
      return;
    }

    if (viewerComponent.selection1.selected.length == 1) {
      const row = viewerComponent.selection1.selected[0];
      const child: ltAnnotationsEngine.AnnObject = this.utils.getSelectedChild(cell, row);

      data['label'] = row.label;
      data['fillColor'] = (child.get_fill() != null) ? (<any>child.get_fill()).color : null;
      data['outlineColor'] = (<any>child.get_stroke()).stroke.color;
      data['outlineSize'] = child.get_stroke().strokeThickness.value;
      data['opacity'] = child.get_opacity() * 100;
      data['selectionColor'] = null;
      data['selectionBoxSize'] = 4;
      data['selectionOutlineColor'] = (<any>child.get_selectionStroke()).stroke.color;
      data['selectionOutilineSize'] = child.get_selectionStroke().strokeThickness.value;
      data['child'] = child;
    } else {
      // set default settings
      const row = viewerComponent.selection1.selected[0];
      const child: ltAnnotationsEngine.AnnObject = this.utils.getSelectedChild(cell, row);

      data['label'] = null;
      data['fillColor'] = null;
      data['outlineColor'] = 'red';
      data['outlineSize'] = 1;
      data['opacity'] = 100;
      data['selectionColor'] = null;
      data['selectionBoxSize'] = 4;
      data['selectionOutlineColor'] = 'blue';
      data['selectionOutilineSize'] = '1';
      data['child'] = child;
    }

    const dialogRef = this.dialog.open(ROISettingsDialogComponent, {
      height: 'auto',
      width: '250px',
      disableClose: true,
      data: data
    });
  }

  showROICopyDelete(info) {
    try {
      if (this.dialog.openDialogs.length) {
        return;
      }
      const activeCell: lt.Controls.Medical.Cell = this.getActiveCell();
      const cellAutomation = activeCell.get_automation();
      if(!cellAutomation){
        console.log('Any image is not selected');
        return;
      }
      const currentEditObject: lt.Annotations.Engine.AnnObject = cellAutomation.get_currentEditObject();
      if (currentEditObject) {
        this.dialog.open(ROICopyDeleteDialogComponent, {
          height: 'auto',
          width: '300px',
          data: { isCopy: info.isCopy, viewerComponent: info.viewerComponent}
        });
      }
      else {
        console.log('Any ROI is not selected');
      }
    } catch (error) {
      console.log('Error in RIO selecting');
    }
  }

  showVOIsSettings(info) {

  }

  onEmptyDivsCollectionChanged(e, args, info) {
    info['seriesManagerService'] = this;
    this.utils.emptyDivsCollectionChanged(e, args, info);
  }

  enableDropTarget(elem, info) {
    info['seriesManagerService'] = this;
    this.utils.enableDropTarget(elem, info);
  }

  onROIRowClick(row, info) {
    const cell = this.getActiveCell();
    this.utils.onROIRowClick(cell, row, info);
  }

  toggleROISelections(info, currentSlice = false) {
    const cell = this.getActiveCell();
    this.utils.toggleROISelections(cell, info);
  }

  rgbToHex(rgb){
    try {
      rgb = rgb.match(/^rgba?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
      return (rgb && rgb.length === 4) ? "" +
      ("0" + parseInt(rgb[1],10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[2],10).toString(16)).slice(-2) +
      ("0" + parseInt(rgb[3],10).toString(16)).slice(-2) : '';
    } catch (error) {

    }
  }

  getRealId(fullID: string) {
    const index = fullID.lastIndexOf('_');
    const stripEngineID = (index == -1 ? fullID : fullID.substr(0, index));
    // let stripEngineID = (index == -1 ? fullID : fullID.substr(index+1));
    return stripEngineID;
  }

  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject  = {};

    for (var i in originalArray) {
       lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      if (i != 'undefined' && i != undefined) {
        newArray.push(lookupObject[i]);
      }
    }
     return newArray;
  }
}
