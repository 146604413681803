import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ReadingDashboardService} from '../../../../_services';
import {ReadingDetailsDialogComponent, ReadingDetailsDialogData} from '../reading-details-dialog.component';
import {ToastOptions, ToastyService} from 'ng2-toasty';

@Component({
  selector: 'app-confirm-termination-dialog',
  templateUrl: './confirm-termination-dialog.component.html',
  styleUrls: ['./confirm-termination-dialog.component.css']
})
export class ConfirmTerminationDialogComponent implements OnInit {

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  constructor(public dialogRef: MatDialogRef<ReadingDetailsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ReadingDetailsDialogData,
              private readingService: ReadingDashboardService,
              private toastyService: ToastyService) {
  }

  ngOnInit(): void {
  }

  terminateReading(): void {
    this.readingService.terminateReading(this.data.readingId, this.data.studyId, this.data.endpoint).subscribe(resp => {
      if (resp.responseCode === 200) {
        this.toastOptions.title = 'Reading is terminated successfully';
        this.toastOptions.msg = 'Reading is terminated successfully. Reading and all related (if any) were terminated successfully';
        this.toastyService.success(this.toastOptions);
      } else {
        this.toastOptions.title = 'ERROR: ' + 'Reading termination failure';
        this.toastOptions.msg = 'Reading and all related (if any) are not terminated due to some reason. Try again or contact support team';
        this.toastyService.error(this.toastOptions);
      }
      this.close(resp.responseCode);
    });
  }

  close(resp: any) {
    this.dialogRef.close(resp);
  }

}
