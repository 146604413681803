import { mJSWScoring } from './../_models/IF/mJSW-Scoring-model';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';

import {environment} from 'src/environments/environment';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Observable} from 'rxjs';
import {BasicResponse} from '../core/interfaces/basic-response';
import { Report } from '../_models/IF/get-ifmultitab-reports-model';


const helper = new JwtHelperService();
const API_URL = environment.apiUrl;
const API_VERSION = environment.MSReadingIFVersion;
const SERVICE = 'msreadingif-dot-';
const BASE_URL = 'https://' + API_VERSION + SERVICE + API_URL;
// const BASE_URL = 'http://localhost:8080/api';

@Injectable({
  providedIn: 'root'
})
export class ReadingIncidentalFindingsService {

  constructor(private http: HttpClient) {
  }

  getReading(studyId, readingId) {
    const url = BASE_URL + '/reading/' + studyId + '/' + readingId;
    return this.http.get(url);
  }

  updateReadingOld(studyId, readingId, reading) {
    const url = BASE_URL + '/reading/' + studyId +'/' + readingId;
    return this.http.put<BasicResponse<any>>(url, reading);
  }

  createIncidentalFindingsEntry(studyId, readerId, readId, visitConfigId, dataObj): Observable<BasicResponse<any>> {
    const url = BASE_URL + '/incidentalfindings/' + studyId + '/' + readerId + '/' + readId + '/' + visitConfigId;
    return this.http.put<BasicResponse<any>>(url, dataObj);
  }

  updateIncidentialFindings(studyId, incidentialFindings): Observable<BasicResponse<any>> {
    const url = BASE_URL + '/incidentalfindings/' + studyId;
    return this.http.post<BasicResponse<any>>(url, incidentialFindings);
  }

  getIncidentalFindingsUploadLink(studyId, readingId, visitConfigId, seriesId, sliceNumber): Observable<BasicResponse<any>> {
    const url = BASE_URL + '/incidentalfindings/upload-link/' + studyId + '/' + readingId + '/' + visitConfigId + '/' + seriesId + '/' + sliceNumber;
    return this.http.get<BasicResponse<any>>(url);
  }

  uplodFileToCloud(uploadUrl, formData) {
    return this.http.put(uploadUrl, formData);
  }

  deleteIncidentialFindingsEntry(studyId, readerId, incidentalFindigsId):  Observable<BasicResponse<any>> {
    const url = BASE_URL + '/incidentalfindings/delete/' + studyId + '/' + readerId + '/' + incidentalFindigsId;
    return this.http.delete<BasicResponse<any>>(url);
  }

  deleteFileInCloud(deleteUrl) {
    return this.http.delete(deleteUrl);
  }

  getPrdefinedComments(studyId, version) {
    const url = BASE_URL + '/predefined-comment/' + studyId + '/' + version;
    return this.http.get(url);
  }

  getPrdefinedQuestions(studyId, version) {
    const url = BASE_URL + '/predefined-question/' + studyId + '/' + version;
    return this.http.get(url);
  }

  getNumberOfAvailableReadings(studyId, readerId): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/get-number-of-available-readings-by-study-id-and-reader-id/${studyId}/${readerId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  getAvailableReadings(studyId, userId): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/get-by-reader-id/${studyId}/${userId}`;
    return this.http.get<BasicResponse<any>>(url);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    const url = `${BASE_URL}/reading/start/${studyId}/${readingId}`;
    return this.http.put<BasicResponse<any>>(url, null);
  }

  updateReading(studyId, readingId, spentSeconds, comment): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/reading/${studyId}/${readingId}`, {spentSeconds, comment});
  }

  completeReading(studyId, reading): Observable<BasicResponse<any>> {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/reading/complete/${studyId}`, reading);
  }

  updateTimepointQuestion(studyId, readingId, visitConfigId, model) {
    return this.http.patch<BasicResponse<any>>(`${BASE_URL}/visit/save/${studyId}/${readingId}/${visitConfigId}`, {
      model: model
    });
  }

  lockTimepoint(studyId, readingId, visitConfigId, model) {
    return this.http.patch<BasicResponse<any>>(`${BASE_URL}/visit/submit/${studyId}/${readingId}/${visitConfigId}`, {
      model: model,
    });
  }

  updateTimepointStatus(studyId, readingId, visitConfigId, status) {
    return this.http.put<BasicResponse<any>>(`${BASE_URL}/visit/status/${studyId}/${readingId}/${visitConfigId}`, {
      status: status
    });
  }

  getPreviousIFmultitabReading(patientId, studyId) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('patientId', patientId);
    queryParams = queryParams.append('studyId', studyId);
    return this.http.get<BasicResponse<Report[]>>(`${BASE_URL}/resources/multi-tab/report-links`, {params: queryParams});
  }

  getmJSWScorings(readingId, visitId) {
    return this.http.get<BasicResponse<mJSWScoring>>(`${BASE_URL}/scoring/get-last/${readingId}/${visitId}`);
  }

  updatemJSWScoringis(readingId, visitId, scores: mJSWScoring) {
    return this.http.post<BasicResponse<any>>(`${BASE_URL}/scoring/${readingId}/${visitId}`, {
      rightKneeLateralMm: scores.rightKneeLateralMm,
      rightKneeMedialMm: scores.rightKneeMedialMm,
      leftKneeLateralMm: scores.leftKneeLateralMm,
      leftKneeMedialMm: scores.leftKneeMedialMm,
      rightKneeLateralValid: scores.rightKneeLateralValid,
      rightKneeMedialValid: scores.rightKneeMedialValid,
      leftKneeLateralValid: scores.leftKneeLateralValid,
      leftKneeMedialValid: scores.leftKneeMedialValid,
      leftKneeComment: scores.leftKneeComment,
      rightKneeComment: scores.rightKneeComment,
      rightHip: scores.rightHip,
      leftHip: scores.leftHip,
      hipComment: scores.hipComment
    });
  }

}
