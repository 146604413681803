import { HangingProtocolModel } from './hanging-protocol.model';
import { HangingProtocolListDetailsModel } from './hanging-protocol-list-details.model';
import get from 'lodash/get';

export class HangingProtocolListModel {
  info: HangingProtocolListDetailsModel = null;
  items: Array<HangingProtocolModel> = [];

  constructor(data: any) {
    if (data) {
      this._parse(data);
    }
  }

  get defaultProtocol() {
    return this.items.find(item => item.default);
  }

  private _parse(data: any) {
    const info = get(data, 'HangingProtocolList.HangingProtocoListDetails', null);
    let items = get(data, 'HangingProtocolList.HangingProtocol', []);
    items = items instanceof Array ? items : [items];

    if (info) {
      const infoModel = new HangingProtocolListDetailsModel();
      infoModel.id = get(info, 'HangingProtocoListStudyIdentifier._text', null);
      infoModel.description = get(info, 'HangingProtocoListDescription._text', null);
      infoModel.criteria = get(info, 'HangingProtocoListScoringCriteria._text', null);
      infoModel.version = get(info, 'HangingProtocolInterfaceCompatibility.VersionNumber._text', null);
      this.info = infoModel;
    }

    if (items.length) {
      items.forEach(item => {
        this.items.push(new HangingProtocolModel(item));
      });
    }
  }
}
