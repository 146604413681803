import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { ViewerAuthenticationService } from '../interfaces/viewer/viewer-authentication-service';

const ROOT_URL = environment.Leadtools.rootURL;
const SERVICES = environment.Leadtools.services;

@Injectable()
export class LeadToolsAuthenticationService implements ViewerAuthenticationService {
  constructor(private http: HttpClient) {
    this.authenticationCode = "";
    this.authenticationMessage = "";
  }

  private _authenticationCode: string;

  get authenticationCode(): string {
      return this._authenticationCode;
  }

  set authenticationCode(value: string) {
      this._authenticationCode = value;
  }

  private _authenticationMessage: string;

  get authenticationMessage(): string {
    return this._authenticationMessage;
  }

  set authenticationMessage(value: string) {        
      this._authenticationMessage = value;
  }

  authenticate(username: string = "", password: string = "") {
    if (!username)
      username = environment.Leadtools.defaultUserName;
    if (!password)
      password = environment.Leadtools.defaultPassword;

    let requestURL = ROOT_URL+"/"+SERVICES.authenticationServiceName+"/AuthenticateUser";
    return this.http.post(requestURL, { userName: username, password: password}, {responseType: 'text'});
  }
}