import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetPageHeaderTitle } from '../../../core/data-management/actions/projects.action';
import { Observable, Subscription } from 'rxjs';
import { BasicResponse } from 'src/app/core/interfaces/basic-response';
import { ReadingFormScoring, ReadingFormScoringComponent } from '../reading-form-scoring.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReadingDXAService } from 'src/app/_services/reading-dxa.service';
import { ReadingConfigFlexibleService } from 'src/app/_services/reading-config-flexible.service';
import { DXADataFormControls, DXADataFormGroup } from './data-model';
import { ToastService } from 'src/app/_services/internal/toast.service';
import { PatientService } from 'src/app/_services/patient.service';

interface Datas {
  recentIQC: boolean;
  hipLaterality: boolean;
  L1L4BMD: number;
  L1L4TScore: number;
  HipBMD: number;
  HipTScore: number;
  comment: string;
  // TODO
}

@Component({
  selector: 'app-dxa',
  templateUrl: './dxa.component.html',
  styleUrls: ['./dxa.component.css']
})
export class DXAComponent extends ReadingFormScoringComponent implements OnInit, OnDestroy, ReadingFormScoring {
  currentReading;
  datasForm: FormGroup = new FormGroup({});
  bodyCompositionOrBMDOnlydatasForm = new FormGroup({});
  flexibleConfig;
  formInitiated = false;
  patientSubscription: Subscription;
  patientCode: string;
  readingVersion;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    private readingDXAService: ReadingDXAService,
    private patientService: PatientService,
    private readingConfigService: ReadingConfigFlexibleService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.viewerEnabledSubject.next(false);
    this.store.dispatch(new SetPageHeaderTitle('DXA'));
    this.initOnCurrentReading();
  }

  ngOnDestroy(): void {
    if (this.patientSubscription) {
      this.patientSubscription.unsubscribe();
    }
  }

  getPatientCode() {
    this.patientSubscription = this.patientService.getById(this.currentReading.patientId).subscribe(response => {
      this.patientCode = response.data.patientCode;
    });
  }

  initOnCurrentReading(): void {
    this.currentReadingSubject.subscribe(currentReading => {
      this.switchSubmitBtnDisabledSubject.next(false);
      this.currentReading = currentReading;
      this.readingConfigService.getById(this.currentReading.flexibleConfigId).subscribe(resp => {
        this.flexibleConfig = resp.data.config;
      });
      this.initReadingForm();
      this.getPatientCode();
    });
  }

  initReadingForm(): void {
    this.formInitiated = false;
    this.currentReading.visit = this.currentReading.visits.filter(v => v.noUploads === false);
    this.onRetrieveData();
  }

  getEndpointName(): string {
    return 'DXA';
  }

  loadReadings(studyId: number, readerId: number): Observable<BasicResponse<any>> {
    return this.readingDXAService.getAvailableReadings(studyId, readerId);
  }

  startReading(studyId: number, readingId: number): Observable<BasicResponse<any>> {
    return this.readingDXAService.startReading(studyId, readingId);
  }

  updateReading(studyId: number, readingId: number, data: {
    spentSeconds: number;
  }, justSaveTime?: boolean): Observable<BasicResponse<any>> {
    const readingData = this.buildReadingData(data);
    this.updateReadingNavigator(this.currentReading);
    const readingTimeData = {
      spentSeconds: data.spentSeconds,
      comment: (this.currentReading.readingVersion === 'SimpleReadDXA' || this.currentReading.readingVersion === 'GenantEligibilityDXA') ?
        this.datasForm.get('comment').value :
        this.bodyCompositionOrBMDOnlydatasForm.get('bodyCompositionOrBMDOnlyComment').value
    };
    if (justSaveTime) {
      return this.readingDXAService.updateReadingTime(studyId, this.currentReading.id, readingTimeData);
    }

    return this.readingDXAService.updateReading(studyId, readingData);
  }

  updateReadingNavigator(reading: any) {
    if (!this.readingListUpdatedSubject.closed) {
      this.readingListUpdatedSubject.next(reading);
    }
  }


  submitReading(studyId: number, data: { spentSeconds: number; }): Observable<BasicResponse<any>> {
    const readingData = this.buildReadingData(data);
    return this.readingDXAService.completeReading(studyId, readingData);
  }

  buildReadingData(data: any) {
    this.currentReading.timeSpent = data.spentSeconds;
    if (this.currentReading.readingVersion === 'SimpleReadDXA' || this.currentReading.readingVersion === 'GenantEligibilityDXA') {
      this.currentReading.comment = this.datasForm.get('comment').value;
      this.currentReading.score.iqc = this.datasForm.get('iqc').value;
      this.currentReading.score.hipLaterality = this.datasForm.get('hipLaterality').value;
      return {
        id: this.currentReading.id,
        score: {
          iqc: this.datasForm.get('iqc').value,
          hipLaterality: this.datasForm.get('hipLaterality').value
        },
        timeSpent: data.spentSeconds,
        comment: this.datasForm.get('comment').value
      };
    }

    if (this.currentReading.readingVersion === 'WholeBodyDXA' || this.currentReading.readingVersion === 'BMDDXA') {
      this.currentReading.comment = this.bodyCompositionOrBMDOnlydatasForm.get('bodyCompositionOrBMDOnlyComment').value;
      this.currentReading.lumbarSpineVertebra = this.bodyCompositionOrBMDOnlydatasForm.get('lumbarSpineVertebra').value;
      this.currentReading.isRegularIQCData = this.bodyCompositionOrBMDOnlydatasForm.get('isRegularIQCData').value;

      return {
        id: this.currentReading.id,
        // isRegularIQCData: this.bodyCompositionOrBMDOnlydatasForm.get('isRegularIQCData').value,
        score: {
          iqc: this.showIQCQuestion ? this.bodyCompositionOrBMDOnlydatasForm.get('isRegularIQCData').value : undefined,
          lumbarSpineVertebra: this.bodyCompositionOrBMDOnlydatasForm.get('lumbarSpineVertebra').value,
          hipLaterality: this.currentReading.score.hipLaterality
        },
        timeSpent: data.spentSeconds,
        comment: this.bodyCompositionOrBMDOnlydatasForm.get('bodyCompositionOrBMDOnlyComment').value
      };
    }
  }

  clearForm(): void {
  }

  onRetrieveData() {
    // create datas for visit
    if (this.currentReading.readingVersion === 'SimpleReadDXA' || this.currentReading.readingVersion === 'GenantEligibilityDXA') {
      this.datasForm = this.fb.group({
        iqc: new FormControl(this.currentReading.score.iqc, Validators.required),
        hipLaterality: new FormControl(this.currentReading.score.hipLaterality, Validators.required),
        lBdm: new FormControl(this.currentReading.score.lBdm),
        lTscore: new FormControl(this.currentReading.score.lTscore),
        hipBdm: new FormControl(this.currentReading.score.hipBdm),
        hipTscore: new FormControl(this.currentReading.score.hipTscore),
        femNeckBdm: new FormControl(this.currentReading.score.femNeckBdm),
        femNeckTscore: new FormControl(this.currentReading.score.femNeckTscore),
        comment: new FormControl(this.currentReading.comment)
      } as DXADataFormControls) as DXADataFormGroup;

      this.checkValidity(this.datasForm);
      this.datasForm.valueChanges.subscribe(() => {
        this.checkValidity(this.datasForm);
      });
    } else if (this.currentReading.readingVersion === 'WholeBodyDXA' || this.currentReading.readingVersion === 'BMDDXA') {
      this.bodyCompositionOrBMDOnlydatasForm = this.fb.group({
        lumbarSpineVertebra: new FormControl('l1l4', Validators.required),
        isRegularIQCData: new FormControl(false, Validators.required),
        bodyCompositionOrBMDOnlyComment: new FormControl(this.currentReading.comment)
      });
    }
    this.formInitiated = true;
  }

  private checkValidity(formControl) {
    this.switchSubmitBtnDisabledSubject.next(formControl.invalid);
  }

  protected get hasGeBody() {
    return this.currentReading?.score?.geBody && Object.keys(this.currentReading.score.geBody).length > 0;
  }
  protected get hasHologicBody() {
    return this.currentReading?.score?.hologicBody && Object.keys(this.currentReading.score.hologicBody).length > 0;
  }

  protected get scoreBody() {
    if (this.hasGeBody) {
      return this.currentReading?.score?.geBody;
    }

    if (this.hasHologicBody) {
      // Hologic body has different structure
      const hologicBody = this.currentReading.score.hologicBody;
      return {
        truk: { totMass: hologicBody.TRUNK_MASS, fatMass: hologicBody.TRUNK_FAT, leanMass: hologicBody.TRUNK_LEAN },
        rightArm: { totMass: hologicBody.RARM_MASS, fatMass: hologicBody.RARM_FAT, leanMass: hologicBody.RARM_LEAN },
        leftArm: { totMass: hologicBody.LARM_MASS, fatMass: hologicBody.LARM_FAT, leanMass: hologicBody.LARM_LEAN },
        
        rightLeg: { totMass: hologicBody.R_LEG_MASS, fatMass: hologicBody.R_LEG_FAT, leanMass: hologicBody.R_LEG_LEAN },
        leftLeg: { totMass: hologicBody.L_LEG_MASS, fatMass: hologicBody.L_LEG_FAT, leanMass: hologicBody.L_LEG_LEAN },

        android: { totMass: hologicBody.ANDROID_MASS, fatMass: hologicBody.ANDROID_FAT, leanMass: hologicBody.ANDROID_LEAN },
        gynoid: { totMass: hologicBody.GYNOID_MASS, fatMass: hologicBody.GYNOID_FAT, leanMass: hologicBody.GYNOID_LEAN },
        
        total: { totMass: hologicBody.SUBTOT_MASS, fatMass: hologicBody.SUBTOT_FAT, leanMass: hologicBody.SUBTOT_LEAN }
      };
    }
  }

  protected calcTotal(bodyStruct) {
    if (bodyStruct) {
      return !bodyStruct.totMass ? bodyStruct.fatMass + bodyStruct.leanMass + bodyStruct.boneMass : bodyStruct.totMass;
    }
  }

  protected hasBodyRegion(bodyRegion) {
    return this.flexibleConfig?.dxaConfig?.DXABMDbodyRegions.includes(bodyRegion);
  }

  protected getLaterality(value, leftValue: any, rightValue: any) {
    if (value === leftValue) {
      return 'Left';
    }

    if (value === rightValue) {
      return 'Right';
    }

    return 'N/A';
  }

  protected get showIQCQuestion() {
    if (this.currentReading.readingVersion === 'WholeBodyDXA' && this.flexibleConfig?.dxaConfig?.DXAWholeBodyIsIQCQuestion) {
      return true;
    }

    return !!(this.currentReading.readingVersion === 'BMDDXA' && this.flexibleConfig?.dxaConfig?.DXABMDIsIQCQuestion);
  }

  protected get showAllRegions() {
    return this.flexibleConfig?.dxaConfig?.DXAWholeBody === 'All_Regions';
  }

  protected get isReferencePopulationGE() {
    return this.currentReading?.score?.referencePopulation === 'GE';
  }
}
