import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {FormMode} from '../../../../../core/constants/form-mode';
import {MatDialog} from '@angular/material/dialog';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import { ImagingProjectReadingBasicComponent } from '../../../imaging-project-reading-basic.component';

@Component({
    selector: 'app-imaging-project-reading-canden-sparcc-wrapper',
    templateUrl: './imaging-project-reading-canden-sparcc-wrapper.component.html',
    styleUrls: ['./imaging-project-reading-canden-sparcc-wrapper.component.css']
})
export class ImagingProjectReadingCandenSparccWrapperComponent extends ImagingProjectReadingBasicComponent implements OnInit {

    versionFormControl = new FormControl(null, [Validators.required]);
    @Input() public mode: FormMode;
    readonly formModes = FormMode;
    readonly readingVersions = ReadingVersion;
    @Output() public clickCancel = new EventEmitter<any>();
    @Output() public clickSubmit = new EventEmitter<any>();
    @Input() selectedConfig: FlexibleConfig;

    constructor(private flowViewer: MatDialog) {
      super();
    }

    ngOnInit(): void {
      if (this.mode === this.formModes.EDIT) {
        this.setupEditVersion();
      }
    }

    handleCancelClick(event) {
        this.clickCancel.emit(event);
    }

    handleSubmitClick(event) {
        this.clickSubmit.emit(event);
    }

    private setupEditVersion() {
      const readingVersion = this.selectedConfig.config.readingVersion;
      if (readingVersion === ReadingVersion.CANDEN_SPARCC_MODERATION) {
        this.versionFormControl.setValue(ReadingVersion.CANDEN_SPARCC_BASIC);
      } else {
        this.versionFormControl.setValue(readingVersion);
      }
    }
}

export interface Reader {
    id: number;
    firstName: string;
    lastName: string;
}
