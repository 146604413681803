import { Directive, HostListener, Input, ElementRef } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
  selector: '[appCustomInput]',
})
export class CustomInputDirective {
  @Input()
  maxLength!: number

  @Input()
  language!: string

  @Input() isAllowFirstZero = 'true'

  @Input() isDirectiveDisabled = false

  constructor(private element: ElementRef, public model: NgControl) {}

  @HostListener('input', ['$event'])
  onEvent() {
    if (this.isDirectiveDisabled) {
      return
    }
    // let regx = this.language == 'ar' ? '[٠-٩^]' : '[^0-9]';
    let newVal: any = this.element.nativeElement.value

     if (newVal.length > this.maxLength) {
      newVal = newVal.slice(0, this.maxLength - newVal.length)
    }

    if (newVal && newVal[0] === '0' && this.isAllowFirstZero === 'false') {
      this.model?.control?.setValue('')
    } else {
      this.model?.control?.setValue(newVal)
    }

    if (
      /^([^0-9]+\.?[^0-9]*|\.[^0-9]+)$/.test(newVal.toString()) &&
      newVal.toString() !== 'NE' 
    ) {
      this.model?.control?.setErrors({ invalid: true })
    }
  }
}
