import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ROIRow } from '../../../../_models/viewer/RoiRowModel';
import { Utils } from '../../../../_services/leadtools/lead-tools-utils';

@Component({
  selector: 'app-roi-filters-panel',
  templateUrl: './roi-filters-panel.component.html',
  styleUrls: ['./roi-filters-panel.component.css']
})
export class RoiFiltersPanelComponent implements OnInit, OnChanges {
  DEFAULT_VALUE = [];
  roiSearchForm: FormGroup;
  label = '';
  @Input() dataSource: MatTableDataSource<ROIRow> = null;
  @Input() options: Array<string> = null;
  @Input() defaultValue = this.DEFAULT_VALUE;
  @Output() filterSet = new EventEmitter();

  constructor(private utils: Utils) {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dataSource && changes.dataSource.currentValue !== undefined) {
      const val = changes.dataSource.currentValue.filter;
      if (this.roiSearchForm) {
        this.roiSearchForm.get('label').setValue(this._isLabelExists(val) ? val : []);
      }
      this.applyFilter();
    }
  }

  ngOnInit(): void {
    this.roiSearchForm = new FormGroup({
      label: new FormControl(this._isLabelExists(this.defaultValue[0]) ? this.defaultValue : [])
    });
    this.dataSource.filterPredicate = this.utils.getRoiFilterPredicate();
    this.applyFilter();
  }

  applyFilter(): void {
    if (this.roiSearchForm) {
      let label = this.roiSearchForm.get('label').value;
      label = label === null ? [] : label;
      this.label = label;
      this.dataSource.filter = this.label;
      this.filterSet.emit();
    }
  }

  clearFilters(): void {
    this.label = '';
    this.roiSearchForm.get('label').setValue([]);
    this.dataSource.filter = '';
  }

  private _isLabelExists(label: string = ''): boolean {
    return this.options.includes(label);
  }

  get labels() {
    return this.options.filter(i => i !== 'New Label');
  }

}
