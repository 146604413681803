import { FormControl, Validators } from "@angular/forms";

export class GlobalLesionModel{
	constructor(lesionType:string,
				patientId:number,
				readerId:number,
				readingId:number,
				visitConfigId:number,
				endpointName:string,
				isBaseline:boolean,
				markerType:string){

			this.patientId = patientId;
			this.readerId = readerId;
			this.readingId = readingId;
			this.visitConfigId = visitConfigId;
			this.endpointName = endpointName;
			this.isBaseline = isBaseline;
			this.markerType = markerType;
			this.type = lesionType;
			this.setDefaults(lesionType);
			}

		setDefaults(lesionType: string) {
			this.markerType = LesionTypeMarkers[lesionType];
			switch (lesionType) {
				case OncologyLesionType.TARGET_LESION:
					this.notMeasurabilityReason = 'PRESENT';
					this.isMeasurable = true;
					break;
				case OncologyLesionType.NON_TARGET_ENHANCING_LESION:
					this.isEnhanced = true;
					this.status = 'PRESENT';
					break;
				case OncologyLesionType.NON_TARGET_NON_ENHANCING_LESION:
					this.isEnhanced = false;
					this.status = 'PRESENT';
					break;
				case OncologyLesionType.NEW_LESION:
					this.status = 'PRESENT';
					this.changeStatus = 'NOT_EVALUABLE';
					break;
				default:
					break;
				}
	}

	createLesionTemplate() {
		this.id = null;
		this.frameNumber = 0;
		this.objectLocationX = 0;
		this.objectLocationY = 0;
		this.sopInstanceUid = '1';
		this.sliceNumber = 0;
		this.seriesId = 0;
		this.primaryLocation = 'Brain';
		this.subLocation = 'location';
		this.isCommentMandatory = null;
		this.laterality = null;
		this.comment = '';
		this.snapshot = null;
		this.isNode = false;
		this.isMeasurable = true;
		this.isEnhanced = true;
		this.notMeasurabilityReason = 'PRESENT';
		this.isMerged = false;
		this.mergedWith = null;
		this.axialLongDiameter = this.type === OncologyLesionType.NEW_LESION ? null : 'NA';
		this.shortAxis = this.type === OncologyLesionType.NEW_LESION ? null : 'NA';
		this.ppd = this.type === OncologyLesionType.NEW_LESION ? null : 'NA';
		this.ppdChgBase = null;
		this.ppdChgNadir = null;
		this.diam = null;
		this.diamChgBase = null;
		this.diamChgNadir = null;
		this.totalVolume = null;
		this.volEdema = null;
		this.volNecrosis = null;
		this.volEnhancing = null;
		this.suvMean = null;
		this.suvMax = null;
		this.densityMean = null;
		this.densityMax = null;
		this.isPetPositive = null;
		this.status = 'PRESENT';
		this.changeStatus = null;
		this.markerType = MarkerType.BI_RULER
	}

			id?:number | any = [null];
			patientId: number | any = [null, Validators.required];
			readerId: number | any = [null, Validators.required];
			readingId: number | any = [null, Validators.required];
			frameNumber: number | any = [null, Validators.required];
			objectLocationX: number | any = [null, Validators.required];
			objectLocationY: number | any = [null, Validators.required];
			visitConfigId: number | any = [null, Validators.required];
			endpointName: string | any = ['', Validators.required];
			isBaseline: boolean | any = [null, Validators.required];
			sopInstanceUid: string | any = ['', Validators.required];
			sliceNumber: number | any = [null, Validators.required];
			seriesId: number | any = [null, Validators.required];
			lesionName?: string | any = [''];
			primaryLocation: number | any = [null, Validators.required];
			subLocation: number | any = [null, Validators.required];
			isCommentMandatory?: boolean | any = [null];
			laterality?: 'LEFT' | 'RIGHT' | any = [null];
			type: string | any = [null, Validators.required];
			comment: string | any = [null];
			snapshot?:Snapshot | any = new FormControl(new Snapshot());
			isNode?: boolean  | any = [null];
			isMeasurable?: boolean | any = [null];
			isEnhanced?: boolean | any = [null];
			notMeasurabilityReason?: 'PRESENT' | 'RESOLVED' | 'TOO_SMALL_TO_MEASURE' | 'MERGED_WITH_ANOTHER_LESION' | 'SPLIT_IN_MULTIPLE_LESION' | 'NOT_EVALUABLE' | any = [null];
			isMerged?: boolean | any = [null];
			mergedWith?: string | any = [null];
			axialLongDiameter?: number | any = [null];
			shortAxis?: number | any = [null];
			ppd?: number | any = [null];
			ppdChgBase?: number | any = [null];
			ppdChgNadir?: number | any = [null];
			diam?: number | any = [null];
			diamChgBase?: number | any = [null];
			diamChgNadir?: number | any = [null];
			markerType?: MarkerType | any = [null];
			totalVolume?: number | any = [null];
			volEdema?: number | any = [null];
			volNecrosis?: number | any = [null];
			volEnhancing?: number | any = [null];
			suvMean?: number | any = [null];
			suvMax?: number | any = [null];
			densityMean?: number | any = [null];
			densityMax?: number | any = [null];
			isPetPositive?: boolean | any = [null];
			status?:'NON_PATHOLOGICAL' | 'PRESENT' | 'PRESENT_UNEQUIVOCAL' | 'PRESENT_EQUIVOCAL'
			| 'UNEQUIVOCAL_PROGRESSION' |'NOT_EVALUABLE' | 'ABSENT_OR_RESOLVED' | 'ABSENT'
			|'DECREASED' | 'INCREASED' | 'EQUIVOCAL_BORDERLINE' | 'UNEQUIVOCAL_WELL_DEFINED' | 'NA' | any = [null];
			changeStatus?: 'WORSE' | 'STABLE' | 'DISAPPEARED' | 'NOT_EVALUABLE' | any = [null];
}
export class Snapshot{
		sliceNumber?: number | any = null;
		seriesId?: number | any = null;
		modalityName?: string | any = null;
		sequenceLabelName?: string | any = null;
		sopInstanceUid?: string | any = null;
		storageFileName?: string | any = null;
		storageFileNamePreview?: string | any = null;
		storagePath?: string | any = null;
		bucketName?: string | any = null;
		fileUrl?: string | any = null;
		fileUrlPreview?: string | any = null;
}

export const allLesionTypes = [
	'TARGET_LESION',
	'NON_TARGET_ENHANCING_LESION',
	'NON_TARGET_NON_ENHANCING_LESION',
	'NEW_LESION',
  'MEASURABLE_LESION',
]

export const prefixLesionID = {
	TARGET_LESION: 'T',
	NON_TARGET_ENHANCING_LESION: 'NTE',
	NON_TARGET_NON_ENHANCING_LESION: 'NTNE',
	NEW_LESION: 'NL',
	MEASURABLE_LESION: 'ML',
}

//enums
export enum OncologyScoringType{
    HISTORICAL = 'HISTORICAL',
    ELIGIBILITY = 'ELIGIBILITY',
    VISIT = 'VISIT'
}
export enum OncologyLesionType{
    TARGET_LESION = 'TARGET_LESION',
    NON_TARGET_ENHANCING_LESION = 'NON_TARGET_ENHANCING_LESION',
	NON_TARGET_NON_ENHANCING_LESION = 'NON_TARGET_NON_ENHANCING_LESION',
	NEW_LESION = 'NEW_LESION',
	NON_TARGET_LESION = 'NON_TARGET_LESION',
  MEASURABLE_LESION = 'MEASURABLE_LESION'
}
export enum OncologyLesionEditType{
    COMMENT = 'COMMENT',
    LOCATION = 'LOCATION',
    MEASUREMENT = 'MEASUREMENT',
    SCREENSHOT = 'SCREENSHOT',
	STATUS = 'STATUS',
	CHANGE = 'CHANGE',
	ENHANCING = 'ENHANCING',
	MEASURABILITY = 'MEASURABILITY',
	NOTMEASURABILITYREASON = 'NOTMEASURABILITYREASON'
}

export interface OncologyAddLesionData{
	viewerData?: {
		objectType? : any,
		objectLocationX? :number,
		objectLocationY?: number,
        axialLongDiameter?: number,
        shortAxis?: number,
        diam?: number,
        sliceNumber?: number,
        seriesId?: number,
        sopInstanceUid?: string,
        frameNumber?: number
	  } ,
	  edit?: any,
	  editType: OncologyLesionEditType,
	  data:	any,
	  requestedData:AddLesionRequest
}
export interface OncologyResponseModel{
	id?: number,
	response: string,
	visitConfigId: number,
	endpointName: string,
	readingId: number,
	type: OncologyResponseType,
	userId: number
}
export enum OncologyResponseType{
	TARGET_LESION = 'TARGET_LESION',
    NON_TARGET_ENHANCING_LESION = 'NON_TARGET_ENHANCING_LESION',
	NON_TARGET_NON_ENHANCING_LESION = 'NON_TARGET_NON_ENHANCING_LESION',
    NEW_LESION = 'NEW_LESION',
    OVERALL = 'OVERALL',
}
export enum OncologyResponseEnum{
	TARGET_LESION = 'targetResponse',
    NON_TARGET_ENHANCING_LESION = 'nonTargetEnhancingResponse',
	NON_TARGET_NON_ENHANCING_LESION = 'nonTargetNonEnhancingResponse',
    NEW_LESION = 'newLesionResponse',
    OVERALL = 'overallResponse',
}
export interface AddLesionRequest{
	readingId: number,
	visitId: number,
	visitConfigId: number,
	lesionType: string,
	markerType: MarkerType,
	lesionName: string
}
export enum MarkerType {
	RULER = 'RULER',
	BI_RULER = 'BI_RULER',
	MARKER = 'MARKER'
}
//constants
export const LesionTypeMarkers = {
	TARGET_LESION : MarkerType.BI_RULER,
	MEASURABLE_LESION : MarkerType.BI_RULER,
	NON_TARGET_ENHANCING_LESION : MarkerType.MARKER,
	NON_TARGET_NON_ENHANCING_LESION : MarkerType.MARKER,
    NEW_LESION : MarkerType.MARKER
}
export const StatusOptions = {
	NEW_LESION: [
		{
		value: 'PRESENT',
		text: 'Present'
		},
		{
		value: 'NOT_EVALUABLE',
		text: 'Not Evaluable'
		},
		{
		value: 'EQUIVOCAL_BORDERLINE',
		text: 'Equivocal Borderline'
		},
		{
		value: 'UNEQUIVOCAL_WELL_DEFINED',
		text: 'Unequivocal Well Defined'
		}],
		NON_TARGET_ENHANCING_LESION :[
			{
			value: 'PRESENT',
			text: 'Present'
			},
			{
			value: 'ABSENT',
			text: 'Absent'
			},
			{
			value: 'UNEQUIVOCAL_PROGRESSION',
			text: 'Unequivocal Progression'
			},
			{
			value: 'NOT_EVALUABLE',
			text: 'Not Evaluable'
		}],
		NON_TARGET_NON_ENHANCING_LESION :[
			{
			value: 'PRESENT',
			text: 'Present'
			},
			{
			value: 'ABSENT',
			text: 'Absent'
			},
			{
			value: 'UNEQUIVOCAL_PROGRESSION',
			text: 'Unequivocal Progression'
			},
			{
			value: 'NOT_EVALUABLE',
			text: 'Not Evaluable'
		}],
}
export const ResponseOptions = {
	NEW_LESION: [
		{
			text: 'Yes',
			value: 'YES'
			},
			{
			text: 'No',
			value: 'NO'
			}
	],
	TARGET_LESION:[
		{
			text: 'NE',
			value: 'NE'
		},
		{
			text: 'NA',
			value: 'NA'
		},
		{
			text: 'CR',
			value: 'CR'
		},
		{
			text: 'PR',
			value: 'PR'
		},
		{
			text: 'SD',
			value: 'SD'
		},
		{
			text: 'PD',
			value: 'PD'
		}],
		NON_TARGET_ENHANCING_LESION :[
			{
				text: 'NE',
				value: 'NE'
			},
			{
				text: 'NA',
				value: 'NA'
			},
			{
				text: 'CR',
				value: 'CR'
			},
			{
				text: 'PR',
				value: 'PR'
			},
			{
				text: 'SD',
				value: 'SD'
			},
			{
				text: 'PD',
				value: 'PD'
			}],
		NON_TARGET_NON_ENHANCING_LESION :[
			{
				text: 'NE',
				value: 'NE'
			},
			{
				text: 'NA',
				value: 'NA'
			},
			{
				text: 'CR',
				value: 'CR'
			},
			{
				text: 'PR',
				value: 'PR'
			},
			{
				text: 'SD',
				value: 'SD'
			},
			{
				text: 'PD',
				value: 'PD'
		}],
		OVERALL:[
			{
				text: 'NE',
				value: 'NE'
			},
			{
				text: 'NA',
				value: 'NA'
			},
			{
				text: 'CR',
				value: 'CR'
			},
			{
				text: 'PR',
				value: 'PR'
			},
			{
				text: 'SD',
				value: 'SD'
			},
			{
				text: 'PD',
				value: 'PD'
			}]
}
 // temp thing to test mRano Assessment
export const MranoEfficacyLesionSetting = {
	maxLesionNo: {
		TARGET_LESION: 5,
		NON_TARGET_LESION: 10,
		NEW_LESION: 10,
	},
	 prefixLesionID : {
		 TARGET_LESION: 'T',
		 NON_TARGET_LESION: 'NT',
		 NEW_LESION: 'NL',
	 },
	 allLesionTypes : [
		'TARGET_LESION',
		'NON_TARGET_LESION',
		'NEW_LESION'
	  ],
};
export const MranoEfficacyStatusOptions = {
	NEW_LESION :[
		{
			value: 'PRESENT',
			text: 'Present'
			},
			{
			value: 'ABSENT',
			text: 'Absent'
			},
			{
			value: 'NOT_EVALUABLE',
			text: 'NE (not evaluable)'
		}],
		NON_TARGET_LESION :[
			{
			value: 'PRESENT',
			text: 'Present'
			},
			{
			value: 'ABSENT',
			text: 'Absent'
			},
			{
			value: 'NOT_EVALUABLE',
			text: 'NE (not evaluable)'
		}]
}
export const MranoEfficacyResponseOptions = {
		OVERALL:[
			{
				text: 'Not Evaluable',
				value: 'NE'
			},
			{
				text: 'Not applicable',
				value: 'NA'
			},
			{
				text: 'Preliminary CR',
				value: 'PRELIMINARY_CR'
			},
			{
				text: 'Confirmed CR ',
				value: 'CONFIRMED_CR'
			},
			{
				text: 'Preliminary PR',
				value: 'PRELIMINARY_PR'
			},
			{
				text: 'Confirmed PR ',
				value: 'CONFIRMED_PR'
			},
			{
				text: 'Preliminary PD',
				value: 'PRELIMINARY_PD'
			},
			{
				text: 'Confirmed PD',
				value: 'CONFIRMED_PD'
			},
			{
			text: 'Stable disease',
			value: 'SD'
		}]
}
