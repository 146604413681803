export class Base64 {

  public static encode(str) {
    return btoa(unescape(encodeURIComponent(str)));
  }

  public static decode(str) {
    return decodeURIComponent(escape(atob(str)));
  }
}
