import {BasicResponse} from 'src/app/core/interfaces/basic-response';
import {Component, OnInit} from '@angular/core';
import * as jszip from "jszip"
import {DataUploadService, ImagingProjectService} from 'src/app/_services';
import {first, mergeMap, tap} from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {UploadDialogComponent} from '../../DataUpload/upload-dialog/upload-dialog.component';
import {from, Observable, Subject} from "rxjs";
import { StudyModel } from 'src/app/_models/ImagingProject/study-model';

const dxaFileTypes = ['df', 'me', 'r0', 'p0', 'nt', 'r1', 'p1'];
const documentFileTypes = ['pdf', 'doc', 'docx', 'txt', 'csv', 'png', 'jpg', 'jpeg', 'tiff', 'xls', 'xlsx'];

class UploadFileInfo {
  id: number;
  name: string;
  size: number;
  status: string;
  resourceType: string;
}

class BFile {
  url: string
  name: string
  size?: number
}

export type UserStudy = {
  userId: number,
  studyId: number,
  bucketLocation: string,
};

@Component({
  selector: 'app-bulk-upload',
  templateUrl: './bulk-upload.component.html',
  styleUrls: ['./bulk-upload.component.css']
})
export class BulkUploadComponent implements OnInit {

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  emptyFileContainer = true;
  dropOver = false;
  files: BFile[] = [];
  files$ = new Subject<BFile[]>();
  fileToDelete$ = new Subject<BFile>();
  _zipOnly = false;

  areaTitle: string = 'Drag and Drop ZIP archive with DXA calibration records';
  multiselect: boolean = true;
  disabled: boolean;

  private _userStudy: UserStudy | null;
  get userStudy(): UserStudy {
      return this._userStudy;
  }
  set userStudy(value: UserStudy) {
      this._userStudy = value;
  }

  uploadFilesInfo: UploadFileInfo[] = [];

  set parameters(value: boolean) {
    if (value == null)
      value = true;
    this._zipOnly = value;
  }

  get parameters(): boolean {
    return this._zipOnly;
  }

  showZipOnlyWarning = false;

  constructor(private serviceDataUpload: DataUploadService,
              private imagingProjectService: ImagingProjectService,
              public dialog: MatDialog,
              private toastyService: ToastyService) {
    this.showZipOnlyWarning = false;
    this._zipOnly = false;
    this.files$.next([])
  }

  ngOnInit() {
    const userId = Number(JSON.parse(localStorage.getItem('userId')));
    const studyId = Number(JSON.parse(localStorage.getItem('project')).id);
    this.userStudy = { userId: userId,
                        studyId: studyId,
                        bucketLocation: '' };
    this.getStudyById(studyId);
  }

  getStudyById(studyId: number): void {
    this.imagingProjectService.getStudy(studyId).subscribe((response: BasicResponse<StudyModel>) => {
      this.userStudy.bucketLocation = response.data.bucketLocation;
    });
  }

  readFiles(files) {
    this.showZipOnlyWarning = false;
    let pushed = false
    for (var i = 0; i < files.length; i++) {
      if (this._zipOnly == true) {
        let fileName = files[i].name;
        if (fileName.toLowerCase().endsWith(".zip") == true) {
          this.files.push(files[i]);
          pushed = true
        } else {
          this.showZipOnlyWarning = true;
        }
      } else {
        this.files.push(files[i]);
        pushed = true
      }
    }
    if (pushed)
      this.files$.next(this.files)
  }

  dragOver(e) {
    if (this.disabled != true) {
      this.dropOver = true;
    }
    return false;
  }

  dragEnded() {
    if (this.disabled != true) {
      this.dropOver = false;
    }
  }

  dragLeave() {
    if (this.disabled != true) {
      this.dropOver = false;
    }
  }

  dropFile(e) {
    if (this.disabled !== true) {
      e.preventDefault();
      e.stopPropagation();
      this.dropOver = false;
      this.readFiles(e.dataTransfer.files);
    }
    return false;
  }

  clearFiles(event) {
    this.files = [];
    this.files$.next(this.files)
  }

  selectFiles(event) {
    this.showZipOnlyWarning = false;
    let pushed = false;
    if (event.target.files) {
      for (var i = 0; i < event.target.files.length; i++) {
        if (this._zipOnly === true) {
          let fileName = event.target.files[i].name;
          if (fileName.toLowerCase().endsWith('.zip') === true) {
            this.files.push(event.target.files[i]);
            pushed = true;
          } else {
            this.showZipOnlyWarning = true;
          }
        } else {
          this.files.push(event.target.files[i]);
          pushed = true;
        }
      }
    }
    if (pushed)
      this.files$.next(this.files)
    event.preventDefault();
    event.target.value = '';
  }

  save() {
    const formData: FormData = new FormData();

    formData.append('imageProjectId', JSON.stringify(this.userStudy.studyId));
    formData.append('bucketLocation', this.userStudy.bucketLocation);
    // we need to have user in the path
    formData.append('userId', JSON.stringify(this.userStudy.userId));

    const registerFilesInfo = [];
    this.uploadFilesInfo = []
    for (let i = 0; i < this.files.length; i++) {
      const extention = this.extractFileTypes(this.files[i]);
      const resourceType = Array.isArray(extention) ? extention.filter(ex => dxaFileTypes.includes(ex.substring(0, 2))).length > 0 ? 'dxa' :
        ((extention.filter(ex => documentFileTypes.includes(ex)).length > 0) ? 'document' : 'image') : "image";
      const file = {
        name: this.files[i].name,
        size: this.files[i].size,
        resourceType: resourceType
      };
      const fInfo = new UploadFileInfo();
      fInfo.name = this.files[i].name;
      fInfo.size = this.files[i].size;
      fInfo.status = 'pending';
      fInfo.resourceType = resourceType;
      this.uploadFilesInfo.push(fInfo);
      registerFilesInfo.push(file);
    }
    formData.append('files', JSON.stringify(registerFilesInfo));

    this.openDialog();
    this.serviceDataUpload.getUploadLinks(formData)
      .pipe(first())
      .subscribe(
        ({data}: BasicResponse<BFile[]>) => {
          this.uploadFiles(formData, data);
        }, (error) => {
          this.dialog.closeAll();
          if (error.status === 400) {
            this.toastOptions.title = "ERROR: Acquisition Date";
            this.toastOptions.msg = "Acquisition Date must be valid";
            this.toastyService.error(this.toastOptions);
          }
        });
  }

  extractFileTypes(file: any): Promise<any[]> {
    return new Promise(resolve => {
      jszip.loadAsync(file)
        .then((zip) => {
          const regex = /(?:\.([^.]+))?$/;
          const extentions = [];
          zip.forEach(function (relativePath, zipEntry) {
            if (zipEntry.dir === false) {
              const entryName: any[] = regex.exec(zipEntry.name);
              if (entryName[1])
                extentions.push((regex.exec(zipEntry.name)[1]));
            }
          });
          resolve(extentions)
        }, (e) => {
          resolve(e.message)
        });
    });
  }

  uploadFiles(formData: FormData, dbFilesInfo: BFile[]) {
    const files = [...this.files]
    const observabales: Observable<any>[] = []
    for (let i = 0; i < files.length; i++) {
      let bFile = files[i];
      const obs = this.serviceDataUpload.uplodFileToCloud(dbFilesInfo[i].url, bFile)
        .pipe(first(), tap(data => this.fileToDelete$.next(files[i])))
      observabales.push(obs);
    }
    from(observabales).pipe(mergeMap(it => it, 2)).subscribe(partialResult => {
      console.log("Some uploads completed: " + partialResult)
    }, err => {
      this.dialog.closeAll();
      console.log("Error on uploading files: " + err)
    }, () => {
      this.dialog.closeAll();
      this.toastOptions.title = "Success";
      this.toastOptions.msg = "All uploads done.";
      this.toastyService.success(this.toastOptions);
      console.log("Uploading all files ended. Num of files: " + files.length)
    })
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(UploadDialogComponent, {
      height: '400px',
      width: '600px',
      disableClose: true,
      data: {files: this.uploadFilesInfo}
    });
  }

  deleteFile(fileToDelete: BFile) {
    const index = this.files.indexOf(fileToDelete)
    if (index >= 0) {
      this.files.splice(index, 1);
      this.files$.next(this.files);
    }
    return false;
  }
}
