import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ReadingVersion} from '../../../../../core/constants/reading-version';
import {ModalityModel} from '../../../../../_models/ImagingProject/modality-model';
import {SequenceLabelModel} from '../../../../../_models/ImagingProject/sequence-label-model';
import {User} from '../../../../../_models/user';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import {FlexibleConfig} from '../../../../../core/interfaces/flexible-config';
import {FormMode} from '../../../../../core/constants/form-mode';
import {BatchConfigModel} from '../../../../../_models/BatchLogic/batch-config-model';
import {MatTableDataSource} from '@angular/material/table';
import {ToastOptions, ToastyService} from 'ng2-toasty';
import {MatSelectChange} from '@angular/material/select';
import {forkJoin} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ReadingConfigFlexibleService, StudySequenceLabelService, StudyService, StudyUserService} from '../../../../../_services';
import {BatchLogicService} from '../../../../../_services/batch-logic.service';
import {MatDialog} from '@angular/material/dialog';
import {AutoBatchConfigPayload} from '../../../../../core/payload/auto-batch-config-payload';
import {AutoCreationBatchConfigDialogComponent} from '../../../../../components/controls/auto-creation-batch-config-dialog/auto-creation-batch-config-dialog.component';
import {ReadingType} from '../../../../../core/constants/reading-type';
import {ImageViewerConfigurations, JSW_OARSI_BasicConfigModel, JSW_OARSI_VisitModel} from '../../../../../_models/ImagingProject/JSW/jsw-basic-config-model';
import {
  defaultBasicAutoBatchConfig,
  ImagingProjectSubmitEvent
} from '../../imaging-project-reading-selector/imaging-project-reading-selector.component';
import {ImagingProjectReadingBasicComponent} from '../../../imaging-project-reading-basic.component';
import { VisitModalityConfiguratorDialogComponent } from '../../visit-modality-configurator-dialog/visit-modality-configurator-dialog.component';
import { VisitType } from 'src/app/_models/ImagingProject/patient-model';
import * as moment from 'moment';

enum EndpointReadingVersions {
  EXTERNAL_AI = 'External AI'
}

interface ConfigForm {
  endpointReadingVersion: EndpointReadingVersions;
  readingLevel: 'patient';
  readingType: 'parallel';
  readingVersion: ReadingVersion;
  modality: ModalityModel;
  hideVisitHistory: string;
  imageViewerConfiguration: string;
  useDynamikaAI: string;
  anatomySelector: SequenceLabelModel[];
  batchManagement: boolean;
  modalities: [];
  readers: User[];
  visit: [];
  lateralityAIcheck: boolean;
  radioboticsAIcheck: boolean;
  enableObservedAdverseEvent: boolean;
}

type ConfigControls = { [key in keyof ConfigForm]: FormControl };

type ConfigFormGroup = FormGroup & { value: ConfigForm, controls: ConfigControls };

@Component({
  selector: 'app-imaging-project-reading-jsw-basic',
  templateUrl: './imaging-project-reading-jsw-basic.component.html',
  styleUrls: ['./imaging-project-reading-jsw-basic.component.css']
})
export class ImagingProjectReadingJswBasicComponent extends ImagingProjectReadingBasicComponent implements OnInit {

  readonly HIDE_VISITS_CHRONOLOGY = 'hide_visits_chronology';
  readonly SHOW_VISITS_CHRONOLOGY = 'show_visits_chronology';
  readonly HIDE_SCAN_DATES = 'hide_scan_dates';
  readonly IMAGE_VIEWER_CONFIGURATION_DEFAULT = 'default';
  readonly IMAGE_VIEWER_CONFIGURATION_eCRF_ONLY = 'eCRF mode only';
  readonly FORM_MODE = FormMode;

  readonly MIN_READERS = 1;
  readonly MAX_READERS = 20;
  readonly MODERATOR_READERS = 1;

  readonly readingVersions = ReadingVersion;

  @Input() selectedConfig: FlexibleConfig<JSW_OARSI_BasicConfigModel>;

  @Input() selectedReadingVersion: String;

  @Input() public mode: FormMode;

  @Input() set configModel(configModel: JSW_OARSI_BasicConfigModel) {
    if (configModel) {
      this._configModel = configModel;
    }
  }

  get configModel(): JSW_OARSI_BasicConfigModel {
    return this._configModel;
  }

  @Output() public clickCancel = new EventEmitter<any>();

  @Output() public clickSubmit = new EventEmitter<any>();

  readonly EARLY_TERMINATION_VISIT = 'Early Termination';

  jswForm: ConfigFormGroup;

  batchConfig: BatchConfigModel;
  moderationBatchConfig: BatchConfigModel;
  studySequenceLabels: SequenceLabelModel[] = [];

  availableSequenceLabels: SequenceLabelModel[] = [];
  selectedSequenceLabels: SequenceLabelModel[] = [];
  modalities: ModalityModel[];

  availableReaders: User[] = [];

  readerSelectOptions: User[] = [];
  moderatorReaderSelectOptions: User[] = [];
  selectedModality: ModalityModel;

  readersDataSource: MatTableDataSource<User>;

  displayedReadersColumns: string[] = ['id', 'userName', 'email', 'actionDelete'];

  readers: any[] = [];
  unscheduledAllowed: boolean;

  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };

  studyId: number;

 _configModel: JSW_OARSI_BasicConfigModel
  studyVisits: any[] = [];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private readingConfigFlexibleService: ReadingConfigFlexibleService,
              private studySequenceLabelService: StudySequenceLabelService,
              private batchLogicService: BatchLogicService,
              private studyUserService: StudyUserService,
              private toastyService: ToastyService,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private studyService: StudyService) {
    super();
  }

  private static prepareSubmitEvent(submitEvent: ImagingProjectSubmitEvent): ImagingProjectSubmitEvent {
    if (submitEvent.data.moderationEnabled) {
      submitEvent.data.moderationReader = submitEvent.data.moderatorReaders[0].id;
    }

    const readerToReaderId = reader => {
      return {id: reader.id};
    };
    submitEvent.data.readers = submitEvent.data.readers.map(readerToReaderId);
    return submitEvent;
  }

  ngOnInit() {
    this.studyId = parseInt(this.route.snapshot.params.id, 10);
    this.readersDataSource = new MatTableDataSource<User>();
    this.initConfigForm();

    const serviceCalls = [
      this.studySequenceLabelService.getGroupedStudyModalitiesByStudyId(this.studyId),
      this.studyUserService.getReadersByStudyId(this.studyId),
      this.studySequenceLabelService.getSequenceLabelsByStudyId(this.studyId)
    ];

    if (this.mode === FormMode.EDIT) {
      serviceCalls.push(this.batchLogicService.getBatchConfigsForReadingConfig(this.studyId, this.selectedConfig.id));
    }

    forkJoin(serviceCalls)
      .subscribe(([respGroupedModalitiesResp, readersResponse, studySequenceResp, batchConfigResponse]) => {
        if (this.mode === FormMode.EDIT) {
          this.initBatchConfig(batchConfigResponse);
        }
        this.initConfigFormValues(readersResponse.data);
        this.initModalities(respGroupedModalitiesResp);
        this.initReaders(readersResponse);
        this.prepareSequenceLabels(studySequenceResp);
      });
  }

    //modality config
    initStudyVisits(study: any) {
      //sort study visits by id
      this.studyVisits = this.sortVisits(this.studyVisits);
      this.unscheduledAllowed = study.unscheduledAllowed;
      //add unschaduled visits and early termination
      const unscheduled = new JSW_OARSI_VisitModel();
      unscheduled.name = 'Unscheduled';
      unscheduled.type = VisitType.UNSCHEDULED_REGULAR;
      this.studyVisits.push(unscheduled);

      const earlyTermination = new JSW_OARSI_VisitModel();
      earlyTermination.name = this.EARLY_TERMINATION_VISIT;
      earlyTermination.type = VisitType.UNSCHEDULED_EARLY_TERMINATION;
      this.studyVisits.forEach(visit => visit.isAvailable = true);
      this.studyVisits.push(earlyTermination);
      if (this.mode != FormMode.EDIT) {
        this.jswForm.patchValue({
          visit : this.studyVisits
          })
        this.jswForm.controls.visit.value.forEach(v => v['modalities'] = !this.unscheduledAllowed && v.type === VisitType.UNSCHEDULED_REGULAR ? [] : this.modalities)
      }

    }

    sortVisits(visits) {
      visits.forEach(visit => {
        let visitDate = moment(visit.create);
        if (visit.durationTimeUnit == 'D') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'd');
        } else if (visit.durationTimeUnit == 'W') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'w');
        } else if (visit.durationTimeUnit == 'M') {
          visitDate = moment(visit.create).add(visit.durationTimeValue, 'M');
        }
        visit.visitDate = visitDate;
      });
      visits.sort((a, b) => {
        if (a.visitDate < b.visitDate) {
          return -1;
        }
        if (a.visitDate > b.visitDate) {
          return 1;
        }
        return 0;
      });
      let baselineIndex = visits.indexOf(visits.find(this.isBaseline));
      visits.unshift(visits.find(this.isBaseline));
      visits.splice(baselineIndex + 1, 1);
      return visits;
    }
    isBaseline(visit) {
      return visit.baseline === true;
    }

  initConfigForm(): void {
    this.jswForm = this.fb.group({
      endpointReadingVersion: new FormControl(EndpointReadingVersions.EXTERNAL_AI, [Validators.required]),
      readingVersion: new FormControl(ReadingVersion.JSW_BASIC, [Validators.required]),
      readingLevel: new FormControl('patient', [Validators.required]),
      modalities: new FormControl([], [Validators.required]),
      visit: new FormControl([], [Validators.required]),
      hideVisitHistory: new FormControl(this.SHOW_VISITS_CHRONOLOGY, [Validators.required]),
      readingType: new FormControl('parallel', [Validators.required]),
      readers: new FormControl([], [this.readersValidator]),
      anatomySelector: new FormControl(null, [Validators.required]),
      batchManagement: new FormControl(false, [Validators.required, this.batchManagementValidator]),
      // moderationBatchManagement: new FormControl(false, [Validators.required, this.moderationBatchManagementValidator]),
      imageViewerConfiguration:
        new FormControl(this.IMAGE_VIEWER_CONFIGURATION_DEFAULT, [Validators.required]),
      useDynamikaAI: new FormControl(true, [Validators.required]),
      lateralityAIcheck: new FormControl(true, [Validators.required]),
      radioboticsAIcheck: new FormControl(true, [Validators.required]),
      enableObservedAdverseEvent: new FormControl(false),
    } as ConfigControls) as ConfigFormGroup;
  }

  initConfigFormValues(availableReaders: User[]): void {
    const inputConfig = this.configModel;
    if (this.mode === FormMode.EDIT) {
      const readers = availableReaders.filter(r => !!inputConfig.readers.find(rId => rId.id === r.id));
      this.readerSelectOptions = availableReaders.filter(r => !readers.find(rId => rId.id === r.id));

      this.jswForm.patchValue({
        endpointReadingVersion: inputConfig.endpointReadingVersion,
        readingVersion: inputConfig.readingVersion,
        readingLevel: inputConfig.readingLevel,
        readingType: inputConfig.readingType,
        batchManagement: inputConfig.batchManagement,
        readers: readers,
        modalities: inputConfig.modalities,
        visit: inputConfig.visit,
        hideVisitHistory: inputConfig.hideVisitHistory,
        imageViewerConfiguration: inputConfig.imageViewerConfiguration,
        useDynamikaAI: inputConfig.useDynamikaAI,
        lateralityAIcheck: inputConfig.lateralityAIcheck,
        radioboticsAIcheck: inputConfig.radioboticsAIcheck,
        enableObservedAdverseEvent: inputConfig.enableObservedAdverseEvent || false,
      });

      this.readersDataSource.data = readers;
      this.jswForm.markAllAsTouched();
      this.jswForm.markAsDirty();
    }

    this.jswForm.controls.readers.updateValueAndValidity();
    this.jswForm.updateValueAndValidity();
  }

  initModalities(respGroupedModalitiesResp): void {
    if (!!respGroupedModalitiesResp.data) {
      this.modalities = respGroupedModalitiesResp.data;
      this.modalities.sort((a, b) => a.name.localeCompare(b.name));
      if (this.mode == FormMode.EDIT)
          this.selectedModality = respGroupedModalitiesResp.data.filter(x => this.jswForm.controls.modalities.value.find(m => m.name == x.name))
        else
          this.jswForm.patchValue({
            modalities : this.modalities
            })
          // modality config
          const studyServices = [
            this.studyService.getVisitsByStudyId(this.studyId),
            this.studyService.getById(this.studyId),
          ]
          forkJoin(studyServices).subscribe(([studyVisitResponse, studyResponse]) => {
            this.studyVisits = [...studyVisitResponse['data']];
            this.initStudyVisits(studyResponse['data']);
          })
    }
    this.initDefaultSequenceLabels(this.jswForm.controls.modalities.value);
  }

  // modality config
  openModalitiesDialog() {
    ImagingProjectReadingBasicComponent.clearFocus();
    const dialogRef = this.dialog.open(VisitModalityConfiguratorDialogComponent, {
      data: {
        visits: this.jswForm.controls.visit.value,
        modalities: this.modalities,
        unscheduledAllowed: this.unscheduledAllowed
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.filterVisits(result.visits);
        const uniqueModality = this.getAllVisitsUniqueModalities(result.visits);
        this.jswForm.patchValue({
          modalities :  [...uniqueModality]
          });
        this.initDefaultSequenceLabels(this.jswForm.controls.modalities.value);
      }
    });
  }

  getAllVisitsUniqueModalities(visits: any[]) {
    let modalities = [];
    visits.forEach((visit, visitIndex) => {
      const formVisits = this.jswForm.controls.visit.value;
      formVisits[visitIndex].modalities = [...visit.modalities];
      modalities = [...modalities, ...visit.modalities];
    });
     const uniqueModality = [];
     modalities.forEach((modality) => {
       let index = uniqueModality.findIndex((m) => m.id === modality.id) === -1;
       if (index) uniqueModality.push(modality);
     });
     return uniqueModality;
  }

  private filterVisits(visits) {
    visits.forEach(visit => {
      if (!visit.modalities || visit.modalities.length < 1) {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = false;
      } else {
        this.studyVisits.find(v => (v.id === visit.id) || (!v.id && v.name === visit.name)).isAvailable = true;
      }
    });
  }

  prepareSequenceLabels(studySequenceResp) {
    this.studySequenceLabels = studySequenceResp.data;
    if (this.mode === FormMode.EDIT) {
      if (!!this.configModel?.anatomySelector) {
        this.initSequenceLabels(this.configModel.anatomySelector);
      } else {
        this.initDefaultSequenceLabels(this.modalities);
      }
    } else
      this.initDefaultSequenceLabels(this.modalities);
  }

  initSequenceLabels(sequenceLabels: { id: number }[]) {
    const modalities = this.modalities.filter(m => this.selectedConfig.config.modalities.find(cm => cm.name === m.name));
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.studySequenceLabels
      .filter(label => sequenceLabels.find(targetLabel => label.id === targetLabel.id));
  }

  initDefaultSequenceLabels(modalities) {
    this.availableSequenceLabels = this.getAvailableSequenceLabels(modalities);
    this.selectedSequenceLabels = this.availableSequenceLabels;
  }

  get readersValidator() {
    return (): ValidationErrors | null => {
      return this.isReadersValid() ? null : {
        tableError: `${this.MIN_READERS}..${this.MAX_READERS} users must be in the list`,
        selectError: `please ${this.readersDataSource.data.length < this.MAX_READERS ? 'select' : 'remove'} option`
      };
    };
  }

  get batchManagementValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return control.value === true && ((!!this.batchConfig && !this.batchConfig?.numberOfReadings) || !this.batchConfig)
        ? {norError: 'Number of readings is required'} : null;
    };
  }


  getAvailableSequenceLabels(modalities): SequenceLabelModel[] {
    const configLabels: Set<SequenceLabelModel> = new Set<SequenceLabelModel>();
    for (const modality of modalities) {
      for (const label of modality.sequenceLabels) {
        configLabels.add(label);
      }
    }
    return this.studySequenceLabels
      .filter(label => Array.from(configLabels).find(targetLabel => targetLabel.id === label.id));
  }

  initReaders(readersResponse): void {
    if (readersResponse.responseCode === 200) {
      if (readersResponse.data) {
        this.availableReaders = readersResponse.data;
        if (this.mode === FormMode.NEW) {
          this.readerSelectOptions = (readersResponse.data as User[]).sort(this.compareReadersByName);
          this.moderatorReaderSelectOptions = (readersResponse.data as User[]).sort(this.compareReadersByName);
        } else {
          this.readerSelectOptions.sort(this.compareReadersByName);
          this.moderatorReaderSelectOptions.sort(this.compareReadersByName);
        }
      }
    }
  }

  initBatchConfig(batchConfigResponse): void {
    if (batchConfigResponse.responseCode !== 200 || !batchConfigResponse.data) {
      return;
    }
    batchConfigResponse.data.forEach(batchConfig => {
      if (batchConfig.readingType === ReadingType.BASIC_READING) {
        this.batchConfig = batchConfig;
      } else if (batchConfig.readingType === ReadingType.LEVEL1_READING) {
        this.batchConfig = batchConfig;
      } else if (batchConfig.readingType === ReadingType.MODERATION_READING) {
        this.moderationBatchConfig = batchConfig;
      }
    });
  }

  onSubmit() {
    const result = {
      ...this.jswForm.value,
      readingVersion: this.selectedReadingVersion,
    }
    this.propagateSubmitEvent(result);
  }

  onCancel(event) {
    this.clickCancel.emit(event);
  }

  onModalityTypeChange(event: MatSelectChange) {
    this.jswForm.patchValue({modalities: event.value});
    this.initDefaultSequenceLabels(this.jswForm.controls.modalities.value);
  }

  onAddSelectedReaders() {
    const selectedReaderIds = [...this.jswForm.controls.readers.value, ...this.readersDataSource.data.map(reader => reader.id)];
    this.readersDataSource.data = this.availableReaders
      .filter(reader => selectedReaderIds.includes(reader.id))
      .sort(this.compareReadersByName);
    this.readerSelectOptions = this.availableReaders
      .filter(reader => !selectedReaderIds.includes(reader.id))
      .sort(this.compareReadersByName);
    this.jswForm.controls.readers.setValue(this.readersDataSource.data);
  }

  onDeleteSelectedReader(reader: User): void {
    this.readersDataSource.data = this.readersDataSource.data.filter(r => r.id !== reader.id);
    this.readerSelectOptions = [...this.readerSelectOptions, this.availableReaders
      .find(r => r.id === reader.id)].sort(this.compareReadersByName);
    this.jswForm.controls.readers.setValue(this.readersDataSource.data);
    this.jswForm.controls.readers.updateValueAndValidity();
  }


  onBatchManagementSelected(): void {
    if (this.batchConfig) {
      this.batchConfig.numberOfReadings = null;
    }
    this.jswForm.controls.batchManagement.updateValueAndValidity();
  }


  openAutoCreationBatchConfigDialog(config: BatchConfigModel, isModeration: boolean): void {
    let inputConfig = config as AutoBatchConfigPayload;
    const val = this.jswForm.controls.batchManagement.value;
    if (!val) {
      inputConfig = { ...defaultBasicAutoBatchConfig };
    } else {
      inputConfig = this.batchConfig ? { ...this.batchConfig } : null;
    }

    const dialogRef = this.dialog.open(AutoCreationBatchConfigDialogComponent, {
      width: '500px',
      data: {
        readingType: ReadingType.BASIC_READING,
        configInput: inputConfig,
        readonlyDialog: !val
      }
    });

    dialogRef.afterClosed().subscribe((result: BatchConfigModel) => {
      if (result) {
          this.batchConfig = {...result};
          this.jswForm.get('batchManagement').updateValueAndValidity();
      }
      ImagingProjectReadingBasicComponent.clearFocus();
    });
  }

  propagateSubmitEvent(configModel: JSW_OARSI_BasicConfigModel): void {
    const submitEvent: ImagingProjectSubmitEvent = {
      result: 'submit',
      data: configModel,
      basicBatchConfig: this.prepareBasicBatchConfigPayload(),
    };

    this.clickSubmit.emit(ImagingProjectReadingJswBasicComponent.prepareSubmitEvent(submitEvent));
  }


  prepareBasicBatchConfigPayload(): AutoBatchConfigPayload {
    let configPayload: AutoBatchConfigPayload = null;
    if (this.batchConfig) {
      const configId: number = this.batchConfig.id ? this.batchConfig.id : null;
      if (this.jswForm.get('batchManagement').value) {
        configPayload = {
          numberOfReadings: this.batchConfig.numberOfReadings,
          readingType: ReadingType.BASIC_READING,
          initiationMethod: this.batchConfig.initiationMethod,
          initiationOption: this.batchConfig.initiationOption,
          batchCount: this.batchConfig.batchCount
        };
      } else {
        configPayload = {...defaultBasicAutoBatchConfig};
        configPayload.readingType = ReadingType.BASIC_READING;
      }
      configPayload.id = configId;
    }
    return configPayload;
  }

  compareModality(m1, m2): boolean {
    return m1.name === m2.name;
  }

  compareReadersByName(r1: User, r2: User): number {
    return (r1.firstName + r1.lastName).localeCompare(r2.firstName + r2.lastName);
  }

  private isReadersValid(): boolean {
    return this.readersDataSource.data.length >= this.MIN_READERS && this.readersDataSource.data.length <= this.MAX_READERS;
  }

}
