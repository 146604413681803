import {Component, OnInit, ViewChild, ElementRef} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { RoleService, ActivityService } from '../../../_services';
import { FormControl, Validators } from '@angular/forms';
import { ToastyService, ToastOptions } from 'ng2-toasty';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete } from '@angular/material/autocomplete';
import {Store} from '@ngxs/store';
import {SetPageHeaderTitle} from '../../../core/data-management/actions/projects.action';

export interface RoleData {
  roleId: number,
  roleName: string;
  roleType: string;
  activityName: string;
  activityDescription: string;
}

@Component({
  selector: 'app-imaging-project-role',
  templateUrl: './imaging-project-role.component.html',
  styleUrls: ['./imaging-project-role.component.css']
})
export class ImagingProjectRoleComponent implements OnInit {
  requiredRoleNameCtrl = new FormControl('', [
    Validators.required
  ]);
  requiredRoleTypeCtrl = new FormControl('', [
    Validators.required
  ]);
  toastOptions: ToastOptions = {
    title: '',
    showClose: true,
    timeout: 10000,
    theme: 'material',
  };
  displayedColumns: string[] = ['row-num', 'roleName', 'roleType', 'activityName', 'activityDescription', 'operations'];
  dataSource: MatTableDataSource<RoleData>;
  roleList: RoleData[] = [];
  roles: any[] = [];
  activities: any[] = [];
  role: any = {};
  showTable = true;
  showCreateRoles = false;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectedActivities: any[] = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('activityInput') activityInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(private roleService: RoleService,
    private activityService: ActivityService,
    private toastyService: ToastyService, private store: Store) { }

  ngOnInit() {
    this.store.dispatch(new SetPageHeaderTitle('Roles'));
    this.roleService.getAll().subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.roles = response.data;
          for (let role of this.roles) {
            for (let activity of role.activities) {
              let x = { roleId: role.id, roleName: role.roleName, roleType: role.roleType, activityName: activity.activityName, activityDescription: activity.description };
              this.roleList.push(x);
            }
          }
          this.dataSource = new MatTableDataSource(this.roleList);
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
          this.activityService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                this.activities = response.data;
              } else {
                this.toastOptions.title = "ERROR " + response.responseCode;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  showRoles(element) {
    this.role = {};
    this.showTable = false;
    this.showCreateRoles = true;
    this.requiredRoleNameCtrl.clearValidators();
    this.requiredRoleTypeCtrl.clearValidators();
    if (element) {
      this.roleService.getById(element.roleId).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.role = response.data;
            this.selectedActivities = this.role.activities;
            this.store.dispatch(new SetPageHeaderTitle('Edit Role'));
          } else {
            this.toastOptions.title = "ERROR " + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.store.dispatch(new SetPageHeaderTitle('Create Role'));
    }
  }

  deleteRole(element) {
    this.roleService.delete(element.roleId).subscribe(
      (response: any) => {
        if (response.responseCode === 200) {
          this.roleService.getAll().subscribe(
            (response: any) => {
              if (response.responseCode === 200) {
                this.roleList = [];
                this.roles = response.data;
                for (let role of this.roles) {
                  for (let activity of role.activities) {
                    var x = { roleId: role.id, roleName: role.roleName, roleType: role.roleType, activityName: activity.activityName, activityDescription: activity.description };
                    this.roleList.push(x);
                  }
                }
                this.dataSource.data = this.roleList;
                this.toastOptions.title = "";
                this.toastOptions.msg = "Role successfully deleted.";
                this.toastyService.success(this.toastOptions);
              } else {
                this.toastOptions.title = "ERROR " + response.responseCode;
                this.toastOptions.msg = response.responseMessage;
                this.toastyService.error(this.toastOptions);
              }
            }
          );
        } else {
          this.toastOptions.title = "ERROR " + response.responseCode;
          this.toastOptions.msg = response.responseMessage;
          this.toastyService.error(this.toastOptions);
        }
      }
    );
  }

  hideRoles() {
    this.showTable = true;
    this.showCreateRoles = false;
  }

  saveRole() {
    this.role.activities = this.selectedActivities;
    if (this.role.id) {
      this.roleService.update(this.role).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.roleService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.roleList = [];
                  this.roles = response.data;
                  for (let role of this.roles) {
                    for (let activity of role.activities) {
                      var x = { roleId: role.id, roleName: role.roleName, roleType: role.roleType, activityName: activity.activityName, activityDescription: activity.description };
                      this.roleList.push(x);
                    }
                  }
                  this.dataSource.data = this.roleList;
                  this.toastOptions.title = "";
                  this.toastOptions.msg = "Role successfully updated";
                  this.toastyService.success(this.toastOptions);
                  this.hideRoles();
                } else {
                  this.toastOptions.title = "ERROR " + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = "ERROR " + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    } else {
      this.roleService.create(this.role).subscribe(
        (response: any) => {
          if (response.responseCode === 200) {
            this.roleService.getAll().subscribe(
              (response: any) => {
                if (response.responseCode === 200) {
                  this.roleList = [];
                  this.roles = response.data;
                  for (let role of this.roles) {
                    for (let activity of role.activities) {
                      var x = { roleId: role.id, roleName: role.roleName, roleType: role.roleType, activityName: activity.activityName, activityDescription: activity.description };
                      this.roleList.push(x);
                    }
                  }
                  this.dataSource.data = this.roleList;
                  this.toastOptions.title = "";
                  this.toastOptions.msg = "Role successfully created";
                  this.toastyService.success(this.toastOptions);
                  this.hideRoles();
                } else {
                  this.toastOptions.title = "ERROR " + response.responseCode;
                  this.toastOptions.msg = response.responseMessage;
                  this.toastyService.error(this.toastOptions);
                }
              }
            );
          } else {
            this.toastOptions.title = "ERROR " + response.responseCode;
            this.toastOptions.msg = response.responseMessage;
            this.toastyService.error(this.toastOptions);
          }
        }
      );
    }
  }

  applyFilter(filterValue: string) {
    if (this.dataSource !== undefined) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  compareActivities(a1: any, a2: any): boolean {
    return a1 && a2 ? a1.activityName === a2.activityName : a1 === a2;
  }

}
