import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface IFScreenshotDialog {
  url: string;
  modality: string;
  label: string;
  slice: number;
}

@Component({
  selector: 'app-image-dialog',
  templateUrl: './screenshot-dialog.component.html',
  styleUrls: ['./screenshot-dialog.component.css']
})
export class ScreenshotDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
              public data: IFScreenshotDialog,
              public dialogRef: MatDialogRef<ScreenshotDialogComponent>) {
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

}
